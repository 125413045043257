var Handlebars = require("handlebars/dist/handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "    <div>Attach notarized Proofs of Publication for both Newspaper of General Circulation and\n      Legal Newspaper.  If either or both Proofs of Publication are not available, please \n      state so in the comments section below and provide an anticipated date the Proof(s)\n      will be submitted.</div>\n";
},"3":function(container,depth0,helpers,partials,data) {
    return "  <div>Attach a copy of the “Proof(s) of Publication” for the amendment activity being filed.  If the Proof(s) of Publication is not yet available, please state so in the comments section below and provide an anticipated date the proof(s) will be submitted. </div>\n";
},"5":function(container,depth0,helpers,partials,data) {
    return "  <h3>NEWSPAPER OF GENERAL PUBLICATION</h3>\n  <div class=\"general-publication-proofs-container\"></div>\n  <h3>LEGAL NEWSPAPER PUBLICATION</h3>\n  <div class=\"legal-publication-proofs-container\"></div>\n";
},"7":function(container,depth0,helpers,partials,data) {
    return "  <div class=\"publication-requirements-container\"></div>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "\n<div class=\"m-b-md\">\n  <b>Instructions:</b>\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.isTrust : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data})) != null ? stack1 : "")
    + "</div>\n<form class=\"document-form\">\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.isTrust : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.program(7, data, 0),"data":data})) != null ? stack1 : "")
    + "</form>\n";
},"useData":true});