/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Marionette = require('backbone.marionette');
var Syphon = require('backbone.syphon');
var moment = require('moment');
var userHasRole = require('common/util/userHasRole');
var _ = require('underscore');

var NonDepIndividualView = require('../../common/views/NonDepIndividualView');

var tmplNonDepInternalIndividualFormView = require('../templates/NonDepInternalIndividualFormView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplNonDepInternalIndividualFormView,

  className: 'non-dep-internal-individual-form-view',

  behaviors: {
    ReadOnlyFormBehavior: {}
  },

  ui: {
    nonDepIndividualForm: '.non-dep-individual-form',
    pspCheckCompletedDate: '.psp-check-completed-date',
    nmlsDateCompleted: '.nmls-date-completed',
    nonNmlsDateCompleted: '.non-nmls-date-completed',
    jnetDateReceived: '.jnet-date-received',
    otherDateReceived: '.other-date-received'
  },

  regions: {
    nonDepIndividualForm: '@ui.nonDepIndividualForm'
  },

  templateHelpers: function() {
    return { individualType: this.options.individualType };
  },

  onBeforeShow: function() {
    var isManager = userHasRole('ND - Manager');

    this.individualForm = new NonDepIndividualView({
      fullProfileRequired: false,
      individualType: this.options.individualType,
      officerTypes: this.model.get('officerTypes'),
      model: this.model,
      isReadOnly: this.model.get('isNMLSIndividual') && !isManager
    });

    Syphon.deserialize(this, this.model.toJSON());

    this.showChildView('nonDepIndividualForm', this.individualForm);

    this.ui.pspCheckCompletedDate.datepicker();
    this.ui.nmlsDateCompleted.datepicker();
    this.ui.nonNmlsDateCompleted.datepicker();
    this.ui.jnetDateReceived.datepicker();
    this.ui.otherDateReceived.datepicker();
  },

  validate: function() {
    return this.ui.nonDepIndividualForm
      .find('form')
      .parsley()
      .whenValidate();
  },

  getFormData: function() {
    var formData = _.extend(Syphon.serialize(this), this.individualForm.getFormData());
    formData.individualId = this.model.get('individualId');
    formData.dob = formData.dob ? moment(formData.dob, 'MM-DD-YYYY').format('YYYY-MM-DD') : undefined;
    formData.pspCheck = +formData.pspCheck;
    formData.nmlsCheck = +formData.nmlsCheck;
    formData.nonNmlsCheck = +formData.nonNmlsCheck;
    formData.jnetReceived = +formData.jnetReceived;
    formData.otherCheck = +formData.otherCheck;

    formData.pspCheckCompletedDate = formData.pspCheckCompletedDate
      ? moment(formData.pspCheckCompletedDate, 'MM-DD-YYYY').format('YYYY-MM-DD')
      : null;
    formData.nmlsDateCompleted = formData.nmlsDateCompleted
      ? moment(formData.nmlsDateCompleted, 'MM-DD-YYYY').format('YYYY-MM-DD')
      : null;
    formData.nonNmlsDateCompleted = formData.nonNmlsDateCompleted
      ? moment(formData.nonNmlsDateCompleted, 'MM-DD-YYYY').format('YYYY-MM-DD')
      : null;
    formData.jnetDateReceived = formData.jnetDateReceived
      ? moment(formData.jnetDateReceived, 'MM-DD-YYYY').format('YYYY-MM-DD')
      : null;
    formData.otherDateReceived = formData.otherDateReceived
      ? moment(formData.otherDateReceived, 'MM-DD-YYYY').format('YYYY-MM-DD')
      : null;

    formData.flagged = +formData.flagged;

    return formData;
  }
});
