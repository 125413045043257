var Handlebars = require("handlebars/dist/handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "            <span class=\"label label-danger label-legal-hold-status\">\n              <i class=\"fa fa-lock m-r-xs\"></i> Legal Hold Active\n            </span>\n";
},"3":function(container,depth0,helpers,partials,data) {
    return "            <span class=\"label label-success label-legal-hold-status\">\n              <i class=\"fa fa-unlock m-r-xs\"></i> No Legal Hold Active\n            </span>\n";
},"5":function(container,depth0,helpers,partials,data) {
    var helper;

  return "            <p class=\"input-label\">By checking the box below, and submitting, this entity's legal hold will be removed.</p>\n            <div class=\"checkbox\">\n              <label>\n                <input type=\"checkbox\" name=\"removeLegalHold\" data-parsley-required=\"true\">\n                Remove Legal Hold from <b>"
    + container.escapeExpression(((helper = (helper = helpers.entityName || (depth0 != null ? depth0.entityName : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"entityName","hash":{},"data":data}) : helper)))
    + "</b>\n              </label>\n            </div>\n";
},"7":function(container,depth0,helpers,partials,data) {
    var helper;

  return "            <p class=\"input-label\">By checking the box below, and submitting, this entity will have an active legal hold.</p>\n            <div class=\"checkbox\">\n              <label>\n                <input type=\"checkbox\" name=\"placeLegalHold\" data-parsley-required=\"true\">\n                Place Legal Hold on <b>"
    + container.escapeExpression(((helper = (helper = helpers.entityName || (depth0 != null ? depth0.entityName : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"entityName","hash":{},"data":data}) : helper)))
    + "</b>\n              </label>\n            </div>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "\n<div class=\"page-title clearfix\">\n  <h1 class=\"pull-left m-n p-n\">"
    + alias4(((helper = (helper = helpers.entityName || (depth0 != null ? depth0.entityName : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"entityName","hash":{},"data":data}) : helper)))
    + "</h1>\n  <div class=\"dropdown-container pull-right\"></div>\n</div>\n<div class=\"header-bar-container\"></div>\n<div class=\"breadcrumb-container\"></div>\n\n<div class=\"col-sm-9 col-sm-offset-1\">\n  <div class=\"form-horizontal\">\n    <form class=\"legal-hold-form\">\n      <div class=\"row\">\n        <h3 class=\"m-t-n col-sm-offset-3 col-sm-9\">Edit Legal Hold Status</h3>\n      </div>\n\n      <div class=\"form-group\">\n        <label class=\"control-label col-sm-3\">Current Legal Hold Status</label>\n        <div class=\"form-control-static col-sm-9\">\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.hasLegalHold : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data})) != null ? stack1 : "")
    + "        </div>\n      </div>\n\n      <div class=\"form-group legal-hold-status required\">\n        <label class=\"control-label col-sm-3\">New Legal Hold Status</label>\n        <div class=\"col-sm-9\">\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.hasLegalHold : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.program(7, data, 0),"data":data})) != null ? stack1 : "")
    + "        </div>\n      </div>\n    </form>\n\n    <div class=\"row m-b-lg\">\n      <div class=\"col-sm-6 col-sm-offset-3 pull-left\">\n        <a href=\"#dep/entity/"
    + alias4(((helper = (helper = helpers.entityId || (depth0 != null ? depth0.entityId : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"entityId","hash":{},"data":data}) : helper)))
    + "/dashboard\" class=\"btn btn-default\">Cancel</a>\n      <button class=\"btn btn-primary btn-submit\">Submit</button>\n      </div>\n    </div>\n  </div>\n</div>";
},"useData":true});