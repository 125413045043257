var Handlebars = require("handlebars/dist/handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "        <div class=\"new-address-container form-control-static col-sm-9\"></div>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "        <div class=\"col-sm-9\">\n          <select name=\"newLocationId\" class=\"form-control location-select\" data-placeholder=\"--\"\n            data-parsley-required=\"true\" data-parsley-not-equal=\""
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? depth0.licenseDashboard : depth0)) != null ? stack1.licenseLocationID : stack1), depth0))
    + "\"\n            data-parsley-trigger-after-failure=\"change.select2\"\n            data-parsley-not-equal-message=\"License is currently assosciated with the selected location. Please choose a different location.\">\n          </select>\n        </div> ";
},"5":function(container,depth0,helpers,partials,data) {
    return "    <form class=\"affirmation-form\">\n      <div class=\"form-group required\">\n        <label class=\"control-label col-sm-3\">Affirmation</label>\n        <label class=\"control-label font-weight-normal text-left col-sm-9\">\n          <input type=\"checkbox\" name=\"isCertified\" value=\"1\" data-parsley-required=\"true\"\n            data-parsley-errors-container=\".affirmation-error-container\" />\n          I understand by submitting this License Address Change Application: I am agreeing to be bound by the\n          following\n          declaration: \"I declare that all of my answers on this License Application are complete, true and correct. I\n          make this declaration subject to the penalties of 18 PA.C.S. § 4904 relating to unsworn falsification to\n          authorities.\"\n        </label>\n        <div class=\"col-sm-offset-3 col-sm-9\">\n          <div class=\"affirmation-error-container\"></div>\n        </div>\n      </div>\n      <div class=\"form-group required\">\n        <div class=\"col-sm-offset-3 col-sm-9\">\n          <label><strong>Enter Name Below</strong></label>\n          <input type=\"text\" class=\"form-control\" name=\"certificationName\" data-parsley-required=\"true\"\n            data-parsley-maxlength=\"100\" />\n        </div>\n      </div>\n    </form>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "\n<div class=\"col-sm-8 col-sm-offset-1 m-b-lg\">\n  <div class=\"form-horizontal\">\n    <form class=\"change-license-address-form\">\n      <div class=\"form-group\">\n        <div class=\"org-type\">\n          <label class=\"control-label col-sm-3\">Current License Address</label>\n          <div class=\"current-address-container form-control-static col-sm-9\"></div>\n        </div>\n      </div>\n      <div class=\"form-group required new-location-form\">\n        <label class=\"control-label col-sm-3\">New Location</label>\n"
    + ((stack1 = (helpers.ifCond || (depth0 && depth0.ifCond) || alias2).call(alias1,(depth0 != null ? depth0.viewMode : depth0),"===","int",{"name":"ifCond","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data})) != null ? stack1 : "")
    + "\n      </div>\n      <form class=\"effective-change-date-form\" data-parsley-focus=\"none\">\n        <div class=\"form-group required\">\n          <label class=\"control-label col-sm-3\">Effective Date of Change</label>\n          <div class=\"col-sm-4\">\n            <div class=\"input-group effective-change-date-container\">\n              <input type=\"text\" name=\"effectiveChangeDate\" class=\"form-control datepicker effective-change-date\"\n                placeholder=\"Effective Date of Change\" value=\""
    + alias4(((helper = (helper = helpers.effectiveChangeDate || (depth0 != null ? depth0.effectiveChangeDate : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"effectiveChangeDate","hash":{},"data":data}) : helper)))
    + "\" data-parsley-required=\"true\"\n                data-parsley-date=\"\" data-parsley-trigger-after-failure=\"changeDate\"\n                data-parsley-errors-container=\"#effectiveChangeDateErrorsContainer\" />\n              <div class=\"input-group-addon\">\n                <i class=\"fa fa-calendar\"></i>\n              </div>\n            </div>\n            <div id=\"effectiveChangeDateErrorsContainer\"></div>\n          </div>\n        </div>\n      </form>\n    </form> \n    <div class=\"form-group\">\n      <div class=\"col-sm-offset-3 col-sm-9\">\n        <h3>Document Upload</h3>\n        <label class=\"control-label\">Upload any other relevant documents</label>\n        <div class=\"application-documents-container\"></div>\n      </div>\n    </div>\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.showAffirmation : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "    <div class=\"row\">\n      <div class=\"btn-container col-sm-9 col-sm-offset-3\">\n        <a href=\"#ext/nondep/entity/"
    + alias4(((helper = (helper = helpers.entityId || (depth0 != null ? depth0.entityId : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"entityId","hash":{},"data":data}) : helper)))
    + "/license/"
    + alias4(((helper = (helper = helpers.licenseId || (depth0 != null ? depth0.licenseId : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"licenseId","hash":{},"data":data}) : helper)))
    + "\" class=\"btn btn-default\" role=\"button\">Cancel</a>\n        <button class=\"btn btn-primary submit-button ladda-button\" data-style=\"expand-right\" type=\"button\"><span\n            class=\"ladda-label\">Submit</span></button>\n      </div>\n    </div>\n  </div>\n</div>";
},"useData":true});