/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Marionette = require('backbone.marionette');

var BreadcrumbView = require('common/views/BreadcrumbView');

var ConSerComplaintFormView = require('./ConSerComplaintFormView');

var tmplConSerComplaintFormEditPageView = require('../templates/ConSerComplaintFormEditPageView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplConSerComplaintFormEditPageView,

  className: 'conser-complaint-form-edit-page-view container-fluid',

  ui: {
    editComplaintForm: '.edit-complaint-form',
    breadcrumb: '.breadcrumb-container'
  },

  regions: {
    editComplaintForm: '@ui.editComplaintForm',
    breadcrumb: '@ui.breadcrumb'
  },

  templateHelpers: function() {
    return {
      isExtEdit: this.options.viewMode === 'extEdit'
    };
  },

  onBeforeShow: function() {

    var complaintName = this.model.get('complainantFirstName');
    var complaintId = this.model.get('id');

    var breadcrumbs = [
      {
        link: '#ext/conser/dashboard',
        text: 'Home'
      }
    ];

    if (this.options.viewMode === 'extEdit') {
      breadcrumbs = breadcrumbs.concat([
        {
          link: '#ext/conser/Complaint/' + complaintId + '/dashboard',
          text: complaintName
        }
      ]);
    }

    breadcrumbs.push({
      text: 'Edit Complaint'
    });

    this.showChildView(
      'breadcrumb',
      new BreadcrumbView({
        breadcrumbs: breadcrumbs
      })
    );

    this.form = new ConSerComplaintFormView({
      useNavigationButtons: true,
      viewMode: this.options.viewMode,
      model: this.model
    });
    this.showChildView('editComplaintForm', this.form);
  }
});
