/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var MultiButtonCell = require('common/backgrid/MultiButtonCell');

module.exports = MultiButtonCell.extend({
  className: 'edit-compliance-payment-multi-button-cell multi-button-cell td-grid-cell-btn',

  render: function() {
    if (
      this.model.get('statusId') === '3' ||
      this.model.get('statusId') === '4' ||
      this.model.get('statusId') === '5'
    ) {
      this.$el.hide();
    }

    if (MultiButtonCell.prototype.render) {
      MultiButtonCell.prototype.render.apply(this, arguments);
    }

    this.delegateEvents();
    return this;
  }
});
