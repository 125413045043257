/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2020] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Marionette = require('backbone.marionette');

var GridView = require('psa-marionette/views/GridView');
var PageableCollection = require('psa-backbone/models/PageableCollection');

var ButtonLinkCell = require('common/backgrid/ButtonLinkCell');

var MomentCell = require('common/backgrid/MomentCell');

var tmplNonDepEntitySiteRecordkeepingHistoryView = require('../templates/NonDepEntitySiteRecordkeepingHistoryView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplNonDepEntitySiteRecordkeepingHistoryView,

  className: 'non-dep-entity-site-recordkeeping-history-view container-fluid',

  ui: {
    entitySiteRecordkeepingHistory: '.entity-site-recordkeeping-history-container'
  },

  regions: {
    entitySiteRecordkeepingHistory: '@ui.entitySiteRecordkeepingHistory'
  },

  onBeforeShow: function() {
    this.entitySiteRecordkeepingHistory = new GridView({
      columns: [
        {
          name: 'createdDate',
          label: 'Changed Date',
          cell: MomentCell.MomentCell,
          formatter: MomentCell.MomentDateTimeFormatter.extend({
            emptyValue: '--'
          }),
          sortValue: MomentCell.MomentSortValue,
          headerCell: 'custom',
          width: 40,
          editable: false
        },
        {
          name: 'createdBy',
          label: 'Changed By',
          cell: 'string',
          headerCell: 'custom',
          width: 50,
          editable: false
        },
        {
          name: '',
          label: '',
          cell: ButtonLinkCell.extend({
            name: 'Open',
            buttonClass: 'btn-primary btn-sm',
            href: function(model) {
              var entityId = model.get('entityId');
              var historyId = model.get('id');
              return '#nondep/entity/' + entityId + '/recordkeeping-history/' + historyId;
            }
          }),
          headerCell: 'custom',
          width: 10,
          editable: false,
          sortable: false
        }
      ],
      emptyText: 'No Site Recordkeeping History',
      collection: new PageableCollection(this.model.get('siteRecordkeepingHistory'), { pageSize: 25 })
    });

    this.showChildView('entitySiteRecordkeepingHistory', this.entitySiteRecordkeepingHistory);
  }
});
