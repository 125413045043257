var Handlebars = require("handlebars/dist/handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "\n<div class=\"modal-body\">\n  <form class=\"entity-dba-form\">\n    <div class=\"form-group required\">\n      <label class=\"control-label\">D/B/A Name</label>\n      <input type=\"text\" class=\"form-control\" name=\"dbaName\" placeholder=\"D/B/A Name\" data-parsley-required=\"true\"\n        data-parsley-maxlength=\"500\" data-parsley-errors-container=\"#dbaNameError"
    + alias4(((helper = (helper = helpers.childIndex || (depth0 != null ? depth0.childIndex : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"childIndex","hash":{},"data":data}) : helper)))
    + "\" />\n      <div id=\"dbaNameError"
    + alias4(((helper = (helper = helpers.childIndex || (depth0 != null ? depth0.childIndex : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"childIndex","hash":{},"data":data}) : helper)))
    + "\"></div>\n    </div>\n    <div class=\"form-group required\">\n      <label class=\"control-label\">Fictitious Name Registration</label>\n      <div class=\"fictitious-name-registration-container\"></div>\n    </div>\n    <div class=\"row\">\n      <div class=\"form-group required col-sm-6\">\n        <label class=\"control-label\">Registration Date</label>\n        <div class=\"input-group\">\n          <input type=\"text\" name=\"registrationDate\" class=\"form-control date-picker\" placeholder=\"Registration Date\"\n           data-parsley-required=\"true\" data-parsley-date=\"\" data-parsley-trigger-after-failure=\"changeDate\"\n            data-parsley-errors-container=\"#registrationDateErrorsContainer\" />\n          <div class=\"input-group-addon\">\n            <i class=\"fa fa-calendar\"></i>\n          </div>\n        </div>\n        <div id=\"registrationDateErrorsContainer\"></div>\n      </div>\n    </div>\n  </form>\n</div>\n<div class=\"modal-footer\">\n  <button class=\"btn btn-default\" data-dismiss=\"modal\">Cancel</button>\n  <button class=\"btn btn-primary btn-new-entity-dba btn-md\">Add New D/B/A</button>\n</div>";
},"useData":true});