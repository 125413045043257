/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var Marionette = require('backbone.marionette');

var EmptySetView = require('common/views/EmptySetView');

var NonDepEntityFormLocationPanelView = require('./NonDepEntityFormLocationPanelView');

module.exports = Marionette.CollectionView.extend({
  className: 'nondep-entity-form-location-collection-view',

  childView: NonDepEntityFormLocationPanelView,

  childViewOptions: function(model, index) {
    // Pass the index to the collection view
    return {
      childIndex: index
    };
  },

  childEvents: {
    'remove:location': 'onChildClickRemoveLocation',
    'selected:primary-place-of-business': 'onChangePrimaryPlaceOfBusiness'
  },

  emptyView: EmptySetView,

  emptyViewOptions: {
    emptyText: 'No Locations Provided'
  },

  onChildClickRemoveLocation: function(childView) {
    this.collection.remove(childView.model);
  },

  onChangePrimaryPlaceOfBusiness: function(targetChildView) {
    this.triggerMethod('selected:primary-place-of-business');
    this.children.each(function(child) {
      if (child.cid !== targetChildView.cid) {
        child.setPrimaryPlaceOfBusiness(false);
      }
    });
  },

  hasOnePrimaryPlaceOfBusiness: function() {
    var numberOfPPoBs = this.children.reduce(function(memo, child) {
      if (child.isPrimaryPlaceOfBusiness && _.isFunction(child.isPrimaryPlaceOfBusiness)) {
        if (child.isPrimaryPlaceOfBusiness()) {
          memo++;
        }
      }
      return memo;
    }, 0);
    return numberOfPPoBs === 1;
  },

  getFormData: function() {
    return this.children.reduce(function(memo, child) {
      if (child.getFormData && _.isFunction(child.getFormData)) {
        memo.push(child.getFormData());
      }
      return memo;
    }, []);
  }
});
