/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var Marionette = require('backbone.marionette');

var callByNeed = require('common/util/callByNeed');

require('./DropdownMenuItemView.css');
var tmplDropdownMenuItemView = require('./DropdownMenuItemView.hbs');

module.exports = Marionette.ItemView.extend({
  template: tmplDropdownMenuItemView,

  tagName: 'li',

  options: {
    isDivider: false
  },

  className: function() {
    if (this.model.get('isDivider')) {
      return 'dropdown-menu-item-view divider';
    } else if (this.model.get('isDisabled')) {
      return 'dropdown-menu-item-view disabled';
    }
    return 'dropdown-menu-item-view';
  },

  ui: {
    dropdownItem: '.dropdown-item'
  },

  events: {
    'click @ui.dropdownItem': 'onClickDropdownItem'
  },

  templateHelpers: function() {
    var link = callByNeed(this.model.get('link'), this);
    return {
      link: link
    };
  },

  onClickDropdownItem: function(e) {
    e.preventDefault();
    var onClick = this.model.get('onClick');
    if (onClick && _.isFunction(onClick)) {
      onClick(e, this.model);
    }
  }
});
