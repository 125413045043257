/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Marionette = require('backbone.marionette');
var Syphon = require('backbone.syphon');

var tmplCorpFinInvHistoryFormView = require('../templates/CorpFinInvHistoryFormView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplCorpFinInvHistoryFormView,

  className: 'corp-fin-inv-history-form-view row',

  ui: {
    filedDate: '.filed-date',
    dispositionDate: '.disposition-date',
    effectiveFromDate: '.effective-from-date',
    effectiveToDate: '.effective-to-date'
  },

  onBeforeShow: function() {
    var investmentHistory = this.model.get('invHistory') || {};

    Syphon.deserialize(this, investmentHistory);

    this.ui.filedDate.datepicker();
    this.ui.dispositionDate.datepicker();
    this.ui.effectiveFromDate.datepicker();
    this.ui.effectiveToDate.datepicker();
  },

  getFormData: function() {
    var formData = Syphon.serialize(this);
    formData.investmentId = this.model.get('investmentId');
    formData.investmentHistoryId = this.model.get('investmentHistoryId');

    return formData;
  }
});
