/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Marionette = require('backbone.marionette');
var Syphon = require('backbone.syphon');

var parsleyUtil = require('common/util/parsleyUtil');

var tmplDebtSettlementServicesAnnualReportChangesInOwnershipSectionView = require('./DebtSettlementServicesAnnualReportChangesInOwnershipSectionView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplDebtSettlementServicesAnnualReportChangesInOwnershipSectionView,

  className: 'debt-settlement-services-annual-report-production-report-section-view',

  behaviors: {
    RadioGroupToggleBehavior: {
      radios: [
        {
          target: '#hasNewOwnersExplanationArea',
          radioGroup: '[name="hasNewOwners"]',
          valueToShow: '1'
        },
        {
          target: '#hasQuittedOwnersExplanationArea',
          radioGroup: '[name="hasQuittedOwners"]',
          valueToShow: '1'
        },
        {
          target: '#hasNewAssetsExplanationArea',
          radioGroup: '[name="hasNewAssets"]',
          valueToShow: '1'
        }
      ]
    }
  },

  ui: {
    changesInOwnershipForm: '.changes-in-ownership-form'
  },

  onBeforeShow: function() {
    Syphon.deserialize(this, this.model.get('annualReport'));
  },

  validate: function(config) {
    if (config.type === 'save') {
      parsleyUtil.disableRequiredValidation(this.ui.changesInOwnershipForm);
    } else {
      parsleyUtil.enableRequiredValidation(this.ui.changesInOwnershipForm);
    }
    return this.ui.changesInOwnershipForm.parsley().whenValidate();
  },

  getFormData: function() {
    return Syphon.serialize(this);
  }
});
