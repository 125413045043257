/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var _get = require('lodash.get');
var moment = require('moment');

var cleanUpResponse = require('common/util/cleanUpResponse');

module.exports = function() {
  var deferred = $.Deferred();

  $.soap({
    body: {
      GetAssignedTasks: {
        $xmlns: 'http://dobs.pa.gov/cf/task/bpm/1.0'
      }
    },
    blueprintArrays: [
      'GetAssignedTasksResponse.Tasks.NOTF_TASK_INSTANCE',
      'GetAssignedTasksResponse.TaskTypes.CM_LU_TaskType',
      'GetAssignedTasksResponse.Filings.CF_Filing'
    ],
    blueprint: {
      tasks: [
        '$..NOTF_TASK_INSTANCE',
        {
          taskId: '$..TaskId',
          deliveryDate: '$..DeliveryDate',
          claimedDate: '$..ClaimedDate',
          taskTypeName: '$..TaskTypeName',
          filingId: '$..CFFilingID'
        }
      ],
      taskMetaData: {
        taskTypes: [
          '$..CM_LU_TaskType',
          {
            id: '$.ID',
            name: '$.Name',
            description: '$.Description'
          }
        ],
        filings: [
          '$..CF_Filing',
          {
            id: '$..FilingID',
            appplicationType: '$..Type',
            filingType: '$..FilingType'
          }
        ]
      }
    }
  })
    .done(function(response) {
      cleanUpResponse(response);
      _.each(response.tasks, function(task) {
        task.deliveryDate = moment.utc(task.deliveryDate, moment.ISO_8601).local();
        task.claimedDate = task.claimedDate ? moment(task.claimedDate, moment.ISO_8601) : undefined;

        // The Assigned/Claimed date represents when a task started belonging
        // to a specific user.
        task.assignedClaimedDate = task.deliveryDate;
        if (task.claimedDate && task.claimedDate.isValid()) {
          task.assignedClaimedDate = task.claimedDate;
        }

        var taskTypeName = _get(task, 'taskTypeName', {});
        var filingId = _get(task, 'filingId', {});

        if (taskTypeName.__text) {
          taskTypeName = taskTypeName.__text;
        }

        if (filingId.__text) {
          filingId = filingId.__text;
        }

        var type = _.find(response.taskMetaData.taskTypes, function(type) {
          return taskTypeName === type.name;
        });

        task.type = type.description;

        if (filingId) {
          var filing = _.find(response.taskMetaData.filings, function(filing) {
            return filingId === filing.id;
          });

          if (filing) {
            task.filingType = filing.filingType;
            task.appplicationType = filing.appplicationType;
          }
        }
      });

      deferred.resolve(response.tasks);
    })
    .fail(function(err) {
      deferred.reject();
    });

  return deferred.promise();
};
