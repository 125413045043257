/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var Marionette = require('backbone.marionette');
var callByNeed = require('common/util/callByNeed');

module.exports = Marionette.Behavior.extend({
  onBeforeShow: function() {
    var self = this;
    var radios = callByNeed(this.options.radios, this.view);
    _.each(radios, function(radio) {
      self.$(radio.radioGroup).on('change', function() {
        self.handleTargetDisplay(radio);
      });
      self.handleTargetDisplay(radio);
    });
  },

  handleTargetDisplay: function(radio) {
    var currentRadioValue = this.$(radio.radioGroup + ':checked').val();
    if (currentRadioValue === radio.valueToShow) {
      this.$(radio.target).show();
      this.$(radio.target).removeAttr('disabled');
    } else {
      this.$(radio.target).hide();
      this.$(radio.target).attr('disabled', '');
    }
  }
});
