/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var NProgress = require('nprogress');
var _ = require('underscore');

var NavUtils = require('psa-core/util/nav');

var Scaffold = require('common/layout/Scaffold');

var GetExamDetailsForEntity = require('../../common/services/GetExamDetailsForEntity');
var GetExamDetailsForInternal = require('../../common/services/GetExamDetailsForInternal');
var GetExamPaymentsByExamId = require('../services/GetExamPaymentsByExamId');
var GetNonDepExamBillingTimesheet = require('../services/GetNonDepExamBillingTimesheet');
var formatBillableTimesheet = require('../util/formatBillableTimesheet');

var NonDepInternalExamUpdatePaymentScheduleView = require('../views/NonDepInternalExamUpdatePaymentScheduleView');

module.exports = function(examId) {
  NProgress.start();
  NProgress.set(0.6);

  $.when(
    GetExamDetailsForEntity(examId),
    GetExamDetailsForInternal(examId),
    GetExamPaymentsByExamId(examId),
    GetNonDepExamBillingTimesheet(examId)
  ).done(function(externalDetails, internalDetails, examPaymentDetails, timesheets) {
    NavUtils.navigate('nondep/dashboard/exam/' + examId + '/update-payment-schedule');
    Scaffold.content.show(
      new NonDepInternalExamUpdatePaymentScheduleView({
        model: new Backbone.Model({
          exam: _.extend(externalDetails, internalDetails),
          examInvoiceItems: formatBillableTimesheet(timesheets, internalDetails.hourlyRate),
          examPayments: examPaymentDetails.examPayments,
          examAdminFee: examPaymentDetails.examAdminFee
        })
      })
    );
    NProgress.done();
  });
};
