/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Backbone = require('backbone');
var Marionette = require('backbone.marionette');
var Radio = require('backbone.radio');

var UIUtil = require('psa-core/util/ui');

var ConfirmationModalView = require('common/views/ConfirmationModalView');
var SideNavSectionView = require('common/views/SideNavSectionView');
var BreadcrumbView = require('common/views/BreadcrumbView');
var DropdownView = require('common/views/DropdownView');

var DepTrackingDetailsSectionView = require('./DepTrackingDetailsSectionView');

var SubmitEntityTracking = require('../services/SubmitEntityTracking');

var tmplDepTrackingDashboardView = require('../templates/DepTrackingDashboardView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplDepTrackingDashboardView,

  className: 'dep-tracking-dashboard-view container-fluid',

  ui: {
    trackingWrapper: '.tracking-wrapper',
    trackingActionDropdown: '.dropdown-container',
    breadcrumb: '.breadcrumb-container'
  },

  regions: {
    trackingWrapper: '@ui.trackingWrapper',
    trackingActionDropdown: '@ui.trackingActionDropdown',
    breadcrumb: '@ui.breadcrumb'
  },

  onBeforeShow: function() {
    var entityId = this.model.get('entityId');
    var entityName = this.model.get('entityName');
    var tracking = this.model.get('entityTracking') || {};

    var dropDownOptions = [
      {
        text: 'Edit Entity Tracking',
        link: '#dep/entity/' + entityId + '/tracking/' + tracking.id + '/edit'
      },
      {
        text: 'Delete Entity Tracking',
        onClick: this.onClickDelete.bind(this)
      }
    ];

    var sideNavTabs = [
      {
        id: 'trackingDetails',
        title: 'Tracking Details',
        view: new DepTrackingDetailsSectionView({
          model: this.model
        })
      },
      {
        id: 'notes',
        title: 'Notes',
        view: Radio.channel('dp').request('build:notes-view', {
          objectId: tracking.id,
          objectTypeId: 'ENTITY-TRACKING'
        })
      },
      {
        id: 'auditLog',
        title: 'Audit Log',
        view: Radio.channel('dp').request('build:audit-log-view', {
          objectId: tracking.id,
          objectTypeId: 'ENTITY-TRACKING'
        })
      }
    ];

    this.showChildView(
      'trackingActionDropdown',
      new DropdownView({
        rightAlign: true,
        buttonClass: 'btn-default btn-md',
        model: new Backbone.Model({
          name: 'Tracking Actions',
          options: dropDownOptions
        })
      })
    );

    this.tracking = new SideNavSectionView({
      useNavigationButtons: this.options.isReadOnly,
      collection: new Backbone.Collection(sideNavTabs)
    });

    this.showChildView('trackingWrapper', this.tracking);

    this.showChildView(
      'breadcrumb',
      new BreadcrumbView({
        breadcrumbs: [
          {
            link: '#dep/dashboard',
            text: 'Home'
          },
          {
            link: '#dep/entity/' + entityId + '/dashboard',
            text: entityName
          },
          {
            text: tracking.trackingType + ' (' + tracking.enteredDate + ')'
          }
        ]
      })
    );
  },

  onClickDelete: function(e) {
    e.preventDefault();

    var tracking = this.model.get('entityTracking') || {};
    var entityId = this.model.get('entityId');
    UIUtil.showModal({
      title: 'Confirm Deleting Tracking',
      view: new ConfirmationModalView({
        confirmText: 'Delete Tracking',
        model: new Backbone.Model({
          message: 'Are you sure you want to delete this tracking?',
          onConfirm: function() {
            return SubmitEntityTracking({
              id: tracking.id,
              shouldDelete: '1'
            });
          },
          onConfirmDone: function() {
            Radio.channel('navigate').trigger('show:int:dp:entity', entityId);
          }
        })
      })
    });
  }
});
