/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var PageableCollection = require('psa-backbone/models/PageableCollection');

var PlaceholderView = require('common/views/PlaceholderView');

var DepSEGLocationView = require('../views/DepSEGLocationView');
var GetSEGLocationsBySEG = require('../services/GetSEGLocationsBySEG');

module.exports = {
  channel: 'dp',

  event: 'build:entity-seg-location-view',

  builder: function(segId) {
    return new PlaceholderView({
      viewPromise: buildEntitySEGView(segId)
    });
  }
};

function buildEntitySEGView(segId) {
  var deferred = $.Deferred();
  GetSEGLocationsBySEG(segId)
    .done(function(segs) {
      deferred.resolve(
        new DepSEGLocationView({
          collection: new PageableCollection(segs, { pageSize: 25 })
        })
      );
    })
    .fail(function(err) {
      deferred.reject(err);
    });
  return deferred.promise();
}
