/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var NProgress = require('nprogress');
var Scaffold = require('common/layout/Scaffold');
var Radio = require('backbone.radio');

var NavUtils = require('psa-core/util/nav');

var GetApplicationDetails = require('../../common/services/GetApplicationDetails');

var NonDepExternalApplicationViewOnlyView = require('../views/NonDepExternalActiveApplicationView');

module.exports = function(applicationId) {
  NProgress.start();
  NProgress.set(0.6);

  $.when(GetApplicationDetails(applicationId))
    .done(function(applicationDetails) {
      NavUtils.navigate('#ext/nondep/application/' + applicationId + '/view');
      NProgress.set(0.5);

      var applicationFormIdentifier = applicationDetails.formIdentifier;

      var applicationTypeId = applicationDetails.applicationTypeId;
      var entityIsVerified = applicationDetails.entityIsVerified;
      var promises = [
        // Application Form Builder Promise
        Radio.channel('nd:application').request('build:' + applicationFormIdentifier, {
          applicationId: applicationId,
          applicationTypeId: applicationTypeId,
          isReadOnly: true,
          viewMode: 'ext'
        })
      ];

      // If the entity is not yet verified, also build the entity form to show
      // on the review screen.
      if (!entityIsVerified) {
        promises.push(
          Radio.channel('nd:entity-form').request('build:entity-form', {
            entityId: applicationDetails.entityId,
            isReadOnly: true
          })
        );
      }

      $.when
        .apply($, promises)
        .done(function(applicationFormView, entityForm) {
          applicationFormView.model.set('applicationDetails', applicationDetails);

          Scaffold.content.show(
            new NonDepExternalApplicationViewOnlyView({
              viewMode: 'int',
              model: new Backbone.Model({
                applicationId: applicationId,
                applicationDetails: applicationDetails,
                applicationView: applicationFormView
              })
            })
          );
          NProgress.done();
        })
        .fail(function(err) {
          NProgress.done();
        });
    })
    .fail(function(err) {
      NProgress.done();
    });
};
