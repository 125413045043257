/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Backbone = require('backbone');
var NProgress = require('nprogress');

var NavUtils = require('psa-core/util/nav');

var Scaffold = require('common/layout/Scaffold');

var DepEditLocationView = require('../views/DepLocationEditView');

var GetLocation = require('../services/GetLocation');
var GetEntityIdentifiers = require('../../common/services/GetEntityIdentifiers');
var GetAllLocationStatusLookup = require('../services/GetAllLocationStatusLookup');
var GetAllLocationOfficeTypeLookup = require('../services/GetAllLocationOfficeTypeLookup');
var GetAllLocationActivityLookup = require('../services/GetAllLocationActivityLookup');
var GetAllLocationTypes = require('../services/GetAllLocationTypes');

module.exports = function(entityId, locationId) {
  NProgress.start();
  NProgress.set(0.4);
  $.when(
    GetLocation(locationId),
    GetEntityIdentifiers(entityId),
    GetAllLocationStatusLookup(),
    GetAllLocationActivityLookup()
  )
    .done(function(location, entityIdentifiers, locationStatus, locationActivityType) {
      NProgress.set(0.7);
      $.when(
        GetAllLocationOfficeTypeLookup(entityIdentifiers.categoryId),
        GetAllLocationTypes(entityIdentifiers.categoryId)
      )
        .done(function(locationOfficeType, locationType) {
          NavUtils.navigate('#dep/entity/' + entityId + '/location/' + locationId + '/edit');
          Scaffold.content.show(
            new DepEditLocationView({
              viewMode: 'int',
              model: new Backbone.Model({
                entityId: entityId,
                locationId: locationId,
                entityIdentifiers: entityIdentifiers,
                locationStatus: locationStatus,
                locationOfficeType: locationOfficeType,
                locationActivityType: locationActivityType,
                locationType: locationType,
                location: location
              })
            })
          );
          NProgress.done();
        })
        .fail(function(err) {
          NProgress.done();
        });
    })
    .fail(function(err) {
      NProgress.done();
    });
};
