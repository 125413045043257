/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var get = require('lodash.get');
var moment = require('moment');

var Config = require('../../../config/Config');

var getUserIdFromDn = require('common/util/getUserIdFromDn');
var cleanUpResponse = require('common/util/cleanUpResponse');

module.exports = function(entityId) {
  var deferred = $.Deferred();

  $.soap({
    body: {
      GetInternalTasksByEntity: {
        $xmlns: 'http://dobs.pa.gov/dp/task/bpm/1.0',
        EntityID: entityId
      }
    },
    blueprintArrays: [
      'GetInternalTasksByEntityResponse.Tasks.Task',
      'GetInternalTasksByEntityResponse.Tasks.Task.BusinessAttributes.Attribute',
      'GetInternalTasksByEntityResponse.Tasks.Task.Targets.Target',
      'GetInternalTasksByEntityResponse.TaskTypes.CM_LU_TaskType',
      'GetInternalTasksByEntityResponse.Filings.DP_Filing',
      'GetInternalTasksByEntityResponse.Exams.DP_EntityExam'
    ],
    blueprint: {
      tasks: [
        '$..Task',
        {
          taskId: '$..TaskId',
          deliveryDate: '$..DeliveryDate',
          businessAttributes: [
            '$..Attribute',
            {
              name: '$.$name',
              value: '$.__text'
            }
          ],
          assignee: '$..Assignee.$displayName',
          target: '$..Targets.Target[0].__text'
        }
      ],
      taskMetaData: {
        filings: [
          '$..DP_Filing',
          {
            id: '$..FilingID',
            decisionDueDate: '$..DecisionDueDate',
            entityName: '$..EntityName',
            filingType: '$..FilingType',
            filingTypeId: '$..FilingTypeID',
            submissionTypeName: '$..SubmissionTypeName',
            licenseNumber: '$..LicenseNumber'
          }
        ],
        exams: [
          '$..DP_EntityExam',
          {
            id: '$..ID',
            entityName: '$..EntityName',
            examType: '$..ExamType',
            dueDate: '$..DueDate'
          }
        ],
        taskTypes: [
          '$..CM_LU_TaskType',
          {
            id: '$.ID',
            name: '$.Name',
            description: '$.Description'
          }
        ]
      }
    }
  })
    .done(function(response) {
      cleanUpResponse(response);

      _.each(response.tasks, function(task) {
        task.entityId = entityId;

        if (task.deliveryDate) {
          task.deliveryDate = moment.utc(task.deliveryDate, moment.ISO_8601).local();
        }

        _.each(task.businessAttributes, function(attr) {
          var attrMap = {
            DueDate: 'dueDate',
            DPFilingID: 'filingId',
            ApplicationID: 'applicationId',
            DPExamID: 'examId',
            ComplianceID: 'complianceId',
            TaskTypeName: 'typeName'
          };

          if (attrMap[attr.name]) {
            task[attrMap[attr.name]] = attr.value;
          }
        });

        delete task.businessAttributes;

        var type = _.find(response.taskMetaData.taskTypes, function(type) {
          return task.typeName === type.name;
        });
        task.typeName = get(type, 'description');

        if (task.target) {
          task.target = getUserIdFromDn(task.target);
        }
        task.assignee = task.assignee || task.target;
        task.assigneeId = task.assignee;
        if (task.assigneeId === Config.getExternalUserServiceAccountUserId()) {
          task.assignee = '(Entity)';
        }
        delete task.target;

        if (task.filingId) {
          /* Handle Filing Meta Data */
          var filing = _.find(response.taskMetaData.filings, function(filing) {
            return task.filingId === filing.id;
          });
          if (filing) {
            //add submission type to filing type if generic submission
            if (_.includes(['100', '200', '300'], filing.filingTypeId) && filing.submissionTypeName) {
              task.filingType = filing.filingType + ' - ' + filing.submissionTypeName;
            } else {
              task.filingType = filing.filingType;
            }

            if (filing.decisionDueDate) {
              task.dueDate = moment(filing.decisionDueDate, 'YYYY-MM-DD');
            }
          }
        }

        if (task.examId) {
          /* Handle Filing Meta Data */
          var exam = _.find(response.taskMetaData.exams, function(exam) {
            return task.examId === exam.id;
          });
          if (exam && exam.dueDate) {
            task.dueDate = moment(exam.dueDate, 'YYYY-MM-DD');
          }
        }

        if (!task.dueDate) {
          task.dueDate = '--';
        }
      });

      delete response.taskMetaData;

      deferred.resolve(response.tasks);
    })
    .fail(function(err) {
      deferred.reject();
    });

  return deferred.promise();
};
