/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var moment = require('moment');
var formatAsDollars = require('common/util/formatAsDollars');

var cleanUpResponse = require('common/util/cleanUpResponse');

module.exports = function(enforcementId) {
  var defer = $.Deferred();

  $.soap({
    body: {
      GetEnforcement: {
        $xmlns: 'http://dobs.pa.gov/dp/enforcement/bpm/1.0',
        ID: enforcementId
      }
    },
    blueprint: {
      id: '$..ID',
      entityId: '$..EntityID',
      entityTypeId: '$..EntityTypeID',
      actionId: '$..ActionID',
      areaId: '$..AreaID',
      typeId: '$..TypeID',
      agencyId: '$..AgencyID',
      examAsOfDate: '$..ExamAsOfDate',
      effectiveDate: '$..EffectiveDate',
      terminationDate: '$..TerminationDate',
      cmp: '$..CMP',
      statusId: '$..StatusID',
      isDeleted: '$..IsDeleted',
      action: '$..Action',
      area: '$..Area',
      type: '$..Type',
      agency: '$..Agency',
      status: '$..Status',
      comments: '$..Comments',
      returnedToCompositeHalf: '$..ReturnedToCompositeHalf'
    }
  })
    .done(function(response) {
      cleanUpResponse(response);
      if (response.examAsOfDate) {
        response.examAsOfDate = moment(response.examAsOfDate, 'YYYY-MM-DD').format('MM/DD/YYYY');
      }
      if (response.effectiveDate) {
        response.effectiveDate = moment(response.effectiveDate, 'YYYY-MM-DD').format('MM/DD/YYYY');
      }
      if (response.terminationDate) {
        response.terminationDate = moment(response.terminationDate, 'YYYY-MM-DD').format('MM/DD/YYYY');
      }
      if (response.returnedToCompositeHalf) {
        response.returnedToCompositeHalf = moment(response.returnedToCompositeHalf, 'YYYY-MM-DD').format('MM/DD/YYYY');
      }

      if (response.cmp) {
        response.cmpDisplay = formatAsDollars(parseFloat(response.cmp));
      }
      defer.resolve(response);
    })
    .fail(function(err) {
      defer.reject(err);
    });

  return defer.promise();
};
