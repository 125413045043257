var Handlebars = require("handlebars/dist/handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression;

  return "\n<h1 class=\"page-title\">"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.task : depth0)) != null ? stack1.activity : stack1), depth0))
    + "</h1>\n<div class=\"header-bar\"></div>\n<div class=\"breadcrumb-container\"></div>\n\n<ul class=\"nav nav-tabs m-b-md\" role=\"tablist\">\n  <li role=\"presentation\" class=\"active\">\n    <a href=\"#filing\" class=\"filing-tab filing-part-tab\" aria-controls=\"home\" role=\"tab\" data-toggle=\"tab\">\n      <i class=\"fa fa-inbox\" aria-hidden=\"true\"></i>\n      <div class=\"tab-text\">\n        <div class=\"tab-step\">Step 1</div>\n        <div class=\"tab-title\">Filing</div>\n      </div>\n    </a>\n  </li>\n  <li role=\"presentation\" class=\"disabled the-payment\" >\n    \n    <div role=\"tab\" class=\"payment-tab filing-part-tab\" data-target=\"payment\">\n      <i class=\"fa fa-shopping-cart\" aria-hidden=\"true\"></i>\n      <div class=\"tab-text\">\n        <div class=\"tab-step\">Step 2</div>\n        <div class=\"tab-title\">Submit & Pay</div>\n      </div>\n    </div>\n  </li>\n</ul>\n\n<div class=\"tab-content\">\n  <div role=\"tabpanel\" class=\"tab-pane filing-container active\" id=\"filing\">\n    <div class=\"well well-sm rework-comments-container collapse\">\n      <h4 class=\"m-t-n\">\n        <i class=\"fa fa-university\" aria-hidden=\"true\"></i> Comments\n      </h4>\n      <p class=\"m-n\">"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.filingDetails : depth0)) != null ? stack1.reworkComments : stack1), depth0))
    + "</p>\n    </div>\n    <div class=\"well well-sm acknowledgement-letter-container collapse\">\n      <h4 class=\"m-t-n\">\n        <i class=\"fa fa-university\" aria-hidden=\"true\"></i> Acknowledgement Letter\n      </h4>\n      <p class=\"m-n\">The department has provided feedback regarding this filing in the following acknowledgement letter. Please download and review before re-submitting the filing.</p>\n      <div class=\"acknowledgement-letter-file\"></div>\n    </div>\n    <div class=\"filing-content-container\"></div>\n  </div>\n  <div role=\"tabpanel\" class=\"tab-pane payment-container\" id=\"payment\"></div>\n</div>";
},"useData":true});