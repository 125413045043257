/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');

/**
 * Takes a number and returns a formatted US dollar string.
 * @param {number} rawVal A US dollar amount.
 * @param {integer} decimals - number of decimal-digits.
 * @returns {string} Formatted US dollar string.
 * @example
 *
 * formatAsDollars(1579)
 * // => '$1,578.00'
 * formatAsDollars(1579, 5)
 * // => '$1,578.00,000'
 * formatAsDollars(1579, 0)
 * // => '$1,578'
 */
module.exports = function(rawVal, decimals) {
  if (_.isNumber(rawVal) && !_.isNaN(rawVal)) {
    var isNegative = rawVal < 0;
    var absRawVal = Math.abs(rawVal);

    if (decimals === undefined) {
      decimals = 2;
    }
    var result = '$' + absRawVal.toFixed(decimals).replace(/\B(?=(\d{3})+(?!\d))/g, '$&,');
    if (isNegative) {
      return '-' + result;
    }
    return result;
  }
};
