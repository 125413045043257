/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var Backgrid = require('backgrid');

var AddressDisplayView = require('../../views/AddressDisplayView');

module.exports = Backgrid.AddressCell = Backgrid.Cell.extend({
  className: 'address-cell',

  render: function() {
    var cellValue = this.model.get(this.column.get('name'));
    var content = '--';
    if (cellValue && _.isObject(cellValue)) {
      var addressView = new AddressDisplayView({
        model: new Backbone.Model(cellValue)
      });
      content = addressView.render().el;
    }
    this.$el.empty().append(content);
    this.$el.addClass(this.column.get('name'));
    this.delegateEvents();
    return this;
  }
});
