/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Backbone = require('backbone');
var Marionette = require('backbone.marionette');

var PaymentInvoiceView = require('common/views/PaymentInvoiceView');
var formatAsDollars = require('common/util/formatAsDollars');
var modelGet = require('common/util/modelGet');
var showErrorMessage = require('common/util/showErrorMessage');

var GetComplianceDetailsForEntity = require('../../../common/services/GetComplianceDetailsForEntity');

require('./EntityRemitPaymentForComplianceView.css');
var tmplEntityRemitPaymentForComplianceView = require('./EntityRemitPaymentForComplianceView.hbs');

var PAYMENT_DELETED_TITLE = 'This Payment Is No Longer Valid';
var PAYMENT_DELETED_MESSAGE =
  'The payment has either already been made, been rescheduled, or been deleted. ' +
  'Please return to the home page to see the most up to date set of payments ' +
  'which must be submitted.';

module.exports = Marionette.LayoutView.extend({
  template: tmplEntityRemitPaymentForComplianceView,

  className: 'entity-remit-compliance-payment-view container-fluid',

  ui: {
    headerBar: '.header-bar-container',
    breadcrumb: '.breadcrumb-container',
    summary: '.summary-container',
    paymentInvoice: '.payment-invoice'
  },

  regions: {
    headerBar: '@ui.headerBar',
    breadcrumb: '@ui.breadcrumb',
    summary: '@ui.summary',
    paymentInvoice: '@ui.paymentInvoice'
  },

  templateHelpers: function() {
    var paymentDetails = this.model.get('compliancePaymentDetails');
    var fineAmount = paymentDetails.fineAmount;
    var fineAmountFormatted = '$0.00';
    if (fineAmount) {
      fineAmountFormatted = formatAsDollars(parseFloat(fineAmount));
    }
    return {
      fineAmount: fineAmountFormatted,
      PAYMENT_DELETED_TITLE: PAYMENT_DELETED_TITLE,
      PAYMENT_DELETED_MESSAGE: PAYMENT_DELETED_MESSAGE
    };
  },

  onBeforeShow: function() {
    var isCompliancePaymentDeleted = modelGet(this.model, 'compliancePaymentDetails.paymentIsDeleted');

    this.showChildView('headerBar', this.options.complianceHeaderBar);

    this.showChildView('breadcrumb', this.options.complianceBreadcrumbs);

    this.showChildView('summary', this.options.complianceSummary);

    var paymentDetails = this.model.get('compliancePaymentDetails');

    this.showChildView(
      'paymentInvoice',
      new PaymentInvoiceView({
        isReadOnly: isCompliancePaymentDeleted,
        onBeforeSubmit: this.onBeforeSubmitPayment.bind(this),
        model: new Backbone.Model({
          department: 'NON-DEPOSITORY',
          paymentObjectType: 'COMPLIANCE_PAYMENT',
          paymentObjectId: paymentDetails.compliancePaymentId,
          taskId: this.model.get('taskId'),
          items: [
            {
              description: paymentDetails.paymentDescription,
              quantity: 1,
              unitPrice: paymentDetails.paymentAmount
            }
          ]
        })
      })
    );
  },

  // Double check that the payment is still valid at time of submission.
  onBeforeSubmitPayment: function(e) {
    var defer = $.Deferred();
    var complianceId = modelGet(this.model, 'compliancePaymentDetails.complianceId');
    var compliancePaymentId = modelGet(this.model, 'compliancePaymentDetails.compliancePaymentId');
    var data = {
      complianceId: complianceId,
      compliancePaymentId: compliancePaymentId
    };
    GetComplianceDetailsForEntity(data)
      .done(function(compliancePayment) {
        if (!compliancePayment.paymentIsDeleted) {
          defer.resolve();
        } else {
          defer.reject();
          showErrorMessage({
            errorCode: 'payment.not.found',
            errorTitle: PAYMENT_DELETED_TITLE,
            errorMessage: PAYMENT_DELETED_MESSAGE
          });
        }
      })
      .fail(function(err) {
        defer.reject();
      });

    return defer.promise();
  }
});
