/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var Marionette = require('backbone.marionette');
var Syphon = require('backbone.syphon');
var modelGet = require('common/util/modelGet');

var parsleyUtil = require('common/util/parsleyUtil');
var FileUploadCollection = require('psa-backbone/models/FileUploadCollection');

var CommentsDocumentView = require('common/views/CommentsDocumentView');
var makeUploadCollection = require('common/util/makeUploadCollection');
var FileUploadView = require('common/views/FileUploadView');
var toggleForm = require('common/util/toggleForm');

require('./DepSection112FedAppSectionView.css');

var DepSection112SharesRegisteredCollectionView = require('./DepSection112SharesRegisteredCollectionView');
var DepSection112PurchasePriceCollectionView = require('./DepSection112PurchasePriceCollectionView');
var DepSection112ShareInfoCollectionView = require('./DepSection112ShareInfoCollectionView');
var DepSection112AcquirerPositionCollectionView = require('./DepSection112AcquirerPositionCollectionView');
var tmplDepSection112FedAppSectionView = require('./DepSection112FedAppSectionView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplDepSection112FedAppSectionView,

  className: 'dep-section-112-fed-app-section-view',

  behaviors: {
    ReadOnlyFormBehavior: {},
    RadioGroupToggleBehavior: {
      radios: [
        {
          target: '.questionnaire-a-container',
          radioGroup: 'input[name="hasFederalApplication"]',
          valueToShow: '0'
        },
        {
          target: '.questionnaire-b-container',
          radioGroup: 'input[name="hasFederalApplication"]',
          valueToShow: '1'
        },
        {
          target: '.no-prior-notice',
          radioGroup: 'input[name="isPriorNotice"]',
          valueToShow: '0'
        },
        {
          target: '.application-notice',
          radioGroup: 'input[name="isSubjectNotified"]',
          valueToShow: '1'
        },
        {
          target: '.has-intended-plans',
          radioGroup: 'input[name="hasIntendedPlans"]',
          valueToShow: '1'
        }
      ]
    }
  },

  ui: {
    documentForm: '.document-form',
    fedAppContainer: '.fed-app-container',
    hasFedApp: '[name="hasFederalApplication"]',
    questionnaireAContainer: '.questionnaire-a-container',
    questionnaireBContainer: '.questionnaire-b-container',
    noticeDocumentContainer: '.notice-document-container',
    acquisitionRequestContainer: '.acquisition-request-container',
    sharesRegistered: '.shares-registered',
    addSharesRegistered: '.add-shares-registered',
    purchasePrice: '.purchase-price',
    addPurchasePrice: '.add-purchase-price',
    shareInfo: '.share-info',
    addShareInfo: '.add-share-info',
    acquirerPosition: '.acquirer-position',
    addAcquirerPosition: '.add-acquirer-position',
    fundDocumentsContainer: '.fund-documents-container',
    pertinentDocumentsContainer: '.pertinent-documents-container',
    tenderOfferContainer: '.tender-offer-container',
    assistantPersonContainer: '.assistant-person-container'
  },

  regions: {
    fedAppContainer: '@ui.fedAppContainer',
    questionnaireAContainer: '@ui.questionnaireAContainer',
    questionnaireBContainer: '@ui.questionnaireBContainer',
    noticeDocumentContainer: '@ui.noticeDocumentContainer',
    acquisitionRequestContainer: '@ui.acquisitionRequestContainer',
    sharesRegistered: '@ui.sharesRegistered',
    purchasePrice: '@ui.purchasePrice',
    shareInfo: '@ui.shareInfo',
    acquirerPosition: '@ui.acquirerPosition',
    fundDocumentsContainer: '@ui.fundDocumentsContainer',
    pertinentDocumentsContainer: '@ui.pertinentDocumentsContainer',
    tenderOfferContainer: '@ui.tenderOfferContainer',
    assistantPersonContainer: '@ui.assistantPersonContainer'
  },

  events: {
    'change @ui.hasFedApp': 'onChangeFedAppUploadSelection',
    'click @ui.addSharesRegistered': 'onClickAddSharesRegistered',
    'click @ui.addPurchasePrice': 'onClickAddPurchasePrice',
    'click @ui.addShareInfo': 'onClickAddShareInfo',
    'click @ui.addAcquirerPosition': 'onClickAddAcquirerPosition'
  },

  childEvents: {
    'remove:share-registered': 'onChildRemoveSharesRegistered',
    'remove:purchase-price': 'onChildRemovePurchasePrice',
    'remove:share-info': 'onChildRemoveShareInfo',
    'remove:acquirer-position': 'onChildRemoveAcquirerPosition'
  },

  templateHelpers: function() {
    return {
      isReadOnly: this.options.isReadOnly
    };
  },

  onAttach: function() {
    if (this.options.mode === 'print') {
      this.switchToPrint();
    }
  },

  onBeforeShow: function() {
    var filingContent = this.model.get('filingContent') || {};
    var assistantPersonDocument = filingContent.assistantPersonDocument || {};
    var applicationNoticeDocument = filingContent.applicationNoticeDocument || {};
    var fundDocuments = _.pluck(modelGet(this.model, 'filingContent.fundDocuments', []), 'file');
    var pertinentDocuments = _.pluck(modelGet(this.model, 'filingContent.pertinentDocuments', []), 'file');
    var tenderOfferDocuments = _.pluck(modelGet(this.model, 'filingContent.tenderOfferDocuments', []), 'file');
    var fedAppDocuments = _.pluck(modelGet(this.model, 'filingContent.federalApplicationDocuments', []), 'file');
    var acquisitionRequestDocuments = _.pluck(
      modelGet(this.model, 'filingContent.acquisitionRequestDocuments', []),
      'file'
    );
    var sharesRegistered = filingContent.sharesRegistered || [];
    var shareInfos = filingContent.shareInfos || [];
    var aquirerPositions = filingContent.aquirerPositions || [];
    var purchasePrices = filingContent.purchasePrices || [];

    Syphon.deserialize(this, filingContent);

    this.fedAppView = new CommentsDocumentView({
      isReadOnly: this.options.isReadOnly,
      allowMultipleFiles: true,
      isRequired: true,
      fileUploadLabel: 'Federal Applications',
      commentsLabel: 'Federal Applications Comments',
      commentsName: 'fedAppComments',
      collection: new FileUploadCollection(fedAppDocuments),
      model: new Backbone.Model({ fedAppComments: filingContent.federalApplicationComments }),
      documentTypeId: 20001
    });
    this.showChildView('fedAppContainer', this.fedAppView);

    this.acquisitionRequest = new CommentsDocumentView({
      isReadOnly: this.options.isReadOnly,
      allowMultipleFiles: true,
      isRequired: true,
      fileUploadLabel: 'Copy of requests or invitations ',
      commentsLabel: 'Comments',
      commentsName: 'acquisitionRequestComments',
      collection: new FileUploadCollection(acquisitionRequestDocuments),
      model: new Backbone.Model({ acquisitionRequestComments: filingContent.acquisitionRequestComments }),
      documentTypeId: 20001
    });
    this.showChildView('acquisitionRequestContainer', this.acquisitionRequest);

    this.applicationNoticeDocument = new FileUploadView({
      isReadOnly: this.options.isReadOnly,
      allowMultipleFiles: false,
      isRequired: false,
      collection: makeUploadCollection(applicationNoticeDocument),
      metadata: {
        documentTypeId: 20001
      }
    });
    this.showChildView('noticeDocumentContainer', this.applicationNoticeDocument);

    this.fundDocuments = new FileUploadView({
      isReadOnly: this.options.isReadOnly,
      allowMultipleFiles: true,
      isRequired: false,
      collection: new FileUploadCollection(fundDocuments),

      metadata: {
        documentTypeId: 20001
      }
    });
    this.showChildView('fundDocumentsContainer', this.fundDocuments);

    this.pertinentDocuments = new FileUploadView({
      isReadOnly: this.options.isReadOnly,
      allowMultipleFiles: true,
      isRequired: false,
      collection: new FileUploadCollection(pertinentDocuments),

      metadata: {
        documentTypeId: 20001
      }
    });
    this.showChildView('pertinentDocumentsContainer', this.pertinentDocuments);

    this.tenderOfferDocuments = new FileUploadView({
      isReadOnly: this.options.isReadOnly,
      allowMultipleFiles: true,
      isRequired: false,
      collection: new FileUploadCollection(tenderOfferDocuments),

      metadata: {
        documentTypeId: 20001
      }
    });
    this.showChildView('tenderOfferContainer', this.tenderOfferDocuments);

    this.assistantPersonDocument = new FileUploadView({
      isReadOnly: this.options.isReadOnly,
      allowMultipleFiles: false,
      isRequired: false,
      collection: makeUploadCollection(assistantPersonDocument),
      metadata: {
        documentTypeId: 20001
      }
    });
    this.showChildView('assistantPersonContainer', this.assistantPersonDocument);

    if (filingContent.hasFederalApplication) {
      this.ui.hasFedApp.filter('[value="' + filingContent.hasFederalApplication + '"]').prop('checked', true);
    }

    this.sharesRegistered = new DepSection112SharesRegisteredCollectionView({
      collection: new Backbone.Collection(sharesRegistered)
    });
    this.showChildView('sharesRegistered', this.sharesRegistered);

    this.purchasePrice = new DepSection112PurchasePriceCollectionView({
      collection: new Backbone.Collection(purchasePrices)
    });
    this.showChildView('purchasePrice', this.purchasePrice);

    this.shareInfo = new DepSection112ShareInfoCollectionView({
      collection: new Backbone.Collection(shareInfos)
    });
    this.showChildView('shareInfo', this.shareInfo);

    this.acquirerPosition = new DepSection112AcquirerPositionCollectionView({
      collection: new Backbone.Collection(aquirerPositions)
    });
    this.showChildView('acquirerPosition', this.acquirerPosition);
  },

  onClickAddSharesRegistered: function(e) {
    e.preventDefault();
    this.sharesRegistered.collection.add({});
  },

  onChildRemoveSharesRegistered: function(childView) {
    this.sharesRegistered.collection.remove(childView);
  },

  onClickAddPurchasePrice: function(e) {
    e.preventDefault();
    this.purchasePrice.collection.add({});
  },

  onChildRemovePurchasePrice: function(childView) {
    this.purchasePrice.collection.remove(childView);
  },

  onClickAddShareInfo: function(e) {
    e.preventDefault();
    this.shareInfo.collection.add({});
  },

  onChildRemoveShareInfo: function(childView) {
    this.shareInfo.collection.remove(childView);
  },

  onClickAddAcquirerPosition: function(e) {
    e.preventDefault();
    this.acquirerPosition.collection.add({});
  },

  onChildRemoveAcquirerPosition: function(childView) {
    this.acquirerPosition.collection.remove(childView);
  },

  validate: function(config) {
    this.ui.documentForm.parsley().reset();
    this.fedAppView.removeValidationErrors();

    var hasFederalApplication = this.ui.hasFedApp.filter(':checked').val() === '1';
    var validationPromises = [];

    if (config.type === 'save') {
      // Turn off required field validation for this form
      parsleyUtil.disableRequiredValidation(this.ui.documentForm);
      // Validate Federal Application and Part B if yes
    } else if (hasFederalApplication) {
      validationPromises = [
        this.ui.documentForm.parsley().whenValidate(),
        this.fedAppView.validate(),
        this.fundDocuments.validate(),
        this.tenderOfferDocuments.validate(),
        this.pertinentDocuments.validate(),
        this.assistantPersonDocument.validate()
      ];

      // Turn on required field validation for this form
      parsleyUtil.enableRequiredValidation(this.ui.documentForm);
      // Validate Part A if no
    } else {
      validationPromises = [
        this.ui.documentForm.parsley().whenValidate(),
        this.applicationNoticeDocument.validate(),
        this.acquisitionRequest.validate()
      ];

      // Turn on required field validation for this form
      parsleyUtil.enableRequiredValidation(this.ui.documentForm);
    }
    return $.when.apply($, validationPromises);
  },

  getFormData: function() {
    var formData = Syphon.serialize(this, {
      exclude: []
    });

    if (formData.hasFederalApplication === '1') {
      formData.assistantPersonDocumentId = this.assistantPersonDocument.getFirstFileId();
      formData.files = this.fedAppView.fileUploadView.collection.map(function(file) {
        return {
          fileId: file.get('fileId'),
          documentTypeId: modelGet(file, 'metadata.documentTypeId')
        };
      });
      formData.fundDocuments = this.fundDocuments.collection.map(function(file) {
        return {
          fileId: file.get('fileId'),
          documentTypeId: modelGet(file, 'metadata.documentTypeId')
        };
      });
      formData.tenderOfferDocuments = this.tenderOfferDocuments.collection.map(function(file) {
        return {
          fileId: file.get('fileId'),
          documentTypeId: modelGet(file, 'metadata.documentTypeId')
        };
      });
      formData.pertinentDocuments = this.pertinentDocuments.collection.map(function(file) {
        return {
          fileId: file.get('fileId'),
          documentTypeId: modelGet(file, 'metadata.documentTypeId')
        };
      });
    } else {
      formData.acquisitionRequestDocuments = this.acquisitionRequest.collection.map(function(file) {
        return {
          fileId: file.get('fileId'),
          documentTypeId: modelGet(file, 'metadata.documentTypeId')
        };
      });

      formData.applicationNoticeDocumentId = this.applicationNoticeDocument.getFirstFileId();
    }
    formData.sharesRegistered = this.sharesRegistered.getFormData();
    formData.purchasePrices = this.purchasePrice.getFormData();
    formData.shareInfos = this.shareInfo.getFormData();
    formData.acquirerPositions = this.acquirerPosition.getFormData();

    return formData;
  },

  switchToPrint: function() {
    var textAreas = $('.input-view');

    _.each(textAreas, function(textArea) {
      var textAreaVal = textArea.value;
      var textAreaName = textArea.name;
      var printElementId =
        textAreaName === 'companyNameAddress'
          ? '#'.concat(textArea.id, 'PrintView')
          : '#'.concat(textAreaName, 'PrintView');
      $(printElementId).text(textAreaVal);
    });
    toggleForm(textAreas, false);
  }
});
