/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');

var cleanUpResponse = require('common/util/cleanUpResponse');

module.exports = function(data) {
  var defer = $.Deferred();

  $.soap({
    body: {
      GetExamInvInqReferral: {
        $xmlns: 'http://dobs.pa.gov/sc/examInvInq/bpm/1.0',
        ObjectID: data.objectId,
        ObjectTypeID: data.objectTypeId
      }
    },
    blueprintArrays: ['GetExamInvInqReferralResponse.SC_ExamInvInqReferral'],
    blueprint: {
      referrals: [
        '$..SC_ExamInvInqReferral',
        {
          id: '$..ID',
          objectTypeId: '$..ObjectTypeID',
          objectId: '$..ObjectID',
          comment: '$..Comment',
          isIncoming: '$..IsIncoming',
          referralOn: '$..ReferralOn',
          referralOptionId: '$..ReferralOptionId',
          referralOption: '$..ReferralOption',
          isDeleted: '$..IsDeleted'
        }
      ]
    }
  })
    .done(function(response) {
      cleanUpResponse(response);

      _.each(response.referrals, function(referral) {
        referral.comment = referral.comment ? referral.comment : '--';
        referral.type = referral.isIncoming === '1' ? 'Incoming' : 'Outgoing';
        var comment = referral.comment;
        if (comment) {
          referral.comment = comment.length > 100 ? comment.substring(0, 100) + '...' : comment;
        } else {
          referral.comment = '--';
        }

        if (referral.objectTypeId === 'EXAM') {
          referral.objectType = 'exam';
        } else if (referral.objectTypeId === 'INV-INQ') {
          referral.objectType = 'investigation-inquiry';
        }
      });

      defer.resolve(response.referrals);
    })
    .fail(function(err) {
      defer.reject(err);
    });

  return defer.promise();
};
