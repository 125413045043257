/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Backbone = require('backbone');
var Marionette = require('backbone.marionette');
var _ = require('underscore');

var PageableCollection = require('psa-backbone/models/PageableCollection');

var PanelGridView = require('common/views/PanelGridView');
var ButtonsPanelCellView = require('common/views/PanelGridView/ButtonsPanelCellView');
var MomentPanelCellView = require('common/views/PanelGridView/MomentPanelCellView');
var BreadcrumbView = require('common/views/BreadcrumbView');

var EntityHeaderBarView = require('../../common/views/EntityHeaderBarView');
var NMLSLabelView = require('../../common/views/NMLSLabelView');

var tmplNonDepEntityExternalCompletedApplicationView = require('../templates/NonDepEntityExternalCompletedApplicationView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplNonDepEntityExternalCompletedApplicationView,

  className: 'nondep-entity-completed-application-view container-fluid',

  ui: {
    nmlsLabelContainer: '.nmls-label-container',
    headerBar: '.header-bar-container',
    breadcrumb: '.breadcrumb-container',
    completedApplicationsContainer: '.completed-applications-container'
  },

  regions: {
    nmlsLabelContainer: '@ui.nmlsLabelContainer',
    headerBar: '@ui.headerBar',
    breadcrumb: '@ui.breadcrumb',
    completedApplicationsContainer: '@ui.completedApplicationsContainer'
  },

  templateHelpers: function() {
    return {
      viewMode: this.options.viewMode
    };
  },

  onBeforeShow: function() {
    var entityName = this.model.get('entityName');
    var entityId = this.model.get('id');

    if (this.model.get('nmlsID')) {
      this.showChildView(
        'nmlsLabelContainer',
        new NMLSLabelView({
          model: new Backbone.Model({
            objectName: 'entity'
          })
        })
      );
    }

    this.showChildView(
      'headerBar',
      new EntityHeaderBarView({
        model: this.model
      })
    );

    this.showChildView(
      'breadcrumb',
      new BreadcrumbView({
        breadcrumbs: [
          {
            link: '#ext/nondep/dashboard',
            text: 'Home'
          },
          {
            link: '#ext/nondep/entity/' + entityId + '/dashboard',
            text: entityName
          },
          {
            text: 'Completed Applications'
          }
        ]
      })
    );

    this.showChildView(
      'completedApplicationsContainer',
      new PanelGridView({
        columns: [
          {
            name: 'applicationNumber',
            label: 'Application Number',
            width: 20,
            overflow: 'truncate'
          },
          {
            name: 'applicationType',
            label: 'Application Type',
            width: 30,
            overflow: 'truncate'
          },
          {
            name: 'applicationStatus',
            label: 'Application Status',
            width: 20,
            overflow: 'truncate'
          },
          {
            name: 'submissionDate',
            label: 'Date Submitted',
            width: 20,
            panelCell: MomentPanelCellView
          },
          {
            name: '',
            label: '',
            width: 10,
            panelCell: ButtonsPanelCellView.extend({
              buttons: [
                {
                  buttonText: 'View',
                  newTab: true,
                  buttonClass: 'btn-primary btn-md',
                  href: function(model) {
                    return '#ext/nondep/application/' + model.get('id') + '/view';
                  }
                }
              ]
            })
          }
        ],
        emptyText: 'No Completed Applications.',
        collection: new PageableCollection(
          _.sortBy(this.model.get('completedApplications'), function(apps) {
            return apps.submissionDate;
          }).reverse(),
          { pageSize: 20 }
        )
      })
    );
  }
});
