/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');

var cleanUpResponse = require('common/util/cleanUpResponse');
var formatAsDollars = require('common/util/formatAsDollars');

module.exports = function(entityId) {
  var deferred = $.Deferred();

  $.soap({
    body: {
      GetEntityCallReportsByEntity: {
        $xmlns: 'http://dobs.pa.gov/dp/callreport/bpm/1.0',
        EntityID: entityId
      }
    },
    blueprintArrays: ['GetEntityCallReportsByEntityResponse.Response.DP_EntityCallReport'],
    blueprint: {
      callReports: [
        '$..DP_EntityCallReport',
        {
          id: '$..ID',
          reportYear: '$..ReportYear',
          totalAssetsUnderManagement: '$..TotalAssetsUnderManagement',
          callReportType: '$..CallReportType',
          entityId: '$..EntityID',
          quarterEnd: '$..QuarterEnd',
          totalCapital: '$..TotalCapital',
          isAmended: '$..IsAmended'
        }
      ]
    }
  })
    .done(function(response) {
      cleanUpResponse(response);
      _.each(response.callReports, function(callReport) {
        if (!callReport.reportYear) {
          callReport.reportYear = '--';
        }
        callReport.totalAssetsUnderManagement = callReport.totalAssetsUnderManagement
          ? formatAsDollars(parseFloat(callReport.totalAssetsUnderManagement), 0)
          : '--';
        if (!callReport.quarterEnd) {
          callReport.quarterEnd = '--';
        }
        callReport.totalCapital = callReport.totalCapital
          ? formatAsDollars(parseFloat(callReport.totalCapital), 0)
          : '--';
        if (callReport.isAmended === '1') {
          callReport.isAmended = 'Yes';
        } else {
          callReport.isAmended = 'No';
        }
      });
      deferred.resolve(response.callReports);
    })
    .fail(function(err) {
      deferred.reject();
    });

  return deferred.promise();
};
