/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2020] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Marionette = require('backbone.marionette');
var _get = require('lodash.get');
var parsleyUtil = require('common/util/parsleyUtil');

var DepOathRosterOfficersCollectionView = require('./DepOathRosterOfficersCollectionView');

var tmplDepOathRosterOfficersSectionView = require('./DepOathRosterOfficersSectionView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplDepOathRosterOfficersSectionView,

  className: 'dep-oath-roster-officers-section-view',

  ui: {
    addOfficer: '.add-officer',
    officersContainer: '.officers-container'
  },

  regions: {
    officersContainer: '@ui.officersContainer'
  },

  events: {
    'click @ui.addOfficer': 'onClickAddOfficer'
  },

  onBeforeShow: function() {
    this.officers = new DepOathRosterOfficersCollectionView({
      model: this.model,
      collection: this.options.collection
    });
    this.showChildView('officersContainer', this.officers);
  },

  onClickAddOfficer: function(e) {
    e.preventDefault();
    this.officers.collection.unshift({ indexOffset: _get(this.officers, 'collection.length') });
  },

  validate: function(config) {
    this.ui.officersContainer.parsley().reset();
    if (config.type === 'submit') {
      // Turn on required field validation for this form
      parsleyUtil.enableRequiredValidation(this.ui.officersContainer);
    } else {
      // Turn off required field validation for this form
      parsleyUtil.disableRequiredValidation(this.ui.officersContainer);
    }

    return this.ui.officersContainer.parsley().whenValidate();
  },

  getFormData: function() {
    return this.officers.getFormData();
  }
});
