/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var Backbone = require('backbone');
var Backgrid = require('backgrid');

require('./SelectOneCell.css');

var SelectOneRowCell = (Backgrid.Extension.SelectOneRowCell = Backbone.View.extend({
  className: 'select-one-row-cell',

  tagName: 'td',

  events: {
    'keydown :radio': 'onKeydown',
    'change :radio': 'onChange',
    'click :radio': 'enterEditMode'
  },

  initialize: function(options) {
    this.column = options.column;
    if (!(this.column instanceof Backgrid.Column)) {
      this.column = new Backgrid.Column(this.column);
    }

    this.listenTo(this.model, 'backgrid:select', function(model, selected) {
      this.$el
        .find(':radio')
        .prop('checked', selected)
        .change();
    });
  },

  enterEditMode: function() {
    this.$el.find(':radio').focus();
  },

  exitEditMode: function() {
    this.$el.find(':radio').blur();
  },

  onKeydown: function(e) {
    var command = new Backgrid.Command(e);
    if (command.passThru()) {
      return true;
    } // skip ahead to `change`
    if (command.cancel()) {
      e.stopPropagation();
      this.$el.find(':radio').blur();
    } else if (command.save() || command.moveLeft() || command.moveRight() || command.moveUp() || command.moveDown()) {
      e.preventDefault();
      e.stopPropagation();
      this.model.trigger('backgrid:edited', this.model, this.column, command);
    }
  },

  onChange: function(e) {
    this.model.trigger('backgrid:selected', this.model, $(e.target).prop('checked'));

    if (!this.column.get('editable')) {
      this.$el
        .parents('tbody')
        .find(':radio:not(:checked)')
        .attr('disabled', true);
    }
  },

  render: function() {
    var x = this.$el
      .empty()
      .append('<input tabindex="-1" type="radio" name="' + this.column.cid + '"/>')
      .css('text-align', 'center')
      .show();
    if (this.model.get(this.column.get('name'))) {
      this.$el.find('input[type=radio]').prop('checked', true);
    }
    x.attr('disabled', !this.column.get('editable'));
    this.delegateEvents();
    return this;
  }
}));

var SelectOneHeaderCell = (Backgrid.Extension.SelectOneHeaderCell = SelectOneRowCell.extend({
  className: 'select-one-header-cell',

  tagName: 'th',

  initialize: function(options) {
    this.column = options.column;
    if (!(this.column instanceof Backgrid.Column)) {
      this.column = new Backgrid.Column(this.column);
    }

    var collection = this.collection;
    this.selectedModel = undefined;
    this.listenTo(collection, 'backgrid:selected', function(model, selected) {
      if (selected) {
        this.selectedModel = model;
      }
    });

    this.listenTo(collection, 'remove', function(model) {
      if ((this.selectedModel || {}).cid === model.cid) {
        this.selectedModel = undefined;
      }
    });

    this.listenTo(collection, 'backgrid:refresh', function() {
      if (this.selectedModel) {
        this.selectedModel.trigger('backgrid:select', this.selectedModel, true);
      }
    });
  },

  render: function() {
    this.$el
      .empty()
      .append('<input style="display:none;" tabindex="-1" type="radio" />')
      .show();

    if (!_.isUndefined(this.column.get('label'))) {
      this.$el.append('<a href="#">' + this.column.get('label') + '</a>');
    }

    if (!_.isUndefined(this.column.get('width'))) {
      this.$el.attr('width', this.column.get('width') + '%');
    }

    this.delegateEvents();
    return this;
  }
}));

Backgrid.Grid.prototype.getSelectedModel = function() {
  var selectOneHeaderCell;
  var headerCells = this.header.row.cells;
  for (var i = 0, l = headerCells.length; i < l; i++) {
    var headerCell = headerCells[i];
    if (headerCell instanceof SelectOneHeaderCell) {
      selectOneHeaderCell = headerCell;
      break;
    }
  }

  return selectOneHeaderCell.selectedModel;
};

module.exports = {
  SelectOneRowCell: SelectOneRowCell,
  SelectOneHeaderCell: SelectOneHeaderCell
};
