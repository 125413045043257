/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var cleanUpResponse = require('common/util/cleanUpResponse');

module.exports = function(entityId) {
  
  var defer = $.Deferred();

  $.soap({
    body: {
      GetEntityIdentifiers: {
        $xmlns: 'http://dobs.pa.gov/dp/entity/bpm/1.0',
        EntityID: entityId
      }
    },
    blueprint: {
      entityName: '$..EntityName',
      entityTypeId: '$..EntityTypeID',
      examsCSFolderId: '$..ExamsCSFolderID',
      categoryId: '$..CategoryID',
      entityType: '$..Type',
      charterTypeId: '$..CharterTypeID',
      charterType: '$..CharterType',
      fomTypeID: '$..FOMTypeID',
      ownershipStructureId: '$..OwnershipStructureID',
      ownershipStructure: '$..OwnershipStructure',
      federalRegulatorId: '$..FederalRegulatorID',
      federalRegulator: '$..FederalRegulator',
      phoneNumber: '$..PPOBPhone',
      isCollectiveInvestmentFund: '$..IsCollectiveInvestmentFund',
      hasLegalHold: '$..HasLegalHold',
      ppob: {
        addressLine1: '$..PPOBAddressLine1',
        addressLine2: '$..PPOBAddressLine2',
        addressLine3: '$..PPOBAddressLine3',
        city: '$..PPOBCity',
        country: '$..PPOBCountry',
        county: '$..PPOBCounty',
        postalCode: '$..PPOBPostalCode',
        state: '$..PPOBState'
      }
    }
  })
    .done(function(response) {
      cleanUpResponse(response);
      response.entityId = entityId;
      defer.resolve(response);
    })
    .fail(function(err) {
      defer.reject(err);
    });

  return defer.promise();
};
