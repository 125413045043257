var Handlebars = require("handlebars/dist/handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "\n<div class=\"row\">\n  <div class=\"col-sm-12 col-md-6\">\n    <div class=\"form-group\">\n      <label class=\"control-label col-md-4 col-sm-3\">Entity Name</label>\n      <div class=\"form-control-static col-md-8 col-sm-9\">"
    + alias3((helpers["default"] || (depth0 && depth0["default"]) || alias2).call(alias1,(depth0 != null ? depth0.entityName : depth0),"--",{"name":"default","hash":{},"data":data}))
    + "</div>\n    </div>\n  </div>\n  <div class=\"col-sm-12 col-md-6\">\n    <div class=\"form-group\">\n      <label class=\"control-label col-md-4 col-sm-3\">D/B/A</label>\n      <div class=\"form-control-static col-md-8 col-sm-9\">"
    + alias3((helpers["default"] || (depth0 && depth0["default"]) || alias2).call(alias1,(depth0 != null ? depth0.dbas : depth0),"--",{"name":"default","hash":{},"data":data}))
    + "</div>\n    </div>\n  </div>\n</div>\n<div class=\"row\">\n  <div class=\"col-sm-12 col-md-6\">\n    <div class=\"form-group\">\n      <label class=\"control-label col-md-4 col-sm-3\">Email</label>\n      <div class=\"form-control-static col-md-8 col-sm-9\">"
    + alias3((helpers["default"] || (depth0 && depth0["default"]) || alias2).call(alias1,(depth0 != null ? depth0.email : depth0),"--",{"name":"default","hash":{},"data":data}))
    + "</div>\n    </div>\n  </div>\n  <div class=\"col-sm-12 col-md-6\">\n    <div class=\"form-group\">\n      <label class=\"control-label col-md-4 col-sm-3\">Phone</label>\n      <div class=\"form-control-static col-md-8 col-sm-9\">"
    + alias3((helpers["default"] || (depth0 && depth0["default"]) || alias2).call(alias1,(depth0 != null ? depth0.phone : depth0),"--",{"name":"default","hash":{},"data":data}))
    + "</div>\n    </div>\n  </div>\n</div>\n<div class=\"row\">\n  <div class=\"col-sm-12 col-md-6\">\n    <div class=\"form-group\">\n      <label class=\"control-label col-md-4 col-sm-3\">Address</label>\n      <div class=\"address-container form-control-static col-md-8 col-sm-9\"></div>\n    </div>\n  </div>\n  <div class=\"col-sm-12 col-md-6\">\n    <div class=\"form-group\">\n      <label class=\"control-label col-md-4 col-sm-3\">Fax</label>\n      <div class=\"form-control-static col-md-8 col-sm-9\">"
    + alias3((helpers["default"] || (depth0 && depth0["default"]) || alias2).call(alias1,(depth0 != null ? depth0.fax : depth0),"--",{"name":"default","hash":{},"data":data}))
    + "</div>\n    </div>\n  </div>\n</div>";
},"useData":true});