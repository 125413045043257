/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var NProgress = require('nprogress');

var NavUtils = require('psa-core/util/nav');

var Scaffold = require('common/layout/Scaffold');

var GetComplianceDetails = require('../../common/services/GetComplianceDetails');
var GetDocumentTypesByDepartment = require('common/services/GetDocumentTypesByDepartment');

var NonDepComplianceInitiateCorrespondenceView = require('../views/NonDepComplianceInitiateCorrespondenceView');

module.exports = function(complianceId) {
  NProgress.start();
  NProgress.set(0.6);

  $.when(GetComplianceDetails(complianceId), GetDocumentTypesByDepartment('NON_DEPOSITORY')).done(function(
    complianceDetails,
    documentTypes
  ) {
    NavUtils.navigate('nondep/compliance/case/' + complianceId + '/initiate-correspondence');
    Scaffold.content.show(
      new NonDepComplianceInitiateCorrespondenceView({
        model: new Backbone.Model({
          complianceDetails: complianceDetails,
          documentTypes: documentTypes
        })
      })
    );
    NProgress.done();
  });
};
