/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Marionette = require('backbone.marionette');

var EmptyView = require('../EmptyView');

require('./SideNavContentView.css');
var tmplSideNavContentView = require('./SideNavContentView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplSideNavContentView,

  id: function() {
    return this.model.get('id');
  },

  attributes: {
    role: 'tabpanel'
  },

  className: function() {
    var baseClass = 'side-nav-content-view';
    if (this.options.expanded) {
      return baseClass;
    } else {
      if (this.options.isContentActive) {
        return baseClass + ' tab-pane active';
      }
      return baseClass + ' tab-pane';
    }
  },

  ui: {
    contentContainer: '.content-container'
  },

  regions: {
    contentContainer: '@ui.contentContainer'
  },

  onBeforeShow: function() {
    var viewToRender = this.model.get('view');
    if (!viewToRender) {
      viewToRender = new EmptyView();
    }
    viewToRender.sideNavView = this.options.sideNavView;
    this.showChildView('contentContainer', viewToRender);
  },

  makeActive: function() {
    this.$el.addClass('active');
  }
});
