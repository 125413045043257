/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Marionette = require('backbone.marionette');
var Syphon = require('backbone.syphon');
var moment = require('moment');

var FileUploadView = require('common/views/FileUploadView');
var makeUploadCollection = require('common/util/makeUploadCollection');

require('../styles/EnforcementActionFormView.css');
var tmplEnforcementActionFormView = require('../templates/EnforcementActionFormView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplEnforcementActionFormView,

  className: 'enforcement-action-form-view',

  behaviors: {
    ReadOnlyFormBehavior: {}
  },

  ui: {
    enforcementActionForm: '.enforcement-action-form',
    entityConsentSelect: '.consent-to-enforcement-action-select',
    legalOrder: '.legal-order-upload-container',
    orderTypeSelect: '.order-type-select',
    dateNegotiated: '.date-negotiated',
    dateToInstitution: '.date-to-institution',
    dateToLegal: '.date-to-legal',
    dateFullyExecuted: '.date-fully-executed'
  },

  regions: {
    legalOrder: '@ui.legalOrder'
  },

  onBeforeShow: function() {
    var complianceDetails = this.model.get('complianceDetails');

    this.legalOrder = new FileUploadView({
      collection: makeUploadCollection(complianceDetails.finalizedOrderDocument),
      isRequired: true,
      isReadOnly: this.options.isReadOnly,
      metadata: {
        documentTypeId: 10005
      }
    });
    this.showChildView('legalOrder', this.legalOrder);

    Syphon.deserialize(this, this.model.get('complianceDetails'));

    this.ui.dateNegotiated.datepicker();
    this.ui.dateToInstitution.datepicker();
    this.ui.dateToLegal.datepicker();
    this.ui.dateFullyExecuted.datepicker();
  },

  validate: function() {
    return $.when(this.ui.enforcementActionForm.parsley().whenValidate(), this.legalOrder.validate());
  },

  getFormData: function() {
    var formData = Syphon.serialize(this);
    formData.negotiatedDate = formData.negotiatedDate
      ? moment(formData.negotiatedDate, 'MM-DD-YYYY').format('YYYY-MM-DD')
      : null;
    formData.sentToEntityDate = formData.sentToEntityDate
      ? moment(formData.sentToEntityDate, 'MM-DD-YYYY').format('YYYY-MM-DD')
      : null;
    formData.sentToLegalDate = formData.sentToLegalDate
      ? moment(formData.sentToLegalDate, 'MM-DD-YYYY').format('YYYY-MM-DD')
      : null;
    formData.fullyExecutedDate = formData.fullyExecutedDate
      ? moment(formData.fullyExecutedDate, 'MM-DD-YYYY').format('YYYY-MM-DD')
      : null;
    formData.finalizedOrderDocumentId = this.legalOrder.getFirstFileId();
    return formData;
  }
});
