/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var moment = require('moment');

var formatAsDollars = require('common/util/formatAsDollars');
var momentUtil = require('common/util/momentUtil');
var cleanUpResponse = require('common/util/cleanUpResponse');

module.exports = function(recordId) {
  var deferred = $.Deferred();

  $.soap({
    body: {
      GetRecord: {
        $xmlns: 'http://dobs.pa.gov/cf/record/bpm/1.0',
        RecordID: recordId
      }
    },
    blueprintArrays: [
      'GetRecordResponse.Reg207JDates.CF_Registration207J',
      'GetRecordResponse.RegBDUnderwriters.CF_RegistrationBDUnderwriter'
    ],
    blueprint: {
      id: '$..ID',
      recordCategoryId: '$..RecordCategoryID',
      recordCSFolderId: '$..CSFolderID',
      recordName: '$..RecordName',
      statusId: '$..RecordStatusID',
      category: '$..Category',
      status: '$..Status',
      filedDate: '$..FiledDate',
      dispositionDate: '$..DispositionDate',
      recordTypeId: '$..RecordTypeID',
      recordType: '$..RecordType',
      fileNumberSuffix: '$..FileNumberSuffix',
      fileNumber: '$..FileNumber',
      exemptionIssuerName: '$..ExemptionIssuerName',
      issuerAddress: {
        issuerAddressId: '$..IssuerAddressID',
        addressLine1: '$..IssAddressLine1',
        addressLine2: '$..IssAddressLine2',
        addressLine3: '$..IssAddressLine3',
        city: '$..IssCity',
        country: '$..IssCountry',
        county: '$..IssCounty',
        postalCode: '$..IssPostalCode',
        state: '$..IssState'
      },
      isIndefiniteOffering: '$..IsIndefiniteOffering',
      isNoDollarAmount: '$..IsNoDollarAmount',
      totalOfferingAmount: '$..TotalOfferingAmount',
      PAOfferingAmount: '$..PAOfferingAmount',
      offeringTypeId: '$..OfferingTypeID',
      offeringType: '$..OfferingType',
      investmentCompanyName: '$..InvestmentCompanyName',
      increaseAmount: '$..IncreaseAmount',
      increaseDate: '$..IncreaseDate',
      businessDescription: '$..BusinessDescription',
      fyeOn: '$..FYEOn',
      fyeChange: '$..FYEChange',
      stateOfFormation: '$..StateOfFormation',
      entityTypeId: '$..EntityTypeID',
      entityType: '$..EntityType',
      examiner: '$..Examiner',
      examinerFirstName: '$..ExaminerFirstName',
      examinerLastName: '$..ExaminerLastName',
      recordCenterSentDate: '$..RecordCenterSentDate',
      dateDestroyed: '$..DestroyedDate',
      reg207JDates: [
        '$..CF_Registration207J',
        {
          id: '$..ID',
          registrationId: '$..RegistrationID',
          reg207JDate: '$..J207Date'
        }
      ],
      regBDUnderwriters: [
        '$..CF_RegistrationBDUnderwriter',
        {
          id: '$..ID',
          registrationId: '$..RegistrationID',
          BDUnderwriterName: '$..BDUnderwriterName',
          BDUnderwriterCRD: '$..BDUnderwriterCRD'
        }
      ],
      BDUnderwriter: '$..BDUnderwriter',
      accountant: '$..Accountant',
      securitiesDescription: '$..SecuritiesDescription',
      securitiesEffectiveDate: '$..SecuritiesEffectiveDate',
      buildingsConstructionAmount: '$..BuildingsConstructionAmount',
      equipmentPurchasesAmount: '$..EquipmentPurchasesAmount',
      researchAmount: '$..ResearchAmount',
      laborProductionCost: '$..LaborProductionCost',
      corrLastName: '$..CorresLastName',
      corrFirstName: '$..CorresFirstName',
      corrMiddleInitial: '$..CorresMiddleInitial',
      corrSuffix: '$..CorresSuffix',
      corrLawFirm: '$..CorresLawFirm',
      corrPhone: '$..CorresPhone',
      corrExt: '$..CorresExt',
      corrEmail: '$..CorresEmail',
      corrAddress: {
        corrAddressId: '$..CorresAddressID',
        addressLine1: '$..CorrAddressLine1',
        addressLine2: '$..CorrAddressLine2',
        addressLine3: '$..CorrAddressLine3',
        city: '$..CorrCity',
        country: '$..CorrCountry',
        county: '$..CorrCounty',
        postalCode: '$..CorrPostalCode',
        state: '$..CorrState'
      }
    }
  })
    .done(function(response) {
      cleanUpResponse(response);

      var emptyDefaultInboundDateConfig = {
        sourceFormat: 'YYYY-MM-DD',
        targetFormat: 'MM/DD/YYYY',
        default: ''
      };

      if (response.corrLastName && response.corrFirstName) {
        response.correspondentFullName = response.corrLastName + ', ' + response.corrFirstName;
        if (response.corrMiddleInitial) {
          response.correspondentFullName += ' ' + response.corrMiddleInitial;
        }
        if (response.corrSuffix) {
          response.correspondentFullName += ' ' + response.corrSuffix;
        }
      }

      response.filedDate = momentUtil.formatStr(response.filedDate, emptyDefaultInboundDateConfig);
      response.dispositionDate = momentUtil.formatStr(response.dispositionDate, emptyDefaultInboundDateConfig);
      response.increaseDate = momentUtil.formatStr(response.increaseDate, emptyDefaultInboundDateConfig);
      response.securitiesEffectiveDate = momentUtil.formatStr(
        response.securitiesEffectiveDate,
        emptyDefaultInboundDateConfig
      );
      response.recordCenterSentDate = momentUtil.formatStr(
        response.recordCenterSentDate,
        emptyDefaultInboundDateConfig
      );
      response.dateDestroyed = momentUtil.formatStr(response.dateDestroyed, emptyDefaultInboundDateConfig);

      response.isIndefiniteOfferingDisplay = response.isIndefiniteOffering === '1' ? 'Yes' : 'No';

      _.each(response.reg207JDates, function(reg207J) {
        if (reg207J.reg207JDate) {
          reg207J.reg207JDate = moment(reg207J.reg207JDate, 'YYYY-MM-DD');
          reg207J.reg207JDateStr = momentUtil.formatStr(reg207J.reg207JDate, emptyDefaultInboundDateConfig);
        }
      });

      response.totalOfferingAmountDisplay = formatAsDollars(parseFloat(response.totalOfferingAmount));
      response.increaseAmountDisplay = formatAsDollars(parseFloat(response.increaseAmount));
      response.PAOfferingAmountDisplay = formatAsDollars(parseFloat(response.PAOfferingAmount));
      response.laborProductionCostDisplay = formatAsDollars(parseFloat(response.laborProductionCost));
      response.buildingsConstructionAmountDisplay = formatAsDollars(parseFloat(response.buildingsConstructionAmount));
      response.equipmentPurchasesAmountDisplay = formatAsDollars(parseFloat(response.equipmentPurchasesAmount));
      response.researchAmountDisplay = formatAsDollars(parseFloat(response.researchAmount));

      deferred.resolve(response);
    })
    .fail(function(err) {
      deferred.reject();
    });

  return deferred.promise();
};
