var Handlebars = require("handlebars/dist/handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "    <div class=\"form-group\">\n      <button class=\"btn btn-primary download-instructions\">\n        <i class=\"fa fa-download\"></i>\n        Click Here to Download Application Instructions\n      </button>\n    </div>\n";
},"3":function(container,depth0,helpers,partials,data) {
    return "    <form class=\"affirmation-form\">\n      <div class=\"form-group\">\n        <label class=\"control-label\">Affirmation</label>\n        <label class=\"control-label font-weight-normal\">\n          <input type=\"checkbox\" name=\"isCertified\" value=\"1\" data-parsley-required=\"true\" />\n          I understand by submitting this Entity Books and Records Locations Change Application: I am agreeing to be\n          bound by the\n          following\n          declaration: \"I declare that all of my answers on this License Application are complete, true and correct.\n          I\n          make this declaration subject to the penalties of 18 PA.C.S. § 4904 relating to unsworn falsification to\n          authorities.\"\n        </label>\n        <div class=\"affirmation-error-container\"></div>\n      </div>\n      <div class=\"form-group required\">\n        <label><strong>Enter Name Below</strong></label>\n        <input type=\"text\" class=\"form-control\" name=\"certificationName\" data-parsley-required=\"true\" data-parsley-maxlength=\"100\" />\n      </div>\n    </form>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "\n<div class=\"col-sm-8 col-sm-offset-2 m-b-lg\">\n  <div class=\"row\">\n"
    + ((stack1 = helpers.unless.call(alias1,(depth0 != null ? depth0.hideInstructionDoc : depth0),{"name":"unless","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "    <form class=\"entity-section-records-location-container\"></form>\n    <div>\n      <h3>Document Upload</h3>\n      <div class=\"form-group\">\n        <label class=\"control-label\">Upload any other relevant documents</label>\n        <div class=\"application-documents-container\"></div>\n      </div>\n    </div>\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.showAffirmation : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "    <div class=\"row\">\n      <div class=\"col-sm-12\">\n        <a href=\"#ext/nondep/entity/"
    + container.escapeExpression(((helper = (helper = helpers.entityId || (depth0 != null ? depth0.entityId : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"entityId","hash":{},"data":data}) : helper)))
    + "/dashboard\" class=\"btn btn-default cancel-button\"\n          role=\"button\">Cancel</a>\n        <button class=\"btn btn-primary submit-button ladda-button\" data-style=\"expand-right\" type=\"button\"><span\n            class=\"ladda-label\">Submit</span></button>\n      </div>\n    </div>\n  </div>\n</div>";
},"useData":true});