/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var moment = require('moment');
var formatAsDollars = require('common/util/formatAsDollars');

var cleanUpResponse = require('common/util/cleanUpResponse');

module.exports = function(entityId) {
  var deferred = $.Deferred();

  $.soap({
    body: {
      GetEnforcementsByEntity: {
        $xmlns: 'http://dobs.pa.gov/dp/enforcement/bpm/1.0',
        EntityID: entityId
      }
    },
    blueprintArrays: ['GetEnforcementsByEntityResponse.DP_EntityEnforcementAction'],
    blueprint: {
      enforcements: [
        '$..DP_EntityEnforcementAction',
        {
          id: '$..ID',
          entityId: '$..EntityID',
          actionId: '$..ActionID',
          areaId: '$..AreaID',
          typeId: '$..TypeID',
          agencyId: '$..AgencyID',
          examAsOfDate: '$..ExamAsOfDate',
          effectiveDate: '$..EffectiveDate',
          terminationDate: '$..TerminationDate',
          cmp: '$..CMP',
          statusId: '$..StatusID',
          isDeleted: '$..IsDeleted',
          createdDate: '$..CreatedDate',
          createdBy: '$..CreatedBy',
          modifiedDate: '$..ModifiedDate',
          modifiedBy: '$..ModifiedBy',
          action: '$..Action',
          area: '$..Area',
          agency: '$..Agency',
          status: '$..Status',
          type: '$..Type',
          comments: '$..Comments'
        }
      ]
    }
  })
    .done(function(response) {
      cleanUpResponse(response);

      _.each(response.enforcements, function(enforcement) {
        enforcement.action = enforcement.action || '--';
        enforcement.area = enforcement.area || '--';
        enforcement.agency = enforcement.agency || '--';
        enforcement.status = enforcement.status || '--';
        enforcement.type = enforcement.type || '--';

        if (enforcement.examAsOfDate) {
          enforcement.examAsOfDate = moment(enforcement.examAsOfDate, 'YYYY-MM-DD');
        }
        if (enforcement.effectiveDate) {
          enforcement.effectiveDate = moment(enforcement.effectiveDate, 'YYYY-MM-DD');
        }

        var comments = enforcement.comments;
        if (comments) {
          enforcement.comments = comments.length > 100 ? comments.substring(0, 100) + '...' : comments;
        } else {
          enforcement.comments = '--';
        }

        if (enforcement.terminationDate) {
          enforcement.terminationDate = moment(enforcement.terminationDate, 'YYYY-MM-DD');
        }

        if (enforcement.cmp) {
          enforcement.cmpDisplay = formatAsDollars(parseFloat(enforcement.cmp));
        } else {
          enforcement.cmpDisplay = '--';
        }
      });

      deferred.resolve(response.enforcements);
    })
    .fail(function(err) {
      deferred.reject();
    });

  return deferred.promise();
};
