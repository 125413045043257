/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

window.Parsley.addValidator('dollarAmount', {
  validateString: function(value, characteristic) {
    var regex = /^-?\d{1,17}(\.\d{2})?$/;
    return regex.test(value);
  },
  messages: {
    en:
      'Must be valid dollar amount, do not include commas, dollar signs. If including cents, the amount must include two decimal places. Ex: 5020, 294.99'
  }
});
