/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var cleanUpResponse = require('common/util/cleanUpResponse');

module.exports = function(applicationID) {
  var defer = $.Deferred();

  $.soap({
    body: {
      GetApplicationOwner: {
        $xmlns: 'http://dobs.pa.gov/nd/application/bpm/1.0',
        ID: applicationID
      }
    },
    blueprint: {
      owner: '$..Response'
    }
  })
    .done(function(response) {
      cleanUpResponse(response);

      response.owner = response.owner || '--';

      defer.resolve(response);
    })
    .fail(function(err) {
      defer.reject(err);
    });

  return defer.promise();
};
