/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Backbone = require('backbone');

var PlaceholderView = require('common/views/PlaceholderView');

var DepEntityContactsSectionView = require('../views/DepEntityContactsSectionView');

var GetContactByEntityID = require('../services/GetContactByEntityID');

module.exports = {
  channel: 'dp',

  event: 'build:contacts-section-view',

  builder: function(entityId) {
    return new PlaceholderView({
      viewPromise: buildContactsSectionView(entityId)
    });
  }
};

function buildContactsSectionView(entityId) {
  var deferred = $.Deferred();

  GetContactByEntityID(entityId)
    .done(function(contacts) {
      deferred.resolve(
        new DepEntityContactsSectionView({
          model: new Backbone.Model({
            contacts: contacts
          })
        })
      );
    })
    .fail(function(err) {
      deferred.reject(err);
    });
  return deferred.promise();
}
