/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2020] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var Backbone = require('backbone');
var Marionette = require('backbone.marionette');
var Syphon = require('backbone.syphon');
var Radio = require('backbone.radio');
var Ladda = require('ladda');

var UIUtil = require('psa-core/util/ui');
var modelGet = require('common/util/modelGet');
var toggleForm = require('common/util/toggleForm');
var SelectFieldView = require('common/views/SelectFieldView');

var SecIndividualSearchModalView = require('../../../securities/common/views/SecIndividualSearchModalView');
var SecOrganizationSearchModalView = require('../../../securities/common/views/SecOrganizationSearchModalView');
var DepEntitySearchModalView = require('../../../depository/common/views/DepEntitySearchModalView');
var NonDepEntitySearchModalView = require('../../../non-depository/common/views/NonDepEntitySearchModalView');
var ConSerEntitySearchModalView = require('./ConSerEntitySearchModalView');
var ConSerIndividualSearchModalView = require('./ConSerIndividualSearchModalView');

var tmplConSerComplaintSectionEntityIndividualView = require('../templates/ConSerComplaintSectionEntityIndividualView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplConSerComplaintSectionEntityIndividualView,

  className: 'con-ser-complaint-section-entity-individual-view',

  ui: {
    individualDisplayContainer: '.individual-display-container',
    individualSelectionContainer: '.individual-selection-section',
    entityDisplayContainer: '.entity-display-container',
    selectEntity: '.select-entity',
    selectIndividual: '.select-individual',
    entityType2: '.entity-type-2-dropdown',
    entityType2Row: '.entity-type-2-row',
    entityType2Select: '[name="entityType2Id"]',
    includeInactiveLicensesSection: '.include-inactive-licenses-section',
    includeInactiveLicensesCheckbox: '.include-inactive-licenses-checkbox',
    programType: '.program-type',
    programType2: '.program-type-ii',
    programType2Select: '.program-type-ii-select',
    isIndividualUnknown: 'input[name="isIndividualUnknown"]',
    individualKnown: '.individual-known',
    form: '.entity-individual-form',
    entity: '.entity',
    selectedEntityId: '.selected-entity-id',
    attemptedContact: '.attempted-contact',
    listIndividual: '.list-individualformgroup',
    submittedbycomplainant: '.submitted-by-complaintantformgroup'
  },

  regions: {
    individualDisplayContainer: '@ui.individualDisplayContainer',
    entityDisplayContainer: '@ui.entityDisplayContainer',
    entityType2: '@ui.entityType2'
  },

  events: {
    'change @ui.programType': 'onChangeProgramType',
    'change @ui.programType2': 'onChangeProgramType2',
    'change @ui.isIndividualUnknown': 'onChangeIsIndividualUnknown',
    'click @ui.selectEntity': 'onClickSelectEntity',
    'change @ui.entityType2Select': 'onChangeEntityType2Select',
    'change @ui.includeInactiveLicensesCheckbox': 'onChangeIncludeInactiveLicensesCheckbox',
    'click @ui.selectIndividual': 'onClickSelectIndividual'
  },

  // Keep toggles in order of importance in order
  behaviors: {
    ToggleBehavoir: {
      toggles: [
        {
          // on Department Change, show/hide isIndividualUnknown and labels
          target: '.department-selected',
          group: '[name="departmentId"]',
          valuesToShow: ['1000', '2000', '3000', '4000']
        },
        {
          // on Department Change, show/hide Program Type II
          target: '.program-type-ii',
          group: '[name="departmentId"]',
          valuesToShow: ['4000']
        },
        {
          // on Department Change, show/hide Individual Section
          target: '.individual',
          group: '[name="departmentId"]',
          valuesToShow: ['1000', '2000', '3000', '4000']
        },
        {
          // on Department Change, show/hide Entity Section
          target: '.entity',
          group: '[name="departmentId"]',
          valuesToShow: ['1000', '2000', '3000', '4000']
        },
        {
          // on isIndividualUnknown Change, show/hide individual Display and Select Individual
          target: '.individual-selection-section',
          group: 'input[name="isIndividualUnknown"]',
          valuesToShow: ['0']
        }
      ]
    }
  },

  onBeforeShow: function() {
    console.log(this.model);
    var complaint = this.model.get('complaint') || {};
    if (complaint.hasAttemptedContact === '0' || complaint.hasAttemptedContact === null) {
      toggleForm(this.ui.listIndividual, false);
    }

    if (!complaint.caseNumber) {
      toggleForm(this.ui.submittedbycomplainant, false);
    }

    this.entityType2Dropdown = new SelectFieldView({
      isDisabled: false,
      isRequired: false,
      name: 'entityType2Id',
      collection: new Backbone.Collection()
    });
    this.showChildView('entityType2', this.entityType2Dropdown);

    this.initializeFormState();

    // Display entity information
    this.showChildView(
      'entityDisplayContainer',
      Radio.channel('cs').request('build:entity-display-view', this.entityId, complaint.departmentId)
    );

    // Display individual information
    this.showChildView(
      'individualDisplayContainer',
      Radio.channel('cs').request('build:individual-display-view', complaint.individualId, complaint.individualTypeId)
    );
  },

  onChangeIncludeInactiveLicensesCheckbox: function() {
    var departmentId = this.ui.programType.val();
    var licenses = this.entityLicenseList;

    var isIncludeDiscChecked = this.ui.includeInactiveLicensesCheckbox.prop('checked');
    var ndInactiveLicenses = ['3', '4', '6', '8', '9', '10', '11', '12', '14', '15', '21'];

    var entityType2Filter = [];

    if (!isIncludeDiscChecked) {
      licenses = _.filter(licenses, function(license) {
        return !_.contains(ndInactiveLicenses, license.licenseStatusId);
      });
    }

    entityType2Filter = _.chain(licenses)
      .map(function(license) {
        return license.licenseTypeId;
      })
      .uniq()
      .value();

    this.resetEntityType2Dropdown(departmentId, entityType2Filter);
  },

  onChangeProgramType: function() {
    var departmentId = this.ui.programType.val();
    this.ui.programType2Select.val('');
    this.entityId = undefined;
    this.ui.selectedEntityId.val('');
    this.individualId = undefined;
    this.individualTypeId = undefined;

    this.showChildView('entityDisplayContainer', Radio.channel('cs').request('build:entity-display-view'));
    this.showChildView('individualDisplayContainer', Radio.channel('cs').request('build:individual-display-view'));

    // Default 'Individual Known' to Unchecked
    this.ui.isIndividualUnknown.prop('checked', false);
    this.onChangeIsIndividualUnknown();
    toggleForm(this.ui.individualSelectionContainer, false);

    var showDiscontinuedCheckbox = !(departmentId === '2000' || departmentId === '3000' || departmentId === '4000');
    toggleForm(this.ui.includeInactiveLicensesSection, showDiscontinuedCheckbox);

    this.resetEntityType2Dropdown(departmentId);
    this.toggleEntityTypeDropdown();
    this.resetContactForm();
  },

  onChangeProgramType2: function() {
    var departmentId2 = this.ui.programType2Select.val();
    var entityType2DropdownOptions = this.getUnlicensedEntityType2(departmentId2);

    this.entityType2Dropdown.collection.reset(entityType2DropdownOptions);
    this.toggleEntityTypeDropdown();
    this.resetContactForm();
  },

  onClickSelectEntity: function(e) {
    e.preventDefault();
    var self = this;
    var departmentId = this.ui.programType.val();

    var modalView = undefined;

    var isIncludeDiscChecked = this.ui.includeInactiveLicensesCheckbox.prop('checked');
    var ndInactiveLicenses = ['3', '4', '6', '8', '9', '10', '11', '12', '14', '15', '21'];

    switch (departmentId) {
      case '1000':
        modalView = new NonDepEntitySearchModalView({
          model: new Backbone.Model({ searchLimit: 50, licenseTypes: this.model.get('nondepLicenseTypes') })
        });
        break;
      case '2000':
        modalView = new DepEntitySearchModalView({
          model: new Backbone.Model({ searchLimit: 50, entityTypes: this.model.get('depEntityTypes') })
        });
        break;
      case '3000':
        modalView = new SecOrganizationSearchModalView({ searchLimit: 50 });
        break;
      case '4000':
        modalView = new ConSerEntitySearchModalView();
        break;
      default:
        break;
    }

    var dialogClass = 'modal-dialog-entity-search-modal-view modal-lg';

    var entityType2Filter = [];
    if (modalView) {
      UIUtil.showModal({
        title: 'Search for an Entity',
        dialogClasses: dialogClass,
        view: modalView
      }).done(function(entity) {
        self.entityLicenseList = [];
        if (entity) {
          self.entityId = entity.id || entity.entityId || entity.organizationId;
          self.ui.selectedEntityId.val(self.entityId);

          // Non-Dep
          if (departmentId === '1000') {
            self.entityLicenseList = entity.licenses;

            // Elastic search used in ND modal returns status and type as int
            _.each(self.entityLicenseList, function(license) {
              license.licenseStatusId = license.licenseStatusId.toString();
              license.licenseTypeId = license.licenseTypeId.toString();
            });

            if (!isIncludeDiscChecked) {
              entity.licenses = _.filter(entity.licenses, function(license) {
                return !_.contains(ndInactiveLicenses, license.licenseStatusId);
              });
            }

            entityType2Filter = _.chain(entity.licenses)
              .map(function(license) {
                return license.licenseTypeId;
              })
              .uniq()
              .value();
          }
          // Dep
          else if (departmentId === '2000') {
            entityType2Filter = entity.entityTypeId;
          }
          // Sec
          else if (departmentId === '3000') {
            self.entityLicenseList = entity.licenses;

            entityType2Filter = _.chain(self.entityLicenseList)
              .map(function(license) {
                return license.typeId.toString();
              })
              .uniq()
              .value();
          }
        } else {
          self.entityId = undefined;
          self.ui.selectedEntityId.val('');
        }
        self.showChildView(
          'entityDisplayContainer',
          Radio.channel('cs').request('build:entity-display-view', self.entityId, departmentId)
        );

        // Reset the contact information form via backbone radio anytime
        // selected entity changes.
        self.resetContactForm();

        if (departmentId !== '4000') {
          self.resetEntityType2Dropdown(departmentId, entityType2Filter);
        }

        self.individualId = undefined;
        self.individualTypeId = undefined;
        self.showChildView('individualDisplayContainer', Radio.channel('cs').request('build:individual-display-view'));
      });
    }
  },

  onChangeEntityType2Select: function() {
    // Reset the contact information form via backbone radio anytime selected
    // entity type 2 changes if the department is Non-Depository.
    var departmentId = this.ui.programType.val();
    if (departmentId === '1000') {
      this.resetContactForm();
    }
  },

  onChangeIsIndividualUnknown: function() {
    this.individualId = undefined;
    this.individualTypeId = undefined;
  },

  onClickSelectIndividual: function() {
    var self = this;

    var departmentId = this.ui.programType.val();
    var l = Ladda.create(self.ui.selectIndividual[0]);

    l.start();
    Radio.channel('cs')
      .request('build:individual-modal-list', self.entityId, departmentId)
      .done(function(response) {
        l.stop();
        var modalView;

        if (departmentId === '3000') {
          modalView = new SecIndividualSearchModalView({
            model: new Backbone.Model({
              departmentId: departmentId,
              searchLimit: 50
            })
          });
        } else {
          modalView = new ConSerIndividualSearchModalView({
            model: new Backbone.Model({
              entityId: self.entityId,
              departmentId: departmentId,
              searchLimit: 50
            }),
            collection: new Backbone.Collection({
              individuals: response.individuals,
              totalHits: response.totalHits
            })
          });
        }

        var dialogClass = 'modal-dialog-individual-search-modal-view';

        // In all cases except when department is securities, render large modal
        if (departmentId !== '3000') {
          dialogClass += ' modal-lg';
        }

        UIUtil.showModal({
          title: 'Select an Individual',
          dialogClasses: dialogClass,
          view: modalView
        }).done(function(individual) {
          if (individual) {
            self.individualId = individual.individualId;
            self.individualTypeId = individual.individualTypeId;
          } else {
            self.individualId = undefined;
            self.individualTypeId = undefined;
          }
          self.showChildView(
            'individualDisplayContainer',
            Radio.channel('cs').request('build:individual-display-view', self.individualId, self.individualTypeId)
          );
        });
      })
      .fail(function() {
        l.stop();
      });
  },

  getFormData: function() {
    var formData = Syphon.serialize(this);
    formData.entityId = this.entityId;

    formData.individualId = this.individualId;
    formData.individualTypeId = this.individualTypeId;

    return formData;
  },

  getUnlicensedEntityType2: function(departmentId2) {
    var isNonDepository = departmentId2 === '1000';
    var isDepository = departmentId2 === '2000';
    var isSecurities = departmentId2 === '3000';
    var entityType2DropdownOptions = [];
    if (isNonDepository) {
      entityType2DropdownOptions = this.formatDropdown(this.model.get('nondepLicenseTypes'), 'name');
    } else if (isDepository) {
      entityType2DropdownOptions = this.formatDropdown(this.model.get('depEntityTypes'), 'typeWithCategory');
    } else if (isSecurities) {
      entityType2DropdownOptions = this.formatDropdown(this.model.get('secLicenseTypes'), 'type');
    } else {
      this.entityType2Dropdown.collection.reset([]);
    }
    return entityType2DropdownOptions;
  },

  validate: function() {
    return this.ui.form.parsley().whenValidate();
  },

  initializeFormState: function() {
    var complaint = this.model.get('complaint') || {};
    var departmentId = complaint.departmentId;
    var departmentId2 = complaint.departmentId2;

    this.entityId = complaint.entityId;
    this.ui.selectedEntityId.val(this.entityId);
    this.individualId = complaint.individualId;
    this.individualTypeId = complaint.individualTypeId;

    var entityType2Filter = [];
    if (departmentId === '1000') {
      this.entityLicenseList = complaint.ndLicenses;
      entityType2Filter = _.chain(this.entityLicenseList)
        .map(function(license) {
          return license.licenseTypeId;
        })
        .uniq()
        .value();
    } else if (departmentId === '2000') {
      entityType2Filter = modelGet(this.model, 'complaint.dpEntityDetails.entityTypeId');
    } else if (departmentId === '3000') {
      this.entityLicenseList = complaint.scLicenses;
      entityType2Filter = _.chain(this.entityLicenseList)
        .map(function(license) {
          return license.licenseTypeId;
        })
        .uniq()
        .value();
    } else {
      entityType2Filter = this.getUnlicensedEntityType2(departmentId2);
    }

    toggleForm(this.ui.includeInactiveLicensesSection, departmentId === '1000');

    this.resetEntityType2Dropdown(departmentId, entityType2Filter);
    Syphon.deserialize(this, complaint);
    this.toggleEntityTypeDropdown();
  },

  resetEntityType2Dropdown: function(departmentId, entityType2Filter) {
    var entityType2DropdownOptions = [];
    var options = [];

    this.entityType2Dropdown.collection.reset(entityType2DropdownOptions);

    switch (departmentId) {
      case '1000':
        options = _.filter(this.model.get('nondepLicenseTypes'), function(licenseType) {
          var licenseId = licenseType.id;
          return _.contains(entityType2Filter, licenseId);
        });
        entityType2DropdownOptions = this.formatDropdown(options, 'name');
        this.entityType2Dropdown.collection.reset(entityType2DropdownOptions);

        break;
      case '2000':
        options = _.where(this.model.get('depEntityTypes'), { id: '' + entityType2Filter });
        entityType2DropdownOptions = this.formatDropdown(options, 'typeWithCategory');
        this.entityType2Dropdown.collection.reset(entityType2DropdownOptions);

        break;
      case '3000':
        options = _.filter(this.model.get('secLicenseTypes'), function(licenseType) {
          var licenseId = licenseType.id;
          return _.contains(entityType2Filter, licenseId);
        });
        entityType2DropdownOptions = this.formatDropdown(options, 'type');
        this.entityType2Dropdown.collection.reset(entityType2DropdownOptions);

        break;
      case '4000':
        entityType2DropdownOptions = entityType2Filter;
        this.entityType2Dropdown.collection.reset(entityType2DropdownOptions);

        break;
    }
  },

  formatDropdown: function(options, fieldName) {
    return _.map(options, function(option) {
      return { value: option.id, text: option[fieldName] };
    });
  },

  removeIndividual: function() {
    this.individualId = undefined;
    this.individualTypeId = undefined;
  },

  toggleEntityTypeDropdown: function() {
    var departmentId2 = this.ui.programType2Select.val();

    toggleForm(this.ui.entityType2Row, departmentId2 !== '4000');
  },

  resetContactForm: function() {
    var contactView = this.sideNavView.getViewBySection('contactInformation');
    contactView.resetContact();
  }
});
