/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var Ladda = require('ladda');
var Radio = require('backbone.radio');
var Backbone = require('backbone');
var Session = require('psa-core/Session');

var SideNavSectionView = require('common/views/SideNavSectionView');

var SubmitInvestorComplaint = require('../services/SubmitInvestorComplaint');

var ConSerComplaintFormYourInformationView = require('./ConSerComplaintFormYourInformationView');
var ConSerComplaintFormCompanyInformationView = require('./ConSerComplaintFormCompanyInformationView');
var ConSerComplaintFormConsumerServicesResolutionView = require('./ConSerComplaintFormConsumerServicesResolutionView');
var ConSerComplaintFormComplaintInformationView = require('./ConSerComplaintFormComplaintInformationView');
var ConSerComplaintFormServiceMemberView = require('./ConSerComplaintFormServiceMemberView');

module.exports = SideNavSectionView.extend({
  behaviors: {
    ReadOnlyFormBehavior: {}
  },

  onBeforeRender: function() {
    if (SideNavSectionView.prototype.onBeforeRender) {
      SideNavSectionView.prototype.onBeforeRender.apply(this, arguments);
    }

    var complainantModel;

    if (this.model.get('complainant')) {
      complainantModel = _.extend(this.model.get('complainant'), { contactMethods: this.model.get('contactMethods') });
    } else {
      complainantModel = { contactMethods: this.model.get('contactMethods') };
    }

    this.collection = new Backbone.Collection([
      {
        id: 'complaintFormYourInformation',
        title: 'Your Information',
        view: new ConSerComplaintFormYourInformationView({
          model: new Backbone.Model(complainantModel)
        })
      },
      {
        id: 'complaintFormCompanyInformation',
        title: 'Company Information',
        view: new ConSerComplaintFormCompanyInformationView({
          model: new Backbone.Model(this.model.get('company'))
        })
      },
      {
        id: 'complaintFormServiceMember',
        title: 'Service Member Status',
        view: new ConSerComplaintFormServiceMemberView({
          model: this.model
        })
      },
      {
        id: 'complaintFormComplaintInformation',
        title: 'Complaint Information',
        view: new ConSerComplaintFormComplaintInformationView({
          model: this.model
        })
      },
      {
        id: 'complaintFormServicesResolution',
        title: 'Consumer Services Resolution',
        view: new ConSerComplaintFormConsumerServicesResolutionView({
          model: this.model
        })
      }
    ]);

    if (this.options.viewMode !== 'extEdit') {
      this.options.buttons = [
        {
          buttonText: 'Save Draft',
          buttonClass: 'btn-default ladda-button',
          buttonIconClass: 'fa-save',
          labelClass: 'ladda-label',
          buttonAttrs: {
            'data-style': 'expand-right'
          },
          onClick: this.onClickSave.bind(this)
        }
      ];
    }
  },

  onClickSave: function(e) {
    var self = this;
    var complaintId = this.model.get('complaintId');
    var l = Ladda.create(e.currentTarget);
    l.start();
    var validating = this.validateAll({ type: 'save' });

    validating
      .done(function() {
        var rawFormData = self.getFormData();
        var formData = {
          isDraft: '4', // 4 is draft status
          externalUserId: Session.user.id,
          complaintId: complaintId,
          complainant: rawFormData.complaintFormYourInformation,
          serviceMember: rawFormData.complaintFormServiceMember,
          company: rawFormData.complaintFormCompanyInformation,
          information: rawFormData.complaintFormComplaintInformation,
          resolution: rawFormData.complaintFormServicesResolution
        };
        SubmitInvestorComplaint(formData)
          .done(function(newComplaintId) {
            if (!complaintId) {
              Radio.channel('navigate').trigger('show:ext:cs:edit-draft-investor-complaint', newComplaintId);
            } else {
              l.stop();
            }
          })
          .fail(function() {
            l.stop();
          });
      })
      .fail(function() {
        l.stop();
      });
  }
});
