/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2020] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Marionette = require('backbone.marionette');
var Radio = require('backbone.radio');

var GridSpinnerView = require('common/views/GridSpinnerView');
var ButtonLinkCell = require('common/backgrid/ButtonLinkCell');
var MomentCell = require('common/backgrid/MomentCell');
var LinkCell = require('common/backgrid/LinkCell');

var ConSerInternalSidebarNavView = require('./ConSerInternalSidebarNavView');

require('../styles/ConSerInternalMyTasksView.css');
var tmplConSerInternalMyTasksView = require('../templates/ConSerInternalMyTasksView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplConSerInternalMyTasksView,

  className: 'con-ser-internal-my-tasks-view container-fluid',

  ui: {
    sideBarContainer: '.side-bar-container',
    availableTasks: '.available-tasks-container'
  },

  regions: {
    sideBarContainer: '@ui.sideBarContainer',
    availableTasks: '@ui.availableTasks'
  },

  onBeforeShow: function() {
    this.showChildView('sideBarContainer', new ConSerInternalSidebarNavView());

    var availableTasksGridColumns = [
      {
        name: 'type',
        label: 'Task Name',
        cell: 'string',
        headerCell: 'custom',
        width: 20,
        editable: false
      },
      {
        name: 'complainantName',
        label: 'Consumer Name',
        cell: 'string',
        headerCell: 'custom',
        width: 20,
        editable: false
      },
      {
        name: 'entityName',
        label: 'Entity Name',
        cell: LinkCell.extend({
          emptyText: '--',
          newTab: true,
          href: function(model) {
            var entityId = model.get('entityId');
            var departmentId = model.get('departmentId');
            var url = entityId ? '#conser/' + departmentId + '/entity/' + entityId + '/dashboard' : '';
            return url;
          }
        }),
        headerCell: 'custom',
        width: 15,
        editable: false
      },
      {
        name: 'deliveryDate',
        label: 'Assigned On',
        cell: MomentCell.MomentCell,
        formatter: MomentCell.MomentDateTimeFormatter.extend({
          emptyValue: '--'
        }),
        sortValue: MomentCell.MomentSortValue,
        headerCell: 'custom',
        width: 10,
        editable: false
      },
      {
        name: '',
        label: '',
        cell: ButtonLinkCell.extend({
          name: 'Open',
          buttonClass: 'btn-primary btn-sm',
          href: function(model) {
            return '#conser/task/' + model.get('taskId');
          }
        }),
        headerCell: 'custom',
        width: 10,
        editable: false,
        sortable: false
      }
    ];

    this.availableTasksView = new GridSpinnerView({
      columns: availableTasksGridColumns,
      spinner: {
        scale: 0.75,
        length: 10
      },
      pageable: true
    });

    this.showChildView('availableTasks', this.availableTasksView);

    Radio.channel('cs:my-tasks')
      .request('build:con-ser-available-tasks-grid-view', availableTasksGridColumns)
      .done(
        function(gridView) {
          if (!this.isDestroyed) {
            this.availableTasksView = gridView;
            this.showChildView('availableTasks', gridView);
          }
        }.bind(this)
      );
  }
});
