/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var _get = require('lodash.get');

module.exports = function(formData) {
  var defer = $.Deferred();

  var isDraft = formData.isDraft;
  var externalUserId = formData.externalUserId;
  var complaintId = formData.complaintId;
  var complainant = formData.complainant;
  //var serviceMember = formData.serviceMember;
  var issues = formData.issue;
  var entity = formData.entity;
  var information = formData.information;
  var authority = formData.authority;

  $.soap({
    body: {
      SubmitExternalComplaint: {
        $xmlns: 'http://dobs.pa.gov/cs/complaint/bpm/1.0',
        IsDraft: isDraft, // 4 is draft, 1 is open
        ExternalUserID: externalUserId,
        ComplaintID: complaintId,
        ComplainantFirstName: complainant.firstName,
        ComplainantLastName: complainant.lastName,
        ComplainantEmail: complainant.email,
        ComplainantPhone: complainant.phoneNumber,
        ComplainantCellPhone: complainant.cellPhoneNumber,
        ComplainantAddressLine1: complainant.address.addressLine1,
        ComplainantAddressLine2: complainant.address.addressLine2,
        ComplainantCity: complainant.address.city,
        ComplainantState: complainant.address.state,
        ComplainantPostalCode: complainant.address.postalCode,
        ComplainantCountry: complainant.address.country,
        ComplainantCounty: complainant.address.county,
        ComplainantContactPreference: complainant.contactPreference,
        ComplainantIsConsumer: complainant.isConsumer,
        ComplainantConsumerRelation: complainant.consumerRelation, // Maps to a 1 for service member status
        Documents: (function() {
          if (!_get(information, 'documents.length')) {
            return {};
          }
          return {
            Document: _.map(information.documents, function(document) {
              return {
                DocumentID: document.fileId,
                DocumentTypeID: document.documentTypeId
              };
            })
          };
        })(),
        // ServiceMemberStatus: serviceMember.isVeteranServiceMember === "0" ? "1" : serviceMember.serviceMemberStatus, // 2 veteran, 3 active duty, 4 reserve
        Issues: _.map(issues.issues, function(issue) {
          return {
            Issue: issue.issue
          };
        }), // ComplaintIssueComplaint cross table
        OtherIssue: issues.otherIssue,
        EntityName: entity.name,
        EntityAccountNumber: entity.accountNumber,
        EntityEmail: entity.email,
        EntityPhone: entity.phoneNumber,
        EntityWebsite: entity.website,
        EntityAddressLine1: entity.address.addressLine1,
        EntityAddressLine2: entity.address.addressLine2,
        EntityCity: entity.address.city,
        EntityState: entity.address.state,
        EntityPostalCode: entity.address.postalCode,
        EntityCountry: entity.address.country,
        EntityCounty: entity.address.county,
        EntityHasAttemptedContact: entity.hasContactedAttempt, // HasAttemptedContactWithEntity
        EntityContactIndividuals: entity.contactIndividuals, // ContactedEntityIndividuals
        HasPendingLitigation: information.hasPendingLitigation,
        NatureOfComplaint: information.natureOfComplaint, // Synopsis
        DesiredResolution: information.desiredResolution,
        IsInformationalOnly: information.isInformationalOnly ? 1 : 0,
        AuthorityIsAffirmed: authority.isAffirmed ? 1 : 0
      }
    },
    blueprint: {
      newComplaintId: '$..ComplaintID.__text'
    }
  })
    .done(function(response) {
      defer.resolve(response.newComplaintId);
    })
    .fail(function(err) {
      defer.reject();
    });

  return defer.promise();
};
