/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

 var _ = require('underscore');
 var Backbone = require('backbone');
 
 var GetDocumentTypesByDepartment = require('common/services/GetDocumentTypesByDepartment');
 
 var MortgageDiscountCompanyAnnualReport = require('../forms/MortgageDiscountCompanyAnnualReport');
 var GetAnnualReportByApplication = require('../services/GetAnnualReportByApplication');
 var GetMortgageDiscountCompanyAnnualReport = require('../services/GetMortgageDiscountCompanyAnnualReport');
 var GetAnnualReportFinancialStatements = require('../services/GetAnnualReportFinancialStatements');
 
 module.exports = {
   event: 'build:annual-report:mortgage-discount-company',
 
   channel: 'nd:application',
 
   builder: function(config) {
     var defer = $.Deferred();
 
     var applicationId = config.applicationId;
 
     GetAnnualReportByApplication(applicationId)
       .done(function(annualReport) {
         var annualReportId = annualReport.annualReportId;
         var annualReportYear = annualReport.year;
 
         $.when(
        GetMortgageDiscountCompanyAnnualReport(annualReportId),
           GetAnnualReportFinancialStatements(annualReportId),
           GetDocumentTypesByDepartment('NON_DEPOSITORY')
         )
           .done(function(mortgageDiscountCompanyAnnualReport, financialStatements, documentTypes) {
             _.extend(annualReport, mortgageDiscountCompanyAnnualReport);
             annualReport.financialStatements = financialStatements;
             defer.resolve(
               new MortgageDiscountCompanyAnnualReport({
                 mode: config.mode,
                 isReadOnly: config.isReadOnly,
                 model: new Backbone.Model({
                   annualReportId: annualReportId,
                   annualReportYear: annualReportYear,
                   annualReport: annualReport,
                   documentTypes: documentTypes
                 })
               })
             );
           })
           .fail(function(err) {
             defer.reject(err);
           });
       })
       .fail(function(err) {
         defer.reject(err);
       });
 
     return defer.promise();
   }
 };
 