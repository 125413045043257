/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Marionette = require('backbone.marionette');

var SideNavLinkView = require('./SideNavLinkView');

require('./SideNavLinkCollectionView.css');

module.exports = Marionette.CollectionView.extend({
  tagName: 'ul',

  className: 'side-nav-link-collection-view nav nav-pills',

  attributes: {
    role: 'tablist'
  },

  childView: SideNavLinkView,

  initialize: function(options) {
    this.activeSectionExists = false;
    if (options.activeSectionId) {
      this.activeSectionExists = !!this.collection.get(options.activeSectionId);
    }
  },

  childViewOptions: function(model, index) {
    var isLinkActive;
    if (this.activeSectionExists) {
      isLinkActive = model.id === this.options.activeSectionId;
    } else {
      isLinkActive = index === 0;
    }
    return {
      childIndex: index,
      isLinkActive: isLinkActive
    };
  },

  childEvents: {
    'link:clicked': 'onChildLinkClicked'
  },

  getActiveLinkIndex: function() {
    var child = this.children.find(function(child) {
      return child.isActive();
    });
    return child.options.childIndex;
  },

  setActiveLinkByIndex: function(index) {
    var child = this.children.find(function(child) {
      return child.options.childIndex === index;
    });
    child.makeActive();
  },

  getActiveLinkId: function() {
    var child = this.children.find(function(child) {
      return child.isActive();
    });
    return child.model.id;
  },

  setActiveLinkById: function(id) {
    var child = this.children.find(function(child) {
      return child.model.id === id;
    });
    child.makeActive();
  },

  onChildLinkClicked: function(childView) {
    this.triggerMethod('navlink:clicked', childView.options.childIndex);
  }
});
