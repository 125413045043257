/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');

var moment = require('moment');

module.exports = function(entityDetails) {
  entityDetails.incorporationDate = moment(entityDetails.incorporationDate, 'MM/DD/YYYY').isValid()
    ? moment(entityDetails.incorporationDate, 'MM/DD/YYYY').format('YYYY-MM-DD')
    : '';

  var newDBAs = entityDetails.dbas || [];
  var deleteDBAs = entityDetails.deleteDBAs || [];

  return $.soap({
    body: {
      SubmitEntityIdentification: {
        $xmlns: 'http://dobs.pa.gov/nd/entity/bpm/1.0',
        EntityID: entityDetails.entityId,
        EntityOrganizationTypeID: entityDetails.entityOrganizationTypeId,
        EntityName: entityDetails.entityName,
        EntityName2: entityDetails.entityName2,
        EntityNameForced: entityDetails.entityNameForced,
        TIN: entityDetails.tin,
        TINTypeID: entityDetails.tinTypeId,
        PhoneNumber: entityDetails.phoneNumber,
        PhoneNumberExt: entityDetails.phoneNumberExt,
        SecondaryPhoneNumber: entityDetails.secondaryPhoneNumber,
        SecondaryPhoneNumberExt: entityDetails.secondaryPhoneNumberExt,
        FaxNumber: entityDetails.faxNumber,
        FaxNumberExt: entityDetails.faxNumberExt,
        IncorporationDate: entityDetails.incorporationDate,
        IncorporationPlace: entityDetails.incorporationPlace,
        NewDBAs: (function() {
          if (!newDBAs.length) {
            return {};
          } else {
            return {
              DBA: _.map(newDBAs, function(dba) {
                return {
                  DBA: dba.dbaName,
                  FictitiousDocumentID: dba.fictitiousDocumentId,
                  RegistrationDate: dba.registrationDate,
                  EndDate: dba.endDate
                };
              })
            };
          }
        })(),
        WebAddress: entityDetails.webAddress,
        ListWebsiteBusiness: entityDetails.listWebsiteBusiness,
        ArticlesOfIncorporationDocumentID: entityDetails.articlesOfIncorporationDocumentId,
        OperatingAgreementDocumentID: entityDetails.operatingAgreementDocumentId,
        ByLawsDocumentID: entityDetails.byLawsDocumentId,
        DocumentExceptionLegalOpinion: entityDetails.documentExceptionLegalOpinion,
        IsOfficerConvicted: entityDetails.isOfficerConvicted,
        IsOfficerConvictedDocumentID: entityDetails.isOfficerConvictedDocumentId,
        IsForeignEntity: entityDetails.isForeignEntity,
        ForeignRegistrationStatementDocumentID: entityDetails.foreignRegistrationStatementDocumentId,
        AgentOfService: (function() {
          var aosDetails = entityDetails.agentOfService || {};
          return {
            FullName: aosDetails.fullName,
            AddressLine1: aosDetails.addressLine1,
            AddressLine2: aosDetails.addressLine2,
            AddressLine3: aosDetails.addressLine3,
            City: aosDetails.city,
            State: aosDetails.state,
            PostalCode: aosDetails.postalCode,
            Country: aosDetails.country,
            County: aosDetails.county,
            PhoneNumber: aosDetails.phoneNumber
          };
        })(),
        DeleteDBAs: (function() {
          if (!deleteDBAs.length) {
            return {};
          }
          return { DBAID: deleteDBAs };
        })()
      }
    }
  });
};
