/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var cleanUpResponse = require('common/util/cleanUpResponse');

module.exports = function(boardAnnualMeetingRequireId) {
  var deferred = $.Deferred();
  $.soap({
    body: {
      GetBoardAnnualMeetingRequirements: {
        $xmlns: 'http://dobs.pa.gov/dp/director/bpm/1.0',
        BoardAnnualMeetingRequireId: boardAnnualMeetingRequireId
      }
    },
    blueprint: {
      boardAnnualMeetingRequireId: '$..ID',
      entityId: '$..EntityID',
      annualMeetingDue: '$..AnnualMeetingDue',
      boardTypeId: '$..BoardTypeID',
      terms: '$..Terms',
      boardSizeMin: '$..BoardSizeMin',
      boardSizeMax: '$..BoardSizeMax',
      comments: '$..Comments',
      isDeleted: '$..IsDeleted'
    }
  })
    .done(function(response) {
      cleanUpResponse(response);
      deferred.resolve(response);
    })
    .fail(function(err) {
      deferred.reject();
    });

  return deferred.promise();
};
