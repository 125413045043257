/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */
var _ = require('underscore');

var moment = require('moment');

module.exports = function(data) {
  var deferred = $.Deferred();

  data.effectiveStartDate = moment(data.effectiveStartDate, 'MM/DD/YYYY').format('YYYY-MM-DD');
  data.effectiveEndDate = moment(data.effectiveEndDate, 'MM/DD/YYYY').format('YYYY-MM-DD');
  if (data.originalIssueDate) {
    data.originalIssueDate = moment(data.originalIssueDate, 'MM/DD/YYYY').format('YYYY-MM-DD');
  }

  $.soap({
    body: {
      SubmitLicenseGeneralInformation: {
        $xmlns: 'http://dobs.pa.gov/nd/license/bpm/1.0',
        LicenseID: data.licenseId,
        LocationID: data.locationId,
        LicenseStatusID: data.licenseStatusId,
        EffectiveStartDate: data.effectiveStartDate,
        EffectiveEndDate: data.effectiveEndDate,
        OriginalIssueDate: data.originalIssueDate,
        DBAs: (function() {
          if (!data.licenseDbas.length) {
            return {};
          } else {
            return {
              DBA: _.map(data.licenseDbas, function(dba) {
                return {
                  EntityDBAID: dba
                };
              })
            };
          }
        })()
      }
    }
  })
    .done(function() {
      deferred.resolve();
    })
    .fail(function(err) {
      deferred.reject(err);
    });

  return deferred.promise();
};
