/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var moment = require('moment');

var cleanUpResponse = require('common/util/cleanUpResponse');

module.exports = function(organizationId) {
  var deferred = $.Deferred();

  $.soap({
    body: {
      GetOrganizationDetails: {
        $xmlns: 'http://dobs.pa.gov/sc/organization/bpm/1.0',
        OrganizationID: organizationId
      }
    },
    blueprintArrays: ['GetOrganizationDetailsResponse.SC_OrganizationDBA'],
    blueprint: {
      organization: {
        id: '$..ID',
        CSFolderId: '$..CSFolderID',
        CRDNumber: '$..CRDNumber',
        nonFINRANumber: '$..NonFINRANumber',
        PSCNumber: '$..PSCNumber',
        DBAName: '$..DBAName',
        applicantLegalName: '$..ApplicantLegalName',
        lastLicensingReviewDate: '$..LastLicensingReviewDate',
        lastLicensingReviewBy: '$..LastLicensingReviewBy',
        lastLicensingReviewByName: '$..LastLicensingReviewByName',
        dbas: [
          '$..SC_OrganizationDBA',
          {
            id: '$..ID',
            organizationId: '$..OrganizationID',
            dba: '$..DBA',
            createdDate: '$..CreatedDate'
          }
        ]
      }
    }
  })
    .done(function(response) {
      cleanUpResponse(response);
      var organization = response.organization;

      if (organization.lastLicensingReviewDate) {
        organization.lastLicensingReviewDate = moment(organization.lastLicensingReviewDate, 'YYYY-MM-DD').format(
          'MM/DD/YYYY'
        );
      }

      deferred.resolve(organization);
    })
    .fail(function(err) {
      deferred.reject();
    });
  return deferred.promise();
};
