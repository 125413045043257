/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var NProgress = require('nprogress');

var NavUtils = require('psa-core/util/nav');

var Scaffold = require('common/layout/Scaffold');

var GetAllComplianceViolations = require('../services/GetAllComplianceViolations');
var GetAllPrincipleLicenseTypes = require('../services/GetAllPrincipleLicenseTypes');

var NonDepCreateComplianceCaseRequestView = require('../views/NonDepCreateComplianceCaseRequestView');

module.exports = function() {
  NProgress.start();
  NProgress.set(0.6);

  $.when(GetAllPrincipleLicenseTypes(), GetAllComplianceViolations())
    .done(function(licenseTypes, violationCodes) {
      NavUtils.navigate('#nondep/compliance/create-case');
      Scaffold.content.show(
        new NonDepCreateComplianceCaseRequestView({
          model: new Backbone.Model({
            licenseTypes: licenseTypes,
            violationCodes: violationCodes
          })
        })
      );
      NProgress.done();
    })
    .fail(function() {
      NProgress.done();
    });
};
