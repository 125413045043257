/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Backbone = require('backbone');
var Marionette = require('backbone.marionette');
var Radio = require('backbone.radio');

var UIUtil = require('psa-core/util/ui');

var SideNavSectionView = require('common/views/SideNavSectionView');
var BreadcrumbView = require('common/views/BreadcrumbView');
var DropdownView = require('common/views/DropdownView');
var ConfirmationModalView = require('common/views/ConfirmationModalView');
var ContentServerLinkView = require('common/views/ContentServerLinkView');

var SubmitEnforcement = require('../services/SubmitEnforcement');

var DepEnforcementDetailsSectionView = require('./DepEnforcementDetailsSectionView');

var tmplDepEnforcementDashboardView = require('../templates/DepEnforcementDashboardView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplDepEnforcementDashboardView,

  className: 'dep-enforcement-dashboard-view container-fluid m-b-lg',

  behaviors: {
    ReadOnlyFormBehavior: {}
  },

  ui: {
    enforcementWrapper: '.enforcement-wrapper',
    csLinkContainer: '.cs-link-container',
    enforcementActionActionDropDown: '.dropdown-container',
    breadcrumb: '.breadcrumb-container'
  },

  regions: {
    enforcementWrapper: '@ui.enforcementWrapper',
    csLinkContainer: '@ui.csLinkContainer',
    enforcementActionActionDropDown: '@ui.enforcementActionActionDropDown',
    breadcrumb: '@ui.breadcrumb'
  },

  onBeforeShow: function() {
    var entityId = this.model.get('entityId');
    var enforcementId = this.model.get('enforcementId');
    var enforcement = this.model.get('enforcement') || {};
    var enforcementIdentifiers = this.model.get('enforcementIdentifiers') || {};
    var entityName = enforcementIdentifiers.entityName;
    var enforcementAction = enforcement.action;
    var effectiveDate = enforcement.effectiveDate;
    var enforcementCSFolderId = this.model.get('enforcementCSFolderId');
    var entityTypeId = enforcementIdentifiers.entityTypeId;
    var isTSP = entityTypeId === '3' || entityTypeId === '12' || entityTypeId === '23';

    this.showChildView(
      'breadcrumb',
      new BreadcrumbView({
        breadcrumbs: [
          {
            link: '#dep/dashboard',
            text: 'Home'
          },
          {
            link: '#dep/entity/' + entityId + '/dashboard',
            text: entityName
          },
          {
            text: enforcementAction + ' - Effective as of ' + effectiveDate
          }
        ]
      })
    );

    var dropDownOptions = [];
    var sideNavs = [];

    if (isTSP) {
      dropDownOptions = [
        {
          text: 'Edit Enforcement Action Details',
          link: '#dep/entity/' + entityId + '/enforcement/' + enforcementId + '/edit-details'
        },
        {
          text: 'Delete Enforcement Action',
          onClick: this.onClickDelete.bind(this)
        }
      ];
      sideNavs = [
        {
          id: 'enforcementActionDetails',
          title: 'Enforcement Action Details',
          view: new DepEnforcementDetailsSectionView({
            model: new Backbone.Model({
              enforcement: enforcement
            })
          })
        },
        {
          id: 'notes',
          title: 'Notes',
          view: Radio.channel('dp').request('build:notes-view', {
            objectId: enforcementId,
            objectTypeId: 'ENFORCEMENT'
          })
        },
        {
          id: 'auditLog',
          title: 'Audit Log',
          view: Radio.channel('dp').request('build:audit-log-view', {
            objectId: enforcementId,
            objectTypeId: 'ENFORCEMENT'
          })
        }
      ];
    } else {
      dropDownOptions = [
        {
          text: 'Edit Enforcement Action Details',
          link: '#dep/entity/' + entityId + '/enforcement/' + enforcementId + '/edit-details'
        },
        {
          text: 'Delete Enforcement Action',
          onClick: this.onClickDelete.bind(this)
        },
        {
          isDivider: true
        },
        {
          text: 'Add Provision',
          link: '#dep/entity/' + entityId + '/enforcement/' + enforcementId + '/new-provision'
        },
        {
          text: 'Add Progress Report',
          link: '#dep/entity/' + entityId + '/enforcement/' + enforcementId + '/new-progress-report'
        }
      ];
      sideNavs = [
        {
          id: 'enforcementActionDetails',
          title: 'Enforcement Action Details',
          view: new DepEnforcementDetailsSectionView({
            model: new Backbone.Model({
              enforcement: enforcement
            })
          })
        },
        {
          id: 'provisions',
          title: 'Provisions',
          view: Radio.channel('dp').request('build:enforcement-provision-section-view', enforcementId)
        },
        {
          id: 'progressReports',
          title: 'Progress Reports',
          view: Radio.channel('dp').request('build:enforcement-progress-report-section-view', enforcementId)
        },
        {
          id: 'notes',
          title: 'Notes',
          view: Radio.channel('dp').request('build:notes-view', {
            objectId: enforcementId,
            objectTypeId: 'ENFORCEMENT'
          })
        },
        {
          id: 'auditLog',
          title: 'Audit Log',
          view: Radio.channel('dp').request('build:audit-log-view', {
            objectId: enforcementId,
            objectTypeId: 'ENFORCEMENT'
          })
        }
      ];
    }

    this.showChildView(
      'enforcementActionActionDropDown',
      new DropdownView({
        rightAlign: true,
        model: new Backbone.Model({
          name: 'Enforcement Action Actions',
          options: dropDownOptions
        })
      })
    );

    this.showChildView(
      'csLinkContainer',
      new ContentServerLinkView({
        model: new Backbone.Model({
          buttonText: 'Open Enforcement Action Folder',
          contentServerId: enforcementCSFolderId
        })
      })
    );

    this.enforcement = new SideNavSectionView({
      useNavigationButtons: this.options.isReadOnly,
      collection: new Backbone.Collection(sideNavs)
    });
    this.showChildView('enforcementWrapper', this.enforcement);
  },

  onClickDelete: function() {
    var self = this;
    var message = 'Are you sure you want to delete this enforcement action?';
    UIUtil.showModal({
      title: 'Confirm Deleting Enforcement Action',
      view: new ConfirmationModalView({
        confirmText: 'Delete Enforcement Action',
        model: new Backbone.Model({
          message: message,
          onConfirm: function() {
            var enforcementId = self.model.get('enforcementId');
            return SubmitEnforcement({ enforcementId: enforcementId, isDeleted: '1' });
          },
          onConfirmDone: function() {
            var entityId = self.model.get('entityId');
            Radio.channel('navigate').trigger('show:int:dp:entity', entityId);
          }
        })
      })
    });
  }
});
