var Handlebars = require("handlebars/dist/handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "            <option value=\""
    + alias4(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\">"
    + alias4(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data}) : helper)))
    + " - "
    + alias4(((helper = (helper = helpers.description || (depth0 != null ? depth0.description : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"description","hash":{},"data":data}) : helper)))
    + "</option>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "\n<div class=\"modal-body\">\n  <form class=\"form-horizontal form-violation\">\n    <div class=\"form-group required\">\n      <label class=\"control-label col-md-3\">Violation</label>\n      <div class=\"col-md-9\">\n        <select class=\"form-control exam-placement-select\" name=\"examViolationCodeId\" data-parsley-required=\"true\">\n          <option value=\"\">--</option>\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.examViolationCodes : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "        </select>\n      </div>\n    </div>\n    <div class=\"form-group required\">\n      <label class=\"control-label col-md-3\">Status</label>\n      <div class=\"col-md-9\">\n        <select class=\"form-control exam-placement-select\" name=\"examViolationCorrectiveActionId\" data-parsley-required=\"true\">\n          <option value=\"\">--</option>\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.examViolationCorrectiveActions : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "        </select>\n      </div>\n    </div>\n    <div class=\"form-group\">\n      <label class=\"control-label col-md-3\">Refund Amount</label>\n      <div class=\"col-md-9\">\n        <div class=\"input-group amount-input-group\">\n          <div class=\"input-group-addon\">$</div>\n          <input type=\"number\" step=\"any\" class=\"form-control\" name=\"refundAmount\" placeholder=\"Refund Amount\" data-parsley-dollar-amount=\"true\" data-parsley-errors-container=\"#refundAmountModalError\" />\n        </div>\n        <div id=\"refundAmountModalError\"></div>\n      </div>\n    </div>\n  </form>\n</div>\n<div class=\"modal-footer\">\n  <button class=\"btn btn-default\" data-dismiss=\"modal\" type=\"button\">Cancel</button>\n  <button class=\"btn btn-primary add-violation\" type=\"button\">Add Violation</button>\n</div>";
},"useData":true});