/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Backbone = require('backbone');
var Marionette = require('backbone.marionette');
var _ = require('underscore');

var BreadcrumbView = require('common/views/BreadcrumbView');
var HeaderBarView = require('common/views/HeaderBarView');
var HeaderBarLinkCellView = require('common/views/HeaderBarView/HeaderBarLinkCellView');

var tmplNonDepExternalActiveApplicationView = require('../templates/NonDepExternalActiveApplicationView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplNonDepExternalActiveApplicationView,

  className: 'non-dep-external-active-application-view container-fluid',

  ui: {
    headerBar: '.header-bar',
    breadcrumb: '.breadcrumb-container',
    application: '.application-form-container'
  },

  regions: {
    headerBar: '@ui.headerBar',
    breadcrumb: '@ui.breadcrumb',
    application: '@ui.application'
  },

  onBeforeShow: function() {
    var applicationDetails = this.model.get('applicationDetails');
    var headerData = _.extend(applicationDetails, {
      location: applicationDetails.location.addressLine1
    });

    var applicationRenewalTypeIds = ['3', '6', '8', '11', '13', '27'];
    var headerbarColumns = [
      {
        name: 'entityName',
        label: 'Entity Name',
        minWidth: '160px',
        maxWidth: '300px'
      },
      {
        name: 'tin',
        label: 'Federal ID Number',
        minWidth: '160px'
      },
      {
        name: 'location',
        label: 'Location',
        minWidth: '160px',
        maxWidth: '350px',
        overflow: 'truncate'
      },
      {
        name: 'webAddress',
        label: 'Web Address',
        width: '350px',
        overflow: 'truncate',
        headerCell: HeaderBarLinkCellView
      }
    ];

    // Replace location in header with application status if application is renewal triggered by workflow
    if (_.contains(applicationRenewalTypeIds, applicationDetails.applicationTypeId)) {
      headerbarColumns.splice(2, 1, {
        name: 'applicationStatus',
        label: 'Application Status',
        minWidth: '160px',
        maxWidth: '200px',
        overflow: 'truncate'
      });
    }

    this.showChildView(
      'headerBar',
      new HeaderBarView({
        columns: new Backbone.Collection(headerbarColumns),
        model: new Backbone.Model(headerData)
      })
    );

    this.showChildView(
      'breadcrumb',
      new BreadcrumbView({
        breadcrumbs: [
          {
            link: '#ext/nondep/dashboard',
            text: 'Home'
          },
          {
            link: '#ext/nondep/entity/' + applicationDetails.entityId + '/dashboard',
            text: applicationDetails.entityName
          },
          {
            text: 'Application for a ' + applicationDetails.applicationType
          }
        ]
      })
    );

    this.showChildView('application', this.model.get('applicationView'));
  }
});
