var Handlebars = require("handlebars/dist/handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "collapse";
},"3":function(container,depth0,helpers,partials,data) {
    return "        <div class=\"form-group\">\n          <label class=\"control-label col-sm-3\">Action</label>\n          <div class=\"form-control-static col-sm-9 col-md-6\">Close</div>\n        </div>\n";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "        <div class=\"form-group required\">\n          <label class=\"control-label col-sm-3\">Action</label>\n          <div class=\"col-sm-9 col-md-6\">\n            <select name=\"actionId\" class=\"form-control action-id\" data-parsley-required=\"true\">\n              <option value=\"\" selected>--</option>\n"
    + ((stack1 = helpers.each.call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.examActions : depth0),{"name":"each","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "            </select>\n          </div>\n        </div>\n";
},"6":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "              <option value=\""
    + alias4(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\">"
    + alias4(((helper = (helper = helpers.action || (depth0 != null ? depth0.action : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"action","hash":{},"data":data}) : helper)))
    + "</option>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {});

  return "\n<div class=\"page-title clearfix\">\n  <h1 class=\"pull-left m-n p-n\">"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? depth0.task : depth0)) != null ? stack1.taskType : stack1)) != null ? stack1.description : stack1), depth0))
    + " for "
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.examDetails : depth0)) != null ? stack1.entityName : stack1), depth0))
    + "</h1>\n  <div class=\"page-title-buttons-container\">\n    <a role=\"button\" class=\"btn btn-primary\" href=\"#dep/entity/"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.examDetails : depth0)) != null ? stack1.entityId : stack1), depth0))
    + "/exam/"
    + alias2(((helper = (helper = helpers.examId || (depth0 != null ? depth0.examId : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(alias3,{"name":"examId","hash":{},"data":data}) : helper)))
    + "/dashboard\"\n      target=\"_blank\">Open\n      Exam</a>\n  </div>\n</div>\n<div class=\"header-bar\"></div>\n<div class=\"breadcrumb-container\"></div>\n<div class=\"task-status\"></div>\n<div class=\"form-horizontal\">\n  <form class=\"review-exam-form\">\n    <div class=\"row entity-exam-docs-form-container "
    + ((stack1 = helpers["if"].call(alias3,(depth0 != null ? depth0.noExtDocuments : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\">\n      <div class=\"col-sm-12\">\n        <div class=\"form-group\">\n          <label class=\"control-label col-sm-3\">Exam Response(s) Received from Entity</label>\n          <div class=\" col-sm-9 col-md-6\">\n            <div class=\"entity-file-upload-container\"></div>\n          </div>\n        </div>\n      </div>\n    </div>\n    <div class=\"row\">\n      <div class=\"col-sm-12\">\n"
    + ((stack1 = helpers["if"].call(alias3,(depth0 != null ? depth0.isHoldingCompanyExam : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.program(5, data, 0),"data":data})) != null ? stack1 : "")
    + "      </div>\n    </div>\n    <div class=\"notify-entity-form-container collapse\">\n      <div class=\"row\">\n        <div class=\"col-sm-12\">\n          <div class=\"form-group required\">\n            <label class=\"control-label col-sm-3\">Notify Entity?</label>\n            <div class=\"col-sm-9\">\n              <label class=\"radio-inline \">\n                <input type=\"radio\" name=\"isNotifyEntity\" value=\"1\" data-parsley-required=\"true\"\n                  data-parsley-errors-container=\"#notify-entity-error-container\" />\n                Yes\n              </label>\n              <label class=\"radio-inline\">\n                <input type=\"radio\" name=\"isNotifyEntity\" value=\"0\" data-parsley-required=\"true\"\n                  data-parsley-errors-container=\"#notify-entity-error-container\" />\n                No\n              </label>\n              <div id=\"notify-entity-error-container\"></div>\n            </div>\n          </div>\n        </div>\n      </div>\n    </div>\n    <div class=\"row collapse exam-docs-form-container\">\n      <div class=\"col-sm-12\">\n        <div class=\"form-group required\">\n          <label class=\"control-label col-sm-3\">Exam Documents for Entity</label>\n          <div class=\" col-sm-9 col-md-6\">\n            <div class=\"file-upload-container\"></div>\n          </div>\n        </div>\n      </div>\n    </div>\n  </form>\n  <div class=\"form-group\">\n    <div class=\"col-sm-9 col-lg-7 col-sm-offset-3\">\n      <a href=\"#dep/dashboard\" class=\"btn btn-default\" role=\"button\">Cancel</a>\n      <button type=\"button\" class=\"btn btn-primary submit\">Submit</button>\n      <div class=\"task-actions\"></div>\n    </div>\n  </div>\n</div>";
},"useData":true});