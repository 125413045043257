/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var Backgrid = require('backgrid');
var uuid = require('uuid');

var callByNeed = require('common/util/callByNeed');

require('./MultiButtonCell.css');
var tmplMultiButtonCell = require('./MultiButtonCell.hbs');

module.exports = Backgrid.ButtonCell = Backgrid.Cell.extend({
  className: 'multi-button-cell td-grid-cell-btn',

  events: {
    'click .btn-stand-alone': 'onClickStandAloneButton',
    'click .dropdown-item-click-handler': 'onClickDropdownLink'
  },

  dropdownActions: {},

  render: function() {
    var self = this;
    this.$el.addClass(this.column.get('name'));
    var baseButtonClass = 'btn-primary btn-sm';
    this.$el.empty();

    var id = 0;
    _.each(
      this.buttons,
      function(button) {
        // If button is a dropdown list of actions, get the actions, iterate
        // over them, create a new UUID for each one, and then add those
        // id/handler key-value pairs to the view property called
        // 'dropdownActions'
        var dropdownOptions = Backgrid.callByNeed(button.dropdownOptions, this, this.model);

        _.each(dropdownOptions, function(dropdownOption) {
          if (dropdownOption.onClick && _.isFunction(dropdownOption.onClick)) {
            dropdownOption.actionId = uuid.v4();
            self.dropdownActions[dropdownOption.actionId] = dropdownOption.onClick;
          }

          if (!_.has(dropdownOption, 'renderable')) {
            dropdownOption.renderable = true;
          } else {
            dropdownOption.renderable = callByNeed(dropdownOption.renderable);
          }
        });

        if (dropdownOptions) {
          dropdownOptions = _.where(dropdownOptions, { renderable: true });
        }

        var buttonType;
        if (dropdownOptions) {
          buttonType = 'dropdown';
        } else if (button.href) {
          buttonType = 'link';
        } else {
          buttonType = 'button';
        }

        var renderable;
        if (!_.has(button, 'renderable')) {
          renderable = true;
        } else {
          renderable = callByNeed(button.renderable, this, this.model);
        }

        if (renderable) {
          this.$el.append(
            tmplMultiButtonCell({
              id: id++,
              name: button.name,
              buttonClass: button.buttonClass
                ? Backgrid.callByNeed(button.buttonClass, this, this.model)
                : baseButtonClass,
              labelClass: button.labelClass,
              buttonType: buttonType,
              dropdownOptions: dropdownOptions,
              href: Backgrid.callByNeed(button.href, this, this.model),
              newTab: button.newTab
            })
          );

          if (this.buttonAttrs) {
            this.$el.find('button').attr(this.buttonAttrs);
          }
        }
      }.bind(this)
    );

    this.delegateEvents();
    return this;
  },

  onClickStandAloneButton: function(e) {
    e.preventDefault();

    var handler = this.buttons[$(e.currentTarget).data('multi-button-id')].onClick;

    if (handler && _.isFunction(handler)) {
      handler(e, this.model);
    }
  },

  onClickDropdownLink: function(e) {
    e.preventDefault();

    var handler = this.dropdownActions[$(e.currentTarget).data('multi-button-dropdown-action-id')];

    if (handler && _.isFunction(handler)) {
      handler(e, this.model);
    }
  }
});
