var Handlebars = require("handlebars/dist/handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), alias4=helpers.helperMissing;

  return "\n<div class=\"page-title clearfix\">\n  <h1 class=\"pull-left m-n p-n\">"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? depth0.task : depth0)) != null ? stack1.taskType : stack1)) != null ? stack1.description : stack1), depth0))
    + " for "
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.correspondence : depth0)) != null ? stack1.entityName : stack1), depth0))
    + "</h1>\n</div>\n<div class=\"header-bar\"></div>\n<div class=\"breadcrumb-container\"></div>\n<div class=\"task-status\"></div>\n<div class=\"form-horizontal\">\n  <form class=\"correspondence-form\">\n    <div class=\"row\">\n      <div class=\"col-sm-12\">\n        <div class=\"form-group\">\n          <label class=\"control-label col-sm-3\">Entity Name</label>\n          <div class=\"col-sm-9 col-md-6\">\n            <p class=\"form-control-static \">"
    + alias2((helpers["default"] || (depth0 && depth0["default"]) || alias4).call(alias3,((stack1 = (depth0 != null ? depth0.correspondence : depth0)) != null ? stack1.entityName : stack1),"--",{"name":"default","hash":{},"data":data}))
    + "</p>\n          </div>\n        </div>\n      </div>\n    </div>\n    <div class=\"row\">\n      <div class=\"col-sm-12\">\n        <div class=\"form-group\">\n          <label class=\"control-label col-sm-3\">Reply Date</label>\n          <div class=\"col-sm-9 col-md-6\">\n            <p class=\"form-control-static \">"
    + alias2((helpers["default"] || (depth0 && depth0["default"]) || alias4).call(alias3,((stack1 = (depth0 != null ? depth0.correspondence : depth0)) != null ? stack1.repliedOn : stack1),"--",{"name":"default","hash":{},"data":data}))
    + "</p>\n          </div>\n        </div>\n      </div>\n    </div>\n    <div class=\"row\">\n      <div class=\"col-sm-9 col-sm-offset-3\">\n        <h2>Correspondence</h2>\n      </div>\n    </div>\n    <div class=\"row\">\n      <div class=\"col-sm-12\">\n        <div class=\"form-group\">\n          <label class=\"control-label col-sm-3\">Message From Entity</label>\n          <div class=\"col-sm-9 col-md-6\">\n            <p class=\"form-control-static\">"
    + alias2((helpers["default"] || (depth0 && depth0["default"]) || alias4).call(alias3,((stack1 = (depth0 != null ? depth0.correspondence : depth0)) != null ? stack1.requestComments : stack1),"--",{"name":"default","hash":{},"data":data}))
    + "</p>\n          </div>\n        </div>\n      </div>\n    </div>\n    <div class=\"row\">\n      <div class=\"col-sm-12\">\n        <div class=\"form-group\">\n          <label class=\"control-label col-sm-3\">Reply Comments</label>\n          <div class=\"col-sm-9 col-md-6\">\n            <p class=\"form-control-static\">"
    + alias2((helpers["default"] || (depth0 && depth0["default"]) || alias4).call(alias3,((stack1 = (depth0 != null ? depth0.correspondence : depth0)) != null ? stack1.replyComments : stack1),"--",{"name":"default","hash":{},"data":data}))
    + "</p>\n          </div>\n        </div>\n      </div>\n    </div>\n    <div class=\"row\">\n      <div class=\"col-sm-9 col-sm-offset-3\">\n        <h2>Documents</h2>\n      </div>\n    </div>\n    <div class=\"row\">\n      <div class=\"col-sm-12\">\n        <div class=\"form-group\">\n          <label class=\"control-label col-sm-3 \">External Documents</label>\n          <div class=\" col-sm-9 col-md-6\">\n            <div class=\"ext-file-upload-table-container\"></div>\n          </div>\n        </div>\n      </div>\n    </div>\n    <div class=\"row\">\n      <div class=\"col-sm-12\">\n        <div class=\"form-group\">\n          <label class=\"control-label col-sm-3 \">Internal Documents</label>\n          <div class=\" col-sm-9 col-md-6\">\n            <div class=\"int-file-upload-table-container\"></div>\n          </div>\n        </div>\n      </div>\n    </div>\n  </form>\n  <div class=\"form-group\">\n    <div class=\"col-sm-9 col-lg-7 col-sm-offset-3\">\n      <a href=\"#ext/nondep/dashboard\" class=\"btn btn-default\" role=\"button\">Cancel</a>\n      <button type=\"button\" class=\"btn btn-primary submit\">Acknowledge</button>\n    </div>\n  </div>\n</div>";
},"useData":true});