/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var cleanUpResponse = require('common/util/cleanUpResponse');

module.exports = function(filingId) {
  var deferred = $.Deferred();
  $.soap({
    body: {
      GetBankArticlesAmendmentFiling: {
        $xmlns: 'http://dobs.pa.gov/dp/filing/bpm/1.0',
        FilingID: filingId
      }
    },
    blueprintArrays: [
      'GetBankArticlesAmendmentFilingResponse.SupportingDocuments.DP_FilingSupportingDocument',
      'GetBankArticlesAmendmentFilingResponse.GeneralPublicationProofDocs.DP_FilBankArticlesOfAmendmentGeneralPublicationProofDocument',
      'GetBankArticlesAmendmentFilingResponse.LegalPublicationProofDocs.DP_FilBankArticlesOfAmendmentLegalPublicationProofDocument'
    ],
    blueprint: {
      bankArticlesAmendmentFilingId: '$..ID',
      filingId: '$..FilingID',
      entityId: '$..EntityID',
      publicationReqs: {
        file: {
          fileId: '$..PublicationReqsDocID',
          fileName: '$..PublicationReqsName'
        },
        comments: '$..PublicationReqsComments'
      },
      articlesOfAmendment: {
        file: {
          fileId: '$..ArticlesOfAmendmentDocID',
          fileName: '$..ArticlesOfAmendmentName'
        },
        comments: '$..ArticlesOfAmendmentComments'
      },
      boardResolution: {
        file: {
          fileId: '$..BoardResolutionDocID',
          fileName: '$..BoardResolutionName'
        },
        comments: '$..BoardResolutionComments'
      },
      shareholderNotice: {
        file: {
          fileId: '$..ShareholderNoticeDocID',
          fileName: '$..ShareholderNoticeName'
        },
        comments: '$..ShareholderNoticeComments'
      },
      shareholderApproval: {
        file: {
          fileId: '$..ShareholderApprovalDocID',
          fileName: '$..ShareholderApprovalName'
        },
        comments: '$..ShareholderApprovalComments'
      },
      nameReservation: {
        file: {
          fileId: '$..NameReservationDocID',
          fileName: '$..NameReservationName'
        },
        comments: '$..NameReservationComments'
      },
      principlePlaceActivityNew: '$..PrinciplePlaceActivityNew',
      principlePlaceActivityExisting: '$..PrinciplePlaceActivityExisting',
      isSameMailingAddress: '$..IsSameMailingAddress',
      newMailingAddressId: '$..NewMailingAddressID',
      newMailingAddress: {
        addressLine1: '$..AddressLine1',
        addressLine2: '$..AddressLine2',
        addressLine3: '$..AddressLine3',
        county: '$..County',
        city: '$..City',
        state: '$..State',
        postalCode: '$..PostalCode',
        country: '$..Country'
      },
      articlesOfAmendmentTypeId: '$..ArticlesOfAmendmentTypeID',
      newName: '$..NewName',
      purposeDescription: '$..PurposeDescription',
      generalPublicationProofComments: '$..GeneralPublicationProofComments',
      legalPublicationProofComments: '$..LegalPublicationProofComments',
      requestedEffectiveDate: '$..RequestedEffectiveDate',
      supportingDocuments: [
        '$..DP_FilingSupportingDocument',
        { file: { fileId: '$..DocumentID', fileName: '$..DocumentName' } }
      ],
      generalPublicationProofDocs: [
        '$..DP_FilBankArticlesOfAmendmentGeneralPublicationProofDocument',
        { fileId: '$.DocumentID', fileName: '$.FileName' }
      ],
      legalPublicationProofDocs: [
        '$..DP_FilBankArticlesOfAmendmentLegalPublicationProofDocument',
        { fileId: '$.DocumentID', fileName: '$.FileName' }
      ]
    }
  })
    .done(function(response) {
      cleanUpResponse(response);
      deferred.resolve(response);
    })
    .fail(function(err) {
      deferred.reject();
    });

  return deferred.promise();
};
