/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2020] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var BreadcrumbView = require('common/views/BreadcrumbView');

module.exports = BreadcrumbView.extend({
  initialize: function(options) {
    var identifiers = options.identifiers || {};

    if (identifiers.examId) {
      identifiers.isExamOrg = true;
    } else if (identifiers.isOrganization === '1') {
      identifiers.isInvInqOrg = true;
    }

    var orgUrl;
    var licenseUrl;
    var examInvInqUrl;
    if (identifiers.isExamOrg) {
      orgUrl = '#sec/organization/' + identifiers.orgIndObjectId + '/dashboard';
      licenseUrl =
        '#sec/organization/' + identifiers.orgIndObjectId + '/license/' + identifiers.licenseId + '/dashboard';
      examInvInqUrl = '#sec/exam/' + identifiers.objectId + '/dashboard';
    } else if (identifiers.isInvInqOrg) {
      orgUrl = '#sec/organization/' + identifiers.orgIndObjectId + '/dashboard';
      licenseUrl =
        '#sec/organization/' + identifiers.orgIndObjectId + '/license/' + identifiers.licenseId + '/dashboard';
      examInvInqUrl = '#sec/investigation-inquiry/' + identifiers.objectId + '/dashboard';
    } else {
      orgUrl = '#sec/individual/' + identifiers.orgIndObjectId + '/dashboard';
      licenseUrl = '#sec/individual/' + identifiers.orgIndObjectId + '/license/' + identifiers.licenseId + '/dashboard';
      examInvInqUrl = '#sec/investigation-inquiry/' + identifiers.objectId + '/dashboard';
    }

    var baseBreadcrumbs = [
      {
        link: '#sec/dashboard',
        text: 'Home'
      },
      {
        link: orgUrl,
        text: identifiers.applicantLegalName
      }
    ];

    if (identifiers.licenseId) {
      baseBreadcrumbs.push({
        link: licenseUrl,
        text: identifiers.licenseType
      });
    }

    baseBreadcrumbs.push({
      link: examInvInqUrl,
      text: identifiers.complianceObjectTypeDisplay + ' (' + identifiers.examCaseNumber + ')'
    });

    this.options.breadcrumbs = baseBreadcrumbs.concat(options.breadcrumbs);

    if (BreadcrumbView.prototype.initialize) {
      BreadcrumbView.prototype.initialize.apply(options);
    }
  }
});
