var Handlebars = require("handlebars/dist/handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression;

  return "\n<div class=\"page-title clearfix\">\n  <h1 class=\"m-n p-n\">"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.entityIdentifiers : depth0)) != null ? stack1.entityName : stack1), depth0))
    + "</h1>\n  <div class=\"page-title-buttons-container\">\n    <div class=\"dropdown-container\"></div>\n  </div>\n</div>\n<div class=\"breadcrumb-container\"></div>\n<div class=\"col-sm-12 col-md-10 col-md-offset-1\">\n  <div class=\"row\">\n    <div class=\"col-sm-9 col-sm-offset-3 col-md-10 col-md-offset-2\">\n      <h3 class=\"m-t-xs m-b-lg\">New Risk Matrix</h3>\n    </div>\n  </div>\n  <div class=\"new-risk-matrix-form-container\"></div>\n  <div class=\"row\">\n    <div class=\"col-sm-9 col-sm-offset-3 col-md-10 col-md-offset-2\">\n      <a class=\"btn btn-default\" href=\"#dep/entity/"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.entityIdentifiers : depth0)) != null ? stack1.entityId : stack1), depth0))
    + "/dashboard\">Cancel</a>\n      <button class=\"btn btn-primary btn-submit\">Submit</button>\n    </div>\n  </div>\n</div>";
},"useData":true});