/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Backbone = require('backbone');
var Marionette = require('backbone.marionette');

var Session = require('psa-core/Session');

var AppCardCollectionView = require('common/views/AppCardCollectionView');
var userHasRole = require('common/util/userHasRole');

require('../styles/TimeTrkNonDepPageSelectView.css');
var tmplTimeTrkNonDepPageSelectView = require('../templates/TimeTrkNonDepPageSelectView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplTimeTrkNonDepPageSelectView,

  className: 'timetrk-nondep-page-selection-view container-fluid',

  ui: {
    cardContainer: '.card-container'
  },

  regions: {
    cardContainer: '@ui.cardContainer'
  },

  onBeforeShow: function() {
    var user = Session.user;
    var isExamManagementTech = userHasRole('ND - MT Exam');

    var appCards = [];

    if (user.timeNDHasTimesheet) {
      // https://pixabay.com/photos/writing-write-person-paperwork-828911/
      appCards.push({
        image: 'img/writing.jpg',
        name: 'Submit Timesheets',
        description: 'Submit timesheets and expenses for the current period.',
        link: '#nondep/time-tracking'
      });
    }

    if (user.timeNDIsReviewer) {
      // https://pixabay.com/photos/audit-auditor-analysis-examination-4190944/
      appCards.push({
        image: 'img/audit.jpg',
        name: 'Review Timesheets',
        description: 'Review submitted timesheets and expenses.',
        link: '#nondep/time-tracking/review'
      });
    }

    if (isExamManagementTech) {
      // https://pixabay.com/photos/digital-marketing-seo-google-1725340/
      appCards.push({
        image: 'img/digital-marketing.jpg',
        name: 'View Timesheets',
        description: 'View timesheets and expenses.',
        link: '#nondep/time-tracking/view'
      });
    }

    this.showChildView(
      'cardContainer',
      new AppCardCollectionView({
        collection: new Backbone.Collection(appCards)
      })
    );
  }
});
