/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var cleanUpResponse = require('common/util/cleanUpResponse');
var momentUtil = require('common/util/momentUtil');

module.exports = function(directorId) {
  var deferred = $.Deferred();

  $.soap({
    body: {
      GetDirector: {
        $xmlns: 'http://dobs.pa.gov/dp/director/bpm/1.0',
        DirectorID: directorId
      }
    },
    blueprint: {
      directorId: '$..ID',
      entityId: '$..EntityID',
      positionHeld: '$..PositionHeld',
      firstName: '$..FirstName',
      lastName: '$..LastName',
      middleInitial: '$..MiddleInitial',
      termLength: '$..TermLength',
      statusId: '$..StatusID',
      status: '$..Status',
      termStartDate: '$..TermStartDate',
      termEndDate: '$..TermEndDate',
      electionDate: '$..ElectionDate',
      electionBasisId: '$..ElectionBasisID',
      reasonId: '$..ReasonID',
      reason: '$..Reason',
      electionBasis: '$..ElectionBasis',
      isChairman: '$..IsChairman',
      comment: '$..Comment',
      positionHeldId: '$..PositionHeldID',
      positionHeldLookup: '$..PositionHeldLookup',
      isDeleted: '$..IsDeleted'
    }
  })
    .done(function(response) {
      cleanUpResponse(response);

      var emptyDefaultInboundDateConfig = {
        sourceFormat: 'YYYY-MM-DD',
        targetFormat: 'MM/DD/YYYY',
        default: undefined
      };

      response.termStartDate = momentUtil.formatStr(response.termStartDate, emptyDefaultInboundDateConfig);
      response.termEndDate = momentUtil.formatStr(response.termEndDate, emptyDefaultInboundDateConfig);
      response.electionDate = momentUtil.formatStr(response.electionDate, emptyDefaultInboundDateConfig);

      deferred.resolve(response);
    })
    .fail(function(err) {
      deferred.reject();
    });

  return deferred.promise();
};
