var Handlebars = require("handlebars/dist/handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression;

  return "<div class=\"page-title clearfix\">\n  <h1 class=\"pull-left m-n p-n\">"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.task : depth0)) != null ? stack1.activity : stack1), depth0))
    + "</h1>\n</div>\n<div class=\"breadcrumb-container\"></div>\n<div class=\"col-sm-8 col-sm-offset-2 notification-container\">\n  <div class=\"m-b-lg\">\n    <b>The License Number for Entity <br>\n     "
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.application : depth0)) != null ? stack1.entityName : stack1), depth0))
    + "  \n      for the <br>\n      "
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.application : depth0)) != null ? stack1.licenseType : stack1), depth0))
    + " license is #"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.license : depth0)) != null ? stack1.licenseNumber : stack1), depth0))
    + "\n    </b>\n  </div>\n  <div class=\"document-container\"></div>\n  <button class=\"btn btn-primary submit ladda-button\" data-style=\"expand-right\" type=\"button\">\n    <span class=\"ladda-label\">Acknowledge</span>\n  </button>\n</div>";
},"useData":true});