/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var Marionette = require('backbone.marionette');
var Syphon = require('backbone.syphon');

var FileUploadCollection = require('psa-backbone/models/FileUploadCollection');
var CommentsDocumentView = require('common/views/CommentsDocumentView');
var makeUploadCollection = require('common/util/makeUploadCollection');

var toggleForm = require('common/util/toggleForm');
var parsleyUtil = require('common/util/parsleyUtil');
var modelGet = require('common/util/modelGet');
var FileUploadView = require('common/views/FileUploadView');

require('../../styles/DepFilingPrintView.css');
var tmplDepCreditUnionParityRequiredInfoAndDocSectionView = require('./DepCreditUnionParityRequiredInfoAndDocSectionView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplDepCreditUnionParityRequiredInfoAndDocSectionView,

  className: 'dep-credit-union-parity-required-info-and-doc-section-view',

  ui: {
    requiredInfoAndDocForm: '.required-info-and-doc-form',
    datePickerFields: '.date-picker',
    isCheckbox: '.is-checkbox',
    isCitationUSCInputs: '#isCitationUSCInputs',
    isCitationCFRInputs: '#isCitationCFRInputs',
    isCitationOtherInputs: '#isCitationOtherInputs',
    isCitationInterpretiveLetterInputs: '#isCitationInterpretiveLetterInputs',
    missingFile: '.missing-file',
    parityAgencyInterpretiveDocumentsContainer: '.parity-agency-interpretive-documents-container',
    riskAnalysisDocumentContainer: '.risk-analysis-document-container',
    imposedRestrictionsDocumentContainer: '.imposed-restrictions-document-container',
    contactInformationDocumentContainer: '.contact-information-document-container',
    interpretiveLetter: '.interpretive-letter',
    citationForm: '.citation-form'
  },

  regions: {
    datePickerFields: '@ui.datePickerFields',
    citationUsc: '@ui.citationUsc',
    parityAgencyInterpretiveDocumentsContainer: '@ui.parityAgencyInterpretiveDocumentsContainer',
    riskAnalysisDocumentContainer: '@ui.riskAnalysisDocumentContainer',
    imposedRestrictionsDocumentContainer: '@ui.imposedRestrictionsDocumentContainer',
    contactInformationDocumentContainer: '@ui.contactInformationDocumentContainer'
  },

  events: {
    'change @ui.isCheckbox': 'onChangeCheckbox'
  },

  templateHelpers: function() {
    return {
      isReadOnly: this.options.isReadOnly
    };
  },

  onBeforeShow: function() {
    var filingContent = this.model.get('filingContent') || {};
    var parityAgencyInterpretiveDocuments = _.pluck(
      modelGet(this.model, 'filingContent.parityAgencyInterpretiveDocuments', []),
      'file'
    );
    var riskAnalysisDocumentId = modelGet(this.model, 'filingContent.riskAnalysisDocument.fileId');
    if (riskAnalysisDocumentId) {
      var riskAnalysisDocument = modelGet(this.model, 'filingContent.riskAnalysisDocument');
    }
    var imposedRestrictionsDocumentId = modelGet(this.model, 'filingContent.imposedRestrictionsDocument.fileId');
    if (imposedRestrictionsDocumentId) {
      var imposedRestrictionsDocument = modelGet(this.model, 'filingContent.imposedRestrictionsDocument');
    }
      var contactInformationDocument = modelGet(this.model, 'filingContent.contactInformationDocument');
  
    Syphon.deserialize(this, filingContent);

    var isCitationUSC = modelGet(this.model, 'filingContent.isCitationUSC');
    toggleForm(this.ui.isCitationUSCInputs, isCitationUSC);
    var isCitationCFR = modelGet(this.model, 'filingContent.isCitationCFR');
    toggleForm(this.ui.isCitationCFRInputs, isCitationCFR);
    var isCitationOther = modelGet(this.model, 'filingContent.isCitationOther');
    toggleForm(this.ui.isCitationOtherInputs, isCitationOther);
    var isCitationInterpretiveLetter = modelGet(this.model, 'filingContent.isCitationInterpretiveLetter');
    toggleForm(this.ui.isCitationInterpretiveLetterInputs, isCitationInterpretiveLetter);

    this.parityAgencyInterpretiveDocuments = new FileUploadView({
      isReadOnly: this.options.isReadOnly,
      allowMultipleFiles: true,
      isRequired: true,
      collection: new FileUploadCollection(parityAgencyInterpretiveDocuments),
      metadata: {
        documentTypeId: 20001
      }
    });
    this.showChildView('parityAgencyInterpretiveDocumentsContainer', this.parityAgencyInterpretiveDocuments);

    this.riskAnalysisDocument = new FileUploadView({
      isReadOnly: this.options.isReadOnly,
      isRequired: true,
      collection: new FileUploadCollection(riskAnalysisDocument),
      metadata: {
        documentTypeId: 20001
      }
    });
    this.showChildView('riskAnalysisDocumentContainer', this.riskAnalysisDocument);

    this.imposedRestrictionsDocument = new FileUploadView({
      isReadOnly: this.options.isReadOnly,
      isRequired: true,
      collection: new FileUploadCollection(imposedRestrictionsDocument),
      metadata: {
        documentTypeId: 20001
      }
    });
    this.showChildView('imposedRestrictionsDocumentContainer', this.imposedRestrictionsDocument);

    this.contactInformationDocument = new CommentsDocumentView({
      isReadOnly: this.options.isReadOnly,
      allowMultipleFiles: false,
      isRequired: true,
      fileUploadLabel: 'Section II - New Activity Attachment',
      commentsLabel: 'Section II - New Activity',
      commentsName: 'contactInformationComments',
      collection: makeUploadCollection(contactInformationDocument),
      model: new Backbone.Model({ contactInformationComments: filingContent.contactInformationComments }),
      documentTypeId: 20001
    });

    this.showChildView('contactInformationDocumentContainer', this.contactInformationDocument);
  },

  onAttach: function() {
    if (this.options.mode === 'print') {
      this.switchToPrint();
    }
  },

  onChangeCheckbox: function(e) {
    var $checkbox = $(e.target);
    var isCheckbox = $checkbox.prop('checked');
    var formTargetSelector = $checkbox.attr('data-target');
    var $commentInputs = this.$el.find(formTargetSelector);
    toggleForm($commentInputs, isCheckbox);
  },

  switchToPrint: function() {
    var textAreas = $('.input-view');

    _.each(textAreas, function(textArea) {
      var textAreaVal = textArea.value;
      var textAreaName = textArea.name;
      var printElementId = '#'.concat(textAreaName, 'PrintView');
      $(printElementId).text(textAreaVal);
    });
    toggleForm(textAreas, false);
  },

  validate: function(config) {
    var defer = $.Deferred();

    this.ui.requiredInfoAndDocForm.parsley().reset();
    this.riskAnalysisDocument.removeValidationErrors();
    this.imposedRestrictionsDocument.removeValidationErrors();
    this.contactInformationDocument.removeValidationErrors();
    this.parityAgencyInterpretiveDocuments.removeValidationErrors();

    if (config.type === 'save') {
      // Turn off required field validation for this form
      parsleyUtil.disableRequiredValidation(this.ui.requiredInfoAndDocForm);
    } else {
      var validationPromises = [
        this.ui.requiredInfoAndDocForm.parsley().whenValidate(),
        this.riskAnalysisDocument.validate(),
        this.imposedRestrictionsDocument.validate(),
        this.contactInformationDocument.validate()
      ];
      if (this.ui.interpretiveLetter.is(':checked')) {
        validationPromises.push(this.parityAgencyInterpretiveDocuments.validate());
      }
      // Turn on required field validation for this form
      parsleyUtil.enableRequiredValidation(this.ui.requiredInfoAndDocForm);
      var citation = this.ui.isCheckbox.filter(':checked') || [];
      if (citation.length < 1) {
        this.ui.citationForm.addClass('has-error');
        defer.reject();
      } else {
        defer.resolve();
      }
      validationPromises.push(defer.promise());
    }
    return $.when.apply($, validationPromises);
  },

  getFormData: function() {
    var formData = Syphon.serialize(this);
    formData.parityAgencyInterpretiveDocuments = this.parityAgencyInterpretiveDocuments.collection.map(function(file) {
      return {
        fileId: file.get('fileId'),
        documentTypeId: modelGet(file, 'metadata.documentTypeId')
      };
    });
    formData.riskAnalysisDocId = this.riskAnalysisDocument.getFirstFileId();
    formData.imposedRestrictionsDocId = this.imposedRestrictionsDocument.getFirstFileId();
    formData.contactInformationDocId = this.contactInformationDocument.getFirstFileId();
    return formData;
  }
});
