/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2020] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Marionette = require('backbone.marionette');
var Syphon = require('backbone.syphon');

var tmplDepMrbaFormView = require('../templates/DepMrbaFormView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplDepMrbaFormView,

  className: 'dep-mrba-form-view',

  ui: {
    examAsOfDate: '.exam-as-of-date',
    dueDate: '.due-date',
    latestResponseDueDate: '.latest-response-due-date',
    latestReceivedDate: '.latest-received-date',
    latestExtensionDate: '.latest-extension-date'
  },

  onBeforeShow: function() {
    Syphon.deserialize(this, this.model.get('mrba'));

    this.ui.examAsOfDate.datepicker({});
    this.ui.dueDate.datepicker({});
    this.ui.latestResponseDueDate.datepicker({});
    this.ui.latestReceivedDate.datepicker({});
    this.ui.latestExtensionDate.datepicker({});
  },

  getFormData: function() {
    return Syphon.serialize(this);
  }
});
