var Handlebars = require("handlebars/dist/handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "  <div class=\"row\">\n    <div class=\"col-sm-6\">\n      <div class=\"form-group\">\n        <label class=\"control-label col-sm-6 col-md-4\">Audit Firm</label>\n        <div class=\"form-control-static col-sm-6 col-md-8\">"
    + container.escapeExpression((helpers["default"] || (depth0 && depth0["default"]) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.auditFirm : depth0),"--",{"name":"default","hash":{},"data":data}))
    + "</div>\n      </div>\n    </div>\n  </div>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "\n<div class=\"form-horizontal form-static\">\n  <div class=\"row\">\n    <div class=\"col-sm-6\">\n      <div class=\"form-group\">\n        <label class=\"control-label col-sm-6 col-md-4\">Trust Audit Type</label>\n        <div class=\"form-control-static col-sm-6 col-md-8\">"
    + alias3((helpers["default"] || (depth0 && depth0["default"]) || alias2).call(alias1,(depth0 != null ? depth0.auditTrustType : depth0),"--",{"name":"default","hash":{},"data":data}))
    + "</div>\n      </div>\n    </div>\n    <div class=\"col-sm-6\">\n      <div class=\"form-group\">\n        <label class=\"control-label col-sm-6 col-md-4\">Audit Date</label>\n        <div class=\"form-control-static col-sm-6 col-md-8\">"
    + alias3((helpers["default"] || (depth0 && depth0["default"]) || alias2).call(alias1,(depth0 != null ? depth0.auditDate : depth0),"--",{"name":"default","hash":{},"data":data}))
    + "</div>\n      </div>\n    </div>\n  </div>\n  <div class=\"row\">\n    <div class=\"col-sm-6\">\n      <div class=\"form-group\">\n        <label class=\"control-label col-sm-6 col-md-4\">Received Date</label>\n        <div class=\"form-control-static col-sm-6 col-md-8\">"
    + alias3((helpers["default"] || (depth0 && depth0["default"]) || alias2).call(alias1,(depth0 != null ? depth0.receivedDate : depth0),"--",{"name":"default","hash":{},"data":data}))
    + "</div>\n    </div>\n    </div>\n  </div>\n  <div class=\"row\">\n    <div class=\"col-sm-12\">\n      <div class=\"form-group\">\n        <label class=\"control-label col-sm-3 col-md-2\">Opinion Details</label>\n        <p class=\"form-control-static col-sm-9 col-md-10\">"
    + alias3((helpers["default"] || (depth0 && depth0["default"]) || alias2).call(alias1,(depth0 != null ? depth0.opinionDetails : depth0),"--",{"name":"default","hash":{},"data":data}))
    + "</p>\n      </div>\n    </div>\n  </div>\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.cpaAudit : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "</div>";
},"useData":true});