/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Backbone = require('backbone');
var Ladda = require('ladda');
var Marionette = require('backbone.marionette');
var Radio = require('backbone.radio');

var BreadcrumbView = require('common/views/BreadcrumbView');
var HeaderBarView = require('common/views/HeaderBarView');
var modelGet = require('common/util/modelGet');

var SubmitNewCorrespondence = require('../../common/services/SubmitNewCorrespondence');

var NonDepInitiateCorrespondenceFormView = require('../../correspondence/views/NonDepInitiateCorrespondenceFormView');

var tmplNonDepApplicationInitiateCorrespondenceView = require('../templates/NonDepApplicationInitiateCorrespondenceView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplNonDepApplicationInitiateCorrespondenceView,

  className: 'non-dep-application-initiate-correspondence-view container-fluid',

  ui: {
    headerBar: '.header-bar',
    breadcrumb: '.breadcrumb-container',
    correspondenceFormContainer: '.correspondence-form-container',
    submit: '.submit'
  },

  regions: {
    headerBar: '@ui.headerBar',
    breadcrumb: '@ui.breadcrumb',
    correspondenceFormContainer: '@ui.correspondenceFormContainer'
  },

  events: {
    'click @ui.submit': 'onClickSubmit'
  },

  onBeforeShow: function() {
    var entityId = modelGet(this.model, 'applicationDetails.entityId');
    var entityName = modelGet(this.model, 'applicationDetails.entityName');
    var applicationId = modelGet(this.model, 'applicationDetails.applicationId');
    var applicationType = modelGet(this.model, 'applicationDetails.applicationType');
    var applicationStatus = modelGet(this.model, 'applicationDetails.applicationStatus');

    var headerData = {
      title: 'Application',
      applicationType: applicationType,
      status: applicationStatus,
      owner: this.model.get('owner')
    };

    this.showChildView(
      'headerBar',
      new HeaderBarView({
        columns: new Backbone.Collection([
          {
            name: 'applicationType',
            label: 'Application Type',
            minWidth: '160px',
            maxWidth: '300px'
          },
          {
            name: 'owner',
            label: 'Owner',
            minWidth: '160px'
          },
          {
            name: 'status',
            label: 'Status',
            minWidth: '160px',
            maxWidth: '350px',
            overflow: 'truncate'
          }
        ]),
        model: new Backbone.Model(headerData)
      })
    );

    this.showChildView(
      'breadcrumb',
      new BreadcrumbView({
        breadcrumbs: [
          {
            link: '#nondep/dashboard',
            text: 'Home'
          },
          {
            link: '#nondep/entity/' + entityId + '/dashboard',
            text: entityName
          },
          {
            link: '#nondep/application/' + applicationId + '/dashboard',
            text: 'Application #' + applicationId + ' - ' + applicationType
          },
          {
            text: 'Initiate Correspondence'
          }
        ]
      })
    );

    this.correspondenceForm = new NonDepInitiateCorrespondenceFormView({
      model: this.model
    });

    this.showChildView('correspondenceFormContainer', this.correspondenceForm);
  },

  onClickSubmit: function(e) {
    e.preventDefault();
    var self = this;
    var applicationId = modelGet(this.model, 'applicationDetails.applicationId');
    var entityId = modelGet(this.model, 'applicationDetails.entityId');

    this.correspondenceForm.validate().done(function() {
      var l = Ladda.create(self.ui.submit[0]);
      l.start();
      var formData = self.correspondenceForm.getFormData();
      formData.objectId = applicationId;
      formData.objectTypeId = 'APPLICATION';
      formData.entityId = entityId;
      formData.isInternal = '1';

      SubmitNewCorrespondence(formData)
        .done(function() {
          Radio.channel('navigate').trigger('show:int:nd:application-dashboard', applicationId);
        })
        .fail(function() {
          l.stop();
        });
    });
  }
});
