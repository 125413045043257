var Handlebars = require("handlebars/dist/handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "\n<div class=\"panel-heading\" role=\"tab\" id=\"heading-"
    + alias4(((helper = (helper = helpers.childIndex || (depth0 != null ? depth0.childIndex : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"childIndex","hash":{},"data":data}) : helper)))
    + "\">\n  <div class=\"panel-title\">\n    <a data-toggle=\"collapse\" data-parent=\"#accordion\" href=\"#collapse-"
    + alias4(((helper = (helper = helpers.childIndex || (depth0 != null ? depth0.childIndex : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"childIndex","hash":{},"data":data}) : helper)))
    + "\" aria-expanded=\"true\"\n      aria-controls=\"collapse-"
    + alias4(((helper = (helper = helpers.childIndex || (depth0 != null ? depth0.childIndex : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"childIndex","hash":{},"data":data}) : helper)))
    + "\">\n      Acquirer or Transferee\n    </a>\n    <button class=\"btn btn-danger btn-md remove-acquirer-position pull-right\">Remove</button>\n  </div>\n</div>\n<div id=\"collapse-"
    + alias4(((helper = (helper = helpers.childIndex || (depth0 != null ? depth0.childIndex : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"childIndex","hash":{},"data":data}) : helper)))
    + "\" class=\"panel-collapse collapse in\" role=\"tabpanel\"\n  aria-labelledby=\"heading-"
    + alias4(((helper = (helper = helpers.childIndex || (depth0 != null ? depth0.childIndex : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"childIndex","hash":{},"data":data}) : helper)))
    + "\">\n  <div class=\"panel-body\">\n    <form>\n      <div class=\"row\">\n        <div class=\"col-md-6 col-sm-12\">\n          <div class=\"form-group required\">\n            <label class=\"control-label\">Name of each acquirer or transferee</label>\n            <input type=\"text\" name=\"name\" class=\"form-control\" placeholder=\"Name\" data-parsley-maxlength=\"100\"\n              data-parsley-required=\"true\" />\n          </div>\n        </div>\n      </div>\n      <div class=\"row\">\n        <div class=\"col-sm-12\">\n          <div class=\"form-group required\">\n            <label class=\"control-label\">Name and address of each depository institution or holding company</label>\n            <textarea class=\"form-control input-view\" name=\"companyNameAddress\" id=\"companyNameAddress"
    + alias4(((helper = (helper = helpers.childIndex || (depth0 != null ? depth0.childIndex : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"childIndex","hash":{},"data":data}) : helper)))
    + "\" rows=\"8\" \n            placeholder=\"Name and address of each depository institution or holding company\" data-parsley-required=\"true\"></textarea>\n          </div>\n          <div class=\"form-group print-view\" id=\"companyNameAddress"
    + alias4(((helper = (helper = helpers.childIndex || (depth0 != null ? depth0.childIndex : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"childIndex","hash":{},"data":data}) : helper)))
    + "PrintView\"></div>\n        </div>\n      </div>\n      <div class=\"row\">\n        <div class=\"col-md-6 col-sm-12\">\n          <div class=\"form-group required\">\n            <label class=\"control-label\">Position and date appointed</label>\n            <input type=\"text\" name=\"positionDateAppointed\" class=\"form-control\" placeholder=\"Position and date appointed\" data-parsley-maxlength=\"100\"\n              data-parsley-required=\"true\" />\n          </div>\n        </div>\n        <div class=\"col-md-6 col-sm-12\">\n          <div class=\"form-group required\">\n            <label class=\"control-label\">Percent ownership of institution %</label>\n            <input type=\"text\" name=\"ownershipPercent\" class=\"form-control\" placeholder=\"Percent ownership of institution %\" data-parsley-maxlength=\"100\"\n              data-parsley-required=\"true\"/>\n          </div>\n        </div>\n      </div>\n    </form>\n  </div>\n</div>";
},"useData":true});