var Handlebars = require("handlebars/dist/handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1;

  return container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? depth0.exam : depth0)) != null ? stack1.totalAmountDue : stack1), depth0));
},"3":function(container,depth0,helpers,partials,data) {
    return "--";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1;

  return container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? depth0.exam : depth0)) != null ? stack1.totalAmountPaid : stack1), depth0));
},"7":function(container,depth0,helpers,partials,data) {
    var stack1;

  return container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? depth0.exam : depth0)) != null ? stack1.totalFeesDue : stack1), depth0));
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "\n<div class=\"form-horizontal form-static\">\n  <div class=\"row\">\n    <div class=\"col-sm-6\">\n      <div class=\"form-group\">\n        <label class=\"control-label col-sm-4\">Total Amount Due (with Fees)</label>\n        <div class=\"form-control-static col-sm-8\">\n          "
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.exam : depth0)) != null ? stack1.totalAmountDue : stack1),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data})) != null ? stack1 : "")
    + "\n        </div>\n      </div>\n      <div class=\"form-group\">\n        <label class=\"control-label col-sm-4\">Total Amount Paid</label>\n        <div class=\"form-control-static col-sm-8\">\n          "
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.exam : depth0)) != null ? stack1.totalAmountPaid : stack1),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.program(3, data, 0),"data":data})) != null ? stack1 : "")
    + "</div>\n      </div>\n    </div>\n    <div class=\"col-sm-6\">\n      <div class=\"form-group\">\n        <label class=\"control-label col-sm-4\">Total Fees Due</label>\n        <div class=\"form-control-static col-sm-8\">\n          "
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.exam : depth0)) != null ? stack1.totalFeesDue : stack1),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.program(3, data, 0),"data":data})) != null ? stack1 : "")
    + "</div>\n      </div>\n    </div>\n  </div>\n  <div class=\"form-group\">\n    <label class=\"control-label col-sm-2\">Payments</label>\n    <div class=\"payment-grid col-sm-8\"></div>\n  </div>\n  <div class=\"form-group\">\n    <label class=\"control-label col-sm-2\">Exam User Hours</label>\n    <div class=\"user-hour-grid col-sm-8\"></div>\n  </div>\n  <div class=\"form-group\">\n    <label class=\"control-label col-sm-2\">Billing Comments</label>\n    <p class=\"form-control-static col-sm-8\">"
    + container.escapeExpression((helpers["default"] || (depth0 && depth0["default"]) || helpers.helperMissing).call(alias1,((stack1 = (depth0 != null ? depth0.exam : depth0)) != null ? stack1.billingComments : stack1),"--",{"name":"default","hash":{},"data":data}))
    + "</p>\n  </div>\n</div>";
},"useData":true});