var Handlebars = require("handlebars/dist/handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.escapeExpression;

  return "\n<div class=\"page-title clearfix\">\n  <h1 class=\"pull-left m-n p-n\">\n    "
    + alias1(container.lambda(((stack1 = (depth0 != null ? depth0.identifiers : depth0)) != null ? stack1.applicantLegalName : stack1), depth0))
    + "\n  </h1>\n</div>\n<div class=\"header-bar-container\"></div>\n<div class=\"breadcrumb-container\"></div>\n<div class=\"form-horizontal col-sm-9 col-sm-offset-1 correspondence-form-container\"></div>\n<div class=\"form-horizontal col-sm-9 col-sm-offset-1\">\n  <div class=\"row m-b-lg\">\n    <div class=\"col-sm-12\">\n      <div class=\"form-group\">\n        <div class=\"col-sm-9 col-sm-offset-3\">\n          <a href=\"#sec/investigation-inquiry/"
    + alias1(((helper = (helper = helpers.invInqId || (depth0 != null ? depth0.invInqId : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"invInqId","hash":{},"data":data}) : helper)))
    + "/dashboard\" class=\"btn btn-default\">Cancel</a>\n          <button class=\"btn btn-primary submit ladda-button\" data-style=\"expand-right\" type=\"button\">\n            <span class=\"ladda-label\">Submit</span>\n          </button>\n        </div>\n      </div>\n    </div>\n  </div>\n</div>";
},"useData":true});