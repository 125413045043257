/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var Marionette = require('backbone.marionette');
var Syphon = require('backbone.syphon');

var toggleForm = require('common/util/toggleForm');
var modelGet = require('common/util/modelGet');
var parsleyUtil = require('common/util/parsleyUtil');
var FileUploadView = require('common/views/FileUploadView');
var makeUploadCollection = require('common/util/makeUploadCollection');

require('./DebtSettlementServicesAnnualReportLicenseeActionsSectionView.css');
var tmplDebtSettlementServicesAnnualReportLicenseeActionsSectionView = require('./DebtSettlementServicesAnnualReportLicenseeActionsSectionView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplDebtSettlementServicesAnnualReportLicenseeActionsSectionView,

  className: 'debt-settlement-services-annual-report-licensee-actions-section-view',

  ui: {
    licenseeActionsForm: '.licensee-actions-form',
    revokedFileContainer: '.revoked-file-container',
    surrenderFileContainer: '.surrender-file-container',
    enforcementFileContainer: '.enforcement-file-container',
    lawsuitsFileContainer: '.lawsuits-file-container',
    underInvestigationFileContainer: '.under-investigation-file-container',
    lawsuitsInput: '.lawsuitsInput',
    hasLawsuitsFileUploadArea: '#hasLawsuitsFileUploadArea'
  },

  regions: {
    revokedFileContainer: '@ui.revokedFileContainer',
    surrenderFileContainer: '@ui.surrenderFileContainer',
    enforcementFileContainer: '@ui.enforcementFileContainer',
    lawsuitsFileContainer: '@ui.lawsuitsFileContainer',
    underInvestigationFileContainer: '@ui.underInvestigationFileContainer'
  },

  events: {
    'change @ui.lawsuitsInput': 'onChangeLawsuits'
  },

  behaviors: {
    RadioGroupToggleBehavior: {
      radios: [
        {
          target: '#isRevokedFileUploadArea',
          radioGroup: '[name="isRevoked"]',
          valueToShow: '1'
        },
        {
          target: '#hasSurrenderedFileUploadArea',
          radioGroup: '[name="hasSurrendered"]',
          valueToShow: '1'
        },
        {
          target: '#hasEnforcementFileUploadArea',
          radioGroup: '[name="hasEnforcement"]',
          valueToShow: '1'
        },
        {
          target: '#hasCriminalOfficerExplainationArea',
          radioGroup: '[name="hasCriminalOfficer"]',
          valueToShow: '1'
        },
        {
          target: '#isUnderInvestigationUploadArea',
          radioGroup: '[name="isUnderInvestigation"]',
          valueToShow: '1'
        }
      ]
    }
  },

  onBeforeShow: function() {
    var hasLawsuitsInitiated = modelGet(this.model, 'annualReport.hasLawsuitsInitiated') === '1';
    var hasLawsuitsContinued = modelGet(this.model, 'annualReport.hasLawsuitsContinued') === '1';
    var hasLawsuitsResolved = modelGet(this.model, 'annualReport.hasLawsuitsResolved') === '1';
    toggleForm(this.ui.hasLawsuitsFileUploadArea, hasLawsuitsInitiated || hasLawsuitsContinued || hasLawsuitsResolved);

    Syphon.deserialize(this, this.model.get('annualReport'));

    this.isRevokedDocument = new FileUploadView({
      collection: makeUploadCollection(modelGet(this.model, 'annualReport.isRevokedDocument')),
      isRequired: true,
      isReadOnly: this.options.isReadOnly,
      metadata: {
        documentTypeId: 10023
      }
    });
    this.showChildView('revokedFileContainer', this.isRevokedDocument);

    this.hasSurrenderedDocument = new FileUploadView({
      collection: makeUploadCollection(modelGet(this.model, 'annualReport.hasSurrenderedDocument')),
      isRequired: true,
      isReadOnly: this.options.isReadOnly,
      metadata: {
        documentTypeId: 10023
      }
    });
    this.showChildView('surrenderFileContainer', this.hasSurrenderedDocument);

    this.hasEnforcementDocument = new FileUploadView({
      collection: makeUploadCollection(modelGet(this.model, 'annualReport.hasEnforcementDocument')),
      isRequired: true,
      isReadOnly: this.options.isReadOnly,
      metadata: {
        documentTypeId: 10023
      }
    });
    this.showChildView('enforcementFileContainer', this.hasEnforcementDocument);

    this.hasLawsuitsDocument = new FileUploadView({
      collection: makeUploadCollection(modelGet(this.model, 'annualReport.hasLawsuitsDocument')),
      isRequired: true,
      isReadOnly: this.options.isReadOnly,
      metadata: {
        documentTypeId: 10023
      }
    });
    this.showChildView('lawsuitsFileContainer', this.hasLawsuitsDocument);

    this.isUnderInvestigationDocument = new FileUploadView({
      collection: makeUploadCollection(modelGet(this.model, 'annualReport.isUnderInvestigationDocument')),
      isRequired: true,
      isReadOnly: this.options.isReadOnly,
      metadata: {
        documentTypeId: 10023
      }
    });
    this.showChildView('underInvestigationFileContainer', this.isUnderInvestigationDocument);
  },

  onChangeLawsuits: function(e) {
    var formData = Syphon.serialize(this);
    toggleForm(
      this.ui.hasLawsuitsFileUploadArea,
      formData.hasLawsuitsInitiated === '1' ||
        formData.hasLawsuitsContinued === '1' ||
        formData.hasLawsuitsResolved === '1'
    );
  },

  validate: function(config) {
    var formData = Syphon.serialize(this);

    if (config.type === 'save') {
      parsleyUtil.disableRequiredValidation(this.ui.licenseeActionsForm);
    } else {
      parsleyUtil.enableRequiredValidation(this.ui.licenseeActionsForm);
    }

    var validationPromises = [this.ui.licenseeActionsForm.parsley().whenValidate()];
    if (formData.isRevoked === '1') {
      validationPromises.push(this.isRevokedDocument.validate(config));
    }
    if (formData.hasSurrendered === '1') {
      validationPromises.push(this.hasSurrenderedDocument.validate(config));
    }
    if (formData.hasEnforcement === '1') {
      validationPromises.push(this.hasEnforcementDocument.validate(config));
    }
    if (formData.isUnderInvestigation === '1') {
      validationPromises.push(this.isUnderInvestigationDocument.validate(config));
    }

    if (
      formData.hasLawsuitsInitiated === '1' ||
      formData.hasLawsuitsContinued === '1' ||
      formData.hasLawsuitsResolved === '1'
    ) {
      validationPromises.push(this.hasLawsuitsDocument.validate(config));
    }
    return $.when.apply($, validationPromises);
  },

  getFormData: function() {
    var formData = Syphon.serialize(this);
    var hasLawsuitsDocument =
      formData.hasLawsuitsInitiated === '1' ||
      formData.hasLawsuitsContinued === '1' ||
      formData.hasLawsuitsResolved === '1';
    formData = _.extend(formData, {
      isRevokedDocumentId: formData.isRevoked === '1' ? this.isRevokedDocument.getFirstFileId() : null,
      hasSurrenderedDocumentId: formData.hasSurrendered === '1' ? this.hasSurrenderedDocument.getFirstFileId() : null,
      hasEnforcementDocumentId: formData.hasEnforcement === '1' ? this.hasEnforcementDocument.getFirstFileId() : null,
      hasLawsuitsDocumentId: hasLawsuitsDocument ? this.hasLawsuitsDocument.getFirstFileId() : null,
      isUnderInvestigationDocumentId:
        formData.isUnderInvestigation === '1' ? this.isUnderInvestigationDocument.getFirstFileId() : null
    });
    return formData;
  }
});
