/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var Marionette = require('backbone.marionette');

var Syphon = require('backbone.syphon');

var FileUploadCollection = require('psa-backbone/models/FileUploadCollection');

var modelGet = require('common/util/modelGet');

var FileUploadView = require('common/views/FileUploadView');

var tmplDepFilingSupportingDocumentsSectionView = require('../templates/DepFilingSupportingDocumentsSectionView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplDepFilingSupportingDocumentsSectionView,

  className: 'dep-filing-supporting-documents-section-view',

  behaviors: {
    ReadOnlyFormBehavior: {}
  },

  ui: {
    missingFile: '.missing-file',
    supportingDocumentsContainer: '.supporting-documents-container',
    supportingDocDescriptionField: '.supporting-doc-description-field'
  },

  regions: {
    supportingDocumentsContainer: '@ui.supportingDocumentsContainer'
  },

  templateHelpers: function() {
    return {
      isReadOnly: this.options.isReadOnly,
      hasInstructions: this.options.hasInstructions,
      isTrust: modelGet(this.model, 'filingDetails.entityCategoryId') === '3'
    };
  },

  onBeforeShow: function() {
    var filingContent = this.model.get('filingContent') || {};
    var supportingDocuments = _.pluck(modelGet(this.model, 'filingContent.supportingDocuments', []), 'file');
    this.supportingDocuments = new FileUploadView({
      isReadOnly: this.options.isReadOnly,
      allowMultipleFiles: true,
      isRequired: false,
      collection: new FileUploadCollection(supportingDocuments),
      metadata: {
        documentTypeId: 20001
      }
    });
    this.showChildView('supportingDocumentsContainer', this.supportingDocuments);
    Syphon.deserialize(this, filingContent);
  },

  validate: function(config) {
    return this.supportingDocuments.validate(config);
  },

  getFormData: function() {
    var descriptionField = Syphon.serialize(this);
    var formData = this.supportingDocuments.collection.map(function(file) {
      return {
        fileId: file.get('fileId'),
        documentTypeId: modelGet(file, 'metadata.documentTypeId')
      };
    });
    formData.descriptionField = descriptionField;
    return formData;
  }
});
