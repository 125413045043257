var Handlebars = require("handlebars/dist/handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "              <option value=\""
    + alias4(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\">"
    + alias4(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data}) : helper)))
    + "</option>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "              <option value=\""
    + alias4(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\">"
    + alias4(((helper = (helper = helpers.status || (depth0 != null ? depth0.status : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"status","hash":{},"data":data}) : helper)))
    + "</option>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "\n<div class=\"col-sm-12 col-md-10 col-md-offset-1\">\n  <div class=\"form-horizontal\">\n    <div class=\"row\">\n      <div class=\"col-md-6 col-sm-12\">\n        <div class=\"form-group required\">\n          <label class=\"control-label col-md-4 col-sm-3\">Provision Number</label>\n          <div class=\"col-md-8 col-sm-9\">\n            <input type=\"text\" name=\"provisionNumber\" class=\"form-control\" placeholder=\"Provision Number\"\n              data-parsley-maxlength=\"50\" data-parsley-required=\"true\" />\n          </div>\n        </div>\n      </div>\n      <div class=\"col-md-6 col-sm-12\">\n        <div class=\"form-group\">\n          <label class=\"control-label col-md-4 col-sm-3\">Category</label>\n          <div class=\"col-md-8 col-sm-9\">\n            <select name=\"categoryId\" class=\"form-control\">\n              <option value=\"\">--</option>\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.provisionCategories : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "            </select>\n          </div>\n        </div>\n      </div>\n    </div>\n    <div class=\"row\">\n      <div class=\"col-md-6 col-sm-12\">\n        <div class=\"form-group\">\n          <label class=\"control-label col-md-4 col-sm-3\">Response Due Date</label>\n          <div class=\"col-md-8 col-sm-9\">\n            <div class=\"input-group\">\n              <input type=\"text\" name=\"responseDueDate\" class=\"form-control datepicker response-due-date\"\n                placeholder=\"Response Due Date\" data-parsley-date=\"\"\n                data-parsley-trigger-after-failure=\"changeDate\" data-parsley-errors-container=\"#responseDueDateError\" />\n              <div class=\"input-group-addon\">\n                <i class=\"fa fa-calendar\"></i>\n              </div>\n            </div>\n            <div id=\"responseDueDateError\"></div>\n          </div>\n        </div>\n      </div>\n      <div class=\"col-md-6 col-sm-12\">\n        <div class=\"form-group\">\n          <label class=\"control-label col-md-4 col-sm-3\">Status</label>\n          <div class=\"col-md-8 col-sm-9\">\n            <select name=\"statusId\" class=\"form-control\">\n              <option value=\"\">--</option>\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.provisionStatuses : depth0),{"name":"each","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "            </select>\n          </div>\n        </div>\n      </div>\n    </div>\n    <div class=\"form-group\">\n      <label class=\"control-label col-md-2 col-sm-3\">Ongoing</label>\n      <div class=\"col-md-10 col-sm-9\">\n        <label class=\"radio-inline\">\n          <input type=\"radio\" name=\"isOngoing\" value=\"1\"> Yes\n        </label>\n        <label class=\"radio-inline\">\n          <input type=\"radio\" name=\"isOngoing\" value=\"0\"> No\n        </label>\n      </div>\n    </div>\n    <div class=\"form-group\">\n      <label class=\"control-label col-md-2 col-sm-3\">Provisions</label>\n      <div class=\"col-md-10 col-sm-9\">\n        <textarea name=\"provisions\" class=\"form-control\" placeholder=\"Provisions\" rows=\"6\"></textarea>\n      </div>\n    </div>\n    <div class=\"form-group\">\n      <label class=\"control-label col-md-2 col-sm-3\">Comments</label>\n      <div class=\"col-md-10 col-sm-9\">\n        <textarea name=\"comments\" class=\"form-control\" placeholder=\"Comments\" rows=\"6\"></textarea>\n      </div>\n    </div>\n  </div>\n</div>";
},"useData":true});