/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2020] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Backbone = require('backbone');
var Marionette = require('backbone.marionette');
var Syphon = require('backbone.syphon');
var Radio = require('backbone.radio');
var Ladda = require('ladda');

var UIUtil = require('psa-core/util/ui');

var BreadcrumbView = require('common/views/BreadcrumbView');
var ConfirmationModalView = require('common/views/ConfirmationModalView');

var GetIndividualCRDNumberExists = require('../../individual-dashboard/services/GetIndividualCRDNumberExists');
var SecInternalSidebarNavView = require('../../internal-dashboard/views/SecInternalSidebarNavView');
var SubmitIndividual = require('../services/SubmitIndividual');
var SecIndividualGeneralFormView = require('./SecIndividualGeneralFormView');

require('../styles/SecIndividualNewView.css');
var tmplSecIndividualNewView = require('../templates/SecIndividualNewView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplSecIndividualNewView,

  className: 'sec-individual-new-view container-fluid',

  ui: {
    sideBar: '.side-bar-container',
    breadCrumbContainer: '.breadcrumb-container',
    individualGeneralForm: '.individual-general-form',
    submit: '.submit'
  },

  regions: {
    breadCrumbContainer: '@ui.breadCrumbContainer',
    sideBar: '@ui.sideBar',
    individualGeneralForm: '@ui.individualGeneralForm'
  },

  events: {
    'click @ui.submit': 'onClickSubmit'
  },

  onBeforeShow: function() {
    this.showChildView('sideBar', new SecInternalSidebarNavView());
    this.showChildView(
      'breadCrumbContainer',
      new BreadcrumbView({
        breadcrumbs: [
          {
            text: 'Home',
            link: '#sec/dashboard'
          },
          {
            text: 'Create A New Individual'
          }
        ]
      })
    );

    this.showChildView('individualGeneralForm', new SecIndividualGeneralFormView({ model: new Backbone.Model() }));
  },

  onClickSubmit: function(e) {
    e.preventDefault();
    var self = this;
    var formData = this.getFormData();
    this.validate().done(function() {
      var spinner = Ladda.create(self.ui.submit.get(0));
      spinner.start();

      GetIndividualCRDNumberExists(formData)
        .done(function(individualCRDNumberExists) {
          if (individualCRDNumberExists) {
            spinner.stop();
            self.showDuplicateCRDConfirmationModal();
          } else {
            if (!formData.otherIdNumber) {
              formData.otherIdNumber = null;
            }
            SubmitIndividual(formData)
              .done(function(newIndividualId) {
                Radio.channel('navigate').trigger('show:int:sc:individual-dashboard', newIndividualId);
              })
              .fail(function() {
                spinner.stop();
              });
          }
        })
        .fail(function() {
          spinner.stop();
        });
    });
  },

  validate: function() {
    return this.ui.individualGeneralForm.parsley().whenValidate();
  },

  getFormData: function() {
    var formData = Syphon.serialize(this);
    return formData;
  },

  showDuplicateCRDConfirmationModal: function() {
    var formData = this.getFormData();
    var crdNumber = formData.crdNumber;
    var name = formData.firstName + ' ' + formData.lastName;

    return UIUtil.showModal({
      title: 'Duplicate CRD Number ' + crdNumber,
      view: new ConfirmationModalView({
        confirmButtonClass: 'collapse',
        rejectText: 'Close',
        model: new Backbone.Model({
          message:
            'Another individual already exists with CRD Number ' +
            crdNumber +
            ' and applicant name ' +
            name +
            '. Please ensure that the individual you are trying to create does not already exist before proceeding.'
        })
      })
    });
  }
});

