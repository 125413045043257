/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var cleanUpResponse = require('common/util/cleanUpResponse');

module.exports = function(historySnapshotId) {
  var defer = $.Deferred();

  $.soap({
    body: {
      GetLicenseContactByHistorySnapshot: {
        $xmlns: 'http://dobs.pa.gov/nd/license/bpm/1.0',
        HistorySnapshotID: historySnapshotId
      }
    },
    blueprintArrays: [
      'GetLicenseContactByHistorySnapshotResponse.LicenseContactTypes.ND_LU_LicenseContactType',
      'GetLicenseContactByHistorySnapshotResponse.LicenseContacts.ND_LicenseContact'
    ],
    blueprint: {
      licenseContactTypes: [
        '$..ND_LU_LicenseContactType',
        {
          id: '$..ID',
          name: '$..Name'
        }
      ],
      licenseContacts: [
        '$..ND_LicenseContact',
        {
          id: '$..ID',
          typeId: '$..LicenseContactTypeID',
          type: '$..LicenseContactType',
          firstName: '$..FirstName',
          lastName: '$..LastName',
          email: '$..Email',
          homePhoneNumber: '$..HomePhoneNumber',
          cellPhoneNumber: '$..CellPhoneNumber',
          workPhoneNumber: '$..WorkPhoneNumber',
          workPhoneExtension: '$..WorkPhoneExtension',
          fax: '$..Fax',
          company: '$..Company',
          title: '$..Title',
          address: {
            addressLine1: '$..AddressLine1',
            addressLine2: '$..AddressLine2',
            addressLine3: '$..AddressLine3',
            city: '$..City',
            state: '$..State',
            postalCode: '$..PostalCode',
            country: '$..Country'
          }
        }
      ]
    }
  })
    .done(function(response) {
      cleanUpResponse(response);

      _.each(response.licenseContacts, function(contact) {
        if (contact.workPhoneNumber && contact.workPhoneExtension) {
          contact.workPhoneNumber = contact.workPhoneNumber + ' (Ext: ' + contact.workP1oneExtension + ')';
        }
      });

      response.licenseContacts = _.map(response.licenseContactTypes, function(contactType) {
        return _.extend(contactType, { contacts: _.where(response.licenseContacts, { typeId: contactType.id }) });
      });

      response.licenseContacts = _.sortBy(response.licenseContacts, 'name');

      defer.resolve(response.licenseContacts);
    })
    .fail(function(err) {
      defer.reject();
    });

  return defer;
};
