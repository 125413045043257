/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Marionette = require('backbone.marionette');

var FileUploadView = require('common/views/FileUploadView');
var FileUploadCollection = require('psa-backbone/models/FileUploadCollection');

var modelGet = require('common/util/modelGet');

var tmplConsumerDiscountFinancialDocumentsSectionView = require('./ConsumerDiscountFinancialDocumentsSectionView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplConsumerDiscountFinancialDocumentsSectionView,

  className: 'consumer-discount-financial-documents-section-view',

  ui: {
    financialDocumentsForm: '.consumer-discount-financial-documents-section-view',
    fileUploadContainer: '.file-upload-container',
    missingFile: '.missing-file'
  },

  regions: {
    fileUploadContainer: '@ui.fileUploadContainer'
  },

  onBeforeShow: function() {
    var statements = modelGet(this.model, 'financialDocuments', []);

    this.financialStatements = new FileUploadView({
      isReadOnly: this.options.isReadOnly,
      allowMultipleFiles: true,
      collection: new FileUploadCollection(statements),
      metadata: {
        documentTypeId: 10012
      }
    });

    this.showChildView('fileUploadContainer', this.financialStatements);
  },

  validate: function(config) {
    return $.when(this.financialStatements.validate(), this.validateFileCount(config));
  },

  validateFileCount: function(config) {
    var defer = $.Deferred();
    this.ui.missingFile.hide();

    if (config.type === 'submit') {
      if (this.financialStatements.collection.length < 1) {
        this.ui.missingFile.show();
        defer.reject();
      } else {
        defer.resolve();
      }
    } else {
      defer.resolve();
    }

    return defer.promise();
  },

  getFormData: function() {
    return this.financialStatements.collection.map(function(statement) {
      return {
        fileId: statement.get('fileId'),
        documentTypeId: modelGet(statement, 'metadata.documentTypeId')
      };
    });
  }
});
