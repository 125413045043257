/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Backbone = require('backbone');

var GetLicenseDBAEditApplication = require('../services/GetLicenseDBAEditApplication');
var GetEntityDetails = require('../../entity-form/services/GetEntityDetails');
var LicenseDBAEditApplication = require('../forms/LicenseDBAEditApplication');

module.exports = {
  event: 'build:amendment:license-dba-edit',

  channel: 'nd:application',

  builder: function(config) {
    var defer = $.Deferred();

    GetLicenseDBAEditApplication(config.applicationId)
      .done(function(appData) {
        $.when(GetEntityDetails(appData.entityId)).done(function(entity) {
          // var data = _.extend(appData, {
          //   entity: entity
          // });
          defer.resolve(
            new LicenseDBAEditApplication({
              isReadOnly: config.isReadOnly,
              model: new Backbone.Model({
                isCertified: appData.isCertified,
                certificationName: appData.certificationName,
                entityId: appData.entityId,
                licenseDBAs: appData.licenseDBAs,
                applicationId: appData.applicationId,
                effectiveChangeDate: appData.effectiveChangeDate,
                applicationDocuments: appData.applicationDocuments,
                entitydbas: entity.dbas
              }),
              viewMode: config.viewMode,
              mode: config.mode
            })
          );
        });
      })
      .fail(function(err) {
        defer.reject(err);
      });

    return defer.promise();
  }
};
