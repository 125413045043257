/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var GridView = require('psa-marionette/views/GridView');

module.exports = GridView.extend({
  className: 'dep-ext-entity-location-section-view',

  options: {
    columns: [
      {
        name: 'addressLine1',
        label: 'Address 1',
        cell: 'string',
        editable: false,
        headerCell: 'custom',
        width: 20
      },
      {
        name: 'city',
        label: 'City',
        cell: 'string',
        editable: false,
        headerCell: 'custom',
        width: 20
      },
      {
        name: 'state',
        label: 'State',
        cell: 'string',
        editable: false,
        headerCell: 'custom',
        width: 10
      },
      {
        name: 'postalCode',
        label: 'Zip',
        cell: 'string',
        editable: false,
        headerCell: 'custom',
        width: 10
      },
      {
        name: 'officeType',
        label: 'Office Type',
        cell: 'string',
        editable: false,
        headerCell: 'custom',
        width: 20
      },
      {
        name: 'county',
        label: 'County',
        cell: 'string',
        editable: false,
        headerCell: 'custom',
        width: 10
      }
    ],
    emptyText: 'No Locations'
  }
});
