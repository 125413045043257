/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */
var _ = require('underscore');
var Marionette = require('backbone.marionette');
var Syphon = require('backbone.syphon');

var parsleyUtil = require('common/util/parsleyUtil');
var FileUploadView = require('common/views/FileUploadView');

var FileUploadCollection = require('psa-backbone/models/FileUploadCollection');

var ConsumerDiscountOtherBusinessesCollectionView = require('./ConsumerDiscountOtherBusinessesCollectionView');

var tmplConsumerDiscountGeneralDetailsSectionView = require('./ConsumerDiscountGeneralDetailsSectionView.hbs');
require('./ConsumerDiscountGeneralDetailsSectionView.css');

module.exports = Marionette.LayoutView.extend({
  template: tmplConsumerDiscountGeneralDetailsSectionView,

  className: 'consumer-discount-general-details-section-view',

  ui: {
    generalDetailsForm: '.general-details-form',
    addBusiness: '.add-business',
    otherBusinessesCollectionContainer: '.other-businesses-collection-container',
    removeBusiness: 'remove-business',
    applicationDocumentsFileContainer: '.application-documents-container',
    bondFileContainer: '.bond-file-container'
  },

  triggers: {
    'click @ui.removeBusiness': 'remove:business'
  },

  events: {
    'click @ui.addBusiness': 'onClickAddBusiness'
  },

  childEvents: {
    'remove:business': 'onChildRemoveBusiness'
  },

  regions: {
    otherBusinessesCollectionContainer: '@ui.otherBusinessesCollectionContainer',
    applicationDocumentsFileContainer: '@ui.applicationDocumentsFileContainer',
    bondFileContainer: '.bond-file-container'
  },

  behaviors: {
    RadioGroupToggleBehavior: {
      radios: [
        {
          target: '#hasCriminalOfficerExplanationArea',
          radioGroup: '[name="hasCriminalOfficer"]',
          valueToShow: '1'
        },
        {
          target: '#OfficerAppRefusedExplanationArea',
          radioGroup: '[name="officerAppRefused"]',
          valueToShow: '1'
        },
        {
          target: '#addOtherBusinesses',
          radioGroup: '[name="hasOtherBusinesses"]',
          valueToShow: '1'
        },
        {
          target: '#hasMoreLicensesExplanationArea',
          radioGroup: '[name="hasMoreLicenses"]',
          valueToShow: '1'
        }
      ]
    }
  },

  templateHelpers: function() {
    return {
      identoGoLink: this.options.identoGoLink,
      isBranch: this.options.isBranch
    };
  },

  onBeforeShow: function() {
    var application = this.model.get('application');
    Syphon.deserialize(this, application);

    this.otherBusinesses = new ConsumerDiscountOtherBusinessesCollectionView({
      collection: new Backbone.Collection(this.model.get('otherBusinesses'))
    });
    this.showChildView('otherBusinessesCollectionContainer', this.otherBusinesses);

    this.applicationDocumentsFile = new FileUploadView({
      isRequired: true,
      allowMultipleFiles: true,
      collection: new FileUploadCollection(application.applicationDocuments),
      isReadOnly: this.options.isReadOnly,
      metadata: {
        documentTypeId: 10023
      }
    });
    this.showChildView('applicationDocumentsFileContainer', this.applicationDocumentsFile);

    if (application.bondDocument.fileId === null || typeof application.bondDocument.fileId === 'undefined') {
      application.bondDocument = null;
    }

    this.bondFile = new FileUploadView({
      isRequired: true,
      collection: new FileUploadCollection(application.bondDocument),
      isReadOnly: this.options.isReadOnly,
      metadata: {
        documentTypeId: 10019
      }
    });
    this.showChildView('bondFileContainer', this.bondFile);
  },

  validate: function(config) {
    this.ui.generalDetailsForm.parsley().reset();
    var validationPromises = [];
    if (config.type === 'save') {
      // Turn off required field validation for this form
      parsleyUtil.disableRequiredValidation(this.ui.generalDetailsForm);
      validationPromises.push(this.ui.generalDetailsForm.parsley().whenValidate());
    } else {
      // Turn on required field validation for this form
      parsleyUtil.enableRequiredValidation(this.ui.generalDetailsForm);
      validationPromises.push(this.ui.generalDetailsForm.parsley().whenValidate());
      validationPromises.push(this.bondFile.validate());
    }
    return $.when.apply($, validationPromises);
  },

  onClickAddBusiness: function(e) {
    e.preventDefault();
    this.otherBusinesses.collection.add({});
  },

  onChildRemoveBusiness: function(childView) {
    this.otherBusinesses.collection.remove(childView.model);
  },

  getFormData: function() {
    var formData = Syphon.serialize(this);

    _.extend(formData, {
      bondDocumentId: this.bondFile.getFirstFileId(),
      applicationDocuments: this.applicationDocumentsFile.collection.pluck('fileId')
    });

    if (formData.hasOtherBusinesses) {
      var otherBusinesses = this.otherBusinesses.getFormData();

      formData = _.extend(formData, {
        otherBusinesses: otherBusinesses
      });
    }

    return formData;
  }
});
