/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var moment = require('moment');

var cleanUpResponse = require('common/util/cleanUpResponse');

module.exports = function(entityId) {
  var deferred = $.Deferred();

  $.soap({
    body: {
      GetEntity: {
        $xmlns: 'http://dobs.pa.gov/nd/entity/bpm/1.0',
        EntityID: entityId
      }
    },
    blueprintArrays: ['GetEntityResponse.DBAs.ND_EntityDBA'],
    blueprint: {
      id: '$..ID',
      CSFolderId: '$..CSFolderID',
      tin: '$..TIN',
      tinTypeId: '$..TINTypeID',
      appCount: '$..AppCount',
      entityStatusId: '$..EntityStatusID',
      nmlsID: '$..NMLSID',
      entityName: '$..EntityName',
      isVerified: '$..IsVerified',
      isForeignEntity: '$..isForeignEntity',
      entityOrganizationTypeId: '$..OrganizationTypeID',
      entityOrganizationType: '$..OrganizationTypeName',
      articlesOfIncorporationDocument: {
        fileId: '$..ArticlesOfIncorporationDocumentID',
        fileName: '$..ArticlesOfIncorporationDocumentName'
      },
      foreignNameRegistrationDocument: {
        fileId: '$..ForeignRegistrationStatementDocumentID',
        fileName: '$..ForeignRegistrationStatementDocumentName'
      },
      operatingAgreementDocument: {
        fileId: '$..OperatingAgreementDocumentID',
        fileName: '$..OperatingAgreementDocumentName'
      },
      byLawsDocument: {
        fileId: '$..ByLawsDocumentID',
        fileName: '$..ByLawsDocumentName'
      },
      webAddress: '$..WebAddress',
      listWebsiteBusiness: '$..ListWebsiteBusiness',
      entityName2: '$..EntityName2',
      entityNameForced: '$..EntityNameForced',
      phoneNumber: '$..PhoneNumber',
      phoneNumberExt: '$..PhoneNumberExt',
      secondaryPhoneNumber: '$..SecondaryPhoneNumber',
      secondaryPhoneNumberExt: '$..SecondaryPhoneNumberExt',
      faxNumber: '$..FaxNumber',
      faxNumberExt: '$..FaxNumberExt',
      incorporationDate: '$..IncorporationDate',
      incorporationPlace: '$..IncorporationPlace',
      documentExceptionLegalOpinion: '$..DocumentExceptionLegalOpinion',
      agentOfService: {
        fullName: '$..AgentOfServiceFullName',
        address: {
          id: '$..AgentOfServiceAddressID',
          addressLine1: '$..AddressLine1',
          addressLine2: '$..AddressLine2',
          addressLine3: '$..AddressLine3',
          city: '$..City',
          state: '$..State',
          postalCode: '$..PostalCode',
          country: '$..Country'
        },
        phoneNumber: '$..AgentOfServicePhoneNumber'
      },
      organizationType: '$..OrganizationTypeName',
      isOfficerConvicted: '$..IsOfficerConvicted',
      isOfficerConvictedDocument: {
        fileId: '$..IsOfficerConvictedDocumentID',
        fileName: '$..IsOfficerConvictedDocumentName'
      },
      dbas: [
        '$..ND_EntityDBA',
        {
          id: '$.ID',
          dba: '$.DBA',
          fictitiousNameDocument: {
            fileId: '$.FictitiousDocumentID',
            fileName: '$.FictitiousDocumentName'
          },
          registrationDate: '$..RegistrationDate',
          endDate: '$..EndDate'
        }
      ],
      legalHoldStatusComments: '$..LegalHoldStatusComments'
    }
  })
    .done(function(response) {
      cleanUpResponse(response);

      response.incorporationDate = response.incorporationDate
        ? moment(response.incorporationDate, moment.ISO_8601).format('MM/DD/YYYY')
        : '--';

      response.entityName2 = response.entityName2 || '--';
      response.entityNameForced = response.entityNameForced || '--';
      response.phoneNumber = response.phoneNumber || '--';
      response.phoneNumberExt = response.phoneNumberExt || '--';
      response.secondaryPhoneNumber = response.secondaryPhoneNumber || '--';
      response.secondaryPhoneNumberExt = response.secondaryPhoneNumberExt || '--';
      response.faxNumber = response.faxNumber || '--';
      response.incorporationPlace = response.incorporationPlace || '--';

      response.isForeignEntity = response.isForeignEntity === '1';
      response.isVerified = response.isVerified === '1';
      response.isNMLSEntity = !!response.nmlsID;

      if (response.isOfficerConvicted === '0') {
        response.isOfficerConvictedDisplay = 'No';
      } else if (response.isOfficerConvicted === '1') {
        response.isOfficerConvictedDisplay = 'Yes';
      }

      _.each(response.dbas, function(dba) {
        if (dba.registrationDate) {
          dba.registrationDate = moment(dba.registrationDate, 'YYYY-MM-DD');
        }
        if (dba.endDate) {
          dba.endDate = moment(dba.endDate, 'YYYY-MM-DD');
        }
      });

      deferred.resolve(response);
    })
    .fail(function(err) {
      deferred.reject(err);
    });

  return deferred.promise();
};
