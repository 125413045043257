var Handlebars = require("handlebars/dist/handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression;

  return "\n<nav class=\"navbar navbar-default\">\n  <div class=\"container-fluid\">\n    <div class=\"navbar-header\">\n      <button type=\"button\" class=\"navbar-toggle collapsed\" data-toggle=\"collapse\" data-target=\"#bs-example-navbar-collapse-1\" aria-expanded=\"false\">\n        <span class=\"sr-only\">Toggle navigation</span>\n        <span class=\"icon-bar\"></span>\n        <span class=\"icon-bar\"></span>\n        <span class=\"icon-bar\"></span>\n      </button>\n      <a href=\""
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.brand : depth0)) != null ? stack1.link : stack1), depth0))
    + "\" class=\"logo-text brand-link\">\n        <img class=\"logo\" src=\"img/logo.png\" alt=\"logo\" />\n        <p class=\"navbar-text navbar-brand\">"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.brand : depth0)) != null ? stack1.text : stack1), depth0))
    + "</p>\n      </a>\n    </div>\n    <div class=\"collapse navbar-collapse\" id=\"int-main-menu\">\n      <ul class=\"nav navbar-nav navbar\">\n        <li><a href=\"#int/applications\">Program Selection</a></li>\n        <li class=\"time-tracking-link-item\">\n          <a href=\"#\">\n            Time Tracking\n          </a>\n        </li>\n      </ul>\n      <ul class=\"nav navbar-nav navbar-right\">\n        <li><p class=\"navbar-text welcome-text\">Welcome, "
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.user : depth0)) != null ? stack1.description : stack1), depth0))
    + "</p></li>\n        <li><a href=\"#\" class=\"logout\">Logout</a></li>\n      </ul>\n    </div>\n  </div>\n</nav>\n";
},"useData":true});