var Handlebars = require("handlebars/dist/handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.escapeExpression;

  return "\n<div class=\"page-title clearfix\">\n  <h1 class=\"pull-left m-n p-n\">"
    + alias1(container.lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.task : depth0)) != null ? stack1.taskType : stack1)) != null ? stack1.description : stack1), depth0))
    + "</h1>\n</div>\n<div class=\"header-bar\"></div>\n<div class=\"breadcrumb-container\"></div>\n<div class=\"task-status\"></div>\n<div class=\"form-horizontal\">\n  <form class=\"examination-closing-form\">\n    <div class=\"form-group\">\n      <label class=\"control-label col-sm-3\">Link to Exam Dashboard</label>\n      <div class=\"col-sm-9\">\n        <a href=\"#sec/exam/"
    + alias1(((helper = (helper = helpers.examId || (depth0 != null ? depth0.examId : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"examId","hash":{},"data":data}) : helper)))
    + "/dashboard\" target=\"_blank\" class=\"btn btn-primary\">Open Exam\n          Dashboard</a>\n      </div>\n    </div>\n    <div class=\"form-group\">\n      <label class=\"control-label col-sm-3\">Internal Comments</label>\n      <div class=\"col-sm-6\">\n        <textarea class=\"form-control\" rows=\"5\" name=\"internalComments\" placeholder=\"Internal Comments\"></textarea>\n      </div>\n    </div>\n    <div class=\"form-group\">\n      <label class=\"control-label col-sm-3 \">Send Notification?</label>\n      <div class=\"col-sm-9\">\n        <label class=\"radio-inline form-label\">\n          <input type=\"radio\" name=\"isSendNotification\" value=\"1\" />\n          Yes\n        </label>\n        <label class=\"radio-inline form-label\">\n          <input type=\"radio\" name=\"isSendNotification\" value=\"0\" />\n          No\n        </label>\n      </div>\n    </div>\n  </form>\n  <div class=\"form-group\">\n    <div class=\"col-sm-9 col-sm-offset-3\">\n      <a href=\"#sec/dashboard\" class=\"btn btn-default\" role=\"button\">Cancel</a>\n      <button type=\"button\" class=\"btn btn-primary submit\">Submit</button>\n      <div class=\"task-actions\"></div>\n    </div>\n  </div>\n</div>";
},"useData":true});