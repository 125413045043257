/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var moment = require('moment');

var cleanUpResponse = require('common/util/cleanUpResponse');

module.exports = function(id) {
  var deferred = $.Deferred();

  $.soap({
    body: {
      GetProvision: {
        $xmlns: 'http://dobs.pa.gov/dp/enforcement/bpm/1.0',
        ID: id
      }
    },
    blueprint: {
      id: '$..ID',
      enforcementActionId: '$..EnforcementActionID',
      entityId: '$..EntityID',
      provisionNumber: '$..ProvisionNumber',
      categoryId: '$..CategoryID',
      responseDueDate: '$..ResponseDueDate',
      isOngoing: '$..IsOngoing',
      categoryName: '$..Name',
      statusId: '$..StatusID',
      status: '$..Status',
      comments: '$..Comments',
      provisions: '$..Provisions',
      isDeleted: '$..IsDeleted'
    }
  })
    .done(function(response) {
      cleanUpResponse(response);
      if (response.responseDueDate) {
        response.responseDueDate = moment(response.responseDueDate, 'YYYY-MM-DD').format('MM/DD/YYYY');
      }
      deferred.resolve(response);
    })
    .fail(function(err) {
      deferred.reject();
    });

  return deferred.promise();
};
