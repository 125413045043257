/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Backbone = require('backbone');
var NProgress = require('nprogress');

var NavUtils = require('psa-core/util/nav');
var Session = require('psa-core/Session');

var Scaffold = require('common/layout/Scaffold');
var GetAllEntityLookups = require('../../common/services/GetAllEntityLookups');

var DepEntitySearchView = require('../views/DepEntitySearchView');

module.exports = function() {
  NProgress.start();
  NProgress.set(0.6);

  GetAllEntityLookups()
    .done(function(lookups) {
      var searchLimit = Session.config.CM_ELASTICSEARCH_MAX_RESULTS || 1000;
      Scaffold.content.show(
        new DepEntitySearchView({
          model: new Backbone.Model({
            searchLimit: searchLimit,
            charterTypes: lookups.charterTypes,
            corporateStructures: lookups.corporateStructures,
            federalRegulators: lookups.federalRegulators,
            ownershipStructures: lookups.ownershipStructures,
            statuses: lookups.statuses,
            entityTypes: lookups.entityTypes
          })
        })
      );
      NavUtils.navigate('dep/dashboard/entity-search');
      NProgress.done();
    })
    .fail(function() {
      NProgress.done();
    });
};
