/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Marionette = require('backbone.marionette');
var Ladda = require('ladda');
var Radio = require('backbone.radio');

var modelGet = require('common/util/modelGet');

var BreadcrumbView = require('common/views/BreadcrumbView');
var DepSubsidiaryFormView = require('./DepSubsidiaryFormView');

var SubmitSubsidiary = require('../services/SubmitSubsidiary');

var tmplDepSubsidiaryEditView = require('../templates/DepSubsidiaryEditView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplDepSubsidiaryEditView,

  className: 'dep-subsidiary-edit-view container-fluid',

  ui: {
    subsidiaryForm: '.subsidiary-form-container',
    breadcrumb: '.breadcrumb-container',
    submit: '.btn-submit'
  },

  regions: {
    subsidiaryForm: '@ui.subsidiaryForm',
    breadcrumb: '@ui.breadcrumb'
  },

  events: {
    'click @ui.submit': 'onClickSubmit'
  },

  templateHelpers: function() {
    return {
      isCreditUnion: modelGet(this.model, 'entityIdentifiers.entityTypeId') === '11'
    };
  },

  onBeforeShow: function() {
    var subsidiary = this.model.get('subsidiary') || {};
    var subsidiaryId = this.model.get('subsidiaryId');
    var entityId = this.model.get('entityId');
    var isCreditUnion = modelGet(this.model, 'entityIdentifiers.entityTypeId') === '11';

    this.showChildView(
      'breadcrumb',
      new BreadcrumbView({
        breadcrumbs: [
          {
            link: '#dep/dashboard',
            text: 'Home'
          },
          {
            link: '#dep/entity/' + entityId + '/dashboard',
            text: this.model.get('entityIdentifiers').entityName
          },
          {
            link: '#dep/entity/' + entityId + '/subsidiary/' + subsidiaryId + '/dashboard',
            text: subsidiary.name + ' - ' + subsidiary.status
          },
          {
            text: isCreditUnion ? 'Edit CUSO' : 'Edit Subsidiary'
          }
        ]
      })
    );

    this.subsidiaryForm = new DepSubsidiaryFormView({
      model: this.model
    });

    this.showChildView('subsidiaryForm', this.subsidiaryForm);
  },

  onClickSubmit: function(e) {
    e.preventDefault();
    var subsidiaryId = this.model.get('subsidiaryId');

    this.validate().done(
      function() {
        var laddaContext = Ladda.create(this.ui.submit.get(0));
        laddaContext.start();
        var formData = this.getFormData();
        formData.isDeleted = 0;
        SubmitSubsidiary(formData)
          .done(function() {
            Radio.channel('navigate').trigger('show:int:dp:entity:subsidiary', formData.entityId, subsidiaryId);
          })
          .fail(function() {
            laddaContext.stop();
          });
      }.bind(this)
    );
  },

  validate: function() {
    return this.ui.subsidiaryForm.parsley().whenValidate();
  },

  getFormData: function() {
    var formData = this.subsidiaryForm.getFormData();
    formData.subsidiaryId = this.model.get('subsidiaryId');
    return formData;
  }
});
