/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Backbone = require('backbone');
var Marionette = require('backbone.marionette');
var Radio = require('backbone.radio');

var UIUtil = require('psa-core/util/ui');

var ContentServerLinkView = require('common/views/ContentServerLinkView');
var SideNavSectionView = require('common/views/SideNavSectionView');
var BreadcrumbView = require('common/views/BreadcrumbView');
var DropdownView = require('common/views/DropdownView');
var ConfirmationModalView = require('common/views/ConfirmationModalView');

var SubmitIrregularity = require('../services/SubmitIrregularity');
var DepIrregularityDetailsSectionView = require('./DepIrregularityDetailsSectionView');

var tmplDepIrregularityDashboardView = require('../templates/DepIrregularityDashboardView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplDepIrregularityDashboardView,

  className: 'dep-irregularity-dashboard-view container-fluid',

  ui: {
    irregularityWrapper: '.irregularity-wrapper',
    breadcrumb: '.breadcrumb-container',
    csLinkContainer: '.cs-link-container',
    entityActionDropDown: '.dropdown-container'
  },

  regions: {
    irregularityWrapper: '@ui.irregularityWrapper',
    breadcrumb: '@ui.breadcrumb',
    csLinkContainer: '@ui.csLinkContainer',
    entityActionDropDown: '@ui.entityActionDropDown'
  },

  onBeforeShow: function() {
    var irregularity = this.model.get('irregularity') || {};
    var irregularityId = this.model.get('irregularityId');
    var irregularitiesCSFolderId = irregularity.csFolderId;
    var entityName = this.model.get('entityIdentifiers').entityName;
    var entityId = this.model.get('entityId');
    var entityCategoryId = this.model.get('entityIdentifiers').categoryId;
    var entityTypeId = this.model.get('entityIdentifiers').entityTypeId;
    var isLegalHold = this.model.get('entityIdentifiers').hasLegalHold;
    isLegalHold = isLegalHold === '1';

    this.showChildView(
      'csLinkContainer',
      new ContentServerLinkView({
        model: new Backbone.Model({
          buttonText: 'Open Irregularity Folder',
          contentServerId: irregularitiesCSFolderId
        })
      })
    );

    this.showChildView(
      'entityActionDropDown',
      new DropdownView({
        rightAlign: true,
        model: new Backbone.Model({
          name: 'Irregularity Actions',
          options: [
            {
              text: 'Edit Irregularity',
              link: '#dep/entity/' + entityId + '/irregularity/' + irregularityId + '/edit'
            },
            {
              text: isLegalHold ? 'Cannot Delete  - Has Legal Hold' : 'Delete Irregularity',
              onClick: isLegalHold ? '' : this.onClickDelete.bind(this)
            }
          ]
        })
      })
    );

    this.showChildView(
      'breadcrumb',
      new BreadcrumbView({
        breadcrumbs: [
          {
            link: '#dep/dashboard',
            text: 'Home'
          },
          {
            link: '#dep/entity/' + entityId + '/dashboard',
            text: entityName
          },
          {
            text: irregularity.eventDate + ' - ' + irregularity.category
          }
        ]
      })
    );

    this.irregularity = new SideNavSectionView({
      useNavigationButtons: false,
      collection: new Backbone.Collection([
        {
          id: 'irregularityDetails',
          title: 'Irregularity Details',
          view: new DepIrregularityDetailsSectionView({
            model: new Backbone.Model(irregularity),
            entityCategoryId: entityCategoryId,
            entityTypeId: entityTypeId
          })
        },
        {
          id: 'notes',
          title: 'Notes',
          view: Radio.channel('dp').request('build:notes-view', {
            objectId: irregularityId,
            objectTypeId: 'IRREGULARITIES'
          })
        },
        {
          id: 'auditLog',
          title: 'Audit Log',
          view: Radio.channel('dp').request('build:audit-log-view', {
            objectId: irregularityId,
            objectTypeId: 'IRREGULARITIES'
          })
        }
      ])
    });
    this.showChildView('irregularityWrapper', this.irregularity);
  },

  onClickDelete: function() {
    var self = this;
    var message = 'Are you sure you want to delete this irregularity?';
    UIUtil.showModal({
      title: 'Confirm Deleting Irregularity',
      view: new ConfirmationModalView({
        confirmText: 'Delete Irregularity',
        model: new Backbone.Model({
          message: message,
          onConfirm: function() {
            var irregularityId = self.model.get('irregularityId');
            return SubmitIrregularity({ irregularityId: irregularityId, isDeleted: '1' });
          },
          onConfirmDone: function() {
            var entityId = self.model.get('entityId');
            Radio.channel('navigate').trigger('show:int:dp:entity', entityId);
          }
        })
      })
    });
  }
});
