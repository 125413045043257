/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var Marionette = require('backbone.marionette');
var Syphon = require('backbone.syphon');
var Radio = require('backbone.radio');
var moment = require('moment');
var Ladda = require('ladda');

var modelGet = require('common/util/modelGet');
var SelectFieldView = require('common/views/SelectFieldView');
var makeUploadCollection = require('common/util/makeUploadCollection');
var switchToPrint = require('common/util/switchToPrint');

var GridView = require('psa-marionette/views/GridView');
var FileUploadCollection = require('psa-backbone/models/FileUploadCollection');
var FileUploadView = require('common/views/FileUploadView');
var FileLinkView = require('common/views/FileLinkView');

var FileUploadCell = require('common/backgrid/FileUploadCell');
var BooleanCell = require('common/backgrid/BooleanCell');
var MomentCell = require('common/backgrid/MomentCell');
var toggleForm = require('common/util/toggleForm');

var NonDepEntityFormDBACollectionView = require('../../../common/views/NonDepEntityFormDBACollectionView');

var SubmitEntityNameChangeApplication = require('../../services/SubmitEntityNameChangeApplication');

require('./EntityNameChangeApplicationView.css');
var tmplEntityNameChangeApplicationView = require('./EntityNameChangeApplicationView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplEntityNameChangeApplicationView,

  className: 'entity-name-change-application-view container-fluid',

  behaviors: {
    RadioGroupToggleBehavior: {
      radios: [
        {
          target: '.change-entity-name-form',
          radioGroup: 'input[name="changeName"]',
          valueToShow: '1'
        },
        {
          target: '.change-entity-dba-form',
          radioGroup: 'input[name="changeDBA"]',
          valueToShow: '1'
        },
        {
          target: '.foreign-entity-info-form',
          radioGroup: '[name="isForeignEntity"]',
          valueToShow: '1'
        }
      ]
    },
    ReadOnlyFormBehavior: {}
  },

  ui: {
    changeEntityNameRadioForm: '.change-entity-name-radio-form',
    changeEntityDBARadioForm: '.change-entity-dba-radio-form',
    changeEntityNameForm: '.change-entity-name-form',
    changeEntityDBAForm: '.change-entity-dba-form',

    changeEntityNameRadioNo: 'input[name="changeName"][value="0"]',
    changeEntityDBARadioNo: 'input[name="changeDBA"][value="0"]',
    isForeignEntityRadioNo: 'input[name="isForeignEntity"][value="0"]',
    changeEntityNameRadioYes: 'input[name="changeName"][value="1"]',
    changeEntityDBARadioYes: 'input[name="changeDBA"][value="1"]',
    isForeignEntityRadioYes: 'input[name="isForeignEntity"][value="1"]',

    entityOrganizationTypeId: '.entity-organization-type-id',
    incorporationPlace: 'select[name="incorporationPlace"]',
    ssnContainer: '.ssn-container',
    tinContainer: '.tin-container',

    noChangesErrorContainer: '.no-changes-error-container',

    foreignRegistrationStatementForm: '.foreign-registration-statement-form',
    foreignRegistrationStatementContainer: '.foreign-registration-statement-container',
    oldForeignRegDownloadLink: '.old-foreign-reg-download-link',

    incorporationDate: '.incorporation-date',
    articlesOfIncorporationContainer: '.articles-of-incorporation-container',
    oldArtOfIncorpDownloadLink: '.old-art-of-incorp-download-link',

    operatingAgreementContainer: '.operating-agreement-container',
    oldOpAgreementDownloadLink: '.old-op-agreement-download-link',

    byLawsContainer: '.by-laws-container',
    oldByLawsDownloadLink: '.old-by-laws-download-link',

    isForeignEntity: 'input[name="isForeignEntity"]',

    currentEntityDBAContainer: '.current-entity-dba-container',
    newEntityDBAContainer: '.new-entity-dba-container',
    newEntityDBAButton: '.btn-new-entity-dba',

    effectiveChangeDate: '.effective-change-date',
    effectiveChangeDateForm: '.effective-change-date-form',

    applicationDocumentsFileContainer: '.application-documents-container',

    isCertified: 'input[name="isCertified"]',

    buttonsContainer: '.btn-container',
    submitButton: '.submit-button',

    formGroups: '.form-group',
    validationErrorContainers: '.validation-error-container',

    orgTypeSelect: '.org-type-select'
  },

  regions: {
    foreignRegistrationStatementContainer: '@ui.foreignRegistrationStatementContainer',
    oldForeignRegDownloadLink: '@ui.oldForeignRegDownloadLink',

    articlesOfIncorporationContainer: '@ui.articlesOfIncorporationContainer',
    oldArtOfIncorpDownloadLink: '@ui.oldArtOfIncorpDownloadLink',

    operatingAgreementContainer: '@ui.operatingAgreementContainer',
    oldOpAgreementDownloadLink: '@ui.oldOpAgreementDownloadLink',

    byLawsContainer: '@ui.byLawsContainer',
    oldByLawsDownloadLink: ' @ui.oldByLawsDownloadLink',

    currentEntityDBAContainer: '@ui.currentEntityDBAContainer',
    newEntityDBAContainer: '@ui.newEntityDBAContainer',

    applicationDocumentsFileContainer: '@ui.applicationDocumentsFileContainer',

    orgTypeSelect: '@ui.orgTypeSelect'
  },

  events: {
    'click @ui.newEntityDBAButton': 'onClickNewDBA',
    'change @ui.entityOrganizationTypeId': 'onChangeOrganizationType',
    'click @ui.submitButton': 'onClickSubmit'
  },

  childEvents: {
    'remove:dba': 'onChildRemoveDBA'
  },

  templateHelpers: function() {
    var amendmentChanges;
    var showChanges = this.options.isReadOnly && this.options.viewMode !== 'ext';
    if (showChanges) {
      amendmentChanges = this.getAmendmentChanges();
    }
    var showAffirmation =
      !this.options.isReadOnly ||
      (this.options.mode === 'print' || this.options.mode === 'int' || this.options.mode === 'int-expand');

    var showDBAs = false;
    var deletedDBAs = _.filter(this.model.get('dbas'), function(dba) {
      return !!dba.shouldDelete;
    });

    if ((!!this.model.get('newDBAs') && this.model.get('newDBAs').length > 0) || deletedDBAs.length > 0) {
      showDBAs = true;
    }

    showDBAs = this.options.isReadOnly && showDBAs;

    return {
      viewMode: this.options.viewMode,
      isReadOnly: this.options.isReadOnly,
      amendmentChanges: amendmentChanges,
      showAffirmation: showAffirmation,
      showDBAs: showDBAs
    };
  },

  onBeforeShow: function() {
    var showAffirmationData =
      this.options.mode === 'print' || this.options.mode === 'int' || this.options.mode === 'int-expand';

    if (showAffirmationData) {
      this.ui.isCertified.prop('checked', this.model.get('isCertified'));
    }

    if (this.model.get('entityOrganizationTypeId')) {
      this.ui.entityOrganizationTypeId.val(this.model.get('entityOrganizationTypeId'));
    }
    this.ui.incorporationPlace.val(this.model.get('incorporationPlace'));

    var amendmentChanges = {};
    var showChanges = this.options.isReadOnly && this.options.viewMode !== 'ext';
    if (showChanges) {
      amendmentChanges = this.getAmendmentChanges();
    }

    if (this.model.get('isForeignEntity') === '1') {
      this.ui.isForeignEntityRadioYes.prop('checked', true);
    } else {
      this.ui.isForeignEntityRadioNo.prop('checked', true);
    }

    // Show/Hide regions based on viewMode and passed data on model
    if (this.options.isReadOnly) {
      this.ui.changeEntityNameRadioForm.hide();
      this.ui.changeEntityDBARadioForm.hide();

      if (this.model.get('newEntityName')) {
        this.ui.changeEntityNameRadioYes.prop('checked', true);
      } else {
        this.ui.changeEntityNameRadioNo.prop('checked', true);
      }

      this.ui.changeEntityDBARadioYes.prop('checked', true);
    } else {
      if (this.model.get('newEntityName')) {
        this.ui.changeEntityNameRadioYes.prop('checked', true);
      } else {
        this.ui.changeEntityNameRadioNo.prop('checked', true);
      }
      if (_.findWhere(this.model.get('dbas'), { shouldDelete: true }) || this.model.get('newDBAs')) {
        this.ui.changeEntityDBARadioYes.prop('checked', true);
      } else {
        this.ui.changeEntityDBARadioNo.prop('checked', true);
      }
    }

    this.foreignRegistration = new FileUploadView({
      collection: makeUploadCollection(this.model.get('foreignRegistrationStatementDocument')),
      isRequired: true,
      isReadOnly: this.options.isReadOnly,
      metadata: {
        documentTypeId: 10018
      }
    });
    this.showChildView('foreignRegistrationStatementContainer', this.foreignRegistration);

    if (amendmentChanges.ffStatement) {
      this.oldForeignRegDownloadLink = new FileLinkView({
        model: new Backbone.Model({
          fileId: modelGet(this.model, 'oldForeignRegistrationStatementDocument.fileId'),
          fileName: modelGet(this.model, 'oldForeignRegistrationStatementDocument.fileName')
        })
      });
      this.showChildView('oldForeignRegDownloadLink', this.oldForeignRegDownloadLink);
    }

    this.articlesOfIncorporation = new FileUploadView({
      collection: makeUploadCollection(this.model.get('articlesOfIncorporationDocument')),
      isReadOnly: this.options.isReadOnly,
      metadata: {
        documentTypeId: 10019
      }
    });
    this.showChildView('articlesOfIncorporationContainer', this.articlesOfIncorporation);

    if (amendmentChanges.articlesOfIncorp) {
      this.oldArtOfIncorpDownloadLink = new FileLinkView({
        model: new Backbone.Model({
          fileId: modelGet(this.model, 'oldArticlesOfIncorporationDocument.fileId'),
          fileName: modelGet(this.model, 'oldArticlesOfIncorporationDocument.fileName')
        })
      });
      this.showChildView('oldArtOfIncorpDownloadLink', this.oldArtOfIncorpDownloadLink);
    }

    this.operatingAgreement = new FileUploadView({
      collection: makeUploadCollection(this.model.get('operatingAgreementDocument')),
      isReadOnly: this.options.isReadOnly,
      metadata: {
        documentTypeId: 10021
      }
    });
    this.showChildView('operatingAgreementContainer', this.operatingAgreement);
    if (amendmentChanges.opAgreement) {
      this.oldOpAgreementDownloadLink = new FileLinkView({
        model: new Backbone.Model({
          fileId: modelGet(this.model, 'oldOperatingAgreementDocument.fileId'),
          fileName: modelGet(this.model, 'oldOperatingAgreementDocument.fileName')
        })
      });
      this.showChildView('oldOpAgreementDownloadLink', this.oldOpAgreementDownloadLink);
    }

    this.byLaws = new FileUploadView({
      collection: makeUploadCollection(this.model.get('byLawsDocument')),
      isReadOnly: this.options.isReadOnly,
      metadata: {
        documentTypeId: 10021
      }
    });
    this.showChildView('byLawsContainer', this.byLaws);

    if (amendmentChanges.bylaws) {
      this.oldByLawsDownloadLink = new FileLinkView({
        model: new Backbone.Model({
          fileId: modelGet(this.model, 'oldByLawsDocument.fileId'),
          fileName: modelGet(this.model, 'oldByLawsDocument.fileName')
        })
      });
      this.showChildView('oldByLawsDownloadLink', this.oldByLawsDownloadLink);
    }

    this.applicationDocumentsFile = new FileUploadView({
      isRequired: true,
      allowMultipleFiles: true,
      collection: new FileUploadCollection(this.model.get('applicationDocuments')),
      isReadOnly: this.options.isReadOnly,
      metadata: {
        documentTypeId: 10023
      }
    });
    this.showChildView('applicationDocumentsFileContainer', this.applicationDocumentsFile);

    if (this.templateHelpers().showDBAs) {
      this.currentDBACollection = new Backbone.Collection(this.model.get('dbas'));

      this.showChildView(
        'currentEntityDBAContainer',
        new GridView({
          emptyText: 'Entity has no current DBAs',
          collection: this.currentDBACollection,
          columns: [
            {
              name: 'dbaName',
              label: 'DBA Name',
              cell: 'string',
              headerCell: 'custom',
              width: 30,
              editable: false
            },
            {
              name: 'fictitiousNameDocument',
              label: 'Fictitious Name Registration',
              cell: FileUploadCell.extend({
                allowMultipleFiles: false,
                isReadOnly: true
              }),
              width: 50,
              headerCell: 'custom',
              editable: false,
              sortable: false
            },
            {
              name: 'registrationDate',
              label: 'Registration Date',
              cell: MomentCell.MomentCell,
              formatter: MomentCell.MomentDateFormatter.extend({
                emptyValue: '--'
              }),
              sortValue: MomentCell.MomentSortValue,
              headerCell: 'custom',
              width: 20,
              editable: false,
              sortable: false
            },
            {
              name: 'endDate',
              label: 'Cancelled Date',
              cell: MomentCell.MomentCell,
              formatter: MomentCell.MomentDateFormatter.extend({
                emptyValue: '--'
              }),
              sortValue: MomentCell.MomentSortValue,
              headerCell: 'custom',
              width: 20,
              editable: false,
              sortable: false
            },
            {
              name: 'shouldDelete',
              label: 'Delete',
              cell: BooleanCell,
              headerCell: 'custom',
              editable: true,
              sortable: false
            }
          ]
        })
      );

      var newDBAs = this.model.get('newDBAs') || [];
      this.newDBAs = new NonDepEntityFormDBACollectionView({
        isReadOnly: this.options.isReadOnly,
        viewMode: this.options.viewMode,
        collection: new Backbone.Collection(newDBAs)
      });
      this.showChildView('newEntityDBAContainer', this.newDBAs);
    }

    this.ui.incorporationDate.datepicker();

    this.ui.effectiveChangeDate.datepicker({
      startDate: '0d'
    });

    if (!this.options.isReadOnly) {
      this.$('.table').addClass('table-bordered');
    }

    var isSoleProp = this.model.get('entityOrganizationTypeId') === '6';
    toggleForm(this.ui.ssnContainer, isSoleProp);
    toggleForm(this.ui.tinContainer, !isSoleProp);

    this.orgTypeSelect = new SelectFieldView({
      isDisabled: false,
      isRequired: true,
      name: 'entityOrganizationTypeId',
      collection: new Backbone.Collection([])
    });
    this.showChildView('orgTypeSelect', this.orgTypeSelect);

    var entityOrganizationTypesToFilter = this.model.get('entityOrganizationTypes');
    if (isSoleProp) {
      var soleOrganizationTypes = _.filter(entityOrganizationTypesToFilter, function(orgType) {
        return orgType.id === '6';
      });
      this.orgTypeSelect.collection.reset(
        _.map(soleOrganizationTypes, function(orgType) {
          return { value: orgType.id, text: orgType.name };
        })
      );
    } else {
      var nonSoleOrganizationTypes = _.filter(entityOrganizationTypesToFilter, function(orgType) {
        return orgType.id !== '6';
      });
      this.orgTypeSelect.collection.reset(
        _.map(nonSoleOrganizationTypes, function(orgType) {
          return { value: orgType.id, text: orgType.name };
        })
      );
    }
    this.orgTypeSelect.setValue(this.model.get('entityOrganizationTypeId'));
  },

  onChangeOrganizationType: function() {
    // If organizationtype ID = 6 (Sole Proprietorship), show and enable SSN instead of TIN
    var isSoleProp = this.ui.entityOrganizationTypeId.val() === '6';
    toggleForm(this.ui.ssnContainer, isSoleProp);
    toggleForm(this.ui.tinContainer, !isSoleProp);
  },

  onClickNewDBA: function(e) {
    e.preventDefault();
    this.newDBAs.collection.add({});
  },

  onClickDeleteDBA: function(e, model) {
    e.preventDefault();
    this.newDBAs.collection.remove(model);
  },

  onClickSubmit: function(event) {
    event.preventDefault();
    this.removeValidationErrors();

    this.validate().done(
      function() {
        var laddaContext = Ladda.create(this.ui.submitButton.get(0));
        laddaContext.start();
        var formData = this.getFormData();
        SubmitEntityNameChangeApplication(formData)
          .done(function(applicationId) {
            Radio.channel('nd:submit-application').trigger('submit');
            Radio.channel('nd:submit-application').trigger('resubmit', laddaContext);
          })
          .fail(function() {
            laddaContext.stop();
          });
      }.bind(this)
    );
  },

  validate: function() {
    var deferred = $.Deferred();

    var isEntityNameChange = this.$('input[name="changeName"]:checked').val() === '1';
    var isDBAChange = this.$('input[name="changeDBA"]:checked').val() === '1';
    var isForeignEntity = this.$('input[name="isForeignEntity"]:checked').val() === '1';

    var validationPromises = [this.ui.effectiveChangeDateForm.parsley().whenValidate()];

    if (!isEntityNameChange && !isDBAChange) {
      this.ui.noChangesErrorContainer.show();
      return deferred.reject();
    } else {
      deferred.resolve();

      if (isEntityNameChange) {
        validationPromises = validationPromises.concat([
          this.ui.changeEntityNameForm.parsley().whenValidate(),
          this.articlesOfIncorporation.validate(),
          this.operatingAgreement.validate(),
          this.byLaws.validate()
        ]);

        if (isForeignEntity) {
          validationPromises.push(this.foreignRegistration.validate());
        }
      }

      if (isDBAChange) {
        validationPromises = validationPromises.concat([
          this.ui.changeEntityDBAForm.parsley().whenValidate(),
          this.newDBAs.validate()
        ]);
      }
      return $.when.apply($, validationPromises);
    }
  },

  removeValidationErrors: function() {
    this.ui.formGroups.removeClass('has-error');
    this.ui.validationErrorContainers.hide();
  },

  getFormData: function() {
    var rawData = Syphon.serialize(this);
    var applicationDetails = this.model.get('applicationDetails');

    var formData = {
      entityId: this.model.get('entityId') || applicationDetails.entityId,
      applicationId: this.model.get('applicationId') || null,
      isCertified: rawData.isCertified,
      certificationName: rawData.certificationName
    };

    if (rawData.changeName === '1') {
      formData.entityNewName = rawData.newEntityName;
      formData = _.extend(formData, rawData);
      if (rawData.incorporationDate) {
        formData.incorporationDate = moment(rawData.incorporationDate, 'MM-DD-YYYY').format('YYYY-MM-DD');
      }
      if (rawData.isForeignEntity === '1') {
        formData.foreignRegistrationStatementDocumentID = this.foreignRegistration.getFirstFileId();
      }
      formData.articlesOfIncorporationDocumentId = this.articlesOfIncorporation.getFirstFileId();
      formData.operatingAgreementDocumentID = this.operatingAgreement.getFirstFileId();
      formData.byLawsDocumentID = this.byLaws.getFirstFileId();
    }

    formData.effectiveChangeDate = moment(rawData.effectiveChangeDate, 'MM-DD-YYYY').format('YYYY-MM-DD');

    if (rawData.changeDBA === '1') {
      formData.dbaChanges = this.currentDBACollection.reduce(function(memo, dbaModel) {
        var dba = dbaModel.toJSON();
        if (dba.shouldDelete) {
          memo.push({
            dbaDeleteID: dba.id
          });
        }
        return memo;
      }, []);

      var newDBAs = this.newDBAs.getFormData();
      if (newDBAs) {
        formData.dbaChanges = formData.dbaChanges.concat(newDBAs);
      }
    }

    // If sole proprietor, set TIN Type as SSN, else EIN
    if (this.ui.entityOrganizationTypeId.val() === '6') {
      formData.tinTypeId = '2';
    } else {
      formData.tinTypeId = '1';
    }
    formData.applicationDocuments = this.applicationDocumentsFile.collection.pluck('fileId');
    return formData;
  },

  getAmendmentChanges: function() {
    // Determine which fields have changed
    return {
      name: this.model.get('newEntityName') !== this.model.get('oldEntityName'),
      ffStatement:
        !!modelGet(this.model, 'foreignRegistrationStatementDocument.fileId') &&
        !!modelGet(this.model, 'oldForeignRegistrationStatementDocument.fileId'),
      articlesOfIncorp:
        !!modelGet(this.model, 'articlesOfIncorporationDocument.fileId') &&
        !!modelGet(this.model, 'oldArticlesOfIncorporationDocument.fileId'),
      opAgreement:
        !!modelGet(this.model, 'operatingAgreementDocument.fileId') &&
        !!modelGet(this.model, 'oldOperatingAgreementDocument.fileId'),
      bylaws: !!modelGet(this.model, 'byLawsDocument.fileId') && !!modelGet(this.model, 'oldByLawsDocument.fileId')
    };
  },

  onAttach: function() {
    if (this.options.mode === 'print') {
      switchToPrint(this.$el, '.input-view');
    }
  }
});
