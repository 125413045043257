/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2020] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var Backbone = require('backbone');
var Marionette = require('backbone.marionette');
var Radio = require('backbone.radio');

var UIUtil = require('psa-core/util/ui');

var modelGet = require('common/util/modelGet');
var ConfirmationModalView = require('common/views/ConfirmationModalView');
var DropdownView = require('common/views/DropdownView');
var BreadcrumbView = require('common/views/BreadcrumbView');

var SubmitEntityDirector = require('../services/SubmitEntityDirector');

var tmplDepDirectorDashboardView = require('../templates/DepDirectorDashboardView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplDepDirectorDashboardView,

  className: 'dep-director-dashboard-view container-fluid',

  ui: {
    headerBar: '.header-bar-container',
    breadcrumb: '.breadcrumb-container',
    directorActionDropDown: '.director-action-drop-down',
    directorDashboard: '.director-dashboard-container'
  },

  regions: {
    headerBar: '@ui.headerBar',
    breadcrumb: '@ui.breadcrumb',
    directorActionDropDown: '@ui.directorActionDropDown',
    directorDashboard: '@ui.directorDashboard'
  },

  templateHelpers: function() {
    var director = this.model.get('director') || {};

    var isChairmanDisplay = director.isChairman === '1' ? 'Yes' : 'No';
    var isCUTrustHC = _.contains(['11', '21', '22'], modelGet(this.model, 'entityIdentifiers.entityTypeId'));

    return {
      positionHeld: this.getPositionHeld(),
      isChairmanDisplay: isChairmanDisplay,
      isCUTrustHC: isCUTrustHC
    };
  },

  onBeforeShow: function() {
    var director = this.model.get('director') || {};
    var directorId = director.directorId;
    var entityId = director.entityId;
    var entityName = modelGet(this.model, 'entityIdentifiers.entityName');
    var positionHeld = this.getPositionHeld();
    var isCUTrustHC = _.contains(['11', '21', '22'], modelGet(this.model, 'entityIdentifiers.entityTypeId'));

    this.showChildView(
      'directorActionDropDown',
      new DropdownView({
        rightAlign: true,
        model: new Backbone.Model({
          name: 'Director Actions',
          options: [
            {
              text: isCUTrustHC ? 'Edit Director' : 'Edit Director-Trustee',
              link: '#dep/entity/' + entityId + '/director/' + directorId + '/edit'
            },
            {
              text: isCUTrustHC ? 'Delete Director' : 'Delete Director-Trustee',
              onClick: this.onClickDelete.bind(this)
            }
          ]
        })
      })
    );

    this.showChildView(
      'breadcrumb',
      new BreadcrumbView({
        breadcrumbs: [
          {
            link: '#dep/dashboard',
            text: 'Home'
          },
          {
            link: '#dep/entity/' + entityId + '/dashboard',
            text: entityName
          },
          {
            text: positionHeld + ' - ' + director.firstName + ' ' + director.lastName
          }
        ]
      })
    );
  },

  onClickDelete: function() {
    var entityId = modelGet(this.model, 'director.entityId');
    var directorId = modelGet(this.model, 'director.directorId');
    var isCUTrustHC = _.contains(['11', '21', '22'], modelGet(this.model, 'entityIdentifiers.entityTypeId'));

    UIUtil.showModal({
      title: isCUTrustHC ? 'Confirm Deleting Director' : 'Confirm Deleting Director-Trustee',
      view: new ConfirmationModalView({
        confirmText: isCUTrustHC ? 'Delete Director' : 'Delete Director-Trustee',
        model: new Backbone.Model({
          message: isCUTrustHC
            ? 'Are you sure you want to delete this director?'
            : 'Are you sure you want to delete this director-trustee?',
          onConfirm: function() {
            return SubmitEntityDirector({
              directorId: directorId,
              isDeleted: 1
            });
          },
          onConfirmDone: function() {
            Radio.channel('navigate').trigger('show:int:dp:entity', entityId);
          }
        })
      })
    });
  },

  getPositionHeld: function() {
    var positionHeldLookup = modelGet(this.model, 'director.positionHeldLookup');
    var positionHeld = modelGet(this.model, 'director.positionHeld');
    return positionHeldLookup || positionHeld;
  }
});
