var Handlebars = require("handlebars/dist/handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "DOR";
},"3":function(container,depth0,helpers,partials,data) {
    return "MRBA";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.escapeExpression, alias2=depth0 != null ? depth0 : (container.nullContext || {});

  return "\n<div class=\"page-title clearfix\">\n  <h1 class=\"pull-left m-n p-n\">"
    + alias1(container.lambda(((stack1 = (depth0 != null ? depth0.entityIdentifiers : depth0)) != null ? stack1.entityName : stack1), depth0))
    + "</h1>\n  <div class=\"mrba-action-drop-down pull-right\"></div>\n</div>\n<div class=\"breadcrumb-container\"></div>\n<div class=\"col-sm-12 col-md-offset-1 col-md-10 m-b-md\">\n  <div class=\"row\">\n    <h3 class=\"m-t-n col-sm-offset-2 col-sm-10\">New "
    + ((stack1 = helpers["if"].call(alias2,(depth0 != null ? depth0.isCU : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data})) != null ? stack1 : "")
    + "</h3>\n  </div>\n  <form class=\"mrba-form-container\"></form>\n  <div class=\"row\">\n    <div class=\"col-sm-offset-3 col-sm-9 col-md-offset-2 col-md-10\">\n      <a href=\"#dep/entity/"
    + alias1(((helper = (helper = helpers.entityId || (depth0 != null ? depth0.entityId : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(alias2,{"name":"entityId","hash":{},"data":data}) : helper)))
    + "/dashboard\" class=\"btn btn-default\">Cancel</a>\n      <button class=\"btn btn-primary submit ladda-button\" data-style=\"expand-right\" type=\"button\">\n        <span class=\"ladda-label\">Submit</span>\n      </button>\n    </div>\n  </div>\n</div>";
},"useData":true});