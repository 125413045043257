/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Radio = require('backbone.radio');
var NProgress = require('nprogress');

var NavUtils = require('psa-core/util/nav');

var Scaffold = require('common/layout/Scaffold');
var PrinterView = require('common/views/PrinterView');

var GetFilingDetails = require('../../common/services/GetFilingDetails');

module.exports = function(filingId) {
  NProgress.start();
  NProgress.set(0.3);
  GetFilingDetails(filingId)
    .done(function(filingDetails) {
      NProgress.set(0.6);
      var formIdentifier = filingDetails.formIdentifier;
      var BuildFilingPromise = Radio.channel('dp:filing').request('build:' + formIdentifier, {
        filingId: filingId,
        mode: 'print',
        entityCategoryId: filingDetails.entityCategoryId,
        isReadOnly: false
      });
      BuildFilingPromise.done(function(filingFormView) {
        Radio.channel('header').trigger('hide:header');
        Radio.channel('footer').trigger('hide:footer');
        NavUtils.navigate('ext/dep/filing/' + filingId + '/print');
        filingFormView.model.set({
          filingId: filingId,
          filingDetails: filingDetails
        });
        Scaffold.content.show(
          new PrinterView({
            viewToPrint: filingFormView
          })
        );
        NProgress.done();
      }).fail(function(err) {
        NProgress.done();
      });
    })
    .fail(function(err) {
      NProgress.done();
    });
};
