/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var Backbone = require('backbone');
var Marionette = require('backbone.marionette');
var Radio = require('backbone.radio');
var Syphon = require('backbone.syphon');
var Ladda = require('ladda');

var GridView = require('psa-marionette/views/GridView');
var DeepCollection = require('psa-backbone/models/DeepCollection');

var FileUploadCell = require('common/backgrid/FileUploadCell');
var AddressDisplayView = require('common/views/AddressDisplayView');
var BreadcrumbView = require('common/views/BreadcrumbView');
var modelGet = require('common/util/modelGet');
var NonDepSelectRowCell = require('../../common/backgrid/NonDepSelectRowCell');

var LicenseHeaderBarView = require('../../common/views/LicenseHeaderBarView');

var SubmitLicenseGeneralInformation = require('../services/SubmitLicenseGeneralInformation');

var tmplNonDepLicenseEditGeneralInformationView = require('../templates/NonDepLicenseEditGeneralInformationView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplNonDepLicenseEditGeneralInformationView,

  className: 'non-dep-license-edit-general-information-view container-fluid',

  ui: {
    headerBarContainer: '.header-bar-container',
    breadCrumbContainer: '.breadcrumb-container',
    locationSelect: '.location-select',
    generalInformationForm: '.general-information-form',
    effectiveStartDate: '.effective-start-date',
    effectiveEndDate: '.effective-end-date',
    originalIssueDate: '.original-start-date',
    dbas: '.dbas',
    submitButton: '.submit-button'
  },

  regions: {
    headerBarContainer: '@ui.headerBarContainer',
    breadCrumbContainer: '@ui.breadCrumbContainer',
    dbas: '@ui.dbas'
  },

  events: {
    'click @ui.submitButton': 'onClickSubmit'
  },

  onBeforeShow: function() {
    var entityId = this.model.get('entityId');
    var licenseId = this.model.get('licenseId');
    var licenseLocationId = modelGet(this.model, 'licenseDashboard.licenseLocationID');
    var entityName = modelGet(this.model, 'entityIdentifiers.entityName');
    var licenseNumber = modelGet(this.model, 'licenseIdentifiers.licenseNumber');
    var licenseType = modelGet(this.model, 'licenseIdentifiers.licenseType');

    this.showChildView(
      'headerBarContainer',
      new LicenseHeaderBarView({
        model: new Backbone.Model(this.model.get('licenseIdentifiers'))
      })
    );

    this.showChildView(
      'breadCrumbContainer',
      new BreadcrumbView({
        breadcrumbs: [
          {
            text: 'Home',
            link: '#nondep/dashboard'
          },
          {
            text: entityName,
            link: '#nondep/entity/' + entityId + '/dashboard'
          },
          {
            text: 'License #' + licenseNumber + ' - ' + licenseType,
            link: '#nondep/entity/' + entityId + '/license/' + licenseId
          },
          {
            text: 'Edit General Information'
          }
        ]
      })
    );

    this.ui.locationSelect.select2({
      allowClear: true,
      data: this.model.get('entityLocations'),
      templateResult: function(location) {
        var addressMarkup = new AddressDisplayView({
          model: new Backbone.Model(location)
        }).render().el.outerHTML;
        if (location.isPrimaryPlaceOfBusiness) {
          addressMarkup += '<span class="label label-primary">Headquarters</span>';
        }
        return $(addressMarkup);
      },
      templateSelection: function(location) {
        if (location.addressLine1) {
          return $('<span>' + location.addressLine1 + '</span>');
        } else {
          return $('<span>--</span>');
        }
      },
      minimumResultsForSearch: -1
    });
    this.ui.locationSelect.val(licenseLocationId).trigger('change');

    var checkedEntityDbas = _.pluck(this.model.get('licenseDbas'), 'entityDBAId');
    var entityDbas = _.map(this.model.get('entityDbas'), function(dba) {
      dba.isChecked = _.includes(checkedEntityDbas, dba.id);
      return dba;
    });
    var licenseDashboard = this.model.get('licenseDashboard');
    if (licenseDashboard.originalIssueDate === '--') {
      licenseDashboard.originalIssueDate = '';
    }
    Syphon.deserialize(this, licenseDashboard);

    this.ui.effectiveStartDate.datepicker({});
    this.ui.effectiveEndDate.datepicker({});
    this.ui.originalIssueDate.datepicker({});

    this.dbaGridView = new GridView({
      columns: [
        {
          name: 'isChecked',
          label: '',
          cell: NonDepSelectRowCell.SelectRowCell,
          headerCell: NonDepSelectRowCell.SelectAllHeaderCell,
          width: 10,
          editable: true,
          sortable: false
        },
        {
          name: 'dbaName',
          label: 'Name',
          cell: 'string',
          headerCell: 'custom',
          width: 40,
          editable: false
        },
        {
          name: 'fictitiousNameDocument',
          label: 'Fictitious Name Registration Document',
          cell: FileUploadCell.extend({
            isReadOnly: true
          }),
          headerCell: 'custom',
          width: 50,
          editable: false,
          sortable: false
        }
      ],
      collection: new DeepCollection(entityDbas),
      emptyText: 'Entity has no D/B/A registrations.'
    });
    this.showChildView('dbas', this.dbaGridView);
  },

  onClickSubmit: function(e) {
    e.preventDefault();
    var self = this;
    var entityId = this.model.get('entityId');
    var licenseId = this.model.get('licenseId');

    this.validate().done(function() {
      var laddaContext = Ladda.create(self.ui.submitButton.get(0));
      laddaContext.start();
      SubmitLicenseGeneralInformation(self.getFormData())
        .done(function() {
          Radio.channel('navigate').trigger('show:int:nd:license-dashboard', entityId, licenseId);
        })
        .fail(function(err) {
          laddaContext.stop();
        });
    });
  },

  getFormData: function() {
    var formData = Syphon.serialize(this);
    var licenseId = this.model.get('licenseId');
    formData.licenseId = licenseId;
    var selectedModel = this.dbaGridView.viewGrid.getSelectedModels();

    var licenseDbas = _.map(selectedModel, function(dba) {
      return dba.get('id');
    });

    formData.licenseDbas = licenseDbas;

    return formData;
  },

  validate: function() {
    return this.ui.generalInformationForm.parsley().whenValidate();
  }
});
