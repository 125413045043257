var Handlebars = require("handlebars/dist/handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function";

  return "    "
    + container.escapeExpression(((helper = (helper = helpers.itemNumber || (depth0 != null ? depth0.itemNumber : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"itemNumber","hash":{},"data":data}) : helper)))
    + " "
    + ((stack1 = ((helper = (helper = helpers.item || (depth0 != null ? depth0.item : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"item","hash":{},"data":data}) : helper))) != null ? stack1 : "")
    + "\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, helper;

  return "    "
    + ((stack1 = ((helper = (helper = helpers.item || (depth0 != null ? depth0.item : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"item","hash":{},"data":data}) : helper))) != null ? stack1 : "")
    + "\n";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.isQuestion : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"6":function(container,depth0,helpers,partials,data) {
    return "    <div class=\"not-applicable-container\">\n      <div class=\"checkbox\">\n        <label>\n          <input type=\"checkbox\" name=\"isNotApplicable\" class=\"not-applicable\" tabindex=\"-1\"> Not Applicable\n        </label>\n      </div>\n      <div class=\"not-applicable-explaination-container form-group required\">\n        <label class=\"control-label\">Provide reason why this question does not apply</label>\n        <textarea class=\"form-control not-applicable-explaination\" name=\"notApplicableReason\" rows=\"8\" placeholder=\"Provide reason why this question does not apply\" data-parsley-required=\"true\" data-parsley-maxlength=\"2000\"></textarea>\n      </div>\n    </div>\n";
},"8":function(container,depth0,helpers,partials,data) {
    return "    <div class=\"file-upload-container\"></div>\n";
},"10":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = (helpers.ifCond || (depth0 && depth0.ifCond) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.itemType : depth0),"===","BOOL",{"name":"ifCond","hash":{},"fn":container.program(11, data, 0),"inverse":container.program(13, data, 0),"data":data})) != null ? stack1 : "");
},"11":function(container,depth0,helpers,partials,data) {
    return "    <label class=\"radio-inline\">\n      <input type=\"radio\" name=\"responseValue\" value=\"1\"> Yes\n    </label>\n    <label class=\"radio-inline\">\n      <input type=\"radio\" name=\"responseValue\" value=\"0\"> No\n    </label>\n";
},"13":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = (helpers.ifCond || (depth0 && depth0.ifCond) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.itemType : depth0),"===","DOLLAR",{"name":"ifCond","hash":{},"fn":container.program(14, data, 0),"inverse":container.program(16, data, 0),"data":data})) != null ? stack1 : "");
},"14":function(container,depth0,helpers,partials,data) {
    return "    <div class=\"input-group amount-input-group\">\n      <div class=\"input-group-addon\">$</div>\n      <input type=\"number\" step=\"any\" class=\"form-control\" name=\"responseValue\" placeholder=\"Amount\" data-parsley-dollar-amount=\"true\" data-parsley-errors-container=\"#dollarAmountError\" />\n    </div>\n    <div id=\"dollarAmountError\"></div>\n";
},"16":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = (helpers.ifCond || (depth0 && depth0.ifCond) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.itemType : depth0),"===","NUMBER",{"name":"ifCond","hash":{},"fn":container.program(17, data, 0),"inverse":container.program(19, data, 0),"data":data})) != null ? stack1 : "");
},"17":function(container,depth0,helpers,partials,data) {
    return "    <input type=\"number\" step=\"any\" class=\"form-control\" name=\"responseValue\" placeholder=\"Provide answer\" data-parsley-maxlength=\"2000\" />\n";
},"19":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = (helpers.ifCond || (depth0 && depth0.ifCond) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.itemType : depth0),"===","TEXT",{"name":"ifCond","hash":{},"fn":container.program(20, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"20":function(container,depth0,helpers,partials,data) {
    return "    <textarea class=\"form-control\" name=\"responseValue\" rows=\"8\" placeholder=\"Provide answer\" data-parsley-maxlength=\"2000\"></textarea>\n  ";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "\n<div class=\"question-container\">\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.itemNumber : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data})) != null ? stack1 : "")
    + "</div>\n\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.isLoanLog : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n<div class=\"form-group answer-container\">\n"
    + ((stack1 = (helpers.ifCond || (depth0 && depth0.ifCond) || helpers.helperMissing).call(alias1,(depth0 != null ? depth0.itemType : depth0),"===","ATTACH",{"name":"ifCond","hash":{},"fn":container.program(8, data, 0),"inverse":container.program(10, data, 0),"data":data})) != null ? stack1 : "")
    + "</div>\n";
},"useData":true});