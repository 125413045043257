/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');

var HeaderBarCellView = require('./HeaderBarCellView');

var tmplHeaderBarAddressCellView = require('./HeaderBarAddressCellView.hbs');

module.exports = HeaderBarCellView.extend({
  template: tmplHeaderBarAddressCellView,

  className: 'header-bar-cell-view header-bar-address-cell-view',

  onBeforeRender: function() {
    var address = this.dataModel.get('address');
    var displayAddress;
    var addressline = _.compact([address.addressLine1, address.addressLine2, address.addressLine3]).join(' ');
    displayAddress = _.compact([addressline, address.city, address.state, address.postalCode]).join(', ');
    if (address.country !== 'US') {
      displayAddress = [displayAddress, address.country].filter(Boolean).join(' ');
    }
    displayAddress = displayAddress || '--';
    this.model.set({ displayAddress: displayAddress });
  }
});
