/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2020] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Backbone = require('backbone');
var NProgress = require('nprogress');

var NavUtils = require('psa-core/util/nav');

var Scaffold = require('common/layout/Scaffold');
var GetDocumentTypesByDepartment = require('common/services/GetDocumentTypesByDepartment');
var GetEntityIdentifiers = require('../../common/services/GetEntityIdentifiers');

var NonDepEntityInitiateCorrespondenceView = require('../views/NonDepEntityInitiateCorrespondenceView');

module.exports = function(entityId) {
  NProgress.start();
  NProgress.set(0.6);
  $.when(GetEntityIdentifiers(entityId), GetDocumentTypesByDepartment('NON_DEPOSITORY'))
    .done(function(entityIdentifiers, documentTypes) {
      NavUtils.navigate('#nondep/entity/' + entityId + '/initiate-correspondence');
      Scaffold.content.show(
        new NonDepEntityInitiateCorrespondenceView({
          model: new Backbone.Model({
            entityId: entityId,
            entityIdentifiers: entityIdentifiers,
            documentTypes: documentTypes
          })
        })
      );
      NProgress.done();
    })
    .fail(function() {
      NProgress.done();
    });
};
