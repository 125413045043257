/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Marionette = require('backbone.marionette');
var Ladda = require('ladda');
var Radio = require('backbone.radio');
var moment = require('moment');
var Session = require('psa-core/Session');

var BreadcrumbView = require('common/views/BreadcrumbView');
var modelGet = require('common/util/modelGet');
var ExamHeaderBarView = require('../../../common/views/ExamHeaderBarView');
var ContentServerLinkView = require('common/views/ContentServerLinkView');

require('./ComplianceExaminerPaymentCollectionsTaskView.css');
var tmplComplianceExaminerPaymentCollectionsTaskView = require('./ComplianceExaminerPaymentCollectionsTaskView.hbs');
var ExamPaymentDetailsView = require('../../views/ExamPaymentDetailsView');

var SubmitComplianceExaminerPaymentCollectionsTask = require('../../services/SubmitComplianceExaminerPaymentCollectionsTask');

module.exports = Marionette.LayoutView.extend({
  template: tmplComplianceExaminerPaymentCollectionsTaskView,

  className: 'compliance-examiner-payment-collections-task-view container-fluid',

  ui: {
    headerBar: '.header-bar',
    csLinkContainer: '.cs-link-container',
    breadcrumb: '.breadcrumb-container',
    submit: '.submit',
    paymentDetailsContainer: '.payment-details-container',
    billingComments: 'textarea[name="billingComments"]',
    taskForm: 'form'
  },

  regions: {
    csLinkContainer: '@ui.csLinkContainer',
    headerBar: '@ui.headerBar',
    breadcrumb: '@ui.breadcrumb',
    paymentDetailsContainer: '@ui.paymentDetailsContainer'
  },

  events: {
    'click @ui.submit': 'onClickSubmit'
  },

  behaviors: {
    TaskBehavior: {}
  },

  onBeforeShow: function() {
    var exam = this.model.get('examDetails') || {};

    this.showChildView(
      'headerBar',
      new ExamHeaderBarView({
        model: new Backbone.Model(this.model.get('examDetails'))
      })
    );

    this.showChildView(
      'csLinkContainer',
      new ContentServerLinkView({
        model: new Backbone.Model({
          buttonText: 'Open Exam Folder',
          contentServerId: exam.CSFolderId
        })
      })
    );

    this.showChildView(
      'breadcrumb',
      new BreadcrumbView({
        breadcrumbs: [
          {
            link: '#nondep/dashboard',
            text: 'Home'
          },
          {
            text: modelGet(this.model, 'task.taskType.description')
          }
        ]
      })
    );

    this.showChildView(
      'paymentDetailsContainer',
      new ExamPaymentDetailsView({
        model: new Backbone.Model({
          examId: modelGet(this.model, 'examDetails.examId'),
          examPaymentDetails: modelGet(this.model, 'examPaymentDetails', {})
        })
      })
    );

    var billingComments = modelGet(this.model, 'examDetails.billingComments');

    if (billingComments) {
      this.ui.billingComments.val('\n\n' + billingComments);
    }
  },

  onClickSubmit: function(e) {
    e.preventDefault();
    var self = this;
    this.ui.taskForm
      .parsley()
      .whenValidate()
      .done(
        function() {
          var l = Ladda.create(self.ui.submit[0]);
          l.start();
          var timestamp = moment().format('MM/DD/YYYY h:mm A'),
            userName = Session.user.description,
            billingComments = this.ui.billingComments.val(),
            formData = {
              taskId: modelGet(this.model, 'taskId'),
              examId: modelGet(this.model, 'examDetails.examId'),
              examPaymentId: modelGet(this.model, 'examPaymentDetails.examPaymentId'),
              billingComments: '[' + userName + ' - ' + timestamp + '] \n' + billingComments
            };

          SubmitComplianceExaminerPaymentCollectionsTask(formData)
            .done(function() {
              Radio.channel('navigate').trigger('show:int:nd:dashboard');
            })
            .fail(function() {
              l.stop();
            });
        }.bind(this)
      );
  }
});
