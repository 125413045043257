/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');

var getFilingValidFields = function(config) {
  config = config || {};
  var categoryId = config.categoryId;
  var filingTypeId = config.filingTypeId;
  var genericSubmissionTypeId = config.genericSubmissionTypeId;

  var validFields = {
    additionalDetails: false,
    conditionList: false,
    section112AdditionalDetails: false,
    subsidiaryAdditionalDetails: false
  };

  // Only show additional details section for Generic Submission Filings with
  // the following submission types:
  // - Bank Subsidiary (105)
  // - Section 112 (109, 304)
  // Also show additional details section for Bank Subsidiary Filings that are NOT Generic Submission
  var isGenericSubmissionFiling = _.contains(['100', '200', '300'], filingTypeId);
  var isBankSubsidiaryGenericFiling = isGenericSubmissionFiling && genericSubmissionTypeId === '506';
  var isBankSubsidiaryFiling = filingTypeId === '105';
  var isSection112GenericFiling = isGenericSubmissionFiling && genericSubmissionTypeId === '520';

  if (isBankSubsidiaryGenericFiling || isSection112GenericFiling || isBankSubsidiaryFiling) {
    validFields.additionalDetails = true;
  }
  if (isSection112GenericFiling) {
    validFields.section112AdditionalDetails = true;
  }
  if (isBankSubsidiaryGenericFiling || isBankSubsidiaryFiling) {
    validFields.subsidiaryAdditionalDetails = true;
  }

  if (categoryId === '1') {
    validFields.conditionList = true;
  }
  return validFields;
};

module.exports = getFilingValidFields;
