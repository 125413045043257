/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Marionette = require('backbone.marionette');
var Ladda = require('ladda');
var Radio = require('backbone.radio');
var Syphon = require('backbone.syphon');

var BreadcrumbView = require('common/views/BreadcrumbView');

var EntityHeaderBarView = require('../../common/views/EntityHeaderBarView');

var SubmitEntityFlaggedStatus = require('../services/SubmitEntityFlaggedStatus');

require('../styles/NonDepEntityEditFlaggedView.css');
var tmplNonDepEntityEditFlaggedView = require('../templates/NonDepEntityEditFlaggedView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplNonDepEntityEditFlaggedView,

  className: 'non-dep-entity-edit-flagged-view container-fluid m-b-lg',

  ui: {
    entityActionDropDown: '.dropdown-container',
    headerBar: '.header-bar-container',
    breadcrumb: '.breadcrumb-container',
    flaggedForm: '.flagged-form',
    flaggedNotesContainer: '.flagged-notes-container',
    //placeLegaHoldCheck: 'input[name="placeFlagged"][value="1"]',
    submit: '.btn-submit'
  },

  regions: {
    entityActionDropDown: '@ui.entityActionDropDown',
    flaggedNotesContainer: '@ui.flaggedNotesContainer',
    headerBar: '@ui.headerBar',
    breadcrumb: '@ui.breadcrumb'
  },

  events: {
    'click @ui.submit': 'onClickSubmit'
  },

  onBeforeShow: function() {
    var entityId = this.model.get('entityId');
    var entityIdentifiers = this.model.get('entityIdentifiers') || {};
    

    this.showChildView(
      'headerBar',
      new EntityHeaderBarView({
        model: new Backbone.Model(entityIdentifiers)
      })
    );

    this.showChildView(
      'breadcrumb',
      new BreadcrumbView({
        breadcrumbs: [
          {
            link: '#nondep/dashboard',
            text: 'Home'
          },
          {
            link: '#nondep/entity/' + entityId + '/dashboard',
            text: this.model.get('entityName')
          },
          {
            text: 'Edit Flagged Status'
          }
        ]
      })
    );
  },

  onClickSubmit: function(e) {
    e.preventDefault();
    var entityId = this.model.get('entityId');

    this.validate().done(
      function() {
        var laddaContext = Ladda.create(this.ui.submit.get(0));
        laddaContext.start();
        var formData = Syphon.serialize(this);
        var flaggedNotes = formData.flaggedNotes;
        var newFlaggedStatus = formData.placeFlagged ? '1' : '0';
        SubmitEntityFlaggedStatus({
          entityId: entityId,
          newFlaggedStatus: newFlaggedStatus,
          flaggedNotes: flaggedNotes
        })
          .done(function() {
            Radio.channel('navigate').trigger('show:int:nd:entity-dashboard', entityId);
          })
          .fail(function() {
            laddaContext.stop();
          });
      }.bind(this)
    );
  },

  validate: function() {
    return this.ui.flaggedForm.parsley().whenValidate();
  }
});
