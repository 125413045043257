/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2020] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Marionette = require('backbone.marionette');

var GridView = require('psa-marionette/views/GridView');

var MomentCell = require('common/backgrid/MomentCell');
var ButtonLinkCell = require('common/backgrid/ButtonLinkCell');

var tmplDepEntityFilingsSectionView = require('../templates/DepEntityFilingsSectionView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplDepEntityFilingsSectionView,

  className: 'dep-filings-section-view container-fluid',

  ui: { filingsList: '.filings-list-container' },

  regions: { filingsList: '@ui.filingsList' },

  onBeforeShow: function() {
    this.filingsGrid = new GridView({
      columns: [
        {
          name: 'filingType',
          label: 'Type',
          cell: 'string',
          headerCell: 'custom',
          width: 20,
          editable: false
        },
        {
          name: 'acceptedDate',
          label: 'Accepted Date',
          cell: MomentCell.MomentCell,
          formatter: MomentCell.MomentDateFormatter.extend({
            emptyValue: '--'
          }),
          sortValue: MomentCell.MomentSortValue,
          headerCell: 'custom',
          width: 13,
          editable: false
        },
        {
          name: 'actionDate',
          label: 'Action Date',
          cell: MomentCell.MomentCell,
          formatter: MomentCell.MomentDateFormatter.extend({
            emptyValue: '--'
          }),
          sortValue: MomentCell.MomentSortValue,
          headerCell: 'custom',
          width: 13,
          editable: false
        },
        {
          name: 'recordStatus',
          label: 'Status',
          cell: 'string',
          headerCell: 'custom',
          width: 13,
          editable: false
        },
        {
          name: 'action',
          label: 'Action',
          cell: 'string',
          headerCell: 'custom',
          width: 13,
          editable: false
        },
        {
          name: 'comments',
          label: 'Comments',
          cell: 'string',
          headerCell: 'custom',
          width: 20,
          editable: false
        },
        {
          name: '',
          label: '',
          cell: ButtonLinkCell.extend({
            name: 'Open',
            buttonClass: 'btn-primary btn-sm',
            href: function(model) {
              var entityId = model.get('entityId');
              var filingId = model.get('filingId');
              return '#dep/entity/' + entityId + '/filing/' + filingId + '/dashboard';
            }
          }),
          headerCell: 'custom',
          width: 8,
          editable: false,
          sortable: false
        }
      ],
      emptyText: 'No Filings',
      collection: this.collection
    });
    this.showChildView('filingsList', this.filingsGrid);
  }
});
