/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Backbone = require('backbone');
var Marionette = require('backbone.marionette');

var DropdownMenuItemView = require('./DropdownMenuItemView');

var tmplDropdownView = require('./DropdownView.hbs');

module.exports = Marionette.CompositeView.extend({
  template: tmplDropdownView,

  className: 'dropdown dropdown-view',

  options: {
    buttonClass: 'btn-default'
  },

  ui: {
    dropdownMenu: '.dropdown-menu'
  },

  childView: DropdownMenuItemView,

  childViewContainer: '@ui.dropdownMenu',

  templateHelpers: function() {
    return {
      buttonClass: this.options.buttonClass
    };
  },

  initialize: function() {
    var dropdownOptions = this.model.get('options');
    this.collection = new Backbone.Collection(dropdownOptions);
  },

  onBeforeShow: function() {
    if (!this.collection.length) {
      this.collection.add({
        text: 'No Actions Available',
        isDisabled: true
      });
    }

    if (this.options.rightAlign) {
      this.ui.dropdownMenu.addClass('dropdown-menu-right');
    }
  }
});
