/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2020] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var NProgress = require('nprogress');

var NavUtils = require('psa-core/util/nav');

var Scaffold = require('common/layout/Scaffold');

var GetAllMrbaLookups = require('../../common/services/GetAllMrbaLookups');
var GetEntityIdentifiers = require('../../common/services/GetEntityIdentifiers');

var GetMrba = require('../services/GetMrba');

var getMrbaValidFields = require('../util/getMrbaValidFields');

var DepMrbaEditView = require('../views/DepMrbaEditView');

module.exports = function(entityId, mrbaId) {
  NProgress.start();
  NProgress.set(0.6);
  $.when(GetEntityIdentifiers(entityId), GetMrba(mrbaId))
    .done(function(entityIdentifiers, mrba) {
      GetAllMrbaLookups(entityIdentifiers.categoryId)
        .done(function(lookups) {
          var validFields = getMrbaValidFields(entityIdentifiers.categoryId);

          NavUtils.navigate('dep/entity/' + entityId + '/mrba/' + mrbaId + '/edit');

          Scaffold.content.show(
            new DepMrbaEditView({
              model: new Backbone.Model({
                mrba: mrba,
                entityIdentifiers: entityIdentifiers,
                validFields: validFields,
                mrbaTypes: lookups.mrbaTypes,
                mrbaCategories: lookups.mrbaCategories,
                mrbaAgencies: lookups.mrbaAgencies,
                mrbaHistories: lookups.mrbaHistories,
                mrbaStatus: lookups.mrbaStatus
              })
            })
          );
          NProgress.done();
        })
        .fail(function() {
          NProgress.done();
        });
    })
    .fail(function() {
      NProgress.done();
    });
};
