/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Marionette = require('backbone.marionette');
var Radio = require('backbone.radio');

var BreadcrumbView = require('common/views/BreadcrumbView');
var modelGet = require('common/util/modelGet');
var LicenseHeaderBarView = require('../../common/views/LicenseHeaderBarView');

var LicenseContactsChangeApplicationView = require('../forms/LicenseContactsChangeApplication/LicenseContactsChangeApplicationView');

var tmplLicenseContactsChangeFormView = require('../templates/LicenseContactsChangeFormView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplLicenseContactsChangeFormView,

  className: 'license-contacts-change-form-view container-fluid',

  ui: {
    headerBarContainer: '.header-bar-container',
    breadCrumbContainer: '.breadcrumb-container',
    licenseContactChangeFormContainer: '.license-contacts-change-form-container'
  },

  regions: {
    headerBarContainer: '@ui.headerBarContainer',
    breadCrumbContainer: '@ui.breadCrumbContainer',
    licenseContactChangeFormContainer: '@ui.licenseContactChangeFormContainer'
  },

  onBeforeShow: function() {
    var entityId = this.model.get('entityId'),
      licenseId = this.model.get('licenseId'),
      licenseContacts = this.model.get('licenseContacts'),
      requiredLicenseContactTypes = this.model.get('requiredLicenseContactTypes'),
      licenseContactTypes = this.model.get('licenseContactTypes'),
      entityName = modelGet(this.model, 'entityIdentifiers.entityName'),
      licenseType = modelGet(this.model, 'licenseIdentifiers.licenseType'),
      licenseNumber = modelGet(this.model, 'licenseIdentifiers.licenseNumber'),
      existingContacts = modelGet(this.model, 'existingContacts');

    this.showChildView(
      'headerBarContainer',
      new LicenseHeaderBarView({
        model: new Backbone.Model(this.model.get('licenseIdentifiers'))
      })
    );

    this.showChildView(
      'breadCrumbContainer',
      new BreadcrumbView({
        breadcrumbs: [
          {
            text: 'Home',
            link: '#ext/nondep/dashboard'
          },
          {
            text: entityName,
            link: '#ext/nondep/entity/' + entityId + '/dashboard'
          },
          {
            text: 'License #' + licenseNumber + ' - ' + licenseType,
            link: '#ext/nondep/entity/' + entityId + '/license/' + licenseId
          },
          {
            text: 'Apply to Change Contacts'
          }
        ]
      })
    );

    this.showChildView(
      'licenseContactChangeFormContainer',
      new LicenseContactsChangeApplicationView({
        viewMode: this.options.viewMode,
        model: new Backbone.Model({
          licenseContacts: licenseContacts,
          entityId: entityId,
          licenseId: licenseId,
          requiredLicenseContactTypes: requiredLicenseContactTypes,
          licenseContactTypes: licenseContactTypes,
          existingContacts: existingContacts
        })
      })
    );

    Radio.channel('nd:submit-application').on('submit', function() {
      Radio.channel('navigate').trigger('show:ext:nd:license-dashboard', entityId, licenseId);
    });
  }
});
