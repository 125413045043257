var Handlebars = require("handlebars/dist/handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, helper;

  return "  <div class=\"m-b-md\">\n    <b>Instructions:</b>\n    <div>"
    + ((stack1 = ((helper = (helper = helpers.instructions || (depth0 != null ? depth0.instructions : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"instructions","hash":{},"data":data}) : helper))) != null ? stack1 : "")
    + "</div>\n  </div>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "  <div class=\"form-group\">\n    <button class=\"btn btn-primary download-additional-instructions\" csDocumentId=\""
    + alias4(((helper = (helper = helpers.csDocumentId || (depth0 != null ? depth0.csDocumentId : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"csDocumentId","hash":{},"data":data}) : helper)))
    + "\">\n      <i class=\"fa fa-download\"></i>\n      "
    + alias4(((helper = (helper = helpers.fileName || (depth0 != null ? depth0.fileName : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"fileName","hash":{},"data":data}) : helper)))
    + "\n    </button>\n  </div>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "\n<form class=\"instructions-form\">\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.instructions : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "  <div class=\"form-group\">\n    <button class=\"btn btn-primary download-instructions\">\n      <i class=\"fa fa-download\"></i>\n      "
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? depth0.instructionsDocument : depth0)) != null ? stack1.fileLabel : stack1), depth0))
    + "\n    </button>\n  </div>\n   <div class=\"certification-form\">\n    <button class=\"btn btn-primary download-certification\">\n      <i class=\"fa fa-download\"></i>\n      Certification Form\n    </button>\n  </div>\n  <br>\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.additionalInstructionDocuments : depth0),{"name":"each","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "  <div class=\"form-group required\">\n    <label class=\"control-label font-weight-normal has-read-label\">\n      <input type=\"checkbox\" class=\"has-read-checkbox\" name=\"hasReadInstructions\" value=\"1\"\n        data-parsley-required=\"true\" />\n      I have read and understood all information and Instructions\n    </label>\n  </div>\n</form>";
},"useData":true});