/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Marionette = require('backbone.marionette');

var GridView = require('psa-marionette/views/GridView');

var Config = require('../../../config/Config');

var MomentCell = require('common/backgrid/MomentCell');
var ButtonLinkCell = require('common/backgrid/ButtonLinkCell');
var LinkCell = require('common/backgrid/LinkCell');

var tmplDepIntEntityPendingTasksView = require('../templates/DepIntEntityPendingTasksView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplDepIntEntityPendingTasksView,

  className: 'dep-int-entity-pending-tasks-view container-fluid',

  ui: {
    pendingTasks: '.pending-tasks-container'
  },

  regions: {
    pendingTasks: '@ui.pendingTasks'
  },

  onBeforeShow: function() {
    var pendingTaskColumns = [
      {
        name: 'typeName',
        label: 'Task Name',
        cell: 'string',
        headerCell: 'custom',
        width: 25,
        editable: false
      },
      {
        name: 'filingType',
        label: 'Filing Type',
        cell: LinkCell.extend({
          emptyText: '--',
          href: function(model) {
            var entityId = model.get('entityId');
            var filingId = model.get('filingId');
            var url = entityId && filingId ? '#dep/entity/' + entityId + '/filing/' + filingId + '/dashboard' : '';
            return url;
          }
        }),
        headerCell: 'custom',
        width: 30,
        editable: false
      },
      {
        name: 'dueDate',
        label: 'Due Date',
        cell: MomentCell.MomentCell,
        formatter: MomentCell.MomentDateFormatter.extend({
          emptyValue: '--'
        }),
        sortValue: MomentCell.MomentSortValue,
        headerCell: 'custom',
        width: 10,
        editable: false
      },
      {
        name: 'deliveryDate',
        cell: MomentCell.MomentCell,
        formatter: MomentCell.MomentDateTimeFormatter.extend({
          emptyValue: '--'
        }),
        sortValue: MomentCell.MomentSortValue,
        label: 'Assigned On',
        headerCell: 'custom',
        width: 15,
        editable: false
      },
      {
        name: 'assignee',
        label: 'Assigned To',
        cell: 'string',
        headerCell: 'custom',
        width: 10,
        editable: false
      },
      {
        name: '',
        label: '',
        cell: ButtonLinkCell.extend({
          name: 'Open',
          buttonClass: 'btn-primary btn-sm',
          renderable: function(model) {
            var assigneeId = model.get('assigneeId');
            var isExternalTask = assigneeId === Config.getExternalUserServiceAccountUserId();
            var taskType = model.get('typeName') === 'Respond to External Correspondence';
            return !isExternalTask || taskType;
          },
          href: function(model) {
            return '#dep/task/' + model.get('taskId');
          }
        }),
        editable: false,
        sortable: false,
        headerCell: 'custom',
        width: 10
      }
    ];
    this.pendingTasksView = new GridView({
      columns: pendingTaskColumns,
      collection: this.collection,
      emptyText: 'No Pending Tasks'
    });
    this.showChildView('pendingTasks', this.pendingTasksView);
    this.pendingTasksView.viewGrid.sort('deliveryDate', 'descending');
  }
});
