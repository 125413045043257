var Handlebars = require("handlebars/dist/handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "  <div class=\"closing-cert-container\"></div>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression, alias5=container.lambda;

  return "\n<form class=\"action-form\">\n  <div class=\"row\">\n    <div class=\"col-sm-12\">\n      <div class=\"form-group required\">\n        <label class=\"control-label\">Anticipated Discontinuance Date of Branch</label>\n        <div class=\"input-group col-sm-6\">\n          <input type=\"text\" name=\"discontinuanceDate\" class=\"form-control datepicker discontinue-date\"\n            placeholder=\"Anticipated Discontinuance Date of Branch\" data-parsley-required=\"true\" data-parsley-date=\"\"\n            data-parsley-trigger-after-failure=\"changeDate\"\n            data-parsley-errors-container=\"#anticipatedDiscontinueDateError"
    + alias4(((helper = (helper = helpers.childIndex || (depth0 != null ? depth0.childIndex : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"childIndex","hash":{},"data":data}) : helper)))
    + "\" />\n          <div class=\"input-group-addon\">\n            <i class=\"fa fa-calendar\"></i>\n          </div>\n        </div>\n        <div id=\"anticipatedDiscontinueDateError"
    + alias4(((helper = (helper = helpers.childIndex || (depth0 != null ? depth0.childIndex : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"childIndex","hash":{},"data":data}) : helper)))
    + "\"></div>\n      </div>\n    </div>\n  </div>\n  <div class=\"row\">\n    <div class=\"col-sm-12\">\n      <div class=\"form-group required\">\n        <label class=\"control-label\">State the basis for the institution’s decision to discontinue operations at the branch or branches.</label>\n        <textarea type=\"text\" name=\"basisForDiscontinuance\" class=\"form-control comments\" id=\"basisForDiscontinuance"
    + alias4(alias5(((stack1 = (depth0 != null ? depth0.locationAction : depth0)) != null ? stack1.locationActivityId : stack1), depth0))
    + "\" data-parsley-required=\"true\"\n          placeholder=\"State the basis for the institution’s decision to discontinue operations at the branch or branches.\" rows=\"5\"></textarea>\n      </div>\n      <div class=\"form-group print-view\" id=\"basisForDiscontinuance"
    + alias4(alias5(((stack1 = (depth0 != null ? depth0.locationAction : depth0)) != null ? stack1.locationActivityId : stack1), depth0))
    + "PrintView\"></div>\n    </div>\n  </div>  \n  <div class=\"row\">\n    <div class=\"col-sm-12\">\n      <div class=\"form-group required\">\n        <label class=\"control-label\">Will operations or accounts from the discontinued location be transferred or consolidated to another location?</label>\n          <label class=\"radio-inline\">\n            <input type=\"radio\" name=\"isOperationTransferred\" value=\"1\" data-parsley-required=\"true\"> Yes\n          </label>\n          <label class=\"radio-inline\">\n            <input type=\"radio\" name=\"isOperationTransferred\" value=\"0\" data-parsley-required=\"true\"> No\n          </label>\n      </div>\n    </div>\n  </div>\n  <div class=\"row location-list-section\">\n    <div class=\"col-sm-12\">\n      <div class=\"form-group required\">\n        <label class=\"control-label\">Please indicate to what branch the accounts and operations will be transferred to</label>\n        <select name=\"transferredToLocationId\" class=\"form-control location-select\" data-placeholder=\"--\"\n          data-parsley-required=\"true\" data-parsley-validate-if-empty=\"true\"\n          data-parsley-trigger-after-failure=\"change.select2\">\n          <option value="
    + alias4(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "></option>\n        </select>\n      </div>\n    </div>\n  </div>\n  <div class=\"questionnaire-container\"></div>\n"
    + ((stack1 = helpers.unless.call(alias1,(depth0 != null ? depth0.isCU : depth0),{"name":"unless","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "</form>";
},"useData":true});