/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Backbone = require('backbone');
var Marionette = require('backbone.marionette');
var Radio = require('backbone.radio');

var userHasRole = require('common/util/userHasRole');
var modelGet = require('common/util/modelGet');
var ContentServerLinkView = require('common/views/ContentServerLinkView');
var SideNavSectionView = require('common/views/SideNavSectionView');
var BreadcrumbView = require('common/views/BreadcrumbView');
var DropdownView = require('common/views/DropdownView');
var HeaderBarView = require('common/views/HeaderBarView');

var SecOrganizationDetailsSectionView = require('./SecOrganizationDetailsSectionView');
var SecOrganizationPendingTasksSectionView = require('./SecOrganizationPendingTasksSectionView');

var tmplSecIntOrganizationDashboardView = require('../templates/SecIntOrganizationDashboardView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplSecIntOrganizationDashboardView,

  className: 'sec-int-organization-dashboard-view container-fluid m-b-lg',

  ui: {
    organizationWrapper: '.organization-wrapper',
    csLinkContainer: '.cs-link-container',
    organizationActionDropdown: '.dropdown-container',
    breadcrumb: '.breadcrumb-container',
    headerBar: '.header-bar-container'
  },

  regions: {
    organizationWrapper: '@ui.organizationWrapper',
    csLinkContainer: '@ui.csLinkContainer',
    organizationActionDropdown: '@ui.organizationActionDropdown',
    breadcrumb: '@ui.breadcrumb',
    headerBar: '@ui.headerBar'
  },

  onBeforeShow: function() {
    var organization = this.model.get('organization') || {};
    var organizationId = modelGet(this.model, 'organizationId');
    var isManager = userHasRole('SC - Manager');
    var isExaminer = userHasRole('SC - Examiner');
    var isAdmin = userHasRole('SC - Admin');
    var isChiefAccounting = userHasRole('SC - Chief Accounting');
    var isFilingExaminer = userHasRole('SC - Filing Examiner');

    this.showChildView(
      'csLinkContainer',
      new ContentServerLinkView({
        model: new Backbone.Model({
          buttonText: 'Open Organization Folder',
          contentServerId: organization.CSFolderId
        })
      })
    );

    this.showChildView(
      'breadcrumb',
      new BreadcrumbView({
        breadcrumbs: [
          {
            link: '#sec/dashboard',
            text: 'Home'
          },
          {
            text: organization.applicantLegalName
          }
        ]
      })
    );

    var dropDownOptions = [
      {
        text: 'Edit Organization Information',
        link: '#sec/organization/' + organizationId + '/edit'
      },
      {
        isDivider: true
      },
      {
        text: 'Add Investigation/Inquiry',
        link: '#sec/organization/' + organizationId + '/new-investigation-inquiry'
      }
    ];

    if (isManager || isExaminer || isChiefAccounting || isFilingExaminer) {
      dropDownOptions.push({
        text: 'Initiate Correspondence',
        link: '#sec/organization/' + organizationId + '/initiate-correspondence'
      });
    }

    if (isAdmin) {
      dropDownOptions.push({
        text: 'Add Registration',
        link: '#sec/organization/' + organizationId + '/new-registration'
      });
    }

    var headerBarColumns = [
      {
        name: 'CRDNumber',
        label: 'CRD Number',
        minWidth: '160px',
        maxWidth: '200px'
      }
    ];

    organization.title = 'Organization';
    this.showChildView(
      'headerBar',
      new HeaderBarView({
        columns: new Backbone.Collection(headerBarColumns),
        model: new Backbone.Model(organization)
      })
    );

    var sideNavTabs = [
      {
        id: 'pendingTasks',
        title: 'Pending Tasks',
        view: new SecOrganizationPendingTasksSectionView({
          model: new Backbone.Model({
            tasks: this.model.get('tasks')
          })
        })
      },
      {
        id: 'main',
        title: 'Organization',
        view: new SecOrganizationDetailsSectionView({
          model: new Backbone.Model({
            organization: organization
          })
        })
      },
      {
        id: 'license',
        title: 'Licenses',
        view: Radio.channel('sc').request('build:organization-license-view', organizationId)
      },
      {
        id: 'exams',
        title: 'Exams',
        view: Radio.channel('sc').request('build:organization-exams-section-view', {
          organizationId: organizationId
        })
      },
      {
        id: 'invinq',
        title: 'Investigations/Inquiries',
        view: Radio.channel('sc').request('build:related-inv-inq-section-view', {
          objectTypeId: 'ORGANIZATION',
          objectId: organizationId
        })
      },
      {
        id: 'userManagement',
        title: 'External User Management',
        view: Radio.channel('sc').request('build:user-management-section-view', organizationId)
      },
      {
        id: 'notes',
        title: 'Notes',
        view: Radio.channel('sc').request('build:notes-view', {
          objectId: organizationId,
          objectTypeId: 'ORGANIZATION'
        })
      },
      {
        id: 'auditLog',
        title: 'Audit Log',
        view: Radio.channel('sc').request('build:audit-log-view', {
          objectId: organizationId,
          objectTypeId: 'ORGANIZATION'
        })
      }
    ];

    this.showChildView(
      'organizationActionDropdown',
      new DropdownView({
        rightAlign: true,
        model: new Backbone.Model({
          name: 'Organization Actions',
          options: dropDownOptions
        })
      })
    );

    this.organization = new SideNavSectionView({
      collection: new Backbone.Collection(sideNavTabs)
    });

    this.showChildView('organizationWrapper', this.organization);
  }
});
