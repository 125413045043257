var Handlebars = require("handlebars/dist/handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression;

  return "      <div class=\"checkbox\">\n        <input type=\"checkbox\" id=\""
    + alias2(alias1((depth0 != null ? depth0.id : depth0), depth0))
    + "\" name=\""
    + alias2(alias1((depth0 != null ? depth0.id : depth0), depth0))
    + "\" value=\""
    + alias2(alias1((depth0 != null ? depth0.id : depth0), depth0))
    + "\">\n        <label for=\""
    + alias2(alias1((depth0 != null ? depth0.id : depth0), depth0))
    + "\">"
    + alias2(alias1((depth0 != null ? depth0.description : depth0), depth0))
    + "</label>\n      </div>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "\n\n<div class=\"form-horizontal\">\n  <form class=\"complaint-issue-form\">\n    <div class=\"form-group required checkbox-question\">\n      <label class=\"control-label\">What is your complaint about? Please check all that apply.</label>\n    </div>\n    <div class=\"checkbox-row\">\n"
    + ((stack1 = helpers.each.call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.complaintIssues : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "    </div>\n    <br>\n    <div class=\"form-group required other-issue-form\">\n      <label class=\"control-label col-sm-3\">Other Service/Product Issues</label>\n      <div class=\"col-sm-12\">\n        <textArea name=\"otherIssue\" class=\"form-control entity-name-input\" placeholder=\"Description of Other Issues\"\n          data-parsley-required=\"true\" data-parsley-maxlength=\"25\" />\n        <p class=\"input-label\"><i class=\"fa fa-info-circle\"></i> Character limit: 25 char.</p>\n      </div>\n    </div>\n  </form>\n</div>";
},"useData":true});