/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2020] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Backbone = require('backbone');
var Marionette = require('backbone.marionette');
var Syphon = require('backbone.syphon');

var AddressView = require('common/views/AddressView');

var tmplConSerEntityFormView = require('../templates/ConSerEntityFormView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplConSerEntityFormView,

  className: 'con-ser-entity-form-view',

  ui: {
    addressFormContainer: '.address-form-container'
  },

  behaviors: {
    PhoneFormatBehavior: {}
  },

  regions: {
    addressFormContainer: '@ui.addressFormContainer'
  },

  templateHelpers: function() {
    return {
      isCSEntity: this.model.get('departmentId') === '4000'
    };
  },

  onBeforeShow: function() {
    var entity = this.model.toJSON();

    this.addressForm = new AddressView({
      model: new Backbone.Model(entity),
      horizontal: true,
      isRequired: false,
      startingCountry: entity.country,
      startingPostalCode: entity.postalCode,
      startingCounty: entity.county
    });

    this.showChildView('addressFormContainer', this.addressForm);
    Syphon.deserialize(this, entity);
  },

  getFormData: function() {
    var formData = Syphon.serialize(this);
    return formData;
  }
});
