/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Backbone = require('backbone');
var Marionette = require('backbone.marionette');
var _remove = require('lodash.remove');
var Radio = require('backbone.radio');

var modelGet = require('common/util/modelGet');
var HeaderBarView = require('common/views/HeaderBarView');
var HeaderBarLinkCellView = require('common/views/HeaderBarView/HeaderBarLinkCellView');
var BreadcrumbView = require('common/views/BreadcrumbView');
var BigTabsView = require('common/views/BigTabsView');
var NMLSLabelView = require('../../common/views/NMLSLabelView');
var ContentServerLinkView = require('common/views/ContentServerLinkView');

var tmplInternalApplicationReviewView = require('../templates/InternalApplicationReviewView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplInternalApplicationReviewView,

  className: 'internal-application-review-view container-fluid',

  ui: {
    nmlsLabelContainer: '.nmls-label-container',
    csLinkContainer: '.cs-link-container',
    headerBar: '.header-bar',
    breadcrumb: '.breadcrumb-container',
    taskSections: '.task-sections'
  },

  regions: {
    nmlsLabelContainer: '@ui.nmlsLabelContainer',
    csLinkContainer: '@ui.csLinkContainer',
    headerBar: '@ui.headerBar',
    breadcrumb: '@ui.breadcrumb',
    taskSections: '@ui.taskSections'
  },

  behaviors: {
    TaskBehavior: {}
  },

  onBeforeShow: function() {
    var applicationDetails = this.model.get('applicationDetails') || {};

    if (modelGet(this.model, 'applicationDetails.isNMLSEntity')) {
      this.showChildView(
        'nmlsLabelContainer',
        new NMLSLabelView({
          model: new Backbone.Model({
            objectName: 'entity'
          })
        })
      );
    }

    this.showChildView(
      'csLinkContainer',
      new ContentServerLinkView({
        model: new Backbone.Model({
          buttonText: 'Open Application Folder',
          contentServerId: applicationDetails.CSFolderId
        })
      })
    );

    var headerBarColumns = [
      {
        name: 'entityName',
        label: 'Entity Name',
        minWidth: '160px',
        maxWidth: '300px'
      },
      {
        name: 'tin',
        label: 'Federal ID Number',
        minWidth: '160px'
      },
      {
        name: 'applicationStatus',
        label: 'Application Status',
        minWidth: '160px',
        maxWidth: '200px',
        overflow: 'truncate'
      },
      {
        name: 'locationName',
        label: 'Location',
        minWidth: '160px',
        maxWidth: '350px',
        overflow: 'truncate'
      },
      {
        name: 'webAddress',
        label: 'Web Address',
        width: '350px',
        overflow: 'truncate',
        headerCell: HeaderBarLinkCellView
      }
    ];

    // If the application does not contain details about a specific location,
    // remove that column
    if (!this.model.get('applicationDetails').hasLocation) {
      _remove(headerBarColumns, function(headerBarColumns) {
        return headerBarColumns.name === 'locationName';
      });
    }

    var isRenewal = modelGet(this.model, 'applicationDetails.applicationCategoryId') === '2';
    if (isRenewal) {
      headerBarColumns.splice(3, 0, {
        name: 'currentLicenseStatus',
        label: 'Current License Status',
        minWidth: '200px',
        maxWidth: '350px',
        overflow: 'truncate'
      });
    }

    this.showChildView(
      'headerBar',
      new HeaderBarView({
        columns: new Backbone.Collection(headerBarColumns),
        model: new Backbone.Model(this.model.get('applicationDetails'))
      })
    );

    this.showChildView(
      'breadcrumb',
      new BreadcrumbView({
        breadcrumbs: [
          {
            link: '#nondep/dashboard',
            text: 'Home'
          },
          {
            text: 'Review Application for ' + this.model.get('applicationDetails').applicationType
          }
        ]
      })
    );

    var taskSections = [
      {
        id: 'entity',
        title: 'Entity',
        subtitle: 'Submitting entity',
        faIconClass: 'fa-building',
        view: this.options.entityFormView
      },
      {
        id: 'application',
        title: 'Application',
        subtitle: 'Contents of application',
        faIconClass: 'fa-envelope',
        view: this.options.applicationFormView
      },
      {
        id: 'warnings',
        title: 'RICS Compliance',
        subtitle: 'Potential issues',
        faIconClass: 'fa-exclamation-triangle',
        view: Radio.channel('nd').request('build:entity-rics-compliance-view', {
          tin: this.model.get('applicationDetails').tin
        })
      },
      {
        id: 'work',
        title: 'Investigation Review',
        subtitle: 'Review this application',
        faIconClass: 'fa-pencil',
        view: this.options.workTabView
      }
    ];

    // If the entity is verified, remove the entity tab
    if (this.model.get('applicationDetails').entityIsVerified) {
      taskSections.splice(0, 1);
    }

    this.showChildView(
      'taskSections',
      new BigTabsView({
        collection: new Backbone.Collection(taskSections)
      })
    );
  }
});
