/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Marionette = require('backbone.marionette');
var Syphon = require('backbone.syphon');

require('../styles/CorpFinInvPortfolioClassFormView.css');
var tmplCorpFinInvPortfolioClassFormView = require('../templates/CorpFinInvPortfolioClassFormView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplCorpFinInvPortfolioClassFormView,

  className: 'corp-fin-inv-portfolio-class-form-view',

  ui: {
    removeClass: '.remove-class'
  },

  triggers: {
    'click @ui.removeClass': 'remove:class'
  },

  templateHelpers: function() {
    return {
      childIndex: this.options.childIndex + 1
    };
  },

  onBeforeShow: function() {
    Syphon.deserialize(this, this.model.toJSON());
  },

  getFormData: function() {
    var formData = Syphon.serialize(this);
    return formData;
  }
});
