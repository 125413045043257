/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtaineds
 * from Counterpoint Consulting Incorporated.
 */

var Marionette = require('backbone.marionette');

var GridView = require('psa-marionette/views/GridView');
var PageableCollection = require('psa-backbone/models/PageableCollection');

var ButtonLinkCell = require('common/backgrid/ButtonLinkCell');

var NonDepInternalSidebarNavView = require('../../common/views/NonDepInternalSidebarNavView');
require('../styles/NonDepInternalUnverifiedEntitiesView.css');

var tmplNonDepInternalUnverifiedEntityView = require('../templates/NonDepInternalUnverifiedEntitiesView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplNonDepInternalUnverifiedEntityView,

  className: 'non-dep-internal-unverified-entity-view container-fluid',

  ui: {
    sideBar: '.side-bar-container',
    unverifiedEntities: '.unverified-entities-container'
  },

  regions: {
    sideBar: '@ui.sideBar',
    unverifiedEntities: '@ui.unverifiedEntities'
  },

  onBeforeShow: function() {
    this.showChildView('sideBar', new NonDepInternalSidebarNavView());

    var unverifiedEntitiesGrid = new GridView({
      columns: [
        {
          name: 'entityName',
          label: 'Entity Name',
          cell: 'string',
          headerCell: 'custom',
          width: 40,
          editable: false
        },
        {
          name: 'dba',
          label: 'D/B/A',
          cell: 'string',
          headerCell: 'custom',
          width: 40,
          editable: false
        },
        {
          name: '',
          label: '',
          cell: ButtonLinkCell.extend({
            name: 'Open',
            buttonClass: 'btn-primary btn-sm',
            href: function(model) {
              return '#nondep/entity/' + model.get('id') + '/dashboard';
            }
          }),
          headerCell: 'custom',
          width: 20,
          editable: false,
          sortable: false
        }
      ],
      collection: new PageableCollection(this.model.get('unverifiedEntities'), { pageSize: 10 }),
      emptyText: 'No Current Unverified Entities'
    });
    this.showChildView('unverifiedEntities', unverifiedEntitiesGrid);
  }
});
