/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Marionette = require('backbone.marionette');

var Session = require('psa-core/Session');
var UIUtil = require('psa-core/util/ui');
var PageableCollection = require('psa-backbone/models/PageableCollection');

var PanelGridView = require('common/views/PanelGridView');
var ButtonsPanelCellView = require('common/views/PanelGridView/ButtonsPanelCellView');

var NonDepEntityJoinCodeFormModalView = require('./NonDepEntityJoinCodeFormModalView');

var tmplNonDepEntitySectionUserManagementView = require('../templates/NonDepEntitySectionUserManagementView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplNonDepEntitySectionUserManagementView,

  className: 'nondep-entity-user-management-view',

  ui: {
    authorizedUsersContainer: '.authorized-users-container',
    invitedUsersContainer: '.invited-users-container',
    inviteButton: '#inviteButton'
  },

  regions: {
    authorizedUsersContainer: '@ui.authorizedUsersContainer',
    invitedUsersContainer: '@ui.invitedUsersContainer',
    inviteButton: '@ui.inviteButton'
  },

  events: {
    'click @ui.inviteButton': 'onClickInvite'
  },

  onBeforeShow: function() {
    var entityId = this.model.get('entityId');
    var isExternal = this.options.viewMode === 'ext';
    var isInternal = this.options.viewMode === 'int';

    var authorizedUserColumns = [
      {
        name: 'firstName',
        label: 'First Name',
        width: 20,
        overflow: 'truncate'
      },
      {
        name: 'lastName',
        label: 'Last Name',
        width: 20,
        overflow: 'truncate'
      },
      {
        name: 'email',
        label: 'Email',
        width: 25,
        overflow: 'truncate'
      },
      {
        name: 'accessLevel',
        label: 'Access Level',
        width: 15,
        overflow: 'truncate'
      },
      {
        name: '',
        label: '',
        width: 20,
        panelCell: ButtonsPanelCellView.extend({
          buttons: [
            {
              buttonText: 'Manage User',
              href: function(model) {
                var userId = model.get('id');
                if (isInternal) {
                  return '#nondep/entity/' + entityId + '/user/' + userId;
                }
                if (isExternal) {
                  return '#ext/nondep/entity/' + entityId + '/user/' + userId;
                }
                return '#';
              },
              buttonClass: function(model) {
                var baseClass = 'btn-primary btn-md m-t-xs';
                if (model.get('userId') === Session.user.userId) {
                  baseClass += ' collapse disabled';
                }
                return baseClass;
              }
            }
          ]
        })
      }
    ];

    var externalAdministratorAccessLevelId = 30;
    if (this.options.userAccessLevel.accessLevelId < externalAdministratorAccessLevelId) {
      authorizedUserColumns.splice(-1, 1);
      this.ui.inviteButton.hide();
    }

    var authorizedUsers = new PanelGridView({
      columns: authorizedUserColumns,
      collection: new PageableCollection(this.model.get('authorizedUsers'), { pageSize: 10 }),
      emptyText: 'No Authorized Users'
    });

    this.invitedUsers = new PanelGridView({
      columns: [
        {
          name: 'firstName',
          label: 'First Name',
          width: 20,
          overflow: 'truncate'
        },
        {
          name: 'lastName',
          label: 'Last Name',
          width: 20,
          overflow: 'truncate'
        },
        {
          name: 'email',
          label: 'Email',
          width: 25,
          overflow: 'truncate'
        },
        {
          name: 'accessLevel',
          label: 'Access Level',
          width: 35,
          overflow: 'truncate'
        }
      ],
      collection: new PageableCollection(this.model.get('invitedUsers'), { pageSize: 10 }),
      emptyText: 'No Invited Users'
    });

    this.showChildView('authorizedUsersContainer', authorizedUsers);
    this.showChildView('invitedUsersContainer', this.invitedUsers);
  },

  onClickInvite: function(e) {
    e.preventDefault();

    var self = this;
    UIUtil.showModal({
      title: 'Invite a User to Join ' + this.model.get('entityName'),
      model: this.model,
      view: new NonDepEntityJoinCodeFormModalView()
    }).done(function(newJoinCode) {
      self.invitedUsers.collection.add(newJoinCode);
    });
  }
});
