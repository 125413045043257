/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var GridView = require('psa-marionette/views/GridView');

var ListCell = require('common/backgrid/ListCell');
var MomentCell = require('common/backgrid/MomentCell');
var ButtonLinkCell = require('common/backgrid/ButtonLinkCell');

module.exports = GridView.extend({
  className: 'dep-entity-seg-view',

  options: {
    columns: [
      {
        name: 'name',
        label: 'Name',
        cell: 'string',
        headerCell: 'custom',
        width: 20,
        editable: false
      },
      {
        name: 'segAddressDisplay',
        label: 'City/State',
        cell: 'string',
        headerCell: 'custom',
        width: 10,
        editable: false,
        sortable: false
      },
      {
        name: 'segIndividualList',
        label: 'Type of Individuals',
        cell: ListCell,
        headerCell: 'custom',
        width: 14,
        editable: false,
        sortable: false
      },
      {
        name: 'employeeCount',
        label: 'No. of Individuals',
        cell: 'string',
        headerCell: 'custom',
        width: 14,
        editable: false
      },
      {
        name: 'status',
        label: 'Status',
        cell: 'string',
        headerCell: 'custom',
        width: 8,
        editable: false
      },
      {
        name: 'approvalDate',
        label: 'Approval Date',
        cell: MomentCell.MomentCell,
        formatter: MomentCell.MomentDateFormatter.extend({
          emptyValue: '--'
        }),
        sortValue: MomentCell.MomentSortValue,
        headerCell: 'custom',
        width: 13,
        editable: false
      },
      {
        name: 'terminationDate',
        label: 'Termination Date',
        cell: MomentCell.MomentCell,
        formatter: MomentCell.MomentDateFormatter.extend({
          emptyValue: '--'
        }),
        sortValue: MomentCell.MomentSortValue,
        headerCell: 'custom',
        width: 13,
        editable: false
      },
      {
        name: '',
        label: '',
        cell: ButtonLinkCell.extend({
          name: 'Open',
          buttonClass: 'btn-primary btn-sm',
          href: function(model) {
            var entityId = model.get('entityId');
            var segId = model.get('id');
            return '#dep/entity/' + entityId + '/seg/' + segId + '/dashboard';
          }
        }),
        headerCell: 'custom',
        width: 8,
        editable: false,
        sortable: false
      }
    ],
    emptyText: 'No SEGs'
  }
});
