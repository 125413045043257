var Handlebars = require("handlebars/dist/handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "\n<div class=\"row share-registered-row sub-form-vertical m-b-sm\">\n  <div class=\"col-sm-3\">\n    <div class=\"form-group required\">            \n      <input type=\"text\" class=\"form-control\" name=\"name\" placeholder=\"Full name of each acquirer or transferee\" data-parsley-required=\"true\"\n        data-parsley-maxlength=\"100\" data-parsley-errors-container=\"#nameError"
    + alias4(((helper = (helper = helpers.childIndex || (depth0 != null ? depth0.childIndex : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"childIndex","hash":{},"data":data}) : helper)))
    + "\" />\n      <div id=\"nameError"
    + alias4(((helper = (helper = helpers.childIndex || (depth0 != null ? depth0.childIndex : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"childIndex","hash":{},"data":data}) : helper)))
    + "\"></div>\n    </div>\n  </div>\n  <div class=\"col-sm-2\">\n    <div class=\"form-group required\">\n      <input type=\"text\" class=\"form-control\" name=\"nowOwned\" placeholder=\"%\"\n        data-parsley-maxlength=\"100\" data-parsley-errors-container=\"#nowOwnedError"
    + alias4(((helper = (helper = helpers.childIndex || (depth0 != null ? depth0.childIndex : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"childIndex","hash":{},"data":data}) : helper)))
    + "\" />\n      <div id=\"nowOwnedError"
    + alias4(((helper = (helper = helpers.childIndex || (depth0 != null ? depth0.childIndex : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"childIndex","hash":{},"data":data}) : helper)))
    + "\"></div>\n    </div>\n  </div>\n  <div class=\"col-sm-3\">\n    <div class=\"form-group required\">\n      <input type=\"text\" class=\"form-control\" name=\"toBeOwned\" placeholder=\"%\"\n        data-parsley-maxlength=\"100\" data-parsley-errors-container=\"#toBeOwnedError"
    + alias4(((helper = (helper = helpers.childIndex || (depth0 != null ? depth0.childIndex : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"childIndex","hash":{},"data":data}) : helper)))
    + "\" />\n      <div id=\"toBeOwnedError"
    + alias4(((helper = (helper = helpers.childIndex || (depth0 != null ? depth0.childIndex : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"childIndex","hash":{},"data":data}) : helper)))
    + "\"></div>\n    </div>\n  </div>\n  <div class=\"col-sm-3\">\n    <div class=\"form-group required\">\n      <input type=\"text\" class=\"form-control\" name=\"afterAcquisition\" placeholder=\"%\"\n        data-parsley-maxlength=\"100\" data-parsley-errors-container=\"#afterAcquisitionError"
    + alias4(((helper = (helper = helpers.childIndex || (depth0 != null ? depth0.childIndex : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"childIndex","hash":{},"data":data}) : helper)))
    + "\" />\n      <div id=\"afterAcquisitionError"
    + alias4(((helper = (helper = helpers.childIndex || (depth0 != null ? depth0.childIndex : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"childIndex","hash":{},"data":data}) : helper)))
    + "\"></div>\n    </div>\n  </div>\n  <div class=\"col-sm-1 m-b-n remove-container\">\n    <button class=\"btn btn-danger btn-sm remove-share-registered\">\n      <i class=\"fa fa-times\" aria-hidden=\"true\"></i>\n    </button>\n  </div>\n</div>";
},"useData":true});