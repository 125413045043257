/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');

/**
 * Given an array of User objects, format the user-name to "{Last Name}, {First Name}".
 * User object elements: {userId, name}. Name is LDAP [description] field of "{firstName lastName}"
 * Note: return as-is for name not matching "{firstName lastName}" format.
 *
 * @param {array} $users The array of user-objects.
 * @returns {array} user-objects with formatted names, sorted (case insensitive) by formatted names
 *
 * @example
 *
 * examiners = [
 *   {userId: "hchau", name: "Howard Chau"},
 *   {userId: "c-alveluz", name: "Aldrich-Benjamin Veluz"},
 *   {userId: "rsemanick", name: "Robert Semanick"},
 *   {userId: "svc01", name: "Internal Service Account"}
 * ];
 *
 * formatUserNamesLastFirst(examiners)
 * // => [
 *   {userId: "hchau", name: "Chau, Howard"},
 *   {userId: "svc01", name: "Internal Service Account"}
 *   {userId: "rsemanick", name: "Semanick, Robert"},
 *   {userId: "c-alveluz", name: "Veluz; Aldrich-Benjamin"},
 * ];
 */
module.exports = function(users) {
  var formatted = _.chain(users)
    .map(function(user) {
      return { userId: user.userId, name: user.name.replace(/^([a-zA-Z\-.]+)\s([a-zA-Z\-.]+)$/, '$2, $1') };
    })
    .sortBy(function(user) {
      return user.name.toUpperCase();
    })
    .value();
  return formatted;
};
