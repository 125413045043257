/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Backbone = require('backbone');
var ComplianceHeaderBarView = require('../../common/views/ComplianceHeaderBarView');
var ComplianceSummaryView = require('../views/ComplianceSummaryView');
var BreadcrumbView = require('common/views/BreadcrumbView');

module.exports = function(data) {
  var complianceModel = new Backbone.Model(data.complianceDetails);

  return {
    complianceHeaderBar: new ComplianceHeaderBarView({
      model: complianceModel
    }),
    complianceSummary: new ComplianceSummaryView({
      model: complianceModel
    }),
    complianceBreadcrumbs: new BreadcrumbView({
      breadcrumbs: [
        {
          link: data.viewMode === 'int' ? '#nondep/dashboard' : '#ext/nondep/dashboard',
          text: 'Home'
        },
        {
          text: data.task.taskType.description
        }
      ]
    })
  };
};
