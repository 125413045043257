/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var LicensingChiefReviewApplicationWorkTabView = require('../LicensingChiefReviewApplicationTask/LicensingChiefReviewApplicationWorkTabView');

var toggleForm = require('common/util/toggleForm');

var tmplInvestigationManagerReviewApplicationWorkTabView = require('./InvestigationManagerReviewApplicationWorkTabView.hbs');

module.exports = LicensingChiefReviewApplicationWorkTabView.extend({
  template: tmplInvestigationManagerReviewApplicationWorkTabView,

  className: 'investigation-manager-review-application-work-tab-view container-fluid',

  onChangeReviewDecision: function() {
    var reviewDecision = this.ui.reviewDecision.val();
    var isApproved = reviewDecision === '1301';
    toggleForm(this.ui.decisionReasonContainer, !isApproved && reviewDecision);
  }
});
