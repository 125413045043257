/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Marionette = require('backbone.marionette');
var Radio = require('backbone.radio');
var Ladda = require('ladda');
var moment = require('moment');

var modelGet = require('common/util/modelGet');
var BreadcrumbView = require('common/views/BreadcrumbView');

var SubmitInternalNewIndividual = require('../../individual-dashboard/services/SubmitInternalNewIndividual');
var NonDepIndividualView = require('../../common/views/NonDepIndividualView');
var LicenseHeaderBarView = require('../../common/views/LicenseHeaderBarView');

var tmplNonDepNewLicenseOfficeManagerView = require('../templates/NonDepNewLicenseOfficeManagerView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplNonDepNewLicenseOfficeManagerView,

  className: 'non-dep-new-license-office-manager container-fluid',

  ui: {
    headerBarContainer: '.header-bar-container',
    breadCrumbContainer: '.breadcrumb-container',
    nondepNewLicenseOfficeManagerContainer: '.non-dep-new-license-office-manager-container',
    submitButton: '.submit-button'
  },

  regions: {
    headerBarContainer: '@ui.headerBarContainer',
    breadCrumbContainer: '@ui.breadCrumbContainer',
    nondepNewLicenseOfficeManagerContainer: '@ui.nondepNewLicenseOfficeManagerContainer'
  },

  events: {
    'click @ui.submitButton': 'onClickSubmit'
  },

  onBeforeShow: function() {
    var entityId = this.model.get('entityId');
    var licenseId = this.model.get('licenseId');
    var licenseType = modelGet(this.model, 'licenseIdentifiers.licenseType');
    var licenseNumber = modelGet(this.model, 'licenseIdentifiers.licenseNumber');
    var entityName = modelGet(this.model, 'licenseIdentifiers.entityName');

    this.showChildView(
      'headerBarContainer',
      new LicenseHeaderBarView({
        model: new Backbone.Model(this.model.get('licenseIdentifiers'))
      })
    );

    this.showChildView(
      'breadCrumbContainer',
      new BreadcrumbView({
        breadcrumbs: [
          {
            text: 'Home',
            link: '#nondep/dashboard'
          },
          {
            text: entityName,
            link: '#nondep/entity/' + entityId + '/dashboard'
          },
          {
            text: 'License #' + licenseNumber + ' - ' + licenseType,
            link: '#nondep/entity/' + entityId + '/license/' + licenseId
          },
          {
            text: 'Edit Branch Manager'
          }
        ]
      })
    );

    this.individualForm = new NonDepIndividualView({
      fullProfileRequired: false,
      individualType: this.options.individualType,
      officerTypes: this.model.get('officerTypes'),
      model: this.model,
      isReadOnly: this.model.get('isNMLSIndividual')
    });
    this.showChildView('nondepNewLicenseOfficeManagerContainer', this.individualForm);
  },

  onClickSubmit: function(e) {
    e.preventDefault();
    var self = this;

    this.validate().done(function() {
      var laddaContext = Ladda.create(self.ui.submitButton.get(0));
      laddaContext.start();
      SubmitInternalNewIndividual(self.getFormData())
        .done(function(newIndividualId) {
          Radio.trigger('navigate').trigger('show:individual-dashboard', newIndividualId);
        })
        .fail(function(err) {
          laddaContext.stop();
        });
    });
  },

  validate: function() {
    return this.ui.nondepNewLicenseOfficeManagerContainer
      .find('form')
      .parsley()
      .whenValidate();
  },

  getFormData: function() {
    var formData = this.individualForm.getFormData();
    formData.licenseId = this.model.get('licenseId');
    formData.dob = formData.dob ? moment(formData.dob, 'MM/DD/YYYY').format('YYYY-MM-DD') : undefined;

    return formData;
  }
});
