/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2020] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Backbone = require('backbone');
var NProgress = require('nprogress');

var NavUtils = require('psa-core/util/nav');

var Scaffold = require('common/layout/Scaffold');

var GetEntity = require('../services/GetEntity');
var GetAllSubmitAuditLookups = require('../../filing-forms/services/GetAllSubmitAuditLookups');
var GetExternalTrustFundsByEntity = require('../services/GetExternalTrustFundsByEntity');

var DepExtEntityInitiateAuditView = require('../views/DepExtEntityInitiateAuditView');

module.exports = function(entityId) {
  NProgress.start();
  NProgress.set(0.6);
  $.when(GetEntity(entityId), GetExternalTrustFundsByEntity(entityId))
    .done(function(entityDetails, trustFunds) {
      GetAllSubmitAuditLookups()
        .done(function(auditTypes) {
          NavUtils.navigate('#ext/dep/entity/' + entityId + '/initiate-audit');
          Scaffold.content.show(
            new DepExtEntityInitiateAuditView({
              isReadOnly: false,
              model: new Backbone.Model({
                entityId: entityId,
                auditTypes: auditTypes,
                entityDetails: entityDetails,
                trustFunds: trustFunds
              })
            })
          );
          NProgress.done();
        })
        .fail(function(err) {
          NProgress.done();
        });
    })
    .fail(function(err) {
      NProgress.done();
    });
};
