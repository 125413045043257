/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Backbone = require('backbone');
var NProgress = require('nprogress');

var GetDocumentTypesByDepartment = require('common/services/GetDocumentTypesByDepartment');

var GetCorrespondence = require('../../services/GetCorrespondence');
var SecRespondCorrespondenceTaskView = require('./SecRespondCorrespondenceTaskView');

module.exports = {
  event: 'build:sc:respond-correspondence',

  channel: 'task',

  builder: function(task) {
    var defer = $.Deferred();
    var correspondenceId = task.taskData.SC.scCorrespondenceId;
    $.when(GetCorrespondence(correspondenceId), GetDocumentTypesByDepartment('SECURITIES'))
      .done(function(correspondence, documentTypes) {
        NProgress.set(0.85);
        defer.resolve(
          new SecRespondCorrespondenceTaskView({
            model: new Backbone.Model({
              task: task,
              taskId: task.taskId,
              correspondenceId: correspondenceId,
              correspondence: correspondence,
              documentTypes: documentTypes
            })
          })
        );
      })
      .fail(function(err) {
        defer.reject(err);
      });
    return defer.promise();
  }
};
