/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */
var _ = require('underscore');
var Marionette = require('backbone.marionette');

var LicenseContactView = require('./LicenseContactView');

module.exports = Marionette.CollectionView.extend({
  className: 'contact-collection-view',

  childView: LicenseContactView,

  ui: {},

  childViewOptions: function(model, index) {
    // Pass the index to the collection view
    return {
      contactTypes: this.options.contactTypes,
      childIndex: index,
      viewMode: this.options.viewMode,
      isReadOnly: this.options.isReadOnly
    };
  },

  childEvents: {
    'copy:contact': 'onChildClickCopyContact',
    'remove:contact': 'onChildClickRemoveContact'
  },

  onBeforeShow: function() {
    if (this.options.isAmendment) {
      this.deletedContacts = [];
    }
  },

  onChildClickCopyContact: function(childView) {
    this.collection.unshift(childView.getFormData());
  },

  onChildClickRemoveContact: function(childView) {
    // Keep track of deletions to display on internal amendment review page
    var deletedContact = childView.model;
    if (this.options.isAmendment) {
      var deletedContactId = deletedContact.get('id');

      if (deletedContactId) {
        deletedContact.set({ isDeleted: '1' });
        this.deletedContacts.push(deletedContact.toJSON());
      } else {
        deletedContact.set({ isDeleted: '0' });
      }
    }

    this.collection.remove(deletedContact);
  },

  hasOneOfEachContacts: function() {
    var requiredContactTypeIds = _.pluck(this.options.requiredContactTypes, 'id');

    // if there's fewer contacts than there are types, return false
    var numContactTypes = requiredContactTypeIds.length;
    if (this.collection.length < numContactTypes) {
      return false;
    }

    // compare entered contact types to required options
    var selectedContactTypes = this.children.map(function(child) {
      return child.getSelectedContactType();
    });
    return _.intersection(requiredContactTypeIds, selectedContactTypes).length === requiredContactTypeIds.length;
  },

  getFormData: function() {
    var isAmendment = this.options.isAmendment;

    var deletedContacts = [];
    if (this.model) {
      deletedContacts = this.model.get('deletedContacts') || [];
    }

    var formData = this.children.map(function(child) {
      return child.getFormData();
    });

    if (isAmendment && this.deletedContacts.length) {
      formData = formData.concat(this.deletedContacts);
    }

    // Include prevsiously deleted books and records in formData (resubmissions)
    if (isAmendment && deletedContacts.length) {
      formData = formData.concat(deletedContacts);
    }

    return formData;
  }
});
