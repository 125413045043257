/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var NProgress = require('nprogress');

var NavUtils = require('psa-core/util/nav');

var Scaffold = require('common/layout/Scaffold');

var GetLicenseIdentifiers = require('../services/GetLicenseIdentifiers');
var GetLicenseBondByID = require('../../common/services/GetLicenseBondByID');
var GetAllBondCompanies = require('../services/GetAllBondCompanies');
var GetAllBondType = require('../services/GetAllBondType');
var GetAllBondStatus = require('../services/GetAllBondStatus');

var NonDepEditLicenseBondView = require('../views/NonDepEditLicenseBondView');

module.exports = function(entityId, licenseId, bondId) {
  NProgress.start();
  NProgress.set(0.6);

  $.when(
    GetLicenseBondByID(bondId),
    GetLicenseIdentifiers(licenseId),
    GetAllBondCompanies(),
    GetAllBondType(),
    GetAllBondStatus()
  )
    .done(function(licenseBond, licenseIdentifiers, entityBondCompanies, entityBondType, entityBondStatus) {
      NavUtils.navigate('nondep/entity/' + entityId + '/license/' + licenseId + '/edit-bond/' + bondId);
      Scaffold.content.show(
        new NonDepEditLicenseBondView({
          viewMode: 'int',
          model: new Backbone.Model({
            entityId: entityId,
            licenseId: licenseId,
            licenseBond: licenseBond,
            entityBondCompanies: entityBondCompanies,
            entityBondType: entityBondType,
            entityBondStatus: entityBondStatus,
            licenseIdentifiers: licenseIdentifiers
          })
        })
      );
      NProgress.done();
    })

    .fail(function() {
      NProgress.done();
    });
};
