/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Backbone = require('backbone');
var NProgress = require('nprogress');

var GetApplicationIndividuals = require('../../services/GetApplicationIndividuals');
var GetInternalApplicationReview = require('../../services/GetInternalApplicationReview');
var GetNewBranchApplicationTypes = require('../../services/GetNewBranchApplicationTypes');
var GetAllBondCompanies = require('../../../license-dashboard/services/GetAllBondCompanies');
var GetAllBondType = require('../../../license-dashboard/services/GetAllBondType');
var GetAllBondStatus = require('../../../license-dashboard/services/GetAllBondStatus');

var InternalApplicationReviewView = require('../../views/InternalApplicationReviewView');

var AO1ReviewApplicationWorkTabView = require('./AO1ReviewApplicationWorkTabView');

module.exports = {
  event: 'build:nd:ao1-review-application',

  channel: 'task',

  builder: function(task) {
    var defer = $.Deferred();

    var applicationId = task.taskData.ND.applicationId;

    $.when(
      GetInternalApplicationReview(applicationId),
      GetApplicationIndividuals(applicationId),
      GetNewBranchApplicationTypes(),
      GetAllBondCompanies(),
      GetAllBondType(),
      GetAllBondStatus()
    )
      .done(function(
        results,
        applicationIndividuals,
        newBranchApplicationTypes,
        entityBondCompanies,
        entityBondType,
        entityBondStatus
      ) {
        NProgress.set(0.85);

        var applicationDetails = results.applicationDetails;
        var entityForm = results.entityForm;
        var applicationFormView = results.applicationFormView;
        var previousReviewDecision = results.previousReviewDecision;

        var workTabView = new AO1ReviewApplicationWorkTabView({
          model: new Backbone.Model({
            taskId: task.taskId,
            applicationId: applicationId,
            task: task,
            applicationDetails: applicationDetails,
            applicationIndividuals: applicationIndividuals,
            previousReviewDecision: previousReviewDecision,
            newBranchApplicationTypes: newBranchApplicationTypes,
            entityBondCompanies: entityBondCompanies,
            entityBondType: entityBondType,
            entityBondStatus: entityBondStatus
          })
        });

        defer.resolve(
          new InternalApplicationReviewView({
            entityFormView: entityForm,
            applicationFormView: applicationFormView,
            workTabView: workTabView,
            model: new Backbone.Model({
              applicationDetails: applicationDetails,
              task: task
            })
          })
        );
      })
      .fail(function(err) {
        defer.reject(err);
      });
    return defer.promise();
  }
};
