/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Marionette = require('backbone.marionette');
var Syphon = require('backbone.syphon');

var parsleyUtil = require('common/util/parsleyUtil');

var tmplMortgageDiscountCompanyAnnualReportGeneralDetailsSectionView = require('./MortgageDiscountCompanyAnnualReportGeneralDetailsSectionView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplMortgageDiscountCompanyAnnualReportGeneralDetailsSectionView,

  className: 'mortgage-discount-company-annual-report-general-details-section-view',

  ui: {
    generalDetailsForm: '.general-details-form'
  },

  behaviors: {
    PhoneFormatBehavior: {}
  },

  onBeforeShow: function() {
    Syphon.deserialize(this, this.model.get('annualReport'));
  },

  validate: function(config) {
    if (config.type === 'save') {
      parsleyUtil.disableRequiredValidation(this.ui.generalDetailsForm);
    } else {
      parsleyUtil.enableRequiredValidation(this.ui.generalDetailsForm);
    }
    return this.ui.generalDetailsForm.parsley().whenValidate();
  },

  getFormData: function() {
    return Syphon.serialize(this);
  }
});
