/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var moment = require('moment');

module.exports = function(data) {
  var deferred = $.Deferred();

  var attachments = data.attachments || [];

  $.soap({
    body: {
      SubmitLicenseSurrenderApplication: {
        $xmlns: 'http://dobs.pa.gov/nd/application/bpm/1.0',
        EntityID: data.entityId,
        LicenseID: data.licenseId,
        ApplicationID: data.applicationId,
        SurrenderDate: moment(data.surrenderDate, 'MM-DD-YYYY').format('YYYY-MM-DD'),
        EntityNewName: data.entityNewName,
        Reason: data.reason,
        IsCertified: data.isCertified ? '1' : '0',
        CertificationName: data.certificationName,
        LicenseToSurrender: (function() {
          if (!data.licensesToSurrender || !data.licensesToSurrender.length) {
            return {};
          }
          return {
            LicenseID: data.licensesToSurrender
          };
        })(),
        ApplicationDocuments: (function() {
          if (!attachments.length) {
            return {};
          }
          return {
            ApplicationDocument: _.map(attachments, function(attachment) {
              return {
                DocumentID: attachment.fileId
              };
            })
          };
        })(),
        DocumentsToMove: (function() {
          var attachmentDocIds = _.pluck(attachments, 'fileId');
          var documentsToMove = _.compact(attachmentDocIds);
          if (!documentsToMove.length) {
            return {};
          }
          return {
            DocumentID: _.map(documentsToMove, function(documentId) {
              return documentId;
            })
          };
        })()
      }
    },
    blueprint: {
      newApplicationId: '$..ID'
    }
  })
    .done(function(response) {
      deferred.resolve(response.newApplicationId);
    })
    .fail(function(err) {
      deferred.reject();
    });

  return deferred.promise();
};
