/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Marionette = require('backbone.marionette');

var GridView = require('psa-marionette/views/GridView');
var PageableCollection = require('psa-backbone/models/PageableCollection');

var MomentCell = require('common/backgrid/MomentCell');
var ButtonLinkCell = require('common/backgrid/ButtonLinkCell');

var tmplDepExtEntityPendingTasksView = require('../templates/DepExtEntityPendingTasksView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplDepExtEntityPendingTasksView,

  className: 'dep-ext-entity-pending-tasks-view container-fluid',

  ui: {
    pendingTasks: '.pending-tasks-container'
  },

  regions: {
    pendingTasks: '@ui.pendingTasks'
  },

  onBeforeShow: function() {
    this.pendingTasksView = new GridView({
      columns: [
        {
          name: 'typeName',
          label: 'Task Name',
          cell: 'string',
          headerCell: 'custom',
          width: 50,
          editable: false
        },
        {
          name: 'deliveryDate',
          cell: MomentCell.MomentCell,
          formatter: MomentCell.MomentDateTimeFormatter.extend({
            emptyValue: '--'
          }),
          sortValue: MomentCell.MomentSortValue,
          label: 'Assigned On',
          headerCell: 'custom',
          width: 40,
          editable: false
        },
        {
          name: '',
          label: '',
          cell: ButtonLinkCell.extend({
            name: 'Open',
            buttonClass: 'btn-primary btn-md',
            href: function(model) {
              return '#ext/task/' + model.get('taskId');
            }
          }),
          editable: false,
          sortable: false,
          headerCell: 'custom',
          width: 10
        }
      ],
      collection: new PageableCollection(this.model.get('tasks'), { pageSize: 5 }),
      emptyText: 'No Pending Tasks'
    });
    this.showChildView('pendingTasks', this.pendingTasksView);
    this.pendingTasksView.viewGrid.sort('deliveryDate', 'descending');
  }
});
