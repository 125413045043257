/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var moment = require('moment');

module.exports = function(data) {
  data.effectiveChangeDate = moment(data.effectiveChangeDate, 'MM-DD-YYYY').format('YYYY-MM-DD');
  var deferred = $.Deferred();
 

  $.soap({
    body: {
      SubmitLicenseDBAEditApplication: {
        $xmlns: 'http://dobs.pa.gov/nd/application/bpm/1.0',
        LicenseID: data.licenseId,
        EntityID: data.entityId,
        DBAs: (function() {
          if (!data.entityDbas.length) {
            return {};
          } else {
            return {
              DBA: _.map(data.entityDbas, function(dba) {
                return {
                  EntityDBAID: dba
                };
              })
            };
          }
        })(),
        IsCertified: data.isCertified ? '1' : '0',
        CertificationName: data.certificationName,
        ApplicationID: data.applicationId,
        ApplicationDocuments: (function() {
          if (data.applicationDocuments.length === 0) {
            return {};
          }
          return {
            DocumentID: data.applicationDocuments
          };
        })()
      ,
        EffectiveDate: data.effectiveChangeDate,
        
      }
    },
    blueprint: {
      newApplicationID: '$..NewApplicationID'
    }
  })
    .done(function(response) {
      deferred.resolve(response.newApplicationID);
    })
    .fail(function(err) {
      deferred.reject();
    });

  return deferred.promise();
};
