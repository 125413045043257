/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var cleanUpResponse = require('common/util/cleanUpResponse');
var momentUtil = require('common/util/momentUtil');

module.exports = function(data) {
  var defer = $.Deferred();

  data.paymentDate = momentUtil.formatStr(data.paymentDate, momentUtil.defaultOutboundDateConfig);

  $.soap({
    body: {
      SubmitComplianceAGReferralPayment: {
        $xmlns: 'http://dobs.pa.gov/sc/compliance/bpm/1.0',
        AGPaymentID: data.AGPaymentId,
        ComplianceID: data.complianceId,
        PaymentDate: data.paymentDate,
        Amount: data.amount,
        CheckNumber: data.checkNumber,
        TransactionNumber: data.transactionNumber,
        IsDeleted: data.isDeleted
      }
    },
    blueprint: {
      AGPaymentId: '$..ID',
      paymentDate: '$..PaymentDate',
      amount: '$..Amount',
      checkNumber: '$..CheckNumber',
      transactionNumber: '$..TransactionNumber'
    }
  })
    .done(function(response) {
      cleanUpResponse(response);

      var formInboundDateConfig = {
        sourceFormat: 'YYYY-MM-DD',
        targetFormat: 'MM/DD/YYYY',
        default: undefined
      };

      response.paymentDate = momentUtil.formatStr(response.paymentDate, formInboundDateConfig);

      defer.resolve(response);
    })
    .fail(function(err) {
      defer.reject(err);
    });

  return defer.promise();
};
