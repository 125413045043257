/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Backbone = require('backbone');
var Marionette = require('backbone.marionette');

var ContentServerLinkView = require('common/views/ContentServerLinkView');
var BreadcrumbView = require('common/views/BreadcrumbView');

var NonDepEntityBooksAndRecordsCollectionView = require('../../entity-dashboard/views/NonDepEntityBooksAndRecordsCollectionView');

var EntityHeaderBarView = require('../../common/views/EntityHeaderBarView');

require('../styles/NonDepEntitySiteRecordkeepingSnapshotView.css');
var tmplNonDepEntitySiteRecordkeepingSnapshotView = require('../templates/NonDepEntitySiteRecordkeepingSnapshotView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplNonDepEntitySiteRecordkeepingSnapshotView,

  className: 'nondep-entity-site-recordkeeping-history-snapshot-view container-fluid',

  ui: {
    headerBar: '.header-bar-container',
    breadcrumb: '.breadcrumb-container',
    csLinkContainer: '.cs-link-container',
    siteRecordkeepingHistoryContainer: '.site-recordkeeping-history-container'
  },

  regions: {
    headerBar: '@ui.headerBar',
    breadcrumb: '@ui.breadcrumb',
    csLinkContainer: '@ui.csLinkContainer',
    siteRecordkeepingHistoryContainer: '@ui.siteRecordkeepingHistoryContainer'
  },

  onBeforeShow: function() {
    var entityIdentifiers = this.model.get('entityIdentifiers') || {};

    this.showChildView(
      'headerBar',
      new EntityHeaderBarView({
        model: new Backbone.Model(entityIdentifiers)
      })
    );

    this.showChildView(
      'breadcrumb',
      new BreadcrumbView({
        breadcrumbs: [
          {
            link: '#nondep/dashboard',
            text: 'Home'
          },
          {
            link: '#nondep/entity/' + entityIdentifiers.id + '/dashboard',
            text: entityIdentifiers.entityName
          },
          {
            link: '#nondep/entity/' + entityIdentifiers.id + '/history',
            text: 'History'
          },
          {
            text: 'Books and Records Snapshot'
          }
        ]
      })
    );

    this.showChildView(
      'csLinkContainer',
      new ContentServerLinkView({
        model: new Backbone.Model({
          buttonText: 'Open Entity Folder',
          contentServerId: entityIdentifiers.csFolderId
        })
      })
    );

    this.showChildView(
      'siteRecordkeepingHistoryContainer',
      new NonDepEntityBooksAndRecordsCollectionView({
        collection: new Backbone.Collection(this.model.get('entitySiteRecordkeepings'))
      })
    );
  }
});
