/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Marionette = require('backbone.marionette');
var Syphon = require('backbone.syphon');
var toggleForm = require('common/util/toggleForm');
var FileUploadView = require('common/views/FileUploadView');
var AddressView = require('common/views/AddressView');
var parsleyUtil = require('common/util/parsleyUtil');
var modelGet = require('common/util/modelGet');
var USStateSelectFieldView = require('common/views/USStateSelectFieldView');

var makeUploadCollection = require('common/util/makeUploadCollection');

var tmplDepCreditUnionSEGRequestGroupInformationView = require('./DepCreditUnionSEGRequestGroupInformationView.hbs');


module.exports = Marionette.LayoutView.extend({
  template: tmplDepCreditUnionSEGRequestGroupInformationView,

  className: 'dep-credit-union-seg-request-group-information-section-view',

  behaviors: {
    ReadOnlyFormBehavior: {}
  },

  ui: {
    groupInformationForm: '.group-information-form',
    isNonProfitRadio: '.is-non-profit',
    addressForm: '.addressForm',
    regAddressForm: '.reg-addressForm',
    stateIncorporationSelectContainer: '.state-incorporation-select-container',
    uploadStateVerification: '.upload-state-verification',
    isHQDiffer: '.is-hq-differ',
    regAddressExplain: '.reg-address-explain',
    isGroupNonProfit: '.is-non-profit',
    entityTypeID: 'select[name="entityTypeID"]',
    otherType: '.entity-type-other',
    uploadByLaws: '.upload-bylaws',
    byLaws: '.by-laws',
    addressLabel: '.address-label'
  },

  regions: {
    addressForm: '@ui.addressForm',
    regAddressForm: '@ui.regAddressForm',
    stateIncorporationSelectContainer: '@ui.stateIncorporationSelectContainer',
    uploadStateVerification: '@ui.uploadStateVerification',
    regAddressExplain: '@ui.regAddressExplain',
    uploadByLaws: '@ui.uploadByLaws',
    byLaws: '@ui.byLaws'
  },

  events: {
    'change @ui.isHQDiffer': 'onChangeIsHQDiffer',
    'change @ui.isGroupNonProfit': 'onChangeIsNonProfit',
    'change @ui.entityTypeID': 'onChangeIsEntityTypeOther'
  },

  templateHelpers: function() {
    return {
      isReadOnly: this.options.isReadOnly,
      // otherTypeComments: modelGet(this.model, 'filingContent.entityTypeID') === '6'
    };
  },

  onBeforeShow: function() {
    var filingContent = modelGet(this.model, 'filingContent');
    filingContent.address = filingContent.groupAddress;

    var stateVerificationDocument = modelGet(this.model, 'filingContent.StateVerification.file');
    var ByLawsDocument = modelGet(this.model, 'filingContent.nonProfitByLaws.file');

    this.addressForm = new AddressView({
      objectName: 'address',
      horizontal: false,
      isRequired: true,
      startingCountry: modelGet(this.model, 'filingContent.address.country'),
      startingPostalCode: modelGet(this.model, 'filingContent.address.postalCode'),
      startingCounty: modelGet(this.model, 'filingContent.address.county')
    });
    this.showChildView('addressForm', this.addressForm);
    this.addressForm.ui.countryGroup.remove();

    this.regAddressForm = new AddressView({
      objectName: 'regAddress',
      horizontal: false,
      isRequired: true,
      startingCountry: modelGet(this.model, 'filingContent.regAddress.country'),
      startingPostalCode: modelGet(this.model, 'filingContent.regAddress.postalCode'),
      startingCounty: modelGet(this.model, 'filingContent.regAddress.county')
    });

    this.showChildView('regAddressForm', this.regAddressForm);

    if (filingContent.isHqAddressDiffer === '0') {
      this.ui.regAddressForm.hide();
    }

    this.regAddressForm.ui.countryGroup.remove();

    this.incorpStateSelect = new USStateSelectFieldView({
      emptyOption: false,
      name: 'stateOfIncorporation',
      isRequired: true
    });
    this.showChildView('stateIncorporationSelectContainer', this.incorpStateSelect);

    this.uploadStateVerification = new FileUploadView({
      isReadOnly: this.options.isReadOnly,
      allowMultipleFiles: false,
      isRequired: false,
      collection: makeUploadCollection(stateVerificationDocument),
      metadata: {
        documentTypeId: 20001
      }
    });

    this.showChildView('uploadStateVerification', this.uploadStateVerification);

    if (this.options.mode === 'ext-new') {
      this.incorpStateSelect.setValue('PA');
    }

    this.bylawsDoc = new FileUploadView({
      isReadOnly: this.options.isReadOnly,
      allowMultipleFiles: false,
      isRequired: true,
      collection: makeUploadCollection(ByLawsDocument),
      metadata: {
        documentTypeId: 20001
      }
    });
    this.showChildView('uploadByLaws', this.bylawsDoc);

    Syphon.deserialize(this, filingContent);
    this.showNonProfitByLaws();
    this.showRegsiteredAddress();
    this.onChangeIsEntityTypeOther();
  },

  onChangeIsNonProfit: function(e) {
    e.preventDefault();
    this.showNonProfitByLaws();
  },

  onChangeIsEntityTypeOther: function() { 
    var entityTypeIsOther = this.ui.entityTypeID.val() === '6';
    toggleForm(this.ui.otherType, entityTypeIsOther); 
  },
  
  showNonProfitByLaws: function() {
    var isNonProfit = this.ui.isGroupNonProfit.filter(':checked').val() === '1';
    if (isNonProfit) {
      this.ui.byLaws.show();
      this.ui.uploadByLaws.show();
      this.ui.uploadByLaws.attr('data-parsley-required', true);
      this.bylawsDoc.options.isRequired = true;
    } else {
      this.ui.uploadByLaws.hide();
      this.ui.byLaws.hide();
      this.ui.uploadByLaws.attr('data-parsley-required', false);
      this.bylawsDoc.options.isRequired = false;
    }
  },

  validate: function(config) {
    this.ui.groupInformationForm.parsley().reset();
    this.bylawsDoc.removeValidationErrors();
    this.uploadStateVerification.removeValidationErrors();

    if (config.type === 'save') {
      // Turn off required field validation for this form
      parsleyUtil.disableRequiredValidation(this.ui.groupInformationForm);
     
      this.bylawsDoc.options.isRequired = false;
      this.uploadStateVerification.options.isRequired = false;
      
    } else {
      // Turn on required field validation for this form
      this.bylawsDoc.options.isRequired = this.ui.isGroupNonProfit.filter(':checked').val() === '1';
      this.uploadStateVerification.options.isRequired = false;
      parsleyUtil.enableRequiredValidation(this.ui.groupInformationForm);
      if (this.ui.isHQDiffer.filter(':checked').val() === '1') {
        parsleyUtil.enableRequiredValidation(this.ui.regAddressForm);
        parsleyUtil.enableRequiredValidation(this.ui.regAddressExplain);
      } else {
        parsleyUtil.disableRequiredValidation(this.ui.regAddressForm);
        parsleyUtil.disableRequiredValidation(this.ui.regAddressExplain);
      }
    }

    var validationPromises = [
      this.ui.groupInformationForm.parsley().whenValidate(),
      this.uploadStateVerification.validate(),
      this.bylawsDoc.validate()
    ];
    return $.when.apply($, validationPromises);
  },

  onChangeIsHQDiffer: function(e) {
    e.preventDefault();
    this.showRegsiteredAddress();
  },

  showRegsiteredAddress: function(e) {
    var isAddressSame = this.ui.isHQDiffer.filter(':checked').val() === '1';
    if (isAddressSame) {
      this.regAddressForm.options.isRequired = true;
      this.ui.regAddressForm.prop('required', true);
      this.ui.regAddressForm.attr('data-parsley-required', true);
      this.ui.regAddressForm.show();
      this.ui.regAddressExplain.show();
      this.ui.addressLabel.show();
      this.ui.regAddressExplain.attr('data-parsley-required', true);
    } else {
      this.regAddressForm.options.isRequired = false;
      this.ui.regAddressForm.prop('required', false);
      this.ui.regAddressForm.attr('data-parsley-required', false);
      this.ui.regAddressForm.hide();
      this.ui.regAddressExplain.hide();
      this.ui.addressLabel.hide();
      this.ui.regAddressExplain.attr('data-parsley-required', false);
    }
  },

  getFormData: function() {
    var formData = Syphon.serialize(this);
    formData.stateVerificationDocID = this.uploadStateVerification.getFirstFileId();
    formData.nonProfitByLawsDocID = this.bylawsDoc.getFirstFileId()
    return formData;
  }
});
