/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Marionette = require('backbone.marionette');
var Ladda = require('ladda');
var Radio = require('backbone.radio');
var Syphon = require('backbone.syphon');

var BreadcrumbView = require('common/views/BreadcrumbView');

var EntityHeaderBarView = require('../../common/views/EntityHeaderBarView');

var SubmitEntityLegalHoldStatus = require('../services/SubmitEntityLegalHoldStatus');

require('../styles/NonDepEntityEditLegalHoldView.css');
var tmplNonDepEntityEditLegalHoldView = require('../templates/NonDepEntityEditLegalHoldView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplNonDepEntityEditLegalHoldView,

  className: 'non-dep-entity-edit-legal-hold-view container-fluid m-b-lg',

  ui: {
    entityActionDropDown: '.dropdown-container',
    headerBar: '.header-bar-container',
    breadcrumb: '.breadcrumb-container',
    legalHoldForm: '.legal-hold-form',
    legalHoldNotesContainer: '.legal-hold-notes-container',
    //placeLegaHoldCheck: 'input[name="placeLegalHold"][value="1"]',
    submit: '.btn-submit'
  },

  regions: {
    entityActionDropDown: '@ui.entityActionDropDown',
    legalHoldNotesContainer: '@ui.legalHoldNotesContainer',
    headerBar: '@ui.headerBar',
    breadcrumb: '@ui.breadcrumb'
  },

  events: {
    'click @ui.submit': 'onClickSubmit'
  },

  onBeforeShow: function() {
    var entityId = this.model.get('entityId');
    var entityIdentifiers = this.model.get('entityIdentifiers') || {};
    

    this.showChildView(
      'headerBar',
      new EntityHeaderBarView({
        model: new Backbone.Model(entityIdentifiers)
      })
    );

    this.showChildView(
      'breadcrumb',
      new BreadcrumbView({
        breadcrumbs: [
          {
            link: '#nondep/dashboard',
            text: 'Home'
          },
          {
            link: '#nondep/entity/' + entityId + '/dashboard',
            text: this.model.get('entityName')
          },
          {
            text: 'Edit Legal Hold Status'
          }
        ]
      })
    );
  },

  onClickSubmit: function(e) {
    e.preventDefault();
    var entityId = this.model.get('entityId');

    this.validate().done(
      function() {
        var laddaContext = Ladda.create(this.ui.submit.get(0));
        laddaContext.start();
        var formData = Syphon.serialize(this);
        var legalHoldNotes = formData.legalHoldNotes;
        var newLegalHoldStatus = formData.placeLegalHold ? '1' : '0';
        SubmitEntityLegalHoldStatus({
          entityId: entityId,
          newLegalHoldStatus: newLegalHoldStatus,
          legalHoldNotes: legalHoldNotes
        })
          .done(function() {
            Radio.channel('navigate').trigger('show:int:nd:entity-dashboard', entityId);
          })
          .fail(function() {
            laddaContext.stop();
          });
      }.bind(this)
    );
  },

  validate: function() {
    return this.ui.legalHoldForm.parsley().whenValidate();
  }
});
