/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');

var cleanUpResponse = require('common/util/cleanUpResponse');

module.exports = function(examId) {
  var deferred = $.Deferred();

  $.soap({
    body: {
      GetInternalExamTaskDetails: {
        $xmlns: 'http://dobs.pa.gov/sc/task/bpm/1.0',
        ExamID: examId
      }
    },
    blueprintArrays: [
      'GetInternalExamTaskDetailsResponse.ExamDocuments.SC_ExamDocument',
      'GetInternalExamTaskDetailsResponse.ExamDeficiencyDocuments.SC_ExamDeficiencyDocument'
    ],
    blueprint: {
      examId: '$..ExamID',
      examNumber: '$..ExamNumber',
      typeId: '$..TypeID',
      primaryExaminer: '$..PrimaryExaminer',
      externalReplyComments: '$..ExternalReplyComments',
      internalComments: '$..InternalComments',
      organizationId: '$..OrganizationID',
      crdNumber: '$..CRDNumber',
      dbaName: '$..DBAName',
      applicantLegalName: '$..ApplicantLegalName',
      examType: '$..ExamType',
      documentPhaseId: '$..ExternalDocumentPhaseID',
      initialOpenedDate: '$..InitialOpenedDate',
      finalExaminationReportDoc: {
        fileId: '$..FinalExaminationReportDocID',
        fileName: '$..FinalExaminationReportName'
      },
      examinationClosingLetterDoc: {
        fileId: '$..ExaminationClosingLetterDocID',
        fileName: '$..ExaminationClosingLetterName'
      },
      examDocuments: [
        '$..SC_ExamDocument',
        {
          fileId: '$.DocumentID',
          fileName: '$.Name',
          documentPhaseId: '$.DocumentType',
          isCurrent: '$.IsCurrent'
        }
      ],
      examDeficiencyDocuments: [
        '$..SC_ExamDeficiencyDocument',
        {
          fileId: '$.DocumentID',
          fileName: '$.Name'
        }
      ]
    }
  })
    .done(function(response) {
      cleanUpResponse(response);
      _.each(response.examDocuments, function(document) {
        document.file = {
          fileId: document.fileId,
          fileName: document.fileName
        };
      });

      deferred.resolve(response);
    })
    .fail(function() {
      deferred.reject();
    });

  return deferred.promise();
};
