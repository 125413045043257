/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Marionette = require('backbone.marionette');
var Radio = require('backbone.radio');
var Ladda = require('ladda');

var modelGet = require('common/util/modelGet');

var BreadcrumbView = require('common/views/BreadcrumbView');

var NonDepInitiateCorrespondenceFormView = require('../../correspondence/views/NonDepInitiateCorrespondenceFormView');

var SubmitNewCorrespondence = require('../../common/services/SubmitNewCorrespondence');

var tmplNonDepIndividualInitiateCorrespondenceView = require('../templates/NonDepIndividualInitiateCorrespondenceView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplNonDepIndividualInitiateCorrespondenceView,

  className: 'non-dep-individual-initiate-correspondence-view container-fluid',

  ui: {
    breadcrumb: '.breadcrumb-container',
    correspondenceForm: '.correspondence-form-container',
    submit: '.btn-submit'
  },

  regions: {
    breadcrumb: '@ui.breadcrumb',
    correspondenceForm: '@ui.correspondenceForm'
  },

  events: {
    'click @ui.submit': 'onClickSubmit'
  },

  onBeforeShow: function() {
    var individualDetails = this.model.get('individualDetails');
    var entityName = individualDetails.entityName;
    var entityId = individualDetails.entityId;
    var individualId = this.model.get('individualId');
    var individualType = individualDetails.individualType;
    var firstName = individualDetails.firstName;
    var lastName = individualDetails.lastName;

    this.showChildView(
      'breadcrumb',
      new BreadcrumbView({
        breadcrumbs: [
          {
            link: '#nondep/dashboard',
            text: 'Home'
          },
          {
            text: entityName,
            link: '#nondep/entity/' + entityId + '/dashboard'
          },
          {
            text: individualType + ' - ' + firstName + ' ' + lastName,
            link: '#nondep/individual/' + individualId + '/dashboard'
          }
        ]
      })
    );

    this.correspondenceForm = new NonDepInitiateCorrespondenceFormView({
      model: this.model
    });

    this.showChildView('correspondenceForm', this.correspondenceForm);
  },

  onClickSubmit: function(e) {
    e.preventDefault();
    var self = this;
    var entityOfficerId = modelGet(this.model, 'individualDetails.entityOfficerId');
    var entityId = modelGet(this.model, 'individualDetails.entityId');
    var individualId = this.model.get('individualId');

    this.correspondenceForm.validate().done(function() {
      var l = Ladda.create(self.ui.submit[0]);
      l.start();
      var formData = self.correspondenceForm.getFormData();
      formData.objectId = entityOfficerId;
      formData.objectTypeId = 'INDIVIDUAL';
      formData.entityId = entityId;
      formData.isInternal = '1';

      SubmitNewCorrespondence(formData)
        .done(function() {
          Radio.channel('navigate').trigger('show:individual-dashboard', individualId);
        })
        .fail(function() {
          l.stop();
        });
    });
  }
});
