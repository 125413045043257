/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');

var momentUtil = require('common/util/momentUtil');
var cleanUpResponse = require('common/util/cleanUpResponse');

module.exports = function(correspondenceId) {
  var deferred = $.Deferred();

  $.soap({
    body: {
      GetCorrespondence: {
        $xmlns: 'http://dobs.pa.gov/cs/correspondence/bpm/1.0',
        CorrespondenceID: correspondenceId
      }
    },
    blueprintArrays: ['GetCorrespondenceResponse.Documents.CS_CorrespondenceDocument'],
    blueprint: {
      correspondenceId: '$..ID',
      complaintId: '$..ComplaintID',
      statusId: '$..StatusID',
      status: '$..Status',
      requestComments: '$..RequestComments',
      requestedBy: '$..RequestedBy',
      requestedByName: '$..RequestedByName',
      requestedOn: '$..RequestedOn',
      requestedByEmail: '$..RequestedByEmail',
      replyComments: '$..ReplyComments',
      repliedBy: '$..RepliedBy',
      repliedByName: '$..RepliedByName',
      repliedOn: '$..RepliedOn',
      repliedByEmail: '$..RepliedByEmail',
      withdrawnComments: '$..WithdrawnComments',
      withdrawnBy: '$..WithdrawnBy',
      withdrawnByName: '$..WithdrawnByName',
      withdrawnOn: '$..WithdrawnOn',
      closedBy: '$..ClosedBy',
      closedByName: '$..ClosedByName',
      closedOn: '$..ClosedOn',
      entityName: '$..EntityName',
      complainantEntityName: '$..ComplainantEntityName',
      complainantName: '$..ComplainantName',
      email: '$..Email',
      complaintCaseNumber: '$..ComplaintCaseNumber',
      recipientTypeId: '$..RecipientTypeID',
      recipientType: '$..RecipientType',
      documents: [
        '$..CS_CorrespondenceDocument',
        {
          correspondenceDocumentId: '$.ID',
          fileId: '$.DocumentID',
          correspondenceId: '$.CorrespondenceID',
          isInternal: '$..IsInternal',
          fileName: '$..Name',
          documentTypeId: '$..DocumentTypeID',
          documentType: '$..Description'
        }
      ]
    }
  })
    .done(function(response) {
      cleanUpResponse(response);
      _.each(response.documents, function(document) {
        document.file = {
          fileId: document.fileId,
          fileName: document.fileName
        };
      });

      var formInboundDateConfig = {
        sourceFormat: 'YYYY-MM-DD',
        targetFormat: 'MM/DD/YYYY',
        default: undefined
      };
      response.intDocuments = _.where(response.documents, { isInternal: '1' });
      response.extDocuments = _.where(response.documents, { isInternal: '0' });

      response.requestedOn = momentUtil.formatStr(response.requestedOn, formInboundDateConfig);
      response.repliedOn = momentUtil.formatStr(response.repliedOn, formInboundDateConfig);
      response.withdrawnOn = momentUtil.formatStr(response.withdrawnOn, formInboundDateConfig);
      response.closedOn = momentUtil.formatStr(response.closedOn, formInboundDateConfig);

      deferred.resolve(response);
    })
    .fail(function(err) {
      deferred.reject();
    });

  return deferred.promise();
};
