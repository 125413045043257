/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var moment = require('moment');

var defaultDateFormat = 'MM/DD/YYYY';

window.Parsley.addValidator('date', {
  requirementType: 'string',
  validateString: function(value, dateFormat) {
    var deferred = $.Deferred();
    if (moment(value, dateFormat.length ? dateFormat : defaultDateFormat).isValid()) {
      return deferred.resolve();
    }
    return deferred.reject(
      'This value must be a date in the format ' + (dateFormat.length ? dateFormat : defaultDateFormat)
    );
  }
});
