var Handlebars = require("handlebars/dist/handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "        <option value=\""
    + alias4(((helper = (helper = helpers.userId || (depth0 != null ? depth0.userId : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"userId","hash":{},"data":data}) : helper)))
    + "\">"
    + alias4(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data}) : helper)))
    + " - ("
    + alias4(((helper = (helper = helpers.userId || (depth0 != null ? depth0.userId : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"userId","hash":{},"data":data}) : helper)))
    + ")</option>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "\n<div class=\"modal-body\">\n  <form>\n    <div class=\"form-group required\">\n      <label class=\"control-label\">Status</label>\n      <textarea name=\"nextStepStatus\" class=\"form-control\" placeholder=\"Status\" rows=\"5\"\n        data-parsley-required=\"true\"></textarea>\n    </div>\n    <div class=\"form-group required\">\n      <label class=\"control-label\">Next Steps</label>\n      <textarea name=\"nextStepDescription\" class=\"form-control\" placeholder=\"Next Step Description\" rows=\"5\"\n        data-parsley-required=\"true\"></textarea>\n    </div>\n    <div class=\"form-group required\">\n      <label class=\"control-label\">Assigned To</label>\n      <select name=\"nextStepAssignedTo\" class=\"form-control\" data-parsley-required=\"true\">\n        <option value=\"\" selected>--</option>\n"
    + ((stack1 = helpers.each.call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.scUsers : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "      </select>\n    </div>\n    <div class=\"form-group required\">\n      <label class=\"control-label\">Next Step Projected Due Date</label>\n      <div class=\"input-group\">\n        <input type=\"text\" name=\"nextStepProjectedDate\" class=\"form-control date-picker\"\n          placeholder=\"Next Step Projected Date\" data-parsley-required=\"true\"\n          data-parsley-date=\"\" data-parsley-trigger-after-failure=\"changeDate\"\n          data-parsley-errors-container=\"#projectedDateErrorsContainer\" />\n        <div class=\"input-group-addon\">\n          <i class=\"fa fa-calendar\"></i>\n        </div>\n      </div>\n      <div id=\"projectedDateErrorsContainer\"></div>\n    </div>\n  </form>\n</div>\n<div class=\"modal-footer\">\n  <button class=\"btn btn-default\" data-dismiss=\"modal\">Cancel</button>\n  <button class=\"btn btn-primary submit ladda-button\" data-style=\"expand-right\" type=\"button\">\n    <span class=\"ladda-label\">Submit</span>\n  </button>\n</div>";
},"useData":true});