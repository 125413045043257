/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var NProgress = require('nprogress');
var Radio = require('backbone.radio');

var NavUtils = require('psa-core/util/nav');

var Scaffold = require('common/layout/Scaffold');

var GetApplicationStatus = require('../../common/services/GetApplicationStatus');
var GetApplicationDetails = require('../../common/services/GetApplicationDetails');
var GetApplicationOwner = require('../services/GetApplicationOwner');
var GetAllReportingStatus = require('../services/GetAllReportingStatus');

var NonDepInternalApplicationView = require('../views/NonDepInternalApplicationView');

module.exports = function(applicationId) {
  NProgress.start();
  NProgress.set(0.6);

  $.when(
    GetApplicationDetails(applicationId),
    GetApplicationOwner(applicationId),
    GetApplicationStatus(applicationId),
    GetAllReportingStatus()
  )
    // create web service called GetApplicationReportingStatus
    .done(function(applicationDetails, owner, applicationStatus, reportingStatusLookups) {
      applicationDetails.applicationStatus = applicationStatus.internalStatus;
      NavUtils.navigate('#nondep/application/' + applicationId + '/dashboard');

      var formIdentifier = applicationDetails.formIdentifier;
      var applicationTypeId = applicationDetails.applicationTypeId;
      var entityId = applicationDetails.entityId;
      var applicationCategoryId = applicationDetails.applicationCategoryId;

      var BuildApplicationPromise = Radio.channel('nd:application').request('build:' + formIdentifier, {
        applicationId: applicationId,
        applicationTypeId: applicationTypeId,
        entityId: entityId,
        mode: 'int-expand',
        isReadOnly: true
      });

      BuildApplicationPromise.done(function(applicationDetailsSectionView) {
        NProgress.set(0.85);
        if (applicationCategoryId !== '3') {
          applicationDetailsSectionView.model.set({
            applicationDetails: applicationDetails
          });
        }

        Scaffold.content.show(
          new NonDepInternalApplicationView({
            viewMode: 'int',
            model: new Backbone.Model(
              _.extend(
                applicationDetails,
                owner,
                { applicationDetailsSectionView: applicationDetailsSectionView },
                { reportingStatusLookups: reportingStatusLookups }
              )
            )
          })
        );
        NProgress.done();
      });
      NProgress.done();
    })
    .fail(function(err) {
      NProgress.done();
    });
};
