/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var get = require('lodash.get');
var moment = require('moment');

var Config = require('../../../config/Config');

var getUserIdFromDn = require('common/util/getUserIdFromDn');
var cleanUpResponse = require('common/util/cleanUpResponse');
var GetEnableRetrieveApplicationFromApplicantDate = require('../../common/services/GetEnableRetrieveApplicationFromApplicantDate');

module.exports = function(entityID) {
  var deferred = $.Deferred();

  $.soap({
    body: {
      GetInternalTasksByEntity: {
        $xmlns: 'http://dobs.pa.gov/nd/task/bpm/1.0',
        EntityID: entityID
      }
    },
    blueprintArrays: [
      'GetInternalTasksByEntityResponse.Tasks.Task',
      'GetInternalTasksByEntityResponse.Tasks.Task.BusinessAttributes.Attribute',
      'GetInternalTasksByEntityResponse.Tasks.Task.Targets.Target',
      'GetInternalTasksByEntityResponse.TaskTypes.CM_LU_TaskType',
      'GetInternalTasksByEntityResponse.Applications.ND_Application',
      'GetInternalTasksByEntityResponse.Exams.ND_Exam',
      'GetInternalTasksByEntityResponse.Compliances.ND_Compliance',
      'GetInternalTasksByEntityResponse.Correspondences.ND_Correspondence',
      'GetInternalTasksByEntityResponse.Licenses.ND_License'
    ],
    blueprint: {
      tasks: [
        '$..Task',
        {
          taskId: '$..TaskId',
          deliveryDate: '$..DeliveryDate',
          assignee: '$..Assignee.$displayName',
          businessAttributes: [
            '$..Attribute',
            {
              name: '$.$name',
              value: '$.__text'
            }
          ],
          target: '$..Targets.Target[0].__text'
        }
      ],
      taskMetaData: {
        applications: [
          '$..ND_Application',
          {
            id: '$.ApplicationID',
            applicationCategory: '$.ApplicationCategory',
            applicationType: '$.ApplicationType',
            applicationStatusID: '$.ApplicationStatusID',
            createdDate: '$.CreatedDate',
            entityName: '$.EntityName',
            licenseType: '$.LicenseType',
            licenseNumber: '$.LicenseNumber',
            address: {
              addressLine1: '$.AddressLine1',
              addressLine2: '$.AddressLine2',
              addressLine3: '$.AddressLine3',
              city: '$.City',
              state: '$.State',
              postalCode: '$.PostalCode',
              country: '$.Country'
            }
          }
        ],
        exams: [
          '$..ND_Exam',
          {
            id: '$..ExamID',
            entityName: '$..EntityName',
            licenseType: '$..LicenseType',
            licenseNumber: '$..LicenseNumber',
            address: {
              addressLine1: '$..AddressLine1',
              addressLine2: '$..AddressLine2',
              addressLine3: '$..AddressLine3',
              city: '$..City',
              state: '$..State',
              postalCode: '$..PostalCode',
              country: '$..Country'
            }
          }
        ],
        compliances: [
          '$..ND_Compliance',
          {
            id: '$..ComplianceID',
            entityName: '$..EntityName',
            licenseType: '$..LicenseType',
            operatingAsLicenseType: '$..OperatingAsLicenseType',
            licenseNumber: '$..LicenseNumber',
            address: {
              addressLine1: '$..AddressLine1',
              addressLine2: '$..AddressLine2',
              addressLine3: '$..AddressLine3',
              city: '$..City',
              state: '$..State',
              postalCode: '$..PostalCode',
              country: '$..Country'
            }
          }
        ],
        correspondences: [
          '$..ND_Correspondence',
          {
            correspondenceId: '$..CorrespondenceID',
            entityName: '$..EntityName',
            licenseType: '$..LicenseType',
            licenseNumber: '$..LicenseNumber',
            licenseId: '$..LicenseID',
            applicationCategory: '$..ApplicationCategory',
            applicationType: '$..ApplicationType',
            createdBy: '$..CreatedBy',
            requestComments: '$..RequestComments',
            address: {
              addressLine1: '$..AddressLine1',
              addressLine2: '$..AddressLine2',
              addressLine3: '$..AddressLine3',
              city: '$..City',
              state: '$..State',
              postalCode: '$..PostalCode',
              country: '$..Country'
            }
          }
        ],
        licenses: [
          '$..ND_License',
          {
            licenseId: '$..ID',
            entityName: '$..EntityName',
            licenseType: '$..LicenseType',
            licenseNumber: '$..LicenseNumber',
            address: {
              addressLine1: '$..AddressLine1',
              addressLine2: '$..AddressLine2',
              addressLine3: '$..AddressLine3',
              city: '$..City',
              state: '$..State',
              postalCode: '$..PostalCode',
              country: '$..Country'
            }
          }
        ],
        taskTypes: [
          '$..CM_LU_TaskType',
          {
            id: '$.ID',
            name: '$.Name',
            description: '$.Description'
          }
        ]
      }
    }
  })
    .done(function(response) {
      var enableRetrieveApplicationFromApplicantDate = '';
      GetEnableRetrieveApplicationFromApplicantDate().done(function(enableFromDateResponse) {
        enableRetrieveApplicationFromApplicantDate = enableFromDateResponse.enableFromDate;

        cleanUpResponse(response);

        _.each(response.tasks, function(task) {
          task.deliveryDate = moment.utc(task.deliveryDate, moment.ISO_8601).local();
          _.each(task.businessAttributes, function(attr) {
            var attrMap = {
              ApplicationID: 'applicationId',
              ExamID: 'examId',
              ComplianceID: 'complianceId',
              TaskTypeName: 'typeName',
              CorrespondenceID: 'correspondenceId',
              LicenseID: 'licenseId',
              EntityID: 'entityId'
            };

            if (attrMap[attr.name]) {
              task[attrMap[attr.name]] = attr.value;
            }
          });

          delete task.businessAttributes;

          var type = _.find(response.taskMetaData.taskTypes, function(type) {
            return task.typeName === type.name;
          });
          task.typeName = get(type, 'description');

          if (task.target) {
            task.target = getUserIdFromDn(task.target);
          }
          task.entityId = entityID;
          task.assignee = task.assignee || task.target;
          task.assigneeId = task.assignee;
          if (task.assigneeId === Config.getExternalUserServiceAccountUserId()) {
            task.assignee = '(Entity)';
          }
          delete task.target;
          task.correspondenceCreatedBy = '--';
          task.requestComments = '--';

          if (task.applicationId) {
            var application = _.find(response.taskMetaData.applications, function(application) {
              return task.applicationId === application.id;
            });

            if (application) {
              task.applicationType = application.applicationType || '--';
              task.applicationCategory = application.applicationCategory;
              task.applicationStatusID = application.applicationStatusID;
              task.applicationCreatedDate = moment.utc(application.createdDate, moment.ISO_8601).local();
              task.entityName = application.entityName;
              task.licenseType = application.licenseType;
              task.licenseNumber = application.licenseNumber || '--';
              task.location = get(application, 'address.addressLine1', '--') || '--';
              task.enableRetrieveApplicationFromApplicantDate = moment
                .utc(enableRetrieveApplicationFromApplicantDate, moment.ISO_8601)
                .local();
            } else {
              task.applicationType = '--';
            }
          } else if (task.examId) {
            /* Handle Exam Meta Data */

            task.applicationType = '--';

            var exam = _.find(response.taskMetaData.exams, function(exam) {
              return task.examId === exam.id;
            });

            if (exam) {
              task.entityName = exam.entityName;
              task.licenseType = exam.licenseType;
              task.licenseNumber = exam.licenseNumber || '--';
              task.location = get(exam, 'address.addressLine1', '--') || '--';
            }
          } else if (task.complianceId) {
            /* Handle Compliance Meta Data */

            task.applicationType = '--';

            var compliance = _.find(response.taskMetaData.compliances, function(compliance) {
              return task.complianceId === compliance.id;
            });

            if (compliance) {
              task.entityName = compliance.entityName;
              task.licenseType = compliance.licenseType || compliance.operatingAsType;
              task.licenseNumber = compliance.licenseNumber || '--';
              task.location = get(compliance, 'address.addressLine1', '--') || '--';
            }
          } else if (task.correspondenceId) {
            var correspondence = _.find(response.taskMetaData.correspondences, function(correspondence) {
              return task.correspondenceId === correspondence.correspondenceId;
            });
            if (correspondence) {
              task.applicationType = correspondence.applicationType || '--';
              task.entityName = correspondence.entityName;
              task.licenseId = correspondence.licenseId;
              task.licenseType = correspondence.licenseType;
              task.licenseNumber = correspondence.licenseNumber || '--';
              task.location = get(correspondence, 'address.addressLine1', '--') || '--';
              task.correspondenceCreatedBy = correspondence.createdBy;
              task.requestComments = correspondence.requestComments || '--';
            }
          } else if (task.licenseId) {
            var license = _.find(response.taskMetaData.licenses, function(license) {
              return task.licenseId === license.licenseId;
            });
            if (license) {
              task.entityName = license.entityName || '--';
              task.licenseType = license.licenseType || '--';
              task.licenseNumber = license.licenseNumber || '--';
              task.location = get(license, 'address.addressLine1', '--') || '--';
              task.applicationType = '--';
              task.applicationId = '--';
            }
          } else if (task.entityId) {
            task.licenseType = '--';
            task.licenseNumber = '--';
            task.location = '--';
            task.applicationType = '--';
            task.applicationId = '--';
          }
        });
        delete response.taskMetaData;

        deferred.resolve(response.tasks);
      });
    })
    .fail(function(err) {
      deferred.reject();
    });

  return deferred.promise();
};
