/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var Marionette = require('backbone.marionette');

var EmptySetView = require('common/views/EmptySetView');

var DepSection112ApplicantFormView = require('./DepSection112ApplicantFormView');

module.exports = Marionette.CollectionView.extend({
  className: 'dep-section112-applicants-collection-view',

  childView: DepSection112ApplicantFormView,

  childViewOptions: function(model, index) {
    return {
      childIndex: index
    };
  },

  childEvents: {
    'remove:applicant': 'onChildClickRemoveApplicant'
  },

  emptyView: EmptySetView,

  emptyViewOptions: {
    emptyText: 'No Applicant Provided'
  },

  onChildClickRemoveApplicant: function(childView) {
    this.collection.remove(childView.model);
    this.triggerMethod('remove:applicant');
  },

  getFormData: function() {
    return this.children.reduce(function(memo, child) {
      if (child.getFormData && _.isFunction(child.getFormData)) {
        memo.push(child.getFormData());
      }
      return memo;
    }, []);
  }
});
