/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2020] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Backbone = require('backbone');
var NProgress = require('nprogress');

var NavUtils = require('psa-core/util/nav');

var Scaffold = require('common/layout/Scaffold');
var GetDocumentTypesByDepartment = require('common/services/GetDocumentTypesByDepartment');
var GetEntityIdentifiers = require('../../common/services/GetEntityIdentifiers');
var GetEntityUsers = require('../services/GetEntityUsers');

var DepIntEntityInitiateCorrespondenceView = require('../views/DepIntEntityInitiateCorrespondenceView');

module.exports = function(entityId) {
  NProgress.start();
  NProgress.set(0.6);
  $.when(GetEntityIdentifiers(entityId), GetDocumentTypesByDepartment('DEPOSITORY'), GetEntityUsers(entityId))
    .done(function(entityIdentifiers, documentTypes, entityUsers) {
      NavUtils.navigate('#dep/entity/' + entityId + '/initiate-correspondence');
      Scaffold.content.show(
        new DepIntEntityInitiateCorrespondenceView({
          model: new Backbone.Model({
            entityId: entityId,
            entityIdentifiers: entityIdentifiers,
            documentTypes: documentTypes,
            entityUsers: entityUsers
          })
        })
      );
      NProgress.done();
    })
    .fail(function() {
      NProgress.done();
    });
};
