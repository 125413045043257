/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Marionette = require('backbone.marionette');

var BigTabsContentCollectionView = require('./BigTabsContentCollectionView');
var BigTabsLinkCollectionView = require('./BigTabsLinkCollectionView');

var tmplBigTabsView = require('./BigTabsView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplBigTabsView,

  className: 'big-tabs-view',

  ui: {
    linksContainer: '.links-container',
    contentsContainer: '.contents-container'
  },

  regions: {
    linksContainer: '@ui.linksContainer',
    contentsContainer: '@ui.contentsContainer'
  },

  onRender: function() {
    this.showChildView(
      'linksContainer',
      new BigTabsLinkCollectionView({
        collection: this.collection
      })
    );

    this.contentCollectionView = new BigTabsContentCollectionView({
      collection: this.collection
    });
    this.showChildView('contentsContainer', this.contentCollectionView);
  }
});
