/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var Syphon = require('backbone.syphon');
var Marionette = require('backbone.marionette');

var toggleForm = require('common/util/toggleForm');

require('./DepSubfundActivitiesItemView.css');
var tmplDepSubfundActivitiesItemView = require('./DepSubfundActivitiesItemView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplDepSubfundActivitiesItemView,

  className: 'dep-subfund-activities-item-view panel panel-default',

  ui: {
    actionTypeDropdown: '.action-type-dropdown',
    amendActionForm: '.amend-action-form',
    terminateActionForm: '.terminate-action-form',
    datepickerFields: '.datepicker'
  },

  regions: {
    amendActionForm: '@ui.amendActionForm'
  },

  events: {
    'change @ui.actionTypeDropdown': 'onChangeActionType'
  },

  templateHelpers: function() {
    return {
      childIndex: this.options.childIndex + 1,
      isReadOnly: this.options.isReadOnly,
      subFundActions: this.options.subFundActions,
      actionType: this.options.actionType
    };
  },

  onBeforeShow: function() {
    var subFundFiling = this.model.get('subFundFiling') || {};
    Syphon.deserialize(this, subFundFiling);
    this.ui.datepickerFields.datepicker({});

    this.onChangeActionType();
  },

  onChangeActionType: function() {
    var actionTypeId = this.ui.actionTypeDropdown.val();

    var amend = actionTypeId === '2';
    var terminate = actionTypeId === '3';
    toggleForm(this.ui.amendActionForm, amend);
    toggleForm(this.ui.terminateActionForm, terminate);
  },

  validate: function(config) {
    var actionTypeId = this.ui.actionTypeDropdown.val();
    if (actionTypeId && _.isFunction(this.actionForm.validate)) {
      return this.actionForm.validate(config);
    }
  },

  getFormData: function() {
    var formData = Syphon.serialize(this);
    formData.subFundId = this.model.get('id');
    return formData;
  }
});
