/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var Backbone = require('backbone');

var MessengerAlertErrorView = require('../views/MessengerAlertErrorView');

var defaultConfig = {
  showCloseButton: true,
  hideAfter: 600
};

module.exports = function(msgConfig) {
  var config = _.extend({}, defaultConfig, msgConfig);

  var alertContent = new MessengerAlertErrorView({
    model: new Backbone.Model({
      errorTitle: config.errorTitle,
      errorMessage: config.errorMessage
    })
  }).render().el.outerHTML;

  Messenger().post({
    // id prevents the same error mesage from being shown more than once
    id: config.errorCode,
    message: alertContent,
    type: 'error',
    showCloseButton: config.showCloseButton,
    hideAfter: config.hideAfter
  });
};
