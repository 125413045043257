/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var PageableCollection = require('psa-backbone/models/PageableCollection');

var PlaceholderView = require('common/views/PlaceholderView');

var GetAllLicenseAddressLookups = require('../../common/services/GetAllLicenseAddressLookups');
var SecIndividualSectionAddressView = require('../views/SecIndividualSectionAddressView');
var GetIndividualAddressByIndividual = require('../services/GetIndividualAddressByIndividual');

module.exports = {
  channel: 'sc',

  event: 'build:individual-address-view',

  builder: function(individualId) {
    return new PlaceholderView({
      viewPromise: buildSecIndividualAddressSectionView(individualId)
    });
  }
};

function buildSecIndividualAddressSectionView(individualId) {
  var deferred = $.Deferred();
  $.when(GetIndividualAddressByIndividual(individualId), GetAllLicenseAddressLookups('INDIVIDUAL'))
    .done(function(individualAddresses, lookups) {
      deferred.resolve(
        new SecIndividualSectionAddressView({
          individualId: individualId,
          collection: new PageableCollection(individualAddresses, { pageSize: 25 }),
          addressTypes: lookups.addressType
        })
      );
    })
    .fail(function(err) {
      deferred.reject(err);
    });
  return deferred.promise();
}
