var Handlebars = require("handlebars/dist/handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "              <option value=\""
    + alias4(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\">"
    + alias4(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data}) : helper)))
    + "</option>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var helper;

  return "          <a href=\"#ext/nondep/entity/"
    + container.escapeExpression(((helper = (helper = helpers.entityId || (depth0 != null ? depth0.entityId : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"entityId","hash":{},"data":data}) : helper)))
    + "/dashboard\" class=\"btn btn-default\" role=\"button\">Cancel</a>\n";
},"5":function(container,depth0,helpers,partials,data) {
    var helper;

  return "          <a href=\"#nondep/entity/"
    + container.escapeExpression(((helper = (helper = helpers.entityId || (depth0 != null ? depth0.entityId : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"entityId","hash":{},"data":data}) : helper)))
    + "/dashboard\" class=\"btn btn-default\" role=\"button\">Cancel</a>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression, alias5=container.lambda;

  return "\n<div class=\"page-title clearfix\">\n  <h1 class=\"pull-left m-n p-n\">"
    + alias4(((helper = (helper = helpers.entityName || (depth0 != null ? depth0.entityName : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"entityName","hash":{},"data":data}) : helper)))
    + "</h1>\n  <div class=\"labels-container\">\n    <div class=\"nmls-label-container\"></div>\n  </div>\n</div>\n<div class=\"header-bar-container\"></div>\n<div class=\"breadcrumb-container\"></div>\n\n<h2 class=\"m-t-n\">Manage User - "
    + alias4(((helper = (helper = helpers.firstName || (depth0 != null ? depth0.firstName : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"firstName","hash":{},"data":data}) : helper)))
    + " "
    + alias4(((helper = (helper = helpers.lastName || (depth0 != null ? depth0.lastName : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"lastName","hash":{},"data":data}) : helper)))
    + "</h2>\n\n<div class=\"row\">\n  <div class=\"col-sm-4 col-md-3\">\n    <h3 class=\"text-right\">User Details</h3>\n  </div>\n</div>\n<div clas=\"row\">\n  <div class=\"form-horizontal form-static user-data\">\n    <div class=\"row\">\n      <div class=\"form-group m-b-n\">\n        <label class=\"control-label col-sm-4 col-md-3\">First Name</label>\n        <div class=\"form-control-static col-sm-8 col-md-9\">"
    + alias4(((helper = (helper = helpers.firstName || (depth0 != null ? depth0.firstName : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"firstName","hash":{},"data":data}) : helper)))
    + "</div>\n      </div>\n    </div>\n    <div class=\"row\">\n      <div class=\"form-group m-b-n\">\n        <label class=\"control-label col-sm-4 col-md-3\">Last Name</label>\n        <div class=\"form-control-static col-sm-8 col-md-9\">"
    + alias4(((helper = (helper = helpers.lastName || (depth0 != null ? depth0.lastName : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"lastName","hash":{},"data":data}) : helper)))
    + "</div>\n      </div>\n    </div>\n    <div class=\"row\">\n      <div class=\"form-group m-b-n\">\n        <label class=\"control-label col-sm-4 col-md-3\">Email</label>\n        <div class=\"form-control-static col-sm-8 col-md-9\">"
    + alias4(((helper = (helper = helpers.email || (depth0 != null ? depth0.email : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"email","hash":{},"data":data}) : helper)))
    + "</div>\n      </div>\n    </div>\n  </div>\n</div>\n<div class=\"row\">\n  <div class=\"col-sm-8 col-md-6\">\n    <div class=\"row\">\n      <div class=\"col-sm-6\">\n        <h3 class=\"text-right\">Entity Privileges</h3>\n      </div>\n    </div>\n    <div class=\"row\">\n      <div class=\"col-sm-6 col-md-5 col-md-offset-1\">\n        <p class=\"text-right m-b-md\">\n          Within the entity "
    + alias4(alias5(((stack1 = (depth0 != null ? depth0.entity : depth0)) != null ? stack1.entityName : stack1), depth0))
    + ", "
    + alias4(((helper = (helper = helpers.firstName || (depth0 != null ? depth0.firstName : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"firstName","hash":{},"data":data}) : helper)))
    + " "
    + alias4(((helper = (helper = helpers.lastName || (depth0 != null ? depth0.lastName : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"lastName","hash":{},"data":data}) : helper)))
    + " should have the following access level:\n        </p>\n      </div>\n    </div>\n    <div class=\"row\">\n      <div class=\"form-horizontal\">\n        <div class=\"form-group\">\n          <label class=\"control-label col-sm-6\">Access Level</label>\n          <div class=\"col-sm-4\">\n            <select name=\"userAccessLevel\" class=\"form-control\">\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.userAccessLevels : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "            </select>\n          </div>\n        </div>\n      </div>\n    </div>\n    <div>\n      <div class=\"col-sm-6 col-sm-offset-6\">\n        <button class=\"btn btn-primary ladda-button save-button\" data-style=\"expand-right\">\n          <span class=\"ladda-label\">Save</span>\n        </button>\n"
    + ((stack1 = (helpers.ifCond || (depth0 && depth0.ifCond) || alias2).call(alias1,(depth0 != null ? depth0.viewMode : depth0),"===","ext",{"name":"ifCond","hash":{},"fn":container.program(3, data, 0),"inverse":container.program(5, data, 0),"data":data})) != null ? stack1 : "")
    + "      </div>  \n    </div>\n  </div>\n  <div class=\"col-sm-4 col-md-6\">\n    <div class=\"row\">\n      <div class=\"col-sm-11 col-md-8\">\n        <h3 class=\"text-right\">Revoke User Access</h3>\n      </div>\n    </div>\n    <div class=\"row\">\n      <div class=\"col-sm-11 col-md-7 col-md-offset-1\">\n        <p class=\"text-right m-b-md\">\n          Click the following button to revoke "
    + alias4(((helper = (helper = helpers.firstName || (depth0 != null ? depth0.firstName : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"firstName","hash":{},"data":data}) : helper)))
    + " "
    + alias4(((helper = (helper = helpers.lastName || (depth0 != null ? depth0.lastName : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"lastName","hash":{},"data":data}) : helper)))
    + "'s access to the entity\n          "
    + alias4(alias5(((stack1 = (depth0 != null ? depth0.entity : depth0)) != null ? stack1.entityName : stack1), depth0))
    + ". The user will\n          lose access to the entity, and will need to be re-invited in order to perform any future actions.\n        </p>\n      </div>\n    </div>\n    <div class=\"row\">\n      <div class=\"col-sm-11 col-md-8\">\n        <button class=\"btn btn-danger pull-right ladda-button revoke-button\" data-style=\"expand-right\">\n          <span class=\"ladda-label\">Revoke access for "
    + alias4(((helper = (helper = helpers.firstName || (depth0 != null ? depth0.firstName : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"firstName","hash":{},"data":data}) : helper)))
    + " "
    + alias4(((helper = (helper = helpers.lastName || (depth0 != null ? depth0.lastName : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"lastName","hash":{},"data":data}) : helper)))
    + "</span>\n        </button>\n      </div>\n    </div>\n  </div>\n</div>";
},"useData":true});