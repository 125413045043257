var Handlebars = require("handlebars/dist/handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "\n<p>Specify which branch licenses will be renewed along with the principal license. Any licenses which are older than "
    + alias4(((helper = (helper = helpers.minimumLicenseAge || (depth0 != null ? depth0.minimumLicenseAge : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"minimumLicenseAge","hash":{},"data":data}) : helper)))
    + " years do not need to provide bond information.</p>\n\n<div class=\"alert alert-danger collapse validation-alert\" role=\"alert\">\n  <i class=\"fa fa-exclamation-triangle\" aria-hidden=\"true\"></i>\n  <span class=\"validation-alert-text\">Some licenses selected for renewal are not older than "
    + alias4(((helper = (helper = helpers.minimumLicenseAge || (depth0 != null ? depth0.minimumLicenseAge : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"minimumLicenseAge","hash":{},"data":data}) : helper)))
    + " years, so they must include Bonding Company, Bond #, and Bond Expiration Date.</span>\n</div>\n\n<div class=\"branch-licenses-container\"></div>";
},"useData":true});