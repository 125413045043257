/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Marionette = require('backbone.marionette');
//var Syphon = require('backbone.syphon');

//var parsleyUtil = require('common/util/parsleyUtil');
//var FileUploadView = require('common/views/FileUploadView');

//var FileUploadCollection = require('psa-backbone/models/FileUploadCollection');

require('./InstallmentSellerRequirementsSectionView.css');
var tmplInstallmentSellerRequirementsSectionView = require('./InstallmentSellerRequirementsSectionView.hbs');

module.exports = Marionette.LayoutView.extend({
    template: tmplInstallmentSellerRequirementsSectionView,

    className: 'installment-seller-requirements-section-view',

    behaviors: {
        ReadOnlyFormBehavior: {}
    },

    ui: {
        installmentSellerRequirementWrapper: '.installment-seller-requirements'
    },

    regions: {
        applicationDocumentsFileContainer: '@ui.applicationDocumentsFileContainer'
      },

    templateHelpers: function() {
    return {
        identoGoLink: this.options.identoGoLink
    };
    }})


