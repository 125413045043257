var Handlebars = require("handlebars/dist/handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "Director";
},"3":function(container,depth0,helpers,partials,data) {
    return "Director-Trustee";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), alias4=helpers.helperMissing;

  return "\n<div class=\"page-title clearfix\">\n  <h1 class=\"pull-left m-n p-n\">"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.entityIdentifiers : depth0)) != null ? stack1.entityName : stack1), depth0))
    + "</h1>\n  <div class=\"page-title-buttons-container\">\n    <div class=\"director-action-drop-down\"></div>\n  </div>\n</div>\n<div class=\"page-subtitle\">\n  <h2>"
    + ((stack1 = helpers["if"].call(alias3,(depth0 != null ? depth0.isCUTrustHC : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data})) != null ? stack1 : "")
    + "</h2>\n  <div class=\"page-subtitle-details\">"
    + alias2(((helper = (helper = helpers.positionHeld || (depth0 != null ? depth0.positionHeld : depth0)) != null ? helper : alias4),(typeof helper === "function" ? helper.call(alias3,{"name":"positionHeld","hash":{},"data":data}) : helper)))
    + " - "
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.director : depth0)) != null ? stack1.firstName : stack1), depth0))
    + " "
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.director : depth0)) != null ? stack1.lastName : stack1), depth0))
    + "</div>\n</div>\n<div class=\"breadcrumb-container\"></div>\n<div class=\"form-horizontal form-static row col-sm-8 col-sm-offset-2\">\n  <div class=\"form-group col-sm-6\">\n    <label class=\"control-label col-sm-4\">Position Held</label>\n    <div class=\"form-control-static col-sm-8\">"
    + alias2((helpers["default"] || (depth0 && depth0["default"]) || alias4).call(alias3,(depth0 != null ? depth0.positionHeld : depth0),"--",{"name":"default","hash":{},"data":data}))
    + "</div>\n  </div>\n  <div class=\"form-group col-sm-6\">\n    <label class=\"control-label col-sm-4\">First Name</label>\n    <div class=\"form-control-static col-sm-8\">"
    + alias2((helpers["default"] || (depth0 && depth0["default"]) || alias4).call(alias3,((stack1 = (depth0 != null ? depth0.director : depth0)) != null ? stack1.firstName : stack1),"--",{"name":"default","hash":{},"data":data}))
    + "</div>\n  </div>\n  <div class=\"form-group col-sm-6\">\n    <label class=\"control-label col-sm-4\">Term Length</label>\n    <div class=\"form-control-static col-sm-8\">"
    + alias2((helpers["default"] || (depth0 && depth0["default"]) || alias4).call(alias3,((stack1 = (depth0 != null ? depth0.director : depth0)) != null ? stack1.termLength : stack1),"--",{"name":"default","hash":{},"data":data}))
    + "</div>\n  </div>\n  <div class=\"form-group col-sm-6\">\n    <label class=\"control-label col-sm-4\">Middle Initial</label>\n    <div class=\"form-control-static col-sm-8\">"
    + alias2((helpers["default"] || (depth0 && depth0["default"]) || alias4).call(alias3,((stack1 = (depth0 != null ? depth0.director : depth0)) != null ? stack1.middleInitial : stack1),"--",{"name":"default","hash":{},"data":data}))
    + "</div>\n  </div>\n  <div class=\"form-group col-sm-6\">\n    <label class=\"control-label col-sm-4\">Status</label>\n    <div class=\"form-control-static col-sm-8\">"
    + alias2((helpers["default"] || (depth0 && depth0["default"]) || alias4).call(alias3,((stack1 = (depth0 != null ? depth0.director : depth0)) != null ? stack1.status : stack1),"--",{"name":"default","hash":{},"data":data}))
    + "</div>\n  </div>\n  <div class=\"form-group col-sm-6\">\n    <label class=\"control-label col-sm-4\">Last Name</label>\n    <div class=\"form-control-static col-sm-8\">"
    + alias2((helpers["default"] || (depth0 && depth0["default"]) || alias4).call(alias3,((stack1 = (depth0 != null ? depth0.director : depth0)) != null ? stack1.lastName : stack1),"--",{"name":"default","hash":{},"data":data}))
    + "</div>\n  </div>\n  <div class=\"form-group col-sm-6\">\n    <label class=\"control-label col-sm-4\">Term Start Date</label>\n    <div class=\"form-control-static col-sm-8\">"
    + alias2((helpers["default"] || (depth0 && depth0["default"]) || alias4).call(alias3,((stack1 = (depth0 != null ? depth0.director : depth0)) != null ? stack1.termStartDate : stack1),"--",{"name":"default","hash":{},"data":data}))
    + "</div>\n  </div>\n  <div class=\"form-group col-sm-6\">\n    <label class=\"control-label col-sm-4\">Term End Date</label>\n    <div class=\"form-control-static col-sm-8\">"
    + alias2((helpers["default"] || (depth0 && depth0["default"]) || alias4).call(alias3,((stack1 = (depth0 != null ? depth0.director : depth0)) != null ? stack1.termEndDate : stack1),"--",{"name":"default","hash":{},"data":data}))
    + "</div>\n  </div>\n  <div class=\"form-group col-sm-6\">\n    <label class=\"control-label col-sm-4\">Election Date</label>\n    <div class=\"form-control-static col-sm-8\">"
    + alias2((helpers["default"] || (depth0 && depth0["default"]) || alias4).call(alias3,((stack1 = (depth0 != null ? depth0.director : depth0)) != null ? stack1.electionDate : stack1),"--",{"name":"default","hash":{},"data":data}))
    + "</div>\n  </div>\n  <div class=\"form-group col-sm-6\">\n    <label class=\"control-label col-sm-4\">Appointment Basis</label>\n    <div class=\"form-control-static col-sm-8\">"
    + alias2((helpers["default"] || (depth0 && depth0["default"]) || alias4).call(alias3,((stack1 = (depth0 != null ? depth0.director : depth0)) != null ? stack1.electionBasis : stack1),"--",{"name":"default","hash":{},"data":data}))
    + "</div>\n  </div>\n  <div class=\"form-group col-sm-6\">\n    <label class=\"control-label col-sm-4\">Reason</label>\n    <div class=\"form-control-static col-sm-8\">"
    + alias2((helpers["default"] || (depth0 && depth0["default"]) || alias4).call(alias3,((stack1 = (depth0 != null ? depth0.director : depth0)) != null ? stack1.reason : stack1),"--",{"name":"default","hash":{},"data":data}))
    + "</div>\n  </div>\n  <div class=\"form-group col-sm-6\">\n    <label class=\"control-label col-sm-4\">Chairman</label>\n    <div class=\"form-control-static col-sm-8\">"
    + alias2((helpers["default"] || (depth0 && depth0["default"]) || alias4).call(alias3,(depth0 != null ? depth0.isChairmanDisplay : depth0),"--",{"name":"default","hash":{},"data":data}))
    + "</div>\n  </div>\n  <div class=\"form-group\">\n    <label class=\"control-label col-sm-2\">Comments</label>\n    <p class=\"form-control-static col-sm-10\">"
    + alias2((helpers["default"] || (depth0 && depth0["default"]) || alias4).call(alias3,((stack1 = (depth0 != null ? depth0.director : depth0)) != null ? stack1.comment : stack1),"--",{"name":"default","hash":{},"data":data}))
    + "</p>\n  </div>\n</div>";
},"useData":true});