/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var DepAA1ReviewFilingWorkTabView = require('./DepAA1ReviewFilingWorkTabView');

module.exports = DepAA1ReviewFilingWorkTabView.extend({
  className: 'dep-setup-review-filing-work-tab-view',

  options: {
    mode: 'int-submission'
  },

  onBeforeShow: function() {
    DepAA1ReviewFilingWorkTabView.prototype.onBeforeShow.apply(this, arguments);

    this.ui.reviewDecision.val('1');
    this.onChangeReviewDecision();
    this.ui.reviewDecision.prop('disabled', true);
  }
});
