var Handlebars = require("handlebars/dist/handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "\n<div class=\"modal-body\">\n    <div class=\"form-group\">\n        <label class=\"control-label\">Request Message</label>\n        <div class=\"form-control-static\">"
    + alias4(((helper = (helper = helpers.requestComments || (depth0 != null ? depth0.requestComments : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"requestComments","hash":{},"data":data}) : helper)))
    + "</div>\n      </div>\n    <div class=\"form-group\">\n      <label class=\"control-label\">Requested By</label>\n      <div class=\"form-control-static\">"
    + alias4(((helper = (helper = helpers.correspondenceCreatedBy || (depth0 != null ? depth0.correspondenceCreatedBy : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"correspondenceCreatedBy","hash":{},"data":data}) : helper)))
    + "</div>\n    </div>  \n    <div class=\"form-group\">\n      <label class=\"control-label\">Object Type</label>\n      <div class=\"form-control-static\">"
    + alias4(((helper = (helper = helpers.assignee || (depth0 != null ? depth0.assignee : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"assignee","hash":{},"data":data}) : helper)))
    + "</div>\n    </div>  \n</div>\n<div class=\"modal-footer\">\n  <button class=\"btn btn-default\" data-dismiss=\"modal\">Cancel</button>\n</div>";
},"useData":true});