/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Backbone = require('backbone');

var GetBankConversionNationalToStateFiling = require('../services/GetBankConversionNationalToStateFiling');
var GetAllBankConversionNationalToStateFilingLookups = require('../services/GetAllBankConversionNationalToStateFilingLookups');

var BankConversionNationalFiling = require('../forms/BankConversionNationalFiling');

module.exports = {
  event: 'build:filing:bank-conversion-national',

  channel: 'dp:filing',

  builder: function(config) {
    var defer = $.Deferred();
    var currFilingId = config.filingId;
    $.when(GetBankConversionNationalToStateFiling(currFilingId), GetAllBankConversionNationalToStateFilingLookups())
      .done(function(filingContent, lookups) {
        defer.resolve(
          new BankConversionNationalFiling({
            isReadOnly: config.isReadOnly,
            mode: config.mode || 'ext-new', // Possible values of mode: 'ext-new', 'int-new', 'resubmit', 'print', 'int-expand'
            model: new Backbone.Model({
              filingId: currFilingId,
              filingContent: filingContent,
              federalCharters: lookups.federalCharters,
              corporateStructures: lookups.corporateStructures,
              stateCharters: lookups.stateCharters,
              ownershipStructures: lookups.ownershipStructures
            })
          })
        );
      })
      .fail(function(err) {
        defer.reject(err);
      });

    return defer.promise();
  }
};
