/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Marionette = require('backbone.marionette');
var Syphon = require('backbone.syphon');
var Ladda = require('ladda');
var _ = require('underscore');

var GridView = require('psa-marionette/views/GridView');
var MomentCell = require('common/backgrid/MomentCell');
var toggleForm = require('common/util/toggleForm');

var tmplCorpFinFeeBatchUpdateView = require('../templates/CorpFinSAPTransmittalCrossRefBatchUpdateView.hbs');
var CorpFinInternalSidebarNavView = require('../../common/views/CorpFinInternalSidebarNavView');
var GetFeesByTransmittalReference = require('../services/GetFeesByTransmittalReference');
var SubmitUpdateFeeDetails = require('../services/SubmitUpdateFeeDetails');

require('../styles/CorpFinSAPTransmittalCrossRefBatchUpdateView.css');

module.exports = Marionette.LayoutView.extend({
  template: tmplCorpFinFeeBatchUpdateView,

  className: 'corp-fin-sap-transmittal-cross-reference-batch-update-view container-fluid',

  ui: {
    sideBar: '.side-bar-container',
    continueButton: '.btn-continue',
    transmittalReference: '.transmittal-reference',
    transmittalNumber: '.transmittal-number',
    sapDocNumber: '.sap-doc-number',
    queryFeesResultsContainer: '.query-fees-results-container',
    queryFeesForm: '.query-fees-form',
    queryButtons: '.query-buttons',
    updateFeeButtons: '.update-fee-buttons',
    cancelUpdateFeesButton: '.btn-cancel-update-fees',
    updateFeesButton: '.btn-update-fees',
    successMessage: '.success-message',
    resetFormButton: '.btn-reset-form'
  },

  regions: {
    sideBar: '@ui.sideBar',
    continueButton: '@ui.continueButton',
    queryFeesResultsContainer: '@ui.queryFeesResultsContainer'
  },

  events: {
    'click @ui.continueButton': 'onClickContinueButton',
    'click @ui.cancelUpdateFeesButton': 'onClickCancelUpdateFeesButton',
    'click @ui.updateFeesButton': 'onClickUpdateFeesButton',
    'click @ui.resetFormButton': 'onClickResetFormButton'
  },

  templateHelpers: function() {},

  onBeforeShow: function() {
    toggleForm(this.ui.updateFeeButtons, false);
    toggleForm(this.ui.successMessage, false);

    this.showChildView('sideBar', new CorpFinInternalSidebarNavView());

    var gridColumns = [
      {
        name: 'transmittalReference',
        label: 'Transmittal Reference',
        cell: 'string',
        headerCell: 'custom',
        width: 18,
        editable: false
      },
      {
        name: 'transmittalNumber',
        label: 'Transmittal Number',
        cell: 'string',
        headerCell: 'custom',
        width: 18,
        editable: false
      },
      {
        name: 'sapDocNum',
        label: 'SAP Doc Number',
        cell: 'string',
        headerCell: 'custom',
        width: 18,
        editable: false
      },
      {
        name: 'recordId',
        label: 'Record ID',
        cell: 'string',
        headerCell: 'custom',
        width: 18,
        editable: false
      },
      {
        name: 'type',
        label: 'Fee Type',
        cell: 'string',
        headerCell: 'custom',
        width: 18,
        editable: false
      },
      {
        name: 'paidDate',
        label: 'Paid Date',
        cell: MomentCell.MomentCell,
        headerCell: 'custom',
        width: 18,
        editable: false
      },
      {
        name: 'paidAmount',
        label: 'Paid Amount',
        cell: 'string',
        headerCell: 'custom',
        width: 18,
        editable: false
      }
    ];

    var feesCollection = new Backbone.Collection();

    this.searchResults = new GridView({
      columns: gridColumns,
      collection: feesCollection,
      emptyText: 'No Fees Found'
    });

    this.showChildView('queryFeesResultsContainer', this.searchResults);
  },

  getFormData: function() {
    var formData = Syphon.serialize(this);
    return formData;
  },

  validate: function() {
    return this.ui.queryFeesForm.parsley().whenValidate();
  },

  onClickContinueButton: function(e) {
    e.preventDefault();
    var form = this;
    var formData = this.getFormData();
    var searchResultsToUpdate = this.searchResults;
    var l = Ladda.create(this.ui.continueButton[0]);

    this.validate().done(function() {
      l.start();
      GetFeesByTransmittalReference(formData.transmittalReference).done(function(fees) {
        l.stop();
        searchResultsToUpdate.collection.reset(fees);
        if (searchResultsToUpdate.collection.length > 0) {
          toggleForm(form.ui.queryButtons, false);
          toggleForm(form.ui.updateFeeButtons, true);
          form.ui.transmittalReference.prop('readonly', true);
          form.ui.transmittalNumber.prop('readonly', true);
          form.ui.sapDocNumber.prop('readonly', true);
        }
      });
    });
  },

  onClickCancelUpdateFeesButton: function(e) {
    e.preventDefault();
    this.searchResults.collection.reset();
    toggleForm(this.ui.queryButtons, true);
    toggleForm(this.ui.updateFeeButtons, false);
    this.ui.transmittalReference.prop('readonly', false);
    this.ui.transmittalNumber.prop('readonly', false);
    this.ui.sapDocNumber.prop('readonly', false);
  },

  onClickUpdateFeesButton: function(e) {
    e.preventDefault();
    var form = this;
    var formData = this.getFormData();
    var ctr = 0;
    var cnt = this.searchResults.collection.models.length;
    _.each(this.searchResults.collection.models, function(feeToUpdate) {
      SubmitUpdateFeeDetails(feeToUpdate.id, formData.transmittalNumber, formData.sapDocNumber);
      ctr = ctr + 1;
      if (ctr === cnt) {
        GetFeesByTransmittalReference(formData.transmittalReference).done(function(fees) {
          form.searchResults.collection.reset(fees);
        });
      }
    });
    toggleForm(this.ui.updateFeeButtons, false);
    toggleForm(this.ui.successMessage, true);
  },

  onClickResetFormButton: function(e) {
    e.preventDefault();
    this.ui.transmittalReference.val('');
    this.ui.transmittalNumber.val('');
    this.ui.sapDocNumber.val('');
    this.searchResults.collection.reset();
    toggleForm(this.ui.updateFeeButtons, false);
    toggleForm(this.ui.successMessage, false);
    toggleForm(this.ui.queryButtons, true);
    this.ui.transmittalReference.prop('readonly', false);
    this.ui.transmittalNumber.prop('readonly', false);
    this.ui.sapDocNumber.prop('readonly', false);
  }
});
