/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var Marionette = require('backbone.marionette');

var GridView = require('psa-marionette/views/GridView');
var PageableCollection = require('psa-backbone/models/PageableCollection');

var MomentCell = require('common/backgrid/MomentCell');
var AddressCell = require('common/backgrid/AddressCell');

var tmplNonDepLicenseOfficeManagerHistoryView = require('../templates/NonDepLicenseOfficeManagerHistoryView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplNonDepLicenseOfficeManagerHistoryView,

  className: 'non-dep-license-office-manager-history-view',

  ui: {
    licenseOfficeManagerHistoryGridContainer: '.license-office-manager-history-grid-container'
  },

  regions: {
    licenseOfficeManagerHistoryGridContainer: '@ui.licenseOfficeManagerHistoryGridContainer'
  },

  onBeforeShow: function() {
    // Fill in data with display values.
    var officeManagers = _.map(this.model.get('officeManagerHistory'), function(officeManager) {
      officeManager.firstName = officeManager.firstName || '--';
      officeManager.lastName = officeManager.lastName || '--';
      officeManager.email = officeManager.email || '--';
      return officeManager;
    });

    this.officeManagerHistoryGrid = new GridView({
      columns: [
        {
          name: 'createdDate',
          label: 'Date Changed',
          cell: MomentCell.MomentCell,
          formatter: MomentCell.MomentDateTimeFormatter.extend({
            emptyValue: '--'
          }),
          sortValue: MomentCell.MomentSortValue,
          headerCell: 'custom',
          width: 15,
          editable: false
        },
        {
          name: 'createdBy',
          label: 'Changed By',
          cell: 'string',
          headerCell: 'custom',
          width: 15,
          editable: false
        },
        {
          name: 'firstName',
          label: 'First Name',
          cell: 'string',
          headerCell: 'custom',
          width: 15,
          editable: false
        },
        {
          name: 'lastName',
          label: 'Last Name',
          cell: 'string',
          headerCell: 'custom',
          width: 15,
          editable: false
        },
        {
          name: 'email',
          label: 'Email',
          cell: 'string',
          headerCell: 'custom',
          width: 20,
          editable: false
        },
        {
          name: 'address',
          label: 'Address',
          cell: AddressCell,
          headerCell: 'custom',
          width: 20,
          editable: false
        }
      ],
      collection: new PageableCollection(officeManagers, { pageSize: 25 }),
      emptyText: 'No Branch Manager History'
    });

    this.showChildView('licenseOfficeManagerHistoryGridContainer', this.officeManagerHistoryGrid);

    this.officeManagerHistoryGrid.viewGrid.sort('createdDate', 'descending');
  }
});
