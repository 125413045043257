/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Backbone = require('backbone');

var GetNewBankCharterFiling = require('../services/GetNewBankCharterFiling');
var GetAllFederalReserveBanks = require('../services/GetAllFederalReserveBanks');
var GetAllBankCharterTypes = require('../services/GetAllBankCharterTypes');

var NewBankCharterFiling = require('../forms/NewBankCharterFiling');

module.exports = {
  event: 'build:filing:new-bank-charter',

  channel: 'dp:filing',

  builder: function(config) {
    var defer = $.Deferred();
    var currFilingId = config.filingId;
    $.when(GetNewBankCharterFiling(currFilingId), GetAllBankCharterTypes(), GetAllFederalReserveBanks())
      .done(function(filingContent, bankCharterTypes, federalReserveBanks) {
        defer.resolve(
          new NewBankCharterFiling({
            isReadOnly: config.isReadOnly,
            mode: config.mode || 'ext-new', // Possible values of mode: 'ext-new', 'int-new', 'resubmit', 'print', 'int-expand'
            model: new Backbone.Model({
              filingId: currFilingId,
              filingContent: filingContent,
              bankCharterTypes: bankCharterTypes,
              federalReserveBanks: federalReserveBanks
            })
          })
        );
      })
      .fail(function(err) {
        defer.reject(err);
      });

    return defer.promise();
  }
};
