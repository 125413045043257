/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2020] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Backbone = require('backbone');
var Marionette = require('backbone.marionette');
var Syphon = require('backbone.syphon');
var Radio = require('backbone.radio');
var Ladda = require('ladda');
var _ = require('underscore');

var momentUtil = require('common/util/momentUtil');
var BreadcrumbView = require('common/views/BreadcrumbView');
var modelGet = require('common/util/modelGet');

var SubmitInvestigationInquiryNextSteps = require('../../common/services/SubmitInvestigationInquiryNextSteps');
var SecInvInqHeaderBarView = require('./SecInvInqHeaderBarView');

var tmplSecInvInqNextStepEditView = require('../templates/SecInvInqNextStepEditView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplSecInvInqNextStepEditView,

  className: 'sec-invInq-next-step-edit-view container-fluid',

  ui: {
    headerBar: '.header-bar-container',
    breadcrumb: '.breadcrumb-container',
    invInqNextStepsForm: '.inv-inq-next-steps-form',
    datePickerField: '.date-picker',
    submit: '.submit'
  },

  regions: {
    headerBar: '@ui.headerBar',
    breadcrumb: '@ui.breadcrumb'
  },

  events: {
    'click @ui.submit': 'onClickSubmit'
  },

  behaviors: {
    ToggleBehavoir: {
      toggles: [
        {
          target: '.next-step-form',
          group: '[name="hasNextStep"]',
          valuesToShow: ['1']
        }
      ]
    }
  },

  templateHelpers: function() {
    var users = modelGet(this.model, 'users', []);
    var user = modelGet(this.model, 'invInq.nextStepAssignedTo');
    var userFirstName = modelGet(this.model, 'invInq.nextStepAssignedToFirstName');
    var userLastName = modelGet(this.model, 'invInq.nextStepAssignedToLastName');

    /*
     * Current user could no longer have sc user role, add current user
     * with internal user data if username does not exist in users array
     */
    var currentUserHasRole = _.find(users, function(cm) {
      return user === cm.userId;
    });

    if (user && !currentUserHasRole) {
      var currentuser = {
        userId: user,
        name: !userFirstName && !userLastName ? '' : userFirstName + ' ' + userLastName
      };
      users.splice(_.sortedIndex(users, currentuser, 'name'), 0, currentuser);
    }

    return {
      users: users
    };
  },

  onBeforeShow: function() {
    var identifiers = this.model.get('identifiers') || {};
    var invInq = this.model.get('invInq') || {};
    var invInqId = this.model.get('invInqId');
    var objectId = identifiers.objectId;
    var objectLicenseId = identifiers.objectLicenseId;

    this.showChildView(
      'headerBar',
      new SecInvInqHeaderBarView({ model: new Backbone.Model(identifiers), isLicenseInvInq: !!objectLicenseId })
    );

    var breadcrumbs = [
      {
        link: '#sec/dashboard',
        text: 'Home'
      }
    ];

    if (invInq.isOrganization === '1') {
      breadcrumbs.push({
        link: '#sec/organization/' + objectId + '/dashboard',
        text: identifiers.applicantLegalName
      });
      if (objectLicenseId) {
        breadcrumbs.push({
          link: '#sec/organization/' + objectId + '/license/' + objectLicenseId + '/dashboard',
          text: identifiers.licenseType
        });
      }
    } else {
      breadcrumbs.push({
        link: '#sec/individual/' + identifiers.objectId + '/dashboard',
        text: identifiers.applicantLegalName
      });
      if (objectLicenseId) {
        breadcrumbs.push({
          link: '#sec/individual/' + objectId + '/license/' + objectLicenseId + '/dashboard',
          text: identifiers.licenseType
        });
      }
    }

    breadcrumbs = breadcrumbs.concat([
      {
        link: '#sec/investigation-inquiry/' + invInqId + '/dashboard',
        text: identifiers.category + ' (' + identifiers.caseNumber + ')'
      },
      {
        text: 'Edit Next Step'
      }
    ]);

    this.showChildView(
      'breadcrumb',
      new BreadcrumbView({
        breadcrumbs: breadcrumbs
      })
    );

    Syphon.deserialize(this, invInq);
    this.ui.datePickerField.datepicker({});
  },

  onClickSubmit: function(event) {
    event.preventDefault();
    var self = this;
    this.validate().done(function() {
      var l = Ladda.create(self.ui.submit[0]);
      l.start();
      var formData = self.getFormData();
      SubmitInvestigationInquiryNextSteps(formData)
        .done(function() {
          Radio.channel('navigate').trigger('show:int:sc:investigation-inquiry-dashboard', self.model.get('invInqId'));
        })
        .fail(function() {
          l.stop();
        });
    });
  },

  getFormData: function() {
    var formData = Syphon.serialize(this);

    formData.invInqId = this.model.get('invInqId');

    formData.nextStepProjectedDate = momentUtil.formatStr(
      formData.nextStepProjectedDate,
      momentUtil.defaultOutboundDateConfig
    );

    return formData;
  },

  validate: function() {
    return this.ui.invInqNextStepsForm.parsley().whenValidate();
  }
});
