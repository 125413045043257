/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var PageableCollection = require('psa-backbone/models/PageableCollection');

var PlaceholderView = require('common/views/PlaceholderView');

var CorpFinInvHistorySectionView = require('../views/CorpFinInvHistorySectionView');
var GetInvestmentHistoryByInvestment = require('../services/GetInvestmentHistoryByInvestment');

module.exports = {
  channel: 'cf',

  event: 'build:corp-fin-inv-history-section-view',

  builder: function(recordId, isReadOnly) {
    return new PlaceholderView({
      viewPromise: buildCorpFinInvHistorySectionView(recordId, isReadOnly)
    });
  }
};

function buildCorpFinInvHistorySectionView(recordId, isReadOnly) {
  var deferred = $.Deferred();
  GetInvestmentHistoryByInvestment(recordId)
    .done(function(history) {
      deferred.resolve(
        new CorpFinInvHistorySectionView({
          collection: new PageableCollection(history, { pageSize: 25 }),
          isReadOnly: isReadOnly
        })
      );
    })
    .fail(function(err) {
      deferred.reject(err);
    });
  return deferred.promise();
}
