/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var PageableCollection = require('psa-backbone/models/PageableCollection');
var _ = require('underscore');

var PlaceholderView = require('common/views/PlaceholderView');

var ConSerEntityContactsSectionView = require('../views/ConSerEntityContactsSectionView');
var GetContactsByEntityAndDepartment = require('../../common/services/GetContactsByEntityAndDepartment');

module.exports = {
  channel: 'cs',

  event: 'build:contacts-section-view',

  builder: function(entityId, departmentId) {
    return new PlaceholderView({
      viewPromise: buildContactsSectionView(entityId, departmentId)
    });
  }
};

function buildContactsSectionView(entityId, departmentId) {
  // format for GetContactsByEntityAndDepartment
  var data = {
    departmentId: departmentId,
    entityId: entityId
  };

  var deferred = $.Deferred();
  GetContactsByEntityAndDepartment(data)
    .done(function(contacts) {
      // clean contacts data
      contacts = _.map(contacts, function(contact) {
        return {
          contactId: contact.contactId,
          departmentId: contact.departmentId,
          email: contact.email,
          entityId: contact.entityId,
          fax: contact.fax,
          firstName: contact.firstName,
          lastName: contact.lastName,
          licenseTypeId: contact.licenseTypeId,
          phoneNumber: contact.phoneNumber,
          title: contact.title,
          uuid: contact.uuid,
          contactType: contact.contactType,
          contactTypeDesc: contact.contactTypeDesc,
          isDeleted: contact.isDeleted,
          address: {
            addressLine1: contact.addressLine1,
            addressLine2: contact.addressLine2,
            addressLine3: contact.addressLine3,
            city: contact.city,
            country: contact.country,
            county: contact.county,
            postalCode: contact.postalCode,
            state: contact.state
          }
        };
      });

      deferred.resolve(
        new ConSerEntityContactsSectionView({
          collection: new PageableCollection(contacts, { pageSize: 25 })
        })
      );
    })
    .fail(function(err) {
      deferred.reject(err);
    });
  return deferred.promise();
}
