/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Backbone = require('backbone');

var NotesView = require('common/views/NotesView');
var PlaceholderView = require('common/views/PlaceholderView');

var GetNotes = require('../services/GetNotes');
var SubmitNoteUpdate = require('../services/SubmitNoteUpdate');

module.exports = {
  channel: 'cs',

  event: 'build:notes-view',

  builder: function(complaintId) {
    return new PlaceholderView({
      viewPromise: buildNotesView(complaintId)
    });
  }
};

function buildNotesView(complaintId) {
  var deferred = $.Deferred();

  GetNotes(complaintId)
    .done(function(notes) {
      deferred.resolve(
        new NotesView({
          submitNoteService: SubmitNoteUpdate,
          managerRole: 'CS - Administrator',
          model: new Backbone.Model({
            objectId: complaintId,
            notes: notes
          })
        })
      );
    })
    .fail(function(err) {
      deferred.reject(err);
    });

  return deferred.promise();
}
