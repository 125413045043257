/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var _get = require('lodash.get');
var Backbone = require('backbone');
var NProgress = require('nprogress');
var Radio = require('backbone.radio');

var GetDocumentTypesByDepartment = require('common/services/GetDocumentTypesByDepartment');
var GetExamDetailsForEntity = require('../../../common/services/GetExamDetailsForEntity');
var GetExamDetailsForInternal = require('../../../common/services/GetExamDetailsForInternal');
var GetAllExamLookups = require('../../../common/services/GetAllExamLookups');

var GetReviewDecision = require('../../services/GetReviewDecision');

var ExaminerRecordExamResultsWorkTabView = require('./ExaminerRecordExamResultsWorkTabView');
var ExamResultsReviewView = require('../../views/ExamResultsReviewView');

module.exports = {
  event: 'build:nd:examiner-record-exam-results',

  channel: 'task',

  builder: function(task) {
    var defer = $.Deferred();

    var examId = _get(task, 'taskData.ND.examId');

    $.when(
      GetExamDetailsForEntity(examId),
      GetExamDetailsForInternal(examId),
      GetAllExamLookups(),
      GetDocumentTypesByDepartment('NON_DEPOSITORY'),
      GetReviewDecision({ objectId: examId, objectTypeId: 'EXAM' })
    )
      .done(function(examEntity, examInternal, examLookups, documentTypes, previousReviewDecision) {
        NProgress.set(0.5);

        var exam = _.extend({}, examEntity, examInternal);

        var LoanLogPromise;
        if (exam.loanLogCode) {
          LoanLogPromise = Radio.channel('nd:exam').request('build:exam-questions', {
            examId: examId,
            examSetupCode: exam.loanLogCode,
            mode: 'record-results'
          });
        }

        var ExamMaterialsPromise;
        if (exam.examMaterialsCode) {
          ExamMaterialsPromise = Radio.channel('nd:exam').request('build:exam-questions', {
            examId: examId,
            examSetupCode: exam.examMaterialsCode,
            mode: 'record-results'
          });
        }

        $.when(LoanLogPromise, ExamMaterialsPromise)
          .done(function(loanLogQuestionsView, examMaterialsQuestionView) {
            NProgress.set(0.85);

            var workTabView = new ExaminerRecordExamResultsWorkTabView({
              loanLogQuestionsView: loanLogQuestionsView,
              examMaterialsQuestionView: examMaterialsQuestionView,
              documentTypes: documentTypes,
              model: new Backbone.Model({
                task: task,
                taskId: task.taskId,
                exam: exam,
                examId: examId,
                examLookups: examLookups,
                previousReviewDecision: previousReviewDecision
              })
            });

            defer.resolve(
              new ExamResultsReviewView({
                loanLogQuestionsView: loanLogQuestionsView,
                examMaterialsQuestionView: examMaterialsQuestionView,
                workTabView: workTabView,
                model: new Backbone.Model({
                  task: task,
                  taskId: task.taskId,
                  exam: exam,
                  examId: exam.examId
                })
              })
            );
          })
          .fail(function(err) {
            defer.reject(err);
          });
      })
      .fail(function(err) {
        defer.reject(err);
      });

    return defer.promise();
  }
};
