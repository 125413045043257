/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var moment = require('moment');

var cleanUpResponse = require('common/util/cleanUpResponse');

module.exports = function(fundId) {
  var defer = $.Deferred();

  $.soap({
    body: {
      GetTrustSubFundsByTrustFund: {
        $xmlns: 'http://dobs.pa.gov/dp/fund/bpm/1.0',
        TrustFundID: fundId
      }
    },
    blueprintArrays: ['GetTrustSubFundsByTrustFundResponse.DP_TrustSubFund'],
    blueprint: {
      subFunds: [
        '$..DP_TrustSubFund',
        {
          subFundId: '$..ID',
          entityId: '$..EntityID',
          entityName: '$..EntityName',
          fundId: '$..TrustFundID',
          fundName: '$..TrustFundName',
          name: '$..Name',
          effectiveDate: '$..EffectiveDate',
          approvalDate: '$..ApprovalDate',
          lastAmendmentDate: '$..LastAmendmentDate',
          lastAmendmentApprovalDate: '$..LastAmendmentApprovalDate',
          terminatedDate: '$..TerminatedDate',
          comments: '$..Comments',
          effectiveAmendDate: '$..EffectiveAmendDate'
        }
      ]
    }
  })
    .done(function(response) {
      cleanUpResponse(response);

      _.each(response.subFunds, function(subFund) {
        if (subFund.effectiveDate) {
          subFund.effectiveDate = moment(subFund.effectiveDate, 'YYYY-MM-DD');
        }
        if (subFund.approvalDate) {
          subFund.approvalDate = moment(subFund.approvalDate, 'YYYY-MM-DD');
        }
        if (subFund.lastAmendmentDate) {
          subFund.lastAmendmentDate = moment(subFund.lastAmendmentDate, 'YYYY-MM-DD');
        }
        if (subFund.lastAmendmentApprovalDate) {
          subFund.lastAmendmentApprovalDate = moment(subFund.lastAmendmentApprovalDate, 'YYYY-MM-DD');
        }
        if (subFund.terminatedDate) {
          subFund.terminatedDate = moment(subFund.terminatedDate, 'YYYY-MM-DD');
        }
        if (subFund.effectiveAmendDate) {
          subFund.effectiveAmendDate = moment(subFund.effectiveAmendDate, 'YYYY-MM-DD');
        }
        var comments = subFund.comments;
        if (comments) {
          subFund.comments = comments.length > 100 ? comments.substring(0, 100) + '...' : comments;
        } else {
          subFund.comments = '--';
        }
      });
      defer.resolve(response.subFunds);
    })
    .fail(function(err) {
      defer.reject(err);
    });

  return defer.promise();
};
