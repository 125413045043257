var Handlebars = require("handlebars/dist/handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "New Director";
},"3":function(container,depth0,helpers,partials,data) {
    return "New Director-Trustee";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.escapeExpression, alias2=depth0 != null ? depth0 : (container.nullContext || {});

  return "\n<div class=\"page-title clearfix\">\n  <h1 class=\"pull-left m-n p-n\">"
    + alias1(container.lambda(((stack1 = (depth0 != null ? depth0.entityIdentifiers : depth0)) != null ? stack1.entityName : stack1), depth0))
    + "</h1>\n</div>\n<div class=\"breadcrumb-container\"></div>\n<div class=\"row m-b-xs\">\n  <div class=\"col-sm-12 col-md-10 col-md-offset-1\">\n    <div class=\"form-group\">\n      <div class=\"col-sm-9 col-md-8 col-md-offset-2 col-sm-offset-3\">\n        <h3 class=\"m-t-n\">"
    + ((stack1 = helpers["if"].call(alias2,(depth0 != null ? depth0.isCUTrustHC : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data})) != null ? stack1 : "")
    + "</h3>\n      </div>\n    </div>\n  </div>\n</div>\n<form class=\"director-form-container\"></form>\n<div class=\"row m-b-lg\">\n  <div class=\"col-sm-12 col-md-10 col-md-offset-1\">\n    <div class=\"form-group\">\n      <div class=\"col-sm-9 col-md-8 col-md-offset-2 col-sm-offset-3\">\n        <a href=\"#dep/entity/"
    + alias1(((helper = (helper = helpers.entityId || (depth0 != null ? depth0.entityId : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(alias2,{"name":"entityId","hash":{},"data":data}) : helper)))
    + "/dashboard\" class=\"btn btn-default\">Cancel</a>\n        <button class=\"btn btn-primary submit ladda-button\" data-style=\"expand-right\" type=\"button\">\n          <span class=\"ladda-label\">Submit</span>\n        </button>\n      </div>\n    </div>\n  </div>\n</div>";
},"useData":true});