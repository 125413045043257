/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var cleanUpResponse = require('common/util/cleanUpResponse');

module.exports = function(examId) {
  var deferred = $.Deferred();

  $.soap({
    body: {
      GetExamDetails: {
        $xmlns: 'http://dobs.pa.gov/dp/exam/bpm/1.0',
        ExamID: examId
      }
    },
    blueprint: {
      examId: '$..ID',
      entityId: '$..EntityID',
      entityName: '$..EntityName',
      entityType: '$..EntityType',
      ppobAddress: {
        addressLine1: '$..AddressLine1',
        addressLine2: '$..AddressLine2',
        addressLine3: '$..AddressLine3',
        county: '$..County',
        city: '$..City',
        state: '$..State',
        postalCode: '$..PostalCode',
        country: '$..Country'
      },
      currentReviewerRoleId: '$..CurrentReviewerRoleID',
      actionComments: '$..ActionComments',
      isCurrentReviewerTemp: '$..IsCurrentReviewerTemp',
      currentReviewerRole: '$..Role',
      isHoldingCompanyExam: '$..IsHoldingCompanyExam'
    }
  })
    .done(function(response) {
      cleanUpResponse(response);
      deferred.resolve(response);
    })
    .fail(function(err) {
      deferred.reject();
    });

  return deferred.promise();
};
