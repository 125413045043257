/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Marionette = require('backbone.marionette');
var Syphon = require('backbone.syphon');
var Ladda = require('ladda');
var moment = require('moment');

var SubmitComplianceAGReferralPayment = require('../services/SubmitComplianceAGReferralPayment');

var tmplSecComplianceAGReferralPaymentModalView = require('../templates/SecComplianceAGReferralPaymentModalView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplSecComplianceAGReferralPaymentModalView,

  className: 'sec-compliance-ag-referral-payment-modal-view',

  ui: {
    modalBody: '.modal-body',
    AGPaymentForm: '.ag-payment-form',
    paymentDate: '.payment-date',
    submit: '.submit'
  },

  events: {
    'click @ui.submit': 'onClickSubmit'
  },

  onBeforeShow: function() {
    var formData = this.model.toJSON();
    var paymentDate = this.model.get('paymentDate');
    if (paymentDate && moment.isMoment(paymentDate) && paymentDate.isValid()) {
      formData.paymentDate = paymentDate.format('MM/DD/YYYY');
    }

    Syphon.deserialize(this, formData);

    this.ui.paymentDate.datepicker();
  },

  onClickSubmit: function(e) {
    e.preventDefault();
    var self = this;
    this.ui.AGPaymentForm.parsley()
      .whenValidate()
      .done(function() {
        var l = Ladda.create(self.ui.submit[0]);
        l.start();

        SubmitComplianceAGReferralPayment(self.getFormData())
          .done(function(payment) {
            self.triggerMethod('modal:close', payment);
          })
          .fail(function() {
            l.stop();
          });
      });
  },

  getFormData: function() {
    var data = Syphon.serialize(this);
    data.AGPaymentId = this.model.get('AGPaymentId');
    data.complianceId = this.model.get('complianceId');
    data.amount = parseFloat(data.amount || 0).toFixed(2);
    return data;
  }
});
