/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Marionette = require('backbone.marionette');
var Syphon = require('backbone.syphon');
var moment = require('moment');
var Ladda = require('ladda');
var Radio = require('backbone.radio');

var BreadcrumbView = require('common/views/BreadcrumbView');
var NonDepInternalSidebarNavView = require('../../common/views/NonDepInternalSidebarNavView');
var NonDepIndividualView = require('../../common/views/NonDepIndividualView');

var SubmitInternalNewIndividual = require('../services/SubmitInternalNewIndividual');

require('../styles/NonDepNewIndividualFormView.css');
var tmplNonDepNewIndividualFormView = require('../templates/NonDepNewIndividualFormView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplNonDepNewIndividualFormView,

  className: 'non-dep-internal-new-individual-form-view container-fluid',

  ui: {
    sideBar: '.side-bar-container',
    breadCrumbContainer: '.breadcrumb-container',
    nonDepIndividualForm: '.non-dep-individual-form',
    submit: '.btn-submit'
  },

  regions: {
    sideBar: '@ui.sideBar',
    breadCrumbContainer: '@ui.breadCrumbContainer',
    nonDepIndividualForm: '@ui.nonDepIndividualForm'
  },

  events: {
    'click @ui.submit': 'onClickSubmit'
  },

  onBeforeShow: function() {
    this.showChildView('sideBar', new NonDepInternalSidebarNavView());

    this.showChildView(
      'breadCrumbContainer',
      new BreadcrumbView({
        breadcrumbs: [
          {
            text: 'Home',
            link: '#nondep/dashboard'
          },
          {
            text: 'Create a New Individual'
          }
        ]
      })
    );

    this.individualForm = new NonDepIndividualView({
      model: this.model,
      fullProfileRequired: false,
      isReadOnly: false
    });

    this.showChildView('nonDepIndividualForm', this.individualForm);
  },

  onClickSubmit: function(e) {
    e.preventDefault();
    this.validate().done(
      function() {
        var laddaContext = Ladda.create(this.ui.submit.get(0));
        laddaContext.start();
        SubmitInternalNewIndividual(this.getFormData())
          .done(function(newIndividualId) {
            Radio.trigger('navigate').trigger('show:individual-dashboard', newIndividualId);
          })
          .fail(function() {
            laddaContext.stop();
          });
      }.bind(this)
    );
  },

  validate: function() {
    return this.ui.nonDepIndividualForm
      .find('form')
      .parsley()
      .whenValidate();
  },

  getFormData: function() {
    var formData = Syphon.serialize(this);

    formData.dob = formData.dob ? moment(formData.dob, 'MM/DD/YYYY').format('YYYY-MM-DD') : undefined;

    return formData;
  }
});
