/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');

var cleanUpResponse = require('common/util/cleanUpResponse');

module.exports = function(filingId) {
  var deferred = $.Deferred();
  $.soap({
    body: {
      GetCreditUnionSEGRequestFiling: {
        $xmlns: 'http://dobs.pa.gov/dp/filing/bpm/1.0',
        FilingID: filingId
      }
    },
    blueprintArrays: [
      'GetCreditUnionSEGRequestFilingResponse.GroupLocations.DP_FilCUSEGRequestGroupLocation',
      'GetCreditUnionSEGRequestFilingResponse.SupportingDocuments.DP_FilingSupportingDocument',
      'GetCreditUnionSEGRequestFilingResponse.Individuals.DP_FilCUSEGRequestIndividual'
    ],
    blueprint: {
      id: '$..ID',
      filingId: '$..FilingID',
      entityId: '$..EntityID',
      groupEmployeesCount: '$..GroupEmployeesCount',
      groupEmployeesComments: '$..GroupEmployeesComments',
      stateOfIncorporation: '$..StateOfIncorporation',
      stateGroupRegisteredNumber: '$..StateGroupRegisteredNumber',
      stateGroupAddressID: '$..StateGroupAddressID',
      stateGroupAddressComments: '$..StateGroupAddressComments',
      groupName: '$..GroupName',
      groupAddressId: '$..GroupAddressID',
      isGroupNonProfit: '$..IsGroupNonProfit',
      isHqAddressDiffer: '$..IsHqAddressDiffer',
      entityTypeID: '$..EntityTypeID',
      otherTypeComments: '$..OtherTypeComments',
      groupBusinessDesc: '$..GroupBusinessDesc',
      groupWebsites: '$..GroupWebsites',
      numEmployessLocation: '$..NumEmployessLocation',
      numMembersLocation: '$..NumMembersLocation',
      numStudentsLocation: '$..NumStudentsLocation',
      numAlumniLocation: '$..NumAlumniLocation',
      numBoardLocation: '$..NumBoardLocation',
      numOtherLocation: '$..NumOtherLocation',
      numEmployessHQ: '$..NumEmployessHQ',
      isAllMembersHQ: '$..IsAllMembersAtHQ',
      StateVerification: {
        file: {
          fileId: '$..StateVerificationDocID',
          fileName: '$..StateVerificationDocName'
        }
      },
      PDOBSRequestLetter: {
        file: {
          fileId: '$..PDOBSRequestLetterDocID',
          fileName: '$..PDOBSRequestLetterDocName'
        },
        pdobsRequestLetterComments: '$..PDOBSRequestLetterComments'
      },
      creditUnionRequestLetter: {
        file: {
          fileId: '$..CreditUnionRequestLetterDocID',
          fileName: '$..CreditUnionRequestLetterDocName'
        },
        creditUnionRequestLetterComments: '$..CreditUnionRequestLetterComments'
      },
      nonProfitByLaws: {
        file: {
          fileId: '$..NonProfitByLawsDocID',
          fileName: '$..NonProfitByLawsDocName'
        }
      },
      bylaws: {
        file: {
          fileId: '$..BylawsDocID',
          fileName: '$..BylawsDocName'
        },
        bylawsComments: '$..BylawsComments'
      },
      groupAddress: {
        addressLine1: '$..GroupAddressAddressLine1',
        addressLine2: '$..GroupAddressAddressLine2',
        addressLine3: '$..GroupAddressAddressLine3',
        county: '$..GroupAddressCounty',
        city: '$..GroupAddressCity',
        state: '$..GroupAddressState',
        postalCode: '$..GroupAddressPostalCode',
        country: '$..GroupAddressCountry'
      },
      regAddress: {
        addressLine1: '$..StateGroupAddressAddressLine1',
        addressLine2: '$..StateGroupAddressAddressLine2',
        addressLine3: '$..StateGroupAddressAddressLine3',
        county: '$..StateGroupAddressCounty',
        city: '$..StateGroupAddressCity',
        state: '$..StateGroupAddressState',
        postalCode: '$..StateGroupAddressPostalCode',
        country: '$..StateGroupAddressCountry'
      },
      groupLocations: [
        '$..DP_FilCUSEGRequestGroupLocation',
        {
          id: '$..ID',
          addressId: '$..AddressID',
          distanceToCU: '$..DistanceToCU',
          employeeCount: '$..EmployeeCount',
          address: {
            addressId: '$..AddressID',
            addressLine1: '$..AddressLine1',
            addressLine2: '$..AddressLine2',
            addressLine3: '$..AddressLine3',
            city: '$..City',
            county: '$..County',
            country: '$..Country',
            postalCode: '$..PostalCode',
            state: '$..State'
          },
          addressLine1: '$..AddressLine1',
          addressLine2: '$..AddressLine2',
          addressLine3: '$..AddressLine3',
          city: '$..City',
          state: '$..State',
          postalCode: '$..PostalCode',
          county: '$..County'
        }
      ],
      supportingDocuments: [
        '$..DP_FilingSupportingDocument',
        {
          id: '$..ID',
          filingId: '$..FilingID',
          documentId: '$..DocumentID',
          documentName: '$..DocumentName',
          documentTypeId: '$..DocumentTypeID',
          documentType: '$..DocumentType',
          file: {
            fileId: '$.DocumentID',
            fileName: '$.DocumentName'
          }
        }
      ],
      individuals: [
        '$..DP_FilCUSEGRequestIndividual',
        {
          individualTypeId: '$..IndividualTypeID',
          individualType: '$..IndividualType'
        }
      ]
    }
  })
    .done(function(response) {
      cleanUpResponse(response);
      _.each(response.groupLocations, function(location) {
        location.addressLine = [location.addressLine1, location.addressLine2, location.addressLine3].join(' ');
      });
      _.each(response.individuals, function(ind) {
        var individualType = ind.individualType;
        response[individualType] = '1';
      });
      deferred.resolve(response);
    })
    .fail(function(err) {
      deferred.reject();
    });

  return deferred.promise();
};
