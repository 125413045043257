/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var PageableCollection = require('psa-backbone/models/PageableCollection');

var PlaceholderView = require('common/views/PlaceholderView');

var CorpFinPriorNamesSectionView = require('../views/CorpFinPriorNamesSectionView');
var GetPriorNamesByRecord = require('../services/GetPriorNamesByRecord');

module.exports = {
  channel: 'cf',

  event: 'build:corp-fin-prior-names-section-view',

  builder: function(recordId, recordCategoryId, isReadOnly) {
    return new PlaceholderView({
      viewPromise: buildCorpFinPriorNamesSectionView(recordId, recordCategoryId, isReadOnly)
    });
  }
};

function buildCorpFinPriorNamesSectionView(recordId, recordCategoryId, isReadOnly) {
  var deferred = $.Deferred();
  GetPriorNamesByRecord(recordId)
    .done(function(priorNames) {
      deferred.resolve(
        new CorpFinPriorNamesSectionView({
          collection: new PageableCollection(priorNames, { pageSize: 25 }),
          recordCategoryId: recordCategoryId,
          isReadOnly: isReadOnly
        })
      );
    })
    .fail(function(err) {
      deferred.reject(err);
    });
  return deferred.promise();
}
