/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');

var cleanUpResponse = require('common/util/cleanUpResponse');

module.exports = function() {
  var deferred = $.Deferred();
  $.soap({
    body: {
      GetAllSubmitAuditLookups: {
        $xmlns: 'http://dobs.pa.gov/dp/lookup/bpm/1.0'
      }
    },
    blueprintArrays: ['GetAllSubmitAuditLookups.AuditTypes.DP_LU_AuditType'],
    blueprint: {
      auditTypes: [
        '$..DP_LU_AuditType',
        {
          id: '$..ID',
          type: '$..Type',
          categoryId: '$..CategoryID'
        }
      ]
    }
  })
    .done(function(response) {
      cleanUpResponse(response);
      // Filter audit types by category equals to Trust
      response.auditTypes = _.filter(response.auditTypes, function(auditType) {
        return auditType.categoryId === '3';
      });

      deferred.resolve(response.auditTypes);
    })
    .fail(function(err) {
      deferred.reject();
    });

  return deferred.promise();
};
