/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var moment = require('moment');

var cleanUpResponse = require('common/util/cleanUpResponse');

module.exports = function(entityId) {
  var deferred = $.Deferred();

  $.soap({
    body: {
      GetEntityHistory: {
        $xmlns: 'http://dobs.pa.gov/nd/entity/bpm/1.0',
        EntityID: entityId
      }
    },
    blueprintArrays: [
      'GetEntityHistoryResponse.NameHistory.ND_EntityNameHistory',
      'GetEntityHistoryResponse.DBAHistory.ND_EntityDBA',
      'GetEntityHistoryResponse.OfficerHistory.ND_EntityOfficer',
      'GetEntityHistoryResponse.SiteRecordkeepingHistory.ND_EntitySiteRecordkeepingHistory',
    ],
    blueprint: {
      nameHistory: [
        '$..ND_EntityNameHistory',
        {
          entityName: '$.EntityName',
          effectiveDate: '$.EffectiveDate',
          createdBy: '$.CreatedBy'
        }
      ],
      dbaHistory: [
        '$..ND_EntityDBA',
        {
          dba: '$.DBA',
          modifiedDate: '$.ModifiedDate',
          modifiedBy: '$.ModifiedBy'
        }
      ],
      officerHistory: [
        '$..ND_EntityOfficer',
        {
          entityOfficerTypeName: '$.EntityOfficerTypeName',
          modifiedDate: '$.ModifiedDate',
          modifiedBy: '$.ModifiedBy',
          firstName: '$.FirstName',
          lastName: '$.LastName',
          email: '$.Email'
        }
      ],
      siteRecordkeepingHistory: [
        '$..ND_EntitySiteRecordkeepingHistory',
        {
          id: '$.ID',
          entityId: '$.EntityID',
          createdDate: '$.CreatedDate',
          createdBy: '$.CreatedBy'
        }
      ]
    }
  })
    .done(function(response) {
      cleanUpResponse(response);

      _.each(response.nameHistory, function(name) {
        if (name.effectiveDate) {
          name.effectiveDate = moment(name.effectiveDate, moment.ISO_8601);
        }
      });

      _.each(response.dbaHistory, function(dba) {
        if (dba.modifiedDate) {
          dba.modifiedDate = moment(dba.modifiedDate, moment.ISO_8601);
        }
      });

      _.each(response.officerHistory, function(officer) {
        if (officer.modifiedDate) {
          officer.modifiedDate = moment(officer.modifiedDate, moment.ISO_8601);
        }
      });

      _.each(response.siteRecordkeepingHistory, function(siteRecordkeeping) {
        if (siteRecordkeeping.createdDate) {
          siteRecordkeeping.createdDate = moment(siteRecordkeeping.createdDate, moment.ISO_8601);
        }
      });

      deferred.resolve(response);
    })
    .fail(function(err) {
      deferred.reject(err);
    });

  return deferred.promise();
};
