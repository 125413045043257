/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2020] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */
var _ = require('underscore');

var NProgress = require('nprogress');

var NavUtils = require('psa-core/util/nav');

var Scaffold = require('common/layout/Scaffold');

var GetUsersForRole = require('common/services/GetUsersForRole');
var GetComplaint = require('../services/GetComplaint');
var GetDocumentTypesByDepartment = require('common/services/GetDocumentTypesByDepartment');
var GetEntityByIdAndDepartment = require('../../common/services/GetEntityByIdAndDepartment');
var GetAllCorrespondenceRecipientTypes = require('../services/GetAllCorrespondenceRecipientTypes');

var ConSerComplaintSectionCorrespondenceView = require('../views/ConSerComplaintCorrespondenceNewView');

module.exports = function(complaintId) {
  NProgress.start();
  NProgress.set(0.4);
  $.when(
    GetComplaint(complaintId),
    GetUsersForRole('CS - User'),
    GetDocumentTypesByDepartment('CONSUMER_SERVICES'),
    GetAllCorrespondenceRecipientTypes()
  )
    .done(function(complaint, caseManagers, documentTypes, recipientTypes) {
      NProgress.set(0.7);

      var hasEntity = false;
      var departmentId = complaint.departmentId;
      if (complaint.entityId && (departmentId === '1000' || departmentId === '2000' || departmentId === '3000')) {
        hasEntity = true;
      }

      var hasExternalUser = false;
      if (complaint.externalUserId) {
        hasExternalUser = true;
      }

      GetEntityByIdAndDepartment(complaint.entityId, complaint.departmentId)
        .done(function(entity) {
          NavUtils.navigate('conser/complaint/' + complaintId + '/new-correspondence');
          Scaffold.content.show(
            new ConSerComplaintSectionCorrespondenceView({
              model: new Backbone.Model({
                complaintId: complaintId,
                complaint: complaint,
                caseManagers: caseManagers,
                documentTypes: documentTypes,
                entity: entity,
                recipientTypes: _.filter(recipientTypes, function(recipientType) {
                  if (recipientType.id === '1') {
                    return hasEntity;
                  } else if (recipientType.id === '2') {
                    return hasExternalUser;
                  } else {
                    return false;
                  }
                })
              })
            })
          );
          NProgress.done();
        })
        .fail(function(err) {
          NProgress.done();
        });
    })
    .fail(function(err) {
      NProgress.done();
    });
};
