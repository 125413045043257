/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2020] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _get = require('lodash.get');

var Session = require('psa-core/Session');
var Config = require('../../../config/Config');

var userHasRole = require('common/util/userHasRole');
var SidebarNavView = require('common/views/SidebarNavView');

require('../styles/SecInternalSidebarNavView.css');
var tmplSecInternalSidebarNavView = require('../templates/SecInternalSidebarNavView.hbs');

module.exports = SidebarNavView.extend({
  template: tmplSecInternalSidebarNavView,

  templateHelpers: function() {
    // Get link to ihub folder with reports
    var iHubReportDirectory = _get(Session, 'departments.SECURITIES.iHubReportDirectory');
    var iHubFolderLink = Config.getIHubFolderLink(iHubReportDirectory);
    return {
      iHubFolderLink: iHubFolderLink,
      isExaminer:
        userHasRole('SC - Examiner') ||
        userHasRole('SC - Chief Examiner') ||
        userHasRole('SC - Filing Examiner') ||
        userHasRole('SC - Exam Chief') ||
        userHasRole('SC - Exam Clerical') ||
        userHasRole('SC - Exam Director') ||
        userHasRole('SC - Exam Supervisor')
    };
  }
});
