/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var Marionette = require('backbone.marionette');
var Radio = require('backbone.radio');
var FileUploadCollection = require('psa-backbone/models/FileUploadCollection');

var UIUtil = require('psa-core/util/ui');

var modelGet = require('common/util/modelGet');

var ConfirmationModalView = require('common/views/ConfirmationModalView');
var BreadcrumbView = require('common/views/BreadcrumbView');
var FileUploadView = require('common/views/FileUploadView');

var SubmitAcknowledgeNotification = require('../../services/SubmitAcknowledgeNotification');

var tmplVerifyContactsRequestTaskView = require('./VerifyContactsRequestTaskView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplVerifyContactsRequestTaskView,

  className: 'entity-view-notification-task-view container-fluid',

  ui: {
    breadcrumb: '.breadcrumb-container',
    documentContainer: '.document-container',
    submit: '.submit',
    notificationContainer: '.notification-container',
    verifyContactsForm: '.verify-contacts-form'
  },

  regions: {
    breadcrumb: '@ui.breadcrumb',
    documentContainer: '@ui.documentContainer'
  },

  events: {
    'click @ui.submit': 'onClickSubmit'
  },

  templateHelpers: function() {
    var entityId = this.model.get('entityId');
    var licenseId = this.model.get('licenseId');
    var accessLevelId = this.model.get('accessLevelId');
    // Only Admin and Manager can submit amendment and perform action on this task
    var hasSubmitPermissions = accessLevelId >= 20;

    return {
      contactsEditUrl: '#ext/nondep/entity/' + entityId + '/license/' + licenseId + '/contacts-change',
      hasSubmitPermissions: hasSubmitPermissions
    };
  },

  onBeforeShow: function() {
    var entityId = this.model.get('entityId');
    var licenseId = this.model.get('licenseId');
    var entityName = modelGet(this.model, 'licenseIdentifiers.entityName');
    var licenseNumber = modelGet(this.model, 'licenseIdentifiers.licenseNumber');
    var licenseType = modelGet(this.model, 'licenseIdentifiers.licenseType');
    var taskDescription = modelGet(this.model, 'task.taskType.description');
    var documents = modelGet(this.model, 'notification.documents');
    _.each(documents, function(document) {
      document = _.extend(document, document.file);
    });

    this.showChildView(
      'breadcrumb',
      new BreadcrumbView({
        breadcrumbs: [
          {
            link: '#ext/nondep/dashboard',
            text: 'Home'
          },
          {
            link: '#ext/nondep/entity/' + entityId + '/dashboard',
            text: entityName
          },
          {
            link: '#ext/nondep/entity/' + entityId + '/license/' + licenseId,
            text: 'License #' + licenseNumber + ' - ' + licenseType
          },
          {
            text: taskDescription
          }
        ]
      })
    );

    if (documents.length) {
      this.fileUploadTable = new FileUploadView({
        isReadOnly: true,
        collection: new FileUploadCollection(documents),
        emptyText: 'No Related Documents'
      });
      this.showChildView('documentContainer', this.fileUploadTable);
    }
  },

  onClickSubmit: function(e) {
    e.preventDefault();
    var taskId = this.model.get('taskId');
    var notificationId = this.model.get('notificationId');
    var filingId = modelGet(this.model, 'task.taskData.ND.ndFilingId');
    this.validate().done(function() {
      UIUtil.showModal({
        title: 'Submit Notification',
        view: new ConfirmationModalView({
          confirmText: 'Submit',
          model: new Backbone.Model({
            message: 'Are you sure you want to submit?',
            onConfirm: function() {
              return SubmitAcknowledgeNotification(taskId, notificationId, filingId);
            },
            onConfirmDone: function() {
              Radio.channel('navigate').trigger('show:ext:nd:dashboard');
            }
          })
        })
      });
    });
  },

  validate: function() {
    return this.ui.verifyContactsForm.parsley().whenValidate();
  }
});
