/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var moment = require('moment');

var cleanUpResponse = require('common/util/cleanUpResponse');

module.exports = function(entityId) {
  var deferred = $.Deferred();

  $.soap({
    body: {
      GetOfficerByEntityID: {
        $xmlns: 'http://dobs.pa.gov/dp/officer/bpm/1.0',
        EntityID: entityId
      }
    },
    blueprintArrays: ['GetOfficerByEntityIDResponse.DP_EntityOfficer'],
    blueprint: {
      officers: [
        '$..DP_EntityOfficer',
        {
          officerId: '$..ID',
          entityId: '$..EntityID',
          officerType: '$..Type',
          officerTypeId: '$..OfficerTypeID',
          firstName: '$..FirstName',
          lastName: '$..LastName',
          middleInitial: '$..MiddleInitial',
          title: '$..Title',
          statusId: '$..StatusID',
          termLength: '$..TermLength',
          termStartDate: '$..TermStartDate',
          termEndDate: '$..TermEndDate',
          status: '$..Status',
          isDeleted: '$..IsDeleted'
        }
      ]
    }
  })
    .done(function(response) {
      cleanUpResponse(response);
      _.each(response.officers, function(officer) {
        officer.title = officer.title || '--';
        officer.middleInitial = officer.middleInitial || '--';
        officer.termLength = officer.termLength || '--';

        if (officer.termStartDate) {
          officer.termStartDate = moment(officer.termStartDate, 'YYYY-MM-DD');
        }
        if (officer.termEndDate) {
          officer.termEndDate = moment(officer.termEndDate, 'YYYY-MM-DD');
        }
      });
      deferred.resolve(response.officers);
    })
    .fail(function(err) {
      deferred.reject();
    });
  return deferred.promise();
};
