var Handlebars = require("handlebars/dist/handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "    <div class=\"form-group required\">\n      <label class=\"control-label col-sm-4 col-md-3\">Fee Amount</label>\n      <div class=\"col-sm-6 col-md-7\">\n        <div class=\"input-group\">\n          <span class=\"input-group-addon\">$</span>\n          <input type=\"number\" step=\"any\" name=\"feeAmount\" class=\"form-control\" placeholder=\"Amount\"\n            data-parsley-required=\"true\" data-parsley-dollar-amount=\"true\"\n            data-parsley-errors-container=\".fee-amount-error-container\" data-parsley-minimum=\"0\" />\n        </div>\n        <div class=\"fee-amount-error-container\"></div>\n      </div>\n    </div>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "\n<div class=\"modal-body loading\">\n  <form class=\"form-horizontal payment-form\">\n    <div class=\"form-group required\">\n      <label class=\"control-label col-sm-4 col-md-3\">Description</label>\n      <div class=\"col-sm-6 col-md-7\">\n        <input type=\"text\" name=\"description\" class=\"form-control\" placeholder=\"Description\"\n          data-parsley-required=\"true\" data-parsley-maxlength=\"500\"\n          data-parsley-errors-container=\".description-error-container\" />\n        <div class=\"description-error-container\"></div>\n      </div>\n    </div>\n    <div class=\"form-group required\">\n      <label class=\"control-label col-sm-4 col-md-3\">Payment Amount</label>\n      <div class=\"col-sm-6 col-md-7\">\n        <div class=\"input-group\">\n          <span class=\"input-group-addon\">$</span>\n          <input type=\"number\" step=\"any\" name=\"amount\" class=\"form-control\" placeholder=\"Amount\"\n            data-parsley-required=\"true\" data-parsley-dollar-amount=\"true\"\n            data-parsley-errors-container=\".amount-error-container\" data-parsley-strict-minimum=\"0\" />\n        </div>\n        <div class=\"amount-error-container\"></div>\n      </div>\n    </div>\n"
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.showFees : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "    <div class=\"form-group required\">\n      <label class=\"control-label col-sm-4 col-md-3\">Due Date</label>\n      <div class=\"col-sm-6 col-md-7\">\n        <div class=\"input-group\">\n          <input type=\"text\" name=\"dueDate\" class=\"form-control datepicker due-date\" placeholder=\"Due Date\"\n            data-parsley-required=\"true\" data-parsley-date=\"\" data-parsley-trigger-after-failure=\"changeDate\"\n            data-parsley-errors-container=\"#dueDateErrorsContainer\" />\n          <div class=\"input-group-addon\">\n            <i class=\"fa fa-calendar\"></i>\n          </div>\n        </div>\n        <div id=\"dueDateErrorsContainer\"></div>\n      </div>\n    </div>\n  </form>\n</div>\n<div class=\"modal-footer\">\n  <button class=\"btn btn-default\" data-dismiss=\"modal\">Cancel</button>\n  <button class=\"btn btn-primary submit ladda-button\" data-style=\"expand-right\" type=\"button\">\n    <span class=\"ladda-label\">Submit</span>\n  </button>\n</div>";
},"useData":true});