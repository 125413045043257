/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var Backbone = require('backbone');

var DebtManagementServicesAnnualReport = require('../forms/DebtManagementServicesAnnualReport');
var GetAnnualReportByApplication = require('../services/GetAnnualReportByApplication');
var GetDebtManagementServicesAnnualReport = require('../services/GetDebtManagementServicesAnnualReport');

module.exports = {
  event: 'build:annual-report:debt-management-services',

  channel: 'nd:application',

  builder: function(config) {
    var defer = $.Deferred();

    var applicationId = config.applicationId;

    GetAnnualReportByApplication(applicationId)
      .done(function(annualReport) {
        var annualReportId = annualReport.annualReportId;
        var annualReportYear = annualReport.year;

        $.when(GetDebtManagementServicesAnnualReport(annualReportId))
          .done(function(debtManagementServicesAnnualReport) {
            _.extend(annualReport, debtManagementServicesAnnualReport);
            defer.resolve(
              new DebtManagementServicesAnnualReport({
                mode: config.mode,
                isReadOnly: config.isReadOnly,
                model: new Backbone.Model({
                  annualReportId: annualReportId,
                  annualReportYear: annualReportYear,
                  annualReport: annualReport
                })
              })
            );
          })
          .fail(function(err) {
            defer.reject(err);
          });
      })
      .fail(function(err) {
        defer.reject(err);
      });

    return defer.promise();
  }
};
