/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Marionette = require('backbone.marionette');
var Syphon = require('backbone.syphon');

var Radio = require('backbone.radio');

var Ladda = require('ladda');

var SubmitUseEntityJoinCode = require('../services/SubmitUseEntityJoinCode');
var tmplOrganizationJoinCodeUseModalView = require('../templates/OrganizationJoinCodeUseModalView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplOrganizationJoinCodeUseModalView,

  className: 'organization-join-code-use-modal-view',

  ui: {
    cancelButton: '.btn-cancel',
    submitButton: '.btn-submit',
    joinCodeForm: '.join-code-form',
    formBody: '.form-body',
    resultMessage: '.result-message',
    resultInstructions: '.result-instructions',
    resultBody: '.result-body'
  },

  events: {
    'click @ui.submitButton': 'onClickSubmit'
  },

  onClickSubmit: function(e) {
    e.preventDefault();
    var self = this;

    this.ui.joinCodeForm
      .parsley()
      .whenValidate()
      .done(function() {
        var formData = Syphon.serialize(self);
        var laddaContext = Ladda.create(self.ui.submitButton.get(0));
        laddaContext.start();
        SubmitUseEntityJoinCode(formData.joinCode).done(function(resultObj) {
          laddaContext.stop();
          self.ui.formBody.addClass('collapse');
          self.ui.submitButton.addClass('collapse');
          self.ui.resultMessage.text(resultObj.resultMessage);
          if (resultObj.result) {
            self.ui.cancelButton.addClass('collapse');
            self.ui.resultBody.addClass('alert-success');
            self.ui.resultInstructions.text('Dashboard will be reloaded shortly.');
            setTimeout(function() {
              if (self) {
                self.triggerMethod('modal:close');
              }
              Radio.channel('navigate').trigger('show:ext:sec:dashboard');
            }, 4000);
          } else {
            self.ui.resultBody.addClass('alert-warning');
            self.ui.resultInstructions.addClass('collapse');
          }
          self.ui.resultBody.removeClass('collapse');
        });
      });
  }
});
