/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');

module.exports = function(formData) {
  var deferrred = $.Deferred();

  $.soap({
    body: {
      SubmitEntityExamOtherInformation: {
        $xmlns: 'http://dobs.pa.gov/dp/exam/bpm/1.0',
        ID: formData.id,
        IsExamViolationsUpdated: formData.isExamViolationsUpdated,
        ExamViolations: (function() {
          if (formData.examViolations && formData.examViolations.length) {
            return {
              ExamViolation: _.map(formData.examViolations, function(examViolation) {
                return {
                  EntityID: examViolation.entityId,
                  LegalCiteID: examViolation.legalCiteId,
                  Comments: examViolation.comments
                };
              })
            };
          }
          return {};
        })(),
        ExamConcentrations: (function() {
          if (formData.examConcentrations && formData.examConcentrations.length) {
            return {
              ExamConcentration: _.map(formData.examConcentrations, function(examConcentration) {
                return {
                  EntityID: examConcentration.entityId,
                  TypeID: examConcentration.typeId,
                  SubTypeID: examConcentration.subTypeId,
                  Comments: examConcentration.comments
                };
              })
            };
          }
          return;
        })(),
        IsExamConcentrationsUpdated: formData.isExamConcentrationsUpdated
      }
    },
    blueprint: {
      id: '$..EntityExamID'
    }
  })
    .done(function(response) {
      deferrred.resolve(response.id);
    })
    .fail(function(err) {
      deferrred.reject(err);
    });

  return deferrred.promise();
};
