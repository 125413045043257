/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Backbone = require('backbone');
var Marionette = require('backbone.marionette');
var Syphon = require('backbone.syphon');
var bodybuilder = require('bodybuilder');
var Ladda = require('ladda');
var moment = require('moment');

var GridView = require('psa-marionette/views/GridView');
var PageableCollection = require('psa-backbone/models/PageableCollection');

var ButtonLinkCell = require('common/backgrid/ButtonLinkCell');
var ElasticSearchSummaryView = require('common/views/ElasticSearchSummaryView');
var toggleForm = require('common/util/toggleForm');

var SecInternalSidebarNavView = require('./SecInternalSidebarNavView');
var SearchSecInvInqs = require('../services/SearchSecInvInqs');

require('../styles/SecInvInqSearchView.css');
var tmplSecInvInqSearchView = require('../templates/SecInvInqSearchView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplSecInvInqSearchView,

  className: 'sec-inv-inq-search-view container-fluid',

  ui: {
    sideBar: '.side-bar-container',
    searchForm: 'form',
    datepicker: '.datepicker',
    searchTypeSelect: 'select[name="searchType"]',
    orgFilters: '.org-filter-container',
    indFilters: '.ind-filter-container',
    searchResultsContainer: '.search-results-container',
    search: '.search',
    clearFilters: '.clear-filters',
    formInputs: ':input',
    searchSummary: '.search-summary'
  },

  regions: {
    sideBar: '@ui.sideBar',
    searchResultsContainer: '@ui.searchResultsContainer',
    searchSummary: '@ui.searchSummary'
  },

  events: {
    'change @ui.searchTypeSelect': 'onChangeSearchType',
    'click @ui.search': 'onClickSearch',
    'click @ui.clearFilters': 'onClickClearFilters',
    'keydown @ui.formInputs': 'onKeydownOfInputs'
  },

  onBeforeShow: function() {
    this.showChildView('sideBar', new SecInternalSidebarNavView());

    this.searchSummaryView = new ElasticSearchSummaryView({
      model: new Backbone.Model({
        searchLimit: this.model.get('searchLimit')
      })
    });
    this.showChildView('searchSummary', this.searchSummaryView);

    this.searchResults = new GridView({
      columns: [
        {
          name: 'legalName',
          label: 'Legal Name',
          cell: 'string',
          headerCell: 'custom',
          width: 18,
          editable: false
        },
        {
          name: 'name',
          label: 'DBA Name',
          cell: 'string',
          headerCell: 'custom',
          width: 18,
          editable: false
        },
        {
          name: 'crdNumber',
          label: 'CRD Number',
          cell: 'string',
          headerCell: 'custom',
          width: 18,
          editable: false
        },
        {
          name: 'licenseType',
          label: 'License Type',
          cell: 'string',
          headerCell: 'custom',
          width: 18,
          editable: false
        },
        {
          name: 'caseNumber',
          label: 'Case Number',
          cell: 'string',
          headerCell: 'custom',
          width: 18,
          editable: false
        },
        {
          name: 'category',
          label: 'Category',
          cell: 'string',
          headerCell: 'custom',
          width: 18,
          editable: false
        },
        {
          name: '',
          label: '',
          cell: ButtonLinkCell.extend({
            name: 'Open',
            newTab: true,
            buttonClass: 'btn-primary btn-sm',
            href: function(model) {
              var invInqId = model.get('invInqId');
              return '#sec/investigation-inquiry/' + invInqId + '/dashboard';
            }
          }),
          headerCell: 'custom',
          sortable: false,
          editable: false,
          width: 10
        }
      ],
      collection: new PageableCollection([], { pageSize: 25 }),
      emptyText: 'No Investigations Or Inquiries Found'
    });

    this.showChildView('searchResultsContainer', this.searchResults);

    toggleForm(this.ui.orgFilters, false);
    toggleForm(this.ui.indFilters, false);

    this.ui.datepicker.datepicker();

    this.$('.backgrid.table').addClass('table-bordered');
  },

  onChangeSearchType: function(e) {
    e.preventDefault();

    var searchType = this.ui.searchTypeSelect.val();

    toggleForm(this.ui.orgFilters, searchType === 'ORGANIZATION');
    toggleForm(this.ui.indFilters, searchType === 'INDIVIDUAL');
  },

  onKeydownOfInputs: function(e) {
    // Searches on press of enter
    if (e.keyCode === 13) {
      this.onClickSearch(e);
    }
  },

  onClickSearch: function() {
    var self = this;

    this.ui.searchForm
      .parsley()
      .whenValidate()
      .done(function() {
        var data = Syphon.serialize(self, {
          inputReaders: Syphon.SearchInputReaderSet
        });
        // If no inputs were given, do not perform a search.
        if (
          !(
            data.caseNumber ||
            data.categoryId ||
            data.caseStatusId ||
            data.typeId ||
            data.primaryExaminer ||
            data.staffAssigned ||
            data.officeAssignedId ||
            data.openedDate ||
            data.searchType
          )
        ) {
          return;
        }
        self.searchSummaryView.reset();

        var l = Ladda.create(self.ui.search[0]);
        l.start();
        var body = bodybuilder();

        if (data.caseNumber) {
          body.query('wildcard', 'caseNumber', data.caseNumber + '*');
        }

        if (data.categoryId) {
          body.query('term', 'categoryId', data.categoryId);
        }

        if (data.caseStatusId) {
          body.query('term', 'caseStatusId', data.caseStatusId);
        }

        if (data.typeId) {
          body.query('term', 'typeId', data.typeId);
        }

        if (data.primaryExaminer) {
          body.query('term', 'primaryExaminer', data.primaryExaminer);
        }

        if (data.staffAssigned) {
          body.query('nested', 'path', 'staffs', function(nb) {
            nb.andQuery('term', 'staffs.staffAssigned', data.staffAssigned);
            return nb;
          });
        }

        if (data.officeAssignedId) {
          body.query('term', 'officeAssignedId', data.officeAssignedId);
        }

        if (data.openedDate) {
          var formattedOpenedDate = moment(data.openedDate, 'MM/DD/YYYY').format('YYYY-MM-DD');
          body.query('range', 'openedDate', {
            lte: formattedOpenedDate,
            gte: formattedOpenedDate
          });
        }

        if (data.searchType) {
          body.query('term', 'objectType', data.searchType);
        }

        if (data.dbaName) {
          body.query('bool', function(b) {
            b.orQuery('match', 'organization.dbaName', {
              query: data.dbaName,
              fuzziness: 'AUTO',
              analyzer: 'standard'
            });
            b.orQuery('wildcard', 'organization.dbaName', data.dbaName.toLowerCase() + '*');
            b.orQuery('match', 'organization.dbaName.phonetic', data.dbaName);
            return b;
          });
        }

        if (data.legalName) {
          body.query('bool', function(b) {
            b.orQuery('match', 'organization.legalName', {
              query: data.legalName,
              fuzziness: 'AUTO',
              analyzer: 'standard'
            });
            b.orQuery('wildcard', 'organization.legalName', data.legalName.toLowerCase() + '*');
            b.orQuery('match', 'organization.legalName.phonetic', data.legalName);
            return b;
          });
        }
        if (data.orgCRDNumber) {
          body.query('wildcard', 'organization.crdNumber', data.orgCRDNumber + '*');
        }

        if (data.orgLicenseTypeId) {
          body.query('term', 'organization.licenseTypeId', data.orgLicenseTypeId);
        }

        if (data.lastName) {
          body.query('bool', function(b) {
            b.orQuery('match', 'individual.lastName', {
              query: data.lastName,
              fuzziness: 'AUTO',
              analyzer: 'standard'
            });
            b.orQuery('wildcard', 'individual.lastName', data.lastName.toLowerCase() + '*');
            return b;
          });
        }

        if (data.firstName) {
          body.query('bool', function(b) {
            b.orQuery('match', 'individual.firstName', {
              query: data.firstName,
              fuzziness: 'AUTO',
              analyzer: 'standard'
            });
            b.orQuery('wildcard', 'individual.firstName', data.firstName.toLowerCase() + '*');
            return b;
          });
        }

        if (data.middleInitial) {
          body.query('match', 'individual.middleInitial', data.middleInitial);
        }

        if (data.indCRDNumber) {
          body.query('term', 'individual.crdNumber', data.indCRDNumber);
        }

        if (data.indLicenseTypeId) {
          body.query('term', 'individual.licenseTypeId', data.indLicenseTypeId);
        }

        body.size(self.model.get('searchLimit'));
        var bodyObj = body.build();
        // Keep for testing
        // console.log(JSON.stringify(bodyObj, null, 2));
        SearchSecInvInqs(bodyObj)
          .done(function(data) {
            l.stop();
            self.searchResults.collection.fullCollection.reset(data.hits);
            self.searchSummaryView.display(data);
          })
          .fail(function(err) {
            l.stop();
          });
      });
  },

  onClickClearFilters: function(e) {
    e.preventDefault();

    this.ui.formInputs.val('');

    toggleForm(this.ui.orgFilters, false);
    toggleForm(this.ui.indFilters, false);
  }
});
