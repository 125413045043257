/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Marionette = require('backbone.marionette');
var Ladda = require('ladda');

var SideNavSectionView = require('common/views/SideNavSectionView');
var switchToPrint = require('common/util/switchToPrint');

var DebtManagementServicesAnnualReportGeneralDetailsSectionView = require('./DebtManagementServicesAnnualReportGeneralDetailsSectionView');
var DebtManagementServicesAnnualReportFinancialStatementsSectionView = require('./DebtManagementServicesAnnualReportFinancialStatementsSectionView');
var DebtManagementServicesAnnualReportProductionReportSectionView = require('./DebtManagementServicesAnnualReportProductionReportSectionView');
var DebtManagementServicesAnnualReportAffirmationSectionView = require('./DebtManagementServicesAnnualReportAffirmationSectionView');
var SubmitDebtManagementServicesAnnualReport = require('../../services/SubmitDebtManagementServicesAnnualReport');
var tmplDebtManagementServicesAnnualReportView = require('./DebtManagementServicesAnnualReportView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplDebtManagementServicesAnnualReportView,

  className: 'debt-management-services-annual-report-view m-b-md',

  behaviors: {
    ReadOnlyFormBehavior: {}
  },

  ui: {
    debtManagementServicesAnnualReportContainer: '.debt-management-services-annual-report-container'
  },

  regions: {
    debtManagementServicesAnnualReportContainer: '@ui.debtManagementServicesAnnualReportContainer'
  },

  onBeforeShow: function() {
    var mode = this.options.mode;
    var isForPrinting = mode === 'print';
    var isExpandInternal = mode === 'int-expand';
    this.form = new SideNavSectionView({
      collection: new Backbone.Collection([
        {
          id: 'debtManagementServicesAnnualReportGeneralDetails',
          title: 'General Details',
          view: new DebtManagementServicesAnnualReportGeneralDetailsSectionView({
            isReadOnly: this.options.isReadOnly,
            model: this.model
          })
        },
        {
          id: 'debtManagementServicesAnnualReportFinancialStatements',
          title: 'Financial Statements',
          view: new DebtManagementServicesAnnualReportFinancialStatementsSectionView({
            isReadOnly: this.options.isReadOnly,
            model: this.model
          })
        },
        {
          id: 'debtManagementServicesAnnualReportProductionReport',
          title: 'Production Report',
          view: new DebtManagementServicesAnnualReportProductionReportSectionView({
            isReadOnly: this.options.isReadOnly,
            model: this.model
          })
        },
        {
          id: 'debtManagementServicesAnnualReportAffirmation',
          title: 'Affirmation',
          view: new DebtManagementServicesAnnualReportAffirmationSectionView({
            isReadOnly: this.options.isReadOnly,
            mode: this.options.mode,
            model: this.model
          })
        }
      ]),
      expanded: isForPrinting || isExpandInternal,
      useNavigationButtons: !this.options.isReadOnly,
      contentWidth: 'medium',
      buttons: [
        {
          buttonText: 'Save',
          buttonClass: 'btn-default ladda-button',
          buttonIconClass: 'fa-save',
          labelClass: 'ladda-label',
          buttonAttrs: {
            'data-style': 'expand-right'
          },
          onClick: this.onClickSave.bind(this)
        }
      ]
    });

    if (
      this.options.isReadOnly &&
      !(this.options.mode === 'print' || this.options.mode === 'int' || this.options.mode === 'int-expand')
    ) {
      this.form.collection.remove(this.form.collection.get('debtManagementServicesAnnualReportAffirmation'));
    }

    this.showChildView('debtManagementServicesAnnualReportContainer', this.form);
  },

  onClickSave: function(e) {
    e.preventDefault();
    var self = this;
    self.form.validateAll({ type: 'save' }).done(function() {
      var l = Ladda.create(e.currentTarget);
      l.start();
      var rawFormData = self.form.getFormData();
      var formData = {
        applicationId: self.model.get('applicationId'),
        annualReportId: self.model.get('annualReportId'),
        generalDetails: rawFormData.debtManagementServicesAnnualReportGeneralDetails,
        productionReport: rawFormData.debtManagementServicesAnnualReportProductionReport,
        affirmation: rawFormData.debtManagementServicesAnnualReportAffirmation
      };
      SubmitDebtManagementServicesAnnualReport(formData)
        .done(function() {
          l.stop();
        })
        .fail(function() {
          l.stop();
        });
    });
  },

  onAttach: function() {
    if (this.options.mode === 'print') {
      switchToPrint(this.$el, '.input-view');
    }
  }
});
