/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');

var cleanUpResponse = require('../util/cleanUpResponse');

module.exports = function(departmentName) {
  var deferred = $.Deferred();
  $.soap({
    body: {
      GetDocumentTypesByDepartment: {
        $xmlns: 'http://dobs.pa.gov/cm/lookup/bpm/1.0',
        DepartmentName: departmentName
      }
    },
    blueprintArrays: ['GetDocumentTypesByDepartmentResponse.CM_LU_DocumentType'],
    blueprint: {
      documentTypes: [
        '$..CM_LU_DocumentType',
        {
          id: '$.ID',
          name: '$.Name',
          description: '$.Description',
          isHidden: '$.IsHidden'
        }
      ]
    }
  })
    .done(function(response) {
      cleanUpResponse(response);
      var documentTypes = _.map(response.documentTypes, function(documentType) {
        return {
          value: documentType.id,
          display: documentType.name,
          isHidden: documentType.isHidden
        };
      });
      deferred.resolve(documentTypes);
    })
    .fail(function(err) {
      deferred.reject(err);
    });

  return deferred.promise();
};
