/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2020] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var Marionette = require('backbone.marionette');

var FileUploadCollection = require('psa-backbone/models/FileUploadCollection');

var modelGet = require('common/util/modelGet');
var makeUploadCollection = require('common/util/makeUploadCollection');

var FileUploadView = require('common/views/FileUploadView');

var tmplDepOathRosterSupportingDocumentsSectionView = require('./DepOathRosterSupportingDocumentsSectionView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplDepOathRosterSupportingDocumentsSectionView,

  className: 'dep-oath-roster-supporting-documents-section-view',

  behaviors: {
    ReadOnlyFormBehavior: {}
  },

  ui: {
    officerReportDocumentsContainer: '.officer-report-documents-container',
    oathBoardElectedDocumentsContainer: '.oath-board-elected-documents-container',
    oathShareholderElectedDocumentsContainer: '.oath-shareholder-elected-documents-container',
    supportingDocumentsContainer: '.supporting-documents-container'
  },

  regions: {
    officerReportDocumentsContainer: '@ui.officerReportDocumentsContainer',
    oathBoardElectedDocumentsContainer: '@ui.oathBoardElectedDocumentsContainer',
    oathShareholderElectedDocumentsContainer: '@ui.oathShareholderElectedDocumentsContainer',
    supportingDocumentsContainer: '@ui.supportingDocumentsContainer'
  },

  templateHelpers: function() {
    return {
      isReadOnly: this.options.isReadOnly
    };
  },

  onBeforeShow: function() {
    var supportingDocuments = _.pluck(modelGet(this.model, 'filingContent.supportingDocuments', []), 'file');
    var filingContent = this.model.get('filingContent') || {};

    this.supportingDocuments = new FileUploadView({
      isReadOnly: this.options.isReadOnly,
      allowMultipleFiles: true,
      isRequired: false,
      collection: new FileUploadCollection(supportingDocuments),
      metadata: {
        documentTypeId: 20001
      }
    });
    this.showChildView('supportingDocumentsContainer', this.supportingDocuments);

    this.officerReportDoc = new FileUploadView({
      isReadOnly: this.options.isReadOnly,
      collection: makeUploadCollection(filingContent.officerReportDoc),
      isRequired: true,
      metadata: {
        documentTypeId: 20001
      }
    });
    this.showChildView('officerReportDocumentsContainer', this.officerReportDoc);

    this.oathBoardElectedDoc = new FileUploadView({
      isReadOnly: this.options.isReadOnly,
      collection: makeUploadCollection(filingContent.oathBoardElectedDoc),
      isRequired: true,
      metadata: {
        documentTypeId: 20001
      }
    });
    this.showChildView('oathBoardElectedDocumentsContainer', this.oathBoardElectedDoc);

    this.oathShareholderElectedDoc = new FileUploadView({
      isReadOnly: this.options.isReadOnly,
      collection: makeUploadCollection(filingContent.oathShareholderElectedDoc),
      isRequired: true,
      metadata: {
        documentTypeId: 20001
      }
    });
    this.showChildView('oathShareholderElectedDocumentsContainer', this.oathShareholderElectedDoc);
  },

  validate: function(config) {
    this.officerReportDoc.removeValidationErrors();
    this.oathBoardElectedDoc.removeValidationErrors();
    this.oathShareholderElectedDoc.removeValidationErrors();
    this.supportingDocuments.removeValidationErrors();

    var validationPromises = [
      this.officerReportDoc.validate(config),
      this.oathBoardElectedDoc.validate(config),
      this.oathShareholderElectedDoc.validate(config),
      this.supportingDocuments.validate(config)
    ];
    return $.when.apply($, validationPromises);
  },

  getFormData: function() {
    var formData = {};

    formData.officerReportDocId = this.officerReportDoc.getFirstFileId();
    formData.oathBoardElectedDocId = this.oathBoardElectedDoc.getFirstFileId();
    formData.oathShareholderElectedDocId = this.oathShareholderElectedDoc.getFirstFileId();
    formData.documents = this.supportingDocuments.collection.map(function(file) {
      return {
        fileId: file.get('fileId'),
        documentTypeId: modelGet(file, 'metadata.documentTypeId')
      };
    });
    return formData;
  }
});
