/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');

var cleanUpResponse = require('common/util/cleanUpResponse');
var momentUtil = require('common/util/momentUtil');

module.exports = function(annualMeetingId) {
  var deferred = $.Deferred();

  $.soap({
    body: {
      GetAnnualMeeting: {
        $xmlns: 'http://dobs.pa.gov/dp/director/bpm/1.0',
        AnnualMeetingID: annualMeetingId
      }
    },
    blueprint: {
      annualMeetingId: '$..ID',
      entityId: '$..EntityID',
      schedule: '$..Schedule',
      directorCount: '$..DirectorCount',
      electionReport: '$..ElectionReport',
      lastMeetingDate: '$..LastMeetingDate',
      meetingDate: '$..MeetingDate',
      receivedDate: '$..ReceivedDate',
      acceptedDate: '$..AcceptedDate',
      isDeleted: '$..IsDeleted'
    }
  })
    .done(function(response) {
      cleanUpResponse(response);

      var inboundDateConfig = _.extend({}, momentUtil.defaultInboundDateConfig, { default: '' });

      response.lastMeetingDate = momentUtil.formatStr(response.lastMeetingDate, inboundDateConfig);
      response.meetingDate = momentUtil.formatStr(response.meetingDate, inboundDateConfig);
      response.receivedDate = momentUtil.formatStr(response.receivedDate, inboundDateConfig);
      response.acceptedDate = momentUtil.formatStr(response.acceptedDate, inboundDateConfig);

      deferred.resolve(response);
    })
    .fail(function(err) {
      deferred.reject();
    });

  return deferred.promise();
};
