var Handlebars = require("handlebars/dist/handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression;

  return "<div class=\"title-bar clearfix\">\n  <h1 class=\"page-title\">"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.entityIdentifiers : depth0)) != null ? stack1.entityName : stack1), depth0))
    + "</h1>\n</div>\n<div class=\"page-subtitle\">\n  <h2>Officer</h2>\n  <div class=\"page-subtitle-details\">"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.officer : depth0)) != null ? stack1.officerType : stack1), depth0))
    + " -\n    "
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.officer : depth0)) != null ? stack1.firstName : stack1), depth0))
    + " "
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.officer : depth0)) != null ? stack1.lastName : stack1), depth0))
    + "</div>\n</div>\n<div class=\"breadcrumb-container\"></div>\n<div class=\"row m-b-xs\">\n  <div class=\"col-sm-12 col-md-10 col-md-offset-1\">\n    <div class=\"form-group\">\n      <div class=\"col-sm-9 col-sm-offset-3 col-md-8 col-md-offset-2\">\n        <h3 class=\"m-t-n\">Edit Officer</h3>\n      </div>\n    </div>\n  </div>\n</div>\n<form class=\"officer-form-container\" id=\"officer-form\"></form>\n<div class=\"row m-b-lg\">\n  <div class=\"col-sm-12 col-md-10 col-md-offset-1\">\n    <div class=\"form-group\">\n      <div class=\"col-sm-9 col-sm-offset-3 col-md-8 col-md-offset-2\">\n        <a href=\"#dep/entity/"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.officer : depth0)) != null ? stack1.entityId : stack1), depth0))
    + "/officer/"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.officer : depth0)) != null ? stack1.officerId : stack1), depth0))
    + "/dashboard\" class=\"btn btn-default\">Cancel</a>\n        <button class=\"btn btn-primary btn-submit\">Submit</button>\n      </div>\n    </div>\n  </div>\n</div>";
},"useData":true});