/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var NProgress = require('nprogress');

var NavUtils = require('psa-core/util/nav');

var Scaffold = require('common/layout/Scaffold');

var DepProvisionNewView = require('../views/DepProvisionNewView');

var GetAllEnforcementLookups = require('../services/GetAllEnforcementLookups');
var GetEnforcementIdentifiers = require('../services/GetEnforcementIdentifiers');

module.exports = function(entityId, enforcementId) {
  NProgress.start();
  NProgress.set(0.6);
  GetEnforcementIdentifiers(enforcementId)
    .done(function(enforcementIdentifiers) {
      GetAllEnforcementLookups(enforcementIdentifiers.categoryId)
        .done(function(enforcementLookups) {
          NavUtils.navigate('dep/entity/' + entityId + '/enforcement/' + enforcementId + '/new-provision');
          Scaffold.content.show(
            new DepProvisionNewView({
              viewMode: 'int',
              model: new Backbone.Model({
                entityId: entityId,
                enforcementId: enforcementId,
                enforcementIdentifiers: enforcementIdentifiers,
                provisionCategories: enforcementLookups.provisionCategories,
                provisionStatuses: enforcementLookups.provisionStatuses
              })
            })
          );
          NProgress.done();
        })
        .fail(function(err) {
          NProgress.done();
        });
    })
    .fail(function(err) {
      NProgress.done();
    });
};
