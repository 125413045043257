/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Backbone = require('backbone');
var Marionette = require('backbone.marionette');
var Radio = require('backbone.radio');
var Ladda = require('ladda');

var UIUtil = require('psa-core/util/ui');

var BreadcrumbView = require('common/views/BreadcrumbView');
var ConfirmationModalView = require('common/views/ConfirmationModalView');
var modelGet = require('common/util/modelGet');

var EntityHeaderBarView = require('../../common/views/EntityHeaderBarView');
var NMLSLabelView = require('../../common/views/NMLSLabelView');

var SubmitEntityExternalUserUpdate = require('../services/SubmitEntityExternalUserUpdate');

var tmplNonDepEntityManageUserView = require('../templates/NonDepEntityManageUserView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplNonDepEntityManageUserView,

  className: 'nondep-entity-manage-user-view container-fluid',

  ui: {
    nmlsLabelContainer: '.nmls-label-container',
    headerBar: '.header-bar-container',
    breadcrumb: '.breadcrumb-container',
    accessLevelSelect: "select[name='userAccessLevel']",
    saveButton: '.save-button',
    revokeButton: '.revoke-button'
  },

  regions: {
    nmlsLabelContainer: '@ui.nmlsLabelContainer',
    headerBar: '@ui.headerBar',
    breadcrumb: '@ui.breadcrumb'
  },

  events: {
    'click @ui.saveButton': 'onClickSave',
    'click @ui.revokeButton': 'onClickRevoke'
  },

  templateHelpers: function() {
    return {
      viewMode: this.options.viewMode
    };
  },

  onBeforeShow: function() {
    var viewMode = this.options.viewMode;
    var entityId = this.model.get('entityId');
    var isNMLSEntity = modelGet(this.model, 'entity.isNMLSEntity');

    if (isNMLSEntity) {
      this.showChildView(
        'nmlsLabelContainer',
        new NMLSLabelView({
          model: new Backbone.Model({
            objectName: 'entity'
          })
        })
      );
    }

    var breadcrumbs = [
      {
        text: 'Home',
        link: viewMode === 'ext' ? '#ext/nondep/dashboard' : '#nondep/dashboard'
      },
      {
        text: this.model.get('entity').entityName,
        link:
          viewMode === 'ext'
            ? '#ext/nondep/entity/' + entityId + '/dashboard'
            : '#nondep/entity/' + entityId + '/dashboard'
      },
      {
        text: 'Manage User'
      }
    ];

    this.showChildView(
      'headerBar',
      new EntityHeaderBarView({
        model: this.model
      })
    );

    this.showChildView(
      'breadcrumb',
      new BreadcrumbView({
        breadcrumbs: breadcrumbs
      })
    );

    this.ui.accessLevelSelect.val(this.model.get('userAccessLevelId'));
  },

  onClickSave: function(e) {
    e.preventDefault();

    var args = {
      entityExternalUserId: this.model.get('userId'),
      entityExternalUserAccessLevelId: this.ui.accessLevelSelect.val()
    };

    var laddaContext = Ladda.create(this.ui.saveButton.get(0));
    laddaContext.start();
    SubmitEntityExternalUserUpdate(args)
      .done(
        function() {
          if (this.options.viewMode === 'int') {
            Radio.channel('navigate').trigger('show:int:nd:entity-dashboard', this.model.get('entityId'));
          } else if (this.options.viewMode === 'ext') {
            Radio.channel('navigate').trigger('show:ext:nd:entity-dashboard', this.model.get('entityId'));
          }
        }.bind(this)
      )
      .fail(function() {
        laddaContext.stop();
      });
  },

  onClickRevoke: function(e) {
    e.preventDefault();

    var firstName = this.model.get('firstName');
    var lastName = this.model.get('lastName');
    var fullName = firstName + ' ' + lastName;
    var entityId = this.model.get('entityId');
    var entityName = this.model.get('entityName');

    UIUtil.showModal({
      title: 'Confirm Revoke ' + fullName + "'s Access",
      model: new Backbone.Model({
        message: 'Are you sure you want to revoke ' + fullName + "'s access to " + entityName + '?',
        onConfirm: function() {
          return SubmitEntityExternalUserUpdate({
            entityExternalUserId: this.model.get('userId'),
            isDeleted: 1
          });
        }.bind(this),
        onConfirmDone: function() {
          if (this.options.viewMode === 'int') {
            Radio.channel('navigate').trigger('show:int:nd:entity-dashboard', entityId);
          } else if (this.options.viewMode === 'ext') {
            Radio.channel('navigate').trigger('show:ext:nd:entity-dashboard', entityId);
          }
        }.bind(this)
      }),
      view: new ConfirmationModalView()
    });
  }
});
