/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2020] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var NProgress = require('nprogress');
var Backbone = require('backbone');

var NavUtils = require('psa-core/util/nav');

var Scaffold = require('common/layout/Scaffold');
var ActivePageSection = require('common/util/ActivePageSection');

var GetAllComplaintLookups = require('../../complaint-dashboard/services/GetAllComplaintLookups');
var GetAllEntityLookups = require('../../../depository/common/services/GetAllEntityLookups');
var GetAllLicenseTypes = require('../../../non-depository/common/services/GetAllLicenseTypes');
var GetAllOrgLicenseLookups = require('../../../securities/common/services/GetAllOrgLicenseLookups');

var ConSerInternalDashboardView = require('../views/ConSerInternalDashboardView');

module.exports = function() {
  NProgress.start();
  NProgress.set(0.6);
  $.when(GetAllComplaintLookups(), GetAllEntityLookups(), GetAllLicenseTypes(), GetAllOrgLicenseLookups())
    .done(function(lookups, entityLookups, nondepLicenseTypes, orgLicenseLookups) {
      NavUtils.navigate('conser/dashboard');

      Scaffold.content.show(
        new ConSerInternalDashboardView({
          model: new Backbone.Model({
            productTypes: lookups.productTypes,
            complaintStatuses: lookups.complaintStatuses,
            weights: lookups.weights,
            departments: lookups.departments,
            depEntityTypes: entityLookups.entityTypes,
            nondepLicenseTypes: nondepLicenseTypes,
            secLicenseTypes: orgLicenseLookups.orgLicenseType,
            conserLicenseTypes: lookups.departments2
          }),
          isMyComplaints: true
        })
      );

      // Clear out the previously visited page section whenever a user views
      // this page.
      ActivePageSection.empty();
      NProgress.done();
    })
    .fail(function() {
      NProgress.done();
    });
};
