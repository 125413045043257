/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var NProgress = require('nprogress');
var Backbone = require('backbone');

var NavUtils = require('psa-core/util/nav');

var Scaffold = require('common/layout/Scaffold');
var GetEntityIdentifiers = require('../../common/services/GetEntityIdentifiers');
var GetEntityDetails = require('../../entity-form/services/GetEntityDetails');

var GetAllEntityOfficerTypes = require('../../entity-form/services/GetAllEntityOfficerTypes');

var NonDepEntityAddOfficerView = require('../views/NonDepEntityAddOfficerView');

module.exports = function(entityId) {
  NProgress.start();
  NProgress.set(0.6);

  $.when(GetEntityIdentifiers(entityId), GetAllEntityOfficerTypes(), GetEntityDetails(entityId))
    .done(function(entityIdentifiers, officerTypes, entity) {
      NavUtils.navigate('nondep/entity/' + entityId + '/add-officer');
      Scaffold.content.show(
        new NonDepEntityAddOfficerView({
          viewMode: 'int',
          model: new Backbone.Model({
            entityId: entityId,
            entityIdentifiers: entityIdentifiers,
            isOfficerConvicted: entity.isOfficerConvicted,
            isOfficerConvictedDocument: entity.isOfficerConvictedDocument,
            officerTypes: officerTypes
          })
        })
      );
      NProgress.done();
    })
    .fail(function(err) {
      NProgress.done();
    });
};
