/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Marionette = require('backbone.marionette');
var PageableCollection = require('psa-backbone/models/PageableCollection');
var Syphon = require('backbone.syphon');
var Ladda = require('ladda');
var bodybuilder = require('bodybuilder');

var ElasticSearchSummaryView = require('common/views/ElasticSearchSummaryView');
var GridView = require('psa-marionette/views/GridView');

var SelectOneCell = require('common/backgrid/SelectOneCell');

var SearchConSerIndividuals = require('../../common/services/SearchConSerIndividuals');

var tmplConSerIndividualSearchModalView = require('../templates/ConSerIndividualSearchModalView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplConSerIndividualSearchModalView,

  className: 'con-ser-individual-search-modal-view',

  ui: {
    searchResultsGrid: '.search-result-grid-container',
    search: '.btn-search',
    searchSummary: '.search-summary',
    formInputs: ':input',
    submit: '.btn-submit'
  },

  regions: {
    searchSummary: '@ui.searchSummary',
    searchResultsGrid: '@ui.searchResultsGrid'
  },

  events: {
    'click @ui.search': 'onClickSearchIndividuals',
    'click @ui.submit': 'onClickSubmit',
    'keydown @ui.formInputs': 'onKeydownOfInputs'
  },  

  onClickSubmit: function(e) {
    e.preventDefault();
    var selectedModel = this.searchResultsGrid.viewGrid.getSelectedModel();

    if (selectedModel) {
      this.triggerMethod('modal:close', selectedModel.toJSON());
    } else {
      this.triggerMethod('modal:close');
    }
  },

  onBeforeShow: function() {
    this.searchSummaryView = new ElasticSearchSummaryView({
      model: new Backbone.Model({
        searchLimit: this.model.get('searchLimit')
      })
    });
    this.showChildView('searchSummary', this.searchSummaryView);

    this.searchResultsGrid = new GridView({
      columns: [
        {
          name: 'selected',
          label: '',
          cell: SelectOneCell.SelectOneRowCell,
          headerCell: SelectOneCell.SelectOneHeaderCell,
          width: 5,
          editable: true,
          sortable: false
        },
        {
          name: 'lastName',
          label: 'Last Name',
          cell: 'string',
          headerCell: 'custom',
          width: 19,
          editable: false
        },
        {
          name: 'firstName',
          label: 'First Name',
          cell: 'string',
          headerCell: 'custom',
          width: 19,
          editable: false
        },
        {
          name: 'title',
          label: 'Title',
          cell: 'string',
          headerCell: 'custom',
          width: 19,
          editable: false
        },
        {
          name: 'phoneNumber',
          label: 'Phone',
          cell: 'string',
          width: 19,
          editable: false
        },
        {
          name: 'email',
          label: 'Email',
          cell: 'string',
          headerCell: 'custom',
          width: 19,
          editable: false
        }
      ],
      collection: new PageableCollection([], { pageSize: 10 }),
      emptyText: 'No Individuals Found'
    });

    this.showChildView('searchResultsGrid', this.searchResultsGrid);

  },

  onKeydownOfInputs: function(e) {
    // Searches on press of enter
    if (e.keyCode === 13) {
      this.onClickSearchIndividuals(e);
    }
  },

  onClickSearchIndividuals: function(e) {
    e.preventDefault();
    var self = this;

    var data = Syphon.serialize(this, {
      inputReaders: Syphon.SearchInputReaderSet
    });

    // If no inputs were given, do not perform a search.
    if (!data.firstName && !data.lastName) {
      return;
    }
    this.searchSummaryView.reset();

    var laddaContext = Ladda.create(this.ui.search[0]);
    laddaContext.start();
    var body = bodybuilder();

    if (data.firstName) {
      body.query('bool', function(b) {
        b.orQuery('match', 'firstName', {
          query: data.firstName,
          fuzziness: 'AUTO'
        });
        b.orQuery('wildcard', 'firstName', data.firstName.toLowerCase() + '*');
        return b;
      });
    }

    if (data.lastName) {
      body.query('bool', function(b) {
        b.orQuery('match', 'lastName', {
          query: data.lastName,
          fuzziness: 'AUTO'
        });
        b.orQuery('wildcard', 'lastName', data.lastName.toLowerCase() + '*');
        return b;
      });
    }

    body.size(this.model.get('searchLimit'));
    // Keep for testing
    // console.log(JSON.stringify(bodyObj, null, 2));
    SearchConSerIndividuals(body.build())
      .done(function(data) {
        laddaContext.stop();
        self.searchResultsGrid.collection.fullCollection.reset(data.hits);
        self.searchSummaryView.display(data);
      })
      .fail(function(err) {
        laddaContext.stop();
      });
  },
});