/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Marionette = require('backbone.marionette');
var _ = require('underscore');

var FileUploadView = require('common/views/FileUploadView');
var FileUploadCollection = require('psa-backbone/models/FileUploadCollection');
var modelGet = require('common/util/modelGet');

var tmplConsumerDiscountAnnualReportFinancialStatementsSectionView = require('./ConsumerDiscountAnnualReportFinancialStatementsSectionView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplConsumerDiscountAnnualReportFinancialStatementsSectionView,

  className: 'consumer-discount-annual-report-financial-statements-section-view',

  ui: {
    financialStatementsContainer: '.financial-statements-container',
    missingFile: '.missing-file'
  },

  regions: {
    financialStatementsContainer: '@ui.financialStatementsContainer'
  },

  onBeforeShow: function() {
    var statements = _.pluck(modelGet(this.model, 'annualReport.financialStatements', []), 'file');

    this.financialStatements = new FileUploadView({
      isReadOnly: this.options.isReadOnly,
      allowMultipleFiles: true,
      collection: new FileUploadCollection(statements),
      metadata: {
        documentTypeId: 10012
      }
    });

    this.showChildView('financialStatementsContainer', this.financialStatements);
  },

  validate: function(config) {
    return $.when(this.financialStatements.validate(), this.validateFileCount(config));
  },

  validateFileCount: function(config) {
    var defer = $.Deferred();
    this.ui.missingFile.hide();

    if (config.type === 'submit') {
      if (this.financialStatements.collection.length < 1) {
        this.ui.missingFile.show();
        defer.reject();
      } else {
        defer.resolve();
      }
    } else if (config.type === 'save') {
      defer.resolve();
    }
    return defer.promise();
  },

  getFormData: function() {
    return this.financialStatements.collection.map(function(statement) {
      return {
        fileId: statement.get('fileId'),
        documentTypeId: modelGet(statement, 'metadata.documentTypeId')
      };
    });
  }
});
