/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Backbone = require('backbone');
var Marionette = require('backbone.marionette');
var Radio = require('backbone.radio');

var UIUtil = require('psa-core/util/ui');
var GridView = require('psa-marionette/views/GridView');
var PageableCollection = require('psa-backbone/models/PageableCollection');

var MultiButtonCell = require('common/backgrid/MultiButtonCell');
var AddressCell = require('common/backgrid/AddressCell');
var ConfirmationModalView = require('common/views/ConfirmationModalView');

var SubmitLicenseAddress = require('../services/SubmitLicenseAddress');

var tmplSecLicenseAddressSectionView = require('../templates/SecLicenseAddressSectionView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplSecLicenseAddressSectionView,

  className: 'sec-organization-license-address-section-view',

  ui: {
    addressGrid: '.license-address-grid-container'
  },

  regions: {
    addressGrid: '@ui.addressGrid'
  },

  onBeforeShow: function() {
    this.addressCollection = new PageableCollection(this.model.get('licenseAddresses'), { pageSize: 25 });

    this.showChildView(
      'addressGrid',
      new GridView({
        columns: [
          {
            name: 'type',
            label: 'Address Type',
            cell: 'string',
            headerCell: 'custom',
            width: 15,
            editable: false
          },
          {
            name: 'isPrincipalText',
            label: 'Principal Place of Business',
            cell: 'string',
            headerCell: 'custom',
            width: 20,
            editable: false
          },
          {
            name: 'address',
            label: 'Business Address',
            cell: AddressCell,
            headerCell: 'custom',
            width: 30,
            editable: false,
            sortable: false
          },
          {
            name: 'country',
            label: 'Country',
            cell: 'string',
            headerCell: 'custom',
            width: 15,
            editable: false
          },
          {
            name: 'editDeleteMultiButtonCell',
            label: '',
            cell: MultiButtonCell.extend({
              buttons: [
                {
                  name: '',
                  labelClass: 'fa fa-edit',
                  buttonClass: 'btn-primary btn-sm',
                  onClick: this.onClickEditAddress.bind(this)
                },
                {
                  name: '',
                  labelClass: 'fa fa-trash',
                  buttonClass: 'btn-danger btn-sm',
                  onClick: this.onClickDeleteAddress.bind(this)
                }
              ]
            }),
            editable: false,
            sortable: false
          }
        ],
        emptyText: 'No Associated License Addresses',
        collection: this.addressCollection
      })
    );
  },

  onClickEditAddress: function(e, model) {
    var self = this;
    var modalViewPromise = Radio.channel('sc').request('build:license-address-modal-view', {
      objectId: model.get('objectId'),
      id: model.get('id'),
      objectTypeId: self.options.objectTypeId
    });
    UIUtil.showModal({
      title: 'Edit License Address Details',
      view: modalViewPromise
    }).done(function(editedLicenseAddress) {
      model.set(editedLicenseAddress);
    });
  },

  onClickDeleteAddress: function(e, model) {
    var self = this;
    var message = 'Are you sure you want to delete this address?';
    UIUtil.showModal({
      title: 'Confirm Deleting Address',
      view: new ConfirmationModalView({
        confirmText: 'Delete Address',
        model: new Backbone.Model({
          message: message,
          onConfirm: function() {
            var id = model.get('id');
            return SubmitLicenseAddress({ id: id, isDeleted: '1' });
          },
          onConfirmDone: function() {
            self.addressCollection.remove(model);
          }
        })
      })
    });
  }
});
