/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var NProgress = require('nprogress');
var NavUtils = require('psa-core/util/nav');
var Scaffold = require('common/layout/Scaffold');

var GetTrustSubFundAudit = require('../services/GetTrustSubFundAudit');
var GetTrustFundDocMgmtDetails = require('../services/GetTrustFundDocMgmtDetails');

var DepAuditDashboardView = require('../views/DepAuditDashboardView');

module.exports = function(entityId, fundId, subFundId, auditId) {
  NProgress.start();
  NProgress.set(0.6);

  $.when(GetTrustSubFundAudit(auditId), GetTrustFundDocMgmtDetails(fundId))
    .done(function(audit, trustFundDetails) {
      NavUtils.navigate(
        '#dep/entity/' + entityId + '/fund/' + fundId + '/sub-fund/' + subFundId + '/audit/' + auditId + '/dashboard'
      );

      Scaffold.content.show(
        new DepAuditDashboardView({
          viewMode: 'int',
          model: new Backbone.Model({
            audit: audit,
            auditsCSFolderId: trustFundDetails.auditsCSFolderId
          })
        })
      );
      NProgress.done();
    })
    .fail(function(err) {
      NProgress.done();
    });
};
