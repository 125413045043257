/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');

module.exports = function(formData) {
  var deferred = $.Deferred();

  $.soap({
    body: {
      SubmitEntityExamMain: {
        $xmlns: 'http://dobs.pa.gov/dp/exam/bpm/1.0',
        ID: formData.id,
        TypeID: formData.examTypeId,
        ScopeID: formData.examScopeId,
        TargetVisitationCode: formData.targetVisitationCode,
        ScopeSubcategory: formData.scopeSubcategory,
        LeadAgencyID: formData.leadAgencyId,
        IsHoldingCompanyExam: formData.isHoldingCompanyExam,
        BHCAsOfDate: formData.bhcAsOfDate,
        Comments: formData.comments,
        IsConcurrent: formData.isConcurrent,
        IsParticipation: formData.isParticipation,
        DueDate: formData.dueDate,
        InitialProcessorReceivedDate: formData.initialProcessorReceivedDate,
        SentToCaseManagerDate: formData.sentToCaseManagerDate,
        SentToFinalReviewerDate: formData.sentToFinalReviewerDate,
        LeadAgencyFederalAgencyReceivedID: formData.leadAgencyFederalAgencyReceivedID,
        LeadAgencyReceivedFromFedDate: formData.leadAgencyReceivedFromFedDate,
        LeadAgencyReturnedToFedDate: formData.leadAgencyReturnedToFedDate,
        StateLeadAgencySentToFedDate: formData.stateLeadAgencySentToFedDate,
        StateLeadAgencyReceivedFromFedDate: formData.stateLeadAgencyReceivedFromFedDate,
        FinalProcessorReceivedDate: formData.finalProcessorReceivedDate,
        FinalProcessorSentForTransmittalDate: formData.finalProcessorSentForTransmittalDate,
        SignoffSSDate: formData.signoffSSDate,
        SignoffITDate: formData.signoffITDate,
        SignoffTrustDate: formData.signoffTrustDate,
        TransmittalDate: formData.transmittalDate,
        ReceivedByEntityDate: formData.receivedByEntityDate,
        ExamClosedDate: formData.examClosedDate,
        SAERCompleteDate: formData.saerCompleteDate,
        CompletionDate: formData.completionDate,
        IsDeleted: formData.isDeleted,
        ExamProcessingResponses: (function(responses) {
          if (!responses || responses.length === 0) {
            return {};
          }
          return {
            ExamProcessingResponse: _.map(responses, function(response) {
              return {
                EntityID: response.entityId,
                ResponseDueDate: response.responseDueDate,
                ResponseReceivedDate: response.responseReceivedDate,
                AcknowledgementSentDate: response.acknowledgementSentDate,
                Notes: response.notes
              };
            })
          };
        })(formData.processingResponses),
        IsProcessingResponseUpdated: +formData.isProcessingResponseUpdated,

        ConductedBys: (function(conductedBys) {
          if (!conductedBys || conductedBys.length === 0) {
            return {};
          }
          return {
            ConductedBy: _.map(conductedBys, function(conductedBy) {
              return {
                EntityID: conductedBy.entityId,
                ConductedByID: conductedBy.conductedById
              };
            })
          };
        })(formData.conductedBys),
        IsExamConductedByUpdated: +formData.isExamConductedByUpdated,
        ExamAreas: (function(formData) {
          var examAreas = [];
          if (formData.ssArea) {
            examAreas.push({
              EntityID: formData.entityId,
              ExamAreaID: formData.ssArea.examAreaId,
              AsOfDate: formData.ssArea.asOfDate,
              IsNotifyEntity: +formData.ssArea.isNotifyEntity,
              FirstDayLetterDate: formData.ssArea.firstDayLetterDate,
              FirstDayLetterDocID: formData.ssArea.firstDayLetterDocId,
              EIC: formData.ssArea.eic,
              FederalEIC: formData.ssArea.federalEIC,
              ExamStartDate: formData.ssArea.examStartDate,
              ExitMeetingDate: formData.ssArea.exitMeetingDate,
              FirstDayLetterAcknowledgedDate: formData.ssArea.firstDayLetterAcknowledgedDate,
              ExamHours: formData.ssArea.examHours
            });
          }
          if (formData.trustArea) {
            examAreas.push({
              EntityID: formData.entityId,
              ExamAreaID: formData.trustArea.examAreaId,
              AsOfDate: formData.trustArea.asOfDate,
              IsNotifyEntity: +formData.trustArea.isNotifyEntity,
              FirstDayLetterDate: formData.trustArea.firstDayLetterDate,
              FirstDayLetterDocID: formData.trustArea.firstDayLetterDocId,
              EIC: formData.trustArea.eic,
              FederalEIC: formData.trustArea.federalEIC,
              ExamStartDate: formData.trustArea.examStartDate,
              ExitMeetingDate: formData.trustArea.exitMeetingDate,
              FirstDayLetterAcknowledgedDate: formData.trustArea.firstDayLetterAcknowledgedDate,
              ExamHours: formData.trustArea.examHours
            });
          }
          if (formData.itArea) {
            examAreas.push({
              EntityID: formData.entityId,
              ExamAreaID: formData.itArea.examAreaId,
              AsOfDate: formData.itArea.asOfDate,
              IsNotifyEntity: +formData.itArea.isNotifyEntity,
              FirstDayLetterDate: formData.itArea.firstDayLetterDate,
              FirstDayLetterDocID: formData.itArea.firstDayLetterDocId,
              EIC: formData.itArea.eic,
              FederalEIC: formData.itArea.federalEIC,
              ExamStartDate: formData.itArea.examStartDate,
              ExitMeetingDate: formData.itArea.exitMeetingDate,
              FirstDayLetterAcknowledgedDate: formData.itArea.firstDayLetterAcknowledgedDate,
              ExamHours: formData.itArea.examHours
            });
          }
          if (formData.complianceArea) {
            examAreas.push({
              EntityID: formData.entityId,
              ExamAreaID: formData.complianceArea.examAreaId,
              AsOfDate: formData.complianceArea.asOfDate
            });
          }
          if (formData.craArea) {
            examAreas.push({
              EntityID: formData.entityId,
              ExamAreaID: formData.craArea.examAreaId,
              AsOfDate: formData.craArea.asOfDate
            });
          }

          if (examAreas.length > 0) {
            return {
              ExamArea: examAreas
            };
          }
          return {};
        })(formData),
        IsExamAreaUpdated: +formData.isExamAreaUpdated,
        DataCenter: formData.dataCenter,
        BusinessLineRiskRanking: formData.businessLineRiskRankingId,
        TSPProviderRiskRanking: formData.TSPProviderRiskRankingId,
        ExamPriorityRanking: formData.examPriorityRankingId,
        AgencyAgreement: formData.agencyAgreementId,
        OtherScope: formData.otherScope,
        OtherLeadAgency: formData.otherLeadAgency,
        TSPAsOfDate: formData.TSPAsOfDate,
        ParticipatingAgencies: (function(agencies) {
          if (!agencies || agencies.length === 0) {
            return {};
          }
          return {
            ParticipatingAgency: _.map(agencies, function(agency) {
              return {
                ExamID: agency.examId,
                NameID: agency.nameId,
                RegionDistrictID: agency.regionDistrictId,
                Other: agency.otherRegion
              };
            })
          };
        })(formData.participatingAgencies),
        PriorExams: (function(priorExams) {
          if (!priorExams || priorExams.length === 0) {
            return {};
          }
          return {
            PriorExam: _.map(priorExams, function(priorExam) {
              return {
                Date: priorExam.priorExamDate,
                Rating: priorExam.rating,
                LeadAgencyID: priorExam.leadAgencyId,
                ExamID: priorExam.examId,
                OtherLeadAgency: priorExam.otherLeadAgency
              };
            })
          };
        })(formData.priorExam)
      }
    },
    blueprint: {
      id: '$..EntityExamID'
    }
  })
    .done(function(response) {
      deferred.resolve(response.id);
    })
    .fail(function(err) {
      deferred.reject(err);
    });

  return deferred.promise();
};
