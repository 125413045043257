/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

module.exports = function(size, searchTerm) {
  this.size = size;
  this.query = {
    bool: {
      should: [
        {
          match: {
            recordName: {
              query: searchTerm,
              fuzziness: 'AUTO',
              analyzer: 'standard',
              boost: 2
            }
          }
        },
        {
          match: {
            'recordName.phonetic': {
              query: searchTerm,
              boost: 2
            }
          }
        },
        {
          wildcard: {
            recordName: {
              value: searchTerm.toLowerCase() + '*',
              boost: 2
            }
          }
        },
        {
          match: {
            'priorNames.priorName': {
              query: searchTerm,
              fuzziness: 'AUTO',
              analyzer: 'standard'
            }
          }
        },
        {
          match: {
            'priorNames.priorName.phonetic': searchTerm
          }
        },
        {
          wildcard: {
            'priorNames.priorName': searchTerm.toLowerCase() + '*'
          }
        }
      ],
      must_not: {
        term: {
          isDeleted: '1'
        }
      }
    }
  };
};
