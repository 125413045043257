/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _get = require('lodash.get');
var _ = require('underscore');

module.exports = function(formData) {
  var supportingDocuments = formData.supportingDocuments || [];
  var publication = formData.publicationRequirements || {};
  var infoDocs = formData.requiredInformationDocumentation || {};
  var lawyerExternalUserId = _get(formData, 'bankArticlesAmendmentSubmissionLawyer.lawyerExternalUserId');
  var pointOfContact = formData.pointOfContact || {};
  var certification = formData.certification || {};

  return $.soap({
    body: {
      SubmitBankArticlesAmendmentFiling: {
        $xmlns: 'http://dobs.pa.gov/dp/filing/bpm/1.0',
        BankArticlesAmendmentFilingID: formData.bankArticlesAmendmentFilingId,
        FilingID: formData.filingId,
        PublicationReqsDocID: publication.publicationReqsDocId,
        PublicationReqsComments: publication.publicationReqsComments,
        GeneralPublicationProofComments: publication.generalPublicationProofComments,
        LegalPublicationProofComments: publication.legalPublicationProofComments,
        ArticlesOfAmendmentDocID: infoDocs.articlesOfAmendmentDocId,
        ArticlesOfAmendmentComments: infoDocs.articlesOfAmendmentComments,
        BoardResolutionDocID: infoDocs.boardResolutionDocId,
        BoardResolutionComments: infoDocs.boardResolutionComments,
        ShareholderNoticeDocID: infoDocs.shareholderNoticeDocId,
        ShareholderNoticeComments: infoDocs.shareholderNoticeComments,
        ShareholderApprovalDocID: infoDocs.shareholderApprovalDocId,
        ShareholderApprovalComments: infoDocs.shareholderApprovalComments,
        NameReservationDocID: infoDocs.nameReservationDocId,
        NameReservationComments: infoDocs.nameReservationComments,
        PrinciplePlaceActivityNew: infoDocs.principlePlaceActivityNew,
        PrinciplePlaceActivityExisting: infoDocs.principlePlaceActivityExisting,
        ArticlesOfAmendmentTypeID: infoDocs.articlesOfAmendmentTypeId,
        NewName: infoDocs.newName,
        PurposeDescription: infoDocs.purposeDescription,
        IsSameMailingAddress: infoDocs.isSameMailingAddress,
        RequestedEffectiveDate: infoDocs.requestedEffectiveDate,
        IsCertified: certification.isCertified ? '1' : '0',
        CertificationName: certification.certificationName,
        CertificationDocID: certification.certificationDocId,
        NewMailingAddress: {
          AddressLine1: _get(infoDocs, 'newMailingAddress.addressLine1'),
          AddressLine2: _get(infoDocs, 'newMailingAddress.addressLine2'),
          AddressLine3: _get(infoDocs, 'newMailingAddress.addressLine3'),
          County: _get(infoDocs, 'newMailingAddress.county'),
          City: _get(infoDocs, 'newMailingAddress.city'),
          State: _get(infoDocs, 'newMailingAddress.state'),
          PostalCode: _get(infoDocs, 'newMailingAddress.postalCode'),
          Country: _get(infoDocs, 'newMailingAddress.country')
        },
        PointOfContact: {
          Name: pointOfContact.name,
          Title: pointOfContact.title,
          Phone: pointOfContact.phone,
          Email: pointOfContact.email,
          AddressLine1: pointOfContact.addressLine1,
          AddressLine2: pointOfContact.addressLine2,
          AddressLine3: pointOfContact.addressLine3,
          City: pointOfContact.city,
          State: pointOfContact.state,
          PostalCode: pointOfContact.postalCode,
          County: pointOfContact.county,
          Country: pointOfContact.country
        },
        LawyerExternalUserID: lawyerExternalUserId,
        GeneralPublicationProofDocuments: (function() {
          if (!_get(publication, 'generalPublicationProofDocIds.length')) {
            return {};
          }
          return _.map(publication.generalPublicationProofDocIds, function(docId) {
            return { DocumentID: docId };
          });
        })(),
        LegalPublicationProofDocuments: (function() {
          if (!_get(publication, 'legalPublicationProofDocIds.length')) {
            return {};
          }
          return _.map(publication.legalPublicationProofDocIds, function(docId) {
            return { DocumentID: docId };
          });
        })(),
        SupportingDocuments: (function() {
          if (!supportingDocuments.length) {
            return {};
          }
          return {
            Document: _.map(supportingDocuments, function(supportingDocument) {
              return {
                DocumentID: supportingDocument.fileId
              };
            })
          };
        })()
      }
    }
  });
};
