/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var NProgress = require('nprogress');

var NavUtils = require('psa-core/util/nav');

var Scaffold = require('common/layout/Scaffold');

var DepSEGLocationEditView = require('../views/DepSEGLocationEditView');
var GetSEGLocation = require('../services/GetSEGLocation');
var GetSEGIdentifiers = require('../services/GetSEGIdentifiers');
var GetEntityIdentifiers = require('../../common/services/GetEntityIdentifiers');

module.exports = function(entityId, segId, segLocationId) {
  NProgress.start();
  NProgress.set(0.4);
  $.when(GetSEGLocation(segLocationId), GetEntityIdentifiers(entityId), GetSEGIdentifiers(segId))
    .done(function(address, entityIdentifiers, segIdentifiers) {
      NProgress.set(0.7);
      NavUtils.navigate('#dep/entity/' + entityId + '/seg/' + segId + '/location/' + segLocationId + '/edit');
      Scaffold.content.show(
        new DepSEGLocationEditView({
          viewMode: 'int',
          model: new Backbone.Model({
            entityId: entityId,
            segId: segId,
            entityIdentifiers: entityIdentifiers,
            segIdentifiers: segIdentifiers,
            segLocationId: segLocationId,
            address: address
          })
        })
      );
      NProgress.done();
    })
    .fail(function(err) {
      NProgress.done();
    });
};
