/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Marionette = require('backbone.marionette');
var Radio = require('backbone.radio');
var Ladda = require('ladda');

var modelGet = require('common/util/modelGet');
var BreadcrumbView = require('common/views/BreadcrumbView');

var DepCallReportFormView = require('./DepCallReportFormView');
var SubmitEntityCallReport = require('../services/SubmitEntityCallReport');

var tmplDepCallReportNewView = require('../templates/DepCallReportNewView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplDepCallReportNewView,

  className: 'dep-new-call-report-view container-fluid',

  ui: {
    breadCrumbContainer: '.breadcrumb-container',
    callReportForm: '.call-report-form-container',
    submitButton: '.btn-submit'
  },

  regions: {
    breadCrumbContainer: '@ui.breadCrumbContainer',
    callReportForm: '@ui.callReportForm'
  },

  events: {
    'click @ui.submitButton': 'onClickSubmit'
  },

  onBeforeShow: function() {
    var entityId = this.model.get('entityId');
    var entityName = modelGet(this.model, 'entityIdentifiers.entityName');

    this.showChildView(
      'breadCrumbContainer',
      new BreadcrumbView({
        breadcrumbs: [
          {
            text: 'Home',
            link: '#dep/dashboard'
          },
          {
            text: entityName,
            link: '#dep/entity/' + entityId + '/dashboard'
          },
          {
            text: 'New Call Report'
          }
        ]
      })
    );

    this.callReportForm = new DepCallReportFormView({
      model: this.model
    });

    this.showChildView('callReportForm', this.callReportForm);
  },

  onClickSubmit: function(event) {
    event.preventDefault();
    var self = this;
    this.validate().done(function() {
      var laddaContext = Ladda.create(self.ui.submitButton.get(0));
      laddaContext.start();
      var formData = self.getFormData();
      formData.isDeleted = 0;
      SubmitEntityCallReport(formData)
        .done(function(response) {
          Radio.channel('navigate').trigger(
            'show:int:dp:entity:call-report',
            formData.entityId,
            response.newCallReportId
          );
        })
        .fail(function(err) {
          laddaContext.stop();
        });
    });
  },

  validate: function() {
    return this.ui.callReportForm.parsley().whenValidate();
  },

  getFormData: function() {
    return this.callReportForm.getFormData();
  }
});
