var Handlebars = require("handlebars/dist/handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "          <option value=\""
    + alias4(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\">"
    + alias4(((helper = (helper = helpers.type || (depth0 != null ? depth0.type : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"type","hash":{},"data":data}) : helper)))
    + "</option>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "\n<form class=\"applicant-form\">\n  <div class=\"row\">\n    <div class=\"col-sm-12\">\n      <div class=\"form-group required\">\n        <label class=\"control-label\">Name of Depository Institution/Holding Company whose shares are to be acquired.</label>\n        <input type=\"text\" name=\"acquiredEntityName\" class=\"form-control\" placeholder=\"Name\"\n          data-parsley-maxlength=\"200\" data-parsley-required=\"true\" />\n      </div>\n    </div>\n  </div>\n  <div class=\"row\">\n    <div class=\"col-md-6 col-sm-12\">\n      <div class=\"form-group required\">\n        <label class=\"control-label\">Type of Applicant</label>\n        <select name=\"applicantTypeId\" class=\"form-control\" data-parsley-required=\"true\">\n          <option value=\"\" selected>--</option>\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.applicantTypes : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "        </select>\n      </div>\n    </div>\n  </div>\n  <div class=\"row\">\n    <div class=\"col-md-6 col-sm-12\">\n      <div class=\"form-group required\">\n        <label class=\"control-label\">Type of Acquisition</label>\n        <select name=\"acquisitionTypeId\" class=\"form-control\" data-parsley-required=\"true\">\n          <option value=\"\" selected>--</option>\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.acquisitionTypes : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "        </select>\n      </div>\n    </div>\n  </div>\n  <div class=\"row applicant-count-section\">\n    <div class=\"col-md-6 col-sm-12\">\n      <div class=\"form-group\">\n        <label class=\"control-label\"># of Individuals</label>\n        <input type=\"text\" name=\"applicantCount\" class=\"form-control\" disabled />\n        <p class=\"input-label\">Populates automatically based on each additional applicant added</p>\n      </div>\n    </div>\n  </div>\n  <div class=\"applicants-container\"></div>\n  <p>\n    <button class=\"btn btn-primary btn-md add-applicant\">Add Additional Applicant</button>\n  </p>\n</form>";
},"useData":true});