/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var Backbone = require('backbone');
var Marionette = require('backbone.marionette');
var Syphon = require('backbone.syphon');
var Ladda = require('ladda');

var FileUploadCollection = require('psa-backbone/models/FileUploadCollection');

var modelGet = require('common/util/modelGet');
var toggleForm = require('common/util/toggleForm');
var downloadDocument = require('common/util/downloadDocument');
var FileUploadView = require('common/views/FileUploadView');
var SelectFieldView = require('common/views/SelectFieldView');

var CalculateFilingFee = require('../../external-dashboard/services/CalculateFilingFee');

var tmplCorpFinGenericSubmissionFormView = require('../templates/CorpFinGenericSubmissionFormView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplCorpFinGenericSubmissionFormView,

  tagName: 'form',

  className: 'corp-fin-generic-submission-submission-section-view',

  ui: {
    applicationType: 'select[name="applicationTypeId"]',
    filingTypeContainer: '.filing-type-select-container',
    filingType: 'select[name="filingTypeId"]',

    isIndefinite: 'select[name="isIndefinite"]',
    isIndefiniteFormGroup: '.is-indefinite-form-group',
    feeAmountFormGroup: '.fee-amount-form-group',
    amountFormGroup: '.amount-form-group',
    oversaleAmountFormGroup: '.oversale-amount-form-group',
    filingDocumentsContainer: '.filing-documents-container',

    submissionFormFormGroup: '.submission-form-form-group',
    btnDownloadSubmissionForm: '.btn-download-submission-form',

    feeAmount: '.fee-amount',
    btnUpdateFeeAmount: '.btn-update-fee-amount',

    updateFeeAmountLabel:'.update-fee-amount-label',
    enterFeeAmountLabel:'.enter-fee-amount-label'
  },

  regions: {
    filingTypeContainer: '@ui.filingTypeContainer',
    filingDocumentsContainer: '@ui.filingDocumentsContainer'
  },

  events: {
    'change @ui.isIndefinite': 'updateFormState',
    'change @ui.applicationType': 'onChangeApplicationType',
    'change @ui.filingType': 'updateFormState',
    'click @ui.btnDownloadSubmissionForm': 'onClickBtnDownloadSubmissionForm',

    'click @ui.btnUpdateFeeAmount': 'onClickUpdateFeeAmount'
  },

  behaviors: {
    ReadOnlyFormBehavior: {}
  },

  templateHelpers: function() {
    return {
     viewMode: this.options.viewMode,
    isReadOnly: this.options.isReadOnly
    
    };
  },

  onBeforeShow: function() {
    var rawFilingTypes = modelGet(this.model, 'filingTypes', []);
    var isInternal = this.options.viewMode === 'int';
   
    this.filingTypes = _.map(rawFilingTypes, function(filingType) {
      return {
        value: filingType.id,
        text: filingType.type,
        filingApplicationTypeId: filingType.filingApplicationTypeId
      };
    });

    this.filingSubmissionForms = _.reduce(
      rawFilingTypes,
      function(forms, filingType) {
        forms[String(filingType.id)] = {
          fileId: filingType.downloadFormDocId,
          fileName: filingType.downloadFormDocName
        };
        return forms;
      },
      {}
    );

    this.filingTypesCollection = new Backbone.Collection();

    var filingTypeSelect = new SelectFieldView({
      name: 'filingTypeId',
      isDisabled: false,
      isRequired: true,
      collection: this.filingTypesCollection,
      attributes: {
        'data-parsley-group': 'filing-fee'
      }
    });

    this.showChildView('filingTypeContainer', filingTypeSelect);

    var filingDocuments = _.pluck(modelGet(this.model, 'filing.filingDocuments'), 'file');

    this.fileDocumentsUploadView = new FileUploadView({
      isReadOnly: this.options.isReadOnly,
      allowMultipleFiles: true,
      isRequired: true,
      collection: new FileUploadCollection(filingDocuments)
    });
    this.showChildView('filingDocumentsContainer', this.fileDocumentsUploadView);

    if (this.options.isReadOnly && filingDocuments.length === 0) {
      this.ui.filingDocumentsEmptyMessageContainer.show();
    }

    var filingData = this.model.get('filing');
    if (filingData) {
      Syphon.deserialize(this, filingData);
      this.filingTypesCollection.reset(
        _.filter(this.filingTypes, function(filingType) {
          return filingType.filingApplicationTypeId === filingData.applicationTypeId;
        })
      );

      filingTypeSelect.setValue(filingData.filingTypeId);
    }
    this.updateFormState();

    if (isInternal){
      this.ui.feeAmount.val(filingData.feeAmount)
    } 

  },

  onChangeApplicationType: function() {
    var applicationTypeId = this.ui.applicationType.val();

    this.filingTypesCollection.reset(
      _.filter(this.filingTypes, function(filingType) {
        return filingType.filingApplicationTypeId === applicationTypeId;
      })
    );

    this.updateFormState();


  },

  updateFormState: function() {
    var formData = Syphon.serialize(this, {
      exclude: ['fileselect[]']
    });

    toggleForm(this.ui.amountFormGroup, formData.isIndefinite === '0' || !formData.isIndefinite);

    var oversaleAssessmentFilingTypeIds = ['24'],
      isOversaleAmountRequired = _.contains(oversaleAssessmentFilingTypeIds, formData.filingTypeId);

    toggleForm(this.ui.oversaleAmountFormGroup, isOversaleAmountRequired);

    var FeeAmountRequiredFilingTypeIds = ['23'],
      FeeAmountRequired = _.contains(FeeAmountRequiredFilingTypeIds, formData.filingTypeId);
  
    this.ui.feeAmount.val('');
  
    this.ui.feeAmount.attr('readonly', !FeeAmountRequired);

    this.ui.updateFeeAmountLabel.attr('hidden',FeeAmountRequired)
    this.ui.enterFeeAmountLabel.attr('hidden',!FeeAmountRequired)

    toggleForm(this.ui.btnUpdateFeeAmount, !FeeAmountRequired);

    var isFeeAmountHiddenFilingTypeIds = ['25'];
    var isFeeAmountHidden = isFeeAmountHiddenFilingTypeIds.includes(formData.filingTypeId);
    if (isFeeAmountHidden === true) { this.ui.btnUpdateFeeAmount.click(); }
    toggleForm(this.ui.feeAmountFormGroup, !isFeeAmountHidden);

    var isAmountHiddenFilingTypeIds = ['25'];
    var isAmountHidden = isAmountHiddenFilingTypeIds.includes(formData.filingTypeId);
    toggleForm(this.ui.amountFormGroup, !isAmountHidden);

    var isIndefiniteRequiredFilingTypeIds = ['21', '22', '23'],
      isIndefiniteRequired = _.contains(isIndefiniteRequiredFilingTypeIds, formData.filingTypeId);

    this.ui.isIndefiniteFormGroup.toggleClass('required', isIndefiniteRequired);

    this.ui.isIndefinite.attr('data-parsley-required', isIndefiniteRequired);

    var isTypeSection211b1 = formData.filingTypeId === '17';
    var isTypeSection203u = formData.filingTypeId === '15';
    var isMFISalesReportAndAmendment = formData.filingTypeId === '25';

    var showSubmissionFormFormGroup = !!formData.filingTypeId && !isTypeSection211b1 && !isTypeSection203u && !isMFISalesReportAndAmendment;

    toggleForm(this.ui.submissionFormFormGroup, showSubmissionFormFormGroup);

    var isIndefiniteHiddenFilingTypeIds = ['1', '2', '11', '12', '13', '14', '15', '17','25'],
      isIndefiniteHidden = !_.contains(isIndefiniteHiddenFilingTypeIds, formData.filingTypeId);
    toggleForm(this.ui.isIndefiniteFormGroup, isIndefiniteHidden);
  },

  validate: function(conf) {
    if (conf.type === 'fee-update') {
      return this.$el.parsley().whenValidate({
        group: 'filing-fee'
      });
    }

    var validationArray = [this.$el.parsley().whenValidate(), this.fileDocumentsUploadView.validate()];
    return $.when.apply($, validationArray);
  },

  getFormData: function() {
    var formData = Syphon.serialize(this);
    formData.documents = this.fileDocumentsUploadView.collection.pluck('fileId');
    return formData;
  },

  onClickBtnDownloadSubmissionForm: function(e) {
    e.preventDefault();

    var formData = this.getFormData(),
      submissionFormDoc = this.filingSubmissionForms[formData.filingTypeId];
    downloadDocument({
      fileId: submissionFormDoc.fileId,
      fileName: submissionFormDoc.fileName,
      isStatic: true
    });
  },

  onClickUpdateFeeAmount: function(e) {
    e.preventDefault();

    var self = this;

    this.validate({ type: 'fee-update' }).done(function() {
      var laddaContext = Ladda.create(e.currentTarget).start();

      var formData = self.getFormData();

      CalculateFilingFee(formData)
        .done(function(feeAmount) {
          self.ui.feeAmount.val(feeAmount);
          laddaContext.stop();
        })
        .fail(function() {
          laddaContext.stop();
        });
    });
  }
});
