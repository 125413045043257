/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var GridView = require('psa-marionette/views/GridView');

var MomentCell = require('common/backgrid/MomentCell');
var ButtonLinkCell = require('common/backgrid/ButtonLinkCell');

module.exports = GridView.extend({
  className: 'non-dep-entity-external-pending-task-view',

  options: {
    columns: [
      {
        name: 'typeName',
        label: 'Task Name',
        cell: 'string',
        editable: false,
        headerCell: 'custom',
        width: 20
      },
      {
        name: 'licenseType',
        label: 'License Type',
        cell: 'string',
        editable: false,
        headerCell: 'custom',
        width: 20
      },
      {
        name: 'licenseNumber',
        label: 'License Number',
        cell: 'string',
        editable: false,
        headerCell: 'custom',
        width: 10
      },
      {
        name: 'location',
        label: 'Location',
        cell: 'string',
        editable: false,
        headerCell: 'custom',
        width: 15
      },
      {
        name: 'deliveryDate',
        label: 'Assigned On',
        width: 15,
        headerCell: 'custom',
        cell: MomentCell.MomentCell,
        formatter: MomentCell.MomentDateTimeFormatter.extend({
          emptyValue: '--'
        }),
        sortValue: MomentCell.MomentSortValue,
        editable: false
      },
      {
        name: 'dueDate',
        label: 'Due Date',
        width: 10,
        headerCell: 'custom',
        cell: 'string',
        sortValue: MomentCell.MomentSortValue,
        editable: false
      },
      {
        name: '',
        label: '',
        cell: ButtonLinkCell.extend({
          name: 'Open',
          href: function() {
            return '#ext/task/' + this.model.get('taskId');
          }
        }),
        editable: false,
        sortable: false,
        headerCell: 'custom',
        width: 10
      }
    ],
    emptyText: 'No Pending Tasks'
  },

  initialize: function(options) {
    var externalManagerAccessLevelId = 20;

    if (options.userAccessLevel.accessLevelId < externalManagerAccessLevelId) {
      options.columns.splice(-1, 1, {
        name: '',
        label: '',
        cell: 'string',
        editable: false,
        width: 10
      });
    }

    if (GridView.prototype.initialize) {
      GridView.prototype.initialize.apply(options);
    }
  }
});
