var Handlebars = require("handlebars/dist/handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "            <option value=\""
    + alias4(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\">"
    + alias4(((helper = (helper = helpers.type || (depth0 != null ? depth0.type : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"type","hash":{},"data":data}) : helper)))
    + "</option>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "        <option value=\""
    + alias4(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\">"
    + alias4(((helper = (helper = helpers.regarding || (depth0 != null ? depth0.regarding : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"regarding","hash":{},"data":data}) : helper)))
    + "</option>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "\n<div class=\"modal-body\">\n  <form class=\"license-payment-form\">\n    <div class=\"row\">\n      <div class=\"col-sm-6\">\n        <div class=\"form-group required\">\n          <label class=\"control-label\">Payment Date</label>\n          <div class=\"input-group\">\n            <input type=\"text\" name=\"paidDate\" class=\"form-control datepicker paid-date\" placeholder=\"Payment Date\"\n              data-parsley-required=\"true\" data-parsley-date=\"\"\n              data-parsley-trigger-after-failure=\"changeDate\" \n              data-parsley-errors-container=\"#paidDateErrorsContainer\" />\n            <div class=\"input-group-addon\">\n              <i class=\"fa fa-calendar\"></i>\n            </div>\n          </div>\n          <span id=\"paidDateErrorsContainer\"></span>\n        </div>\n      </div>\n      <div class=\"col-sm-6\">\n        <div class=\"form-group required\">\n          <label class=\"control-label\">Payment Type</label>\n          <select name=\"typeId\" class=\"form-control\" data-parsley-required=\"true\">\n            <option value=\"\">--</option>\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.paymentType : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "          </select>\n        </div>\n      </div>\n    </div>\n    <div class=\"form-group required\">\n      <label class=\"control-label\">Payment Regarding</label>\n      <select name=\"regardingId\" class=\"form-control\" data-parsley-required=\"true\">\n        <option value=\"\">--</option>\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.paymentRegarding : depth0),{"name":"each","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "      </select>\n    </div>\n    <div class=\"form-group required\">\n      <label class=\"control-label\"> Payment Amount</label>\n      <div class=\"input-group amount-input-group\">\n        <div class=\"input-group-addon\">$</div>\n        <input type=\"number\" step=\"any\" class=\"form-control\" name=\"amount\" placeholder=\"Payment Amount\"\n          data-parsley-dollar-amount=\"true\" data-parsley-errors-container=\"#amountError\" data-parsley-required=\"true\" />\n      </div>\n      <div id=\"amountError\"></div>\n    </div>\n    <div class=\"form-group\">\n      <label class=\"control-label\">Comments</label>\n      <textarea class=\"form-control\" name=\"comments\" rows=\"4\" placeholder=\"Comments\"></textarea>\n    </div>\n  </form>\n</div>\n<div class=\"modal-footer\">\n  <button class=\"btn btn-default\" data-dismiss=\"modal\">Cancel</button>\n  <button class=\"btn btn-primary submit ladda-button\" data-style=\"expand-right\" type=\"button\">\n    <span class=\"ladda-label\">Submit</span>\n  </button>\n</div>";
},"useData":true});