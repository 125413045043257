var Handlebars = require("handlebars/dist/handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "Sub-";
},"3":function(container,depth0,helpers,partials,data) {
    var helper;

  return container.escapeExpression(((helper = (helper = helpers.subFundName || (depth0 != null ? depth0.subFundName : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"subFundName","hash":{},"data":data}) : helper)));
},"5":function(container,depth0,helpers,partials,data) {
    var helper;

  return container.escapeExpression(((helper = (helper = helpers.fundName || (depth0 != null ? depth0.fundName : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"fundName","hash":{},"data":data}) : helper)));
},"7":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "      <a class=\"btn btn-default\"\n        href=\"#dep/entity/"
    + alias4(((helper = (helper = helpers.entityId || (depth0 != null ? depth0.entityId : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"entityId","hash":{},"data":data}) : helper)))
    + "/fund/"
    + alias4(((helper = (helper = helpers.fundId || (depth0 != null ? depth0.fundId : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"fundId","hash":{},"data":data}) : helper)))
    + "/sub-fund/"
    + alias4(((helper = (helper = helpers.subFundId || (depth0 != null ? depth0.subFundId : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"subFundId","hash":{},"data":data}) : helper)))
    + "/dashboard\">Cancel</a>\n";
},"9":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "      <a class=\"btn btn-default\" href=\"#dep/entity/"
    + alias4(((helper = (helper = helpers.entityId || (depth0 != null ? depth0.entityId : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"entityId","hash":{},"data":data}) : helper)))
    + "/fund/"
    + alias4(((helper = (helper = helpers.fundId || (depth0 != null ? depth0.fundId : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"fundId","hash":{},"data":data}) : helper)))
    + "/dashboard\">Cancel</a>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "\n<div class=\"page-title clearfix\">\n  <h1 class=\"m-n p-n\">"
    + container.escapeExpression(((helper = (helper = helpers.entityName || (depth0 != null ? depth0.entityName : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"entityName","hash":{},"data":data}) : helper)))
    + "</h1>\n</div>\n<div class=\"page-subtitle\">\n  <h2>Collective Investment "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.subFundId : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "Fund</h2>\n  <div class=\"page-subtitle-details\">"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.subFundId : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.program(5, data, 0),"data":data})) != null ? stack1 : "")
    + "</div>\n</div>\n<div class=\"breadcrumb-container\"></div>\n<div class=\"col-sm-10 col-sm-offset-1\">\n  <div class=\"row\">\n    <div class=\"col-sm-12 col-sm-offset-3 col-md-10 col-md-offset-2\">\n      <h3 class=\"m-t-xs m-b-lg\">New Collective Investment "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.subFundId : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "Fund Audit</h3>\n    </div>\n  </div>\n  <div class=\"new-audit-form-container\"></div>\n  <form>\n    <div class=\"form-horizontal\">\n      <div class=\"form-group\">\n        <label class=\"control-label col-md-2 col-sm-3\">Documents</label>\n        <div class=\"col-md-10 col-sm-9\">\n          <div class=\"supporting-documents-container\"></div>\n        </div>\n      </div>\n    </div>\n  </form>\n  <div class=\"row\">\n    <div class=\"col-sm-12 col-sm-offset-3 col-md-10 col-md-offset-2\">\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.subFundId : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.program(9, data, 0),"data":data})) != null ? stack1 : "")
    + "      <button class=\"btn btn-primary btn-submit\">Submit</button>\n    </div>\n  </div>\n</div>";
},"useData":true});