/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var moment = require('moment');

module.exports = function(applicationDetails, countOfRenewingBranches) {
  var invoiceItems = [];
  var mainApplicationFee = applicationDetails.applicationFee;
  var mainRenewalFee = applicationDetails.renewalFee;
  var branchApplicationFee = applicationDetails.branchApplicationFee;
  var branchRenewalFee = applicationDetails.branchRenewalFee;
  var renewalPeriodStart = applicationDetails.license.renewalPeriodStart;
  var renewalPeriodEnd = applicationDetails.license.renewalPeriodEnd;
  var submissionDate = applicationDetails.submissionDate;
  var totalPastTransactions = applicationDetails.totalPastTransactions;
  var renewalPeriodSpansYears = applicationDetails.renewalPeriodSpansYears === '1';

  var eligibleForRenewalFee = true;
  var description = applicationDetails.license.licenseNumber + ' ' + applicationDetails.license.licenseType;
  renewalPeriodStart.year(moment().year());
  renewalPeriodEnd.year(moment().year());

  if (renewalPeriodSpansYears) {
    renewalPeriodEnd.year(
      moment()
        .add(1, 'years')
        .year()
    );
  }

  if (submissionDate && submissionDate.isValid()) {
    if (!submissionDate.isBetween(renewalPeriodStart, renewalPeriodEnd, 'day', '[]')) {
      eligibleForRenewalFee = false;
    }
  } else {
    if (!moment().isBetween(renewalPeriodStart, renewalPeriodEnd, 'day', '[]')) {
      eligibleForRenewalFee = false;
    }
  }
  var mainFeeToUse = eligibleForRenewalFee ? mainRenewalFee : mainApplicationFee;
  var branchFeeToUse = eligibleForRenewalFee ? branchRenewalFee : branchApplicationFee;

  invoiceItems.push({
    description: 'Principal License Renewal Fee' + ' ' + description,
    quantity: 1,
    unitPrice: mainFeeToUse
  });

  if (countOfRenewingBranches > 0) {
    invoiceItems.push({
      description: 'Branch License Renewal Fee',
      quantity: countOfRenewingBranches,
      unitPrice: branchFeeToUse
    });
  }

  if (totalPastTransactions > 0) {
    invoiceItems.push({
      description: 'Previously Paid Amount',
      quantity: '1',
      unitPrice: parseFloat((-1 * totalPastTransactions).toFixed(2))
    });
  }
  return invoiceItems;
};
