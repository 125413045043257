/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var Marionette = require('backbone.marionette');
var Syphon = require('backbone.syphon');
var Radio = require('backbone.radio');
var Ladda = require('ladda');

var ExamRecordedResultsView = require('../../views/ExamRecordedResultsView');
var SubmitExaminerRecordExamResultsTask = require('../../services/SubmitExaminerRecordExamResultsTask');
var FileUploadCollection = require('psa-backbone/models/FileUploadCollection');
var FileUploadView = require('common/views/FileUploadView');
var modelGet = require('common/util/modelGet');

require('./ExaminerRecordExamResultsWorkTabView.css');
var tmplExaminerRecordExamResultsWorkTabView = require('./ExaminerRecordExamResultsWorkTabView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplExaminerRecordExamResultsWorkTabView,

  className: 'examiner-record-exam-results-work-tab-view container-fluid',

  ui: {
    formWorkTab: '.form-work-tab',
    examRecordedResultsContainer: '.exam-recorded-results-container',
    attachmentContainer: '.attachment-container',
    submit: '.submit'
  },

  regions: {
    examRecordedResultsContainer: '@ui.examRecordedResultsContainer',
    attachmentContainer: '@ui.attachmentContainer'
  },

  events: {
    'click @ui.submit': 'onClickSubmit'
  },

  onBeforeShow: function() {
    this.examRecordedResultsView = new ExamRecordedResultsView({
      model: this.model,
      mode: 'examiner',
      documentTypes: this.options.documentTypes
    });

    this.showChildView('examRecordedResultsContainer', this.examRecordedResultsView);

    var decisionReason = this.model.get('previousReviewDecision').decisionReason;
    if (decisionReason) {
      var regionalDirectorReviewDocumentId = modelGet(this.model, 'exam.regionalDirectorReviewDocument.fileId');
      if (regionalDirectorReviewDocumentId) {
        var regionalDirectorReviewDocument = modelGet(this.model, 'exam.regionalDirectorReviewDocument');
      }
      this.fileUploadView = new FileUploadView({
        isReadOnly: true,
        allowMultipleFiles: false,
        collection: new FileUploadCollection(regionalDirectorReviewDocument)
      });
      this.showChildView('attachmentContainer', this.fileUploadView);
    }
  },

  onClickSubmit: function(e) {
    e.preventDefault();
    var self = this;
    var FormValidationPromise = this.ui.formWorkTab.parsley().whenValidate();
    var ChildViewValidationPromise = this.examRecordedResultsView.validate();
    $.when(FormValidationPromise, ChildViewValidationPromise).done(function() {
      var l = Ladda.create(self.ui.submit[0]);
      l.start();
      var childFormData = self.examRecordedResultsView.getFormData();
      var formData = Syphon.serialize(self);

      var retainedDocuments = [];
      // Include loan log documents retained if available
      if (self.options.loanLogQuestionsView) {
        var retainedLoanLogDocuments = self.options.loanLogQuestionsView.getRetainedDocuments();
        retainedDocuments = retainedDocuments.concat(retainedLoanLogDocuments);
      }
      // Include exam material documents retained if available
      if (self.options.examMaterialsQuestionView) {
        var retainedExamMaterialsDocuments = self.options.examMaterialsQuestionView.getRetainedDocuments();
        retainedDocuments = retainedDocuments.concat(retainedExamMaterialsDocuments);
      }

      _.extend(formData, childFormData, {
        retainedResponseDocumentIds: retainedDocuments,
        taskId: self.model.get('taskId'),
        examId: self.model.get('examId')
      });
      formData.complianceViolationIds = formData.complianceViolationIds || [];
      SubmitExaminerRecordExamResultsTask(formData)
        .done(function() {
          Radio.channel('navigate').trigger('show:int:nd:dashboard');
        })
        .fail(function() {
          l.stop();
        });
    });
  }
});
