/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Marionette = require('backbone.marionette');
var Syphon = require('backbone.syphon');

var parsleyUtil = require('common/util/parsleyUtil');

var tmplConSerComplaintFormYourInformationView = require('../templates/ConSerComplaintFormYourInformationView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplConSerComplaintFormYourInformationView,

  className: 'conser-complaint-form-your-information-view',

  ui: {
    isInvestor: 'input[name="isInvestor"]',
    isInvestorNoRadio: 'input[name="isInvestor"][value="0"]',
    isInvestorInfoForm: '.is-not-investor-form',
    complaintantInformationForm: '.complaintant-information-form'
  },

  events: {
    'change @ui.isInvestor': 'onChangeIsInvestor'
  },

  behaviors: {
    RadioGroupToggleBehavior: {
      radios: function() {
        var radios = [
          {
            target: '.is-not-investor-form',
            radioGroup: '[name="isInvestor"]',
            valueToShow: '0'
          }
        ];

        return radios;
      }
    }
  },

  onBeforeShow: function() {
    Syphon.deserialize(this, this.model.toJSON());
  },

  validate: function(config) {
    this.ui.complaintantInformationForm.parsley().reset();

    if (config.type === 'save') {
      // Turn off required field validation for these forms
      parsleyUtil.disableRequiredValidation(this.ui.complaintantInformationForm);
      parsleyUtil.disableRequiredValidation(this.ui.isInvestorInfoForm);
    } else {
      // Turn on required field validation for this form
      parsleyUtil.enableRequiredValidation(this.ui.complaintantInformationForm);
      parsleyUtil.enableRequiredValidation(this.ui.isInvestorInfoForm);
    }
    var formData = Syphon.serialize(this.ui.complaintantInformationForm);
    var isNotInvestor = formData.isInvestor === '0';

    var validationPromises = [this.ui.complaintantInformationForm.parsley().whenValidate()];
    if (isNotInvestor) {
      validationPromises.push(this.ui.isInvestorInfoForm.parsley().whenValidate());
    }

    return $.when.apply($, validationPromises);
  },

  getFormData: function() {
    var formData = Syphon.serialize(this);

    if (formData.isInvestor === '1' || !formData.isInvestor) {
      formData.address = {};
    }

    return formData;
  }
});
