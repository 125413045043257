/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var _get = require('lodash.get');

module.exports = function(formData) {
  var supportingDocuments = formData.supportingDocuments || [];
  var publicationReqsDoc = formData.publicationReqsDoc || {};
  var requiredInfosDoc = formData.requiredInfosDoc || {};
  var pointOfContact = formData.pointOfContact || {};
  var lawyerExternalUserId = _get(formData, 'bankConversionNationalSubmissionLawyer.lawyerExternalUserId');
  var certification = formData.certification || {};

  return $.soap({
    body: {
      SubmitBankConversionNationalToStateFiling: {
        $xmlns: 'http://dobs.pa.gov/dp/filing/bpm/1.0',
        BankConversionNationalToStateID: formData.id,
        FilingID: formData.filingId,
        IsCertified: certification.isCertified ? '1' : '0',
        CertificationName: certification.certificationName,
        CertificationDocID: certification.certificationDocId,
        FilBankConversionNationalToState: {
          PublicationReqsDocID: publicationReqsDoc.docId,
          PublicationReqsComments: publicationReqsDoc.comments,
          ActionReason: requiredInfosDoc.actionReason,
          FederalCharterID: requiredInfosDoc.federalCharterId,
          OwnershipStructureID: requiredInfosDoc.ownershipStructureId,
          CorporateStructureID: requiredInfosDoc.corporateStructureId,
          StateCharterID: requiredInfosDoc.stateCharterId,
          HasTrustPowers: requiredInfosDoc.hasTrustPowers,
          HasHCStructure: requiredInfosDoc.hasHCStructure,
          FederalReserveBank: requiredInfosDoc.federalReserveBank,
          BranchesDocID: requiredInfosDoc.branchesDocId,
          BranchesComments: requiredInfosDoc.branchesComments,
          SubsidiariesDocID: requiredInfosDoc.subsidiariesDocId,
          SubsidiariesComments: requiredInfosDoc.subsidiariesComments,
          AffiliatesDocID: requiredInfosDoc.affiliatesDocId,
          AffiliatesComments: requiredInfosDoc.affiliatesComments,
          ArticlesOfConversionDocID: requiredInfosDoc.articlesOfConversionDocId,
          ArticlesOfConversionComments: requiredInfosDoc.articlesOfConversionComments,
          BylawsDocID: requiredInfosDoc.bylawsDocId,
          BylawsComments: requiredInfosDoc.bylawsComments,
          NameReservationDocID: requiredInfosDoc.nameReservationDocId,
          NameReservationComments: requiredInfosDoc.nameReservationComments
        },
        PointOfContact: {
          Name: pointOfContact.name,
          Phone: pointOfContact.phone,
          Email: pointOfContact.email,
          AddressLine1: pointOfContact.addressLine1,
          AddressLine2: pointOfContact.addressLine2,
          AddressLine3: pointOfContact.addressLine3,
          City: pointOfContact.city,
          State: pointOfContact.state,
          PostalCode: pointOfContact.postalCode,
          County: pointOfContact.county,
          Country: pointOfContact.country,
          Title: pointOfContact.title
        },
        LawyerExternalUserID: lawyerExternalUserId,
        SupportingDocuments: (function() {
          if (!supportingDocuments.length) {
            return {};
          }
          return {
            Document: _.map(supportingDocuments, function(supportingDocument) {
              return {
                DocumentID: supportingDocument.fileId
              };
            })
          };
        })()
      }
    }
  });
};
