/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var GridView = require('psa-marionette/views/GridView');

var Config = require('../../../config/Config');

var MultiButtonCell = require('common/backgrid/MultiButtonCell');
var MomentCell = require('common/backgrid/MomentCell');

var UIUtil = require('psa-core/util/ui');
var NavUtils = require('psa-core/util/nav');
var Radio = require('backbone.radio');
var NonDepCorrespondenceModalView = require('../../correspondence/views/NonDepCorrespondenceModalView');
var RetrieveApplicationFromApplicant = require('../../common/services/RetrieveApplicationFromApplicant');
var GetEnableRetrieveApplicationFromApplicantDate = require('../../common/services/GetEnableRetrieveApplicationFromApplicantDate');
var userHasRole = require('common/util/userHasRole');

module.exports = GridView.extend({
  className: 'non-dep-entity-internal-pending-task-view',

  options: {
    columns: [
      {
        name: 'typeName',
        label: 'Action Needed',
        cell: 'string',
        headerCell: 'custom',
        width: 20,
        editable: false
      },
      {
        name: 'applicationType',
        label: 'Application Type',
        cell: 'string',
        headerCell: 'custom',
        width: 20,
        editable: false
      },
      {
        name: 'licenseType',
        label: 'License Type',
        cell: 'string',
        headerCell: 'custom',
        width: 20,
        editable: false
      },
      {
        name: 'applicationId',
        label: 'APP ID',
        cell: 'string',
        headerCell: 'custom',
        width: 10,
        editable: false
      },
      {
        name: 'licenseNumber',
        label: 'License Number',
        cell: 'string',
        headerCell: 'custom',
        width: 10,
        editable: false
      },
      {
        name: 'deliveryDate',
        label: 'Assigned On',
        headerCell: 'custom',
        width: 10,
        cell: MomentCell.MomentCell,
        formatter: MomentCell.MomentDateTimeFormatter.extend({
          emptyValue: '--'
        }),
        sortValue: MomentCell.MomentSortValue,
        editable: false
      },
      {
        name: 'assignee',
        label: 'Assigned To',
        cell: 'string',
        headerCell: 'custom',
        width: 20,
        editable: false
      },
      {
        name: 'correspondenceCreatedBy',
        label: 'Correspondence Inititator',
        cell: 'string',
        headerCell: 'custom',
        width: 10,
        editable: false
      },
      {
        name: '',
        cell: MultiButtonCell.extend({
          buttons: [
            {
              name: '',
              labelClass: 'fa fa-edit',
              buttonClass: 'btn-primary btn-sm view-correspondence-details',
              renderable: function(model) {
                var isCorrespondence = !model.get('correspondenceId');
                return !isCorrespondence;
              },
              onClick: function viewCorrespondence(e, model) {
                e.preventDefault();
                UIUtil.showModal({
                  title: 'Correspondence Details',

                  view: new NonDepCorrespondenceModalView({ model: model })
                });
              }
            }
          ]
        }),
        editable: false,
        sortable: false,
        headerCell: 'custom',
        width: 5
      },
      {
        name: '',
        label: '',
        cell: MultiButtonCell.extend({
          buttons: [
            {
              name: 'Retrieve',
              buttonClass: 'btn-primary btn-sm retrieve-application-from-entity',
              renderable: function(model) {
                var assigneeId = model.get('assigneeId');
                var applicationCreatedDate = model.get('applicationCreatedDate');
                var enabledFromDate = model.get('enableRetrieveApplicationFromApplicantDate');
                var isExternalTask = assigneeId === Config.getExternalUserServiceAccountUserId();
                var applicationStatusID = model.get('applicationStatusID');
                var isReturnedToApplicant = applicationStatusID === '3';
                var isApplicationCreatedDateGreaterThanEnableFromDate = applicationCreatedDate >= enabledFromDate;
                var isManager = userHasRole('ND - Manager');
                return (
                  isExternalTask &&
                  isReturnedToApplicant &&
                  isApplicationCreatedDateGreaterThanEnableFromDate &&
                  isManager
                );
              },
              onClick: function(e, model) {
                RetrieveApplicationFromApplicant({
                  reviewObjectType: 'APPLICATION',
                  reviewObjectId: model.get('applicationId'),
                  taskId: model.get('taskId')
                }).done(function() {
                  Radio.channel('navigate').trigger('show:int:nd:entity-dashboard', model.get('entityId'));
                });
              }
            },
            {
              name: 'Open',
              buttonClass: 'btn-primary btn-sm',
              renderable: function(model) {
                var assigneeId = model.get('assigneeId');
                var isExternalTask = assigneeId === Config.getExternalUserServiceAccountUserId();
                return !isExternalTask;
              },
              href: function(model) {
                return '#nondep/task/' + model.get('taskId');
              }
            }
          ]
        }),
        editable: false,
        sortable: false,
        headerCell: 'custom',
        width: 15
      }
    ],
    emptyText: 'No Pending Tasks'
  }
});
