/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var Marionette = require('backbone.marionette');
var Syphon = require('backbone.syphon');
var moment = require('moment');
var Ladda = require('ladda');
var Radio = require('backbone.radio');

var Session = require('psa-core/Session');

var SubmitComplianceExaminerReviewAnnualReportTask = require('../../services/SubmitComplianceExaminerReviewAnnualReportTask');

var tmplComplianceExaminerReviewAnnualReportWorkTabView = require('./ComplianceExaminerReviewAnnualReportWorkTabView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplComplianceExaminerReviewAnnualReportWorkTabView,

  className: 'compliance-examiner-review-annual-report-work-tab-view container-fluid',

  ui: {
    reviewForm: '.review-form',
    reviewDecision: '.review-decision',
    externalReviewCommentsContainer: '.external-review-comments-container',
    externalReviewComments: '[name="externalReviewComments"]',
    submit: '.submit'
  },

  events: {
    'change @ui.reviewDecision': 'onChangeReviewDecision',
    'click @ui.submit': 'onClickSubmit'
  },

  onBeforeShow: function() {
    var applicationDetails = this.model.get('applicationDetails');
    if (applicationDetails.externalReviewComments) {
      this.ui.externalReviewComments.val('\n\n' + applicationDetails.externalReviewComments);
    }
  },

  onChangeReviewDecision: function() {
    // Return to Entity
    var isReturning = this.ui.reviewDecision.val() === '1601';
    if (isReturning) {
      this.ui.externalReviewCommentsContainer.show();
    } else {
      this.ui.externalReviewCommentsContainer.hide();
    }
  },

  onClickSubmit: function(e) {
    e.preventDefault();
    var self = this;
    var reviewDecisionId = this.ui.reviewDecision.val();
    var parsleyGroups = {
      '1601': 'returning',
      '1602': 'approved'
    };
    var parsleyGroup = parsleyGroups[reviewDecisionId] ? parsleyGroups[reviewDecisionId] : 'none';
    var isReturning = reviewDecisionId === '1601';
    this.ui.reviewForm
      .parsley()
      .whenValidate({ group: parsleyGroup })
      .done(function() {
        var l = Ladda.create(self.ui.submit[0]);
        l.start();
        var formData = Syphon.serialize(self);
        _.extend(formData, {
          taskId: self.model.get('taskId'),
          applicationId: self.model.get('applicationId')
        });
        if (isReturning) {
          var timestamp = moment().format('MM/DD/YYYY h:mm A');
          var userName = Session.user.description;
          formData.externalReviewComments =
            '[' + userName + ' - ' + timestamp + '] \n' + formData.externalReviewComments;
        }
        SubmitComplianceExaminerReviewAnnualReportTask(formData)
          .done(function() {
            Radio.channel('navigate').trigger('show:int:nd:dashboard');
          })
          .fail(function() {
            l.stop();
          });
      });
  }
});
