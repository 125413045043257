/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');

module.exports = function(data) {
  var generalDetails = data.generalDetails;
  var productionReport = data.productionReport;
  var financialStatements = data.financialStatements || [];
  var affirmation = data.affirmation;

  return $.soap({
    body: {
      SubmitConsumerDiscountAnnualReport: {
        $xmlns: 'http://dobs.pa.gov/nd/annualreport/bpm/1.0',
        AnnualReportID: data.annualReportId,
        ApplicationID: data.applicationId,
        ContactFullName: generalDetails.contactFullName,
        ContactPhoneNumber: generalDetails.contactPhoneNumber,
        ContactEmail: generalDetails.contactEmail,
        IsOperatingInAllStates: generalDetails.isOperatingInAllStates ? '1' : '0',
        IsCertified: affirmation.isCertified ? '1' : '0',
        CertificationName: affirmation.certificationName,
        // The operating states are stored as a JSON array
        OperatingStateCodes: (function() {
          var operatingStateCodes = generalDetails.operatingStateCodes || [];
          if (operatingStateCodes.length) {
            return JSON.stringify(operatingStateCodes);
          }
        })(),
        LoanReceivablesHeldNotAvailable: productionReport.loanReceivablesHeldNotAvailable ? '1' : '0',
        LoanReceivablesHeldNumber: productionReport.loanReceivablesHeldNumber,
        LoanReceivablesHeldDollars: productionReport.loanReceivablesHeldDollars,
        LoanReceivablesHeldExplanation: productionReport.loanReceivablesHeldExplanation,
        LoansNotAvailable: productionReport.loansNotAvailable ? '1' : '0',
        LoansNumber: productionReport.loansNumber,
        LoansDollars: productionReport.loansDollars,
        LoansExplanation: productionReport.loansExplanation,
        RepossessionNotAvailable: productionReport.repossessionNotAvailable ? '1' : '0',
        RepossessionNumber: productionReport.repossessionNumber,
        RepossessionDollars: productionReport.repossessionDollars,
        RepossessionExplanation: productionReport.repossessionExplanation,
        HasServicedUnownedPAMortgageLoans: productionReport.hasServicedUnownedPAMortgageLoans,
        HasServicedUnownedPAMortgageLoansExplaination: productionReport.hasServicedUnownedPAMortgageLoansExplaination,
        FinancialStatements: (function() {
          if (!financialStatements.length) {
            return {};
          }
          return {
            FinancialStatement: _.map(financialStatements, function(financialStatement) {
              return {
                DocumentID: financialStatement.fileId
              };
            })
          };
        })()
      }
    }
  });
};
