/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Backbone = require('backbone');
var Marionette = require('backbone.marionette');
var Radio = require('backbone.radio');
var _ = require('underscore');

var UIUtil = require('psa-core/util/ui');

var modelGet = require('common/util/modelGet');
var userHasRole = require('common/util/userHasRole');
var ContentServerLinkView = require('common/views/ContentServerLinkView');
var ConfirmationModalView = require('common/views/ConfirmationModalView');
var SideNavSectionView = require('common/views/SideNavSectionView');
var BreadcrumbView = require('common/views/BreadcrumbView');
var DropdownView = require('common/views/DropdownView');

var InterruptFilingProcess = require('../../common/services/InterruptFilingProcess');
var SubmitFiling = require('../services/SubmitFiling');
var DepFilingMainView = require('./DepFilingMainView');
var DepFilingConditionsView = require('./DepFilingConditionsView');
var DepFilingProcessingView = require('./DepFilingProcessingView');
var DepFilingAdditionalDetailsView = require('./DepFilingAdditionalDetailsView');
var DepEntityWithdrawFilingModalView = require('../../entity-dashboard/views/DepEntityWithdrawFilingModalView');
var DepFilingDetailsView = require('../views/DepFilingDetailsView');

var tmplDepFilingDashboardView = require('../templates/DepFilingDashboardView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplDepFilingDashboardView,

  className: 'dep-filing-dashboard-view container-fluid',

  ui: {
    headerBar: '.header-bar-container',
    breadcrumb: '.breadcrumb-container',
    filingActionDropDown: '.filing-action-drop-down',
    filingDashboard: '.filing-dashboard-container',
    csLinkContainer: '.cs-link-container'
  },

  regions: {
    headerBar: '@ui.headerBar',
    breadcrumb: '@ui.breadcrumb',
    filingActionDropDown: '@ui.filingActionDropDown',
    filingDashboard: '@ui.filingDashboard',
    csLinkContainer: '@ui.csLinkContainer'
  },

  onBeforeShow: function() {
    var filing = this.model.get('filing') || {};
    var filingId = filing.filingId;
    var entityId = filing.entityId;
    var filingType = filing.filingType;
    var recordStatusId = filing.recordStatusId;
    var entityName = modelGet(this.model, 'entityIdentifiers.entityName');
    var categoryId = modelGet(this.model, 'entityIdentifiers.categoryId');
    var workflowStatusId = filing.workflowStatusId;
    var csFolderId = filing.csFolderId || this.model.get('entityFilingsCSFolderId');
    var isAA1 = categoryId === '1' && userHasRole('DP - AA1');
    //Check with DEP Trust and CU
    var isAOTrust = categoryId === '2' && userHasRole('DP - AO - Trust');
    var isAOCU = categoryId === '3' && userHasRole('DP - AO - Credit Union');
    var returnedToApplicant = workflowStatusId === '2';
    var isDirectorCU = categoryId === '2' && userHasRole('DP - Director - Credit Union');
    var isDirectorTrust = categoryId === '3' && userHasRole('DP - Director - Trust');
    var isDirectorBank = categoryId === '1' && userHasRole('DP - Director - Bank');

    this.showChildView(
      'csLinkContainer',
      new ContentServerLinkView({
        model: new Backbone.Model({
          buttonText: 'Open Filing Folder',
          contentServerId: csFolderId
        })
      })
    );

    this.showChildView(
      'breadcrumb',
      new BreadcrumbView({
        breadcrumbs: [
          {
            link: '#dep/dashboard',
            text: 'Home'
          },
          {
            link: '#dep/entity/' + entityId + '/dashboard',
            text: entityName
          },
          {
            text: filingType + ' Filing'
          }
        ]
      })
    );

    var dropDownOptions = [
      {
        text: 'Edit Filing',
        link: '#dep/entity/' + entityId + '/filing/' + filingId + '/edit'
      }
    ];

    if (isDirectorCU || isAOCU || isAOTrust || isDirectorBank || isDirectorTrust || isAA1) {
      dropDownOptions.push({
        text: 'Delete Filing',
        onClick: this.onClickDelete.bind(this)
      });
    }

    if (_.includes(['2', '3', '4'], recordStatusId)) {
      dropDownOptions.push({
        text: 'Withdraw Filing',
        onClick: this.onClickWithdrawFiling.bind(this)
      });
    }

    dropDownOptions.push({ isDivider: true });
    dropDownOptions.push({
      text: 'Manage Filer',
      link: '#dep/entity/' + entityId + '/filing/' + filingId + '/manage-lawyer'
    });

    if (returnedToApplicant && (isAA1 || isAOTrust || isAOCU)) {
      dropDownOptions.push({
        text: 'Skip Request for More Information',
        onClick: this.onClickSkip.bind(this)
      });
    }

    var sideNavTabs = [
      {
        id: 'main',
        title: 'Main',
        view: new DepFilingMainView({
          model: this.model
        })
      },
      {
        id: 'processing',
        title: 'Processing',
        view: new DepFilingProcessingView({
          model: this.model
        })
      },
      {
        id: 'filingDetails',
        title: 'Filing Details',
        view: new DepFilingDetailsView({
          model: new Backbone.Model({
            filingId: filingId
          }),
          filingDetailsView: this.model.get('filingDetailsView')
        })
      },
      {
        id: 'notes',
        title: 'Notes',
        view: Radio.channel('dp').request('build:notes-view', {
          objectId: filingId,
          objectTypeId: 'FILING'
        })
      },
      {
        id: 'auditLog',
        title: 'Audit Log',
        view: Radio.channel('dp').request('build:audit-log-view', {
          objectId: filingId,
          objectTypeId: 'FILING'
        })
      }
    ];

    if (modelGet(this.model, 'validFields.conditionList')) {
      sideNavTabs.splice(2, 0, {
        id: 'conditions',
        title: 'Conditions',
        view: new DepFilingConditionsView({
          model: new Backbone.Model({ conditions: filing.conditions })
        })
      });

      dropDownOptions = dropDownOptions.concat([
        {
          isDivider: true
        },
        {
          text: 'Add Condition',
          link: '#dep/entity/' + entityId + '/filing/' + filingId + '/new-condition'
        }
      ]);
    }

    if (modelGet(this.model, 'validFields.additionalDetails')) {
      sideNavTabs.splice(2, 0, {
        id: 'additionalDetails',
        title: 'Additional Details',
        view: new DepFilingAdditionalDetailsView({
          model: this.model
        })
      });
    }

    this.showChildView(
      'filingActionDropDown',
      new DropdownView({
        rightAlign: true,
        model: new Backbone.Model({
          name: 'Filing Actions',
          options: dropDownOptions
        })
      })
    );

    this.dashboard = new SideNavSectionView({
      collection: new Backbone.Collection(sideNavTabs)
    });

    this.showChildView('filingDashboard', this.dashboard);
  },

  onClickDelete: function() {
    var self = this;
    UIUtil.showModal({
      title: 'Confirm Deleting Filing',
      view: new ConfirmationModalView({
        confirmText: 'Delete Filing',
        model: new Backbone.Model({
          message: 'Are you sure you want to delete this filing?',
          onConfirm: function() {
            return SubmitFiling({
              filingId: modelGet(self.model, 'filing.filingId'),
              isDeleted: 1
            });
          },
          onConfirmDone: function() {
            Radio.channel('navigate').trigger('show:int:dp:entity', modelGet(self.model, 'filing.entityId'));
          }
        })
      })
    });
  },

  onClickWithdrawFiling: function() {
    var self = this;
    var filing = self.model.get('filing');
    UIUtil.showModal({
      title: 'Confirm Withdrawing Filing',
      view: new DepEntityWithdrawFilingModalView({ model: new Backbone.Model(filing) }),
      dialogClasses: 'modal-dialog-withdraw-filing-modal-view modal-lg'
    }).done(function() {
      Radio.channel('navigate').trigger('show:int:dp:entity', modelGet(self.model, 'filing.entityId'));
    });
  },

  onClickSkip: function() {
    var self = this;
    UIUtil.showModal({
      title: 'Skip Request for More Information',
      model: new Backbone.Model({
        message: 'Are you sure you want to Skip Request for More Information?',
        onConfirm: function() {
          return InterruptFilingProcess({
            filingId: modelGet(self.model, 'filing.filingId'),
            skipOrWithdraw: 'SKIP'
          });
        },
        onConfirmDone: function() {
          Radio.channel('navigate').trigger(
            'show:int:dp:filing',
            modelGet(self.model, 'filing.entityId'),
            modelGet(self.model, 'filing.filingId')
          );
        }
      }),
      view: new ConfirmationModalView()
    });
  }
});
