/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var Backbone = require('backbone');
var Marionette = require('backbone.marionette');
var Radio = require('backbone.radio');

var modelGet = require('common/util/modelGet');
var makeUploadCollection = require('common/util/makeUploadCollection');
var BreadcrumbView = require('common/views/BreadcrumbView');
var HeaderBarView = require('common/views/HeaderBarView');
var FileUploadView = require('common/views/FileUploadView');
var GetFilingInvoice = require('../../../common/services/GetFilingInvoice');
var DepFilingPaymentView = require('../../../common/views/DepFilingPaymentView');
var SubmitEntityUpdateFilingTask = require('../../../tasks/services/SubmitEntityUpdateFilingTask');

require('./DepEntitySubmitFilingTaskView.css');
var tmplDepEntitySubmitFilingTaskView = require('./DepEntitySubmitFilingTaskView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplDepEntitySubmitFilingTaskView,

  className: 'dep-entity-submit-filing-task-view container-fluid',

  ui: {
    headerBar: '.header-bar',
    breadcrumb: '.breadcrumb-container',
    filingContainer: '.filing-container',
    acknowledgementLetterContainer: '.acknowledgement-letter-container',
    acknowledgementLetterFile: '.acknowledgement-letter-file',
    reworkCommentsContainer: '.rework-comments-container',
    filingContentContainer: '.filing-content-container',
    paymentContainer: '.payment-container',
    paymentTab: '.payment-tab',
    thePayment: '.the-payment'
  },

  regions: {
    headerBar: '@ui.headerBar',
    breadcrumb: '@ui.breadcrumb',
    acknowledgementLetterFile: '@ui.acknowledgementLetterFile',
    filingContentContainer: '@ui.filingContentContainer',
    paymentContainer: '@ui.paymentContainer',
    thePayment: '@ui.thePayment'
  },

  initialize: function(options) {
    this.filingFormView = options.filingFormView;
  },

  onBeforeShow: function() {
    var self = this;
    var filingId = this.model.get('filingId');
    var taskId = this.model.get('taskId');
    var taskActivity = modelGet(this.model, 'task.activity');
    var filingDetails = this.model.get('filingDetails') || {};
    var entityId = filingDetails.entityId;
    var entityName = filingDetails.entityName;
    var ackLetter = modelGet(this.model, 'filingDetails.acknowledgementLetterDocument', {});
    var hasAcknowledgementLetter = ackLetter.fileId && ackLetter.fileName;
    var hasReworkComments = filingDetails.reworkComments;

    if (taskActivity.includes('Update') || filingDetails.filingFee === 0) {
      $(this.ui.thePayment).css('display', 'none');
    }

    this.showChildView(
      'headerBar',
      new HeaderBarView({
        columns: new Backbone.Collection([
          {
            name: 'entityName',
            label: 'Entity Name',
            minWidth: '160px',
            maxWidth: '300px'
          },
          {
            name: 'entityType',
            label: 'Entity Type',
            minWidth: '160px'
          },
          {
            name: 'filingType',
            label: 'Filing Type',
            minWidth: '160px',
            maxWidth: '350px',
            overflow: 'truncate'
          }
        ]),
        model: new Backbone.Model(filingDetails)
      })
    );

    this.showChildView(
      'breadcrumb',
      new BreadcrumbView({
        breadcrumbs: [
          {
            link: '#ext/dep/dashboard',
            text: 'Home'
          },
          {
            link: '#ext/dep/entity/' + entityId + '/dashboard',
            text: entityName
          },
          {
            text: taskActivity
          }
        ]
      })
    );

    if (hasReworkComments) {
      this.ui.reworkCommentsContainer.show();
    }

    if (hasAcknowledgementLetter) {
      this.ui.acknowledgementLetterContainer.show();
      this.showChildView(
        'acknowledgementLetterFile',
        new FileUploadView({
          collection: makeUploadCollection(ackLetter),
          isReadOnly: true
        })
      );
    }

    this.showChildView('filingContentContainer', this.filingFormView);
    Radio.channel('dp:draft-filing').on('proceed-to-payment', function(l) {
      GetFilingInvoice(filingId)
        .done(function(invoiceItems) {
          var totalPrice = 0;

          _.each(invoiceItems, function(price) {
            totalPrice += price.unitPrice;
          });

          if (totalPrice > 0) {
            self.showChildView(
              'paymentContainer',
              new DepFilingPaymentView({
                viewMode: 'resubmit',
                model: new Backbone.Model({
                  filingId: filingId,
                  taskId: taskId,
                  invoiceItems: invoiceItems
                })
              })
            );
            self.ui.paymentTab.tab('show');
            self.ui.filingContainer.removeClass('active');
            self.ui.paymentContainer.addClass('active');
            l.stop();
          } else {
            var data = {};
            data.taskId = taskId;
            data.filingId = filingId;

            return SubmitEntityUpdateFilingTask(data).done(function() {
              Radio.channel('navigate').trigger('show:ext:dp:dashboard');
            });
          }
        })
        .fail(function() {
          l.stop();
        });
    });
  },

  onDestroy: function() {
    Radio.channel('dp:draft-filing').reset();
  }
});
