/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var NProgress = require('nprogress');

var NavUtils = require('psa-core/util/nav');

var Scaffold = require('common/layout/Scaffold');

var GetAllComplianceViolations = require('../services/GetAllComplianceViolations');
var GetComplianceDetails = require('../../common/services/GetComplianceDetails');
var GetAllComplianceOrderTypes = require('../../tasks/services/GetAllComplianceOrderTypes');

var NonDepInternalComplianceEditGeneralInformationView = require('../views/NonDepInternalComplianceEditGeneralInformationView');

module.exports = function(complianceId) {
  NProgress.start();
  NProgress.set(0.6);

  $.when(GetComplianceDetails(complianceId), GetAllComplianceViolations(), GetAllComplianceOrderTypes())
    .done(function(caseInfo, violationCodes, orderTypes) {
      NavUtils.navigate('nondep/compliance/case/' + complianceId + '/edit/general-information');
      Scaffold.content.show(
        new NonDepInternalComplianceEditGeneralInformationView({
          model: new Backbone.Model({ case: caseInfo, violationCodes: violationCodes, orderTypes: orderTypes })
        })
      );
      NProgress.done();
    });
};
