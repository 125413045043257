/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */
var _ = require('underscore');

var cleanUpResponse = require('common/util/cleanUpResponse');

module.exports = function(filingId) {
  var deferred = $.Deferred();
  $.soap({
    body: {
      GetBankSubsidiaryFiling: {
        $xmlns: 'http://dobs.pa.gov/dp/filing/bpm/1.0',
        FilingID: filingId
      }
    },
    blueprintArrays: [
      'GetBankSubsidiaryFilingResponse.SupportingDocs.DP_FilingSupportingDocument',
      'GetBankSubsidiaryFilingResponse.FDICInterpretiveDocs.DP_FilBankSubsidiaryFDICInterpretiveDocument',
      'GetBankSubsidiaryFilingResponse.AgencyInterpretiveDocs.DP_FilBankSubsidiaryAgencyInterpretiveDocument'
    ],
    blueprint: {
      subsidiaryId: '$..ID',
      filingId: '$..FilingID',
      activityTypeId: '$..ActivityTypeID',
      bankTypeId: '$..BankTypeID',
      bankingCodeId: '$..BankingCodeID',
      isSubsidary: '$..IsSubsidary',
      subsidaryName: '$..SubsidaryName',
      proposedActivity: '$..ProposedActivity',
      authorityId: '$..AuthorityID',
      isCitationUSC: '$..IsCitationUSC',
      citationUSCComments: '$..CitationUSCComments',
      isCitationCFR: '$..IsCitationCFR',
      citationCFRComments: '$..CitationCFRComments',
      isCitationOther: '$..IsCitationOther',
      citationOtherComments: '$..CitationOtherComments',
      isCitationInterpretiveLetter: '$..IsCitationInterpretiveLetter',
      isCitation24FederalDepositAct: '$..IsCitation24FederalDepositAct',
      isCitation24HomeState: '$..IsCitation24HomeState',
      citation24HomeStateComments: '$..Citation24HomeStateComments',
      isCitation24LegalCitations: '$..IsCitation24LegalCitations',
      citation24LegalCitationsComments: '$..Citation24LegalCitationsComments',
      isCitation24InterpretiveLetter: '$..IsCitation24InterpretiveLetter',
      isLegalBasis7: '$..IsLegalBasis7',
      legalBasis7Comments: '$..LegalBasis7Comments',
      isLegalBasisUSC: '$..IsLegalBasisUSC',
      legalBasisUSCComments: '$..LegalBasisUSCComments',
      isLegalBasisCFR: '$..IsLegalBasisCFR',
      legalBasisCFRComments: '$..LegalBasisCFRComments',
      fedRegulatorId: '$..FedRegulatorID',
      ownershipStructureId: '$..OwnershipStructureID',
      riskAnalysisComments: '$..RiskAnalysisComments',
      imposedRestrictionComments: '$..ImposedRestrictionComments',
      contactInformationComments: '$..ContactInformationComments',
      citationInterpretiveLetterComments: '$..CitationInterpretiveLetterComments',
      citation24InterpretiveLetterComments: '$..Citation24InterpretiveLetterComments',

      riskAnalysisDoc: {
        fileId: '$..RiskAnalysisDocID',
        fileName: '$..RiskAnalysisDocName'
      },
      imposedRestrictionsDoc: {
        fileId: '$..ImposedRestrictionsDocID',
        fileName: '$..ImposedRestrictionDocName'
      },
      contactInformationDoc: {
        fileId: '$..ContactInformationDocID',
        fileName: '$..ContactInformationDocName'
      },
      agencyInterpretiveDocs: [
        '$..DP_FilBankSubsidiaryAgencyInterpretiveDocument',
        {
          fileId: '$.DocumentID',
          fileName: '$.FileName'
        }
      ],
      fdicInterpretiveDocs: [
        '$..DP_FilBankSubsidiaryFDICInterpretiveDocument',
        { fileId: '$.DocumentID', fileName: '$.FileName' }
      ],
      supportingDocuments: [
        '$..DP_FilingSupportingDocument',
        { file: { fileId: '$.DocumentID', fileName: '$.DocumentName' } }
      ]
    }
  })
    .done(function(response) {
      cleanUpResponse(response);
      var checkboxes = [
        'isCitationUSC',
        'isCitationCFR',
        'isCitationOther',
        'isCitationInterpretiveLetter',
        'isCitation24FederalDepositAct',
        'isCitation24HomeState',
        'isCitation24LegalCitations',
        'isCitation24InterpretiveLetter',
        'isLegalBasis7',
        'isLegalBasisUSC',
        'isLegalBasisCFR'
      ];
      _.each(checkboxes, function(attr) {
        response[attr] = response[attr] === '1';
      });
      deferred.resolve(response);
    })
    .fail(function(err) {
      deferred.reject();
    });

  return deferred.promise();
};
