/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2016] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _get = require('lodash.get');
var transformer = require('jsonpath-object-transform');
var soapUtils = require('psa-core/util/soap');

var X2JS = soapUtils.getX2JS();

module.exports = {
  soap: function(config) {
    var deferred = $.Deferred();
    var blueprint = config.blueprint || {};
    var preprocessor = config.preprocessor;
    var blueprintArrays = config.blueprintArrays;

    config = soapUtils.configureAjax(X2JS, config);

    $.ajax(config)
      .done(function(data, textStatus, jqXHR) {
        var resolvedData = soapUtils.resolveAjax(
          X2JS,
          { blueprint: blueprint, blueprintArrays: blueprintArrays, preprocessor: preprocessor },
          data
        );
        deferred.resolve(resolvedData, textStatus, jqXHR);
      })
      .fail(function(error, response, jqXHR) {
        if (error.responseText.length) {
          error.responseJSON = X2JS.xml_str2json(error.responseText);

          error.message = transformer(error.responseJSON, {
            code: '$..faultcode.__text',
            string: '$..faultstring.__text',
            detail: '$..FaultDetailString.__text'
          });

          if (!error.message.detail) {
            var details = _get(error, 'responseJSON.Envelope.Body.Fault.detail', {});
            if (details.FaultDetails) {
              delete details.FaultDetails;
            }
            if (!$.isEmptyObject(details)) {
              error.message.object = details;
              error.message.object.xml = X2JS.json2xml_str(details);
            }
          }
        }

        deferred.reject(error, response, jqXHR);
      });

    return deferred;
  }
};
