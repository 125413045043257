/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2020] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Marionette = require('backbone.marionette');
var Syphon = require('backbone.syphon');

var modelGet = require('common/util/modelGet');

var DepFilingSection112IndividualCollectionView = require('./DepFilingSection112IndividualCollectionView');

var tmplDepFilingFormView = require('../templates/DepFilingFormView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplDepFilingFormView,

  className: 'dep-filing-form-view',

  ui: {
    addIndividualName: '.add-individual-name',
    individualNameCollectionContainer: '.individual-name-collection-container',
    datePickerFields: '.date-picker'
  },

  regions: {
    individualNameCollectionContainer: '@ui.individualNameCollectionContainer'
  },

  events: {
    'click @ui.addIndividualName': 'onClickAddIndividualName'
  },

  templateHelpers: function() {
    return {
      isCU: modelGet(this.model, 'entityIdentifiers.categoryId') === '2'
    };
  },

  onBeforeShow: function() {
    var filing = this.model.get('filing') || {};
    Syphon.deserialize(this, filing);
    var validFields = this.model.get('validFields');

    if (validFields.section112AdditionalDetails) {
      this.section112IndividualNames = new DepFilingSection112IndividualCollectionView({
        collection: new Backbone.Collection(filing.section112IndividualNames)
      });
      this.showChildView('individualNameCollectionContainer', this.section112IndividualNames);
    }

    this.ui.datePickerFields.datepicker({});
  },

  onClickAddIndividualName: function(e) {
    e.preventDefault();
    this.section112IndividualNames.collection.add({});
  },

  getFormData: function() {
    var validFields = this.model.get('validFields');
    var formData = Syphon.serialize(this);

    if (validFields.section112AdditionalDetails) {
      formData.section112IndividualNames = this.section112IndividualNames.getFormData();
    }
    return formData;
  }
});
