/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2020] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var Backbone = require('backbone');
var Marionette = require('backbone.marionette');
var Syphon = require('backbone.syphon');
var moment = require('moment');

var GridView = require('psa-marionette/views/GridView');
var PageableCollection = require('psa-backbone/models/PageableCollection');

var ButtonLinkCell = require('common/backgrid/ButtonLinkCell');
var MomentCell = require('common/backgrid/MomentCell');
var SelectFieldView = require('common/views/SelectFieldView');
var GridSpinnerView = require('common/views/GridSpinnerView');
var IconBooleanCell = require('common/backgrid/IconBooleanCell');

var GetComplaintByCaseManager = require('../services/GetComplaintByCaseManager');
var getComplaintEntityType2 = require('../util/getComplaintEntityType2');
var ConSerInternalSidebarNavView = require('./ConSerInternalSidebarNavView');

require('../styles/ConSerInternalDashboardView.css');
var tmplConSerInternalDashboardView = require('../templates/ConSerInternalDashboardView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplConSerInternalDashboardView,

  className: 'con-ser-internal-dashboard-view container-fluid',

  ui: {
    sideBarContainer: '.side-bar-container',
    complaintsContainer: '.complaints-container',
    entityType2Dropdown: '.entity-type-2-dropdown',
    department: '.department', // Entity Type 1 dropdown
    dateOpenedRange: '.date-opened-range',
    filter: '.filter',
    clearFilters: '.clear-filters',
    formInputs: ':input'
  },

  regions: {
    sideBarContainer: '@ui.sideBarContainer',
    complaintsContainer: '@ui.complaintsContainer',
    entityType2Dropdown: '@ui.entityType2Dropdown',
    datePickerFields: '@ui.datePickerFields'
  },

  events: {
    'change @ui.department': 'onChangeDepartment',
    'click @ui.filter': 'onClickFilter',
    'click @ui.clearFilters': 'onClickClearFilters',
    'keydown @ui.formInputs': 'onKeydownOfInputs'
  },

  templateHelpers: function() {
    return {
      isMyComplaints: this.options.isMyComplaints // used for title
    };
  },

  onBeforeShow: function() {
    var self = this;

    this.ui.dateOpenedRange.datepicker();

    this.showChildView('sideBarContainer', new ConSerInternalSidebarNavView());

    this.entityType2Dropdown = new SelectFieldView({
      isDisabled: true,
      isRequired: false,
      name: 'entityType2Id',
      collection: new Backbone.Collection()
    });
    this.showChildView('entityType2Dropdown', this.entityType2Dropdown);

    // initialize empty collection which will be filled when data is returned by
    // service
    this.collection = new Backbone.Collection();
    this.shadowCollection = new PageableCollection([], { pageSize: 15 });

    // define columns which will be shared by grid spinner view and results grid
    var complaintColumns = [
      {
        name: 'caseNumber',
        label: 'Case Number',
        cell: 'string',
        headerCell: 'custom',
        width: 8,
        editable: false
      },
      {
        name: 'entityName',
        label: 'Entity Name',
        cell: 'string',
        headerCell: 'custom',
        width: 16,
        editable: false
      },
      {
        name: 'individualName',
        label: 'Individual Name',
        cell: 'string',
        headerCell: 'custom',
        width: 13,
        editable: false
      },
      {
        name: 'complainantName',
        label: 'Consumer Name',
        cell: 'string',
        headerCell: 'custom',
        width: 13,
        editable: false
      },
      {
        name: 'department',
        label: 'Entity Type 1',
        cell: 'string',
        headerCell: 'custom',
        width: 9,
        editable: false
      },
      {
        name: 'entityType2',
        label: 'Entity Type 2',
        cell: 'string',
        headerCell: 'custom',
        width: 13,
        editable: false
      },
      {
        name: 'weight',
        label: 'Level',
        cell: 'string',
        headerCell: 'custom',
        width: 8,
        editable: false
      },
      {
        name: 'status',
        label: 'Status',
        cell: 'string',
        headerCell: 'custom',
        width: 6,
        editable: false
      },
      {
        name: 'dateOpened',
        label: 'Date Opened',
        cell: MomentCell.MomentCell,
        formatter: MomentCell.MomentDateFormatter.extend({
          emptyValue: '--'
        }),
        sortValue: MomentCell.MomentSortValue,
        headerCell: 'custom',
        width: 8,
        editable: false
      },
      {
        name: 'reminderDate',
        label: 'Reminder Date',
        cell: MomentCell.MomentCell,
        formatter: MomentCell.MomentDateFormatter.extend({
          emptyValue: '--'
        }),
        sortValue: MomentCell.MomentSortValue,
        headerCell: 'custom',
        width: 10,
        editable: false
      },
      {
        name: 'reminderPastDue',
        label: 'Reminder Due',
        cell: IconBooleanCell.extend({
          name: 'reminderPastDue',
          iconClass: 'fa fa-flag fa-lg',
          iconStyle: 'color:red'
        }),
        headerCell: 'custom',
        width: 10,
        editable: false,
        sortable: true
      },
      {
        name: '',
        label: '',
        cell: ButtonLinkCell.extend({
          name: 'Open',
          newTab: true,
          buttonClass: 'btn-primary btn-sm',
          href: function(model) {
            return '#conser/complaint/' + model.get('complaintId') + '/dashboard';
          }
        }),
        headerCell: 'custom',
        width: 8,
        editable: false,
        sortable: false
      }
    ];

    this.complaintList = new GridSpinnerView({
      columns: complaintColumns,
      spinner: {
        scale: 0.75,
        length: 10
      },
      pageable: true
    });

    this.showChildView('complaintsContainer', this.complaintList);

    GetComplaintByCaseManager(this.options.isMyComplaints)
      .done(function(complaints) {
        // Don't do anything if the view has already been destroyed, which would
        // happen if the user navigated away from the page before the service
        // completed.
        if (self.isDestroyed) {
          return;
        }
        // format results with '--' empty indicator
        _.each(complaints, function(complaint) {
          _.each(
            [
              'caseNumber',
              'entityName',
              'individualName',
              'complainantName',
              'department',
              'weight',
              'status',
              'dateOpened',
              'reminderDate'
            ],
            function(attr) {
              if (!complaint[attr]) {
                complaint[attr] = '--';
              }
            }
          );
        });
        complaints = getComplaintEntityType2(
          complaints,
          self.model.get('depEntityTypes'),
          self.model.get('nondepLicenseTypes'),
          self.model.get('secLicenseTypes'),
          self.model.get('conserLicenseTypes')
        );
        self.collection.reset(complaints);
        self.shadowCollection.fullCollection.reset(complaints);
        self.complaintList = new GridView({
          columns: complaintColumns,
          collection: self.shadowCollection,
          emptyText: 'No Complaints'
        });
        self.showChildView('complaintsContainer', self.complaintList);
        self.ui.filter.prop('disabled', false);
        self.ui.clearFilters.prop('disabled', false);
      })
      .fail(function() {
        if (self.isDestroyed) {
          return;
        }
        self.complaintList = new GridView({
          columns: complaintColumns,
          collection: self.shadowCollection,
          emptyText: 'No Complaints'
        });
        self.showChildView('complaintsContainer', self.complaintList);
        self.ui.filter.prop('disabled', false);
        self.ui.clearFilters.prop('disabled', false);
      });
  },

  onChangeDepartment: function() {
    var departmentId = this.ui.department.val();
    var entityType2DropdownOptions = [];
    switch (departmentId) {
      case '1000':
        entityType2DropdownOptions = this.formatDropdown(this.model.get('nondepLicenseTypes'), 'name');
        this.entityType2Dropdown.collection.reset(entityType2DropdownOptions);
        this.entityType2Dropdown.setDisabled(false);
        break;
      case '2000':
        entityType2DropdownOptions = this.formatDropdown(this.model.get('depEntityTypes'), 'typeWithCategory');
        this.entityType2Dropdown.collection.reset(entityType2DropdownOptions);
        this.entityType2Dropdown.setDisabled(false);
        break;
      case '3000':
        entityType2DropdownOptions = this.formatDropdown(this.model.get('secLicenseTypes'), 'type');
        this.entityType2Dropdown.collection.reset(entityType2DropdownOptions);
        this.entityType2Dropdown.setDisabled(false);
        break;
      case '4000':
        entityType2DropdownOptions = this.formatDropdown(this.model.get('conserLicenseTypes'), 'description');
        this.entityType2Dropdown.collection.reset(entityType2DropdownOptions);
        this.entityType2Dropdown.setDisabled(false);
        break;
      default:
        this.entityType2Dropdown.collection.reset(entityType2DropdownOptions);
        this.entityType2Dropdown.setDisabled(true);
        break;
    }
  },

  onKeydownOfInputs: function(e) {
    // Searches on press of enter
    if (e.keyCode === 13) {
      this.onClickFilter(e);
    }
  },

  onClickFilter: function(e) {
    e.preventDefault();
    var self = this;
    var filter = Syphon.serialize(this);
    filter = _.pick(filter, _.identity);
    this.shadowCollection.fullCollection.reset(this.collection.models);
    var models;

    _.each(filter, function(filterVal, filterType, filter) {
      models = self.shadowCollection.fullCollection.filter(function(model) {
        return self.filter(model, filterType, filterVal);
      });
      self.shadowCollection.fullCollection.reset(models);
    });
  },

  onClickClearFilters: function() {
    this.shadowCollection.fullCollection.reset(this.collection.models);

    this.ui.formInputs.val('');
    this.entityType2Dropdown.collection.reset();
    this.entityType2Dropdown.setDisabled(true);
  },

  formatDropdown: function(options, fieldName) {
    return _.map(options, function(option) {
      return { value: option.id, text: option[fieldName] };
    });
  },

  filter: function(shadow, filterType, filterVal) {
    if (_.contains(['departmentId', 'entityType2Id', 'weightId'], filterType)) {
      return shadow.attributes[filterType] === filterVal;
    } else if (filterType === 'initialOpenedDateStart') {
      if (moment.isMoment(shadow.attributes.dateOpened)) {
        return shadow.attributes.dateOpened.isSameOrAfter(moment(filterVal, 'MM-DD-YYYY'), 'day');
      }
    } else if (filterType === 'initialOpenedDateEnd') {
      if (moment.isMoment(shadow.attributes.dateOpened)) {
        return shadow.attributes.dateOpened.isSameOrBefore(moment(filterVal, 'MM-DD-YYYY'), 'day');
      }
    } else {
      var temp = isNaN(shadow.attributes[filterType])
        ? shadow.attributes[filterType].toLowerCase()
        : shadow.attributes[filterType];
      filterVal = isNaN(filterVal) ? filterVal.toLowerCase() : filterVal;
      return typeof temp === 'string' && temp.indexOf(filterVal) > -1;
    }
  }
});
