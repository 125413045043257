/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2020] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _get = require('lodash.get');
var _ = require('underscore');
var Marionette = require('backbone.marionette');
var Ladda = require('ladda');

var GridView = require('psa-marionette/views/GridView');

var MomentCell = require('common/backgrid/MomentCell');
var MultiButtonCell = require('common/backgrid/MultiButtonCell');
var momentUtil = require('common/util/momentUtil');

var GetMrba = require('../../mrba-dashboard/services/GetMrba');
var SubmitMrba = require('../../mrba-dashboard/services/SubmitMrba');

var tmplDepEntityMrbaSectionView = require('../templates/DepEntityMrbaSectionView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplDepEntityMrbaSectionView,

  className: 'dep-mrba-section-view',

  ui: {
    mrbasList: '.mrba-list-container',
    showClosedStatus: ' .show-closed-status',
    isCheckedShowClosed: '.showClosedStatus'
  },

  regions: {
    mrbasList: '@ui.mrbasList'
  },

  events: {
    'click @ui.showClosedStatus': 'onClickShowClosedStatus'
  },

  onBeforeShow: function() {
    var entityTypeId = _get(this.model.get('entityIdentifiers'), 'entityTypeId');

    var gridColumns = [
      {
        name: 'number',
        label: 'Number',
        cell: 'string',
        headerCell: 'custom',
        width: 10,
        editable: false
      },
      {
        name: 'examAsOfDate',
        label: 'Exam As of Date',
        cell: MomentCell.MomentCell,
        formatter: MomentCell.MomentDateFormatter.extend({
          emptyValue: '--'
        }),
        sortValue: MomentCell.MomentSortValue,
        headerCell: 'custom',
        width: 10,
        editable: false
      },
      {
        name: 'category',
        label: 'Category',
        cell: 'string',
        headerCell: 'custom',
        width: 10,
        editable: false
      },
      {
        name: 'dueDate',
        label: 'Due Date',
        cell: MomentCell.MomentCell,
        formatter: MomentCell.MomentDateFormatter.extend({
          emptyValue: '--'
        }),
        sortValue: MomentCell.MomentSortValue,
        headerCell: 'custom',
        width: 10,
        editable: false
      },
      {
        name: 'status',
        label: 'Status',
        cell: 'string',
        headerCell: 'custom',
        width: 10,
        editable: false
      },
      {
        name: 'history',
        label: 'History',
        cell: 'string',
        headerCell: 'custom',
        width: 10,
        editable: false
      },
      {
        name: 'comments',
        label: 'Comment',
        cell: 'string',
        headerCell: 'custom',
        width: 10,
        editable: false
      },
      {
        name: 'copyOpenMultiButtonCell',
        label: '',
        cell: MultiButtonCell.extend({
          buttons: [
            {
              name: 'Open',
              buttonClass: 'btn-primary btn-sm',
              href: function(model) {
                var entityId = model.get('entityId');
                var mrbaId = model.get('mrbaId');
                return '#dep/entity/' + entityId + '/mrba/' + mrbaId + '/dashboard';
              }
            },
            {
              name: '',
              label: '',
              labelClass: 'fa fa-copy',
              buttonClass: 'btn-primary btn-sm',
              onClick: this.onClickDuplicateMRBA.bind(this)
            }
          ]
        }),
        editable: false,
        sortable: false
      }
    ];

    if (_get(this.model.get('validFields'), 'type')) {
      gridColumns.splice(0, 0, {
        name: 'type',
        label: 'Type',
        cell: 'string',
        headerCell: 'custom',
        width: 10,
        editable: false
      });
    }

    if (_get(this.model.get('validFields'), 'agency')) {
      gridColumns.splice(4, 0, {
        name: 'agency',
        label: 'Agency',
        cell: 'string',
        headerCell: 'custom',
        width: 10,
        editable: false
      });
    }

    this.mrbasGrid = new GridView({
      columns: gridColumns,
      emptyText: _.contains(['11', '12', '13', '14'], entityTypeId) ? 'No DORs' : 'No MRBAs',
      collection: this.collection
    });
    this.onClickShowClosedStatus();  
    this.showChildView('mrbasList', this.mrbasGrid);
  },

  onClickShowClosedStatus: function(e) {
    var $checkbox = this.ui.showClosedStatus;
    var includeClosed = $checkbox.prop('checked');
    var mrbas = this.model.get('entityMrbas');
    if (!includeClosed) {
      this.mrbasGrid.collection.fullCollection.reset(
        _.filter(mrbas, function(fil) {
          return fil.status !== 'Closed';
        })
      );
    } else {
      this.mrbasGrid.collection.fullCollection.reset(mrbas);
    }
  },

  onClickDuplicateMRBA: function(e, model) {
    var newMRBAId;
    var self = this;
    var laddaContext = Ladda.create(e.currentTarget);
    laddaContext.start();

    GetMrba(model.get('mrbaId')).done(function(mrba) {
      mrba.mrbaId = newMRBAId;
      var dateAttributes = [
        'examAsOfDate',
        'dueDate',
        'latestResponseDueDate',
        'latestReceivedDate',
        'latestExtensionDate'
      ];
      momentUtil.formatDateAttributes(mrba, dateAttributes, 'MM/DD/YYYY', 'YYYY-MM-DD');

      SubmitMrba(mrba).done(function(newMrbaId) {
        var newModel = model.attributes;
        newModel.mrbaId = newMrbaId;
        self.mrbasGrid.collection.add(newModel);
        laddaContext.stop();
      });
    });
  }
});
