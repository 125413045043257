/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var Backbone = require('backbone');
var Marionette = require('backbone.marionette');

var modelGet = require('common/util/modelGet');
var SideNavSectionView = require('common/views/SideNavSectionView');
var BreadcrumbView = require('common/views/BreadcrumbView');
var DropdownView = require('common/views/DropdownView');
var NMLSLabelView = require('../../common/views/NMLSLabelView');

var NonDepIndividualGeneralInformationSectionView = require('./NonDepIndividualGeneralInformationSectionView');
var NonDepIndividualNotesSectionView = require('./NonDepIndividualNotesSectionView');
var NonDepIndividualAuditLogSectionView = require('./NonDepIndividualAuditLogSectionView');
var ContentServerLinkView = require('common/views/ContentServerLinkView');

var tmplNonDepIndividualDashboardView = require('../templates/NonDepIndividualDashboardView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplNonDepIndividualDashboardView,

  className: 'non-dep-individual-dashboard-view container-fluid',

  ui: {
    nmlsLabelContainer: '.nmls-label-container',
    csLinkContainer: '.cs-link-container',
    individualActionDropdown: '.dropdown-container',
    individualWrapper: '.individual-wrapper',
    breadcrumb: '.breadcrumb-container'
  },

  regions: {
    nmlsLabelContainer: '@ui.nmlsLabelContainer',
    csLinkContainer: '@ui.csLinkContainer',
    individualActionDropdown: '@ui.individualActionDropdown',
    individualWrapper: '@ui.individualWrapper',
    breadcrumb: '@ui.breadcrumb'
  },

  onBeforeShow: function() {
    var self = this;
    var individual = this.model.get('individual'),
      individualType = individual.individualType,
      entityId = this.model.get('entityId'),
      entityName = this.model.get('entityName'),
      licenseId = this.model.get('licenseId'),
      licenseNumber = this.model.get('licenseNumber'),
      licenseType = this.model.get('licenseType'),
      breadcrumbs;

    if (individualType === 'Entity Officer') {
      breadcrumbs = [
        {
          link: '#nondep/dashboard',
          text: 'Home'
        },
        {
          text: entityName,
          link: '#nondep/entity/' + entityId + '/dashboard'
        },
        {
          text: individualType + ' - ' + individual.firstName + ' ' + individual.lastName
        }
      ];
    } else if (individualType === 'Office Manager') {
      breadcrumbs = [
        {
          link: '#nondep/dashboard',
          text: 'Home'
        },
        {
          text: entityName,
          link: '#nondep/entity/' + entityId + '/dashboard'
        },
        {
          text: 'License #' + licenseNumber + ' - ' + licenseType,
          link: '#nondep/entity/' + entityId + '/license/' + licenseId
        },
        {
          text: individualType + ' - ' + individual.firstName + ' ' + individual.lastName
        }
      ];
    } else {
      breadcrumbs = [
        {
          link: '#nondep/dashboard',
          text: 'Home'
        },
        {
          text: individualType + ' - ' + individual.firstName + ' ' + individual.lastName
        }
      ];
    }

    if (individual.isNMLSIndividual) {
      this.showChildView(
        'nmlsLabelContainer',
        new NMLSLabelView({
          model: new Backbone.Model({
            objectName: 'individual'
          })
        })
      );
    }

    // TO DO Update CSFolder ID
    this.showChildView(
      'csLinkContainer',
      new ContentServerLinkView({
        model: new Backbone.Model({
          buttonText: 'Open Individual Folder',
          contentServerId: individual.CSFolderId
        })
      })
    );

    this.individual = new SideNavSectionView({
      useNavigationButtons: false,
      collection: new Backbone.Collection([
        {
          id: 'generalInformation',
          title: 'General Information',
          view: new NonDepIndividualGeneralInformationSectionView({
            model: new Backbone.Model(
              _.extend(individual, {
                entityName: self.model.get('entityName'),
                individualId: self.model.get('individualId'),
                entityId: self.model.get('entityId')
              })
            ),
            isReadOnly: this.options.isReadOnly,
            individualType: modelGet(self.model, 'individual.individualType')
          })
        },
        {
          id: 'notes',
          title: 'Notes',
          view: new NonDepIndividualNotesSectionView({
            model: this.model
          })
        },
        {
          id: 'auditLog',
          title: 'Audit Log',
          view: new NonDepIndividualAuditLogSectionView({
            model: this.model
          })
        }
      ])
    });
    this.showChildView('individualWrapper', this.individual);

    this.showChildView(
      'breadcrumb',
      new BreadcrumbView({
        breadcrumbs: breadcrumbs
      })
    );

    var dropDownOptions = [
      {
        link: '#nondep/individual/' + this.model.get('individualId') + '/edit',
        text: individual.individualType === 'Entity Officer' ? 'Edit Entity Officer' : 'Edit Individual'
      }
    ];

    if (individualType === 'Entity Officer') {
      dropDownOptions = dropDownOptions.concat([
        {
          isDivider: true
        },
        {
          link: '#nondep/individual/' + this.model.get('individualId') + '/initiate-correspondence',
          text: 'Initiate Correspondence'
        }
      ]);
    }

    dropDownOptions = dropDownOptions.concat([
      {
        isDivider: true
      },
      {
        link: '#nondep/individual/' + this.model.get('individualId') + '/history',
        text: 'View Individual History'
      }
    ]);

    this.showChildView(
      'individualActionDropdown',
      new DropdownView({
        rightAlign: true,
        model: new Backbone.Model({
          name: 'Individual Actions',
          options: dropDownOptions
        })
      })
    );
  }
});
