/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2020] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Backbone = require('backbone');
var NProgress = require('nprogress');

var NavUtils = require('psa-core/util/nav');

var Scaffold = require('common/layout/Scaffold');
var GetEntityIdentifiers = require('../../common/services/GetEntityIdentifiers');

var GetDirectorsByEntity = require('../../common/services/GetDirectorsByEntity');
var DepDirectorBulkEditView = require('../views/DepDirectorBulkEditView');

module.exports = function(entityId) {
  NProgress.start();
  NProgress.set(0.6);

  $.when(GetEntityIdentifiers(entityId), GetDirectorsByEntity(entityId))
    .done(function(entityIdentifiers, directors) {
      NProgress.set(0.7);
      NavUtils.navigate('dep/entity/' + entityId + '/bulk-edit-director');
      Scaffold.content.show(
        new DepDirectorBulkEditView({
          model: new Backbone.Model({
            entityIdentifiers: entityIdentifiers,
            directors: directors
          })
        })
      );
      NProgress.done();
    })
    .fail(function() {
      NProgress.done();
    });
};
