/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var cleanUpResponse = require('common/util/cleanUpResponse');

module.exports = function(formData) {
  var defer = $.Deferred();
  var supportingDocuments = formData.supportingDocuments || [];

  $.soap({
    body: {
      SubmitTrustFundAudit: {
        $xmlns: 'http://dobs.pa.gov/dp/fund/bpm/1.0',
        ID: formData.id,
        EntityID: formData.entityId,
        TrustFundID: formData.fundId,
        AuditDate: formData.auditDate,
        ReceivedDate: formData.receivedDate,
        AuditTrustTypeID: formData.auditTrustTypeId,
        OpinionID: formData.opinionId,
        OpinionDetails: formData.opinionDetails,
        AuditFirmID: formData.auditFirmId,
        ShouldDelete: formData.shouldDelete,
        SupportingDocuments: (function() {
          if (!supportingDocuments.length) {
            return {};
          }
          return {
            Document: _.map(supportingDocuments, function(supportingDocument) {
              return {
                DocumentID: supportingDocument.fileId
              };
            })
          };
        })()
      }
    },
    blueprint: {
      id: '$..TrustAuditID'
    }
  })
    .done(function(response) {
      cleanUpResponse(response, { cleanUpTextKeys: true });
      defer.resolve(response.id);
    })
    .fail(function(err) {
      defer.reject(err);
    });

  return defer.promise();
};
