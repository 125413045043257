var Handlebars = require("handlebars/dist/handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression;

  return "\n<h1 class=\"page-title\">"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.exam : depth0)) != null ? stack1.entityName : stack1), depth0))
    + "</h1>\n<div class=\"header-bar\"></div>\n<div class=\"breadcrumb-container\"></div>\n\n<form class=\"form-horizontal update-payment-schedule-form\">\n  <div class=\"form-group\">\n    <label class=\"control-label col-md-3\">Billed Examiner Hours</label>\n    <div class=\"col-md-8\">\n      <div class=\"billed-hours-container\"></div>\n    </div>\n  </div>\n  <div class=\"form-group required\">\n    <label class=\"control-label col-md-3\">Total Payment Due</label>\n    <div class=\"col-md-3\">\n      <div class=\"input-group\">\n        <span class=\"input-group-addon\">$</span>\n        <input type=\"number\" step=\"any\" name=\"totalPaymentDue\" class=\"form-control total-payment-due\"\n          placeholder=\"Amount\" data-parsley-required=\"true\" data-parsley-dollar-amount=\"true\"\n          data-parsley-errors-container=\".amount-error-container\" data-parsley-strict-minimum=\"0\"\n          value=\""
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.paymentSums : depth0)) != null ? stack1.totalAmountDue : stack1), depth0))
    + "\" />\n      </div>\n      <div class=\"amount-error-container\"></div>\n    </div>\n  </div>\n  <div class=\"form-group\">\n    <label class=\"control-label col-md-3\">Potential Fees Incurred</label>\n    <div class=\"col-md-3 form-control-static total-fees-incurred\">"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.paymentSums : depth0)) != null ? stack1.totalFeesIncurred : stack1), depth0))
    + "</div>\n  </div>\n  <div class=\"form-group\">\n    <label class=\"control-label col-md-3 \">Total Payment Made</label>\n    <div class=\"col-md-3 form-control-static total-paid-amount\">"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.paymentSums : depth0)) != null ? stack1.totalPaidAmount : stack1), depth0))
    + "</div>\n  </div>\n  <div class=\"form-group required\">\n    <label class=\"control-label col-md-3\">Payment Plan</label>\n    <div class=\"col-md-8\">\n      <div class=\"payment-plan-container\"></div>\n    </div>\n  </div>\n  <div class=\"form-group\">\n    <div class=\"col-md-8 col-md-offset-3\">\n      <div class=\"alert alert-danger collapse payment-amount-error\" role=\"alert\"><i class=\"fa fa-exclamation-triangle\"\n          aria-hidden=\"true\"></i>Value entered for <strong>Total Payment Due</strong> must equal the sum of all payments\n        in the payment plan. </div>\n    </div>\n  </div>\n</form>\n\n<div class=\"row m-b-lg\">\n  <div class=\"col-md-6 col-md-offset-3\">\n    <a href=\"#nondep/dashboard/exam/"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.exam : depth0)) != null ? stack1.examId : stack1), depth0))
    + "\" class=\"btn btn-default\" role=\"button\">Cancel</a>\n    <button class=\"btn btn-primary submit ladda-button\" data-style=\"expand-right\" type=\"button\">\n      <span class=\"ladda-label\">Save Exam Invoice</span>\n    </button>\n  </div>\n</div>";
},"useData":true});