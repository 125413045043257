/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var cleanUpResponse = require('common/util/cleanUpResponse');

module.exports = function(applicationId) {
  var defer = $.Deferred();

  $.soap({
    body: {
      GetApplication: {
        $xmlns: 'http://dobs.pa.gov/sc/application/bpm/1.0',
        ApplicationID: applicationId
      }
    },
    blueprint: {
      id: '$..ID',
      processInstanceId: '$..ProcessInstanceID',
      csFolderId: '$..CSFolderID',
      csPublicFolderId: '$..CSPublicFolderID',
      organizationId: '$..OrganizationID',
      applicationTypeId: '$..ApplicationTypeID',
      licenseTypeId: '$..LicenseTypeID',
      initiatedStatusDate: '$..InitiatedStatusDate',
      filingExaminerUserId: '$..FilingExaminerUserID',
      filingExaminerDecisionId: '$..FilingExaminerDecisionID',
      recordStatusId: '$..RecordStatusID',
      workflowStatusId: '$..WorkflowStatusID',
      decisionLetterDocId: '$..DecisionLetterDocID',
      comments: '$..Comments',
      isSendNotification: '$..IsSendNotification',
      applicationType: '$..ApplicationType',
      licenseType: '$..LicenseType',
      recordStatus: '$..RecordStatus',
      workflowStatus: '$..WorkflowStatus',
      filingExaminerUserName: '$..FilingExaminerUserName',
      filingExaminerPhone: '$..FilingExaminerPhone',
      orgName: '$..OrgName',
      filingExaminerDecision: '$..FilingExaminerDecision'
    }
  })
    .done(function(response) {
      cleanUpResponse(response);
      defer.resolve(response);
    })
    .fail(function(err) {
      defer.reject(err);
    });

  return defer.promise();
};
