var Handlebars = require("handlebars/dist/handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "    <li>\n      <a class=\"menu-item\" href=\"#dep/dashboard/seg-search\">\n        <i class=\"menu-item-icon fa fa-users fa-fw\"></i>\n        <span class=\"menu-item-label\">SEG Search</span>\n      </a>\n    </li>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "\n<div class=\"on-deck-view\">\n  <p>ON DECK</p>\n  <ul class=\"nav nav-pills nav-stacked\">\n    <li>\n      <a class=\"menu-item\" href=\"#dep/dashboard\">\n        <i class=\"menu-item-icon fa fa-bars fa-fw\"></i>\n        <span class=\"menu-item-label\">My Tasks</span>\n      </a>\n    </li>\n    <li>\n      <a class=\"menu-item\" href=\"#dep/dashboard/external-tasks\">\n        <i class=\"menu-item-icon fa fa-bars fa-fw\"></i>\n        <span class=\"menu-item-label\">External Tasks</span>\n      </a>\n    </li>\n    <li>\n      <a class=\"menu-item\" href=\"#dep/dashboard/all-tasks\">\n        <i class=\"menu-item-icon fa fa-globe fa-fw\"></i>\n        <span class=\"menu-item-label\">All Tasks</span>\n      </a>\n    </li>\n    <li>\n      <a class=\"menu-item\" href=\""
    + alias4(((helper = (helper = helpers.bureauResourceCenterLink || (depth0 != null ? depth0.bureauResourceCenterLink : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"bureauResourceCenterLink","hash":{},"data":data}) : helper)))
    + "\" target=\"_blank\">\n        <i class=\"menu-item-icon fa fa-university fa-fw\"></i>\n        <span class=\"menu-item-label\">Bureau Resource Center</span>\n        <i class=\"fa fa-external-link external-link\"></i>\n      </a>\n    </li>\n  </ul>\n</div>\n<div class=\"menu-view\">\n  <p>MENU</p>\n  <ul class=\"nav nav-pills nav-stacked\">\n    <li>\n      <a class=\"menu-item\" href=\"#dep/dashboard/entity-search\">\n        <i class=\"menu-item-icon fa fa-building fa-fw\"></i>\n        <span class=\"menu-item-label\">Entity Search</span>\n      </a>\n    </li>\n    <li>\n      <a class=\"menu-item\" href=\"#dep/dashboard/filing-search\">\n        <i class=\"menu-item-icon fa fa-file fa-fw\"></i>\n        <span class=\"menu-item-label\">Filings Search</span>\n      </a>\n    </li>\n    <li>\n      <a class=\"menu-item\" href=\"#dep/dashboard/person-search\">\n        <i class=\"menu-item-icon fa fa-user fa-fw\"></i>\n        <span class=\"menu-item-label\">Person Search</span>\n      </a>\n    </li>\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.hasCURole : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "    <li>\n      <a class=\"menu-item\" href=\""
    + alias4(((helper = (helper = helpers.iHubFolderLink || (depth0 != null ? depth0.iHubFolderLink : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"iHubFolderLink","hash":{},"data":data}) : helper)))
    + "\" target=\"_blank\">\n        <i class=\"menu-item-icon fa fa-bar-chart fa-fw\"></i>\n        <span class=\"menu-item-label\">Reports</span>\n        <i class=\"fa fa-external-link external-link\"></i>\n      </a>\n    </li>\n  </ul>\n</div>";
},"useData":true});