/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var moment = require('moment');

module.exports = function(formData) {
  formData.lastLicensingReviewDate = formData.lastLicensingReviewDate
    ? moment(formData.lastLicensingReviewDate, 'MM/DD/YYYY').format('YYYY-MM-DD')
    : '';

  return $.soap({
    body: {
      SubmitOrganization: {
        $xmlns: 'http://dobs.pa.gov/sc/organization/bpm/1.0',
        OrganizationID: formData.organizationId,
        CRDNumber: formData.CRDNumber,
        DBAs: (function() {
          if (!formData.newDBAs || !formData.newDBAs.length) {
            return {};
          } else {
            return {
              DBA: _.map(formData.newDBAs, function(dba) {
                return {
                  DBA: dba.dbaName
                };
              })
            };
          }
        })(),
        ExistingDBAs: (function() {
          if (!formData.deleteDBAs || !formData.deleteDBAs.length) {
            return {};
          }
          return { DBA: formData.deleteDBAs };
        })(),
        NonFINRANumber: formData.nonFINRANumber,
        DBAName: formData.DBAName,
        ApplicantLegalName: formData.applicantLegalName,
        LastLicensingReviewDate: formData.lastLicensingReviewDate,
        LastLicensingReviewBy: formData.lastLicensingReviewBy
      }
    }
  });
};
