/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2020] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var Backbone = require('backbone');
var Marionette = require('backbone.marionette');
var Radio = require('backbone.radio');

var UIUtil = require('psa-core/util/ui');
var GridView = require('psa-marionette/views/GridView');
var PageableCollection = require('psa-backbone/models/PageableCollection');

var modelGet = require('common/util/modelGet');
var ConfirmationModalView = require('common/views/ConfirmationModalView');
var ButtonLinkCell = require('common/backgrid/ButtonLinkCell');
var ParagraphCell = require('common/backgrid/ParagraphCell');
var MomentCell = require('common/backgrid/MomentCell');
var MultiButtonCell = require('common/backgrid/MultiButtonCell');

var SubmitAnnualMeeting = require('../../director-dashboard/services/SubmitAnnualMeeting');
var SubmitBoardAnnualMeetingRequirements = require('../../director-dashboard/services/SubmitBoardAnnualMeetingRequirements');

var tmplDepEntityDirectorsSectionView = require('../templates/DepEntityDirectorsSectionView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplDepEntityDirectorsSectionView,

  className: 'dep-entity-directors-section-view',

  ui: {
    directorsList: '.directors-list-container',
    annualMeetingsList: '.annual-meetings-list-container',
    boardAnnualMeetingsList: '.board-annual-meetings-list-container',
    showInactiveStatus: ' .show-inactive-status',
    isCheckedSHow: '.showActiveStatus'
  },

  regions: {
    directorsList: '@ui.directorsList',
    annualMeetingsList: '@ui.annualMeetingsList',
    boardAnnualMeetingsList: '@ui.boardAnnualMeetingsList'
  },

  events: {
    'click @ui.showInactiveStatus': 'onClickShowInactiveStatus'
  },

  onBeforeShow: function() {
    var isCUTrustHC = _.contains(['11', '21', '22'], modelGet(this.model, 'entityIdentifiers.entityTypeId'));
    var activeDirectors = _.filter(this.model.get('directors'), function(fil) {
      return fil.status === 'Active';
    });

    this.directorsGrid = new GridView({
      columns: [
        {
          name: 'positionHeld',
          label: 'Position Held',
          cell: 'string',
          headerCell: 'custom',
          width: 10,
          editable: false
        },
        {
          name: 'firstName',
          label: 'First Name',
          cell: 'string',
          headerCell: 'custom',
          width: 10,
          editable: false
        },
        {
          name: 'middleInitial',
          label: 'Middle Initial',
          cell: 'string',
          headerCell: 'custom',
          width: 5,
          editable: false
        },
        {
          name: 'lastName',
          label: 'Last Name',
          cell: 'string',
          headerCell: 'custom',
          width: 10,
          editable: false
        },
        {
          name: 'termLength',
          label: 'Term Length',
          cell: 'string',
          headerCell: 'custom',
          width: 10,
          editable: false
        },
        {
          name: 'status',
          label: 'Status',
          cell: 'string',
          headerCell: 'custom',
          width: 10,
          editable: false
        },
        {
          name: 'termStartDate',
          label: 'Term Start',
          cell: MomentCell.MomentCell,
          formatter: MomentCell.MomentDateFormatter.extend({
            emptyValue: '--'
          }),
          sortValue: MomentCell.MomentSortValue,
          headerCell: 'custom',
          width: 10,
          editable: false
        },
        {
          name: 'termEndDate',
          label: 'Term End',
          cell: MomentCell.MomentCell,
          formatter: MomentCell.MomentDateFormatter.extend({
            emptyValue: '--'
          }),
          sortValue: MomentCell.MomentSortValue,
          headerCell: 'custom',
          width: 10,
          editable: false
        },
        {
          name: '',
          label: '',
          cell: ButtonLinkCell.extend({
            name: 'Open',
            buttonClass: 'btn-primary btn-sm',
            href: function(model) {
              var entityId = model.get('entityId');
              var directorId = model.get('directorId');
              return '#dep/entity/' + entityId + '/director/' + directorId + '/dashboard';
            }
          }),
          headerCell: 'custom',
          width: 10,
          editable: false,
          sortable: false
        }
      ],
      emptyText: isCUTrustHC ? 'No Directors' : 'No Director-Trustees',
      collection: new PageableCollection(activeDirectors, { pageSize: 10 })
    });
    this.showChildView('directorsList', this.directorsGrid);

    this.annualMeetingsGrid = new GridView({
      columns: [
        {
          name: 'meetingDate',
          label: 'Meeting Date',
          cell: MomentCell.MomentCell,
          formatter: MomentCell.MomentDateFormatter.extend({
            emptyValue: '--'
          }),
          sortValue: MomentCell.MomentSortValue,
          headerCell: 'custom',
          width: 25,
          editable: false
        },
        {
          name: 'receivedDate',
          label: 'Received Date',
          cell: MomentCell.MomentCell,
          formatter: MomentCell.MomentDateFormatter.extend({
            emptyValue: '--'
          }),
          sortValue: MomentCell.MomentSortValue,
          headerCell: 'custom',
          width: 25,
          editable: false
        },
        {
          name: 'acceptedDate',
          label: 'Accepted Date',
          cell: MomentCell.MomentCell,
          formatter: MomentCell.MomentDateFormatter.extend({
            emptyValue: '--'
          }),
          sortValue: MomentCell.MomentSortValue,
          headerCell: 'custom',
          width: 25,
          editable: false
        },
        {
          name: 'editDeleteMultiButtonCell',
          label: '',
          cell: MultiButtonCell.extend({
            buttons: [
              {
                name: '',
                buttonClass: 'btn-primary btn-sm',
                labelClass: 'fa fa-edit',
                href: function(model) {
                  var entityId = model.get('entityId');
                  var annualMeetingId = model.get('annualMeetingId');
                  return '#dep/entity/' + entityId + '/governance/' + annualMeetingId + '/edit';
                }
              },
              {
                name: '',
                label: '',
                labelClass: 'fa fa-trash',
                buttonClass: 'btn-danger btn-sm ladda-button',
                onClick: this.onClickDeleteAnnualMeeting.bind(this),
                buttonAttrs: {
                  'data-color': '#EEEEEE',
                  'data-spinner-color': '#EEEEEE'
                }
              }
            ]
          }),
          editable: false,
          sortable: false
        }
      ],
      emptyText: 'No Governance',
      collection: new PageableCollection(this.model.get('annualMeetings'), { pageSize: 10 })
    });
    this.showChildView('annualMeetingsList', this.annualMeetingsGrid);

    this.boardAnnualMeetingsGrid = new GridView({
      columns: [
        {
          name: 'annualMeetingDue',
          label: 'Annual Meeting Due',
          cell: 'string',
          headerCell: 'custom',
          width: 25,
          editable: false
        },
        {
          name: 'boardType',
          label: 'Board Type',
          cell: 'string',
          headerCell: 'custom',
          width: 10,
          editable: false
        },
        {
          name: 'terms',
          label: 'Terms',
          cell: 'string',
          headerCell: 'custom',
          width: 10,
          editable: false
        },
        {
          name: 'boardSizeMin',
          label: 'Board Size Min',
          cell: 'string',
          headerCell: 'custom',
          width: 10,
          editable: false
        },
        {
          name: 'boardSizeMax',
          label: 'Board Size Max',
          cell: 'string',
          headerCell: 'custom',
          width: 10,
          editable: false
        },
        {
          name: 'comments',
          label: 'Comments',
          headerCell: 'custom',
          cell: ParagraphCell,
          width: 25,
          editable: false,
          sortable: false
        },
        {
          name: '',
          label: '',
          cell: MultiButtonCell.extend({
            buttons: [
              {
                name: '',
                buttonClass: 'btn-primary btn-sm',
                labelClass: 'fa fa-edit',
                href: function(model) {
                  var entityId = model.get('entityId');
                  var boardAnnualMeetingRequireId = model.get('boardAnnualMeetingRequireId');
                  return (
                    '#dep/entity/' +
                    entityId +
                    '/board-annual-meeting-requirements/' +
                    boardAnnualMeetingRequireId +
                    '/edit'
                  );
                }
              },
              {
                name: '',
                label: '',
                labelClass: 'fa fa-trash',
                buttonClass: 'btn-danger btn-sm ladda-button',
                onClick: this.onClickDeleteBoardAnnualMeetingRequire.bind(this),
                buttonAttrs: {
                  'data-color': '#EEEEEE',
                  'data-spinner-color': '#EEEEEE'
                }
              }
            ]
          }),
          editable: false,
          sortable: false
        }
      ],
      emptyText: 'No Board/Annual Meeting Requirements',
      collection: new PageableCollection(this.model.get('boardAnnualMeetingRequire'), { pageSize: 10 })
    });
    this.showChildView('boardAnnualMeetingsList', this.boardAnnualMeetingsGrid);
  },

  onClickShowInactiveStatus: function(e) {
    var $checkbox = $(e.target);
    var isInactive = $checkbox.prop('checked');

    if (isInactive) {
      this.directorsGrid.collection.fullCollection.reset(
        _.filter(this.model.get('directors'), function(fil) {
          return fil.status === 'Inactive';
        })
      );
    } else {
      this.directorsGrid.collection.fullCollection.reset(
        _.filter(this.model.get('directors'), function(fil) {
          return fil.status === 'Active';
        })
      );
    }
  },

  onClickDeleteAnnualMeeting: function(e, model) {
    e.preventDefault();

    UIUtil.showModal({
      title: 'Confirm Deleting Governance',
      view: new ConfirmationModalView({
        confirmText: 'Delete Governance',
        model: new Backbone.Model({
          message: 'Are you sure you want to delete this governance?',
          onConfirm: function() {
            return SubmitAnnualMeeting({
              annualMeetingId: model.get('annualMeetingId'),
              isDeleted: 1
            });
          },
          onConfirmDone: function() {
            Radio.channel('navigate').trigger('show:int:dp:entity', model.get('entityId'));
          }
        })
      })
    });
  },

  onClickDeleteBoardAnnualMeetingRequire: function(e, model) {
    e.preventDefault();

    UIUtil.showModal({
      title: 'Delete Board/Annual Meeting Requirement',
      view: new ConfirmationModalView({
        confirmText: 'Delete Board/Annual Meeting Requirement',
        model: new Backbone.Model({
          message: 'Are you sure you want to delete this board/annual meeting requirement?',
          onConfirm: function() {
            return SubmitBoardAnnualMeetingRequirements({
              boardAnnualMeetingRequireId: model.get('boardAnnualMeetingRequireId'),
              isDeleted: 1
            });
          },
          onConfirmDone: function() {
            Radio.channel('navigate').trigger('show:int:dp:entity', model.get('entityId'));
          }
        })
      })
    });
  }
});
