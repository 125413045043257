/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Marionette = require('backbone.marionette');
var Radio = require('backbone.radio');
var Ladda = require('ladda');
var _ = require('underscore');
var Syphon = require('backbone.syphon');

var FileUploadCollection = require('psa-backbone/models/FileUploadCollection');
var FileUploadView = require('common/views/FileUploadView');

var LicenseContactsSectionView = require('../../../new-license/views/LicenseContactsSectionView');

var SubmitLicenseContactChangeApplication = require('../../services/SubmitLicenseContactChangeApplication');

var tmplLicenseContactsChangeApplicationView = require('./LicenseContactsChangeApplicationView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplLicenseContactsChangeApplicationView,

  className: 'license-contacts-change-application-view row',

  ui: {
    licenseContactsSectionViewContainer: '.license-contacts-section-view-container',
    affirmationForm: '.affirmation-form',
    applicationDocumentsFileContainer: '.application-documents-container',
    submitButton: '.submit-button',
    isCertified: 'input[name="isCertified"]',
    certificationName: 'input[name="certificationName"]'
  },

  regions: {
    licenseContactsSectionViewContainer: '@ui.licenseContactsSectionViewContainer',
    applicationDocumentsFileContainer: '@ui.applicationDocumentsFileContainer'
  },

  events: {
    'click @ui.submitButton': 'onClickSubmit'
  },

  behaviors: {
    ReadOnlyFormBehavior: {}
  },

  templateHelpers: function() {
    var showAffirmation =
      !this.options.isReadOnly ||
      (this.options.mode === 'print' || this.options.mode === 'int' || this.options.mode === 'int-expand');
    return {
      isReadOnly: this.options.isReadOnly,
      showAffirmation: showAffirmation
    };
  },

  onBeforeShow: function() {
    var self = this;
    var contacts = this.model.get('licenseContacts') || [];
    var deletedContacts = [];
    var contactsToShow = [];
    var showAffirmationData =
      this.options.mode === 'print' || this.options.mode === 'int' || this.options.mode === 'int-expand';
    if (showAffirmationData) {
      this.ui.isCertified.prop('checked', this.model.get('isCertified'));
      this.ui.certificationName.val(this.model.get('certificationName'));
    }
    if (this.options.isReadOnly && this.options.viewMode !== 'ext') {
      // Flag updated and new Contacts to display changes for internal reviewer
      contactsToShow = _.map(contacts, function(contact) {
        if (contact.id && contact.isDeleted !== '1') {
          contact.isUpdate = true;
          var oldContact = _.findWhere(self.model.get('oldContacts'), { id: contact.id });
          contact.oldContact = oldContact;
        } else if (!contact.id) {
          contact.isNew = true;
        }
        return contact;
      });
    } else {
      // Show read only form without changes on the external 'View Application' page
      contactsToShow = _.filter(contacts, function(contact) {
        return contact.isDeleted !== '1';
      });
      // For resubmissions, store previously deleted contacts to send back to internal reviewer with the rest of the form updates
      deletedContacts = _.where(contacts, { isDeleted: '1' });
    }

    this.licenseContactsSectionView = new LicenseContactsSectionView({
      viewMode: this.options.viewMode,
      isReadOnly: this.options.isReadOnly,
      isAmendment: true,
      model: new Backbone.Model({
        licenseContactTypes: this.model.get('licenseContactTypes'),
        requiredContactTypes: this.model.get('requiredLicenseContactTypes'),
        contacts: contactsToShow,
        applicationId: this.model.get('applicationId'),
        existingContacts: this.model.get('existingContacts'),
        deletedContacts: deletedContacts
      })
    });
    this.showChildView('licenseContactsSectionViewContainer', this.licenseContactsSectionView);

    this.applicationDocumentsFile = new FileUploadView({
      isRequired: true,
      allowMultipleFiles: true,
      collection: new FileUploadCollection(this.model.get('applicationDocuments')),
      isReadOnly: this.options.isReadOnly,
      metadata: {
        documentTypeId: 10023
      }
    });
    this.showChildView('applicationDocumentsFileContainer', this.applicationDocumentsFile);
  },

  onClickSubmit: function(e) {
    e.preventDefault();
    var self = this;

    this.validate().done(function() {
      var formData = Syphon.serialize(self);
      var appData = {
        entityId: self.model.get('entityId'),
        licenseId: self.model.get('licenseId'),
        applicationId: self.model.get('applicationId'),
        contacts: self.getFormData(),
        applicationDocuments: self.applicationDocumentsFile.collection.pluck('fileId'),
        isCertified: formData.isCertified,
        certificationName: formData.certificationName
      };
      var laddaContext = Ladda.create(self.ui.submitButton.get(0));
      laddaContext.start();
      SubmitLicenseContactChangeApplication(appData)
        .done(function(response) {
          Radio.channel('nd:submit-application').trigger('submit');
          Radio.channel('nd:submit-application').trigger('resubmit', laddaContext);
        })
        .fail(function(err) {
          laddaContext.stop();
        });
    });
  },

  validate: function() {
    var validationPromises = [this.licenseContactsSectionView.validate({ type: 'submit' })];
    if (!this.options.isReadOnly) {
      validationPromises.push(this.ui.affirmationForm.parsley().whenValidate());
    }
    return $.when.apply($, validationPromises);
  },

  getFormData: function() {
    return _.each(this.licenseContactsSectionView.getFormData(), function(contact) {
      contact.homePhoneNumber = contact.homePhoneNumber === '--' ? undefined : contact.homePhoneNumber;
      contact.cellPhoneNumber = contact.cellPhoneNumber === '--' ? undefined : contact.cellPhoneNumber;
    });
  }
});
