/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Marionette = require('backbone.marionette');
var Syphon = require('backbone.syphon');

var AddressView = require('common/views/AddressView');

require('../styles/NonDepEntityFormLocationView.css');
var tmplNonDepEntityFormLocationView = require('../templates/NonDepEntityFormLocationView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplNonDepEntityFormLocationView,

  className: 'nondep-entity-form-location-view',

  ui: {
    address: '.address',
    primaryPlaceOfBusiness: 'input[name="isPrimaryPlaceOfBusiness"]',
    primaryPlaceOfBusinessNo: 'input[name="isPrimaryPlaceOfBusiness"][value="false"]',
    primaryPlaceOfBusinessYes: 'input[name="isPrimaryPlaceOfBusiness"][value="true"]',
    primaryPlaceOfBusinessLabel: '.primary-place-of-business-label'
  },

  regions: {
    address: '@ui.address'
  },

  behaviors: {
    PhoneFormatBehavior: {}
  },

  events: {
    'change @ui.primaryPlaceOfBusiness': 'onChangePrimaryPlaceOfBusiness'
  },

  onBeforeShow: function() {
    this.showChildView(
      'address',
      new AddressView({
        startingCountry: this.model.get('country'),
        startingPostalCode: this.model.get('postalCode'),
        startingCounty: this.model.get('county'),
        isRequired: true
      })
    );
    //Logic to not allow a user to edit an existing location from HQ to not HQ.
    var isHeadquarters = this.model.get('isPrimaryPlaceOfBusiness');
    if (isHeadquarters) {
      this.ui.primaryPlaceOfBusinessNo.prop('disabled', true);
    }
    Syphon.deserialize(this, this.model.toJSON());
  },

  getFormData: function() {
    return Syphon.serialize(this);
  },

  onChangePrimaryPlaceOfBusiness: function() {
    if (this.isPrimaryPlaceOfBusiness()) {
      this.triggerMethod('selected:primary-place-of-business');
    }
  },

  setPrimaryPlaceOfBusiness: function(val) {
    if (val === false) {
      this.ui.primaryPlaceOfBusinessNo.prop('checked', true);
      this.ui.primaryPlaceOfBusinessYes.prop('checked', false);
    } else {
      this.ui.primaryPlaceOfBusinessNo.prop('checked', false);
      this.ui.primaryPlaceOfBusinessYes.prop('checked', true);
    }
  },

  isPrimaryPlaceOfBusiness: function() {
    var formData = this.getFormData();
    return !!formData.isPrimaryPlaceOfBusiness;
  }
});
