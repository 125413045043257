/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var Backbone = require('backbone');
var Marionette = require('backbone.marionette');
var Syphon = require('backbone.syphon');
var Radio = require('backbone.radio');
var Ladda = require('ladda');

var FileUploadCollection = require('psa-backbone/models/FileUploadCollection');
var FileUploadView = require('common/views/FileUploadView');
var AddressDisplayView = require('common/views/AddressDisplayView');
var modelGet = require('common/util/modelGet');
var addressToString = require('common/util/addressToString');

var SubmitLicenseLocationChangeApplication = require('../../services/SubmitLicenseLocationChangeApplication');

var tmplLicenseAddressChangeFormView = require('./LicenseAddressChangeApplicationView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplLicenseAddressChangeFormView,

  className: 'license-address-change-form-view container-fluid',

  behaviors: {
    ReadOnlyFormBehavior: {}
  },

  ui: {
    changeAddressForm: '.change-license-address-form',
    currentAddressContainer: '.current-address-container',
    newAddressContainer: '.new-address-container',
    effectiveChangeDate: '.effective-change-date',
    locationSelect: '.location-select',
    affirmationForm: '.affirmation-form',
    submitButton: '.submit-button',
    formGroups: '.form-group',
    validationErrorContainers: '.validation-error-container',
    newLocation: '.new-location-form',
    applicationDocumentsFileContainer: '.application-documents-container',
    isCertified: 'input[name="isCertified"]',
    certificationName: 'input[name="certificationName"]'
  },

  regions: {
    currentAddressContainer: '@ui.currentAddressContainer',
    newAddressContainer: '@ui.newAddressContainer',
    applicationDocumentsFileContainer: '@ui.applicationDocumentsFileContainer'
  },

  events: {
    'click @ui.certificateDropdown': 'onClickSelect',
    'click @ui.submitButton': 'onClickSubmit'
  },

  templateHelpers: function() {
    var showAffirmation =
      !this.options.isReadOnly ||
      (this.options.mode === 'print' || this.options.mode === 'int' || this.options.mode === 'int-expand');
    return {
      viewMode: this.options.viewMode,
      isReadOnly: this.options.isReadOnly,
      showAffirmation: showAffirmation
    };
  },

  onBeforeShow: function() {
    var viewMode = this.options.viewMode;
    var showAffirmationData =
      this.options.mode === 'print' || this.options.mode === 'int' || this.options.mode === 'int-expand';
    if (showAffirmationData) {
      this.ui.isCertified.prop('checked', this.model.get('isCertified'));
      this.ui.certificationName.val(this.model.get('certificationName'));
    }
    var currentLicenseAddress = modelGet(this.model, 'licenseDashboard.licenseAddress');

    this.showChildView(
      'currentAddressContainer',
      new AddressDisplayView({
        model: new Backbone.Model(currentLicenseAddress)
      })
    );

    this.ui.effectiveChangeDate.datepicker({
      startDate: '0d'
    });

    if (viewMode === 'int') {
      var newLicenseLocation = _.findWhere(this.model.get('entityLocations'), { id: this.model.get('newLocationId') });

      this.showChildView(
        'newAddressContainer',
        new AddressDisplayView({
          model: new Backbone.Model(newLicenseLocation)
        })
      );
    } else {
      this.ui.locationSelect.select2({
        allowClear: true,
        data: this.model.get('entityLocations'),
        dropdownCssClass: 'select2-options-bordered',
        templateResult: function(location) {
          var addressMarkup = new AddressDisplayView({
            model: new Backbone.Model(location)
          }).render().el.outerHTML;
          if (location.isPrimaryPlaceOfBusiness) {
            addressMarkup += '<span class="label label-primary">Headquarters</span>';
          }
          return $(addressMarkup);
        },
        templateSelection: function(location) {
          if (location.addressLine1) {
            return $('<span>' + location.addressLine1 + '</span>');
          } else {
            return $('<span>--</span>');
          }
        },
        matcher: function(params, data) {
          // If there are no search terms, return all of the data
          if (!params.term || params.term.trim() === '') {
            return data;
          }
          // Reduce address to single string for matching.
          var addressStr = addressToString(data, { separator: ' ' }) || '';
          var searchTerm = params.term.toLowerCase();
          var target = addressStr.toLowerCase();
          if (target.indexOf(searchTerm) > -1) {
            return data;
          }
          // Return `null` if the term should not be displayed
          return null;
        }
      });
    }

    this.applicationDocumentsFile = new FileUploadView({
      isRequired: true,
      allowMultipleFiles: true,
      collection: new FileUploadCollection(this.model.get('applicationDocuments')),
      isReadOnly: this.options.isReadOnly,
      metadata: {
        documentTypeId: 10023
      }
    });
    this.showChildView('applicationDocumentsFileContainer', this.applicationDocumentsFile);
  },

  onClickSubmit: function(event) {
    event.preventDefault();
    this.removeValidationErrors();
    var self = this;
    this.validate().done(
      function() {
        var laddaContext = Ladda.create(this.ui.submitButton.get(0));
        laddaContext.start();
        SubmitLicenseLocationChangeApplication(self.getFormData())
          .done(function() {
            Radio.channel('nd:submit-application').trigger('submit');
            Radio.channel('nd:submit-application').trigger('resubmit', laddaContext);
          })
          .fail(function() {
            laddaContext.stop();
          });
      }.bind(this)
    );
  },

  validate: function() {
    this.ui.changeAddressForm.parsley().reset();
    var validationPromises = [this.ui.changeAddressForm.parsley().whenValidate()];
    if (!this.options.isReadOnly) {
      validationPromises.push(this.ui.affirmationForm.parsley().whenValidate());
    }
    return $.when.apply($, validationPromises);
  },

  removeValidationErrors: function() {
    this.ui.formGroups.removeClass('has-error');
    this.ui.validationErrorContainers.hide();
  },

  getFormData: function() {
    var formData = Syphon.serialize(this);
    formData.entityId = this.model.get('entityId');
    formData.licenseId = this.model.get('licenseId');
    formData.applicationId = this.model.get('applicationId');
    formData.applicationDocuments = this.applicationDocumentsFile.collection.pluck('fileId');
    return formData;
  }
});
