/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var moment = require('moment');

var cleanUpResponse = require('common/util/cleanUpResponse');

module.exports = function(applicationId) {
  var defer = $.Deferred();
  $.soap({
    body: {
      GetCollectorRepossessorApplication: {
        $xmlns: 'http://dobs.pa.gov/nd/application/bpm/1.0',
        ApplicationId: applicationId
      }
    },
    blueprintArrays: [
      'GetCollectorRepossessorApplicationResponse.ApplicationContact.ND_ApplicationContact',
      'GetCollectorRepossessorApplicationResponse.ApplicationDocuments.ND_ApplicationDocument'
    ],
    blueprint: {
      application: {
        id: '$..ND_AppLicenseCollectorRepossessor.ID',
        applicationId: '$..ApplicationID',
        hasCriminalOfficer: '$..HasCriminalOfficer',
        hasCriminalOfficerExplanation: '$..HasCriminalOfficerExplanation',
        hasOfficerOperatedWithoutLicense: '$..HasOfficerOperatedWithoutLicense',
        hasOfficerOperatedWithoutLicenseExplanation: '$..HasOfficerOperatedWithoutLicenseExplanation',
        hasLegalProceedings: '$..HasLegalProceedings',
        certificationName: '$..CertificationName',
        isCertified: '$..IsCertified',
        bondCompanyId: '$..BondCompanyID',
        bondNumber: '$..BondNumber',
        bondDocument: {
          fileId: '$..BondDocumentID',
          fileName: '$..BondDocumentName'
        },
        applicationDocuments: [
          '$..ND_ApplicationDocument',
          {
            fileId: '$.DocumentID',
            fileName: '$..Name'
          }
        ]
      },
      branchManager: {
        firstName: '$..ND_Individual.FirstName',
        lastName: '$..ND_Individual.LastName',
        title: '$..ND_Individual.Title',
        homePhoneNumber: '$..ND_Individual.HomePhoneNumber',
        cellPhoneNumber: '$..ND_Individual.CellPhoneNumber',
        email: '$..ND_Individual.Email',
        ssn: '$..ND_Individual.SSN',
        dob: '$..ND_Individual.DOB',
        addressId: '$..ND_Individual.AddressID',
        addressLine1: '$..ND_Individual.AddressLine1',
        addressLine2: '$..ND_Individual.AddressLine2',
        addressLine3: '$..ND_Individual.AddressLine3',
        county: '$..ND_Individual.County',
        city: '$..ND_Individual.City',
        state: '$..ND_Individual.State',
        postalCode: '$..ND_Individual.PostalCode',
        country: '$..ND_Individual.Country',
        middleName: '$..ND_Individual.MiddleName',
        suffix: '$..ND_Individual.Suffix'
      },
      contacts: [
        '$..ND_ApplicationContact',
        {
          id: '$..ID',
          firstName: '$..FirstName',
          lastName: '$..LastName',
          title: '$..Title',
          homePhoneNumber: '$..HomePhoneNumber',
          cellPhoneNumber: '$..CellPhoneNumber',
          email: '$..Email',
          isDeleted: '$..IsDeleted',
          applicationId: '$..ApplicationID',
          licenseContactTypeId: '$..LicenseContactTypeID',
          addressId: '$..AddressID',
          createdDate: '$..CreatedDate',
          createdBsy: '$..CreatedBy',
          modifiedDate: '$..ModifiedDate',
          modifiedBy: '$..ModifiedBy',
          addressLine1: '$..AddressLine1',
          addressLine2: '$..AddressLine2',
          addressLine3: '$..AddressLine3',
          county: '$..County',
          city: '$..City',
          state: '$..State',
          postalCode: '$..PostalCode',
          country: '$..Country',
          company: '$..Company',
          workPhoneNumber: '$..WorkPhoneNumber',
          workPhoneExtension: '$..WorkPhoneExtension',
          fax: '$..Fax'
        }
      ]
    }
  })
    .done(function(response) {
      cleanUpResponse(response);
      if (response.branchManager.dob) {
        response.branchManager.dob = moment(response.branchManager.dob, 'YYYY-MM-DD').format('MM/DD/YYYY');
      }
      response.isCertified = response.isCertified === '1';
      defer.resolve(response);
    })
    .fail(function(err) {
      defer.reject();
    });

  return defer.promise();
};
