/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Backbone = require('backbone');
var Marionette = require('backbone.marionette');
var Radio = require('backbone.radio');
var Ladda = require('ladda');

var modelGet = require('common/util/modelGet');

var HeaderBarView = require('common/views/HeaderBarView');
var BreadcrumbView = require('common/views/BreadcrumbView');

var NonDepInitiateCorrespondenceFormView = require('../../correspondence/views/NonDepInitiateCorrespondenceFormView');

var SubmitNewCorrespondence = require('../../common/services/SubmitNewCorrespondence');

var tmplNonDepComplianceInitiateCorrespondenceView = require('../templates/NonDepComplianceInitiateCorrespondenceView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplNonDepComplianceInitiateCorrespondenceView,

  className: 'non-dep-compliance-initiate-correspondence-view container-fluid',

  ui: {
    breadcrumb: '.breadcrumb-container',
    headerBar: '.header-bar',
    correspondenceForm: '.correspondence-form-container',
    submit: '.btn-submit'
  },

  regions: {
    headerBar: '@ui.headerBar',
    breadcrumb: '@ui.breadcrumb',
    correspondenceForm: '@ui.correspondenceForm'
  },

  events: {
    'click @ui.submit': 'onClickSubmit'
  },

  onBeforeShow: function() {
    var complianceDetails = this.model.get('complianceDetails') || {};

    var headerData = {
      title: 'Compliance',
      caseNumber: complianceDetails.caseNumber,
      tin: complianceDetails.tin,
      licenseNumber: complianceDetails.licenseNumber
    };

    this.showChildView(
      'headerBar',
      new HeaderBarView({
        title: 'Compliance',
        columns: new Backbone.Collection([
          {
            name: 'caseNumber',
            label: 'Case Number'
          },
          {
            name: 'tin',
            label: 'Federal ID Number'
          },
          {
            name: 'licenseNumber',
            label: 'License Number'
          }
        ]),
        model: new Backbone.Model(headerData)
      })
    );

    var breadcrumbs = [
      {
        link: '#nondep/dashboard',
        text: 'Home'
      },
      {
        text: complianceDetails.entityName,
        link: '#nondep/entity/' + complianceDetails.entityId + '/dashboard'
      },
      {
        text: 'Case #' + complianceDetails.caseNumber
      }
    ];

    if (complianceDetails.licenseId) {
      breadcrumbs.splice(2, 0, {
        link: '#nondep/entity/' + complianceDetails.entityId + '/license/' + complianceDetails.licenseId,
        text: 'License #' + complianceDetails.licenseNumber + ' - ' + complianceDetails.licenseType
      });
    }

    this.showChildView(
      'breadcrumb',
      new BreadcrumbView({
        breadcrumbs: breadcrumbs
      })
    );

    this.correspondenceForm = new NonDepInitiateCorrespondenceFormView({
      model: this.model
    });

    this.showChildView('correspondenceForm', this.correspondenceForm);
  },

  onClickSubmit: function(e) {
    e.preventDefault();
    var self = this;
    var complianceId = modelGet(this.model, 'complianceDetails.complianceId');
    var entityId = modelGet(this.model, 'complianceDetails.entityId');

    this.correspondenceForm.validate().done(function() {
      var l = Ladda.create(self.ui.submit[0]);
      l.start();
      var formData = self.correspondenceForm.getFormData();
      formData.objectId = complianceId;
      formData.objectTypeId = 'COMPLIANCE';
      formData.entityId = entityId;
      formData.isInternal = '1';

      SubmitNewCorrespondence(formData)
        .done(function() {
          Radio.channel('navigate').trigger('show:int:nd:compliance:dashboard', complianceId);
        })
        .fail(function() {
          l.stop();
        });
    });
  }
});
