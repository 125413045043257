var Handlebars = require("handlebars/dist/handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression;

  return "      <option value=\""
    + alias2(alias1(depth0, depth0))
    + "\">"
    + alias2(alias1(depth0, depth0))
    + "</option>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "\n\n<div class=\"page-title clearfix\">\n  <h1 class=\"pull-left m-n p-n\">Time Tracking</h1>\n</div>\n<form class=\"form-inline m-t-md\">\n  <div class=\"form-group\">\n    <label class=\"control-label\">Year</label>\n    <select name=\"year\" class=\"form-control year-select m-l-xs\">\n      <option value=\"\">--</option>\n"
    + ((stack1 = helpers.each.call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.years : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "    </select>\n  </div>\n  <div class=\"form-group m-l-md\">\n    <label class=\"control-label\">Period</label>\n  </div>\n  <div class=\"form-group\">\n    <div class=\"m-l-xs period-select\"></div>\n  </div>\n  <button class=\"btn btn-primary go-to-period m-l-md\" data-dismiss=\"modal\">Go</button>\n</form>\n<div class=\"time-tracking-form m-t-lg\"></div>";
},"useData":true});