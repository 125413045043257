/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Backbone = require('backbone');
var Marionette = require('backbone.marionette');
var _remove = require('lodash.remove');

var HeaderBarView = require('common/views/HeaderBarView');
var HeaderBarLinkCellView = require('common/views/HeaderBarView/HeaderBarLinkCellView');
var BreadcrumbView = require('common/views/BreadcrumbView');
var BigTabsView = require('common/views/BigTabsView');
var ContentServerLinkView = require('common/views/ContentServerLinkView');

var modelGet = require('common/util/modelGet');

var ComplianceExaminerReviewAnnualReportWorkTabView = require('./ComplianceExaminerReviewAnnualReportWorkTabView');

require('./ComplianceExaminerReviewAnnualReportTaskView.css');
var tmplComplianceExaminerReviewAnnualReportTaskView = require('./ComplianceExaminerReviewAnnualReportTaskView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplComplianceExaminerReviewAnnualReportTaskView,

  className: 'compliance-examiner-review-annual-report-task-view container-fluid',

  ui: {
    csLinkContainer: '.cs-link-container',
    headerBar: '.header-bar',
    breadcrumb: '.breadcrumb-container',
    taskSections: '.task-sections'
  },

  regions: {
    csLinkContainer: '@ui.csLinkContainer',
    headerBar: '@ui.headerBar',
    breadcrumb: '@ui.breadcrumb',
    taskSections: '@ui.taskSections'
  },

  behaviors: {
    TaskBehavior: {}
  },

  onBeforeShow: function() {
    var application = this.model.get('applicationDetails') || {};

    this.showChildView(
      'csLinkContainer',
      new ContentServerLinkView({
        model: new Backbone.Model({
          buttonText: 'Open Application Folder',
          contentServerId: application.CSFolderId
        })
      })
    );

    var headerBarColumns = [
      {
        name: 'entityName',
        label: 'Entity Name',
        minWidth: '160px',
        maxWidth: '300px'
      },
      {
        name: 'tin',
        label: 'Federal ID Number',
        minWidth: '160px'
      },
      {
        name: 'applicationStatus',
        label: 'Application Status',
        minWidth: '160px',
        maxWidth: '200px',
        overflow: 'truncate'
      },
      {
        name: 'locationName',
        label: 'Location',
        minWidth: '160px',
        maxWidth: '350px',
        overflow: 'truncate'
      },
      {
        name: 'webAddress',
        label: 'Web Address',
        width: '350px',
        overflow: 'truncate',
        headerCell: HeaderBarLinkCellView
      }
    ];

    // If the application does not contain details about a specific location,
    // remove that column
    if (!this.model.get('applicationDetails').hasLocation) {
      _remove(headerBarColumns, function(headerBarColumns) {
        return headerBarColumns.name === 'locationName';
      });
    }

    this.showChildView(
      'headerBar',
      new HeaderBarView({
        columns: new Backbone.Collection(headerBarColumns),
        model: new Backbone.Model(this.model.get('applicationDetails'))
      })
    );

    var applicationTypeDescription = modelGet(this.model, 'applicationDetails.applicationTypeDescription');
    var licenseType = modelGet(this.model, 'applicationDetails.licenseType');

    this.showChildView(
      'breadcrumb',
      new BreadcrumbView({
        breadcrumbs: [
          {
            link: '#nondep/dashboard',
            text: 'Home'
          },
          {
            text: 'Review ' + applicationTypeDescription + ' for ' + licenseType
          }
        ]
      })
    );

    var taskSections = [
      {
        id: 'application',
        title: 'Annual Report',
        subtitle: 'Contents of Annual Report',
        faIconClass: 'fa-envelope',
        view: this.options.applicationFormView
      },
      {
        id: 'work',
        title: 'Work',
        subtitle: 'Review the Annual Report',
        faIconClass: 'fa-pencil',
        view: new ComplianceExaminerReviewAnnualReportWorkTabView({
          model: this.model
        })
      }
    ];

    this.showChildView(
      'taskSections',
      new BigTabsView({
        collection: new Backbone.Collection(taskSections)
      })
    );
  }
});
