/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Marionette = require('backbone.marionette');

require('./ElasticSearchSummaryView.css');
var tmplElasticSearchSummaryView = require('./ElasticSearchSummaryView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplElasticSearchSummaryView,

  className: 'elastic-search-summary-view',

  ui: {
    searchResultSummary: '.search-result-summary',
    searchResultsMax: '.search-result-max',
    hitCount: '.hit-count',
    searchTime: '.search-time',
    refineSeachTip: '.refine-seach-tip'
  },

  onBeforeShow: function() {
    this.ui.refineSeachTip.tooltip();
  },

  reset: function() {
    this.ui.searchResultSummary.addClass('collapse');
    this.ui.searchResultsMax.addClass('collapse');
  },

  display: function(data) {
    var searchLimitStr = this.model.get('searchLimit');
    var searchLimit = searchLimitStr ? parseInt(searchLimitStr) : 100;
    this.ui.hitCount.text(data.totalHits);
    this.ui.searchTime.text(parseFloat(parseFloat(data.took) / 1000).toFixed(2));
    this.ui.searchResultSummary.removeClass('collapse');
    if (data.totalHits > searchLimit) {
      this.ui.searchResultsMax.removeClass('collapse');
    }
  }
});
