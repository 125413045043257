/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Backbone = require('backbone');

var PlaceholderView = require('common/views/PlaceholderView');

var GetRicsCompliance = require('../../entity-dashboard/services/GetRicsCompliance');

var NonDepRicsComplianceView = require('../../entity-dashboard/views/NonDepRicsComplianceView');

module.exports = {
  channel: 'nd',

  event: 'build:application-rics-compliance-view',

  /**
   * @param config Defines how the view should be built
   * @param config.applicationId The entityId of the entity
   * @param config.tin The tin of the entity
   */
  builder: function(config) {
    return new PlaceholderView({
      viewPromise: buildEntityRicsComplianceView(config)
    });
  }
};

function buildEntityRicsComplianceView(config) {
  var deferred = $.Deferred();
  GetRicsCompliance(config.tin)
    .done(function(ricsCompliance) {
      deferred.resolve(
        new NonDepRicsComplianceView({
          viewMode: config.viewMode,
          model: new Backbone.Model({
            ricsCompliance: ricsCompliance
          })
        })
      );
    })
    .fail(function(err) {
      deferred.reject(err);
    });
  return deferred.promise();
}
