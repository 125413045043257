/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Backbone = require('backbone');

var EntityOfficerNewApplication = require('../forms/EntityOfficerNewApplication');

var GetAllEntityOfficerTypes = require('../../entity-form/services/GetAllEntityOfficerTypes');
var GetEntityOfficerChangeApplication = require('../services/GetEntityOfficerChangeApplication');
var GetEntityDetails = require('../../entity-form/services/GetEntityDetails');

module.exports = {
  event: 'build:amendment:entity-officer-new',

  channel: 'nd:application',

  builder: function(config) {
    var defer = $.Deferred();
    $.when(GetAllEntityOfficerTypes(), GetEntityOfficerChangeApplication(config.applicationId)).done(function(
      officerTypes,
      officer
    ) {
      GetEntityDetails(officer.entityId).done(function(entityDetails) {
        defer.resolve(
          new EntityOfficerNewApplication({
            model: new Backbone.Model({
              officer: officer,
              officerTypes: officerTypes,
              applicationId: officer.applicationId,
              entityId: officer.entityId,
              isOfficerConvicted: officer.isOfficerConvicted,
              isOfficerConvictedDocument: officer.isOfficerConvictedDocument,
              isCertified: officer.isCertified,
              certificationName: officer.certificationName,
              oldIsOfficerConvicted: entityDetails.isOfficerConvicted,
              oldIsOfficerConvictedDocument: entityDetails.isOfficerConvictedDocument
            }),
            isReadOnly: config.isReadOnly,
            viewMode: config.viewMode,
            isAmendment: true,
            mode: config.mode
          })
        );
      });
    });
    return defer.promise();
  }
};
