/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var cleanUpResponse = require('common/util/cleanUpResponse');
var momentUtil = require('common/util/momentUtil');

module.exports = function(tin) {
  var deferred = $.Deferred();

  $.soap({
    body: {
      GetRicsCompliance: {
        $xmlns: 'http://dobs.pa.gov/nd/rics/bpm/1.0',
        TIN: tin
      }
    },
    blueprint: {
      ricsComplianceId: '$..ID',
      tin: '$..TIN',
      caseNumber: '$..CaseNumber',
      clearanceDecisionCode: '$..ClearanceDecisionCode',
      clearanceDecision: '$..ClearanceDecision',
      clearanceDecisionDate: '$..ClearanceDecisionDate',
      caseStatusCode: '$..CaseStatusCode',
      caseStatus: '$..CaseStatus',
      caseStatusReasonCode: '$..CaseStatusReasonCode',
      caseStatusReason: '$..CaseStatusReason',
      errorDescription: '$..ErrorDescription',
      latestRequestDate: '$..LatestRequestDate',
      latestAgencyTrackingNumber: '$..LatestAgencyTrackingNumber'
    }
  })
    .done(function(response) {
      cleanUpResponse(response);
      response.clearanceDecisionDate = momentUtil.formatStr(response.clearanceDecisionDate, {
        sourceFormat: 'YYYY-MM-DD',
        targetFormat: 'MM/DD/YYYY',
        default: undefined
      });

      response.latestRequestDate = momentUtil.formatStr(
        response.latestRequestDate,
        momentUtil.defaultInboundDateTimeConfig
      );

      deferred.resolve(response);
    })
    .fail(function(err) {
      deferred.reject();
    });

  return deferred.promise();
};
