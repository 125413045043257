var Handlebars = require("handlebars/dist/handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "\n<form class=\"production-report-form\">\n  <p>All questions referring to loans are for <strong>PA Loans</strong>.</p>\n\n  <strong class=\"control-label\">\n    A.	Total number and dollar volume of consumer discount loan receivables held by company as of year-end "
    + alias4(((helper = (helper = helpers.annualReportYear || (depth0 != null ? depth0.annualReportYear : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"annualReportYear","hash":{},"data":data}) : helper)))
    + "\n  </strong>\n  <div class=\"checkbox\">\n    <label>\n      <input type=\"checkbox\" name=\"loanReceivablesHeldNotAvailable\" class=\"not-available\" tabindex=\"-1\" data-target=\"#loanReceivables\" /> Not Available\n    </label>\n  </div>\n  <div class=\"row\" id=\"loanReceivablesInputs\">\n    <div class=\"col-md-6\">\n      <div class=\"form-group required\">\n        <label class=\"control-label\">\n          Number\n        </label>\n        <input type=\"number\" step=\"any\" class=\"form-control\" name=\"loanReceivablesHeldNumber\" placeholder=\"Number\" data-parsley-required=\"true\" />\n      </div>\n    </div>\n    <div class=\"col-md-6\">\n      <div class=\"form-group required\">\n        <label class=\"control-label\">\n          Dollar Value\n        </label>\n        <div class=\"input-group amount-input-group\">\n          <div class=\"input-group-addon\">$</div>\n          <input type=\"number\" step=\"any\" class=\"form-control\" name=\"loanReceivablesHeldDollars\" placeholder=\"Amount\" data-parsley-required=\"true\" data-parsley-dollar-amount=\"true\" data-parsley-errors-container=\"#loanReceivablesHeldDollarsError\" />\n        </div>\n        <div id=\"loanReceivablesHeldDollarsError\"></div>\n      </div>\n    </div>\n  </div>\n  <div class=\"row\" id=\"loanReceivablesExplanation\">\n    <div class=\"col-md-12\">\n      <div class=\"form-group required\">\n        <label class=\"control-label\">Please provide details:  </label>\n        <textarea class=\"form-control input-view\" rows=\"4\" cols=\"100\" name=\"loanReceivablesHeldExplanation\" placeholder=\"Please provide a detailed explanation\" data-parsley-required=\"true\"></textarea>\n        <div class=\"pre-wrap\" id=\"loanReceivablesHeldExplanationPrintView\"></div>\n      </div>\n    </div>\n  </div>\n\n  <strong class=\"control-label\">\n    B. Total number and dollar volume of consumer discount loans made in "
    + alias4(((helper = (helper = helpers.annualReportYear || (depth0 != null ? depth0.annualReportYear : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"annualReportYear","hash":{},"data":data}) : helper)))
    + "\n  </strong>\n  <div class=\"checkbox\">\n    <label>\n      <input type=\"checkbox\" name=\"loansNotAvailable\" class=\"not-available\" tabindex=\"-1\" data-target=\"#loans\" /> Not Available\n    </label>\n  </div>\n  <div class=\"row\" id=\"loansInputs\">\n    <div class=\"col-md-6\">\n      <div class=\"form-group required\">\n        <label class=\"control-label\">\n          Number\n        </label>\n        <input type=\"number\" step=\"any\" class=\"form-control\" name=\"loansNumber\" placeholder=\"Number\" data-parsley-required=\"true\" />\n      </div>\n    </div>\n    <div class=\"col-md-6\">\n      <div class=\"form-group required\">\n        <label class=\"control-label\">\n          Dollar Value\n        </label>\n        <div class=\"input-group amount-input-group\">\n          <div class=\"input-group-addon\">$</div>\n          <input type=\"number\" step=\"any\" class=\"form-control\" name=\"loansDollars\" placeholder=\"Amount\" data-parsley-required=\"true\" data-parsley-dollar-amount=\"true\" data-parsley-errors-container=\"#loansDollarsError\" />\n        </div>\n        <div id=\"loansDollarsError\"></div>\n      </div>\n    </div>\n  </div>\n  <div class=\"row\" id=\"loansExplanation\">\n    <div class=\"col-md-12\">\n      <div class=\"form-group required\">\n        <label class=\"control-label\">Please provide details:  </label>\n        <textarea class=\"form-control input-view\" rows=\"4\" cols=\"100\" name=\"loansExplanation\" placeholder=\"Please provide a detailed explanation\" data-parsley-required=\"true\"></textarea>\n        <div class=\"pre-wrap\" id=\"loansExplanationPrintView\"></div>\n      </div>\n    </div>\n  </div>\n\n  <strong class=\"control-label\">\n    C. Total number of non-real estate secured repossessions executed in "
    + alias4(((helper = (helper = helpers.annualReportYear || (depth0 != null ? depth0.annualReportYear : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"annualReportYear","hash":{},"data":data}) : helper)))
    + "\n  </strong>\n  <div class=\"checkbox\">\n    <label>\n      <input type=\"checkbox\" name=\"repossessionNotAvailable\" class=\"not-available\" tabindex=\"-1\" data-target=\"#repossession\" /> Not Available\n    </label>\n  </div>\n  <div class=\"row\" id=\"repossessionInputs\">\n    <div class=\"col-md-6\">\n      <div class=\"form-group required\">\n        <label class=\"control-label\">\n          Number\n        </label>\n        <input type=\"number\" step=\"any\" class=\"form-control\" name=\"repossessionNumber\" placeholder=\"Number\" data-parsley-required=\"true\" />\n      </div>\n    </div>\n    <div class=\"col-md-6\">\n      <div class=\"form-group required\">\n        <label class=\"control-label\">\n          Dollar Value\n        </label>\n        <div class=\"input-group amount-input-group\">\n          <div class=\"input-group-addon\">$</div>\n          <input type=\"number\" step=\"any\" class=\"form-control\" name=\"repossessionDollars\" placeholder=\"Amount\" data-parsley-required=\"true\" data-parsley-dollar-amount=\"true\" data-parsley-errors-container=\"#repossessionDollarsError\" />\n        </div>\n        <div id=\"repossessionDollarsError\"></div>\n      </div>\n    </div>\n  </div>\n  <div class=\"row\" id=\"repossessionExplanation\">\n    <div class=\"col-md-12\">\n      <div class=\"form-group required\">\n        <label class=\"control-label\">Please provide details:  </label>\n        <textarea class=\"form-control input-view\" rows=\"4\" cols=\"100\" name=\"repossessionExplanation\" placeholder=\"Please provide a detailed explanation\" data-parsley-required=\"true\"></textarea>\n        <div class=\"pre-wrap\" id=\"repossessionExplanationPrintView\"></div>\n      </div>\n    </div>\n  </div>\n</form>";
},"useData":true});