/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var _get = require('lodash.get');
var moment = require('moment');

var momentUtil = require('common/util/momentUtil');
var cleanUpResponse = require('common/util/cleanUpResponse');

module.exports = function() {
  var deferred = $.Deferred();

  $.soap({
    body: {
      GetSelfServiceDashboard: {
        $xmlns: 'http://dobs.pa.gov/dp/dashboard/bpm/1.0'
      }
    },
    blueprintArrays: [
      'GetSelfServiceDashboardResponse.Entities.DP_Entity',
      'GetSelfServiceDashboardResponse.Filings.DP_Filing',
      'GetSelfServiceDashboardResponse.Exams.DP_EntityExams',
      'GetSelfServiceDashboardResponse.Tasks.Tasks.NOTF_TASK_INSTANCE',
      'GetSelfServiceDashboardResponse.Tasks.Filings.DP_Filing',
      'GetSelfServiceDashboardResponse.Tasks.Exams.DP_EntityExam',
      'GetSelfServiceDashboardResponse.Tasks.TaskTypes.CM_LU_TaskType'
    ],
    blueprint: {
      entities: [
        '$..DP_Entity',
        {
          id: '$.EntityID',
          entityName: '$.EntityName',
          entityStatusId: '$.StatusID',
          entityStatus: '$.Status',
          address: {
            PPOBAddressId: '$.PPOBAddressID',
            addressLine1: '$.AddressLine1',
            addressLine2: '$.AddressLine2',
            addressLine3: '$.AddressLine3',
            city: '$.City',
            state: '$.State',
            postalCode: '$.PostalCode',
            county: '$.County',
            country: '$.Country'
          }
        }
      ],
      tasks: [
        '$..NOTF_TASK_INSTANCE',
        {
          taskId: '$..TaskId',
          deliveryDate: '$..DeliveryDate',
          filingId: '$..DPFilingID',
          entityId: '$..DPEntityID',
          examId: '$..DPExamID',
          correspondenceId: '$..DPCorrespondenceID',
          taskTypeName: '$..TaskTypeName',
          dueDate: '$..DueDate'
        }
      ],
      filings: [
        '$.GetSelfServiceDashboardResponse.Filings.DP_Filing',
        {
          id: '$.ID',
          filingTypeId: '$.FilingTypeID',
          filingType: '$.FilingType',
          entityName: '$.EntityName',
          entityAccessLevelId: '$..EntityExternalUserAccessLevelID',
          modifiedDate: '$.ModifiedDate'
        }
      ],
      exams: [
        '$.GetSelfServiceDashboardResponse.Exams.DP_EntityExam',
        {
          id: '$..ID',
          entityName: '$..EntityName',
          examType: '$..ExamType',
          dueDate: '$..DueDate'
        }
      ],
      taskMetaData: {
        filings: [
          '$.GetSelfServiceDashboardResponse.Tasks.Filings.DP_Filing',
          {
            id: '$.FilingID',
            entityName: '$.EntityName',
            filingType: '$.FilingType',
            submissionTypeName: '$.SubmissionTypeName',
            filingTypeId: '$.FilingTypeID'
          }
        ],
        exams: [
          '$.GetSelfServiceDashboardResponse.Tasks.Exams.DP_EntityExam',
          {
            id: '$.ID',
            entityName: '$.EntityName',
            examType: '$.ExamType',
            dueDate: '$.DueDate'
          }
        ],
        taskTypes: [
          '$.GetSelfServiceDashboardResponse.Tasks.TaskTypes.CM_LU_TaskType',
          {
            id: '$.ID',
            name: '$.Name',
            description: '$.Description'
          }
        ]
      }
    }
  })
    .done(function(response) {
      cleanUpResponse(response, { cleanUpTextKeys: true });
      _.each(response.entities, function(entity) {
        entity.entityStatus = entity.entityStatus || '--';
      });

      _.each(response.filings, function(filing) {
        filing.modifiedDate = momentUtil.formatStr(filing.modifiedDate, momentUtil.defaultInboundDateConfig);
        filing.entityName = filing.entityName || '--';
        filing.entityAccessLevelId = filing.entityAccessLevelId
          ? Number(filing.entityAccessLevelId)
          : filing.entityAccessLevelId;
      });

      _.each(response.tasks, function(task) {
        var taskTypeName = _get(task, 'taskTypeName');
        var filingId = _get(task, 'filingId');
        var examId = _get(task, 'examId');
        var entityId = _get(task, 'entityId');

        task.deliveryDate = moment.utc(task.deliveryDate, moment.ISO_8601).local();

        var type = _.find(response.taskMetaData.taskTypes, function(type) {
          return taskTypeName === type.name;
        });

        if (type === undefined) {
          type = '--';
        }

        task.type = type.description;

        if (filingId) {
          var filing = _.find(response.taskMetaData.filings, function(filing) {
            return filingId === filing.id;
          });
          if (filing) {
            task.filingType = filing.filingType;
            task.entityName = filing.entityName;
            if (_.includes(['100', '200', '300'], filing.filingTypeId) && filing.submissionTypeName) {
              task.filingType = filing.filingType + ' - ' + filing.submissionTypeName;
            } else {
              task.filingType = filing.filingType;
            }
          }
        }
        if (examId) {
          var exam = _.find(response.taskMetaData.exams, function(exam) {
            return examId === exam.id;
          });
          if (exam) {
            task.entityName = exam.entityName;
          }
        }
        if (entityId) {
          var entity = _.find(response.entities, function(entity) {
            return entityId === entity.id;
          });
          if (entity) {
            task.entityName = entity.entityName;
          }
        }

        task.entityName = task.entityName || '--';
        task.filingType = task.filingType || '--';
        task.dueDate = task.dueDate || '--';
      });

      delete response.taskMetaData;
      deferred.resolve(response);
    })
    .fail(function(err) {
      deferred.reject();
    });

  return deferred.promise();
};
