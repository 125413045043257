/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var cleanUpResponse = require('common/util/cleanUpResponse');

module.exports = function(annualReportId) {
  var deferred = $.Deferred();

  $.soap({
    body: {
      GetDebtManagementServicesAnnualReport: {
        $xmlns: 'http://dobs.pa.gov/nd/annualreport/bpm/1.0',
        AnnualReportID: annualReportId
      }
    },
    blueprint: {
      id: '$..ID',
      annualReportId: '$..AnnualReportID',
      isOperatingInAllStates: '$..IsOperatingInAllStates',
      operatingStateCodes: '$..OperatingStateCodes',
      isTaxExempt: '$..IsTaxExempt',
      isNonProit: '$..IsNonProit',
      hasStatusChange: '$..HasStatusChange',
      hasStatusChangeExplanation: '$..HasStatusChangeExplanation',
      numEnrolledNotAvailable: '$..NumEnrolledNotAvailable',
      numEnrolledNumber: '$..NumEnrolledNumber',
      numEnrolledDollars: '$..NumEnrolledDollars',
      numEnrolledExplanation: '$..NumEnrolledExplanation',
      numSignedUpNotAvailable: '$..NumSignedUpNotAvailable',
      numSignedUpNumber: '$..NumSignedUpNumber',
      numSignedUpDollars: '$..NumSignedUpDollars',
      numSignedUpExplanation: '$..NumSignedUpExplanation',
      numCompletedNotAvailable: '$..NumCompletedNotAvailable',
      numCompletedNumber: '$..NumCompletedNumber',
      numCompletedDollars: '$..NumCompletedDollars',
      numCompletedExplanation: '$..NumCompletedExplanation',
      numDroppedOutAfterThreeMonthsNotAvailable: '$..NumDroppedOutAfterThreeMonthsNotAvailable',
      numDroppedOutAfterThreeMonthsNumber: '$..NumDroppedOutAfterThreeMonthsNumber',
      numDroppedOutAfterThreeMonthsDollars: '$..NumDroppedOutAfterThreeMonthsDollars',
      numDroppedOutAfterThreeMonthsExplanation: '$..NumDroppedOutAfterThreeMonthsExplanation',
      numDroppedOutBeforeThreeMonthsNotAvailable: '$..NumDroppedOutBeforeThreeMonthsNotAvailable',
      numDroppedOutBeforeThreeMonthsNumber: '$..NumDroppedOutBeforeThreeMonthsNumber',
      numDroppedOutBeforeThreeMonthsDollars: '$..NumDroppedOutBeforeThreeMonthsDollars',
      numDroppedOutBeforeThreeMonthsExplanation: '$..NumDroppedOutBeforeThreeMonthsExplanation',
      delinquentMoreThanThirtyDaysNotAvailable: '$..DelinquentMoreThanThirtyDaysNotAvailable',
      delinquentMoreThanThirtyDaysNumber: '$..DelinquentMoreThanThirtyDaysNumber',
      delinquentMoreThanThirtyDaysDollars: '$..DelinquentMoreThanThirtyDaysDollars',
      delinquentMoreThanThirtyDaysExplanation: '$..DelinquentMoreThanThirtyDaysExplanation',
      delinquentLessThanThirtyDaysNotAvailable: '$..DelinquentLessThanThirtyDaysNotAvailable',
      delinquentLessThanThirtyDaysNumber: '$..DelinquentLessThanThirtyDaysNumber',
      delinquentLessThanThirtyDaysDollars: '$..DelinquentLessThanThirtyDaysDollars',
      delinquentLessThanThirtyDaysExplanation: '$..DelinquentLessThanThirtyDaysExplanation',
      numDeniedNotAvailable: '$..NumDeniedNotAvailable',
      numDeniedNumber: '$..NumDeniedNumber',
      numDeniedDollars: '$..NumDeniedDollars',
      numDeniedExplanation: '$..NumDeniedExplanation',
      dollarDebtManagementNotAvailable: '$..NumCompletedNotAvailable',
      dollarDebtManagementNumber: '$..DollarDebtManagementNumber',
      dollarDebtManagementDollars: '$..DollarDebtManagementDollars',
      dollarDebtManagementExplanation: '$..DollarDebtManagementExplanation',
      numDebtManagementNotAvailable: '$..NumDebtManagementNotAvailable',
      numDebtManagementNumber: '$..NumDebtManagementNumber',
      numDebtManagementDollars: '$..NumDebtManagementDollars',
      numDebtManagementExplanation: '$..NumDebtManagementExplanation',
      paymentTransmittedNotAvailable: '$..PaymentTransmittedNotAvailable',
      paymentTransmittedNumber: '$..PaymentTransmittedNumber',
      paymentTransmittedDollars: '$..PaymentTransmittedDollars',
      paymentTransmittedExplanation: '$..PaymentTransmittedExplanation',
      maxFundsNotAvailable: '$..MaxFundsNotAvailable',
      maxFundsNumber: '$..MaxFundsNumber',
      maxFundsDollars: '$..MaxFundsDollars',
      maxFundsExplanation: '$..MaxFundsExplanation',
      bondDollars: '$..BondDollars',
      isSufficient: '$..IsSufficient',
      isSufficientExplanation: '$..IsSufficientExplanation',
      certificationName: '$..CertificationName',
      isCertified: '$..IsCertified'
    }
  })
    .done(function(response) {
      cleanUpResponse(response);

      response.isOperatingInAllStates = response.isOperatingInAllStates === '1';
      response.numEnrolledNotAvailable = response.numEnrolledNotAvailable === '1';
      response.numSignedUpNotAvailable = response.numSignedUpNotAvailable === '1';
      response.numCompletedNotAvailable = response.numCompletedNotAvailable === '1';
      response.numDroppedOutAfterThreeMonthsNotAvailable = response.numDroppedOutAfterThreeMonthsNotAvailable === '1';
      response.numDroppedOutBeforeThreeMonthsNotAvailable = response.numDroppedOutBeforeThreeMonthsNotAvailable === '1';
      response.delinquentMoreThanThirtyDaysNotAvailable = response.delinquentMoreThanThirtyDaysNotAvailable === '1';
      response.delinquentLessThanThirtyDaysNotAvailable = response.delinquentLessThanThirtyDaysNotAvailable === '1';
      response.numDeniedNotAvailable = response.numDeniedNotAvailable === '1';
      response.dollarDebtManagementNotAvailable = response.dollarDebtManagementNotAvailable === '1';
      response.numDebtManagementNotAvailable = response.numDebtManagementNotAvailable === '1';
      response.paymentTransmittedNotAvailable = response.paymentTransmittedNotAvailable === '1';
      response.maxFundsNotAvailable = response.maxFundsNotAvailable === '1';
      response.isCertified = response.isCertified === '1';

      if (response.operatingStateCodes) {
        response.operatingStateCodes = JSON.parse(response.operatingStateCodes);
      }
      deferred.resolve(response);
    })
    .fail(function(err) {
      deferred.reject();
    });

  return deferred.promise();
};
