/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Marionette = require('backbone.marionette');
var Syphon = require('backbone.syphon');

var parsleyUtil = require('common/util/parsleyUtil');

var toggleForm = require('common/util/toggleForm');

var tmplInstallmentSellerRenewalContractsSectionView = require('./InstallmentSellerRenewalContractsSectionView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplInstallmentSellerRenewalContractsSectionView,

  className: 'installment-seller-renewal-contract-section-view',

  behaviors: {
    RadioGroupToggleBehavior: {
      radios: [
        {
          target: '.contracts-info',
          radioGroup: 'input[name="isReportingContracts"]',
          valueToShow: '1'
        },
        {
          target: '.reporting-zero',
          radioGroup: 'input[name="isReportingContracts"]',
          valueToShow: '0'
        }
      ]
    }
  },

  ui: {
    contractsForm: '.contracts-form',
    contractsNA: '.contracts-na',
    hasContractsExplanationSection: '.has-contracts-explaination-section',
    contractsTotalNumberInput: '.contracts-total-number-input',
    contractsTotalNumberSection: '.contracts-total-number-section',
    contractsDollarValueInput: '.contracts-dollar-value-input',
    contractsDollarValueSection: '.contracts-dollar-value-section',
    thirdPartyNA: '.third-party-na',
    hasThirdPartyExplanationSection: '.has-third-party-explaination-section',
    thirdPartyTotalNumberInput: '.third-party-total-number-input',
    thirdPartyTotalNumberSection: '.third-party-total-number-section',
    thirdPartyDollarValueInput: '.third-party-dollar-value-input',
    thirdPartyDollarValueSection: '.third-party-dollar-value-section',
  },

  events: {
    'click @ui.contractsNA': 'onClickContractsNA',
    'click @ui.thirdPartyNA': 'onClickThirdPartyNA'
  },

  onBeforeShow: function() {
    var formData = this.model.get('renewalData');
    formData.contractsNA = formData.hasContracts === '0';
    formData.thirdPartyNA = formData.hasThirdParty === '0';

    Syphon.deserialize(this, formData);
    this.onClickContractsNA();
    this.onClickThirdPartyNA();
  },

  onClickContractsNA: function() {
    var hasContracts = !this.ui.contractsNA.prop('checked');

    toggleForm(this.ui.hasContractsExplanationSection, !hasContracts);
    this.ui.contractsTotalNumberSection.toggleClass('required', hasContracts);
    this.ui.contractsDollarValueSection.toggleClass('required', hasContracts);
    this.ui.contractsTotalNumberInput.attr('data-parsley-required', hasContracts);
    this.ui.contractsDollarValueInput.attr('data-parsley-required', hasContracts);
  },

  onClickThirdPartyNA: function() {
    var hasThirdParty = !this.ui.thirdPartyNA.prop('checked');

    toggleForm(this.ui.hasThirdPartyExplanationSection, !hasThirdParty);
    this.ui.thirdPartyTotalNumberSection.toggleClass('required', hasThirdParty);
    this.ui.thirdPartyDollarValueSection.toggleClass('required', hasThirdParty);
    this.ui.thirdPartyTotalNumberInput.attr('data-parsley-required', hasThirdParty);
    this.ui.thirdPartyDollarValueInput.attr('data-parsley-required', hasThirdParty);
  },

  validate: function(config) {
    if (config.type === 'save') {
      // Turn off required field validation for this form
      parsleyUtil.disableRequiredValidation(this.ui.contractsForm);
    } else {
      // Turn on required field validation for this form
      parsleyUtil.enableRequiredValidation(this.ui.contractsForm);
    }
    return this.ui.contractsForm.parsley().whenValidate();
  },

  getFormData: function() {
    var formData = Syphon.serialize(this);
    formData.hasContracts = !this.ui.contractsNA.prop('checked');
    formData.hasThirdParty = !this.ui.thirdPartyNA.prop('checked');
    return formData;
  }
});
