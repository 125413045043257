/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2020] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Radio = require('backbone.radio');
var Backbone = require('backbone');
var Marionette = require('backbone.marionette');

var SideNavSectionView = require('common/views/SideNavSectionView');
var BreadcrumbView = require('common/views/BreadcrumbView');
var DropdownView = require('common/views/DropdownView');

var ConSerEntityDetailsSectionView = require('./ConSerEntityDetailsSectionView');

var tmplConSerEntityDashboardView = require('../templates/ConSerEntityDashboardView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplConSerEntityDashboardView,

  className: 'con-ser-entity-dashboard-view container-fluid',

  ui: {
    headerBar: '.header-bar-container',
    breadcrumb: '.breadcrumb-container',
    entityDashboard: '.entity-dashboard-container',
    entityActionDropDown: '.entity-action-dropdown'
  },
  regions: {
    headerBar: '@ui.headerBar',
    breadcrumb: '@ui.breadcrumb',
    entityDashboard: '@ui.entityDashboard',
    entityActionDropDown: '@ui.entityActionDropDown'
  },

  onBeforeShow: function() {
    var entity = this.model.get('entity') || {};
    var entityId = entity.entityId;
    var departmentId = entity.departmentId;

    this.showChildView(
      'breadcrumb',
      new BreadcrumbView({
        breadcrumbs: [
          {
            link: '#conser/dashboard',
            text: 'Home'
          },
          {
            text: entity.entityName
          }
        ]
      })
    );

    var dropDownOptions = [
      {
        text: 'Add Contact',
        link: '#conser/' + departmentId + '/entity/' + entityId + '/new-contact'
      }
    ];

    if (departmentId === '4000') {
      dropDownOptions.unshift(
        {
          text: 'Edit Entity',
          link: '#conser/' + departmentId + '/entity/' + entityId + '/edit'
        },
        {
          isDivider: true
        },
        {
          text: 'Join Individual',
          link: '#conser/entity/' + entityId + '/join-individual'
        },
        {
          text: 'Add Individual',
          link: '#conser/entity/' + entityId + '/new-individual'
        },
        {
          text: 'Add Complaint',
          link: '#conser/new-complaint?entityId=' + entityId
        }
      );
    }

    this.showChildView(
      'entityActionDropDown',
      new DropdownView({
        rightAlign: true,
        model: new Backbone.Model({
          name: 'Entity Actions',
          options: dropDownOptions
        })
      })
    );

    var sideNavTabs = [
      {
        id: 'entityDetails',
        title: 'Entity Details',
        view: new ConSerEntityDetailsSectionView({ model: new Backbone.Model(entity) })
      },
      {
        id: 'complaints',
        title: 'Complaints',
        view: Radio.channel('cs').request('build:complaints-section-view', entityId, departmentId)
      },
      {
        id: 'Individuals',
        title: 'Individuals',
        view: Radio.channel('cs').request('build:individuals-section-view', entityId)
      },
      {
        id: 'contacts',
        title: 'Contacts',
        view: Radio.channel('cs').request('build:contacts-section-view', entityId, departmentId)
      }
    ];

    this.dashboard = new SideNavSectionView({
      collection: new Backbone.Collection(sideNavTabs),
      useNavigationButtons: false
    });

    this.showChildView('entityDashboard', this.dashboard);
  }
});
