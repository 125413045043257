/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var cleanUpResponse = require('common/util/cleanUpResponse');

module.exports = function(formData) {
  var deferred = $.Deferred();

  $.soap({
    body: {
      SubmitIndividual: {
        $xmlns: 'http://dobs.pa.gov/sc/individual/bpm/1.0',
        IndividualID: formData.individualId,
        LastName: formData.lastName,
        FirstName: formData.firstName,
        MiddleInitial: formData.middleInitial,
        CRDNumber: formData.crdNumber,
        OtherIDNumber: formData.otherIdNumber,
        IsDeleted: formData.isDeleted
      }
    },
    blueprint: {
      individualId: '$..IndividualID.__text'
    }
  })
    .done(function(response) {
      cleanUpResponse(response);
      deferred.resolve(response.individualId);
    })
    .fail(function(err) {
      deferred.reject();
    });

  return deferred.promise();
};

