/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var cleanUpResponse = require('common/util/cleanUpResponse');
var _ = require('underscore');
var momentUtil = require('common/util/momentUtil');

module.exports = function(entityTrackingId) {
  var defer = $.Deferred();

  $.soap({
    body: {
      GetEntityTracking: {
        $xmlns: 'http://dobs.pa.gov/dp/tracking/bpm/1.0',
        ID: entityTrackingId
      }
    },
    blueprint: {
      id: '$..ID.__text',
      entityId: '$..EntityID.__text',
      trackingTypeId: '$..TrackingTypeID.__text',
      trackingType: '$..TrackingType.__text',
      enteredDate: '$..EnteredDate.__text',
      trackingSourceId: '$..TrackingSourceID.__text',
      trackingSource: '$..TrackingSource.__text',
      trackingEntityTypeId: '$..TrackingEntityTypeID.__text',
      trackingEntityType: '$..TrackingEntityType.__text',
      adoptionDeadlineDate: '$..AdoptionDeadlineDate.__text',
      expectedAdoptionDate: '$..ExpectedAdoptionDate.__text',
      isAdopted: '$..IsAdopted.__text',
      adoptionDate: '$..AdoptionDate.__text',
      isServiceMRBS: '$..IsServiceMRBS.__text',
      isMRBInvestments: '$..IsMRBInvestments.__text',
      isDirect: '$..IsDirect.__text',
      isIndirect: '$..IsIndirect.__text',
      isBank: '$..IsBank.__text',
      isHC: '$..IsHC.__text',
      year: '$..Year.__text',
      quarterId: '$..QuarterID.__text',
      quarter: '$..Quarter.__text',
      redFlags: {
        isLoanGrowthOver20: '$..IsLoanGrowthOver20.__text',
        isCommunityLeverageRatioUnder9: '$..IsCommunityLeverageRatioUnder9.__text',
        isCREToRBCOver300: '$..IsCREToRBCOver300.__text',
        isPastDueRatioOver5: '$..IsPastDueRatioOver5.__text',
        isTexasRatioOver30: '$..IsTexasRatioOver30.__text',
        isROAUnderPt25: '$..IsROAUnderPt25.__text',
        isTier1LeverageUnder8: '$..IsTier1LeverageUnder8.__text',
        isNonCoreRatioOver20: '$..IsNonCoreRatioOver20.__text',
        isLoanToDepositOver100: '$..IsLoanToDepositOver100.__text',
        isLoanGrowthOver10: '$..IsLoanGrowthOver10.__text',
        isNetAssetsUnder8Pt5: '$..IsNetAssetsUnder8Pt5.__text',
        isPastDueRatioOver3Pt5: '$..IsPastDueRatioOver3Pt5.__text',
        isNetLongTermAssetsOver25: '$..IsNetLongTermAssetsOver25.__text',
        isROAUnderPt50: '$..IsROAUnderPt50.__text',
        isNetWorthGrowthOver10: '$..IsNetWorthGrowthOver10.__text',
        isCashShortTermUnder10: '$..IsCashShortTermUnder10.__text',
        isShareGrowthOver10: '$..IsShareGrowthOver10.__text',
        isAssetGrowthOver10: '$..IsAssetGrowthOver10.__text'
      },
      asOfDate: '$..AsOfDate.__text',
      examStartDate: '$..ExamStartDate.__text',
      vendorName: '$..VendorName.__text',
      vendorService: '$..VendorService.__text',
      comments: '$..Comments.__text',
      isActive: '$..IsActive.__text',
      details: '$..Details.__text'
    }
  })
    .done(function(response) {
      cleanUpResponse(response);

      response.redFlagCount = _.reduce(
        _.values(response.redFlags),
        function(memo, flag) {
          return memo + (flag === '1' ? 1 : 0);
        },
        0
      );

      _.extend(response, response.redFlags);

      var isBankRedFlag = response.trackingTypeId === '4';
      var isCreditUnionRedFlag = response.trackingTypeId === '13';
      var isTrustRedFlag = response.trackingTypeId === '23';

      var checkboxes = [];
      if (isBankRedFlag) {
        checkboxes = [
          'isLoanGrowthOver20',
          'isCommunityLeverageRatioUnder9',
          'isCREToRBCOver300',
          'isPastDueRatioOver5',
          'isTexasRatioOver30',
          'isTier1LeverageUnder8',
          'isROAUnderPt25',
          'isNonCoreRatioOver20',
          'isLoanToDepositOver100'
        ];
        _.each(checkboxes, function(attr) {
          response[attr] = response[attr] === '1';
        });
      } else if (isCreditUnionRedFlag || isTrustRedFlag) {
        checkboxes = [
          'isLoanGrowthOver10',
          'isNetAssetsUnder8Pt5',
          'isPastDueRatioOver3Pt5',
          'isNetLongTermAssetsOver25',
          'isROAUnderPt50',
          'isNetWorthGrowthOver10',
          'isCashShortTermUnder10',
          'isShareGrowthOver10',
          'isAssetGrowthOver10'
        ];
        _.each(checkboxes, function(attr) {
          response[attr] = response[attr] === '1';
        });
      }
      delete response.redFlags;

      var formatCfg = _.extend({}, momentUtil.defaultInboundDateConfig, { default: undefined });
      response.enteredDate = momentUtil.formatStr(response.enteredDate, formatCfg);
      response.adoptionDeadlineDate = momentUtil.formatStr(response.adoptionDeadlineDate, formatCfg);
      response.expectedAdoptionDate = momentUtil.formatStr(response.expectedAdoptionDate, formatCfg);
      response.adoptionDate = momentUtil.formatStr(response.adoptionDate, formatCfg);
      response.asOfDate = momentUtil.formatStr(response.asOfDate, formatCfg);
      response.examStartDate = momentUtil.formatStr(response.examStartDate, formatCfg);

      defer.resolve(response);
    })
    .fail(function(err) {
      defer.reject(err);
    });

  return defer.promise();
};
