/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Marionette = require('backbone.marionette');
var Backbone = require('backbone');

var GridView = require('psa-marionette/views/GridView');
var PageableCollection = require('psa-backbone/models/PageableCollection');

var Config = require('../../../config/Config');
var UIUtil = require('psa-core/util/ui');

var userHasRole = require('common/util/userHasRole');
var ConfirmationModalView = require('common/views/ConfirmationModalView');
var MomentCell = require('common/backgrid/MomentCell');
var MultiButtonCell = require('common/backgrid/MultiButtonCell');
var CancelCorrespondence = require('../services/CancelCorrespondence');
var CancelFinancialStatementTask = require('../../organization-license-dashboard/services/CancelFinancialStatementTask');

var tmplSecOrganizationPendingTasksSectionView = require('../templates/SecOrganizationPendingTasksSectionView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplSecOrganizationPendingTasksSectionView,

  className: 'sec-organization-pending-tasks-section-view container-fluid',

  ui: {
    pendingTasks: '.pending-tasks-container'
  },

  regions: {
    pendingTasks: '@ui.pendingTasks'
  },

  onBeforeShow: function() {
    var isFilingDirector = userHasRole('SC - Filing Director');
    var isExamChief = userHasRole('SC - Exam Chief');
    this.pendingTasksView = new GridView({
      columns: [
        {
          name: 'typeName',
          label: 'Action Needed',
          cell: 'string',
          headerCell: 'custom',
          width: 35,
          editable: false
        },
        {
          name: 'deliveryDate',
          label: 'Assigned On',
          cell: MomentCell.MomentCell,
          formatter: MomentCell.MomentDateTimeFormatter.extend({
            emptyValue: '--'
          }),
          sortValue: MomentCell.MomentSortValue,
          headerCell: 'custom',
          width: 20,
          editable: false
        },
        {
          name: 'assignee',
          label: 'Assigned To',
          cell: 'string',
          headerCell: 'custom',
          width: 35,
          editable: false
        },
        {
          name: '',
          label: '',
          cell: MultiButtonCell.extend({
            buttons: [
              {
                name: '',
                labelClass: 'fa fa-trash',
                buttonClass: 'btn-danger btn-sm',
                renderable: function(model) {
                  var typeName = model.get('typeName');

                  if (isFilingDirector) {
                    return (
                      typeName === 'Respond to Correspondence' || typeName === 'Submit Annual Financial Statements' || typeName === 'Submit Accounting Registration'
                    );
                  } else if (isExamChief) {
                    return typeName === 'Respond to Correspondence';
                  }
                },
                onClick: this.onClickDeleteButton.bind(this)
              },
              {
                name: '',
                buttonClass: 'btn-primary btn-sm',
                labelClass: 'fa fa-edit',
                renderable: function(model) {
                  var assigneeId = model.get('assigneeId');
                  var isExternalTask = assigneeId === Config.getExternalUserServiceAccountUserId();
                  return !isExternalTask;
                },
                href: function(model) {
                  return '#sec/task/' + model.get('taskId');
                }
              }
            ]
          }),
          editable: false,
          sortable: false,
          headerCell: 'custom',
          width: 10
        }
      ],
      collection: new PageableCollection(this.model.get('tasks'), { pageSize: 25 }),
      emptyText: 'No Pending Tasks'
    });
    this.showChildView('pendingTasks', this.pendingTasksView);
  },

  onClickDeleteButton: function(e, model) {
    e.preventDefault();
    var typeName = model.get('typeName');

    if (typeName === 'Respond to Correspondence') {
      var correspondenceId = model.get('correspondenceId');
      this.onClickCancelCorrespondence(correspondenceId, model);
    } else if (typeName === 'Submit Annual Financial Statements' || typeName === 'Submit Accounting Registration') {
      var financialStatementId = model.get('financialStatementId');
      this.onClickCancelFinancialStatement(financialStatementId, model);
    }
  },

  onClickCancelCorrespondence: function(correspondenceId, model) {
    var self = this;

    UIUtil.showModal({
      title: 'Confirm End Correspondence Task',
      view: new ConfirmationModalView({
        confirmText: 'End Task',
        model: new Backbone.Model({
          message:
            'Are you sure you want to delete this Correspondence task? All associated tasks and worflow will be terminated.',
          onConfirm: function() {
            return CancelCorrespondence(correspondenceId).done(function() {
              self.pendingTasksView.collection.remove(model);
            });
          },
          onConfirmDone: function() {
            return true;
          }
        })
      })
    });
  },

  onClickCancelFinancialStatement: function(financialStatementId, model) {
    var self = this;

    UIUtil.showModal({
      title: 'Confirm End Annual Financial Statement Task',
      view: new ConfirmationModalView({
        confirmText: 'End Task',
        model: new Backbone.Model({
          message:
            'Are you sure you want to delete this Annual Financial Statement task? All associated tasks and worflow will be terminated.',
          onConfirm: function() {
            return CancelFinancialStatementTask(financialStatementId).done(function() {
              self.pendingTasksView.collection.remove(model);
            });
          },
          onConfirmDone: function() {
            return true;
          }
        })
      })
    });
  }
});
