var Handlebars = require("handlebars/dist/handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "  <div class=\"row\">\n    <div class=\"col-md-6 col-sm-12\">\n      <div class=\"form-group\">\n        <label class=\"control-label col-sm-3 col-md-4\">Returned to Composite 1/2</label>\n        <div class=\"form-control-static col-sm-9 col-md-8\">"
    + ((stack1 = (helpers["default"] || (depth0 && depth0["default"]) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? depth0.enforcement : depth0)) != null ? stack1.returnedToCompositeHalf : stack1),"--",{"name":"default","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "</div>\n      </div>\n    </div>\n  </div>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing;

  return "\n<div class=\"enforcement-details form-horizontal form-static m-t-md\">\n  <div class=\"row\">\n    <div class=\"col-md-6 col-sm-12\">\n      <div class=\"form-group\">\n        <label class=\"control-label col-sm-3 col-md-4\">Action</label>\n        <div class=\"form-control-static col-sm-9 col-md-8\">"
    + ((stack1 = (helpers["default"] || (depth0 && depth0["default"]) || alias2).call(alias1,((stack1 = (depth0 != null ? depth0.enforcement : depth0)) != null ? stack1.action : stack1),"--",{"name":"default","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "</div>\n      </div>\n    </div>\n    <div class=\"col-md-6 col-sm-12 non-tsp-info\">\n      <div class=\"form-group\">\n        <label class=\"control-label col-sm-3 col-md-4\">Area</label>\n        <div class=\"form-control-static col-sm-9 col-md-8\">"
    + ((stack1 = (helpers["default"] || (depth0 && depth0["default"]) || alias2).call(alias1,((stack1 = (depth0 != null ? depth0.enforcement : depth0)) != null ? stack1.area : stack1),"--",{"name":"default","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "</div>\n      </div>\n    </div>\n    <div class=\"col-md-6 col-sm-12 tsp-info\">\n      <div class=\"form-group\">\n        <label class=\"control-label col-sm-3 col-md-4\">Effective Date</label>\n        <div class=\"form-control-static col-sm-9 col-md-8\">"
    + ((stack1 = (helpers["default"] || (depth0 && depth0["default"]) || alias2).call(alias1,((stack1 = (depth0 != null ? depth0.enforcement : depth0)) != null ? stack1.effectiveDate : stack1),"--",{"name":"default","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n        </div>\n      </div>\n    </div>\n  </div>\n  <div class=\"row non-tsp-info\">\n    <div class=\"col-md-6 col-sm-12\">\n      <div class=\"form-group\">\n        <label class=\"control-label col-sm-3 col-md-4\">Type</label>\n        <div class=\"form-control-static col-sm-9 col-md-8\">"
    + ((stack1 = (helpers["default"] || (depth0 && depth0["default"]) || alias2).call(alias1,((stack1 = (depth0 != null ? depth0.enforcement : depth0)) != null ? stack1.type : stack1),"--",{"name":"default","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "</div>\n      </div>\n    </div>\n    <div class=\"col-md-6 col-sm-12\">\n      <div class=\"form-group\">\n        <label class=\"control-label col-sm-3 col-md-4\">Agency</label>\n        <div class=\"form-control-static col-sm-9 col-md-8\">"
    + ((stack1 = (helpers["default"] || (depth0 && depth0["default"]) || alias2).call(alias1,((stack1 = (depth0 != null ? depth0.enforcement : depth0)) != null ? stack1.agency : stack1),"--",{"name":"default","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "</div>\n      </div>\n    </div>\n  </div>\n  <div class=\"row non-tsp-info\">\n    <div class=\"col-md-6 col-sm-12\">\n      <div class=\"form-group\">\n        <label class=\"control-label col-sm-3 col-md-4\">Exam as of Date</label>\n        <div class=\"form-control-static col-sm-9 col-md-8\">"
    + ((stack1 = (helpers["default"] || (depth0 && depth0["default"]) || alias2).call(alias1,((stack1 = (depth0 != null ? depth0.enforcement : depth0)) != null ? stack1.examAsOfDate : stack1),"--",{"name":"default","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "</div>\n      </div>\n    </div>\n    <div class=\"col-md-6 col-sm-12\">\n      <div class=\"form-group\">\n        <label class=\"control-label col-sm-3 col-md-4\">Effective Date</label>\n        <div class=\"form-control-static col-sm-9 col-md-8\">"
    + ((stack1 = (helpers["default"] || (depth0 && depth0["default"]) || alias2).call(alias1,((stack1 = (depth0 != null ? depth0.enforcement : depth0)) != null ? stack1.effectiveDate : stack1),"--",{"name":"default","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n        </div>\n      </div>\n    </div>\n  </div>\n  <div class=\"row tsp-info\">\n    <div class=\"col-md-6 col-sm-12\">\n      <div class=\"form-group\">\n        <label class=\"control-label col-sm-3 col-md-4\">Termination Date</label>\n        <div class=\"form-control-static col-sm-9 col-md-8\">"
    + ((stack1 = (helpers["default"] || (depth0 && depth0["default"]) || alias2).call(alias1,((stack1 = (depth0 != null ? depth0.enforcement : depth0)) != null ? stack1.terminationDate : stack1),"--",{"name":"default","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "</div>\n      </div>\n    </div>\n    <div class=\"col-md-6 col-sm-12\">\n      <div class=\"form-group\">\n        <label class=\"control-label col-sm-3 col-md-4\">CMP</label>\n        <div class=\"form-control-static col-sm-9 col-md-8\">"
    + ((stack1 = (helpers["default"] || (depth0 && depth0["default"]) || alias2).call(alias1,((stack1 = (depth0 != null ? depth0.enforcement : depth0)) != null ? stack1.cmpDisplay : stack1),"--",{"name":"default","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "</div>\n      </div>\n    </div>\n  </div>\n  <div class=\"row\">\n    <div class=\"col-md-6 col-sm-12\">\n      <div class=\"form-group\">\n        <label class=\"control-label col-sm-3 col-md-4\">Status</label>\n        <div class=\"form-control-static col-sm-9 col-md-8\">"
    + ((stack1 = (helpers["default"] || (depth0 && depth0["default"]) || alias2).call(alias1,((stack1 = (depth0 != null ? depth0.enforcement : depth0)) != null ? stack1.status : stack1),"--",{"name":"default","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "</div>\n      </div>\n    </div>\n  </div>\n"
    + ((stack1 = (helpers.ifCond || (depth0 && depth0.ifCond) || alias2).call(alias1,((stack1 = (depth0 != null ? depth0.enforcement : depth0)) != null ? stack1.entityTypeId : stack1),"===","1",{"name":"ifCond","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "  <div class=\"row\">\n    <div class=\"col-sm-12\">\n      <div class=\"form-group\">\n        <label class=\"control-label col-sm-3 col-md-2\">Comments</label>\n        <p class=\"form-control-static col-sm-9 col-md-10\">"
    + ((stack1 = (helpers["default"] || (depth0 && depth0["default"]) || alias2).call(alias1,((stack1 = (depth0 != null ? depth0.enforcement : depth0)) != null ? stack1.comments : stack1),"--",{"name":"default","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "</p>\n      </div>\n    </div>\n  </div>\n</div>";
},"useData":true});