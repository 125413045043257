/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');

var cleanUpResponse = require('common/util/cleanUpResponse');
var momentUtil = require('common/util/momentUtil');

module.exports = function(entityId) {
  var deferred = $.Deferred();

  $.soap({
    body: {
      GetBoardAnnualMeetingRequirementsByEntity: {
        $xmlns: 'http://dobs.pa.gov/dp/director/bpm/1.0',
        EntityID: entityId
      }
    },
    blueprintArrays: ['GetBoardAnnualMeetingRequirementsByEntityResponse.DP_BoardAnnualMeetingRequirements'],
    blueprint: {
      boardAnnualMeetingRequire: [
        '$..DP_BoardAnnualMeetingRequirements',
        {
          boardAnnualMeetingRequireId: '$..ID',
          entityId: '$..EntityID',
          annualMeetingDue: '$..AnnualMeetingDue',
          boardType: '$..BoardType',
          boardTypeID: '$..BoardTypeID',
          terms: '$..Terms',
          boardSizeMin: '$..BoardSizeMin',
          boardSizeMax: '$..BoardSizeMax',
          comments: '$..Comments',
          isDeleted: '$..IsDeleted'
        }
      ]
    }
  })
    .done(function(response) {
      cleanUpResponse(response);

      _.each(response.boardAnnualMeetingRequire, function(boardAnnualMeetingRequire) {
        boardAnnualMeetingRequire.lastMeetingDate = momentUtil.formatStr(
          boardAnnualMeetingRequire.lastMeetingDate,
          momentUtil.defaultInboundDateConfig
        );

        var comments = boardAnnualMeetingRequire.comments;
        if (comments) {
          boardAnnualMeetingRequire.comments = comments.length > 100 ? comments.substring(0, 100) + '...' : comments;
        }

        _.each(['annualMeetingDue', 'boardType', 'terms', 'boardSizeMin', 'boardSizeMax', 'comments'], function(attr) {
          if (!boardAnnualMeetingRequire[attr]) {
            boardAnnualMeetingRequire[attr] = '--';
          }
        });
      });

      deferred.resolve(response.boardAnnualMeetingRequire);
    })
    .fail(function(err) {
      deferred.reject();
    });

  return deferred.promise();
};
