/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2020] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Marionette = require('backbone.marionette');
var Syphon = require('backbone.syphon');
var Ladda = require('ladda');

var GridView = require('psa-marionette/views/GridView');
var PageableCollection = require('psa-backbone/models/PageableCollection');

var SelectOneCell = require('common/backgrid/SelectOneCell');

var GetIndividualByNameAndCRDNumber = require('../services/GetIndividualByNameAndCRDNumber');
var SubmitExamInvInqRelatedSubject = require('../services/SubmitExamInvInqRelatedSubject');

var tmplSecExamInvInqIndividualSearchModalView = require('../templates/SecExamInvInqIndividualSearchModalView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplSecExamInvInqIndividualSearchModalView,

  className: 'sec-exam-inv-inq-individual-search-modal-view',

  ui: {
    searchResultsGrid: '.search-result-grid-container',
    search: '.btn-search',
    searchResultSummary: '.search-result-summary',
    searchResultMax: '.search-result-max',
    hitCount: '.hit-count',
    searchTime: '.search-time',
    refineSeachTip: '.refine-seach-tip',
    formInputs: ':input',
    submit: '.btn-submit'
  },

  regions: {
    searchResultsGrid: '@ui.searchResultsGrid'
  },

  events: {
    'click @ui.search': 'onClickSearchIndividuals',
    'click @ui.submit': 'onClickSubmit',
    'keydown @ui.formInputs': 'onKeydownOfInputs'
  },

  templateHelpers: function() {
    return {
      searchLimit: this.options.searchLimit
    };
  },

  onBeforeShow: function() {
    this.individualSearchResults = new GridView({
      columns: [
        {
          name: 'selected',
          label: '',
          cell: SelectOneCell.SelectOneRowCell,
          headerCell: SelectOneCell.SelectOneHeaderCell,
          width: 10,
          editable: true,
          sortable: false
        },
        {
          name: 'firstName',
          label: 'First Name',
          cell: 'string',
          headerCell: 'custom',
          width: 30,
          editable: false
        },
        {
          name: 'lastName',
          label: 'Last Name',
          cell: 'string',
          headerCell: 'custom',
          width: 30,
          editable: false
        },
        {
          name: 'crdNumber',
          label: 'CRD Number',
          cell: 'string',
          headerCell: 'custom',
          width: 30,
          editable: false
        }
      ],
      collection: new PageableCollection([], { pageSize: 5 }),
      emptyText: 'Search returned no individuals.'
    });

    this.showChildView('searchResultsGrid', this.individualSearchResults);
  },

  onKeydownOfInputs: function(e) {
    // Searches on press of enter
    if (e.keyCode === 13) {
      this.onClickSearchIndividuals(e);
    }
  },

  onClickSearchIndividuals: function(e) {
    e.preventDefault();
    var self = this;
    this.ui.searchResultSummary.hide();
    this.ui.searchResultMax.hide();

    var data = Syphon.serialize(this, {
      inputReaders: Syphon.SearchInputReaderSet
    });

    if (!(data.firstName || data.lastName || data.crdNumber)) {
      return;
    }

    var l = Ladda.create(this.ui.search[0]);
    l.start();

    GetIndividualByNameAndCRDNumber(data)
      .done(function(data) {
        l.stop();
        self.individualSearchResults.collection.fullCollection.reset(data.individuals);
        self.ui.hitCount.text(data.totalHits);
        self.ui.searchResultSummary.show();
        if (data.totalHits === self.options.searchLimit) {
          self.ui.searchResultMax.show();
        }
      })
      .fail(function(err) {
        l.stop();
      });
  },

  onClickSubmit: function(e) {
    e.preventDefault();
    var selectedModel = this.individualSearchResults.viewGrid.getSelectedModel();
    if (selectedModel) {
      var laddaContext = Ladda.create(e.currentTarget);
      laddaContext.start();

      SubmitExamInvInqRelatedSubject({
        objectId: this.model.get('objectId'),
        objectTypeId: this.model.get('objectTypeId'),
        subjectObjectTypeId: 'INDIVIDUAL',
        subjectObjectId: selectedModel.id
      }).done(
        function(relatedSubjectId) {
          var relatedSubject = {
            id: relatedSubjectId,
            subjectObjectId: selectedModel.get('id'),
            firstName: selectedModel.get('firstName'),
            lastName: selectedModel.get('lastName'),
            middleInitial: selectedModel.get('middleInitial') || '--',
            crdNumber: selectedModel.get('crdNumber')
          };

          this.triggerMethod('modal:close', relatedSubject);
        }.bind(this)
      );
    } else {
      this.triggerMethod('modal:close');
    }
  }
});
