/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Backbone = require('backbone');
var Marionette = require('backbone.marionette');

var GridView = require('psa-marionette/views/GridView');

var modelGet = require('common/util/modelGet');
var ParagraphCell = require('common/backgrid/ParagraphCell');

var tmplDepExamOtherInformationSectionView = require('../templates/DepExamOtherInformationSectionView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplDepExamOtherInformationSectionView,

  className: 'dep-exam-other-information-section-view',

  ui: {
    violationGrid: '.violation-information-grid-container',
    concentrationGrid: '.concentration-information-grid-container'
  },

  regions: {
    violationGrid: '@ui.violationGrid',
    concentrationGrid: '@ui.concentrationGrid'
  },

  onBeforeShow: function() {
    this.showChildView(
      'concentrationGrid',
      new GridView({
        emptyText: 'No Concentrations',
        columns: [
          {
            name: 'typeDisplay',
            label: 'Type',
            cell: 'string',
            headerCell: 'custom',
            width: 20,
            editable: false
          },
          {
            name: 'subTypeDisplay',
            label: 'SubType',
            cell: 'string',
            headerCell: 'custom',
            width: 20,
            editable: false
          },
          {
            name: 'commentsDisplay',
            label: 'Comments',
            cell: ParagraphCell,
            headerCell: 'custom',
            width: 35,
            editable: false
          }
        ],
        collection: new Backbone.Collection(modelGet(this.model, 'exam.examConcentrations', []))
      })
    );

    this.showChildView(
      'violationGrid',
      new GridView({
        emptyText: 'No Exam Violations',
        columns: [
          {
            name: 'legalCiteDisplay',
            label: 'Legal Cite',
            cell: 'string',
            headerCell: 'custom',
            width: 15,
            editable: false
          },
          {
            name: 'commentsDisplay',
            label: 'Comments',
            cell: ParagraphCell,
            headerCell: 'custom',
            width: 50,
            editable: false
          }
        ],
        collection: new Backbone.Collection(modelGet(this.model, 'exam.examViolations', []))
      })
    );
  }
});
