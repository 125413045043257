/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var moment = require('moment');

var cleanUpResponse = require('common/util/cleanUpResponse');

module.exports = function(entityId) {
  var deferred = $.Deferred();

  $.soap({
    body: {
      GetAnnualMeetingsByEntity: {
        $xmlns: 'http://dobs.pa.gov/dp/director/bpm/1.0',
        EntityID: entityId
      }
    },
    blueprintArrays: ['GetAnnualMeetingsByEntityResponse.DP_AnnualMeeting'],
    blueprint: {
      annualMeetings: [
        '$..DP_AnnualMeeting',
        {
          annualMeetingId: '$..ID',
          entityId: '$..EntityID',
          schedule: '$..Schedule',
          directorCount: '$..DirectorCount',
          electionReport: '$..ElectionReport',
          lastMeetingDate: '$..LastMeetingDate',
          meetingDate: '$..MeetingDate',
          receivedDate: '$..ReceivedDate',
          acceptedDate: '$..AcceptedDate',
          isDeleted: '$..IsDeleted'
        }
      ]
    }
  })
    .done(function(response) {
      cleanUpResponse(response);

      _.each(response.annualMeetings, function(annualMeeting) {
        if (annualMeeting.lastMeetingDate) {
          annualMeeting.lastMeetingDate = moment(annualMeeting.lastMeetingDate, 'YYYY-MM-DD');
        }
        if (annualMeeting.receivedDate) {
          annualMeeting.receivedDate = moment(annualMeeting.receivedDate, 'YYYY-MM-DD');
        }
        if (annualMeeting.meetingDate) {
          annualMeeting.meetingDate = moment(annualMeeting.meetingDate, 'YYYY-MM-DD');
        }
        if (annualMeeting.acceptedDate) {
          annualMeeting.acceptedDate = moment(annualMeeting.acceptedDate, 'YYYY-MM-DD');
        }

        _.each(
          [
            'schedule',
            'directorCount',
            'electionReport',
            'lastMeetingDate',
            'meetingDate',
            'receivedDate',
            'acceptedDate'
          ],
          function(attr) {
            if (!annualMeeting[attr]) {
              annualMeeting[attr] = '--';
            }
          }
        );
      });

      deferred.resolve(response.annualMeetings);
    })
    .fail(function(err) {
      deferred.reject();
    });

  return deferred.promise();
};
