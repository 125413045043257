/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var GridView = require('psa-marionette/views/GridView');

var ButtonLinkCell = require('common/backgrid/ButtonLinkCell');
var MomentCell = require('common/backgrid/MomentCell');

module.exports = GridView.extend({
  className: 'dep-entity-audit-view',

  onBeforeRender: function() {
    var entityCategoryId = this.model.get('entityCategoryId');
    var isTrust = entityCategoryId === '3';
    var isCIF = this.model.get('isCIF') === '1';
    var isCIFAndTrust = isTrust && isCIF;

    this.options.columns = [
      {
        name: 'auditDate',
        label: 'Audit Date',
        cell: MomentCell.MomentCell,
        formatter: MomentCell.MomentDateFormatter.extend({
          emptyValue: '--'
        }),
        sortValue: MomentCell.MomentSortValue,
        headerCell: 'custom',
        width: isTrust ? 20 : 10,
        editable: false
      },
      {
        name: 'auditType',
        label: 'Audit Type',
        cell: 'string',
        headerCell: 'custom',
        width: isTrust ? 20 : 15,
        editable: false
      },
      {
        name: 'receivedDate',
        label: 'Received Date',
        cell: MomentCell.MomentCell,
        formatter: MomentCell.MomentDateFormatter.extend({
          emptyValue: '--'
        }),
        sortValue: MomentCell.MomentSortValue,
        headerCell: 'custom',
        width: isTrust ? 20 : 15,
        editable: false,
        renderable: !isCIFAndTrust
      },
      {
        name: 'opinion',
        label: 'Opinion',
        cell: 'string',
        headerCell: 'custom',
        width: 25,
        editable: false,
        renderable: isCIFAndTrust || !isTrust
      },
      {
        name: 'auditFirm',
        label: 'Audit Firm',
        cell: 'string',
        headerCell: 'custom',
        width: isTrust ? 30 : 25,
        editable: false
      },
      {
        name: '',
        label: '',
        cell: ButtonLinkCell.extend({
          name: 'Open',
          buttonClass: 'btn-primary btn-sm',
          href: function(model) {
            var entityId = model.get('entityId');
            var auditId = model.get('id');
            return '#dep/entity/' + entityId + '/audit/' + auditId + '/dashboard';
          }
        }),
        headerCell: 'custom',
        width: 10,
        editable: false,
        sortable: false
      }
    ];
    this.options.emptyText = 'No Audits';
  }
});
