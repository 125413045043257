/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var cleanUpResponse = require('common/util/cleanUpResponse');

module.exports = function(examId) {
  var deferred = $.Deferred();

  $.soap({
    body: {
      GetExamIdentifiers: {
        $xmlns: 'http://dobs.pa.gov/sc/exam/bpm/1.0',
        ExamID: examId
      }
    },
    blueprint: {
      examId: '$..ID',
      crdNumber: '$..CRDNumber',
      dbaName: '$..DBAName',
      applicantLegalName: '$..ApplicantLegalName',
      CSFolderId: '$..CSFolderID',
      organizationLicenseId: '$..OrganizationLicenseID',
      organizationId: '$..OrganizationID',
      examNumber: '$..ExamNumber',
      licenseType: '$..LicenseType'
    }
  })
    .done(function(response) {
      cleanUpResponse(response);

      // Format identifiers to be used in compliance breadcrumb view
      response.objectId = response.examId;
      response.orgIndObjectId = response.organizationId;
      response.licenseId = response.organizationLicenseId;
      response.complianceObjectTypeDisplay = 'Exam';
      response.examCaseNumber = response.examNumber;

      // For headerbar title
      response.title = 'Exam';

      deferred.resolve(response);
    })
    .fail(function(err) {
      deferred.reject();
    });
  return deferred.promise();
};
