/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var GetIndividualsByEntityAndDepartment = require('../services/GetIndividualsByEntityAndDepartment');

module.exports = {
  channel: 'cs',

  event: 'build:individual-modal-list',

  builder: function(entityId, departmentId) {
    return buildIndividualsDropdown(entityId, departmentId);
  }
};

function buildIndividualsDropdown(entityId, departmentId) {
  var deferred = $.Deferred();
  GetIndividualsByEntityAndDepartment(entityId, departmentId)
    .done(function(response) {
      deferred.resolve(response);
    })
    .fail(function(err) {
      deferred.reject(err);
    });

  return deferred.promise();
}
