/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var _get = require('lodash.get');

var momentUtil = require('common/util/momentUtil');

module.exports = function(formData) {
  var defer = $.Deferred();

  var isDraft = formData.isDraft;
  var externalUserId = formData.externalUserId;
  var complaintId = formData.complaintId;
  var complainant = formData.complainant;
  var serviceMember = formData.serviceMember;
  var company = formData.company;
  var information = formData.information;
  var resolution = formData.resolution;

  var formOutboundDateConfig = {
    sourceFormat: 'MM/DD/YYYY',
    targetFormat: 'YYYY-MM-DD',
    default: ''
  };
  information.initialInvestmentDate = momentUtil.formatStr(information.initialInvestmentDate, formOutboundDateConfig);
  information.lastInvestmentDate = momentUtil.formatStr(information.lastInvestmentDate, formOutboundDateConfig);

  $.soap({
    body: {
      SubmitInvestorComplaint: {
        $xmlns: 'http://dobs.pa.gov/cs/complaint/bpm/1.0',
        IsDraft: isDraft,
        ExternalUserID: externalUserId,
        ComplaintID: complaintId,
        ContactFirstName: complainant.firstName,
        ContactLastName: complainant.lastName,
        ContactEmail: complainant.email,
        ContactDaytimePhone: complainant.phoneNumber,
        ContactWorkPhone: complainant.workPhoneNumber,
        ContactAddressLine1: complainant.address.addressLine1,
        ContactAddressLine2: complainant.address.addressLine2,
        ContactCity: complainant.address.city,
        ContactState: complainant.address.state,
        ContactZipCode: complainant.address.postalCode,
        ContactCountry: complainant.address.country,
        ContactCounty: complainant.address.county,
        ContactContactPreference: complainant.contactPreference,
        IsInvestor: complainant.isInvestor,
        InvestorRelationship: complainant.investorRelation,
        InvestorFirstName: complainant.investorFirstName,
        InvestorLastName: complainant.investorLastName,
        InvestorEmail: complainant.investorEmail,
        InvestorPhoneNumber: complainant.investorPhoneNumber,
        InvestorCellPhoneNumber: complainant.investorCellPhoneNumber,
        InvestorAddressLine1: complainant.investorAddress.addressLine1,
        InvestorAddressLine2: complainant.investorAddress.addressLine2,
        InvestorCity: complainant.investorAddress.city,
        InvestorState: complainant.investorAddress.state,
        InvestorZipCode: complainant.investorAddress.postalCode,
        InvestorCountry: complainant.investorAddress.country,
        InvestorContactPreference: complainant.investorContactPreference,
        Documents: (function() {
          if (!_get(information, 'documents.length')) {
            return {};
          }
          return {
            Document: _.map(information.documents, function(document) {
              return {
                DocumentID: document.fileId,
                DocumentTypeID: document.documentTypeId
              };
            })
          };
        })(),
        ServiceMemberStatusID: serviceMember.isVeteranServiceMember === "0" ? "1" : serviceMember.serviceMemberStatus, // 2 veteran, 3 active duty, 4 reserve
        CompanyName: company.name,
        CompanyEmail: company.email,
        CompanyPhone: company.phoneNumber,
        CompanyWebsite: company.website,
        CompanyAddressLine1: company.address.addressLine1,
        CompanyAddressLine2: company.address.addressLine2,
        CompanyCity: company.address.city,
        CompanyState: company.address.state,
        CompanyZipCode: company.address.postalCode,
        CompanyCountry: company.address.country,
        CompanyCounty: company.address.county,
        HasAttemptedContact: company.hasContactedAttempt, // HasAttemptedContactWithCompany
        ContactedCompanyIndividuals: company.contactIndividuals, // ContactedCompanyIndividuals
        Synopsis: information.natureOfComplaint, // Synopsis
        InitialInvestmentDate: information.initialInvestmentDate,
        LastInvestmentDate: information.lastInvestmentDate,
        DesiredResolution: resolution.desiredResolution, 
        IsInformationalOnly: information.isInformationalOnly ? 1 : 0,
        IsAuthorityAffirmed: resolution.isAffirmed ? 1 : 0,
        CanTestify: resolution.canTestify,
        NoTestifyReason: resolution.noTestifyReason
      }
    },
    blueprint: {
      newComplaintId: '$..ComplaintID.__text'
    }
  })
    .done(function(response) {
      defer.resolve(response.newComplaintId);
    })
    .fail(function(err) {
      defer.reject();
    });

  return defer.promise();
};
