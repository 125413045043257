/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Marionette = require('backbone.marionette');

var CorpFinOfferingSectionView = require('../../common/views/CorpFinOfferingSectionView');
var CorpFinCorrespondentSectionView = require('../../common/views/CorpFinCorrespondentSectionView');
var CorpFinRegRegistrantSectionView = require('./CorpFinRegRegistrantSectionView');

var tmplCorpFinRegRegistrationSectionView = require('../templates/CorpFinRegRegistrationSectionView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplCorpFinRegRegistrationSectionView,

  className: 'corp-fin-reg-registration-section-view form-horizontal form-static m-b-lg',

  ui: {
    registrant: '.registrant',
    offering: '.offering',
    correspondent: '.correspondent'
  },

  regions: {
    registrant: '@ui.registrant',
    offering: '@ui.offering',
    correspondent: '@ui.correspondent'
  },

  onBeforeShow: function() {
    this.showChildView('registrant', new CorpFinRegRegistrantSectionView({ model: this.model }));

    this.showChildView('offering', new CorpFinOfferingSectionView({ model: this.model }));

    this.showChildView('correspondent', new CorpFinCorrespondentSectionView({ model: this.model }));
  }
});
