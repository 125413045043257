/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var Marionette = require('backbone.marionette');
var Syphon = require('backbone.syphon');

var parsleyUtil = require('common/util/parsleyUtil');
var FileUploadView = require('common/views/FileUploadView');

var FileUploadCollection = require('psa-backbone/models/FileUploadCollection');

require('./SalesFinanceGeneralDetailsSectionView.css');
var tmplSalesFinanceGeneralDetailsSectionView = require('./SalesFinanceGeneralDetailsSectionView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplSalesFinanceGeneralDetailsSectionView,

  className: 'sales-finance-general-details-section-view',

  ui: {
    generalDetailsForm: '.general-details-form',
    applicationDocumentsFileContainer: '.application-documents-container',
    hasManufacturedHomeContract: 'input[name="hasManufacturedHomeContract"]',
    otherManufacturedHomeContract: 'input[name="otherManufacturedHomeContract"]',
    otherContractsExplanationArea: '#other-contracts-explanation-area',
    otherContractsExplanation: 'input[name="otherContractsExplanation"]',
    bondFileContainer: '.bond-file-container'
  },

  events: {
    'change @ui.hasManufacturedHomeContract': 'setManufacturedHomeContract'
  },

  regions: {
    applicationDocumentsFileContainer: '@ui.applicationDocumentsFileContainer',
    bondFileContainer: '.bond-file-container'
  },

  behaviors: {
    RadioGroupToggleBehavior: {
      radios: [
        {
          target: '#hasManufacturedHomeContractQuestion2',
          radioGroup: '[name="hasManufacturedHomeContract"]',
          valueToShow: '1'
        },
        {
          target: '#other-contracts-explanation-area',
          radioGroup: '[name="otherManufacturedHomeContract"]',
          valueToShow: '1'
        },
      ]
    }
  },

  templateHelpers: function() {
    return {
      identoGoLink: this.options.identoGoLink
    };
  },

  onBeforeShow: function() {
    var application = this.model.get('application');

    this.applicationDocumentsFile = new FileUploadView({
      isRequired: true,
      allowMultipleFiles: true,
      collection: new FileUploadCollection(application.applicationDocuments),
      isReadOnly: this.options.isReadOnly,
      metadata: {
        documentTypeId: 10023
      }
    });
    this.showChildView('applicationDocumentsFileContainer', this.applicationDocumentsFile);

    if (application.bondDocument.fileId === null) {
      application.bondDocument = null;
    }

    this.bondFile = new FileUploadView({
      isRequired: true,
      collection: new FileUploadCollection(application.bondDocument),
      isReadOnly: this.options.isReadOnly,
      metadata: {
        documentTypeId: 10019
      }
    });
    this.showChildView('bondFileContainer', this.bondFile);
    
    Syphon.deserialize(this, application);
  },
  

  setManufacturedHomeContract: function() {
    var formData = Syphon.serialize(this);
    this.ui.hasManufacturedHomeContract = formData.hasManufacturedHomeContract;
    this.ui.otherManufacturedHomeContract = formData.otherManufacturedHomeContract;
    this.ui.otherContractsExplanation = formData.otherContractsExplanation;

   
    if (this.ui.hasManufacturedHomeContract === '0') {
  this.ui.otherContractsExplanationArea.hide();
 }
    if (this.ui.hasManufacturedHomeContract === '1') {
      if (this.ui.otherManufacturedHomeContract === '1') {
     this.ui.otherContractsExplanationArea.show();
    }
    }
  },
  
 
  validate: function(config) {
    this.ui.generalDetailsForm.parsley().reset();
    var validationPromises = []
    if (config.type === 'save') {
      // Turn off required field validation for this form
      parsleyUtil.disableRequiredValidation(this.ui.generalDetailsForm);
      validationPromises.push(this.ui.generalDetailsForm.parsley().whenValidate());
    } else {
      // Turn on required field validation for this form
      parsleyUtil.enableRequiredValidation(this.ui.generalDetailsForm);
      validationPromises.push(this.ui.generalDetailsForm.parsley().whenValidate());
      validationPromises.push(this.bondFile.validate());
    }
    return $.when.apply($, validationPromises);
  },

  getFormData: function() {
    var formData = Syphon.serialize(this);
    _.extend(formData, {
      bondDocumentId: this.bondFile.getFirstFileId(),
      applicationDocuments: this.applicationDocumentsFile.collection.pluck('fileId')
    });
    if (this.ui.hasManufacturedHomeContract === '0') {
      formData.otherContractsExplanation = null;
      formData.otherManufacturedHomeContract = null
    }
    return formData;
  }
});
