/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var NProgress = require('nprogress');

var NavUtils = require('psa-core/util/nav');

var Scaffold = require('common/layout/Scaffold');

var GetExamPeriodById = require('../services/GetExamPeriodById');
var GetAssignExamDashboard = require('../services/GetAssignExamDashboard');
var GetUsersForRole = require('common/services/GetUsersForRole');

var NonDepInternalAssignExamsView = require('../views/NonDepInternalAssignExamsView');

module.exports = function(examYearId) {
  NProgress.start();
  NProgress.set(0.6);

  $.when(GetExamPeriodById(examYearId), GetAssignExamDashboard(examYearId), GetUsersForRole('ND - Examiner')).done(
    function(examPeriod, exams, examiners) {
      NavUtils.navigate('nondep/dashboard/exams/' + examYearId + '/assign');
      Scaffold.content.show(
        new NonDepInternalAssignExamsView({
          model: new Backbone.Model({
            examYear: examPeriod.yearPeriod,
            examYearId: examPeriod.id,
            exams: exams,
            examiners: examiners
          })
        })
      );
      NProgress.done();
    }
  );
};
