var Handlebars = require("handlebars/dist/handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "    <li>\n      <a class=\"menu-item\" href=\"#sec/examiner-dashboard\">\n        <i class=\"menu-item-icon fa fa-window-maximize fa-fw\"></i>\n        <span class=\"menu-item-label\">Examiner Dashboard</span>\n      </a>\n    </li>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "\n<div class=\"on-deck-view\">\n  <p>ON DECK</p>\n  <ul class=\"nav nav-pills nav-stacked\">\n    <li>\n      <a class=\"menu-item\" href=\"#sec/dashboard\">\n        <i class=\"menu-item-icon fa fa-home fa-fw\"></i>\n        <span class=\"menu-item-label\">My Tasks</span>\n      </a>\n    </li>\n    <li>\n      <a class=\"menu-item\" href=\"#sec/dashboard/all-tasks\">\n        <i class=\"menu-item-icon fa fa-globe fa-fw\"></i>\n        <span class=\"menu-item-label\">All Tasks</span>\n      </a>\n    </li>\n  </ul>\n</div>\n<div class=\"menu-view\">\n  <p>MENU</p>\n  <ul class=\"nav nav-pills nav-stacked\">\n    <li>\n      <a class=\"menu-item\" href=\"#sec/organization-search\">\n        <i class=\"menu-item-icon fa fa-building fa-fw\"></i>\n        <span class=\"menu-item-label\">Organization</span>\n      </a>\n    </li>\n    <li>\n      <a class=\"menu-item\" href=\"#sec/individual-search\">\n        <i class=\"menu-item-icon fa fa-user fa-fw\"></i>\n        <span class=\"menu-item-label\">Individual</span>\n      </a>\n    </li>\n    <li>\n      <a class=\"menu-item\" href=\"#sec/application-search\">\n        <i class=\"menu-item-icon fa fa-file fa-fw\"></i>\n        <span class=\"menu-item-label\">Applications</span>\n      </a>\n    </li>\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.isExaminer : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "    <li>\n      <a class=\"menu-item\" href=\"#sec/exam-search\">\n        <i class=\"menu-item-icon fa fa-list-ol fa-fw\"></i>\n        <span class=\"menu-item-label\">Exams</span>\n      </a>\n    </li>\n    <li>\n      <a class=\"menu-item\" href=\"#sec/investigation-inquiry-search\">\n        <i class=\"menu-item-icon fa fa-clipboard fa-fw\"></i>\n        <span class=\"menu-item-label\">Investigations/Inquiries</span>\n      </a>\n    </li>\n    <li>\n      <a class=\"menu-item\" href=\"#sec/next-step-search\">\n        <i class=\"menu-item-icon fa fa-forward fa-fw\"></i>\n        <span class=\"menu-item-label\">Next Steps</span>\n      </a>\n    </li>\n    <li>\n      <a class=\"menu-item\" href=\""
    + container.escapeExpression(((helper = (helper = helpers.iHubFolderLink || (depth0 != null ? depth0.iHubFolderLink : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"iHubFolderLink","hash":{},"data":data}) : helper)))
    + "\" target=\"_blank\">\n        <i class=\"menu-item-icon fa fa-bar-chart fa-fw\"></i>\n        <span class=\"menu-item-label\">Reports</span>\n        <i class=\"fa fa-external-link external-link\"></i>\n      </a>\n    </li>\n  </ul>\n</div>";
},"useData":true});