/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Marionette = require('backbone.marionette');
var Syphon = require('backbone.syphon');

var parsleyUtil = require('common/util/parsleyUtil');
var toggleForm = require('common/util/toggleForm');
var makeUploadCollection = require('common/util/makeUploadCollection');

var CommentsDocumentView = require('common/views/CommentsDocumentView');

var tmplDepNewBankCharterQuestionnaireSectionView = require('./DepNewBankCharterQuestionnaireSectionView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplDepNewBankCharterQuestionnaireSectionView,

  className: 'dep-new-bank-charter-questionnaire-section-view',

  behaviors: {
    ReadOnlyFormBehavior: {}
  },

  ui: {
    documentForm: '.document-form',
    depositCheckbox: '#depositInsuranceUploadedCheckbox',
    questionnaireContainer: '.questionnaire-container',
    additionalInfo: '.alert-info'
  },

  regions: {
    questionnaireContainer: '@ui.questionnaireContainer'
  },

  events: {
    'change @ui.depositCheckbox': 'onCheckDepositInsuranceUploaded'
  },

  templateHelpers: function() {
    return {
      isReadOnly: this.options.isReadOnly
    };
  },

  onBeforeShow: function() {
    var filingContent = this.model.get('filingContent') || {};
    var questionnaire = filingContent.questionnaire || {};

    Syphon.deserialize(this, questionnaire);

    this.questionnaire = new CommentsDocumentView({
      isReadOnly: this.options.isReadOnly,
      allowMultipleFiles: false,
      isRequired: true,
      fileUploadLabel: 'Questionnaire',
      commentsLabel: 'Questionnaire Comments',
      commentsName: 'questionnaireComments',
      collection: makeUploadCollection(questionnaire.file),
      model: new Backbone.Model({ questionnaireComments: questionnaire.questionnaireComments }),
      documentTypeId: 20001
    });
    this.showChildView('questionnaireContainer', this.questionnaire);

    toggleForm(this.ui.additionalInfo, questionnaire.depositInsuranceUploaded);
    this.questionnaire.options.isRequired = !questionnaire.depositInsuranceUploaded;
    toggleForm(this.ui.questionnaireContainer, !questionnaire.depositInsuranceUploaded);
  },

  onCheckDepositInsuranceUploaded: function(e) {
    e.preventDefault();
    var formData = Syphon.serialize(this);
    toggleForm(this.ui.additionalInfo, formData.depositInsuranceUploaded);
    this.questionnaire.options.isRequired = !formData.depositInsuranceUploaded;
    toggleForm(this.ui.questionnaireContainer, !formData.depositInsuranceUploaded);
  },

  validate: function(config) {
    this.ui.documentForm.parsley().reset();
    this.questionnaire.removeValidationErrors();

    if (config.type === 'save') {
      // Turn off required field validation for this form
      parsleyUtil.disableRequiredValidation(this.ui.documentForm);
    } else {
      var validationPromises = [this.ui.documentForm.parsley().whenValidate(), this.questionnaire.validate()];

      // Turn on required field validation for this form
      parsleyUtil.enableRequiredValidation(this.ui.documentForm);
    }
    return $.when.apply($, validationPromises);
  },

  getFormData: function() {
    var formData = Syphon.serialize(this);
    formData.depositInsuranceUploaded = formData.depositInsuranceUploaded ? '1' : '0';
    if (formData.depositInsuranceUploaded === '0') {
      formData.docId = this.questionnaire.getFirstFileId();
    }

    return formData;
  }
});
