/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Backbone = require('backbone');

var HeaderBarView = require('common/views/HeaderBarView');

module.exports = HeaderBarView.extend({
  options: {
    columns: new Backbone.Collection([
      {
        name: 'licenseType',
        label: 'License Type'
      },
      {
        name: 'licenseNumber',
        label: 'License Number'
      },
      {
        name: 'licenseStatus',
        label: 'Status'
      },
      {
        name: 'originalIssueDate',
        label: 'Original License Date'
      }
    ])
  },

  initialize: function() {
    if (!this.model.has('title')) {
      this.model.set('title', 'License');
    }
  }
});
