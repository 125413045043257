/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var Marionette = require('backbone.marionette');
var Syphon = require('backbone.syphon');

var UIUtil = require('psa-core/util/ui');
var GridView = require('psa-marionette/views/GridView');
var PageableCollection = require('psa-backbone/models/PageableCollection');

var toggleForm = require('common/util/toggleForm');
var MultiButtonCell = require('common/backgrid/MultiButtonCell');
var TruncateStringCell = require('common/backgrid/TruncateStringCell');
var LinkCell = require('common/backgrid/LinkCell');

var SecNextStepsEditModalView = require('./SecNextStepEditModalView');
var SecInternalSidebarNavView = require('./SecInternalSidebarNavView');

require('../styles/SecNextStepSearchView.css');
var tmplSecNextStepSearchView = require('../templates/SecNextStepSearchView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplSecNextStepSearchView,

  className: 'sec-next-step-search-view container-fluid',

  ui: {
    sideBar: '.side-bar-container',

    searchSummary: '.search-summary',
    searchResultsContainer: '.search-results-container',
    search: '.search',
    searchType: 'select[name="objectType"]',
    orgFilters: '.org-filter',
    indFilters: '.ind-filter',
    clearFilters: '.clear-filters',
    formInputs: ':input'
  },

  regions: {
    sideBar: '@ui.sideBar',
    searchResultsContainer: '@ui.searchResultsContainer',
    searchSummary: '@ui.searchSummary'
  },

  events: {
    'click @ui.search': 'onClickSearch',
    'change @ui.searchType': 'onChangeSearchType',
    'click @ui.clearFilters': 'onClickClearFilters',
    'keydown @ui.formInputs': 'onKeydownOfInputs'
  },

  onBeforeShow: function() {
    this.showChildView('sideBar', new SecInternalSidebarNavView());

    this.collection = new PageableCollection(this.model.get('nextSteps'), { pageSize: 25 });
    this.shadowCollection = new PageableCollection(this.model.get('nextSteps'), { pageSize: 25 });

    this.searchResults = new GridView({
      columns: [
        {
          name: 'caseNumber',
          label: 'Exam/Inq/Inv Number',
          cell: 'string',
          width: 8,
          editable: false
        },
        {
          name: 'nextStepType',
          label: 'Exam/Inq/Inv',
          cell: 'string',
          headerCell: 'custom',
          width: 5,
          editable: false
        },
        {
          name: 'displayName',
          label: 'Name',
          cell: LinkCell.extend({
            emptyText: '--',
            newTab: true,
            href: function(model) {
              var url = '';
              var objectId = model.get('objectId');
              var objectType = model.get('objectType');

              if (objectType === 'ORGANIZATION') {
                url = '#sec/organization/' + objectId + '/dashboard';
              } else if (objectType === 'INDIVIDUAL') {
                url = '#sec/individual/' + objectId + '/dashboard';
              }

              return url;
            }
          }),
          headerCell: 'custom',
          width: 10,
          editable: false
        },
        {
          name: 'nextStepStatus',
          label: 'Status',
          cell: TruncateStringCell,
          headerCell: 'custom',
          width: 26,
          editable: false
        },
        {
          name: 'nextStepDescription',
          label: 'Next Steps',
          cell: TruncateStringCell,
          headerCell: 'custom',
          width: 25,
          editable: false
        },
        {
          name: 'nextStepProjectedDate',
          label: 'Target Date',
          cell: 'string',
          headerCell: 'custom',
          width: 8,
          editable: false
        },
        {
          name: 'nextStepAssignedTo',
          label: 'Assigned To',
          cell: 'string',
          headerCell: 'custom',
          width: 10,
          editable: false
        },
        {
          name: '',
          label: '',
          cell: MultiButtonCell.extend({
            buttons: [
              {
                name: '',
                labelClass: 'fa fa-edit',
                buttonClass: 'btn-primary btn-sm',
                onClick: this.onClickEditNextStep.bind(this)
              },
              {
                name: '',
                labelClass: 'fa fa-external-link',
                buttonClass: 'btn-primary btn-sm',
                newTab: true,
                href: function(model) {
                  var nextStepType = model.get('nextStepType'),
                    objectId = model.get('id');
                  if (nextStepType === 'EXAM') {
                    return '#sec/exam/' + objectId + '/dashboard';
                  } else {
                    return '#sec/investigation-inquiry/' + objectId + '/dashboard';
                  }
                }
              }
            ]
          }),
          headerCell: 'custom',
          width: 8,
          sortable: false,
          editable: false
        }
      ],
      collection: this.shadowCollection,
      emptyText: 'No Next Steps Found'
    });

    toggleForm(this.ui.orgFilters, false);
    toggleForm(this.ui.indFilters, false);

    this.showChildView('searchResultsContainer', this.searchResults);

    this.$('.backgrid.table').addClass('table-bordered');
  },

  onClickEditNextStep: function(e, model) {
    e.preventDefault();
    UIUtil.showModal({
      title: 'Edit Next Step',
      view: new SecNextStepsEditModalView({
        model: model,
        scUsers: this.model.get('scUsers')
      })
    }).done(function(updatedNextStep) {
      model.set(updatedNextStep);
    });
  },

  onChangeSearchType: function(e) {
    var type = this.ui.searchType.val();

    toggleForm(this.ui.orgFilters, type === 'ORGANIZATION');
    toggleForm(this.ui.indFilters, type === 'INDIVIDUAL');
  },

  onKeydownOfInputs: function(e) {
    // Searches on press of enter
    if (e.keyCode === 13) {
      this.onClickSearch(e);
    }
  },

  onClickSearch: function(e) {
    e.preventDefault();
    var self = this;
    var filter = Syphon.serialize(this, {
      inputReaders: Syphon.SearchInputReaderSet
    });
    filter = _.pick(filter, _.identity);
    this.shadowCollection.fullCollection.reset(this.collection.fullCollection.models);
    var models;

    _.each(filter, function(filterVal, filterType, filter) {
      models = self.shadowCollection.fullCollection.filter(function(model) {
        return self.filter(model, filterType, filterVal);
      });
      self.shadowCollection.fullCollection.reset(models);
    });
  },

  filter: function(shadow, filterType, filterVal) {
    if (filterType === 'nextStepType' || filterType === 'nextStepAssignedTo' || filterType === 'objectType') {
      return shadow.attributes[filterType] === filterVal;
    } else {
      var temp = isNaN(shadow.attributes[filterType])
        ? shadow.attributes[filterType].toLowerCase()
        : shadow.attributes[filterType];
      filterVal = isNaN(filterVal) ? filterVal.toLowerCase() : filterVal;
      return typeof temp === 'string' && temp.indexOf(filterVal) > -1;
    }
  },

  onClickClearFilters: function() {
    this.ui.formInputs.val('');
  }
});
