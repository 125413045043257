/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var moment = require('moment');

var cleanUpResponse = require('common/util/cleanUpResponse');

module.exports = function(segId) {
  var defer = $.Deferred();

  $.soap({
    body: {
      GetSEG: {
        $xmlns: 'http://dobs.pa.gov/dp/seg/bpm/1.0',
        SegID: segId
      }
    },
    blueprintArrays: ['GetSEGResponse.DP_EntitySEGIndividual'],
    blueprint: {
      entityId: '$..EntityID',
      name: '$..Name',
      isNonProfit: '$..IsNonProfit',
      entityTypeId: '$..EntityTypeID',
      entityType: '$..EntityType',
      stateOfIncorporation: '$..StateOfIncorporation',
      website: '$..Website',
      employeeCount: '$..EmployeeCount',
      statusId: '$..StatusID',
      status: '$..Status',
      addressId: '$..AddressID',
      approvalDate: '$..ApprovalDate',
      terminationDate: '$..TerminationDate',
      addressLine1: '$..AddressLine1',
      addressLine2: '$..AddressLine2',
      addressLine3: '$..AddressLine3',
      county: '$..County',
      city: '$..City',
      state: '$..State',
      postalCode: '$..PostalCode',
      country: '$..Country',
      comments: '$..Comments',
      csFolderId: '$..CSFolderID',
      segIndividuals: [
        '$..DP_EntitySEGIndividual',
        {
          individualTypeId: '$..IndividualTypeID',
          individualType: '$..IndividualType'
        }
      ]
    }
  })
    .done(function(response) {
      cleanUpResponse(response);
      if (response.approvalDate) {
        response.approvalDate = moment(response.approvalDate, 'YYYY-MM-DD').format('MM/DD/YYYY');
      }
      if (response.terminationDate) {
        response.terminationDate = moment(response.terminationDate, 'YYYY-MM-DD').format('MM/DD/YYYY');
      }
      response.segIndividualList = _.pluck(response.segIndividuals, 'individualType');
      _.each(response.segIndividuals, function(ind) {
        var individualType = ind.individualType;
        response[individualType] = '1';
      });

      defer.resolve(response);
    })
    .fail(function(err) {
      defer.reject(err);
    });

  return defer.promise();
};
