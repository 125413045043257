/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Backbone = require('backbone');
var Marionette = require('backbone.marionette');
var Radio = require('backbone.radio');
var Ladda = require('ladda');

var modelGet = require('common/util/modelGet');
var ContentServerLinkView = require('common/views/ContentServerLinkView');
var BreadcrumbView = require('common/views/BreadcrumbView');
var HeaderBarView = require('common/views/HeaderBarView');

var SubmitOrganizationLicenseFinancials = require('../services/SubmitOrganizationLicenseFinancials');
var SecOrganizationLicenseFinancialsFormView = require('./SecOrganizationLicenseFinancialsFormView');

var tmplSecOrganizationLicenseFinancialsEditView = require('../templates/SecOrganizationLicenseFinancialsEditView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplSecOrganizationLicenseFinancialsEditView,

  className: 'sec-organization-license-financials-edit-view container-fluid m-b-lg',

  ui: {
    financialsFormContainer: '.financials-form-container',
    breadcrumb: '.breadcrumb-container',
    headerBar: '.header-bar-container',
    submit: '.btn-submit',
    csLinkContainer: '.cs-link-container'
  },

  regions: {
    financialsFormContainer: '@ui.financialsFormContainer',
    breadcrumb: '@ui.breadcrumb',
    headerBar: '@ui.headerBar',
    csLinkContainer: '@ui.csLinkContainer'
  },

  events: {
    'click @ui.submit': 'onClickSubmit'
  },

  onBeforeShow: function() {
    var identifiers = this.model.get('identifiers') || {};
    var financials = this.model.get('financials') || {};
    var licenseId = this.model.get('licenseId') || {};
    var organizationId = modelGet(this.model, 'organizationId');
    var financialsId = financials.id;

    identifiers.financialYear = financials.financialYear || '--';
    identifiers.fiscalYearEnd = financials.fiscalYearEnd || '--';

    identifiers.title = 'Financials';
    var headerBarColumns = [
      {
        name: 'CRDNumber',
        label: 'CRD Number',
        minWidth: '160px',
        maxWidth: '200px'
      },
      {
        name: 'financialYear',
        label: 'Financial Year'
      },
      {
        name: 'fiscalYearEnd',
        label: 'Fiscal Year End'
      }
    ];

    this.showChildView(
      'headerBar',
      new HeaderBarView({
        columns: new Backbone.Collection(headerBarColumns),
        model: new Backbone.Model(identifiers)
      })
    );

    this.showChildView(
      'breadcrumb',
      new BreadcrumbView({
        breadcrumbs: [
          {
            link: '#sec/dashboard',
            text: 'Home'
          },
          {
            link: '#sec/organization/' + organizationId + '/dashboard',
            text: identifiers.applicantLegalName
          },
          {
            link: '#sec/organization/' + organizationId + '/license/' + licenseId + '/dashboard',
            text: identifiers.type
          },
          {
            link:
              '#sec/organization/' +
              organizationId +
              '/license/' +
              licenseId +
              '/financials/' +
              financialsId +
              '/dashboard',
            text: 'Financials'
          },
          {
            text: 'Edit Financials'
          }
        ]
      })
    );

    this.showChildView(
      'csLinkContainer',
      new ContentServerLinkView({
        model: new Backbone.Model({
          buttonText: 'Open Financials Folder',
          contentServerId: financials.CSFolderId
        })
      })
    );

    this.financialsForm = new SecOrganizationLicenseFinancialsFormView({
      model: this.model
    });

    this.showChildView('financialsFormContainer', this.financialsForm);
  },

  onClickSubmit: function(e) {
    e.preventDefault();
    this.validate().done(
      function() {
        var laddaContext = Ladda.create(this.ui.submit.get(0));
        laddaContext.start();
        var formData = this.getFormData();
        formData.isDeleted = 0;
        SubmitOrganizationLicenseFinancials(formData)
          .done(function() {
            Radio.channel('navigate').trigger(
              'show:int:sc:organization:license:financials',
              formData.organizationId,
              formData.licenseId,
              formData.financialsId
            );
          })
          .fail(function() {
            laddaContext.stop();
          });
      }.bind(this)
    );
  },

  validate: function() {
    return this.ui.financialsFormContainer.parsley().whenValidate();
  },

  getFormData: function() {
    return this.financialsForm.getFormData();
  }
});
