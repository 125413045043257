/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var cleanUpResponse = require('common/util/cleanUpResponse');

module.exports = function(applicationId) {
  var deferred = $.Deferred();

  $.soap({
    body: {
      GetCollectorRepossessorEmployeeApplication: {
        $xmlns: 'http://dobs.pa.gov/nd/application/bpm/1.0',
        ApplicationID: applicationId
      }
    },
    blueprintArrays: ['GetCollectorRepossessorEmployeeApplicationResponse.ApplicationDocuments.ND_ApplicationDocument'],
    blueprint: {
      applicationId: '$..ID.__text',
      employeeName: '$..FullName.__text',
      felony: '$..HasCriminalHistory.__text',
      felonyCircumstances: '$..HasCriminalHistoryExplaination.__text',
      proofOfEmploymentDocument: {
        fileId: '$..ProofOfEmploymentDocumentID.__text',
        fileName: '$..ProofOfEmploymentName.__text'
      },
      licenseId: '$..LicenseID.__text',
      entityId: '$..EntityID.__text',
      addressLine1: '$..AddressLine1.__text',
      addressLine2: '$..AddressLine2.__text',
      addressLine3: '$..AddressLine3.__text',
      county: '$..County.__text',
      city: '$..City.__text',
      state: '$..State.__text',
      postalCode: '$..PostalCode.__text',
      country: '$..Country.__text',
      certificationName: '$..CertificationName.__text',
      isCertified: '$..IsCertified.__text',
      applicationDocuments: [
        '$..ND_ApplicationDocument',
        {
          fileId: '$.DocumentID',
          fileName: '$..Name'
        }
      ]
    }
  })
    .done(function(response) {
      cleanUpResponse(response);

      deferred.resolve(response);
    })
    .fail(function(err) {
      deferred.reject();
    });

  return deferred.promise();
};
