/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var NProgress = require('nprogress');
var NavUtils = require('psa-core/util/nav');
var Scaffold = require('common/layout/Scaffold');

var GetTrustSubFundIdentifiers = require('../services/GetTrustSubFundIdentifiers');
var GetAllFundAuditLookups = require('../services/GetAllFundAuditLookups');

var DepAuditNewView = require('../views/DepAuditNewView');

module.exports = function(entityId, fundId, subFundId) {
  NProgress.start();
  NProgress.set(0.6);

  $.when(GetTrustSubFundIdentifiers(subFundId), GetAllFundAuditLookups())
    .done(function(subFundIdentifiers, auditLookups) {
      NavUtils.navigate('#dep/entity/' + entityId + '/fund/' + fundId + '/sub-fund/' + subFundId + '/new-audit');
      Scaffold.content.show(
        new DepAuditNewView({
          viewMode: 'int',
          model: new Backbone.Model({
            entityId: entityId,
            fundId: fundId,
            subFundId: subFundId,
            entityName: subFundIdentifiers.entityName,
            fundName: subFundIdentifiers.fundName,
            subFundName: subFundIdentifiers.subFundName,
            auditLookups: auditLookups
          })
        })
      );
      NProgress.done();
    })
    .fail(function(err) {
      NProgress.done();
    });
};
