/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');

var cleanUpResponse = require('common/util/cleanUpResponse');
var momentUtil = require('common/util/momentUtil');

module.exports = function(examId) {
  var deferred = $.Deferred();

  $.soap({
    body: {
      GetExam: {
        $xmlns: 'http://dobs.pa.gov/sc/exam/bpm/1.0',
        ExamID: examId
      }
    },
    blueprintArrays: ['GetExamResponse.Violations.SC_ExamInvInqViolation'],
    blueprint: {
      examId: '$..ID',
      processInstanceId: '$..ProcessInstanceID',
      crdNumber: '$..CRDNumber',
      dbaName: '$..DBAName',
      licenseDescription: '$..LicenseDescription',
      CSFolderId: '$..CSFolderID',
      organizationLicenseId: '$..OrganizationLicenseID',
      organizationId: '$..OrganizationID',
      primaryExaminer: '$..PrimaryExaminer',
      primaryExaminerFirstName: '$..PrimaryExaminerFirstName',
      primaryExaminerLastName: '$..PrimaryExaminerLastName',
      examNumber: '$..ExamNumber',
      statusId: '$..StatusID',
      status: '$..Status',
      workflowStatusId: '$..WorkflowStatusID',
      workflowStatus: '$..WorkflowStatus',
      openedDate: '$..OpenedDate',
      closedDate: '$..ClosedDate',
      description: '$..Description',
      typeId: '$..TypeID',
      type: '$..Type',
      subtypeId: '$..SubtypeID',
      subtype: '$..Subtype',
      categoryId: '$..CategoryID',
      category: '$..Category',
      conductedLocationId: '$..ConductedLocationID',
      locationForExam: '$..LocationForExam',
      location: '$..Location',
      officeAssignedId: '$..OfficeAssignedID',
      office: '$..Office',
      sourceId: '$..SourceID',
      source: '$..Source',
      branchCRDNumber: '$..BranchCRDNumber',
      branchAddressId: '$..BranchAddressID',
      addressLine1: '$..AddressLine1',
      addressLine2: '$..AddressLine2',
      addressLine3: '$..AddressLine3',
      county: '$..County',
      city: '$..City',
      state: '$..State',
      postalCode: '$..PostalCode',
      country: '$..Country',
      branchAddressIsDeleted: '$..BranchAddressIsDeleted',
      otherViolation: '$..OtherViolation',
      personRequesting: '$..PersonRequesting',
      hasNextStep: '$..HasNextStep',
      nextStepStatus: '$..NextStepStatus',
      nextStepDescription: '$..NextStepDescription',
      nextStepProjectedDate: '$..NextStepProjectedDate',
      nextStepAssignedTo: '$..NextStepAssignedTo',
      nextStepAssignedToFirstName: '$..NextStepsAssignedToFirstName',
      nextStepAssignedToLastName: '$..NextStepsAssignedToLastName',
      isDeficienciesFound: '$..IsDeficienciesFound',
      deficienciesLetterSent: '$..DeficienciesLetterSent',
      isDeleted: '$..IsDeleted',
      violations: [
        '$..SC_ExamInvInqViolation',
        {
          violationType: '$..ViolationType',
          violationTypeId: '$..ViolationTypeID'
        }
      ]
    }
  })
    .done(function(response) {
      cleanUpResponse(response);

      var formInboundDateConfig = {
        sourceFormat: 'YYYY-MM-DD',
        targetFormat: 'MM/DD/YYYY',
        default: undefined
      };

      response.openedDate = momentUtil.formatStr(response.openedDate, formInboundDateConfig);
      response.closedDate = momentUtil.formatStr(response.closedDate, formInboundDateConfig);
      response.nextStepProjectedDate = momentUtil.formatStr(response.nextStepProjectedDate, formInboundDateConfig);
      response.deficienciesLetterSent = momentUtil.formatStr(response.deficienciesLetterSent, formInboundDateConfig);

      response.hasOtherViolation = _.contains(_.pluck(response.violations, 'violationTypeId'), '33');

      if (response.hasNextStep === '0') {
        response.hasNextStepDisplay = 'No';
      } else if (response.hasNextStep === '1') {
        response.hasNextStepDisplay = 'Yes';
      }
      if (response.isDeficienciesFound === '0') {
        response.isDeficienciesFoundDisplay = 'No';
      } else if (response.isDeficienciesFound === '1') {
        response.isDeficienciesFoundDisplay = 'Yes';
      }
      deferred.resolve(response);
    })
    .fail(function(err) {
      deferred.reject();
    });

  return deferred.promise();
};
