var Handlebars = require("handlebars/dist/handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "          <option value=\""
    + alias4(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\">"
    + alias4(((helper = (helper = helpers.category || (depth0 != null ? depth0.category : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"category","hash":{},"data":data}) : helper)))
    + "</option>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "\n<div class=\"panel-body\">\n  <div class=\"row\">\n    <div class=\"col-time-category\">\n      <div class=\"form-group\">\n        <select class=\"form-control category-select m-t-sm\" name=\"categoryId\" data-parsley-required=\"true\">\n          <option value=\"\">--</option>\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.categories : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "        </select>\n      </div>\n    </div>\n    <div class=\"col-time-object\">\n      <div class=\"form-group\">\n        <div class=\"input-group object-selection\">\n          <span class=\"input-group-btn\">\n            <button class=\"btn btn-primary btn-object-selection\">Select</button>\n          </span>\n          <input type=\"text\" class=\"selected-object-name form-control\"\n            placeholder=\"Select Exam, Investigation, or Inquiry\" name=\"objectDisplayName\" readonly>\n          <span class=\"input-group-btn\">\n            <button class=\"btn btn-default btn-clear-object\"><i class=\"fa fa-times\" aria-hidden=\"true\"></i></button>\n          </span>\n          <input type=\"number\" class=\"selected-object-id\" name=\"objectId\"\n            data-parsley-errors-container=\"#timeObjErrorContainer"
    + alias4(((helper = (helper = helpers.childIndex || (depth0 != null ? depth0.childIndex : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"childIndex","hash":{},"data":data}) : helper)))
    + "\" hidden>\n        </div>\n        <span id=\"timeObjErrorContainer"
    + alias4(((helper = (helper = helpers.childIndex || (depth0 != null ? depth0.childIndex : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"childIndex","hash":{},"data":data}) : helper)))
    + "\"></span>\n        <div class=\"m-t-xs\">\n          <label class=\"m-b-n control-label font-weight-normal\">\n            <input type=\"checkbox\" class=\"show-closed-checkbox\" value=\"1\" />\n            Include billed exams, investigations, and inquiries\n          </label>\n        </div>\n      </div>\n    </div>\n    <div class=\"col-time-hrs\">\n      <div class=\"hours-container\"></div>\n    </div>\n    <div class=\"col-time-remove\">\n      <button class=\"btn btn-danger pull-right btn-sm remove-entry\">\n        <i class=\"fa fa-times\" aria-hidden=\"true\"></i>\n      </button>\n    </div>\n  </div>\n</div>";
},"useData":true});