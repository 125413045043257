/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var Marionette = require('backbone.marionette');

var GridView = require('psa-marionette/views/GridView');

var DollarCell = require('../../backgrid/DollarCell');

require('./InvoiceGridView.css');
var tmplInvoiceGridView = require('./InvoiceGridView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplInvoiceGridView,

  className: 'invoice-grid-view',

  ui: {
    paymentGrid: '.payment-grid',
    amount: '.amount'
  },

  regions: {
    paymentGrid: '@ui.paymentGrid'
  },

  options: {
    totalLabel: 'Total Amount Due'
  },

  templateHelpers: function() {
    return {
      totalLabel: this.options.totalLabel
    };
  },

  onBeforeRender: function() {
    this.collection.each(function(model) {
      var amountRaw = parseFloat(model.get('amount'));
      var quantityRaw = parseFloat(model.get('quantity'));
      var unitPriceRaw = parseFloat(model.get('unitPrice'));
      var hasAmount = _.isNumber(amountRaw) && !_.isNaN(amountRaw);
      var hasQuantity = _.isNumber(quantityRaw) && !_.isNaN(quantityRaw);
      var hasUnitPrice = _.isNumber(unitPriceRaw) && !_.isNaN(unitPriceRaw);
      if (!hasAmount && hasQuantity && hasUnitPrice) {
        var calculatedAmount = parseFloat(quantityRaw * unitPriceRaw);
        model.set('amount', calculatedAmount);
      }
    });
  },

  onBeforeShow: function() {
    this.invoiceGrid = new GridView({
      columns: [
        {
          name: 'description',
          label: 'Description',
          cell: 'string',
          headerCell: 'custom',
          width: 50,
          editable: false
        },
        {
          name: 'quantity',
          label: 'Quantity',
          cell: 'string',
          headerCell: 'custom',
          width: 10,
          editable: false
        },
        {
          name: 'unitPrice',
          label: 'Unit Price',
          cell: DollarCell,
          headerCell: 'custom',
          width: 20,
          editable: false
        },
        {
          name: 'amount',
          label: 'Amount',
          cell: DollarCell,
          headerCell: 'custom',
          width: 20,
          editable: false
        }
      ],
      emptyText: this.options.emptyText,
      collection: this.collection
    });
    this.showChildView('paymentGrid', this.invoiceGrid);

    this.ui.amount.text(this.getTotalFormatted());
  },

  getTotal: function() {
    var total = this.collection.reduce(function(memo, model) {
      var hasAmount = !!model.get('amount');
      if (hasAmount) {
        memo += parseFloat(model.get('amount'));
      }
      var hasFee = !!model.get('feeAmount');
      if (hasFee) {
        memo += parseFloat(model.get('feeAmount'));
      }
      return memo;
    }, 0);

    // If the amount due is negative, just set the amount due to be $0.00
    if (total < 0) {
      return 0;
    }
    return total;
  },

  getTotalFormatted: function() {
    return (+(Math.round(+(this.getTotal() + 'e' + 2)) + 'e' + -2)).toFixed(2);
  }
});
