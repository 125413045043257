/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Backbone = require('backbone');
var NProgress = require('nprogress');
var Radio = require('backbone.radio');

var GetApplicationDetails = require('../../../common/services/GetApplicationDetails');

var EntitySubmitApplicationTaskView = require('./EntitySubmitApplicationTaskView');

module.exports = {
  event: 'build:nd:entity-submit-application',

  channel: 'task',

  builder: function(task) {
    var defer = $.Deferred();

    var applicationId = task.taskData.ND.applicationId;

    GetApplicationDetails(applicationId).done(function(applicationDetails) {
      NProgress.set(0.5);

      var applicationFormIdentifier = applicationDetails.formIdentifier;
      var applicationTypeId = applicationDetails.applicationTypeId;
      var entityId = applicationDetails.entityId;
      var licenseId = applicationDetails.licenseId;
      var isAssigned = task.state === 'ASSIGNED';

      var GetApplicationPromise = Radio.channel('nd:application').request('build:' + applicationFormIdentifier, {
        applicationId: applicationId,
        applicationTypeId: applicationTypeId,
        entityId: entityId,
        isReadOnly: !isAssigned,
        viewMode: 'ext',
        mode: 'resubmit'
      });

      GetApplicationPromise.done(function(applicationFormView) {
        NProgress.set(0.85);
        applicationFormView.model.set({
          taskId: task.taskId,
          applicationId: applicationId,
          task: task,
          applicationDetails: applicationDetails,
          licenseId: applicationDetails.license.id
        });

        defer.resolve(
          new EntitySubmitApplicationTaskView({
            applicationFormView: applicationFormView,
            model: new Backbone.Model({
              taskId: task.taskId,
              applicationId: applicationId,
              task: task,
              licenseId: licenseId,
              applicationDetails: applicationDetails
            })
          })
        );
      }).fail(function(err) {
        defer.reject(err);
      });
    });
    return defer.promise();
  }
};
