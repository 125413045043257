/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Marionette = require('backbone.marionette');
var Ladda = require('ladda');
var Syphon = require('backbone.syphon');
var Radio = require('backbone.radio');

var momentUtil = require('common/util/momentUtil');

var BreadcrumbView = require('common/views/BreadcrumbView');
var DepFundFormView = require('./DepFundFormView');

var tmplDepSubFundNewView = require('../templates/DepSubFundNewView.hbs');
var SubmitTrustSubFund = require('../services/SubmitTrustSubFund');

module.exports = Marionette.LayoutView.extend({
  template: tmplDepSubFundNewView,

  className: 'dep-new-sub-fund-form-view container-fluid',

  ui: {
    breadcrumb: '.breadcrumb-container',
    subFundFormContainer: '.new-sub-fund-form-container',
    submit: '.btn-submit'
  },

  regions: {
    breadcrumb: '@ui.breadcrumb',
    subFundFormContainer: '@ui.subFundFormContainer'
  },

  events: {
    'click @ui.submit': 'onClickSubmit'
  },

  onBeforeShow: function() {
    var fundIdentifiers = this.model.get('fundIdentifiers') || {},
      entityId = fundIdentifiers.entityId,
      entityName = fundIdentifiers.entityName,
      fundId = fundIdentifiers.fundId,
      fundName = fundIdentifiers.fundName;

    this.showChildView(
      'breadcrumb',
      new BreadcrumbView({
        breadcrumbs: [
          {
            link: '#dep/dashboard',
            text: 'Home'
          },
          {
            link: '#dep/entity/' + entityId + '/dashboard',
            text: entityName
          },
          {
            link: '#dep/entity/' + entityId + '/fund/' + fundId + '/dashboard',
            text: fundName
          },
          {
            text: 'New Collective Investment Sub-Fund'
          }
        ]
      })
    );

    this.fundForm = new DepFundFormView({
      model: new Backbone.Model({})
    });

    this.showChildView('subFundFormContainer', this.fundForm);
  },

  onClickSubmit: function(e) {
    e.preventDefault();

    var fundIdentifiers = this.model.get('fundIdentifiers'),
      entityId = fundIdentifiers.entityId,
      fundId = fundIdentifiers.fundId;

    var laddaContext = Ladda.create(e.currentTarget);
    this.fundForm.validate().done(
      function() {
        laddaContext.start();

        var formData = Syphon.serialize(this);
        formData.fundId = fundId;

        formData.effectiveDate = momentUtil.formatStr(formData.effectiveDate, momentUtil.defaultOutboundDateConfig);
        formData.approvalDate = momentUtil.formatStr(formData.approvalDate, momentUtil.defaultOutboundDateConfig);
        formData.lastAmendmentDate = momentUtil.formatStr(
          formData.lastAmendmentDate,
          momentUtil.defaultOutboundDateConfig
        );
        formData.lastAmendmentApprovalDate = momentUtil.formatStr(
          formData.lastAmendmentApprovalDate,
          momentUtil.defaultOutboundDateConfig
        );
        formData.terminatedDate = momentUtil.formatStr(formData.terminatedDate, momentUtil.defaultOutboundDateConfig);
        formData.effectiveAmendDate = momentUtil.formatStr(
          formData.effectiveAmendDate,
          momentUtil.defaultOutboundDateConfig
        );

        SubmitTrustSubFund(formData)
          .done(function(subFundId) {
            Radio.channel('navigate').trigger('show:int:dp:sub-fund', entityId, fundId, subFundId);
          })
          .fail(function(err) {
            laddaContext.stop();
          });
      }.bind(this)
    );
  }
});
