/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var Backbone = require('backbone');
var Marionette = require('backbone.marionette');
var Syphon = require('backbone.syphon');
var Ladda = require('ladda');
var Radio = require('backbone.radio');

var UIUtil = require('psa-core/util/ui');
var GridView = require('psa-marionette/views/GridView');

var toggleForm = require('common/util/toggleForm');
var modelGet = require('common/util/modelGet');
var SelectFieldView = require('common/views/SelectFieldView');
var BreadcrumbView = require('common/views/BreadcrumbView');
var AddressView = require('common/views/AddressView');
var USStateSelectFieldView = require('common/views/USStateSelectFieldView');
var BooleanCell = require('common/backgrid/BooleanCell');
var DepEntitySearchModalView = require('../../common/views/DepEntitySearchModalView');

var getEntityMainHiddenFields = require('../util/getEntityMainHiddenFields');
var DepEntityFormDBACollectionView = require('./DepEntityFormDBACollectionView');
var SubmitEntity = require('../services/SubmitEntity');

require('../styles/DepEntityEditMainView.css');
var tmplDepEntityEditMainView = require('../templates/DepEntityEditMainView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplDepEntityEditMainView,

  className: 'dep-entity-edit-main-view container-fluid',

  behaviors: {
    RadioGroupToggleBehavior: {
      radios: [
        {
          target: '.mailing-address-container',
          radioGroup: 'input[name="isMailingSame"]',
          valueToShow: '0'
        },
        {
          target: '.billing-address-container',
          radioGroup: 'input[name="isBillingSame"]',
          valueToShow: '0'
        }
      ]
    }
  },

  ui: {
    // headers and wrappers
    entityWrapper: '.entity-wrapper',
    breadcrumb: '.breadcrumb-container',

    // form fields to toggle
    dbas: '.dbas',
    entityName: '.entity-name',
    entityType: '.entity-type',
    charterType: '.charter-type',
    frsrssd: '.frs-rssd',
    occcharter: '.occ-charter',
    fdicuninum: '.fdic-uninum',
    ownershipStructure: '.ownership-structure',
    FOMType: '.FOM-type',
    corporateStructure: '.corporate-structure',
    status: '.status',
    RSSDId: '.RSSD-id',
    certificationNumber: '.certification-number',
    idCharterNumber: '.id-charter-number',
    isComplex: '.is-complex',
    lid: '.lid',
    mbl: '.mbl',
    fhlb: '.fhlb',
    isCollectiveInvestmentFund: '.is-collective-investment-fund',
    supervisoryCommittee: '.supervisory-committee',
    totalAssets: '.total-assets',
    totalAssetsUnderManagement: '.total-assets-under-management',
    totalCapital: '.total-capital',
    regulatorSection: '.regulator-section',
    fieldSupervisorDetails: '.field-supervisor-details',
    stateRegulator: '.state-regulator',
    holdingCompanySection: '.holding-company-section',
    subsidaryBankSection: '.subsidary-bank-section',
    mainPhoneNumberSection: '.main-phone-number-section',
    addressSection: '.address-section',
    mailingAddress: '.mailing-address',
    billingAddress: '.billing-address',
    website: '.website',
    ffSection: '.ff-section',
    ppobLabel: '.ppob-label',
    corpAddressLabel: '.corp-address-label',
    physAddressLabel: '.phys-address-label',
    examSection: '.exam-section',

    // drop downs that get filtered
    entityTypeSelect: '.entity-type-select',
    charterTypeSelect: '.charter-type-select',
    fedRegSelect: '.fed-reg-select',
    statusSelect: '.status-select',

    // date pickers, buttons, speical form elements
    ppobAddressForm: '.ppob-address-form',
    mailingAddressForm: '.mailing-address-form',
    billingAddressForm: '.billing-address-form',
    addDBA: '.add-dba',
    currentDBACollectionContainer: '.current-dba-collection-container',
    dbaCollectionContainer: '.dba-collection-container',
    totalAssetsAsOfDate: '.total-assets-as-of-date',
    totalAssetsUnderManagementAsOfDate: '.total-assets-under-management-as-of-date',
    totalCapitalAsOfDate: '.total-capital-as-of-date',
    capitalAsOfDate: '.capital-as-of-date',
    charteredOnDate: '.chartered-on-date',
    departmentActionSelect: '.department-action',
    regulatorDivisionSelect: '.regulator-division',
    fieldSupervisorName: '.field-supervisor-name',
    terminationInfo: '.termination-info',
    departmentActionDate: '.department-action-date',
    terminationDate: '.termination-date',
    charteringStateSelectContainer: '.chartering-state-select-container',
    stateRegulatorSelectContainer: '.state-regulator-select-container',
    entityMainForm: '.entity-main-form',
    holdingCompanySearchButton: '.btn-holding-company-search',
    selectedHoldingCompanyEntityName: '.selected-holding-company-entity-name',
    subsidaryBankSearchButton: '.btn-subsidary-bank-search',
    selectedSubsidaryBankEntityName: '.selected-subsidary-bank-entity-name',
    clearHoldingCompany: '.clear-holding-company',
    clearSubsidaryBank: '.clear-subsidary-bank',
    submit: '.btn-submit',
    nextExamYear: '.next-exam-year',
    nextExamStartDate: '.next-exam-start-date',
    statusDate: '.status-date'
  },

  events: {
    'change @ui.departmentActionSelect': 'onChangeDeptActionSelect',
    'change @ui.entityTypeSelect': 'onChangeEntityType',
    'change @ui.charterTypeSelect': 'onChangeCharterType',
    'change @ui.regulatorDivisionSelect': 'onChangeRegulatorDivision',
    'click @ui.clearHoldingCompany': 'onClearHoldingCompany',
    'click @ui.clearSubsidaryBank': 'onClearSubsidaryBank',
    'click @ui.addDBA': 'onClickAddDBA',
    'click @ui.holdingCompanySearchButton': 'onClickHoldingCompanySearch',
    'click @ui.subsidaryBankSearchButton': 'onClickSubsidaryBankSearch',
    'click @ui.submit': 'onClickSubmit'
  },

  childEvents: {
    'remove:dba': 'onChildRemoveDBA'
  },

  regions: {
    currentDBACollectionContainer: '@ui.currentDBACollectionContainer',
    dbaCollectionContainer: '@ui.dbaCollectionContainer',
    entityWrapper: '@ui.entityWrapper',
    breadcrumb: '@ui.breadcrumb',
    ppobAddressForm: '@ui.ppobAddressForm',
    mailingAddressForm: '@ui.mailingAddressForm',
    billingAddressForm: '@ui.billingAddressForm',
    charteringStateSelectContainer: '@ui.charteringStateSelectContainer',
    stateRegulatorSelectContainer: '@ui.stateRegulatorSelectContainer',
    charterTypeSelect: '@ui.charterTypeSelect',
    fedRegSelect: '@ui.fedRegSelect',
    statusSelect: '@ui.statusSelect'
  },

  templateHelpers: function() {
    var caseManagers = modelGet(this.model, 'caseManagers', []);
    var caseManager = modelGet(this.model, 'entity.caseManagerUserId');
    var caseManagerFirstName = modelGet(this.model, 'entity.caseManagerFirstName');
    var caseManagerLastName = modelGet(this.model, 'entity.caseManagerLastName');

    /*
     * Current case manager could no longer have case manager role, add current
     * case manager with internal user data if username does not exist in
     * examiners array
     */
    var currentCaseManagerHasRole = _.find(caseManagers, function(cm) {
      return caseManager === cm.userId;
    });

    if (caseManager && !currentCaseManagerHasRole) {
      var currentCaseManager = {
        userId: caseManager,
        name: !caseManagerFirstName && !caseManagerLastName ? '' : caseManagerFirstName + ' ' + caseManagerLastName
      };
      caseManagers.splice(_.sortedIndex(caseManagers, currentCaseManager, 'name'), 0, currentCaseManager);
    }

    return {
      caseManagers: caseManagers
    };
  },

  onBeforeShow: function() {
    var entity = this.model.get('entity') || {};
    var entityTypeId = entity.entityTypeId;
    var entityId = entity.entityId;
    var entityName = entity.entityName;
    this.ui.totalAssetsAsOfDate.datepicker();
    this.ui.totalAssetsUnderManagementAsOfDate.datepicker();
    this.ui.totalCapitalAsOfDate.datepicker();
    this.ui.capitalAsOfDate.datepicker();
    this.ui.departmentActionDate.datepicker();
    this.ui.terminationDate.datepicker();
    this.ui.charteredOnDate.datepicker();
    this.ui.nextExamStartDate.datepicker();
    this.ui.nextExamYear.datepicker({
      viewMode: 'years',
      changeYear: true,
      minViewMode: 'years',
      format: 'yyyy'
    });
    this.ui.statusDate.datepicker();

    this.showChildView(
      'breadcrumb',
      new BreadcrumbView({
        breadcrumbs: [
          {
            link: '#dep/dashboard',
            text: 'Home'
          },
          {
            text: entityName,
            link: '#dep/entity/' + entityId + '/dashboard'
          },
          {
            text: 'Edit Main Information'
          }
        ]
      })
    );

    var DBAs = entity.dbas;
    this.currentDBAs = new GridView({
      emptyText: 'Entity has no current DBAs',
      collection: new Backbone.Collection(DBAs),
      columns: [
        {
          name: 'dba',
          label: 'DBA Name',
          cell: 'string',
          headerCell: 'custom',
          width: 75,
          editable: false
        },
        {
          name: 'shouldDelete',
          label: 'Delete?',
          cell: BooleanCell,
          headerCell: 'custom',
          width: 25,
          editable: true,
          sortable: false
        }
      ]
    });
    this.dbas = new DepEntityFormDBACollectionView({
      collection: new Backbone.Collection([])
    });
    this.showChildView('currentDBACollectionContainer', this.currentDBAs);
    this.showChildView('dbaCollectionContainer', this.dbas);

    // Set holding company and subsidary bank entity
    this.holdingCompanyId = entity.holdingCompanyEntityId;
    this.subsidaryBankId = entity.subsidaryBankEntityId;

    // Set field supervisor
    this.fieldSupervisorId = entity.fieldSupervisorId;

    // Drop downs
    this.charterTypeSelect = new SelectFieldView({
      isDisabled: false,
      isRequired: false,
      name: 'charterTypeId',
      collection: new Backbone.Collection([])
    });
    this.showChildView('charterTypeSelect', this.charterTypeSelect);

    this.statusSelect = new SelectFieldView({
      isDisabled: false,
      isRequired: false,
      name: 'statusId',
      collection: new Backbone.Collection([])
    });
    this.showChildView('statusSelect', this.statusSelect);

    this.fedRegSelect = new SelectFieldView({
      isDisabled: false,
      isRequired: false,
      name: 'federalRegulatorId',
      collection: new Backbone.Collection([])
    });
    this.showChildView('fedRegSelect', this.fedRegSelect);

    // Show address views
    this.showChildView(
      'ppobAddressForm',
      new AddressView({
        objectName: 'ppob',
        startingCountry: modelGet(this.model, 'entity.ppob.country'),
        startingPostalCode: modelGet(this.model, 'entity.ppob.postalCode'),
        startingCounty: modelGet(this.model, 'entity.ppob.county')
      })
    );

    this.showChildView(
      'mailingAddressForm',
      new AddressView({
        objectName: 'mailingAddress',
        startingCountry: modelGet(this.model, 'entity.mailingAddress.country'),
        startingPostalCode: modelGet(this.model, 'entity.mailingAddress.postalCode'),
        startingCounty: modelGet(this.model, 'entity.mailingAddress.county')
      })
    );

    this.showChildView(
      'billingAddressForm',
      new AddressView({
        objectName: 'billingAddress',
        startingCountry: modelGet(this.model, 'entity.billingAddress.country'),
        startingPostalCode: modelGet(this.model, 'entity.billingAddress.postalCode'),
        startingCounty: modelGet(this.model, 'entity.billingAddress.county')
      })
    );

    // Show state selects
    this.regStateSelect = new USStateSelectFieldView({
      emptyOption: false,
      name: 'stateRegulator',
      isRequired: false,
      multiple: false
    });
    this.showChildView('stateRegulatorSelectContainer', this.regStateSelect);

    this.ffStateSelect = new USStateSelectFieldView({
      emptyOption: false,
      name: 'FFCharteringState',
      isRequired: false,
      multiple: false
    });
    this.showChildView('charteringStateSelectContainer', this.ffStateSelect);

    // Deserialize and show correct fields
    Syphon.deserialize(this, entity);
    this.ui.totalAssetsAsOfDate.datepicker();
    this.ui.totalAssetsUnderManagementAsOfDate.datepicker();
    this.ui.totalCapitalAsOfDate.datepicker();
    this.ui.capitalAsOfDate.datepicker();
    this.ui.departmentActionDate.datepicker();
    this.ui.terminationDate.datepicker();
    this.ui.statusDate.datepicker();
    this.toggleFormFields(entityTypeId);

    // Set the selects after toggleFormFields resets them
    this.charterTypeSelect.setValue(entity.charterTypeId);
    this.statusSelect.setValue(entity.statusId);
    this.fedRegSelect.setValue(entity.federalRegulatorId);

    // Initialize state select dropdowns
    if (this.ffStateSelect) {
      this.ffStateSelect.setValue(entity.FFCharteringState);
    }

    if (this.regStateSelect) {
      this.regStateSelect.setValue(entity.stateRegulator);
    }

    // Show termination info if FF Entity that's terminated
    if (this.ui.departmentActionSelect.val() === '3') {
      this.ui.terminationInfo.show();
    } else {
      this.ui.terminationInfo.hide();
    }

    this.setCharterType(this.ui.entityTypeSelect.val());
  },

  onClickAddDBA: function(e) {
    e.preventDefault();
    this.dbas.collection.add({});
  },

  onChildRemoveDBA: function(childView) {
    this.dbas.collection.remove(childView);
  },

  toggleFormFields: function(entityTypeId) {
    var self = this;
    var hidden = getEntityMainHiddenFields(entityTypeId);

    toggleForm(this.ui.ppobAddress, !hidden.ppobAddress);
    toggleForm(this.ui.mailingAddress, !hidden.mailingAddress);
    toggleForm(this.ui.billingAddress, !hidden.billingAddress);
    toggleForm(this.ui.dbas, !hidden.dbas);
    toggleForm(this.ui.charterType, !hidden.charterType);
    toggleForm(this.ui.ownershipStructure, !hidden.ownershipStructure);
    toggleForm(this.ui.FOMType, !hidden.FOMType);
    toggleForm(this.ui.corporateStructure, !hidden.corporateStructure);
    toggleForm(this.ui.status, !hidden.status);
    toggleForm(this.ui.RSSDId, !hidden.RSSDId);
    toggleForm(this.ui.certificationNumber, !hidden.certificationNumber);
    toggleForm(this.ui.isComplex, !hidden.isComplex);
    toggleForm(this.ui.idCharterNumber, !hidden.idCharterNumber);
    toggleForm(this.ui.isComplex, !hidden.isComplex);
    toggleForm(this.ui.lid, !hidden.lid);
    toggleForm(this.ui.mbl, !hidden.mbl);
    toggleForm(this.ui.isCollectiveInvestmentFund, !hidden.isCollectiveInvestmentFund);
    toggleForm(this.ui.supervisoryCommittee, !hidden.supervisoryCommittee);
    toggleForm(this.ui.totalAssets, !hidden.totalAssets);
    toggleForm(this.ui.regulatorSection, !hidden.regulatorSection);
    toggleForm(this.ui.stateRegulator, !hidden.stateRegulator);
    toggleForm(this.ui.holdingCompanySection, !hidden.holdingCompanySection);
    toggleForm(this.ui.subsidaryBankSection, !hidden.subsidaryBankSection);
    toggleForm(this.ui.addressSection, !hidden.addressSection);
    toggleForm(this.ui.website, !hidden.website);
    toggleForm(this.ui.ffSection, !hidden.ffSection);
    toggleForm(this.ui.fhlb, !hidden.fhlb);
    toggleForm(this.ui.mainPhoneNumberSection, !hidden.mainPhoneNumberSection);
    toggleForm(this.ui.frsrssd, !hidden.frsrssd);
    toggleForm(this.ui.occcharter, !hidden.occcharter);
    toggleForm(this.ui.fdicuninum, !hidden.fdicuninum);
    toggleForm(this.ui.examSection, !hidden.examSection);

    // Show collective investment fund if BT charter type when Bank
    if (entityTypeId === '1') {
      if (this.ui.charterTypeSelect.val() === '2') {
        toggleForm(self.ui.isCollectiveInvestmentFund, true);
      } else {
        toggleForm(self.ui.isCollectiveInvestmentFund, false);
      }
    } else if (this.ui.entityTypeSelect.val() === '21' || this.ui.entityTypeSelect.val() === '22') {
      toggleForm(this.ui.isCollectiveInvestmentFund, true);
    } else {
      toggleForm(this.ui.isCollectiveInvestmentFund, false);
    }

    // Show supervisory committee when Credit Union
    if (entityTypeId === '13' || entityTypeId === '11') {
      toggleForm(this.ui.supervisoryCommittee, true);
    } else {
      toggleForm(this.ui.supervisoryCommittee, false);
    }

    // Show Total Assets Under Management and Total Capital when Trust
    var isTrust = _.contains(['21', '22', '23', '24', '25'], entityTypeId);
    if (isTrust) {
      toggleForm(this.ui.totalCapital, true);
    } else {
      toggleForm(this.ui.totalCapital, false);
    }

    // Show Total Assets Under Management when Bank And Trust or Trust
    if (modelGet(this.model, 'entity.charterTypeId') === '2' || isTrust) {
      toggleForm(this.ui.totalAssetsUnderManagement, true);
    } else {
      toggleForm(this.ui.totalAssetsUnderManagement, false);
    }

    // Change label for primary address depending on entity type
    this.ui.corpAddressLabel.hide();
    this.ui.physAddressLabel.hide();
    this.ui.ppobLabel.hide();
    var isTSP = entityTypeId === '3' || entityTypeId === '12' || entityTypeId === '23';
    if (isTSP) {
      this.ui.corpAddressLabel.show();
    } else if (entityTypeId === '4') {
      this.ui.physAddressLabel.show();
    } else {
      this.ui.ppobLabel.show();
    }

    // Filtering Dropdowns

    // Change contents of charter type dropdown
    // based on entity type when entity category is Trust
    var charterTypeOptions = [];
    var statusOptions = [];
    if (modelGet(this.model, 'entity.entityCategoryId') === '3') {
      if (entityTypeId === '24') {
        charterTypeOptions = charterTypeOptions.concat(['22', '23', '24']);
      } else {
        charterTypeOptions = charterTypeOptions.concat(['21']);
      }

      var charterTypeOptionsFiltered = _.filter(this.model.get('charterTypes'), function(charterType) {
        return _.contains(charterTypeOptions, charterType.id);
      });
      this.charterTypeSelect.collection.reset(
        _.map(charterTypeOptionsFiltered, function(charterType) {
          return { value: charterType.id, text: charterType.type };
        })
      );
    } else {
      this.charterTypeSelect.collection.reset(
        _.map(this.model.get('charterTypes'), function(charterType) {
          return { value: charterType.id, text: charterType.type };
        })
      );
    }

    // Change contents of status dropdown
    // based on entity type when entity category is Trust or Credit Union
    if (
      modelGet(this.model, 'entity.entityCategoryId') === '3' ||
      modelGet(this.model, 'entity.entityCategoryId') === '2'
    ) {
      statusOptions = statusOptions.concat(['', '1', '4', '5']);

      var statusOptionsFiltered = _.filter(this.model.get('statuses'), function(status) {
        return _.contains(statusOptions, status.id);
      });

      this.statusSelect.collection.reset(
        _.map(statusOptionsFiltered, function(status) {
          return { value: status.id, text: status.status };
        })
      );
    } else {
      this.statusSelect.collection.reset(
        _.map(this.model.get('statuses'), function(status) {
          return { value: status.id, text: status.status };
        })
      );
    }

    // Filter federal regulator dropdown if Bank Category
    var fedRegIdOptionsFiltered;
    if (entityTypeId === '4') {
      var fedRegIdOptions = ['1', '2', '3', '4', '7'];
      fedRegIdOptionsFiltered = _.filter(this.model.get('federalRegulators'), function(fedReg) {
        return _.contains(fedRegIdOptions, fedReg.id);
      });

      this.fedRegSelect.collection.reset(
        _.map(fedRegIdOptionsFiltered, function(fedReg) {
          return { value: fedReg.id, text: fedReg.regulator };
        })
      );
    } else {
      this.fedRegSelect.collection.reset(
        _.map(this.model.get('federalRegulators'), function(fedReg) {
          return { value: fedReg.id, text: fedReg.regulator };
        })
      );
    }

    //Filter Charter Type dropdown if Entity Type ID
    var charterTypeFiltered;
    var charterTypeIdOptions = [];
    if (modelGet(this.model, 'entity.entityCategoryId') === '1') {
      if (entityTypeId === '1' || entityTypeId === '4' || entityTypeId === '8') {
        charterTypeIdOptions = ['1', '2', '3', '31', '32'];
      } else if (entityTypeId === '2') {
        charterTypeIdOptions = ['4', '5', '6', '7', '8'];
      } else if (
        entityTypeId === '3' ||
        entityTypeId === '5' ||
        entityTypeId === '7' ||
        entityTypeId === '9' ||
        entityTypeId === '10'
      ) {
        charterTypeIdOptions = ['1', '2', '3', '9', '31', '32'];
      }

      charterTypeFiltered = _.filter(this.model.get('charterTypes'), function(charType) {
        return _.contains(charterTypeIdOptions, charType.id);
      });
      this.charterTypeSelect.collection.reset(
        _.map(charterTypeFiltered, function(charType) {
          return { value: charType.id, text: charType.type };
        })
      );
    } else {
      this.charterTypeSelect.collection.reset(
        _.map(this.model.get('charterTypes'), function(charType) {
          return { value: charType.id, text: charType.type };
        })
      );
    }
  },

  onChangeEntityType: function() {
    this.toggleFormFields(this.ui.entityTypeSelect.val());

    this.setCharterType(this.ui.entityTypeSelect.val());
  },

  onChangeCharterType: function() {
    if (this.ui.entityTypeSelect.val() === '1') {
      if (this.charterTypeSelect.getValue() === '2') {
        toggleForm(this.ui.isCollectiveInvestmentFund, true);
      } else {
        toggleForm(this.ui.isCollectiveInvestmentFund, false);
      }
    }
  },

  onChangeDeptActionSelect: function() {
    // If FF is terminated
    toggleForm(this.ui.terminationInfo, this.ui.departmentActionSelect.val() === '3');
  },

  onChangeRegulatorDivision: function() {
    this.setFieldSupervisor(this.ui.regulatorDivisionSelect.find('option:selected').text());
  },

  setFieldSupervisor: function(divisionCode) {
    var fieldSupervisors = this.model.get('fieldSupervisors');
    var supervisor = _.findWhere(fieldSupervisors, { division_cd: divisionCode });

    if (supervisor) {
      this.ui.fieldSupervisorName.val(supervisor.name);
      this.fieldSupervisorId = supervisor.id;
    } else {
      this.ui.fieldSupervisorName.val('--');
      this.fieldSupervisorId = '';
    }
  },

  setCharterType: function(typeId) {
    var self = this;
    var isCreditUnion = typeId === '11';
    var isTrust = _.contains(['21', '22', '23', '25'], typeId); // not including foreign fiduciary

    if (isCreditUnion) {
      this.charterTypeSelect.setValue('11');
    } else if (isTrust) {
      this.charterTypeSelect.setValue('21');
    } else {
      self.ui.charterTypeSelect.val(0);
    }
  },

  onClickHoldingCompanySearch: function(e) {
    e.preventDefault();
    var self = this;

    UIUtil.showModal({
      title: 'Search for an Entity',
      dialogClasses: 'modal-dialog-entity-search-modal-view modal-lg',
      view: new DepEntitySearchModalView({
        model: this.model
      })
    }).done(function(entity) {
      if (entity) {
        self.holdingCompanyId = entity.entityId;
        self.ui.selectedHoldingCompanyEntityName.val(entity.entityName);
      } else {
        self.holdingCompanyId = '';
        self.ui.selectedHoldingCompanyEntityName.val('');
      }
    });
  },

  onClickSubsidaryBankSearch: function(e) {
    e.preventDefault();
    var self = this;

    UIUtil.showModal({
      title: 'Search for an Entity',
      dialogClasses: 'modal-dialog-entity-search-modal-view modal-lg',
      view: new DepEntitySearchModalView({
        model: this.model
      })
    }).done(function(entity) {
      if (entity) {
        self.subsidaryBankId = entity.entityId;
        self.ui.selectedSubsidaryBankEntityName.val(entity.entityName);
      } else {
        self.subsidaryBankId = '';
        self.ui.selectedSubsidaryBankEntityName.val('');
      }
    });
  },

  onClearHoldingCompany: function(e) {
    e.preventDefault();
    this.holdingCompanyId = '';
    this.ui.selectedHoldingCompanyEntityName.val('');
  },

  onClearSubsidaryBank: function(e) {
    e.preventDefault();
    this.subsidaryBankId = '';
    this.ui.selectedSubsidaryBankEntityName.val('');
  },

  onClickSubmit: function(e) {
    e.preventDefault();

    var self = this;
    this.validate().done(function() {
      var l = Ladda.create(self.ui.submit.get(0));
      l.start();

      var formData = self.getFormData();
      SubmitEntity(formData)
        .done(function() {
          Radio.channel('navigate').trigger('show:int:dp:entity', formData.entityId);
        })
        .fail(function() {
          l.stop();
        });
    });
  },

  getFormData: function() {
    var formData = Syphon.serialize(this);
    var dbas = this.dbas.getFormData();

    formData.entityId = modelGet(this.model, 'entity.entityId');
    formData.holdingCompanyId = this.holdingCompanyId;
    formData.subsidaryBankId = this.subsidaryBankId;
    formData.fieldSupervisorId = formData.regulatorDivisionId ? this.fieldSupervisorId : '';

    formData.deleteDBAs = _.reduce(
      this.currentDBAs.collection.toJSON(),
      function(memo, dba) {
        if (dba.shouldDelete) {
          memo.push(dba.id);
        }
        return memo;
      },
      []
    );
    formData.newDBAs = dbas;

    return formData;
  },

  validate: function() {
    return this.ui.entityMainForm.parsley().whenValidate();
  }
});
