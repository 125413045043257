/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var Backbone = require('backbone');
var Marionette = require('backbone.marionette');
var Syphon = require('backbone.syphon');
var Radio = require('backbone.radio');
var UIUtil = require('psa-core/util/ui');

var momentUtil = require('common/util/momentUtil');
var modelGet = require('common/util/modelGet');
var HeaderBarView = require('common/views/HeaderBarView');
var BreadcrumbView = require('common/views/BreadcrumbView');
var FileUploadTableView = require('common/views/FileUploadTableView');
var ConfirmationModalView = require('common/views/ConfirmationModalView');

var SubmitRespondCorrespondenceTask = require('../../services/SubmitRespondCorrespondenceTask');

var tmplDepRespondCorrespondenceTaskView = require('./DepReviewCorrespondenceTaskView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplDepRespondCorrespondenceTaskView,

  className: 'dep-review-correspondence-task-view container-fluid',

  ui: {
    headerBar: '.header-bar',
    breadcrumb: '.breadcrumb-container',
    correspondenceForm: '.correspondence-form',
    intFileUploadTableContainer: '.int-file-upload-table-container',
    submit: '.submit'
  },

  regions: {
    headerBar: '@ui.headerBar',
    breadcrumb: '@ui.breadcrumb',
    intFileUploadTableContainer: '@ui.intFileUploadTableContainer'
  },

  events: {
    'click @ui.submit': 'onClickSubmit'
  },

  onBeforeShow: function() {
    var correspondence = this.model.get('correspondence') || {};
    var intDocuments = modelGet(this.model, 'correspondence.intDocuments', []);
    var headerBarColumns = [
      {
        name: 'entityName',
        label: 'Entity Name',
        minWidth: '160px',
        maxWidth: '300px'
      }
    ];

    this.showChildView(
      'headerBar',
      new HeaderBarView({
        columns: new Backbone.Collection(headerBarColumns),
        model: new Backbone.Model(correspondence)
      })
    );

    this.showChildView(
      'breadcrumb',
      new BreadcrumbView({
        breadcrumbs: [
          {
            link: '#ext/dep/dashboard',
            text: 'Home'
          },
          {
            text: 'Respond to Correspondence'
          }
        ]
      })
    );

    Syphon.deserialize(this, correspondence);

    this.intFileUploadTableView = new FileUploadTableView({
      collection: new Backbone.Collection(intDocuments),
      emptyText: 'No Internal Documents',
      isReadOnly: true
    });

    this.showChildView('intFileUploadTableContainer', this.intFileUploadTableView);
  },

  onClickSubmit: function(e) {
    e.preventDefault();
    var self = this;
    var data = self.getFormData();
    this.validate().done(function() {
      UIUtil.showModal({
        title: 'Close Correspondence',
        view: new ConfirmationModalView({
          confirmText: 'Submit',
          model: new Backbone.Model({
            message:
              'Do not acknowledge and close correspondence until you have downloaded and saved the attachments. Once this correspondence is closed, access to the correspondence and its attachments will be revoked.',
            onConfirm: function() {
              return SubmitRespondCorrespondenceTask(data);
            },
            onConfirmDone: function() {
              Radio.channel('navigate').trigger('show:ext:dp:dashboard');
            }
          })
        })
      });
    });
  },

  validate: function() {
    return this.ui.correspondenceForm.parsley().whenValidate();
  },

  getFormData: function() {
    var formData = Syphon.serialize(this);
    formData.fiscalYearEndDate = momentUtil.formatStr(formData.fiscalYearEndDate, momentUtil.defaultOutboundDateConfig);

    _.extend(formData, {
      taskId: this.model.get('taskId'),
      correspondenceId: this.model.get('correspondenceId')
    });
    formData.isInternal = '0';
    return formData;
  }
});
