var Handlebars = require("handlebars/dist/handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), alias4=helpers.helperMissing;

  return "\n<div class=\"page-title clearfix\">\n  <h1 class=\"pull-left m-n p-n\">"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.entityIdentifiers : depth0)) != null ? stack1.entityName : stack1), depth0))
    + "</h1>\n  <div class=\"page-title-buttons-container\">\n    <div class=\"condition-action-drop-down\"></div>\n  </div>\n</div>\n<div class=\"page-subtitle\">\n  <h2>Condition</h2>\n  <div class=\"page-subtitle-details\">"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.condition : depth0)) != null ? stack1.type : stack1), depth0))
    + "</div>\n</div>\n<div class=\"breadcrumb-container\"></div>\n<div class=\"form-horizontal form-static\">\n  <div class=\"row\">\n    <div class=\"col-sm-8 col-sm-offset-1\">\n      <div class=\"row\">\n        <div class=\"col-sm-6\">\n          <div class=\"form-group type\">\n            <label class=\"control-label col-sm-4\">Type</label>\n            <div class=\"form-control-static col-sm-8\">"
    + alias2((helpers["default"] || (depth0 && depth0["default"]) || alias4).call(alias3,((stack1 = (depth0 != null ? depth0.condition : depth0)) != null ? stack1.type : stack1),"--",{"name":"default","hash":{},"data":data}))
    + "</div>\n          </div>\n        </div>\n        <div class=\"col-sm-6\">\n          <div class=\"form-group frequency\">\n            <label class=\"control-label col-sm-4\">Frequency</label>\n            <div class=\"form-control-static col-sm-8\">"
    + alias2((helpers["default"] || (depth0 && depth0["default"]) || alias4).call(alias3,((stack1 = (depth0 != null ? depth0.condition : depth0)) != null ? stack1.frequency : stack1),"--",{"name":"default","hash":{},"data":data}))
    + "</div>\n          </div>\n        </div>\n      </div>\n      <div class=\"row\">\n        <div class=\"col-sm-6\">\n          <div class=\"form-group due-date\">\n            <label class=\"control-label col-sm-4\">Due Date</label>\n            <div class=\"form-control-static col-sm-8\">"
    + alias2((helpers["default"] || (depth0 && depth0["default"]) || alias4).call(alias3,((stack1 = (depth0 != null ? depth0.condition : depth0)) != null ? stack1.dueDate : stack1),"--",{"name":"default","hash":{},"data":data}))
    + "</div>\n          </div>\n        </div>\n        <div class=\"col-sm-6\">\n          <div class=\"form-group status\">\n            <label class=\"control-label col-sm-4\">Status</label>\n            <div class=\"form-control-static col-sm-8\">"
    + alias2((helpers["default"] || (depth0 && depth0["default"]) || alias4).call(alias3,((stack1 = (depth0 != null ? depth0.condition : depth0)) != null ? stack1.status : stack1),"--",{"name":"default","hash":{},"data":data}))
    + "</div>\n          </div>\n        </div>\n      </div>\n      <div class=\"row\">\n        <div class=\"col-sm-6\">\n          <div class=\"form-group received-date\">\n            <label class=\"control-label col-sm-4\">Received Date</label>\n            <div class=\"form-control-static col-sm-8\">"
    + alias2((helpers["default"] || (depth0 && depth0["default"]) || alias4).call(alias3,((stack1 = (depth0 != null ? depth0.condition : depth0)) != null ? stack1.receivedDate : stack1),"--",{"name":"default","hash":{},"data":data}))
    + "</div>\n          </div>\n        </div>\n      </div>\n      <div class=\"form-group address-activity\">\n        <label class=\"control-label col-sm-2\">Address Activity</label>\n        <p class=\"form-control-static col-sm-10\">"
    + alias2((helpers["default"] || (depth0 && depth0["default"]) || alias4).call(alias3,((stack1 = (depth0 != null ? depth0.condition : depth0)) != null ? stack1.addressActivity : stack1),"--",{"name":"default","hash":{},"data":data}))
    + "</p>\n      </div>\n      <div class=\"form-group comments\">\n        <label class=\"control-label col-sm-2\">Comments</label>\n        <p class=\"form-control-static col-sm-10\">"
    + alias2((helpers["default"] || (depth0 && depth0["default"]) || alias4).call(alias3,((stack1 = (depth0 != null ? depth0.condition : depth0)) != null ? stack1.comments : stack1),"--",{"name":"default","hash":{},"data":data}))
    + "</p>\n      </div>\n    </div>\n  </div>\n</div>";
},"useData":true});