/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2020] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var Backbone = require('backbone');
var Marionette = require('backbone.marionette');
var Radio = require('backbone.radio');

var Session = require('psa-core/Session');
var PageableCollection = require('psa-backbone/models/PageableCollection');

var userHasRole = require('common/util/userHasRole');
var SideNavSectionView = require('common/views/SideNavSectionView');
var ContentServerLinkView = require('common/views/ContentServerLinkView');
var BreadcrumbView = require('common/views/BreadcrumbView');
var DropdownView = require('common/views/DropdownView');

var SecExamInvInqSectionNextStepsView = require('../../common/views/SecExamInvInqSectionNextStepsView');
var SecExamInvInqSectionStaffMemberView = require('../../common/views/SecExamInvInqSectionStaffMemberView');
var SecInvInqHeaderBarView = require('./SecInvInqHeaderBarView');
var SecInvInqSectionGeneralView = require('./SecInvInqSectionGeneralView');

var tmplSecInvInqDashboardView = require('../templates/SecInvInqDashboardView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplSecInvInqDashboardView,

  className: 'sec-inv-inq-dashboard-view container-fluid',

  ui: {
    headerBar: '.header-bar-container',
    breadcrumb: '.breadcrumb-container',
    actionDropdown: '.exam-action-drop-down',
    invInqDashboard: '.inv-inq-dashboard-container',
    csLinkContainer: '.cs-link-container'
  },

  regions: {
    headerBar: '@ui.headerBar',
    breadcrumb: '@ui.breadcrumb',
    actionDropdown: '@ui.actionDropdown',
    invInqDashboard: '@ui.invInqDashboard',
    csLinkContainer: '@ui.csLinkContainer'
  },
  onBeforeShow: function() {
    var invInq = this.model.get('invInq') || {};
    var identifiers = this.model.get('identifiers') || {};
    var invInqId = this.model.get('invInqId');
    var caseNumber = identifiers.caseNumber;
    var objectId = invInq.objectId;
    var objectLicenseId = identifiers.objectLicenseId;
    var staffMembers = this.model.get('staffMembers');
    var category = invInq.category;
    var isManager = userHasRole('SC - Manager');
    var isExaminer = userHasRole('SC - Examiner');
    var isChiefAccounting = userHasRole('SC - Chief Accounting');
    var isFilingExaminer = userHasRole('SC - Filing Examiner');

    this.showChildView(
      'csLinkContainer',
      new ContentServerLinkView({
        model: new Backbone.Model({
          buttonText: 'Open ' + category + ' Folder',
          contentServerId: invInq.csFolderId
        })
      })
    );

    var dropDownOptions = [
      {
        text: 'Edit General Section',
        link: '#sec/investigation-inquiry/' + invInqId + '/edit'
      },
      {
        isDivider: true
      },
      {
        text: 'Add Staff Member',
        link: '#sec/investigation-inquiry/' + invInqId + '/new-staff-member'
      },
      {
        text: 'Add Complainant',
        link: '#sec/investigation-inquiry/' + invInqId + '/new-complainant'
      },
      {
        text: 'Add Subpoena',
        link: '#sec/investigation-inquiry/' + invInqId + '/new-subpoena'
      },
      {
        text: 'Add Referral',
        link: '#sec/investigation-inquiry/' + invInqId + '/new-referral'
      },
      {
        text: 'Add Compliance',
        link: '#sec/investigation-inquiry/' + invInqId + '/new-compliance'
      }
    ];

    var editNextSteps =
      userHasRole('SC - Exam Supervisor') || _.contains(_.pluck(staffMembers, 'staffMember'), Session.user.userName);

    if (editNextSteps) {
      dropDownOptions.push({
        text: 'Edit Next Steps Section',
        link: '#sec/investigation-inquiry/' + invInqId + '/edit-next-steps'
      });
    }

    if ((invInq.isOrganization === '1') & (isManager || isExaminer || isChiefAccounting || isFilingExaminer)) {
      dropDownOptions = dropDownOptions.concat(
        {
          isDivider: true
        },
        {
          text: 'Initiate Correspondence',
          link: '#sec/investigation-inquiry/' + invInqId + '/initiate-correspondence'
        }
      );
    }

    this.showChildView(
      'actionDropdown',
      new DropdownView({
        rightAlign: true,
        model: new Backbone.Model({
          name: category + ' Actions',
          options: dropDownOptions
        })
      })
    );

    this.showChildView(
      'headerBar',
      new SecInvInqHeaderBarView({
        model: new Backbone.Model(identifiers),
        isLicenseInvInq: !!objectLicenseId
      })
    );

    var breadcrumbs = [
      {
        link: '#sec/dashboard',
        text: 'Home'
      }
    ];

    if (invInq.isOrganization === '1') {
      breadcrumbs.push({
        link: '#sec/organization/' + objectId + '/dashboard',
        text: identifiers.applicantLegalName
      });
      if (objectLicenseId) {
        breadcrumbs.push({
          link: '#sec/organization/' + objectId + '/license/' + objectLicenseId + '/dashboard',
          text: identifiers.licenseType
        });
      }
    } else {
      breadcrumbs.push({
        link: '#sec/individual/' + objectId + '/dashboard',
        text: identifiers.applicantLegalName
      });
      if (objectLicenseId) {
        breadcrumbs.push({
          link: '#sec/individual/' + objectId + '/license/' + objectLicenseId + '/dashboard',
          text: identifiers.licenseType
        });
      }
    }

    breadcrumbs.push({
      text: category + ' (' + caseNumber + ')'
    });

    this.showChildView(
      'breadcrumb',
      new BreadcrumbView({
        breadcrumbs: breadcrumbs
      })
    );

    var sideNavTabs = [
      {
        id: 'general',
        title: 'General',
        view: new SecInvInqSectionGeneralView({ model: new Backbone.Model(invInq) })
      },
      {
        id: 'relatedSubjects',
        title: 'Related Subject List',
        view: Radio.channel('sc').request('build:exam-inv-inq-section-related-subject-view', {
          objectId: invInqId,
          objectTypeId: 'INV-INQ'
        })
      },
      {
        id: 'staffMembers',
        title: 'Staff Assigned',
        view: new SecExamInvInqSectionStaffMemberView({
          collection: new PageableCollection(staffMembers, { pageSize: 25 })
        })
      },
      {
        id: 'complainants',
        title: 'Complainants',
        view: Radio.channel('sc').request('build:inv-inq-section-complainant-view', invInqId)
      },
      {
        id: 'relatedCases',
        title: 'Related Cases',
        view: Radio.channel('sc').request('build:inv-inq-section-related-case-view', invInqId)
      },
      {
        id: 'subpoenas',
        title: 'Subpoenas',
        view: Radio.channel('sc').request('build:inv-inq-section-subpoena-view', invInqId)
      },
      {
        id: 'referrals',
        title: 'Referrals',
        view: Radio.channel('sc').request('build:exam-inv-inq-section-referral-view', {
          objectId: invInqId,
          objectTypeId: 'INV-INQ'
        })
      },
      {
        id: 'compliances',
        title: 'Compliance',
        view: Radio.channel('sc').request('build:exam-inv-inq-section-compliance-view', {
          objectId: invInqId,
          objectTypeId: 'INV-INQ'
        })
      },
      {
        id: 'nextSteps',
        title: 'Next Steps',
        view: new SecExamInvInqSectionNextStepsView({ model: new Backbone.Model(invInq) })
      },
      {
        id: 'notes',
        title: 'Notes',
        view: Radio.channel('sc').request('build:notes-view', { objectId: invInqId, objectTypeId: 'INV-INQ' })
      },
      {
        id: 'auditLog',
        title: 'Audit Log',
        view: Radio.channel('sc').request('build:audit-log-view', { objectId: invInqId, objectTypeId: 'INV-INQ' })
      }
    ];

    this.dashboard = new SideNavSectionView({
      collection: new Backbone.Collection(sideNavTabs),
      useNavigationButtons: false
    });

    this.showChildView('invInqDashboard', this.dashboard);
  }
});
