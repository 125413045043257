/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');

module.exports = function(data) {
  var generalDetails = data.generalDetails;
  var volumeAndRetention = data.volumeAndRetention;
  var licenseeActions = data.licenseeActions;
  var changesInOwnership = data.changesInOwnership;
  var affirmation = data.affirmation;

  return $.soap({
    body: {
      SubmitDebtSettlementServicesAnnualReport: {
        $xmlns: 'http://dobs.pa.gov/nd/annualreport/bpm/1.0',
        AnnualReportID: data.annualReportId,
        ApplicationID: data.applicationId,
        ContactFullName: generalDetails.contactFullName,
        ContactPhoneNumber: generalDetails.contactPhoneNumber,
        ContactEmail: generalDetails.contactEmail,

        NumBeginningEnrolledNotAvailable: volumeAndRetention.numBeginningEnrolledNotAvailable ? '1' : '0',
        NumBeginningEnrolledNumber: volumeAndRetention.numBeginningEnrolledNumber,
        NumBeginningEnrolledDollars: volumeAndRetention.numBeginningEnrolledDollars,
        NumBeginningEnrolledExplanation: volumeAndRetention.numBeginningEnrolledExplanation,

        NumSignedUpNotAvailable: volumeAndRetention.numSignedUpNotAvailable ? '1' : '0',
        NumSignedUpNumber: volumeAndRetention.numSignedUpNumber,
        NumSignedUpDollars: volumeAndRetention.numSignedUpDollars,
        NumSignedUpExplanation: volumeAndRetention.numSignedUpExplanation,

        NumCompletedNotAvailable: volumeAndRetention.numCompletedNotAvailable ? '1' : '0',
        NumCompletedNumber: volumeAndRetention.numCompletedNumber,
        NumCompletedDollars: volumeAndRetention.numCompletedDollars,
        NumCompletedExplanation: volumeAndRetention.numCompletedExplanation,

        NumDroppedOutAfterThreeMonthsNotAvailable: volumeAndRetention.numDroppedOutAfterThreeMonthsNotAvailable
          ? '1'
          : '0',
        NumDroppedOutAfterThreeMonthsNumber: volumeAndRetention.numDroppedOutAfterThreeMonthsNumber,
        NumDroppedOutAfterThreeMonthsDollars: volumeAndRetention.numDroppedOutAfterThreeMonthsDollars,
        NumDroppedOutAfterThreeMonthsExplanation: volumeAndRetention.numDroppedOutAfterThreeMonthsExplanation,
        NumDroppedOutBeforeThreeMonthsNotAvailable: volumeAndRetention.numDroppedOutBeforeThreeMonthsNotAvailable
          ? '1'
          : '0',
        NumDroppedOutBeforeThreeMonthsNumber: volumeAndRetention.numDroppedOutBeforeThreeMonthsNumber,
        NumDroppedOutBeforeThreeMonthsDollars: volumeAndRetention.numDroppedOutBeforeThreeMonthsDollars,
        NumDroppedOutBeforeThreeMonthsExplanation: volumeAndRetention.numDroppedOutBeforeThreeMonthsExplanation,

        NumEnrolledNotAvailable: volumeAndRetention.numEnrolledNotAvailable ? '1' : '0',
        NumEnrolledNumber: volumeAndRetention.numEnrolledNumber,
        NumEnrolledDollars: volumeAndRetention.numEnrolledDollars,
        NumEnrolledExplanation: volumeAndRetention.numEnrolledExplanation,

        NumDeniedNotAvailable: volumeAndRetention.numDeniedNotAvailable ? '1' : '0',
        NumDeniedNumber: volumeAndRetention.numDeniedNumber,
        NumDeniedDollars: volumeAndRetention.numDeniedDollars,
        NumDeniedExplanation: volumeAndRetention.numDeniedExplanation,

        IsRevoked: licenseeActions.isRevoked,
        HasSurrendered: licenseeActions.hasSurrendered,
        HasEnforcement: licenseeActions.hasEnforcement,
        IsUnderInvestigation: licenseeActions.isUnderInvestigation,
        HasLawsuitsInitiated: licenseeActions.hasLawsuitsInitiated,
        HasLawsuitsContinued: licenseeActions.hasLawsuitsContinued,
        HasLawsuitsResolved: licenseeActions.hasLawsuitsResolved,
        HasCriminalOfficer: licenseeActions.hasCriminalOfficer,
        HasCriminalOfficerExplaination: licenseeActions.hasCriminalOfficerExplaination,
        IsRevokedDocumentID: licenseeActions.isRevokedDocumentId,
        HasSurrenderedDocumentID: licenseeActions.hasSurrenderedDocumentId,
        HasEnforcementDocumentID: licenseeActions.hasEnforcementDocumentId,
        HasLawsuitsDocumentID: licenseeActions.hasLawsuitsDocumentId,
        IsUnderInvestigationDocumentId: licenseeActions.isUnderInvestigationDocumentId,

        HasNewOwners: changesInOwnership.hasNewOwners,
        HasNewOwnersExplanation: changesInOwnership.hasNewOwnersExplanation,
        HasQuittedOwners: changesInOwnership.hasQuittedOwners,
        HasQuittedOwnersExplanation: changesInOwnership.hasQuittedOwnersExplanation,
        HasNewAssets: changesInOwnership.hasNewAssets,
        HasNewAssetsExplanation: changesInOwnership.hasNewAssetsExplanation,
        IsCertified: affirmation.isCertified ? '1' : '0',
        CertificationName: affirmation.certificationName,
       
        DocumentsToMove: (function() {
          var documentsToMove = _.compact([
            licenseeActions.isRevokedDocumentId,
            licenseeActions.hasSurrenderedDocumentId,
            licenseeActions.hasEnforcementDocumentId,
            licenseeActions.hasLawsuitsDocumentId,
            licenseeActions.isUnderInvestigationDocumentId
          ]);
          if (!documentsToMove.length) {
            return {};
          }
          return {
            DocumentID: _.map(documentsToMove, function(documentId) {
              return documentId;
            })
          };
        })()
      }
    }
  });
};
