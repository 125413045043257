/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2020] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var Marionette = require('backbone.marionette');
var moment = require('moment');

var ConSerOpenCloseView = require('./ConSerOpenCloseView');

module.exports = Marionette.CollectionView.extend({
  className: 'con-ser-open-close-history-collection-view',

  childView: ConSerOpenCloseView,

  childViewOptions: function(model, index) {
    // Pass the index and readOnly status to the child view
    return {
      childIndex: index
    };
  },

  addRow: function() {
    var lastChildView = this.children.last();

    if (lastChildView && !lastChildView.getClosedDate()) {
      lastChildView.setClosedDate(moment().format('MM/DD/YYYY'));
    }

    this.collection.push({ openedDate: moment().format('MM/DD/YYYY') });
  },

  onBeforeShow: function() {
    this.initialCollectionLength = this.collection.length;
  },

  getFormData: function() {
    return this.children.reduce(function(memo, child) {
      if (child.getFormData && _.isFunction(child.getFormData)) {
        memo.push(child.getFormData());
      }
      return memo;
    }, []);
  },

  onAddChild: function() {
    var lastChildCid = this.children.last().cid;
    this.children.each(function(child) {
      child.setReadonly(child.cid !== lastChildCid);
    });
  },

  onRemoveChild: function() {
    if (this.children.length) {
      var lastChildCid = this.children.last().cid;
      this.children.each(function(child) {
        child.setReadonly(child.cid !== lastChildCid);
      });
    }
  },

  setRequired: function(isRequired) {
    this.children.each(function(child) {
      child.setRequired(isRequired);
    });
  }
});
