/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');

module.exports = function(args) {
  return $.soap({
    body: {
      SubmitEntityBooksAndRecords: {
        $xmlns: 'http://dobs.pa.gov/nd/entity/bpm/1.0',
        EntityID: args.entityId,
        BooksAndRecordsAddresses: (function() {
          if (!args.booksAndRecords.length) {
            return {};
          }
          return {
            BooksAndRecordsAddress: _.map(args.booksAndRecords, function(booksAndRecordsAddress) {
              var booksAndRecordsObj = {
                AddressLine1: booksAndRecordsAddress.addressLine1,
                AddressLine2: booksAndRecordsAddress.addressLine2,
                AddressLine3: booksAndRecordsAddress.addressLine3,
                City: booksAndRecordsAddress.city,
                State: booksAndRecordsAddress.state,
                PostalCode: booksAndRecordsAddress.postalCode,
                Country: booksAndRecordsAddress.country,
                Description: booksAndRecordsAddress.description,
                CompanyName: booksAndRecordsAddress.companyName,
                FirstName: booksAndRecordsAddress.firstName,
                LastName: booksAndRecordsAddress.lastName,
                Phone: booksAndRecordsAddress.phone,
                Fax: booksAndRecordsAddress.fax,
                Email: booksAndRecordsAddress.email,
                IsDeleted: booksAndRecordsAddress.isDeleted ? booksAndRecordsAddress.isDeleted : '0'
              };

              if (booksAndRecordsAddress.id) {
                booksAndRecordsObj.ID = booksAndRecordsAddress.id;
              }

              return booksAndRecordsObj;
            })
          };
        })()
      }
    }
  });
};
