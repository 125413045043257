/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var moment = require('moment');

var cleanUpResponse = require('common/util/cleanUpResponse');

module.exports = function(data) {
  var defer = $.Deferred();

  $.soap({
    body: {
      SubmitProgressReport: {
        $xmlns: 'http://dobs.pa.gov/dp/enforcement/bpm/1.0',
        ProgressReportID: data.progressReportId,
        EnforcementID: data.enforcementId,
        DueDate: data.dueDate ? moment(data.dueDate, 'MM-DD-YYYY').format('YYYY-MM-DD') : null,
        QuarterID: data.quarterId,
        SubmissionDate: data.submissionDate ? moment(data.submissionDate, 'MM-DD-YYYY').format('YYYY-MM-DD') : null,
        AcknowledgmentDate: data.acknowledgmentDate
          ? moment(data.acknowledgmentDate, 'MM-DD-YYYY').format('YYYY-MM-DD')
          : null,
        Year: data.year,
        StatusID: data.statusId,
        Comments: data.comments,
        IsDeleted: data.isDeleted
      }
    },
    blueprint: {
      newProgressReportId: '$..NewProgressReportID'
    }
  })
    .done(function(response) {
      cleanUpResponse(response, { cleanUpTextKeys: true });
      defer.resolve(response.newProgressReportId);
    })
    .fail(function(err) {
      defer.reject(err);
    });

  return defer.promise();
};
