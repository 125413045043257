/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var cleanUpResponse = require('common/util/cleanUpResponse');

module.exports = function(filingId) {
  var deferred = $.Deferred();

  $.soap({
    body: {
      GetFilingDetails: {
        $xmlns: 'http://dobs.pa.gov/cf/filing/bpm/1.0',
        FilingID: filingId
      }
    },
    blueprintArrays: ['GetFilingDetailsResponse.FilingDocuments.CF_FilingDocument'],
    blueprint: {
      id: '$..ID',
      applicationTypeId: '$..ApplicationTypeID',
      applicationType: '$..ApplicationType',
      filingTypeId: '$..FilingTypeID',
      filingType: '$..FilingType',
      isIndefinite: '$..IsIndefinite',
      amount: '$..Amount',
      oversaleAmount: '$..OversaleAmount',
      feeAmount: '$..FeeAmount',
      createdBy: '$..CreatedBy',
      firstName: '$..FirstName', 
      lastName: '$..LastName', 
      email: '$..Email', 
      filingDocuments: [
        '$..CF_FilingDocument',
        {
          id: '$..ID',
          filingId: '$..FilingID',
          file: {
            fileId: '$..DocumentID',
            fileName: '$..DocumentName'
          }
        }
      ]
    }
  })
    .done(function(response) {
      cleanUpResponse(response);

      deferred.resolve(response);
    })
    .fail(function(err) {
      deferred.reject();
    });

  return deferred.promise();
};
