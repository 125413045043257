/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var Backbone = require('backbone');
var Marionette = require('backbone.marionette');
var Syphon = require('backbone.syphon');

var GridView = require('psa-marionette/views/GridView');

var toggleForm = require('common/util/toggleForm');
var MomentCell = require('common/backgrid/MomentCell');
var BooleanCell = require('common/backgrid/BooleanCell');
var modelGet = require('common/util/modelGet');

var CorpFinReg207JDatesCollectionView = require('../../registration-dashboard/views/CorpFinReg207JDatesCollectionView');

var tmplCorpFinOfferingFormView = require('../templates/CorpFinOfferingFormView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplCorpFinOfferingFormView,

  className: 'corp-fin-offering-form-view',

  ui: {
    filedDate: '.filed-date',
    dispositionDate: '.disposition-date',
    increaseDate: '.increase-date',
    dateSentToRecord: '.date-sent-to-record',
    dateDestroyed: '.date-destroyed',
    current207JDatesContainer: '.current-207J-dates-container',
    new207JDatesContainer: '.new-207J-dates-collection-container',
    offeringAmountsReg: '.offering-amounts-reg',
    indefiniteOfferingRadio: '.indefinite-offering-radio',
    offeringTypeSelect: '.offering-type-select',
    addDate: '.add-date',
      deleteDatesLabel: '.delete-dates-label',
    date207J: '.date207J'
  },

  regions: {
    curr207JDatesContainer: '@ui.current207JDatesContainer',
    new207JDatesContainer: '@ui.new207JDatesContainer',
     },

  events: {
    'click @ui.addDate': 'onClickAddDate'
  },

  childEvents: {
    'remove:date': 'onChildRemoveDate'
  },

  behaviors: {
    RadioGroupToggleBehavior: {
      radios: [
        {
          target: '.offering-amounts-exem',
          radioGroup: 'input[name="isIndefiniteOffering"]',
          valueToShow: '0'
        }
      ]
    }
  },

  templateHelpers: function() {
    var isNewRegistration = this.options.isNewRegistration;
    var examiners = modelGet(this.model, 'examiners', []);
    var examiner = modelGet(this.model, 'record.examiner');
    var examinerFirstName = modelGet(this.model, 'record.examinerFirstName');
    var examinerLastName = modelGet(this.model, 'record.examinerLastName');

    /*
     * Current examiner could no longer have examiner role, add current examiner
     * with internal user data if username does not exist in examiners array
     */
    var currentExaminerHasRole = _.find(examiners, examiner, function(e) {
      return examiner === e.userId;
    });

    if (examiner && !currentExaminerHasRole) {
      var currentExaminer = {
        userId: examiner,
        name: !examinerFirstName && !examinerLastName ? '' : examinerLastName + ', ' + examinerFirstName
      };

      examiners.splice(_.sortedIndex(examiners, currentExaminer, 'name'), 0, currentExaminer);
    }
    return {
      examiners: examiners,
      isNewRegistration: isNewRegistration
    };
  },

  onBeforeShow: function() {
    var record = this.model.get('record') || {};
    var recordId = this.model.get('recordId');
    var isRegistration = this.options.isRegistration;
    var isExemption = this.options.isExemption;
    var fileTypeId = record.recordTypeId;

    toggleForm(this.ui.indefiniteOfferingRadio, isExemption);
    toggleForm(this.ui.offeringTypeSelect, isExemption);
    toggleForm(this.ui.offeringAmountsReg, isRegistration);

    if (isRegistration) {
      if (recordId) {
        var curr207JDates = record.reg207JDates;
        this.ui.deleteDatesLabel.show();

        this.curr207JDates = new GridView({
          columns: [
            {
              name: 'reg207JDate',
              label: '207J Date',
              cell: MomentCell.MomentCell,
              formatter: MomentCell.MomentDateFormatter.extend({
                emptyValue: '--'
              }),
              sortValue: MomentCell.MomentSortValue,
              headerCell: 'custom',
              width: 75,
              editable: false
            },
            {
              name: 'shouldDelete',
              label: 'Delete?',
              cell: BooleanCell,
              headerCell: 'custom',
              width: 25,
              editable: true,
              sortable: false
            }
          ],
          emptyText: 'No 207J Dates',
          collection: new Backbone.Collection(curr207JDates)
        });
        this.showChildView('curr207JDatesContainer', this.curr207JDates);
      }
      this.new207JDates = new CorpFinReg207JDatesCollectionView({
        collection: new Backbone.Collection([])
      });
      this.showChildView('new207JDatesContainer', this.new207JDates);
    }

    Syphon.deserialize(this, record);
    this.ui.filedDate.datepicker();
    this.ui.dispositionDate.datepicker();
    this.ui.increaseDate.datepicker();
    this.ui.dateSentToRecord.datepicker();
    this.ui.dateDestroyed.datepicker();
    this.onChangeFileType(fileTypeId);
  },

  // Called by parent view whenever file type changes
  onChangeFileType: function(fileTypeId) {
    var isFileTypeC = fileTypeId === '1';
    var isFileTypeRD = fileTypeId === '9';
    var isRegistration = this.options.isRegistration;
    var isNewRegistration = this.options.isNewRegistration;
    var isExemption = this.options.isExemption;

    toggleForm(this.ui.date207J, isFileTypeC && isRegistration && !isNewRegistration);

    toggleForm(this.ui.offeringTypeSelect, isFileTypeRD && isExemption);
  },

  onClickAddDate: function(e) {
    e.preventDefault();
    this.new207JDates.collection.add({});
  },

  onChildRemoveDates: function(childView) {
    this.new207JDates.collection.remove(childView);
  },

  getFormData: function() {
    var formData = Syphon.serialize(this);
    formData.recordId = this.model.get('recordId');
    var isRegistration = this.options.isRegistration;

    if (isRegistration) {
      if (this.model.get('record')) {
        formData.existing207Js = _.reduce(
          this.curr207JDates.collection.toJSON(),
          function(memo, date) {
            if (date.shouldDelete) {
              memo.push(date.id);
            }
            return memo;
          },
          []
        );
      }
      formData.new207Js = this.new207JDates.getFormData();
    }

    return formData;
  }
});
