/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var moment = require('moment');

var cleanUpResponse = require('common/util/cleanUpResponse');

module.exports = function(entityId) {
  var defer = $.Deferred();

  $.soap({
    body: {
      GetExternalTrustFundsByEntity: {
        $xmlns: 'http://dobs.pa.gov/dp/fund/bpm/1.0',
        EntityID: entityId
      }
    },
    blueprintArrays: [
      'GetExternalTrustFundsByEntityResponse.DP_TrustFund',
      'GetExternalTrustFundsByEntityResponse.DP_TrustSubFund'
    ],
    blueprint: {
      trustFunds: [
        '$..DP_TrustFund',
        {
          id: '$.ID',
          entityId: '$.EntityID',
          name: '$.Name',
          effectiveDate: '$.EffectiveDate',
          approvalDate: '$.ApprovalDate',
          lastAmendmentDate: '$.LastAmendmentDate',
          lastAmendmentApprovalDate: '$.LastAmendmentApprovalDate',
          effectiveAmendDate: '$.EffectiveAmendDate'
        }
      ],
      trustSubfunds: [
        '$..DP_TrustSubFund',
        {
          id: '$.ID',
          trustFundId: '$.TrustFundID',
          name: '$.Name'
        }
      ]
    }
  })
    .done(function(response) {
      cleanUpResponse(response);

      _.each(response.trustFunds, function(fund) {
        if (fund.effectiveDate) {
          fund.effectiveDate = moment(fund.effectiveDate, 'YYYY-MM-DD');
        }
        if (fund.approvalDate) {
          fund.approvalDate = moment(fund.approvalDate, 'YYYY-MM-DD');
        }
        if (fund.lastAmendmentDate) {
          fund.lastAmendmentDate = moment(fund.lastAmendmentDate, 'YYYY-MM-DD');
        }
        if (fund.lastAmendmentApprovalDate) {
          fund.lastAmendmentApprovalDate = moment(fund.lastAmendmentApprovalDate, 'YYYY-MM-DD');
        }
        if (fund.effectiveAmendDate) {
          fund.effectiveAmendDate = moment(fund.effectiveAmendDate, 'YYYY-MM-DD');
        }
      });

      defer.resolve(response);
    })
    .fail(function(err) {
      defer.reject(err);
    });

  return defer.promise();
};
