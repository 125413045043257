/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var Marionette = require('backbone.marionette');
var Syphon = require('backbone.syphon');

var AddressView = require('common/views/AddressView');
var AddressDisplayView = require('common/views/AddressDisplayView');
var toggleForm = require('common/util/toggleForm');

require('../styles/NonDepEntityFormBooksAndRecordsView.css');
var tmplNonDepEntityFormBooksAndRecordsView = require('../templates/NonDepEntityFormBooksAndRecordsView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplNonDepEntityFormBooksAndRecordsView,

  className: function() {
    var baseClass = 'nondep-entity-form-books-and-records-view panel';
    if (this.model.get('isDeleted') === '1') {
      return baseClass + ' panel-danger';
    } else {
      return baseClass + ' panel-info';
    }
  },

  ui: {
    address: '.address',
    oldAddress: '.old-address',
    removeBooksAndRecords: '.remove-books-and-records'
  },

  regions: {
    address: '@ui.address',
    oldAddress: '@ui.oldAddress'
  },

  triggers: {
    'click @ui.removeBooksAndRecords': 'remove:books-and-records'
  },

  behaviors: {
    PhoneFormatBehavior: {}
  },

  templateHelpers: function() {
    var amendmentChanges;
    var showChanges = this.options.isReadOnly && this.options.viewMode !== 'ext' && this.model.get('isUpdate');
    if (showChanges) {
      amendmentChanges = this.getAmendmentChanges();
    }

    return {
      childIndex: this.options.childIndex + 1,
      isDeleted: this.model.get('isDeleted') === '1',
      isNew: this.model.get('isNew'),
      isUpdate: this.model.get('isUpdate'),
      amendmentChanges: amendmentChanges
    };
  },

  onBeforeShow: function() {
    var showChanges = this.options.isReadOnly && this.options.viewMode !== 'ext' && this.model.get('isUpdate');
    var amendmentChanges;
    if (showChanges) {
      amendmentChanges = this.getAmendmentChanges();
    }

    this.showChildView(
      'address',
      new AddressView({
        startingCountry: this.model.get('country'),
        startingPostalCode: this.model.get('postalCode'),
        startingCounty: this.model.get('county'),
        isRequired: true
      })
    );
    Syphon.deserialize(this, this.model.toJSON());

    var oldBooksAndRecord = this.model.get('oldBooksAndRecord') || {};
    if (showChanges && oldBooksAndRecord && amendmentChanges.address) {
      this.showChildView(
        'oldAddress',
        new AddressDisplayView({ model: new Backbone.Model(oldBooksAndRecord.address) })
      );
    }
  },

  getFormData: function() {
    var formData = Syphon.serialize(this);
    formData.siteRecordkeepingId = this.model.get('id');
    return formData;
  },

  getAmendmentChanges: function() {
    var oldBooksAndRecord = this.model.get('oldBooksAndRecord') || {};
    var appAddress = {
      addressLine1: this.model.get('addressLine1'),
      addressLine2: this.model.get('addressLine2'),
      addressLine3: this.model.get('addressLine3'),
      city: this.model.get('city'),
      state: this.model.get('state'),
      postalCode: this.model.get('postalCode'),
      country: this.model.get('country')
    };

    // When an existing contact was updated and application is being reviewed internally,
    // check that an old value exists for each Contact property and that new value is different
    return {
      companyName: oldBooksAndRecord.companyName && this.model.get('companyName') !== oldBooksAndRecord.companyName,
      firstName: oldBooksAndRecord.firstName && this.model.get('firstName') !== oldBooksAndRecord.firstName,
      lastName: oldBooksAndRecord.lastName && this.model.get('lastName') !== oldBooksAndRecord.lastName,
      phone: oldBooksAndRecord.phone && this.model.get('phone') !== oldBooksAndRecord.phone,
      fax: oldBooksAndRecord.fax && this.model.get('fax') !== oldBooksAndRecord.fax,
      email: oldBooksAndRecord.email && this.model.get('email') !== oldBooksAndRecord.email,
      address: !_.isEqual(appAddress, oldBooksAndRecord.address),
      description: oldBooksAndRecord.description && this.model.get('description') !== oldBooksAndRecord.description
    };
  },

  onAttach: function() {
    if (this.options.mode === 'print') {
      var textAreas = $('.input-view');

      _.each(textAreas, function(textArea) {
        var textAreaVal = textArea.value;
        var textAreaid = textArea.id;
        var printElementId = '#'.concat(textAreaid, 'PrintView');
        $(printElementId).text(textAreaVal);
      });
      toggleForm(textAreas, false);
    }
  }
});
