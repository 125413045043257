/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Marionette = require('backbone.marionette');

require('../styles/OfficialTopHeaderView.css');
var tmplOfficialTopHeaderView = require('../templates/OfficialTopHeaderView.hbs');

/* global google */
module.exports = Marionette.LayoutView.extend({
  template: tmplOfficialTopHeaderView,

  className: 'language-bar bg-gry official-top-header-view',

  id: 'language-bar',

  ui: {
    translateLink: '.translate-link'
  },

  events: {
    'click @ui.translateLink': 'onClickTranslateLink'
  },

  onRender: function() {
    window.googleTranslateElementInit = function() {
      var translateConfig = {
        pageLanguage: 'en',
        layout: google.translate.TranslateElement.InlineLayout.SIMPLE
      };
      new google.translate.TranslateElement(translateConfig, 'google_translate_element');
    };

    $.getScript('https://translate.google.com/translate_a/element.js?cb=googleTranslateElementInit');
  },

  onDestroy: function() {
    window.googleTranslateElementInit = undefined;
  }
});
