/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var momentUtil = require('common/util/momentUtil');

module.exports = function(formData) {
  var filingId = formData.filingId;
  var pointOfContact = formData.pointOfContact;
  var submission = formData.newBankCharterSubmission || {};

  var publicationRequirements = formData.publicationRequirements;
  var federalApplications = formData.federalApplications;
  var biographicalFinancialReports = formData.biographicalFinancialReports;
  var articlesOfIncorporation = formData.articlesOfIncorporation;
  var feesAndExpenses = formData.feesAndExpenses;
  var affidavit1003 = formData.affidavit1003;
  var questionnaire = formData.questionnaire;

  var supportingDocuments = formData.supportingDocuments || [];

  var certification = formData.newBankCharterCertification || {};

  var formOutboundDateConfig = {
    sourceFormat: 'MM/DD/YYYY',
    targetFormat: 'YYYY-MM-DD',
    default: ''
  };

  submission.fedReserveFilingDate = momentUtil.formatStr(submission.fedReserveFilingDate, formOutboundDateConfig);
  submission.bankHoldingFilingDate = momentUtil.formatStr(submission.bankHoldingFilingDate, formOutboundDateConfig);

  return $.soap({
    body: {
      SubmitNewBankCharterFiling: {
        $xmlns: 'http://dobs.pa.gov/dp/filing/bpm/1.0',
        FilingID: filingId,
        NewBankCharterFilingID: formData.newBankCharterFilingId,
        PointOfContact: {
          Name: pointOfContact.name,
          Title:pointOfContact.title,
          Phone: pointOfContact.phone,
          Email: pointOfContact.email,
          AddressLine1: pointOfContact.addressLine1,
          AddressLine2: pointOfContact.addressLine2,
          AddressLine3: pointOfContact.addressLine3,
          City: pointOfContact.city,
          State: pointOfContact.state,
          PostalCode: pointOfContact.postalCode,
          County: pointOfContact.county,
          Country: pointOfContact.country
        },
        ProposedName: submission.proposedName,
        CharterTypeID: submission.bankCharterTypeId,
        IsFedReserveMember: submission.isFedReserveMember,
        FedReserveBankID: submission.fedReserveBankId,
        FedReserveFilingDate: submission.fedReserveFilingDate,
        IsBankHolding: submission.isBankHolding,
        BankHoldingBankID: submission.bankHoldingBankId,
        BankHoldingFilingDate: submission.bankHoldingFilingDate,
        PublicationReqsDocID: publicationRequirements.docId,
        PublicationReqsComments: publicationRequirements.publicationReqsComments,
        FederalApplicationDocID: federalApplications.docId,
        FederalApplicationComments: federalApplications.federalApplicationsComments,
        BioFinReportsDocID: biographicalFinancialReports.docId,
        BioFinReportsComments: biographicalFinancialReports.bioFinReportsComments,
        ArticlesOfIncorporationDocID: articlesOfIncorporation.articlesOfIncorporationDocId,
        ArticlesOfIncorporationComments: articlesOfIncorporation.articlesOfIncorporationComments,
        BylawsDocID: articlesOfIncorporation.bylawsDocId,
        BylawsComments: articlesOfIncorporation.bylawsComments,
        FeesExpensesDocID: feesAndExpenses.docId,
        FeesExpensesComments: feesAndExpenses.feesExpensesComments,
        Affidavit1003DocID: affidavit1003.docId,
        Affidavit1003Comments: affidavit1003.affidavit1003Comments,
        QuestionnaireDocID: questionnaire.docId,
        QuestionnaireComments: questionnaire.questionnaireComments,
        DepositInsuranceUploaded: questionnaire.depositInsuranceUploaded,
        IsCertified: certification.isCertified ? '1' : '0',
        CertificationName: certification.certificationName,
        CertificationDocID: certification.certificationDocId,
        SupportingDocuments: (function() {
          if (!supportingDocuments.length) {
            return {};
          }
          return {
            Document: _.map(supportingDocuments, function(supportingDocument) {
              return {
                DocumentID: supportingDocument.fileId
              };
            })
          };
        })()
      }
    }
  });
};
