/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2020] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Marionette = require('backbone.marionette');
var Syphon = require('backbone.syphon');
var Radio = require('backbone.radio');
var Ladda = require('ladda');

var BreadcrumbView = require('common/views/BreadcrumbView');
var momentUtil = require('common/util/momentUtil');

var SecExamHeaderBarView = require('../../exam-dashboard/views/SecExamHeaderBarView');
var SecInvInqHeaderBarView = require('../../investigation-inquiry-dashboard/views/SecInvInqHeaderBarView');
var SubmitExamInvInqReferral = require('../services/SubmitExamInvInqReferral');

var tmplSecExamInvInqReferralEditView = require('../templates/SecExamInvInqReferralEditView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplSecExamInvInqReferralEditView,

  className: 'sec-exam-inv-inq-referral-edit-view container-fluid',

  ui: {
    headerBar: '.header-bar-container',
    breadcrumb: '.breadcrumb-container',
    referralForm: '.referral-form',
    datePickerField: '.date-picker',
    submit: '.submit'
  },

  regions: {
    headerBar: '@ui.headerBar',
    breadcrumb: '@ui.breadcrumb'
  },

  events: {
    'click @ui.submit': 'onClickSubmit'
  },

  behaviors: {
    ToggleBehavoir: {
      toggles: [
        {
          target: '.incoming-form',
          group: '[name="isIncoming"]',
          valuesToShow: '1'
        },
        {
          target: '.outgoing-form',
          group: '[name="isIncoming"]',
          valuesToShow: '0'
        }
      ]
    }
  },

  onBeforeShow: function() {
    var examIdentifiers = this.model.get('examIdentifiers') || {};
    var identifiers = this.model.get('identifiers'); //inv-inq-other identifiers
    var referral = this.model.get('referral') || {};
    var objectId = this.model.get('objectId');

    var breadcrumbs = [
      {
        link: '#sec/dashboard',
        text: 'Home'
      }
    ];
    var headerView;

    if (this.model.get('isExam')) {
      breadcrumbs = breadcrumbs.concat([
        {
          link: '#sec/organization/' + examIdentifiers.organizationId + '/dashboard',
          text: examIdentifiers.applicantLegalName
        },
        {
          link:
            '#sec/organization/' +
            examIdentifiers.organizationId +
            '/license/' +
            examIdentifiers.organizationLicenseId +
            '/dashboard',
          text: examIdentifiers.licenseType
        },
        {
          link: '#sec/exam/' + objectId + '/dashboard',
          text: 'Exam (' + examIdentifiers.examNumber + ')'
        }
      ]);
      headerView = new SecExamHeaderBarView({
        model: new Backbone.Model(examIdentifiers)
      });
    } else if (this.model.get('isInvInq')) {
      if (identifiers.isOrganization === '1') {
        breadcrumbs.push({
          link: '#sec/organization/' + identifiers.objectId + '/dashboard',
          text: identifiers.applicantLegalName
        });
        if (identifiers.objectLicenseId) {
          breadcrumbs.push({
            link:
              '#sec/organization/' + identifiers.objectId + '/license/' + identifiers.objectLicenseId + '/dashboard',
            text: identifiers.licenseType
          });
        }
      } else {
        breadcrumbs.push({
          link: '#sec/individual/' + identifiers.objectId + '/dashboard',
          text: identifiers.applicantLegalName
        });
        if (identifiers.objectLicenseId) {
          breadcrumbs.push({
            link: '#sec/individual/' + identifiers.objectId + '/license/' + identifiers.objectLicenseId + '/dashboard',
            text: identifiers.licenseType
          });
        }
      }

      breadcrumbs.push({
        link: '#sec/investigation-inquiry/' + identifiers.invInqId + '/dashboard',
        text: identifiers.category + ' (' + identifiers.caseNumber + ')'
      });

      headerView = new SecInvInqHeaderBarView({
        model: new Backbone.Model(identifiers),
        isLicenseInvInq: !!identifiers.objectLicenseId
      });
    }
    breadcrumbs.push({
      text: 'Referral'
    });

    this.showChildView('headerBar', headerView);

    this.showChildView(
      'breadcrumb',
      new BreadcrumbView({
        breadcrumbs: breadcrumbs
      })
    );

    Syphon.deserialize(this, referral);

    this.ui.datePickerField.datepicker({});
  },

  onClickSubmit: function(event) {
    event.preventDefault();
    var self = this;
    this.validate().done(function() {
      var l = Ladda.create(self.ui.submit[0]);
      l.start();
      var formData = self.getFormData();
      SubmitExamInvInqReferral(formData)
        .done(function() {
          if (self.model.get('isExam')) {
            Radio.channel('navigate').trigger('show:int:sc:exam-dashboard', self.model.get('objectId'));
          } else if (self.model.get('isInvInq')) {
            Radio.channel('navigate').trigger(
              'show:int:sc:investigation-inquiry-dashboard',
              self.model.get('objectId')
            );
          }
        })
        .fail(function() {
          l.stop();
        });
    });
  },

  getFormData: function() {
    var formData = Syphon.serialize(this);

    formData.objectId = this.model.get('objectId');
    formData.objectTypeId = this.model.get('objectTypeId');
    formData.referralId = this.model.get('referralId');

    formData.referralOn = momentUtil.formatStr(formData.referralOn, momentUtil.defaultOutboundDateConfig);

    return formData;
  },

  validate: function() {
    return this.ui.referralForm.parsley().whenValidate();
  }
});
