/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var Marionette = require('backbone.marionette');

var EmptySetView = require('common/views/EmptySetView');

var MTReviewInvestigation = require('./MTReviewInvestigation');

require('./MTReviewIndividualInvestigationCollectionView.css');

module.exports = Marionette.CollectionView.extend({
  className: 'mt-review-individual-investigation-collection-view',

  emptyView: EmptySetView,

  emptyViewOptions: {
    emptyText: 'No Individual Investigations Provided'
  },

  childView: MTReviewInvestigation,

  childViewOptions: function(model, index) {
    // Pass the index to the collection view
    return {
      childIndex: index,
      mode: 'individual',
      documentTypes: this.options.documentTypes,
      isReadOnly: this.options.isReadOnly
    };
  },

  validate: function(config) {
    // Run the .validate() method on every child view, which should return true
    // if valid
    if (this.collection.length) {
      var validationPromises = this.children.map(function(childView) {
        var validateView = childView.validate.bind(childView);
        return validateView(config);
      });
      return $.when.apply($, validationPromises);
    }
  },

  getFormData: function() {
    return this.children.reduce(function(memo, child) {
      if (child.getFormData && _.isFunction(child.getFormData)) {
        return memo.concat(child.getFormData());
      }
      return memo;
    }, []);
  }
});
