/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var PageableCollection = require('psa-backbone/models/PageableCollection');

var PlaceholderView = require('common/views/PlaceholderView');

var DepFundSubFundSectionView = require('../views/DepFundSubFundSectionView');
var GetTrustSubFundsByTrustFund = require('../services/GetTrustSubFundsByTrustFund');

module.exports = {
  channel: 'dp',

  event: 'build:fund-sub-fund-view',

  builder: function(fundId, isTrust) {
    return new PlaceholderView({
      viewPromise: buildEntityFundView(fundId, isTrust)
    });
  }
};

function buildEntityFundView(fundId, isTrust) {
  var deferred = $.Deferred();
  GetTrustSubFundsByTrustFund(fundId)
    .done(function(audits) {
      deferred.resolve(
        new DepFundSubFundSectionView({
          isTrust: isTrust,
          collection: new PageableCollection(audits, { pageSize: 25 })
        })
      );
    })
    .fail(function(err) {
      deferred.reject(err);
    });
  return deferred.promise();
}
