/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Marionette = require('backbone.marionette');
var Syphon = require('backbone.syphon');

var tmplCheckCasherBranchRequirementsSectionView = require('./CheckCasherBranchRequirementsSectionView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplCheckCasherBranchRequirementsSectionView,

  className: 'check-casher-branch-requirements-section-view',

  ui: {
    requirementsForm: '.requirements-form'
  },

  templateHelpers: function() {
    return {
      mode: this.options.mode
    };
  },

  onBeforeShow: function() {},

  validate: function() {
    return this.ui.requirementsForm.parsley().whenValidate();
  },

  getFormData: function() {
    return Syphon.serialize(this);
  }
});
