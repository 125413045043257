/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Marionette = require('backbone.marionette');
var Ladda = require('ladda');
var Radio = require('backbone.radio');
var Syphon = require('backbone.syphon');

var BreadcrumbView = require('common/views/BreadcrumbView');
var AddressView = require('common/views/AddressView');

var SubmitSEGLocation = require('../services/SubmitSEGLocation');

var tmplDepSEGLocationNewView = require('../templates/DepSEGLocationNewView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplDepSEGLocationNewView,

  className: 'dep-seg-add-view container-fluid',

  ui: {
    segForm: '.seg-form-container',
    breadcrumb: '.breadcrumb-container',
    submit: '.btn-submit',
    address: '.address'
  },

  regions: {
    segForm: '@ui.segForm',
    breadcrumb: '@ui.breadcrumb',
    address: '@ui.address'
  },

  events: {
    'click @ui.submit': 'onClickSubmit'
  },

  onBeforeShow: function() {
    var segId = this.model.get('segId');
    var entityId = this.model.get('entityId');

    this.showChildView(
      'breadcrumb',
      new BreadcrumbView({
        breadcrumbs: [
          {
            link: '#dep/dashboard',
            text: 'Home'
          },
          {
            link: '#dep/entity/' + entityId + '/dashboard',
            text: this.model.get('entityIdentifiers').entityName
          },
          {
            text: 'SEG',
            link: '#dep/entity/' + entityId + '/seg/' + segId + '/dashboard'
          },
          {
            text: 'New SEG Location'
          }
        ]
      })
    );

    this.showChildView(
      'address',
      new AddressView({
        horizontal: true,
        isRequired: false
      })
    );
  },

  onClickSubmit: function(e) {
    e.preventDefault();
    this.validate().done(
      function() {
        var laddaContext = Ladda.create(this.ui.submit.get(0));
        laddaContext.start();
        var formData = this.getFormData();
        SubmitSEGLocation(formData)
          .done(function() {
            Radio.channel('navigate').trigger('show:int:dp:entity:seg', formData.entityId, formData.segId);
          })
          .fail(function() {
            laddaContext.stop();
          });
      }.bind(this)
    );
  },

  validate: function() {
    return this.ui.address.parsley().whenValidate();
  },

  getFormData: function() {
    var formData = Syphon.serialize(this);
    formData.isDeleted = 0;
    formData.segId = this.model.get('segId');
    formData.entityId = this.model.get('entityId');
    return formData;
  }
});
