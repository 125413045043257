/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');

var cleanUpResponse = require('common/util/cleanUpResponse');

module.exports = function(formData) {
  var defer = $.Deferred();

  $.soap({
    body: {
      SubmitIssueExam: {
        $xmlns: 'http://dobs.pa.gov/nd/exam/bpm/1.0',
        ExamPeriodID: formData.examYearId,
        Licenses: (function() {
          if (!formData.licenses) {
            return {};
          }
          return {
            License: _.map(formData.licenses, function(license) {
              return {
                LicenseID: license.licenseId,
                RegionID: license.nonDExamRegionId
              };
            })
          };
        })()
      }
    }
  })
    .done(function(response) {
      cleanUpResponse(response);
      defer.resolve(response);
    })
    .fail(function(err) {
      defer.reject(err);
    });

  return defer.promise();
};
