/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var Scaffold = require('common/layout/Scaffold');
var NProgress = require('nprogress');
var GetAllLicenseTypes = require('../services/GetAllLicenseTypes');
var GetAllInstitutionTypes = require('../services/GetAllInstitutionTypes.js');
var GetAllCOunties = require('../services/GetAllCounties.js');
var GetEntites = require('../services/GetEntities.js');
var InstitutionSearchView = require('../views/InstitutionSearchView.js');

module.exports = function() {
  NProgress.start();
  NProgress.set(0.6);

  $.when(GetAllLicenseTypes(), GetAllInstitutionTypes(), GetAllCOunties(), GetEntites())
    .done(function(licenseTypes, institutionTypes, counties, entities) {
      licenseTypes = _.filter(licenseTypes, function(licType) {
        return licType.id <= 5;
      });
      Scaffold.content.show(
        new InstitutionSearchView({
          model: new Backbone.Model({
            licenseTypes: licenseTypes,
            institutionTypes: institutionTypes,
            counties: counties,
            entities: entities
          })
        })
      );
      NProgress.done();
    })
    .fail(function() {
      NProgress.done();
    });
};

