var Handlebars = require("handlebars/dist/handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression, alias4=container.lambda;

  return "\n<div class=\"page-title clearfix\">\n    <h1 class=\"pull-left m-n p-n\">"
    + alias3(((helper = (helper = helpers.entityName || (depth0 != null ? depth0.entityName : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"entityName","hash":{},"data":data}) : helper)))
    + "</h1>\n    <div class=\"page-title-buttons-container\">\n        <div class=\"dropdown-container\"></div>\n    </div>\n</div>\n<div class=\"page-subtitle\">\n    <h2>Entity Risk Matrix</h2>\n    <div class=\"page-subtitle-details\">"
    + alias3(alias4(((stack1 = (depth0 != null ? depth0.riskMatrix : depth0)) != null ? stack1.riskType : stack1), depth0))
    + " ("
    + alias3(alias4(((stack1 = (depth0 != null ? depth0.riskMatrix : depth0)) != null ? stack1.asOfDate : stack1), depth0))
    + ")</div>\n</div>\n<div class=\"breadcrumb-container\"></div>\n\n<div class=\"form-horizontal form-static row col-sm-8 col-sm-offset-2\">\n  <div class=\"row\">\n    <div class=\"col-sm-12 col-md-6\">\n      <div class=\"form-group\">\n        <label class=\"control-label col-sm-3 col-md-4\">Type Of Risk</label>\n        <div class=\"form-control-static col-sm-9 col-md-8\">"
    + alias3(alias4(((stack1 = (depth0 != null ? depth0.riskMatrix : depth0)) != null ? stack1.riskType : stack1), depth0))
    + "</div>\n      </div>\n    </div>\n    <div class=\"col-sm-12 col-md-6\">\n      <div class=\"form-group\">\n        <label class=\"control-label col-sm-3 col-md-4\">As Of Date</label>\n        <div class=\"form-control-static col-sm-9 col-md-8\">"
    + alias3((helpers["default"] || (depth0 && depth0["default"]) || alias2).call(alias1,((stack1 = (depth0 != null ? depth0.riskMatrix : depth0)) != null ? stack1.asOfDate : stack1),"--",{"name":"default","hash":{},"data":data}))
    + "</div>\n      </div>\n    </div>\n  </div>\n  <div class=\"row\">\n    <div class=\"col-sm-12 col-md-6\">\n      <div class=\"form-group\">\n        <label class=\"control-label col-sm-3 col-md-4\">Exam Start Date</label>\n        <div class=\"form-control-static col-sm-9 col-md-8\">"
    + alias3((helpers["default"] || (depth0 && depth0["default"]) || alias2).call(alias1,((stack1 = (depth0 != null ? depth0.riskMatrix : depth0)) != null ? stack1.examStartDate : stack1),"--",{"name":"default","hash":{},"data":data}))
    + "</div>\n      </div>\n    </div>\n    <div class=\"col-sm-12 col-md-6\">\n      <div class=\"form-group\">\n        <label class=\"control-label col-sm-3 col-md-4\">Date Entered</label>\n        <div class=\"form-control-static col-sm-9 col-md-8\">"
    + alias3((helpers["default"] || (depth0 && depth0["default"]) || alias2).call(alias1,((stack1 = (depth0 != null ? depth0.riskMatrix : depth0)) != null ? stack1.dateEntered : stack1),"--",{"name":"default","hash":{},"data":data}))
    + "</div>\n      </div>\n    </div>\n  </div>\n  <div class=\"row\">\n    <div class=\"col-sm-12 col-md-6\">\n      <div class=\"form-group\">\n        <label class=\"control-label col-sm-3 col-md-4\">Risk Assessment</label>\n        <div class=\"form-control-static col-sm-9 col-md-8\">"
    + alias3((helpers["default"] || (depth0 && depth0["default"]) || alias2).call(alias1,((stack1 = (depth0 != null ? depth0.riskMatrix : depth0)) != null ? stack1.riskAssessment : stack1),"--",{"name":"default","hash":{},"data":data}))
    + "</div>\n      </div>\n    </div>\n    <div class=\"col-sm-12 col-md-6\">\n      <div class=\"form-group\">\n        <label class=\"control-label col-sm-3 col-md-4\">Direction Of Risk</label>\n        <div class=\"form-control-static col-sm-9 col-md-8\">"
    + alias3((helpers["default"] || (depth0 && depth0["default"]) || alias2).call(alias1,((stack1 = (depth0 != null ? depth0.riskMatrix : depth0)) != null ? stack1.riskDirection : stack1),"--",{"name":"default","hash":{},"data":data}))
    + "</div>\n      </div>\n    </div>\n  </div>\n  <div class=\"row\">\n    <div class=\"col-sm-12 col-md-6\">\n        <div class=\"form-group\">\n          <label class=\"control-label col-sm-3 col-md-4\">Comments</label>\n          <div class=\"form-control-static col-sm-9 col-md-8\">"
    + alias3((helpers["default"] || (depth0 && depth0["default"]) || alias2).call(alias1,((stack1 = (depth0 != null ? depth0.riskMatrix : depth0)) != null ? stack1.comments : stack1),"--",{"name":"default","hash":{},"data":data}))
    + "</div>\n        </div>\n    </div>\n  </div>\n</div>";
},"useData":true});