/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Marionette = require('backbone.marionette');
var Syphon = require('backbone.syphon');

var tmplDepRiskMatrixFormView = require('../templates/DepRiskMatrixFormView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplDepRiskMatrixFormView,

  className: 'dep-risk-matrix-form-view',

  ui: {
    riskMatrixForm: 'form',
    asOfDate: '.as-of-date',
    examStartDate: '.exam-start-date',
    dateEntered: '.date-entered'
  },

  events: {
    'change @ui.trackingTypeSelect': 'onChangeTrackingType'
  },

  onBeforeShow: function() {
    Syphon.deserialize(this, this.model.get('riskMatrix'));

    this.ui.asOfDate.datepicker();
    this.ui.examStartDate.datepicker();
    this.ui.dateEntered.datepicker();
  },

  validate: function() {
    return this.ui.riskMatrixForm.parsley().whenValidate();
  },

  getFormData: function() {
    return Syphon.serialize(this);
  }
});
