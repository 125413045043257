/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');

var cleanUpResponse = require('common/util/cleanUpResponse');

module.exports = function(invInqId) {
  var deferred = $.Deferred();

  $.soap({
    body: {
      GetInvestigationInquiryComplainantByInvInq: {
        $xmlns: 'http://dobs.pa.gov/sc/investigationinquiry/bpm/1.0',
        InvInqID: invInqId
      }
    },
    blueprintArrays: ['GetInvestigationInquiryComplainantByInvInqResponse.SC_InvestigationInquiryComplainant'],
    blueprint: {
      complainants: [
        '$..SC_InvestigationInquiryComplainant',
        {
          id: '$..ID',
          invInqId: '$..InvInqID',
          name: '$..Name',
          addressId: '$..AddressID',
          age: '$..Age',
          email: '$..Email',
          phone: '$..Phone',
          fax: '$..Fax',
          note: '$..Note',
          address: {
            addressLine1: '$..AddressLine1',
            addressLine2: '$..AddressLine2',
            addressLine3: '$..AddressLine3',
            county: '$..County',
            city: '$..City',
            state: '$..State',
            postalCode: '$..PostalCode',
            country: '$..Country'
          }
        }
      ]
    }
  })
    .done(function(response) {
      cleanUpResponse(response);
      _.each(response.complainants, function(complainant) {
        complainant.email = complainant.email ? complainant.email : '--';
        complainant.phone = complainant.phone ? complainant.phone : '--';
        complainant.fax = complainant.fax ? complainant.fax : '--';
        complainant.note = complainant.note ? complainant.note : '--';
        complainant.age = complainant.age ? complainant.age : '--';
      });
      deferred.resolve(response.complainants);
    })
    .fail(function(err) {
      deferred.reject();
    });

  return deferred.promise();
};
