/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var moment = require('moment');

var cleanUpResponse = require('common/util/cleanUpResponse');

module.exports = function(data) {
  var defer = $.Deferred();

  $.soap({
    body: {
      SubmitEnforcement: {
        $xmlns: 'http://dobs.pa.gov/dp/enforcement/bpm/1.0',
        EntityID: data.entityId,
        EnforcementID: data.enforcementId,
        ExamAsOfDate: data.examAsOfDate ? moment(data.examAsOfDate, 'MM-DD-YYYY').format('YYYY-MM-DD') : null,
        EffectiveDate: data.effectiveDate ? moment(data.effectiveDate, 'MM-DD-YYYY').format('YYYY-MM-DD') : null,
        TerminationDate: data.terminationDate ? moment(data.terminationDate, 'MM-DD-YYYY').format('YYYY-MM-DD') : null,
        ReturnedToCompositeHalf: data.returnedToCompositeHalf ? moment(data.returnedToCompositeHalf, 'MM-DD-YYYY').format('YYYY-MM-DD') : null,
        CMP: data.cmp,
        ActionID: data.actionId,
        AgencyID: data.agencyId,
        AreaID: data.areaId,
        TypeID: data.typeId,
        StatusID: data.statusId,
        Comments: data.comments,
        IsDeleted: data.isDeleted
      }
    },
    blueprint: {
      newEnforcementId: '$..NewEnforcementID'
    }
  })
    .done(function(response) {
      cleanUpResponse(response, { cleanUpTextKeys: true });
      defer.resolve(response.newEnforcementId);
    })
    .fail(function(err) {
      defer.reject(err);
    });

  return defer.promise();
};
