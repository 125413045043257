/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var cleanUpResponse = require('common/util/cleanUpResponse');

var momentUtil = require('common/util/momentUtil');

module.exports = function(filingId) {
  var deferred = $.Deferred();
  $.soap({
    body: {
      GetSubmitAuditFiling: {
        $xmlns: 'http://dobs.pa.gov/dp/filing/bpm/1.0',
        FilingID: filingId
      }
    },
    blueprintArrays: [
      'GetSubmitAuditFilingResponse.SupportingDocuments.DP_FilingSupportingDocument',
      'GetSubmitAuditFilingResponse.TrustFunds.DP_TrustFund',
      'GetSubmitAuditFilingResponse.TrustSubfunds.DP_TrustSubFund'
    ],
    blueprint: {
      id: '$..ID',
      filingId: '$..FilingID',
      auditDated: '$..AuditDated',
      auditTypeId: '$..AuditTypeID',
      isCPA: '$..IsCPA',
      isFund: '$..IsFund',
      fundId: '$..FundID',
      subFundId: '$..SubFundID',
      auditFileDocument: {
        fileId: '$..AuditFileDocID',
        fileName: '$..AuditFileDocName'
      },
      auditComments: '$..AuditComments',
      supportingDocuments: [
        '$..DP_FilingSupportingDocument',
        {
          id: '$..ID',
          filingId: '$..FilingID',
          documentId: '$..DocumentID',
          documentName: '$..DocumentName',
          documentTypeId: '$..DocumentTypeID',
          documentType: '$..DocumentType',
          file: {
            fileId: '$.DocumentID',
            fileName: '$.DocumentName'
          }
        }
      ],
      trustFunds: [
        '$..DP_TrustFund',
        {
          id: '$..ID',
          name: '$..Name'
        }
      ],
      trustSubfunds: [
        '$..DP_TrustSubFund',
        {
          id: '$..ID',
          name: '$..Name',
          trustFundId: '$..TrustFundID'
        }
      ]
    }
  })
    .done(function(response) {
      cleanUpResponse(response);

      var emptyDefaultInboundDateConfig = {
        sourceFormat: 'YYYY-MM-DD',
        targetFormat: 'MM/DD/YYYY',
        default: ''
      };

      response.auditDated = momentUtil.formatStr(response.auditDated, emptyDefaultInboundDateConfig);

      deferred.resolve(response);
    })
    .fail(function(err) {
      deferred.reject();
    });

  return deferred.promise();
};
