/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var moment = require('moment');

var cleanUpResponse = require('common/util/cleanUpResponse');

module.exports = function(formData) {
  var defer = $.Deferred();

  formData.paidDate = formData.paidDate ? moment(formData.paidDate, 'MM/DD/YYYY').format('YYYY-MM-DD') : '';

  $.soap({
    body: {
      SubmitNonFINRAPaymentMemberDetail: {
        $xmlns: 'http://dobs.pa.gov/sc/nonfinrapaymentmemberdetail/bpm/1.0',
        ObjectTypeID: formData.objectTypeId,
        ObjectID: formData.objectId,
        IsDeleted: formData.isDeleted,
        ID: formData.id,
        TypeID: formData.typeId,
        PaidDate: formData.paidDate,
        RegardingID: formData.regardingId,
        Amount: formData.amount,
        Comments: formData.comments
      }
    },
    blueprint: {
      id: '$..ID',
      objectTypeId: '$..ObjectTypeID',
      objectId: '$..ObjectID',
      typeId: '$..TypeID',
      paidDate: '$..PaidDate',
      regardingId: '$..RegardingID',
      amount: '$..Amount',
      comments: '$..Comments',
      type: '$..Type',
      regarding: '$..Regarding'
    }
  })
    .done(function(response) {
      cleanUpResponse(response);
      if (response.paidDate) {
        response.paidDate = moment(response.paidDate, 'YYYY-MM-DD');
      }
      defer.resolve(response);
    })
    .fail(function(err) {
      defer.reject(err);
    });

  return defer.promise();
};
