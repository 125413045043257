/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

 var _get = require('lodash.get');
 var Backbone = require('backbone');
 var NProgress = require('nprogress');
 
 var GetNotification = require('common/services/GetNotification');
 var GetApplicationDetails = require('../../../common/services/GetApplicationDetails');
 var GetLicenseDetails = require('../../../license-dashboard/services/GetLicenseDetails');
 
 var NonDepLicenseViewNotificationTaskView = require('./NonDepLicenseViewNotificationTaskView');
 
 module.exports = {
   event: 'build:nd:review-license-number',
 
   channel: 'task',
 
   builder: function(task) {
     var defer = $.Deferred();
     var notificationId = _get(task, 'taskData.CM.notificationId');
     var applicationId = _get(task, 'taskData.ND.applicationId');
     var entityId = _get(task, 'taskData.ND.entityId');
     var licenseId = _get(task, 'taskData.ND.correspondenceId');

     $.when(GetNotification(notificationId),GetApplicationDetails(applicationId),GetLicenseDetails(licenseId) )
       .done(function(notification,application,license) {
         NProgress.set(0.85);
         defer.resolve(
           new NonDepLicenseViewNotificationTaskView({
             model: new Backbone.Model({
               task: task,
               taskId: task.taskId,
               applicationId: applicationId,
               notification: notification,
               entityId:entityId,
               application:application,
               license:license
             })
           })
         );
       })
       .fail(function(err) {
         defer.reject(err);
       });
 
     return defer.promise();
   }
 };
 