var Handlebars = require("handlebars/dist/handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "          <option value=\""
    + alias4(((helper = (helper = helpers.userId || (depth0 != null ? depth0.userId : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"userId","hash":{},"data":data}) : helper)))
    + "\">"
    + alias4(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data}) : helper)))
    + " - ("
    + alias4(((helper = (helper = helpers.userId || (depth0 != null ? depth0.userId : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"userId","hash":{},"data":data}) : helper)))
    + ")</option>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "\n<div class=\"side-bar-container pull-left\"></div>\n<div class=\"dashboard-container pull-right\">\n  <h2>Next Step Search</h2>\n  <div class=\"row\">\n    <div class=\"col-sm-3\">\n      <div class=\"form-group\">\n        <label class=\"control-label\">Exam/Inquiry/Investigation Number</label>\n        <input type=\"text\" name=\"caseNumber\" class=\"form-control\" placeholder=\"Exam/Inquiry/Investigation Number\" />\n      </div>\n    </div>\n    <div class=\"col-sm-3\">\n      <div class=\"form-group\">\n        <label class=\"control-label\">Type</label>\n        <select name=\"nextStepType\" class=\"form-control\">\n          <option value=\"\">--</option>\n          <option value=\"EXAM\">Exam</option>\n          <option value=\"INV\">Investigation</option>\n          <option value=\"INQ\">Inquiry</option>\n          <option value=\"OTHER\">Other</option>\n        </select>\n      </div>\n    </div>\n    <div class=\"col-sm-3\">\n      <div class=\"form-group\">\n        <label class=\"control-label\">Assigned To</label>\n        <select name=\"nextStepAssignedTo\" class=\"form-control assignee-select\">\n          <option value=\"\">--</option>\n"
    + ((stack1 = helpers.each.call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.scUsers : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "        </select>\n      </div>\n    </div>\n    <div class=\"col-sm-3\">\n      <div class=\"form-group\">\n        <label class=\"control-label\">Search Type</label>\n        <select name=\"objectType\" class=\"form-control\">\n          <option value=\"\">--</option>\n          <option value=\"INDIVIDUAL\">Individual</option>\n          <option value=\"ORGANIZATION\">Organization</option>\n        </select>\n      </div>\n    </div>\n  </div>\n  <div class=\"row\">\n    <div class=\"col-sm-3 org-filter\">\n      <div class=\"form-group\">\n        <label class=\"control-label\">DBA Name</label>\n        <input type=\"text\" name=\"dbaName\" class=\"form-control\" placeholder=\"DBA Name\">\n      </div>\n    </div>\n    <div class=\"col-sm-3 ind-filter\">\n      <div class=\"form-group\">\n        <label class=\"control-label\">Last Name</label>\n        <input type=\"text\" name=\"lastName\" class=\"form-control\" placeholder=\"Last Name\">\n      </div>\n    </div>\n    <div class=\"col-sm-3 ind-filter\">\n      <div class=\"form-group\">\n        <label class=\"control-label\">First Name</label>\n        <input type=\"text\" name=\"firstName\" class=\"form-control\" placeholder=\"First Name\">\n      </div>\n    </div>\n  </div>\n  <div class=\"search-buttons m-b-sm\">\n    <button class=\"btn btn-primary ladda-button search\" data-style=\"expand-right\">\n      <span class=\"ladda-label\"><i class=\"fa fa-search m-r-sm\"></i>Search</span>\n    </button>\n    <button class=\"btn btn-default clear-filters\"><i class=\"fa fa-eraser m-r-sm\"></i>Clear Filters</button>\n    <span class=\"search-summary\"></span>\n  </div>\n  <div class=\"search-results-container\"></div>\n</div>";
},"useData":true});