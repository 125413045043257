/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2020] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Marionette = require('backbone.marionette');
var Ladda = require('ladda');
var Radio = require('backbone.radio');

var modelGet = require('common/util/modelGet');
var momentUtil = require('common/util/momentUtil');
var BreadcrumbView = require('common/views/BreadcrumbView');

var SubmitFiling = require('../services/SubmitFiling');
var DepFilingFormView = require('./DepFilingFormView');

var tmplDepFilingEditView = require('../templates/DepFilingEditView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplDepFilingEditView,

  className: 'dep-filing-edit-view container-fluid',

  ui: {
    filingFormContainer: '.filing-form-container',
    headerBar: '.header-bar-container',
    breadcrumb: '.breadcrumb-container',
    submitFiling: '.submit'
  },

  regions: {
    filingFormContainer: '@ui.filingFormContainer',
    headerBar: '@ui.headerBar',
    breadcrumb: '@ui.breadcrumb'
  },

  events: {
    'click @ui.submitFiling': 'onClickSubmit'
  },

  onBeforeShow: function() {
    var filing = this.model.get('filing') || {};
    this.filingId = filing.filingId;
    this.entityId = filing.entityId;
    var entityName = modelGet(this.model, 'entityIdentifiers.entityName');
    var filingType = filing.filingType;

    this.showChildView(
      'breadcrumb',
      new BreadcrumbView({
        breadcrumbs: [
          {
            link: '#dep/dashboard',
            text: 'Home'
          },
          {
            link: '#dep/entity/' + this.entityId + '/dashboard',
            text: entityName
          },
          {
            link: '#dep/entity/' + this.entityId + '/filing/' + this.filingId + '/dashboard',
            text: filingType + ' Filing'
          },
          {
            text: 'Edit Filing'
          }
        ]
      })
    );

    this.filingForm = new DepFilingFormView({
      model: this.model
    });

    this.showChildView('filingFormContainer', this.filingForm);
  },

  onClickSubmit: function(event) {
    event.preventDefault();
    var self = this;
    var l = Ladda.create(this.ui.submitFiling[0]);
    l.start();
    var formData = this.getFormData();
    SubmitFiling(formData)
      .done(function() {
        Radio.channel('navigate').trigger('show:int:dp:filing', self.entityId, self.filingId);
      })
      .fail(function() {
        l.stop();
      });
  },

  getFormData: function() {
    var formData = this.filingForm.getFormData();

    var dateAttributes = [
      'decisionDueDate',
      'receivedDate',
      'acceptedDate',
      'pubProofsGeneralReceivedDate',
      'pubProofsGeneralExpireDate',
      'pubProofsLegalReceivedDate',
      'pubProofsLegalExpireDate',
      'bulletinPublishedDate',
      'bulletinExpireDate',
      'infoRequestMadeDate',
      'infoRequestReceivedDate',
      'actionDate',
      'frbApprovalDate',
      'fdicApprovalDate',
      'ncuaApprovalDate',
      'approvalDate',
      'acknowledgementSentDate',
      'acknowledgementNotificationDate'
    ];

    momentUtil.formatDateAttributes(formData, dateAttributes, 'MM/DD/YYYY', 'YYYY-MM-DD');
    formData.filingId = this.filingId;

    return formData;
  }
});
