/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var Marionette = require('backbone.marionette');
var Backbone = require('backbone');

var GridView = require('psa-marionette/views/GridView');
var MomentCell = require('common/backgrid/MomentCell');
var FileUploadView = require('common/views/FileUploadView');
var FileUploadCollection = require('psa-backbone/models/FileUploadCollection');
var toggleForm = require('common/util/toggleForm');

var modelGet = require('common/util/modelGet');
var ParagraphCell = require('common/backgrid/ParagraphCell');

require('../styles/DepExamMainSectionView.css');
var tmplDepExamMainSectionView = require('../templates/DepExamMainSectionView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplDepExamMainSectionView,

  className: 'dep-exam-main-section-view',

  ui: {
    isBHCExam: '.bhc-exam-container',
    ssSection: '.ss-container',
    ssFirstDayLetter: '.ss-container .first-day-letter-container',
    ssFirstDayLetterDefault: '.ss-container .first-day-letter-default',
    ssExamHours: '.ss-container .exam-hours-container',
    trustSection: '.trust-container',
    trustFirstDayLetter: '.trust-container .first-day-letter-container',
    trustFirstDayLetterDefault: '.trust-container .first-day-letter-default',
    trustExamHours: '.trust-container .exam-hours-container',
    itSection: '.it-container',
    itFirstDayLetter: '.it-container .first-day-letter-container',
    itFirstDayLetterDefault: '.it-container .first-day-letter-default',
    itExamHours: '.it-container .exam-hours-container',
    complianceSection: '.compliance-container',
    craSection: '.cra-container',
    bhcSection: '.bhc-container',
    tspSection: '.tsp-container',
    mainSection: '.exam-main-container',
    mainInfoSection: '.exam-info-section',
    responseSection: '.responses-container',

    examYear: '.exam-year',
    firstDayLetterSent: '.first-day-letter-container',

    eicSections: '.eic-container',
    federalEICSections: '.federal-eic-container',
    //Processing Section
    examProcessType: '.process-type-container',

    saerCompleteDate: '.saer-complete-date-container',

    bankJointExam: '.bank-joint-exam-container',
    jointPDOBSDates: '.joint-pdobs-container',
    ssEICSignOff: '.ss-eic-signoff-container',
    itEICSignOff: '.it-eic-signoff-container',
    trustEICSignOff: '.trust-eic-signoff-container',

    responseGrid: '.response-grid-container',
    participatingAgencyGrid: '.participating-agency-grid-container',
    priorExamGrid: '.prior-exam-grid-container'
  },

  regions: {
    ssFirstDayLetter: '@ui.ssFirstDayLetter',
    trustFirstDayLetter: '@ui.trustFirstDayLetter',
    itFirstDayLetter: '@ui.itFirstDayLetter',
    responseGrid: '@ui.responseGrid',
    participatingAgencyGrid: '@ui.participatingAgencyGrid',
    priorExamGrid: '@ui.priorExamGrid'
  },

  templateHelpers: function() {
    var isBank = this.model.get('entityCategoryId') === '1';
    var isCU = this.model.get('entityCategoryId') === '2';
    // var isTrust = this.model.get('entityCategoryId') === '3';
    return {
      isBank: isBank,
      isCU: isCU
      //isTrust: isTrust
    };
  },

  onBeforeShow: function() {
    var entityTypeId = modelGet(this.model, 'entityTypeId');
    var isTSP = entityTypeId === '3' || entityTypeId === '12' || entityTypeId === '23';
    if (isTSP) {
      this.ui.tspSection.show();
      this.agencyCollection = new Backbone.Collection(modelGet(this.model, 'exam.examParticipatingAgencies', []));

      var participatingAgencyColumns = [
        {
          name: 'nameDisplay',
          label: 'Name',
          cell: 'string',
          editable: false,
          headerCell: 'custom',
          width: 10,
          default: 'enter name'
        },
        {
          name: 'regionDisplay',
          label: 'Region/District',
          cell: 'string',
          editable: false,
          headerCell: 'custom',
          width: 20
        },
        {
          name: 'otherRegionDisplay',
          label: 'Other Region/District',
          cell: 'string',
          editable: false,
          headerCell: 'custom',
          width: 20
        }
      ];
      var paGridView = new GridView({
        columns: participatingAgencyColumns,
        emptyText: 'No Participating Agencies',
        collection: this.agencyCollection
      });
      this.showChildView('participatingAgencyGrid', paGridView);

      this.priorExamCollection = new Backbone.Collection(modelGet(this.model, 'exam.examPriorExams', []));

      var priorExamColumns = [
        {
          name: 'priorExamDate',
          label: 'Date',
          cell: MomentCell.MomentCell,
          formatter: MomentCell.MomentDateFormatter.extend({
            emptyValue: '--'
          }),
          editable: false,
          headerCell: 'custom',
          width: 25
        },
        {
          name: 'ratingDisplay',
          label: 'Rating',
          cell: 'string',
          editable: false,
          headerCell: 'custom',
          width: 25
        },
        {
          name: 'leadAgencyDisplay',
          label: 'Agency In Charge',
          cell: 'string',
          editable: false,
          headerCell: 'custom',
          width: 25
        },
        {
          name: 'otherLeadAgencyDisplay',
          label: 'Other Agency In Charge',
          cell: 'string',
          editable: false,
          headerCell: 'custom',
          width: 25
        }
      ];
      var priorGridView = new GridView({
        columns: priorExamColumns,
        emptyText: 'No Prior Exams',
        collection: this.priorExamCollection
      });
      this.showChildView('priorExamGrid', priorGridView);
    } else {
      this.ui.mainInfoSection.show();
      this.ui.mainSection.show();
      this.ui.responseSection.show();
      // Show Exam Area Sections
      if (modelGet(this.model, 'exam.ssArea.id')) {
        this.ui.ssSection.show();

        this.ui.ssFirstDayLetter.closest('div.row').show();

        var ssFirstDayLetter = modelGet(this.model, 'exam.ssArea.firstDayLetter', {});
        this.showChildView(
          'ssFirstDayLetter',
          new FileUploadView({
            isReadOnly: true,
            allowMultipleFiles: false,
            collection: new FileUploadCollection([ssFirstDayLetter])
          })
        );

        if (ssFirstDayLetter.fileId) {
          this.ui.ssFirstDayLetterDefault.hide();
        } else {
          this.ui.ssFirstDayLetter.hide();
        }
      }
      if (modelGet(this.model, 'exam.trustArea.id')) {
        this.ui.trustSection.show();

        this.ui.trustFirstDayLetter.closest('div.row').show();

        var trustFirstDayLetter = modelGet(this.model, 'exam.trustArea.firstDayLetter', {});
        this.showChildView(
          'trustFirstDayLetter',
          new FileUploadView({
            isReadOnly: true,
            allowMultipleFiles: false,
            collection: new FileUploadCollection([trustFirstDayLetter])
          })
        );

        if (trustFirstDayLetter.fileId) {
          this.ui.trustFirstDayLetterDefault.hide();
        } else {
          this.ui.trustFirstDayLetter.hide();
        }
      }
      if (modelGet(this.model, 'exam.itArea.id')) {
        this.ui.itSection.show();

        this.ui.itFirstDayLetter.closest('div.row').show();

        var itFirstDayLetter = modelGet(this.model, 'exam.itArea.firstDayLetter', {});
        this.showChildView(
          'itFirstDayLetter',
          new FileUploadView({
            isReadOnly: true,
            allowMultipleFiles: false,
            collection: new FileUploadCollection([itFirstDayLetter])
          })
        );

        if (itFirstDayLetter.fileId) {
          this.ui.itFirstDayLetterDefault.hide();
        } else {
          this.ui.itFirstDayLetter.hide();
        }
      }
      if (modelGet(this.model, 'exam.complianceArea.id')) {
        this.ui.complianceSection.show();
      }
      if (modelGet(this.model, 'exam.craArea.id')) {
        this.ui.craSection.show();
      }
      if (this.model.get('entityTypeId') === '2' || this.model.get('entityTypeId') === '22') {
        this.ui.isBHCExam.show();
        if (modelGet(this.model, 'exam.isHoldingCompanyExam') === '1') {
          this.ui.bhcSection.show();
        }
      }

      //Processing Section
      var categoryId = this.model.get('entityCategoryId');

      // Show S&S Exam Hours for Trust and CU
      toggleForm(this.ui.ssExamHours, categoryId === '2' || categoryId === '3');

      // Show IT Exam Hours for Trust and CU
      toggleForm(this.ui.itExamHours, categoryId === '2' || categoryId === '3');

      // Bank
      if (categoryId === '1') {
        this.ui.examProcessType.show();
        this.ui.saerCompleteDate.show();
      }
      // Credit Union
      else if (categoryId === '2') {
        this.ui.examProcessType.show();
      }

      var examAreas = _.pluck(modelGet(this.model, 'exam.examAreas', []), 'id');
      // Show S&S EIC Sign Off
      var ssAreaId = ['1', '11'];
      if (_.intersection(ssAreaId, examAreas).length) {
        this.ui.ssEICSignOff.show();
      }
      // Show IT EIC Sign Off
      var itAreaId = ['3', '12', '22'];
      if (_.intersection(itAreaId, examAreas).length) {
        this.ui.itEICSignOff.show();
      }
      // Show Trust EIC Sign Off
      var trustAreaId = ['2', '21'];
      if (_.intersection(trustAreaId, examAreas).length) {
        this.ui.trustEICSignOff.show();
      }

      this.showChildView(
        'responseGrid',
        new GridView({
          columns: [
            {
              name: 'responseDueDate',
              label: 'Response Due',
              cell: 'string',
              headerCell: 'custom',
              width: 20,
              editable: false,
              sortable: false
            },
            {
              name: 'responseReceivedDate',
              label: 'Response Received',
              cell: 'string',
              headerCell: 'custom',
              width: 20,
              editable: false,
              sortable: false
            },
            {
              name: 'acknowledgementSentDate',
              label: 'Acknowledgement Sent',
              cell: 'string',
              headerCell: 'custom',
              width: 20,
              editable: false,
              sortable: false
            },
            {
              name: 'notes',
              label: 'Notes',
              cell: ParagraphCell,
              headerCell: 'custom',
              width: 40,
              editable: false
            }
          ],
          collection: new Backbone.Collection(modelGet(this.model, 'exam.examProcessingResponses', [])),
          emptyText: 'No Processing Responses'
        })
      );

      this.ui.examYear.hide();

      var conductedBy = _.pluck(modelGet(this.model, 'exam.examConductedBy', []), 'conductedById');

      // Show PDOBS EIC
      var pdobsIds = ['1', '11', '21'];
      if (_.intersection(conductedBy, pdobsIds).length > 0) {
        this.ui.eicSections.show();
      }
      // Show Other Agency EIC
      if (_.difference(conductedBy, pdobsIds).length > 0) {
        this.ui.federalEICSections.show();
      }

      if (modelGet(this.model, 'exam.examTypeId') === '2') {
        var examAreaIdOptions = ['2', '3', '4'];
        var leadAgencyId = this.model.get('exam').leadAgencyId;

        if (_.contains(examAreaIdOptions, leadAgencyId)) {
          this.ui.firstDayLetterSent.hide();
          this.ui.eicSections.hide();
        }
      }
    }
  }
});
