var Handlebars = require("handlebars/dist/handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1;

  return container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? depth0["case"] : depth0)) != null ? stack1.fineAmountOrdered : stack1), depth0));
},"3":function(container,depth0,helpers,partials,data) {
    return "--";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1;

  return container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? depth0["case"] : depth0)) != null ? stack1.totalFineAmountPaid : stack1), depth0));
},"7":function(container,depth0,helpers,partials,data) {
    var stack1;

  return container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? depth0["case"] : depth0)) != null ? stack1.totalPaymentScheduled : stack1), depth0));
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "\n<div class=\"row\">\n  <div class=\"col-sm-6 form-horizontal form-static\">\n    <div class=\"form-group\">\n      <label class=\"control-label col-sm-6\">Payment Amount</label>\n      <div class=\"form-control-static col-sm-6\">\n        "
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0["case"] : depth0)) != null ? stack1.fineAmountOrdered : stack1),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data})) != null ? stack1 : "")
    + "\n      </div>\n    </div>\n    <div class=\"form-group\">\n      <label class=\"control-label col-sm-6\">Amount Paid</label>\n      <div class=\"form-control-static col-sm-6 payment-paid\">\n        "
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0["case"] : depth0)) != null ? stack1.totalFineAmountPaid : stack1),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.program(3, data, 0),"data":data})) != null ? stack1 : "")
    + "</div>\n    </div>\n    <div class=\"form-group\">\n      <label class=\"control-label col-sm-6\">Balance Due</label>\n      <div class=\"form-control-static col-sm-6 payment-scheduled\">\n        "
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0["case"] : depth0)) != null ? stack1.totalPaymentScheduled : stack1),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.program(3, data, 0),"data":data})) != null ? stack1 : "")
    + "</div>\n    </div>\n  </div>\n</div>\n<div class=\"row\">\n  <div class=\"col-sm-12\">\n    <div class=\"payment-grid\"></div>\n  </div>\n</div>";
},"useData":true});