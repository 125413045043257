var Handlebars = require("handlebars/dist/handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "          <option value=\""
    + alias4(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\">"
    + alias4(((helper = (helper = helpers.locationAction || (depth0 != null ? depth0.locationAction : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"locationAction","hash":{},"data":data}) : helper)))
    + "</option>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "\n<div class=\"panel-body\">\n  <div class=\"row location-info\">\n    <div class=\"col-sm-8\">\n      <div class=\"row\">\n        <div class=\"col-sm-5\">\n          <div class=\"form-group\">\n            <label class=\"control-label\">Location Type</label>\n            <div class=\"form-control-static\">"
    + container.escapeExpression(((helper = (helper = helpers.locationType || (depth0 != null ? depth0.locationType : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"locationType","hash":{},"data":data}) : helper)))
    + "</div>\n          </div>\n        </div>\n        <div class=\"col-sm-7\">\n          <div class=\"form-group\">\n            <label class=\"control-label\">Location Address</label>\n            <div class=\"current-location-address\"></div>\n          </div>\n        </div>\n      </div>\n    </div>\n    <div class=\"col-sm-4\">\n      <div class=\"form-group\">\n        <label class=\"control-label\">Select Action</label>\n        <select name=\"locationActionTypeId\" class=\"form-control action-type-dropdown\">\n          <option value=\"\" selected>--</option>\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.actionTypes : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "        </select>\n      </div>\n    </div>\n  </div>\n  <div class=\"action-form\"></div>\n</div>";
},"useData":true});