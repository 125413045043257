/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Backbone = require('backbone');

var UIUtil = require('psa-core/util/ui');
var GridView = require('psa-marionette/views/GridView');

var ConfirmationModalView = require('common/views/ConfirmationModalView');
var MultiButtonCell = require('common/backgrid/MultiButtonCell');
var ParagraphCell = require('common/backgrid/ParagraphCell');
var DollarCell = require('common/backgrid/DollarCell');
var MomentCell = require('common/backgrid/MomentCell');

var SubmitFee = require('../services/SubmitFee');

module.exports = GridView.extend({
  className: 'corp-fin-fees-section-view',

  onBeforeRender: function() {
    if (GridView.prototype.onBeforeRender) {
      GridView.prototype.onBeforeRender.apply(this.arguments);
    }

    var isReadOnly = this.options.isReadOnly;

    var self = this;
    this.options.columns = [
      {
        name: 'paidDate',
        label: 'Paid Date',
        cell: MomentCell.MomentCell,
        formatter: MomentCell.MomentDateFormatter.extend({
          emptyValue: '--'
        }),
        sortValue: MomentCell.MomentSortValue,
        headerCell: 'custom',
        width: 10,
        editable: false
      },
      {
        name: 'paidAmount',
        label: 'Fee Paid',
        cell: DollarCell,
        headerCell: 'custom',
        width: 10,
        editable: false
      },
      {
        name: 'type',
        label: 'Type',
        cell: 'string',
        headerCell: 'custom',
        width: 10,
        editable: false
      },
      {
        name: 'checkNumberDisplay',
        label: 'Check Number',
        cell: 'string',
        headerCell: 'custom',
        width: 10,
        editable: false
      },
      {
        name: 'transmittalNumberDisplay',
        label: 'Transmittal Number',
        cell: 'string',
        headerCell: 'custom',
        width: 10,
        editable: false
      },
      {
        name: 'transmittalReferenceDisplay',
        label: 'Transmittal Reference',
        cell: 'string',
        headerCell: 'custom',
        width: 10,
        editable: false
      },
      {
        name: 'sapDocNumDisplay',
        label: 'SAP Doc Number',
        cell: 'string',
        headerCell: 'custom',
        width: 10,
        editable: false
      },
      {
        name: 'transactionDescriptionDisplay',
        label: 'Transaction Description',
        cell: ParagraphCell,
        headerCell: 'custom',
        width: 20,
        editable: false,
        sortable: false
      },
      {
        name: 'editDeleteMultiButtonCell',
        label: '',
        width: 10,
        cell: MultiButtonCell.extend({
          buttons: [
            {
              name: '',
              labelClass: 'fa fa-edit',
              buttonClass: 'btn-primary btn-sm edit-fee',
              href: function(model) {
                var isRegistration = self.options.recordCategoryId === '1';
                var isExemption = self.options.recordCategoryId === '2';
                var isInvestment = self.options.recordCategoryId === '3';

                if (isRegistration) {
                  return '#corpfin/registration/' + model.get('recordId') + '/fee/' + model.get('id') + '/edit';
                } else if (isExemption) {
                  return '#corpfin/exemption/' + model.get('recordId') + '/fee/' + model.get('id') + '/edit';
                } else if (isInvestment) {
                  return '#corpfin/investment/' + model.get('recordId') + '/fee/' + model.get('id') + '/edit';
                }
              },
              renderable: function() {
                return !isReadOnly;
              }
            },
            {
              name: '',
              labelClass: 'fa fa-trash',
              buttonClass: 'btn-danger btn-sm delete-fee',
              onClick: this.onClickDeleteFee.bind(this),
              renderable: function() {
                return !isReadOnly;
              }
            }
          ]
        }),
        editable: false,
        sortable: false
      }
    ];
    this.options.emptyText = 'No Fees';
  },

  onClickDeleteFee: function(e, model) {
    e.preventDefault();
    var self = this;
    var message = 'Are you sure you want to delete this fee?';
    UIUtil.showModal({
      title: 'Confirm Deleting Fee',
      view: new ConfirmationModalView({
        confirmText: 'Delete Fee',
        model: new Backbone.Model({
          message: message,
          onConfirm: function() {
            var feeId = model.get('id');
            return SubmitFee({ feeId: feeId, isDeleted: '1' });
          },
          onConfirmDone: function() {
            self.collection.remove(model);
          }
        })
      })
    });
  }
});
