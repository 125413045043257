/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var _get = require('lodash.get');
var Backbone = require('backbone');
var Marionette = require('backbone.marionette');
var Radio = require('backbone.radio');
var Ladda = require('ladda');
var Syphon = require('backbone.syphon');
var moment = require('moment');

var NonDepEntityFormOfficerView = require('../../../entity-form/views/NonDepEntityFormOfficerView');
var FileUploadView = require('common/views/FileUploadView');
var FileLinkView = require('common/views/FileLinkView');

var makeUploadCollection = require('common/util/makeUploadCollection');
var modelGet = require('common/util/modelGet');

var FileUploadCollection = require('psa-backbone/models/FileUploadCollection');

var SubmitEntityOfficerChangeApplication = require('../../services/SubmitEntityOfficerChangeApplication');
var SubmitEntityOfficerGeneralInformation = require('../../../individual-dashboard/services/SubmitEntityOfficerGeneralInformation');

require('./EntityOfficerNewApplicationView.css');
var tmplEntityOfficerNewApplicationView = require('./EntityOfficerNewApplicationView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplEntityOfficerNewApplicationView,

  className: 'entity-officer-new-form-view',

  ui: {
    officerContainer: '.entity-form-officer-view-container',
    affirmationForm: '.affirmation-form',
    convictedForm: '.convicted-form',
    isOfficerConvictedDocumentContainer: '.is-officer-convicted-document-container',
    oldIsOfficerConvictedDocumentLink: '.old-is-officer-convicted-document-link',
    applicationDocumentsFileContainer: '.application-documents-container',
    effectiveChangeDateForm: '.effective-change-date-form',
    effectiveChangeDate: '.effective-change-date',
    submitButton: '.submit-button',
    isCertified: 'input[name="isCertified"]',
    certificationName: 'input[name="certificationName"]'
  },

  regions: {
    officerContainer: '@ui.officerContainer',
    isOfficerConvictedDocumentContainer: '@ui.isOfficerConvictedDocumentContainer',
    oldIsOfficerConvictedDocumentLink: '@ui.oldIsOfficerConvictedDocumentLink',
    applicationDocumentsFileContainer: '@ui.applicationDocumentsFileContainer'
  },

  events: {
    'click @ui.submitButton': 'onClickSubmit'
  },

  behaviors: {
    ReadOnlyFormBehavior: {},
    RadioGroupToggleBehavior: {
      radios: [
        {
          target: '.officer-convicted-document-container',
          radioGroup: 'input[name=isOfficerConvicted]',
          valueToShow: '1'
        }
      ]
    }
  },

  templateHelpers: function() {
    var amendmentChanges;
    var showChanges = this.options.isReadOnly && this.options.viewMode !== 'ext';
    if (showChanges) {
      amendmentChanges = this.getAmendmentChanges();
    }
    var showAffirmation =
      (this.options.isAmendment && !this.options.isReadOnly) ||
      (this.options.mode === 'print' || this.options.mode === 'int' || this.options.mode === 'int-expand');
    return {
      viewMode: this.options.viewMode,
      amendmentChanges: amendmentChanges,
      isReadOnly: this.options.isReadOnly,
      showAffirmation: showAffirmation
    };
  },

  onBeforeShow: function() {
    var qualifyingIndividualId = '2';
    var officerTypes;
    if (this.options.viewMode !== 'int') {
      officerTypes = _.filter(this.model.get('officerTypes'), function(type) {
        return type.id !== qualifyingIndividualId;
      });
    } else {
      officerTypes = this.model.get('officerTypes');
    }

    var amendmentChanges = {};
    var showChanges = this.options.isReadOnly && this.options.viewMode !== 'ext';
    if (showChanges) {
      amendmentChanges = this.getAmendmentChanges();
    }

    Syphon.deserialize(this, this.model.toJSON());

    if (this.options.mode === 'resubmit') {
      this.ui.isCertified.prop('checked', false);
      this.ui.certificationName.val(null);
    }

    this.entityFormOfficerView = new NonDepEntityFormOfficerView({
      officerTypes: officerTypes,
      model: new Backbone.Model(this.model.get('officer')),
      isAmendment: this.options.isAmendment,
      isReadOnly: this.options.isReadOnly,
      viewMode: this.options.viewMode
    });
    this.showChildView('officerContainer', this.entityFormOfficerView);

    this.isOfficerConvictedDocument = new FileUploadView({
      collection: makeUploadCollection(modelGet(this.model, 'isOfficerConvictedDocument')),
      isRequired: true,
      isReadOnly: this.options.isReadOnly,
      metadata: {
        documentTypeId: 10025
      }
    });
    this.showChildView('isOfficerConvictedDocumentContainer', this.isOfficerConvictedDocument);

    if (amendmentChanges.isOfficerConvictedDocument) {
      this.oldIsOfficerConvictedDocumentLink = new FileLinkView({
        model: new Backbone.Model({
          fileId: modelGet(this.model, 'oldIsOfficerConvictedDocument.fileId'),
          fileName: modelGet(this.model, 'oldIsOfficerConvictedDocument.fileName')
        })
      });
      this.showChildView('oldIsOfficerConvictedDocumentLink', this.oldIsOfficerConvictedDocumentLink);
    }

    this.applicationDocumentsFile = new FileUploadView({
      isRequired: true,
      allowMultipleFiles: true,
      collection: new FileUploadCollection(modelGet(this.model, 'officer.applicationDocuments')),
      isReadOnly: this.options.isReadOnly,
      metadata: {
        documentTypeId: 10023
      }
    });
    this.showChildView('applicationDocumentsFileContainer', this.applicationDocumentsFile);
  },

  onClickSubmit: function(event) {
    event.preventDefault();
    var self = this;
    this.validate().done(function() {
      var laddaContext = Ladda.create(self.ui.submitButton.get(0));
      laddaContext.start();

      var formData = self.getFormData();
      if (formData.isDeleted) {
        applicationData = self.removeOfficer(formData);
      } else {
        var applicationData = formData;
        applicationData.isDeleted = 0;
      }

      if (!self.options.isAmendment) {
        // For internal case management
        if (applicationData.dob) {
          applicationData.dob = moment(applicationData.dob, 'MM/DD/YYYY').format('YYYY-MM-DD');
        }
        SubmitEntityOfficerGeneralInformation(applicationData)
          .done(function() {
            Radio.channel('nd:submit-case-management-change').trigger('submit');
          })
          .fail(function() {
            laddaContext.stop();
          });
      } else {
        // For external ammendment applications
        applicationData.isNewOfficer = '1';
        SubmitEntityOfficerChangeApplication(applicationData)
          .done(function(applicationId) {
            Radio.channel('nd:submit-application').trigger('submit');
            Radio.channel('nd:submit-application').trigger('resubmit', laddaContext);
          })
          .fail(function(err) {
            laddaContext.stop();
          });
      }
    });
  },

  validate: function(config) {
    var validationPromises = [this.ui.officerContainer.parsley().whenValidate()];
    if (this.options.isAmendment && !this.options.isReadOnly) {
      validationPromises.push(this.ui.affirmationForm.parsley().whenValidate());
    }
    validationPromises.push(this.ui.convictedForm.parsley().whenValidate());

    if (this.getFormData().isOfficerConvicted === '1') {
      validationPromises.push(this.isOfficerConvictedDocument.validate(config));
    }
    return $.when.apply($, validationPromises);
  },

  getFormData: function() {
    var formData = Syphon.serialize(this);
    var rawData = this.entityFormOfficerView.getFormData();
    rawData.entityId = this.model.get('entityId');
    rawData.applicationId = this.model.get('applicationId');
    rawData.entityOfficerId = _get(this.model.get('officer'), 'entityOfficerId');
    rawData.isOfficerConvicted = formData.isOfficerConvicted;
    rawData.isCertified = formData.isCertified;
    rawData.certificationName = formData.certificationName;

    if (rawData.isOfficerConvicted === '1') {
      rawData.isOfficerConvictedDocumentId = this.isOfficerConvictedDocument.getFirstFileId();
    }
    rawData.applicationDocuments = this.applicationDocumentsFile.collection.pluck('fileId');
    return rawData;
  },

  removeOfficer: function(formData) {
    var officer = this.model.get('officer');
    return {
      entityOfficerId: officer.entityOfficerId,
      applicationId: this.model.get('applicationId'),
      entityId: this.model.get('entityId'),
      isDeleted: 1,
      effectiveChangeDate: formData.effectiveChangeDate,
      entityOfficerTypeId: officer.entityOfficerTypeId,
      associatedLicenses: []
    };
  },

  getAmendmentChanges: function() {
    // Determine which fields have changed
    return {
      isOfficerConvicted:
        this.model.get('oldIsOfficerConvicted') &&
        this.model.get('isOfficerConvicted') !== this.model.get('oldIsOfficerConvicted'),
      isOfficerConvictedDocument:
        !!modelGet(this.model, 'oldIsOfficerConvictedDocument.fileId') &&
        modelGet(this.model, 'isOfficerConvictedDocument.fileId') !==
          modelGet(this.model, 'oldIsOfficerConvictedDocument.fileId')
    };
  }
});
