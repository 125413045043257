/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

module.exports = function(data) {
  var generalDetails = data.generalDetails;
  var productionReport = data.productionReport;
  var affirmation = data.affirmation;

  return $.soap({
    body: {
      SubmitDebtManagementServicesAnnualReport: {
        $xmlns: 'http://dobs.pa.gov/nd/annualreport/bpm/1.0',
        AnnualReportID: data.annualReportId,
        ApplicationID: data.applicationId,
        ContactFullName: generalDetails.contactFullName,
        ContactPhoneNumber: generalDetails.contactPhoneNumber,
        ContactEmail: generalDetails.contactEmail,
        IsTaxExempt: generalDetails.isTaxExempt,
        IsNonProit: generalDetails.isNonProit,
        HasStatusChange: generalDetails.hasStatusChange,
        HasStatusChangeExplanation: generalDetails.hasStatusChangeExplanation,
        IsOperatingInAllStates: generalDetails.isOperatingInAllStates ? '1' : '0',
        IsCertified: affirmation.isCertified ? '1' : '0',
        CertificationName: affirmation.certificationName,
        // The operating states are stored as a JSON array
        OperatingStateCodes: (function() {
          var operatingStateCodes = generalDetails.operatingStateCodes || [];
          if (operatingStateCodes.length) {
            return JSON.stringify(operatingStateCodes);
          }
        })(),
        NumEnrolledNotAvailable: productionReport.numEnrolledNotAvailable ? '1' : '0',
        NumEnrolledNumber: productionReport.numEnrolledNumber,
        NumEnrolledDollars: productionReport.numEnrolledDollars,
        NumEnrolledExplanation: productionReport.numEnrolledExplanation,
        NumSignedUpNotAvailable: productionReport.numSignedUpNotAvailable ? '1' : '0',
        NumSignedUpNumber: productionReport.numSignedUpNumber,
        NumSignedUpDollars: productionReport.numSignedUpDollars,
        NumSignedUpExplanation: productionReport.numSignedUpExplanation,
        NumCompletedNotAvailable: productionReport.numCompletedNotAvailable ? '1' : '0',
        NumCompletedNumber: productionReport.numCompletedNumber,
        NumCompletedDollars: productionReport.numCompletedDollars,
        NumCompletedExplanation: productionReport.numCompletedExplanation,
        NumDroppedOutAfterThreeMonthsNotAvailable: productionReport.numDroppedOutAfterThreeMonthsNotAvailable
          ? '1'
          : '0',
        NumDroppedOutAfterThreeMonthsNumber: productionReport.numDroppedOutAfterThreeMonthsNumber,
        NumDroppedOutAfterThreeMonthsDollars: productionReport.numDroppedOutAfterThreeMonthsDollars,
        NumDroppedOutAfterThreeMonthsExplanation: productionReport.numDroppedOutAfterThreeMonthsExplanation,
        NumDroppedOutBeforeThreeMonthsNotAvailable: productionReport.numDroppedOutBeforeThreeMonthsNotAvailable
          ? '1'
          : '0',
        NumDroppedOutBeforeThreeMonthsNumber: productionReport.numDroppedOutBeforeThreeMonthsNumber,
        NumDroppedOutBeforeThreeMonthsDollars: productionReport.numDroppedOutBeforeThreeMonthsDollars,
        NumDroppedOutBeforeThreeMonthsExplanation: productionReport.numDroppedOutBeforeThreeMonthsExplanation,
        DelinquentMoreThanThirtyDaysNotAvailable: productionReport.delinquentMoreThanThirtyDaysNotAvailable ? '1' : '0',
        DelinquentMoreThanThirtyDaysNumber: productionReport.delinquentMoreThanThirtyDaysNumber,
        DelinquentMoreThanThirtyDaysDollars: productionReport.delinquentMoreThanThirtyDaysDollars,
        DelinquentMoreThanThirtyDaysExplanation: productionReport.delinquentMoreThanThirtyDaysExplanation,
        DelinquentLessThanThirtyDaysNotAvailable: productionReport.delinquentLessThanThirtyDaysNotAvailable ? '1' : '0',
        DelinquentLessThanThirtyDaysNumber: productionReport.delinquentLessThanThirtyDaysNumber,
        DelinquentLessThanThirtyDaysDollars: productionReport.delinquentLessThanThirtyDaysDollars,
        DelinquentLessThanThirtyDaysExplanation: productionReport.delinquentLessThanThirtyDaysExplanation,
        NumDeniedNotAvailable: productionReport.numDeniedNotAvailable ? '1' : '0',
        NumDeniedNumber: productionReport.numDeniedNumber,
        NumDeniedDollars: productionReport.numDeniedDollars,
        NumDeniedExplanation: productionReport.numDeniedExplanation,
        DollarDebtManagementNotAvailable: productionReport.dollarDebtManagementNotAvailable ? '1' : '0',
        DollarDebtManagementNumber: productionReport.dollarDebtManagementNumber,
        DollarDebtManagementDollars: productionReport.dollarDebtManagementDollars,
        DollarDebtManagementExplanation: productionReport.dollarDebtManagementExplanation,
        NumDebtManagementNotAvailable: productionReport.numDebtManagementNotAvailable ? '1' : '0',
        NumDebtManagementNumber: productionReport.numDebtManagementNumber,
        NumDebtManagementDollars: productionReport.numDebtManagementDollars,
        NumDebtManagementExplanation: productionReport.numDebtManagementExplanation,
        PaymentTransmittedNotAvailable: productionReport.paymentTransmittedNotAvailable ? '1' : '0',
        PaymentTransmittedNumber: productionReport.paymentTransmittedNumber,
        PaymentTransmittedDollars: productionReport.paymentTransmittedDollars,
        PaymentTransmittedExplanation: productionReport.paymentTransmittedExplanation,
        MaxFundsNotAvailable: productionReport.maxFundsNotAvailable ? '1' : '0',
        MaxFundsNumber: productionReport.maxFundsNumber,
        MaxFundsDollars: productionReport.maxFundsDollars,
        MaxFundsExplanation: productionReport.maxFundsExplanation,
        BondDollars: productionReport.bondDollars,
        IsSufficient: productionReport.isSufficient,
        IsSufficientExplanation: productionReport.isSufficientExplanation
      }
    }
  });
};
