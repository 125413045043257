/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */
var moment = require('moment');
module.exports = function(applicationData) {
  var deferred = $.Deferred();

  applicationData.expirationDate = moment(applicationData.expirationDate, 'MM/DD/YYYY').isValid()
    ? moment(applicationData.expirationDate, 'MM/DD/YYYY').format('YYYY-MM-DD')
    : undefined;

  applicationData.hireDate = moment(applicationData.hireDate, 'MM/DD/YYYY').isValid()
    ? moment(applicationData.hireDate, 'MM/DD/YYYY').format('YYYY-MM-DD')
    : undefined;

  applicationData.effectiveChangeDate = moment(applicationData.effectiveChangeDate, 'MM/DD/YYYY').isValid()
    ? moment(applicationData.effectiveChangeDate, 'MM/DD/YYYY').format('YYYY-MM-DD')
    : undefined;

  $.soap({
    body: {
      SubmitChangeStatusCertificationApplication: {
        $xmlns: 'http://dobs.pa.gov/nd/application/bpm/1.0',
        EntityID: applicationData.entityId,
        LicenseID: applicationData.licenseId,
        CreditCounselorID: applicationData.creditCounselorId,
        ApplicationID: applicationData.applicationId,
        Address: {
          AddressLine1: applicationData.addressLine1,
          AddressLine2: applicationData.addressLine2,
          AddressLine3: applicationData.addressLine3,
          City: applicationData.city,
          State: applicationData.state,
          County: applicationData.county,
          PostalCode: applicationData.postalCode,
          Country: applicationData.country
        },
        FirstName: applicationData.firstName,
        LastName: applicationData.lastName,
        EmployeeTitle: applicationData.title,
        EmployeeHireDate: applicationData.hireDate,
        CertifyingOrganization: applicationData.certifyingOrganization,
        StatusID: applicationData.certificationStatusId,
        CertificateNumber: applicationData.certificateNumber,
        CertificateExpirationDate: applicationData.expirationDate,
        AdditionalInformation: applicationData.additionalInformation,
        EffectiveChangeDate: applicationData.effectiveChangeDate,
        IsCertified: applicationData.isCertified ? '1' : '0',
        CertificationName: applicationData.certificationName,
        ApplicationDocuments: (function() {
          if (applicationData.applicationDocuments.length === 0) {
            return {};
          }
          return {
            DocumentID: applicationData.applicationDocuments
          };
        })()
      }
    }
  })
    .done(function() {
      deferred.resolve();
    })
    .fail(function(err) {
      deferred.reject(err);
    });

  return deferred.promise();
};
