/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Marionette = require('backbone.marionette');
var Radio = require('backbone.radio');
var Ladda = require('ladda');

var FileUploadCollection = require('psa-backbone/models/FileUploadCollection');

var modelGet = require('common/util/modelGet');
var BreadcrumbView = require('common/views/BreadcrumbView');
var FileUploadView = require('common/views/FileUploadView');

var DepAuditFormView = require('./DepAuditFormView');
var SubmitEntityAudit = require('../services/SubmitEntityAudit');

var tmplDepAuditNewView = require('../templates/DepAuditNewView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplDepAuditNewView,

  className: 'dep-new-audit-view container-fluid',

  ui: {
    breadCrumbContainer: '.breadcrumb-container',
    auditForm: '.audit-form-container',
    submitButton: '.btn-submit',
    supportingDocumentsContainer: '.supporting-documents-container'
  },

  regions: {
    breadCrumbContainer: '@ui.breadCrumbContainer',
    auditForm: '@ui.auditForm',
    supportingDocumentsContainer: '@ui.supportingDocumentsContainer'
  },

  events: {
    'click @ui.submitButton': 'onClickSubmit'
  },

  onBeforeShow: function() {
    var entityId = this.model.get('entityId');
    var entityName = modelGet(this.model, 'entityIdentifiers.entityName');

    this.showChildView(
      'breadCrumbContainer',
      new BreadcrumbView({
        breadcrumbs: [
          {
            text: 'Home',
            link: '#dep/dashboard'
          },
          {
            text: entityName,
            link: '#dep/entity/' + entityId + '/dashboard'
          },
          {
            text: 'New Audit'
          }
        ]
      })
    );

    this.auditForm = new DepAuditFormView({
      model: this.model
    });

    this.showChildView('auditForm', this.auditForm);

    this.supportingDocuments = new FileUploadView({
      isReadOnly: this.options.isReadOnly,
      allowMultipleFiles: true,
      isRequired: false,
      collection: new FileUploadCollection([]),
      metadata: {
        documentTypeId: 20001
      }
    });
    this.showChildView('supportingDocumentsContainer', this.supportingDocuments);
  },

  onClickSubmit: function(event) {
    event.preventDefault();
    var self = this;
    this.validate().done(function() {
      var laddaContext = Ladda.create(self.ui.submitButton.get(0));
      laddaContext.start();
      var formData = self.getFormData();
      formData.supportingDocuments = self.supportingDocuments.collection.map(function(file) {
        return {
          fileId: file.get('fileId'),
          documentTypeId: modelGet(file, 'metadata.documentTypeId')
        };
      });
      formData.isDeleted = 0;
      SubmitEntityAudit(formData)
        .done(function(response) {
          Radio.channel('navigate').trigger('show:int:dp:entity:audit', formData.entityId, response.newAuditId);
        })
        .fail(function(err) {
          laddaContext.stop();
        });
    });
  },

  validate: function() {
    return this.ui.auditForm.parsley().whenValidate();
  },

  getFormData: function() {
    return this.auditForm.getFormData();
  }
});
