var Handlebars = require("handlebars/dist/handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "            <label class=\"checkbox-inline\">\n              <input type=\"checkbox\" class=\"review-roles\" name=\"reviewRoles\" value=\""
    + alias4(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\" disabled>"
    + alias4(((helper = (helper = helpers.role || (depth0 != null ? depth0.role : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"role","hash":{},"data":data}) : helper)))
    + "\n            </label>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1;

  return " - "
    + container.escapeExpression((helpers["default"] || (depth0 && depth0["default"]) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (depth0 != null ? depth0.filing : depth0)) != null ? stack1.genericSubmissionDetails : stack1)) != null ? stack1.submissionType : stack1),"--",{"name":"default","hash":{},"data":data}));
},"5":function(container,depth0,helpers,partials,data) {
    return "Yes";
},"7":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = (helpers.ifCond || (depth0 && depth0.ifCond) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? depth0.filing : depth0)) != null ? stack1.isPreAcceptInfoRequest : stack1),"===","0",{"name":"ifCond","hash":{},"fn":container.program(8, data, 0),"inverse":container.program(10, data, 0),"data":data})) != null ? stack1 : "");
},"8":function(container,depth0,helpers,partials,data) {
    return "No";
},"10":function(container,depth0,helpers,partials,data) {
    return "--";
},"12":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "  <div class=\"col-sm-6 info-requested-date\">\n    <div class=\"form-group\">\n      <label class=\"control-label col-sm-4\">Info Date Requested</label>\n      <div class=\"form-control-static col-sm-8\">"
    + alias3((helpers["default"] || (depth0 && depth0["default"]) || alias2).call(alias1,((stack1 = (depth0 != null ? depth0.filing : depth0)) != null ? stack1.preAcceptInfoRequestMadeDate : stack1),"--",{"name":"default","hash":{},"data":data}))
    + "</div>\n    </div>\n  </div>\n  <div class=\"col-sm-6 info-received-date\">\n    <div class=\"form-group\">\n      <label class=\"control-label col-sm-4\">Info Date Received</label>\n      <div class=\"form-control-static col-sm-8\">"
    + alias3((helpers["default"] || (depth0 && depth0["default"]) || alias2).call(alias1,((stack1 = (depth0 != null ? depth0.filing : depth0)) != null ? stack1.preAcceptInfoRequestReceivedDate : stack1),"--",{"name":"default","hash":{},"data":data}))
    + "</div>\n    </div>\n  </div>\n";
},"14":function(container,depth0,helpers,partials,data) {
    return "Case Manager";
},"16":function(container,depth0,helpers,partials,data) {
    return "EA1";
},"18":function(container,depth0,helpers,partials,data) {
    return "OELB supervisor";
},"20":function(container,depth0,helpers,partials,data) {
    return "Program Manager";
},"22":function(container,depth0,helpers,partials,data) {
    return "Program\n        Manager";
},"24":function(container,depth0,helpers,partials,data) {
    return "col-sm-offset-6";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "\n<div class=\"form-horizontal form-static row\">\n  <div class=\"col-sm-12\">\n    <div class=\"form-group\">\n      <label class=\"control-label col-sm-2\">Selected Review Roles</label>\n      <div class=\"col-sm-9 col-md-6\">\n        <div class=\"row\">\n          <div class=\"col-sm-12\">\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.allReviewRoles : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "          </div>\n        </div>\n      </div>\n    </div>\n  </div>\n  <div class=\"col-sm-6 filing-type\">\n    <div class=\"form-group\">\n      <label class=\"control-label col-sm-4\">Type</label>\n      <div class=\"form-control-static col-sm-8\">\n        "
    + alias3((helpers["default"] || (depth0 && depth0["default"]) || alias2).call(alias1,((stack1 = (depth0 != null ? depth0.filing : depth0)) != null ? stack1.filingType : stack1),"--",{"name":"default","hash":{},"data":data}))
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.isGenericFiling : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n      </div>\n    </div>\n  </div>\n  <div class=\"col-sm-6 record-status\">\n    <div class=\"form-group\">\n      <label class=\"control-label col-sm-4\">Record Status</label>\n      <div class=\"form-control-static col-sm-8\">"
    + alias3((helpers["default"] || (depth0 && depth0["default"]) || alias2).call(alias1,((stack1 = (depth0 != null ? depth0.filing : depth0)) != null ? stack1.recordStatus : stack1),"--",{"name":"default","hash":{},"data":data}))
    + "</div>\n    </div>\n  </div>\n  <div class=\"col-sm-6 after-the-fact-filing\">\n    <div class=\"form-group\">\n      <label class=\"control-label col-sm-4\">After-the-fact Filing</label>\n      <div class=\"form-control-static col-sm-8\">"
    + alias3((helpers["default"] || (depth0 && depth0["default"]) || alias2).call(alias1,((stack1 = (depth0 != null ? depth0.filing : depth0)) != null ? stack1.isAfterTheFactFilingRequiredDisplay : stack1),"--",{"name":"default","hash":{},"data":data}))
    + "</div>\n    </div>\n  </div>\n  <div class=\"col-sm-6 received-date\">\n    <div class=\"form-group\">\n      <label class=\"control-label col-sm-4\">Received Date</label>\n      <div class=\"form-control-static col-sm-8\">"
    + alias3((helpers["default"] || (depth0 && depth0["default"]) || alias2).call(alias1,((stack1 = (depth0 != null ? depth0.filing : depth0)) != null ? stack1.receivedDate : stack1),"--",{"name":"default","hash":{},"data":data}))
    + "</div>\n    </div>\n  </div>\n  <div class=\"col-sm-6 accepted-date\">\n    <div class=\"form-group\">\n      <label class=\"control-label col-sm-4\">Accepted Date</label>\n      <div class=\"form-control-static col-sm-8\">"
    + alias3((helpers["default"] || (depth0 && depth0["default"]) || alias2).call(alias1,((stack1 = (depth0 != null ? depth0.filing : depth0)) != null ? stack1.acceptedDate : stack1),"--",{"name":"default","hash":{},"data":data}))
    + "</div>\n    </div>\n  </div>\n  <div class=\"col-sm-6\">\n    <div class=\"form-group\">\n      <label class=\"control-label col-sm-4\">Workflow Status</label>\n      <div class=\"form-control-static col-sm-8\">"
    + alias3((helpers["default"] || (depth0 && depth0["default"]) || alias2).call(alias1,((stack1 = (depth0 != null ? depth0.filing : depth0)) != null ? stack1.workflowStatus : stack1),"--",{"name":"default","hash":{},"data":data}))
    + "</div>\n    </div>\n  </div>\n  <div class=\"col-sm-6 decision-due-date\">\n    <div class=\"form-group\">\n      <label class=\"control-label col-sm-4\">Decision Due Date</label>\n      <div class=\"form-control-static col-sm-8\">"
    + alias3((helpers["default"] || (depth0 && depth0["default"]) || alias2).call(alias1,((stack1 = (depth0 != null ? depth0.filing : depth0)) != null ? stack1.decisionDueDate : stack1),"--",{"name":"default","hash":{},"data":data}))
    + "</div>\n    </div>\n  </div>\n  <div class=\"col-sm-6 acknowledgment-sent-date\">\n    <div class=\"form-group\">\n      <label class=\"control-label col-sm-4\">Acknowledgment Sent</label>\n      <div class=\"form-control-static col-sm-8\">"
    + alias3((helpers["default"] || (depth0 && depth0["default"]) || alias2).call(alias1,((stack1 = (depth0 != null ? depth0.filing : depth0)) != null ? stack1.acknowledgementSentDate : stack1),"--",{"name":"default","hash":{},"data":data}))
    + "</div>\n    </div>\n  </div>\n  <div class=\"col-sm-6 days-from-receipt\">\n    <div class=\"form-group\">\n      <label class=\"control-label col-sm-4\">Days from Receipt</label>\n      <div class=\"form-control-static col-sm-8\">"
    + alias3((helpers["default"] || (depth0 && depth0["default"]) || alias2).call(alias1,((stack1 = (depth0 != null ? depth0.filing : depth0)) != null ? stack1.daysFromReceipt : stack1),"--",{"name":"default","hash":{},"data":data}))
    + "</div>\n    </div>\n  </div>\n  <div class=\"col-sm-6 is-pre-accept-info-requested\">\n    <div class=\"form-group\">\n      <label class=\"control-label col-sm-4\">Information Request (Before Acceptance)</label>\n      <div class=\"form-control-static col-sm-8\">\n        "
    + ((stack1 = (helpers.ifCond || (depth0 && depth0.ifCond) || alias2).call(alias1,((stack1 = (depth0 != null ? depth0.filing : depth0)) != null ? stack1.isPreAcceptInfoRequest : stack1),"===","1",{"name":"ifCond","hash":{},"fn":container.program(5, data, 0),"inverse":container.program(7, data, 0),"data":data})) != null ? stack1 : "")
    + "\n      </div>\n    </div>\n  </div>\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.filing : depth0)) != null ? stack1.isPreAcceptInfoRequest : stack1),{"name":"if","hash":{},"fn":container.program(12, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "  <div class=\"col-sm-12 acceptance-sent-date\">\n    <div class=\"form-group\">\n      <label class=\"control-label col-sm-2\">Date Acceptance Sent</label>\n      <div class=\"form-control-static col-sm-10\">"
    + alias3((helpers["default"] || (depth0 && depth0["default"]) || alias2).call(alias1,((stack1 = (depth0 != null ? depth0.filing : depth0)) != null ? stack1.acceptanceSentDate : stack1),"--",{"name":"default","hash":{},"data":data}))
    + "</div>\n    </div>\n  </div>\n  <div class=\"col-sm-12 acceptance-sent-date\">\n    <div class=\"form-group\">\n      <label class=\"control-label col-sm-2\">Filer</label>\n      <div class=\"form-control-static col-sm-10\">"
    + alias3((helpers["default"] || (depth0 && depth0["default"]) || alias2).call(alias1,((stack1 = ((stack1 = (depth0 != null ? depth0.filing : depth0)) != null ? stack1.lawyer : stack1)) != null ? stack1.displayName : stack1),"--",{"name":"default","hash":{},"data":data}))
    + "</div>\n    </div>\n  </div>\n  <div class=\"col-sm-6 case-manager\">\n    <div class=\"form-group\">\n      <label class=\"control-label col-sm-4\">"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.isBank : depth0),{"name":"if","hash":{},"fn":container.program(14, data, 0),"inverse":container.program(16, data, 0),"data":data})) != null ? stack1 : "")
    + "</label>\n      <div class=\"form-control-static col-sm-8\">"
    + alias3((helpers["default"] || (depth0 && depth0["default"]) || alias2).call(alias1,((stack1 = (depth0 != null ? depth0.filing : depth0)) != null ? stack1.caseManager : stack1),"--",{"name":"default","hash":{},"data":data}))
    + "</div>\n    </div>\n  </div>\n  <div class=\"col-sm-6 case-manager-sent-date\">\n    <div class=\"form-group\">\n      <label class=\"control-label col-sm-4\">Date Sent to "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.isBank : depth0),{"name":"if","hash":{},"fn":container.program(14, data, 0),"inverse":container.program(16, data, 0),"data":data})) != null ? stack1 : "")
    + "</label>\n      <div class=\"form-control-static col-sm-8\">"
    + alias3((helpers["default"] || (depth0 && depth0["default"]) || alias2).call(alias1,((stack1 = (depth0 != null ? depth0.filing : depth0)) != null ? stack1.caseManagerSentDate : stack1),"--",{"name":"default","hash":{},"data":data}))
    + "</div>\n    </div>\n  </div>\n  <div class=\"col-sm-6 OELB Supervisor\">\n    <div class=\"form-group\">\n      <label class=\"control-label col-sm-4\">"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.isBank : depth0),{"name":"if","hash":{},"fn":container.program(18, data, 0),"inverse":container.program(20, data, 0),"data":data})) != null ? stack1 : "")
    + "</label>\n      <div class=\"form-control-static col-sm-8\">"
    + alias3((helpers["default"] || (depth0 && depth0["default"]) || alias2).call(alias1,((stack1 = (depth0 != null ? depth0.filing : depth0)) != null ? stack1.supervisor : stack1),"--",{"name":"default","hash":{},"data":data}))
    + "</div>\n    </div>\n  </div>\n  <div class=\"col-sm-6 oelb-supervisor-sent-date\">\n    <div class=\"form-group\">\n      <label class=\"control-label col-sm-4\">Date Sent to "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.isBank : depth0),{"name":"if","hash":{},"fn":container.program(18, data, 0),"inverse":container.program(22, data, 0),"data":data})) != null ? stack1 : "")
    + "</label>\n      <div class=\"form-control-static col-sm-8\">"
    + alias3((helpers["default"] || (depth0 && depth0["default"]) || alias2).call(alias1,((stack1 = (depth0 != null ? depth0.filing : depth0)) != null ? stack1.supervisorSentDate : stack1),"--",{"name":"default","hash":{},"data":data}))
    + "</div>\n    </div>\n  </div>\n  <div class=\"col-sm-6 chief-examiner\">\n    <div class=\"form-group\">\n      <label class=\"control-label col-sm-4\">Chief Examiner</label>\n      <div class=\"form-control-static col-sm-8\">"
    + alias3((helpers["default"] || (depth0 && depth0["default"]) || alias2).call(alias1,((stack1 = (depth0 != null ? depth0.filing : depth0)) != null ? stack1.chiefExaminer : stack1),"--",{"name":"default","hash":{},"data":data}))
    + "</div>\n    </div>\n  </div>\n  <div class=\"col-sm-6 chief-examiner-sent-date\">\n    <div class=\"form-group\">\n      <label class=\"control-label col-sm-4\">Date Sent to Chief Examiner</label>\n      <div class=\"form-control-static col-sm-8\">"
    + alias3((helpers["default"] || (depth0 && depth0["default"]) || alias2).call(alias1,((stack1 = (depth0 != null ? depth0.filing : depth0)) != null ? stack1.chiefExaminerSentDate : stack1),"--",{"name":"default","hash":{},"data":data}))
    + "</div>\n    </div>\n  </div>\n  <div class=\"col-sm-6 director\">\n    <div class=\"form-group\">\n      <label class=\"control-label col-sm-4\">Director</label>\n      <div class=\"form-control-static col-sm-8\">"
    + alias3((helpers["default"] || (depth0 && depth0["default"]) || alias2).call(alias1,((stack1 = (depth0 != null ? depth0.filing : depth0)) != null ? stack1.director : stack1),"--",{"name":"default","hash":{},"data":data}))
    + "</div>\n    </div>\n  </div>\n  <div class=\"col-sm-6 director-sent-date\">\n    <div class=\"form-group\">\n      <label class=\"control-label col-sm-4\">Date Sent to Director</label>\n      <div class=\"form-control-static col-sm-8\">"
    + alias3((helpers["default"] || (depth0 && depth0["default"]) || alias2).call(alias1,((stack1 = (depth0 != null ? depth0.filing : depth0)) != null ? stack1.directorSentDate : stack1),"--",{"name":"default","hash":{},"data":data}))
    + "</div>\n    </div>\n  </div>\n  <div class=\"col-sm-6 deputy-secretary\">\n    <div class=\"form-group\">\n      <label class=\"control-label col-sm-4\">Deputy Secretary</label>\n      <div class=\"form-control-static col-sm-8\">"
    + alias3((helpers["default"] || (depth0 && depth0["default"]) || alias2).call(alias1,((stack1 = (depth0 != null ? depth0.filing : depth0)) != null ? stack1.deputySecretary : stack1),"--",{"name":"default","hash":{},"data":data}))
    + "</div>\n    </div>\n  </div>\n  <div class=\"col-sm-6 legal-sent-date\">\n    <div class=\"form-group\">\n      <label class=\"control-label col-sm-4\">Date Sent to Legal</label>\n      <div class=\"form-control-static col-sm-8\">"
    + alias3((helpers["default"] || (depth0 && depth0["default"]) || alias2).call(alias1,((stack1 = (depth0 != null ? depth0.filing : depth0)) != null ? stack1.legalSentDate : stack1),"--",{"name":"default","hash":{},"data":data}))
    + "</div>\n    </div>\n  </div>\n  <div class=\"col-sm-6 "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.isBank : depth0),{"name":"if","hash":{},"fn":container.program(24, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + " deputy-secretary-sent-date\">\n    <div class=\"form-group\">\n      <label class=\"control-label col-sm-4\">Date Sent to Deputy Secretary</label>\n      <div class=\"form-control-static col-sm-8\">"
    + alias3((helpers["default"] || (depth0 && depth0["default"]) || alias2).call(alias1,((stack1 = (depth0 != null ? depth0.filing : depth0)) != null ? stack1.deputySecretarySentDate : stack1),"--",{"name":"default","hash":{},"data":data}))
    + "</div>\n    </div>\n  </div>\n  <div class=\"col-sm-6 aa1\">\n    <div class=\"form-group\">\n      <label class=\"control-label col-sm-4\">AA1</label>\n      <div class=\"form-control-static col-sm-8\">"
    + alias3((helpers["default"] || (depth0 && depth0["default"]) || alias2).call(alias1,((stack1 = (depth0 != null ? depth0.filing : depth0)) != null ? stack1.aa1 : stack1),"--",{"name":"default","hash":{},"data":data}))
    + "</div>\n    </div>\n  </div>\n  <div class=\"col-sm-6 aa1-sent-date\">\n    <div class=\"form-group\">\n      <label class=\"control-label col-sm-4\">Date Sent to AA1</label>\n      <div class=\"form-control-static col-sm-8\">"
    + alias3((helpers["default"] || (depth0 && depth0["default"]) || alias2).call(alias1,((stack1 = (depth0 != null ? depth0.filing : depth0)) != null ? stack1.aa1SentDate : stack1),"--",{"name":"default","hash":{},"data":data}))
    + "</div>\n    </div>\n  </div>\n  <div class=\"col-sm-6 review-decision-comments\">\n    <div class=\"form group\">\n      <label class=\"control-label col-sm-4\">Workflow Comments</label>\n      <div class=\"form-control-static col-sm-8\">"
    + alias3((helpers["default"] || (depth0 && depth0["default"]) || alias2).call(alias1,((stack1 = (depth0 != null ? depth0.filing : depth0)) != null ? stack1.reviewDecisionComments : stack1),"--",{"name":"default","hash":{},"data":data}))
    + "</div>\n    </div>\n  </div>\n</div>";
},"useData":true});