/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');

var cleanUpResponse = require('common/util/cleanUpResponse');
var momentUtil = require('common/util/momentUtil');

module.exports = function(filingId) {
  var deferred = $.Deferred();
  $.soap({
    body: {
      GetBankLocationActivitiesFiling: {
        $xmlns: 'http://dobs.pa.gov/dp/filing/bpm/1.0',
        FilingID: filingId
      }
    },
    blueprintArrays: [
      'GetBankLocationActivitiesFilingResponse.LocationActivityActions.DP_FilBankLocationActivityAction',
      'GetBankLocationActivitiesFilingResponse.Locations.DP_EntityLocation',
      'GetBankLocationActivitiesFilingResponse.SupportingDocuments.DP_FilingSupportingDocument',
      'GetBankLocationActivitiesFilingResponse.ActionApprovalRequiredDocuments.DP_FilBankLocationActivityActionApprovalRequiredDocument'
    ],
    blueprint: {
      locationActivityFilingId: '$..ID',
      filingId: '$..FilingID',
      entityId: '$..EntityID',
      publicationRequirements: {
        file: {
          fileId: '$..PublicationRequirementsDocID',
          fileName: '$..PublicationReqsDocName'
        },
        publicationReqsComments: '$..PublicationRequirmentsComments'
      },
      boardResolution: {
        file: {
          fileId: '$..BoardResolutionDocID',
          fileName: '$..BoardResolutionDocName'
        },
        boardResolutionComments: '$..BoardResolutionComments'
      },
      locationActivityActions: [
        '$..DP_FilBankLocationActivityAction',
        {
          locationActivityId: '$..LocationActivityID',
          entityLocationId: '$..EntityLocationID',
          locationActionTypeId: '$..LocationActionTypeID',
          locationTypeId: '$..LocationTypeID',
          transferredToLocationId: '$..TransferredToLocationID',
          isOperationTransferred: '$..IsOperationTransferred',
          isTransferredToNewLocation: '$..IsTransferredToNewLocation',
          basisForDiscontinuance: '$..BasisForDiscontinuance',
          discontinuanceDate: '$..DiscontinuanceDate',
          transferredToAddress: '$..TransferredToAddress',
          transferredToLocationType: '$..TransferredToLocationType',
          anticipatedOpeningDate: '$..AnticipatedOpeningDate',
          anticipatedClosingDate: '$..AnticipatedClosingDate',
          locationAddress: {
            locationAddressId: '$..LocationAddressID',
            addressLine1: '$..AddressLine1',
            addressLine2: '$..AddressLine2',
            addressLine3: '$..AddressLine3',
            city: '$..City',
            county: '$..County',
            country: '$..Country',
            postalCode: '$..PostalCode',
            state: '$..State'
          },
          locationContactFirstName: '$..LocationContactFirstName',
          locationContactLastName: '$..LocationContactLastName',
          locationContactMiddleInitial: '$..LocationContactMiddleInitial',
          locationContactTitle: '$..LocationContactTitle',
          contactAddress: {
            addressId: '$..LocationContactAddressID',
            addressLine1: '$..ContactAddressLine1',
            addressLine2: '$..ContactAddressLine2',
            addressLine3: '$..ContactAddressLine3',
            city: '$..ContactCity',
            county: '$..ContactCounty',
            country: '$..ContactCountry',
            postalCode: '$..ContactPostalCode',
            state: '$..ContactState'
          },
          locationContactPhone: '$..LocationContactPhone',
          locationContactEmail: '$..LocationContactEmail',
          isBranchAcquired: '$..IsBranchAcquired',
          sellingEntityName: '$..SellingEntityName',
          questionnaireComments: '$..QuestionnaireComments',
          sellingAddress: {
            addressId: '$..SellingEntityAddressID',
            addressLine1: '$..SellingAddressLine1',
            addressLine2: '$..SellingAddressLine2',
            addressLine3: '$..SellingAddressLine3',
            city: '$..SellingCity',
            county: '$..SellingCounty',
            country: '$..SellingCountry',
            postalCode: '$..SellingPostalCode',
            state: '$..SellingState'
          },
          fixedAssets: {
            file: {
              fileId: '$..FixedAssetsDocID',
              fileName: '$..FixedAssetsDocName'
            },
            fixedAssetsComments: '$..FixedAssetsComments'
          },
          questionnaire: {
            file: {
              fileId: '$..QuestionnaireDocID',
              fileName: '$..QuestionnaireDocName'
            },
            questionnaireComments: '$..QuestionnaireComments'
          },
          closingNotice: {
            file: {
              fileId: '$..NoticeCertClosingBranchDocID',
              fileName: '$..NoticeCertClosingBranchDocName'
            },
            closingNoticeComments: '$..NoticeCertClosingBranchComments'
          },
          foreignBranch: {
            file: {
              fileId: '$..ForeignBranchDocID',
              fileName: '$..ForeignBranchDocName'
            },
            foreignBranchComments: '$..ForeignBranchComments'
          },
          decisionBasis: '$..DecisionBasis',
          hasTempQuarters: '$..HasTempQuarters',
          tempFacilityDetails: '$..TempFacilityDetails',
          relatedCosts: '$..RelatedCosts',
          anticipatedRecoupDate: '$..AnticipatedRecoupDate',
          leasedPremise: '$..LeasedPremise',
          hasRelatedInterest: '$..HasRelatedInterest',
          hasInsiderInterest: '$..HasInsiderInterest',
          insiderNameAndRelationship: '$..InsiderNameAndRelationship',
          stepsTaken: '$..StepsTaken',
          transactionApprovingDocument: {
            fileId: '$..TransactionApprovingDocID',
            fileName: '$..TransactionApprovingDocName'
          },
          securityPrecautions: '$..SecurityPrecautions',
          insuranceCoverage: '$..InsuranceCoverage',
          approvalRequired: '$..ApprovalRequired',
          mobileTransactionApprovingDocument: {
            fileId: '$..MobileTransactionApprovingDocID',
            fileName: '$..MobileTransactionApprovingDocName'
          },
          lpoInstitution: '$..LpoInstitution',
          lpoAddressPhone: '$..LpoAddressPhone',
          lpoActivities: '$..LpoActivities',
          shareOffice: '$..ShareOffice',
          shareOfficeExplanation: '$..ShareOfficeExplanation',
          lpoEmployees: '$..LpoEmployees',
          employmentContracts: '$..EmploymentContracts',
          employmentContractDocument: {
            fileId: '$..EmploymentContractDocID',
            fileName: '$..EmploymentContractDocName'
          },
          newMarketCompliance: '$..NewMarketCompliance',
          leaseAgreement: '$..LeaseAgreement',
          leaseAgreementtDocument: {
            fileId: '$..LeaseAgreementDocID',
            fileName: '$..LeaseAgreementDocName'
          },
          businessPlan: '$..BusinessPlan',
          financialStatement1Document: {
            fileId: '$..FinancialStatement1DocID',
            fileName: '$..FinancialStatement1DocName'
          },
          financialStatement2Document: {
            fileId: '$..FinancialStatement2DocID',
            fileName: '$..FinancialStatement2DocName'
          },
          financialStatement3Document: {
            fileId: '$..FinancialStatement3DocID',
            fileName: '$..FinancialStatement3DocName'
          },
          legalOpinion: '$..LegalOpinion',
          lpoCondition: '$..LpoCondition',
          adoptedAuthorizingDocument: {
            fileId: '$..AdoptedAuthorizingDocID',
            fileName: '$..AdoptedAuthorizingDocName'
          },
          authorizingDocument: {
            file: {
              fileId: '$..AuthorizingDocID',
              fileName: '$..AuthorizingDocName'
            },
            authorizingDocumentComments: '$..AuthorizingDocumentComments'
          },
          hasRelatedInterestMobile: '$..HasRelatedInterestMobile',
          isFullService: '$..IsFullService',
          isLimitedService: '$..IsLimitedService',
          acqLimitedServiceExplanation: '$..AcqLimitedServiceExplanation',
          acqBasis: '$..AcqBasis',
          acqAnticipatedClosingDate: '$..AcqAnticipatedClosingDate',
          acqAnticipatedOpeningDate: '$..AcqAnticipatedOpeningDate',
          acqCosts: '$..AcqCosts',
          acqRecoup: '$..AcqRecoup',
          acqLeaseTerms: '$..AcqLeaseTerms',
          acqHasRelatedInterest: '$..AcqHasRelatedInterest',
          acqHasInsiderInterest: '$..AcqHasInsiderInterest',
          acqInsiderNameAndRelationship: '$..AcqInsiderNameAndRelationship',
          acqStepsTaken: '$..AcqStepsTaken',
          anticipatedOpeningDates: '$..AnticipatedOpeningDates',
          acqPurchaseAgreementDocument: {
            fileId: '$..AcqPurchaseAgreementDocID',
            fileName: '$..AcqPurchaseAgreementDocName'
          },
          acqStatementDocument: {
            fileId: '$..AcqStatementDocID',
            fileName: '$..AcqStatementDocName'
          },
          acqTransactionApprovingDocument: {
            fileId: '$..AcqTransactionApprovingDocID',
            fileName: '$..AcqTransactionApprovingDocName'
          }
        }
      ],
      locations: [
        '$..DP_EntityLocation',
        {
          entityLocationId: '$..EntityLocationID',
          entityId: '$..EntityID',
          statusId: '$..StatusID',
          status: '$..Status',
          locationTypeId: '$..LocationTypeID',
          locationType: '$..LocationType',
          officeTypeId: '$..OfficeTypeID',
          officeType: '$..OfficeType',
          activityId: '$..ActivityID',
          openedDate: '$..OpenedDate',
          closedDate: '$..ClosedDate',
          currentLocationAddress: {
            addressId: '$..AddressID',
            addressLine1: '$..AddressLine1',
            addressLine2: '$..AddressLine2',
            addressLine3: '$..AddressLine3',
            city: '$..City',
            county: '$..County',
            country: '$..Country',
            postalCode: '$..PostalCode',
            state: '$..State'
          },
          relocatedTo: '$..RelocatedTo',
          isDeleted: '$..IsDeleted',
          createdDate: '$..CreatedDate',
          createdBy: '$..CreatedBy',
          modifiedDate: '$..ModifiedDate',
          modifiedBy: '$..ModifiedBy',
          contactFirstName: '$..ContactFirstName',
          contactLastName: '$..ContactLastName',
          contactMiddleInitial: '$..ContactMiddleInitial',
          contactTitle: '$..ContactTitle',
          currentContactAddress: {
            addressId: '$..ContactAddressID',
            addressLine1: '$..ContactAddressLine1',
            addressLine2: '$..ContactAddressLine2',
            addressLine3: '$..ContactAddressLine3',
            city: '$..ContactCity',
            county: '$..ContactCounty',
            country: '$..ContactCountry',
            postalCode: '$..ContactPostalCode',
            state: '$..ContactState'
          },
          contactPhone: '$..ContactPhone',
          contactEmail: '$..ContactEmail'
        }
      ],
      supportingDocuments: [
        '$..DP_FilingSupportingDocument',
        {
          id: '$..ID',
          filingId: '$..FilingID',
          documentId: '$..DocumentID',
          documentName: '$..DocumentName',
          documentTypeId: '$..DocumentTypeID',
          documentType: '$..DocumentType',
          file: {
            fileId: '$.DocumentID',
            fileName: '$.DocumentName'
          }
        }
      ],
      approvalRequiredDocuments: [
        '$..DP_FilBankLocationActivityActionApprovalRequiredDocument',
        {
          id: '$..ID',
          bankLocationActivityActionId: '$..BankLocationActivityActionID',
          documentId: '$..DocumentID',
          documentName: '$..DocumentName',
          file: {
            fileId: '$.DocumentID',
            fileName: '$.DocumentName'
          }
        }
      ]
    }
  })
    .done(function(response) {
      cleanUpResponse(response);

      var emptyDefaultInboundDateConfig = {
        sourceFormat: 'YYYY-MM-DD',
        targetFormat: 'MM/DD/YYYY',
        default: ''
      };

      // Format dates, 'tranferred to location' display name, and
      // separate new locations (actions not associated with entity id)
      var newLocations = [];
      _.each(response.locationActivityActions, function(action) {
        action.anticipatedOpeningDate = momentUtil.formatStr(
          action.anticipatedOpeningDate,
          emptyDefaultInboundDateConfig
        );
        action.anticipatedClosingDate = momentUtil.formatStr(
          action.anticipatedClosingDate,
          emptyDefaultInboundDateConfig
        );
        action.anticipatedRecoupDate = momentUtil.formatStr(
          action.anticipatedRecoupDate,
          emptyDefaultInboundDateConfig
        );
        action.discontinuanceDate = momentUtil.formatStr(action.discontinuanceDate, emptyDefaultInboundDateConfig);
        var checkboxes = ['isFullService', 'isLimitedService'];
        _.each(checkboxes, function(attr) {
          action[attr] = action[attr] === '1';
        });

        if (!action.entityLocationId) {
          newLocations.push(action);
        }
        action.approvalRequiredDocuments = _.filter(response.approvalRequiredDocuments, function(doc) {
          return doc.bankLocationActivityActionId === action.locationActivityId;
        });
      });
      response.newLocations = newLocations;

      // Map actions associated with existing locations
      _.each(response.locations, function(location) {
        if (location.entityLocationId) {
          var locationAction = _.find(response.locationActivityActions, function(action) {
            return action.entityLocationId === location.entityLocationId;
          });
          if (locationAction) {
            location.locationAction = locationAction;
          }
        }
      });
      delete response.locationActivityActions;
      deferred.resolve(response);
    })
    .fail(function(err) {
      deferred.reject();
    });

  return deferred.promise();
};
