/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2020] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _get = require('lodash.get');

var Session = require('psa-core/Session');
var Config = require('../../../config/Config');

var SidebarNavView = require('common/views/SidebarNavView');

require('../styles/ConSerInternalSidebarNavView.css');
var tmplConSerInternalSidebarNavView = require('../templates/ConSerInternalSidebarNavView.hbs');

module.exports = SidebarNavView.extend({
  template: tmplConSerInternalSidebarNavView,

  templateHelpers: function() {
    // Get link to ihub folder with reports

    var iHubReportDirectory = _get(Session, 'departments.CONSUMER_SERVICES.iHubReportDirectory');
    var iHubFolderLink = Config.getIHubFolderLink(iHubReportDirectory);

    var csFolderId = _get(Session, 'departments.CONSUMER_SERVICES.csFolderId');
    var csFolderLink = Config.getOTCSSmartUIUrl(csFolderId);

    return {
      iHubFolderLink: iHubFolderLink,
      csFolderLink: csFolderLink
    };
  }
});
