/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Backbone = require('backbone');
var Marionette = require('backbone.marionette');
var Radio = require('backbone.radio');
var Ladda = require('ladda');
var Syphon = require('backbone.syphon');
var _get = require('lodash.get');

var BreadcrumbView = require('common/views/BreadcrumbView');

var EntityHeaderBarView = require('../../common/views/EntityHeaderBarView');
var NonDepEntityFormLocationView = require('../../entity-form/views/NonDepEntityFormLocationView');

var SubmitNewEntityLocation = require('../services/SubmitNewEntityLocation');

var tmplNonDepEntityChangeLocationView = require('../templates/NonDepEntityChangeLocationView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplNonDepEntityChangeLocationView,

  className: 'nondep-change-location-view container-fluid',

  ui: {
    headerBar: '.header-bar-container',
    breadcrumb: '.breadcrumb-container',
    changeLocationContainer: '.change-location-container',
    submitButton: '.submit-button',
    cancelButton: '.cancel-button'
  },

  regions: {
    headerBar: '@ui.headerBar',
    breadcrumb: '@ui.breadcrumb',
    changeLocationContainer: '@ui.changeLocationContainer'
  },

  events: {
    'click @ui.submitButton': 'onClickSubmit'
  },

  onBeforeShow: function() {
    var breadcrumbs = [
      {
        text: 'Home'
      },
      {
        text: this.model.get('entityName')
      },
      {
        text: 'Edit Location'
      }
    ];

    var viewMode = this.options.viewMode;
    if (viewMode === 'int') {
      breadcrumbs[0].link = '#nondep/dashboard';
      breadcrumbs[1].link = '#nondep/entity/' + this.model.get('entityId') + '/dashboard';
    } else {
      breadcrumbs[0].link = '#ext/nondep/dashboard';
      breadcrumbs[1].link = '#ext/nondep/entity/' + this.model.get('entityId') + '/dashboard';
    }

    this.showChildView(
      'headerBar',
      new EntityHeaderBarView({
        model: this.model
      })
    );

    this.showChildView(
      'breadcrumb',
      new BreadcrumbView({
        breadcrumbs: breadcrumbs
      })
    );

    this.entityFormLocationView = new NonDepEntityFormLocationView({
      model: new Backbone.Model(this.model.get('location'))
    });

    this.showChildView('changeLocationContainer', this.entityFormLocationView);

    if (this.options.viewMode === 'int') {
      this.ui.cancelButton.attr('href', '#nondep/entity/' + this.model.get('entityId') + '/dashboard');
    } else {
      this.ui.cancelButton.attr('href', '#ext/nondep/entity/' + this.model.get('entityId') + '/dashboard');
    }
    Syphon.deserialize(this, this.model.get('location'));
  },

  onClickSubmit: function(e) {
    e.preventDefault();

    var self = this;

    this.ui.changeLocationContainer
      .parsley()
      .whenValidate()
      .done(function() {
        var laddaContext = Ladda.create(self.ui.submitButton.get(0));
        laddaContext.start();

        var formData = self.entityFormLocationView.getFormData();

        formData.entityId = self.model.get('entityId');
        formData.locationId = _get(self.model.toJSON(), 'location.id');
        SubmitNewEntityLocation(formData)
          .done(function() {
            if (self.options.viewMode === 'int') {
              Radio.channel('navigate').trigger('show:int:nd:entity-dashboard', self.model.get('entityId'));
            } else {
              Radio.channel('navigate').trigger('show:ext:nd:entity-dashboard', self.model.get('entityId'));
            }
          })
          .fail(function() {
            laddaContext.stop();
          });
      });
  }
});
