/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var _get = require('lodash.get');
var moment = require('moment');

var cleanUpResponse = require('common/util/cleanUpResponse');

module.exports = function() {
  var deferred = $.Deferred();

  $.soap({
    body: {
      GetAllInternalTasks: {
        $xmlns: 'http://dobs.pa.gov/sc/task/bpm/1.0'
      }
    },
    blueprintArrays: [
      'GetAllInternalTasksResponse.Tasks.Task',
      'GetAllInternalTasksResponse.Tasks.Task.BusinessAttributes.Attribute',
      'GetAllInternalTasksResponse.TaskTypes.CM_LU_TaskType',
      'GetAllInternalTasksResponse.Organizations.SC_Organization',
      'GetAllInternalTasksResponse.Applications.SC_Application',
      'GetAllInternalTasksResponse.Exams.SC_Exam',
      'GetAllInternalTasksResponse.Correspondences.SC_Correspondence',
      'GetAllInternalTasksResponse.FinancialStatements.SC_FinancialStatement'
    ],
    blueprint: {
      tasks: [
        '$..Task',
        {
          taskId: '$..TaskId',
          deliveryDate: '$..DeliveryDate',
          businessAttributes: [
            '$..Attribute',
            {
              name: '$.$name',
              value: '$..__text'
            }
          ],
          assignee: '$..Assignee.$displayName'
        }
      ],
      taskTypes: [
        '$..CM_LU_TaskType',
        {
          id: '$.ID',
          name: '$.Name',
          description: '$.Description'
        }
      ],
      organizations: [
        '$..SC_Organization',
        {
          id: '$..ID',
          applicantLegalName: '$..ApplicantLegalName',
          crdNumber: '$..CRDNumber'
        }
      ],
      applications: [
        '$..SC_Application',
        {
          id: '$..ID',
          organizationId: '$..OrganizationID',
          applicantLegalName: '$..ApplicantLegalName',
          crdNumber: '$..CRDNumber'
        }
      ],
      exams: [
        '$..SC_Exam',
        {
          id: '$..ID',
          applicantLegalName: '$..ApplicantLegalName',
          organizationId: '$..OrganizationID',
          crdNumber: '$..CRDNumber',
          primaryExaminer: '$..PrimaryExaminer'
        }
      ],
      correspondences: [
        '$..SC_Correspondence',
        {
          id: '$..ID',
          applicantLegalName: '$..ApplicantLegalName',
          crdNumber: '$..CRDNumber',
          organizationId: '$..OrganizationID'
        }
      ],
      financialStatements: [
        '$..SC_FinancialStatement',
        {
          id: '$..ID',
          applicantLegalName: '$..ApplicantLegalName',
          crdNumber: '$..CRDNumber',
          organizationId: '$..OrganizationID'
        }
      ]
    }
  })
    .done(function(response) {
      cleanUpResponse(response);

      _.each(response.tasks, function(task) {
        task.deliveryDate = moment.utc(task.deliveryDate, moment.ISO_8601).local();

        task.assignee = task.assignee || '--';

        _.each(task.businessAttributes, function(attr) {
          var attrMap = {
            TaskTypeName: 'taskTypeName',
            SCOrganizationID: 'organizationId',
            SCApplicationID: 'applicationId',
            SCExamID: 'examId',
            SCCorrespondenceID: 'correspondenceId',
            SCFinancialStatementID: 'financialStatementId'
          };

          if (attrMap[attr.name]) {
            task[attrMap[attr.name]] = attr.value;
          }
        });

        delete task.businessAttributes;

        var taskType = _.findWhere(response.taskTypes, { name: task.taskTypeName });
        task.type = _get(taskType, 'description', '--');

        if (task.organizationId) {
          var organization = _.findWhere(response.organizations, { id: task.organizationId });
          task.applicantLegalName = _get(organization, 'applicantLegalName', '--');
          task.primaryExaminer = '--';
        } else if (task.applicationId) {
          var application = _.findWhere(response.applications, { id: task.applicationId });
          task.applicantLegalName = _get(application, 'applicantLegalName', '--');
          task.primaryExaminer = '--';
          task.organizationId = _get(application, 'organizationId');
        } else if (task.examId) {
          var exam = _.findWhere(response.exams, { id: task.examId });
          task.applicantLegalName = _get(exam, 'applicantLegalName', '--');
          task.primaryExaminer = _get(exam, 'primaryExaminer', '--');
          task.organizationId = _get(exam, 'organizationId');
        } else if (task.correspondenceId) {
          var correspondence = _.findWhere(response.correspondences, { id: task.correspondenceId });
          task.applicantLegalName = _get(correspondence, 'applicantLegalName', '--');
          task.primaryExaminer = '--';
          task.organizationId = _get(correspondence, 'organizationId');
        } else if (task.financialStatementId) {
          var financialStatement = _.findWhere(response.financialStatements, { id: task.financialStatementId });
          task.applicantLegalName = _get(financialStatement, 'applicantLegalName', '--');
          task.primaryExaminer = '--';
          task.organizationId = _get(financialStatement, 'organizationId');
        } else {
          task.primaryExaminer = '--';
        }
      });
      deferred.resolve(response.tasks);
    })
    .fail(function(err) {
      deferred.reject();
    });

  return deferred.promise();
};
