var Handlebars = require("handlebars/dist/handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression;

  return "\n<div class=\"page-title clearfix\">\n  <h1 class=\"pull-left m-n p-n\">"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.individual : depth0)) != null ? stack1.individualType : stack1), depth0))
    + " - "
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.individual : depth0)) != null ? stack1.firstName : stack1), depth0))
    + " "
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.individual : depth0)) != null ? stack1.lastName : stack1), depth0))
    + "</h1>\n  <div class=\"labels-container\">\n    <div class=\"nmls-label-container\"></div>\n  </div>\n  <div class=\"page-title-buttons-container\">\n    <div class=\"cs-link-container\"></div>\n    <div class=\"dropdown-container\"></div>\n  </div>\n</div>\n<div class=\"breadcrumb-container\"></div>\n<div class=\"individual-wrapper\"></div>";
},"useData":true});