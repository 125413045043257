/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Backbone = require('backbone');
var Marionette = require('backbone.marionette');
var Radio = require('backbone.radio');

var UIUtil = require('psa-core/util/ui');

var SideNavSectionView = require('common/views/SideNavSectionView');
var BreadcrumbView = require('common/views/BreadcrumbView');
var DropdownView = require('common/views/DropdownView');
var ConfirmationModalView = require('common/views/ConfirmationModalView');
var ContentServerLinkView = require('common/views/ContentServerLinkView');

var SubmitSEG = require('../services/SubmitSEG');
var DepSEGDetailsSectionView = require('./DepSEGDetailsSectionView');

var tmplDepSEGDashboardView = require('../templates/DepSEGDashboardView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplDepSEGDashboardView,

  className: 'dep-seg-dashboard-view container-fluid',

  ui: {
    segWrapper: '.seg-wrapper',
    csLinkContainer: '.cs-link-container',
    breadcrumb: '.breadcrumb-container',
    segActionDropDown: '.dropdown-container'
  },

  regions: {
    segWrapper: '@ui.segWrapper',
    csLinkContainer: '@ui.csLinkContainer',
    breadcrumb: '@ui.breadcrumb',
    segActionDropDown: '@ui.segActionDropDown'
  },

  onBeforeShow: function() {
    var seg = this.model.get('seg') || {};
    var segId = this.model.get('segId');
    var entityName = this.model.get('entityIdentifiers').entityName;
    var entityId = this.model.get('entityId');
    var segCSFolderId = this.model.get('segCSFolderId');
    var csFolderId = seg.csFolderId;

    var dropDownOptions = [
      {
        text: 'Edit SEG Details',
        link: '#dep/entity/' + entityId + '/seg/' + segId + '/edit'
      },
      {
        text: 'Delete SEG',
        onClick: this.onClickDelete.bind(this)
      },
      {
        isDivider: true
      },
      {
        text: 'Add SEG Location',
        link: '#dep/entity/' + entityId + '/seg/' + segId + '/new-location'
      }
    ];

    this.showChildView(
      'segActionDropDown',
      new DropdownView({
        rightAlign: true,
        model: new Backbone.Model({
          name: 'SEG Actions',
          options: dropDownOptions
        })
      })
    );

    this.showChildView(
      'csLinkContainer',
      new ContentServerLinkView({
        model: new Backbone.Model({
          buttonText: 'Open SEG Folder',
          contentServerId: csFolderId ? csFolderId : segCSFolderId
        })
      })
    );

    this.seg = new SideNavSectionView({
      useNavigationButtons: false,
      collection: new Backbone.Collection([
        {
          id: 'segDetails',
          title: 'SEG Details',
          view: new DepSEGDetailsSectionView({
            model: new Backbone.Model(seg)
          })
        },
        {
          id: 'locations',
          title: 'Locations',
          view: Radio.channel('dp').request('build:entity-seg-location-view', segId)
        },
        {
          id: 'notes',
          title: 'Notes',
          view: Radio.channel('dp').request('build:notes-view', {
            objectId: segId,
            objectTypeId: 'SEG'
          })
        },
        {
          id: 'auditLog',
          title: 'Audit Log',
          view: Radio.channel('dp').request('build:audit-log-view', {
            objectId: segId,
            objectTypeId: 'SEG'
          })
        }
      ])
    });
    this.showChildView('segWrapper', this.seg);

    this.showChildView(
      'breadcrumb',
      new BreadcrumbView({
        breadcrumbs: [
          {
            link: '#dep/dashboard',
            text: 'Home'
          },
          {
            link: '#dep/entity/' + entityId + '/dashboard',
            text: entityName
          },
          {
            text: seg.name
          }
        ]
      })
    );
  },

  onClickDelete: function() {
    var self = this;
    var message = 'Are you sure you want to delete this SEG?';
    UIUtil.showModal({
      title: 'Confirm Deleting SEG',
      view: new ConfirmationModalView({
        confirmText: 'Delete SEG',
        model: new Backbone.Model({
          message: message,
          onConfirm: function() {
            var segId = self.model.get('segId');
            return SubmitSEG({ segId: segId, isDeleted: '1' });
          },
          onConfirmDone: function() {
            var entityId = self.model.get('entityId');
            Radio.channel('navigate').trigger('show:int:dp:entity', entityId);
          }
        })
      })
    });
  }
});
