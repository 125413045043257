/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var cleanUpResponse = require('common/util/cleanUpResponse');
var momentUtil = require('common/util/momentUtil');

module.exports = function(filingId) {
  var deferred = $.Deferred();
  $.soap({
    body: {
      GetNewBankCharterFiling: {
        $xmlns: 'http://dobs.pa.gov/dp/filing/bpm/1.0',
        FilingID: filingId
      }
    },
    blueprintArrays: ['GetNewBankCharterFilingResponse.SupportingDocuments.DP_FilingSupportingDocument'],
    blueprint: {
      id: '$..ID',
      filingId: '$..FilingID',
      proposedName: '$..ProposedName',
      bankCharterTypeId: '$..CharterTypeID',
      isFedReserveMember: '$..IsFedReserveMember',
      fedReserveBankId: '$..FedReserveBankID',
      fedReserveFilingDate: '$..FedReserveFilingDate',
      isBankHolding: '$..IsBankHolding',
      bankHoldingBankId: '$..BankHoldingBankID',
      bankHoldingFilingDate: '$..BankHoldingFilingDate',
      publicationRequirements: {
        id: '$..ID',
        file: {
          fileId: '$..PublicationReqsDocID',
          fileName: '$..PublicationReqsDocName'
        },
        publicationReqsComments: '$..PublicationReqsComments'
      },
      federalApplications: {
        id: '$..ID',
        file: {
          fileId: '$..FederalApplicationDocID',
          fileName: '$..FederalApplicationDocName'
        },
        federalApplicationsComments: '$..FederalApplicationComments'
      },
      biographicalFinancialReports: {
        id: '$..ID',
        file: {
          fileId: '$..BioFinReportsDocID',
          fileName: '$..BioFinReportsDocName'
        },
        bioFinReportsComments: '$..BioFinReportsComments'
      },
      articlesOfIncorporation: {
        id: '$..ID',
        file: {
          fileId: '$..ArticlesOfIncorporationDocID',
          fileName: '$..ArticlesOfIncorporationDocName'
        },
        articlesOfIncorporationComments: '$..ArticlesOfIncorporationComments'
      },
      feesAndExpenses: {
        id: '$..ID',
        file: {
          fileId: '$..FeesExpensesDocID',
          fileName: '$..FeesExpensesDocName'
        },
        feesExpensesComments: '$..FeesExpensesComments'
      },
      affidavit1003: {
        id: '$..ID',
        file: {
          fileId: '$..Affidavit1003DocID',
          fileName: '$..Affidavit1003DocName'
        },
        affidavit1003Comments: '$..Affidavit1003Comments'
      },
      questionnaire: {
        id: '$..ID',
        file: {
          fileId: '$..QuestionnaireDocID',
          fileName: '$..QuestionnaireDocName'
        },
        questionnaireComments: '$..QuestionnaireComments',
        depositInsuranceUploaded: '$..DepositInsuranceUploaded'
      },
      bylaws: {
        id: '$..ID',
        file: {
          fileId: '$..BylawsDocID',
          fileName: '$..BylawsDocName'
        },
        bylawsComments: '$..BylawsComments'
      },
      supportingDocuments: [
        '$..DP_FilingSupportingDocument',
        {
          id: '$..ID',
          filingId: '$..FilingID',
          documentId: '$..DocumentID',
          documentName: '$..DocumentName',
          documentTypeId: '$..DocumentTypeID',
          documentType: '$..DocumentType',
          file: {
            fileId: '$..DocumentID',
            fileName: '$..DocumentName'
          }
        }
      ]
    }
  })
    .done(function(response) {
      cleanUpResponse(response);

      var formInboundDateConfig = {
        sourceFormat: 'YYYY-MM-DD',
        targetFormat: 'MM/DD/YYYY',
        default: undefined
      };
      var depositUpload = response.questionnaire.depositInsuranceUploaded === '1';
      response.questionnaire.depositInsuranceUploaded = depositUpload ? true : false;
      response.fedReserveFilingDate = momentUtil.formatStr(response.fedReserveFilingDate, formInboundDateConfig);
      response.bankHoldingFilingDate = momentUtil.formatStr(response.bankHoldingFilingDate, formInboundDateConfig);

      deferred.resolve(response);
    })
    .fail(function(err) {
      deferred.reject();
    });

  return deferred.promise();
};
