/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Backbone = require('backbone');
var Marionette = require('backbone.marionette');
var Radio = require('backbone.radio');
var Syphon = require('backbone.syphon');
var Ladda = require('ladda');

var GridView = require('psa-marionette/views/GridView');
var PageableCollection = require('psa-backbone/models/PageableCollection');

var modelGet = require('common/util/modelGet');
var BreadcrumbView = require('common/views/BreadcrumbView');
var MomentCell = require('common/backgrid/MomentCell');
var momentUtil = require('common/util/momentUtil');

var LicenseHeaderBarView = require('../../common/views/LicenseHeaderBarView');
var SubmitStartNewLicenseRenewal = require('../services/SubmitStartNewLicenseRenewal');

var tmplNonDepLicenseInitiateRenewalView = require('../templates/NonDepLicenseInitiateRenewalView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplNonDepLicenseInitiateRenewalView,

  className: 'non-dep-license-initiate-renewal-view container-fluid',

  ui: {
    headerBar: '.header-bar-container',
    breadcrumb: '.breadcrumb-container',
    activeAppliations: '.active-applications',
    initiateRenewalForm: '.initiate-renewal-form',
    effectiveStartDate: '.effective-start-date',
    effectiveEndDate: '.effective-end-date',
    submit: '.btn-submit'
  },

  regions: {
    headerBar: '@ui.headerBar',
    breadcrumb: '@ui.breadcrumb',
    activeAppliations: '@ui.activeAppliations'
  },

  events: {
    'click @ui.submit': 'onClickSubmit'
  },

  onBeforeShow: function() {
    var entityId = this.model.get('entityId'),
      entityName = modelGet(this.model, 'header.entityName'),
      licenseId = this.model.get('licenseId'),
      licenseNumber = modelGet(this.model, 'header.licenseNumber'),
      licenseType = modelGet(this.model, 'header.licenseType');

    this.showChildView(
      'headerBar',
      new LicenseHeaderBarView({
        model: new Backbone.Model(this.model.get('header'))
      })
    );

    this.showChildView(
      'breadcrumb',
      new BreadcrumbView({
        breadcrumbs: [
          {
            link: '#nondep/dashboard',
            text: 'Home'
          },
          {
            link: '#nondep/entity/' + entityId + '/dashboard',
            text: entityName
          },
          {
            link: '#nondep/entity/' + entityId + '/license/' + licenseId,
            text: 'License #' + licenseNumber + ' - ' + licenseType
          },
          {
            text: 'Initiate License Renewal'
          }
        ]
      })
    );

    this.showChildView(
      'activeAppliations',
      new GridView({
        columns: [
          {
            name: 'applicationNumber',
            label: 'Application Number',
            cell: 'string',
            editable: false,
            headerCell: 'custom',
            width: 20
          },
          {
            name: 'applicationType',
            label: 'Application Type',
            cell: 'string',
            editable: false,
            headerCell: 'custom',
            width: 40
          },
          {
            name: 'applicationStatus',
            label: 'Application Status',
            cell: 'string',
            editable: false,
            headerCell: 'custom',
            width: 20
          },
          {
            name: 'submissionDate',
            label: 'Date Submitted',
            cell: MomentCell.MomentCell,
            formatter: MomentCell.MomentDateFormatter.extend({
              emptyValue: '--'
            }),
            sortValue: MomentCell.MomentSortValue,
            editable: false,
            headerCell: 'custom',
            width: 20
          }
        ],
        emptyText: 'No Active License Applications.',
        collection: new PageableCollection(this.model.get('activeApplications'), { pageSize: 20 })
      })
    );
    this.ui.effectiveStartDate.datepicker();
    this.ui.effectiveEndDate.datepicker();
  },

  onClickSubmit: function(e) {
    e.preventDefault();
    var entityId = this.model.get('entityId');
    var licenseId = this.model.get('licenseId');
    var self = this;
    this.validate().done(function() {
      var l = Ladda.create(e.currentTarget);
      l.start();
      SubmitStartNewLicenseRenewal(self.getFormData())
        .done(function() {
          Radio.channel('navigate').trigger('show:int:nd:license-dashboard', entityId, licenseId);
        })
        .fail(function() {
          l.stop();
        });
    });
  },

  getFormData: function(e) {
    var formData = Syphon.serialize(this);
    var licenseId = this.model.get('licenseId');

    formData.effectiveStartDate = momentUtil.formatStr(
      formData.effectiveStartDate,
      momentUtil.defaultOutboundDateConfig
    );
    formData.effectiveEndDate = momentUtil.formatStr(formData.effectiveEndDate, momentUtil.defaultOutboundDateConfig);
    formData.licenseId = licenseId;
    return formData;
  },

  validate: function() {
    return this.ui.initiateRenewalForm.parsley().whenValidate();
  }
});
