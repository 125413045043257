/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var moment = require('moment');

module.exports = function(data) {
  return $.soap({
    body: {
      SubmitExaminerRecordExamResultsTask: {
        $xmlns: 'http://dobs.pa.gov/nd/task/bpm/1.0',
        TaskID: data.taskId,
        ExamID: data.examId,
        ExamPlacementID: data.examPlacementId,
        IsRepeatViolation: data.isRepeatViolation,
        BackgroundInfo: data.backgroundInfo,
        ExamStartDate: moment(data.examStartDate, 'MM/DD/YYYY').format('YYYY-MM-DD'),
        ExamEndDate: moment(data.examEndDate, 'MM/DD/YYYY').format('YYYY-MM-DD'),
        Rating: data.rating,
        ExamDocumentID: data.examDocumentId,
        Violations: (function() {
          if (!data.violations || !data.violations.length) {
            return;
          }
          return {
            Violation: _.map(data.violations, function(violation) {
              return {
                ExamViolationCodeID: violation.examViolationCodeId,
                ExamViolationCorrectiveActionID: violation.examViolationCorrectiveActionId,
                Amount: violation.refundAmount
              };
            })
          };
        })(),
        ComplianceViolations: (function() {
          if (!data.complianceViolationIds || !data.complianceViolationIds.length) {
            return;
          }
          return {
            ComplianceViolationID: data.complianceViolationIds
          };
        })(),
        SupportingDocuments: (function() {
          if (!data.supportingDocuments || !data.supportingDocuments.length) {
            return;
          }
          return {
            SupportingDocument: _.map(data.supportingDocuments, function(supportingDocument) {
              return {
                DocumentID: supportingDocument.fileId,
                DocumentTypeID: supportingDocument.documentTypeId
              };
            })
          };
        })(),
        RetainedDocuments: (function() {
          if (!data.retainedResponseDocumentIds || !data.retainedResponseDocumentIds.length) {
            return;
          }
          return {
            ExamQuestionResponseDocumentID: data.retainedResponseDocumentIds
          };
        })()
      }
    }
  });
};
