/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2020] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Backbone = require('backbone');
var Marionette = require('backbone.marionette');

var GridView = require('psa-marionette/views/GridView');
var UIUtil = require('psa-core/util/ui');
var PageableCollection = require('psa-backbone/models/PageableCollection');

var MultiButtonCell = require('common/backgrid/MultiButtonCell');
var ButtonCell = require('common/backgrid/ButtonCell');
var MomentCell = require('common/backgrid/MomentCell');
var ConfirmationModalView = require('common/views/ConfirmationModalView');

var SecInvInqRelatedCaseAddModalView = require('./SecInvInqRelatedCaseAddModalView');
var SubmitInvInqRelatedCase = require('../services/SubmitInvInqRelatedCase');

var tmplSecInvInqSectionRelatedCaseView = require('../templates/SecInvInqSectionRelatedCaseView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplSecInvInqSectionRelatedCaseView,

  className: 'sec-inv-inq-section-related-case-view',

  ui: {
    examInvInqGrid: '.exam-inv-inq-grid',
    otherGrid: '.other-grid',
    addRelatedCase: '.add-related-case',
    addOtherRelatedCase: '.add-other-related-case'
  },

  regions: {
    examInvInqGrid: '@ui.examInvInqGrid',
    otherGrid: '@ui.otherGrid'
  },

  events: {
    'click @ui.addRelatedCase': 'onClickAddRelatedCase'
  },

  onBeforeShow: function() {
    var relatedCases = this.model.get('relatedCases');
    var otherSubjects = this.model.get('otherSubjects');

    this.relatedCaseGrid = new GridView({
      emptyText: 'No Exam/Inquiry/Investigation Related Cases',
      collection: new PageableCollection(relatedCases, { pageSize: 10 }),
      columns: [
        {
          name: 'relatedType',
          label: 'Type',
          cell: 'string',
          headerCell: 'custom',
          width: 15,
          editable: false
        },
        {
          name: 'caseNumber',
          label: 'Case Number',
          cell: 'string',
          headerCell: 'custom',
          width: 15,
          editable: false
        },
        {
          name: 'status',
          label: 'Status',
          cell: 'string',
          headerCell: 'custom',
          width: 14,
          editable: false
        },
        {
          name: 'openedDate',
          label: 'Date Opened',
          cell: MomentCell.MomentCell,
          formatter: MomentCell.MomentDateFormatter.extend({
            emptyValue: '--'
          }),
          sortValue: MomentCell.MomentSortValue,
          headerCell: 'custom',
          width: 14,
          editable: false
        },
        {
          name: 'upgradedDate',
          label: 'Date Upgraded',
          cell: MomentCell.MomentCell,
          formatter: MomentCell.MomentDateFormatter.extend({
            emptyValue: '--'
          }),
          sortValue: MomentCell.MomentSortValue,
          headerCell: 'custom',
          width: 14,
          editable: false
        },
        {
          name: 'closedDate',
          label: 'Date Closed',
          cell: MomentCell.MomentCell,
          formatter: MomentCell.MomentDateFormatter.extend({
            emptyValue: '--'
          }),
          sortValue: MomentCell.MomentSortValue,
          headerCell: 'custom',
          width: 14,
          editable: false
        },
        {
          name: 'editDeleteMultiButtonCell',
          label: '',
          cell: MultiButtonCell.extend({
            buttons: [
              {
                name: '',
                labelClass: 'fa fa-external-link',
                buttonClass: 'btn-primary btn-sm',
                href: function(model) {
                  return '#sec/' + model.get('objectType') + '/' + model.get('objectId') + '/dashboard';
                }
              },
              {
                name: '',
                labelClass: 'fa fa-trash',
                buttonClass: 'btn-danger btn-sm delete-processing-response',
                onClick: this.onClickDelete.bind(this)
              }
            ]
          }),
          editable: false,
          sortable: false,
          width: 14
        }
      ]
    });
    this.showChildView('examInvInqGrid', this.relatedCaseGrid);

    this.otherGrid = new GridView({
      columns: [
        {
          name: 'otherCaseInfo',
          label: 'Case Info',
          cell: 'string',
          headerCell: 'custom',
          width: 90,
          editable: false
        },
        {
          name: '',
          label: '',
          cell: ButtonCell.extend({
            name: '',
            onClick: this.onClickDelete.bind(this),
            buttonAttrs: {
              'data-color': '#EEEEEE',
              'data-spinner-color': '#EEEEEE'
            },
            buttonClass: 'btn-danger btn-sm ladda-button',
            labelClass: 'ladda-label fa fa-trash'
          }),
          headerCell: 'custom',
          width: 10,
          editable: false,
          sortable: false
        }
      ],
      emptyText: 'No Other Cases',
      collection: new PageableCollection(otherSubjects, { pageSize: 10 })
    });

    this.showChildView('otherGrid', this.otherGrid);
  },

  onClickDelete: function(e, model) {
    e.preventDefault();

    UIUtil.showModal({
      title: 'Remove Related Case',
      view: new ConfirmationModalView({
        model: new Backbone.Model({
          message: 'Are you sure you want to remove this related case?',
          onConfirm: function() {
            return SubmitInvInqRelatedCase({
              relatedCaseId: model.get('id'),
              isDeleted: '1'
            });
          },
          onConfirmDone: function() {
            if (model.get('relatedTypeId') === '4') {
              this.otherGrid.collection.remove(model);
            } else {
              this.relatedCaseGrid.collection.remove(model);
            }
          }.bind(this)
        })
      })
    });
  },

  onClickAddRelatedCase: function(e) {
    e.preventDefault();
    var self = this;
    UIUtil.showModal({
      title: 'Search for a Related Case',
      dialogClasses: 'modal-dialog-add-related-case-modal-view modal-lg',
      view: new SecInvInqRelatedCaseAddModalView({
        searchLimit: 50,
        model: new Backbone.Model({
          invInqId: self.model.get('invInqId'),
          invInqRelatedCaseTypes: self.model.get('invInqRelatedCaseTypes')
        })
      })
    }).done(function(relatedCase) {
      if (relatedCase) {
        if (relatedCase.relatedTypeId === '4') {
          self.otherGrid.collection.push(relatedCase);
        } else {
          self.relatedCaseGrid.collection.push(relatedCase);
        }
      }
    });
  }
});
