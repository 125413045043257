/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var cleanUpResponse = require('common/util/cleanUpResponse');
var _ = require('underscore');
var momentUtil = require('common/util/momentUtil');

module.exports = function(entityRiskMatrixId) {
  var defer = $.Deferred();

  $.soap({
    body: {
      GetEntityRiskMatrix: {
        $xmlns: 'http://dobs.pa.gov/dp/tracking/bpm/1.0',
        ID: entityRiskMatrixId
      }
    },
    blueprint: {
      id: '$..ID.__text',
      entityTrackingId: '$..EntityTrackingID.__text',
      riskTypeId: '$..RiskTypeID.__text',
      riskType: '$..RiskType.__text',
      asOfDate: '$..AsOfDate.__text',
      comments: '$..Comments.__text',
      examStartDate: '$..ExamStartDate.__text',
      riskAssessmentId: '$..RiskAssessmentID.__text',
      riskAssessment: '$..RiskAssessment.__text',
      riskDirectionId: '$..RiskDirectionID.__text',
      riskDirection: '$..RiskDirection.__text',
      dateEntered: '$..DateEntered.__text'
    }
  })
    .done(function(response) {
      cleanUpResponse(response);

      var formatCfg = _.extend({}, momentUtil.defaultInboundDateConfig, { default: undefined });
      response.asOfDate = momentUtil.formatStr(response.asOfDate, formatCfg);
      response.examStartDate = momentUtil.formatStr(response.examStartDate, formatCfg);
      response.dateEntered = momentUtil.formatStr(response.dateEntered, formatCfg);

      defer.resolve(response);
    })
    .fail(function(err) {
      defer.reject(err);
    });

  return defer.promise();
};
