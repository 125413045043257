/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var moment = require('moment');

var cleanUpResponse = require('common/util/cleanUpResponse');

module.exports = function(applicationId) {
  var deferred = $.Deferred();

  $.soap({
    body: {
      GetLicenseSurrenderApplication: {
        $xmlns: 'http://dobs.pa.gov/nd/application/bpm/1.0',
        ApplicationID: applicationId
      }
    },
    blueprintArrays: [
      'GetLicenseSurrenderApplicationResponse.Attachments.ND_AppEntityLicenseSurrenderDocument',
      'GetLicenseSurrenderApplicationResponse.Licenses.ND_AppEntityLicenseSurrenderLicense'
    ],
    blueprint: {
      applicationId: '$..ApplicationID',
      entityId: '$..EntityID.__text',
      licenseId: '$..LicenseID.__text',
      surrenderDate: '$..EffectiveDate',
      reason: '$..Reason',
      certificationName: '$..CertificationName',
      isCertified: '$..IsCertified',
      attachments: [
        '$..ND_AppEntityLicenseSurrenderDocument',
        {
          documentId: '$.ID',
          file: {
            fileId: '$.DocumentID',
            fileName: '$.Name'
          },
          documentTypeId: '$.DocumentTypeID',
          documentType: '$..DocumentType'
        }
      ],
      licensesToSurrender: [
        '$..ND_AppEntityLicenseSurrenderLicense',
        {
          licenseId: '$.LicenseID'
        }
      ]
    }
  })
    .done(function(response) {
      cleanUpResponse(response);
      response.surrenderDate = moment(response.surrenderDate, 'YYYY-MM-DD').format('MM/DD/YYYY');
      response.isCertified = response.isCertified === '1';
      deferred.resolve(response);
    })
    .fail(function(err) {
      deferred.reject();
    });

  return deferred.promise();
};
