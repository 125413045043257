/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Marionette = require('backbone.marionette');

var HeaderBarBodyView = require('./HeaderBarBodyView');

require('./HeaderBarView.css');
var HeaderBar = require('./HeaderBarView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: HeaderBar,

  className: 'header-bar-view clearfix',

  ui: {
    headerBarTitle: '.header-bar-title-container',
    headerBarBody: '.header-bar-body-container'
  },

  regions: {
    headerBarBody: '@ui.headerBarBody'
  },

  onBeforeShow: function() {
    if (!this.model.get('title')) {
      this.ui.headerBarTitle.hide();
    }
    this.showChildView(
      'headerBarBody',
      new HeaderBarBodyView({
        model: this.model,
        collection: this.options.columns
      })
    );
  }
});
