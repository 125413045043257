/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var _get = require('lodash.get');
var changeCase = require('change-case');
var moment = require('moment');

var cleanUpResponse = require('../util/cleanUpResponse');
var getUserIdFromDn = require('../util/getUserIdFromDn');

module.exports = function(taskId) {
  var deferred = $.Deferred();

  $.soap({
    body: {
      GetTaskData: {
        $xmlns: 'http://dobs.pa.gov/cm/task/bpm/1.0',
        TaskID: taskId
      }
    },
    blueprintArrays: ['GetTaskDataResponse.Task.Targets.Target'],
    blueprint: {
      taskId: '$..Task.TaskId',
      processInstanceId: '$..ProcessInstanceId',
      possibleActions: '$..PossibleActions',
      state: '$..State',
      activity: '$..Activity',
      assigneeDn: '$..Assignee.__text',
      assigneeName: '$..Assignee.$displayName',
      completedByDn: '$..CompletedByUser.__text',
      completedByName: '$..CompletedByUser.$displayName',
      deliveryDate: '$..DeliveryDate',
      startDate: '$..StartDate',
      completionDate: '$..CompletionDate',
      rawTaskData: '$..Task.TaskData.ApplicationData.forminputdata.TaskModel.Task',
      targets: [
        '$..Target',
        {
          type: '$type',
          targetDn: '$.__text'
        }
      ],
      taskType: {
        id: '$.GetTaskDataResponse.TaskType.ID',
        name: '$.GetTaskDataResponse.TaskType.Name',
        description: '$.GetTaskDataResponse.TaskType.Description',
        formIdentifier: '$.GetTaskDataResponse.TaskType.FormIdentifier'
      }
    }
  })
    .done(function(response) {
      cleanUpResponse(response);
      var taskData = {};
      var taskDataObjects = ['CM', 'ND', 'DP', 'CS', 'SC', 'CF'];
      _.each(taskDataObjects, function(taskDataObject) {
        taskData[taskDataObject] = {};
        _.each(response.rawTaskData[taskDataObject], function(value, key, list) {
          if (key) {
            var keyName = changeCase.camel(key);
            taskData[taskDataObject][keyName] = value;
          }
        });
      });
      var claimedDate = _get(taskData, 'CM.ClaimedDate');
      if (claimedDate) {
        response.claimedDate = moment(claimedDate, moment.ISO_8601);
      }
      response.taskData = taskData;
      response.assignee = getUserIdFromDn(response.assigneeDn);
      response.completedBy = getUserIdFromDn(response.completedByDn);
      response.isCreated = response.state === 'CREATED';
      response.isAssigned = response.state === 'ASSIGNED';
      response.isCompleted = response.state === 'COMPLETED';
      response.isObsolete = response.state === 'OBSOLETE' || response.state === 'SUSPENDED';
      response.completionDate = moment.utc(response.completionDate, moment.ISO_8601).local();
      response.deliveryDate = moment.utc(response.deliveryDate, moment.ISO_8601).local();
      response.startDate = moment.utc(response.startDate, moment.ISO_8601).local();
      deferred.resolve(response);
    })
    .fail(function(err) {
      deferred.reject(err);
    });

  return deferred.promise();
};
