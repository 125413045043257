/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Marionette = require('backbone.marionette');
var Syphon = require('backbone.syphon');

var parsleyUtil = require('common/util/parsleyUtil');
var makeUploadCollection = require('common/util/makeUploadCollection');

var CommentsDocumentView = require('common/views/CommentsDocumentView');

var tmplDepNewBankCharterAffidavit1003SectionView = require('./DepNewBankCharterAffidavit1003SectionView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplDepNewBankCharterAffidavit1003SectionView,

  className: 'dep-new-bank-charter-affidavit-1003-section-view',

  behaviors: {
    ReadOnlyFormBehavior: {}
  },

  ui: {
    documentForm: '.document-form',
    affidavit1003Container: '.affidavit-1003-container'
  },

  regions: {
    affidavit1003Container: '@ui.affidavit1003Container'
  },

  templateHelpers: function() {
    return {
      isReadOnly: this.options.isReadOnly
    };
  },

  onBeforeShow: function() {
    var filingContent = this.model.get('filingContent') || {};
    var affidavit1003 = filingContent.affidavit1003 || {};

    Syphon.deserialize(this, this.model.get('filingContent'));

    this.affidavit1003 = new CommentsDocumentView({
      isReadOnly: this.options.isReadOnly,
      allowMultipleFiles: false,
      isRequired: true,
      fileUploadLabel: '1003 Affidavit',
      commentsLabel: '1003 Affidavit Comments',
      commentsName: 'affidavit1003Comments',
      collection: makeUploadCollection(affidavit1003.file),
      model: new Backbone.Model({ affidavit1003Comments: affidavit1003.affidavit1003Comments }),
      documentTypeId: 20001
    });
    this.showChildView('affidavit1003Container', this.affidavit1003);
  },

  validate: function(config) {
    this.ui.documentForm.parsley().reset();
    this.affidavit1003.removeValidationErrors();

    if (config.type === 'save') {
      // Turn off required field validation for this form
      parsleyUtil.disableRequiredValidation(this.ui.documentForm);
    } else {
      var validationPromises = [this.ui.documentForm.parsley().whenValidate(), this.affidavit1003.validate()];

      // Turn on required field validation for this form
      parsleyUtil.enableRequiredValidation(this.ui.documentForm);
    }
    return $.when.apply($, validationPromises);
  },

  getFormData: function() {
    var formData = Syphon.serialize(this);
    formData.docId = this.affidavit1003.getFirstFileId();
    return formData;
  }
});
