/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Syphon = require('backbone.syphon');
var Marionette = require('backbone.marionette');

var Ladda = require('ladda');

var SubmitNewEntityJoinCode = require('../services/SubmitNewEntityJoinCode');
var tmplEntityJoinCodeFormModalView = require('../templates/DepEntityJoinCodeFormModalView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplEntityJoinCodeFormModalView,

  className: 'dep-entity-join-code-form-modal-view',

  ui: {
    submitButton: '.btn-submit',
    joinCodeForm: '.join-code-form'
  },

  events: {
    'click @ui.submitButton': 'onClickSubmit'
  },

  onClickSubmit: function(e) {
    e.preventDefault();
    var self = this;
    this.ui.joinCodeForm
      .parsley()
      .whenValidate()
      .done(function() {
        var formData = Syphon.serialize(self);
        var laddaContext = Ladda.create(self.ui.submitButton.get(0));
        laddaContext.start();
        SubmitNewEntityJoinCode({
          firstName: formData.firstName,
          lastName: formData.lastName,
          email: formData.email,
          entityId: self.model.get('entityId'),
          userAccessLevelId: formData.userAccessLevel
        }).done(function(newJoinCode) {
          laddaContext.stop();
          self.triggerMethod('modal:close', newJoinCode);
        });
      });
  }
});
