/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var cleanUpResponse = require('common/util/cleanUpResponse');

module.exports = function() {
  var deferred = $.Deferred();

  $.soap({
    body: {
      GetAllRicsComplianceLookups: {
        $xmlns: 'http://dobs.pa.gov/nd/lookup/bpm/1.0'
      }
    },
    blueprintArrays: [
      'GetAllRicsComplianceLookupsResponse.RicsCaseStatuses.ND_LU_RICSCaseStatus',
      'GetAllRicsComplianceLookupsResponse.RicsCaseStatusReasons.ND_LU_RICSCaseStatusReason',
      'GetAllRicsComplianceLookupsResponse.RicsClearanceDecisions.ND_LU_RICSClearanceDecision'
    ],
    blueprint: {
      caseStatusCodes: [
        '$..ND_LU_RICSCaseStatus',
        {
          code: '$.StatusCode',
          status: '$.Status'
        }
      ],
      caseStatusReasonCodes: [
        '$..ND_LU_RICSCaseStatusReason',
        {
          code: '$.StatusReasonCode',
          reason: '$.StatusReason'
        }
      ],
      clearaceDecisionCodes: [
        '$..ND_LU_RICSClearanceDecision',
        {
          code: '$.ClearanceDecisionCode',
          decision: '$.ClearanceDecision'
        }
      ]
    }
  })
    .done(function(response) {
      cleanUpResponse(response);
      deferred.resolve(response);
    })
    .fail(function(err) {
      deferred.reject();
    });

  return deferred.promise();
};
