var Handlebars = require("handlebars/dist/handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper;

  return "\"#ext/conser/complaint/"
    + container.escapeExpression(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"id","hash":{},"data":data}) : helper)))
    + "/dashboard\"";
},"3":function(container,depth0,helpers,partials,data) {
    return "\"#ext/conser/dashboard\"";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "\n\n<div class=\"form-horizontal\">\n  <form class=\"authority-form\">\n    <div class=\"form-group required\">\n      <label class=\"control-label font-weight-normal authority-label\" id=\"AuthorityViewCheckBoxLabel\">\n        <input type=\"checkbox\" name=\"isAffirmed\" value=\"1\" class=\"authority-checkbox\" id=\"AuthorityViewCheckBox\"\n          data-parsley-required=\"true\" />\n        All information provided may be shared in order to address your inquiry.\n      </label>\n    </div>\n\n    <div class=\"alert alert-danger collapse validation-errors\" role=\"alert\">\n      <i class=\"fa fa-exclamation-triangle\" aria-hidden=\"true\"></i>\n      Validation errors where found while attempting to submit the form. Please fix these issues and re-submit.\n    </div>\n\n    <p>\n      <a href="
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.isExtEdit : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data})) != null ? stack1 : "")
    + "\n        class=\"btn btn-default btn-md\" role=\"button\">Cancel</a>\n      <button class=\"btn btn-primary btn-md submit-new-complaint ladda-button\" data-style=\"expand-right\" type=\"button\">\n        <span class=\"ladda-label\">Submit</span>\n      </button>\n    </p>\n  </form>\n</div>";
},"useData":true});