/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Backbone = require('backbone');
var NProgress = require('nprogress');

var NavUtils = require('psa-core/util/nav');

var Scaffold = require('common/layout/Scaffold');
var GetEntityIdentifiers = require('../../common/services/GetEntityIdentifiers');
var GetEntityDocMgmtDetails = require('../../common/services/GetEntityDocMgmtDetails');

var GetEntityCallReport = require('../services/GetEntityCallReport');
var DepCallReportDashboardView = require('../views/DepCallReportDashboardView');

module.exports = function(entityId, callReportId) {
  NProgress.start();
  NProgress.set(0.6);
  $.when(GetEntityCallReport(callReportId), GetEntityIdentifiers(entityId), GetEntityDocMgmtDetails(entityId))
    .done(function(callReport, entityIdentifiers, docMgmt) {
      NavUtils.navigate('dep/entity/' + entityId + '/call-report/' + callReportId + '/dashboard');

      Scaffold.content.show(
        new DepCallReportDashboardView({
          viewMode: 'int',
          model: new Backbone.Model({
            entityId: entityId,
            entityIdentifiers: entityIdentifiers,
            callReportId: callReportId,
            callReport: callReport,
            callReportsCSFolderId: docMgmt.callReportsCSFolderId
          })
        })
      );
      NProgress.done();
    })
    .fail(function(err) {
      NProgress.done();
    });
};
