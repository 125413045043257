/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var Marionette = require('backbone.marionette');
var Syphon = require('backbone.syphon');
var modelGet = require('common/util/modelGet');

var toggleForm = require('common/util/toggleForm');
var parsleyUtil = require('common/util/parsleyUtil');
var FileUploadCollection = require('psa-backbone/models/FileUploadCollection');

var CommentsDocumentView = require('common/views/CommentsDocumentView');

require('../../styles/DepFilingPrintView.css');
var tmplDepSection112BioFinSectionView = require('./DepSection112BioFinSectionView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplDepSection112BioFinSectionView,

  className: 'dep-section-112-bio-fin-section-view',

  behaviors: {
    ReadOnlyFormBehavior: {}
  },

  ui: {
    documentForm: '.document-form',
    bioFinContainer: '.bio-fin-container'
  },

  regions: {
    bioFinContainer: '@ui.bioFinContainer'
  },

  templateHelpers: function() {
    return {
      isReadOnly: this.options.isReadOnly
    };
  },

  onBeforeShow: function() {
    var filingContent = this.model.get('filingContent') || {};

    var bioFinReports = _.pluck(modelGet(this.model, 'filingContent.bioFinReportDocuments', []), 'file');
    Syphon.deserialize(this, this.model.get('filingContent'));

    this.bioFinDoc = new CommentsDocumentView({
      isReadOnly: this.options.isReadOnly,
      allowMultipleFiles: true,
      isRequired: true,
      fileUploadLabel: 'Biographical and Financial Reports',
      commentsLabel: 'Biographical and Financial Reports Comments',
      commentsName: 'bioFincomments',
      collection: new FileUploadCollection(bioFinReports),
      model: new Backbone.Model({ bioFincomments: filingContent.bioFinReportsComments }),
      documentTypeId: 20001
    });
    this.showChildView('bioFinContainer', this.bioFinDoc);
  },

  onAttach: function() {
    if (this.options.mode === 'print') {
      this.switchToPrint();
    }
  },

  switchToPrint: function() {
    var textAreas = $('.input-view');

    _.each(textAreas, function(textArea) {
      var textAreaVal = textArea.value;
      var textAreaName = textArea.name;
      var printElementId = '#'.concat(textAreaName, 'PrintView');
      $(printElementId).text(textAreaVal);
    });
    toggleForm(textAreas, false);
  },

  validate: function(config) {
    this.ui.documentForm.parsley().reset();
    this.bioFinDoc.removeValidationErrors();

    if (config.type === 'save') {
      // Turn off required field validation for this form
      parsleyUtil.disableRequiredValidation(this.ui.documentForm);
    } else {
      var validationPromises = [this.ui.documentForm.parsley().whenValidate(), this.bioFinDoc.validate()];

      // Turn on required field validation for this form
      parsleyUtil.enableRequiredValidation(this.ui.documentForm);
    }
    return $.when.apply($, validationPromises);
  },

  getFormData: function() {
    var formData = Syphon.serialize(this);
    formData.files = this.bioFinDoc.collection.toJSON();
    return formData;
  }
});
