/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var moment = require('moment');

module.exports = function(formData) {
  var defer = $.Deferred();

  var applicationContacts = formData.contacts || [];
  var financialDocuments = formData.financialDocuments || [];
  var otherBusinesses = formData.generalDetails.otherBusinesses;
  var branchManager = formData.branchManager;
  var dbas = formData.dbasSection;
  var affirmation = formData.affirmation;

  $.soap({
    body: {
      SubmitConsumerDiscountBranchApplication: {
        $xmlns: 'http://dobs.pa.gov/nd/application/bpm/1.0',
        ApplicationID: formData.applicationId,
        HasCriminalOfficer: formData.generalDetails.hasCriminalOfficer,
        HasCriminalOfficerExplanation: formData.generalDetails.hasCriminalOfficerExplanation,
        OfficerAppRefused: formData.generalDetails.officerAppRefused,
        OfficerAppRefusedExplanation: formData.generalDetails.officerAppRefusedExplanation,
        HasOtherBusinesses: formData.generalDetails.hasOtherBusinesses,
        HasMoreLicenses: formData.generalDetails.hasMoreLicenses,
        HasMoreLicensesExplanation: formData.generalDetails.hasMoreLicensesExplanation,
        HasLegalProceedings: formData.generalDetails.hasLegalProceedings,
        BondCompanyID: formData.generalDetails.bondCompanyId,
        BondNumber: formData.generalDetails.bondNumber,
        BondDocumentID: formData.generalDetails.bondDocumentId,
        Email: formData.generalDetails.email,
        IsCertified: affirmation.isCertified ? '1' : '0',
        CertificationName: affirmation.certificationName,
        BranchManager: {
          FirstName: branchManager.firstName,
          MiddleName: branchManager.middleName,
          LastName: branchManager.lastName,
          Title: branchManager.title,
          Suffix: branchManager.suffix,
          SSN: branchManager.ssn,
          DOB: branchManager.dob ? moment(branchManager.dob, 'MM/DD/YYYY').format('YYYY-MM-DD') : '',
          HomePhoneNumber: branchManager.homePhoneNumber,
          CellPhoneNumber: branchManager.cellPhoneNumber,
          Email: branchManager.email,
          Address: {
            AddressLine1: branchManager.addressLine1,
            AddressLine2: branchManager.addressLine2,
            AddressLine3: branchManager.addressLine3,
            County: branchManager.county,
            City: branchManager.city,
            Country: branchManager.country,
            PostalCode: branchManager.postalCode,
            State: branchManager.state
          }
        },
        ApplicationDocuments: (function() {
          if (formData.generalDetails.applicationDocuments.length === 0) {
            return {};
          }
          return {
            DocumentID: formData.generalDetails.applicationDocuments
          };
        })(),
        OtherBusinesses: (function() {
          if (otherBusinesses === undefined || !otherBusinesses.length) {
            return {};
          }
          return {
            OtherBusiness: _.map(otherBusinesses, function(otherBusiness) {
              return {
                Name: otherBusiness.name,
                Description: otherBusiness.description,
                OriginalLicenseDate: otherBusiness.originalLicenseDate
              };
            })
          };
        })(),
        EntityDBAIDs: (function() {
          if (!dbas.entityDBAIds || !dbas.entityDBAIds.length) {
            return {};
          } else {
            return {
              ID: dbas.entityDBAIds
            };
          }
        })(),
        DocumentsToMove: (function() {
          var documentsToMove = _.compact(
            [
              formData.generalDetails.bondDocumentId
            ].concat(formData.generalDetails.applicationDocuments)
          );
          if (!documentsToMove.length) {
            return {};
          }
          return {
            DocumentID: _.map(documentsToMove, function(documentId) {
              return documentId;
            })
          };
        })(),
        ApplicationContacts: (function() {
          if (!applicationContacts.length) {
            return {};
          }
          return {
            ApplicationContact: _.map(applicationContacts, function(applicationContact) {
              return {
                FirstName: applicationContact.firstName,
                LastName: applicationContact.lastName,
                HomePhoneNumber: applicationContact.homePhoneNumber,
                CellPhoneNumber: applicationContact.cellPhoneNumber,
                Email: applicationContact.email,
                LicenseContactTypeID: applicationContact.licenseContactTypeId,
                Title: applicationContact.title,
                Company: applicationContact.company,
                WorkPhoneNumber: applicationContact.workPhoneNumber,
                WorkPhoneExtension: applicationContact.workPhoneExtension,
                Fax: applicationContact.fax,
                Address: {
                  AddressLine1: applicationContact.addressLine1,
                  AddressLine2: applicationContact.addressLine2,
                  AddressLine3: applicationContact.addressLine3,
                  County: applicationContact.county,
                  City: applicationContact.city,
                  State: applicationContact.state,
                  PostalCode: applicationContact.postalCode,
                  Country: applicationContact.country
                }
              };
            })
          };
        })(),
        FinancialStatements: (function() {
          if (!financialDocuments.length) {
            return {};
          }
          return {
            FinancialStatement: _.map(financialDocuments, function(financialDocument) {
              return {
                DocumentID: financialDocument.fileId,
                DocumentTypeID: financialDocument.documentTypeId
              };
            })
          };
        })()
      }
    }
  })
    .done(function() {
      defer.resolve();
    })
    .fail(function(err) {
      defer.reject(err);
    });
  return defer.promise();
};
