var Handlebars = require("handlebars/dist/handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.escapeExpression, alias2=depth0 != null ? depth0 : (container.nullContext || {}), alias3=helpers.helperMissing;

  return "   \n<div class=\"page-title clearfix\">\n  <h1 class=\"pull-left m-n p-n\">"
    + alias1(container.lambda(((stack1 = (depth0 != null ? depth0.task : depth0)) != null ? stack1.activity : stack1), depth0))
    + "</h1>\n</div>\n<div class=\"header-bar-container\"></div>\n<div class=\"breadcrumb-container\"></div>\n<div class=\"task-status\"></div>\n<div class=\"form-horizontal\">\n  <form class=\"examine-application-container\">\n    <div class=\"row\">\n      <div class=\"col-sm-9 col-sm-offset-3\">\n        <h3>Recommendation from Examiner</h3>\n      </div>\n    </div>\n    <div class=\"row\">\n      <div class=\"col-sm-12\">\n        <div class=\"form-group\">\n          <label class=\"control-label col-sm-3 \">Recommendation</label>\n          <div class=\"form-control-static col-sm-9 col-md-6\">\n            "
    + alias1((helpers["default"] || (depth0 && depth0["default"]) || alias3).call(alias2,((stack1 = (depth0 != null ? depth0.application : depth0)) != null ? stack1.filingExaminerDecision : stack1),"--",{"name":"default","hash":{},"data":data}))
    + "\n          </div>\n        </div>\n      </div>\n    </div>\n    <div class=\"row\">\n      <div class=\"col-sm-12\">\n        <div class=\"form-group\">\n          <label class=\"control-label col-sm-3 \">Comments</label>\n          <div class=\"form-control-static col-sm-9 col-md-6\">\n            <p class=\"comments-content\">"
    + alias1((helpers["default"] || (depth0 && depth0["default"]) || alias3).call(alias2,((stack1 = (depth0 != null ? depth0.application : depth0)) != null ? stack1.comments : stack1),"--",{"name":"default","hash":{},"data":data}))
    + "</p>\n          </div>\n        </div>\n      </div>\n    </div>\n    <div class=\"row\">\n      <div class=\"col-sm-9 col-sm-offset-3\">\n        <h3>Documents</h3>\n      </div>\n    </div>\n    <div class=\"row\">\n      <div class=\"col-sm-12\">\n        <div class=\"form-group\">\n          <label class=\"control-label col-sm-3 \">Link To Content Server Folder</label>\n          <div class=\" col-sm-9 col-md-6\">\n            <div class=\"cs-link-container\"></div>\n          </div>\n        </div>\n      </div>\n    </div>\n    <div class=\"row\">\n      <div class=\"col-sm-9 col-sm-offset-3\">\n        <h3>Decision</h3>\n      </div>\n    </div>\n    <div class=\"row\">\n      <div class=\"col-sm-12\">\n        <div class=\"form-group required\">\n          <label class=\"control-label col-sm-3\">Decision</label>\n          <div class=\"col-sm-3\">\n            <select name=\"decisionId\" class=\"form-control\" data-parsley-required=\"true\">\n              <option value=\"\">--</option>\n              <option value=\"1\">Submit</option>\n              <option value=\"2\">Need Further Clarification</option>\n            </select>\n          </div>\n        </div>\n      </div>\n    </div>\n    <div class=\"row\">\n      <div class=\"col-sm-12\">\n        <div class=\"form-group\">\n          <label class=\"control-label col-sm-3 \">Comments</label>\n          <div class=\" col-sm-9 col-md-6\">\n            <textarea name=\"comments\" class=\"form-control\" placeholder=\"Comment\" rows=\"5\"></textarea>\n          </div>\n        </div>\n      </div>\n    </div>\n  </form>\n  <div class=\"form-group\">\n    <div class=\"col-sm-9 col-lg-7 col-sm-offset-3\">\n      <a href=\"#sec/dashboard\" class=\"btn btn-default\" role=\"button\">Cancel</a>\n      <button type=\"button\" class=\"btn btn-primary submit\">Submit</button>\n      <div class=\"task-actions\"></div>\n    </div>\n  </div>\n</div>\n";
},"useData":true});