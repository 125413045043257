/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2020] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var NProgress = require('nprogress');

var NavUtils = require('psa-core/util/nav');

var Scaffold = require('common/layout/Scaffold');
var GetDocumentTypesByDepartment = require('common/services/GetDocumentTypesByDepartment');
var GetUsersForRole = require('common/services/GetUsersForRole');

var GetEntityIdentifiers = require('../../common/services/GetEntityIdentifiers');
var GetAllExamsLookups = require('../services/GetAllExamsLookups');
var GetEntityExternalUsersByAccessLevel = require('../../common/services/GetEntityExternalUsersByAccessLevel');

var DepExamNewView = require('../views/DepExamNewView');

module.exports = function(entityId) {
  NProgress.start();
  NProgress.set(0.6);
  GetEntityIdentifiers(entityId)
    .done(function(entityIdentifiers) {
      var roleString = '';
      if (entityIdentifiers.categoryId === '1') {
        roleString = 'DP - EIC - Bank';
      } else if (entityIdentifiers.categoryId === '2') {
        roleString = 'DP - EIC - Credit Union';
      } else if (entityIdentifiers.categoryId === '3') {
        roleString = 'DP - EIC - Trust';
      }
      $.when(
        GetAllExamsLookups(entityIdentifiers.categoryId, entityIdentifiers.entityTypeId),
        GetUsersForRole(roleString),
        GetDocumentTypesByDepartment('DEPOSITORY'),
        GetEntityExternalUsersByAccessLevel({ entityId: entityId, accessLevel: 1 })
      )
        .done(function(lookups, examiners, documentTypes, entityUsers) {
          NavUtils.navigate('dep/entity/' + entityId + '/new-exam');
          Scaffold.content.show(
            new DepExamNewView({
              model: new Backbone.Model({
                entityIdentifiers: entityIdentifiers,
                entityId: entityId,
                examiners: examiners,
                documentTypes: documentTypes,
                isHoldingCompany: entityIdentifiers.entityTypeId === '2' || entityIdentifiers.entityTypeId === '22',
                lookups: lookups,
                entityUsers: entityUsers
              })
            })
          );
          NProgress.done();
        })
        .fail(function() {
          NProgress.done();
        });
    })
    .fail(function() {
      NProgress.done();
    });
};
