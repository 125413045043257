/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var cleanUpResponse = require('common/util/cleanUpResponse');

module.exports = function(data) {
  var defer = $.Deferred();

  $.soap({
    body: {
      GetInvestigationInquiriesByObject: {
        $xmlns: 'http://dobs.pa.gov/sc/investigationinquiry/bpm/1.0',
        ObjectTypeID: data.objectTypeId,
        ObjectID: data.objectId,
        ObjectLicenseID: data.objectLicenseId
      }
    },
    blueprintArrays: ['GetInvestigationInquiriesByObjectResponse.SC_InvestigationInquiry'],
    blueprint: {
      invinqs: [
        '$..SC_InvestigationInquiry',
        {
          id: '$..ID',
          caseNumber: '$..CaseNumber',
          openedDate: '$..OpenedDate',
          closedDate: '$..ClosedDate',
          primaryExaminer: '$..PrimaryExaminer',
          status: '$..Status',
          category: '$..Category'
        }
      ]
    }
  })
    .done(function(response) {
      cleanUpResponse(response);
      _.each(response.invinqs, function(invinq) {
        invinq.primaryExaminer = invinq.primaryExaminer || '--';
        invinq.status = invinq.status || '--';
        invinq.type = invinq.type || '--';
      });
      defer.resolve(response.invinqs);
    })
    .fail(function(err) {
      defer.reject(err);
    });

  return defer.promise();
};
