/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Marionette = require('backbone.marionette');
var Ladda = require('ladda');
var Radio = require('backbone.radio');

var BreadcrumbView = require('common/views/BreadcrumbView');
var modelGet = require('common/util/modelGet');
var downloadDocument = require('common/util/downloadDocument');
var parsleyUtil = require('common/util/parsleyUtil');

var ExamHeaderBarView = require('../../../common/views/ExamHeaderBarView');

var SubmitEntityExamAnswersTask = require('../../services/SubmitEntityExamAnswersTask');

require('./EntityPrepareExamMaterialsTaskView.css');
var tmplEntityPrepareExamMaterialsTaskView = require('./EntityPrepareExamMaterialsTaskView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplEntityPrepareExamMaterialsTaskView,

  className: 'entity-prepare-exam-materials-task-view container-fluid',

  behaviors: {
    ReadOnlyFormBehavior: {}
  },

  ui: {
    headerBar: '.header-bar',
    breadcrumb: '.breadcrumb-container',
    examQuestionnaire: '.exam-questionnaire',
    examInstructions: '.exam-instructions',
    examQuestionsContainer: '.exam-questions-container',
    examMaterialsForm: '.exam-materials-form',
    save: '.save',
    submit: '.submit'
  },

  regions: {
    headerBar: '@ui.headerBar',
    breadcrumb: '@ui.breadcrumb',
    examQuestionsContainer: '@ui.examQuestionsContainer'
  },

  events: {
    'click @ui.examInstructions': 'onClickDownloadInstructions',
    'click @ui.examQuestionnaire': 'onClickDownloadQuestionnaire',
    'click @ui.save': 'onClickSave',
    'click @ui.submit': 'onClickSubmit'
  },

  initialize: function() {
    this.examQuestions = this.options.examQuestionsView;
  },

  onBeforeShow: function() {
    this.showChildView(
      'headerBar',
      new ExamHeaderBarView({
        model: new Backbone.Model(this.model.get('exam'))
      })
    );

    var exam = this.model.get('exam');
    var entityId = exam.entityId;
    var entityName = exam.entityName;
    var taskName = modelGet(this.model, 'task.taskType.description');

    this.showChildView(
      'breadcrumb',
      new BreadcrumbView({
        breadcrumbs: [
          {
            link: '#ext/nondep/dashboard',
            text: 'Home'
          },
          {
            link: '#ext/nondep/entity/' + entityId + '/dashboard',
            text: entityName
          },
          {
            text: taskName
          }
        ]
      })
    );

    this.showChildView('examQuestionsContainer', this.examQuestions);
  },

  onClickDownloadInstructions: function(e) {
    e.preventDefault();
    var exam = this.model.get('exam');
    downloadDocument({
      fileId: exam.examInstructionsDocumentId,
      fileName: exam.examInstructionsDocumentName,
      isStatic: true
    });
  },

  onClickDownloadQuestionnaire: function(e) {
    e.preventDefault();
    var exam = this.model.get('exam');
    downloadDocument({
      fileId: exam.examQuestionnaireDocumentId,
      fileName: exam.examQuestionnaireDocumentName,
      isStatic: true
    });
  },

  onClickSave: function(e) {
    e.preventDefault();
    var self = this;
    this.validate({ type: 'save' }).done(function() {
      var l = Ladda.create(self.ui.save[0]);
      l.start();
      var examAnswers = self.examQuestions.getFormData();
      var data = {
        examId: self.model.get('examId'),
        examSetupCode: self.model.get('examSetupCode'),
        examQuestionResponses: examAnswers
      };
      SubmitEntityExamAnswersTask(data)
        .done(function() {
          l.stop();
        })
        .fail(function() {
          l.stop();
        });
    });
  },

  onClickSubmit: function(e) {
    e.preventDefault();
    var self = this;
    this.validate({ type: 'submit' }).done(function() {
      var l = Ladda.create(self.ui.submit[0]);
      l.start();
      var examAnswers = self.examQuestions.getFormData();
      var data = {
        taskId: self.model.get('taskId'),
        examId: self.model.get('examId'),
        examSetupCode: self.model.get('examSetupCode'),
        taskType: self.model.get('taskTypeCode'),
        examQuestionResponses: examAnswers
      };
      SubmitEntityExamAnswersTask(data)
        .done(function() {
          Radio.channel('navigate').trigger('show:ext:nd:dashboard');
        })
        .fail(function() {
          l.stop();
        });
    });
  },

  validate: function(config) {
    this.ui.examMaterialsForm.parsley().reset();
    if (config.type === 'save') {
      // Turn off required field validation for this form
      parsleyUtil.disableRequiredValidation(this.ui.examMaterialsForm);
    } else {
      // Turn on required field validation for this form
      parsleyUtil.enableRequiredValidation(this.ui.examMaterialsForm);
    }
    return $.when(this.ui.examMaterialsForm.parsley().whenValidate(), this.examQuestions.validate());
  }
});
