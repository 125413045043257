var Handlebars = require("handlebars/dist/handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "              <option value=\""
    + alias4(((helper = (helper = helpers.userId || (depth0 != null ? depth0.userId : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"userId","hash":{},"data":data}) : helper)))
    + "\">"
    + alias4(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data}) : helper)))
    + " - ("
    + alias4(((helper = (helper = helpers.userId || (depth0 != null ? depth0.userId : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"userId","hash":{},"data":data}) : helper)))
    + ")</option>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {});

  return "\n<div class=\"page-title clearfix\">\n  <h1 class=\"pull-left m-n p-n\">"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? depth0.task : depth0)) != null ? stack1.taskType : stack1)) != null ? stack1.description : stack1), depth0))
    + "</h1>\n</div>\n<div class=\"header-bar-container\"></div>\n<div class=\"breadcrumb-container\"></div>\n<div class=\"task-status\"></div>\n\n<div class=\"container-fluid m-b-lg\">\n  <form class=\"form-horizontal review-form\">\n    <div class=\"col-sm-10 col-sm-offset-1\">\n      <div class=\"case-summary-container\">\n        <div class=\"form-group\">\n          <label class=\"control-label col-sm-4 col-md-3\">Jump To</label>\n          <div class=\"form-control-static col-sm-8 col-md-9 jump-to-container\"></div>\n        </div>\n        <div class=\"summary-container\"></div>\n        <div class=\"form-group\">\n          <label class=\"control-label col-sm-4 col-md-3\">Case Background</label>\n          <p class=\"form-control-static col-sm-8 col-md-9\">"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.complianceDetails : depth0)) != null ? stack1.backgroundInfo : stack1), depth0))
    + "</p>\n        </div>\n      </div>\n      <div class=\"form-group required\">\n        <label class=\"control-label col-sm-4 col-md-3\">Decision</label>\n        <div class=\"col-sm-6 col-md-7 \">\n          <select name=\"decisionId\" class=\"decision-select form-control\" data-parsley-required=\"true\"\n            data-parsley-errors-container=\".decision-select-error-container\">\n            <option value=\"\" selected>--</option>\n            <option value=\"2101\">Accept Request as Compliance Case</option>\n            <option value=\"2102\">Close Request</option>\n          </select>\n          <div class=\"decision-select-error-container\"></div>\n        </div>\n      </div>\n      <div class=\"accept-inputs\">\n        <div class=\"form-group required\">\n          <label class=\"control-label col-sm-4 col-md-3\">Admin</label>\n          <div class=\"col-sm-6 col-md-7\">\n            <select name=\"admin\" class=\"case-admin-select form-control\" data-parsley-required=\"true\"\n              data-parsley-errors-container=\".case-admin-select-error-container\">\n              <option value=\"\">--</option>\n"
    + ((stack1 = helpers.each.call(alias3,(depth0 != null ? depth0.complianceAdmins : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "            </select>\n            <div class=\"case-decision-select-error-container\"></div>\n          </div>\n        </div>\n        <div class=\"form-group required\">\n          <label class=\"control-label col-sm-4 col-md-3\">Date Received</label>\n          <div class=\"col-sm-6 col-md-7\">\n            <div class=\"input-group\">\n              <input type=\"text\" name=\"dateReceived\" class=\"form-control datepicker date-received\"\n                placeholder=\"Date Received\" value=\""
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.complianceDetails : depth0)) != null ? stack1.submissionDate : stack1), depth0))
    + "\" data-parsley-required=\"true\"\n                data-parsley-date=\"\" data-parsley-trigger-after-failure=\"changeDate\"\n                data-parsley-errors-container=\"#dateReceivedErrorsContainer\" />\n              <div class=\"input-group-addon\">\n                <i class=\"fa fa-calendar\"></i>\n              </div>\n            </div>\n            <div id=\"dateReceivedErrorsContainer\"></div>\n          </div>\n        </div>\n        <div class=\"form-group required\">\n          <label class=\"control-label col-sm-4 col-md-3\">Date Assigned</label>\n          <div class=\"col-sm-6 col-md-7\">\n            <div class=\"input-group\">\n              <input type=\"text\" name=\"dateAssigned\" class=\"form-control datepicker date-assigned\"\n                placeholder=\"Date Assigned\" value=\""
    + alias2(((helper = (helper = helpers.currentDate || (depth0 != null ? depth0.currentDate : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(alias3,{"name":"currentDate","hash":{},"data":data}) : helper)))
    + "\" data-parsley-required=\"true\" data-parsley-date=\"\"\n                data-parsley-trigger-after-failure=\"changeDate\"\n                data-parsley-errors-container=\"#dateAssignedErrorsContainer\" />\n              <div class=\"input-group-addon\">\n                <i class=\"fa fa-calendar\"></i>\n              </div>\n            </div>\n            <div id=\"dateAssignedErrorsContainer\"></div>\n          </div>\n        </div>\n      </div>\n      <div class=\"form-group required accept-narrative\">\n        <label class=\"control-label col-sm-4 col-md-3\">Narrative</label>\n        <div class=\"col-sm-6 col-md-7\">\n          <textarea name=\"decisionReason\" class=\"form-control\" placeholder=\"Compliance Case Narrative\" rows=\"10\"\n            data-parsley-required=\"true\" data-parsley-errors-container=\".narrative-error-container\">"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.complianceDetails : depth0)) != null ? stack1.backgroundInfo : stack1), depth0))
    + "</textarea>\n          <div class=\"narrative-error-container\"></div>\n        </div>\n      </div>\n      <div class=\"form-group required reject-reason\">\n        <label class=\"control-label col-sm-4 col-md-3\">Close Reason</label>\n        <div class=\"col-sm-6 col-md-7\">\n          <textarea name=\"decisionReason\" class=\"form-control\" placeholder=\"Compliance Case Close Reason\" rows=\"10\"\n            data-parsley-required=\"true\" data-parsley-errors-container=\".close-reason-error-container\"></textarea>\n          <div class=\"close-reason-error-container\"></div>\n        </div>\n      </div>\n      <div class=\"col-sm-8 col-md-9 col-sm-offset-4 col-md-offset-3\">\n        <a class=\"btn btn-default btn-cancel\" href=\"#nondep/dashboard\">Cancel</a>\n        <button class=\"btn btn-primary btn-submit\">Submit</button>\n        <div class=\"task-actions\"></div>\n      </div>\n  </form>\n</div>";
},"useData":true});