/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var NProgress = require('nprogress');
var Backbone = require('backbone');
var Radio = require('backbone.radio');

var NavUtils = require('psa-core/util/nav');

var Scaffold = require('common/layout/Scaffold');

var GetInvestorComplaint = require('../services/GetInvestorComplaint');
var GetAllContactMethods = require('../../complaint-form/services/GetAllContactMethods');
var GetAllServiceMemberStatus = require('../../complaint-form/services/GetAllServiceMemberStatus');

var ConSerComplaintFormDraftPageView = require('../views/ConSerComplaintFormDraftPageView');

module.exports = function(complaintId) {
  NProgress.start();
  NProgress.set(0.6);
  $.when(GetInvestorComplaint(complaintId), GetAllContactMethods(), GetAllServiceMemberStatus())
    .done(function(complaint, contactMethods, serviceMemberStatuses) {
      NavUtils.navigate('ext/conser/investor-complaint/' + complaintId + '/draft');
      var data = _.extend(complaint, {
        contactMethods: contactMethods,
        serviceMemberStatuses: serviceMemberStatuses
      });
      Scaffold.content.show(
        new ConSerComplaintFormDraftPageView({
          model: new Backbone.Model(data)
        })
      );
      NProgress.done();
    })
    .fail(function(err) {
      NProgress.done();
      if (err.message.codee==="bpm:403") {
        Radio.channel('navigate').trigger('show:ext:cs:dashboard');
      }
    });
};
