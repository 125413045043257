/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Marionette = require('backbone.marionette');
var Syphon = require('backbone.syphon');
var Radio = require('backbone.radio');
var Ladda = require('ladda');

var FileUploadCollection = require('psa-backbone/models/FileUploadCollection');
var FileUploadView = require('common/views/FileUploadView');

var SubmitChangeStatusCounselorApplication = require('../../services/SubmitChangeStatusCounselorApplication');

require('./LicenseCounselorsStatusChangeApplicationView.css');
var tmplLicenseCounselorsStatusChangeApplicationView = require('./LicenseCounselorsStatusChangeApplicationView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplLicenseCounselorsStatusChangeApplicationView,

  className: 'license-counselors-status-change-form-view row',

  ui: {
    counselorsStatusChangeForm: '.counselors-status-change-form',
    buttonsContainer: '.btn-container',
    submitButton: '.submit-button',
    submissionDate: '.submission-date',
    formGroups: '.form-group',
    validationErrorContainers: '.validation-error-container',
    applicationDocumentsFileContainer: '.application-documents-container'
  },

  regions: {
    applicationDocumentsFileContainer: '@ui.applicationDocumentsFileContainer'
  },

  events: {
    'click @ui.submitButton': 'onClickSubmit'
  },

  behaviors: {
    ReadOnlyFormBehavior: {}
  },

  onBeforeShow: function() {
    Syphon.deserialize(this, this.model.toJSON());

    this.applicationDocumentsFile = new FileUploadView({
      isRequired: true,
      allowMultipleFiles: true,
      collection: new FileUploadCollection(this.model.get('applicationDocuments')),
      isReadOnly: this.options.isReadOnly,
      metadata: {
        documentTypeId: 10023
      }
    });
    this.showChildView('applicationDocumentsFileContainer', this.applicationDocumentsFile);
  },

  onClickSubmit: function(event) {
    event.preventDefault();
    var self = this;
    this.validate().done(function() {
      var laddaContext = Ladda.create(self.ui.submitButton.get(0));
      laddaContext.start();
      SubmitChangeStatusCounselorApplication(self.getFormData())
        .done(function(newApplicationId) {
          Radio.channel('nd:submit-application').trigger('submit');
          Radio.channel('nd:submit-application').trigger('resubmit', laddaContext);
        })
        .fail(function(err) {
          laddaContext.stop();
        });
    });
  },

  getFormData: function() {
    var rawData = Syphon.serialize(this);
    rawData.licenseId = this.model.get('licenseId');
    rawData.entityId = this.model.get('entityId');
    rawData.applicationId = this.model.get('applicationId');
    if (rawData.isAffirmed) {
      rawData.isAffirmed = 1;
    }
    rawData.applicationDocuments = this.applicationDocumentsFile.collection.pluck('fileId');
    return rawData;
  },

  validate: function() {
    return this.ui.counselorsStatusChangeForm.parsley().whenValidate();
  }
});
