/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var Marionette = require('backbone.marionette');
var Syphon = require('backbone.syphon');
var Radio = require('backbone.radio');
var Ladda = require('ladda');

var BreadcrumbView = require('common/views/BreadcrumbView');
var SelectFieldView = require('common/views/SelectFieldView');
var DepInternalSidebarNavView = require('../../common/views/DepInternalSidebarNavView');

var SubmitNewEntity = require('../services/SubmitNewEntity');

require('../styles/DepEntityNewView.css');
var tmplDepEntityNewView = require('../templates/DepEntityNewView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplDepEntityNewView,

  className: 'dep-entity-new-view container-fluid',

  ui: {
    sideBar: '.side-bar-container',
    breadCrumbContainer: '.breadcrumb-container',
    category: '.entity-category',
    entityType: '.entity-type',
    newEntityForm: '.new-entity-form',
    entityTypeSelect: '.entity-type-select',
    submit: '.btn-submit'
  },

  regions: {
    breadCrumbContainer: '@ui.breadCrumbContainer',
    entityTypeSelect: '@ui.entityTypeSelect',
    sideBar: '@ui.sideBar'
  },

  events: {
    'change @ui.category': 'onChangeCategory',
    'click @ui.submit': 'onClickSubmit'
  },

  onBeforeShow: function() {
    this.showChildView('sideBar', new DepInternalSidebarNavView());
    this.showChildView(
      'breadCrumbContainer',
      new BreadcrumbView({
        breadcrumbs: [
          {
            text: 'Home',
            link: '#dep/dashboard'
          },
          {
            text: 'Create A New Entity'
          }
        ]
      })
    );

    this.entityTypeSelect = new SelectFieldView({
      isDisabled: false,
      isRequired: true,
      name: 'entityTypeId',
      collection: new Backbone.Collection([])
    });
    this.showChildView('entityTypeSelect', this.entityTypeSelect);
  },

  onChangeCategory: function(e) {
    e.preventDefault();
    var types = this.setEntityTypes(this.ui.category.val());
    this.entityTypeSelect.collection.reset(types);
  },

  setEntityTypes: function(categoryId) {
    var allTypes = this.model.get('entityTypes');
    return _.chain(allTypes)
      .where({ categoryId: categoryId })
      .map(function(entityType) {
        return { value: entityType.id, text: entityType.type };
      })
      .value();
  },

  onClickSubmit: function(e) {
    e.preventDefault();
    this.ui.newEntityForm
      .parsley()
      .whenValidate()
      .done(
        function() {
          var laddaContext = Ladda.create(this.ui.submit.get(0));
          laddaContext.start();
          SubmitNewEntity(this.getFormData())
            .done(function(newEntityId) {
              Radio.channel('navigate').trigger('show:int:dp:entity', newEntityId);
            })
            .fail(function() {
              laddaContext.stop();
            });
        }.bind(this)
      );
  },

  getFormData: function() {
    return Syphon.serialize(this.ui.newEntityForm);
  }
});
