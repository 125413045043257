/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Backbone = require('backbone');
var NProgress = require('nprogress');

var NavUtils = require('psa-core/util/nav');

var Scaffold = require('common/layout/Scaffold');
var GetEntityIdentifiers = require('../../common/services/GetEntityIdentifiers');

var GetEntityAudit = require('../services/GetEntityAudit');
var GetAllEntityAuditLookups = require('../services/GetAllEntityAuditLookups');
var DepAuditEditView = require('../views/DepAuditEditView');

module.exports = function(entityId, auditId) {
  NProgress.start();
  NProgress.set(0.4);
  $.when(GetEntityAudit(auditId), GetEntityIdentifiers(entityId))
    .done(function(audit, entityIdentifiers) {
      NProgress.set(0.7);
      GetAllEntityAuditLookups(entityIdentifiers.categoryId, entityIdentifiers.charterTypeId)
        .done(function(lookups) {
          NavUtils.navigate('#dep/entity/' + entityId + '/audit/' + auditId + '/edit');
          Scaffold.content.show(
            new DepAuditEditView({
              viewMode: 'int',
              model: new Backbone.Model({
                entityId: entityId,
                auditId: auditId,
                entityIdentifiers: entityIdentifiers,
                auditTypes: lookups.auditTypes,
                auditTrustTypes: lookups.auditTrustTypes,
                auditOpinions: lookups.auditOpinions,
                auditFirms: lookups.auditFirms,
                audit: audit
              })
            })
          );
          NProgress.done();
        })
        .fail(function(err) {
          NProgress.done();
        });
    })
    .fail(function(err) {
      NProgress.done();
    });
};
