/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtaineds
 * from Counterpoint Consulting Incorporated.
 */

var Backbone = require('backbone');
var Marionette = require('backbone.marionette');
var Syphon = require('backbone.syphon');
var bodybuilder = require('bodybuilder');
var Ladda = require('ladda');

var GridView = require('psa-marionette/views/GridView');
var PageableCollection = require('psa-backbone/models/PageableCollection');

var LinkCell = require('common/backgrid/LinkCell');
var AddressCell = require('common/backgrid/AddressCell');

var ElasticSearchSummaryView = require('common/views/ElasticSearchSummaryView');
var NonDepInternalSidebarNavView = require('../../common/views/NonDepInternalSidebarNavView');

var SearchNonDepLicenseAddressHistory = require('../services/SearchNonDepLicenseAddressHistory');

require('../styles/NonDepInternalLicenseAddressHistorySearchView.css');
var tmplNonDepInternalLicenseAddressHistorySearchView = require('../templates/NonDepInternalLicenseAddressHistorySearchView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplNonDepInternalLicenseAddressHistorySearchView,

  className: 'non-dep-internal-license-address-history-search-view container-fluid',

  ui: {
    sideBar: '.side-bar-container',
    searchResultsContainer: '.search-results-container',
    search: '.search',
    clearFilters: '.clear-filters',
    formInputs: ':input',
    searchSummary: '.search-summary'
  },

  regions: {
    sideBar: '@ui.sideBar',
    searchResultsContainer: '@ui.searchResultsContainer',
    searchSummary: '@ui.searchSummary'
  },

  events: {
    'click @ui.search': 'onClickSearch',
    'click @ui.clearFilters': 'onClickClearFilters',
    'keydown @ui.formInputs': 'onKeydownOfInputs'
  },

  onBeforeShow: function() {
    this.showChildView('sideBar', new NonDepInternalSidebarNavView());

    this.searchSummaryView = new ElasticSearchSummaryView({
      model: new Backbone.Model({
        searchLimit: this.model.get('searchLimit')
      })
    });
    this.showChildView('searchSummary', this.searchSummaryView);

    this.searchResults = new GridView({
      columns: [
        {
          name: 'entityName',
          label: 'Entity',
          cell: LinkCell.extend({
            emptyText: '--',
            newTab: true,
            href: function(model) {
              var entityId = model.get('entityId');
              var url = entityId ? '#nondep/entity/' + entityId + '/dashboard' : '';
              return url;
            }
          }),
          headerCell: 'custom',
          width: 33,
          editable: false
        },
        {
          name: 'license',
          label: 'License',
          cell: LinkCell.extend({
            emptyText: '--',
            newTab: true,
            href: function(model) {
              var entityId = model.get('entityId');
              var licenseId = model.get('licenseId');
              return entityId && licenseId ? '#nondep/entity/' + entityId + '/license/' + licenseId : '';
            }
          }),
          headerCell: 'custom',
          width: 33,
          editable: false
        },
        {
          name: 'address',
          label: 'Address',
          cell: AddressCell.extend({
            render: function() {
              AddressCell.prototype.render.apply(this);
              if (this.model.get('isCurrentAddress') === 1) {
                this.$el.append('<span class="label label-primary">Current License Address</span>');
              }
              return this;
            }
          }),
          headerCell: 'custom',
          width: 33,
          editable: false
        }
      ],
      collection: new PageableCollection([], { pageSize: 25 }),
      emptyText: 'No License Addresses Found'
    });

    this.showChildView('searchResultsContainer', this.searchResults);

    this.$('.backgrid.table').addClass('table-bordered');
  },

  onKeydownOfInputs: function(e) {
    // Searches on press of enter
    if (e.keyCode === 13) {
      this.onClickSearch(e);
    }
  },

  onClickSearch: function() {
    var self = this;
    var data = Syphon.serialize(this, {
      inputReaders: Syphon.SearchInputReaderSet
    });
    // If no inputs were given, do not perform a search.
    if (!data.streetAddress && !data.city) {
      return;
    }
    this.searchSummaryView.reset();

    var l = Ladda.create(this.ui.search[0]);
    l.start();
    var body = bodybuilder();

    if (data.streetAddress) {
      body.query('bool', function(bb) {
        bb.orQuery('match', 'address.addressLine1', {
          query: data.streetAddress,
          fuzziness: 'AUTO'
        });
        bb.orQuery('wildcard', 'address.addressLine1', data.streetAddress.toLowerCase() + '*');
        return bb;
      });
    }

    if (data.city) {
      body.query('bool', function(bb) {
        bb.orQuery('match', 'address.city', {
          query: data.city,
          fuzziness: 'AUTO'
        });
        bb.orQuery('wildcard', 'address.city', data.city.toLowerCase() + '*');
        return bb;
      });
    }

    body.size(this.model.get('searchLimit'));
    var bodyObj = body.build();
    // Keep for testing
    // console.log(JSON.stringify(bodyObj, null, 2));
    SearchNonDepLicenseAddressHistory(bodyObj)
      .done(function(data) {
        l.stop();
        self.searchResults.collection.fullCollection.reset(data.hits);
        self.searchSummaryView.display(data);
      })
      .fail(function(err) {
        l.stop();
      });
  },

  onClickClearFilters: function() {
    this.ui.formInputs.val('');
    this.ui.checkboxes.prop('checked', false);
  }
});
