var Handlebars = require("handlebars/dist/handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "            <option value=\""
    + alias4(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\">"
    + alias4(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data}) : helper)))
    + "</option>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "\n<div class=\"modal-body\">\n  <div class=\"form-horizontal\">\n    <form class=\"join-code-form\">\n      <div class=\"form-group required\">\n        <label class=\"control-label col-sm-3\">First Name</label>\n        <div class=\"col-sm-9\">\n          <input type=\"text\" name=\"firstName\" class=\"form-control\" placeholder=\"First Name\" data-parsley-required=\"true\" data-parsley-maxlength=\"100\" />\n        </div>\n      </div>\n      <div class=\"form-group required\">\n        <label class=\"control-label col-sm-3\">Last Name</label>\n        <div class=\"col-sm-9\">\n          <input type=\"text\" name=\"lastName\" class=\"form-control\" placeholder=\"Last Name\" data-parsley-required=\"true\" data-parsley-maxlength=\"100\" />\n        </div>\n      </div>\n      <div class=\"form-group required\">\n        <label class=\"control-label col-sm-3\">Email Address</label>\n        <div class=\"col-sm-9\">\n          <input type=\"text\" name=\"email\" class=\"form-control\" placeholder=\"Email\"  data-parsley-required=\"true\" data-parsley-maxlength=\"254\" data-parsley-type=\"email\" />\n        </div>\n      </div>  \n      <div class=\"form-group required\">\n        <label class=\"control-label col-sm-3\">Access Level</label>\n        <div class=\"col-sm-9\">\n          <select name=\"userAccessLevel\" class=\"form-control\" data-parsley-required=\"true\">\n            <option value=\"\">--</option>\n"
    + ((stack1 = helpers.each.call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.userAccessLevels : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "          </select>\n        </div>\n      </div>\n    </form>\n  </div>\n</div>\n<div class=\"modal-footer\">\n  <button class=\"btn btn-default\" data-dismiss=\"modal\">Cancel</button>\n    <button class=\"btn btn-primary btn-submit ladda-button\" data-style=\"expand-right\">\n    <span class=\"ladda-label\">Submit</span>\n  </button>\n</div>";
},"useData":true});