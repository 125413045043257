/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Radio = require('backbone.radio');
var NProgress = require('nprogress');

var NavUtils = require('psa-core/util/nav');

var Scaffold = require('common/layout/Scaffold');

var DraftLicenseApplicationView = require('../views/DraftLicenseApplicationView');

var GetApplicationDetails = require('../../common/services/GetApplicationDetails');

module.exports = function(applicationId) {
  NProgress.start();
  NProgress.set(0.3);
  GetApplicationDetails(applicationId)
    .done(function(applicationDetails) {
      NProgress.set(0.6);
      Radio.channel('nd:application')
        .request('build:' + applicationDetails.formIdentifier, {
          applicationId: applicationDetails.applicationId,
          applicationTypeId: applicationDetails.applicationTypeId,
          isReadOnly: false,
          entityId: applicationDetails.entityId
        })
        .done(function(licenseApplicationView) {
          NavUtils.navigate('ext/nondep/application/' + applicationId + '/draft');
          Scaffold.content.show(
            new DraftLicenseApplicationView({
              licenseApplicationView: licenseApplicationView,
              model: new Backbone.Model({
                applicationDetails: applicationDetails
              })
            })
          );
          NProgress.done();
        })
        .fail(function(err) {
          NProgress.done();
        });
    })
    .fail(function(err) {
      NProgress.done();
    });
};
