var Handlebars = require("handlebars/dist/handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "        <option value=\""
    + alias4(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\">"
    + alias4(((helper = (helper = helpers.code || (depth0 != null ? depth0.code : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"code","hash":{},"data":data}) : helper)))
    + " - "
    + alias4(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data}) : helper)))
    + "</option>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "\n<form class=\"form-horizontal compliance-form\">\n  <div class=\"form-group required\">\n    <label class=\"control-label col-sm-3\">Entity</label>\n    <div class=\"col-sm-9\">\n      <div class=\"input-group\">\n        <span class=\"input-group-btn\">\n          <button class=\"btn btn-primary btn-entity-search\">Search for Entity</button>\n        </span>\n        <input type=\"text\" class=\"selected-entity-name form-control\" placeholder=\"Search for an Entity\" readonly>\n        <input type=\"number\" class=\"selected-entity-id\" name=\"entityId\" data-parsley-required=\"true\"\n          data-parsley-errors-container=\".entity-select-error-container\" hidden>\n      </div>\n      <div class=\"entity-select-error-container\"></div>\n    </div>\n  </div>\n  <div class=\"form-group required\">\n    <div class=\"col-sm-9 col-sm-offset-3 col-md-offset-3\">\n      <label class=\"control-label\">\n        <input type=\"checkbox\" class=\"existing-license-checkbox\" name=\"existingLicense\">\n        Is compliance case against a license currently or once owned by the entity?\n      </label>\n    </div>\n  </div>\n  <div class=\"form-group required license-number-form-group\">\n    <label class=\"control-label col-sm-3\">License Number</label>\n    <div class=\"license-number-select-container col-sm-9\"></div>\n  </div>\n  <div class=\"form-group required operating-as-form-group\">\n    <label class=\"control-label col-sm-3\">Type Operating As</label>\n    <div class=\"col-sm-9 \">\n      <select name=\"operatingAsType\" class=\"operating-as-select form-control\" data-parsley-required=\"true\"\n        data-parsley-errors-container=\".operating-as-select-error-container\"\n        data-parsley-trigger-after-failure=\"change.select2\">\n        <option value=\"\" selected>--</option>\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.licenseTypes : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "      </select>\n      <div class=\"operating-as-select-error-container\"></div>\n    </div>\n  </div>\n  <div class=\"form-group required\">\n    <label class=\"control-label col-sm-3\">Violations</label>\n    <div class=\"col-sm-9 \">\n      <select name=\"violationCodes\" class=\"violations-select form-control\" multiple data-parsley-required=\"true\"\n        data-parsley-errors-container=\".violations-select-error-container\"\n        data-parsley-trigger-after-failure=\"change.select2\">\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.violationCodes : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "      </select>\n      <div class=\"violations-select-error-container\"></div>\n    </div>\n  </div>\n  <div class=\"form-group required\">\n    <label class=\"control-label col-sm-3\">Background Information</label>\n    <div class=\"col-sm-9 \">\n      <textarea name=\"backgroundInformation\" class=\"form-control\" placeholder=\"Compliance Case Background Information\"\n        rows=\"6\" data-parsley-required=\"true\"></textarea>\n    </div>\n  </div>\n</form>";
},"useData":true});