/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var NProgress = require('nprogress');
var Backbone = require('backbone');

var NavUtils = require('psa-core/util/nav');

var GetLicenseDashboard = require('../services/GetLicenseDashboard');
var GetLicenseDBA = require('../services/GetLicenseDBA');

var ShowNonDepLicenseDashboardView = require('./ShowNonDepLicenseDashboardView');

module.exports = function(entityId, licenseId) {
  NProgress.start();
  NProgress.set(0.6);
  var isEdit = 0;

  $.when(GetLicenseDashboard(licenseId), GetLicenseDBA(licenseId, isEdit))
    .done(function(licenseDashboard, licenseDbas) {
      ShowNonDepLicenseDashboardView({
        viewMode: 'int',
        model: new Backbone.Model(
          _.extend(
            {
              entityId: entityId,
              licenseId: licenseId,
              licenseDbas: licenseDbas
            },
            licenseDashboard
          )
        )
      })
        .done(function() {
          NProgress.done();
          NavUtils.navigate('nondep/entity/' + entityId + '/license/' + licenseId);
        })
        .fail(function() {
          NProgress.done();
        });
    })
    .fail(function() {
      NProgress.done();
    });
};
