var Handlebars = require("handlebars/dist/handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper;

  return "    <div class=\"m-b-md\">\n      <a href="
    + container.escapeExpression(((helper = (helper = helpers.contactsEditUrl || (depth0 != null ? depth0.contactsEditUrl : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"contactsEditUrl","hash":{},"data":data}) : helper)))
    + " target=\"_blank\">Click to view and Apply to Edit Contacts</a>\n    </div>\n";
},"3":function(container,depth0,helpers,partials,data) {
    return "    <div class=\"alert alert-danger m-t-md\" role=\"alert\">\n      <i class=\"fa fa-exclamation-triangle\" aria-hidden=\"true\"></i>\n      This task must be completed by a user with Manager or Administrator access.\n    </div>\n    <a class=\"btn disabled\" href=\"#\">Click to view and Apply to Edit Contacts</a>\n";
},"5":function(container,depth0,helpers,partials,data) {
    return "  <button class=\"btn btn-primary submit ladda-button\" data-style=\"expand-right\" type=\"button\">\n    <span class=\"ladda-label\">Acknowledge</span>\n  </button>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {});

  return "\n<div class=\"page-title clearfix\">\n  <h1 class=\"pull-left m-n p-n\">"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? depth0.task : depth0)) != null ? stack1.taskType : stack1)) != null ? stack1.description : stack1), depth0))
    + "</h1>\n</div>\n<div class=\"breadcrumb-container\"></div>\n<div class=\"col-sm-8 col-sm-offset-2 notification-container m-b-lg\">\n  <form class=\"verify-contacts-form\">\n    <div class=\"m-b-md\">\n      <b>Please verify the Contact information for License #"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.licenseIdentifiers : depth0)) != null ? stack1.licenseNumber : stack1), depth0))
    + ".\n        Click the link below to view and edit the Contacts as necessary.</b>\n      <br />\n    </div>\n\n"
    + ((stack1 = helpers["if"].call(alias3,(depth0 != null ? depth0.hasSubmitPermissions : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data})) != null ? stack1 : "")
    + "\n    <div class=\"form-group required\">\n      <label class=\"control-label font-weight-normal certify-label\">\n        <input type=\"checkbox\" class=\"certify-checkbox\" name=\"isCertified\" value=\"1\" data-parsley-required=\"true\" />\n        I understand that by acknowledging this notifcation I am agreeing to be bound by the following declaration:\n        <br />\n        \"I declare that all of the Contact information for License #123 is true and correct. I make this declaration\n        subject to the penalties of 18 PA.C.S. § 4904 relating to unsworn falsifcation to authorities.\"\n      </label>\n    </div>\n  </form>\n</div>\n<div class=\"col-sm-8 col-sm-offset-2\">\n  <a href=\"#ext/nondep/dashboard\" class=\"btn btn-default\" role=\"button\">Cancel</a>\n"
    + ((stack1 = helpers["if"].call(alias3,(depth0 != null ? depth0.hasSubmitPermissions : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "</div>";
},"useData":true});