/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Backbone = require('backbone');
var Marionette = require('backbone.marionette');

var PanelCellCollectionView = require('./PanelCellCollectionView');

require('./PanelRowView.css');
var tmplPanelRowView = require('./PanelRowView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplPanelRowView,

  className: 'panel-row-view panel panel-default',

  ui: {
    panelBody: '.panel-body'
  },

  regions: {
    panelBody: '@ui.panelBody'
  },

  onBeforeShow: function() {
    this.showChildView(
      'panelBody',
      new PanelCellCollectionView({
        model: this.model,
        collection: new Backbone.Collection(this.options.columns)
      })
    );
  }
});
