/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Backbone = require('backbone');
var Marionette = require('backbone.marionette');
var Radio = require('backbone.radio');

var UIUtil = require('psa-core/util/ui');

var DropdownView = require('common/views/DropdownView');
var HeaderBarView = require('common/views/HeaderBarView');
var SideNavSectionView = require('common/views/SideNavSectionView');
var ConfirmationModalView = require('common/views/ConfirmationModalView');
var ContentServerLinkView = require('common/views/ContentServerLinkView');

var SubmitCompliance = require('../services/SubmitCompliance');
var SecComplianceGeneralInfoSectionView = require('./SecComplianceGeneralInfoSectionView');
var SecComplianceBreadcrumbView = require('./SecComplianceBreadcrumbView');

var tmplSecComplianceDashboardView = require('../templates/SecComplianceDashboardView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplSecComplianceDashboardView,

  className: 'sec-compliance-dashboard-view container-fluid',

  ui: {
    headerBar: '.header-bar',
    complianceWrapper: '.compliance-wrapper',
    csLinkContainer: '.cs-link-container',
    breadcrumb: '.breadcrumb-container',
    complianceActionDropDown: '.dropdown-container'
  },

  regions: {
    headerBar: '@ui.headerBar',
    complianceWrapper: '@ui.complianceWrapper',
    csLinkContainer: '@ui.csLinkContainer',
    breadcrumb: '@ui.breadcrumb',
    complianceActionDropDown: '@ui.complianceActionDropDown'
  },

  onBeforeShow: function() {
    var compliance = this.model.get('compliance') || {};
    var identifiers = compliance.identifiers || {};
    var complianceId = this.model.get('complianceId');
    var complianceCSFolderId = compliance.csFolderId;
    var objectId = compliance.objectId;
    var actionType = compliance.actionType;

    this.showChildView(
      'csLinkContainer',
      new ContentServerLinkView({
        model: new Backbone.Model({
          buttonText: 'Open Compliance Folder',
          contentServerId: complianceCSFolderId
        })
      })
    );

    var dropDownOptions = [
      {
        text: 'Edit Compliance General Information',
        link: '#sec/compliance/' + complianceId + '/edit-general-info'
      },
      {
        text: 'Delete Compliance',
        onClick: this.onClickDelete.bind(this)
      },
      {
        isDivider: true
      },
      {
        text: 'Add Sanction',
        link: '#sec/compliance/' + complianceId + '/new-sanction'
      }
    ];

    this.showChildView(
      'complianceActionDropDown',
      new DropdownView({
        rightAlign: true,
        model: new Backbone.Model({
          name: 'Compliance Actions',
          options: dropDownOptions
        })
      })
    );

    var headerData = {
      title: 'Compliance',
      dbaName: identifiers.dbaName,
      crdNumber: identifiers.crdNumber,
      licenseType: identifiers.licenseType,
      examCaseNumber: identifiers.examCaseNumber,
      actionType: identifiers.actionType
    };

    this.showChildView(
      'headerBar',
      new HeaderBarView({
        columns: new Backbone.Collection([
          {
            name: 'crdNumber',
            label: 'CRD Number',
            minWidth: '160px'
          },
          {
            name: 'licenseType',
            label: 'License Type',
            minWidth: '160px'
          },
          {
            name: 'examCaseNumber',
            label: 'Exam/Case Number',
            minWidth: '160px'
          },
          {
            name: 'actionType',
            label: 'Action Type',
            minWidth: '160px'
          }
        ]),
        model: new Backbone.Model(headerData)
      })
    );

    this.showChildView(
      'breadcrumb',
      new SecComplianceBreadcrumbView({
        identifiers: identifiers,
        objectId: objectId,
        breadcrumbs: [
          {
            text: actionType + ' Compliance Case'
          }
        ]
      })
    );

    this.compliance = new SideNavSectionView({
      useNavigationButtons: false,
      collection: new Backbone.Collection([
        {
          id: 'generalInformation',
          title: 'General Information',
          view: new SecComplianceGeneralInfoSectionView({
            model: new Backbone.Model(compliance)
          })
        },
        {
          id: 'sanctions',
          title: 'Sanctions',
          view: Radio.channel('sc').request('build:comp-sanction-view', complianceId)
        },
        {
          id: 'payments',
          title: 'Payments',
          view: Radio.channel('sc').request('build:comp-payments-view', complianceId)
        },
        {
          id: 'agReferral',
          title: 'AG Referral',
          view: Radio.channel('sc').request('build:comp-ag-referral-view', complianceId)
        },
        {
          id: 'notes',
          title: 'Notes',
          view: Radio.channel('sc').request('build:notes-view', {
            objectId: complianceId,
            objectTypeId: 'COMPLIANCE'
          })
        },
        {
          id: 'auditLog',
          title: 'Audit Log',
          view: Radio.channel('sc').request('build:audit-log-view', {
            objectId: complianceId,
            objectTypeId: 'COMPLIANCE'
          })
        }
      ])
    });
    this.showChildView('complianceWrapper', this.compliance);
  },

  onClickDelete: function(e) {
    e.preventDefault();

    var self = this;
    var message = 'Are you sure you want to delete this compliance case?';
    UIUtil.showModal({
      title: 'Confirm Deleting Compliance Case',
      view: new ConfirmationModalView({
        confirmText: 'Delete Compliance Case',
        model: new Backbone.Model({
          message: message,
          onConfirm: function() {
            var complianceId = self.model.get('complianceId');
            return SubmitCompliance({ complianceId: complianceId, isDeleted: '1' });
          },
          onConfirmDone: function() {
            var compliance = self.model.get('compliance') || {};
            var objectTypeId = compliance.objectTypeId;
            var objectId = compliance.objectId;

            if (objectTypeId === 'EXAM') {
              Radio.channel('navigate').trigger('show:int:sc:exam-dashboard', objectId);
            } else {
              Radio.channel('navigate').trigger('show:int:sc:investigation-inquiry-dashboard', objectId);
            }
          }
        })
      })
    });
  }
});
