/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Backbone = require('backbone');
var Marionette = require('backbone.marionette');
var Syphon = require('backbone.syphon');
var bodybuilder = require('bodybuilder');
var Ladda = require('ladda');
var _ = require('underscore');

var toggleForm = require('common/util/toggleForm');

var GridView = require('psa-marionette/views/GridView');
var PageableCollection = require('psa-backbone/models/PageableCollection');

var ListCell = require('common/backgrid/ListCell');
var AddressCell = require('common/backgrid/AddressCell');
var ButtonLinkCell = require('common/backgrid/ButtonLinkCell');
var ElasticSearchSummaryView = require('common/views/ElasticSearchSummaryView');
var ConSerInternalSidebarNavView = require('./ConSerInternalSidebarNavView');

var SearchNonDepEntities = require('../../../non-depository/common/services/SearchNonDepEntities');
var SearchDepEntities = require('../../../depository/common/services/SearchDepEntities');
var SearchSecOrganizations = require('../../../securities/common/services/SearchSecOrganizations');
var SearchConSerEntities = require('../../common/services/SearchConSerEntities');

require('../styles/ConSerEntitySearchView.css');
var tmplConSerEntitySearchView = require('../templates/ConSerEntitySearchView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplConSerEntitySearchView,

  className: 'con-ser-entity-search-view container-fluid',

  ui: {
    sideBar: '.side-bar-container',
    department: '[name="departmentId"]',
    entityName: '.entity-name-col',
    applicantLegalName: '.applicant-legal-name-col',
    licenseType: '.license-type-col',
    secLicenseType: '.sec-license-type-col',
    includeInactiveLicense: '.include-inactive-license-col',
    crdNumber: '.crd-number-col',
    primaryPhoneNumber: '.primary-phone-number-col',
    secondaryPhoneNumber: '.secondary-phone-number-col',
    streetAddress: '.street-address-col',
    city: '.city-col',
    searchResultsContainer: '.search-results-container',
    search: '.search',
    clearFilters: '.clear-filters',
    formInputs: ':input[name!="departmentId"]',
    searchSummary: '.search-summary'
  },

  regions: {
    sideBar: '@ui.sideBar',
    searchResultsContainer: '@ui.searchResultsContainer',
    searchSummary: '@ui.searchSummary'
  },

  events: {
    'change @ui.department': 'onChangeDepartment',
    'click @ui.search': 'onClickSearch',
    'click @ui.clearFilters': 'onClickClearFilters',
    'keydown @ui.formInputs': 'onKeydownOfInputs'
  },

  onBeforeShow: function() {
    this.showChildView('sideBar', new ConSerInternalSidebarNavView());

    this.searchSummaryView = new ElasticSearchSummaryView({
      model: new Backbone.Model({
        searchLimit: this.model.get('searchLimit')
      })
    });
    this.showChildView('searchSummary', this.searchSummaryView);

    this.searchResultsGrid = new GridView({
      columns: [
        {
          name: 'entityName',
          label: 'Entity Name',
          cell: 'string',
          headerCell: 'custom',
          width: 15,
          editable: false
        },
        {
          name: 'dbas',
          label: 'D/B/A',
          cell: ListCell,
          headerCell: 'custom',
          width: 15,
          editable: false
        },
        {
          name: 'licensesList',
          label: 'Licenses',
          cell: ListCell,
          headerCell: 'custom',
          width: 15,
          editable: false
        },
        {
          name: 'secLicensesList',
          label: 'Licenses',
          cell: ListCell,
          headerCell: 'custom',
          width: 15,
          editable: false
        },
        {
          name: 'licenseNumber',
          label: 'License Number(s)',
          cell: 'string',
          headerCell: 'custom',
          width: 15,
          editable: false
        },
        {
          name: 'nmlsEntityId',
          label: 'NMLS ID',
          cell: 'string',
          headerCell: 'custom',
          width: 10,
          editable: false
        },
        {
          name: 'phone',
          label: 'Primary Phone',
          cell: 'string',
          headerCell: 'custom',
          width: 15,
          editable: false
        },
        {
          name: 'secondaryPhone',
          label: 'Secondary Phone',
          cell: 'string',
          headerCell: 'custom',
          width: 15,
          editable: false
        },
        {
          name: 'fax',
          label: 'Fax',
          cell: 'string',
          headerCell: 'custom',
          width: 15,
          editable: false
        },
        {
          name: 'address',
          label: 'Street Address',
          cell: AddressCell,
          headerCell: 'custom',
          width: 15,
          editable: false
        },
        {
          name: 'crdNumber',
          label: 'CRD Number',
          cell: 'string',
          headerCell: 'custom',
          width: 15,
          editable: false
        },
        {
          name: 'openButton',
          label: '',
          cell: ButtonLinkCell.extend({
            name: 'Open',
            newTab: true,
            buttonClass: 'btn-primary btn-sm',
            href: function(model) {
              // departmentId and entityId
              var departmentId = $('[name="departmentId"]').val();
              var entityId = model.get('entityId');

              return '#conser/' + departmentId + '/entity/' + entityId + '/dashboard';
            }
          }),
          headerCell: 'custom',
          width: 10,
          editable: false,
          sortable: false
        }
      ],
      collection: new PageableCollection([], {
        pageSize: 25
      }),
      emptyText: 'No Entities Found'
    });

    this.showChildView('searchResultsContainer', this.searchResultsGrid);

    this.$('.backgrid.table').addClass('table-bordered');
  },

  onShow: function() {
    // Set column comparator to keep column ordering
    this.searchResultsGrid.viewGrid.columns.comparator = function(column) {
      var columnOrder = [
        'entityName',
        'dbas',
        'licensesList',
        'secLicensesList',
        'licenseNumber',
        'nmlsEntityId',
        'phone',
        'secondaryPhone',
        'fax',
        'address',
        'crdNumber'
      ];
      var index = columnOrder.indexOf(column.get('name'));
      return index !== -1 ? index : Infinity;
    };

    this.entityNameColModel = this.searchResultsGrid.viewGrid.columns.findWhere({ name: 'entityName' });
    this.openButtonColModel = this.searchResultsGrid.viewGrid.columns.findWhere({ name: 'openButton' });
    this.licensesColModel = this.searchResultsGrid.viewGrid.columns.findWhere({ name: 'licensesList' });
    this.secLicensesColModel = this.searchResultsGrid.viewGrid.columns.findWhere({ name: 'secLicensesList' });
    this.faxColModel = this.searchResultsGrid.viewGrid.columns.findWhere({ name: 'fax' });
    this.phoneColModel = this.searchResultsGrid.viewGrid.columns.findWhere({ name: 'phone' });
    this.secondaryPhoneColModel = this.searchResultsGrid.viewGrid.columns.findWhere({ name: 'secondaryPhone' });
    this.addressColModel = this.searchResultsGrid.viewGrid.columns.findWhere({ name: 'address' });
    this.crdNumberColModel = this.searchResultsGrid.viewGrid.columns.findWhere({ name: 'crdNumber' });
    this.licNumberColModel = this.searchResultsGrid.viewGrid.columns.findWhere({ name: 'licenseNumber' });
    this.nmlsColModel = this.searchResultsGrid.viewGrid.columns.findWhere({ name: 'nmlsEntityId' });
    this.onChangeDepartment();
  },

  onKeydownOfInputs: function(e) {
    // Searches on press of enter
    if (e.keyCode === 13) {
      this.onClickSearch(e);
    }
  },

  onChangeDepartment: function() {
    var departmentId = this.ui.department.val();

    // Show when department is ND
    toggleForm(this.ui.licenseType, departmentId === '1000');
    toggleForm(this.ui.includeInactiveLicense, departmentId === '1000');
    toggleForm(this.ui.secondaryPhoneNumber, departmentId === '1000' || departmentId === '2000');

    // Show shen department is SC
    toggleForm(this.ui.secLicenseType, departmentId === '3000');
    toggleForm(this.ui.applicantLegalName, departmentId === '3000');
    toggleForm(this.ui.crdNumber, departmentId === '3000');

    // Show when department is CS
    toggleForm(this.ui.streetAddress, departmentId === '4000');
    toggleForm(this.ui.city, departmentId === '4000');

    //Show when department is not SC
    toggleForm(this.ui.entityName, departmentId !== '3000');

    // Show when department is ND or DP or SC
    toggleForm(
      this.ui.primaryPhoneNumber,
      departmentId === '1000' || departmentId === '2000' || departmentId === '4000'
    );

    // NonDep
    if (departmentId === '1000') {
      this.searchResultsGrid.viewGrid.columns.add(this.licensesColModel);
      this.searchResultsGrid.viewGrid.columns.add(this.nmlsColModel);
    } else {
      this.searchResultsGrid.viewGrid.columns.remove(this.licensesColModel);
      this.searchResultsGrid.viewGrid.columns.remove(this.nmlsColModel);
    }

    // Sec
    if (departmentId === '3000') {
      this.entityNameColModel.set('label', 'Entity Name');
      this.searchResultsGrid.viewGrid.columns.remove(this.faxColModel);
      this.searchResultsGrid.viewGrid.columns.remove(this.phoneColModel);
      this.searchResultsGrid.viewGrid.columns.remove(this.addressColModel);
      this.searchResultsGrid.viewGrid.columns.add(this.secLicensesColModel);
      this.searchResultsGrid.viewGrid.columns.add(this.crdNumberColModel);
    } else {
      this.entityNameColModel.set('label', 'Entity Name');
      this.searchResultsGrid.viewGrid.columns.add(this.faxColModel);
      this.searchResultsGrid.viewGrid.columns.add(this.phoneColModel);
      this.searchResultsGrid.viewGrid.columns.add(this.addressColModel);
      this.searchResultsGrid.viewGrid.columns.remove(this.secLicensesColModel);
      this.searchResultsGrid.viewGrid.columns.remove(this.crdNumberColModel);
    }

    // Consumer Service
    if (departmentId === '4000') {
      this.searchResultsGrid.viewGrid.columns.add(this.licNumberColModel);
    } else {
      this.searchResultsGrid.viewGrid.columns.remove(this.licNumberColModel);
    }

    // ND and DP
    if (departmentId === '1000' || departmentId === '2000') {
      this.searchResultsGrid.viewGrid.columns.add(this.secondaryPhoneColModel);
    } else {
      this.searchResultsGrid.viewGrid.columns.remove(this.secondaryPhoneColModel);
    }

    this.searchResultsGrid.collection.fullCollection.reset();
  },

  formatDropdown: function(options, fieldName) {
    return _.map(options, function(option) {
      return { value: option.id, text: option[fieldName] };
    });
  },

  onClickSearch: function() {
    var data = Syphon.serialize(this, {
      inputReaders: Syphon.SearchInputReaderSet
    });

    if (data.departmentId === '1000') {
      this.searchNonDep(data);
    } else if (data.departmentId === '2000') {
      this.searchDep(data);
    } else if (data.departmentId === '3000') {
      this.searchSec(data);
    } else if (data.departmentId === '4000') {
      this.searchConSer(data);
    }
  },

  onClickClearFilters: function() {
    this.ui.formInputs.val('');
  },

  searchNonDep: function(data) {
    var self = this;

    // If no inputs were given, do not perform a search.
    if (
      !data.entityName &&
      !data.dba &&
      !data.licenseTypeId &&
      !data.primaryPhoneNumber &&
      !data.secondaryPhoneNumber
    ) {
      return;
    }
    this.searchSummaryView.reset();

    var laddaContext = Ladda.create(this.ui.search[0]);
    laddaContext.start();
    var body = bodybuilder();

    /**
     * https://github.com/danpaz/bodybuilder/issues/186
     * How to build a nested query with inner_hits using bodybuilder
     * */
    if (data.entityName) {
      body.query('bool', function(b) {
        b.orQuery('match', 'entityName', {
          query: data.entityName,
          fuzziness: 'AUTO',
          analyzer: 'standard'
        });
        b.orQuery('wildcard', 'entityName', data.entityName.toLowerCase() + '*');
        b.orQuery('match', 'entityName.phonetic', data.entityName);
        return b;
      });
    }

    if (data.dba) {
      body.query('bool', function(nb) {
        nb.orQuery('match', 'dbas', {
          query: data.dba,
          fuzziness: 'AUTO',
          analyzer: 'standard'
        });
        nb.orQuery('wildcard', 'dbas', data.dba.toLowerCase() + '*');
        nb.orQuery('match', 'dbas.phonetic', data.dba);
        return nb;
      });
    }

    if (data.licenseTypeId) {
      body.query('nested', { path: 'licenses', score_mode: 'none' }, function(nb) {
        nb.query('term', 'licenses.licenseTypeId', data.licenseTypeId);
        return nb;
      });
    }

    if (data.primaryPhoneNumber) {
      body.query('wildcard', 'phoneNumber', data.primaryPhoneNumber.replace(/\D/g, '') + '*');
    }

    if (data.secondaryPhoneNumber) {
      body.query('wildcard', 'secondaryPhoneNumber', data.secondaryPhoneNumber.replace(/\D/g, '') + '*');
    }

    body.size(this.model.get('searchLimit'));
    // Keep for testing
    // console.log(JSON.stringify(bodyObj, null, 2));
    SearchNonDepEntities(body.build())
      .done(function(data) {
        _.each(data.hits, function(hit) {
          hit.email = hit.email || '--';
          hit.phone = hit.phoneNumber || '--';
          hit.secondaryPhone = hit.secondaryPhoneNumber || '--';
          hit.fax = hit.faxNumber || '--';
          hit.address = hit.ppobAddress || '--';
        });

        laddaContext.stop();
        self.searchResultsGrid.collection.fullCollection.reset(data.hits);
        self.searchSummaryView.display(data);
      })
      .fail(function(err) {
        laddaContext.stop();
      });
  },

  searchDep: function(data) {
    var self = this;

    // If no inputs were given, do not perform a search.
    if (!data.entityName && !data.dba && !data.primaryPhoneNumber && !data.secondaryPhoneNumber) {
      return;
    }
    this.searchSummaryView.reset();

    var laddaContext = Ladda.create(this.ui.search[0]);
    laddaContext.start();
    var body = bodybuilder();

    /**
     * https://github.com/danpaz/bodybuilder/issues/186
     * How to build a nested query with inner_hits using bodybuilder
     * */
    if (data.entityName) {
      body.query('bool', function(b) {
        b.orQuery('match', 'entityName', {
          query: data.entityName,
          fuzziness: 'AUTO',
          analyzer: 'standard'
        });
        b.orQuery('wildcard', 'entityName', data.entityName.toLowerCase() + '*');
        b.orQuery('match', 'entityName.phonetic', data.entityName);
        return b;
      });
    }

    if (data.dba) {
      body.query('nested', 'path', 'dbas', function(nb) {
        nb.query('bool', 'minimum_should_match', 1, function(bb) {
          bb.orQuery('match', 'dbas.dba', {
            query: data.dba,
            fuzziness: 'AUTO',
            analyzer: 'standard'
          });
          bb.orQuery('wildcard', 'dbas.dba', data.dba.toLowerCase() + '*');
          bb.orQuery('match', 'dbas.dba.phonetic', data.dba);
          return bb;
        });
        return nb;
      });
    }

    if (data.primaryPhoneNumber) {
      body.query('wildcard', 'ppobPhone', data.primaryPhoneNumber.replace(/\D/g, '') + '*');
    }

    if (data.secondaryPhoneNumber) {
      body.query('wildcard', 'mainPhoneNumber', data.secondaryPhoneNumber.replace(/\D/g, '') + '*');
    }

    body.size(this.model.get('searchLimit'));
    // Keep for testing
    // console.log(JSON.stringify(bodyObj, null, 2));
    SearchDepEntities(body.build(), data, {})
      .done(function(data) {
        data.hits = _.map(data.hits, function(hit) {
          return {
            entityId: hit.entityId,
            entityName: hit.entityName,
            dbas: _.map(hit.dbas, function(dba) {
              return {
                value: dba
              };
            }),
            email: '--',
            phone: hit.ppobPhone || '--',
            secondaryPhone: hit.mainPhoneNumber || '--',
            fax: hit.ppobFax || '--',
            address: hit.ppobAddress || '--'
          };
        });

        laddaContext.stop();

        self.searchResultsGrid.collection.fullCollection.reset(data.hits);
        self.searchSummaryView.display(data);
      })
      .fail(function(err) {
        laddaContext.stop();
      });
  },

  searchSec: function(data) {
    var self = this;

    // If no inputs were given, do not perform a search.
    if (!data.applicantLegalName && !data.dba && !data.crdNumber && !data.secLicenseTypeId) {
      return;
    }
    this.searchSummaryView.reset();

    var laddaContext = Ladda.create(this.ui.search[0]);
    laddaContext.start();
    var body = bodybuilder();

    /**
     * https://github.com/danpaz/bodybuilder/issues/186
     * How to build a nested query with inner_hits using bodybuilder
     * */
    if (data.applicantLegalName) {
      body.query('bool', function(b) {
        b.orQuery('match', 'applicantLegalName', {
          query: data.applicantLegalName,
          fuzziness: 'AUTO',
          analyzer: 'standard'
        });
        b.orQuery('wildcard', 'applicantLegalName', data.applicantLegalName.toLowerCase() + '*');
        b.orQuery('match', 'applicantLegalName.phonetic', data.applicantLegalName);
        return b;
      });
    }

    if (data.dba) {
      body.query('bool', function(b) {
        b.orQuery('match', 'dbaName', {
          query: data.dba,
          fuzziness: 'AUTO',
          analyzer: 'standard'
        });
        b.orQuery('wildcard', 'dbaName', data.dba.toLowerCase() + '*');
        b.orQuery('match', 'dbaName.phonetic', data.dba);
        body.orQuery('nested', 'path', 'otherDBAs', function(nb) {
          nb.query('bool', 'minimum_should_match', 1, function(bb) {
            bb.orQuery('match', 'otherDBAs.dba', {
              query: data.dba,
              fuzziness: 'AUTO',
              analyzer: 'standard'
            });
            bb.orQuery('wildcard', 'otherDBAs.dba', data.dba.toLowerCase() + '*');
            bb.orQuery('match', 'otherDBAs.dba.phonetic', data.dba);
            return bb;
          });
          return nb;
        });
        return b;
      });
    }

    if (data.crdNumber) {
      body.query('wildcard', 'crdNumber', data.crdNumber + '*');
    }

    if (data.secLicenseTypeId) {
      body.query('nested', { path: 'licenses', score_mode: 'none' }, function(nb) {
        nb.query('term', 'licenses.typeId', data.secLicenseTypeId);
        return nb;
      });
    }

    body.size(this.model.get('searchLimit'));
    // Keep for testing
    // console.log(JSON.stringify(bodyObj, null, 2));
    SearchSecOrganizations(body.build())
      .done(function(data) {
        _.each(data.hits, function(hit) {
          hit.email = hit.email || '--';
          hit.address = hit.ppobAddress || '--';
          hit.crdNumber = hit.crdNumber || '--';
          hit.entityName = hit.applicantLegalName;
          hit.dbas = _.map(hit.dbas, function(dba) {
            return {
              value: dba
            };
          });
          hit.secLicensesList = _.map(hit.licenses, function(license) {
            var licenseType = _.find(self.model.get('secLicenseTypes'), function(licenseType) {
              return licenseType.id == license.typeId;
            });
            var licenseStatus = _.find(self.model.get('secLicenseStatuses'), function(licenseStatus) {
              return licenseStatus.id == license.statusId;
            });
            return {
              label: licenseType ? licenseType.type : '',
              value: licenseStatus ? licenseStatus.status : ''
            };
          });
        });

        laddaContext.stop();
        self.searchResultsGrid.collection.fullCollection.reset(data.hits);
        self.searchSummaryView.display(data);
      })
      .fail(function(err) {
        laddaContext.stop();
      });
  },

  searchConSer: function(data) {
    var self = this;

    // If no inputs were given, do not perform a search.
    if (!data.addressLine1 && !data.city && !data.entityName && !data.dba && !data.primaryPhoneNumber) {
      return;
    }
    this.searchSummaryView.reset();

    var laddaContext = Ladda.create(this.ui.search[0]);
    laddaContext.start();
    var body = bodybuilder();

    /**
     * https://github.com/danpaz/bodybuilder/issues/186
     * How to build a nested query with inner_hits using bodybuilder
     * */
    if (data.entityName) {
      body.query('bool', function(b) {
        b.orQuery('match', 'entityName', {
          query: data.entityName,
          fuzziness: 'AUTO',
          analyzer: 'standard'
        });
        b.orQuery('wildcard', 'entityName', data.entityName.toLowerCase() + '*');
        b.orQuery('match', 'entityName.phonetic', data.entityName);
        return b;
      });
    }

    if (data.dba) {
      body.query('bool', function(b) {
        b.orQuery('match', 'dba', {
          query: data.dba,
          fuzziness: 'AUTO',
          analyzer: 'standard'
        });
        b.orQuery('wildcard', 'dba', data.dba.toLowerCase() + '*');
        b.orQuery('match', 'dba.phonetic', data.dba);
        return b;
      });
    }

    if (data.addressLine1) {
      body.query('bool', function(b) {
        b.orQuery('match', 'address.addressLine1', {
          query: data.addressLine1,
          fuzziness: 'AUTO'
        });
        b.orQuery('wildcard', 'address.addressLine1', data.addressLine1.toLowerCase() + '*');
        return b;
      });
    }

    if (data.city) {
      body.query('bool', function(b) {
        b.orQuery('match', 'address.city', {
          query: data.city,
          fuzziness: 'AUTO'
        });
        b.orQuery('wildcard', 'address.city', data.city.toLowerCase() + '*');
        return b;
      });
    }

    if (data.primaryPhoneNumber) {
      body.query('bool', function(b) {
        return b
          .orQuery('wildcard', 'phone', data.primaryPhoneNumber.toLowerCase() + '*')
          .orQuery('wildcard', 'phone', data.primaryPhoneNumber.replace(/\D/g, ''));
      });
    }

    body.size(this.model.get('searchLimit'));
    // Keep for testing
    // console.log(JSON.stringify(bodyObj, null, 2));
    SearchConSerEntities(body.build())
      .done(function(data) {
        laddaContext.stop();

        _.each(data.hits, function(hit) {
          hit.dbas = hit.dba ? [{ value: hit.dba }] : [];
        });
        self.searchResultsGrid.collection.fullCollection.reset(data.hits);
        self.searchSummaryView.display(data);
      })
      .fail(function(err) {
        laddaContext.stop();
      });
  }
});
