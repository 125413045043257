/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var moment = require('moment');

var cleanUpResponse = require('common/util/cleanUpResponse');

module.exports = function(complianceId) {
  var defer = $.Deferred();

  $.soap({
    body: {
      GetCompliancePaymentsByCompliance: {
        $xmlns: 'http://dobs.pa.gov/sc/compliance/bpm/1.0',
        ComplianceID: complianceId
      }
    },
    blueprintArrays: ['GetCompliancePaymentsByComplianceResponse.Payments.SC_CompliancePayment'],
    blueprint: {
      complianceId: '$..ID',
      adminAssessments: '$..AdminAssessments',
      investigativeCosts: '$..InvestigativeCosts',
      legalCosts: '$..LegalCosts',
      civilPenalty: '$..CivilPenalty',
      restitutionAgreedByOrder: '$..RestitutionAgreedByOrder',
      globalSettlement: '$..GlobalSettlement',
      payments: [
        '$..SC_CompliancePayment',
        {
          compliancePaymentId: '$..ID',
          complianceId: '$..ComplianceID',
          paymentDate: '$..PaymentDate',
          amount: '$..Amount',
          adminAssessmentsApplied: '$..AdminAssessmentsApplied',
          investigativeCostsApplied: '$..InvestigativeCostsApplied',
          legalCostsApplied: '$..LegalCostsApplied',
          civilPenaltyApplied: '$..CivilPenaltyApplied',
          globalSettlementApplied: '$..GlobalSettlementApplied',
          checkNumber: '$..CheckNumber',
          transmittalNumber: '$..TransmittalNumber'
        }
      ]
    }
  })
    .done(function(response) {
      cleanUpResponse(response);
      _.each(response.payments, function(payment) {
        if (payment.paymentDate) {
          payment.paymentDate = moment(payment.paymentDate, 'YYYY-MM-DD');
        }
        payment.checkNumberDisplay = payment.checkNumber ? payment.checkNumber : '--';
        payment.transmittalNumberDisplay = payment.transmittalNumber ? payment.transmittalNumber : '--';
      });
      defer.resolve(response);
    })
    .fail(function(err) {
      defer.reject(err);
    });

  return defer.promise();
};
