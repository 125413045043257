/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Marionette = require('backbone.marionette');
var Syphon = require('backbone.syphon');
var Ladda = require('ladda');

var modelGet = require('common/util/modelGet');
var AddressView = require('common/views/AddressView');

var SubmitIndividualAddress = require('../services/SubmitIndividualAddress');

var tmplSecIndividualAddressModalView = require('../templates/SecIndividualAddressModalView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplSecIndividualAddressModalView,

  className: 'sec-individual-address-modal-view',

  ui: {
    individualAddressForm: '.individual-address-form',
    submit: '.submit',
    address: '.address',
    branchNumberContainer: '.branch-number-container',
    addressTypeDropdown: 'select[name="typeId"]'
  },

  regions: {
    address: '@ui.address'
  },

  events: {
    'click @ui.submit': 'onClickSubmit',
    'change @ui.addressTypeDropdown': 'onChangeAddressType'
  },

  templateHelpers: function() {
    return {
      addressTypes: this.options.addressTypes
    };
  },

  onBeforeShow: function() {
    var indiviudalAddress = this.model.get('address') || {};
    indiviudalAddress.typeId = this.model.get('typeId');

    this.showChildView(
      'address',
      new AddressView({
        horizontal: false,
        requiredFields: {
          addressLine1: true
        },
        startingCountry: modelGet(this.model, 'address.country'),
        startingPostalCode: modelGet(this.model, 'address.postalCode'),
        startingCounty: modelGet(this.model, 'address.county')
      })
    );

    Syphon.deserialize(this, indiviudalAddress);
  },

  onClickSubmit: function(e) {
    e.preventDefault();
    var self = this;
    this.ui.individualAddressForm
      .parsley()
      .whenValidate()
      .done(function() {
        var l = Ladda.create(self.ui.submit[0]);
        l.start();
        var formData = Syphon.serialize(self);
        var individualAddressId = self.model.get('id');

        formData.individualId = self.options.individualId;
        formData.individualAddressId = individualAddressId;

        SubmitIndividualAddress(formData)
          .done(function(newAddress) {
            self.triggerMethod('modal:close', newAddress);
          })
          .fail(function() {
            l.stop();
          });
      });
  }
});
