var Handlebars = require("handlebars/dist/handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression;

  return "\n<div class=\"page-title clearfix\">\n  <h1 class=\"pull-left m-n p-n\">"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? depth0.task : depth0)) != null ? stack1.taskType : stack1)) != null ? stack1.description : stack1), depth0))
    + " - "
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.examPaymentDetails : depth0)) != null ? stack1.daysPastDue : stack1), depth0))
    + " Days Past Due</h1>\n   <div class=\"page-title-buttons-container\">\n    <div class=\"cs-link-container\"></div>\n  </div>\n</div>\n<div class=\"header-bar\"></div>\n<div class=\"breadcrumb-container\"></div>\n<div class=\"task-status\"></div>\n\n<div class=\"row\">\n  <form class=\"form-horizontal col-sm-12 col-md-10 col-md-offset-1 payment-past-due-task-form\">\n    <div class=\"form-group\">\n      <label class=\"control-label col-sm-2\">Link to Exam Dashboard</label>\n      <div class=\"col-sm-8\">\n        <a role=\"button\" class=\"btn btn-primary\" href=\"#nondep/dashboard/exam/"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.examDetails : depth0)) != null ? stack1.examId : stack1), depth0))
    + "\"\n          target=\"_blank\">Open Exam</a>\n      </div>\n    </div>\n    <div class=\"payment-details-container m-b-lg\"></div>\n    <div class=\"row\">\n      <div class=\"col-sm-8 col-sm-offset-2 task-instructions\">All correspondence whether via email, telephone call or\n        USPS will be documented. Any correspondence by telephone will be manually entered, complete with:\n        <ul class=\"unstyled\">\n          <li>Date &amp; time of telephone call</li>\n          <li>Staff member(s) who participated in the call</li>\n          <li>Who was spoken to</li>\n          <li>A synopsis of the conversation</li>\n        </ul>\n      </div>\n    </div>\n\n    <div class=\"form-group\">\n      <label class=\"control-label col-sm-2\">Billing Comments</label>\n      <div class=\"col-sm-8\">\n        <textarea class=\"form-control\" rows=\"10\" name=\"billingComments\" placeholder=\"Billing Comments\"></textarea>\n      </div>\n    </div>\n    <div class=\"row bottom-spacer\">\n      <div class=\"col-sm-8 col-sm-offset-2\">\n        <a href=\"#nondep/dashboard\" class=\"btn btn-default\" role=\"button\">Cancel</a>\n        <button class=\"btn btn-primary submit ladda-button\" data-style=\"expand-right\" type=\"button\">\n          <span class=\"ladda-label\">Submit</span>\n        </button>\n        <div class=\"task-actions\"></div>\n      </div>\n    </div>\n  </form>\n</div>v";
},"useData":true});