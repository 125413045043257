var Handlebars = require("handlebars/dist/handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "Sub-";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression;

  return "\n<div class=\"page-title clearfix\">\n  <h1 class=\"m-n p-n\">"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.audit : depth0)) != null ? stack1.entityName : stack1), depth0))
    + "</h1>\n  <div class=\"page-title-buttons-container\">\n    <div class=\"cs-link-container\"></div>\n    <div class=\"dropdown-container\"></div>\n  </div>\n</div>\n<div class=\"page-subtitle\">\n  <h2>Collective Investment "
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? depth0.audit : depth0)) != null ? stack1.subFundId : stack1),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "Fund Audit</h2>\n  <div class=\"page-subtitle-details\">"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.audit : depth0)) != null ? stack1.auditDate : stack1), depth0))
    + "</div>\n</div>\n<div class=\"breadcrumb-container\"></div>\n<div class=\"audit-wrapper\"></div>";
},"useData":true});