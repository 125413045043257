/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Marionette = require('backbone.marionette');
var Syphon = require('backbone.syphon');
var Ladda = require('ladda');

var FileUploadView = require('common/views/FileUploadView');
var makeUploadCollection = require('common/util/makeUploadCollection');
var momentUtil = require('common/util/momentUtil');

var SubmitEntityDBA = require('../services/SubmitEntityDBA.js');

var tmplNonDepEntitySectionAddDBAModalView = require('../templates/NonDepEntitySectionAddDBAModalView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplNonDepEntitySectionAddDBAModalView,

  className: 'Non-Dep-Entity-Section-Add-DBA-Modal-View',

  ui: {
    fictitiousNameRegistrationContainer: '.fictitious-name-registration-container',
    datePickerFields: '.date-picker',
    submitButton: '.btn-new-entity-dba',
    entityDBAForm: '.entity-dba-form'
  },

  regions: {
    fictitiousNameRegistrationContainer: '@ui.fictitiousNameRegistrationContainer'
  },

  events: {
    'click @ui.submitButton': 'onClickSubmit'
  },

  onBeforeShow: function() {
    Syphon.deserialize(this, this.model.toJSON());
    this.ui.datePickerFields.datepicker({});

    this.fictitiousNameRegistration = new FileUploadView({
      isRequired: true,
      isReadOnly: this.options.isReadOnly,
      collection: makeUploadCollection(this.model.get('fictitiousNameDocument')),
      metadata: {
        documentTypeId: 10017
      }
    });
    this.showChildView('fictitiousNameRegistrationContainer', this.fictitiousNameRegistration);
  },

  validate: function() {
    this.ui.entityDBAForm.parsley().reset();
    var validationPromises = [
      this.ui.entityDBAForm.parsley().whenValidate(),
      this.fictitiousNameRegistration.validate()
    ];
    return $.when.apply($, validationPromises);
  },

  getFormData: function() {
    var formData = Syphon.serialize(this);
    var fictitiousNameRegistrationFileId = this.fictitiousNameRegistration.getFirstFileId();
    formData.fictitiousDocumentId = fictitiousNameRegistrationFileId;
    formData.registrationDate = momentUtil.formatStr(formData.registrationDate, momentUtil.defaultOutboundDateConfig);

    return formData;
  },

  onClickSubmit: function(e) {
    e.preventDefault();
    var self = this;

    var entityId = self.model.get('entityId');
    self.validate().done(function() {
      var formData = self.getFormData();
      formData.entityId = entityId;

      var laddaContext = Ladda.create(self.ui.submitButton.get(0));
      laddaContext.start();
      SubmitEntityDBA(formData).done(function() {
        self.triggerMethod('modal:close');
      });
    });
  }
});
