/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and reConditionss
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Marionette = require('backbone.marionette');

var GridView = require('psa-marionette/views/GridView');
var PageableCollection = require('psa-backbone/models/PageableCollection');

var ParagraphCell = require('common/backgrid/ParagraphCell');
var MomentCell = require('common/backgrid/MomentCell');
var ButtonLinkCell = require('common/backgrid/ButtonLinkCell');

var tmplDepFilingConditionsView = require('../templates/DepFilingConditionsView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplDepFilingConditionsView,

  className: 'dep-filing-conditions-view container-fluid',

  ui: { conditionList: '.conditions-list-container' },

  regions: { conditionList: '@ui.conditionList' },

  onBeforeShow: function() {
    this.conditionsGrid = new GridView({
      columns: [
        {
          name: 'type',
          label: 'Type',
          cell: 'string',
          headerCell: 'custom',
          width: 10,
          editable: false
        },
        {
          name: 'frequency',
          label: 'Frequency',
          cell: 'string',
          headerCell: 'custom',
          width: 8,
          editable: false
        },
        {
          name: 'addressActivity',
          label: 'Address/Activity',
          cell: ParagraphCell,
          headerCell: 'custom',
          width: 15,
          editable: false
        },
        {
          name: 'dueDate',
          label: 'Due Date',
          cell: MomentCell.MomentCell,
          formatter: MomentCell.MomentDateFormatter.extend({
            emptyValue: '--'
          }),
          sortValue: MomentCell.MomentSortValue,
          headerCell: 'custom',
          width: 10,
          editable: false
        },
        {
          name: 'status',
          label: 'Status',
          cell: 'string',
          headerCell: 'custom',
          width: 6,
          editable: false
        },
        {
          name: 'receivedDate',
          label: 'Received Date',
          cell: MomentCell.MomentCell,
          formatter: MomentCell.MomentDateFormatter.extend({
            emptyValue: '--'
          }),
          sortValue: MomentCell.MomentSortValue,
          headerCell: 'custom',
          width: 10,
          editable: false
        },
        {
          name: 'comments',
          label: 'Comments',
          cell: 'string',
          headerCell: 'custom',
          width: 16,
          editable: false
        },
        {
          name: '',
          label: '',
          cell: ButtonLinkCell.extend({
            name: 'View',
            buttonClass: 'btn-primary btn-sm',
            href: function(model) {
              var entityId = model.get('entityId');
              var filingId = model.get('filingId');
              var filingConditionId = model.get('filingConditionId');
              return (
                '#dep/entity/' + entityId + '/filing/' + filingId + '/condition/' + filingConditionId + '/dashboard'
              );
            }
          }),
          headerCell: 'custom',
          width: 5,
          editable: false,
          sortable: false
        }
      ],
      collection: new PageableCollection(this.model.get('conditions'), { pageSize: 25 }),
      emptyText: 'No Conditions'
    });
    this.showChildView('conditionList', this.conditionsGrid);
  }
});
