/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Backbone = require('backbone');
var Radio = require('backbone.radio');
var NProgress = require('nprogress');
var _get = require('lodash.get');

var GetExamDetailsForEntity = require('../../../common/services/GetExamDetailsForEntity');

var EntityPrepareExamMaterialsTaskView = require('./EntityPrepareExamMaterialsTaskView');

module.exports = {
  event: 'build:nd:entity-prepare-exam-materials',

  channel: 'task',

  builder: function(task) {
    var defer = $.Deferred();

    var examId = _get(task, 'taskData.ND.examId');
    var taskTypeName = _get(task, 'taskType.name');
    var isAssigned = task.state === 'ASSIGNED';

    if (!examId || !taskTypeName) {
      defer.reject('Task missing required data');
      return defer.promise();
    }

    GetExamDetailsForEntity(examId)
      .done(function(exam) {
        NProgress.set(0.5);
        var examSetupCode;
        var taskTypeCode;
        if (taskTypeName === 'ND_ENTITY_PREPARE_LOAN_LOGS') {
          examSetupCode = exam.loanLogCode;
          taskTypeCode = 'LOAN_LOGS';
        } else if (taskTypeName === 'ND_ENTITY_PREPARE_EXAM_MATERIALS') {
          examSetupCode = exam.examMaterialsCode;
          taskTypeCode = 'MATERIALS';
        }
        var ExamQuestionsPromise = Radio.channel('nd:exam').request('build:exam-questions', {
          examId: examId,
          examSetupCode: examSetupCode,
          mode: 'entity-answers'
        });
        ExamQuestionsPromise.done(function(examQuestionsView) {
          NProgress.set(0.85);
          defer.resolve(
            new EntityPrepareExamMaterialsTaskView({
              isReadOnly: !isAssigned,
              examQuestionsView: examQuestionsView,
              model: new Backbone.Model({
                task: task,
                taskId: task.taskId,
                exam: exam,
                examId: exam.examId,
                examSetupCode: examSetupCode,
                taskTypeCode: taskTypeCode
              })
            })
          );
        }).fail(function(err) {
          defer.reject(err);
        });
      })
      .fail(function(err) {
        defer.reject(err);
      });

    return defer.promise();
  }
};
