/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Backbone = require('backbone');
var Marionette = require('backbone.marionette');

var GridView = require('psa-marionette/views/GridView');
var PageableCollection = require('psa-backbone/models/PageableCollection');

var MomentCell = require('common/backgrid/MomentCell');
var ButtonLinkCell = require('common/backgrid/ButtonLinkCell');
var AddressCell = require('common/backgrid/AddressCell');
var BreadcrumbView = require('common/views/BreadcrumbView');
var NMLSLabelView = require('../../common/views/NMLSLabelView');

var ContentServerLinkView = require('common/views/ContentServerLinkView');

var tmplNonDepIndividualHistoryDashboardView = require('../templates/NonDepIndividualHistoryDashboardView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplNonDepIndividualHistoryDashboardView,

  className: 'non-dep-individual-history-dashboard-view container-fluid',

  ui: {
    nmlsLabelContainer: '.nmls-label-container',
    csLinkContainer: '.cs-link-container',
    individualList: '.individual-list',
    breadcrumb: '.breadcrumb-container'
  },

  regions: {
    nmlsLabelContainer: '@ui.nmlsLabelContainer',
    csLinkContainer: '@ui.csLinkContainer',
    individualActionDropdown: '@ui.individualActionDropdown',
    individualList: '@ui.individualList',
    breadcrumb: '@ui.breadcrumb'
  },

  onBeforeShow: function() {
    var individual = this.model.get('individual'),
      individualType = individual.individualType,
      individualId = this.model.get('individualId'),
      entityId = this.model.get('entityId'),
      entityName = this.model.get('entityName'),
      licenseId = this.model.get('licenseId'),
      licenseNumber = this.model.get('licenseNumber'),
      licenseType = this.model.get('licenseType'),
      breadcrumbs;

    if (individualType === 'Entity Officer') {
      breadcrumbs = [
        {
          link: '#nondep/dashboard',
          text: 'Home'
        },
        {
          text: entityName,
          link: '#nondep/entity/' + entityId + '/dashboard'
        },
        {
          text: individualType + ' - ' + individual.firstName + ' ' + individual.lastName,
          link: '#nondep/individual/' + individualId + '/dashboard'
        },
        {
          text: 'History'
        }
      ];
    } else if (individualType === 'Office Manager') {
      breadcrumbs = [
        {
          link: '#nondep/dashboard',
          text: 'Home'
        },
        {
          text: entityName,
          link: '#nondep/entity/' + entityId + '/dashboard'
        },
        {
          text: 'License #' + licenseNumber + ' - ' + licenseType,
          link: '#nondep/entity/' + entityId + '/license/' + licenseId
        },
        {
          text: individualType + ' - ' + individual.firstName + ' ' + individual.lastName,
          link: '#nondep/individual/' + individualId + '/dashboard'
        },
        {
          text: 'History'
        }
      ];
    } else {
      breadcrumbs = [
        {
          link: '#nondep/dashboard',
          text: 'Home'
        },
        {
          text: individualType + ' - ' + individual.firstName + ' ' + individual.lastName,
          link: '#nondep/individual/' + individualId + '/dashboard'
        },
        {
          text: 'History'
        }
      ];
    }

    this.showChildView(
      'breadcrumb',
      new BreadcrumbView({
        breadcrumbs: breadcrumbs
      })
    );

    if (individual.isNMLSIndividual) {
      this.showChildView(
        'nmlsLabelContainer',
        new NMLSLabelView({
          model: new Backbone.Model({
            objectName: 'individual'
          })
        })
      );
    }

    this.showChildView(
      'csLinkContainer',
      new ContentServerLinkView({
        model: new Backbone.Model({
          buttonText: 'Open Individual Folder',
          contentServerId: individual.CSFolderId
        })
      })
    );

    this.individualHistory = new GridView({
      columns: [
        {
          name: 'createdDate',
          label: 'Date Changed',
          cell: MomentCell.MomentCell,
          formatter: MomentCell.MomentDateTimeFormatter.extend({
            emptyValue: '--'
          }),
          sortValue: MomentCell.MomentSortValue,
          headerCell: 'custom',
          width: 15,
          editable: false
        },
        {
          name: 'createdBy',
          label: 'Changed By',
          cell: 'string',
          headerCell: 'custom',
          width: 10,
          editable: false
        },
        {
          name: 'firstName',
          label: 'First Name',
          cell: 'string',
          headerCell: 'custom',
          width: 15,
          editable: false
        },
        {
          name: 'lastName',
          label: 'Last Name',
          cell: 'string',
          headerCell: 'custom',
          width: 15,
          editable: false
        },
        {
          name: 'homePhoneNumber',
          label: 'Phone',
          cell: 'string',
          headerCell: 'custom',
          width: 10,
          editable: false
        },
        {
          name: 'email',
          label: 'Email',
          cell: 'string',
          headerCell: 'custom',
          width: 10,
          editable: false
        },
        {
          name: 'address',
          label: 'Address',
          cell: AddressCell,
          headerCell: 'custom',
          width: 15,
          editable: false,
          sortable: false
        },
        {
          name: '',
          label: '',
          cell: ButtonLinkCell.extend({
            name: 'Open',
            buttonClass: 'btn-primary btn-sm',
            href: function(model) {
              var historyId = model.get('id');
              return '#nondep/individual/' + individualId + '/history/' + historyId;
            }
          }),
          headerCell: 'custom',
          width: 10,
          editable: false,
          sortable: false
        }
      ],
      emptyText: 'No Individual History',
      collection: new PageableCollection(this.model.get('individualHistory'), { pageSize: 10 })
    });

    this.showChildView('individualList', this.individualHistory);
  }
});
