var Handlebars = require("handlebars/dist/handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "          Yes\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = (helpers.ifCond || (depth0 && depth0.ifCond) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.isCustody : depth0),"===","0",{"name":"ifCond","hash":{},"fn":container.program(4, data, 0),"inverse":container.program(6, data, 0),"data":data})) != null ? stack1 : "");
},"4":function(container,depth0,helpers,partials,data) {
    return "          No\n";
},"6":function(container,depth0,helpers,partials,data) {
    return "          --\n          ";
},"8":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "    <div class=\"form-group\">\n      <label class=\"control-label col-sm-3 col-md-2\">Custody Type</label>\n"
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.licenseCustodyList : depth0),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.program(12, data, 0),"data":data})) != null ? stack1 : "")
    + "\n    </div>\n";
},"9":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "      <div class=\"form-control-static individual-type-list col-sm-9 col-md-8\">\n        <ul class=\"list-unstyled\">"
    + ((stack1 = helpers.each.call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.licenseCustodyList : depth0),{"name":"each","hash":{},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "</ul>\n      </div>\n      ";
},"10":function(container,depth0,helpers,partials,data) {
    return "<li>"
    + container.escapeExpression(container.lambda(depth0, depth0))
    + "</li>";
},"12":function(container,depth0,helpers,partials,data) {
    return "<div class=\"form-control-static col-sm-9 col-md-8\">--</div>";
},"14":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = (helpers.ifCond || (depth0 && depth0.ifCond) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.isFinancialReportReq : depth0),"===","0",{"name":"ifCond","hash":{},"fn":container.program(4, data, 0),"inverse":container.program(6, data, 0),"data":data})) != null ? stack1 : "");
},"16":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = (helpers.ifCond || (depth0 && depth0.ifCond) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.isDiscretion : depth0),"===","0",{"name":"ifCond","hash":{},"fn":container.program(4, data, 0),"inverse":container.program(6, data, 0),"data":data})) != null ? stack1 : "");
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "  \n<div class=\"organization-license-form form-horizontal form-static m-t-lg\">\n  <div class=\"row\">\n    <div class=\"col-md-6 col-sm-12\">\n      <div class=\"form-group\">\n        <label class=\"control-label col-sm-3 col-md-4\">License Type</label>\n          <div class=\"form-control-static col-sm-9 col-md-8\">"
    + alias3((helpers["default"] || (depth0 && depth0["default"]) || alias2).call(alias1,(depth0 != null ? depth0.type : depth0),"--",{"name":"default","hash":{},"data":data}))
    + "</div>\n      </div>\n    </div>\n    <div class=\"col-md-6 col-sm-12\">\n      <div class=\"form-group\">\n        <label class=\"control-label col-sm-3 col-md-4\">Filing Examiner</label>\n        <div class=\"form-control-static col-sm-9 col-md-8\">"
    + alias3((helpers["default"] || (depth0 && depth0["default"]) || alias2).call(alias1,(depth0 != null ? depth0.filingExaminerDisplay : depth0),"--",{"name":"default","hash":{},"data":data}))
    + "</div>\n      </div>\n    </div>\n  </div>\n  <div class=\"row\">\n    <div class=\"col-md-6 col-sm-12\">\n      <div class=\"form-group\">\n        <label class=\"control-label col-sm-3 col-md-4\">License Status</label>\n        <div class=\"form-control-static col-sm-9 col-md-8\">"
    + alias3((helpers["default"] || (depth0 && depth0["default"]) || alias2).call(alias1,(depth0 != null ? depth0.status : depth0),"--",{"name":"default","hash":{},"data":data}))
    + "</div>\n      </div>\n    </div>\n    <div class=\"col-md-6 col-sm-12\">\n      <div class=\"form-group\">\n        <label class=\"control-label col-sm-3 col-md-4\">License Status Date</label>\n        <div class=\"form-control-static col-sm-9 col-md-8\">"
    + alias3((helpers["default"] || (depth0 && depth0["default"]) || alias2).call(alias1,(depth0 != null ? depth0.licenseStatusDate : depth0),"--",{"name":"default","hash":{},"data":data}))
    + "</div>\n      </div>\n    </div>\n  </div>\n  <div class=\"row\">\n    <div class=\"col-md-6 col-sm-12\">\n      <div class=\"form-group\">\n        <label class=\"control-label col-sm-3 col-md-4\">FINRA Date</label>\n        <div class=\"form-control-static col-sm-9 col-md-8\">"
    + alias3((helpers["default"] || (depth0 && depth0["default"]) || alias2).call(alias1,(depth0 != null ? depth0.FINRADate : depth0),"--",{"name":"default","hash":{},"data":data}))
    + "</div>\n      </div>\n    </div>\n    <div class=\"col-md-6 col-sm-12\">\n      <div class=\"form-group\">\n        <label class=\"control-label col-sm-3 col-md-4\">SEC Number</label>\n        <div class=\"form-control-static col-sm-9 col-md-8\">"
    + alias3((helpers["default"] || (depth0 && depth0["default"]) || alias2).call(alias1,(depth0 != null ? depth0.SECNumber : depth0),"--",{"name":"default","hash":{},"data":data}))
    + "</div>\n      </div>\n    </div>\n  </div>\n  <div class=\"row\">\n    <div class=\"col-md-6 col-sm-12\">\n      <div class=\"form-group\">\n        <label class=\"control-label col-sm-3 col-md-4\">SEC Registration Date</label>\n        <div class=\"form-control-static col-sm-9 col-md-8\">"
    + alias3((helpers["default"] || (depth0 && depth0["default"]) || alias2).call(alias1,(depth0 != null ? depth0.SECRegistrationDate : depth0),"--",{"name":"default","hash":{},"data":data}))
    + "</div>\n      </div>\n    </div>\n    <div class=\"col-md-6 col-sm-12\">\n      <div class=\"form-group\">\n        <label class=\"control-label col-sm-3 col-md-4\">Custody</label>\n        <div class=\"form-control-static col-sm-9 col-md-8\">\n"
    + ((stack1 = (helpers.ifCond || (depth0 && depth0.ifCond) || alias2).call(alias1,(depth0 != null ? depth0.isCustody : depth0),"===","1",{"name":"ifCond","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data})) != null ? stack1 : "")
    + "        </div>\n      </div>\n    </div>\n  </div>\n"
    + ((stack1 = (helpers.ifCond || (depth0 && depth0.ifCond) || alias2).call(alias1,(depth0 != null ? depth0.licenseTypeId : depth0),"===","2",{"name":"ifCond","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "  <div class=\"row\">\n    <div class=\"col-md-6 col-sm-12\">\n      <div class=\"form-group\">\n        <label class=\"control-label col-sm-3 col-md-4\">Fiscal Year End</label>\n        <div class=\"form-control-static col-sm-9 col-md-8\">"
    + alias3((helpers["default"] || (depth0 && depth0["default"]) || alias2).call(alias1,(depth0 != null ? depth0.fiscalYearEnd : depth0),"--",{"name":"default","hash":{},"data":data}))
    + "</div>\n      </div>\n    </div>\n    <div class=\"col-md-6 col-sm-12\">\n      <div class=\"form-group\">\n        <label class=\"control-label col-sm-3 col-md-4\">Financial Report Required</label>\n        <div class=\"form-control-static col-sm-9 col-md-8\">\n"
    + ((stack1 = (helpers.ifCond || (depth0 && depth0.ifCond) || alias2).call(alias1,(depth0 != null ? depth0.isFinancialReportReq : depth0),"===","1",{"name":"ifCond","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(14, data, 0),"data":data})) != null ? stack1 : "")
    + "        </div>\n      </div>\n    </div>\n  </div>\n  <div class=\"row\">\n    <div class=\"col-md-6 col-sm-12\">\n      <div class=\"form-group\">\n        <label class=\"control-label col-sm-3 col-md-4\">Discretion</label>\n        <div class=\"form-control-static col-sm-9 col-md-8\">\n"
    + ((stack1 = (helpers.ifCond || (depth0 && depth0.ifCond) || alias2).call(alias1,(depth0 != null ? depth0.isDiscretion : depth0),"===","1",{"name":"ifCond","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(16, data, 0),"data":data})) != null ? stack1 : "")
    + "        </div>\n      </div>\n    </div>\n  </div>\n</div>\n";
},"useData":true});