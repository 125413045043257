/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Backgrid = require('backgrid');

require('./IconBooleanCell.css');

module.exports = Backgrid.Cell.extend({
  className: 'icon-boolean-cell', // Add a custom class if needed

  render: function() {
    // Clear the cell first
    this.$el.empty();

    // Get the value of the property (assuming it's a boolean)
    var value = this.model.get(this.column.get('name'));

    // Conditionally render an icon if the value is true
    if (value === true) {
      var iconHtml = '<i class="' + this.iconClass + '" style="' + this.iconStyle + '"></i>'; // Use Font Awesome or any other icon
      this.$el.append(iconHtml);
    }

    // Return the cell for proper chaining
    return this;
  }
});
