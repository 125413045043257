/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2020] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var Syphon = require('backbone.syphon');
var Backbone = require('backbone');
var Marionette = require('backbone.marionette');

var SelectFieldView = require('common/views/SelectFieldView');
var modelGet = require('common/util/modelGet');
var toggleForm = require('common/util/toggleForm');

var tmplConSerComplaintSectionCodesView = require('../templates/ConSerComplaintSectionCodesView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplConSerComplaintSectionCodesView,

  className: 'con-ser-complaint-section-codes-view',

  ui: {
    codeForm: '.code-form',
    productType: '.product-type',
    code1dropdown: '.code-1-dropdown',
    code2dropdown: '.code-2-dropdown',
    oldProductType: '.old-product-type',
    test: 'input[oldProductType]',
    oldCode1: '.old-code-1',
    oldCode2: '.old-code-2',
    oldCode3: '.old-code-3',
    other1: '.other-code-1',
    other2: '.other-code-2',
    oldOther1: '.old-other-code-1',
    oldOther2: '.old-other-code-2',
    oldOther3: '.old-other-code-3',
    datepickerFields: '.date-picker'
  },

  regions: {
    code1dropdown: '@ui.code1dropdown',
    code2dropdown: '@ui.code2dropdown'
  },

  events: {
    'change @ui.code1dropdown': 'onChangeCode1',
    'change @ui.code2dropdown': 'onChangeCode2'
  },

  onBeforeShow: function() {
    this.code1dropdown = new SelectFieldView({
      isDisabled: false,
      isRequired: true,
      name: 'complaintCode1Id',
      collection: new Backbone.Collection(this.formatDropdown(modelGet(this.model, 'complaintCode1'), 'code1'))
    });
    this.showChildView('code1dropdown', this.code1dropdown);

    this.code2dropdown = new SelectFieldView({
      isDisabled: false,
      isRequired: true,
      name: 'complaintCode2Id',
      collection: new Backbone.Collection(this.formatDropdown(modelGet(this.model, 'complaintCode2'), 'code2'))
    });
    this.showChildView('code2dropdown', this.code2dropdown);

    this.deserialize();
    this.ui.datepickerFields.datepicker({});
  },

  getDropdownValue: function(type, field) {
    var typeId = this.getFormData()[field];
    var selectedObj = this.findCode(type, typeId);
    return selectedObj;
  },

  formatDropdown: function(options, fieldName) {
    return _.map(options, function(option) {
      return { value: option.id, text: option[fieldName] };
    });
  },

  findCode: function(type, typeId) {
    return (
      _.find(modelGet(this.model, type), function(code) {
        return code.id === typeId;
      }) || {}
    );
  },

  onChangeCode1: function() {
    var code1Obj = this.getDropdownValue('complaintCode1', 'complaintCode1Id');

    var code1IsOther = code1Obj.isOther === '1';
    toggleForm(this.ui.other1, code1IsOther);
  },

  onChangeCode2: function() {
    var code2Obj = this.getDropdownValue('complaintCode2', 'complaintCode2Id');

    var code2IsOther = code2Obj.isOther === '1';
    toggleForm(this.ui.other2, code2IsOther);
  },

  deserialize: function() {
    var complaint = this.model.get('complaint') || {};
    Syphon.deserialize(this, complaint);

    // Hide old complaint codes if none exist
    toggleForm(this.ui.oldProductType, !!complaint.oldProductType);
    toggleForm(this.ui.oldCode1, !!complaint.oldComplaintCode1);
    toggleForm(this.ui.oldCode2, !!complaint.oldComplaintCode2);
    toggleForm(this.ui.oldCode3, !!complaint.oldComplaintCode3);

    // Get code object using object ID
    // var productTypeObj = this.findCode('productTypes', complaint.productTypeId);
    var code1Obj = this.findCode('complaintCode1', complaint.complaintCode1Id);
    var code2Obj = this.findCode('complaintCode2', complaint.complaintCode2Id);

    //check is code is Other
    var code1IsOther = code1Obj.isOther === '1';
    var code2IsOther = code2Obj.isOther === '1';
    var oldCode1IsOther = !!complaint.oldComplaintCode1Other;
    var oldCode2IsOther = !!complaint.oldComplaintCode2Other;
    var oldCode3IsOther = !!complaint.oldComplaintCode3Other;

    // display/hide code other fields
    toggleForm(this.ui.other1, code1IsOther);
    toggleForm(this.ui.other2, code2IsOther);
    toggleForm(this.ui.oldOther1, oldCode1IsOther);
    toggleForm(this.ui.oldOther2, oldCode2IsOther);
    toggleForm(this.ui.oldOther3, oldCode3IsOther);

    // set code dropdowns values
    this.code1dropdown.setValue(code1Obj.id);
    this.code2dropdown.setValue(code2Obj.id);
  },

  getFormData: function() {
    return Syphon.serialize(this);
  },

  validate: function() {
    return this.ui.codeForm.parsley().whenValidate();
  }
});
