/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var _get = require('lodash.get');
var moment = require('moment');

var getUserIdFromDN = require('common/util/getUserIdFromDn');
var cleanUpResponse = require('common/util/cleanUpResponse');

module.exports = function() {
  var deferred = $.Deferred();

  $.soap({
    body: {
      GetAvailableTasks: {
        $xmlns: 'http://dobs.pa.gov/sc/task/bpm/1.0'
      }
    },
    blueprintArrays: [
      'GetAvailableTasksResponse.Tasks.NOTF_TASK_INSTANCE',
      'GetAvailableTasksResponse.TaskTypes.CM_LU_TaskType',
      'GetAvailableTasksResponse.Organizations.SC_Organization',
      'GetAvailableTasksResponse.Applications.SC_Application',
      'GetAvailableTasksResponse.Exams.SC_Exam',
      'GetAvailableTasksResponse.Correspondences.SC_Correspondence',
      'GetAvailableTasksResponse.FinancialStatements.SC_FinancialStatement'
    ],
    blueprint: {
      tasks: [
        '$..NOTF_TASK_INSTANCE',
        {
          taskId: '$..TaskId',
          deliveryDate: '$..DeliveryDate',
          claimedDate: '$..ClaimedDate',
          assignedRole: '$..Target.__text',
          taskTypeName: '$..TaskTypeName',
          organizationId: '$..SCOrganizationID',
          applicationId: '$..SCApplicationID',
          examId: '$..SCExamID',
          correspondenceId: '$..SCCorrespondenceID',
          financialStatementId: '$..SCFinancialStatementID'
        }
      ],
      taskTypes: [
        '$..CM_LU_TaskType',
        {
          id: '$.ID',
          name: '$.Name',
          description: '$.Description'
        }
      ],
      organizations: [
        '$..SC_Organization',
        {
          id: '$..ID',
          applicantLegalName: '$..ApplicantLegalName',
          crdNumber: '$..CRDNumber'
        }
      ],
      applications: [
        '$..SC_Application',
        {
          id: '$..ID',
          applicantLegalName: '$..ApplicantLegalName',
          crdNumber: '$..CRDNumber',
          filingExaminerUserId: '$..FilingExaminerUserID',
          organizationId: '$..OrganizationID'
        }
      ],
      exams: [
        '$..SC_Exam',
        {
          id: '$..ID',
          primaryExaminer: '$..PrimaryExaminer',
          applicantLegalName: '$..ApplicantLegalName',
          crdNumber: '$..CRDNumber',
          organizationId: '$..OrganizationID'
        }
      ],
      correspondences: [
        '$..SC_Correspondence',
        {
          id: '$..ID',
          applicantLegalName: '$..ApplicantLegalName',
          crdNumber: '$..CRDNumber',
          organizationId: '$..OrganizationID'
        }
      ],
      financialStatements: [
        '$..SC_FinancialStatement',
        {
          id: '$..ID',
          applicantLegalName: '$..ApplicantLegalName',
          crdNumber: '$..CRDNumber',
          organizationId: '$..OrganizationID'
        }
      ]
    }
  })
    .done(function(response) {
      cleanUpResponse(response, { cleanUpTextKeys: true });
      // Create a moment object for each draft app's last modified date
      _.each(response.tasks, function(task) {
        task.deliveryDate = moment.utc(task.deliveryDate, moment.ISO_8601).local();

        task.assignedRole = getUserIdFromDN(task.assignedRole);

        var taskType = _.findWhere(response.taskTypes, { name: task.taskTypeName });
        task.type = _get(taskType, 'description', '--');

        if (task.organizationId) {
          var organization = _.findWhere(response.organizations, { id: task.organizationId });
          task.applicantLegalName = _get(organization, 'applicantLegalName', '--');
        }
        if (task.applicationId) {
          var application = _.findWhere(response.applications, { id: task.applicationId });
          task.primaryExaminer = _get(application, 'filingExaminerUserId', '--');
          task.applicantLegalName = _get(application, 'applicantLegalName', '--');
        }
        if (task.examId) {
          var exam = _.findWhere(response.exams, { id: task.examId });
          task.primaryExaminer = _get(exam, 'primaryExaminer', '--');
          task.applicantLegalName = _get(exam, 'applicantLegalName', '--');
        }
        if (task.correspondenceId) {
          var correspondence = _.findWhere(response.correspondences, { id: task.correspondenceId });
          task.organizationId = _get(correspondence, 'organizationId');
          task.applicantLegalName = _get(correspondence, 'applicantLegalName', '--');
        }
        if (task.financialStatementId) {
          var financialStatement = _.findWhere(response.financialStatements, { id: task.financialStatementId });
          task.organizationId = _get(financialStatement, 'organizationId');
          task.applicantLegalName = _get(financialStatement, 'applicantLegalName', '--');
        }

        task.primaryExaminer = task.primaryExaminer ? task.primaryExaminer : '--';
      });

      deferred.resolve(response.tasks);
    })
    .fail(function(err) {
      deferred.reject();
    });

  return deferred.promise();
};
