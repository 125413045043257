/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var PageableCollection = require('psa-backbone/models/PageableCollection');

var PlaceholderView = require('common/views/PlaceholderView');

var ConSerEntityComplaintsSectionView = require('../views/ConSerEntityComplaintsSectionView');

var GetComplaintsByCSEntity = require('../services/GetComplaintsByCSEntity');

module.exports = {
  channel: 'cs',

  event: 'build:complaints-section-view',

  builder: function(entityId, departmentId) {
    return new PlaceholderView({
      viewPromise: buildComplaintsSectionView(entityId, departmentId)
    });
  }
};

function buildComplaintsSectionView(entityId, departmentId) {
  var deferred = $.Deferred();
  GetComplaintsByCSEntity(entityId, departmentId)
    .done(function(complaints) {
      deferred.resolve(
        new ConSerEntityComplaintsSectionView({
          collection: new PageableCollection(complaints, { pageSize: 25 })
        })
      );
    })
    .fail(function(err) {
      deferred.reject(err);
    });
  return deferred.promise();
}
