var Handlebars = require("handlebars/dist/handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.escapeExpression, alias2=depth0 != null ? depth0 : (container.nullContext || {}), alias3=helpers.helperMissing, alias4="function";

  return "\n<div class=\"page-title clearfix\">\n  <h1 class=\"pull-left m-n p-n\">"
    + alias1(container.lambda(((stack1 = (depth0 != null ? depth0.entityIdentifiers : depth0)) != null ? stack1.entityName : stack1), depth0))
    + "</h1>\n</div>\n<div class=\"breadcrumb-container\"></div>\n<div class=\"col-sm-9 col-sm-offset-1 main-form\">\n  <div class=\"form-horizontal\">\n    <form class=\"entity-merge-form\">\n      <div class=\"entity-search-section\">\n        <div class=\"row m-b-lg\">\n          <h3 class=\"col-sm-offset-3 col-sm-9\">Mergers and Acquisition</h3>\n        </div>\n        <div class=\"form-group required\">\n          <label class=\"control-label col-sm-3\">Target Entity</label>\n          <div class=\"col-sm-6\">\n            <div class=\"input-group\">\n              <span class=\"input-group-btn\">\n                <button class=\"btn btn-primary btn-entity-search\">Search for Entity</button>\n              </span>\n              <input type=\"text\" class=\"selected-entity-search-entity-name form-control\"\n                placeholder=\"Search for an Entity\" name=\"acquiredCompanyEntityName\" readonly>\n              <span class=\"input-group-btn\">\n                <button class=\"btn btn-default clear-entity-search\">Clear</button>\n              </span>\n              <input type=\"number\" class=\"selected-entity-id\" name=\"entitySearchId\"\n                data-parsley-errors-container=\".entity-select-error-container\" \n                data-parsley-required=\"true\"\n                data-parsley-not-equal=\""
    + alias1(((helper = (helper = helpers.entityId || (depth0 != null ? depth0.entityId : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias2,{"name":"entityId","hash":{},"data":data}) : helper)))
    + "\" \n                data-parsley-not-equal-message=\"Please choose a different Entity.\" hidden>\n            </div>\n            <div class=\"entity-select-error-container\"></div>\n          </div>\n        </div>\n        <div class=\"form-group\">\n          <label class=\"control-label col-sm-3\">Target Locations To Be Merged</label>\n          <div class=\"entity-location-grid-container col-sm-9\"></div>\n        </div>\n      </div>\n    </form>\n    <div class=\"row m-b-lg\">\n      <div class=\"col-sm-6 col-sm-offset-3 pull-left\">\n        <a href=\"#dep/entity/"
    + alias1(((helper = (helper = helpers.entityId || (depth0 != null ? depth0.entityId : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias2,{"name":"entityId","hash":{},"data":data}) : helper)))
    + "/dashboard\" class=\"btn btn-default\">Cancel</a>\n        <button class=\"btn btn-primary btn-submit\">Submit</button>\n      </div>\n    </div>\n  </div>\n</div>";
},"useData":true});