var Handlebars = require("handlebars/dist/handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "\n<div class=\"page-title clearfix\">\n  <h1 class=\"m-n p-n\">"
    + alias4(((helper = (helper = helpers.entityName || (depth0 != null ? depth0.entityName : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"entityName","hash":{},"data":data}) : helper)))
    + "</h1>\n  <div class=\"page-title-buttons-container\">\n    <div class=\"dropdown-container\"></div>\n  </div>\n</div>\n<div class=\"breadcrumb-container\"></div>\n<div class=\"col-sm-12 col-md-10 col-md-offset-1\">\n  <div class=\"row\">\n    <div class=\"col-sm-9 col-sm-offset-3 col-md-10 col-md-offset-2\">\n      <h3 class=\"m-t-xs m-b-lg\">New Entity Tracking</h3>\n    </div>\n  </div>\n  <div class=\"new-tracking-form-container\"></div>\n  <div class=\"row m-b-lg\">\n    <div class=\"col-sm-9 col-sm-offset-3 col-md-10 col-md-offset-2\">\n      <a class=\"btn btn-default\" href=\"#dep/entity/"
    + alias4(((helper = (helper = helpers.entityId || (depth0 != null ? depth0.entityId : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"entityId","hash":{},"data":data}) : helper)))
    + "/dashboard\">Cancel</a>\n      <button class=\"btn btn-primary btn-submit\">Submit</button>\n    </div>\n  </div>\n</div>";
},"useData":true});