/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2020] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var UIUtil = require('psa-core/util/ui');

var GridView = require('psa-marionette/views/GridView');

var AddressCell = require('common/backgrid/AddressCell');

var MultiButtonCell = require('common/backgrid/MultiButtonCell');

var SubmitContact = require('../../common/services/SubmitContact');

var ConfirmationModalView = require('../../../common/views/ConfirmationModalView/index');

module.exports = GridView.extend({
  className: 'con-ser-entity-contacts-section-view',

  onBeforeRender: function() {
    this.options.columns = [
      {
        name: 'firstName',
        label: 'First Name',
        cell: 'string',
        headerCell: 'custom',
        width: 15,
        editable: false
      },
      {
        name: 'lastName',
        label: 'Last Name',
        cell: 'string',
        headerCell: 'custom',
        width: 15,
        editable: false
      },
      {
        name: 'contactTypeDesc',
        label: 'Contact Type',
        cell: 'string',
        headerCell: 'custom',
        width: 15,
        editable: false
      },
      {
        name: 'email',
        label: 'Email',
        cell: 'string',
        headerCell: 'custom',
        width: 15,
        editable: false
      },
      {
        name: 'phoneNumber',
        label: 'Phone Number',
        cell: 'string',
        headerCell: 'custom',
        width: 15,
        editable: false
      },
      {
        name: 'address',
        label: 'Street Address',
        cell: AddressCell,
        headerCell: 'custom',
        width: 15,
        editable: false
      },
      {
        name: '',
        label: '',
        cell: MultiButtonCell.extend({
          buttons: [
            {
              name: 'Edit',
              buttonClass: 'btn-primary btn-sm',
              href: function(model) {
                var departmentId = model.get('departmentId');
                var entityId = model.get('entityId');
                var contactId = model.get('contactId');

                return '#conser/' + departmentId + '/entity/' + entityId + '/contact/' + contactId + '/edit';
              }
            },
            {
              name: '',
              labelClass: 'fa fa-trash',
              buttonClass: 'btn-danger btn-sm',
              onClick: this.onClickDeleteContactLink.bind(this)
            }
          ]
        }),
        headerCell: 'custom',
        width: 10,
        editable: false,
        sortable: false
      }
    ];

    this.options.emptyText = 'No Contacts';
  },

  onClickDeleteContactLink: function(e, contact) {
    var self = this;
    e.preventDefault();

    var contactDetails = contact.attributes;
    contactDetails.isDeleted = 1;

    var title = 'Confirm Deleting Contact: ' + contact.attributes.firstName + ' ' + contact.attributes.lastName;
    var message =
      'Are you sure you would like to delete this contact? Before deleting, verify that this contact is not used on any associated complaints.';

    UIUtil.showModal({
      title: title,
      view: new ConfirmationModalView({
        confirmText: 'Delete Contact',
        model: new Backbone.Model({
          message: message,
          onConfirm: function() {
            return SubmitContact(contactDetails);
          },
          onConfirmDone: function() {
            self.collection.remove(contact);
          }
        })
      })
    });
  }
});
