var Handlebars = require("handlebars/dist/handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return " Yes\n            ";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = (helpers.ifCond || (depth0 && depth0.ifCond) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? depth0.entityTracking : depth0)) != null ? stack1.isAdopted : stack1),"===","0",{"name":"ifCond","hash":{},"fn":container.program(4, data, 0),"inverse":container.program(6, data, 0),"data":data})) != null ? stack1 : "");
},"4":function(container,depth0,helpers,partials,data) {
    return " No\n            ";
},"6":function(container,depth0,helpers,partials,data) {
    return " --\n            ";
},"8":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = (helpers.ifCond || (depth0 && depth0.ifCond) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? depth0.entityTracking : depth0)) != null ? stack1.isDirect : stack1),"===","0",{"name":"ifCond","hash":{},"fn":container.program(4, data, 0),"inverse":container.program(6, data, 0),"data":data})) != null ? stack1 : "");
},"10":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = (helpers.ifCond || (depth0 && depth0.ifCond) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? depth0.entityTracking : depth0)) != null ? stack1.isIndirect : stack1),"===","0",{"name":"ifCond","hash":{},"fn":container.program(4, data, 0),"inverse":container.program(6, data, 0),"data":data})) != null ? stack1 : "");
},"12":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = (helpers.ifCond || (depth0 && depth0.ifCond) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? depth0.entityTracking : depth0)) != null ? stack1.isServiceMRBS : stack1),"===","0",{"name":"ifCond","hash":{},"fn":container.program(4, data, 0),"inverse":container.program(6, data, 0),"data":data})) != null ? stack1 : "");
},"14":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = (helpers.ifCond || (depth0 && depth0.ifCond) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? depth0.entityTracking : depth0)) != null ? stack1.isMRBInvestments : stack1),"===","0",{"name":"ifCond","hash":{},"fn":container.program(4, data, 0),"inverse":container.program(6, data, 0),"data":data})) != null ? stack1 : "");
},"16":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = (helpers.ifCond || (depth0 && depth0.ifCond) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? depth0.entityTracking : depth0)) != null ? stack1.isBank : stack1),"===","0",{"name":"ifCond","hash":{},"fn":container.program(4, data, 0),"inverse":container.program(6, data, 0),"data":data})) != null ? stack1 : "");
},"18":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = (helpers.ifCond || (depth0 && depth0.ifCond) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? depth0.entityTracking : depth0)) != null ? stack1.isHC : stack1),"===","0",{"name":"ifCond","hash":{},"fn":container.program(4, data, 0),"inverse":container.program(6, data, 0),"data":data})) != null ? stack1 : "");
},"20":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = (helpers.ifCond || (depth0 && depth0.ifCond) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? depth0.entityTracking : depth0)) != null ? stack1.isLoanGrowthOver20 : stack1),"===",false,{"name":"ifCond","hash":{},"fn":container.program(4, data, 0),"inverse":container.program(6, data, 0),"data":data})) != null ? stack1 : "");
},"22":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = (helpers.ifCond || (depth0 && depth0.ifCond) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? depth0.entityTracking : depth0)) != null ? stack1.isCommunityLeverageRatioUnder9 : stack1),"===",false,{"name":"ifCond","hash":{},"fn":container.program(4, data, 0),"inverse":container.program(6, data, 0),"data":data})) != null ? stack1 : "");
},"24":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = (helpers.ifCond || (depth0 && depth0.ifCond) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? depth0.entityTracking : depth0)) != null ? stack1.isCREToRBCOver300 : stack1),"===",false,{"name":"ifCond","hash":{},"fn":container.program(4, data, 0),"inverse":container.program(6, data, 0),"data":data})) != null ? stack1 : "");
},"26":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = (helpers.ifCond || (depth0 && depth0.ifCond) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? depth0.entityTracking : depth0)) != null ? stack1.isPastDueRatioOver5 : stack1),"===",false,{"name":"ifCond","hash":{},"fn":container.program(4, data, 0),"inverse":container.program(6, data, 0),"data":data})) != null ? stack1 : "");
},"28":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = (helpers.ifCond || (depth0 && depth0.ifCond) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? depth0.entityTracking : depth0)) != null ? stack1.isTexasRatioOver30 : stack1),"===",false,{"name":"ifCond","hash":{},"fn":container.program(4, data, 0),"inverse":container.program(6, data, 0),"data":data})) != null ? stack1 : "");
},"30":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = (helpers.ifCond || (depth0 && depth0.ifCond) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? depth0.entityTracking : depth0)) != null ? stack1.isROAUnderPt25 : stack1),"===",false,{"name":"ifCond","hash":{},"fn":container.program(4, data, 0),"inverse":container.program(6, data, 0),"data":data})) != null ? stack1 : "");
},"32":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = (helpers.ifCond || (depth0 && depth0.ifCond) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? depth0.entityTracking : depth0)) != null ? stack1.isTier1LeverageUnder8 : stack1),"===",false,{"name":"ifCond","hash":{},"fn":container.program(4, data, 0),"inverse":container.program(6, data, 0),"data":data})) != null ? stack1 : "");
},"34":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = (helpers.ifCond || (depth0 && depth0.ifCond) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? depth0.entityTracking : depth0)) != null ? stack1.isNonCoreRatioOver20 : stack1),"===",false,{"name":"ifCond","hash":{},"fn":container.program(4, data, 0),"inverse":container.program(6, data, 0),"data":data})) != null ? stack1 : "");
},"36":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = (helpers.ifCond || (depth0 && depth0.ifCond) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? depth0.entityTracking : depth0)) != null ? stack1.isLoanToDepositOver100 : stack1),"===",false,{"name":"ifCond","hash":{},"fn":container.program(4, data, 0),"inverse":container.program(6, data, 0),"data":data})) != null ? stack1 : "");
},"38":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = (helpers.ifCond || (depth0 && depth0.ifCond) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? depth0.entityTracking : depth0)) != null ? stack1.isLoanGrowthOver10 : stack1),"===",false,{"name":"ifCond","hash":{},"fn":container.program(4, data, 0),"inverse":container.program(6, data, 0),"data":data})) != null ? stack1 : "");
},"40":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = (helpers.ifCond || (depth0 && depth0.ifCond) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? depth0.entityTracking : depth0)) != null ? stack1.isNetAssetsUnder8Pt5 : stack1),"===",false,{"name":"ifCond","hash":{},"fn":container.program(4, data, 0),"inverse":container.program(6, data, 0),"data":data})) != null ? stack1 : "");
},"42":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = (helpers.ifCond || (depth0 && depth0.ifCond) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? depth0.entityTracking : depth0)) != null ? stack1.isPastDueRatioOver3Pt5 : stack1),"===",false,{"name":"ifCond","hash":{},"fn":container.program(4, data, 0),"inverse":container.program(6, data, 0),"data":data})) != null ? stack1 : "");
},"44":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = (helpers.ifCond || (depth0 && depth0.ifCond) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? depth0.entityTracking : depth0)) != null ? stack1.isNetLongTermAssetsOver25 : stack1),"===",false,{"name":"ifCond","hash":{},"fn":container.program(4, data, 0),"inverse":container.program(6, data, 0),"data":data})) != null ? stack1 : "");
},"46":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = (helpers.ifCond || (depth0 && depth0.ifCond) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? depth0.entityTracking : depth0)) != null ? stack1.isROAUnderPt50 : stack1),"===",false,{"name":"ifCond","hash":{},"fn":container.program(4, data, 0),"inverse":container.program(6, data, 0),"data":data})) != null ? stack1 : "");
},"48":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = (helpers.ifCond || (depth0 && depth0.ifCond) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? depth0.entityTracking : depth0)) != null ? stack1.isNetWorthGrowthOver10 : stack1),"===",false,{"name":"ifCond","hash":{},"fn":container.program(4, data, 0),"inverse":container.program(6, data, 0),"data":data})) != null ? stack1 : "");
},"50":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = (helpers.ifCond || (depth0 && depth0.ifCond) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? depth0.entityTracking : depth0)) != null ? stack1.isCashShortTermUnder10 : stack1),"===",false,{"name":"ifCond","hash":{},"fn":container.program(4, data, 0),"inverse":container.program(6, data, 0),"data":data})) != null ? stack1 : "");
},"52":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = (helpers.ifCond || (depth0 && depth0.ifCond) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? depth0.entityTracking : depth0)) != null ? stack1.isShareGrowthOver10 : stack1),"===",false,{"name":"ifCond","hash":{},"fn":container.program(4, data, 0),"inverse":container.program(6, data, 0),"data":data})) != null ? stack1 : "");
},"54":function(container,depth0,helpers,partials,data) {
    return " Yes\n        ";
},"56":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = (helpers.ifCond || (depth0 && depth0.ifCond) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? depth0.entityTracking : depth0)) != null ? stack1.isAssetGrowthOver10 : stack1),"===",false,{"name":"ifCond","hash":{},"fn":container.program(57, data, 0),"inverse":container.program(59, data, 0),"data":data})) != null ? stack1 : "");
},"57":function(container,depth0,helpers,partials,data) {
    return " No\n        ";
},"59":function(container,depth0,helpers,partials,data) {
    return " --\n        ";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), alias4=helpers.helperMissing;

  return "\n<div class=\"form-horizontal form-static\">\n  <div class=\"row\">\n    <div class=\"col-sm-12 col-md-6\">\n      <div class=\"form-group\">\n        <label class=\"control-label col-sm-3 col-md-4\">Tracking Type</label>\n        <div class=\"form-control-static col-sm-9 col-md-8 col-md-8\">"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.entityTracking : depth0)) != null ? stack1.trackingType : stack1), depth0))
    + "</div>\n      </div>\n    </div>\n    <div class=\"col-sm-12 col-md-6\">\n      <div class=\"form-group\">\n        <label class=\"control-label col-sm-3 col-md-4\">Date Entered</label>\n        <div class=\"form-control-static col-sm-9 col-md-8\">"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.entityTracking : depth0)) != null ? stack1.enteredDate : stack1), depth0))
    + "</div>\n      </div>\n    </div>\n  </div>\n  <div class=\"form-group\">\n    <label class=\"control-label col-sm-3 col-md-2\">Source</label>\n    <div class=\"form-control-static col-sm-9 col-md-10\">"
    + alias2((helpers["default"] || (depth0 && depth0["default"]) || alias4).call(alias3,((stack1 = (depth0 != null ? depth0.entityTracking : depth0)) != null ? stack1.trackingSource : stack1),"--",{"name":"default","hash":{},"data":data}))
    + "</div>\n  </div>\n  <div class=\"cecl-container collapse\">\n    <div class=\"row\">\n      <div class=\"col-sm-12 col-md-6\">\n        <div class=\"form-group\">\n          <label class=\"control-label col-sm-3 col-md-4\">Adoption Deadline</label>\n          <div class=\"form-control-static col-sm-9 col-md-8\">"
    + alias2((helpers["default"] || (depth0 && depth0["default"]) || alias4).call(alias3,((stack1 = (depth0 != null ? depth0.entityTracking : depth0)) != null ? stack1.adoptionDeadlineDate : stack1),"--",{"name":"default","hash":{},"data":data}))
    + "</div>\n        </div>\n      </div>\n      <div class=\"col-sm-12 col-md-6\">\n        <div class=\"form-group\">\n          <label class=\"control-label col-sm-3 col-md-4\">Expected Adoption Date</label>\n          <div class=\"form-control-static col-sm-9 col-md-8\">"
    + alias2((helpers["default"] || (depth0 && depth0["default"]) || alias4).call(alias3,((stack1 = (depth0 != null ? depth0.entityTracking : depth0)) != null ? stack1.expectedAdoptionDate : stack1),"--",{"name":"default","hash":{},"data":data}))
    + "</div>\n        </div>\n      </div>\n    </div>\n    <div class=\"row\">\n      <div class=\"col-sm-12 col-md-6\">\n        <div class=\"form-group\">\n          <label class=\"control-label col-sm-3 col-md-4\">Adopted</label>\n          <div class=\"form-control-static col-sm-9 col-md-8\">\n            "
    + ((stack1 = (helpers.ifCond || (depth0 && depth0.ifCond) || alias4).call(alias3,((stack1 = (depth0 != null ? depth0.entityTracking : depth0)) != null ? stack1.isAdopted : stack1),"===","1",{"name":"ifCond","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data})) != null ? stack1 : "")
    + "          </div>\n        </div>\n      </div>\n      <div class=\"col-sm-12 col-md-6\">\n        <div class=\"form-group\">\n          <label class=\"control-label col-sm-3 col-md-4\">Adoption Date</label>\n          <div class=\"form-control-static col-sm-9 col-md-8\">"
    + alias2((helpers["default"] || (depth0 && depth0["default"]) || alias4).call(alias3,((stack1 = (depth0 != null ? depth0.entityTracking : depth0)) != null ? stack1.adoptionDate : stack1),"--",{"name":"default","hash":{},"data":data}))
    + "</div>\n        </div>\n      </div>\n    </div>\n    <div class=\"form-group\">\n      <label class=\"control-label col-sm-3 col-md-2\">Entity Type</label>\n      <div class=\"form-control-static col-sm-9 col-md-10\">"
    + alias2((helpers["default"] || (depth0 && depth0["default"]) || alias4).call(alias3,((stack1 = (depth0 != null ? depth0.entityTracking : depth0)) != null ? stack1.trackingEntityType : stack1),"--",{"name":"default","hash":{},"data":data}))
    + "</div>\n    </div>\n  </div>\n  <div class=\"mrb-container collapse\">\n    <div class=\"row\">\n      <div class=\"col-sm-12 col-md-6\">\n        <div class=\"form-group\">\n          <label class=\"control-label col-sm-3 col-md-4\">Direct</label>\n          <div class=\"form-control-static col-sm-9 col-md-8\">\n            "
    + ((stack1 = (helpers.ifCond || (depth0 && depth0.ifCond) || alias4).call(alias3,((stack1 = (depth0 != null ? depth0.entityTracking : depth0)) != null ? stack1.isDirect : stack1),"===","1",{"name":"ifCond","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(8, data, 0),"data":data})) != null ? stack1 : "")
    + "          </div>\n        </div>\n      </div>\n      <div class=\"col-sm-12 col-md-6\">\n        <div class=\"form-group\">\n          <label class=\"control-label col-sm-3 col-md-4\">Indirect</label>\n          <div class=\"form-control-static col-sm-9 col-md-8\">\n            "
    + ((stack1 = (helpers.ifCond || (depth0 && depth0.ifCond) || alias4).call(alias3,((stack1 = (depth0 != null ? depth0.entityTracking : depth0)) != null ? stack1.isIndirect : stack1),"===","1",{"name":"ifCond","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(10, data, 0),"data":data})) != null ? stack1 : "")
    + "          </div>\n        </div>\n      </div>\n    </div>\n    <div class=\"row\">\n      <div class=\"col-sm-12 col-md-6\">\n        <div class=\"form-group\">\n          <label class=\"control-label col-sm-3 col-md-4\">Services MRBs</label>\n          <div class=\"form-control-static col-sm-9 col-md-8\">\n            "
    + ((stack1 = (helpers.ifCond || (depth0 && depth0.ifCond) || alias4).call(alias3,((stack1 = (depth0 != null ? depth0.entityTracking : depth0)) != null ? stack1.isServiceMRBS : stack1),"===","1",{"name":"ifCond","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(12, data, 0),"data":data})) != null ? stack1 : "")
    + "          </div>\n        </div>\n      </div>\n      <div class=\"col-sm-12 col-md-6\">\n        <div class=\"form-group\">\n          <label class=\"control-label col-sm-3 col-md-4\">MRB Investments</label>\n          <div class=\"form-control-static col-sm-9 col-md-8\">\n            "
    + ((stack1 = (helpers.ifCond || (depth0 && depth0.ifCond) || alias4).call(alias3,((stack1 = (depth0 != null ? depth0.entityTracking : depth0)) != null ? stack1.isMRBInvestments : stack1),"===","1",{"name":"ifCond","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(14, data, 0),"data":data})) != null ? stack1 : "")
    + "          </div>\n        </div>\n      </div>\n    </div>\n  </div>\n  <div class=\"sub-debt-container collapse\">\n    <div class=\"row\">\n      <div class=\"col-sm-12 col-md-6\">\n        <div class=\"form-group\">\n          <label class=\"control-label col-sm-3 col-md-4\">Bank</label>\n          <div class=\"form-control-static col-sm-9 col-md-8\">\n            "
    + ((stack1 = (helpers.ifCond || (depth0 && depth0.ifCond) || alias4).call(alias3,((stack1 = (depth0 != null ? depth0.entityTracking : depth0)) != null ? stack1.isBank : stack1),"===","1",{"name":"ifCond","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(16, data, 0),"data":data})) != null ? stack1 : "")
    + "          </div>\n        </div>\n      </div>\n      <div class=\"col-sm-12 col-md-6\">\n        <div class=\"form-group\">\n          <label class=\"control-label col-sm-3 col-md-4\">HC</label>\n          <div class=\"form-control-static col-sm-9 col-md-8\">\n            "
    + ((stack1 = (helpers.ifCond || (depth0 && depth0.ifCond) || alias4).call(alias3,((stack1 = (depth0 != null ? depth0.entityTracking : depth0)) != null ? stack1.isHC : stack1),"===","1",{"name":"ifCond","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(18, data, 0),"data":data})) != null ? stack1 : "")
    + "          </div>\n        </div>\n      </div>\n    </div>\n  </div>\n  <div class=\"red-flag-container collapse\">\n    <div class=\"row\">\n      <div class=\"col-sm-12 col-md-6\">\n        <div class=\"form-group\">\n          <label class=\"control-label col-sm-3 col-md-4\">Year</label>\n          <div class=\"form-control-static col-sm-9 col-md-8\">"
    + alias2((helpers["default"] || (depth0 && depth0["default"]) || alias4).call(alias3,((stack1 = (depth0 != null ? depth0.entityTracking : depth0)) != null ? stack1.year : stack1),"--",{"name":"default","hash":{},"data":data}))
    + "</div>\n        </div>\n      </div>\n      <div class=\"col-sm-12 col-md-6\">\n        <div class=\"form-group\">\n          <label class=\"control-label col-sm-3 col-md-4\">Quarter</label>\n          <div class=\"form-control-static col-sm-9 col-md-8\">"
    + alias2((helpers["default"] || (depth0 && depth0["default"]) || alias4).call(alias3,((stack1 = (depth0 != null ? depth0.entityTracking : depth0)) != null ? stack1.quarter : stack1),"--",{"name":"default","hash":{},"data":data}))
    + "</div>\n        </div>\n      </div>\n    </div>\n    <div class=\"form-group\">\n      <label class=\"control-label col-sm-3 col-md-2\"># Of Red Flags</label>\n      <div class=\"form-control-static col-sm-9 col-md-10\">"
    + alias2((helpers["default"] || (depth0 && depth0["default"]) || alias4).call(alias3,((stack1 = (depth0 != null ? depth0.entityTracking : depth0)) != null ? stack1.redFlagCount : stack1),"--",{"name":"default","hash":{},"data":data}))
    + "</div>\n    </div>\n  </div>\n  <div class=\"bank-red-flag-container collapse\">\n    <div class=\"row\">\n      <div class=\"col-sm-12 col-md-6\">\n        <div class=\"form-group\">\n          <label class=\"control-label col-sm-3 col-md-4\">Loan Growth Greater Than 20%</label>\n          <div class=\"form-control-static col-sm-9 col-md-8\">\n            "
    + ((stack1 = (helpers.ifCond || (depth0 && depth0.ifCond) || alias4).call(alias3,((stack1 = (depth0 != null ? depth0.entityTracking : depth0)) != null ? stack1.isLoanGrowthOver20 : stack1),"===",true,{"name":"ifCond","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(20, data, 0),"data":data})) != null ? stack1 : "")
    + "          </div>\n        </div>\n      </div>\n      <div class=\"col-sm-12 col-md-6\">\n        <div class=\"form-group\">\n          <label class=\"control-label col-sm-3 col-md-4\">Community Bank Leverage Ratio Less Than 9%</label>\n          <div class=\"form-control-static col-sm-9 col-md-8\">\n            "
    + ((stack1 = (helpers.ifCond || (depth0 && depth0.ifCond) || alias4).call(alias3,((stack1 = (depth0 != null ? depth0.entityTracking : depth0)) != null ? stack1.isCommunityLeverageRatioUnder9 : stack1),"===",true,{"name":"ifCond","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(22, data, 0),"data":data})) != null ? stack1 : "")
    + "          </div>\n        </div>\n      </div>\n    </div>\n    <div class=\"row\">\n      <div class=\"col-sm-12 col-md-6\">\n        <div class=\"form-group\">\n          <label class=\"control-label col-sm-3 col-md-4\">CRE To Total RBC Greater Than 300%</label>\n          <div class=\"form-control-static col-sm-9 col-md-8\">\n            "
    + ((stack1 = (helpers.ifCond || (depth0 && depth0.ifCond) || alias4).call(alias3,((stack1 = (depth0 != null ? depth0.entityTracking : depth0)) != null ? stack1.isCREToRBCOver300 : stack1),"===",true,{"name":"ifCond","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(24, data, 0),"data":data})) != null ? stack1 : "")
    + "          </div>\n        </div>\n      </div>\n      <div class=\"col-sm-12 col-md-6\">\n        <div class=\"form-group\">\n          <label class=\"control-label col-sm-3 col-md-4\">Past Due Ratio Greater Than 5%</label>\n          <div class=\"form-control-static col-sm-9 col-md-8\">\n            "
    + ((stack1 = (helpers.ifCond || (depth0 && depth0.ifCond) || alias4).call(alias3,((stack1 = (depth0 != null ? depth0.entityTracking : depth0)) != null ? stack1.isPastDueRatioOver5 : stack1),"===",true,{"name":"ifCond","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(26, data, 0),"data":data})) != null ? stack1 : "")
    + "          </div>\n        </div>\n      </div>\n    </div>\n    <div class=\"row\">\n      <div class=\"col-sm-12 col-md-6\">\n        <div class=\"form-group\">\n          <label class=\"control-label col-sm-3 col-md-4\">Texas Ratio Greater Than 30%</label>\n          <div class=\"form-control-static col-sm-9 col-md-8\">\n            "
    + ((stack1 = (helpers.ifCond || (depth0 && depth0.ifCond) || alias4).call(alias3,((stack1 = (depth0 != null ? depth0.entityTracking : depth0)) != null ? stack1.isTexasRatioOver30 : stack1),"===",true,{"name":"ifCond","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(28, data, 0),"data":data})) != null ? stack1 : "")
    + "          </div>\n        </div>\n      </div>\n      <div class=\"col-sm-12 col-md-6\">\n        <div class=\"form-group\">\n          <label class=\"control-label col-sm-3 col-md-4\">ROA Less Than 0.25%</label>\n          <div class=\"form-control-static col-sm-9 col-md-8\">\n            "
    + ((stack1 = (helpers.ifCond || (depth0 && depth0.ifCond) || alias4).call(alias3,((stack1 = (depth0 != null ? depth0.entityTracking : depth0)) != null ? stack1.isROAUnderPt25 : stack1),"===",true,{"name":"ifCond","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(30, data, 0),"data":data})) != null ? stack1 : "")
    + "          </div>\n        </div>\n      </div>\n    </div>\n    <div class=\"row\">\n      <div class=\"col-sm-12 col-md-6\">\n        <div class=\"form-group\">\n          <label class=\"control-label col-sm-3 col-md-4\">Tier 1 Leverage Less Than 8%</label>\n          <div class=\"form-control-static col-sm-9 col-md-8\">\n            "
    + ((stack1 = (helpers.ifCond || (depth0 && depth0.ifCond) || alias4).call(alias3,((stack1 = (depth0 != null ? depth0.entityTracking : depth0)) != null ? stack1.isTier1LeverageUnder8 : stack1),"===",true,{"name":"ifCond","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(32, data, 0),"data":data})) != null ? stack1 : "")
    + "          </div>\n        </div>\n      </div>\n      <div class=\"col-sm-12 col-md-6\">\n        <div class=\"form-group\">\n          <label class=\"control-label col-sm-3 col-md-4\">Non-Core Funding Deposit Ratio Greater Than 20%</label>\n          <div class=\"form-control-static col-sm-9 col-md-8\">\n            "
    + ((stack1 = (helpers.ifCond || (depth0 && depth0.ifCond) || alias4).call(alias3,((stack1 = (depth0 != null ? depth0.entityTracking : depth0)) != null ? stack1.isNonCoreRatioOver20 : stack1),"===",true,{"name":"ifCond","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(34, data, 0),"data":data})) != null ? stack1 : "")
    + "          </div>\n        </div>\n      </div>\n    </div>\n    <div class=\"row\">\n      <div class=\"col-sm-12 col-md-6\">\n        <div class=\"form-group\">\n          <label class=\"control-label col-sm-3 col-md-4\">Loan To Deposit Ratio Greater Than 100% and Liquidity Ratio\n            Less\n            Then\n            10%</label>\n          <div class=\"form-control-static col-sm-9 col-md-8\">\n            "
    + ((stack1 = (helpers.ifCond || (depth0 && depth0.ifCond) || alias4).call(alias3,((stack1 = (depth0 != null ? depth0.entityTracking : depth0)) != null ? stack1.isLoanToDepositOver100 : stack1),"===",true,{"name":"ifCond","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(36, data, 0),"data":data})) != null ? stack1 : "")
    + "          </div>\n        </div>\n      </div>\n    </div>\n  </div>\n  <div class=\"credit-union-red-flag-container collapse\">\n    <div class=\"row\">\n      <div class=\"col-sm-12 col-md-6\">\n        <div class=\"form-group\">\n          <label class=\"control-label col-sm-3 col-md-4\">Loan Growth Greater Than 10%</label>\n          <div class=\"form-control-static col-sm-9 col-md-8\">\n            "
    + ((stack1 = (helpers.ifCond || (depth0 && depth0.ifCond) || alias4).call(alias3,((stack1 = (depth0 != null ? depth0.entityTracking : depth0)) != null ? stack1.isLoanGrowthOver10 : stack1),"===",true,{"name":"ifCond","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(38, data, 0),"data":data})) != null ? stack1 : "")
    + "          </div>\n        </div>\n      </div>\n      <div class=\"col-sm-12 col-md-6\">\n        <div class=\"form-group\">\n          <label class=\"control-label col-sm-3 col-md-4\">Net Worth/Total Assets Less Than 8.5%</label>\n          <div class=\"form-control-static col-sm-9 col-md-8\">\n            "
    + ((stack1 = (helpers.ifCond || (depth0 && depth0.ifCond) || alias4).call(alias3,((stack1 = (depth0 != null ? depth0.entityTracking : depth0)) != null ? stack1.isNetAssetsUnder8Pt5 : stack1),"===",true,{"name":"ifCond","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(40, data, 0),"data":data})) != null ? stack1 : "")
    + "          </div>\n        </div>\n      </div>\n    </div>\n    <div class=\"row\">\n      <div class=\"col-sm-12 col-md-6\">\n        <div class=\"form-group\">\n          <label class=\"control-label col-sm-3 col-md-4\">Past Due Ratio Greater Than 3.5%</label>\n          <div class=\"form-control-static col-sm-9 col-md-8\">\n            "
    + ((stack1 = (helpers.ifCond || (depth0 && depth0.ifCond) || alias4).call(alias3,((stack1 = (depth0 != null ? depth0.entityTracking : depth0)) != null ? stack1.isPastDueRatioOver3Pt5 : stack1),"===",true,{"name":"ifCond","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(42, data, 0),"data":data})) != null ? stack1 : "")
    + "          </div>\n        </div>\n      </div>\n      <div class=\"col-sm-12 col-md-6\">\n        <div class=\"form-group\">\n          <label class=\"control-label col-sm-3 col-md-4\">Net Long Term Assets/Assets Greater Than 25%</label>\n          <div class=\"form-control-static col-sm-9 col-md-8\">\n            "
    + ((stack1 = (helpers.ifCond || (depth0 && depth0.ifCond) || alias4).call(alias3,((stack1 = (depth0 != null ? depth0.entityTracking : depth0)) != null ? stack1.isNetLongTermAssetsOver25 : stack1),"===",true,{"name":"ifCond","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(44, data, 0),"data":data})) != null ? stack1 : "")
    + "          </div>\n        </div>\n      </div>\n    </div>\n    <div class=\"row\">\n      <div class=\"col-sm-12 col-md-6\">\n        <div class=\"form-group\">\n          <label class=\"control-label col-sm-3 col-md-4\">ROA Less Than 0.50%</label>\n          <div class=\"form-control-static col-sm-9 col-md-8\">\n            "
    + ((stack1 = (helpers.ifCond || (depth0 && depth0.ifCond) || alias4).call(alias3,((stack1 = (depth0 != null ? depth0.entityTracking : depth0)) != null ? stack1.isROAUnderPt50 : stack1),"===",true,{"name":"ifCond","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(46, data, 0),"data":data})) != null ? stack1 : "")
    + "          </div>\n        </div>\n      </div>\n      <div class=\"col-sm-12 col-md-6\">\n        <div class=\"form-group\">\n          <label class=\"control-label col-sm-3 col-md-4\">Net Worth Growth Greater Than 10%</label>\n          <div class=\"form-control-static col-sm-9 col-md-8\">\n            "
    + ((stack1 = (helpers.ifCond || (depth0 && depth0.ifCond) || alias4).call(alias3,((stack1 = (depth0 != null ? depth0.entityTracking : depth0)) != null ? stack1.isNetWorthGrowthOver10 : stack1),"===",true,{"name":"ifCond","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(48, data, 0),"data":data})) != null ? stack1 : "")
    + "          </div>\n        </div>\n      </div>\n    </div>\n    <div class=\"row\">\n      <div class=\"col-sm-12 col-md-6\">\n        <div class=\"form-group\">\n          <label class=\"control-label col-sm-3 col-md-4\">Cash and Short Term Investments/Assets Less Than 15%</label>\n          <div class=\"form-control-static col-sm-9 col-md-8\">\n            "
    + ((stack1 = (helpers.ifCond || (depth0 && depth0.ifCond) || alias4).call(alias3,((stack1 = (depth0 != null ? depth0.entityTracking : depth0)) != null ? stack1.isCashShortTermUnder10 : stack1),"===",true,{"name":"ifCond","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(50, data, 0),"data":data})) != null ? stack1 : "")
    + "          </div>\n        </div>\n      </div>\n      <div class=\"col-sm-12 col-md-6\">\n        <div class=\"form-group\">\n          <label class=\"control-label col-sm-3 col-md-4\">Share Growth Greater Than 10%</label>\n          <div class=\"form-control-static col-sm-9 col-md-8\">\n            "
    + ((stack1 = (helpers.ifCond || (depth0 && depth0.ifCond) || alias4).call(alias3,((stack1 = (depth0 != null ? depth0.entityTracking : depth0)) != null ? stack1.isShareGrowthOver10 : stack1),"===",true,{"name":"ifCond","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(52, data, 0),"data":data})) != null ? stack1 : "")
    + "          </div>\n        </div>\n      </div>\n    </div>\n    <div class=\"form-group\">\n      <label class=\"control-label col-sm-3 col-md-2\">Asset Growth Greater Than 10%</label>\n      <div class=\"form-control-static col-sm-9 col-md-10\">\n        "
    + ((stack1 = (helpers.ifCond || (depth0 && depth0.ifCond) || alias4).call(alias3,((stack1 = (depth0 != null ? depth0.entityTracking : depth0)) != null ? stack1.isAssetGrowthOver10 : stack1),"===",true,{"name":"ifCond","hash":{},"fn":container.program(54, data, 0),"inverse":container.program(56, data, 0),"data":data})) != null ? stack1 : "")
    + "      </div>\n    </div>\n  </div>\n  <div class=\"key-third-party-vendor-container collapse\">\n    <div class=\"row\">\n      <div class=\"col-sm-12 col-md-6\">\n        <div class=\"form-group\">\n          <label class=\"control-label col-sm-3 col-md-4\">As Of Date</label>\n          <div class=\"form-control-static col-sm-9 col-md-8\">"
    + alias2((helpers["default"] || (depth0 && depth0["default"]) || alias4).call(alias3,((stack1 = (depth0 != null ? depth0.entityTracking : depth0)) != null ? stack1.asOfDate : stack1),"--",{"name":"default","hash":{},"data":data}))
    + "</div>\n        </div>\n      </div>\n      <div class=\"col-sm-12 col-md-6\">\n        <div class=\"form-group\">\n          <label class=\"control-label col-sm-3 col-md-4\">Exam Start Date</label>\n          <div class=\"form-control-static col-sm-9 col-md-8\">"
    + alias2((helpers["default"] || (depth0 && depth0["default"]) || alias4).call(alias3,((stack1 = (depth0 != null ? depth0.entityTracking : depth0)) != null ? stack1.examStartDate : stack1),"--",{"name":"default","hash":{},"data":data}))
    + "</div>\n        </div>\n      </div>\n    </div>\n    <div class=\"row\">\n      <div class=\"col-sm-12 col-md-6\">\n        <div class=\"form-group\">\n          <label class=\"control-label col-sm-3 col-md-4\">Vendor Name</label>\n          <div class=\"form-control-static col-sm-9 col-md-8\">"
    + alias2((helpers["default"] || (depth0 && depth0["default"]) || alias4).call(alias3,((stack1 = (depth0 != null ? depth0.entityTracking : depth0)) != null ? stack1.vendorName : stack1),"--",{"name":"default","hash":{},"data":data}))
    + "</div>\n        </div>\n      </div>\n      <div class=\"col-sm-12 col-md-6\">\n        <div class=\"form-group\">\n          <label class=\"control-label col-sm-3 col-md-4\">Vendor Service</label>\n          <p class=\"form-control-static col-sm-9 col-md-8\">"
    + alias2((helpers["default"] || (depth0 && depth0["default"]) || alias4).call(alias3,((stack1 = (depth0 != null ? depth0.entityTracking : depth0)) != null ? stack1.vendorService : stack1),"--",{"name":"default","hash":{},"data":data}))
    + "</p>\n        </div>\n      </div>\n    </div>\n  </div>\n  <div class=\"form-group\">\n    <label class=\"control-label col-sm-3 col-md-2\">Comments</label>\n    <p class=\"form-control-static col-sm-9 col-md-10\">"
    + alias2((helpers["default"] || (depth0 && depth0["default"]) || alias4).call(alias3,((stack1 = (depth0 != null ? depth0.entityTracking : depth0)) != null ? stack1.comments : stack1),"--",{"name":"default","hash":{},"data":data}))
    + "</p>\n  </div>\n</div>";
},"useData":true});