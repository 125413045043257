var Handlebars = require("handlebars/dist/handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "              <option value=\""
    + alias4(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\">"
    + alias4(((helper = (helper = helpers.type || (depth0 != null ? depth0.type : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"type","hash":{},"data":data}) : helper)))
    + "</option>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "              <option value=\""
    + alias4(((helper = (helper = helpers.userId || (depth0 != null ? depth0.userId : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"userId","hash":{},"data":data}) : helper)))
    + "\">"
    + alias4(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data}) : helper)))
    + " - ("
    + alias4(((helper = (helper = helpers.userId || (depth0 != null ? depth0.userId : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"userId","hash":{},"data":data}) : helper)))
    + ")</option>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.escapeExpression, alias2=depth0 != null ? depth0 : (container.nullContext || {});

  return "\n<div class=\"page-title clearfix\">\n  <h1 class=\"pull-left m-n p-n\">"
    + alias1(container.lambda(((stack1 = (depth0 != null ? depth0.identifiers : depth0)) != null ? stack1.applicantLegalName : stack1), depth0))
    + "</h1>\n  <div class=\"page-title-buttons-container\">\n    <div class=\"cs-link-container\"></div>\n  </div>\n</div>\n<div class=\"header-bar-container\"></div>\n<div class=\"breadcrumb-container\"></div>\n<div class=\"form-horizontal col-sm-9 col-sm-offset-1\">\n  <form class=\"application-form\">\n    <div class=\"row\">\n      <div class=\"col-sm-12\">\n        <div class=\"form-group\">\n          <div class=\"col-sm-9 col-sm-offset-3\">\n            <h3 class=\"m-t-n\">New Registration</h3>\n          </div>\n        </div>\n      </div>\n    </div>\n    <div class=\"row\">\n      <div class=\"col-sm-12\">\n        <div class=\"form-group required\">\n          <label class=\"control-label col-sm-3\">License Type</label>\n          <div class=\"col-sm-6\">\n            <select name=\"licenseTypeId\" class=\"form-control\" data-parsley-required=\"true\">\n              <option value=\"\">--</option>\n"
    + ((stack1 = helpers.each.call(alias2,(depth0 != null ? depth0.licenseTypes : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "            </select>\n          </div>\n        </div>\n      </div>\n    </div>\n    <div class=\"row\">\n      <div class=\"col-sm-12\">\n        <div class=\"form-group required\">\n          <label class=\"control-label col-sm-3\">Initiated Status Date</label>\n          <div class=\"col-sm-3\">\n            <div class=\"input-group\">\n              <input type=\"text\" name=\"initiatedStatusDate\" class=\"form-control date-picker\"\n                placeholder=\"Initiated Status Date\" data-parsley-required=\"true\" data-parsley-date=\"\"\n                data-parsley-trigger-after-failure=\"changeDate\"\n                data-parsley-errors-container=\"#statusDateErrorsContainer\" />\n              <div class=\"input-group-addon\">\n                <i class=\"fa fa-calendar\"></i>\n              </div>\n            </div>\n            <div id=\"statusDateErrorsContainer\"></div>\n          </div>\n        </div>\n      </div>\n    </div>\n    <div class=\"row primary-examiner-container\">\n      <div class=\"col-sm-12\">\n        <div class=\"form-group required\">\n          <label class=\"control-label col-sm-3\">Primary Examiner</label>\n          <div class=\"col-sm-6\">\n            <select name=\"filingExaminerId\" class=\"form-control\" data-parsley-required=\"true\">\n              <option value=\"\">--</option>\n"
    + ((stack1 = helpers.each.call(alias2,(depth0 != null ? depth0.examiners : depth0),{"name":"each","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "            </select>\n          </div>\n        </div>\n      </div>\n    </div>\n    <div class=\"row\">\n      <div class=\"col-sm-12\">\n        <div class=\"form-group\">\n          <label class=\"control-label col-sm-3\">Comments</label>\n          <div class=\"col-sm-6\">\n            <textarea type=\"text\" name=\"comments\" class=\"form-control\" placeholder=\"Comments\" rows=\"5\"></textarea>\n          </div>\n        </div>\n      </div>\n    </div>\n    <div class=\"row m-b-lg\">\n      <div class=\"col-sm-12\">\n        <div class=\"form-group\">\n          <div class=\"col-sm-9 col-sm-offset-3\">\n            <a href=\"#sec/organization/"
    + alias1(((helper = (helper = helpers.organizationId || (depth0 != null ? depth0.organizationId : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(alias2,{"name":"organizationId","hash":{},"data":data}) : helper)))
    + "/dashboard\" class=\"btn btn-default\">Cancel</a>\n            <button class=\"btn btn-primary btn-submit submit\">Submit</button>\n          </div>\n        </div>\n      </div>\n    </div>\n  </form>\n</div>";
},"useData":true});