/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Marionette = require('backbone.marionette');

var BreadcrumbView = require('common/views/BreadcrumbView');
var PaymentInvoiceView = require('common/views/PaymentInvoiceView');
var modelGet = require('common/util/modelGet');
var formatAsDollars = require('common/util/formatAsDollars');
var showErrorMessage = require('common/util/showErrorMessage');

var ExamHeaderBarView = require('../../../common/views/ExamHeaderBarView');
var GetExamPayment = require('../../services/GetExamPayment');

require('./EntityRemitExamPaymentTaskView.css');
var tmplEntityRemitExamPaymentTaskView = require('./EntityRemitExamPaymentTaskView.hbs');

var PAYMENT_DELETED_TITLE = 'This Payment Is No Longer Valid';
var PAYMENT_DELETED_MESSAGE =
  'The payment has either already been made, been rescheduled, or been deleted. ' +
  'Please return to the home page to see the most up to date set of payments ' +
  'which must be submitted.';

module.exports = Marionette.LayoutView.extend({
  template: tmplEntityRemitExamPaymentTaskView,

  className: 'entity-remit-exam-payment-task-view container-fluid',

  behaviors: {
    ReadOnlyFormBehavior: {}
  },

  ui: {
    headerBar: '.header-bar',
    breadcrumb: '.breadcrumb-container',
    paymentInvoice: '.payment-invoice'
  },

  regions: {
    headerBar: '@ui.headerBar',
    breadcrumb: '@ui.breadcrumb',
    paymentInvoice: '@ui.paymentInvoice'
  },

  events: {
    'click @ui.submit': 'onClickSubmit'
  },

  templateHelpers: function() {
    return {
      PAYMENT_DELETED_TITLE: PAYMENT_DELETED_TITLE,
      PAYMENT_DELETED_MESSAGE: PAYMENT_DELETED_MESSAGE
    };
  },

  onBeforeRender: function() {
    var rawAmt = this.model.get('exam').paymentAmount;
    this.model.get('exam').paymentAmount = formatAsDollars(parseFloat(rawAmt));
  },

  onBeforeShow: function() {
    var entityId = modelGet(this.model, 'exam.entityId');
    var entityName = modelGet(this.model, 'exam.entityName');
    var taskName = modelGet(this.model, 'task.taskType.description');
    var examPaymentId = modelGet(this.model, 'examPayment.examPaymentId');
    var isExamPaymentDeleted = modelGet(this.model, 'examPayment.isDeleted');
    var examPayment = this.model.get('examPayment');

    this.showChildView(
      'headerBar',
      new ExamHeaderBarView({
        model: new Backbone.Model(this.model.get('exam'))
      })
    );

    this.showChildView(
      'breadcrumb',
      new BreadcrumbView({
        breadcrumbs: [
          {
            link: '#ext/nondep/dashboard',
            text: 'Home'
          },
          {
            link: '#ext/nondep/entity/' + entityId + '/dashboard',
            text: entityName
          },
          {
            text: taskName
          }
        ]
      })
    );

    var totalAmount = examPayment.amount ? parseFloat(examPayment.amount) : 0;
    totalAmount += examPayment.feeAmount ? parseFloat(examPayment.feeAmount) : 0;

    this.showChildView(
      'paymentInvoice',
      new PaymentInvoiceView({
        isReadOnly: isExamPaymentDeleted,
        onBeforeSubmit: this.onBeforeSubmitPayment.bind(this),
        model: new Backbone.Model({
          department: 'NON-DEPOSITORY',
          paymentObjectType: 'EXAM_PAYMENT',
          paymentObjectId: examPaymentId,
          taskId: this.model.get('taskId'),
          items: [
            {
              description: examPayment.description,
              quantity: 1,
              unitPrice: totalAmount
            }
          ]
        })
      })
    );
  },

  // Double check that the payment is still valid at time of submission.
  onBeforeSubmitPayment: function(e) {
    var defer = $.Deferred();
    var examPaymentId = modelGet(this.model, 'examPayment.examPaymentId');
    GetExamPayment(examPaymentId)
      .done(function(examPayment) {
        if (!examPayment.isDeleted) {
          defer.resolve();
        } else {
          defer.reject();
          showErrorMessage({
            errorCode: 'payment.not.found',
            errorTitle: PAYMENT_DELETED_TITLE,
            errorMessage: PAYMENT_DELETED_MESSAGE
          });
        }
      })
      .fail(function(err) {
        defer.reject();
      });

    return defer.promise();
  }
});
