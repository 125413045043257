/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var Backgrid = require('backgrid');

var FileUploadCell = require('psa-backgrid/FileUploadCell');
var FileUploadModel = require('psa-backbone/models/FileUploadModel');
var FileUploadCollection = require('psa-backbone/models/FileUploadCollection');

var FileUploadView = require('../../views/FileUploadView');

module.exports = FileUploadCell.extend({
  uploadSingleLabel: 'Attach File...',
  uploadMultipleLabel: 'Attach Files...',
  showError: false,
  btnClass: 'btn-primary',
  returnData: function() {},

  initialize: function(args) {
    var self = this;
    this.column = args.column;
    var cellVal = args.model.get(this.column.get('name'));
    var readOnly = Backgrid.callByNeed(this.isReadOnly, this.column, this.model);

    if (cellVal) {
      this.fileCollection = new FileUploadCollection();

      // Array of files
      if (_.isArray(cellVal)) {
        cellVal.map(function(file) {
          var newModel = new FileUploadModel({
            uuid: file.uuid,
            fileId: file.fileId,
            fileName: file.fileName,
            isReadOnly: readOnly ? readOnly : false
          });
          if (file.fileId) {
            self.fileCollection.add(newModel);
          }
        });
      }
      // Single File
      else {
        var fileModel = new FileUploadModel({
          uuid: cellVal.uuid,
          fileId: cellVal.fileId,
          fileName: cellVal.fileName,
          isReadOnly: readOnly ? readOnly : false
        });
        if (cellVal.fileId) {
          this.fileCollection.add(fileModel);
        }
      }
      self.model.unset(self.column.get('name')); //bug in backbone model.set where the objects are merged rather than replaced
      self.model.set(self.column.get('name'), _.pluck(self.fileCollection.models, 'attributes'));
    }

    var metadata = Backgrid.callByNeed(this.metadata, this.column, this.model);

    this.fileUploaderCell = new FileUploadView({
      el: this.$el,
      isReadOnly: readOnly,
      collection: this.fileCollection,
      metadata: metadata,
      allowMultipleFiles: this.allowMultipleFiles,
      uploadSingleLabel: this.uploadSingleLabel,
      uploadMultipleLabel: this.uploadMultipleLabel,
      showError: this.showError,
      btnClass: this.btnClass,
      validator: this.validator,
      onFileRemove: this.onFileRemove,
      returnData: this.returnData,
      childEvents: {
        render: function() {
          this.collection.bind('update', function() {
            self.model.unset(self.column.get('name')); //bug in backbone model.set where the objects are merged rather than replaced
            self.model.set(self.column.get('name'), _.pluck(this.models, 'attributes'));
          });
        }
      }
    });
  },

  render: function() {
    this.$el.addClass(this.column.get('name'));
    return FileUploadCell.prototype.render.apply(this, arguments);
  }
});
