/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var config = require('../../config/Config');
var cleanUpResponse = require('common/util/cleanUpResponse');

module.exports = function() {
  var defer = $.Deferred();

  $.soap({
    url: config.getAnonymousDoBSUrl(),
    body: {
      GetInstitutionTypes: {
        $xmlns: 'http://dobs.pa.gov/instsearch/instsearch/db/1.0'
      }
    },
    blueprintArrays: ['GetInstitutionTypesResponse.IS_LU_InstitutionType'],
    blueprint: {
      instutitonTypes: ['$..IS_LU_InstitutionType']
    }
  })
    .done(function(response) {
      cleanUpResponse(response);

      defer.resolve(response.instutitonTypes);
    })
    .fail(function(err) {
      defer.reject();
    });
  return defer.promise();
};

