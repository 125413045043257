/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Backbone = require('backbone');
var Marionette = require('backbone.marionette');
var Syphon = require('backbone.syphon');
var Radio = require('backbone.radio');
var Ladda = require('ladda');
var moment = require('moment');

var Session = require('psa-core/Session');
var UIUtil = require('psa-core/util/ui');

var modelGet = require('common/util/modelGet');
var HeaderBarView = require('common/views/HeaderBarView');
var BreadcrumbView = require('common/views/BreadcrumbView');
var FileUploadView = require('common/views/FileUploadView');
var ConfirmationModalView = require('common/views/ConfirmationModalView');

var SubmitExamCreateRFITask = require('../../services/SubmitExamCreateRFITask');

var tmplSecExamCreateRFITaskView = require('./SecExamCreateRFITaskView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplSecExamCreateRFITaskView,

  className: 'sec-exam-create-rfi-task-view container-fluid',

  ui: {
    headerBar: '.header-bar',
    breadcrumb: '.breadcrumb-container',
    examCreateRFIForm: '.exam-create-rfi-form',
    internalComments: 'textArea[name="internalComments"]',
    externalComments: 'textArea[name="externalComments"]',
    rfiRequestContainer: '.rfi-request-container',
    submit: '.submit',
    skipRFITask: '.skip-rfi-task',
    missingFile: '.missing-file'
  },

  regions: {
    headerBar: '@ui.headerBar',
    breadcrumb: '@ui.breadcrumb',
    rfiRequestContainer: '@ui.rfiRequestContainer'
  },

  behaviors: {
    TaskBehavior: {}
  },

  events: {
    'change @ui.action': 'onChangeAction',
    'click @ui.submit': 'onClickSubmit',
    'click @ui.skipRFITask': 'onClickSkipRFITask'
  },

  onBeforeRender: function() {
    var exam = this.model.get('exam');
    // checking if the exam initialOpenedDate is before June 5, 2024 to decided whether to display the skip RFI button
    var showSkipRFI = moment(exam.initialOpenedDate, 'YYYY-MM-DD').isBefore(moment('2024-06-05', 'YYYY-MM-DD')) ? 0 : 1;
    exam.showSkipRFI = showSkipRFI;
  },

  onBeforeShow: function() {
    var exam = this.model.get('exam');

    var headerBarColumns = [
      {
        name: 'applicantLegalName',
        label: 'Applicant Legal Name',
        minWidth: '160px',
        maxWidth: '300px'
      },
      {
        name: 'crdNumber',
        label: 'CRD Number',
        minWidth: '160px',
        maxWidth: '300px'
      },
      {
        name: 'examNumber',
        label: 'Exam Number',
        minWidth: '160px',
        maxWidth: '300px'
      },
      {
        name: 'examType',
        label: 'Exam Type',
        minWidth: '160px',
        maxWidth: '300px'
      },
      {
        name: 'primaryExaminer',
        label: 'Primary Examiner',
        minWidth: '160px',
        maxWidth: '300px'
      }
    ];

    this.showChildView(
      'headerBar',
      new HeaderBarView({
        columns: new Backbone.Collection(headerBarColumns),
        model: new Backbone.Model(exam)
      })
    );

    this.showChildView(
      'breadcrumb',
      new BreadcrumbView({
        breadcrumbs: [
          {
            link: '#sec/dashboard',
            text: 'Home'
          },
          {
            text: modelGet(this.model, 'task.taskType.description')
          }
        ]
      })
    );

    var internalComments;
    if (exam.internalComments) {
      internalComments = '\n\n' + exam.internalComments;
    } else {
      internalComments = '';
    }

    this.ui.internalComments.val(internalComments);

    this.rfiRequestDoc = new FileUploadView({
      collection: new Backbone.Collection(),
      isRequired: true,
      metadata: {
        documentTypeId: 30014
      }
    });
    this.showChildView('rfiRequestContainer', this.rfiRequestDoc);
  },

  onClickSubmit: function(e) {
    e.preventDefault();

    this.validate().done(
      function() {
        var l = Ladda.create(this.ui.submit[0]);
        l.start();
        var formData = this.getFormData();
        formData.decisionId = '1';

        SubmitExamCreateRFITask(formData)
          .done(function() {
            Radio.channel('navigate').trigger('show:int:sc:dashboard');
          })
          .fail(function() {
            l.stop();
          });
      }.bind(this)
    );
  },

  onClickSkipRFITask: function(e) {
    e.preventDefault();
    var formData = {
      examId: modelGet(this.model, 'exam.examId'),
      taskId: modelGet(this.model, 'task.taskId'),
      decisionId: '2'
    };

    var message =
      'Are you sure you want skip the Create/Issue RFI Document task? This will move the exam workflow to Conduct Exam.';
    UIUtil.showModal({
      title: 'Confirm Skip RFI',
      view: new ConfirmationModalView({
        confirmText: 'Skip RFI',
        model: new Backbone.Model({
          message: message,
          onConfirm: function() {
            return SubmitExamCreateRFITask(formData);
          },
          onConfirmDone: function() {
            Radio.channel('navigate').trigger('show:int:sc:dashboard');
          }
        })
      })
    });
  },

  removeValidationErrors: function() {
    this.ui.missingFile.hide();
  },

  validate: function() {
    this.ui.examCreateRFIForm.parsley().reset();
    this.rfiRequestDoc.removeValidationErrors();

    return $.when.apply($, [this.ui.examCreateRFIForm.parsley().whenValidate(), this.validateExtCommentsRFIRequest()]);
  },

  validateExtCommentsRFIRequest: function() {
    var defer = $.Deferred();
    var hasOneSuccessfulFileOrComment = true;
    var hasComments = !!this.ui.externalComments.val();
    var uploads = this.rfiRequestDoc.collection;

    if (!uploads.length && !hasComments) {
      hasOneSuccessfulFileOrComment = false;
    } else {
      var hasSuccessfulFile;
      if (uploads) {
        hasSuccessfulFile = uploads.some(function(model) {
          return model.get('fileId');
        });
      }
      hasOneSuccessfulFileOrComment = hasComments || hasSuccessfulFile;
    }

    if (!hasOneSuccessfulFileOrComment) {
      this.ui.missingFile.show();
      defer.reject();
    } else {
      defer.resolve();
    }

    return defer.promise();
  },

  getFormData: function() {
    var formData = Syphon.serialize(this);

    formData.examId = modelGet(this.model, 'exam.examId');
    formData.taskId = modelGet(this.model, 'task.taskId');

    var timestamp = moment().format('MM/DD/YYYY h:mm A');
    var userName = Session.user.description;

    formData.internalComments = '[' + userName + ' - ' + timestamp + '] \n' + formData.internalComments;
    formData.rfiRequestDocumentId = this.rfiRequestDoc.getFirstFileId();

    return formData;
  }
});
