/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Marionette = require('backbone.marionette');

var modelGet = require('common/util/modelGet');

var LicenseContactsCollectionView = require('./LicenseContactsCollectionView');

var parsleyUtil = require('common/util/parsleyUtil');

require('../styles/LicenseContactsSectionView.css');
var tmplLicenseContactsSectionView = require('../templates/LicenseContactsSectionView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplLicenseContactsSectionView,

  className: 'license-contacts-section-view',

  ui: {
    addContactsContainer: '.add-contacts-container',
    addContact: '.btn-add-contact',
    addExistingContact: '.btn-add-existing-contact',
    existingContactSelect: '.existing-contact-select',
    contactCollectionContainer: '.contact-collection-container',
    missingContactType: '.missing-contact-type',
    deletedContactCollectionContainer: '.deleted-contact-collection-container'
  },

  regions: {
    contactCollectionContainer: '@ui.contactCollectionContainer',
    deletedContactCollectionContainer: '@ui.deletedContactCollectionContainer'
  },

  events: {
    'click @ui.addContact': 'onClickAddContact',
    'click @ui.addExistingContact': 'onClickAddExistingContact'
  },

  templateHelpers: function() {
    return {
      isReadOnly: this.options.isReadOnly
    };
  },

  onBeforeShow: function() {
    this.contacts = new LicenseContactsCollectionView({
      viewMode: this.options.viewMode,
      isAmendment: this.options.isAmendment,
      isReadOnly: this.options.isReadOnly,
      contactTypes: this.model.get('licenseContactTypes'),
      requiredContactTypes: this.model.get('requiredContactTypes'),
      collection: new Backbone.Collection(this.model.get('contacts')),
      model: new Backbone.Model({ deletedContacts: this.model.get('deletedContacts') })
    });
    this.showChildView('contactCollectionContainer', this.contacts);

    if (this.options.isReadOnly) {
      this.ui.addContactsContainer.hide();
    }
  },

  onClickAddContact: function(e) {
    e.preventDefault();
    this.contacts.collection.unshift({});
  },

  onClickAddExistingContact: function(e) {
    e.preventDefault();

    var contactIndex = this.ui.existingContactSelect.val();

    if (contactIndex) {
      var contact = modelGet(this.model, 'existingContacts[' + contactIndex + ']');
      if (contact) {
        this.contacts.collection.unshift(contact);
      }
    }
  },

  getFormData: function() {
    return this.contacts.getFormData();
  },

  validate: function(config) {
    var defer = $.Deferred();
    this.ui.contactCollectionContainer.parsley().reset();

    if (config.type === 'save') {
      // Turn off required field validation for this form
      this.ui.missingContactType.hide();
      parsleyUtil.disableRequiredValidation(this.ui.contactCollectionContainer);
    } else {
      // Turn on required field validation for this form
      if (!this.contacts.hasOneOfEachContacts()) {
        this.ui.missingContactType.show();
        defer.reject();
      } else {
        this.ui.missingContactType.hide();
      }

      if (!this.contacts.hasOneOfEachContacts()) {
        defer.reject();
      }

      parsleyUtil.enableRequiredValidation(this.ui.contactCollectionContainer);
    }

    this.ui.contactCollectionContainer
      .parsley()
      .whenValidate()
      .done(function() {
        defer.resolve();
      })
      .fail(function() {
        defer.reject();
      });
    return defer.promise();
  }
});
