/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */
var _ = require('underscore');
var Backbone = require('backbone');
var Marionette = require('backbone.marionette');
var Radio = require('backbone.radio');

var BreadcrumbView = require('common/views/BreadcrumbView');
var HeaderBarView = require('common/views/HeaderBarView');
var GetFilingInvoice = require('../../common/services/GetFilingInvoice');
var StartFilingReviewProcess = require('../../common/services/StartFilingReviewProcess');
var DepFilingPaymentView = require('../../common/views/DepFilingPaymentView');

require('../styles/DepDraftFilingView.css');
var tmplDepDraftFilingWithFee = require('../templates/DepDraftFilingWithFee.hbs');
var tmplDepDraftFilingWithoutFee = require('../templates/DepDraftFilingWithoutFee.hbs');

module.exports = Marionette.LayoutView.extend({
  getTemplate: function() {
    var viewMode = this.options.viewMode;
    var isExternal = viewMode === 'ext';
    if (isExternal) {
      return tmplDepDraftFilingWithFee;
    } else {
      return tmplDepDraftFilingWithoutFee;
    }
  },

  className: 'dep-draft-filing-view container-fluid',

  ui: {
    headerBar: '.header-bar',
    breadcrumb: '.breadcrumb-container',
    filingContainer: '.filing-container',
    paymentContainer: '.payment-container',
    paymentTab: '.payment-tab',
    thePayment: '.the-payment'
  },

  regions: {
    headerBar: '@ui.headerBar',
    breadcrumb: '@ui.breadcrumb',
    filingContainer: '@ui.filingContainer',
    paymentContainer: '@ui.paymentContainer',
    thePayment: '@ui.thePayment'
  },

  templateHelpers: function() {
    var filingDetails = this.model.get('filingDetails') || {};
    var filingTypeId = filingDetails.filingTypeId;
    // Filing type is CU Audit, Trust Audit, Trust Call Report or CIF/CTF
    var isSubmission = _.includes(['111', '205', '305', '306', '307'], filingTypeId);

    return {
      isSubmission: isSubmission
    };
  },

  initialize: function(options) {
    this.filingFormView = options.filingFormView;
  },

  onBeforeShow: function() {
    var self = this;
    var viewMode = this.options.viewMode;
    var isExternal = viewMode === 'ext';
    var filingId = this.model.get('filingId');
    var filingDetails = this.model.get('filingDetails') || {};
    var entityId = filingDetails.entityId;
    var entityName = filingDetails.entityName;
    var filingType = filingDetails.filingType;
    var filingTypeId = filingDetails.filingTypeId;
    var isEntityFiling = !!entityId;
    // Filing type is CU Audit, Trust Audit, Trust Call Report or CIF/CTF
    var isSubmission = _.includes(['111', '205', '305', '306', '307'], filingTypeId);

    if (filingDetails.filingFee === 0 || filingType === 'Generic Submission' || filingType === 'Location Activities') {
      $(this.ui.thePayment).css('display', 'none');
    }
    var headerBarColumns = [
      {
        name: 'filingType',
        label: isSubmission ? 'Type' : 'Filing Type',
        minWidth: '160px',
        maxWidth: '350px',
        overflow: 'truncate'
      }
    ];

    if (isEntityFiling) {
      headerBarColumns.unshift(
        {
          name: 'entityName',
          label: 'Entity Name',
          minWidth: '160px',
          maxWidth: '300px'
        },
        {
          name: 'entityType',
          label: 'Entity Type',
          minWidth: '160px'
        }
      );
    }

    this.showChildView(
      'headerBar',
      new HeaderBarView({
        columns: new Backbone.Collection(headerBarColumns),
        model: new Backbone.Model(filingDetails)
      })
    );

    var breadcrumbs = [
      {
        link: isExternal ? '#ext/dep/dashboard' : '#dep/dashboard',
        text: 'Home'
      },
      {
        text: isSubmission ? filingType : filingType + ' Filing'
      }
    ];

    if (isEntityFiling) {
      breadcrumbs.splice(1, 0, {
        link: isExternal ? '#ext/dep/entity/' + entityId + '/dashboard' : '#dep/entity/' + entityId + '/dashboard',
        text: entityName
      });
    }

    this.showChildView(
      'breadcrumb',
      new BreadcrumbView({
        breadcrumbs: breadcrumbs
      })
    );

    this.showChildView('filingContainer', this.filingFormView);

    if (isExternal) {
      Radio.channel('dp:draft-filing').on('proceed-to-payment', function(l) {
        GetFilingInvoice(filingId)
          .done(function(invoiceItems) {
            var totalPrice = 0;

            _.each(invoiceItems, function(price) {
              totalPrice += price.unitPrice;
            });

            if (totalPrice > 0) {
              self.showChildView(
                'paymentContainer',
                new DepFilingPaymentView({
                  viewMode: 'new',
                  model: new Backbone.Model({
                    filingId: filingId,
                    invoiceItems: invoiceItems
                  })
                })
              );
              self.ui.paymentTab.tab('show');
              self.ui.filingContainer.removeClass('active');
              self.ui.paymentContainer.addClass('active');
              l.stop();
            } else {
              var data = {};
              data.filingId = filingId;

              return StartFilingReviewProcess(data).done(function() {
                Radio.channel('navigate').trigger('show:ext:dp:dashboard');
              });
            }
          })
          .fail(function() {
            l.stop();
          });
      });
    } else {
      Radio.channel('dp:draft-filing').on('submit', function(l) {
        StartFilingReviewProcess({ filingId: filingId })
          .done(function() {
            Radio.channel('navigate').trigger('show:int:dp:entity', entityId);
          })
          .fail(function() {
            l.stop();
          });
      });
    }
  },

  onDestroy: function() {
    Radio.channel('dp:draft-filing').reset();
  }
});
