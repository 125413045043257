/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var cleanUpResponse = require('common/util/cleanUpResponse');
var momentUtil = require('common/util/momentUtil');

module.exports = function(mrbaId) {
  var deferred = $.Deferred();

  $.soap({
    body: {
      GetMrba: {
        $xmlns: 'http://dobs.pa.gov/dp/mrba/bpm/1.0',
        MrbaID: mrbaId
      }
    },
    blueprint: {
      mrbaId: '$..ID',
      entityId: '$..EntityID',
      entityName: '$..EntityName',
      entityCategoryId: '$..EntityCategoryID',
      typeId: '$..TypeID',
      type: '$..DP_EntityMRBA.Type',
      number: '$..Number',
      examAsOfDate: '$..ExamAsOfDate',
      categoryId: '$..CategoryID',
      category: '$..DP_EntityMRBA.Category',
      agencyId: '$..AgencyID',
      agency: '$..DP_EntityMRBA.Agency',
      dueDate: '$..DueDate',
      statusId: '$..StatusID',
      status: '$..DP_EntityMRBA.Status',
      description: '$..Description',
      historyId: '$..HistoryID',
      history: '$..DP_EntityMRBA.History',
      latestResponseDueDate: '$..LatestResponseDueDate',
      responseHistory: '$..ResponseHistory',
      latestReceivedDate: '$..LatestReceivedDate',
      receivedHistory: '$..ReceivedHistory',
      comments: '$..Comments',
      isDeleted: '$..IsDeleted'
    }
  })
    .done(function(response) {
      cleanUpResponse(response);
      var dateAttributes = ['examAsOfDate', 'dueDate', 'latestResponseDueDate', 'latestReceivedDate'];

      momentUtil.formatDateAttributes(response, dateAttributes, 'YYYY-MM-DD', 'MM/DD/YYYY');
      deferred.resolve(response);
    })
    .fail(function(err) {
      deferred.reject();
    });

  return deferred.promise();
};
