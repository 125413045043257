var Handlebars = require("handlebars/dist/handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "          <option value=\""
    + alias4(((helper = (helper = helpers.externalUserId || (depth0 != null ? depth0.externalUserId : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"externalUserId","hash":{},"data":data}) : helper)))
    + "\">"
    + alias4(((helper = (helper = helpers.userDisplay || (depth0 != null ? depth0.userDisplay : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"userDisplay","hash":{},"data":data}) : helper)))
    + "</option>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression, alias5=container.lambda;

  return "\n<div class=\"page-title clearfix\">\n  <h1 class=\"m-n p-n\">"
    + alias4(((helper = (helper = helpers.entityName || (depth0 != null ? depth0.entityName : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"entityName","hash":{},"data":data}) : helper)))
    + "</h1>\n</div>\n<div class=\"page-subtitle\">\n  <h2>Exam</h2>\n  <div class=\"page-subtitle-details\">"
    + alias4(alias5(((stack1 = (depth0 != null ? depth0.exam : depth0)) != null ? stack1.examType : stack1), depth0))
    + " - "
    + alias4(alias5(((stack1 = (depth0 != null ? depth0.exam : depth0)) != null ? stack1.leadAgency : stack1), depth0))
    + "</div>\n</div>\n<div class=\"breadcrumb-container\"></div>\n<div class=\"col-sm-10 col-sm-offset-1\">\n  <div class=\"row\">\n    <div class=\"col-sm-8 col-md-9 col-sm-offset-4 col-md-offset-3\">\n      <h3 class=\"m-t-xs m-b-lg\">Manage Owner</h3>\n    </div>\n  </div>\n  <div class=\"form-horizontal form-static\">\n    <div class=\"form-group m-b-n\">\n      <label class=\"control-label col-sm-4 col-md-3\">Current Exam Owner</label>\n      <div class=\"form-control-static col-sm-8 col-md-9\">"
    + alias4((helpers["default"] || (depth0 && depth0["default"]) || alias2).call(alias1,((stack1 = ((stack1 = (depth0 != null ? depth0.exam : depth0)) != null ? stack1.examOwner : stack1)) != null ? stack1.displayName : stack1),"--",{"name":"default","hash":{},"data":data}))
    + "</div>\n    </div>\n  </div>\n  <form class=\"form-horizontal manage-owner-form\">\n    <div class=\"form-group required\">\n      <label class=\"control-label col-sm-4 col-md-3\">Should This Exam Have an Owner?</label>\n      <div class=\"col-md-4\">\n        <label class=\"radio-inline\">\n          <input type=\"radio\" name=\"hasOwner\" value=\"1\" data-parsley-required=\"true\" checked />\n          Yes\n        </label>\n        <label class=\"radio-inline\">\n          <input type=\"radio\" name=\"hasOwner\" value=\"0\" data-parsley-required=\"true\" />\n          No\n        </label>\n      </div>\n    </div>\n    <div class=\"form-group required new-owner\">\n      <label class=\"control-label col-sm-4 col-md-3\">New Owner</label>\n      <div class=\"col-md-4\">\n        <select name=\"ownerExternalUserId\" class=\"form-control\" data-parsley-required=\"true\">\n          <option value=\"\">--</option>\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.entityUsers : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "        </select>\n      </div>\n    </div>\n  </form>\n  <div class=\"row\">\n    <div class=\"col-sm-8 col-md-9 col-sm-offset-4 col-md-offset-3\">\n      <a class=\"btn btn-default\" href=\"#dep/entity/"
    + alias4(((helper = (helper = helpers.entityId || (depth0 != null ? depth0.entityId : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"entityId","hash":{},"data":data}) : helper)))
    + "/exam/"
    + alias4(alias5(((stack1 = (depth0 != null ? depth0.exam : depth0)) != null ? stack1.id : stack1), depth0))
    + "/dashboard\">Cancel</a>\n      <button class=\"btn btn-primary btn-submit\">Submit</button>\n    </div>\n  </div>\n</div>";
},"useData":true});