/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var moment = require('moment');

var cleanUpResponse = require('common/util/cleanUpResponse');

module.exports = function(entityId) {
  var deferred = $.Deferred();

  $.soap({
    body: {
      GetEntityAuditsByEntity: {
        $xmlns: 'http://dobs.pa.gov/dp/audit/bpm/1.0',
        EntityID: entityId
      }
    },
    blueprintArrays: ['GetEntityAuditsByEntityResponse.Response.DP_EntityAudit'],
    blueprint: {
      audits: [
        '$..DP_EntityAudit',
        {
          id: '$..ID',
          auditDate: '$..AuditDate',
          receivedDate: '$..ReceivedDate',
          auditType: '$..AuditType',
          entityId: '$..EntityID',
          opinion: '$..Opinion',
          auditFirm: '$..AuditFirm'
        }
      ]
    }
  })
    .done(function(response) {
      cleanUpResponse(response);
      _.each(response.audits, function(audit) {
        if (!audit.auditType) {
          audit.auditType = '--';
        }
        if (!audit.auditFirm) {
          audit.auditFirm = '--';
        }
        if (!audit.opinion) {
          audit.opinion = '--';
        }
        if (audit.auditDate) {
          audit.auditDate = moment(audit.auditDate, 'YYYY-MM-DD');
        }
        if (audit.receivedDate) {
          audit.receivedDate = moment(audit.receivedDate, 'YYYY-MM-DD');
        }
      });

      deferred.resolve(response.audits);
    })
    .fail(function(err) {
      deferred.reject();
    });

  return deferred.promise();
};
