/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Marionette = require('backbone.marionette');
var Syphon = require('backbone.syphon');

var parsleyUtil = require('common/util/parsleyUtil');
var FileUploadView = require('common/views/FileUploadView');
var makeUploadCollection = require('common/util/makeUploadCollection');

var tmplDepBankAcquisitionMergerNameReservationsSectionView = require('./DepBankAcquisitionMergerNameReservationsSectionView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplDepBankAcquisitionMergerNameReservationsSectionView,

  className: 'dep-bank-acquisition-merger-name-reservation-section-view',

  behaviors: {
    ReadOnlyFormBehavior: {},
    RadioGroupToggleBehavior: {
      radios: [
        {
          target: '.proposed-name',
          radioGroup: 'input[name="nameTypeId"]',
          valueToShow: '1'
        },
        {
          target: '.interim-name',
          radioGroup: 'input[name="nameTypeId"]',
          valueToShow: '2'
        }
      ]
    }
  },

  ui: {
    nameResForm: '.name-res-form',
    proposedNameContainer: '.proposed-name-container',
    interimNameContainer: '.interim-name-container'
  },

  regions: {
    proposedNameContainer: '@ui.proposedNameContainer',
    interimNameContainer: '@ui.interimNameContainer'
  },

  templateHelpers: function() {
    return {
      isReadOnly: this.options.isReadOnly
    };
  },

  onBeforeShow: function() {
    var filingContent = this.model.get('filingContent') || {};
    Syphon.deserialize(this, filingContent);

    this.proposedNameDocument = new FileUploadView({
      isReadOnly: this.options.isReadOnly,
      allowMultipleFiles: false,
      isRequired: false,
      collection: makeUploadCollection(filingContent.proposedNameDocument),
      metadata: {
        documentTypeId: 20001
      }
    });
    this.showChildView('proposedNameContainer', this.proposedNameDocument);

    this.interimNameDocument = new FileUploadView({
      isReadOnly: this.options.isReadOnly,
      allowMultipleFiles: false,
      isRequired: false,
      collection: makeUploadCollection(filingContent.interimNameDocument),
      metadata: {
        documentTypeId: 20001
      }
    });
    this.showChildView('interimNameContainer', this.interimNameDocument);
  },

  validate: function(config) {
    var formData = Syphon.serialize(this);

    if (config.type === 'save') {
      // Turn off required field validation for this form
      parsleyUtil.disableRequiredValidation(this.ui.nameResForm);
    } else {
      // Turn on required field validation for this form
      parsleyUtil.enableRequiredValidation(this.ui.nameResForm);
    }

    var validationPromises = [this.ui.nameResForm.parsley().whenValidate()];
    if (config.type !== 'save') {
      if (formData.nameTypeId === '1') {
        validationPromises.push(this.proposedNameDocument.validate());
      } else if (formData.nameTypeId === '2') {
        validationPromises.push(this.interimNameDocument.validate());
      }
    }
    return $.when.apply($, validationPromises);
  },

  getFormData: function() {
    var formData = Syphon.serialize(this);
    formData.proposedNameDocumentId = formData.nameTypeId === '1' ? this.proposedNameDocument.getFirstFileId() : null;
    formData.interimNameDocumentId = formData.nameTypeId === '2' ? this.interimNameDocument.getFirstFileId() : null;
    return formData;
  }
});
