/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Backbone = require('backbone');
var Marionette = require('backbone.marionette');
var Syphon = require('backbone.syphon');

var modelGet = require('common/util/modelGet');
var AddressView = require('common/views/AddressView');

var tmplCorpFinRegRegistrantFormView = require('../templates/CorpFinRegRegistrantFormView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplCorpFinRegRegistrantFormView,

  className: 'corp-fin-reg-registrant-form-view',

  ui: {
    address: '.address',
    fileType: '.file-type'
  },

  regions: {
    address: '@ui.address'
  },

  events: {
    'change @ui.fileType': 'onChangeFileType'
  },

  templateHelpers: function() {
    var isNewRegistration = this.options.isNewRegistration;
    return {
      isNewRegistration: isNewRegistration,
      recordType: modelGet(this.model, 'record.recordType')
    };
  },

  onBeforeShow: function() {
    var record = this.model.get('record') || {};
    this.showChildView(
      'address',
      new AddressView({
        model: new Backbone.Model(),
        objectName: 'issuerAddress',
        horizontal: true,
        isRequired: true,
        startingCountry: modelGet(this.model, 'record.issuerAddress.country'),
        startingPostalCode: modelGet(this.model, 'record.issuerAddress.postalCode'),
        startingCounty: modelGet(this.model, 'record.issuerAddress.county')
      })
    );

    Syphon.deserialize(this, record);
  },

  getFormData: function() {
    var record = this.model.get('record') || {};
    var formData = Syphon.serialize(this);
    formData.currentRecordName = record.recordName;
    formData.registrationId = this.model.get('recordId');

    return formData;
  },

  onChangeFileType: function() {
    var fileTypeId = this.ui.fileType.val();
    this.triggerMethod('fileType:changed', fileTypeId);
  }
});
