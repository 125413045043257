/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var Marionette = require('backbone.marionette');
var Syphon = require('backbone.syphon');

var tmplTimeTrkOpenObjectSelectionModalView = require('../templates/TimeTrkOpenObjectSelectionModalView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplTimeTrkOpenObjectSelectionModalView,

  className: 'time-trk-open-object-selection-modal-view',

  ui: {
    submit: '.btn-submit'
  },

  events: {
    'click @ui.submit': 'onClickSubmit'
  },

  onClickSubmit: function(e) {
    e.preventDefault();
    var objects = this.model.get('objects');
    var object = Syphon.serialize(this);

    var selection = _.findWhere(objects, { objectId: object.objectId });

    if (selection.objectId) {
      this.triggerMethod('modal:close', selection);
    } else {
      this.triggerMethod('modal:close');
    }
  }
});
