/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var Backbone = require('backbone');
var Marionette = require('backbone.marionette');
var Syphon = require('backbone.syphon');
var Radio = require('backbone.radio');
var Ladda = require('ladda');
var moment = require('moment');

var Session = require('psa-core/Session');
var FileUploadCollection = require('psa-backbone/models/FileUploadCollection');

var modelGet = require('common/util/modelGet');
var toggleForm = require('common/util/toggleForm');
var makeUploadCollection = require('common/util/makeUploadCollection');
var HeaderBarView = require('common/views/HeaderBarView');
var BreadcrumbView = require('common/views/BreadcrumbView');
var FileUploadView = require('common/views/FileUploadView');

var SubmitExamRecordResultsTask = require('../../services/SubmitExamRecordResultsTask');

var tmplSecRecordExamResultsTaskView = require('./SecRecordExamResultsTaskView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplSecRecordExamResultsTaskView,

  className: 'sec-record-exam-results-task-view container-fluid',

  ui: {
    headerBar: '.header-bar',
    breadcrumb: '.breadcrumb-container',
    recordExamResultsForm: '.record-exam-results-form',
    action: '.action',
    decision: '.decision',
    isPendingDeficiency: '.is-pending-deficiency',
    isPendingClose: '.is-pending-close',
    deficiencyDocumentContainer: '.deficiency-document-container',
    finalExaminationReportContainer: '.final-examination-report-container',
    submit: '.submit'
  },

  regions: {
    headerBar: '@ui.headerBar',
    breadcrumb: '@ui.breadcrumb',
    deficiencyDocumentContainer: '@ui.deficiencyDocumentContainer',
    finalExaminationReportContainer: '@ui.finalExaminationReportContainer'
  },

  behaviors: {
    TaskBehavior: {}
  },

  events: {
    'change @ui.decision': 'onChangeDecision',
    'change @ui.action': 'onChangeAction',
    'click @ui.submit': 'onClickSubmit'
  },

  templateHelpers: function() {
    var taskTypeId = modelGet(this.model, 'task.taskType.id', {});
    var examinerTask = taskTypeId === '30016';
    var chiefTask = taskTypeId === '30017';
    var directorTask = taskTypeId === '30018';
    var depSecTask = taskTypeId === '30019';

    return {
      showAction: chiefTask || directorTask || depSecTask || examinerTask
    };
  },

  onBeforeRender: function() {
    var taskTypeId = modelGet(this.model, 'task.taskType.id', {});
    var examinerTask = taskTypeId === '30016';
    var chiefTask = taskTypeId === '30017';
    var directorTask = taskTypeId === '30018';
    var depSecTask = taskTypeId === '30019';

    // Set Actions Dropdown
    var actions = this.model.get('actions');

    // Only show actions 'Send to Chief' and 'Send for Rework'
    // if Examiner Task
    if (examinerTask) {
      actions = _.filter(actions, function(action) {
        return action.id === '3' || action.id === '7';
      });
    }

    // Only show actions 'Send for Rework', 'Send to Director', and 'Close Exam' if Chief Task
    if (chiefTask) {
      actions = _.filter(actions, function(action) {
        return action.id === '3' || action.id === '4' || action.id === '5';
      });
    }
    // Only show actions 'Close Exam','Send to Deuputy Secretary' and 'Send for Rework'
    //if Director Task
    if (directorTask) {
      actions = _.filter(actions, function(action) {
        return action.id === '5' || action.id === '6' || action.id === '3';
      });
    }
    // Only show actions 'Close Exam' and 'Send for Rework'
    // if Deputy Secretary Task
    if (depSecTask) {
      actions = _.filter(actions, function(action) {
        return action.id === '5' || action.id === '3';
      });
    }

    this.model.set({ actions: actions });

    // Set Decisions Dropdown
    var decisions = this.model.get('decisions');
    // Always show decisions 'Refer for Consideration of Action' and 'Pending Close', except for Deputy Secretary Task
    if (examinerTask || chiefTask || directorTask) {
      decisions = _.filter(decisions, function(decision) {
        return decision.id === '1' || decision.id === '2';
      });
    }

    // Only show decisions 'Refer for Consideration of Action', 'Deficiency' and 'Close' if Deputy Secretary Task
    if (depSecTask) {
      decisions = _.filter(decisions, function(decision) {
        return decision.id === '1' || decision.id === '3' || decision.id === '4';
      });
    }
    this.model.set({ decisions: decisions });
  },

  onBeforeShow: function() {
    var exam = this.model.get('exam') || {};

    var headerBarColumns = [
      {
        name: 'applicantLegalName',
        label: 'Applicant Legal Name',
        minWidth: '160px',
        maxWidth: '300px'
      },
      {
        name: 'crdNumber',
        label: 'CRD Number',
        minWidth: '160px',
        maxWidth: '300px'
      },
      {
        name: 'examNumber',
        label: 'Exam Number',
        minWidth: '160px',
        maxWidth: '300px'
      },
      {
        name: 'examType',
        label: 'Exam Type',
        minWidth: '160px',
        maxWidth: '300px'
      },
      {
        name: 'primaryExaminer',
        label: 'Primary Examiner',
        minWidth: '160px',
        maxWidth: '300px'
      }
    ];

    this.showChildView(
      'headerBar',
      new HeaderBarView({
        columns: new Backbone.Collection(headerBarColumns),
        model: new Backbone.Model(exam)
      })
    );

    this.showChildView(
      'breadcrumb',
      new BreadcrumbView({
        breadcrumbs: [
          {
            link: '#sec/dashboard',
            text: 'Home'
          },
          {
            text: modelGet(this.model, 'task.taskType.description')
          }
        ]
      })
    );

    var internalComments;
    if (exam.internalComments) {
      internalComments = '\n\n' + exam.internalComments;
    } else {
      internalComments = '';
    }

    exam.internalComments = internalComments;

    Syphon.deserialize(this, exam);

    this.deficiencyDocuments = new FileUploadView({
      collection: new FileUploadCollection(exam.examDeficiencyDocuments),
      allowMultipleFiles: true,
      isRequired: true,
      metadata: {
        documentTypeId: 30004
      }
    });
    this.showChildView('deficiencyDocumentContainer', this.deficiencyDocuments);

    this.finalExaminationReport = new FileUploadView({
      collection: makeUploadCollection(exam.finalExaminationReportDoc),
      isRequired: true,
      metadata: {
        documentTypeId: 30006
      }
    });
    this.showChildView('finalExaminationReportContainer', this.finalExaminationReport);
  },

  onChangeDecision: function() {
    var decision = this.ui.decision.val();
    var isReferForConsiderationOfAction = decision === '1';
    var isPendingClose = decision === '2';
    var isDeficiency = decision === '3';
    var isClose = decision === '4';

    toggleForm(this.ui.isPendingDeficiency, isReferForConsiderationOfAction || isDeficiency);
    toggleForm(this.ui.isPendingClose, isPendingClose || isClose);
    this.onChangeAction();
  },

  onChangeAction: function() {
    var taskTypeId = modelGet(this.model, 'task.taskType.id', {});
    var examinerTask = taskTypeId === '30016';

    if (examinerTask) {
      var decision = this.ui.decision.val();
      var action = this.ui.action.val();
      // decision is 'pending close' and action is 'send to chief'
      toggleForm(this.ui.isPendingClose, decision === '2' && action === '7');
    }
  },

  onClickSubmit: function(e) {
    e.preventDefault();
    var self = this;
    var formData = this.getFormData();

    this.validate().done(function() {
      var l = Ladda.create(self.ui.submit[0]);
      l.start();
      SubmitExamRecordResultsTask(formData)
        .done(function() {
          Radio.channel('navigate').trigger('show:int:sc:dashboard');
        })
        .fail(function() {
          l.stop();
        });
    });
  },

  validate: function() {
    this.ui.recordExamResultsForm.parsley().reset();
    var validationPromises = [this.ui.recordExamResultsForm.parsley().whenValidate()];
    if (!this.ui.isPendingClose.attr('disabled')) {
      validationPromises.push(this.finalExaminationReport.validate());
    }
    return $.when.apply($, validationPromises);
  },

  getFormData: function() {
    var formData = Syphon.serialize(this);
    formData.examId = this.model.get('examId');
    formData.taskId = this.model.get('taskId');
    formData.deficiencyDocumentIds = this.deficiencyDocuments.collection.pluck('fileId');
    formData.finalExaminationReportDocumentId = this.finalExaminationReport.getFirstFileId();
    formData.taskType = modelGet(this.model, 'task.taskType.name', {});

    var timestamp = moment().format('MM/DD/YYYY h:mm A');
    var userName = Session.user.description;
    formData.internalComments = '[' + userName + ' - ' + timestamp + '] \n' + formData.internalComments;

    return formData;
  }
});
