/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Marionette = require('backbone.marionette');
var Ladda = require('ladda');
var Radio = require('backbone.radio');

var BreadcrumbView = require('common/views/BreadcrumbView');
var modelGet = require('common/util/modelGet');

var DepLocationFormView = require('./DepLocationFormView');

var SubmitLocation = require('../services/SubmitLocation');

var tmplDepEditLocationView = require('../templates/DepLocationEditView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplDepEditLocationView,

  className: 'dep-location-edit-view container-fluid',

  ui: {
    locationForm: '.location-form-container',
    breadcrumb: '.breadcrumb-container',
    submit: '.btn-submit'
  },

  regions: {
    locationForm: '@ui.locationForm',
    breadcrumb: '@ui.breadcrumb'
  },

  events: {
    'click @ui.submit': 'onClickSubmit'
  },

  onBeforeShow: function() {
    var location = this.model.get('location') || {};
    var locationId = this.model.get('locationId');
    var entityId = this.model.get('entityId');
    var entityName = modelGet(this.model, 'entityIdentifiers.entityName');

    this.showChildView(
      'breadcrumb',
      new BreadcrumbView({
        breadcrumbs: [
          {
            link: '#dep/dashboard',
            text: 'Home'
          },
          {
            link: '#dep/entity/' + entityId + '/dashboard',
            text: entityName
          },
          {
            text: 'Opened on ' + location.openedDate + ' - ' + location.status,
            link: '#dep/entity/' + entityId + '/location/' + locationId
          },
          {
            text: 'Edit Location'
          }
        ]
      })
    );

    this.locationForm = new DepLocationFormView({
      model: this.model
    });

    this.showChildView('locationForm', this.locationForm);
  },

  onClickSubmit: function(e) {
    e.preventDefault();
    this.validate().done(
      function() {
        var laddaContext = Ladda.create(this.ui.submit.get(0));
        laddaContext.start();
        var formData = this.getFormData();
        SubmitLocation(this.getFormData())
          .done(function() {
            Radio.channel('navigate').trigger('show:int:dp:entity:location', formData.entityId, formData.locationId);
          })
          .fail(function() {
            laddaContext.stop();
          });
      }.bind(this)
    );
  },

  validate: function() {
    return this.ui.locationForm.parsley().whenValidate();
  },

  getFormData: function() {
    return this.locationForm.getFormData();
  }
});
