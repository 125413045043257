/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

 var _ = require('underscore');
 var moment = require('moment');
 var Ladda = require('ladda');
 var Radio = require('backbone.radio');
 var Marionette = require('backbone.marionette');
 var Syphon = require('backbone.syphon');
 
 var GridView = require('psa-marionette/views/GridView');
 
 var StringCell = require('common/backgrid/StringCell');
 var MomentCell = require('common/backgrid/MomentCell');
 var DollarCell = require('common/backgrid/DollarCell');
 var StaticInputCell = require('common/backgrid/StaticInputCell');
 var DollarInputCellEditor = require('common/backgrid/DollarInputCellEditor');
 var ButtonLinkCell = require('common/backgrid/ButtonLinkCell');
 var BreadcrumbView = require('common/views/BreadcrumbView');
 var formatAsDollars = require('common/util/formatAsDollars');
 
 var DepInternalSidebarNavView = require('../../common/views/DepInternalSidebarNavView');
 var SubmitEntityAssetsBulkUpdate = require('../services/SubmitEntityAssetsBulkUpdate');
 
 require('../styles/DepBulkUpdateEntityAssetsView.css');
 var tmplDepBulkUpdateEntityAssetsView = require('../templates/DepBulkUpdateEntityAssetsView.hbs');
 
 module.exports = Marionette.LayoutView.extend({
   template: tmplDepBulkUpdateEntityAssetsView,
 
   className: 'dep-bulk-update-entity-assets-view container-fluid',
 
   ui: {
     sideBar: '.side-bar-container',
     breadcrumb: '.breadcrumb-container',
     previousQuarterEnd: '.previous-quarter-end',
     manageAssetsForm: '.manage-assets-form',
     entitiesContainer: '.entities-container',
     incompleteRowsError: '.incomplete-rows-error',
     calculate: '.btn-calculate',
     amount: '.amount',
     submit: '.submit',
     totalNewAssets: '[name="totalNewAssets"]',
     datePickerFields: '.date-picker',
     newAsOfDate: '.new-as-of-date'
   },
 
   regions: {
     sideBar: '@ui.sideBar',
     breadcrumb: '@ui.breadcrumb',
     entitiesContainer: '@ui.entitiesContainer',
     datePickerFields: '@ui.datePickerFields'
   },
 
   events: {
     'click @ui.submit': 'onClickSubmit',
     'click @ui.previousQuarterEnd': 'onClickPreviousQuarterEnd',
     'click @ui.calculate': 'onClickCaluclate',
     'change @ui.newAsOfDate': 'onClickNewAsOfDate'
   },
 
   templateHelpers: function() {
     var previousQuarterEndDate = this.calculatePreviousQuarterEndDate();
     return {
       previousQuarterEndDate: previousQuarterEndDate
     };
   },
 
   onBeforeShow: function() {
     this.showChildView('sideBar', new DepInternalSidebarNavView());
     this.ui.datePickerFields.datepicker();
     this.showChildView(
       'breadcrumb',
       new BreadcrumbView({
         breadcrumbs: [
           {
             link: '#dep/dashboard',
             text: 'Home'
           },
           {
             link: '#dep/dashboard/entity-search',
             text: 'Entity Search'
           },
           {
             text: 'Manage All Credit Union Assets'
           }
         ]
       })
     );
 
     this.entities = new GridView({
       columns: [
         {
           name: 'entityName',
           label: 'Entity Name',
           cell: StringCell,
           headerCell: 'custom',
           width: 20,
           editable: false
         },
         {
           name: 'totalAssetsAmount',
           label: 'Current Total Assets',
           cell: DollarCell,
           headerCell: 'custom',
           width: 15,
           editable: false
         },
         {
           name: 'totalAssetsAsOfDate',
           label: 'Current As of Date',
           cell: MomentCell.MomentCell,
           formatter: MomentCell.MomentDateFormatter.extend({
             emptyValue: '--'
           }),
           sortValue: MomentCell.MomentSortValue,
           headerCell: 'custom',
           width: 15,
           editable: false
         },
         {
           name: 'newAssetsAmount',
           label: 'New Assets Amount',
           cell: StaticInputCell,
           editor: DollarInputCellEditor.extend({
             placeholder: 'New Assets Amount'
           }),
           headerCell: 'custom',
           width: 20,
           sortable: false
         },
       
         {
           name: '',
           label: '',
           cell: ButtonLinkCell.extend({
             name: 'Open Dashboard',
             newTab: true,
             buttonClass: 'btn-primary btn-sm',
             href: function(model) {
               return '#dep/entity/' + model.get('entityId') + '/dashboard';
             }
           }),
           headerCell: 'custom',
           width: 10,
           sortable: false,
           editable: false
         }
       ],
       collection: new Backbone.Collection(this.model.get('entities'), { pageSize: 25 }),
       emptyText: 'No Entities Found'
     });
 
     var total = this.model.get('entities').totalsOfCurrentTotalAssests;
     this.showChildView('entitiesContainer', this.entities);
     this.ui.amount.text(total);
     this.$('.backgrid.table').addClass('table-bordered');
     this.ui.submit.hide();
   },
 
   onClickPreviousQuarterEnd: function(e) {
     e.target.setSelectionRange(0, e.target.value.length);
   },
 
   onClickCaluclate: function() {
     var totalAssests = 0;
     _.each(this.entities.collection.models, function(entity) {
       if (entity.attributes.newAssetsAmount > 0) {
         totalAssests += +entity.attributes.newAssetsAmount;
       }
     });
     if (totalAssests > 0) {
       this.ui.totalNewAssets.val(formatAsDollars(totalAssests));
       this.ui.submit.show();
     }
   },
 
   onClickSubmit: function() {
     var spinner = Ladda.create(this.ui.submit[0]);
     spinner.start();
 
     var newAsOfDate = this.ui.newAsOfDate.val();
 
     this.validate()
       .done(
         function() {
           var updatedEntities = this.getFormData();
         
           _.each(updatedEntities, function(entity) {
          
             updatedEntities.newAssetsAsOfDate = newAsOfDate;
           });
 
           // Exit early if no entities have been udpated
           if (!updatedEntities.length) {
             spinner.stop();
             return;
           }
          SubmitEntityAssetsBulkUpdate(updatedEntities)
           .done(function() {
              Radio.channel('navigate').trigger('show:int:dp:entity-search');
           })
            .fail(function() {
              spinner.stop();
            });
         }.bind(this)
       )
       .fail(function() {
         spinner.stop();
       });
   },
 
   validate: function() {
    
     var deferred = $.Deferred();
     this.ui.incompleteRowsError.hide();
     var updatedEntities = this.entities.collection.toJSON();
 
     var hasIncompleteRows = _.some(updatedEntities, function(entity) {
       var hasNewAmount = !!entity.newAssetsAmount;
    
    if (!hasNewAmount) {
       return true;
       }
     });
     if (hasIncompleteRows) {
       this.ui.incompleteRowsError.show();
       deferred.reject();
     } else {
       deferred.resolve();
     }
     return $.when(deferred.promise(), this.ui.manageAssetsForm.parsley().whenValidate());
   },
 
   getFormData: function() {
     var formData = Syphon.serialize(this);
     return _.chain(this.entities.collection.toJSON())
       .filter(function(entity) {
         return entity.newAssetsAmount && formData.newAsOfDate;
       })
       .map(function(entity) {
         var newAssetsAsOfDate;
         if (formData.newAsOfDate) {
           newAssetsAsOfDate = moment(formData.newAsOfDate, 'MM/DD/YYYY').format('YYYY-MM-DD');
         }
         return {
           entityId: entity.entityId,
           newAssetsAmount: entity.newAssetsAmount,
           newAssetsAsOfDate: newAssetsAsOfDate
         };
       })
       .value();
   },
 
   calculatePreviousQuarterEndDate: function() {
     var today = moment();
     var currentYear = moment().format('YYYY');
     var currentYearStart = moment('01/01/' + currentYear, 'MM/DD/YYYY');
     var previousYear = moment()
       .subtract('1', 'years')
       .format('YYYY');
 
     var quarters = this.model.get('quarters');
     var quartersEnds = _.pluck(quarters, 'quarterEnd');
     var quarter1Date = moment(quartersEnds[0] + '/' + currentYear, 'MM/DD/YYYY');
     var quarter2Date = moment(quartersEnds[1] + '/' + currentYear, 'MM/DD/YYYY');
     var quarter3Date = moment(quartersEnds[2] + '/' + currentYear, 'MM/DD/YYYY');
     var quarter4Date = moment(quartersEnds[3] + '/' + currentYear, 'MM/DD/YYYY');
 
     var result;
     if (today.isBetween(currentYearStart, quarter1Date, 'day', '[]')) {
       result = quartersEnds[3] + '/' + previousYear;
     } else if (today.isBetween(quarter1Date, quarter2Date, 'day', '[]')) {
       result = quarter1Date.format('MM/DD/YYYY');
     } else if (today.isBetween(quarter2Date, quarter3Date, 'day', '[]')) {
       result = quarter2Date.format('MM/DD/YYYY');
     } else if (today.isBetween(quarter3Date, quarter4Date, 'day', '[]')) {
       result = quarter3Date.format('MM/DD/YYYY');
     }
     return result;
   }
 });
 