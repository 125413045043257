var Handlebars = require("handlebars/dist/handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "          <option value=\""
    + alias4(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\">"
    + alias4(((helper = (helper = helpers.option || (depth0 != null ? depth0.option : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"option","hash":{},"data":data}) : helper)))
    + "</option>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "\n<form class=\"conversion-form\">\n  <div class=\"row\">\n    <div class=\"col-sm-12\">\n      <div class=\"form-group required\">\n        <label class=\"control-label\">Conversion Options</label>\n        <select name=\"conversionOptionId\" class=\"form-control\" data-parsley-required=\"true\">\n          <option value=\"\" selected>--</option>\n"
    + ((stack1 = helpers.each.call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.conversionOptions : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "        </select>\n      </div>\n    </div>\n    <div class=\"col-sm-12\">\n      <div class=\"form-group required\">\n        <label class=\"control-label\">Under the Name of</label>\n        <input type=\"text\" name=\"underNameOf\" class=\"form-control\" placeholder=\"Under the Name of\"\n          data-parsley-maxlength=\"1000\" data-parsley-required=\"true\" />\n      </div>\n    </div>\n    <div class=\"col-sm-12\">\n      <div class=\"form-group required\">\n        <label class=\"control-label\">Brief statement giving reason(s) for taking this corporate action</label>\n        <textarea rows=\"5\" name=\"actionReason\" class=\"form-control input-view\"\n          placeholder=\"Brief statement giving reason(s) for taking this corporate action\"\n          data-parsley-required=\"true\"></textarea>\n      </div>\n      <div class=\"form-group print-view\" id=\"actionReasonPrintView\"></div>\n    </div>\n  </div>\n  </div>\n</form>";
},"useData":true});