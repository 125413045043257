/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Marionette = require('backbone.marionette');
var Radio = require('backbone.radio');
var Syphon = require('backbone.syphon');
var Ladda = require('ladda');

var modelGet = require('common/util/modelGet');

var BreadcrumbView = require('common/views/BreadcrumbView');
var LicenseHeaderBarView = require('../../common/views/LicenseHeaderBarView');
var AddressDisplayView = require('common/views/AddressDisplayView');

var SubmitIssueExam = require('../../exam-dashboard/services/SubmitIssueExam');

require('../styles/NonDepLicenseDashboardView.css');
var tmplNonDepLicenseInitiateExamView = require('../templates/NonDepLicenseInitiateExamView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplNonDepLicenseInitiateExamView,

  className: 'non-dep-license-initiate-exam-view container-fluid',

  ui: {
    headerBar: '.header-bar-container',
    breadcrumb: '.breadcrumb-container',
    examForm: 'form',
    region: 'select[name="regionId"]',
    licenseAddressContainer: '.license-address-container',
    primaryExaminer: '#primaryExaminer',
    secondaryExaminers: '#secondaryExaminers',
    submit: '.btn-submit'
  },

  regions: {
    headerBar: '@ui.headerBar',
    breadcrumb: '@ui.breadcrumb',
    licenseAddressContainer: '@ui.licenseAddressContainer'
  },

  events: {
    'click @ui.submit': 'onClickSubmit'
  },

  onBeforeShow: function() {
    var entityId = this.model.get('entityId'),
      entityName = modelGet(this.model, 'header.entityName'),
      licenseId = this.model.get('licenseId'),
      licenseNumber = modelGet(this.model, 'header.licenseNumber'),
      licenseType = modelGet(this.model, 'header.licenseType');

    this.showChildView(
      'headerBar',
      new LicenseHeaderBarView({
        model: new Backbone.Model(this.model.get('header'))
      })
    );

    this.showChildView(
      'breadcrumb',
      new BreadcrumbView({
        breadcrumbs: [
          {
            link: '#nondep/dashboard',
            text: 'Home'
          },
          {
            link: '#nondep/entity/' + entityId + '/dashboard',
            text: entityName
          },
          {
            link: '#nondep/entity/' + entityId + '/license/' + licenseId,
            text: 'License #' + licenseNumber + ' - ' + licenseType
          },
          {
            text: 'Initiate Exam'
          }
        ]
      })
    );

    this.showChildView(
      'licenseAddressContainer',
      new AddressDisplayView({
        model: new Backbone.Model(this.model.get('licenseAddress'))
      })
    );

    this.ui.region.val(this.model.get('defaultRegionId'));

    this.ui.primaryExaminer.select2({
      allowClear: true
    });
    this.ui.secondaryExaminers.select2();
  },

  onClickSubmit: function(e) {
    e.preventDefault();
    var spinner = Ladda.create(e.currentTarget);
    spinner.start();
    this.validate()
      .done(
        function() {
          SubmitIssueExam(this.getFormData())
            .done(
              function() {
                Radio.channel('navigate').trigger(
                  'show:int:nd:license-dashboard',
                  this.model.get('entityId'),
                  this.model.get('licenseId')
                );
              }.bind(this)
            )
            .fail(function() {
              spinner.stop();
            });
        }.bind(this)
      )
      .fail(function() {
        spinner.stop();
      });
  },

  validate: function() {
    return this.ui.examForm.parsley().whenValidate();
  },

  getFormData: function() {
    var formData = Syphon.serialize(this.ui.examForm);

    formData.licenses = [
      {
        licenseId: this.model.get('licenseId'),
        nonDExamRegionId: formData.regionId
      }
    ];

    delete formData.regionId;

    return formData;
  }
});
