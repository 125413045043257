/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Backbone = require('backbone');

var GetAttachments = require('../services/GetAttachments');
var AttachmentsView = require('../views/AttachmentsView');

module.exports = {
  channel: 'nd',

  event: 'build:attachments-view',

  builder: function(data) {
    var deferred = $.Deferred();
    GetAttachments({ objectId: data.objectId, objectTypeId: data.objectTypeId })
      .done(function(attachments) {
        deferred.resolve(
          new AttachmentsView({
            model: new Backbone.Model({
              entityId: data.entityId,
              objectId: data.objectId,
              objectTypeId: data.objectTypeId,
              departmentName: data.departmentName
            }),
            collection: new Backbone.Collection(attachments)
          })
        );
      })
      .fail(function(err) {
        deferred.reject(err);
      });
    return deferred.promise();
  }
};
