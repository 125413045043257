/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var moment = require('moment');

var cleanUpResponse = require('common/util/cleanUpResponse');

module.exports = function(data) {
  var defer = $.Deferred();

  $.soap({
    body: {
      GetExamInvInqStaff: {
        $xmlns: 'http://dobs.pa.gov/sc/examInvInq/bpm/1.0',
        ObjectID: data.objectId,
        ObjectTypeID: data.objectTypeId
      }
    },
    blueprintArrays: ['GetExamInvInqStaffResponse.SC_ExamInvInqStaff'],
    blueprint: {
      staffMembers: [
        '$..SC_ExamInvInqStaff',
        {
          id: '$..ID',
          objectTypeId: '$..ObjectTypeID',
          objectId: '$..ObjectID',
          staffMember: '$..StaffMember',
          functionId: '$..FunctionID',
          function: '$..StaffFunction',
          assignedDate: '$..AssignedDate',
          removedDate: '$..RemovedDate'
        }
      ]
    }
  })
    .done(function(response) {
      cleanUpResponse(response);
      _.each(response.staffMembers, function(staffMember) {
        if (staffMember.assignedDate) {
          staffMember.assignedDate = moment(staffMember.assignedDate, 'YYYY-MM-DD');
        }

        if (staffMember.removedDate) {
          staffMember.removedDate = moment(staffMember.removedDate, 'YYYY-MM-DD');
        }

        staffMember.staffMember = staffMember.staffMember || '--';
        staffMember.function = staffMember.function || '--';

        if (staffMember.objectTypeId === 'EXAM') {
          staffMember.objectType = 'exam';
        } else if (staffMember.objectTypeId === 'INV-INQ') {
          staffMember.objectType = 'investigation-inquiry';
        }
      });

      defer.resolve(response.staffMembers);
    })
    .fail(function(err) {
      defer.reject(err);
    });

  return defer.promise();
};
