/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2020] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Backbone = require('backbone');
var Marionette = require('backbone.marionette');
var Radio = require('backbone.radio');
var Ladda = require('ladda');

var BreadcrumbView = require('common/views/BreadcrumbView');
var SecExamHeaderBarView = require('./SecExamHeaderBarView');
var modelGet = require('common/util/modelGet');

var SubmitNewCorrespondence = require('../../common/services/SubmitNewCorrespondence');

var SecInitiateCorrespondenceFormView = require('../../common/views/SecInitiateCorrespondenceFormView');

var tmplSecExamInitiateCorrespondenceView = require('../templates/SecExamInitiateCorrespondenceView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplSecExamInitiateCorrespondenceView,

  className: 'sec-exam-initiate-corespondence-view container-fluid',

  ui: {
    headerBar: '.header-bar-container',
    breadcrumb: '.breadcrumb-container',
    correspondenceFormContainer: '.correspondence-form-container',

    submit: '.submit'
  },

  regions: {
    headerBar: '@ui.headerBar',
    breadcrumb: '@ui.breadcrumb',
    correspondenceFormContainer: '@ui.correspondenceFormContainer'
  },

  events: {
    'click @ui.submit': 'onClickSubmit'
  },

  onBeforeShow: function() {
    var examIdentifiers = this.model.get('examIdentifiers') || {};
    var examId = this.model.get('examId');

    this.showChildView('headerBar', new SecExamHeaderBarView({ model: new Backbone.Model(examIdentifiers) }));

    this.showChildView(
      'breadcrumb',
      new BreadcrumbView({
        breadcrumbs: [
          {
            link: '#sec/dashboard',
            text: 'Home'
          },
          {
            link: '#sec/organization/' + examIdentifiers.organizationId + '/dashboard',
            text: examIdentifiers.applicantLegalName
          },
          {
            link:
              '#sec/organization/' +
              examIdentifiers.organizationId +
              '/license/' +
              examIdentifiers.organizationLicenseId +
              '/dashboard',
            text: examIdentifiers.licenseType
          },
          {
            link: '#sec/exam/' + examId + '/dashboard',
            text: 'Exam - ' + examIdentifiers.examNumber
          },
          {
            text: 'Initiate Correspondence'
          }
        ]
      })
    );

    this.correspondenceForm = new SecInitiateCorrespondenceFormView({
      model: this.model
    });

    this.showChildView('correspondenceFormContainer', this.correspondenceForm);
  },

  onClickSubmit: function(event) {
    event.preventDefault();
    var self = this;
    var organizationId = modelGet(this.model, 'examIdentifiers.organizationId');
    var organizationLicenseId = modelGet(this.model, 'examIdentifiers.organizationLicenseId');
    var examId = modelGet(this.model, 'examIdentifiers.examId');
    this.correspondenceForm.validate().done(function() {
      var l = Ladda.create(self.ui.submit[0]);
      l.start();
      var formData = self.correspondenceForm.getFormData();
      formData.organizationId = organizationId;
      formData.organizationLicenseId = organizationLicenseId;
      formData.examId = examId;
      SubmitNewCorrespondence(formData)
        .done(function() {
          Radio.channel('navigate').trigger('show:int:sc:exam-dashboard', self.model.get('examId'));
        })
        .fail(function() {
          l.stop();
        });
    });
  }
});
