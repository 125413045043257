/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Backbone = require('backbone');
var Marionette = require('backbone.marionette');

var UIUtil = require('psa-core/util/ui');
var PageableCollection = require('psa-backbone/models/PageableCollection');
var GridView = require('psa-marionette/views/GridView');

var FileUploadCell = require('common/backgrid/FileUploadCell');
var MomentCell = require('common/backgrid/MomentCell');
var NonDepEntitySectionAddDBAModalView = require('./NonDepEntitySectionAddDBAModalView.js');

require('../styles/NonDepEntitySectionAddDBAView.css');
var tmplNonDepEntitySectionAddDBAView = require('../templates/NonDepEntitySectionAddDBAView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplNonDepEntitySectionAddDBAView,

  className: 'nondep-entity-section-add-dba-view',

  ui: {
    dba: '.doing-business-as',
    newEntityDBAContainer: '.new-entity-dba-container',
    newEntityDBAButton: '.btn-new-entity-dba'
  },

  regions: {
    dba: '@ui.dba'
  },

  events: {
    'click @ui.newEntityDBAButton': 'onClickNewDBA'
  },

  templateHelpers: function() {
    return {
      isNMLSEntity: this.model.get('isNMLSEntity')
    };
  },

  onBeforeShow: function() {
    this.showChildView(
      'dba',
      new GridView({
        columns: [
          {
            name: 'dba',
            label: 'Name',
            cell: 'string',
            headerCell: 'custom',
            width: 30,
            editable: false
          },
          {
            name: 'registrationDate',
            label: 'Registration Date',
            cell: MomentCell.MomentCell,
            formatter: MomentCell.MomentDateFormatter.extend({
              emptyValue: '--'
            }),
            sortValue: MomentCell.MomentSortValue,
            headerCell: 'custom',
            width: 10,
            editable: false
          },
          {
            name: 'fictitiousNameDocument',
            label: 'Fictitious Name Registration Document',
            cell: FileUploadCell.extend({
              isReadOnly: true
            }),
            headerCell: 'custom',
            width: 50,
            editable: false,
            sortable: false
          }
        ],

        collection: new PageableCollection(this.model.get('dbas'), { pageSize: 10 }),
        emptyText: 'Entity has no D/B/A registrations.'
      })
    );
  },
  onClickNewDBA: function() {
    var self = this;
    UIUtil.showModal({
      title: 'Add a New D/B/A',
      view: new NonDepEntitySectionAddDBAModalView({
        model: new Backbone.Model({
          entityId: self.model.get('id')
        })
      })
    }).done(function() {
      Backbone.history.loadUrl();
    });
  }
});
