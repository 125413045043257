/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Backbone = require('backbone');
var Marionette = require('backbone.marionette');
var Syphon = require('backbone.syphon');
var bodybuilder = require('bodybuilder');
var Ladda = require('ladda');
var moment = require('moment');

var GridView = require('psa-marionette/views/GridView');
var PageableCollection = require('psa-backbone/models/PageableCollection');

var LinkCell = require('common/backgrid/LinkCell');
var ListCell = require('common/backgrid/ListCell');
var ButtonLinkCell = require('common/backgrid/ButtonLinkCell');

var ElasticSearchSummaryView = require('common/views/ElasticSearchSummaryView');

var DepInternalSidebarNavView = require('../../common/views/DepInternalSidebarNavView');
var SearchDepSEGs = require('../services/SearchDepSEGs');

require('../styles/DepSEGSearchView.css');
var tmplDepSEGSearchView = require('../templates/DepSEGSearchView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplDepSEGSearchView,

  className: 'dep-seg-search-view container-fluid',

  ui: {
    sideBar: '.side-bar-container',
    searchForm: 'form',
    searchSummary: '.search-summary',
    searchResultsContainer: '.search-results-container',
    search: '.search',
    clearFilters: '.clear-filters',
    rangeDatepickers: '.input-daterange',
    formInputs: ':input'
  },

  regions: {
    sideBar: '@ui.sideBar',
    searchResultsContainer: '@ui.searchResultsContainer',
    searchSummary: '@ui.searchSummary'
  },

  events: {
    'click @ui.search': 'onClickSearch',
    'click @ui.clearFilters': 'onClickClearFilters',
    'keydown @ui.formInputs': 'onKeydownOfInputs'
  },

  onBeforeShow: function() {
    this.showChildView('sideBar', new DepInternalSidebarNavView());

    this.searchSummaryView = new ElasticSearchSummaryView({
      model: new Backbone.Model({
        searchLimit: this.model.get('searchLimit')
      })
    });
    this.showChildView('searchSummary', this.searchSummaryView);

    this.searchResults = new GridView({
      columns: [
        {
          name: 'segName',
          label: 'SEG Name',
          cell: 'string',
          headerCell: 'custom',
          width: 20,
          editable: false
        },
        {
          name: 'entityName',
          label: 'Credit Union Name',
          cell: LinkCell.extend({
            emptyText: '--',
            newTab: true,
            href: function(model) {
              var entityId = model.get('entityId');
              var url = entityId ? '#dep/entity/' + entityId + '/dashboard' : '';
              return url;
            }
          }),
          headerCell: 'custom',
          width: 15,
          editable: false
        },
        {
          name: 'individualTypes',
          label: 'Individual Type(s)',
          cell: ListCell,
          headerCell: 'custom',
          width: 15,
          editable: false
        },
        {
          name: '',
          label: '',
          cell: ButtonLinkCell.extend({
            name: 'Open',
            newTab: true,
            buttonClass: 'btn-primary btn-sm',
            href: function(model) {
              var entityId = model.get('entityId');
              var segId = model.get('segId');
              return '#dep/entity/' + entityId + '/seg/' + segId + '/dashboard';
            }
          }),
          headerCell: 'custom',
          width: 5,
          sortable: false,
          editable: false
        }
      ],
      collection: new PageableCollection([], { pageSize: 25 }),
      emptyText: 'No SEGs Found'
    });

    this.ui.rangeDatepickers.datepicker();

    this.showChildView('searchResultsContainer', this.searchResults);

    this.$('.backgrid.table').addClass('table-bordered');
  },

  onKeydownOfInputs: function(e) {
    // Searches on press of enter
    if (e.keyCode === 13) {
      this.onClickSearch(e);
    }
  },

  onClickSearch: function() {
    this.ui.searchForm
      .parsley()
      .whenValidate()
      .done(
        function() {
          var data = Syphon.serialize(this, {
            inputReaders: Syphon.SearchInputReaderSet
          });

          // If no inputs were given, do not perform a search.
          if (
            !data.segName &&
            !data.entityName &&
            !data.individualTypeId &&
            !data.approvalDateStart &&
            !data.approvalDateEnd &&
            !data.terminationDateStart &&
            !data.terminationDateEnd &&
            !data.statusId
          ) {
            return;
          }
          this.searchSummaryView.reset();

          var l = Ladda.create(this.ui.search[0]);
          l.start();
          var body = bodybuilder();

          body.query('term', 'isDeleted', '0');

          if (data.segName) {
            body.query('bool', function(b) {
              b.orQuery('match', 'segName', {
                query: data.segName,
                fuzziness: 'AUTO',
                analyzer: 'standard'
              });
              b.orQuery('wildcard', 'segName', data.segName.toLowerCase() + '*');
              b.orQuery('match', 'segName.phonetic', data.segName);
              return b;
            });
          }

          if (data.entityName) {
            body.query('bool', function(b) {
              b.orQuery('match', 'entityName', {
                query: data.entityName,
                fuzziness: 'AUTO',
                analyzer: 'standard'
              });
              b.orQuery('wildcard', 'entityName', data.entityName.toLowerCase() + '*');
              b.orQuery('match', 'entityName.phonetic', data.entityName);
              return b;
            });
          }

          if (data.individualTypeId) {
            body.query('match', 'individualTypes.individualTypeId', data.individualTypeId);
          }

          if (data.statusId) {
            body.query('match', 'statusId', data.statusId);
          }

          if (data.approvalDateStart && data.approvalDateEnd) {
            body.query('bool', function(b) {
              b.andQuery('range', 'approvalDate', {
                gte: moment(data.approvalDateStart).format('YYYY-MM-DD')
              });
              b.andQuery('range', 'approvalDate', {
                lte: moment(data.approvalDateEnd).format('YYYY-MM-DD')
              });
              return b;
            });
          } else {
            if (data.approvalDateStart) {
              body.query('range', 'approvalDate', {
                gte: moment(data.approvalDateStart).format('YYYY-MM-DD')
              });
            }
            if (data.approvalDateEnd) {
              body.query('range', 'approvalDate', {
                lte: moment(data.approvalDateEnd).format('YYYY-MM-DD')
              });
            }
          }

          if (data.terminationDateStart && data.terminationDateEnd) {
            body.query('bool', function(b) {
              b.andQuery('range', 'terminationDate', {
                gte: moment(data.terminationDateStart).format('YYYY-MM-DD')
              });
              b.andQuery('range', 'terminationDate', {
                lte: moment(data.terminationDateEnd).format('YYYY-MM-DD')
              });
              return b;
            });
          } else {
            if (data.terminationDateStart) {
              body.query('range', 'terminationDate', {
                gte: moment(data.terminationDateStart).format('YYYY-MM-DD')
              });
            }
            if (data.terminationDateEnd) {
              body.query('range', 'terminationDate', {
                lte: moment(data.terminationDateEnd).format('YYYY-MM-DD')
              });
            }
          }

          body.size(this.model.get('searchLimit'));
          var bodyObj = body.build();
          // Keep for testing
          // console.log(JSON.stringify(bodyObj, null, 2));
          // console.log('formData:', data);
          SearchDepSEGs(bodyObj)
            .done(
              function(data) {
                l.stop();
                this.searchResults.collection.fullCollection.reset(data.hits);
                this.searchSummaryView.display(data);
              }.bind(this)
            )
            .fail(function() {
              l.stop();
            });
        }.bind(this)
      );
  },

  onClickClearFilters: function() {
    this.ui.formInputs.val('');
  }
});
