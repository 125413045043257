/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Marionette = require('backbone.marionette');

var PageableCollection = require('psa-backbone/models/PageableCollection');
var GridView = require('psa-marionette/views/GridView');

var ButtonLinkCell = require('common/backgrid/ButtonLinkCell');
var MomentCell = require('common/backgrid/MomentCell');
var LinkCell = require('common/backgrid/LinkCell');

var NonDepInternalSidebarNavView = require('../../common/views/NonDepInternalSidebarNavView');
var NonDepInternalApplicationFiltersView = require('./NonDepInternalApplicationFiltersView');

require('../styles/NonDepInternalMyApplicationsView.css');
var tmplNonDepInternalMyApplications = require('../templates/NonDepInternalMyApplicationsView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplNonDepInternalMyApplications,

  className: 'non-dep-my-applications-view container-fluid',

  ui: {
    sideBar: '.side-bar-container',
    applicationFilters: '.application-filters',
    myApplications: '.my-applications-container'
  },

  regions: {
    sideBar: '@ui.sideBar',
    applicationFilters: '@ui.applicationFilters',
    myApplications: '@ui.myApplications'
  },

  childEvents: {
    'apply:filters': 'onChildClickApplyFilters'
  },

  onChildClickApplyFilters: function(childView) {
    // Get filtered list from NonDepInternalApplicationFiltersView
    var models = childView.shadowCollection.models;
    this.shadowCollection.fullCollection.reset(models);
  },

  onBeforeShow: function() {
    this.showChildView('sideBar', new NonDepInternalSidebarNavView());
    this.showChildView('applicationFilters', new NonDepInternalApplicationFiltersView({ model: this.model }));

    this.collection = new PageableCollection(this.model.get('applications'), { pageSize: 25 });
    this.shadowCollection = new PageableCollection(this.model.get('applications'), { pageSize: 25 });

    var myApplicationsGridView = new GridView({
      columns: [
        {
          name: 'entityName',
          label: 'Entity Name',
          cell: LinkCell.extend({
            emptyText: '--',
            href: function(model) {
              var entityId = model.get('entityId');
              var url = entityId ? '#nondep/entity/' + entityId + '/dashboard' : '';
              return url;
            }
          }),
          headerCell: 'custom',
          width: 15,
          editable: false
        },
        {
          name: 'tin',
          label: 'Federal ID',
          cell: 'string',
          headerCell: 'custom',
          width: 10,
          editable: false
        },
        {
          name: 'applicationType',
          label: 'Application Type',
          cell: 'string',
          headerCell: 'custom',
          width: 10,
          editable: false
        },
        {
          name: 'licenseType',
          label: 'License Type',
          cell: LinkCell.extend({
            emptyText: '--',
            href: function(model) {
              var entityId = model.get('entityId');
              var licenseId = model.get('licenseId');
              var url = entityId && licenseId ? '#nondep/entity/' + entityId + '/license/' + licenseId : '';
              return url;
            }
          }),
          headerCell: 'custom',
          width: 15,
          editable: false
        },
        {
          name: 'applicationStatus',
          label: 'Status',
          cell: 'string',
          headerCell: 'custom',
          width: 15,
          editable: false
        },
        {
          name: 'owner',
          label: 'Owner',
          cell: 'string',
          headerCell: 'custom',
          width: 10,
          editable: false
        },
        {
          name: 'submissionDate',
          label: 'Submission Date',
          cell: MomentCell.MomentCell,
          formatter: MomentCell.MomentDateTimeFormatter.extend({
            emptyValue: '--'
          }),
          sortValue: MomentCell.MomentSortValue,
          headerCell: 'custom',
          width: 15,
          editable: false
        },
        {
          name: '',
          label: '',
          cell: ButtonLinkCell.extend({
            name: 'Open',
            buttonClass: 'btn-primary btn-sm',
            href: function(model) {
              return '#nondep/application/' + model.get('id') + '/dashboard';
            }
          }),
          headerCell: 'custom',
          width: 10,
          editable: false,
          sortable: false
        }
      ],
      collection: this.shadowCollection,
      emptyText: 'No Currently Owned Applications'
    });
    this.showChildView('myApplications', myApplicationsGridView);

    myApplicationsGridView.getViewGrid().sort('submissionDate', 'descending');
  }
});
