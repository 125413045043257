/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Backbone = require('backbone');
var Marionette = require('backbone.marionette');

var UIUtil = require('psa-core/util/ui');
var GridView = require('psa-marionette/views/GridView');

var DollarCell = require('common/backgrid/DollarCell');
var MomentCell = require('common/backgrid/MomentCell');
var MultiButtonCell = require('common/backgrid/MultiButtonCell');
var ConfirmationModalView = require('common/views/ConfirmationModalView');
var momentUtil = require('common/util/momentUtil');

var SecComplianceAGReferralsTotalsView = require('./SecComplianceAGReferralTotalsView');
var SecComplianceAGReferralEditModalView = require('./SecComplianceAGReferralEditModalView');
var SecComplianceAGReferralPaymentModalView = require('./SecComplianceAGReferralPaymentModalView');
var SubmitComplianceAGReferralPayment = require('../services/SubmitComplianceAGReferralPayment');

var tmplSecComplianceAGReferralSectionView = require('../templates/SecComplianceAGReferralSectionView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplSecComplianceAGReferralSectionView,

  className: 'sec-compliance-ag-referral-section-view',

  ui: {
    editAGReferral: '.edit-ag-referral',
    AGReferralsTotals: '.ag-referral-totals',
    addAGReferralPayment: '.add-ag-referral-payment',
    AGPaymentGrid: '.ag-payment-grid'
  },

  regions: {
    AGReferralsTotals: '@ui.AGReferralsTotals',
    AGPaymentGrid: '@ui.AGPaymentGrid'
  },

  events: {
    'click @ui.editAGReferral': 'onClickEditAGReferral',
    'click @ui.addAGReferralPayment': 'onClickAddAGReferralPayment'
  },

  onBeforeShow: function() {
    var payments = this.model.get('AGPayments') || {};

    this.AGReferralsTotalsView = new SecComplianceAGReferralsTotalsView({ model: this.model });
    this.showChildView('AGReferralsTotals', this.AGReferralsTotalsView);

    this.AGPaymentGrid = new GridView({
      columns: [
        {
          name: 'paymentDateDisplay',
          label: 'Payment Date',
          cell: MomentCell.MomentCell,
          formatter: MomentCell.MomentDateFormatter.extend({
            emptyValue: '--'
          }),
          sortValue: MomentCell.MomentSortValue,
          headerCell: 'custom',
          width: 20,
          editable: false
        },
        {
          name: 'amount',
          label: 'Amount',
          cell: DollarCell,
          headerCell: 'custom',
          width: 20,
          editable: false
        },
        {
          name: 'checkNumberDisplay',
          label: 'Check Number',
          cell: 'string',
          headerCell: 'custom',
          width: 20,
          editable: false
        },
        {
          name: 'transactionNumberDisplay',
          label: 'Transaction Number',
          cell: 'string',
          headerCell: 'custom',
          width: 20,
          editable: false
        },
        {
          name: 'editDeleteMultiButtonCell',
          label: '',
          width: 20,
          cell: MultiButtonCell.extend({
            buttons: [
              {
                name: '',
                labelClass: 'fa fa-edit',
                buttonClass: 'btn-sm btn-primary edit-ag-payment',
                onClick: this.onClickEditAGReferralPayment.bind(this)
              },
              {
                name: '',
                labelClass: 'fa fa-trash',
                buttonClass: 'btn-danger btn-sm delete-ag-payment',
                onClick: this.onClickDelete.bind(this)
              }
            ]
          }),
          editable: false,
          sortable: false
        }
      ],
      emptyText: 'No Payments',
      collection: new Backbone.Collection(payments)
    });
    this.showChildView('AGPaymentGrid', this.AGPaymentGrid);
  },

  onClickEditAGReferral: function(e) {
    e.preventDefault();

    UIUtil.showModal({
      title: 'Edit AG Referral',
      view: new SecComplianceAGReferralEditModalView({ model: this.model })
    }).done(
      function(updatedReferral) {
        this.model.set('AGReferralDate', updatedReferral.AGReferralDate);
        this.model.set('AGReferredAmount', updatedReferral.AGReferredAmount);

        this.AGReferralsTotalsView = new SecComplianceAGReferralsTotalsView({ model: this.model });
        this.showChildView('AGReferralsTotals', this.AGReferralsTotalsView);
      }.bind(this)
    );
  },

  onClickAddAGReferralPayment: function(e) {
    e.preventDefault();
    UIUtil.showModal({
      title: 'Add Payment',
      view: new SecComplianceAGReferralPaymentModalView({
        model: new Backbone.Model({
          complianceId: this.model.get('complianceId')
        })
      })
    }).done(
      function(AGPayment) {
        AGPayment.paymentDateDisplay = momentUtil.formatStr(
          AGPayment.paymentDate,
          momentUtil.defaultOutboundDateConfig
        );
        AGPayment.checkNumberDisplay = AGPayment.checkNumber || '--';
        AGPayment.transactionNumberDisplay = AGPayment.transactionNumber || '--';

        this.AGPaymentGrid.collection.push(AGPayment);

        this.model.set('AGPayments', this.AGPaymentGrid.collection.toJSON());

        this.AGReferralsTotalsView = new SecComplianceAGReferralsTotalsView({ model: this.model });
        this.showChildView('AGReferralsTotals', this.AGReferralsTotalsView);
      }.bind(this)
    );
  },

  onClickEditAGReferralPayment: function(e, originalPayment) {
    e.preventDefault();
    UIUtil.showModal({
      title: 'Edit Payment',
      view: new SecComplianceAGReferralPaymentModalView({
        model: originalPayment
      })
    }).done(
      function(updatedPayment) {
        updatedPayment.paymentDateDisplay = momentUtil.formatStr(
          updatedPayment.paymentDate,
          momentUtil.defaultOutboundDateConfig
        );
        updatedPayment.checkNumberDisplay = updatedPayment.checkNumber || '--';
        updatedPayment.transactionNumberDisplay = updatedPayment.transactionNumber || '--';

        originalPayment.set(updatedPayment);

        this.model.set('AGPayments', this.AGPaymentGrid.collection.toJSON());

        this.AGReferralsTotalsView = new SecComplianceAGReferralsTotalsView({ model: this.model });
        this.showChildView('AGReferralsTotals', this.AGReferralsTotalsView);
      }.bind(this)
    );
  },

  onClickDelete: function(e, AGPayment) {
    e.preventDefault();
    var self = this;
    var AGPaymentId = AGPayment.get('AGPaymentId');

    UIUtil.showModal({
      title: 'Confirm Deleting AG Referral Payment',
      view: new ConfirmationModalView({
        confirmText: 'Delete AG Referral Payment',
        model: new Backbone.Model({
          message: 'Are you sure you would like to delete this AG referral payment?',
          onConfirm: function() {
            var data = {
              AGPaymentId: AGPaymentId,
              isDeleted: '1'
            };
            return SubmitComplianceAGReferralPayment(data);
          },
          onConfirmDone: function() {
            self.AGPaymentGrid.collection.remove(AGPayment);

            self.model.set('AGPayments', self.AGPaymentGrid.collection.toJSON());

            self.AGReferralsTotalsView = new SecComplianceAGReferralsTotalsView({ model: self.model });
            self.showChildView('AGReferralsTotals', self.AGReferralsTotalsView);
          }
        })
      })
    });
  }
});
