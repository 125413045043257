/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var moment = require('moment');

var cleanUpResponse = require('common/util/cleanUpResponse');

module.exports = function(complianceId) {
  var defer = $.Deferred();
  $.soap({
    body: {
      GetComplianceDetails: {
        $xmlns: 'http://dobs.pa.gov/nd/compliance/bpm/1.0',
        ComplianceID: complianceId
      }
    },
    blueprintArrays: ['GetComplianceDetailsResponse.ComplianceViolations.ND_ComplianceViolation'],
    blueprint: {
      complianceId: '$..ComplianceID',
      CSFolderId: '$..CSFolderID',
      complianceStatus: '$..ComplianceStatus',
      orderType: '$..OrderType',
      entityName: '$..EntityName',
      licenseId: '$..LicenseID',
      licenseNumber: '$..LicenseNumber',
      licenseTypeId: '$..LicenseTypeID',
      licenseTypeCode: '$..LicenseTypeCode',
      licenseType: '$..LicenseType',
      processInstanceId: '$..ProcessInstanceID',
      entityId: '$..EntityID',
      tin: '$..TIN',
      csFolderId: '$..CSFolderID',
      unlicensedAsLicenseTypeId: '$..UnlicensedAsLicenseTypeID',
      unlicensedLicenseType: '$..UnlicensedLicenseType',
      unlicensedAsLicenseTypeCode: '$..UnlicensedAsLicenseTypeCode',
      submittedBy: '$..SubmittedBy',
      submissionDate: '$..SubmissionDate',
      modifiedBy: '$..ModifiedBy',
      modifiedDate: '$..ModifiedDate',
      isDeleted: '$..IsDeleted',
      backgroundInfo: '$..BackgroundInfo',
      statusId: '$..StatusID',
      decisionDate: '$..DecisionDate',
      decisionReason: '$..DecisionReason',
      narrative: '$..Narrative',
      administrator: '$..Administrator',
      receivedDate: '$..ReceivedDate',
      assignedDate: '$..AssignedDate',
      caseNumber: '$..CaseNumber',
      isConsentToEa: '$..IsConsentToEA',
      finalizedOrderDocument: {
        fileId: '$..FinalizedOrderDocumentID',
        fileName: '$..FinalizedOrderDocumentName'
      },
      docketNumber: '$..DocketNumber',
      orderTypeId: '$..OrderTypeID',
      negotiatedDate: '$..NegotiatedDate',
      sentToEntityDate: '$..SentToEntityDate',
      sentToLegalDate: '$..SentToLegalDate',
      fullyExecutedDate: '$..FullyExecutedDate',
      firstPaymentDate: '$..FirstPaymentDate',
      lastPaymentDate: '$..LastPaymentDate',
      finePaidInFullDate: '$..FinePaidInFullDate',
      finePaidAckLetterSentDate: '$..FinePaidAckLetterSentDate',
      ackLetterDocument: {
        fileId: '$..AckLetterDocumentID',
        fileName: '$..AckLetterDocumentName'
      },
      fineAmountOrdered: '$..FineAmountOrdered',
      fineAmountPaid: '$..FineAmountPaid',
      paymentPlanDescription: '$..PaymentPlanDescription',
      restitutionOrdered: '$..RestitutionOrdered',
      restitutionPaid: '$..RestitutionPaid',
      anticipatedFineNotIssued: '$..AnticipatedFineNotIssued',
      uncollectedDollarAmount: '$..UncollectedDollarAmount',
      referredToOag: '$..ReferredToOAG',
      underAppealAmount: '$..UnderAppealAmount',
      waivedDollarAmount: '$..WaivedDollarAmount',
      nonConsentLineNumbers: '$..NonConsentLineNumbers',
      caseNotesDocument: {
        fileId: '$..CaseNotesDocumentID',
        fileName: '$..AckLetterDocumentName'
      },
      examId: '$..ExamID',
      examCSFolderId: '$..ExamCSFolderID',
      enforcementActionReviewComments: '$..EnforcementActionReviewComments',
      complianceViolations: [
        '$..ND_ComplianceViolation',
        {
          id: '$..ID',
          complianceViolationTypeId: '$..ComplianceViolationTypeID',
          code: '$..Code',
          name: '$..Name'
        }
      ]
    }
  })
    .done(function(response) {
      cleanUpResponse(response, { cleanUpTextKeys: true });

      response.caseNumber = response.caseNumber || '--';
      response.licenseNumber = response.licenseNumber || '--';
      response.narrative = response.narrative || '--';

      response.submissionDate = response.submissionDate
        ? moment(response.submissionDate).format('MM/DD/YYYY')
        : undefined;
      response.negotiatedDate = response.negotiatedDate
        ? moment(response.negotiatedDate, 'YYYY-MM-DD').format('MM/DD/YYYY')
        : undefined;
      response.sentToEntityDate = response.sentToEntityDate
        ? moment(response.sentToEntityDate, 'YYYY-MM-DD').format('MM/DD/YYYY')
        : undefined;
      response.sentToLegalDate = response.sentToLegalDate
        ? moment(response.sentToLegalDate, 'YYYY-MM-DD').format('MM/DD/YYYY')
        : undefined;
      response.fullyExecutedDate = response.fullyExecutedDate
        ? moment(response.fullyExecutedDate, 'YYYY-MM-DD').format('MM/DD/YYYY')
        : undefined;
      response.finePaidInFullDate = response.finePaidInFullDate
        ? moment(response.finePaidInFullDate, 'YYYY-MM-DD').format('MM/DD/YYYY')
        : undefined;
      response.firstPaymentDate = response.firstPaymentDate
        ? moment(response.firstPaymentDate, 'YYYY-MM-DD').format('MM/DD/YYYY')
        : undefined;
      response.lastPaymentDate = response.lastPaymentDate
        ? moment(response.lastPaymentDate, 'YYYY-MM-DD').format('MM/DD/YYYY')
        : undefined;
      response.finePaidAckLetterSentDate = response.finePaidAckLetterSentDate
        ? moment(response.finePaidAckLetterSentDate, 'YYYY-MM-DD').format('MM/DD/YYYY')
        : undefined;

      if (!response.licenseId) {
        response.licenseCode = 'UL - ' + response.unlicensedAsLicenseTypeCode;
      } else {
        response.licenseCode = response.licenseTypeCode;
      }

      defer.resolve(response);
    })
    .fail(function(err) {
      defer.reject();
    });
  return defer.promise();
};
