/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var moment = require('moment');
var Marionette = require('backbone.marionette');

var PageableCollection = require('psa-backbone/models/PageableCollection');
var GridView = require('psa-marionette/views/GridView');

var ButtonLinkCell = require('common/backgrid/ButtonLinkCell');
var MomentCell = require('common/backgrid/MomentCell');

var tmplNonDepLicenseExamsView = require('../templates/NonDepLicenseExamsView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplNonDepLicenseExamsView,

  className: 'non-dep-license-exams-view',

  ui: {
    examsContainer: '.exams-container'
  },

  regions: {
    examsContainer: '@ui.examsContainer'
  },

  onBeforeShow: function() {
    var exams = this.model.get('exams');
    _.each(exams, function(exam) {
      if (exam.examCoverageStartDate) {
        exam.examCoverageStartDate = moment(exam.examCoverageStartDate, 'YYYY-MM-DD');
      }
      if (exam.examCoverageEndDate) {
        exam.examCoverageEndDate = moment(exam.examCoverageEndDate, 'YYYY-MM-DD');
      }
      if (exam.examStartDate){
        exam.examStartDate = moment(exam.examStartDate, 'YYYY-MM-DD');
      }
      if (exam.examEndDate){
        exam.examEndDate = moment(exam.examEndDate, 'YYYY-MM-DD');
      }
    });
    var examsGrid = new GridView({
      columns: [
        {
          name: 'examId',
          label: 'Exam Number',
          cell: 'string',
          headerCell: 'custom',
          editable: false,
          width: 15
        },
        {
          name: 'status',
          label: 'Exam Status',
          cell: 'string',
          headerCell: 'custom',
          editable: false,
          width: 20
        },
        {
          name: 'examStartDate',
          label: 'Exam Start Date',
          cell: MomentCell.MomentCell,
          formatter: MomentCell.MomentDateFormatter.extend({
            emptyValue: '--'
          }),
          sortValue: MomentCell.MomentSortValue,
          headerCell: 'custom',
          editable: false,
          width: 25
        },
        {
          name: 'examEndDate',
          label: 'Exam End Date',
          cell: MomentCell.MomentCell,
          formatter: MomentCell.MomentDateFormatter.extend({
            emptyValue: '--'
          }),
          sortValue: MomentCell.MomentSortValue,
          headerCell: 'custom',
          editable: false,
          width: 25
        },
        {
          name: '',
          label: '',
          cell: ButtonLinkCell.extend({
            name: 'Open',
            buttonClass: 'btn-primary btn-sm',
            href: function(model) {
              return '#nondep/dashboard/exam/' + model.get('examId');
            }
          }),
          headerCell: 'custom',
          width: 15,
          editable: false,
          sortable: false
        }
      ],
      collection: new PageableCollection(exams, { pageSize: 10 }),
      emptyText: 'No Exams'
    });
    this.showChildView('examsContainer', examsGrid);
  }
});
