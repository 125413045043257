var Handlebars = require("handlebars/dist/handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "  <div class=\"form-group\">\n    <div class=\"col-sm-9 col-lg-7 col-sm-offset-3\">\n      <button type=\"button\" class=\"btn btn-primary close-correspondence\">Mark as Closed</button>\n    </div>\n  </div>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.escapeExpression, alias2=depth0 != null ? depth0 : (container.nullContext || {}), alias3=helpers.helperMissing;

  return "\n<div class=\"page-title clearfix\">\n  <h1 class=\"pull-left m-n p-n\">Complaint - "
    + alias1(container.lambda(((stack1 = (depth0 != null ? depth0.correspondence : depth0)) != null ? stack1.complaintCaseNumber : stack1), depth0))
    + "</h1>\n</div>\n<div class=\"header-bar\"></div>\n<div class=\"breadcrumb-container\"></div>\n<div class=\"form-horizontal\">\n  <form class=\"correspondence-form\">\n    <div class=\"int-correspondence-form\">\n      <div class=\"row\">\n        <div class=\"col-sm-9 col-sm-offset-3\">\n          <h2>Internal Correspondence</h2>\n        </div>\n      </div>\n      <div class=\"form-group\">\n        <label class=\"control-label col-sm-3\">Message From Dobs</label>\n        <div class=\"col-sm-9 col-md-6\">\n          <p class=\"form-control-static\">"
    + alias1((helpers["default"] || (depth0 && depth0["default"]) || alias3).call(alias2,((stack1 = (depth0 != null ? depth0.correspondence : depth0)) != null ? stack1.requestComments : stack1),"--",{"name":"default","hash":{},"data":data}))
    + "</p>\n        </div>\n      </div>\n      <div class=\"form-group\">\n        <label class=\"control-label col-sm-3 \">Internal Documents</label>\n        <div class=\" col-sm-9 col-md-6\">\n          <div class=\"int-file-upload-table-container\"></div>\n        </div>\n      </div>\n      <div class=\"row\">\n        <div class=\"col-sm-9 col-sm-offset-3\">\n          <h2>External Response</h2>\n        </div>\n      </div>\n      <div class=\"form-group\">\n        <label class=\"control-label col-sm-3\">Reply Comments</label>\n        <div class=\"col-sm-9 col-md-6\">\n          <p class=\"form-control-static\">"
    + alias1((helpers["default"] || (depth0 && depth0["default"]) || alias3).call(alias2,((stack1 = (depth0 != null ? depth0.correspondence : depth0)) != null ? stack1.replyComments : stack1),"--",{"name":"default","hash":{},"data":data}))
    + "</p>\n        </div>\n      </div>\n      <div class=\"form-group\">\n        <label class=\"control-label col-sm-3 \">External Documents</label>\n        <div class=\" col-sm-9 col-md-6\">\n          <div class=\"ext-file-upload-table-container\"></div>\n        </div>\n      </div>\n    </div>\n    <div class=\"ext-correspondence-form\">\n      <div class=\"row\">\n        <div class=\"col-sm-9 col-sm-offset-3\">\n          <h2>External Correspondence</h2>\n        </div>\n      </div>\n      <div class=\"form-group\">\n        <label class=\"control-label col-sm-3\">Message From External</label>\n        <div class=\"col-sm-9 col-md-6\">\n          <p class=\"form-control-static\">"
    + alias1((helpers["default"] || (depth0 && depth0["default"]) || alias3).call(alias2,((stack1 = (depth0 != null ? depth0.correspondence : depth0)) != null ? stack1.requestComments : stack1),"--",{"name":"default","hash":{},"data":data}))
    + "</p>\n        </div>\n      </div>\n      <div class=\"form-group\">\n        <label class=\"control-label col-sm-3 \">External Documents</label>\n        <div class=\" col-sm-9 col-md-6\">\n          <div class=\"ext-correspondence-file-upload\"></div>\n        </div>\n      </div>\n      <div class=\"row\">\n        <div class=\"col-sm-9 col-sm-offset-3\">\n          <h2>Internal Response</h2>\n        </div>\n      </div>\n      <div class=\"form-group\">\n        <label class=\"control-label col-sm-3\">Reply Comments</label>\n        <div class=\"col-sm-9 col-md-6\">\n          <p class=\"form-control-static\">"
    + alias1((helpers["default"] || (depth0 && depth0["default"]) || alias3).call(alias2,((stack1 = (depth0 != null ? depth0.correspondence : depth0)) != null ? stack1.replyComments : stack1),"--",{"name":"default","hash":{},"data":data}))
    + "</p>\n        </div>\n      </div>\n      <div class=\"form-group\">\n        <label class=\"control-label col-sm-3 \">Internal Documents</label>\n        <div class=\" col-sm-9 col-md-6\">\n          <div class=\"int-response-file-upload\"></div>\n        </div>\n      </div>\n    </div>\n    <div class=\"row\">\n      <div class=\"col-sm-9 col-sm-offset-3\">\n        <h2>Correspondence Details</h2>\n      </div>\n    </div>\n    <div class=\"form-group\">\n      <label class=\"control-label col-sm-3\">Status</label>\n      <div class=\"col-sm-9 col-md-6\">\n        <p class=\"form-control-static \">"
    + alias1((helpers["default"] || (depth0 && depth0["default"]) || alias3).call(alias2,((stack1 = (depth0 != null ? depth0.correspondence : depth0)) != null ? stack1.status : stack1),"--",{"name":"default","hash":{},"data":data}))
    + "</p>\n      </div>\n    </div>\n    <div class=\"ext-reply-form\">\n      <div class=\"form-group\">\n        <label class=\"control-label col-sm-3\">Correspondence Replied By</label>\n        <div class=\"col-sm-9 col-md-6\">\n          <p class=\"form-control-static \">"
    + alias1((helpers["default"] || (depth0 && depth0["default"]) || alias3).call(alias2,((stack1 = (depth0 != null ? depth0.correspondence : depth0)) != null ? stack1.repliedByName : stack1),"--",{"name":"default","hash":{},"data":data}))
    + "</p>\n        </div>\n      </div>\n      <div class=\"form-group\">\n        <label class=\"control-label col-sm-3\">Replied By Email</label>\n        <div class=\"col-sm-9 col-md-6\">\n          <p class=\"form-control-static \">"
    + alias1((helpers["default"] || (depth0 && depth0["default"]) || alias3).call(alias2,((stack1 = (depth0 != null ? depth0.correspondence : depth0)) != null ? stack1.repliedByEmail : stack1),"--",{"name":"default","hash":{},"data":data}))
    + "</p>\n        </div>\n      </div>\n      <div class=\"form-group\">\n        <label class=\"control-label col-sm-3\">Reply Date</label>\n        <div class=\"col-sm-9 col-md-6\">\n          <p class=\"form-control-static \">"
    + alias1((helpers["default"] || (depth0 && depth0["default"]) || alias3).call(alias2,((stack1 = (depth0 != null ? depth0.correspondence : depth0)) != null ? stack1.repliedOn : stack1),"--",{"name":"default","hash":{},"data":data}))
    + "</p>\n        </div>\n      </div>\n    </div>\n    <div class=\"ext-request-form\">\n      <div class=\"form-group\">\n        <label class=\"control-label col-sm-3\">Correspondence Initiated By</label>\n        <div class=\"col-sm-9 col-md-6\">\n          <p class=\"form-control-static \">"
    + alias1((helpers["default"] || (depth0 && depth0["default"]) || alias3).call(alias2,((stack1 = (depth0 != null ? depth0.correspondence : depth0)) != null ? stack1.requestedByName : stack1),"--",{"name":"default","hash":{},"data":data}))
    + "</p>\n        </div>\n      </div>\n      <div class=\"form-group\">\n        <label class=\"control-label col-sm-3\">Initiated By Email</label>\n        <div class=\"col-sm-9 col-md-6\">\n          <p class=\"form-control-static \">"
    + alias1((helpers["default"] || (depth0 && depth0["default"]) || alias3).call(alias2,((stack1 = (depth0 != null ? depth0.correspondence : depth0)) != null ? stack1.requestedByEmail : stack1),"--",{"name":"default","hash":{},"data":data}))
    + "</p>\n        </div>\n      </div>\n      <div class=\"form-group\">\n        <label class=\"control-label col-sm-3\">Initiated Date</label>\n        <div class=\"col-sm-9 col-md-6\">\n          <p class=\"form-control-static \">"
    + alias1((helpers["default"] || (depth0 && depth0["default"]) || alias3).call(alias2,((stack1 = (depth0 != null ? depth0.correspondence : depth0)) != null ? stack1.requestedOn : stack1),"--",{"name":"default","hash":{},"data":data}))
    + "</p>\n        </div>\n      </div>\n    </div>\n    <div class=\"form-group\">\n      <label class=\"control-label col-sm-3\">Correspondence Withdrawn By</label>\n      <div class=\"col-sm-9 col-md-6\">\n        <p class=\"form-control-static \">"
    + alias1((helpers["default"] || (depth0 && depth0["default"]) || alias3).call(alias2,((stack1 = (depth0 != null ? depth0.correspondence : depth0)) != null ? stack1.withdrawnByName : stack1),"--",{"name":"default","hash":{},"data":data}))
    + "</p>\n      </div>\n    </div>\n    <div class=\"form-group\">\n      <label class=\"control-label col-sm-3\">Withdrawn Date</label>\n      <div class=\"col-sm-9 col-md-6\">\n        <p class=\"form-control-static \">"
    + alias1((helpers["default"] || (depth0 && depth0["default"]) || alias3).call(alias2,((stack1 = (depth0 != null ? depth0.correspondence : depth0)) != null ? stack1.withdrawnOn : stack1),"--",{"name":"default","hash":{},"data":data}))
    + "</p>\n      </div>\n    </div>\n    <div class=\"form-group\">\n      <label class=\"control-label col-sm-3\">Withdrawn Comments</label>\n      <div class=\"col-sm-9 col-md-6\">\n        <p class=\"form-control-static\">"
    + alias1((helpers["default"] || (depth0 && depth0["default"]) || alias3).call(alias2,((stack1 = (depth0 != null ? depth0.correspondence : depth0)) != null ? stack1.withdrawnComments : stack1),"--",{"name":"default","hash":{},"data":data}))
    + "</p>\n      </div>\n    </div>\n    <div class=\"form-group\">\n      <label class=\"control-label col-sm-3\">Correspondence Closed By</label>\n      <div class=\"col-sm-9 col-md-6\">\n        <p class=\"form-control-static \">"
    + alias1((helpers["default"] || (depth0 && depth0["default"]) || alias3).call(alias2,((stack1 = (depth0 != null ? depth0.correspondence : depth0)) != null ? stack1.closedByName : stack1),"--",{"name":"default","hash":{},"data":data}))
    + "</p>\n      </div>\n    </div>\n    <div class=\"form-group\">\n      <label class=\"control-label col-sm-3\">Closed Date</label>\n      <div class=\"col-sm-9 col-md-6\">\n        <p class=\"form-control-static \">"
    + alias1((helpers["default"] || (depth0 && depth0["default"]) || alias3).call(alias2,((stack1 = (depth0 != null ? depth0.correspondence : depth0)) != null ? stack1.closedOn : stack1),"--",{"name":"default","hash":{},"data":data}))
    + "</p>\n      </div>\n    </div>\n  </form>\n"
    + ((stack1 = helpers["if"].call(alias2,(depth0 != null ? depth0.responseReceived : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "</div>";
},"useData":true});