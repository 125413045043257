/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var get = require('lodash.get');
var moment = require('moment');

var getUserIdFromDN = require('common/util/getUserIdFromDn');
var cleanUpResponse = require('common/util/cleanUpResponse');
var isNonDepAmendmentTask = require('../../common/util/isNonDepAmendmentTask');

module.exports = function() {
  var deferred = $.Deferred();

  $.soap({
    body: {
      GetAvailableTasks: {
        $xmlns: 'http://dobs.pa.gov/nd/task/bpm/1.0'
      }
    },
    blueprintArrays: [
      'GetAvailableTasksResponse.Tasks.NOTF_TASK_INSTANCE',
      'GetAvailableTasksResponse.Applications.ND_Application',
      'GetAvailableTasksResponse.TaskTypes.CM_LU_TaskType',
      'GetAvailableTasksResponse.Exams.ND_Exam',
      'GetAvailableTasksResponse.Compliances.ND_Compliance',
      'GetAvailableTasksResponse.Correspondences.ND_Correspondence'
    ],
    blueprint: {
      tasks: [
        '$..NOTF_TASK_INSTANCE',
        {
          taskID: '$..TaskId',
          deliveryDate: '$..DeliveryDate',
          applicationId: '$..ApplicationID',
          entityId: '$..EntityID',
          examId: '$..ExamID',
          complianceId: '$..ComplianceID',
          taskTypeName: '$..TaskTypeName',
          assignedRole: '$..Target.__text',
          correspondenceId: '$..CorrespondenceID'
        }
      ],
      taskTypes: [
        '$..CM_LU_TaskType',
        {
          id: '$.ID',
          name: '$.Name',
          description: '$.Description'
        }
      ],
      applications: [
        '$..ND_Application',
        {
          id: '$..ApplicationID',
          entityName: '$..EntityName',
          nmlsId: '$..NMLSID',
          applicationType: '$..ApplicationType',
          applicationCategory: '$..ApplicationCategory',
          licenseType: '$..LicenseType',
          licenseNumber: '$..LicenseNumber',
          licenseId: '$..LicenseID',
          address: {
            addressLine1: '$..AddressLine1',
            addressLine2: '$..AddressLine2',
            addressLine3: '$..AddressLine3',
            city: '$..City',
            state: '$..State',
            postalCode: '$..PostalCode',
            country: '$..Country'
          }
        }
      ],
      exams: [
        '$..ND_Exam',
        {
          id: '$..ExamID',
          entityName: '$..EntityName',
          nmlsId: '$..NMLSID',
          licenseType: '$..LicenseType',
          licenseNumber: '$..LicenseNumber',
          licenseId: '$..LicenseID',
          address: {
            addressLine1: '$..AddressLine1',
            addressLine2: '$..AddressLine2',
            addressLine3: '$..AddressLine3',
            city: '$..City',
            state: '$..State',
            postalCode: '$..PostalCode',
            country: '$..Country'
          }
        }
      ],
      compliances: [
        '$..ND_Compliance',
        {
          id: '$..ComplianceID',
          entityName: '$..EntityName',
          nmlsId: '$..NMLSID',
          entityId: '$..EntityID',
          licenseType: '$..LicenseType',
          operatingAsType: '$..OperatingAsLicenseType',
          licenseNumber: '$..LicenseNumber',
          licenseId: '$..LicenseID',
          address: {
            addressLine1: '$..AddressLine1',
            addressLine2: '$..AddressLine2',
            addressLine3: '$..AddressLine3',
            city: '$..City',
            state: '$..State',
            postalCode: '$..PostalCode',
            country: '$..Country'
          }
        }
      ],
      correspondences: [
        '$..ND_Correspondence',
        {
          correspondenceId: '$..CorrespondenceID',
          entityName: '$..EntityName',
          nmlsId: '$..NMLSID',
          licenseType: '$..LicenseType',
          licenseNumber: '$..LicenseNumber',
          licenseId: '$..LicenseID',
          applicationCategory: '$..ApplicationCategory',
          applicationType: '$..ApplicationType',
          address: {
            addressLine1: '$..AddressLine1',
            addressLine2: '$..AddressLine2',
            addressLine3: '$..AddressLine3',
            city: '$..City',
            state: '$..State',
            postalCode: '$..PostalCode',
            country: '$..Country'
          }
        }
      ]
    }
  })
    .done(function(response) {
      cleanUpResponse(response);
      // create a moment object for each draft app's last modified date
      _.each(response.tasks, function(task) {
        task.deliveryDate = moment.utc(task.deliveryDate, moment.ISO_8601).local();

        var type = _.find(response.taskTypes, function(type) {
          return task.taskTypeName === type.name;
        });
        task.type = type.description;

        task.assignedRole = getUserIdFromDN(task.assignedRole);

        if (task.applicationId) {
          var application = _.find(response.applications, function(application) {
            return task.applicationId === application.id;
          });
          if (application) {
            task.applicationType = application.applicationCategory || '--';
            task.entityName = application.entityName;
            task.nmlsId = application.nmlsId || '--';
            task.licenseType = application.licenseType || '--';
            task.licenseId = application.licenseId;
            task.licenseNumber = application.licenseNumber || '--';
            task.location = get(application, 'address.addressLine1', '--') || '--';
            task.city = get(application, 'address.city', '--') || '--';
            task.state = get(application, 'address.state', '--') || '--';
            var isAmendmentTask = isNonDepAmendmentTask(type.id);
            if (isAmendmentTask) {
              task.type += ' - ' + application.applicationType;
            }
          }
        } else if (task.examId) {
          /* Handle Exam Meta Data */

          var exam = _.find(response.exams, function(exam) {
            return task.examId === exam.id;
          });

          if (exam) {
            task.applicationType = '--';
            task.entityName = exam.entityName;
            task.nmlsId = exam.nmlsId || '--';
            task.licenseType = exam.licenseType || '--';
            task.licenseId = exam.licenseId;
            task.licenseNumber = exam.licenseNumber || '--';
            task.location = get(exam, 'address.addressLine1', '--') || '--';
            task.city = get(exam, 'address.city', '--') || '--';
            task.state = get(exam, 'address.state', '--') || '--';
          }
        } else if (task.complianceId) {
          /* Handle Compliance Meta Data */

          var compliance = _.find(response.compliances, function(compliance) {
            return task.complianceId === compliance.id;
          });

          if (compliance) {
            task.applicationType = '--';
            task.entityId = compliance.entityId;
            task.nmlsId = compliance.nmlsId || '--';
            task.entityName = compliance.entityName;
            task.licenseId = compliance.licenseId;
            task.licenseType = compliance.licenseType || compliance.operatingAsType || '--';
            task.licenseNumber = compliance.licenseNumber || '--';
            task.location = get(compliance, 'address.addressLine1', '--') || '--';
            task.city = get(compliance, 'address.city', '--') || '--';
            task.state = get(compliance, 'address.state', '--') || '--';
          }
        } else if (task.correspondenceId) {
          var correspondence = _.find(response.correspondences, function(correspondence) {
            return task.correspondenceId === correspondence.correspondenceId;
          });
          if (correspondence) {
            task.applicationType = correspondence.applicationType || '--';
            task.entityName = correspondence.entityName;
            task.nmlsId = correspondence.nmlsId || '--';
            task.licenseId = correspondence.licenseId;
            task.licenseType = correspondence.licenseType || '--';
            task.licenseNumber = correspondence.licenseNumber || '--';
            task.location = get(correspondence, 'address.addressLine1', '--') || '--';
            task.city = get(correspondence, 'address.city', '--') || '--';
            task.state = get(correspondence, 'address.state', '--') || '--';
          }
        }
      });

      deferred.resolve(response.tasks);
    })
    .fail(function(err) {
      deferred.reject();
    });

  return deferred.promise();
};
