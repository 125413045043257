/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Backbone = require('backbone');

var GridView = require('psa-marionette/views/GridView');
var UIUtil = require('psa-core/util/ui');

var MultiButtonCell = require('common/backgrid/MultiButtonCell');
var AddressCell = require('common/backgrid/AddressCell');
var ConfirmationModalView = require('common/views/ConfirmationModalView');

var SecIndividualAddressModalView = require('./SecIndividualAddressModalView');
var SubmitIndividualAddress = require('../services/SubmitIndividualAddress');

module.exports = GridView.extend({
  className: 'sec-individual-section-address-view',

  onBeforeRender: function() {
    if (GridView.prototype.onBeforeRender) {
      GridView.prototype.onBeforeRender.apply(this.arguments);
    }
    this.options.columns = [
      {
        name: 'type',
        label: 'Type',
        cell: 'string',
        headerCell: 'custom',
        width: 40,
        editable: false
      },
      {
        name: 'address',
        label: 'Address',
        cell: AddressCell,
        headerCell: 'custom',
        width: 40,
        editable: false,
        sortable: false
      },
      {
        name: 'editDeleteMultiButtonCell',
        label: '',
        cell: MultiButtonCell.extend({
          buttons: [
            {
              name: '',
              labelClass: 'fa fa-edit',
              buttonClass: 'btn-primary btn-sm edit-processing-response',
              onClick: this.onClickEdit.bind(this)
            },
            {
              name: '',
              labelClass: 'fa fa-trash',
              buttonClass: 'btn-danger btn-sm delete-processing-response',
              onClick: this.onClickDelete.bind(this)
            }
          ]
        }),
        editable: false,
        sortable: false,
        width: 20
      }
    ];
    this.options.emptyText = 'No Addresses';
  },

  onClickEdit: function(e, model) {
    e.preventDefault();

    UIUtil.showModal({
      title: 'Edit Address Details',
      view: new SecIndividualAddressModalView({
        model: model,
        addressTypes: this.options.addressTypes
      })
    }).done(function(address) {
      model.set(address);
    });
  },

  onClickDelete: function(e, model) {
    e.preventDefault();

    UIUtil.showModal({
      title: 'Confirm Deleting Individual Address',
      confirmText: 'Delete Individual Address',
      view: new ConfirmationModalView({
        model: new Backbone.Model({
          message: 'Are you sure you want to delete this individual address?',
          onConfirm: function() {
            return SubmitIndividualAddress({
              individualAddressId: model.get('id'),
              isDeleted: '1'
            });
          },
          onConfirmDone: function() {
            this.collection.remove(model);
          }.bind(this)
        })
      })
    });
  }
});
