/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

// Special handling for libraries that like the `window`
/* eslint-disable no-global-assign */
window.jQuery = $ = require('jquery');
/* eslint-enable no-global-assign */
var _ = require('underscore');
var Handlebars = require('handlebars');
var Swag = require('swag');
var Backbone = require('backbone');
var Marionette = require('backbone.marionette');
var Syphon = require('backbone.syphon');
var Backgrid = require('backgrid');
var NProgress = require('nprogress');

var psaJQuery = require('psa-core/lib/jquery');
var psaHandlebars = require('psa-core/lib/handlebars');
var psaBackgrid = require('psa-core/lib/backgrid');

var Config = require('./config/Config');
var importAll = require('./common/util/importAll');
var handleAjaxError = require('./common/util/handleAjaxError');
var jqueryExtensions = require('./common/util/jqueryExtensions');

require('select2/dist/js/select2.full');
require('messenger');
require('bootstrap');
require('bootstrap-datepicker');
require('bootstrap-timepicker');
require('psa-backgrid/CustomHeaderCell');

require('./common/util/sortingExtensions');

// The bodybuilder.js module uses Object.assign, which is not supported in ie11.
// Load a polyfill for this method.
require('es6-object-assign/auto');

require('../node_modules/select2/dist/css/select2.css');
require('../node_modules/select2-bootstrap-theme/dist/select2-bootstrap.css');
require('./common/styles/bootstrap.less');
require('../node_modules/bootstrap-datepicker/dist/css/bootstrap-datepicker.css');
require('../node_modules/bootstrap-timepicker/css/bootstrap-timepicker.css');
require('../node_modules/messenger/build/css/messenger.css');
require('../node_modules/messenger/build/css/messenger-theme-flat.css');
require('../node_modules/backgrid/lib/backgrid.css');
require('../node_modules/psa-goodies/accordion-nav-wizard/css/accordion-nav-wizard.css');
require('../node_modules/psa-marionette/css/file-upload.css');
require('../node_modules/font-awesome/css/font-awesome.css');
require('../node_modules/ladda/dist/ladda-themeless.min.css');
require('../node_modules/nprogress/nprogress.css');

require('./common/styles/global.css');
require('./common/styles/alert.css');
require('./common/styles/backgrid.css');
require('./common/styles/buttons.css');
require('./common/styles/callouts.css');
require('./common/styles/color-scheme.css');
require('./common/styles/datepicker.css');
require('./common/styles/display.css');
require('./common/styles/forms.css');
require('./common/styles/icon-addon.css');
require('./common/styles/messenger.css');
require('./common/styles/modal.css');
require('./common/styles/navbar.css');
require('./common/styles/nprogress.css');
require('./common/styles/page.css');
require('./common/styles/select2.css');
require('./common/styles/text.css');
require('./common/styles/spacing.css');

// select2 Configuration
$.fn.select2.defaults.set('theme', 'bootstrap');
$.fn.select2.defaults.set('width', '100%');

// Bootstrap Datepicker Configuration
$.fn.datepicker.defaults.autoclose = true;
$.fn.datepicker.defaults.format = 'mm/dd/yyyy';
$.fn.datepicker.defaults.assumeNearbyYear = true;

// Parsley Configuration
window.ParsleyConfig = {
  successClass: '',
  errorClass: 'has-error',
  classHandler: function(el) {
    return el.$element.closest('.form-group');
  },
  errorsWrapper: '<span class="help-block"></span>',
  errorTemplate: '<span></span>',
  excluded:
    'input[type=button], input[type=submit], input[type=reset], input[type=hidden], [disabled], [disabled] *, input.select2-search__field'
};
require('parsleyjs');
importAll(require.context('./', true, /\/parsley\/..*\.js$/));

// Messenger Configuration
Messenger.options = {
  extraClasses: 'messenger-fixed messenger-on-bottom messenger-on-right',
  theme: 'flat'
};

// Backbone Syphon Configuration
Syphon.ignoredTypes.push('[disabled]');
Syphon.ignoredTypes.push('[disabled] *');
Syphon.ignoredTypes.push('file');
Syphon.InputWriters.register('radio', function($el, value) {
  var checked = _.isUndefined(value) || _.isNull(value) ? false : $el.val() === value.toString();
  $el.prop('checked', checked);
});
Syphon.InputWriters.register('select', function($el, value) {
  if (!$el.hasClass('pa-counties-select-DO-NOT-USE')) {
    if (value) {
      $el.val(value);
    } else {
      var $emptyOption = $el.find('option[value=""]');
      if ($emptyOption.length) {
        $el.val('');
      }
    }
  }
});
// Separate InputReaderSet for elastic search views
Syphon.SearchInputReaderSet = new Syphon.InputReaderSet();
Syphon.SearchInputReaderSet.registerDefault(function($el) {
  var input = $el.val();
  if (_.isString(input)) {
    return input.trim();
  }
  return input;
});
Syphon.SearchInputReaderSet.register('checkbox', Syphon.InputReaders.get('checkbox'));

// NProgress Configuration
NProgress.configure({ showSpinner: false });

// jQuery Extensions
$.serverConfig = Config;
// _.extend($, psaJQuery.extensions);
_.extend($, jqueryExtensions);
$.ajaxSetup(psaJQuery.ajaxSetup);
$(document).ajaxError(handleAjaxError);

// Handlebars Extensions
psaHandlebars.registerHelpers(Handlebars, psaHandlebars.helpers);
Swag.registerHelpers(Handlebars);
importAll(require.context('./', true, /\/handlebars\/..*\.js$/));

// Backbone Extensions
Backbone.$ = $;

// Backgrid Extensions
_.extend(Backgrid.Grid.prototype, psaBackgrid);
_.extend(Backgrid.Grid.prototype, {
  className: 'backgrid table table-striped'
});

// Marionette Extensions
var Behaviors = importAll(require.context('./common', true, /\/behaviors\/..*\.js$/), true);
Marionette.Behaviors.behaviorsLookup = function() {
  return Behaviors;
};

if (window.__agent) {
  window.__agent.start(Backbone, Marionette);
}

require('./common/ContextLoader');

module.exports = {};
