var Handlebars = require("handlebars/dist/handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, alias1=container.escapeExpression;

  return "            <option value=\""
    + alias1(((helper = (helper = helpers.key || (data && data.key)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"key","hash":{},"data":data}) : helper)))
    + "\">"
    + alias1(container.lambda(depth0, depth0))
    + "</option>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "\n<div class=\"side-bar-container pull-left\"></div>\n<div class=\"dashboard-container pull-right\">\n  <div class=\"clearfix\">\n    <h2 class=\"pull-left\">Person Search</h2>\n  </div>\n  <div class=\"row\">\n    <div class=\"col-md-3\">\n      <div class=\"form-group\">\n        <label class=\"control-label\">Person Type</label>\n        <select type=\"text\" name=\"personType\" class=\"form-control\">\n          <option value=\"\">--</option>\n"
    + ((stack1 = helpers.each.call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.personTypes : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "        </select>\n      </div>\n    </div>\n    <div class=\"col-md-3\">\n      <div class=\"form-group\">\n        <label class=\"control-label\">First Name</label>\n        <input type=\"text\" name=\"firstName\" class=\"form-control\" placeholder=\"First Name\" />\n      </div>\n    </div>\n    <div class=\"col-md-3\">\n      <div class=\"form-group\">\n        <label class=\"control-label\">Last Name</label>\n        <input type=\"text\" name=\"lastName\" class=\"form-control\" placeholder=\"Last Name\" />\n      </div>\n    </div>\n  </div>\n  <div class=\"search-buttons m-b-sm\">\n    <button class=\"btn btn-primary ladda-button search\" data-style=\"expand-right\">\n      <span class=\"ladda-label\"><i class=\"fa fa-search m-r-sm\"></i>Search</span>\n    </button>\n    <button class=\"btn btn-default clear-filters\"><i class=\"fa fa-eraser m-r-sm\"></i>Clear Filters</button>\n    <span class=\"search-summary\"></span>\n  </div>\n  <div class=\"search-results-container\"></div>\n</div>";
},"useData":true});