/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Backbone = require('backbone');
var Marionette = require('backbone.marionette');
var Syphon = require('backbone.syphon');
var Radio = require('backbone.radio');

var UIUtil = require('psa-core/util/ui');

var modelGet = require('common/util/modelGet');
var toggleForm = require('common/util/toggleForm');
var HeaderBarView = require('common/views/HeaderBarView');
var BreadcrumbView = require('common/views/BreadcrumbView');
var ConfirmationModalView = require('common/views/ConfirmationModalView');

var FileUploadView = require('common/views/FileUploadView');

var CloseCorrespondence = require('../services/CloseCorrespondence');

var tmplConSerComplaintCorrespondenceView = require('../templates/ConSerComplaintCorrespondenceView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplConSerComplaintCorrespondenceView,

  className: 'con-ser-complaint-correspondence-view container-fluid',

  ui: {
    headerBar: '.header-bar',
    breadcrumb: '.breadcrumb-container',
    correspondenceForm: '.correspondence-form',
    extFileUploadTableContainer: '.ext-file-upload-table-container',
    intFileUploadTableContainer: '.int-file-upload-table-container',
    closeCorrespondence: '.close-correspondence',
    extCorrespondenceForm: '.ext-correspondence-form',
    intCorrespondenceForm: '.int-correspondence-form',
    extCorrespondenceFileUploadTableContainer: '.ext-correspondence-file-upload',
    intResponseFileUploadTableContainer: '.int-response-file-upload',
    extReplyForm: '.ext-reply-form',
    extRequestForm: '.ext-request-form'
  },

  regions: {
    headerBar: '@ui.headerBar',
    breadcrumb: '@ui.breadcrumb',
    extFileUploadTableContainer: '@ui.extFileUploadTableContainer',
    intFileUploadTableContainer: '@ui.intFileUploadTableContainer',
    extCorrespondenceFileUpload: '@ui.extCorrespondenceFileUpload',
    extCorrespondenceFileUploadTableContainer: '@ui.extCorrespondenceFileUploadTableContainer',
    intResponseFileUploadTableContainer: '@ui.intResponseFileUploadTableContainer'
  },

  events: {
    'click @ui.closeCorrespondence': 'onClickCloseCorrespondence'
  },

  templateHelpers: function() {
    // mark as closed only available for correspondence with status = response received
    return {
      responseReceived: modelGet(this.model, 'correspondence.statusId') === '2'
    };
  },

  onBeforeShow: function() {
    var correspondence = this.model.get('correspondence') || {};
    var intDocuments = modelGet(this.model, 'correspondence.intDocuments', []);
    var extDocuments = modelGet(this.model, 'correspondence.extDocuments', []);

    var headerBarData = {
      complainantName: correspondence.complainantName || '--',
      entityName: correspondence.entityName || '--',
      title: 'Correspondence',
      status: correspondence.status || '--'
    };

    var headerBarColumns = [
      {
        name: 'entityName',
        label: 'Entity Name',
        minWidth: '160px',
        maxWidth: '350px',
        overflow: 'truncate'
      },
      {
        name: 'complainantName',
        label: 'Consumer Name',
        minWidth: '160px',
        maxWidth: '350px',
        overflow: 'truncate'
      },
      {
        name: 'status',
        label: 'Status',
        minWidth: '160px',
        maxWidth: '350px'
      }
    ];

    this.showChildView(
      'headerBar',
      new HeaderBarView({
        columns: new Backbone.Collection(headerBarColumns),
        model: new Backbone.Model(headerBarData)
      })
    );

    this.showChildView(
      'breadcrumb',
      new BreadcrumbView({
        breadcrumbs: [
          {
            link: '#conser/dashboard',
            text: 'Home'
          },
          {
            link: '#conser/complaint/' + correspondence.complaintId + '/dashboard',
            text: 'Complaint - ' + correspondence.complaintCaseNumber
          },
          {
            text: 'Initiated On ' + correspondence.requestedOn + ' By ' + correspondence.requestedByName
          }
        ]
      })
    );

    Syphon.deserialize(this, correspondence);

    this.intFileUploadTableView = new FileUploadView({
      collection: new Backbone.Collection(intDocuments),
      emptyText: 'No Internal Documents',
      isReadOnly: true
    });

    this.extFileUploadTableView = new FileUploadView({
      collection: new Backbone.Collection(extDocuments),
      emptyText: 'No External Documents',
      isReadOnly: true
    });

    var isExternalCorrespondence = correspondence.recipientTypeId === '3';

    if (isExternalCorrespondence) {
      this.showChildView('extCorrespondenceFileUploadTableContainer', this.extFileUploadTableView);
      this.showChildView('intResponseFileUploadTableContainer', this.intFileUploadTableView);
      toggleForm(this.ui.intCorrespondenceForm, false);
      toggleForm(this.ui.extReplyForm, false);
    } else {
      this.showChildView('extFileUploadTableContainer', this.extFileUploadTableView);
      this.showChildView('intFileUploadTableContainer', this.intFileUploadTableView);
      toggleForm(this.ui.extCorrespondenceForm, false);
      toggleForm(this.ui.extRequestForm, false);
    }
  },

  onClickCloseCorrespondence: function(e) {
    e.preventDefault();
    var correspondenceId = this.model.get('correspondenceId');
    var complaintId = this.model.get('complaintId');

    var message = 'Are you sure you want to mark this correspondence as closed?';
    UIUtil.showModal({
      title: 'Confirm Marking Correspondence as Closed',
      view: new ConfirmationModalView({
        confirmText: 'Mark Correspondence as Closed',
        model: new Backbone.Model({
          message: message,
          onConfirm: function() {
            return CloseCorrespondence({ correspondenceId: correspondenceId });
          },
          onConfirmDone: function() {
            Radio.channel('navigate').trigger('show:int:cs:complaint', complaintId);
          }
        })
      })
    });
  }
});
