/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');

var cleanUpResponse = require('common/util/cleanUpResponse');

var buildExternalUserDisplay = require('../../common/util/buildExternalUserDisplay');

module.exports = function(data) {
  var deferred = $.Deferred();

  $.soap({
    body: {
      GetEntityExternalUsersByAccessLevel: {
        $xmlns: 'http://dobs.pa.gov/dp/entity/bpm/1.0',
        EntityID: data.entityId,
        AccessLevelID: data.accessLevel
      }
    },
    blueprintArrays: ['GetEntityExternalUsersByAccessLevelResponse.Users.DP_EntityExternalUser'],
    blueprint: {
      users: [
        '$..DP_EntityExternalUser',
        {
          externalUserId: '$.ExternalUserID', // int example: 2003
          firstName: '$.FirstName',
          lastName: '$.LastName',
          userId: '$.UserID', // string example: 'jdoe'
          accessLevel: '$.AccessLevel'
        }
      ]
    }
  })
    .done(function(response) {
      cleanUpResponse(response);
      _.each(response.users, function(user) {
        user.userDisplay = buildExternalUserDisplay(user.userId, user.firstName, user.lastName);
      });
      deferred.resolve(response.users);
    })
    .fail(function(err) {
      deferred.reject();
    });

  return deferred.promise();
};
