/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Backbone = require('backbone');
var Marionette = require('backbone.marionette');
var Ladda = require('ladda');
var Syphon = require('backbone.syphon');
var Radio = require('backbone.radio');

var momentUtil = require('common/util/momentUtil');
var BreadcrumbView = require('common/views/BreadcrumbView');

var SubmitEntityRiskMatrix = require('../services/SubmitEntityRiskMatrix');
var DepRiskMatrixFormView = require('./DepRiskMatrixFormView');

var tmplDepRiskMatrixNewView = require('../templates/DepRiskMatrixNewView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplDepRiskMatrixNewView,

  className: 'dep-new-risk-matrix-form-view container-fluid',

  ui: {
    breadcrumb: '.breadcrumb-container',
    riskMatrixFormContainer: '.new-risk-matrix-form-container',
    submit: '.btn-submit'
  },

  regions: {
    breadcrumb: '@ui.breadcrumb',
    riskMatrixFormContainer: '@ui.riskMatrixFormContainer'
  },

  events: {
    'click @ui.submit': 'onClickSubmit'
  },

  onBeforeShow: function() {
    var entityIdentifiers = this.model.get('entityIdentifiers') || {};
    var entityId = entityIdentifiers.entityId;
    var entityName = entityIdentifiers.entityName;

    this.showChildView(
      'breadcrumb',
      new BreadcrumbView({
        breadcrumbs: [
          {
            link: '#dep/dashboard',
            text: 'Home'
          },
          {
            link: '#dep/entity/' + entityId + '/dashboard',
            text: entityName
          },
          {
            text: 'New Risk Matrix'
          }
        ]
      })
    );

    this.riskMatrixForm = new DepRiskMatrixFormView({
      model: new Backbone.Model({
        trackingLookups: this.model.get('trackingLookups') || {}
      })
    });

    this.showChildView('riskMatrixFormContainer', this.riskMatrixForm);
  },

  onClickSubmit: function(e) {
    e.preventDefault();

    var entityIdentifiers = this.model.get('entityIdentifiers');

    var laddaContext = Ladda.create(e.currentTarget);
    this.riskMatrixForm.validate().done(
      function() {
        laddaContext.start();

        var formData = Syphon.serialize(this);
        formData.entityId = entityIdentifiers.entityId;

        formData.asOfDate = momentUtil.formatStr(formData.asOfDate, momentUtil.defaultOutboundDateConfig);
        formData.examStartDate = momentUtil.formatStr(formData.examStartDate, momentUtil.defaultOutboundDateConfig);
        formData.dateEntered = momentUtil.formatStr(formData.dateEntered, momentUtil.defaultOutboundDateConfig);

        SubmitEntityRiskMatrix(formData)
          .done(function(riskMatrixId) {
            Radio.channel('navigate').trigger('show:int:dp:entity', entityIdentifiers.entityId);
          })
          .fail(function(err) {
            laddaContext.stop();
          });
      }.bind(this)
    );
  }
});
