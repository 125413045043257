/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var NProgress = require('nprogress');

var NavUtils = require('psa-core/util/nav');

var Scaffold = require('common/layout/Scaffold');
var GetUsersForRole = require('common/services/GetUsersForRole');

var GetEntityIdentifiers = require('../../common/services/GetEntityIdentifiers');
var GetEntityExam = require('../services/GetEntityExam');
var GetAllExamsLookups = require('../services/GetAllExamsLookups');
var DepExamMainEditView = require('../views/DepExamMainEditView');

module.exports = function(entityId, examId) {
  NProgress.start();
  NProgress.set(0.6);

  $.when(GetEntityIdentifiers(entityId), GetEntityExam(examId))
    .done(function(entityIdentifiers, exam) {
      var examinerRoles = {
        '1': 'DP - EIC - Bank',
        '2': 'DP - EIC - Credit Union',
        '3': 'DP - EIC - Trust'
      };
      $.when(
        GetAllExamsLookups(entityIdentifiers.categoryId, entityIdentifiers.entityTypeId),
        GetUsersForRole(examinerRoles[entityIdentifiers.categoryId] || '')
      )
        .done(function(examLookups, examiners) {
          NavUtils.navigate('#dep/entity/' + entityId + '/exam/' + examId + '/edit-main');

          examLookups.examiners = examiners;

          Scaffold.content.show(
            new DepExamMainEditView({
              model: new Backbone.Model({
                entityId: entityId,
                entityName: entityIdentifiers.entityName,
                entityTypeId: entityIdentifiers.entityTypeId,
                entityCategoryId: entityIdentifiers.categoryId,
                lookups: examLookups,
                exam: exam
              })
            })
          );
          NProgress.done();
        })
        .fail(function(err) {
          NProgress.done();
        });
    })
    .fail(function(err) {
      NProgress.done();
    });
};
