/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var cleanUpResponse = require('../util/cleanUpResponse');
var _ = require('underscore');
var Config = require('../../config/Config');

module.exports = function() {
  var defer = $.Deferred();

  $.soap({
    body: {
      GetInternalUserDetails: {
        $xmlns: 'http://dobs.pa.gov/cm/user/bpm/1.0'
      }
    },
    blueprintArrays: [
      'GetInternalUserDetailsResponse.UserDetails.organization',
      'GetInternalUserDetailsResponse.UserDetails.organization.role'
    ],
    blueprint: {
      authUserDN: '$..UserDetails.authuserdn',
      description: '$..UserDetails.description',
      internalUserId: '$..InternalUserDetails.ID',
      username: '$..InternalUserDetails.Username',
      firstName: '$..InternalUserDetails.FirstName',
      lastName: '$..InternalUserDetails.LastName',
      email: '$..InternalUserDetails.Email',
      title: '$..InternalUserDetails.Title',
      initials: '$..InternalUserDetails.Initials',
      phoneNumber: '$..InternalUserDetails.PhoneNumber',
      timeNDHasTimesheet: '$..InternalUserDetails.TimeNDHasTimesheet',
      timeNDIsReviewer: '$..InternalUserDetails.TimeNDIsReviewer',
      timeNDSupervisorUserID: '$..InternalUserDetails.TimeNDSupervisorUserID',
      timeDPHasTimesheet: '$..InternalUserDetails.TimeDPHasTimesheet',
      timeSCHasTimesheet: '$..InternalUserDetails.TimeSCHasTimesheet',
      organizations: [
        '$..organization',
        {
          dn: '$.dn',
          description: '$.description',
          orgUserDN: '$.organizationaluser.dn',
          roles: [
            '$.role',
            {
              roledn: '$.$id'
            }
          ]
        }
      ]
    }
  })
    .done(function(response) {
      cleanUpResponse(response, { cleanUpTextKeys: true });

      response.timeNDHasTimesheet = response.timeNDHasTimesheet === '1';
      response.timeNDIsReviewer = response.timeNDIsReviewer === '1';
      response.timeDPHasTimesheet = response.timeDPHasTimesheet === '1';
      response.timeSCHasTimesheet = response.timeSCHasTimesheet === '1';

      var defaultOrg = _.find(response.organizations, function(org) {
        return org.dn.substring(2, org.dn.indexOf(',')) === $.serverConfig.getOrganizationName();
      });

      response.organizations = _.reduce(
        response.organizations,
        function(orgs, org) {
          var orgName = org.dn.substring(2, org.dn.indexOf(','));
          orgs[orgName] = {
            dn: org.dn,
            description: org.description,
            orgUserDN: org.orgUserDN
          };
          return orgs;
        },
        {}
      );
      response.orgUserDN = response.organizations[Config.getOrganizationName()].orgUserDN;

      response.userName = response.authUserDN.substring(3, response.authUserDN.indexOf(','));

      response.userMgmtRoles = [];
      _.each(defaultOrg.roles, function(role) {
        var roletype = role.roledn.split(',')[1].substring(3);
        var rolecontxt = roletype === 'organizational roles' ? 'organization' : 'application';
        var rolepackage =
          roletype === 'organizational roles'
            ? role.roledn.split(',')[2].substring(2)
            : role.roledn.split(',')[1].substring(3);
        var roleobj = {};
        roleobj[rolecontxt] = rolepackage;
        roleobj.role = role.roledn.split(',')[0].substring(3);
        response.userMgmtRoles.push(roleobj);
      });

      defer.resolve(response);
    })
    .fail(function(err) {
      defer.reject();
    });

  return defer.promise();
};
