var Handlebars = require("handlebars/dist/handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.escapeExpression, alias2=depth0 != null ? depth0 : (container.nullContext || {}), alias3=helpers.helperMissing;

  return "\n<div class=\"page-title clearfix\">\n  <h1 class=\"pull-left m-n p-n\">"
    + alias1(container.lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.task : depth0)) != null ? stack1.taskType : stack1)) != null ? stack1.description : stack1), depth0))
    + " for "
    + alias1((helpers["default"] || (depth0 && depth0["default"]) || alias3).call(alias2,((stack1 = (depth0 != null ? depth0.correspondence : depth0)) != null ? stack1.entityName : stack1),((stack1 = (depth0 != null ? depth0.correspondence : depth0)) != null ? stack1.complainantEntityName : stack1),{"name":"default","hash":{},"data":data}))
    + "</h1>\n</div>\n<div class=\"header-bar-container\"></div>\n<div class=\"breadcrumb-container\"></div>\n<div class=\"form-horizontal\">\n  <form class=\"correspondence-form\">\n    <div class=\"form-group\">\n      <label class=\"control-label col-sm-3\">DoBS Contact Name</label>\n      <div class=\"col-sm-9 col-md-6\">\n        <p class=\"form-control-static \">"
    + alias1((helpers["default"] || (depth0 && depth0["default"]) || alias3).call(alias2,((stack1 = (depth0 != null ? depth0.correspondence : depth0)) != null ? stack1.requestedByName : stack1),"--",{"name":"default","hash":{},"data":data}))
    + "</p>\n      </div>\n    </div>\n    <div class=\"form-group\">\n      <label class=\"control-label col-sm-3\">Contact Date</label>\n      <div class=\"col-sm-9 col-md-6\">\n        <p class=\"form-control-static \">"
    + alias1((helpers["default"] || (depth0 && depth0["default"]) || alias3).call(alias2,((stack1 = (depth0 != null ? depth0.correspondence : depth0)) != null ? stack1.requestedOn : stack1),"--",{"name":"default","hash":{},"data":data}))
    + "</p>\n      </div>\n    </div>\n    <div class=\"row\">\n      <div class=\"col-sm-9 col-sm-offset-3\">\n        <h3>Correspondence</h3>\n      </div>\n    </div>\n    <div class=\"form-group\">\n      <label class=\"control-label col-sm-3\">Message From DoBS</label>\n      <div class=\"col-sm-9 col-md-6\">\n        <p class=\"form-control-static\">"
    + alias1((helpers["default"] || (depth0 && depth0["default"]) || alias3).call(alias2,((stack1 = (depth0 != null ? depth0.correspondence : depth0)) != null ? stack1.requestComments : stack1),"--",{"name":"default","hash":{},"data":data}))
    + "</p>\n      </div>\n    </div>\n    <div class=\"form-group\">\n      <label class=\"control-label col-sm-3\">Reply Comments</label>\n      <div class=\"col-sm-9 col-md-6\">\n        <textarea type=\"text\" class=\"form-control \" name=\"replyComments\" placeholder=\"Comments\" rows=\"5\"></textarea>\n      </div>\n    </div>\n    <div class=\"row\">\n      <div class=\"col-sm-9 col-sm-offset-3\">\n        <h3>Documents</h3>\n      </div>\n    </div>\n    <div class=\"form-group\">\n      <label class=\"control-label col-sm-3 \">Document List</label>\n      <div class=\" col-sm-9 col-md-6\">\n        <div class=\"int-file-upload-table-container\"></div>\n      </div>\n    </div>\n    <div class=\"form-group\">\n      <label class=\"control-label col-sm-3 \">Attach file(s) for Correspondence</label>\n      <div class=\" col-sm-9 col-md-6\">\n        <div class=\"ext-file-upload-table-container\"></div>\n      </div>\n    </div>\n  </form>\n  <div class=\"form-group\">\n    <div class=\"col-sm-9 col-lg-7 col-sm-offset-3 m-b-lg\">\n      <button type=\"button\" class=\"btn btn-default cancel\">Cancel</button>\n      <button type=\"button\" class=\"btn btn-primary submit\">Submit</button>\n    </div>\n  </div>\n</div>";
},"useData":true});