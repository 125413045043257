/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Backbone = require('backbone');
var Marionette = require('backbone.marionette');
var Syphon = require('backbone.syphon');
var bodybuilder = require('bodybuilder');
var Ladda = require('ladda');

var GridView = require('psa-marionette/views/GridView');
var PageableCollection = require('psa-backbone/models/PageableCollection');

var ButtonLinkCell = require('common/backgrid/ButtonLinkCell');
var ElasticSearchSummaryView = require('common/views/ElasticSearchSummaryView');

var SecInternalSidebarNavView = require('./SecInternalSidebarNavView');
var SearchSecApplications = require('../services/SearchSecApplications');

require('../styles/SecApplicationSearchView.css');
var tmplSecApplicationSearchView = require('../templates/SecApplicationSearchView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplSecApplicationSearchView,

  className: 'sec-application-search-view container-fluid',

  ui: {
    sideBar: '.side-bar-container',
    searchResultsContainer: '.search-results-container',
    search: '.search',
    clearFilters: '.clear-filters',
    formInputs: ':input',
    searchSummary: '.search-summary'
  },

  regions: {
    sideBar: '@ui.sideBar',
    searchResultsContainer: '@ui.searchResultsContainer',
    searchSummary: '@ui.searchSummary'
  },

  events: {
    'click @ui.search': 'onClickSearch',
    'click @ui.clearFilters': 'onClickClearFilters',
    'keydown @ui.formInputs': 'onKeydownOfInputs'
  },

  onBeforeShow: function() {
    this.showChildView('sideBar', new SecInternalSidebarNavView());

    this.searchSummaryView = new ElasticSearchSummaryView({
      model: new Backbone.Model({
        searchLimit: this.model.get('searchLimit')
      })
    });
    this.showChildView('searchSummary', this.searchSummaryView);

    this.searchResults = new GridView({
      columns: [
        {
          name: 'dbaName',
          label: 'Organization D/B/A Name',
          cell: 'string',
          headerCell: 'custom',
          width: 20,
          editable: false
        },
        {
          name: 'applicantLegalName',
          label: 'Applicant Legal Name',
          cell: 'string',
          headerCell: 'custom',
          width: 20,
          editable: false
        },
        {
          name: 'crdNumber',
          label: 'CRD Number',
          cell: 'string',
          headerCell: 'custom',
          width: 11,
          editable: false
        },
        {
          name: 'applicationType',
          label: 'Application Type',
          cell: 'string',
          headerCell: 'custom',
          width: 11,
          editable: false
        },
        {
          name: 'licenseType',
          label: 'License Type',
          cell: 'string',
          headerCell: 'custom',
          width: 11,
          editable: false
        },
        {
          name: 'workflowStatus',
          label: 'Workflow Status',
          cell: 'string',
          headerCell: 'custom',
          width: 11,
          editable: false
        },
        {
          name: 'filingExaminerUsername',
          label: 'Primary Examiner',
          cell: 'string',
          headerCell: 'custom',
          width: 11,
          editable: false
        },
        {
          name: '',
          label: '',
          cell: ButtonLinkCell.extend({
            name: 'Open',
            newTab: true,
            buttonClass: 'btn-primary btn-sm',
            href: function(model) {
              var organizationId = model.get('organizationId');
              return '#sec/organization/' + organizationId + '/dashboard';
            }
          }),
          headerCell: 'custom',
          sortable: false,
          editable: false,
          width: 10
        }
      ],
      collection: new PageableCollection([], { pageSize: 25 }),
      emptyText: 'No Applications Found'
    });

    this.showChildView('searchResultsContainer', this.searchResults);

    this.$('.backgrid.table').addClass('table-bordered');
  },

  onKeydownOfInputs: function(e) {
    // Searches on press of enter
    if (e.keyCode === 13) {
      this.onClickSearch(e);
    }
  },

  onClickSearch: function() {
    var self = this;
    var data = Syphon.serialize(this, {
      inputReaders: Syphon.SearchInputReaderSet
    });
    // If no inputs were given, do not perform a search.
    if (
      !data.dbaName &&
      !data.applicantLegalName &&
      !data.crdNumber &&
      !data.nonFINRANumber &&
      !data.licenseTypeId &&
      !data.applicationTypeId &&
      !data.workflowStatusId &&
      !data.filingExaminerUserName
    ) {
      return;
    }
    this.searchSummaryView.reset();

    var l = Ladda.create(this.ui.search[0]);
    l.start();
    var body = bodybuilder();

    if (data.dbaName) {
      body.query('bool', function(b) {
        b.orQuery('match', 'dbaName', {
          query: data.dbaName,
          fuzziness: 'AUTO',
          analyzer: 'standard'
        });
        b.orQuery('wildcard', 'dbaName', data.dbaName.toLowerCase() + '*');
        b.orQuery('match', 'dbaName.phonetic', data.dbaName);
        return b;
      });
    }

    if (data.applicantLegalName) {
      body.query('bool', function(b) {
        b.orQuery('match', 'applicantLegalName', {
          query: data.applicantLegalName,
          fuzziness: 'AUTO',
          analyzer: 'standard'
        });
        b.orQuery('wildcard', 'applicantLegalName', data.applicantLegalName.toLowerCase() + '*');
        b.orQuery('match', 'applicantLegalName.phonetic', data.applicantLegalName);
        return b;
      });
    }

    if (data.crdNumber) {
      body.query('wildcard', 'crdNumber', data.crdNumber + '*');
    }

    if (data.nonFINRANumber) {
      body.query('wildcard', 'nonFINRANumber', data.nonFINRANumber + '*');
    }

    if (data.licenseTypeId) {
      body.query('match', 'licenseTypeId', data.licenseTypeId);
    }

    if (data.applicationTypeId) {
      body.query('match', 'applicationTypeId', data.applicationTypeId);
    }

    if (data.workflowStatusId) {
      body.query('match', 'workflowStatusId', data.workflowStatusId);
    }

    if (data.filingExaminerUserName) {
      body.query('match', 'filingExaminerUserId', data.filingExaminerUserName);
    }

    body.size(this.model.get('searchLimit'));
    var bodyObj = body.build();
    // Keep for testing
    // console.log(JSON.stringify(bodyObj, null, 2));
    SearchSecApplications(bodyObj)
      .done(function(data) {
        l.stop();
        self.searchResults.collection.fullCollection.reset(data.hits);
        self.searchSummaryView.display(data);
      })
      .fail(function(err) {
        l.stop();
      });
  },

  onClickClearFilters: function() {
    this.ui.formInputs.val('');
  }
});
