/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var moment = require('moment');
var momentUtil = require('common/util/momentUtil');

var cleanUpResponse = require('common/util/cleanUpResponse');

module.exports = function(licenseId) {
  var deferred = $.Deferred();
  $.soap({
    body: {
      GetLicenseDashboard: {
        $xmlns: 'http://dobs.pa.gov/nd/license/bpm/1.0',
        LicenseID: licenseId
      }
    },
    blueprintArrays: [
      'GetLicenseDashboardResponse.LicenseContactTypes.ND_LU_LicenseContactType',
      'GetLicenseDashboardResponse.LicenseContacts.ND_LicenseContact',
      'GetLicenseDashboardResponse.LicenseBond.ND_LicenseBond',
      'GetLicenseDashboardResponse.LicenseStatusHistory.ND_LicenseStatusHistory',
      'GetLicenseDashboardResponse.CreditCounselors.ND_DebtManagementCreditCounselor',
      'GetLicenseDashboardResponse.CreditCounselorStatuses.ND_LU_CreditCounselorStatus',
      'GetLicenseDashboardResponse.LicenseCertificateHistory.ND_LicenseCertificateHistory',
      'GetLicenseDashboardResponse.MortgageOriginatorSponsors.ND_License',
      'GetLicenseDashboardResponse.MortgageOriginatorSponsorships.ND_License'
    ],
    blueprint: {
      id: '$..ID',
      CSFolderId: '$..CSFolderID',
      entityName: '$..EntityName',
      entityCSFolderId: '$..EntityCSFolderID',
      licenseLocationID: '$..LocationID',
      licenseNumber: '$..LicenseNumber',
      nmlsId: '$..NMLSID',
      licenseType: '$..LicenseType',
      licenseTypeId: '$..LicenseTypeID',
      licenseStatus: '$..LicenseStatus',
      licenseStatusId: '$..LicenseStatusID',
      certificateDocument: {
        fileId: '$..CertificateDocumentID',
        fileName: '$..CertificateDocumentName'
      },
      effectiveStartDate: '$..EffectiveStartDate',
      effectiveEndDate: '$..EffectiveEndDate',
      nmlsEntityId: '$..NMLSEntityID',
      nmlsTransitionDate: '$..NMLSTransitionDate',
      originalLicenseNumber: '$..OriginalLicenseNumber',
      sapAccount: '$..SAPAccount',
      nextExamDate: '$..NextExamDate',
      hasInProgressExam: '$..HasInProgressExam',
      nonDExamRegion: '$..NonDExamRegion',
      nonDExamRegionId: '$..NonDExamRegionID',
      isBranch: '$..IsBranch',
      isDOBSManaged: '$..IsDOBSManaged',
      isDOBSAnnualReport: '$..IsDOBSAnnualReport',
      isRetired: '$..IsRetired',
      originalIssueDate: '$..OriginalIssueDate',
      licenseAddress: {
        addressLine1: '$..LicenseAddressLine1',
        addressLine2: '$..LicenseAddressLine2',
        addressLine3: '$..LicenseAddressLine3',
        city: '$..LicenseCity',
        state: '$..LicenseState',
        postalCode: '$..LicensePostalCode',
        country: '$..LicenseCountry'
      },
      officeManager: {
        id: '$..OfficeManagerID',
        firstName: '$..OfficeManagerFirstName',
        lastName: '$..OfficeManagerLastName',
        email: '$..OfficeManagerEmail',
        homePhoneNumber: '$..OfficeManagerHomePhoneNumber',
        cellPhoneNumber: '$..OfficeManagerCellPhoneNumber',
        address: {
          addressLine1: '$..OfficeManagerAddressLine1',
          addressLine2: '$..OfficeManagerAddressLine2',
          addressLine3: '$..OfficeManagerAddressLine3',
          city: '$..OfficeManagerCity',
          state: '$..OfficeManagerState',
          postalCode: '$..OfficeManagerPostalCode',
          country: '$..OfficeManagerCountry'
        }
      },
      licenseContactTypes: [
        '$..ND_LU_LicenseContactType',
        {
          id: '$..ID',
          name: '$..Name'
        }
      ],
      licenseContacts: [
        '$..ND_LicenseContact',
        {
          id: '$..ID',
          typeId: '$..LicenseContactTypeID',
          type: '$..LicenseContactType',
          firstName: '$..FirstName',
          lastName: '$..LastName',
          email: '$..Email',
          homePhoneNumber: '$..HomePhoneNumber',
          cellPhoneNumber: '$..CellPhoneNumber',
          workPhoneNumber: '$..WorkPhoneNumber',
          workPhoneExtension: '$..WorkPhoneExtension',
          fax: '$..Fax',
          company: '$..Company',
          title: '$..Title',
          address: {
            addressLine1: '$..AddressLine1',
            addressLine2: '$..AddressLine2',
            addressLine3: '$..AddressLine3',
            city: '$..City',
            state: '$..State',
            postalCode: '$..PostalCode',
            country: '$..Country'
          }
        }
      ],
      bonds: [
        '$..ND_LicenseBond',
        {
          bondId: '$..BondID',
          entityId: '$..EntityID',
          licenseID: '$..LicenseID',
          BondCompanyID: '$..BondCompanyID',
          bondName: '$..Name',
          bondNumber: '$..BondNumber',
          bondAmount: '$..BondAmount',
          expiration: '$..Expiration',
          requiredTo: '$..RequiredTo',
          licenseRequirement: '$..LicenseRequirement',
          type: '$..Type',
          status: '$..Status',
          typeID: '$..TypeID',
          statusID: '$..StatusID',
          bondTypeOther: '$..BondTypeOther'
        }
      ],
      licenseStatusHistory: [
        '$..ND_LicenseStatusHistory',
        {
          id: '$..ID',
          status: '$..Status',
          effectiveDate: '$..EffectiveDate'
        }
      ],
      creditCounselors: [
        '$..ND_DebtManagementCreditCounselor',
        {
          id: '$..ID',
          licenseId: '$..LicenseID',
          firstName: '$..FirstName',
          lastName: '$..LastName',
          hireDate: '$..EmployeeHireDate',
          certifyingOrganization: '$..CertifyingOrganization',
          certificateNumber: '$..CertificateNumber',
          expirationDate: '$..CertificateExpirationDate',
          additionalInformation: '$..AdditionalInformation',
          status: '$..Status',
          statusId: '$..StatusID',
          title: '$..EmployeeTitle',
          addressLine1: '$..AddressLine1',
          addressLine2: '$..AddressLine2',
          addressLine3: '$..AddressLine3',
          city: '$..City',
          state: '$..State',
          postalCode: '$..PostalCode',
          country: '$..Country'
        }
      ],
      creditCounselorStatuses: [
        '$..ND_LU_CreditCounselorStatus',
        {
          id: '$..ID',
          name: '$..Status'
        }
      ],
      licenseCertificateHistory: [
        '$..ND_LicenseCertificateHistory',
        {
          id: '$..ID',
          licenseId: '$..LicenseID',
          effectiveStartDate: '$..EffectiveStartDate',
          effectiveEndDate: '$..EffectiveEndDate',
          certificateDocumentId: '$..CertificateDocumentID',
          documentId: '$..DocumentID',
          certificateDocumentName: '$..CertificateDocumentName'
        }
      ],
      mortgageOriginatorSponsors: [
        '$..MortgageOriginatorSponsors.ND_License',
        {
          licenseId: '$..LicenseID',
          entityId: '$..EntityID',
          entityName: '$..EntityName',
          licenseNumber: '$..LicenseNumber',
          licenseType: '$..LicenseType',
          acceptedDate: '$..AcceptedDate',
          deficientDate: '$..DeficientDate',
          rejectedDate: '$..RejectedDate',
          removedDate: '$..RemovedDate',
          requestedDate: '$..RequestedDate'
        }
      ],
      mortgageOriginatorSponsorships: [
        '$..MortgageOriginatorSponsorships.ND_License',
        {
          licenseId: '$..LicenseID',
          entityId: '$..EntityID',
          entityName: '$..EntityName',
          licenseNumber: '$..LicenseNumber',
          nmlsId: '$..NMLSID',
          status: '$..SponsorshipStatus',
          statusDate: '$..SponsorshipDate'
        }
      ]
    }
  })
    .done(function(response) {
      cleanUpResponse(response);

      var inboundDateConfig = _.extend({}, momentUtil.defaultInboundDateConfig, { default: '--' });

      response.originalIssueDate = momentUtil.formatStr(response.originalIssueDate, inboundDateConfig);
      response.effectiveStartDate = momentUtil.formatStr(response.effectiveStartDate, inboundDateConfig);
      response.effectiveEndDate = momentUtil.formatStr(response.effectiveEndDate, momentUtil.defaultInboundDateConfig);
      response.nextExamDate = momentUtil.formatStr(
        response.nextExamDate,
        _.extend({}, momentUtil.defaultInboundDateConfig, { default: 'Not Scheduled' })
      );
      response.nmlsTransitionDate = momentUtil.formatStr(
        response.nmlsTransitionDate,
        momentUtil.defaultInboundDateConfig
      );
      response.originalLicenseNumber = response.originalLicenseNumber || '--';
      response.nmlsEntityId = response.nmlsEntityId || '--';
      response.sapAccount = response.sapAccount || '--';

      if (response.officeManager.id === null) {
        delete response.officeManager;
      } else {
        response.officeManager.homePhoneNumber = response.officeManager.homePhoneNumber || '--';
        response.officeManager.cellPhoneNumber = response.officeManager.cellPhoneNumber || '--';
        response.officeManager.email = response.officeManager.email || '--';
      }

      _.each(response.bonds, function(bond) {
        if (bond.expiration) {
          bond.expiration = moment(bond.expiration, 'YYYY-MM-DD');
        }
        if (bond.requiredTo) {
          bond.requiredTo = moment(bond.requiredTo, 'YYYY-MM-DD');
        }
      });

      response.isBranch = response.isBranch === '1';
      response.isDOBSManagedLicenseType = response.isDOBSManaged === '1';
      response.isDOBSAnnualReport = response.isDOBSAnnualReport === '1';
      response.isRetiredLicenseType = response.isRetired === '1';
      response.isNMLSLicense = !!response.nmlsId;

      _.each(response.licenseContacts, function(contact) {
        if (contact.workPhoneNumber && contact.workPhoneExtension) {
          contact.workPhoneNumberDisplay = contact.workPhoneNumber + ' (Ext: ' + contact.workPhoneExtension + ')';
        }
      });

      var licenseContacts = response.licenseContacts;

      response.licenseContacts = [];

      _.each(response.licenseContactTypes, function(type) {
        var contactType = type;
        contactType.contacts = _.where(licenseContacts, { typeId: type.id });
        response.licenseContacts.push(contactType);
      });

      response.licenseContacts = _.sortBy(response.licenseContacts, function(contactType) {
        return contactType.name;
      });

      _.each(response.licenseStatusHistory, function(status) {
        status.effectiveDate = moment(status.effectiveDate, moment.ISO_8601);
      });

      delete response.licenseContactTypes;

      _.each(response.creditCounselors, function(counselor) {
        counselor.expirationDate = momentUtil.formatStr(counselor.expirationDate, momentUtil.defaultInboundDateConfig);

        if (counselor.hireDate) {
          counselor.hireDate = moment(counselor.hireDate, 'YYYY-MM-DD');
        }
      });

      _.each(response.licenseCertificateHistory, function(certificate) {
        if (certificate.effectiveStartDate) {
          certificate.effectiveStartDate = moment(certificate.effectiveStartDate, 'YYYY-MM-DD');
        }
        if (certificate.effectiveEndDate) {
          certificate.effectiveEndDate = moment(certificate.effectiveEndDate, 'YYYY-MM-DD');
        }
        certificate.file = {
          fileId: certificate.documentId,
          fileName: certificate.certificateDocumentName
        };
      });

      _.each(response.mortgageOriginatorSponsors, function(sponsor) {
        if (sponsor.acceptedDate) {
          sponsor.acceptedDate = moment(sponsor.acceptedDate, 'YYYY-MM-DD');
        }
        if (sponsor.deficientDate) {
          sponsor.deficientDate = moment(sponsor.deficientDate, 'YYYY-MM-DD');
        }
        if (sponsor.rejectedDate) {
          sponsor.rejectedDate = moment(sponsor.rejectedDate, 'YYYY-MM-DD');
        }
        if (sponsor.removedDate) {
          sponsor.removedDate = moment(sponsor.removedDate, 'YYYY-MM-DD');
        }
        if (sponsor.requestedDate) {
          sponsor.requestedDate = moment(sponsor.requestedDate, 'YYYY-MM-DD');
        }
      });

      _.each(response.mortgageOriginatorSponsorships, function(sponsorship) {
        if (sponsorship.statusDate) {
          sponsorship.statusDate = moment(sponsorship.statusDate, 'YYYY-MM-DD');
        }
      });
      deferred.resolve(response);
    })
    .fail(function(err) {
      deferred.reject();
    });

  return deferred.promise();
};
