/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2020] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var Marionette = require('backbone.marionette');
var Syphon = require('backbone.syphon');
var Ladda = require('ladda');

var GridView = require('psa-marionette/views/GridView');
var PageableCollection = require('psa-backbone/models/PageableCollection');

var SelectOneCell = require('common/backgrid/SelectOneCell');
var AddressCell = require('common/backgrid/AddressCell');
var isPromise = require('common/util/isPromise');

var GetIndividualByName = require('../services/GetIndividualByName');

var tmplSecIndividualSearchModalView = require('../templates/SecIndividualSearchModalView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplSecIndividualSearchModalView,

  className: 'sec-individual-search-modal-view',

  ui: {
    searchResultsGrid: '.search-result-grid-container',
    search: '.search-individual',
    searchResultSummary: '.search-result-summary',
    searchResultMax: '.search-result-max',
    hitCount: '.hit-count',
    searchTime: '.search-time',
    refineSeachTip: '.refine-seach-tip',
    formInputs: ':input',
    submit: '.btn-submit'
  },

  regions: {
    searchResultsGrid: '@ui.searchResultsGrid'
  },

  events: {
    'click @ui.search': 'onClickSearch',
    'click @ui.submit': 'onClickSubmit',
    'keydown @ui.formInputs': 'onKeydownOfInputs'
  },

  templateHelpers: function() {
    return {
      searchLimit: this.searchLimit
    };
  },

  onBeforeShow: function() {
    var gridColumns = [
      {
        name: 'selected',
        label: '',
        cell: SelectOneCell.SelectOneRowCell,
        headerCell: SelectOneCell.SelectOneHeaderCell,
        width: 10,
        editable: true,
        sortable: false
      },
      {
        name: 'firstName',
        label: 'First Name',
        cell: 'string',
        headerCell: 'custom',
        width: 25,
        editable: false
      },
      {
        name: 'lastName',
        label: 'Last Name',
        cell: 'string',
        headerCell: 'custom',
        width: 25,
        editable: false
      },
      {
        name: 'address',
        label: 'Address',
        cell: AddressCell,
        headerCell: 'custom',
        width: 40,
        editable: false,
        sortable: false
      }
    ];

    this.individualSearchResults = new GridView({
      columns: gridColumns,
      collection: new PageableCollection([], { pageSize: 10 }),
      emptyText: 'No individuals found.'
    });

    this.showChildView('searchResultsGrid', this.individualSearchResults);
  },

  onKeydownOfInputs: function(e) {
    // Searches on press of enter
    if (e.keyCode === 13) {
      this.onClickSearch(e);
    }
  },

  onClickSearch: function(e) {
    e.preventDefault();
    var self = this;
    this.ui.searchResultSummary.hide();
    this.ui.searchResultMax.hide();

    var data = Syphon.serialize(this, {
      inputReaders: Syphon.SearchInputReaderSet
    });
    if (!data.firstName && !data.lastName) {
      return;
    }

    var l = Ladda.create(this.ui.search[0]);
    l.start();

    GetIndividualByName(data.firstName, data.lastName)
      .done(function(data) {
        l.stop();
        self.individualSearchResults.collection.fullCollection.reset(data.individuals);
        self.ui.hitCount.text(data.totalHits);
        self.ui.searchResultSummary.show();
        if (data.totalHits === 50) {
          self.ui.searchResultMax.show();
        }
      })
      .fail(function(err) {
        l.stop();
      });
  },

  onClickSubmit: function(e) {
    e.preventDefault();
    var selectedModel = this.individualSearchResults.viewGrid.getSelectedModel();
    if (!selectedModel) {
      return;
    }
    var individual = selectedModel.toJSON();

    if (_.isFunction(this.options.onSubmit)) {
      var result = this.options.onSubmit(individual);
      if (isPromise(result)) {
        var spinner = Ladda.create(e.currentTarget);
        spinner.start();
        result.always(
          function() {
            this.triggerMethod('modal:close', individual);
          }.bind(this)
        );
      } else {
        this.triggerMethod('modal:close', individual);
      }
    } else {
      this.triggerMethod('modal:close', individual);
    }
  }
});
