var Handlebars = require("handlebars/dist/handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "  <div class=\"row\">\n    <div class=\"col-sm-12\">\n      <button class=\"btn btn-primary submit ladda-button\" data-style=\"expand-right\" type=\"button\">\n"
    + ((stack1 = (helpers.ifCond || (depth0 && depth0.ifCond) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.mode : depth0),"===","ext-new",{"name":"ifCond","hash":{},"fn":container.program(2, data, 0),"inverse":container.program(7, data, 0),"data":data})) != null ? stack1 : "")
    + "      </button>\n    </div>\n  </div>\n";
},"2":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = (helpers.ifCond || (depth0 && depth0.ifCond) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? depth0.filingDetails : depth0)) != null ? stack1.filingFee : stack1),">",0,{"name":"ifCond","hash":{},"fn":container.program(3, data, 0),"inverse":container.program(5, data, 0),"data":data})) != null ? stack1 : "");
},"3":function(container,depth0,helpers,partials,data) {
    return "            <span class=\"ladda-label\">Finalize Filing and Proceed to Cart</span>\n";
},"5":function(container,depth0,helpers,partials,data) {
    return "              <span class=\"ladda-label\">Finalize Filing and Submit</span>\n";
},"7":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = (helpers.ifCond || (depth0 && depth0.ifCond) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.mode : depth0),"===","int-new",{"name":"ifCond","hash":{},"fn":container.program(8, data, 0),"inverse":container.program(10, data, 0),"data":data})) != null ? stack1 : "");
},"8":function(container,depth0,helpers,partials,data) {
    return "        <span class=\"ladda-label\">Submit Filing</span>\n";
},"10":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = (helpers.ifCond || (depth0 && depth0.ifCond) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.mode : depth0),"===","resubmit",{"name":"ifCond","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"11":function(container,depth0,helpers,partials,data) {
    return "        <span class=\"ladda-label\">Finalize Filing and Submit</span>\n        ";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "\n<form class=\"certification-form\">\n  <div class=\"form-group required\">\n    <label class=\"control-label font-weight-normal certify-label\">\n      <input type=\"checkbox\" class=\"certify-checkbox\" name=\"isCertified\" value=\"1\" data-parsley-required=\"true\" />\n      By checking this box, I hereby certify that the information contained herein and contained in any attachments or\n      exhibits hereto are complete, true and correct. I make this declaration subject to the penalties of 18 PA.C.S S\n      4904 relating to unsworn falsification to authorities.\n    </label>\n  </div>\n  <div class=\"form-group required\">\n    <label><strong>Enter Name Below</strong></label>\n    <input type=\"text\" class=\"form-control\" name=\"certificationName\" data-parsley-required=\"true\"\n      data-parsley-maxlength=\"100\" /> </div>\n"
    + ((stack1 = (helpers.ifCond || (depth0 && depth0.ifCond) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.mode : depth0),"!=","print",{"name":"ifCond","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "</form>";
},"useData":true});