/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var moment = require('moment');
var NProgress = require('nprogress');

var GetExamPeriodByDate = require('../services/GetExamPeriodByDate');
var ShowNonDepInternalCurrentExams = require('./ShowNonDepInternalCurrentExams');

// This is a special command that first makes a call to get the current exam
// period ID, and then simply calls another command, and provides this
// information, very similar to an HTTP redirect.
module.exports = function(examYearId) {
  NProgress.start();
  NProgress.set(0.3);

  var dateStr = moment().format('YYYY-MM-DD');

  GetExamPeriodByDate(dateStr)
    .done(function(examPeriod) {
      var examPeriodId = examPeriod.examPeriodId;
      ShowNonDepInternalCurrentExams(examPeriodId);
    })
    .fail(function() {
      NProgress.done();
    });
};
