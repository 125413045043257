var Handlebars = require("handlebars/dist/handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "      <option value=\""
    + alias4(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\">"
    + alias4(((helper = (helper = helpers.type || (depth0 != null ? depth0.type : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"type","hash":{},"data":data}) : helper)))
    + "</option>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "            <option value=\""
    + alias4(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\">"
    + alias4(((helper = (helper = helpers.bank || (depth0 != null ? depth0.bank : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"bank","hash":{},"data":data}) : helper)))
    + "</option>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "\n<form class=\"submission-form\">\n  <div class=\"form-group required\">\n    <label class=\"control-label\">Proposed Name</label>\n    <input type=\"text\" name=\"proposedName\" class=\"form-control\" placeholder=\"Proposed Name\" data-parsley-maxlength=\"200\"\n      data-parsley-required=\"true\" />\n  </div>\n  <div class=\"form-group required\">\n    <label class=\"control-label\">Charter Type</label>\n    <select name=\"bankCharterTypeId\" class=\"form-control charter-type\" data-parsley-required=\"true\">\n      <option value=\"\">--</option>\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.bankCharterTypes : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "    </select>\n  </div>\n  <div class=\"form-group required\">\n    <label class=\"control-label\">Federal Reserve Bank Member </label>\n    <label class=\"radio-inline\">\n      <input type=\"radio\" name=\"isFedReserveMember\" value=\"1\"\n        data-parsley-errors-container=\".federal-reserve-bank-member\" data-parsley-required=\"true\"> Yes\n    </label>\n    <label class=\"radio-inline\">\n      <input type=\"radio\" name=\"isFedReserveMember\" value=\"0\"\n        data-parsley-errors-container=\".federal-reserve-bank-member\" data-parsley-required=\"true\"> No\n    </label>\n    <div class=\"federal-reserve-bank-member\"></div>\n  </div>\n  <div class=\"federal-reserve-details\">\n    <div class=\"row\">\n      <div class=\"col-sm-6\">\n        <div class=\"form-group required\">\n          <label class=\"control-label\">Applicable Federal Reserve Bank</label>\n          <select name=\"fedReserveBankId\" class=\"form-control\" data-parsley-required=\"true\">\n            <option value=\"\">--</option>\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.federalReserveBanks : depth0),{"name":"each","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "          </select>\n        </div>\n      </div>\n      <div class=\"col-sm-6\">\n        <div class=\"form-group required\">\n          <label class=\"control-label\">Date Filed with Federal Reserve Bank for Membership</label>\n          <div class=\"input-group\">\n            <input type=\"text\" name=\"fedReserveFilingDate\" class=\"form-control date-picker filed-date\"\n              placeholder=\"Date Filed with Federal Reserve Bank for Membership\" data-parsley-required=\"true\"\n              data-parsley-date=\"\" data-parsley-trigger-after-failure=\"changeDate\"\n              data-parsley-errors-container=\"#fedReserveFilingDateErrorsContainer\" />\n            <div class=\"input-group-addon\">\n              <i class=\"fa fa-calendar\"></i>\n            </div>\n          </div>\n          <div id=\"fedReserveFilingDateErrorsContainer\"></div>\n        </div>\n      </div>\n    </div>\n  </div>\n  <div class=\"form-group required\">\n    <label class=\"control-label\">Bank Holding Company Structure </label>\n    <label class=\"radio-inline\">\n      <input type=\"radio\" name=\"isBankHolding\" value=\"1\"\n        data-parsley-errors-container=\".bank-holding-company-structure-error\" data-parsley-required=\"true\"> Yes\n    </label>\n    <label class=\"radio-inline\">\n      <input type=\"radio\" name=\"isBankHolding\" value=\"0\"\n        data-parsley-errors-container=\".bank-holding-company-structure-error\" data-parsley-required=\"true\"> No\n    </label>\n    <div class=\"bank-holding-company-structure-error\"></div>\n  </div>\n  <div class=\"holding-company-details\">\n    <div class=\"row\">\n      <div class=\"col-sm-6\">\n        <div class=\"form-group required\">\n          <label class=\"control-label\">Applicable Federal Reserve Bank</label>\n          <select name=\"bankHoldingBankId\" class=\"form-control\" data-parsley-required=\"true\">\n            <option value=\"\">--</option>\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.federalReserveBanks : depth0),{"name":"each","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "          </select>\n        </div>\n      </div>\n      <div class=\"col-sm-6\">\n        <div class=\"form-group required\">\n          <label class=\"control-label\">Date Filed with Federal Reserve Bank for Holding Company\n            Approval</label>\n          <div class=\"input-group\">\n            <input type=\"text\" name=\"bankHoldingFilingDate\" class=\"form-control date-picker filed-date\"\n              placeholder=\"Date Filed with Federal Reserve Bank for Holding Company Approval\"\n              data-parsley-required=\"true\" data-parsley-date=\"\" data-parsley-trigger-after-failure=\"changeDate\"\n              data-parsley-errors-container=\"#bankHoldingFilingDateErrorsContainer\" />\n            <div class=\"input-group-addon\">\n              <i class=\"fa fa-calendar\"></i>\n            </div>\n          </div>\n          <div id=\"bankHoldingFilingDateErrorsContainer\"></div>\n        </div>\n      </div>\n    </div>\n  </div>\n</form>";
},"useData":true});