/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var NProgress = require('nprogress');
var NavUtils = require('psa-core/util/nav');
var Scaffold = require('common/layout/Scaffold');

var GetTrustFundAudit = require('../services/GetTrustFundAudit');
var GetAllFundAuditLookups = require('../services/GetAllFundAuditLookups');

var DepAuditEditView = require('../views/DepAuditEditView');

module.exports = function(entityId, fundId, auditId) {
  NProgress.start();
  NProgress.set(0.6);

  $.when(GetTrustFundAudit(auditId), GetAllFundAuditLookups())
    .done(function(audit, auditLookups) {
      NavUtils.navigate('#dep/entity/' + entityId + '/fund/' + fundId + '/audit/' + auditId + '/edit');
      Scaffold.content.show(
        new DepAuditEditView({
          viewMode: 'int',
          model: new Backbone.Model({
            entityId: entityId,
            fundId: fundId,
            auditId: auditId,
            auditLookups: auditLookups,
            audit: audit
          })
        })
      );
      NProgress.done();
    })
    .fail(function(err) {
      NProgress.done();
    });
};
