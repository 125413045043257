/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var Marionette = require('backbone.marionette');
var Backgrid = require('backgrid');
var Handlebars = require('handlebars');

var TextInputCellEditor = Marionette.ItemView.extend({
  tagName: 'input',

  className: 'text-input-cell-editor form-control',

  template: Handlebars.compile(''),

  events: {
    keydown: 'saveOrCancel',
    change: 'saveOrCancel'
  },

  attributes: function() {
    var attributes = {};
    if (this.options.placeholder) {
      attributes.placeholder = this.options.placeholder;
    }
    return attributes;
  },

  /**
     Initializer. Removes this `el` from the DOM when a `done` event is
     triggered.
     @param {Object} options
     @param {Backgrid.CellFormatter} options.formatter
     @param {Backgrid.Column} options.column
     @param {Backbone.Model} options.model
     @param {string} [options.placeholder]
  */
  initialize: function(options) {
    this.formatter = options.formatter;
    this.column = options.column;
    if (!(this.column instanceof Backgrid.Column)) {
      this.column = new Backgrid.Column(this.column);
    }
  },

  /**
     Renders a text input with the cell value formatted for display, if it
     exists.
  */
  onRender: function() {
    var model = this.model;
    var formattedValue = this.formatter.fromRaw(model.get(this.column.get('name')), model);
    this.$el.val(formattedValue);
  },

  /**
     If the key pressed is `enter`, `tab`, `up`, or `down`, converts the value
     in the editor to a raw value for saving into the model using the formatter.
     If the key pressed is `esc` the changes are undone.
     If the editor goes out of focus (`blur`) but the value is invalid, the
     event is intercepted and cancelled so the cell remains in focus pending for
     further action. The changes are saved otherwise.
     Triggers a Backbone `backgrid:edited` event from the model when successful,
     and `backgrid:error` if the value cannot be converted. Classes listening to
     the `error` event, usually the Cell classes, should respond appropriately,
     usually by rendering some kind of error feedback.
     @param {Event} e
  */
  saveOrCancel: function(e) {
    var formatter = this.formatter;
    var model = this.model;
    var column = this.column;

    var command = new Backgrid.Command(e);
    var changed = e.type === 'change';

    if (
      command.moveUp() ||
      command.moveDown() ||
      command.moveLeft() ||
      command.moveRight() ||
      command.save() ||
      changed
    ) {
      e.preventDefault();
      e.stopPropagation();

      var value = this.getValue();
      var newValue = formatter.toRaw(value, model);
      if (_.isUndefined(newValue)) {
        model.trigger('backgrid:error', model, column, value);
      } else {
        model.set(column.get('name'), newValue);
        model.trigger('backgrid:edited', model, column, command);
      }
    }
    // esc
    else if (command.cancel()) {
      // undo
      e.stopPropagation();
      model.trigger('backgrid:edited', model, column, command);
    }
  },

  getValue: function() {
    return this.$el.val();
  }
});

module.exports = TextInputCellEditor;
