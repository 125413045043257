/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

module.exports = function(location) {
  var deferred = $.Deferred();
  $.soap({
    body: {
      SubmitNewEntityLocation: {
        $xmlns: 'http://dobs.pa.gov/nd/entity/bpm/1.0',
        LocationID: location.locationId,
        IsPrimaryPlaceOfBusiness: location.isPrimaryPlaceOfBusiness === 'true' ? '1' : '0',
        AddressLine1: location.addressLine1,
        AddressLine2: location.addressLine2,
        AddressLine3: location.addressLine3,
        City: location.city,
        State: location.state,
        PostalCode: location.postalCode,
        Country: location.country,
        County: location.county,
        OfficePhoneNumber: location.officePhoneNumber,
        OfficeFaxNumber: location.officeFaxNumber,
        EntityID: location.entityId
      }
    },
    blueprint: {
      locationId: '$..ID'
    }
  })
    .done(function(response) {
      deferred.resolve(response.locationId);
    })
    .fail(function(err) {
      deferred.reject();
    });

  return deferred.promise();
};
