/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2020] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Marionette = require('backbone.marionette');
var Syphon = require('backbone.syphon');
var parsleyUtil = require('common/util/parsleyUtil');
var DepSection112ApplicantsCollectionView = require('./DepSection112ApplicantsCollectionView');
var _get = require('lodash.get');

var tmplDepSection112ApplicantSectionView = require('./DepSection112ApplicantSectionView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplDepSection112ApplicantSectionView,

  className: 'dep-section112-applicant-section-view',

  ui: {
    applicantForm: '.applicant-form',
    applicantsContainer: '.applicants-container',
    addApplicant: '.add-applicant',
    applicantCount: '[name="applicantCount"]',
    applicantCountSection: '.applicant-count-section',
    typeOfApplicantSelection: '[name="applicantTypeId"]'
  },

  regions: {
    applicants: '@ui.applicantsContainer'
  },

  events: {
    'click @ui.addApplicant': 'onClickAddApplicant',
    'change @ui.applicantForm': 'onChangeApplicantType'
  },

  childEvents: {
    'remove:applicant': 'onChildClickRemoveApplicant'
  },

  onBeforeShow: function() {
    var filingContent = this.model.get('filingContent') || {};
    var applicants = filingContent.applicants || [];
    filingContent.applicantCount = applicants.length;
    Syphon.deserialize(this, filingContent);

    this.applicants = new DepSection112ApplicantsCollectionView({
      collection: new Backbone.Collection(applicants)
    });
    this.showChildView('applicants', this.applicants);

    if (!applicants.length) {
      this.applicants.collection.push({});
    }
    this.ui.applicantCount.val(parseInt(_get(this.applicants, 'collection.length')));
    this.onChangeApplicantType();
  },

  onClickAddApplicant: function(e) {
    e.preventDefault();
    this.applicants.collection.push({});
    this.ui.applicantCount.val(parseInt(_get(this.applicants, 'collection.length')));
  },

  onChildClickRemoveApplicant: function() {
    this.ui.applicantCount.val(parseInt(_get(this.applicants, 'collection.length')));
  },

  onChangeApplicantType: function() {
    var singleIndividualSelection = this.ui.typeOfApplicantSelection.val() === '1';
    if (singleIndividualSelection) {
      this.applicants.collection.set(this.applicants.collection.models[0]);
      this.ui.addApplicant.hide();
      this.ui.applicantCountSection.hide();
      this.ui.applicantCount.val(1);
    } else {
      this.ui.addApplicant.show();
      this.ui.applicantCountSection.show();
    }
  },

  validate: function(config) {
    this.ui.applicantForm.parsley().reset();
    if (config.type === 'submit') {
      // Turn on required field validation for this form
      parsleyUtil.enableRequiredValidation(this.ui.applicantForm);
    } else {
      // Turn off required field validation for this form
      parsleyUtil.disableRequiredValidation(this.ui.applicantForm);
    }

    return this.ui.applicantForm.parsley().whenValidate();
  },

  getFormData: function() {
    var formData = Syphon.serialize(this);
    formData.applicants = this.applicants.getFormData();
    return formData;
  }
});
