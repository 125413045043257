/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var _get = require('lodash.get');
var Backbone = require('backbone');
var NProgress = require('nprogress');

var NavUtils = require('psa-core/util/nav');

var Scaffold = require('common/layout/Scaffold');
var GetEntityIdentifiers = require('../../common/services/GetEntityIdentifiers');

var GetLicenseContactTypes = require('../../new-license/services/GetLicenseContactTypes');
var GetLicenseContactTypesByLicenseType = require('../../new-license/services/GetLicenseContactTypesByLicenseType');
var GetLicenseIdentifiers = require('../../license-dashboard/services/GetLicenseIdentifiers');
var GetLicenseDashboard = require('../../license-dashboard/services/GetLicenseDashboard');
var GetLicenseContactsAndOfficersByEntity = require('../../common/services/GetLicenseContactsAndOfficersByEntity');

var LicenseContactsChangeFormView = require('../views/LicenseContactsChangeFormView');

module.exports = function(entityId, licenseId) {
  NProgress.start();
  NProgress.set(0.6);

  $.when(
    GetLicenseContactTypes(),
    GetLicenseDashboard(licenseId),
    GetLicenseIdentifiers(licenseId),
    GetEntityIdentifiers(entityId),
    GetLicenseContactsAndOfficersByEntity(entityId)
  )
    .done(function(licenseContactTypes, licenseDashboard, licenseIdentifiers, entityIdentifiers, existingContacts) {
      var licenseTypeId = _get(licenseDashboard, 'licenseTypeId');
      GetLicenseContactTypesByLicenseType(licenseTypeId)
        .done(function(requiredLicenseContactTypes) {
          var licenseContacts = [];
          NavUtils.navigate('ext/nondep/entity/' + entityId + '/license/' + licenseId + '/contacts-change');
          _.each(licenseDashboard.licenseContacts, function(contacts) {
            _.each(contacts.contacts, function(contact) {
              var contactAddress = contact.address;
              delete contactAddress.id;
              licenseContacts.push(_.extend(contact, contactAddress, { licenseContactTypeId: contact.typeId }));
            });
          });
          Scaffold.content.show(
            new LicenseContactsChangeFormView({
              viewMode: 'ext',
              model: new Backbone.Model({
                entityId: entityId,
                licenseId: licenseId,
                licenseIdentifiers: licenseIdentifiers,
                entityIdentifiers: entityIdentifiers,
                licenseContacts: licenseContacts,
                licenseContactTypes: licenseContactTypes,
                existingContacts: existingContacts,
                requiredLicenseContactTypes: requiredLicenseContactTypes
              })
            })
          );
          NProgress.done();
        })
        .fail(function(err) {
          NProgress.done();
        });
    })
    .fail(function(err) {
      NProgress.done();
    });
};
