/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var cleanUpResponse = require('../util/cleanUpResponse');
var toHash = require('../util/toHash');

module.exports = function() {
  var deferred = $.Deferred();

  $.soap({
    body: {
      GetConfigurations: {
        $xmlns: 'http://dobs.pa.gov/cm/lookup/bpm/1.0'
      }
    },
    blueprintArrays: ['GetConfigurationsResponse.CM_LU_Configuration'],
    blueprint: {
      configs: [
        '$..CM_LU_Configuration',
        {
          configKey: '$..ConfigKey',
          configValue: '$..ConfigValue'
        }
      ]
    }
  })
    .done(function(response) {
      cleanUpResponse(response);
      var config = toHash(response.configs, 'configKey', 'configValue');
      deferred.resolve(config);
    })

    .fail(function(err) {
      deferred.reject(err);
    });

  return deferred.promise();
};
