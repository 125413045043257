/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Marionette = require('backbone.marionette');
var Syphon = require('backbone.syphon');

var parsleyUtil = require('common/util/parsleyUtil');
var makeUploadCollection = require('common/util/makeUploadCollection');

var CommentsDocumentView = require('common/views/CommentsDocumentView');

var tmplDepBankAcquisitionMergerReqDocsSectionView = require('./DepBankAcquisitionMergerReqDocsSectionView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplDepBankAcquisitionMergerReqDocsSectionView,

  className: 'dep-bank-acquisition-merger-req-docs-section-view',

  behaviors: {
    ReadOnlyFormBehavior: {}
  },

  ui: {
    reqDocsForm: '.req-docs-form',
    articlesOfMerger: '.articles-of-merger',
    planOfMerger: '.plan-of-merger',
    meetingNotice: '.meeting-notice',
    branchDocumentation: '.branch-documentation',
    subsidiaryAffiliates: '.subsidiary-affiliates'
  },

  regions: {
    articlesOfMerger: '@ui.articlesOfMerger',
    planOfMerger: '@ui.planOfMerger',
    meetingNotice: '@ui.meetingNotice',
    branchDocumentation: '@ui.branchDocumentation',
    subsidiaryAffiliates: '@ui.subsidiaryAffiliates'
  },

  templateHelpers: function() {
    return {
      isReadOnly: this.options.isReadOnly
    };
  },

  onBeforeShow: function() {
    var filingContent = this.model.get('filingContent') || {};
    var articlesOfMerger = filingContent.articlesOfMerger || {};
    var planOfMerger = filingContent.planOfMerger || {};
    var meetingNotice = filingContent.meetingNotice || {};
    var branchDocumentation = filingContent.branchDocumentation || {};
    var subsidiaryAffiliates = filingContent.subsidiaryAffiliates || {};

    Syphon.deserialize(this, filingContent);

    this.articlesOfMerger = new CommentsDocumentView({
      isReadOnly: this.options.isReadOnly,
      allowMultipleFiles: false,
      isRequired: true,
      fileUploadLabel: 'At least one document or comment must be provided.',
      commentsName: 'articlesOfMergerComments',
      collection: makeUploadCollection(articlesOfMerger.file),
      model: new Backbone.Model({ articlesOfMergerComments: articlesOfMerger.comments }),
      documentTypeId: 20001
    });
    this.showChildView('articlesOfMerger', this.articlesOfMerger);

    this.planOfMerger = new CommentsDocumentView({
      isReadOnly: this.options.isReadOnly,
      allowMultipleFiles: false,
      isRequired: true,
      fileUploadLabel: 'At least one document or comment must be provided.', 
      commentsName: 'planOfMergerComments',
      collection: makeUploadCollection(planOfMerger.file),
      model: new Backbone.Model({ planOfMergerComments: planOfMerger.comments }),
      documentTypeId: 20001
    });
    this.showChildView('planOfMerger', this.planOfMerger);

    this.meetingNotice = new CommentsDocumentView({
      isReadOnly: this.options.isReadOnly,
      allowMultipleFiles: false,
      isRequired: true,
      fileUploadLabel: 'At least one document or comment must be provided.',  
      commentsName: 'meetingNoticeComments',
      collection: makeUploadCollection(meetingNotice.file),
      model: new Backbone.Model({ meetingNoticeComments: meetingNotice.comments }),
      documentTypeId: 20001
    });
    this.showChildView('meetingNotice', this.meetingNotice);

    this.branchDocumentation = new CommentsDocumentView({
      isReadOnly: this.options.isReadOnly,
      allowMultipleFiles: false,
      isRequired: true,
      fileUploadLabel: 'At least one document or comment must be provided.',   
      commentsName: 'branchDocumentationComments',
      collection: makeUploadCollection(branchDocumentation.file),
      model: new Backbone.Model({ branchDocumentationComments: branchDocumentation.comments }),
      documentTypeId: 20001
    });
    this.showChildView('branchDocumentation', this.branchDocumentation);

    this.subsidiaryAffiliates = new CommentsDocumentView({
      isReadOnly: this.options.isReadOnly,
      allowMultipleFiles: false,
      isRequired: true,
      fileUploadLabel: 'At least one document or comment must be provided.',   
      commentsName: 'subsidiaryAffiliatesComments',
      collection: makeUploadCollection(subsidiaryAffiliates.file),
      model: new Backbone.Model({ subsidiaryAffiliatesComments: subsidiaryAffiliates.comments }),
      documentTypeId: 20001
    });
    this.showChildView('subsidiaryAffiliates', this.subsidiaryAffiliates);
  },

  validate: function(config) {
    this.ui.reqDocsForm.parsley().reset();
    this.articlesOfMerger.removeValidationErrors();
    this.planOfMerger.removeValidationErrors();
    this.meetingNotice.removeValidationErrors();
    this.branchDocumentation.removeValidationErrors();
    this.subsidiaryAffiliates.removeValidationErrors();

    if (config.type === 'save') {
      // Turn off required field validation for this form
      parsleyUtil.disableRequiredValidation(this.ui.reqDocsForm);
    } else {
      var validationPromises = [
        this.ui.reqDocsForm.parsley().whenValidate(),
        this.articlesOfMerger.validate(),
        this.planOfMerger.validate(),
        this.meetingNotice.validate(),
        this.branchDocumentation.validate(),
        this.subsidiaryAffiliates.validate()
      ];
      // Turn on required field validation for this form
      parsleyUtil.enableRequiredValidation(this.ui.reqDocsForm);
    }
    return $.when.apply($, validationPromises);
  },

  getFormData: function() {
    var formData = Syphon.serialize(this);
    formData.articlesOfMergerDocId = this.articlesOfMerger.getFirstFileId();
    formData.planOfMergerDocId = this.planOfMerger.getFirstFileId();
    formData.meetingNoticeDocId = this.meetingNotice.getFirstFileId();
    formData.branchDocumentationDocId = this.branchDocumentation.getFirstFileId();
    formData.subsidiaryAffiliatesDocId = this.subsidiaryAffiliates.getFirstFileId();
    return formData;
  }
});
