var Handlebars = require("handlebars/dist/handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.escapeExpression, alias2=container.lambda;

  return "\n<div class=\"page-title clearfix\">\n  <h1 class=\"pull-left m-n p-n\">"
    + alias1(((helper = (helper = helpers.entityName || (depth0 != null ? depth0.entityName : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"entityName","hash":{},"data":data}) : helper)))
    + "</h1>\n  <div class=\"page-title-buttons-container\">\n    <div class=\"cs-link-container\"></div>\n    <div class=\"dropdown-container\"></div>\n  </div>\n</div>\n<div class=\"page-subtitle\">\n  <h2>Exam</h2>\n  <div class=\"page-subtitle-details\">"
    + alias1(alias2(((stack1 = (depth0 != null ? depth0.exam : depth0)) != null ? stack1.examType : stack1), depth0))
    + " - "
    + alias1(alias2(((stack1 = (depth0 != null ? depth0.exam : depth0)) != null ? stack1.leadAgency : stack1), depth0))
    + "</div>\n</div>\n<div class=\"breadcrumb-container\"></div>\n<div class=\"exam-wrapper\"></div>";
},"useData":true});