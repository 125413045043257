/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Marionette = require('backbone.marionette');
var Radio = require('backbone.radio');
var Ladda = require('ladda');
var moment = require('moment');

var ButtonLinkCell = require('common/backgrid/ButtonLinkCell');
var MomentCell = require('common/backgrid/MomentCell');
var MultiButtonCell = require('common/backgrid/MultiButtonCell');
var GridSpinnerView = require('common/views/GridSpinnerView');
var ClaimTask = require('common/services/ClaimTask');
var userHasRole = require('common/util/userHasRole');

var CorpFinInternalSidebarNavView = require('../../common/views/CorpFinInternalSidebarNavView');

require('../styles/CorpFinInternalDashboardView.css');
var tmplCorpFinInternalDashboardView = require('../templates/CorpFinInternalDashboardView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplCorpFinInternalDashboardView,

  className: 'corp-fin-internal-dashboard-view container-fluid',

  ui: {
    sideBar: '.side-bar-container',
    assignedTasks: '.assigned-tasks-container',
    availableTasks: '.available-tasks-container'
  },

  regions: {
    sideBar: '@ui.sideBar',
    assignedTasks: '@ui.assignedTasks',
    availableTasks: '@ui.availableTasks'
  },

  onBeforeShow: function() {
    this.showChildView('sideBar', new CorpFinInternalSidebarNavView());

    var userTasksGridColumns = [
      {
        name: 'type',
        label: 'Task Name',
        cell: 'string',
        headerCell: 'custom',
        width: 15,
        editable: false
      },
      {
        name: 'appplicationType',
        label: 'Type',
        cell: 'string',
        headerCell: 'custom',
        width: 15,
        editable: false
      },
      {
        name: 'filingType',
        label: 'Filing Type',
        cell: 'string',
        headerCell: 'custom',
        width: 35,
        editable: false
      },
      {
        name: 'assignedClaimedDate',
        label: 'Assigned/Claimed On',
        cell: MomentCell.MomentCell,
        formatter: MomentCell.MomentDateTimeFormatter.extend({
          emptyValue: '--'
        }),
        sortValue: MomentCell.MomentSortValue,
        headerCell: 'custom',
        width: 25,
        editable: false
      },

      {
        name: '',
        label: '',
        cell: ButtonLinkCell.extend({
          name: 'Open',
          buttonClass: 'btn-primary btn-sm',
          href: function(model) {
            return '#corpfin/task/' + model.get('taskId');
          }
        }),
        headerCell: 'custom',
        width: 10,
        editable: false,
        sortable: false
      }
    ];

    this.assignedTasksView = new GridSpinnerView({
      columns: userTasksGridColumns,
      spinner: {
        scale: 0.75,
        length: 10
      },
      pageable: true
    });

    this.showChildView('assignedTasks', this.assignedTasksView);

    Radio.channel('cf:internal-dashboard')
      .request('build:corp-fin-assigned-tasks-grid-view', userTasksGridColumns)
      .done(
        function(gridView) {
          if (!this.isDestroyed) {
            this.assignedTasksView = gridView;
            this.showChildView('assignedTasks', gridView);
            gridView.viewGrid.sort('assignedClaimedDate', 'descending');
          }
        }.bind(this)
      );

    var availableTasksGridColumns = [
      {
        name: 'type',
        label: 'Task Name',
        cell: 'string',
        headerCell: 'custom',
        width: 15,
        editable: false
      },
      {
        name: 'appplicationType',
        label: 'Type',
        cell: 'string',
        headerCell: 'custom',
        width: 15,
        editable: false
      },
      {
        name: 'filingType',
        label: 'Filing Type',
        cell: 'string',
        headerCell: 'custom',
        width: 30,
        editable: false
      },
      {
        name: 'deliveryDate',
        label: 'Assigned On',
        cell: MomentCell.MomentCell,
        formatter: MomentCell.MomentDateTimeFormatter.extend({
          emptyValue: '--'
        }),
        sortValue: MomentCell.MomentSortValue,
        headerCell: 'custom',
        width: 15,
        editable: false
      },
      {
        name: 'assignedRole',
        label: 'Assigned Role',
        cell: 'string',
        headerCell: 'custom',
        width: 15,
        editable: false
      },
      {
        name: 'claim',
        label: '',
        cell: MultiButtonCell.extend({
          buttons: [
            {
              name: 'Start',
              buttonClass: 'btn-primary btn-sm ladda-button',
              labelClass: 'ladda-label',
              buttonAttrs: {
                'data-style': 'zoom-in',
                'data-color': '#EEEEEE',
                'data-spinner-color': '#EEEEEE'
              },
              renderable: function() {
                var isReadOnly = userHasRole('CF - Read Only');
                return !isReadOnly;
              },
              onClick: this.onClickClaimAvailableTask.bind(this)
            }
          ]
        }),
        headerCell: 'custom',
        width: 10,
        editable: false,
        sortable: false
      }
    ];

    this.availableTasksView = new GridSpinnerView({
      columns: availableTasksGridColumns,
      spinner: {
        scale: 0.75,
        length: 10
      },
      pageable: true
    });

    this.showChildView('availableTasks', this.availableTasksView);

    Radio.channel('cf:internal-dashboard')
      .request('build:corp-fin-available-tasks-grid-view', availableTasksGridColumns)
      .done(
        function(gridView) {
          if (!this.isDestroyed) {
            this.availableTasksView = gridView;
            this.showChildView('availableTasks', gridView);
          }
        }.bind(this)
      );
  },

  onClickClaimAvailableTask: function(e, model) {
    e.preventDefault();

    var laddaContext = Ladda.create(e.currentTarget);
    laddaContext.start();
    var taskId = model.get('taskId');

    ClaimTask(taskId)
      .done(
        function() {
          this.viewContext.availableTasksView.viewGrid.removeRow(model);
          // Set the initial value of the assignedClaimedDate to be now.
          model.set('assignedClaimedDate', moment());
          var assignedTasksGrid = this.viewContext.assignedTasksView.viewGrid;
          assignedTasksGrid.collection.add(model);
          assignedTasksGrid.sort('assignedClaimedDate', 'descending');
        }.bind({
          event: e,
          viewContext: this
        })
      )
      .fail(function(err) {
        laddaContext.stop();
      });
  }
});
