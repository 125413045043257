var Handlebars = require("handlebars/dist/handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression;

  return "\n<div class=\"page-title clearfix\">\n  <h1 class=\"pull-left m-n p-n\">"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? depth0.task : depth0)) != null ? stack1.taskType : stack1)) != null ? stack1.description : stack1), depth0))
    + "</h1>\n</div>\n<div class=\"breadcrumb-container\"></div>\n<div class=\"task-status\"></div>\n<div class=\"col-sm-8 col-sm-offset-2 generic-submission-review-container\">\n  <h2 class=\"m-t-n\">Filing Submission</h2>\n  <div class=\"generic-submission-form-container\"></div>\n  <h2>Submitter</h2>\n  <div class=\"row\">\n    <div class=\"col-sm-6 col-md-4\">\n      <label >Name:</label>\n       "
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.filing : depth0)) != null ? stack1.firstName : stack1), depth0))
    + " "
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.filing : depth0)) != null ? stack1.lastName : stack1), depth0))
    + " \n    </div>\n  </div>\n  <div class=\"row\">\n    <div class=\"col-sm-6 col-md-4\">\n      <label >Email:</label>\n      "
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.filing : depth0)) != null ? stack1.email : stack1), depth0))
    + "\n    </div>\n  </div>\n  <h2>Work</h2>\n  <form class=\"review-form\">\n    <div class=\"form-group is-send-notification-form-group required\">\n      <label class=\"control-label\">Send Notification?</label>\n      <div class=\"row\">\n        <div class=\"col-sm-6 col-md-4\">\n          <select class=\"form-control\" name=\"isSendNotification\" data-parsley-required=\"true\">\n            <option value=\"\">--</option>\n            <option value=\"1\">Yes</option>\n            <option value=\"0\">No</option>\n          </select>\n        </div>\n      </div>\n    </div>\n    <div class=\"form-group collapse internal-documents-form-group\">\n      <label class=\"control-label\">Notification Attatchment(s)</label>\n      <div class=\"internal-documents-container\" />\n    </div>\n    <div class=\"form-group\">\n      <a href=\"#corpfin/dashboard\" class=\"btn btn-default\" role=\"button\">Cancel</a>\n      <button type=\"button\" class=\"btn btn-primary submit\">Submit</button>\n      <div class=\"task-actions\"></div>\n    </div>\n</div>";
},"useData":true});