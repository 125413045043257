/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Backbone = require('backbone');
var NProgress = require('nprogress');

var NavUtils = require('psa-core/util/nav');

var Scaffold = require('common/layout/Scaffold');
var GetEntityIdentifiers = require('../../common/services/GetEntityIdentifiers');
var GetEntityDocMgmtDetails = require('../../common/services/GetEntityDocMgmtDetails');

var GetEntityAudit = require('../services/GetEntityAudit');
var DepAuditDashboardView = require('../views/DepAuditDashboardView');

module.exports = function(entityId, auditId) {
  NProgress.start();
  NProgress.set(0.6);
  $.when(GetEntityAudit(auditId), GetEntityIdentifiers(entityId), GetEntityDocMgmtDetails(entityId))
    .done(function(audit, entityIdentifiers, docMgmt) {
      NavUtils.navigate('dep/entity/' + entityId + '/audit/' + auditId + '/dashboard');
      Scaffold.content.show(
        new DepAuditDashboardView({
          viewMode: 'int',
          model: new Backbone.Model({
            entityId: entityId,
            entityIdentifiers: entityIdentifiers,
            auditId: auditId,
            audit: audit,
            auditsCSFolderId: docMgmt.auditsCSFolderId
          })
        })
      );
      NProgress.done();
    })
    .fail(function(err) {
      NProgress.done();
    });
};
