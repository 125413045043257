/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Syphon = require('backbone.syphon');
var Marionette = require('backbone.marionette');
var Ladda = require('ladda');

var SubmitUpdateApplicationReportingStatus = require('../services/SubmitUpdateApplicationReportingStatus');

var tmplNonDepUpdateApplicationStatusModalView = require('../templates/NonDepUpdateApplicationStatusModalView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplNonDepUpdateApplicationStatusModalView,

  className: 'non-dep-update-application-status-modal-view',

  ui: {
    applicationStatusForm: '.application-status-form',
    submit: '.btn-submit'
  },

  events: {
    'click @ui.submit': 'onClickSubmit'
  },

  onBeforeShow: function() {
    Syphon.deserialize(this, this.model.toJSON());
  },

  onClickSubmit: function(e) {
    e.preventDefault();
    var self = this;
    var applicationId = self.model.get('applicationId');
    this.ui.applicationStatusForm
      .parsley()
      .whenValidate()
      .done(function() {
        var formData = Syphon.serialize(self);
        formData.applicationId = applicationId;
        var laddaContext = Ladda.create(self.ui.submit.get(0));
        laddaContext.start();
        SubmitUpdateApplicationReportingStatus(formData).done(function() {
          self.triggerMethod('modal:close');
        });
      });
  }
});
