/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Marionette = require('backbone.marionette');

var UIUtil = require('psa-core/util/ui');
var PageableCollection = require('psa-backbone/models/PageableCollection');

var PanelGridView = require('common/views/PanelGridView');
var MomentPanelCellView = require('common/views/PanelGridView/MomentPanelCellView');
var ButtonsPanelCellView = require('common/views/PanelGridView/ButtonsPanelCellView');

var OrganizationJoinCodeUseModalView = require('./OrganizationJoinCodeUseModalView');

require('../styles/SecExtDashboardView.css');
var tmplSecExtDashboardView = require('../templates/SecExtDashboardView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplSecExtDashboardView,

  className: 'sec-ext-dashboard-view container-fluid',

  ui: {
    pendingTaskWrapper: '.pending-task-wrapper',
    myOrganizationsWrapper: '.my-organizations-wrapper',
    joinEntityButton: '.join-entity-button'
  },

  regions: {
    pendingTasksWrapper: '@ui.pendingTaskWrapper',
    myOrganizationsWrapper: '@ui.myOrganizationsWrapper'
  },

  events: {
    'click @ui.joinEntityButton': 'onClickJoinEntity'
  },

  onBeforeShow: function() {
    var pendingTasksView = new PanelGridView({
      columns: [
        {
          name: 'type',
          label: 'Task Name',
          width: 30,
          overflow: 'break-normal'
        },
        {
          name: 'applicantLegalName',
          label: 'Applicant Legal Name',
          width: 30,
          overflow: 'truncate'
        },
        {
          name: 'deliveryDate',
          label: 'Assigned On',
          panelCell: MomentPanelCellView,
          width: 30
        },
        {
          name: '',
          label: '',
          width: 10,
          panelCell: ButtonsPanelCellView.extend({
            buttons: [
              {
                buttonText: 'Open',
                buttonClass: 'btn-primary btn-md',
                href: function(model) {
                  return '#ext/task/' + model.get('taskId');
                }
              }
            ]
          })
        }
      ],
      collection: new PageableCollection(this.model.get('tasks'), { pageSize: 5 }),
      emptyText: 'No Pending Tasks'
    });

    var myOrganizationsView = new PanelGridView({
      columns: [
        {
          name: 'applicantLegalName',
          label: 'Applicant Legal Name',
          width: 30
        },
        {
          name: 'CRDNumber',
          label: 'CRD Number',
          width: 70
        }
      ],
      collection: new PageableCollection(this.model.get('organizations'), { pageSize: 5 }),
      emptyText: 'No Organizations Created'
    });

    this.showChildView('pendingTasksWrapper', pendingTasksView);
    this.showChildView('myOrganizationsWrapper', myOrganizationsView);
  },

  onClickJoinEntity: function(e) {
    e.preventDefault();

    UIUtil.showModal({
      title: 'Join an Organization',
      model: this.model,
      view: new OrganizationJoinCodeUseModalView()
    });
  }
});
