/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var moment = require('moment');

var cleanUpResponse = require('common/util/cleanUpResponse');

module.exports = function(data) {
  var defer = $.Deferred();

  $.soap({
    body: {
      SubmitProvision: {
        $xmlns: 'http://dobs.pa.gov/dp/enforcement/bpm/1.0',
        ProvisionID: data.provisionId,
        EnforcementID: data.enforcementId,
        ResponseDueDate: data.responseDueDate ? moment(data.responseDueDate, 'MM-DD-YYYY').format('YYYY-MM-DD') : null,
        ProvisionNumber: data.provisionNumber,
        CategoryID: data.categoryId,
        IsOngoing: data.isOngoing,
        StatusID: data.statusId,
        Comments: data.comments,
        Provisions: data.provisions,
        IsDeleted: data.isDeleted
      }
    },
    blueprint: {
      newProvisionId: '$..NewProvisionID'
    }
  })
    .done(function(response) {
      cleanUpResponse(response, { cleanUpTextKeys: true });
      defer.resolve(response.newProvisionId);
    })
    .fail(function(err) {
      defer.reject(err);
    });

  return defer.promise();
};
