/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */
var Syphon = require('backbone.syphon');

var Marionette = require('backbone.marionette');
var Ladda = require('ladda');
var Radio = require('backbone.radio');
var Session = require('psa-core/Session');

var parsleyUtil = require('common/util/parsleyUtil');
var SubmitInvestorComplaint = require('../services/SubmitInvestorComplaint')


require('../styles/ConSerComplaintFormConsumerServicesResolutionView.css');

var tmplConSerComplaintFormConsumerServicesResolutionView = require('../templates/ConSerComplaintFormConsumerServicesResolutionView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplConSerComplaintFormConsumerServicesResolutionView,

  className: 'conser-complaint-form-consumer-services-resolution-view',

  ui: {
    submitInvestorComplaint: '.submit-new-complaint',
    validationErrors: '.validation-errors',
    resolutionForm: '.resolution-form',
    noTestifyReason: '.noTestifyReason'
  },

  events: {
    'click @ui.submitInvestorComplaint': 'onClickSubmit'
  },

  behaviors: {
    RadioGroupToggleBehavior: {
      radios: function() {
        var radios = [
          {
            target: '.no-testify-attempt-form',
            radioGroup: '[name="canTestify"]',
            valueToShow: '0'
          }
        ];

        return radios;
      }
    }
  },

  onBeforeShow: function() {
    Syphon.deserialize(this, this.model.toJSON());
  },

  onClickSubmit: function(e) {
    var self = this;
    var complaintId = this.model.get('complaintId');
    var l = Ladda.create(e.currentTarget);
    l.start();
    this.sideNavView.validateAll({ type: 'submit' })
      .done(function() {
        var rawFormData = self.sideNavView.getFormData();
        var formData = {
          isDraft: '1',
          externalUserId: Session.user.id,
          complaintId: complaintId,
          complainant: rawFormData.complaintFormYourInformation,
          serviceMember: rawFormData.complaintFormServiceMember,
          company: rawFormData.complaintFormCompanyInformation,
          information: rawFormData.complaintFormComplaintInformation,
          resolution: rawFormData.complaintFormServicesResolution
        };
        SubmitInvestorComplaint(formData)
          .done(function() {
            Radio.channel('navigate').trigger('show:ext:cs:dashboard');
          })
          .fail(function() {
            l.stop();
          });
      })
      .fail(function() {
        l.stop();
      });
  },

  validate: function(config) {

    this.ui.validationErrors.hide();
    this.ui.resolutionForm.parsley().reset();
    if (config.type === 'save') {
      // Turn off required field validation for this form
      parsleyUtil.disableRequiredValidation(this.ui.resolutionForm);
    } else {
      // Turn on required field validation for this form
      parsleyUtil.enableRequiredValidation(this.ui.resolutionForm);
    }
    var formData = Syphon.serialize(this.ui.resolutionForm);
    var canTestify = formData.canTestify === '1';
    if (!canTestify) {
      this.ui.noTestifyReason.attr('data-parsley-required', 'true');
    } else {
      this.ui.noTestifyReason.attr('data-parsley-required', 'false');
    }
    
    return this.ui.resolutionForm.parsley().whenValidate();
  },

  getFormData: function() {
    var formData = Syphon.serialize(this);
    
    return formData;
  }
});
