/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var _remove = require('lodash.remove');
var moment = require('moment');

var formatAsDollars = require('common/util/formatAsDollars');
var momentUtil = require('common/util/momentUtil');
var cleanUpResponse = require('common/util/cleanUpResponse');

module.exports = function(examId) {
  var defer = $.Deferred();

  $.soap({
    body: {
      GetExamDetailsForEntity: {
        $xmlns: 'http://dobs.pa.gov/nd/exam/bpm/1.0',
        ExamID: examId
      }
    },
    blueprintArrays: [
      'GetExamDetailsForEntityResponse.ExamFollowupDocuments.ND_ExamFollowupDocument',
      'GetExamDetailsForEntityResponse.ExamEntityDBAs.ND_EntityDBA'
    ],
    blueprint: {
      examId: '$..ID',
      licenseId: '$..LicenseID',
      examPeriodId: '$..ExamPeriodID',
      regionId: '$..RegionID',
      region: '$..Region',
      examStatusId: '$..ExamStatusID',
      examContactId: '$..ExamContactID',
      examContactFirstName: '$..ExamContactFirstName',
      examContactLastName: '$..ExamContactLastName',
      primaryExaminerUserId: '$..PrimaryExaminerUserId',
      examinerAssignedDate: '$..ExaminerAssignedDate',
      previousExam: '$..PreviousExam',
      examSetupCompletionDate: '$..ExamSetupCompletionDate',
      examDate: '$..ExamDate',
      examCoverageStartDate: '$..ExamCoverageStartDate',
      examCoverageEndDate: '$..ExamCoverageEndDate',
      examStartDate: '$..ExamStartDate',
      examEndDate: '$..ExamEndDate',
      cancelledDate: '$..CancelledDate',
      isOffsite: '$..IsOffsite',
      examLocation: '$..ExamLocation',
      examTypeId: '$..ExamTypeID',
      isFullScope: '$..IsFullScope',
      isSetupLetterConsolidated: '$..IsSetupLetterConsolidated',
      setupLetterDocumentId: '$..SetupLetterDocumentID',
      setupLetterDocumentName: '$..SetupLetterDocumentName',
      loanLogCode: '$..LoanLogCode',
      examMaterialsCode: '$..ExamMaterialsCode',
      examPrepCompletionDate: '$..ExamPrepCompletionDate',
      paymentAmount: '$..PaymentAmount',
      paymentAmountOverride: '$..PaymentAmountOverride',
      examProcessCompletionDate: '$..ExamProcessCompletionDate',
      reviewExaminerEntityFeedback: '$..ReviewExaminerEntityFeedback',
      entityFeedbackResponse: '$..EntityFeedbackResponse',
      examStatus: '$..ExamStatus',
      yearPeriod: '$..YearPeriod',
      entityId: '$..EntityID',
      entityName: '$..EntityName',
      tin: '$..TIN',
      nmlsid: '$..NMLSID',
      locationId: '$..LocationID',
      isPrimaryPlaceOfBusiness: '$..IsPrimaryPlaceOfBusiness',
      officePhoneNumber: '$..OfficePhoneNumber',
      officeFaxNumber: '$..OfficeFaxNumber',
      addressId: '$..AddressID',
      addressLine1: '$..AddressLine1',
      addressLine2: '$..AddressLine2',
      addressLine3: '$..AddressLine3',
      county: '$..County',
      city: '$..City',
      state: '$..State',
      postalCode: '$..PostalCode',
      country: '$..Country',
      licenseTypeId: '$..LicenseTypeID',
      licenseNumber: '$..LicenseNumber',
      originalIssueDate: '$..OriginalIssueDate',
      licenseType: '$..LicenseType',
      licenseTypeCode: '$..LicenseTypeCode',
      examLeadTimeDays: '$..ExamLeadTimeDays',
      examInstructionsDocumentId: '$..ExamInstructionsDocumentID',
      examInstructionsDocumentName: '$..ExamInstructionsDocumentName',
      examQuestionnaireDocumentId: '$..ExamQuestionaireDocumentID',
      examQuestionnaireDocumentName: '$..ExamQuestionaireDocumentName',
      hasExamPolicyProcedures: '$..HasExamPolicyProcedures',
      hasExamLoanLogs: '$..HasExamLoanLogs',
      loanLogDueDate: '$..LoanLogDueDate',
      examMaterialsDueDate: '$..ExamMaterialsDueDate',
      examinerReviewedDate: '$..ExaminerReviewedDate',
      hourlyRate: '$..HourlyRate',
      refundAmountOverride: '$..RefundAmountOverride',
      isRequestExamMaterial: '$..IsRequestExamMaterial',
      examFollowupDocuments: [
        '$..ND_ExamFollowupDocument',
        {
          id: '$.ID',
          examId: '$.ExamID',
          documentId: '$.DocumentID',
          file: {
            fileId: '$.DocumentID',
            fileName: '$.DocumentName'
          },
          isEntityDocument: '$.IsEntityDocument',
          documentTypeId: '$.DocumentTypeID',
          documentType: '$.DocumentType'
        }
      ],
      dbas: [
        '$..ND_EntityDBA',
        {
          id: '$.ID',
          dba: '$.DBA',
          fictitiousDocumentId: '$.FictitiousDocumentID',
          fictitiousDocumentName: '$.FictitiousDocumentName'
        }
      ]
    }
  })
    .done(function(response) {
      cleanUpResponse(response);
      response.examDate = momentUtil.formatStr(response.examDate, {
        sourceFormat: 'YYYY-MM-DD',
        targetFormat: 'MM/DD/YYYY',
        default: '--'
      });
      response.examinerAssignedDate = momentUtil.formatStr(response.examinerAssignedDate, {
        sourceFormat: moment.ISO_8601,
        targetFormat: 'MM/DD/YYYY hh:mm a',
        default: '--'
      });
      response.examSetupCompletionDate = momentUtil.formatStr(response.examSetupCompletionDate, {
        sourceFormat: moment.ISO_8601,
        targetFormat: 'MM/DD/YYYY hh:mm a',
        default: '--'
      });
      response.examCoverageStartDate = momentUtil.formatStr(response.examCoverageStartDate, {
        sourceFormat: 'YYYY-MM-DD',
        targetFormat: 'MM/DD/YYYY',
        default: '--'
      });
      response.examCoverageEndDate = momentUtil.formatStr(response.examCoverageEndDate, {
        sourceFormat: 'YYYY-MM-DD',
        targetFormat: 'MM/DD/YYYY',
        default: '--'
      });
      response.examStartDate = momentUtil.formatStr(response.examStartDate, {
        sourceFormat: 'YYYY-MM-DD',
        targetFormat: 'MM/DD/YYYY',
        default: '--'
      });
      response.examEndDate = momentUtil.formatStr(response.examEndDate, {
        sourceFormat: 'YYYY-MM-DD',
        targetFormat: 'MM/DD/YYYY',
        default: '--'
      });
      response.cancelledDate = momentUtil.formatStr(response.cancelledDate, {
        sourceFormat: 'YYYY-MM-DD',
        targetFormat: 'MM/DD/YYYY',
        default: '--'
      });
      response.examSetupCompletionDate = momentUtil.formatStr(response.examSetupCompletionDate, {
        sourceFormat: moment.ISO_8601,
        targetFormat: 'MM/DD/YYYY hh:mm a',
        default: '--'
      });
      response.examProcessCompletionDate = momentUtil.formatStr(response.examProcessCompletionDate, {
        sourceFormat: moment.ISO_8601,
        targetFormat: 'MM/DD/YYYY hh:mm a',
        default: '--'
      });
      response.examinerReviewedDate = momentUtil.formatStr(response.examinerReviewedDate, {
        sourceFormat: moment.ISO_8601,
        targetFormat: 'MM/DD/YYYY hh:mm a',
        default: '--'
      });
      response.loanLogDueDate = momentUtil.formatStr(response.loanLogDueDate, {
        sourceFormat: 'YYYY-MM-DD',
        targetFormat: 'MM/DD/YYYY',
        default: null
      });
      response.examMaterialsDueDate = momentUtil.formatStr(response.examMaterialsDueDate, {
        sourceFormat: 'YYYY-MM-DD',
        targetFormat: 'MM/DD/YYYY',
        default: null
      });

      response.isPrimaryPlaceOfBusiness = response.isPrimaryPlaceOfBusiness === '1';
      response.isOffsite = response.isOffsite === '1';
      response.isFullScope = response.isFullScope === '1';
      response.hasExamPolicyProcedures = response.hasExamPolicyProcedures === '1';
      response.hasExamLoanLogs = response.hasExamLoanLogs === '1';

      _.each(response.examFollowupDocuments, function(examFollowupDocument) {
        examFollowupDocument.isEntityDocument = examFollowupDocument.isEntityDocument === '1';
      });

      response.entityFollowupDocuments = _remove(response.examFollowupDocuments, function(examFollowupDocument) {
        return examFollowupDocument.isEntityDocument;
      });

      response.reviewExaminerFollowupDocuments = response.examFollowupDocuments;

      response.examStatus = response.examStatus || '--';

      response.dbasList = _.reduce(
        response.dbas,
        function(memo, dba, index) {
          if (index < response.dbas.length - 1) {
            return memo + dba.dba + ', ';
          }
          return memo + dba.dba;
        },
        ''
      );

      response.originalIssueDate = momentUtil.formatStr(response.originalIssueDate, {
        sourceFormat: 'YYYY-MM-DD',
        targetFormat: 'MM/DD/YYYY',
        default: '--'
      });

      response.previousExam = momentUtil.formatStr(response.previousExam, {
        sourceFormat: 'YYYY-MM-DD',
        targetFormat: 'MM/DD/YYYY',
        default: null
      });

      response.paymentAmountDisplay = formatAsDollars(parseFloat(response.paymentAmount));

      defer.resolve(response);
    })
    .fail(function(err) {
      defer.reject(err);
    });

  return defer.promise();
};
