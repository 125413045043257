var Handlebars = require("handlebars/dist/handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1;

  return container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? depth0.filingDetails : depth0)) != null ? stack1.filingType : stack1), depth0))
    + " \n  ";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1;

  return container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? depth0.filingDetails : depth0)) != null ? stack1.filingType : stack1), depth0))
    + " Filing ";
},"5":function(container,depth0,helpers,partials,data) {
    return "Submission\n          ";
},"7":function(container,depth0,helpers,partials,data) {
    return "Filing ";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "\n<h1 class=\"page-title\">"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.isSubmission : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data})) != null ? stack1 : "")
    + "</h1>\n<div class=\"header-bar\"></div>\n<div class=\"breadcrumb-container\"></div>\n\n<ul class=\"nav nav-tabs m-b-md\" role=\"tablist\">\n  <li role=\"presentation\" class=\"active\">\n    <a href=\"#filing\" class=\"filing-tab filing-part-tab\" aria-controls=\"home\" role=\"tab\" data-toggle=\"tab\">\n      <i class=\"fa fa-inbox\" aria-hidden=\"true\"></i>\n      <div class=\"tab-text\">\n        <div class=\"tab-step\">Step 1</div>\n        <div class=\"tab-title\">\n          "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.isSubmission : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.program(7, data, 0),"data":data})) != null ? stack1 : "")
    + "\n        </div>\n      </div>\n    </a>\n  </li>\n  <li role=\"presentation\" class=\"disabled the-payment\">\n    <div role=\"tab\" class=\"payment-tab filing-part-tab\" data-target=\"payment\">\n      <i class=\"fa fa-shopping-cart\" aria-hidden=\"true\"></i>\n      <div class=\"tab-text\">\n        <div class=\"tab-step\">Step 2</div>\n        <div class=\"tab-title\">Submit & Pay</div>\n      </div>\n    </div>\n  </li>\n</ul>\n\n<div class=\"tab-content\">\n  <div role=\"tabpanel\" class=\"tab-pane filing-container active\" id=\"filing\">...</div>\n  <div role=\"tabpanel\" class=\"tab-pane payment-container\" id=\"payment\"></div>\n</div>";
},"useData":true});