/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');

var cleanUpResponse = require('common/util/cleanUpResponse');

module.exports = function(entityId) {
  var deferred = $.Deferred();

  $.soap({
    body: {
      GetIndividualsByEntity: {
        $xmlns: 'http://dobs.pa.gov/cs/individual/bpm/1.0',
        EntityID: entityId
      }
    },
    blueprintArrays: ['GetIndividualsByEntityResponse.CS_Individual'],
    blueprint: {
      individuals: [
        '$..CS_Individual',
        {
          individualId: '$.ID',
          firstName: '$..FirstName',
          lastName: '$.LastName',
          title: '$.Title',
          email: '$.Email',
          isDeleted: '$.IsDeleted'
        }
      ]
    }
  })
    .done(function(response) {
      cleanUpResponse(response);
      _.each(response.individuals, function(individual) {
        _.each(['firstName', 'lastName', 'title', 'email'], function(attr) {
          if (!individual[attr]) {
            individual[attr] = '--';
          }
        });
      });
      deferred.resolve(response.individuals);
    })
    .fail(function(err) {
      deferred.reject();
    });

  return deferred.promise();
};
