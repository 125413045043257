/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2020] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Marionette = require('backbone.marionette');
var Syphon = require('backbone.syphon');
var parsleyUtil = require('common/util/parsleyUtil');

var tmplDepBankConversionInfoSectionView = require('./DepBankConversionInfoSectionView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplDepBankConversionInfoSectionView,

  className: 'dep-bank-conversion-info-section-view',

  ui: {
    conversionForm: '.conversion-form'
  },

  onBeforeShow: function() {
    var filingContent = this.model.get('filingContent') || {};

    Syphon.deserialize(this, filingContent);
  },

  validate: function(config) {
    this.ui.conversionForm.parsley().reset();
    if (config.type === 'submit') {
      var validationPromises = [this.ui.conversionForm.parsley().whenValidate()];
      // Turn on required field validation for this form
      parsleyUtil.enableRequiredValidation(this.ui.conversionForm);
    } else {
      // Turn off required field validation for this form
      parsleyUtil.disableRequiredValidation(this.ui.conversionForm);
    }
    return $.when.apply($, validationPromises);
  },

  getFormData: function() {
    var formData = Syphon.serialize(this);
    return formData;
  }
});
