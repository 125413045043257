/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var cleanUpResponse = require('common/util/cleanUpResponse');

module.exports = function(data) {
  var deferred = $.Deferred();

  $.soap({
    body: {
      GetIndividualByNameAndCRDNumber: {
        $xmlns: 'http://dobs.pa.gov/sc/individual/bpm/1.0',
        FirstName: data.firstName,
        LastName: data.lastName,
        CRDNumber: data.crdNumber
      }
    },
    blueprintArrays: ['GetIndividualByNameAndCRDNumberResponse.SC_Individual'],
    blueprint: {
      individuals: [
        '$..SC_Individual',
        {
          id: '$..ID',
          csFolderId: '$..CSFolderID',
          lastName: '$..LastName',
          firstName: '$..FirstName',
          middleInitial: '$..MiddleInitial',
          crdNumber: '$..CRDNumber',
          otherIDNumber: '$..OtherIDNumber'
        }
      ]
    }
  })
    .done(function(response) {
      cleanUpResponse(response);
      response.totalHits = response.individuals.length;

      deferred.resolve(response);
    })
    .fail(function(err) {
      deferred.reject();
    });

  return deferred.promise();
};
