/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var Marionette = require('backbone.marionette');

var Session = require('psa-core/Session');

var getUserIdFromDn = require('common/util/getUserIdFromDn');

require('./TaskStatusAlertView.css');
var tmplTaskStatusAlertView = require('./TaskStatusAlertView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplTaskStatusAlertView,

  className: 'task-status-alert-view alert alert-info',

  attributes: {
    role: 'alert'
  },

  onBeforeRender: function() {
    var targets = this.model.get('targets');
    var completionDateMoment = this.model.get('completionDate');
    var isUnclaimed = this.model.get('isCreated');
    var isAssigned = this.model.get('isAssigned');
    var isAssigneeCurrentUser = this.model.get('assignee') === Session.user.userName;
    var isAssignedToCurrentUser = isAssigned && isAssigneeCurrentUser;
    var isAssignedToAnotherUser = isAssigned && !isAssigneeCurrentUser;
    var targetRole = _.findWhere(targets, { type: 'role' });
    var targetRoleDn, targetRoleName;
    if (targetRole) {
      targetRoleDn = targetRole.targetDn;
      targetRoleName = getUserIdFromDn(targetRoleDn);
    }
    var completionDate;
    if (completionDateMoment && completionDateMoment.isValid()) {
      completionDate = completionDateMoment.format('MMMM Do YYYY, h:mm A');
    }
    this.model.set({
      isUnclaimed: isUnclaimed,
      isAssignedToCurrentUser: isAssignedToCurrentUser,
      isAssignedToAnotherUser: isAssignedToAnotherUser,
      targetRoleName: targetRoleName,
      completionDate: completionDate
    });
  },

  onBeforeShow: function() {
    var isAssignedToCurrentUser = this.model.get('isAssignedToCurrentUser');
    if (isAssignedToCurrentUser) {
      this.$el.hide();
    }
  }
});
