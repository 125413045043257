/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Marionette = require('backbone.marionette');
var Syphon = require('backbone.syphon');

var FileUploadTableView = require('common/views/FileUploadTableView');

var tmplSecInitiateCorrespondenceFormView = require('../templates/SecInitiateCorrespondenceFormView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplSecInitiateCorrespondenceFormView,

  className: 'sec-initiate-correspondence-form-view',

  ui: {
    fileContainer: '.file-container',
    correspondenceForm: '.correspondence-form',
    missingFile: '.missing-file'
  },

  regions: {
    fileContainer: '@ui.fileContainer'
  },

  onBeforeShow: function() {
    this.fileUploadTable = new FileUploadTableView({
      documentTypes: this.model.get('documentTypes'),
      emptyText: 'No Document Provided',
      collection: new Backbone.Collection()
    });
    this.showChildView('fileContainer', this.fileUploadTable);
  },

  validate: function() {
    var validationArray = [this.ui.correspondenceForm.parsley().whenValidate(), this.validateCommentsAttachments()];
    var hasAttachment = this.fileUploadTable.collection.length > 0;

    if (hasAttachment) {
      validationArray.push(this.fileUploadTable.validate({ type: 'submit' }, 1));
    }

    return $.when.apply($, validationArray);
  },

  validateCommentsAttachments: function() {
    var defer = $.Deferred();

    var formData = this.getFormData();
    var hasNoComment = formData.requestComments.length === 0;
    var hasNoAttachment = formData.documents.length === 0;

    if (hasNoComment && hasNoAttachment) {
      this.ui.missingFile.show();
      defer.reject();
    } else {
      this.ui.missingFile.hide();
      defer.resolve();
    }

    return defer.promise();
  },

  getFormData: function() {
    var formData = Syphon.serialize(this);
    formData.documents = this.fileUploadTable.getFormData();

    return formData;
  }
});
