/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2020] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Marionette = require('backbone.marionette');
var Syphon = require('backbone.syphon');

var toggleForm = require('common/util/toggleForm');
var momentUtil = require('common/util/momentUtil');

require('./DepOathRosterDirectorFormView.css');

var tmplDepOathRosterDirectorFormView = require('./DepOathRosterDirectorFormView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplDepOathRosterDirectorFormView,

  className: 'dep-oath-roster-director-form-view panel panel-info',

  ui: {
    boardElectedForm: '.board-elected-form',
    shareholderElectedForm: '.shareholder-elected-form',
    removeDirector: '.remove-director',
    datePickerFields: '.date-picker'
  },

  triggers: {
    'click @ui.removeDirector': 'remove:director'
  },

  templateHelpers: function() {
    return {
      modelId: this.model.cid
    };
  },

  onBeforeShow: function() {
    Syphon.deserialize(this, this.model.toJSON());

    var oathRosterTypeId = $('input[name="typeId"]:checked').val();
    toggleForm(this.ui.boardElectedForm, oathRosterTypeId === '1');
    toggleForm(this.ui.shareholderElectedForm, oathRosterTypeId === '2');
    this.ui.datePickerFields.datepicker();
  },

  getFormData: function() {
    var formData = Syphon.serialize(this);
    formData.electionDate = momentUtil.formatStr(formData.electionDate, momentUtil.defaultOutboundDateConfig);
    formData.vacancyDate = momentUtil.formatStr(formData.vacancyDate, momentUtil.defaultOutboundDateConfig);
    formData.serviceEndDate = momentUtil.formatStr(formData.serviceEndDate, momentUtil.defaultOutboundDateConfig);
    return formData;
  }
});
