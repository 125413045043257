/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var _get = require('lodash.get');

module.exports = function(formData) {
  var supportingDocuments = formData.supportingDocuments || [];
  var infoDocs = formData.requiredInformationDocumentation || {};
  var pointOfContact = formData.pointOfContact || {};
  var lawyerExternalUserId = _get(formData, 'bankSubsidiarySubmissionLawyer.lawyerExternalUserId');
  var certification = formData.certification || {};

  return $.soap({
    body: {
      SubmitBankSubsidiaryFiling: {
        $xmlns: 'http://dobs.pa.gov/dp/filing/bpm/1.0',
        BankSubsidiaryID: formData.id,
        FilingID: formData.filingId,
        IsCertified: certification.isCertified ? '1' : '0',
        CertificationName: certification.certificationName,
        CertificationDocID: certification.certificationDocId,
        FilBankSubsidiary: {
          ActivityTypeID: infoDocs.activityTypeId,
          BankTypeID: infoDocs.bankTypeId,
          SubsidaryName: infoDocs.subsidaryName,
          ProposedActivity: infoDocs.proposedActivity,
          AuthorityID: infoDocs.authorityId,
          IsCitationUSC: infoDocs.isCitationUSC,
          CitationUSCComments: infoDocs.citationUSCComments,
          IsCitationCFR: infoDocs.isCitationCFR,
          CitationCFRComments: infoDocs.citationCFRComments,
          IsCitationOther: infoDocs.isCitationOther,
          CitationOtherComments: infoDocs.citationOtherComments,
          IsCitationInterpretiveLetter: infoDocs.isCitationInterpretiveLetter,
          CitationInterpretiveLetterComments: infoDocs.citationInterpretiveLetterComments,
          IsCitation24FederalDepositAct: infoDocs.isCitation24FederalDepositAct,
          IsCitation24HomeState: infoDocs.isCitation24HomeState,
          Citation24HomeStateComments: infoDocs.citation24HomeStateComments,
          IsCitation24LegalCitations: infoDocs.isCitation24LegalCitations,
          Citation24LegalCitationsComments: infoDocs.citation24LegalCitationsComments,
          IsCitation24InterpretiveLetter: infoDocs.isCitation24InterpretiveLetter,

          Citation24InterpretiveLetterComments: infoDocs.citation24InterpretiveLetterComments,
          IsLegalBasis7: infoDocs.isLegalBasis7,
          LegalBasis7Comments: infoDocs.legalBasis7Comments,
          IsLegalBasisUSC: infoDocs.isLegalBasisUSC,
          LegalBasisUSCComments: infoDocs.legalBasisUSCComments,
          IsLegalBasisCFR: infoDocs.isLegalBasisCFR,
          LegalBasisCFRComments: infoDocs.legalBasisCFRComments,
          RiskAnalysisDocID: infoDocs.riskAnalysisDocId,
          ImposedRestrictionsDocID: infoDocs.imposedRestrictionsDocId,
          ContactInformationDocID: infoDocs.contactInformationDocId,
          FedRegulatorID: infoDocs.fedRegulatorId,
          OwnershipStructureID: infoDocs.ownershipStructureId,
          RiskAnalysisComments: infoDocs.riskAnalysisComments,
          ImposedRestrictionComments: infoDocs.imposedRestrictionComments,
          ContactInformationComments: infoDocs.contactInformationComments
        },
        AgencyInterpretiveDocuments: (function() {
          if (!_get(infoDocs, 'agencyDocIds.length')) {
            return {};
          }
          return _.map(infoDocs.agencyDocIds, function(docId) {
            return { DocumentID: docId };
          });
        })(),
        FDICInterpretiveDocuments: (function() {
          if (!_get(infoDocs, 'fdicDocIds.length')) {
            return {};
          }
          return _.map(infoDocs.fdicDocIds, function(docId) {
            return { DocumentID: docId };
          });
        })(),
        PointOfContact: {
          Name: pointOfContact.name,
          Phone: pointOfContact.phone,
          Email: pointOfContact.email,
          AddressLine1: pointOfContact.addressLine1,
          AddressLine2: pointOfContact.addressLine2,
          AddressLine3: pointOfContact.addressLine3,
          City: pointOfContact.city,
          State: pointOfContact.state,
          PostalCode: pointOfContact.postalCode,
          County: pointOfContact.county,
          Country: pointOfContact.country,
          Title: pointOfContact.title
        },
        LawyerExternalUserID: lawyerExternalUserId,
        SupportingDocuments: (function() {
          if (!supportingDocuments.length) {
            return {};
          }
          return {
            Document: _.map(supportingDocuments, function(supportingDocument) {
              return {
                DocumentID: supportingDocument.fileId
              };
            })
          };
        })()
      }
    }
  });
};
