var Handlebars = require("handlebars/dist/handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "red-border";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "    <div class=\"input-label red-text m-b-lg\"><b>Previously: </b>"
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? depth0.oldAssociatedLicense : depth0)) != null ? stack1.entityName : stack1), depth0))
    + "</div>\n";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "    <div class=\"input-label red-text m-b-lg\"><b>Previously: </b>"
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? depth0.oldAssociatedLicense : depth0)) != null ? stack1.licenseType : stack1), depth0))
    + "</div>\n";
},"7":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "    <div class=\"input-label red-text m-b-lg\"><b>Previously: </b>"
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? depth0.oldAssociatedLicense : depth0)) != null ? stack1.licenseNumber : stack1), depth0))
    + "</div>\n";
},"9":function(container,depth0,helpers,partials,data) {
    return "<div class=\"col-sm-1 p-l-n\">\n  <h4><span class=\"label label-danger deletion-flag font-weight-normal\">\n      <span class=\"glyphicon glyphicon-remove m-r-xs\" aria-hidden=\"true\"></span>Deletion\n    </span></h4>\n</div>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "\n<div class=\"col-sm-4\">\n  <div class=\"form-group\">\n    <input type=\"text\" class=\"form-control "
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.amendmentChanges : depth0)) != null ? stack1.isDeleted : stack1),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\" name=\"entityName\" placeholder=\"Business Name\" data-parsley-required=\"true\"\n      data-parsley-maxlength=\"500\" />\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.amendmentChanges : depth0)) != null ? stack1.entityName : stack1),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "  </div>\n</div>\n<div class=\"col-sm-4\">\n  <div class=\"form-group\">\n    <input type=\"text\" class=\"form-control "
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.amendmentChanges : depth0)) != null ? stack1.isDeleted : stack1),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\" name=\"licenseType\" placeholder=\"License Type\" data-parsley-required=\"true\"\n      data-parsley-maxlength=\"200\" />\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.amendmentChanges : depth0)) != null ? stack1.licenseType : stack1),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "  </div>\n</div>\n<div class=\"col-sm-3\">\n  <div class=\"form-group\">\n    <input type=\"text\" class=\"form-control "
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.amendmentChanges : depth0)) != null ? stack1.isDeleted : stack1),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\" name=\"licenseNumber\" placeholder=\"License Number\"\n      data-parsley-required=\"true\" data-parsley-maxlength=\"100\" />\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.amendmentChanges : depth0)) != null ? stack1.licenseNumber : stack1),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "  </div>\n</div>\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.amendmentChanges : depth0)) != null ? stack1.isDeleted : stack1),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "<div class=\"col-sm-1\">\n  <button class=\"btn btn-danger btn-md remove-associated-license\">\n    <i class=\"fa fa-times\" aria-hidden=\"true\"></i>\n  </button>\n</div>";
},"useData":true});