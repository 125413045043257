var Handlebars = require("handlebars/dist/handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "phone-format";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "\n<div class=\"col-sm-10\">\n  <div class=\"form-group\">\n    <input type=\"text\" class=\"form-control "
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.isTrust : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\" name=\"additionalNumber\"\n      placeholder=\"Additional Number\" data-parsley-required=\"true\" data-parsley-maxlength=\"50\" />\n  </div>\n</div>\n<div class=\"col-sm-2\">\n  <button class=\"btn btn-danger btn-sm remove-additional-number\">\n    <i class=\"fa fa-times\" aria-hidden=\"true\"></i>\n  </button>\n</div>";
},"useData":true});