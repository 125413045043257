/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Backbone = require('backbone');

var PlaceholderView = require('common/views/PlaceholderView');

var GetEntityExternalUsersAndInvites = require('../services/GetEntityExternalUsersAndInvites');

var NonDepEntitySectionUserManagementView = require('../views/NonDepEntitySectionUserManagementView');

module.exports = {
  channel: 'nd',

  event: 'build:entity-section-user-management-view',

  /**
   * @param config Defines how the view should be built
   * @param config.entityId The entityId of the entity
   * @param {string} config.entityName The name of the entity
   * @param {string} config.viewMode Should be 'int' or 'ext', so view can be configured for internal or external
   * @param {object} config.userAccessLevel External only config value, object containing accessLevelId and accesslevel of current user for the entity
   */
  builder: function(config) {
    return new PlaceholderView({
      viewPromise: buildEntityUserManagementView(config)
    });
  }
};

function buildEntityUserManagementView(config) {
  var deferred = $.Deferred();
  GetEntityExternalUsersAndInvites(config.entityId)
    .done(function(results) {
      deferred.resolve(
        new NonDepEntitySectionUserManagementView({
          viewMode: config.viewMode,
          userAccessLevel: config.userAccessLevel,
          model: new Backbone.Model({
            entityId: config.entityId,
            entityName: config.entityName,
            authorizedUsers: results.authorizedUsers,
            invitedUsers: results.invitedUsers,
            userAccessLevels: results.userAccessLevels
          })
        })
      );
    })
    .fail(function(err) {
      deferred.reject(err);
    });
  return deferred.promise();
}
