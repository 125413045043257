/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Ladda = require('ladda');
var Radio = require('backbone.radio');
var Backbone = require('backbone');

var SideNavSectionView = require('common/views/SideNavSectionView');

var SubmitNewEntity = require('../services/SubmitNewEntity');

var NonDepEntityFormSectionIdentificationView = require('./NonDepEntityFormSectionIdentificationView');
var NonDepEntityFormSectionOfficersView = require('./NonDepEntityFormSectionOfficersView');
var NonDepEntityFormSectionLocationsView = require('./NonDepEntityFormSectionLocationsView');
var NonDepEntityFormSectionBooksAndRecordsView = require('./NonDepEntityFormSectionBooksAndRecordsView');
var NonDepEntityFormSectionAffirmationView = require('./NonDepEntityFormSectionAffirmationView');

module.exports = SideNavSectionView.extend({
  behaviors: {
    ReadOnlyFormBehavior: {}
  },

  onBeforeRender: function() {
    if (SideNavSectionView.prototype.onBeforeRender) {
      SideNavSectionView.prototype.onBeforeRender.apply(this, arguments);
    }

    this.collection = new Backbone.Collection([
      {
        id: 'entityFormIdentification',
        title: 'Identification',
        view: new NonDepEntityFormSectionIdentificationView({
          isReadOnly: this.options.isReadOnly,
          model: this.model
        })
      }
    ]);

    if (this.options.viewMode !== 'extEdit') {
      this.collection.add([
        {
          id: 'entityFormLocations',
          title: 'Locations',
          view: new NonDepEntityFormSectionLocationsView({
            isReadOnly: this.options.isReadOnly,
            model: this.model
          })
        }
      ]);
    }

    this.collection.add([
      {
        id: 'entityFormEntityOfficers',
        title: 'Entity Officers',
        view: new NonDepEntityFormSectionOfficersView({
          isReadOnly: this.options.isReadOnly,
          model: this.model
        })
      },
      {
        id: 'entityFormBooksAndRecords',
        title: 'Books and Records',
        view: new NonDepEntityFormSectionBooksAndRecordsView({
          isReadOnly: this.options.isReadOnly,
          model: this.model
        })
      },
      {
        id: 'entityFormAffirmation',
        title: 'Affirmation',
        view: new NonDepEntityFormSectionAffirmationView({
          model: this.model,
          viewMode: this.options.viewMode
        })
      }
    ]);

    if (this.options.viewMode !== 'extEdit') {
      this.options.buttons = [
        {
          buttonText: 'Save',
          buttonClass: 'btn-default ladda-button',
          buttonIconClass: 'fa-save',
          labelClass: 'ladda-label',
          buttonAttrs: {
            'data-style': 'expand-right'
          },
          onClick: this.onClickSave.bind(this)
        }
      ];

      if (this.options.isReadOnly) {
        this.collection.remove(this.collection.get('entityFormAffirmation'));
      }
    }
  },

  onClickSave: function(e) {
    var self = this;
    var entityId = this.model.get('id');
    var l = Ladda.create(e.currentTarget);
    l.start();
    this.validateAll({ type: 'save' })
      .done(function() {
        var rawFormData = self.getFormData();
        var formData = {
          isDraft: true,
          entityId: entityId,
          identification: rawFormData.entityFormIdentification,
          entityOfficers: rawFormData.entityFormEntityOfficers,
          locations: rawFormData.entityFormLocations,
          booksAndRecords: rawFormData.entityFormBooksAndRecords
        };
        SubmitNewEntity(formData)
          .done(function(newEntityId) {
            if (!entityId) {
              Radio.channel('navigate').trigger('show:ext:nd:edit-entity', newEntityId);
            } else {
              l.stop();
            }
          })
          .fail(function() {
            l.stop();
          });
      })
      .fail(function() {
        l.stop();
      });
  }
});
