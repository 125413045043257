/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2020] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Marionette = require('backbone.marionette');

var GridView = require('psa-marionette/views/GridView');

var MomentCell = require('common/backgrid/MomentCell');
var ButtonLinkCell = require('common/backgrid/ButtonLinkCell');

var tmplDepEntityOfficersSectionView = require('../templates/DepEntityOfficersSectionView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplDepEntityOfficersSectionView,

  className: 'dep-entity-officers-section-view',

  ui: {
    officersList: '.officers-list-container',
    showInactiveOfficers: ' .show-inactive-officers',
    isCheckedShowInactive: '.showInactiveOfficers'
  },

  regions: {
    officersList: '@ui.officersList'
  },

  events: {
    'click @ui.showInactiveOfficers': 'onClickShowInactiveOfficers'
  },

  onBeforeShow: function() {
    var gridColumns = [
      {
        name: 'officerType',
        label: 'Type',
        cell: 'string',
        headerCell: 'custom',
        width: 10,
        editable: false
      },
      {
        name: 'firstName',
        label: 'First Name',
        cell: 'string',
        headerCell: 'custom',
        width: 10,
        editable: false
      },
      {
        name: 'middleInitial',
        label: 'Middle Initial',
        cell: 'string',
        headerCell: 'custom',
        width: 5,
        editable: false
      },
      {
        name: 'lastName',
        label: 'Last Name',
        cell: 'string',
        headerCell: 'custom',
        width: 10,
        editable: false
      },
      {
        name: 'title',
        label: 'Title',
        cell: 'string',
        headerCell: 'custom',
        width: 10,
        editable: false
      },
      {
        name: 'status',
        label: 'Status',
        cell: 'string',
        headerCell: 'custom',
        width: 10,
        editable: false
      },
      {
        name: 'termLength',
        label: 'Term Length',
        cell: 'string',
        headerCell: 'custom',
        width: 10,
        editable: false
      },
      {
        name: 'termStartDate',
        label: 'Term Start',
        cell: MomentCell.MomentCell,
        formatter: MomentCell.MomentDateFormatter.extend({
          emptyValue: '--'
        }),
        sortValue: MomentCell.MomentSortValue,
        headerCell: 'custom',
        width: 10,
        editable: false
      },
      {
        name: 'termEndDate',
        label: 'Term End',
        cell: MomentCell.MomentCell,
        formatter: MomentCell.MomentDateFormatter.extend({
          emptyValue: '--'
        }),
        sortValue: MomentCell.MomentSortValue,
        headerCell: 'custom',
        width: 10,
        editable: false
      },
      {
        name: '',
        label: '',
        cell: ButtonLinkCell.extend({
          name: 'Open',
          buttonClass: 'btn-primary btn-sm',
          href: function(model) {
            var entityId = model.get('entityId');
            var officerId = model.get('officerId');
            return '#dep/entity/' + entityId + '/officer/' + officerId + '/dashboard';
          }
        }),
        headerCell: 'custom',
        width: 5,
        editable: false,
        sortable: false
      }
    ];

    this.officersGrid = new GridView({
      columns: gridColumns,
      emptyText: 'No Officers',
      collection: this.collection
    });

    this.onClickShowInactiveOfficers();
    this.showChildView('officersList', this.officersGrid);
  },

  onClickShowInactiveOfficers: function(e) {
    var $checkbox = this.ui.showInactiveOfficers;
    var showInactive = $checkbox.prop('checked');

    var activeOfficers = this.model.get('activeOfficers');
    var inactiveOfficers = this.model.get('inactiveOfficers');

    if (!showInactive) {
      this.officersGrid.collection.fullCollection.reset(activeOfficers);
    } else {
      this.officersGrid.collection.fullCollection.reset(inactiveOfficers);
    }
  }
});
