/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Backbone = require('backbone');
var NProgress = require('nprogress');

var NavUtils = require('psa-core/util/nav');

var Scaffold = require('common/layout/Scaffold');
var GetDocumentTypesByDepartment = require('common/services/GetDocumentTypesByDepartment');

var GetLicenseDashboard = require('../../license-dashboard/services/GetLicenseDashboard');
var GetActiveLicensesByParentLicense = require('../services/GetActiveLicensesByParentLicense');

var LicenseSurrenderView = require('../views/LicenseSurrenderView');

module.exports = function(entityId, licenseId) {
  NProgress.start();
  NProgress.set(0.6);

  $.when(
    GetLicenseDashboard(licenseId),
    GetDocumentTypesByDepartment('NON_DEPOSITORY'),
    GetActiveLicensesByParentLicense(licenseId)
  )
    .done(function(licenseDashboard, documentTypes, childLicenses) {
      NavUtils.navigate('ext/nondep/entity/' + entityId + '/license/' + licenseId + '/surrender');

      Scaffold.content.show(
        new LicenseSurrenderView({
          isReadOnly: false,
          model: new Backbone.Model({
            entityId: entityId,
            licenseId: licenseId,
            licenseDashboard: licenseDashboard,
            documentTypes: documentTypes,
            childLicenses: childLicenses
          })
        })
      );
      NProgress.done();
    })
    .fail(function(err) {
      NProgress.done();
    });
};
