/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var moment = require('moment');

var cleanUpResponse = require('common/util/cleanUpResponse');

module.exports = function() {
  var deferred = $.Deferred();

  $.soap({
    body: {
      GetApplicationsByOwner: {
        $xmlns: 'http://dobs.pa.gov/nd/application/bpm/1.0'
      }
    },
    blueprintArrays: ['GetApplicationsByOwnerResponse.ND_Application'],
    blueprint: {
      applications: [
        '$..ND_Application',
        {
          id: '$..ID',
          applicationType: '$..ApplicationType',
          applicationTypeId: '$..ApplicationTypeID',
          entityId: '$..EntityID',
          licenseId: '$..LicenseID',
          licenseType: '$..LicenseType',
          applicationStatus: '$..ApplicationStatus',
          applicationStatusId: '$..ApplicationStatusID',
          entityName: '$..EntityName',
          tin: '$..TIN',
          submissionDate: '$..SubmissionDate',
          owner: '$..Owner'
        }
      ]
    }
  })
    .done(function(response) {
      cleanUpResponse(response);

      _.each(response.applications, function(application) {
        application.submissionDate = moment(application.submissionDate, moment.ISO_8601);
        application.licenseType = application.licenseType || '--';
      });

      deferred.resolve(response.applications);
    })
    .fail(function(err) {
      deferred.reject();
    });

  return deferred.promise();
};
