/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var GridView = require('psa-marionette/views/GridView');

var MomentCell = require('common/backgrid/MomentCell');
var ButtonLinkCell = require('common/backgrid/ButtonLinkCell');

module.exports = GridView.extend({
  className: 'dep-sub-fund-audit-section-view',

  onBeforeRender: function() {
    if (GridView.prototype.onBeforeRender) {
      GridView.prototype.onBeforeRender.apply(this, arguments);
    }

    this.options.columns = [
      {
        name: 'auditDate',
        label: 'Audit Date',
        cell: MomentCell.MomentCell,
        formatter: MomentCell.MomentDateFormatter.extend({
          emptyValue: '--'
        }),
        sortValue: MomentCell.MomentSortValue,
        editable: false,
        headerCell: 'custom',
        width: 30
      },
      {
        name: 'opinionDetails',
        label: 'Opinion Details',
        cell: 'string',
        editable: false,
        headerCell: 'custom',
        width: 30
      },
      {
        name: 'auditFirm',
        label: 'Audit Firm',
        cell: 'string',
        editable: false,
        headerCell: 'custom',
        width: 30
      },
      {
        name: '',
        label: '',
        cell: ButtonLinkCell.extend({
          name: 'Open',
          buttonClass: 'btn-primary btn-sm',
          href: function(model) {
            var entityId = model.get('entityId');
            var fundId = model.get('fundId');
            var subFundId = model.get('subFundId');
            var auditId = model.get('id');
            return (
              '#dep/entity/' +
              entityId +
              '/fund/' +
              fundId +
              '/sub-fund/' +
              subFundId +
              '/audit/' +
              auditId +
              '/dashboard'
            );
          }
        }),
        editable: false,
        sortable: false,
        headerCell: 'custom',
        width: 10
      }
    ];

    this.options.emptyText = 'Sub-Fund Has No Audits';
  }
});
