/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2020] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Backbone = require('backbone');
var NProgress = require('nprogress');

var NavUtils = require('psa-core/util/nav');

var Scaffold = require('common/layout/Scaffold');
var GetEntityIdentifiers = require('../../common/services/GetEntityIdentifiers');
var GetAllOfficerLookups = require('../../common/services/GetAllOfficerLookups');
var GetOfficerByEntityID = require('../../common/services/GetOfficersByEntity');

var DepOfficerNewView = require('../views/DepOfficerNewView');

module.exports = function(entityId) {
  NProgress.start();
  NProgress.set(0.6);

  $.when(GetEntityIdentifiers(entityId), GetOfficerByEntityID(entityId))
    .done(function(entityIdentifiers, entityofficers, lookups) {
      GetAllOfficerLookups(entityIdentifiers.categoryId)
        .done(function(lookups) {
          NavUtils.navigate('dep/entity/' + entityId + '/new-officer');
          Scaffold.content.show(
            new DepOfficerNewView({
              model: new Backbone.Model({
                entityIdentifiers: entityIdentifiers,
                entityofficers: entityofficers,
                entityId: entityId,
                officerType: lookups.officerType,
                officerDirectorStatus: lookups.officerDirectorStatus
              })
            })
          );
          NProgress.done();
        })
        .fail(function() {
          NProgress.done();
        });
    })
    .fail(function(err) {
      NProgress.done();
    });
};
