var Handlebars = require("handlebars/dist/handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "\n<div class=\"panel-heading\" role=\"tab\" id=\"heading-"
    + alias4(((helper = (helper = helpers.modelId || (depth0 != null ? depth0.modelId : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"modelId","hash":{},"data":data}) : helper)))
    + "\">\n  <div class=\"panel-title\">\n    <a data-toggle=\"collapse\" data-parent=\"#accordion\" href=\"#collapse-"
    + alias4(((helper = (helper = helpers.modelId || (depth0 != null ? depth0.modelId : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"modelId","hash":{},"data":data}) : helper)))
    + "\" aria-expanded=\"true\"\n      aria-controls=\"collapse-"
    + alias4(((helper = (helper = helpers.modelId || (depth0 != null ? depth0.modelId : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"modelId","hash":{},"data":data}) : helper)))
    + "\">\n      Applicant\n    </a>\n    <button class=\"btn btn-danger btn-md remove-applicant pull-right\">Remove</button>\n  </div>\n</div>\n<div id=\"collapse-"
    + alias4(((helper = (helper = helpers.modelId || (depth0 != null ? depth0.modelId : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"modelId","hash":{},"data":data}) : helper)))
    + "\" class=\"panel-collapse collapse in\" role=\"tabpanel\"\n  aria-labelledby=\"heading-"
    + alias4(((helper = (helper = helpers.modelId || (depth0 != null ? depth0.modelId : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"modelId","hash":{},"data":data}) : helper)))
    + "\">\n  <div class=\"panel-body\">\n    <form>\n      <div class=\"row\">\n        <div class=\"col-md-6 col-sm-12\">\n          <div class=\"form-group required\">\n            <label class=\"control-label\">Name</label>\n            <input type=\"text\" name=\"name\" class=\"form-control\" placeholder=\"Name\" data-parsley-maxlength=\"200\"\n              data-parsley-required=\"true\" />\n          </div>\n        </div>\n        <div class=\"col-md-6 col-sm-12\">\n          <div class=\"form-group\">\n            <label class=\"control-label\">Business</label>\n            <input type=\"text\" name=\"business\" class=\"form-control\" placeholder=\"Business\" data-parsley-maxlength=\"500\"/>\n          </div>\n        </div>\n      </div>\n      <div class=\"address-container\"></div>\n      <div class=\"row\">\n        <div class=\"col-md-6 col-sm-12\">\n          <div class=\"form-group required\">\n            <label class=\"control-label\">Phone</label>\n            <input type=\"text\" name=\"phone\" class=\"form-control\" placeholder=\"Phone\" data-parsley-maxlength=\"100\"\n              data-parsley-required=\"true\" />\n          </div>\n        </div>\n        <div class=\"col-md-6 col-sm-12\">\n          <div class=\"form-group required\">\n            <label class=\"control-label\">Email</label>\n            <input type=\"text\" name=\"email\" class=\"form-control\" placeholder=\"Email\" data-parsley-maxlength=\"200\"\n              data-parsley-required=\"true\" data-parsley-type=\"email\" />\n          </div>\n        </div>\n      </div>\n    </form>\n  </div>\n</div>";
},"useData":true});