/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Cookie = require('js-cookie');
var Config = require('../../config/Config');
var GetPreLoginInfo = require('psa-authentication/GetPreLoginInfo');
var KeystoneLogin = require('./KeystoneLogin');
var Session = require('psa-core/Session');
var sha1 = require('psa-core/util/sha1');
var UpdateExternalUser = require('./UpdateExternalUser');

module.exports = function() {
  var defer = $.Deferred();
  KeystoneLogin()
    .done(function(sessionTokens) {
      sessionTokens = JSON.parse(sessionTokens);
      if (sessionTokens.Success === 'true') {
        GetPreLoginInfo()
          .done(function(preLoginInfo) {
            Session.preLoginInfo = preLoginInfo;
            var samlCookieName = preLoginInfo.SamlArtifactCookieName;
            var checkCookieName = preLoginInfo.CheckName;
            var cookiesPath = preLoginInfo.SamlArtifactCookiePath || '/';
            Cookie.set(Config.getDoBSSessionToken(), sessionTokens.DOBS_SESSION, { path: cookiesPath });
            Cookie.set(samlCookieName, sessionTokens.SAMLART, { path: cookiesPath });
            var ct = sha1.hex_sha1(sessionTokens.SAMLART);
            Cookie.set(checkCookieName, ct, { path: cookiesPath });
            Session.auth = {
              samlArtifact: sessionTokens.SAMLART,
              checkValue: ct
            };
            UpdateExternalUser(sessionTokens).done(function() {
              defer.resolve();
            });
            defer.resolve(preLoginInfo);
          })
          .fail(function(errCode) {
            defer.reject(errCode);
          });
      } else {
        if (sessionTokens.IsMFAVerified === 'false') {
          window.location = Config.getKeystoneLoginRedirectURL();
        } else if (sessionTokens.IsValidSession === 'false') {
          window.location = Config.getKeystoneLogoutRedirectURL();
        }
      }
    })
    .fail(function(errCode) {
      defer.reject(errCode);
    });
  return defer.promise();
};
