/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var moment = require('moment');

var cleanUpResponse = require('common/util/cleanUpResponse');

module.exports = function(formData) {
  var defer = $.Deferred();

  formData.effectiveDate = formData.effectiveDate
    ? moment(formData.effectiveDate, 'MM/DD/YYYY').format('YYYY-MM-DD')
    : '';

  $.soap({
    body: {
      SubmitLicenseInfoDetail: {
        $xmlns: 'http://dobs.pa.gov/sc/licenseinfodetail/bpm/1.0',
        ObjectTypeID: formData.objectTypeId,
        ObjectID: formData.objectId,
        DescriptionID: formData.descriptionId,
        EffectiveDate: formData.effectiveDate,
        Note: formData.note,
        IsDeleted: formData.isDeleted,
        ID: formData.id
      }
    },
    blueprint: {
      id: '$..ID',
      objectTypeId: '$..ObjectTypeID',
      objectId: '$..ObjectID',
      descriptionId: '$..DescriptionID',
      effectiveDate: '$..EffectiveDate',
      note: '$..Note'
    }
  })
    .done(function(response) {
      cleanUpResponse(response);
      if (response.effectiveDate) {
        response.effectiveDate = moment(response.effectiveDate, 'YYYY-MM-DD');
      }
      defer.resolve(response);
    })
    .fail(function(err) {
      defer.reject(err);
    });

  return defer.promise();
};
