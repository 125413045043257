/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var cleanUpResponse = require('common/util/cleanUpResponse');

module.exports = function(filingId) {
  var deferred = $.Deferred();
  $.soap({
    body: {
      GetSection112Filing: {
        $xmlns: 'http://dobs.pa.gov/dp/filing/bpm/1.0',
        FilingID: filingId
      }
    },
    blueprintArrays: [
      'GetSection112FilingResponse.SupportingDocuments.DP_FilingSupportingDocument',
      'GetSection112FilingResponse.Applicants.DP_FilSection112Applicant',
      'GetSection112FilingResponse.BioFinDocuments.DP_FilSection112BioFinDocument',
      'GetSection112FilingResponse.FederalApplicationDocuments.DP_FilSection112FederalApplicationDocument',
      'GetSection112FilingResponse.AgencyInterpretiveDocs.DP_FilBankParityAgencyInterpretiveDocument',
      'GetSection112FilingResponse.AcquisitionRequestDocuments.DP_FilSection112AcquisitionRequestDocument',
      'GetSection112FilingResponse.AquirerPositions.DP_FilSection112AcquirerPosition',
      'GetSection112FilingResponse.FundDocuments.DP_FilSection112FundDocument',
      'GetSection112FilingResponse.PertinentDocuments.DP_FilSection112PertinentDocument',
      'GetSection112FilingResponse.PurchasePrices.DP_FilSection112PurchasePrice',
      'GetSection112FilingResponse.ShareInfos.DP_FilSection112ShareInfo',
      'GetSection112FilingResponse.SharesRegistered.DP_FilSection112ShareRegistered',
      'GetSection112FilingResponse.TenderOfferDocuments.DP_FilSection112TenderOfferDocument'
    ],
    blueprint: {
      section112Id: '$..ID',
      filingId: '$..FilingID',
      applicantTypeId: '$..ApplicantTypeID',
      acquisitionTypeId: '$..AcquisitionTypeID',
      acquiredEntityName: '$..AcquiredEntityName',
      isPriorNotice: '$..IsPriorNotice',
      noPriorNoticeExplanation: '$..NoPriorNoticeExplanation',
      isSubjectNotified: '$..IsSubjectNotified',
      numberOfShares: '$..NumberOfShares',
      allTransactions: '$..AllTransactions',
      relatedPerson: '$..RelatedPerson',
      hasIntendedPlans: '$..HasIntendedPlans',
      intendedPlans: '$..IntendedPlans',
      votingShares: '$..VotingShares',
      totalNowOwned: '$..TotalNowOwned',
      totalToBeOwned: '$..TotalToBeOwned',
      totalAfterAcquisition: '$..TotalAfterAcquisition',
      totalPercentNowOwned: '$..TotalPercentNowOwned',
      totalPercentToBeOwned: '$..TotalPercentToBeOwned',
      totalPercentAfterAcquisition: '$..TotalPercentAfterAcquisition',
      valueInformation: '$..ValueInformation',
      acquisitionProposal: '$..AcquisitionProposal',
      totalPurchasePrice: '$..TotalPurchasePrice',
      totalSourceAmount: '$..TotalSourceAmount',
      fundsInformation: '$..FundsInformation',
      totalShareNowHold: '$..TotalShareNowHold',
      totalShareToBeSold: '$..TotalShareToBeSold',
      totalShareAfterCompletion: '$..TotalShareAfterCompletion',
      totalShareNowHoldPercent: '$..TotalShareNowHoldPercent',
      totalShareToBeSoldPercent: '$..TotalShareToBeSoldPercent',
      totalShareAfterCompletionPercent: '$..AcquiredEntityName',
      assistantPerson: '$..AssistantPerson',
      tenderOffer: '$..TenderOffer',
      relatedOfficers: '$..RelatedOfficers',
      nameAndLocation: '$..NameAndLocation',
      assistantPersonDocumentId: '$..AssistantPersonDocumentID',
      acquisitionRequestComments: '$..AcquisitionRequestComments',
      applicationNoticeDocument: {
        fileId: '$..ApplicationNoticeDocumentID',
        fileName: '$..ApplicationNoticeDocName'
      },
      assistantPersonDocument: {
        fileId: '$..AssistantPersonDocumentID',
        fileName: '$..AssistantPersonDocName'
      },
      publicationReqsDoc: {
        file: {
          fileId: '$..PublicationReqsDocID',
          fileName: '$..PublicationReqsDocName'
        },
        comments: '$..PublicationReqsComments'
      },
      bioFinReportsComments: '$..BioFinReportsComments',
      bioFinReportDocuments: [
        '$..DP_FilSection112BioFinDocument',
        { file: { fileId: '$.DocumentID', fileName: '$.DocumentName' } }
      ],
      hasFederalApplication: '$..HasFederalApplication',
      federalApplicationComments: '$..FederalApplicationComments',
      federalApplicationDocuments: [
        '$..DP_FilSection112FederalApplicationDocument',
        { file: { fileId: '$.DocumentID', fileName: '$.DocumentName' } }
      ],
      questionnaireADoc: {
        file: {
          fileId: '$..QuestionnaireADocID',
          fileName: '$..QuestionnaireADocName'
        },
        comments: '$..QuestionnaireAComments'
      },
      questionnaireBDoc: {
        file: {
          fileId: '$..QuestionnaireBDocID',
          fileName: '$..QuestionnaireBDocName'
        },
        comments: '$..QuestionnaireBComments'
      },
      acquisitionRequestDocuments: [
        '$..DP_FilSection112AcquisitionRequestDocument',
        { file: { fileId: '$.DocumentID', fileName: '$.DocumentName' } }
      ],
      pertinentDocuments: [
        '$..DP_FilSection112PertinentDocument',
        { file: { fileId: '$.DocumentID', fileName: '$.DocumentName' } }
      ],
      tenderOfferDocuments: [
        '$..DP_FilSection112TenderOfferDocument',
        { file: { fileId: '$.DocumentID', fileName: '$.DocumentName' } }
      ],
      fundDocuments: [
        '$..DP_FilSection112FundDocument',
        { file: { fileId: '$.DocumentID', fileName: '$.DocumentName' } }
      ],
      sharesRegistered: [
        '$..DP_FilSection112ShareRegistered',
        {
          id: '$..ID',
          filSection112Id: '$..FilSection112ID',
          name: '$..Name',
          nowOwned: '$..NowOwned',
          toBeOwned: '$..ToBeOwned',
          afterAcquisition: '$..AfterAcquisition'
        }
      ],
      purchasePrices: [
        '$..DP_FilSection112PurchasePrice',
        {
          id: '$..ID',
          filSection112Id: '$..FilSection112ID',
          name: '$..Name',
          purchasePrice: '$..PurchasePrice',
          sourceAmount: '$..SourceAmount'
        }
      ],
      shareInfos: [
        '$..DP_FilSection112ShareInfo',
        {
          id: '$..ID',
          filSection112Id: '$..FilSection112ID',
          name: '$..Name',
          shareNowHold: '$..ShareNowHold',
          shareToBeSold: '$..ShareToBeSold',
          shareAfterCompletion: '$..ShareAfterCompletion'
        }
      ],
      aquirerPositions: [
        '$..DP_FilSection112AcquirerPosition',
        {
          id: '$..ID',
          filSection112Id: '$..FilSection112ID',
          name: '$..Name',
          companyNameAddress: '$..CompanyNameAddress',
          positionDateAppointed: '$..PositionDateAppointed',
          ownershipPercent: '$..OwnershipPercent'
        }
      ],
      applicants: [
        '$..DP_FilSection112Applicant',
        {
          id: '$..ID',
          filSection112Id: '$..FilSection112ID',
          name: '$..Name',
          business: '$..Business',
          addressLine1: '$..AddressLine1',
          addressLine2: '$..AddressLine2',
          addressLine3: '$..AddressLine3',
          county: '$..County',
          city: '$..City',
          state: '$..State',
          postalCode: '$..PostalCode',
          country: '$..Country',
          phone: '$..Phone',
          email: '$..Email'
        }
      ],

      supportingDocuments: [
        '$..DP_FilingSupportingDocument',
        { file: { fileId: '$.DocumentID', fileName: '$.DocumentName' } }
      ]
    }
  })
    .done(function(response) {
      cleanUpResponse(response);
      deferred.resolve(response);
    })
    .fail(function(err) {
      deferred.reject();
    });

  return deferred.promise();
};
