var Handlebars = require("handlebars/dist/handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "Review Submission for "
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? depth0.filingDetails : depth0)) != null ? stack1.filingType : stack1), depth0));
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression;

  return alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? depth0.task : depth0)) != null ? stack1.taskType : stack1)) != null ? stack1.description : stack1), depth0))
    + " for "
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.filingDetails : depth0)) != null ? stack1.filingType : stack1), depth0));
},"5":function(container,depth0,helpers,partials,data) {
    return "Submission";
},"7":function(container,depth0,helpers,partials,data) {
    return "Filing";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.lambda, alias3=container.escapeExpression;

  return "\n\n<div class=\"page-title clearfix\">\n  <h1 class=\"pull-left m-n p-n\">"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.isSubmission : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data})) != null ? stack1 : "")
    + "</h1>\n  <div class=\"page-title-buttons-container\">\n    <a role=\"button\" class=\"btn btn-primary\"\n      href=\"#dep/entity/"
    + alias3(alias2(((stack1 = (depth0 != null ? depth0.filingDetails : depth0)) != null ? stack1.entityId : stack1), depth0))
    + "/filing/"
    + alias3(alias2(((stack1 = (depth0 != null ? depth0.filingDetails : depth0)) != null ? stack1.filingId : stack1), depth0))
    + "/dashboard\" target=\"_blank\">Open\n      "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.isSubmission : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.program(7, data, 0),"data":data})) != null ? stack1 : "")
    + "</a>\n  </div>\n</div>\n<div class=\"header-bar\"></div>\n<div class=\"breadcrumb-container\"></div>\n<div class=\"task-status\"></div>\n<div class=\"task-sections\"></div>";
},"useData":true});