/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Backbone = require('backbone');
var Marionette = require('backbone.marionette');
var Ladda = require('ladda');

var SideNavSectionView = require('common/views/SideNavSectionView');
var switchToPrint = require('common/util/switchToPrint');

var NonDepIndividualView = require('../../../common/views/NonDepIndividualView');

var LicenseDBAsSectionView = require('../../views/LicenseDBAsSectionView');
var LicenseContactsSectionView = require('../../views/LicenseContactsSectionView');
var SubmitCheckCasherBranchApplication = require('../../services/SubmitCheckCasherBranchApplication');
var CheckCasherGeneralDetailsSectionView = require('./CheckCasherBranchGeneralDetailsSectionView');
var CheckCasherBranchAffirmationSectionView = require('./CheckCasherBranchAffirmationSectionView');
var CheckCasherBranchRequirementsSectionView = require('./CheckCasherBranchRequirementsSectionView');

var tmplCheckCasherBranchApplicationView = require('./CheckCasherBranchApplicationView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplCheckCasherBranchApplicationView,

  className: 'check-casher-application-view m-b-md',

  behaviors: {
    ReadOnlyFormBehavior: {}
  },

  ui: {
    checkCasherBranchApplicationWrapper: '.check-casher-branch-application'
  },

  regions: {
    checkCasherBranchApplicationWrapper: '@ui.checkCasherBranchApplicationWrapper'
  },

  onBeforeShow: function() {
    var application = this.model.get('applicationContent') || {};
    var mode = this.options.mode;
    var isForPrinting = mode === 'print';
    var isExpandInternal = mode === 'int-expand';

    this.form = new SideNavSectionView({
      collection: new Backbone.Collection([
        {
          id: 'checkCasherBranchRequirements',
          title: 'Requirements',
          view: new CheckCasherBranchRequirementsSectionView({
            isReadOnly: this.options.isReadOnly
          })
        },
        {
          id: 'generalDetails',
          title: 'General Details',
          view: new CheckCasherGeneralDetailsSectionView({
            isReadOnly: this.options.isReadOnly,
            model: new Backbone.Model({
              application: application.application,
              foodEstablishmentDocument: application.foodEstablishmentDocument,
              publicEatingDocument: application.publicEatingDocument,
              zoningLawsDocument: application.zoningLawsDocument,
              municipalCountyOrdinanceDocument: application.municipalCountyOrdinanceDocument,
              retailFoodRegistrationDocument: application.retailFoodRegistrationDocument
            })
          })
        },
        {
          id: 'dbasSection',
          title: 'License D/B/As',
          view: new LicenseDBAsSectionView({
            model: new Backbone.Model({
              application: this.model.get('applicationContent').application,
              entityDBAs: this.model.get('entityDBAs'),
              applicationDBAs: this.model.get('applicationDBAs')
            })
          })
        },
        {
          id: 'branchManager',
          title: 'Branch Manager',
          view: new NonDepIndividualView({
            viewMode: 'ext',
            model: new Backbone.Model(application.branchManager)
          })
        },
        {
          id: 'contacts',
          title: 'Contacts',
          view: new LicenseContactsSectionView({
            isReadOnly: this.options.isReadOnly,
            model: new Backbone.Model({
              licenseContactTypes: this.model.get('licenseContactTypes'),
              requiredContactTypes: this.model.get('requiredLicenseContactTypes'),
              contacts: application.contacts,
              applicationId: application.applicationId,
              existingContacts: this.model.get('existingContacts')
            })
          })
        },
        {
          id: 'affirmation',
          title: 'Affirmation',
          view: new CheckCasherBranchAffirmationSectionView({
            mode: this.options.mode,
            model: new Backbone.Model({
              application: application.application
            })
          })
        }
      ]),
      useNavigationButtons: !this.options.isReadOnly,
      expanded: isForPrinting || isExpandInternal,
      contentWidth: 'medium',
      buttons: [
        {
          buttonText: 'Save',
          buttonClass: 'btn-default ladda-button',
          buttonIconClass: 'fa-save',
          labelClass: 'ladda-label',
          buttonAttrs: {
            'data-style': 'expand-right'
          },
          onClick: this.onClickSave.bind(this)
        }
      ]
    });

    if (
      this.options.isReadOnly &&
      !(this.options.mode === 'print' || this.options.mode === 'int' || this.options.mode === 'int-expand')
    ) {
      this.form.collection.remove(this.form.collection.get('affirmation'));
    }

    // If this isnt the new form, remove the save button
    if (this.options.mode !== 'new') {
      this.form.options.buttons.splice(0, 1);
    }

    this.showChildView('checkCasherBranchApplicationWrapper', this.form);
  },

  onClickSave: function(e) {
    var self = this;
    var l = Ladda.create(e.currentTarget);

    var applicationId = this.model.get('applicationId');

    l.start();
    this.form
      .validateAll({ type: 'save' })
      .done(function() {
        var formData = self.form.getFormData();
        formData.applicationId = applicationId;
        SubmitCheckCasherBranchApplication(formData)
          .done(function() {
            l.stop();
          })
          .fail(function() {
            l.stop();
          });
      })
      .fail(function() {
        l.stop();
      });
  },

  onAttach: function() {
    if (this.options.mode === 'print') {
      switchToPrint(this.$el, '.input-view');
    }
  }
});
