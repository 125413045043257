/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');

var cleanUpResponse = require('common/util/cleanUpResponse');

module.exports = function(objectTypeId) {
  var deferred = $.Deferred();

  $.soap({
    body: {
      GetAllLicenseAddressLookups: {
        $xmlns: 'http://dobs.pa.gov/sc/lookup/bpm/1.0'
      }
    },
    blueprintArrays: ['GetAllLicenseAddressLookupsResponse.AddressTypes.SC_LU_AddressType'],
    blueprint: {
      addressType: [
        '$..SC_LU_AddressType',
        {
          id: '$..ID',
          type: '$..Type'
        }
      ]
    }
  })
    .done(function(response) {
      cleanUpResponse(response);
      if (objectTypeId === 'INDIVIDUAL') {
        response.addressType = _.filter(response.addressType, function(type) {
          return type.id !== '5';
        });
      }
      deferred.resolve(response);
    })
    .fail(function(err) {
      deferred.reject();
    });

  return deferred.promise();
};
