/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Marionette = require('backbone.marionette');
var Syphon = require('backbone.syphon');
var Ladda = require('ladda');
var Radio = require('backbone.radio');

var NonDepInternalSidebarNavView = require('../../common/views/NonDepInternalSidebarNavView');
var BreadcrumbView = require('common/views/BreadcrumbView');

var NonDepCreateComplianceCaseRequestFormView = require('../../common/views/NonDepCreateComplianceCaseRequestFormView');

var SubmitComplianceCaseRequest = require('../services/SubmitComplianceCaseRequest');

require('../styles/NonDepCreateComplianceCaseRequestView.css');
var tmplNonDepCreateComplianceCaseRequestView = require('../templates/NonDepCreateComplianceCaseRequestView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplNonDepCreateComplianceCaseRequestView,

  className: 'non-dep-create-compliance-case-request-view container-fluid',

  ui: {
    sidebar: '.side-bar-container',
    breadcrumb: '.breadcrumb-container',
    createComplianceCaseForm: '.compliance-form-container',
    submit: '.btn-submit'
  },

  regions: {
    sidebar: '@ui.sidebar',
    breadcrumb: '@ui.breadcrumb',
    createComplianceCaseForm: '@ui.createComplianceCaseForm'
  },

  events: {
    'click @ui.entitySearchButton': 'onClickEntitySearchButton',
    'change @ui.existingLicenseCheckbox': 'onChangeExistingLicenseCheckbox',
    'click @ui.submit': 'onClickSubmit'
  },

  onBeforeShow: function() {
    this.showChildView('sidebar', new NonDepInternalSidebarNavView());

    this.showChildView(
      'breadcrumb',
      new BreadcrumbView({
        breadcrumbs: [
          {
            link: '#nondep/dashboard',
            text: 'Home'
          },
          {
            text: 'Create Compliance Case'
          }
        ]
      })
    );

    this.complianceForm = new NonDepCreateComplianceCaseRequestFormView({
      individualType: this.options.individualType,
      model: this.model
    });
    this.showChildView('createComplianceCaseForm', this.complianceForm);
  },

  onClickSubmit: function(e) {
    e.preventDefault();

    this.validate().done(
      function() {
        var laddaContext = Ladda.create(this.ui.submit.get(0));
        laddaContext.start();
        SubmitComplianceCaseRequest(Syphon.serialize(this))
          .done(function() {
            Radio.channel('navigate').trigger('show:int:nd:compliance');
          })
          .fail(function() {
            laddaContext.stop();
          });
      }.bind(this)
    );
  },

  validate: function() {
    return this.complianceForm.validate();
  }
});
