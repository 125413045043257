/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var Marionette = require('backbone.marionette');
var Syphon = require('backbone.syphon');
var Radio = require('backbone.radio');
var Ladda = require('ladda');

var modelGet = require('common/util/modelGet');
var toggleForm = require('common/util/toggleForm');
var BreadcrumbView = require('common/views/BreadcrumbView');

var SubmitEntityExamRating = require('../services/SubmitEntityExamRating');

var tmplExamRatingEditView = require('../templates/DepExamRatingEditView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplExamRatingEditView,

  className: 'dep-exam-rating-edit-view container-fluid m-b-lg',

  ui: {
    form: 'form',
    breadcrumb: '.breadcrumb-container',

    ssRating: '.ss-rating-container',
    itRating: '.it-rating-container',
    trustRating: '.trust-rating-container',
    complianceRating: '.compliance-rating-container',
    craRating: '.cra-rating-container',
    bhcRating: '.bhc-rating-container',
    ursitRating: '.ursit-rating-container',
    trustCompanyBSARating: '.trust-company-bsa-rating-container',

    submit: '.btn-submit'
  },

  regions: {
    breadcrumb: '@ui.breadcrumb'
  },

  events: {
    'click @ui.submit': 'onClickSubmit'
  },

  onBeforeShow: function() {
    var entityId = this.model.get('entityId');
    var entityName = this.model.get('entityName');
    var examId = modelGet(this.model, 'exam.id');
    var examType = modelGet(this.model, 'exam.examType');
    var leadAgency = modelGet(this.model, 'exam.leadAgency');
    var entityTypeId = this.model.get('entityTypeId');
    var entityCharterTypeId = this.model.get('entityCharterTypeId');
    var isTSP = entityTypeId === '3' || entityTypeId === '12' || entityTypeId === '23';

    this.showChildView(
      'breadcrumb',
      new BreadcrumbView({
        breadcrumbs: [
          {
            link: '#dep/dashboard',
            text: 'Home'
          },
          {
            link: '#dep/entity/' + entityId + '/dashboard',
            text: entityName
          },
          {
            link: '#dep/entity/' + entityId + '/exam/' + examId + '/dashboard',
            text: examType + ' - ' + leadAgency
          },
          {
            text: 'Edit Rating'
          }
        ]
      })
    );

    var examAreaIds = _.pluck(modelGet(this.model, 'exam.examAreas', []), 'id');

    if (isTSP) {
      // Show only URSIT when entity is TSP
      toggleForm(this.ui.ursitRating, true);
      toggleForm(this.ui.ssRating, false);
      toggleForm(this.ui.itRating, false);
      toggleForm(this.ui.trustRating, false);
      toggleForm(this.ui.complianceRating, false);
      toggleForm(this.ui.craRating, false);
      toggleForm(this.ui.bhcRating, false);
    } else {
      // Hide URSIT ratings for all other types
      toggleForm(this.ui.ursitRating, false);

      // Show S&S rating Section
      var ssAreaIds = ['1', '11'];
      toggleForm(this.ui.ssRating, _.intersection(ssAreaIds, examAreaIds).length > 0);

      // Show IT rating Section
      var itAreaIds = ['3', '12', '22'];
      toggleForm(this.ui.itRating, _.intersection(itAreaIds, examAreaIds).length > 0);

      // Show Trust rating Section
      var trustAreaIds = ['2', '21'];
      toggleForm(this.ui.trustRating, _.intersection(trustAreaIds, examAreaIds).length > 0);

      // Show Compliance rating Section
      var complianceAreaIds = ['4', '13'];
      toggleForm(this.ui.complianceRating, _.intersection(complianceAreaIds, examAreaIds).length > 0);

      // Show CRA rating Section
      var craAreaIds = ['5'];
      toggleForm(this.ui.craRating, _.intersection(craAreaIds, examAreaIds).length > 0);

      // Show BHC rating Section
      toggleForm(this.ui.bhcRating, modelGet(this.model, 'exam.isHoldingCompanyExam') === '1');
    }

    //Show the trust BSA rating for Trust Company charter type only
    toggleForm(this.ui.trustCompanyBSARating, entityCharterTypeId === '21');

    Syphon.deserialize(this, this.model.get('exam'));
  },

  validate: function() {
    return this.ui.form.parsley().whenValidate();
  },

  getFormData: function() {
    return Syphon.serialize(this.ui.form);
  },

  onClickSubmit: function(e) {
    e.preventDefault();

    var l = Ladda.create(this.ui.submit[0]);
    l.start();
    this.validate()
      .done(
        function() {
          var formData = this.getFormData(),
            entityId = this.model.get('entityId');

          formData.id = modelGet(this.model, 'exam.id');

          SubmitEntityExamRating(formData)
            .done(function(examId) {
              Radio.channel('navigate').trigger('show:int:dp:exam', entityId, examId);
            })
            .fail(function(err) {
              l.stop();
            });
        }.bind(this)
      )
      .fail(function() {
        l.stop();
      });
  }
});
