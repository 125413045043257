/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Backbone = require('backbone');
var NProgress = require('nprogress');

var GetUsersForRole = require('common/services/GetUsersForRole');

var GetEntityIdentifiers = require('../../../common/services/GetEntityIdentifiers');
var GetExamDetails = require('../../../exam-dashboard/services/GetExamDetails');
var GetAllExamsLookups = require('../../../exam-dashboard/services/GetAllExamsLookups');
var GetExamReviewRolesByExam = require('../../../exam-dashboard/services/GetExamReviewRolesByExam');

var DepReviewExamTaskView = require('./DepReviewExamTaskView');

module.exports = {
  event: 'build:dp:review-exam',

  channel: 'task',

  builder: function(task) {
    var defer = $.Deferred();
    var examId = task.taskData.DP.dpExamId;
    var entityId = task.taskData.DP.dpEntityId;
    GetEntityIdentifiers(entityId)
      .done(function(entityIdentifiers) {
        $.when(
          GetExamDetails(examId),
          GetUsersForRole('DP - User'),
          GetAllExamsLookups(entityIdentifiers.categoryId, entityIdentifiers.entityTypeId),
          GetExamReviewRolesByExam(examId)
        )
          .done(function(details, examReviewers, lookups, reviewRoles) {
            NProgress.set(0.85);
            defer.resolve(
              new DepReviewExamTaskView({
                model: new Backbone.Model({
                  examDetails: details,
                  task: task,
                  taskId: task.taskId,
                  examId: examId,
                  examReviewers: examReviewers,
                  examActions: lookups.examActions,
                  allReviewRoles: lookups.examReviewRoles,
                  reviewRoles: reviewRoles
                })
              })
            );
          })
          .fail(function(err) {
            defer.reject(err);
          });
      })
      .fail(function(err) {
        defer.reject(err);
      });

    return defer.promise();
  }
};
