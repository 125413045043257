/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Marionette = require('backbone.marionette');

var modelGet = require('common/util/modelGet');

var tmplDepFilingProcessingView = require('../templates/DepFilingProcessingView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplDepFilingProcessingView,

  className: 'dep-filing-processing-view',

  templateHelpers: function() {
    return {
      isCU: modelGet(this.model, 'entityIdentifiers.categoryId') === '2',
      isTrust: modelGet(this.model, 'entityIdentifiers.categoryId') === '3'
    };
  }
});
