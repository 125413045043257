/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Marionette = require('backbone.marionette');
var Ladda = require('ladda');
var Radio = require('backbone.radio');
var _ = require('underscore');

var BreadcrumbView = require('common/views/BreadcrumbView');

var SubmitAuditSubmission = require('../services/SubmitAuditSubmission');

// var DepExtInitiateAuditFormView = require('../../audit/views/DepExtInitiateAuditFormView');
var DepFilingPointOfContactSectionView = require('../../filing-forms/views/DepFilingPointOfContactSectionView');
var DepSubmitAuditSubmissionSectionView = require('../../filing-forms/forms/AuditSubmissionFiling/DepSubmitAuditSubmissionSectionView');
var tmplDepExtEntityInitiateAuditView = require('../templates/DepExtEntityInitiateAuditView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplDepExtEntityInitiateAuditView,

  className: 'dep-ext-entity-initiate-audit-view container-fluid',

  ui: {
    headerBar: '.header-bar',
    breadcrumb: '.breadcrumb-container',
    pointOfContact: '.point-of-contact',
    submit: '.submit',
    auditSubmission: '.audit-submission'
  },

  regions: {
    headerBar: '@ui.headerBar',
    breadcrumb: '@ui.breadcrumb',
    auditSubmissionContainer: '@ui.auditSubmission',
    pointOfContactContainer: '@ui.pointOfContact'
  },

  events: {
    'click @ui.submit': 'onClickSubmit'
  },

  onBeforeShow: function() {
    var auditTypes = this.model.get('auditTypes');
    var entityDetails = this.model.get('entityDetails');
    var trustFunds = this.model.get('trustFunds');
    var submissionContent = trustFunds;

    this.showChildView(
      'breadcrumb',
      new BreadcrumbView({
        breadcrumbs: [
          {
            link: '#ext/dep/dashboard',
            text: 'Home'
          },
          {
            text: entityDetails.entityName,
            link: '#ext/dep/entity/' + entityDetails.entityId + '/dashboard'
          },
          {
            text: 'Initiate Audit'
          }
        ]
      })
    );

    this.pointOfContactSection = new DepFilingPointOfContactSectionView({
      mode: this.options.mode,
      isReadOnly: this.options.isReadOnly,
      hasAddress: false,
      isSubmission: true,
      model: new Backbone.Model({
        auditTypes: auditTypes
      })
    });

    this.showChildView('pointOfContactContainer', this.pointOfContactSection);

    this.auditSubmissionSection = new DepSubmitAuditSubmissionSectionView({
      mode: this.options.mode,
      isReadOnly: this.options.isReadOnly,
      model: new Backbone.Model({
        auditTypes: auditTypes,
        filingDetails: entityDetails,
        filingContent: submissionContent
      })
    });

    this.showChildView('auditSubmissionContainer', this.auditSubmissionSection);
  },

  onClickSubmit: function(e) {
    e.preventDefault();
    var self = this;
    var entityId = this.model.get('entityId');
    var entityCategoryId = this.model.get('entityDetails').entityCategoryId;
    this.validate().done(function() {
      var l = Ladda.create(self.ui.submit[0]);
      l.start();
      var pointOfContact = self.pointOfContactSection.getFormData();
      var auditSubmission = self.auditSubmissionSection.getFormData();
      var formData = _.extend(pointOfContact, auditSubmission);
      formData;
      formData.entityId = entityId;
      // if entity category is trust then submission type is trust audit submission
      formData.submissionTypeId = entityCategoryId === 2 ? 200 : 100;

      SubmitAuditSubmission(formData)
        .done(function() {
          Radio.channel('navigate').trigger('show:ext:dp:entity-dashboard', entityId);
        })
        .fail(function() {
          l.stop();
        });
    });
  },

  validate: function(e) {
    var validationPromises = [this.pointOfContactSection.validate([]), this.auditSubmissionSection.validate([])];
    return $.when.apply($, validationPromises);
  }
});
