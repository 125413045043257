/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var Backbone = require('backbone');
var Marionette = require('backbone.marionette');
var Syphon = require('backbone.syphon');
var Ladda = require('ladda');
var Radio = require('backbone.radio');

var BreadcrumbView = require('../../../common/views/BreadcrumbView');
var HeaderBarView = require('common/views/HeaderBarView');

var SubmitComplianceGeneralInformation = require('../services/SubmitComplianceGeneralInformation');

var tmplNonDepInternalComplianceEditGeneralInformationView = require('../templates/NonDepInternalComplianceEditGeneralInformationView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplNonDepInternalComplianceEditGeneralInformationView,

  className: 'non-dep-compliance-edit-general-information-view container-fluid',

  ui: {
    breadcrumb: '.breadcrumb-container',
    headerBar: '.header-bar',
    editGeneralInfoForm: '.edit-general-info-form',
    violationsSelect: '.violations-select',
    dateNegotiated: '.date-negotiated',
    dateToInstitution: '.date-to-institution',
    dateToLegal: '.date-to-legal',
    dateFullyExecuted: '.date-fully-executed',
    finePaidInFullDate: '.date-fine-paid-in-full',
    firstPaymentDate: '.date-first-payment',
    lastPaymentDate: '.date-last-payment',
    finePaidAckLetterSentDate: '.fine-paid-ack-letter-sent',
    submit: '.btn-submit'
  },

  regions: {
    headerBar: '@ui.headerBar',
    breadcrumb: '@ui.breadcrumb'
  },

  events: {
    'click @ui.submit': 'onClickSubmit'
  },

  onBeforeShow: function() {
    Syphon.deserialize(this, this.model.get('case'));

    // Datepickers
    this.ui.dateNegotiated.datepicker();
    this.ui.dateToInstitution.datepicker();
    this.ui.dateToLegal.datepicker();
    this.ui.dateFullyExecuted.datepicker();
    this.ui.finePaidInFullDate.datepicker();
    this.ui.firstPaymentDate.datepicker();
    this.ui.lastPaymentDate.datepicker();
    this.ui.finePaidAckLetterSentDate.datepicker();

    var headerData = {
      title: 'Compliance',
      caseNumber: this.model.get('case').caseNumber,
      tin: this.model.get('case').tin,
      licenseNumber: this.model.get('case').licenseNumber
    };

    this.showChildView(
      'headerBar',
      new HeaderBarView({
        title: 'Compliance',
        columns: new Backbone.Collection([
          {
            name: 'caseNumber',
            label: 'Case Number'
          },
          {
            name: 'tin',
            label: 'Federal ID Number'
          },
          {
            name: 'licenseNumber',
            label: 'License Number'
          }
        ]),
        model: new Backbone.Model(headerData)
      })
    );

    this.showChildView(
      'breadcrumb',
      new BreadcrumbView({
        breadcrumbs: [
          {
            link: '#nondep/dashboard',
            text: 'Home'
          },
          {
            text: this.model.get('case').entityName,
            link: '#nondep/entity/' + this.model.get('case').entityId + '/dashboard'
          },
          {
            text: 'Case #' + this.model.get('case').caseNumber,
            link: '#nondep/compliance/case/' + this.model.get('case').complianceId
          },
          {
            text: 'Edit General Information'
          }
        ]
      })
    );

    // Set the value of the Compliance Violations field manually because
    // Backbone.syphon cannot deserialize into a multiselect
    this.ui.violationsSelect.select2();
    var complianceViolationIds = _.reduce(
      this.model.get('case').complianceViolations,
      function(memo, violation) {
        memo.push(violation.complianceViolationTypeId);
        return memo;
      },
      []
    );
    this.ui.violationsSelect.val(complianceViolationIds).trigger('change');
  },

  onClickSubmit: function(e) {
    var self = this;
    var laddaContext = Ladda.create(this.ui.submit.get(0));
    laddaContext.start();
    e.preventDefault();
    this.ui.editGeneralInfoForm
      .parsley()
      .whenValidate()
      .done(function() {
        var formData = self.getFormData();
        formData.complianceId = self.model.get('case').complianceId;
        SubmitComplianceGeneralInformation(formData)
          .done(function() {
            Radio.channel('navigate').trigger('show:int:nd:compliance:dashboard', self.model.get('case').complianceId);
          })
          .fail(function() {
            laddaContext.stop();
          });
      })
      .fail(function() {
        laddaContext.stop();
      });
  },

  getFormData: function() {
    return Syphon.serialize(this);
  }
});
