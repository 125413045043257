/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');

var cleanUpResponse = require('common/util/cleanUpResponse');

module.exports = function(filingId) {
  var deferred = $.Deferred();

  $.soap({
    body: {
      GetFilingInvoice: {
        $xmlns: 'http://dobs.pa.gov/dp/filing/bpm/1.0',
        FilingID: filingId
      }
    },
    blueprintArrays: ['GetFilingInvoiceResponse.InvoiceItems.InvoiceItem'],
    blueprint: {
      invoiceItems: [
        '$..InvoiceItem',
        { description: '$..Description', quantity: '$..Quantity', unitPrice: '$..UnitPrice' }
      ]
    }
  })
    .done(function(response) {
      cleanUpResponse(response);
      _.each(response.invoiceItems, function(invoiceItem) {
        invoiceItem.quantity = parseInt(invoiceItem.quantity);
        invoiceItem.unitPrice = parseFloat(invoiceItem.unitPrice);
      });
      deferred.resolve(response.invoiceItems);
    })
    .fail(function(err) {
      deferred.reject();
    });

  return deferred.promise();
};
