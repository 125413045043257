/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var cleanUpResponse = require('common/util/cleanUpResponse');

module.exports = function(licenseId, isEdit) {
  var deferred = $.Deferred();

  $.soap({
    body: {
      GetLicenseDBA: {
        $xmlns: 'http://dobs.pa.gov/nd/license/bpm/1.0',
        LicenseID: licenseId,
        IsEdit: isEdit
      }
    },
    blueprintArrays: ['GetLicenseDBAResponse.ND_LicenseDBA'],
    blueprint: {
      dbas: [
        '$..ND_LicenseDBA',
        {
          id: '$..ID',
          dbaName: '$..DBA',
          entityDBAId: '$..EntityDBAID',
          cancelledDate: '$..CancelledDate',
          fictitiousNameDocument: {
            fileId: '$..FictitiousDocumentID',
            fileName: '$..FictitiousDocumentName'
          }
        }
      ]
    }
  })
    .done(function(response) {
      cleanUpResponse(response);
      deferred.resolve(response.dbas);
    })
    .fail(function(err) {
      deferred.reject(err);
    });

  return deferred.promise();
};
