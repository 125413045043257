/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Backbone = require('backbone');
var Marionette = require('backbone.marionette');
var Ladda = require('ladda');
var Syphon = require('backbone.syphon');

var SubmitTimesheet = require('../../common/services/SubmitTimesheet');
var TimeTrkTotalsView = require('../../common/views/TimeTrkTotalsView');
var TimeTrkSecEntryRowCollectionView = require('./TimeTrkSecEntryRowCollectionView');

var tmplTimeTrkSecExaminerEntryView = require('../templates/TimeTrkSecExaminerEntryView.hbs');

require('../styles/TimeTrkSecExaminerEntryView.css');
module.exports = Marionette.LayoutView.extend({
  template: tmplTimeTrkSecExaminerEntryView,

  className: 'time-trk-sec-examiner-entry-view',

  ui: {
    addEntry: '.add-entry',
    timeEntriesContainer: '.time-entries-container',
    timeTotalsContainer: '.time-totals-container',
    save: '.save'
  },

  regions: {
    timeEntriesContainer: '@ui.timeEntriesContainer',
    timeTotalsContainer: '@ui.timeTotalsContainer'
  },

  events: {
    'click @ui.addEntry': 'onClickAddEntry',
    'click @ui.save': 'onClickSave'
  },

  childEvents: {
    'update:hour-totals': 'updateHourTotals'
  },

  onBeforeShow: function() {
    var timesheet = this.model.get('timesheet') || {};
    var timesheetEntries = timesheet.timesheetEntries || {};

    Syphon.deserialize(this, timesheet);

    this.timeEntries = new TimeTrkSecEntryRowCollectionView({
      collection: new Backbone.Collection(timesheetEntries),
      model: this.model,
      isReadOnly: this.options.isReadOnly
    });
    this.showChildView('timeEntriesContainer', this.timeEntries);

    this.updateHourTotals();
  },

  onClickAddEntry: function(e) {
    e.preventDefault();
    this.timeEntries.collection.add({});
  },

  updateHourTotals: function() {
    this.model.set('timeEntries', this.timeEntries.getTimeEntries());

    this.timeTotals = new TimeTrkTotalsView({
      model: this.model,
      submitAttempted: this.model.get('submitAttempted')
    });

    this.showChildView('timeTotalsContainer', this.timeTotals);
  },

  onClickSave: function(e) {
    e.preventDefault();
    var self = this;
    this.model.set('submitAttempted', true);

    this.validate().done(function() {
      var laddaContext = Ladda.create(self.ui.save.get(0));
      laddaContext.start();

      var formData = self.getFormData();

      SubmitTimesheet(formData)
        .done(function() {
          laddaContext.stop();
        })
        .fail(function() {
          laddaContext.stop();
        });
    });
  },

  validate: function() {
    var validationArray = [this.ui.timeEntriesContainer.parsley().whenValidate(), this.timeTotals.validateTotalTimes()];
    return $.when.apply($, validationArray);
    // return this.ui.timeEntriesContainer.parsley().whenValidate();
  },

  getFormData: function() {
    var formData = Syphon.serialize(this);

    formData.timesheetId = this.model.get('timesheetId');
    formData.timesheetEntries = this.timeEntries.getFormData();
    formData.statusId = '1';

    return formData;
  }
});
