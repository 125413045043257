/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var moment = require('moment');

var formatAsDollars = require('common/util/formatAsDollars');
var cleanUpResponse = require('common/util/cleanUpResponse');

module.exports = function(examPaymentId) {
  var defer = $.Deferred();

  $.soap({
    body: {
      GetExamPayment: {
        $xmlns: 'http://dobs.pa.gov/nd/exam/bpm/1.0',
        ExamPaymentID: examPaymentId
      }
    },
    blueprint: {
      examPaymentId: '$..ID',
      processInstanceId: '$..ProcessInstanceID',
      examId: '$..ExamID',
      description: '$..Description',
      dueDate: '$..DueDate',
      amount: '$..AmountDue',
      feeAmount: '$..FeeAmount',
      isDeleted: '$..IsDeleted',
      statusId: '$..StatusID',
      paidOnDate: '$..PaidOnDate',
      paidBy: '$..PaidBy',
      modifiedBy: '$..ModifiedBy',
      modifiedDate: '$..ModifiedDate',
      createdBy: '$..CreatedBy',
      createdDate: '$..CreatedDate',
      paymentStatus: '$..PaymentStatus'
    }
  })
    .done(function(response) {
      cleanUpResponse(response);

      var dueDate = moment(response.dueDate, 'YYYY-MM-DD');

      response.dueDate = dueDate.format('MM/DD/YYYY');
      response.daysPastDue = moment().diff(dueDate, 'day');

      response.amountDisplay = formatAsDollars(parseFloat(response.amount));
      response.feeAmountDisplay = formatAsDollars(parseFloat(response.feeAmount));

      response.isDeleted = response.isDeleted === '1';

      defer.resolve(response);
    })
    .fail(function(err) {
      defer.reject(err);
    });

  return defer.promise();
};
