var Handlebars = require("handlebars/dist/handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "            <div class=\"checkbox\">\n              <label>\n                <input type=\"checkbox\" class=\"license-to-surrender\" name=\"branchLicense\" id="
    + alias4(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + " value="
    + alias4(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\n                  data-parsley-multiple=\"license-checkbox\">License #\n                "
    + alias4(((helper = (helper = helpers.licenseNumber || (depth0 != null ? depth0.licenseNumber : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"licenseNumber","hash":{},"data":data}) : helper)))
    + " - "
    + alias4(((helper = (helper = helpers.AddressLine1 || (depth0 != null ? depth0.AddressLine1 : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"AddressLine1","hash":{},"data":data}) : helper)))
    + "\n              </label>\n            </div>\n";
},"3":function(container,depth0,helpers,partials,data) {
    return "      <div class=\"form-group required\">\n        <label class=\"control-label col-sm-3\">Affirmation</label>\n        <label class=\"control-label font-weight-normal text-left col-sm-9\">\n          <input type=\"checkbox\" name=\"isCertified\" value=\"1\" data-parsley-required=\"true\"\n            data-parsley-errors-container=\".affirmation-error-container\" />\n          I understand by submitting this Surrender License Application: I am agreeing to be bound by the\n          following\n          declaration: \"I declare that all of my answers on this License Application are complete, true and correct. I\n          make this declaration subject to the penalties of 18 PA.C.S. § 4904 relating to unsworn falsification to\n          authorities.\"\n        </label>\n        <div class=\"col-sm-offset-3 col-sm-9\">\n          <div class=\"affirmation-error-container\"></div>\n        </div>\n      </div>\n      <div class=\"form-group required\">\n        <div class=\"col-sm-offset-3 col-sm-9\">\n          <label><strong>Enter Name Below</strong></label>\n          <input type=\"text\" class=\"form-control\" name=\"certificationName\" data-parsley-required=\"true\"\n            data-parsley-maxlength=\"100\" />\n        </div>\n      </div>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression, alias5=container.lambda;

  return "\n<div class=\"col-sm-8 col-sm-offset-1 m-b-lg\">\n  <div class=\"form-horizontal\">\n    <form class=\"license-surrender-form\">\n      <div class=\"form-group required\">\n        <label class=\"control-label col-sm-3\">Effective Date of Surrender</label>\n        <div class=\"col-sm-4\">\n          <div class=\"input-group\">\n            <input type=\"text\" name=\"surrenderDate\" class=\"form-control datepicker surrender-date\"\n              placeholder=\"Date of Surrender\" data-parsley-required=\"true\"\n              data-parsley-date=\"\" data-parsley-trigger-after-failure=\"changeDate\" \n              data-parsley-errors-container=\"#dateSurrenderedErrorContainer\"/>\n            <div class=\"input-group-addon\">\n              <i class=\"fa fa-calendar\"></i>\n            </div>\n          </div>\n          <div class=\"dateSurrenderedErrorContainer\"></div>\n        </div>\n      </div>\n      <div class=\"form-group required surrenderReason\">\n        <label class=\"control-label col-sm-3\">Reason for license surrender</label>\n        <div class=\"col-sm-9\">\n          <textarea name=\"reason\" class=\"form-control input-view\" data-parsley-required=\"true\"\n            placeholder=\"Please provide reason for license surrender.\" rows=\"6\">"
    + alias4(((helper = (helper = helpers.reason || (depth0 != null ? depth0.reason : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"reason","hash":{},"data":data}) : helper)))
    + "</textarea>\n          <div class=\"pre-wrap\" id=\"reasonPrintView\"></div>\n        </div>\n      </div>\n\n      <div class=\"form-group\">\n        <label class=\"control-label col-sm-3\">Upload attachments</label>\n        <p class=\"col-sm-9 file-upload-instruction\">Please Attach the following information on the status of each loan\n          to Pennsylvania consumers that are outstanding:<br />\n          &#8226; Name of Consumer <br />\n          &#8226; Consumer Address and telephone number <br />\n          &#8226; Current Application Status <br />\n          &#8226; Loan Number <br />\n          &#8226; Amount of Loan <br />\n          &#8226; Contact information for applicable lender with who each loan will be placed <br />\n          &#8226; Date loan will be resolved <br />\n        </p>\n        <div class=\"file-upload-container col-sm-9 col-sm-offset-3\">\n        </div>\n        <div class=\"col-sm-9 col-sm-offset-3 file-upload-error-container help-block collapse\">\n          <span>At least one attachment is required.</span>\n        </div>\n      </div>\n      <div class=\"form-group required\">\n        <label class=\"control-label col-sm-3\">Licenses to Surrender:</label>\n        <div class=\"col-sm-9 license-list\">\n          <div class=\"checkbox\">\n            <label>\n              <input type=\"checkbox\" class=\"license-to-surrender\" name=\"principleLicense\" id=\""
    + alias4(((helper = (helper = helpers.licenseId || (depth0 != null ? depth0.licenseId : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"licenseId","hash":{},"data":data}) : helper)))
    + "\"\n                value=\""
    + alias4(((helper = (helper = helpers.licenseId || (depth0 != null ? depth0.licenseId : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"licenseId","hash":{},"data":data}) : helper)))
    + "\" data-parsley-multiple=\"license-checkbox\" data-parsley-required=\"true\"\n                data-parsley-errors-container=\"#license-checkbox-error-container\"\n                data-parsley-error-message=\"At least one license must be selected.\">License #\n              "
    + alias4(alias5(((stack1 = (depth0 != null ? depth0.licenseDashboard : depth0)) != null ? stack1.licenseNumber : stack1), depth0))
    + " -\n              "
    + alias4(alias5(((stack1 = ((stack1 = (depth0 != null ? depth0.licenseDashboard : depth0)) != null ? stack1.licenseAddress : stack1)) != null ? stack1.addressLine1 : stack1), depth0))
    + "\n            </label>\n          </div>\n          <div class=\"branch-license-container\">\n            <h4 class=\"m-b-n m-t-md\">Branch License(s)</h4>\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.childLicenses : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "          </div>\n          <span id=\"license-checkbox-error-container\"></span>\n        </div>\n      </div>\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.showAffirmation : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "    </form>\n    <div class=\"row\">\n      <div class=\"btn-container col-sm-9 col-sm-offset-3\">\n        <a href=\"#ext/nondep/entity/"
    + alias4(((helper = (helper = helpers.entityId || (depth0 != null ? depth0.entityId : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"entityId","hash":{},"data":data}) : helper)))
    + "/license/"
    + alias4(((helper = (helper = helpers.licenseId || (depth0 != null ? depth0.licenseId : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"licenseId","hash":{},"data":data}) : helper)))
    + "\" class=\"btn btn-default cancel-button\"\n          role=\"button\">Cancel</a>\n        <button class=\"btn btn-primary submit-button ladda-button\" data-style=\"expand-right\" type=\"button\">\n          <spanclass=\"ladda-label\">Submit</span>\n        </button>\n      </div>\n    </div>\n  </div>\n</div>";
},"useData":true});