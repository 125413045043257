var Handlebars = require("handlebars/dist/handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.escapeExpression, alias2=depth0 != null ? depth0 : (container.nullContext || {}), alias3=helpers.helperMissing, alias4="function";

  return "\n<div class=\"title-bar clearfix\">\n  <h1 class=\"page-title\">"
    + alias1(container.lambda(((stack1 = (depth0 != null ? depth0.licenseIdentifiers : depth0)) != null ? stack1.entityName : stack1), depth0))
    + "</h1>\n</div>\n<div class=\"header-bar-container\"></div>\n<div class=\"breadcrumb-container\"></div>\n<h2 class=\"m-b-md m-t-n\">Edit General Information</h2>\n<div class=\"row\">\n  <div class=\"col-sm-8 col-sm-offset-2 m-b-lg\">\n    <h3 class=\"m-b-md m-t-n\">Individual</h3>\n    <form class=\"non-dep-new-license-office-manager-container\"></form>\n    <a href=\"#nondep/entity/"
    + alias1(((helper = (helper = helpers.entityId || (depth0 != null ? depth0.entityId : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias2,{"name":"entityId","hash":{},"data":data}) : helper)))
    + "/license/"
    + alias1(((helper = (helper = helpers.licenseId || (depth0 != null ? depth0.licenseId : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias2,{"name":"licenseId","hash":{},"data":data}) : helper)))
    + "\" class=\"btn btn-default cancel-button\"\n      role=\"button\">Cancel</a>\n    <button class=\"btn btn-primary submit-button ladda-button\" data-style=\"expand-right\" type=\"button\"><span\n        class=\"ladda-label\">Submit</span></button>\n  </div>\n</div>";
},"useData":true});