/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Backbone = require('backbone');
var Marionette = require('backbone.marionette');
var Syphon = require('backbone.syphon');
var bodybuilder = require('bodybuilder');
var Ladda = require('ladda');

var GridView = require('psa-marionette/views/GridView');
var PageableCollection = require('psa-backbone/models/PageableCollection');

var ButtonLinkCell = require('common/backgrid/ButtonLinkCell');
var MomentCell = require('common/backgrid/MomentCell');
var ElasticSearchSummaryView = require('common/views/ElasticSearchSummaryView');

var CorpFinInternalSidebarNavView = require('../../common/views/CorpFinInternalSidebarNavView');
var SearchCorpFinRegistrations = require('../services/SearchCorpFinRegistrations');

require('../styles/CorpFinRegistrationSearchView.css');
var tmplCorpFinRegistrationSearchView = require('../templates/CorpFinRegistrationSearchView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplCorpFinRegistrationSearchView,

  className: 'corp-fin-registration-search-view container-fluid',

  ui: {
    sideBar: '.side-bar-container',
    searchResultsContainer: '.search-results-container',
    search: '.search',
    clearFilters: '.clear-filters',
    formInputs: ':input',
    searchSummary: '.search-summary'
  },

  regions: {
    sideBar: '@ui.sideBar',
    searchResultsContainer: '@ui.searchResultsContainer',
    searchSummary: '@ui.searchSummary'
  },

  events: {
    'click @ui.search': 'onClickSearch',
    'click @ui.clearFilters': 'onClickClearFilters',
    'keydown @ui.formInputs': 'onKeydownOfInputs'
  },

  onBeforeShow: function() {
    this.showChildView('sideBar', new CorpFinInternalSidebarNavView());

    this.searchSummaryView = new ElasticSearchSummaryView({
      model: new Backbone.Model({
        searchLimit: this.model.get('searchLimit')
      })
    });
    this.showChildView('searchSummary', this.searchSummaryView);

    this.searchResults = new GridView({
      columns: [
        {
          name: 'issuerName',
          label: 'Issuer Name',
          cell: 'string',
          headerCell: 'custom',
          width: 23,
          editable: false
        },
        {
          name: 'fileNumber',
          label: 'File Number',
          cell: 'string',
          headerCell: 'custom',
          width: 23,
          editable: false
        },
        {
          name: 'filedDate',
          label: 'Date Filed',
          cell: MomentCell.MomentCell,
          formatter: MomentCell.MomentDateFormatter.extend({
            emptyValue: '--'
          }),
          sortValue: MomentCell.MomentSortValue,
          headerCell: 'custom',
          width: 23,
          editable: false
        },
        {
          name: 'dispositionDate',
          label: 'Disposition Date',
          cell: MomentCell.MomentCell,
          formatter: MomentCell.MomentDateFormatter.extend({
            emptyValue: '--'
          }),
          sortValue: MomentCell.MomentSortValue,
          headerCell: 'custom',
          width: 23,
          editable: false
        },
        {
          name: '',
          label: '',
          cell: ButtonLinkCell.extend({
            name: 'Open',
            newTab: true,
            buttonClass: 'btn-primary btn-sm',
            href: function(model) {
              var registrationId = model.get('registrationId');
              return '#corpfin/registration/' + registrationId + '/dashboard';
            }
          }),
          headerCell: 'custom',
          sortable: false,
          editable: false,
          width: 8
        }
      ],
      collection: new PageableCollection([], { pageSize: 25 }),
      emptyText: 'No Registrations Found'
    });

    this.showChildView('searchResultsContainer', this.searchResults);

    this.$('.backgrid.table').addClass('table-bordered');
  },

  onKeydownOfInputs: function(e) {
    // Searches on press of enter
    if (e.keyCode === 13) {
      this.onClickSearch(e);
    }
  },

  onClickSearch: function() {
    var self = this;
    var data = Syphon.serialize(this, {
      inputReaders: Syphon.SearchInputReaderSet
    });
    // If no inputs were given, do not perform a search.
    if (!data.issuerName && !data.statusId && !data.typeId && !data.fileNumber) {
      return;
    }
    this.searchSummaryView.reset();

    var l = Ladda.create(this.ui.search[0]);
    l.start();
    var body = bodybuilder();

    if (data.issuerName) {
      body.query('bool', function(b) {
        b.orQuery('match', 'issuerName', {
          query: data.issuerName,
          fuzziness: 'AUTO',
          analyzer: 'standard'
        });
        b.orQuery('wildcard', 'issuerName', data.issuerName.toLowerCase() + '*');
        b.orQuery('match', 'issuerName.phonetic', data.issuerName);
        return b;
      });
    }

    if (data.statusId) {
      body.query('term', 'statusId', data.statusId);
    }

    if (data.typeId) {
      body.query('term', 'typeId', data.typeId);
    }

    if (data.fileNumber) {
      body.query('wildcard', 'fileNumber', data.fileNumber + '*');
    }

    body.notQuery('term', 'isDeleted', '1');

    body.size(this.model.get('searchLimit'));
    var bodyObj = body.build();
    // Keep for testing
    // console.log(JSON.stringify(bodyObj, null, 2));
    SearchCorpFinRegistrations(bodyObj)
      .done(function(data) {
        l.stop();
        self.searchResults.collection.fullCollection.reset(data.hits);
        self.searchSummaryView.display(data);
      })
      .fail(function(err) {
        l.stop();
      });
  },

  onClickClearFilters: function() {
    this.ui.formInputs.val('');
  }
});
