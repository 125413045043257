/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Marionette = require('backbone.marionette');
var Radio = require('backbone.radio');

var PaymentInvoiceView = require('common/views/PaymentInvoiceView');
var StartReviewProcess = require('../../../common/services/StartReviewProcess');

var tmplSubmitApplicationPaymentView = require('./SubmitApplicationPaymentView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplSubmitApplicationPaymentView,

  className: 'submit-application-payment-view',

  ui: {
    paymentInvoice: '.payment-invoice'
  },

  regions: {
    paymentInvoice: '@ui.paymentInvoice'
  },

  onBeforeShow: function() {
    var self = this;
    this.showChildView(
      'paymentInvoice',
      new PaymentInvoiceView({
        model: this.model,
        handleZeroAmount: function() {
          return StartReviewProcess({
            reviewObjectType: self.model.get('paymentObjectType'),
            reviewObjectId: self.model.get('paymentObjectId'),
            taskId: self.model.get('taskId')
          }).done(function() {
            Radio.channel('navigate').trigger('show:ext:nd:dashboard');
          });
        }
      })
    );
  }
});
