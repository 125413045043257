/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Radio = require('backbone.radio');

var GridView = require('psa-marionette/views/GridView');
var UIUtil = require('psa-core/util/ui');

var AddressCell = require('common/backgrid/AddressCell');
var MultiButtonCell = require('common/backgrid/MultiButtonCell');
var ConfirmationModalView = require('common/views/ConfirmationModalView');

var SubmitSEGLocation = require('../services/SubmitSEGLocation');

module.exports = GridView.extend({
  className: 'dep-seg-location-view',

  onBeforeRender: function() {
    if (GridView.prototype.onBeforeRender) {
      GridView.prototype.onBeforeRender.apply(this.arguments);
    }
    this.options.columns = [
      {
        name: 'address',
        label: 'Business Address',
        cell: AddressCell,
        headerCell: 'custom',
        width: 20,
        editable: false,
        sortable: false
      },
      {
        name: 'county',
        label: 'County',
        cell: 'string',
        headerCell: 'custom',
        width: 15,
        editable: false
      },
      {
        name: 'editDeleteMultiButtonCell',
        label: '',
        cell: MultiButtonCell.extend({
          buttons: [
            {
              name: '',
              buttonClass: 'btn-primary btn-sm',
              labelClass: 'fa fa-edit',
              href: function(model) {
                var entityId = model.get('entityId');
                var segId = model.get('segId');
                var locationId = model.get('id');
                return '#dep/entity/' + entityId + '/seg/' + segId + '/location/' + locationId + '/edit';
              }
            },
            {
              name: '',
              labelClass: 'fa fa-trash',
              buttonClass: 'btn-danger btn-sm ladda-button',
              onClick: this.onClickDeleteLocation.bind(this)
            }
          ]
        }),
        editable: false,
        sortable: false
      }
    ];
    this.options.emptyText = 'No Locations';
  },

  onClickDeleteLocation: function(e, model) {
    var message = 'Are you sure you want to delete this SEG location: ' + model.get('address').addressLine1 + '?';
    UIUtil.showModal({
      title: 'Confirm Deleting SEG Location',
      view: new ConfirmationModalView({
        confirmText: 'Delete SEG Location',
        model: new Backbone.Model({
          message: message,
          onConfirm: function() {
            var segLocationId = model.get('id');
            var segId = model.get('segId');
            return SubmitSEGLocation({ segLocationId: segLocationId, isDeleted: '1', segId: segId });
          },
          onConfirmDone: function() {
            var entityId = model.get('entityId');
            var segId = model.get('segId');
            Radio.channel('navigate').trigger('show:int:dp:entity:seg', entityId, segId);
          }
        })
      })
    });
  }
});
