/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var get = require('lodash.get');
var moment = require('moment');

var Config = require('../../../config/Config');

var getUserIdFromDn = require('common/util/getUserIdFromDn');
var cleanUpResponse = require('common/util/cleanUpResponse');

module.exports = function(examId) {
  var deferred = $.Deferred();

  $.soap({
    body: {
      GetInternalTasksByExam: {
        $xmlns: 'http://dobs.pa.gov/sc/task/bpm/1.0',
        ExamID: examId
      }
    },
    blueprintArrays: [
      'GetInternalTasksByExamResponse.Tasks.Task',
      'GetInternalTasksByExamResponse.Tasks.Task.BusinessAttributes.Attribute',
      'GetInternalTasksByExamResponse.Tasks.Task.Targets.Target',
      'GetInternalTasksByExamResponse.TaskTypes.CM_LU_TaskType'
    ],
    blueprint: {
      tasks: [
        '$..Task',
        {
          taskId: '$..TaskId',
          deliveryDate: '$..DeliveryDate',
          businessAttributes: [
            '$..Attribute',
            {
              name: '$.$name',
              value: '$.__text'
            }
          ],
          assignee: '$..Assignee.$displayName',
          target: '$..Targets.Target[0].__text'
        }
      ],
      taskMetaData: {
        taskTypes: [
          '$..CM_LU_TaskType',
          {
            id: '$.ID',
            name: '$.Name',
            description: '$.Description'
          }
        ]
      }
    }
  })
    .done(function(response) {
      cleanUpResponse(response);

      _.each(response.tasks, function(task) {
        if (task.deliveryDate) {
          task.deliveryDate = moment.utc(task.deliveryDate, moment.ISO_8601).local();
        }

        _.each(task.businessAttributes, function(attr) {
          var attrMap = {
            DueDate: 'dueDate',
            SCOrganizationLicenseID: 'organizationLicenseId',
            SCExamID: 'examId',
            SCCorrespondenceID: 'correspondenceId',
            SCFinancialStatementID: 'financialStatementId',
            TaskTypeName: 'typeName'
          };

          if (attrMap[attr.name]) {
            task[attrMap[attr.name]] = attr.value;
          }
        });

        delete task.businessAttributes;

        var type = _.find(response.taskMetaData.taskTypes, function(type) {
          return task.typeName === type.name;
        });
        task.typeName = get(type, 'description');

        if (task.target) {
          task.target = getUserIdFromDn(task.target);
        }
        task.assignee = task.assignee || task.target;
        task.assigneeId = task.assignee;
        if (task.assigneeId === Config.getExternalUserServiceAccountUserId()) {
          task.assignee = '(Entity)';
        }
        delete task.target;

        if (!task.dueDate) {
          task.dueDate = '--';
        }
      });
      delete response.taskMetaData;

      deferred.resolve(response.tasks);
    })
    .fail(function(err) {
      deferred.reject();
    });

  return deferred.promise();
};
