/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Backgrid = require('backgrid');
var Marionette = require('backbone.marionette');
var Syphon = require('backbone.syphon');
var bodybuilder = require('bodybuilder');
var Ladda = require('ladda');
var moment = require('moment');
var _ = require('underscore');

var PageableCollection = require('psa-backbone/models/PageableCollection');

var ElasticSearchSummaryView = require('common/views/ElasticSearchSummaryView');
var userHasRole = require('common/util/userHasRole');
var NonDepInternalSidebarNavView = require('../../common/views/NonDepInternalSidebarNavView');

var SearchNonDepIndividuals = require('../../common/services/SearchNonDepIndividuals');
var NonDepIndividualSearchResultGridView = require('./NonDepIndividualSearchResultGridView');

require('../styles/NonDepInternalIndividualSearchView.css');
var tmplNonDepInternalIndividualSearchView = require('../templates/NonDepInternalIndividualSearchView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplNonDepInternalIndividualSearchView,

  className: 'non-dep-internal-individual-search-view container-fluid',

  ui: {
    sideBar: '.side-bar-container',
    searchForm: 'form',
    newIndividual: '.btn-new-individual',
    searchResultsContainer: '.search-results-container',
    paginator: '.paginator-container',
    search: '.search',
    clearFilters: '.clear-filters',
    formInputs: ':input',
    checkboxes: 'input[type="checkbox"]',
    searchSummary: '.search-summary',
    dateOfBirth: '.date-of-birth'
  },

  regions: {
    sideBar: '@ui.sideBar',
    searchResultsContainer: '@ui.searchResultsContainer',
    paginator: '@ui.paginator',
    searchSummary: '@ui.searchSummary'
  },

  events: {
    'click @ui.search': 'onClickSearch',
    'click @ui.clearFilters': 'onClickClearFilters',
    'keydown @ui.formInputs': 'onKeydownOfInputs'
  },

  initialize: function() {
    this.collection = new PageableCollection([], { pageSize: 25 });
  },

  onBeforeShow: function() {
    this.showChildView('sideBar', new NonDepInternalSidebarNavView());

    if (userHasRole('ND - Manager')) {
      this.ui.newIndividual.show();
    }

    this.ui.dateOfBirth.datepicker({
      endDate: '0d',
      startView: 'years'
    });

    this.searchSummaryView = new ElasticSearchSummaryView({
      model: new Backbone.Model({
        searchLimit: this.model.get('searchLimit')
      })
    });
    this.showChildView('searchSummary', this.searchSummaryView);

    this.searchResults = new NonDepIndividualSearchResultGridView({
      collection: this.collection
    });
    this.showChildView('searchResultsContainer', this.searchResults);

    this.showChildView(
      'paginator',
      new Backgrid.Extension.Paginator({
        collection: this.collection
      })
    );
    this.ui.paginator.find('ul').addClass('pagination-sm');
  },

  onKeydownOfInputs: function(e) {
    // Searches on press of enter
    if (e.keyCode === 13) {
      this.onClickSearch(e);
    }
  },

  onClickSearch: function() {
    var self = this;

    this.ui.searchForm
      .parsley()
      .whenValidate()
      .done(function() {
        var data = Syphon.serialize(self, {
          inputReaders: Syphon.SearchInputReaderSet
        });
        // If no inputs were given, do not perform a search.
        if (!data.firstName && !data.lastName && !data.ssn && !data.dob && !data.nmlsId) {
          return;
        }
        self.searchSummaryView.reset();

        var l = Ladda.create(self.ui.search[0]);
        l.start();
        var body = bodybuilder();

        if (data.firstName) {
          body.query('bool', function(b) {
            b.orQuery('match', 'firstName', {
              query: data.firstName,
              fuzziness: 'AUTO'
            });
            b.orQuery('wildcard', 'firstName', data.firstName.toLowerCase() + '*');
            return b;
          });
        }
        if (data.lastName) {
          body.query('bool', function(b) {
            b.orQuery('match', 'lastName', {
              query: data.lastName,
              fuzziness: 'AUTO'
            });
            b.orQuery('wildcard', 'lastName', data.lastName.toLowerCase() + '*');
            return b;
          });
        }
        if (data.ssn) {
          body.query('wildcard', 'ssn', data.ssn + '*');
        }
        if (data.nmlsId) {
          body.query('wildcard', 'nmlsId', data.nmlsId + '*');
        }
        if (data.dob) {
          var dobFormatted = moment(data.dob, 'MM/DD/YYYY').format('YYYY-MM-DD');
          body.query('range', 'dob', {
            gte: dobFormatted,
            lte: dobFormatted
          });
        }
        body.size(self.model.get('searchLimit'));
        body.rawOption('highlight', require('./nondep-individual-search-highlights.json'));
        var bodyObj = body.build();
        // Keep for testing
        // console.log(JSON.stringify(bodyObj, null, 2));
        SearchNonDepIndividuals(bodyObj, data.dob)
          .done(function(data) {
            data.hits = _.chain(data.hits)
              .groupBy('ssn')
              .map(function(hits, ssn) {
                return {
                  ssn: ssn,
                  hits: hits
                };
              })
              .value();

            l.stop();
            self.collection.fullCollection.reset(data.hits);
            self.searchSummaryView.display(data);
          })
          .fail(function(err) {
            l.stop();
          });
      });
  },

  onClickClearFilters: function() {
    this.ui.formInputs.val('');
    this.ui.checkboxes.prop('checked', false);
  }
});
