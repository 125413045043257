/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Marionette = require('backbone.marionette');
var Syphon = require('backbone.syphon');
var Radio = require('backbone.radio');
var Ladda = require('ladda');
var moment = require('moment');
var _ = require('underscore');

var Session = require('psa-core/Session');
var FileUploadCollection = require('psa-backbone/models/FileUploadCollection');

var BreadcrumbView = require('common/views/BreadcrumbView');
var modelGet = require('common/util/modelGet');
var ExamHeaderBarView = require('../../../common/views/ExamHeaderBarView');

var SubmitEntityExamFollowupTask = require('../../services/SubmitEntityExamFollowupTask');
var FileUploadView = require('common/views/FileUploadView');

var tmplEntityProvideExamFollowUpTaskView = require('./EntityProvideExamFollowUpTaskView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplEntityProvideExamFollowUpTaskView,

  className: 'entity-provide-exam-followup-task-view container-fluid',

  behaviors: {
    ReadOnlyFormBehavior: {}
  },

  ui: {
    headerBar: '.header-bar',
    breadcrumb: '.breadcrumb-container',
    followupTaskForm: '.followup-task-form',
    examinerSupportingDocumentsContainer: '.examiner-supporting-documents-container',
    examinerSupportingDocumentsEmptyMessage: '.examiner-supporting-documents-empty-message',
    entityFeedback: 'textarea[name="entityFeedbackResponse"]',
    entityDocumentsContainer: '.entity-documents-container',
    submit: '.submit'
  },

  regions: {
    headerBar: '@ui.headerBar',
    breadcrumb: '@ui.breadcrumb',
    examinerSupportingDocumentsContainer: '@ui.examinerSupportingDocumentsContainer',
    entityDocumentsContainer: '@ui.entityDocumentsContainer'
  },

  events: {
    'click @ui.submit': 'onClickSubmit'
  },

  onBeforeShow: function() {
    this.showChildView(
      'headerBar',
      new ExamHeaderBarView({
        model: new Backbone.Model(this.model.get('exam'))
      })
    );

    var entityId = modelGet(this.model, 'exam.entityId');
    var entityName = modelGet(this.model, 'exam.entityName');
    var taskName = modelGet(this.model, 'task.taskType.description');

    this.showChildView(
      'breadcrumb',
      new BreadcrumbView({
        breadcrumbs: [
          {
            link: '#ext/nondep/dashboard',
            text: 'Home'
          },
          {
            link: '#ext/nondep/entity/' + entityId + '/dashboard',
            text: entityName
          },
          {
            text: taskName
          }
        ]
      })
    );

    var exam = this.model.get('exam');

    var examinerSupportingDocumentsCollection = new FileUploadCollection(
      _.pluck(exam.reviewExaminerFollowupDocuments, 'file')
    );

    this.examinerSupportingDocumentsFileUpload = new FileUploadView({
      isReadOnly: true,
      allowMultipleFiles: true,
      collection: examinerSupportingDocumentsCollection
    });
    this.showChildView('examinerSupportingDocumentsContainer', this.examinerSupportingDocumentsFileUpload);

    if (examinerSupportingDocumentsCollection.length === 0) {
      this.ui.examinerSupportingDocumentsContainer.hide();
      this.ui.examinerSupportingDocumentsEmptyMessage.show();
    }

    this.entityFollowupDocumentsCollection = new FileUploadCollection(_.pluck(exam.entityFollowupDocuments, 'file'));

    this.entityFollowupDocumentsFileUpload = new FileUploadView({
      isReadOnly: false,
      allowMultipleFiles: true,
      collection: this.entityFollowupDocumentsCollection,
      metadata: {
        documentTypeId: 10007
      }
    });
    this.showChildView('entityDocumentsContainer', this.entityFollowupDocumentsFileUpload);

    if (exam.entityFeedbackResponse) {
      this.ui.entityFeedback.val('\n\n' + exam.entityFeedbackResponse);
    }
  },

  validate: function() {
    return this.ui.followupTaskForm.parsley().whenValidate();
  },

  getFormData: function() {
    var formData = Syphon.serialize(this);

    formData.taskId = this.model.get('taskId');
    formData.examId = this.model.get('examId');

    var timestamp = moment().format('MM/DD/YYYY h:mm A'),
      user = Session.user,
      userName = user.userId;

    if (user.firstName && user.lastName) {
      userName = user.firstName + ' ' + user.lastName;
    }

    formData.entityFeedbackResponse = '[' + userName + ' - ' + timestamp + '] \n' + formData.entityFeedbackResponse;

    formData.supportingDocuments = this.entityFollowupDocumentsCollection.pluck('fileId');

    return formData;
  },

  onClickSubmit: function(e) {
    e.preventDefault();
    var self = this;
    this.validate().done(
      function() {
        var l = Ladda.create(self.ui.submit[0]);
        l.start();

        SubmitEntityExamFollowupTask(this.getFormData())
          .done(function() {
            Radio.channel('navigate').trigger('show:ext:nd:dashboard');
          })
          .fail(function() {
            l.stop();
          });
      }.bind(this)
    );
  }
});
