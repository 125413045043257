/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');

var cleanUpResponse = require('common/util/cleanUpResponse');

module.exports = function() {
  var deferred = $.Deferred();

  $.soap({
    body: {
      GetAllCorrespondenceRecipientTypes: {
        $xmlns: 'http://dobs.pa.gov/cs/lookup/bpm/1.0'
      }
    },
    blueprintArrays: ['GetAllCorrespondenceRecipientTypesResponse.RecipientTypes.CS_LU_CorrespondenceRecipientType'],
    blueprint: {
      recipientTypes: [
        '$..CS_LU_CorrespondenceRecipientType',
        {
          id: '$.ID',
          recipientType: '$.RecipientType'
        }
      ]
    }
  })
    .done(function(response) {
      cleanUpResponse(response);
      var recipientTypes = _.map(response.recipientTypes, function(recipientType) {
        return {
          id: recipientType.id,
          recipientType: recipientType.recipientType
        };
      });
      deferred.resolve(recipientTypes);
    })
    .fail(function(err) {
      deferred.reject();
    });
  return deferred.promise();
};
