/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Marionette = require('backbone.marionette');
var EmptySetView = require('common/views/EmptySetView');

var CreditServicesLoanBrokerAgentView = require('./CreditServicesLoanBrokerAgentView');

module.exports = Marionette.CollectionView.extend({
  className: 'credit-services-loan-collection-view',

  childView: CreditServicesLoanBrokerAgentView,

  emptyView: EmptySetView,

  emptyViewOptions: function() {
    if (this.options.isReadOnly) {
      return {
        emptyText: 'No Agents Provided'
      };
    }
  },

  childViewOptions: function(model, index) {
    // Pass the index to the collection view
    return {
      childIndex: index
    };
  },

  childEvents: {
    'remove:agent': 'onChildClickRemoveAgent'
  },

  onChildClickRemoveAgent: function(childView) {
    this.collection.remove(childView.model);
  },

  validate: function() {
    return $.when(
      this.children.map(function(child) {
        return child.validate();
      })
    );
  },

  getFormData: function() {
    if (!this.isEmpty()) {
      return this.children.map(function(child) {
        return child.getFormData();
      });
    }
    return [];
  }
});
