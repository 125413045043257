var Handlebars = require("handlebars/dist/handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression;

  return "\n<h1 class=\"page-title\">"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? depth0.task : depth0)) != null ? stack1.taskType : stack1)) != null ? stack1.description : stack1), depth0))
    + "</h1>\n<div class=\"header-bar\"></div>\n<div class=\"breadcrumb-container\"></div>\n\n<form class=\"form-horizontal followup-task-form\">\n  <div class=\"form-group\">\n    <label class=\"control-label col-md-3\">Examiner Feedback</label>\n    <div class=\"col-lg-7 col-md-9\">\n      <p class=\"form-control-static\">"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.exam : depth0)) != null ? stack1.reviewExaminerEntityFeedback : stack1), depth0))
    + "</p>\n    </div>\n  </div>\n  <div class=\"form-group\">\n    <label class=\"control-label col-md-3\">Examiner Supporting Documents</label>\n    <div class=\"col-lg-7 col-md-9\">\n      <div class=\"examiner-supporting-documents-container\"></div>\n      <div class=\"examiner-supporting-documents-empty-message collapse\">\n        <span class=\"text-muted\">No Attached Supporting Documents</span>\n      </div>\n    </div>\n  </div>\n  <div class=\"form-group required\">\n    <label class=\"control-label col-md-3\">Entity Response</label>\n    <div class=\"col-lg-7 col-md-9\">\n      <textarea class=\"form-control\" name=\"entityFeedbackResponse\" data-parsley-required=\"true\" rows=\"12\"\n        placeholder=\"Provide feedback here\"></textarea>\n    </div>\n  </div>\n  <div class=\"form-group\">\n    <label class=\"control-label col-md-3\">Entity Documents</label>\n    <div class=\"col-lg-7 col-md-9\">\n      <div class=\"entity-documents-container\"></div>\n    </div>\n  </div>\n</form>\n<div class=\"row bottom-spacer\">\n  <div class=\"col-md-3 col-md-offset-3\">\n    <a href=\"#ext/nondep/dashboard\" class=\"btn btn-default\" role=\"button\">Cancel</a>\n    <button class=\"btn btn-primary submit ladda-button\" data-style=\"expand-right\" type=\"button\">\n      <span class=\"ladda-label\">Submit</span>\n    </button>\n  </div>\n</div>";
},"useData":true});