var Handlebars = require("handlebars/dist/handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression;

  return "\n<div class=\"page-title clearfix\">\n  <h1 class=\"pull-left m-n p-n\">"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.entityIdentifiers : depth0)) != null ? stack1.entityName : stack1), depth0))
    + "</h1>\n</div>\n<div class=\"page-subtitle\">\n  <h2>Governance</h2>\n  <div class=\"page-subtitle-details\">Meeting Date - "
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.annualMeeting : depth0)) != null ? stack1.meetingDate : stack1), depth0))
    + "</div>\n</div>\n<div class=\"breadcrumb-container\"></div>\n<div class=\"row\">\n  <div class=\"col-sm-12 col-md-10 col-md-offset-1\">\n    <div class=\"form-group\">\n      <div class=\"col-sm-9 col-md-8 col-md-offset-2 col-sm-offset-3\">\n        <h3 class=\"m-t-n\">Edit Governance</h3>\n      </div>\n    </div>\n  </div>\n</div>\n<form class=\"annual-meeting-form-container\"></form>\n<div class=\"row m-b-lg\">\n  <div class=\"col-sm-12 col-md-10 col-md-offset-1\">\n    <div class=\"form-group\">\n      <div class=\"col-sm-9 col-md-10 col-md-offset-2 col-sm-offset-3\">\n        <a href=\"#dep/entity/"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.annualMeeting : depth0)) != null ? stack1.entityId : stack1), depth0))
    + "/dashboard\" class=\"btn btn-default\">Cancel</a>\n        <button class=\"btn btn-primary submit ladda-button\" data-style=\"expand-right\" type=\"button\">\n          <span class=\"ladda-label\">Submit</span>\n        </button>\n      </div>\n    </div>\n  </div>\n</div>";
},"useData":true});