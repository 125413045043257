/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Marionette = require('backbone.marionette');

var PageableCollection = require('psa-backbone/models/PageableCollection');
var UIUtil = require('psa-core/util/ui');

var PanelGridView = require('common/views/PanelGridView');
var MomentPanelCellView = require('common/views/PanelGridView/MomentPanelCellView');
var ButtonsPanelCellView = require('common/views/PanelGridView/ButtonsPanelCellView');
var DropdownView = require('common/views/DropdownView');
var ConfirmationModalView = require('common/views/ConfirmationModalView');

var NonDepEntityJoinCodeUseModalView = require('./NonDepEntityJoinCodeUseModalView');

var SubmitDeleteApplication = require('../services/SubmitDeleteApplication');

require('../styles/NonDepExternalDashboardView.css');
var tmplNonDepExternalDashboardView = require('../templates/NonDepExternalDashboardView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplNonDepExternalDashboardView,

  className: 'non-dep-external-dashboard-view container-fluid',

  ui: {
    pendingTaskWrapper: '.pending-task-wrapper',
    myEntitiesWrapper: '.my-entities-wrapper',
    draftAppsWrapper: '.draft-apps-wrapper',
    joinEntityButton: '.join-entity-button',
    actionDropdown: '.action-dropdown'
  },

  regions: {
    pendingTasksWrapper: '@ui.pendingTaskWrapper',
    myEntitiesWrapper: '@ui.myEntitiesWrapper',
    draftAppsWrapper: '@ui.draftAppsWrapper',
    actionDropdown: '@ui.actionDropdown'
  },

  onBeforeShow: function() {
    this.showChildView(
      'actionDropdown',
      new DropdownView({
        rightAlign: true,
        model: new Backbone.Model({
          name: 'Actions',
          options: [
            {
              text: 'Create a New Entity',
              link: '#ext/nondep/entity/new'
            },
            {
              text: 'Join an Entity Using a Join Code',
              onClick: this.onClickJoinEntity.bind(this)
            }
          ]
        })
      })
    );

    var pendingTasksView = new PanelGridView({
      columns: [
        {
          name: 'type',
          label: 'Task Name',
          width: 20,
          overflow: 'break-normal'
        },
        {
          name: 'licenseType',
          label: 'License Type',
          width: 15,
          overflow: 'truncate'
        },
        {
          name: 'entityName',
          label: 'Entity Name',
          width: 15,
          overflow: 'truncate'
        },
        {
          name: 'licenseNumber',
          label: 'License Number',
          width: 10
        },
        {
          name: 'location',
          label: 'Location',
          width: 15,
          overflow: 'truncate'
        },
        {
          name: 'deliveryDate',
          label: 'Assigned On',
          panelCell: MomentPanelCellView,
          width: 9
        },
        {
          name: 'dueDate',
          label: 'Due On',
          panelCell: MomentPanelCellView,
          width: 9
        },
        {
          name: '',
          label: '',
          width: 7,
          panelCell: ButtonsPanelCellView.extend({
            buttons: [
              {
                buttonText: 'Open',
                href: function(model) {
                  return '#ext/task/' + model.get('taskId');
                }
              }
            ]
          })
        }
      ],
      collection: new PageableCollection(this.model.get('tasks'), { pageSize: 5 }),
      emptyText: 'No Pending Tasks'
    });

    var myEntitiesView = new PanelGridView({
      columns: [
        {
          name: 'entityName',
          label: 'Entity Name',
          width: 35
        },
        {
          name: 'tin',
          label: 'Federal ID Number',
          width: 30
        },
        {
          name: 'entityStatus',
          label: 'Status',
          width: 25
        },
        {
          name: '',
          label: '',
          width: 10,
          panelCell: ButtonsPanelCellView.extend({
            buttons: [
              {
                buttonText: 'Open',
                buttonClass: 'btn-primary btn-md',
                href: function(model) {
                  var isDraftEntity = model.get('entityStatusId') === '1';
                  if (isDraftEntity) {
                    return '#ext/nondep/entity/' + model.get('id') + '/draft';
                  } else {
                    return '#ext/nondep/entity/' + model.get('id') + '/dashboard';
                  }
                }
              }
            ]
          })
        }
      ],
      collection: new PageableCollection(this.model.get('entities'), { pageSize: 100 }),
      emptyText: 'No Entities Created'
    });

    this.draftAppsCollection = new PageableCollection(this.model.get('applications'), { pageSize: 5 });
    var draftAppsView = new PanelGridView({
      columns: [
        {
          name: 'entityName',
          label: 'Entity Name',
          width: 35,
          overflow: 'break-normal'
        },
        {
          name: 'tin',
          label: 'Federal ID Number',
          width: 10
        },
        {
          name: 'applicationType',
          label: 'Application Type',
          width: 20,
          overflow: 'truncate'
        },
        {
          name: 'modifiedDate',
          label: 'Last Modified',
          panelCell: MomentPanelCellView,
          width: 15
        },
        {
          name: '',
          label: '',
          width: 20,
          panelCell: ButtonsPanelCellView.extend({
            buttons: [
              {
                buttonText: 'Discard',
                buttonClass: function(model) {
                  var baseClass = 'btn-danger btn-md';
                  if (model.get('accessLevelId') < 20) {
                    baseClass += ' collapse';
                  }
                  return baseClass;
                },
                onClick: this.onClickDiscardDraftApp.bind(this)
              },
              {
                buttonText: 'Open',
                buttonClass: 'btn-primary btn-md',
                href: function(model) {
                  return '#ext/nondep/application/' + model.get('id') + '/draft';
                }
              }
            ]
          })
        }
      ],
      collection: this.draftAppsCollection,
      emptyText: 'No Draft Applications Started'
    });

    this.showChildView('pendingTasksWrapper', pendingTasksView);
    this.showChildView('myEntitiesWrapper', myEntitiesView);
    this.showChildView('draftAppsWrapper', draftAppsView);
  },

  onClickJoinEntity: function(e) {
    e.preventDefault();

    UIUtil.showModal({
      title: 'Join an Entity',
      model: this.model,
      view: new NonDepEntityJoinCodeUseModalView()
    });
  },

  onClickDiscardDraftApp: function(e, model) {
    e.preventDefault();
    var self = this;
    UIUtil.showModal({
      title: 'Confirm Discard Application Draft',
      view: new ConfirmationModalView({
        confirmText: 'Discard Application Draft',
        model: new Backbone.Model({
          message:
            'Are you sure you want to discard this ' +
            model.get('applicationType') +
            ' Application Draft for ' +
            model.get('entityName') +
            '?',
          onConfirm: function() {
            return SubmitDeleteApplication(model.id);
          },
          onConfirmDone: function() {
            self.draftAppsCollection.remove(model);
          }
        })
      })
    });
  }
});
