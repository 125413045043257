/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var moment = require('moment');

module.exports = function(formData) {
  var generalDetails = formData.generalDetails;
  var applicationContacts = formData.contacts || [];
  var applicationAgents = formData.agents || [];
  var applicationLenders = formData.lenders || [];
  var dbas = formData.dbasSection;
  var branchManager = formData.branchManager;
  var affirmation = formData.affirmation;

  return $.soap({
    body: {
      SubmitCreditServicesLoanBrokerApplication: {
        $xmlns: 'http://dobs.pa.gov/nd/application/bpm/1.0',
        ApplicationID: formData.applicationId,
        ServicesOffered: generalDetails.servicesOffered,
        HasLegalProceedings: generalDetails.hasLegalProceedings,
        IsCertified: affirmation.isCertified ? '1' : '0',
        CertificationName: affirmation.certificationName,
        BranchManager: {
          FirstName: branchManager.firstName,
          MiddleName: branchManager.middleName,
          LastName: branchManager.lastName,
          Title: branchManager.title,
          Suffix: branchManager.suffix,
          SSN: branchManager.ssn,
          DOB: branchManager.dob ? moment(branchManager.dob, 'MM/DD/YYYY').format('YYYY-MM-DD') : '',
          HomePhoneNumber: branchManager.homePhoneNumber,
          CellPhoneNumber: branchManager.cellPhoneNumber,
          Email: branchManager.email,
          IsCertified: affirmation.isCertified ? '1' : '0',
          CertificationName: affirmation.certificationName,
          Address: {
            AddressLine1: branchManager.addressLine1,
            AddressLine2: branchManager.addressLine2,
            AddressLine3: branchManager.addressLine3,
            County: branchManager.county,
            City: branchManager.city,
            Country: branchManager.country,
            PostalCode: branchManager.postalCode,
            State: branchManager.state
          }
        },
        EntityDBAIDs: (function() {
          if (!dbas.entityDBAIds || !dbas.entityDBAIds.length) {
            return {};
          } else {
            return {
              ID: dbas.entityDBAIds
            };
          }
        })(),
        ApplicationDocuments: (function() {
          if (generalDetails.applicationDocuments.length === 0) {
            return {};
          }
          return {
            DocumentID: generalDetails.applicationDocuments
          };
        })(),
        ApplicationContacts: (function() {
          if (!applicationContacts.length) {
            return {};
          }
          return {
            ApplicationContact: _.map(applicationContacts, function(applicationContact) {
              return {
                FirstName: applicationContact.firstName,
                LastName: applicationContact.lastName,
                HomePhoneNumber: applicationContact.homePhoneNumber,
                CellPhoneNumber: applicationContact.cellPhoneNumber,
                Email: applicationContact.email,
                LicenseContactTypeID: applicationContact.licenseContactTypeId,
                Title: applicationContact.title,
                Company: applicationContact.company,
                WorkPhoneNumber: applicationContact.workPhoneNumber,
                WorkPhoneExtension: applicationContact.workPhoneExtension,
                Fax: applicationContact.fax,
                Address: {
                  AddressLine1: applicationContact.addressLine1,
                  AddressLine2: applicationContact.addressLine2,
                  AddressLine3: applicationContact.addressLine3,
                  County: applicationContact.county,
                  City: applicationContact.city,
                  State: applicationContact.state,
                  PostalCode: applicationContact.postalCode,
                  Country: applicationContact.country
                }
              };
            })
          };
        })(),
        ApplicationAgents: (function() {
          if (!applicationAgents.length) {
            return {};
          }
          return {
            ApplicationAgent: _.map(applicationAgents, function(applicationAgent) {
              return {
                FullName: applicationAgent.fullName,
                TelephoneNumber: applicationAgent.telephoneNumber,
                FaxNumber: applicationAgent.faxNumber,
                Email: applicationAgent.email,
                AddressLine1: applicationAgent.addressLine1,
                AddressLine2: applicationAgent.addressLine2,
                AddressLine3: applicationAgent.addressLine3,
                City: applicationAgent.city,
                Country: applicationAgent.country,
                County: applicationAgent.county,
                PostalCode: applicationAgent.postalCode,
                State: applicationAgent.state
              };
            })
          };
        })(),
        ApplicationLenders: (function() {
          if (!applicationLenders.length) {
            return {};
          }
          return {
            ApplicationLender: _.map(applicationLenders, function(applicationLender) {
              return {
                FullName: applicationLender.fullName,
                TelephoneNumber: applicationLender.telephoneNumber,
                FaxNumber: applicationLender.faxNumber,
                Email: applicationLender.email,
                AddressLine1: applicationLender.addressLine1,
                AddressLine2: applicationLender.addressLine2,
                AddressLine3: applicationLender.addressLine3,
                City: applicationLender.city,
                Country: applicationLender.country,
                County: applicationLender.county,
                PostalCode: applicationLender.postalCode,
                State: applicationLender.state
              };
            })
          };
        })()
      }
    }
  });
};
