/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var moment = require('moment');

var cleanUpResponse = require('common/util/cleanUpResponse');

module.exports = function(recordId) {
  var deferred = $.Deferred();

  $.soap({
    body: {
      GetFeesByRecord: {
        $xmlns: 'http://dobs.pa.gov/cf/record/bpm/1.0',
        RecordID: recordId
      }
    },
    blueprintArrays: ['GetFeesByRecordResponse.CF_Fee'],
    blueprint: {
      fees: [
        '$..CF_Fee',
        {
          id: '$..ID',
          recordId: '$..RecordID',
          paidDate: '$..PaidDate',
          paidAmount: '$..PaidAmount',
          typeId: '$..TypeID',
          type: '$..Type',
          checkNumber: '$..Number',
          transmittalNumber: '$..TransmittalNumber',
          transmittalReference: '$..TransmittalReference',
          transactionDescription: '$..TransactionDescription',
          sapDocNum: '$..SAPDocNum',
          isDeleted: '$..IsDeleted',
          createdDate: '$..CreatedDate',
          createdBy: '$..CreatedBy',
          modifiedDate: '$..ModifiedDate',
          modifiedBy: '$..ModifiedBy'
        }
      ]
    }
  })
    .done(function(response) {
      cleanUpResponse(response);
      _.each(response.fees, function(fee) {
        if (fee.paidDate) {
          fee.paidDate = moment(fee.paidDate, 'YYYY-MM-DD');
        }

        fee.checkNumberDisplay = fee.checkNumber || '--';
        fee.sapDocNumDisplay = fee.sapDocNum || '--';
        fee.transmittalNumberDisplay = fee.transmittalNumber || '--';
        fee.transmittalReferenceDisplay = fee.transmittalReference || '--';
        fee.transactionDescriptionDisplay = fee.transactionDescription || '--';
      });

      deferred.resolve(response.fees);
    })
    .fail(function(err) {
      deferred.reject();
    });

  return deferred.promise();
};
