/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');

var cleanUpResponse = require('common/util/cleanUpResponse');
var momentUtil = require('common/util/momentUtil');

module.exports = function(invInqId) {
  var deferred = $.Deferred();

  $.soap({
    body: {
      GetInvestigationInquiry: {
        $xmlns: 'http://dobs.pa.gov/sc/investigationinquiry/bpm/1.0',
        InvInqID: invInqId
      }
    },
    blueprintArrays: ['GetInvestigationInquiryResponse.Violations.SC_ExamInvInqViolation'],
    blueprint: {
      invInqId: '$..ID',
      csFolderId: '$..CSFolderID',
      objectTypeId: '$..ObjectTypeID',
      isOrganization: '$..IsOrganization',
      objectId: '$..ObjectID',
      objectLicenseId: '$..ObjectLicenseID',
      primaryExaminer: '$..PrimaryExaminer',
      primaryExaminerFirstName: '$..PrimaryExaminerFirstName',
      primaryExaminerLastName: '$..PrimaryExaminerLastName',
      caseNumber: '$..CaseNumber',
      categoryId: '$..CategoryID',
      category: '$..Category',
      statusId: '$..StatusID',
      status: '$..Status',
      openedDate: '$..OpenedDate',
      closedDate: '$..ClosedDate',
      upgradedDate: '$..UpgradedDate',
      typeId: '$..TypeID',
      type: '$..Type',
      officeAssignedId: '$..OfficeAssignedID',
      office: '$..Office',
      description: '$..Description',
      isVictimsSenior: '$..IsVictimsSenior',
      seniorVictimCount: '$..SeniorVictimCount',
      otherViolation: '$..OtherViolation',
      hasNextStep: '$..HasNextStep',
      nextStepStatus: '$..NextStepStatus',
      nextStepDescription: '$..NextStepDescription',
      nextStepProjectedDate: '$..NextStepProjectedDate',
      nextStepAssignedTo: '$..NextStepAssignedTo',
      nextStepAssignedToFirstName: '$..NextStepAssignedToFirstName',
      nextStepAssignedToLastName: '$..NextStepAssignedToLastName',
      isDeleted: '$..IsDeleted',
      violations: [
        '$..SC_ExamInvInqViolation',
        {
          violationType: '$..ViolationType',
          violationTypeId: '$..ViolationTypeID'
        }
      ],
      dbaName: '$..DBAName',
      organizationLicenseType: '$..OrganizationLicenseType',
      firstName: '$..FirstName',
      middleInitial: '$..MiddleInitial',
      lastName: '$..LastName',
      individualLicenseType: '$..IndividualLicenseType'
    }
  })
    .done(function(response) {
      cleanUpResponse(response);

      if (response.isOrganization === '1') {
        response.licenseType = response.organizationLicenseType;
      } else if (response.isOrganization === '0') {
        response.middleInitial = response.middleInitial || '';

        response.dbaName = '';
        if (response.firstName) {
          response.dbaName = response.firstName + ' ';
        }
        if (response.middleInitial !== '') {
          response.dbaName += response.middleInitial + ' ';
        }
        response.dbaName += response.lastName;

        response.licenseType = response.individualLicenseType;
      }

      var formInboundDateConfig = {
        sourceFormat: 'YYYY-MM-DD',
        targetFormat: 'MM/DD/YYYY',
        default: undefined
      };

      if (response.hasNextStep === '0') {
        response.hasNextStepDisplay = 'No';
      } else if (response.hasNextStep === '1') {
        response.hasNextStepDisplay = 'Yes';
      }

      response.isVictimsSenior = response.isVictimsSenior === '1';
      response.openedDate = momentUtil.formatStr(response.openedDate, formInboundDateConfig);
      response.closedDate = momentUtil.formatStr(response.closedDate, formInboundDateConfig);
      response.upgradedDate = momentUtil.formatStr(response.upgradedDate, formInboundDateConfig);
      response.nextStepProjectedDate = momentUtil.formatStr(response.nextStepProjectedDate, formInboundDateConfig);

      response.hasOtherViolation = _.findWhere(response.violations, { violationTypeId: '33' });

      deferred.resolve(response);
    })
    .fail(function(err) {
      deferred.reject();
    });

  return deferred.promise();
};
