var Handlebars = require("handlebars/dist/handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "submission";
},"3":function(container,depth0,helpers,partials,data) {
    return "filing";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "\n<form class=\"point-of-contact-form\">\n  <p>Please provide the following information about a person who can be contacted by the Department regarding this "
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.isSubmission : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data})) != null ? stack1 : "")
    + ".</p>\n  <div class=\"form-group required\">\n    <label class=\"control-label\">Full Name</label>\n    <input type=\"text\" name=\"name\" class=\"form-control\" placeholder=\"Full Name\" data-parsley-required=\"true\" data-parsley-maxlength=\"200\" />\n  </div>\n  <div class=\"form-group\">\n   <label class=\"control-label\">Title</label>\n   <input type=\"text\" name=\"title\" class=\"form-control\" placeholder=\"Title\" data-parsley-maxlength=\"200\" />\n  </div>\n  <div class=\"form-group business-container required\">\n    <label class=\"control-label\">Business</label>\n    <input type=\"text\" name=\"business\" class=\"form-control\" placeholder=\"Business\" data-parsley-required=\"true\" data-parsley-maxlength=\"200\" />\n  </div>\n  <div class=\"row\">\n    <div class=\"col-sm-6\">\n      <div class=\"form-group required\">\n        <label class=\"control-label\">Phone Number</label>\n        <input type=\"text\" name=\"phone\" class=\"form-control\" placeholder=\"Phone Number\" data-parsley-required=\"true\" data-parsley-maxlength=\"20\" />\n      </div>\n    </div>\n  </div>\n  <div class=\"form-group required\">\n    <label class=\"control-label\">Email Address</label>\n    <input type=\"text\" name=\"email\" class=\"form-control\" placeholder=\"Email\" data-parsley-maxlength=\"254\" data-parsley-required=\"true\" data-parsley-type=\"email\" />\n  </div>\n  <div class=\"address-container\"></div>\n</form>\n";
},"useData":true});