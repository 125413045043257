/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2020] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var Marionette = require('backbone.marionette');
var Syphon = require('backbone.syphon');
var Radio = require('backbone.radio');
var Ladda = require('ladda');
var FileUploadCollection = require('psa-backbone/models/FileUploadCollection');

var modelGet = require('common/util/modelGet');
var momentUtil = require('common/util/momentUtil');
var toggleForm = require('common/util/toggleForm');

var BreadcrumbView = require('common/views/BreadcrumbView');
var FileUploadView = require('common/views/FileUploadView');

var SubmitNewExam = require('../services/SubmitNewExam');

var tmplDepExamNewView = require('../templates/DepExamNewView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplDepExamNewView,

  className: 'dep-exam-new-view container-fluid',

  ui: {
    breadcrumb: '.breadcrumb-container',
    formGroups: '.form-group',
    submit: '.submit',
    asOfDate: '.as-of-date',
    datePickerFields: '.date-picker',
    examTypeSelect: '.exam-type-select',
    examScope: '.exam-scope',
    targetCodes: '.target-codes',
    leadAgencySelect: '.lead-agency-select',
    eicSelect: '.eic-select',
    newExamForm: '.new-exam-form',
    newTSPExamForm: '.tsp-new-exam-form',
    validationErrorContainers: '.validation-error-container',
    helpBlock: '.help-block',

    // Exam Area Sections
    examAreaLabel: '.exam-area-label',
    ssSection: '.ss-container',
    ssIsNotifyEntity: 'input[name="ssArea[isNotifyEntity]"]',
    ssFirstDayLetterSection: '.ss-first-day-letter-section',
    ssFirstDayLetterSent: 'input[name="ssArea[firstDayLetterDate]"]',
    ssFirstDayLetter: '.ss-first-day-letter-section .first-day-letter-container',
    ssEic: '.ss-container .eic-container',
    ssFedEic: '.ss-container .fed-eic-container',
    ssExamHours: '.ss-container .exam-hours-container',

    itSection: '.it-container',
    itIsNotifyEntity: 'input[name="itArea[isNotifyEntity]"]',
    itFirstDayLetterSection: '.it-first-day-letter-section',
    itFirstDayLetterSent: 'input[name="itArea[firstDayLetterDate]"]',
    itFirstDayLetter: '.it-first-day-letter-section .first-day-letter-container',
    itEic: '.it-container .eic-container',
    itFedEic: '.it-container .fed-eic-container',
    itExamHours: '.it-container .exam-hours-container',

    trustSection: '.trust-container',
    trustIsNotifyEntity: 'input[name="trustArea[isNotifyEntity]"]',
    trustFirstDayLetterSection: '.trust-first-day-letter-section',
    trustFirstDayLetterSent: 'input[name="trustArea[firstDayLetterDate]"]',
    trustFirstDayLetter: '.trust-first-day-letter-section .first-day-letter-container',
    trustEic: '.trust-container .eic-container',
    trustFedEic: '.trust-container .fed-eic-container',
    trustExamHours: '.trust-container .exam-hours-container',

    complianceSection: '.compliance-container',
    craSection: '.cra-container',
    bhcSection: '.bhc-container',

    tspNewExamForm: '.tsp-form',
    newExForm: '.new-form',
    participatingAgencyGrid: '.participating-agencies-grid',
    newPartAgencyForm: '.add-participating-agency-form',
    priorExamGrid: '.prior-exam-grid',

    //Checkboxes
    conductedByCheckboxes: 'input[name="conductedBys"]',
    examAreaCheckboxes: 'input[name="areas"]',
    reviewRoleCheckboxes: 'input[name="reviewRoles"]',
    isHoldingCompanyExamCheckbox: 'input[name="isHoldingCompany"]',

    //Checkboxes Error Containers
    conductedByErrorContainer: '#conducted-by-errors-container',
    areasErrorContainer: '#areas-error-container',
    reviewRolesErrorContainer: '#review-roles-error-container',

    addParticipatingAgency: '.add-agency',
    addPriorExam: '.add-prior-exam',

    tspLeadAgencySelect: '.tsp-lead-agency-select',
    otherAgency: '.other-agency-in-charge',
    tspScopeSelect: '.tsp-exam-scope-select',
    otherScope: '.other-scope'
  },

  regions: {
    breadcrumb: '@ui.breadcrumb',
    ssFirstDayLetter: '@ui.ssFirstDayLetter',
    ssExamHours: '@ui.ssExamHours',
    trustFirstDayLetter: '@ui.trustFirstDayLetter',
    itFirstDayLetter: '@ui.itFirstDayLetter',
    itExamHours: '@ui.itExamHours',
    trustExamHours: '@ui.trustExamHours'
  },

  events: {
    'click @ui.submit': 'onClickSubmit',
    'change @ui.examTypeSelect': 'onChangeTypeScope',
    'change @ui.examScope': 'onChangeTypeScope',
    'change @ui.leadAgencySelect': 'onChangeTypeScope',
    'change @ui.conductedByCheckboxes': 'onChangeConductedBy',
    'change @ui.examAreaCheckboxes': 'onChangeExamArea',
    'change @ui.isHoldingCompanyExamCheckbox': 'onChangeIsHoldingCompanyExam',
    'change @ui.reviewRoleCheckboxes': 'onChangeReviewRoleCheckbox',
    'change @ui.tspScopeSelect': 'onTSPScopeSelect',
    'change @ui.tspLeadAgencySelect': 'onTSPLeadAgencySelect',
    'change @ui.ssIsNotifyEntity': 'onChangeSSIsNotifyEntity',
    'change @ui.itIsNotifyEntity': 'onChangeItIsNotifyEntity',
    'change @ui.trustIsNotifyEntity': 'onChangeTrustIsNotifyEntity'
  },

  templateHelpers: function() {
    var examAreas = modelGet(this.model, 'lookups.examAreas');
    var isBank = modelGet(this.model, 'entityIdentifiers.categoryId') === '1';

    return {
      ssAreaId: (_.findWhere(examAreas, { area: 'S&S' }) || {}).id,
      trustAreaId: (_.findWhere(examAreas, { area: 'Trust' }) || {}).id,
      itAreaId: (_.findWhere(examAreas, { area: 'Information Technology' }) || {}).id,
      complianceAreaId: (_.findWhere(examAreas, { area: 'Compliance' }) || {}).id,
      craAreaId: (_.findWhere(examAreas, { area: 'CRA' }) || {}).id,
      isBank: isBank
    };
  },

  onBeforeShow: function() {
    var entityId = this.model.get('entityId');
    var entityName = modelGet(this.model, 'entityIdentifiers.entityName');

    this.showChildView(
      'breadcrumb',
      new BreadcrumbView({
        breadcrumbs: [
          {
            link: '#dep/dashboard',
            text: 'Home'
          },
          {
            link: '#dep/entity/' + entityId + '/dashboard',
            text: entityName
          },
          {
            text: 'New Exam'
          }
        ]
      })
    );

    this.ui.datePickerFields.datepicker();

    this.ssFirstDayLetterUpload = new FileUploadView({
      isReadOnly: false,
      allowMultipleFiles: false,
      collection: new FileUploadCollection([]),
      metadata: {
        documentTypeId: 20010
      }
    });
    this.showChildView('ssFirstDayLetter', this.ssFirstDayLetterUpload);

    this.trustFirstDayLetterUpload = new FileUploadView({
      isReadOnly: false,
      allowMultipleFiles: false,
      collection: new FileUploadCollection([]),
      metadata: {
        documentTypeId: 20010
      }
    });
    this.showChildView('trustFirstDayLetter', this.trustFirstDayLetterUpload);

    this.itFirstDayLetterUpload = new FileUploadView({
      isReadOnly: false,
      allowMultipleFiles: false,
      collection: new FileUploadCollection([]),
      metadata: {
        documentTypeId: 20010
      }
    });
    this.showChildView('itFirstDayLetter', this.itFirstDayLetterUpload);
    var entityTypeId = modelGet(this.model, 'entityIdentifiers.entityTypeId');
    var isTSP = entityTypeId === '3' || entityTypeId === '12' || entityTypeId === '23';
    toggleForm(this.ui.tspNewExamForm, isTSP);
    toggleForm(this.ui.newExForm, !isTSP);
  },

  onTSPScopeSelect: function() {
    var scopeValue = this.ui.tspScopeSelect.val();
    var isScopeValueOther = scopeValue === '13';
    toggleForm(this.ui.otherScope, isScopeValueOther);
  },

  onTSPLeadAgencySelect: function() {
    var agencyValue = this.ui.tspLeadAgencySelect.val();
    var isAgencyValueOther = agencyValue === '31' || agencyValue === '37' || agencyValue === '43';
    toggleForm(this.ui.otherAgency, isAgencyValueOther);
  },

  onChangeTypeScope: function() {
    var entityIdentifiers = this.model.get('entityIdentifiers') || {};

    var charterTypeId = entityIdentifiers.charterTypeId;

    var examTypeId = this.ui.examTypeSelect.val();
    var examScopeId = this.ui.examScope.val();
    var leadAgencyId = this.ui.leadAgencySelect.val();

    var isFDICFRBPFRBC = _.contains(['2', '3', '4'], leadAgencyId);
    var isReportofInspection = examScopeId === '5';
    var isIndependent = examTypeId === '2';
    var isBHC = charterTypeId === '4';

    if ((isReportofInspection && isBHC) || (isIndependent && isFDICFRBPFRBC)) {
      this.ui.reviewRoleCheckboxes
        .filter('[value != "2"]')
        .closest('div.row')
        .hide();
    } else {
      this.ui.reviewRoleCheckboxes
        .filter('[value != "2"]')
        .closest('div.row')
        .show();
    }

    this.updateExamAreas();
  },

  onChangeIsHoldingCompanyExam: function(e) {
    e.preventDefault();
    var isHCExam = !!this.ui.isHoldingCompanyExamCheckbox.filter(':checked').val();

    if (!isHCExam) {
      this.ui.examAreaLabel.addClass('required');
    } else {
      this.ui.examAreaLabel.removeClass('required');
    }
  },

  onChangeConductedBy: function(e) {
    e.preventDefault();

    this.updateExamAreas();
  },

  onChangeSSIsNotifyEntity: function() {
    var notifyEntity = this.ui.ssIsNotifyEntity.filter(':checked').val() === '1';

    this.ui.ssFirstDayLetterSection.toggleClass('required', notifyEntity);
    if (notifyEntity) {
      this.ui.ssFirstDayLetterSent.attr('data-parsley-required', true);
      this.ssFirstDayLetterUpload.options.isRequired = true;
      this.ui.ssFirstDayLetterSent.datepicker('setDate', new Date());
    } else {
      this.ui.ssFirstDayLetterSent.removeAttr('data-parsley-required');
      this.ssFirstDayLetterUpload.options.isRequired = false;
    }
  },

  onChangeItIsNotifyEntity: function() {
    var notifyEntity = this.ui.itIsNotifyEntity.filter(':checked').val() === '1';

    this.ui.itFirstDayLetterSection.toggleClass('required', notifyEntity);
    if (notifyEntity) {
      this.ui.itFirstDayLetterSent.attr('data-parsley-required', true);
      this.itFirstDayLetterUpload.options.isRequired = true;
      this.ui.itFirstDayLetterSent.datepicker('setDate', new Date());
    } else {
      this.ui.itFirstDayLetterSent.removeAttr('data-parsley-required');
      this.itFirstDayLetterUpload.options.isRequired = false;
    }
  },

  onChangeTrustIsNotifyEntity: function() {
    var notifyEntity = this.ui.trustIsNotifyEntity.filter(':checked').val() === '1';

    this.ui.trustFirstDayLetterSection.toggleClass('required', notifyEntity);
    if (notifyEntity) {
      this.ui.trustFirstDayLetterSent.attr('data-parsley-required', true);
      this.trustFirstDayLetterUpload.options.isRequired = true;
      this.ui.trustFirstDayLetterSent.datepicker('setDate', new Date());
    } else {
      this.ui.trustFirstDayLetterSent.removeAttr('data-parsley-required');
      this.trustFirstDayLetterUpload.options.isRequired = false;
    }
  },

  onChangeExamArea: function(e) {
    e.preventDefault();

    this.updateExamAreas();
  },

  updateExamAreas: function() {
    // Disable all area sections
    toggleForm(this.ui.ssSection, false);
    toggleForm(this.ui.trustSection, false);
    toggleForm(this.ui.itSection, false);
    toggleForm(this.ui.complianceSection, false);
    toggleForm(this.ui.craSection, false);

    var pdobsLeadAgencyIds = ['1', '11', '21'],
      independentLeadAgencyIds = ['2', '3', '4'],
      naLeadAgencyIds = ['6', '13', '23'];

    var isIndependentExam =
      this.ui.examTypeSelect.val() === '2' && _.contains(independentLeadAgencyIds, this.ui.leadAgencySelect.val());

    var conductedByAgencies = this.ui.conductedByCheckboxes.filter(':checked').map(function() {
      return this.value;
    });

    // Enable selected sections
    this.ui.examAreaCheckboxes.each(
      function(ind, obj) {
        switch (obj.value) {
          case '1':
          case '11':
            toggleForm(this.ui.ssSection, obj.checked);

            if (obj.checked) {
              if (isIndependentExam) {
                toggleForm(this.ui.ssEic, false);
              } else {
                toggleForm(this.ui.ssEic, _.intersection(pdobsLeadAgencyIds, conductedByAgencies).length);
              }
              toggleForm(
                this.ui.ssFedEic,
                _.difference(conductedByAgencies, pdobsLeadAgencyIds, naLeadAgencyIds).length
              );
            }
            break;
          case '2':
          case '21':
            toggleForm(this.ui.trustSection, obj.checked);

            if (obj.checked) {
              if (isIndependentExam) {
                toggleForm(this.ui.trustFirstDayLetter, false);
                toggleForm(this.ui.trustEic, false);
              } else {
                toggleForm(this.ui.trustFirstDayLetter, true);
                toggleForm(this.ui.trustEic, _.intersection(pdobsLeadAgencyIds, conductedByAgencies).length);
              }
              toggleForm(
                this.ui.trustFedEic,
                _.difference(conductedByAgencies, pdobsLeadAgencyIds, naLeadAgencyIds).length
              );
            }
            break;
          case '3':
          case '12':
          case '22':
            toggleForm(this.ui.itSection, obj.checked);

            if (obj.checked) {
              if (isIndependentExam) {
                toggleForm(this.ui.itFirstDayLetter, false);
                toggleForm(this.ui.itEic, false);
              } else {
                toggleForm(this.ui.itirstDayLetter, true);
                toggleForm(this.ui.itEic, _.intersection(pdobsLeadAgencyIds, conductedByAgencies).length);
              }
              toggleForm(
                this.ui.itFedEic,
                _.difference(conductedByAgencies, pdobsLeadAgencyIds, naLeadAgencyIds).length
              );
            }
            break;
          case '4':
          case '13':
            toggleForm(this.ui.complianceSection, obj.checked);
            break;
          case '5':
            toggleForm(this.ui.craSection, obj.checked);
        }
      }.bind(this)
    );
  },

  onChangeReviewRoleCheckbox: function() {
    var reviewRoleIds = this.ui.reviewRoleCheckboxes.filter(':checked').map(function() {
        return this.value;
      }),
      isEICChecked = !!_.intersection(reviewRoleIds, ['1', '11', '21']).length;

    toggleForm(this.ui.eicSelect, isEICChecked);
  },

  validate: function() {
    var validationArray;
    var entityTypeId = modelGet(this.model, 'entityIdentifiers.entityTypeId');
    var isTSP = entityTypeId === '3' || entityTypeId === '12' || entityTypeId === '23';

    if (isTSP) {
      validationArray = [this.ui.newTSPExamForm.parsley().whenValidate(), this.validateCheckboxes()];
    } else {
      validationArray = [
        this.ui.newExamForm.parsley().whenValidate(),
        this.validateCheckboxes(),
        this.ssFirstDayLetterUpload.validate(),
        this.trustFirstDayLetterUpload.validate(),
        this.itFirstDayLetterUpload.validate()
      ];
    }
    return $.when.apply($, validationArray);
  },

  validateCheckboxes: function() {
    var deferred = $.Deferred();
    var hasErrors = false;
    var entityTypeId = modelGet(this.model, 'entityIdentifiers.entityTypeId');
    var isTSP = entityTypeId === '3' || entityTypeId === '12' || entityTypeId === '23';
    if (!isTSP) {
      if (!this.ui.conductedByCheckboxes.filter(':checked').length) {
        hasErrors = true;
        this.ui.conductedByErrorContainer.parents('.form-group').addClass('has-error');
        this.ui.conductedByErrorContainer.show();
      }

      var isHCExam = !!this.ui.isHoldingCompanyExamCheckbox.filter(':checked').val();
      if (!isHCExam && !this.ui.examAreaCheckboxes.filter(':checked').length) {
        hasErrors = true;
        this.ui.areasErrorContainer.show();
        this.ui.areasErrorContainer.parents('.form-group').addClass('has-error');
      }
    }

    if (!this.ui.reviewRoleCheckboxes.filter(':checked').length) {
      hasErrors = true;
      this.ui.reviewRolesErrorContainer.show();
      this.ui.reviewRolesErrorContainer.parents('.form-group').addClass('has-error');
    }

    if (hasErrors) {
      deferred.reject();
    } else {
      deferred.resolve();
    }
    return deferred.promise();
  },

  removeValidationErrors: function() {
    this.ui.formGroups.removeClass('has-error');
    this.ui.validationErrorContainers.hide();
    this.ssFirstDayLetterUpload.removeValidationErrors();
    this.itFirstDayLetterUpload.removeValidationErrors();
    this.trustFirstDayLetterUpload.removeValidationErrors();
  },

  getFormData: function() {
    var formData = Syphon.serialize(this, {
      exclude: [
        'areas',
        'conductedBys',
        'reviewRoles',
        'ssArea[firstDayLetter]',
        'trustArea[firstDayLetter]',
        'itArea[firstDayLetter]'
      ]
    });

    formData.conductedBy = this.ui.conductedByCheckboxes
      .filter(':checked')
      .map(function() {
        return this.value;
      })
      .toArray();

    formData.reviewRoles = this.ui.reviewRoleCheckboxes
      .filter(':checked')
      .map(function() {
        return this.value;
      })
      .toArray();

    momentUtil.formatDateAttributes(
      formData,
      [
        'bhcAsOfDate',
        'ssArea.asOfDate',
        'ssArea.firstDayLetterDate',
        'ssArea.examStartDate',
        'ssArea.exitMeetingDate',
        'trustArea.asOfDate',
        'trustArea.firstDayLetterDate',
        'trustArea.examStartDate',
        'trustArea.exitMeetingDate',
        'itArea.asOfDate',
        'itArea.firstDayLetterDate',
        'itArea.examStartDate',
        'itArea.exitMeetingDate',
        'complianceArea.asOfDate',
        'craArea.asOfDate'
      ],
      momentUtil.defaultOutboundDateConfig.sourceFormat,
      momentUtil.defaultOutboundDateConfig.targetFormat
    );

    formData.entityId = this.model.get('entityId');
    formData.isHoldingCompany = !!formData.isHoldingCompany;

    if (formData.ssArea) {
      formData.ssArea.isNotifyEntity = formData.ssArea.isNotifyEntity === '1';
      formData.ssArea.firstDayLetterDocId = this.ssFirstDayLetterUpload.getFirstFileId();
    }

    if (formData.trustArea) {
      formData.trustArea.isNotifyEntity = formData.trustArea.isNotifyEntity === '1';
      formData.trustArea.firstDayLetterDocId = this.trustFirstDayLetterUpload.getFirstFileId();
    }

    if (formData.itArea) {
      formData.itArea.isNotifyEntity = formData.itArea.isNotifyEntity === '1';
      formData.itArea.firstDayLetterDocId = this.itFirstDayLetterUpload.getFirstFileId();
    }

    var entityTypeId = modelGet(this.model, 'entityIdentifiers.entityTypeId');
    var isTSP = entityTypeId === '3' || entityTypeId === '12' || entityTypeId === '23';
    if (isTSP) {
      formData.isConcurrent = 0;
      formData.isParticipation = 0;
    }

    return formData;
  },

  onClickSubmit: function() {
    var self = this;
    this.getFormData();
    this.removeValidationErrors();
    this.validate().done(function() {
      var l = Ladda.create(self.ui.submit[0]);
      l.start();
      SubmitNewExam(self.getFormData())
        .done(function(examId) {
          Radio.channel('navigate').trigger('show:int:dp:exam', self.model.get('entityId'), examId);
        })
        .fail(function() {
          l.stop();
        });
    });
  }
});
