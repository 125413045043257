var Handlebars = require("handlebars/dist/handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "<div class=\"row\">\n  <div class=\"col-sm-9 col-sm-offset-3 pb-5\">\n  <img class=\"ext-banner\" src=\"img/FORM BANNERDesktop.jpg\" alt=\"portal-logo\"  style=\"width: 355px; padding-bottom: 25px;\" />\n  </div>\n</div>\n<div class=\"form-horizontal\">\n  <form class=\"code-form\">\n    <div class=\"row\" style=\"font-size:1.25em; padding-bottom: 25px;\">Thank you for submitting an Online Consumer Complaint. A representative from the Consumer Services Office may contact you to follow up.</div>\n    <div class=\"row\" style=\"font-size:1.25em; padding-bottom: 25px;\">Here is a copy of the information you provided. <i><b>Please print or save for your records.</i></b></div>\n    <div class=\"row\">\n      <label class=\"control-label col-sm-3\">Reason For Contact</label>\n      <div class=\"form-control-static col-sm-3 \">"
    + alias3((helpers["default"] || (depth0 && depth0["default"]) || alias2).call(alias1,((stack1 = (depth0 != null ? depth0.complaint : depth0)) != null ? stack1.reasonforcontact : stack1),"--",{"name":"default","hash":{},"data":data}))
    + "</div>\n    </div>\n    <div class=\"row\">\n      <label class=\"control-label col-sm-3 \">Thank You</label>\n      <div class=\"form-control-static col-sm-9 \">\n        "
    + alias3((helpers["default"] || (depth0 && depth0["default"]) || alias2).call(alias1,((stack1 = (depth0 != null ? depth0.complaint : depth0)) != null ? stack1.title : stack1),"",{"name":"default","hash":{},"data":data}))
    + " "
    + alias3((helpers["default"] || (depth0 && depth0["default"]) || alias2).call(alias1,((stack1 = (depth0 != null ? depth0.complaint : depth0)) != null ? stack1.fname : stack1),"--",{"name":"default","hash":{},"data":data}))
    + " "
    + alias3((helpers["default"] || (depth0 && depth0["default"]) || alias2).call(alias1,((stack1 = (depth0 != null ? depth0.complaint : depth0)) != null ? stack1.lname : stack1),"--",{"name":"default","hash":{},"data":data}))
    + " for contacting us about "
    + alias3((helpers["default"] || (depth0 && depth0["default"]) || alias2).call(alias1,((stack1 = (depth0 != null ? depth0.complaint : depth0)) != null ? stack1.institutionName : stack1),"--",{"name":"default","hash":{},"data":data}))
    + "\n      </div>\n    </div>\n    <div class=\"row\">\n      <label class=\"control-label col-sm-3\">Phone Number</label>\n      <div class=\"form-control-static col-sm-9\">"
    + alias3((helpers["default"] || (depth0 && depth0["default"]) || alias2).call(alias1,((stack1 = (depth0 != null ? depth0.complaint : depth0)) != null ? stack1.phonenumber : stack1),"--",{"name":"default","hash":{},"data":data}))
    + "</div>\n    </div>\n    <div class=\"row\">\n      <label class=\"control-label col-sm-3\">Email</label>\n      <div class=\"form-control-static col-sm-9\">"
    + alias3((helpers["default"] || (depth0 && depth0["default"]) || alias2).call(alias1,((stack1 = (depth0 != null ? depth0.complaint : depth0)) != null ? stack1.email : stack1),"--",{"name":"default","hash":{},"data":data}))
    + "</div>\n    </div>\n    <div class=\"row\">\n      <label class=\"control-label col-sm-3\">Your Street Address</label>\n      <div class=\"form-control-static col-sm-9\">"
    + alias3((helpers["default"] || (depth0 && depth0["default"]) || alias2).call(alias1,((stack1 = (depth0 != null ? depth0.complaint : depth0)) != null ? stack1.street : stack1),"--",{"name":"default","hash":{},"data":data}))
    + "</div>\n    </div>\n    <div class=\"row\">\n      <label class=\"control-label col-sm-3\">Your City</label>\n      <div class=\"form-control-static col-sm-9\">"
    + alias3((helpers["default"] || (depth0 && depth0["default"]) || alias2).call(alias1,((stack1 = (depth0 != null ? depth0.complaint : depth0)) != null ? stack1.city : stack1),"--",{"name":"default","hash":{},"data":data}))
    + "</div>\n    </div>\n    <div class=\"row\">\n      <label class=\"control-label col-sm-3\">Your Zip</label>\n      <div class=\"form-control-static col-sm-9\">"
    + alias3((helpers["default"] || (depth0 && depth0["default"]) || alias2).call(alias1,((stack1 = (depth0 != null ? depth0.complaint : depth0)) != null ? stack1.zip : stack1),"--",{"name":"default","hash":{},"data":data}))
    + "</div>\n    </div>\n    <div class=\"row\">\n      <label class=\"control-label col-sm-3\">Insitution Name</label>\n      <div class=\"form-control-static col-sm-9\">"
    + alias3((helpers["default"] || (depth0 && depth0["default"]) || alias2).call(alias1,((stack1 = (depth0 != null ? depth0.complaint : depth0)) != null ? stack1.institutionName : stack1),"--",{"name":"default","hash":{},"data":data}))
    + "</div>\n    </div>\n    <div class=\"row\">\n      <label class=\"control-label col-sm-3\">Account/Loan #</label>\n      <div class=\"form-control-static col-sm-9\">"
    + alias3((helpers["default"] || (depth0 && depth0["default"]) || alias2).call(alias1,((stack1 = (depth0 != null ? depth0.complaint : depth0)) != null ? stack1.accountnumber : stack1),"--",{"name":"default","hash":{},"data":data}))
    + "</div>\n    </div>\n    <div class=\"row\">\n      <label class=\"control-label col-sm-3\">Institution Address</label>\n      <div class=\"form-control-static col-sm-9\">"
    + alias3((helpers["default"] || (depth0 && depth0["default"]) || alias2).call(alias1,((stack1 = (depth0 != null ? depth0.complaint : depth0)) != null ? stack1.institutionAddress : stack1),"--",{"name":"default","hash":{},"data":data}))
    + "</div>\n    </div>\n    <div class=\"row\">\n      <label class=\"control-label col-sm-3\">Institution City/State/Zip</label>\n      <div class=\"form-control-static col-sm-9\">"
    + alias3((helpers["default"] || (depth0 && depth0["default"]) || alias2).call(alias1,((stack1 = (depth0 != null ? depth0.complaint : depth0)) != null ? stack1.institutionCityStateZip : stack1),"--",{"name":"default","hash":{},"data":data}))
    + "</div>\n    </div>\n  </form>\n</div>\n";
},"useData":true});