var Handlebars = require("handlebars/dist/handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "<div class=\"form-group\">\n  <label class=\"control-label col-sm-2\">Title</label>\n  <div class=\"form-control-static col-sm-9\">"
    + alias3((helpers["default"] || (depth0 && depth0["default"]) || alias2).call(alias1,(depth0 != null ? depth0.title : depth0),"--",{"name":"default","hash":{},"data":data}))
    + "</div>\n</div>\n<div class=\"form-group\">\n  <label class=\"control-label col-sm-2\">First Name</label>\n  <div class=\"form-control-static col-sm-9\">"
    + alias3((helpers["default"] || (depth0 && depth0["default"]) || alias2).call(alias1,(depth0 != null ? depth0.firstName : depth0),"--",{"name":"default","hash":{},"data":data}))
    + "</div>\n</div>\n<div class=\"form-group\">\n  <label class=\"control-label col-sm-2\">Last Name</label>\n  <div  class=\"form-control-static col-sm-9\">"
    + alias3((helpers["default"] || (depth0 && depth0["default"]) || alias2).call(alias1,(depth0 != null ? depth0.lastName : depth0),"--",{"name":"default","hash":{},"data":data}))
    + "</div>\n</div>\n<div class=\"form-group\">\n  <label class=\"control-label col-sm-2\">Email</label>\n  <div class=\"form-control-static col-sm-9\">"
    + alias3((helpers["default"] || (depth0 && depth0["default"]) || alias2).call(alias1,(depth0 != null ? depth0.email : depth0),"--",{"name":"default","hash":{},"data":data}))
    + "</div>\n</div>\n<div class=\"form-group\">\n  <label class=\"control-label col-sm-2\">Phone Number</label>\n  <div class=\"form-control-static col-sm-9\">"
    + alias3((helpers["default"] || (depth0 && depth0["default"]) || alias2).call(alias1,(depth0 != null ? depth0.phoneNumber : depth0),"--",{"name":"default","hash":{},"data":data}))
    + "</div>\n</div>\n<div class=\"form-group\">\n  <label class=\"control-label col-sm-2\">Fax</label>\n  <div class=\"form-control-static col-sm-9\">"
    + alias3((helpers["default"] || (depth0 && depth0["default"]) || alias2).call(alias1,(depth0 != null ? depth0.fax : depth0),"--",{"name":"default","hash":{},"data":data}))
    + "</div>\n</div>\n<div class=\"form-group\">\n  <label class=\"control-label col-sm-2\">Address</label>\n  <div class=\"form-control-static address-container col-sm-9\"></div>\n</div>";
},"useData":true});