/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Syphon = require('backbone.syphon');
var Marionette = require('backbone.marionette');

var UIUtil = require('psa-core/util/ui');

var parsleyUtil = require('common/util/parsleyUtil');
var makeUploadCollection = require('common/util/makeUploadCollection');
var toggleForm = require('common/util/toggleForm');
var AddressView = require('common/views/AddressView');
var CommentsDocumentView = require('common/views/CommentsDocumentView');

var DepEntitySearchWithCategoryModalView = require('../../../entity-dashboard/views/DepEntitySearchWithCategoryModalView');

var tmplDepCreditUnionLocationActivitiesNewLocationItemView = require('./DepCreditUnionLocationActivitiesNewLocationItemView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplDepCreditUnionLocationActivitiesNewLocationItemView,

  className: 'dep-bank-location-activities-new-location-item-view panel panel-info',

  behaviors: {
    RadioGroupToggleBehavior: {
      radios: [
        {
          target: '.is-acquired',
          radioGroup: 'input[name="isBranchAcquired"]',
          valueToShow: '1'
        }
      ]
    },
    ReadOnlyFormBehavior: {}
  },

  ui: {
    newLocationForm: '.new-location-form',
    newLocationType: '.new-location-type',
    datepickerFields: '.datepicker',
    locationAddressSection: '.location-address-section',
    LPOContact: '.lpo-contact',
    removeLocation: '.remove-location',
    locationAddress: '.location-address',
    contactAddress: '.contact-address',
    btnAcquisitionSearch: '.btn-acquisition-search',
    acquiredFromAddress: '.acquired-from-address',
    acquiredFromName: '.acquired-from-name',
    questionnaireContainer: '.questionnaire-container',
    fixedAssetsContainer: '.fixed-assets-container',
    branchAppContainer: '.branch-app-container',
    currentLocationContact: '.current-location-contact'
  },

  regions: {
    locationAddress: '@ui.locationAddress',
    contactAddress: '@ui.contactAddress',
    acquiredFromAddress: '@ui.acquiredFromAddress',
    questionnaireContainer: '@ui.questionnaireContainer',
    fixedAssetsContainer: '@ui.fixedAssetsContainer',
    branchAppContainer: '@ui.branchAppContainer'
  },

  events: {
    'change @ui.newLocationType': 'onChangeNewLocationType',
    'click @ui.btnAcquisitionSearch': 'onClickAcquisitionSearch',
    'change @ui.currentLocationContact': 'onCheckCurrentLocationContact'
  },

  triggers: {
    'click @ui.removeLocation': 'remove:location'
  },

  templateHelpers: function() {
    var isCU = this.options.entityCategoryId === '2';
    return {
      modelId: this.model.cid,
      locationTypes: this.options.locationTypes,
      isCU: isCU,
      isReadOnly: this.options.isReadOnly
    };
  },

  onBeforeShow: function() {
    var locationAddress = this.model.get('locationAddress') || {};
    var contactAddress = this.model.get('contactAddress') || {};
    var sellingAddress = this.model.get('sellingAddress') || {};

    var questionnaire = this.model.get('questionnaire') || {};
    var fixedAssets = this.model.get('fixedAssets') || {};
    var branchApp = this.model.get('foreignBranch') || {};

    this.locationAddress = new AddressView({
      model: new Backbone.Model(locationAddress),
      objectName: 'locationAddress',
      horizontal: false,
      isRequired: true,
      startingCountry: locationAddress.country,
      startingPostalCode: locationAddress.postalCode,
      startingCounty: locationAddress.county
    });
    this.showChildView('locationAddress', this.locationAddress);
    
    this.locationAddress.ui.countryGroup.remove()

    this.contactAddress = new AddressView({
      model: new Backbone.Model(contactAddress),
      objectName: 'contactAddress',
      horizontal: false,
      isRequired: false,
      startingCountry: contactAddress.country,
      startingPostalCode: contactAddress.postalCode,
      startingCounty: contactAddress.county
    });
    this.showChildView('contactAddress', this.contactAddress);

    this.acquiredFromAddress = new AddressView({
      model: new Backbone.Model(sellingAddress),
      objectName: 'sellingAddress',
      horizontal: false,
      isRequired: true,
      startingCountry: sellingAddress.country,
      startingPostalCode: sellingAddress.postalCode,
      startingCounty: sellingAddress.county
    });
    this.showChildView('acquiredFromAddress', this.acquiredFromAddress);

    Syphon.deserialize(this, this.model.toJSON());
    this.onChangeNewLocationType();
    this.ui.datepickerFields.datepicker({});

    this.questionnaire = new CommentsDocumentView({
      isReadOnly: this.options.isReadOnly,
      allowMultipleFiles: false,
      isRequired: true,
      fileUploadLabel: 'Questionnaire',
      commentsLabel: 'Questionnaire Comments',
      commentsName: 'questionnaireComments',
      uniqueId: this.model.get('locationActivityId'),
      collection: makeUploadCollection(questionnaire.file),
      model: new Backbone.Model({ questionnaireComments: questionnaire.questionnaireComments }),
      documentTypeId: 20001
    });
    this.showChildView('questionnaireContainer', this.questionnaire);

    this.fixedAssets = new CommentsDocumentView({
      isReadOnly: this.options.isReadOnly,
      allowMultipleFiles: false,
      isRequired: true,
      fileUploadLabel: 'Location Fixed Assets Investment Ratio Calculation Pursuant to Section 501(b)(10) of the Credit Union Code',
      commentsLabel: 'Location Fixed Assets Investments Ratio Calculation Comments',
      commentsName: 'fixedAssetsComments',
      uniqueId: this.model.get('locationActivityId'),
      collection: makeUploadCollection(fixedAssets.file),
      model: new Backbone.Model({ fixedAssetsComments: fixedAssets.fixedAssetsComments }),
      documentTypeId: 20001
    });
    this.showChildView('fixedAssetsContainer', this.fixedAssets);

    this.branchApp = new CommentsDocumentView({
      isReadOnly: this.options.isReadOnly,
      allowMultipleFiles: false,
      isRequired: false,
      fileUploadLabel: 'Out of State Branch Supplement ',
      commentsLabel: 'Out of State Branch Comments',
      commentsName: 'foreignBranchComments',
      uniqueId: this.model.get('locationActivityId'),
      collection: makeUploadCollection(branchApp.file),
      model: new Backbone.Model({ foreignBranchComments: branchApp.foreignBranchComments }),
      documentTypeId: 20001
    });
    this.showChildView('branchAppContainer', this.branchApp);
    ({});
    this.onChangeNewLocationType();
  },

  onCheckCurrentLocationContact: function() {
    var isInternational = this.locationAddress.ui.country.val() !== 'US';
    if (this.ui.currentLocationContact.prop('checked')) {
      var formData = Syphon.serialize(this);
      var locationAddress = formData.locationAddress;
      this.contactAddress.setAddress(locationAddress);
      if (isInternational) {
        this.contactAddress.ui.addressLine3.val(this.locationAddress.ui.addressLine3.val());
        this.contactAddress.ui.stateTextInput.val(this.locationAddress.ui.stateTextInput.val());
      }
    } else {
      this.contactAddress.render();
    }
  },

  onChangeNewLocationType: function() {
    var newLocationTypeId = this.ui.newLocationType.val();
    var isLPO = newLocationTypeId === '4' || newLocationTypeId === '25';
   
    toggleForm(this.ui.currentLocationContact, isLPO);
    toggleForm(this.ui.LPOContact, isLPO);
   },

  onClickAcquisitionSearch: function(e) {
    e.preventDefault();
    var self = this;

    UIUtil.showModal({
      title: 'Search for an Institution',
      dialogClasses: 'modal-dialog-entity-search-modal-view',
      view: new DepEntitySearchWithCategoryModalView({
        model: this.model,
        categoryId: this.options.entityCategoryId
      })
    }).done(function(entity) {
      if (entity) {
        self.ui.acquiredFromName.val(entity.entityName);
        var sellingAddress = entity.entityAddress;
        self.acquiredFromAddress.setAddress(sellingAddress);
      }
    });
  },

  validate: function(config) {
    this.ui.newLocationForm.parsley().reset();
    this.questionnaire.removeValidationErrors();
    this.fixedAssets.removeValidationErrors();
    this.branchApp.removeValidationErrors();

    if (config.type === 'save') {
      // Turn off required field validation for this form
      parsleyUtil.disableRequiredValidation(this.ui.newLocationForm);
    } else {
      var validationPromises = [
        this.ui.newLocationForm.parsley().whenValidate(),
        this.questionnaire.validate(),
        this.fixedAssets.validate(),
        this.branchApp.validate()
      ];
      // Turn on required field validation for this form
      parsleyUtil.enableRequiredValidation(this.ui.newLocationForm);
    }
    return $.when.apply($, validationPromises);
  },

  getFormData: function() {
    var formData = Syphon.serialize(this);
    formData.fixedAssetsDocId = this.fixedAssets.getFirstFileId();
    formData.questionnaireDocId = this.questionnaire.getFirstFileId();
    formData.foreignBranchDocId = this.branchApp.getFirstFileId();
    return formData;
  }
});
