var Handlebars = require("handlebars/dist/handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "    <div class=\"form-group\">\n      <label class=\"control-label col-sm-3\">Regional Director Review Comments</label>\n      <div class=\"col-sm-9 col-lg-7\">\n        <p class=\"form-control-static\">"
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? depth0.previousReviewDecision : depth0)) != null ? stack1.decisionReason : stack1), depth0))
    + "</p>\n      </div>\n    </div>\n    <div class=\"form-group review-document-container\">\n      <label class=\"control-label col-md-3\">Regional Director Review Document</label>\n      <div class=\"col-md-6\">\n        <div class=\"attachment-container\"></div>\n      </div>\n    </div>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "\n<form class=\"form-horizontal form-work-tab\">\n"
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? depth0.previousReviewDecision : depth0)) != null ? stack1.decisionReason : stack1),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "  <div class=\"exam-recorded-results-container\"></div>\n  <div class=\"form-group required time-tracking\">\n    <label class=\"control-label col-md-3\">Time Tracking</label>\n    <div class=\"col-md-6\">\n      <div class=\"checkbox time-tracking\">\n        <label>\n          <input type=\"checkbox\" name=\"timeSubmitted\" class=\"not-applicable\" tabindex=\"-1\" data-parsley-required=\"true\"> I have correctly submitted all time and expenses related to this exam accurately, within the Time and Expenses Tracking system.\n        </label>\n      </div>\n    </div>\n  </div>\n</form>\n<div class=\"row bottom-spacer\">\n  <div class=\"col-md-3 col-md-offset-3\">\n    <a href=\"#nondep/dashboard\" class=\"btn btn-default\" role=\"button\">Cancel</a>\n    <button class=\"btn btn-primary submit ladda-button\" data-style=\"expand-right\" type=\"button\">\n      <span class=\"ladda-label\">Submit</span>\n    </button>\n    <div class=\"task-actions\"></div>\n  </div>\n</div>";
},"useData":true});