/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Marionette = require('backbone.marionette');
var Syphon = require('backbone.syphon');
var Radio = require('backbone.radio');
var Ladda = require('ladda');

var FileUploadCollection = require('psa-backbone/models/FileUploadCollection');
var switchToPrint = require('common/util/switchToPrint');

var AddressView = require('common/views/AddressView');
var makeUploadCollection = require('common/util/makeUploadCollection');
var FileUploadView = require('common/views/FileUploadView/FileUploadView');

var SubmitLicenseCollectorRepossessorEmployeeApplication = require('../../services/SubmitCollectorRepossessorEmployeeApplication');

var tmplLicenseCollectorRepossessorEmployeeApplicationView = require('./LicenseCollectorRepossessorEmployeeApplicationView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplLicenseCollectorRepossessorEmployeeApplicationView,

  className: 'license-apply-collector-repossessor-employee-form-view row',

  ui: {
    employeeInformationForm: '.employee-information-form',
    submitButton: '.submit-button',
    address: '.address',
    employmentProofUploadTableContainer: '.employment-proof-upload-table-container',
    applicationDocumentsFileContainer: '.application-documents-container',
    isCertified: 'input[name="isCertified"]',
    certificationName: 'input[name="certificationName"]'
  },

  regions: {
    address: '@ui.address',
    employmentProofUploadTableContainer: '@ui.employmentProofUploadTableContainer',
    applicationDocumentsFileContainer: '@ui.applicationDocumentsFileContainer'
  },

  events: {
    'click @ui.submitButton': 'onClickSubmit'
  },

  behaviors: {
    RadioGroupToggleBehavior: {
      radios: [
        {
          target: '.felony-circumstances-form-group',
          radioGroup: 'input[name="felony"]',
          valueToShow: '1'
        }
      ]
    },
    ReadOnlyFormBehavior: {}
  },

  templateHelpers: function() {
    return {
      isReadOnly: this.options.isReadOnly
    };
  },

  onBeforeShow: function() {
    this.showChildView(
      'address',
      new AddressView({
        horizontal: true,
        startingCountry: this.model.get('country'),
        startingPostalCode: this.model.get('postalCode'),
        startingCounty: this.model.get('county'),
        isRequired: true
      })
    );
    this.employmentProofUpload = new FileUploadView({
      isReadOnly: this.options.isReadOnly,
      isRequired: true,
      collection: makeUploadCollection(this.model.get('proofOfEmploymentDocument')),
      metadata: {
        documentTypeId: 10014
      }
    });

    this.applicationDocumentsFile = new FileUploadView({
      isRequired: true,
      allowMultipleFiles: true,
      collection: new FileUploadCollection(this.model.get('applicationDocuments')),
      isReadOnly: this.options.isReadOnly,
      metadata: {
        documentTypeId: 10023
      }
    });
    this.showChildView('applicationDocumentsFileContainer', this.applicationDocumentsFile);

    this.showChildView('employmentProofUploadTableContainer', this.employmentProofUpload);
    if (this.model.get('employeeName')) {
      Syphon.deserialize(this, this.model.toJSON());
    }

    if (this.options.mode === 'resubmit') {
      this.ui.isCertified.prop('checked', false);
      this.ui.certificationName.val(null);
    }
  },

  onClickSubmit: function(event) {
    event.preventDefault();
    var self = this;
    this.validate().done(function() {
      var laddaContext = Ladda.create(self.ui.submitButton.get(0));
      laddaContext.start();
      SubmitLicenseCollectorRepossessorEmployeeApplication(self.getFormData())
        .done(function(data) {
          Radio.channel('nd:submit-application').trigger('submit');
          Radio.channel('nd:submit-application').trigger('resubmit', laddaContext);
        })
        .fail(function(err) {
          laddaContext.stop();
        });
    });
  },

  getFormData: function() {
    var formData = Syphon.serialize(this);
    formData.proofOfEmploymentDocumentId = this.employmentProofUpload.getFirstFileId();
    formData.entityId = this.model.get('entityId');
    formData.licenseId = this.model.get('licenseId');
    formData.applicationId = this.model.get('applicationId');
    formData.applicationDocuments = this.applicationDocumentsFile.collection.pluck('fileId');
    return formData;
  },

  validate: function() {
    return $.when(this.ui.employeeInformationForm.parsley().whenValidate(), this.employmentProofUpload.validate());
  },

  onAttach: function() {
    if (this.options.mode === 'print') {
      switchToPrint(this.$el, '.input-view');
    }
  }
});
