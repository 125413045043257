/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var Marionette = require('backbone.marionette');

var SelectFieldItemView = require('./SelectFieldItemView');

module.exports = Marionette.CollectionView.extend({
  childView: SelectFieldItemView,

  options: {
    isDisabled: false,
    isRequired: false,
    emptyOption: '--',
    name: null,
    attributes: null,
    selectClass: null,
    multiple: false
  },

  tagName: 'select',

  className: function() {
    var baseClass = 'select-field-collection-view form-control';
    if (this.options.selectClass) {
      baseClass += ' ' + this.options.selectClass;
    }
    return baseClass;
  },

  onBeforeRender: function() {
    this.isDisabled = !!this.options.isDisabled;

    if (this.options.emptyOption) {
      this.collection.unshift({
        value: '',
        text: this.options.emptyOption
      });
    }
  },

  onRender: function() {
    var attributes = {};
    if (this.options.isRequired) {
      attributes['data-parsley-required'] = 'true';
    }
    if (this.options.name) {
      attributes.name = this.options.name;
    }
    if (this.options.multiple) {
      attributes.multiple = 'multiple';
      if (this.options.isRequired) {
        _.extend(attributes, {
          'data-parsley-validate-if-empty': 'true',
          'data-parsley-trigger-after-failure': 'change.select2'
        });
      }
    }
    if (this.options.attributes) {
      attributes = _.extend(attributes, this.options.attributes);
    }
    this.$el.attr(attributes);
    if (this.isDisabled) {
      this.$el.prop('disabled', true);
    }
  },

  setDisabled: function(isDisabled) {
    this.isDisabled = isDisabled;
    this.$el.prop('disabled', this.isDisabled);
    if (this.isDisabled) {
      this.$el.parsley().reset();
    }
    return this;
  },

  setIsRequired: function(isRequired) {
    this.options.isRequired = isRequired;

    if (isRequired) {
      this.$el.attr('data-parsley-required', 'true');
      if (this.options.multiple) {
        this.$el.attr({
          'data-parsley-validate-if-empty': 'true',
          'data-parsley-trigger-after-failure': 'change.select2'
        });
      }
    } else {
      this.$el.removeAttr('data-parsley-required');
      if (this.options.multiple) {
        this.$el.removeAttr('data-parsley-validate-if-empty').removeAttr('data-parsley-trigger-after-failure');
      }
    }
  },

  setValue: function(value) {
    this.$el.val(value).trigger('change');
    return this;
  },

  getValue: function() {
    return this.$el.val();
  }
});
