/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var config = require('../../config/Config');
var _ = require('underscore');
var cleanUpResponse = require('common/util/cleanUpResponse');

module.exports = function() {
  var defer = $.Deferred();

  $.soap({
    url: config.getAnonymousDoBSUrl(),
    body: {
      GetEntities: {
        $xmlns: 'http://dobs.pa.gov/instsearch/instsearch/db/1.0'
      }
    },
    blueprintArrays: ['GetEntitiesResponse.tuple'],
    blueprint: {
      entities: ['$..vw_FinancialInstitution']
    }
  })
    .done(function(response) {
      cleanUpResponse(response);
      _.each(response.unverifiedEntities, function(unverifiedEntity) {
        unverifiedEntity.entityName = unverifiedEntity.entityName || '--';
        unverifiedEntity.dba = unverifiedEntity.dba || '--';
      });

      _.each(response.entities, function(entities) {
        entities.Address = entities.Address || '--';
        entities.City = entities.City || '--';

        entities.Address =
          entities.Address +
          '\n' +
          entities.City +
          ', ' +
          entities.State +
          ' ' +
          entities.PostalCode +
          '\n' +
          entities.County;
      });

      defer.resolve(response.entities);
    })
    .fail(function(err) {
      defer.reject();
    });

  return defer.promise();
};

