/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var cleanUpResponse = require('common/util/cleanUpResponse');

module.exports = function(filingId) {
  var deferred = $.Deferred();
  $.soap({
    body: {
      GetBankConversionNationalToStateFiling: {
        $xmlns: 'http://dobs.pa.gov/dp/filing/bpm/1.0',
        FilingID: filingId
      }
    },
    blueprintArrays: [
      'GetBankConversionNationalToStateFilingResponse.SupportingDocuments.DP_FilingSupportingDocument',
      'GetBankConversionNationalToStateFilingResponse.FDICInterpretiveDocs.DP_FilBankParityFDICInterpretiveDocument',
      'GetBankConversionNationalToStateFilingResponse.AgencyInterpretiveDocs.DP_FilBankParityAgencyInterpretiveDocument'
    ],
    blueprint: {
      conversionToNationalId: '$..ID',
      filingId: '$..FilingID',
      branchesDoc: {
        file: {
          fileId: '$..BranchesDocID',
          fileName: '$..BranchesDocName'
        },
        comments: '$..BranchesComments'
      },
      publicationReqsDoc: {
        file: {
          fileId: '$..PublicationReqsDocID',
          fileName: '$..PublicationReqsDocName'
        },
        comments: '$..PublicationReqsComments'
      },
      subsidiariesDoc: {
        file: {
          fileId: '$..SubsidiariesDocID',
          fileName: '$..SubsidiariesDocName'
        },
        comments: '$..SubsidiariesComments'
      },
      affiliatesDoc: {
        file: {
          fileId: '$..AffiliatesDocID',
          fileName: '$..AffiliatesDocName'
        },
        comments: '$..AffiliatesComments'
      },
      articlesOfConversionDoc: {
        file: {
          fileId: '$..ArticlesOfConversionDocID',
          fileName: '$..ArticlesOfConversionDocName'
        },
        comments: '$..ArticlesOfConversionComments'
      },
      bylawsDoc: {
        file: {
          fileId: '$..BylawsDocID',
          fileName: '$..BylawsDocName'
        },
        comments: '$..BylawsComments'
      },
      nameReservationDoc: {
        file: {
          fileId: '$..NameReservationDocID',
          fileName: '$..NameReservationDocName'
        },
        comments: '$..NameReservationComments'
      },
      actionReason: '$..ActionReason',
      federalCharterId: '$..FederalCharterID',
      ownershipStructureId: '$..OwnershipStructureID',
      corporateStructureId: '$..CorporateStructureID',
      stateCharterId: '$..StateCharterID',
      hasTrustPowers: '$..HasTrustPowers',
      hasHCStructure: '$..HasHCStructure',
      federalReserveBank: '$..FederalReserveBank',
      supportingDocuments: [
        '$..DP_FilingSupportingDocument',
        { file: { fileId: '$.DocumentID', fileName: '$.DocumentName' } }
      ]
    }
  })
    .done(function(response) {
      cleanUpResponse(response);
      response.isFeesAgreement = response.isFeesAgreement === '1';
      deferred.resolve(response);
    })
    .fail(function(err) {
      deferred.reject();
    });

  return deferred.promise();
};
