/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Coulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remai
 * the property of Counterpoint Coulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Coulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Coulting Incorporated.
 */

var _ = require('underscore');
var moment = require('moment');

var momentUtil = require('common/util/momentUtil');
var cleanUpResponse = require('common/util/cleanUpResponse');

module.exports = function(formData) {
  var defer = $.Deferred();

  var issuerAddress = formData.issuerAddress || {};
  var corrAddress = formData.corrAddress || {};

  formData.filedDate = momentUtil.formatStr(formData.filedDate, momentUtil.defaultOutboundDateConfig);
  formData.dispositionDate = momentUtil.formatStr(formData.dispositionDate, momentUtil.defaultOutboundDateConfig);
  formData.recordCenterSentDate = momentUtil.formatStr(
    formData.recordCenterSentDate,
    momentUtil.defaultOutboundDateConfig
  );
  formData.dateDestroyed = momentUtil.formatStr(formData.dateDestroyed, momentUtil.defaultOutboundDateConfig);
  formData.securitiesEffectiveDate = momentUtil.formatStr(
    formData.securitiesEffectiveDate,
    momentUtil.defaultOutboundDateConfig
  );

  formData.increaseDate = momentUtil.formatStr(formData.increaseDate, momentUtil.defaultOutboundDateConfig);
  _.each(formData.new207Js, function(date) {
    date.reg207JDate = momentUtil.formatStr(date.reg207JDate, momentUtil.defaultOutboundDateConfig);
  });

  if (formData.recordName) {
    formData.recordName.trim();
  }

  $.soap({
    body: {
      SubmitRecord: {
        $xmlns: 'http://dobs.pa.gov/cf/record/bpm/1.0',
        RecordID: formData.recordId,
        RecordYear: formData.recordYear,
        RecordMonth: formData.recordMonth,
        RecordCategoryID: formData.recordCategoryId,
        IsDeleted: formData.isDeleted,
        IssuerAddress: {
          AddressLine1: issuerAddress.addressLine1,
          AddressLine2: issuerAddress.addressLine2,
          AddressLine3: issuerAddress.addressLine3,
          City: issuerAddress.city,
          State: issuerAddress.state,
          County: issuerAddress.county,
          PostalCode: issuerAddress.postalCode,
          Country: issuerAddress.country
        },
        CorresAddress: {
          AddressLine1: corrAddress.addressLine1,
          AddressLine2: corrAddress.addressLine2,
          AddressLine3: corrAddress.addressLine3,
          City: corrAddress.city,
          State: corrAddress.state,
          County: corrAddress.county,
          PostalCode: corrAddress.postalCode,
          Country: corrAddress.country
        },
        RecordTypeID: formData.recordTypeId,
        RecordStatusID: formData.statusId,
        RecordName: formData.recordName,
        InvestmentCompanyName: formData.investmentCompanyName,
        ExemptionIssuerName: formData.exemptionIssuerName,
        FiledDate: formData.filedDate,
        IsIndefiniteOffering: formData.isIndefiniteOffering,
        IsNoDollarAmount: formData.isNoDollarAmount,
        OfferingTypeID: formData.offeringTypeId,
        DispositionDate: formData.dispositionDate,
        TotalOfferingAmount: formData.totalOfferingAmount,
        PAOfferingAmount: formData.PAOfferingAmount,
        IncreaseAmount: formData.increaseAmount,
        SecuritiesDescription: formData.securitiesDescription,
        SecuritiesEffectiveDate: formData.securitiesEffectiveDate,
        FYEOn: formData.fyeOn,
        FYEChange: formData.fyeChange,
        BusinessDescription: formData.businessDescription,
        StateOfFormation: formData.stateOfFormation,
        EntityTypeID: formData.entityTypeId,
        Examiner: formData.examiner,
        RecordCenterSentDate: formData.recordCenterSentDate,
        DestroyedDate: formData.dateDestroyed,
        IncreaseDate: formData.increaseDate,
        CorresFirstName: formData.corrFirstName,
        CorresLastName: formData.corrLastName,
        CorresMiddleInitial: formData.corrMiddleInitial,
        CorresSuffix: formData.corrSuffix,
        CorresLawFirm: formData.corrLawFirm,
        Accountant: formData.accountant,
        CorresPhone: formData.corrPhone,
        CorresExt: formData.corrExt,
        CorresEmail: formData.corrEmail,
        CurrentRecordName: formData.currentRecordName,
        ChangeDate: moment().format('YYYY-MM-DD'),
        Existing207Js: (function() {
          if (!formData.existing207Js || !formData.existing207Js.length) {
            return {};
          } else {
            return {
              Reg207JDateID: formData.existing207Js
            };
          }
        })(),
        New207Js: (function() {
          if (!formData.new207Js || !formData.new207Js.length) {
            return {};
          } else {
            return {
              Reg207JDate: formData.new207Js
            };
          }
        })()
      }
    },
    blueprint: {
      newRecordId: '$..NewRecordID.__text'
    }
  })
    .done(function(response) {
      cleanUpResponse(response);
      defer.resolve(response.newRecordId);
    })
    .fail(function(err) {
      defer.reject(err);
    });

  return defer.promise();
};
