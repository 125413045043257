var Handlebars = require("handlebars/dist/handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "    <div class=\"row\">\n      <div class=\"col-sm-12\">\n        <div class=\"form-group\">\n          <label class=\"control-label col-sm-3\">Fiscal Year End Date</label>\n          <div class=\"col-sm-9 col-md-6\">\n            <p class=\"form-control-static\">"
    + container.escapeExpression((helpers["default"] || (depth0 && depth0["default"]) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? depth0.correspondence : depth0)) != null ? stack1.fiscalYearEndDate : stack1),"--",{"name":"default","hash":{},"data":data}))
    + "</p>\n          </div>\n        </div>\n      </div>\n    </div>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), alias4=helpers.helperMissing;

  return "\n<div class=\"page-title clearfix\">\n  <h1 class=\"pull-left m-n p-n\">"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? depth0.task : depth0)) != null ? stack1.taskType : stack1)) != null ? stack1.description : stack1), depth0))
    + " for "
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.correspondence : depth0)) != null ? stack1.orgLegalName : stack1), depth0))
    + "</h1>\n</div>\n<div class=\"header-bar\"></div>\n<div class=\"breadcrumb-container\"></div>\n<div class=\"task-status\"></div>\n<div class=\"form-horizontal\">\n  <form class=\"correspondence-form\">\n    <div class=\"row\">\n      <div class=\"col-sm-12\">\n        <div class=\"form-group\">\n          <label class=\"control-label col-sm-3\">DoBS Contact Name</label>\n          <div class=\"col-sm-9 col-md-6\">\n            <p class=\"form-control-static \">"
    + alias2((helpers["default"] || (depth0 && depth0["default"]) || alias4).call(alias3,((stack1 = (depth0 != null ? depth0.correspondence : depth0)) != null ? stack1.repliedByName : stack1),"--",{"name":"default","hash":{},"data":data}))
    + "</p>\n          </div>\n        </div>\n      </div>\n    </div>\n    <div class=\"row\">\n      <div class=\"col-sm-12\">\n        <div class=\"form-group\">\n          <label class=\"control-label col-sm-3\">Organization Email</label>\n          <div class=\"col-sm-9 col-md-6\">\n            <p class=\"form-control-static \">"
    + alias2((helpers["default"] || (depth0 && depth0["default"]) || alias4).call(alias3,((stack1 = (depth0 != null ? depth0.correspondence : depth0)) != null ? stack1.repliedByEmail : stack1),"--",{"name":"default","hash":{},"data":data}))
    + "</p>\n          </div>\n        </div>\n      </div>\n    </div>\n    <div class=\"row\">\n      <div class=\"col-sm-12\">\n        <div class=\"form-group\">\n          <label class=\"control-label col-sm-3\">Reply Date</label>\n          <div class=\"col-sm-9 col-md-6\">\n            <p class=\"form-control-static \">"
    + alias2((helpers["default"] || (depth0 && depth0["default"]) || alias4).call(alias3,((stack1 = (depth0 != null ? depth0.correspondence : depth0)) != null ? stack1.repliedOn : stack1),"--",{"name":"default","hash":{},"data":data}))
    + "</p>\n          </div>\n        </div>\n      </div>\n    </div>\n    <div class=\"row\">\n      <div class=\"col-sm-9 col-sm-offset-3\">\n        <h2>Correspondence</h2>\n      </div>\n    </div>\n    <div class=\"row\">\n      <div class=\"col-sm-12\">\n        <div class=\"form-group\">\n          <label class=\"control-label col-sm-3\">Message From Dobs</label>\n          <div class=\"col-sm-9 col-md-6 form-control-static\">\n            "
    + alias2((helpers["default"] || (depth0 && depth0["default"]) || alias4).call(alias3,((stack1 = (depth0 != null ? depth0.correspondence : depth0)) != null ? stack1.requestComments : stack1),"--",{"name":"default","hash":{},"data":data}))
    + "\n          </div>\n        </div>\n      </div>\n      <label class=\"control-label col-sm-3\"></label>\n      <div class=\"col-sm-9 col-md-6\">\n          Please be advised that all representations in your response are subject to the \n              penalties of 18 Pa. C.S. §4904 (relating to unsworn falsification to authorities).  \n              Furthermore, your response will be considered a document filed with the \n              Department of Banking and Securities for purposes of Section 407 of the 1972 Act, \n              70 P.S. §1-407.\n      </div>\n    </div>\n    <div class=\"row\">\n      <div class=\"col-sm-12\">\n        <div class=\"form-group\">\n          <label class=\"control-label col-sm-3\">Reply Comments</label>\n          <div class=\"col-sm-9 col-md-6\">\n            <p class=\"form-control-static\">"
    + alias2((helpers["default"] || (depth0 && depth0["default"]) || alias4).call(alias3,((stack1 = (depth0 != null ? depth0.correspondence : depth0)) != null ? stack1.replyComments : stack1),"--",{"name":"default","hash":{},"data":data}))
    + "</p>\n          </div>\n        </div>\n      </div>\n    </div>\n    <div class=\"row\">\n      <div class=\"col-sm-9 col-sm-offset-3\">\n        <h2>Documents</h2>\n      </div>\n    </div>\n    <div class=\"row\">\n      <div class=\"col-sm-12\">\n        <div class=\"form-group\">\n          <label class=\"control-label col-sm-3 \">Internal Documents</label>\n          <div class=\" col-sm-9 col-md-6\">\n            <div class=\"int-file-upload-table-container\"></div>\n          </div>\n        </div>\n      </div>\n    </div>\n    <div class=\"row\">\n      <div class=\"col-sm-12\">\n        <div class=\"form-group\">\n          <label class=\"control-label col-sm-3 \">External Documents</label>\n          <div class=\" col-sm-9 col-md-6\">\n            <div class=\"ext-file-upload-table-container\"></div>\n          </div>\n        </div>\n      </div>\n    </div>\n"
    + ((stack1 = helpers["if"].call(alias3,(depth0 != null ? depth0.hasFinDocs : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "  </form>\n  <div class=\"form-group\">\n    <div class=\"col-sm-9 col-lg-7 col-sm-offset-3\">\n      <a href=\"#sec/dashboard\" class=\"btn btn-default\" role=\"button\">Cancel</a>\n      <button type=\"button\" class=\"btn btn-primary submit\">Submit</button>\n      <div class=\"task-actions\"></div>\n    </div>\n  </div>\n</div>";
},"useData":true});