var Handlebars = require("handlebars/dist/handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression;

  return "\n<h1 class=\"page-title\">"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.exam : depth0)) != null ? stack1.entityName : stack1), depth0))
    + "</h1>\n<div class=\"header-bar\"></div>\n<div class=\"breadcrumb-container\"></div>\n\n<form class=\"form-horizontal update-billing-details-form\">\n  <div class=\"form-group\">\n    <label class=\"control-label col-md-3\">Refund Amount</label>\n    <div class=\"col-md-3\">\n      <div class=\"input-group\">\n        <span class=\"input-group-addon\">$</span>\n        <input type=\"number\" step=\"any\" name=\"refundAmountOverride\" class=\"form-control\"\n          placeholder=\"Override Refund Amount\" data-parsley-dollar-amount=\"true\"\n          data-parsley-errors-container=\".refund-amount-error-container\" data-parsley-strict-minimum=\"0\" />\n      </div>\n      <div class=\"refund-amount-error-container\"></div>\n    </div>\n  </div>\n  <div class=\"form-group\">\n    <label class=\"control-label col-md-3\">Refund Comments</label>\n    <div class=\"col-md-8\">\n      <textarea class=\"form-control\" name=\"billingComments\" rows=\"8\"\n        placeholder=\"Documentation of Correspondence\"></textarea>\n    </div>\n  </div>\n</form>\n\n<div class=\"row m-b-lg\">\n  <div class=\"col-md-6 col-md-offset-3\">\n    <a href=\"#nondep/dashboard/exam/"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.exam : depth0)) != null ? stack1.examId : stack1), depth0))
    + "\" class=\"btn btn-default\" role=\"button\">Cancel</a>\n    <button class=\"btn btn-primary submit ladda-button\" data-style=\"expand-right\" type=\"button\">\n      <span class=\"ladda-label\">Save Refund Details</span>\n    </button>\n  </div>\n</div>";
},"useData":true});