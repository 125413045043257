var Handlebars = require("handlebars/dist/handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "        <ul class=\"list-unstyled\">\n"
    + ((stack1 = helpers.each.call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.reg207JDates : depth0),{"name":"each","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "        </ul>\n";
},"2":function(container,depth0,helpers,partials,data) {
    var helper;

  return "          <li>\n           "
    + container.escapeExpression(((helper = (helper = helpers.reg207JDateStr || (depth0 != null ? depth0.reg207JDateStr : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"reg207JDateStr","hash":{},"data":data}) : helper)))
    + "\n          </li>\n";
},"4":function(container,depth0,helpers,partials,data) {
    return "        --\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "\n<div class=\"row\">\n  <div class=\"col-sm-12 col-md-6\">\n    <div class=\"form-group\">\n      <label class=\"control-label col-sm-3 col-md-4\">Date Filed</label>\n      <div class=\"form-control-static col-sm-9 col-md-8\">"
    + alias3((helpers["default"] || (depth0 && depth0["default"]) || alias2).call(alias1,(depth0 != null ? depth0.filedDate : depth0),"--",{"name":"default","hash":{},"data":data}))
    + "</div>\n    </div>\n  </div>\n  <div class=\"col-sm-12 col-md-6\">\n    <div class=\"form-group\">\n      <label class=\"control-label col-sm-3 col-md-4\">Disposition Date</label>\n      <div class=\"form-control-static col-sm-9 col-md-8\">"
    + alias3((helpers["default"] || (depth0 && depth0["default"]) || alias2).call(alias1,(depth0 != null ? depth0.dispositionDate : depth0),"--",{"name":"default","hash":{},"data":data}))
    + "</div>\n    </div>\n  </div>\n</div>\n<div class=\"row\">\n  <div class=\"col-sm-12 col-md-6 is-indefinite-offering collapse\">\n    <div class=\"form-group\">\n      <label class=\"control-label col-sm-3 col-md-4\">Indefinite Offering</label>\n      <div class=\"form-control-static col-sm-9 col-md-8\">"
    + alias3((helpers["default"] || (depth0 && depth0["default"]) || alias2).call(alias1,(depth0 != null ? depth0.isIndefiniteOfferingDisplay : depth0),"--",{"name":"default","hash":{},"data":data}))
    + "</div>\n    </div>\n  </div>\n  <div class=\"col-sm-12 col-md-6 offering-type collapse\">\n    <div class=\"form-group\">\n      <label class=\"control-label col-sm-3 col-md-4\">Offering Type</label>\n      <div class=\"form-control-static col-sm-9 col-md-8\">"
    + alias3((helpers["default"] || (depth0 && depth0["default"]) || alias2).call(alias1,(depth0 != null ? depth0.offeringType : depth0),"--",{"name":"default","hash":{},"data":data}))
    + "</div>\n    </div>\n  </div>\n</div>\n<div class=\"row offering-amounts\">\n  <div class=\"col-sm-12 col-md-6\">\n    <div class=\"form-group\">\n      <label class=\"control-label col-sm-3 col-md-4\">Total Offering Amount</label>\n      <div class=\"form-control-static col-sm-9 col-md-8\">"
    + alias3((helpers["default"] || (depth0 && depth0["default"]) || alias2).call(alias1,(depth0 != null ? depth0.totalOfferingAmountDisplay : depth0),"--",{"name":"default","hash":{},"data":data}))
    + "</div>\n    </div>\n  </div>\n  <div class=\"col-sm-12 col-md-6\">\n    <div class=\"form-group\">\n      <label class=\"control-label col-sm-3 col-md-4\">PA Offering Amount</label>\n      <div class=\"form-control-static col-sm-9 col-md-8\">"
    + alias3((helpers["default"] || (depth0 && depth0["default"]) || alias2).call(alias1,(depth0 != null ? depth0.PAOfferingAmountDisplay : depth0),"--",{"name":"default","hash":{},"data":data}))
    + "</div>\n    </div>\n  </div>\n</div>\n<div class=\"row\">\n  <div class=\"col-sm-12 col-md-6\">\n    <div class=\"form-group\">\n      <label class=\"control-label col-sm-3 col-md-4\">Increase Amount</label>\n      <div class=\"form-control-static col-sm-9 col-md-8\">"
    + alias3((helpers["default"] || (depth0 && depth0["default"]) || alias2).call(alias1,(depth0 != null ? depth0.increaseAmountDisplay : depth0),"--",{"name":"default","hash":{},"data":data}))
    + "</div>\n    </div>\n  </div>\n  <div class=\"col-sm-12 col-md-6\">\n    <div class=\"form-group\">\n      <label class=\"control-label col-sm-3 col-md-4\">Increase Date</label>\n      <div class=\"form-control-static col-sm-9 col-md-8\">"
    + alias3((helpers["default"] || (depth0 && depth0["default"]) || alias2).call(alias1,(depth0 != null ? depth0.increaseDate : depth0),"--",{"name":"default","hash":{},"data":data}))
    + "</div>\n    </div>\n  </div>\n</div>\n<div class=\"row 207J-dates collapse\">\n  <div class=\"col-sm-12 col-md-6\">\n    <div class=\"form-group\">\n      <label class=\"control-label col-sm-3 col-md-4\">207J Dates</label>\n      <div class=\"form-control-static col-sm-9 col-md-8\">\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.reg207JDates : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(4, data, 0),"data":data})) != null ? stack1 : "")
    + "      </div>\n    </div>\n  </div>\n</div>\n<div class=\"row\">\n  <div class=\"col-sm-12 col-md-6\">\n    <div class=\"form-group\">\n      <label class=\"control-label col-sm-3 col-md-4\">Business Description</label>\n      <div class=\"form-control-static col-sm-9 col-md-8\">"
    + alias3((helpers["default"] || (depth0 && depth0["default"]) || alias2).call(alias1,(depth0 != null ? depth0.businessDescription : depth0),"--",{"name":"default","hash":{},"data":data}))
    + "</div>\n    </div>\n  </div>\n  <div class=\"col-sm-12 col-md-6\">\n    <div class=\"form-group\">\n      <label class=\"control-label col-sm-3 col-md-4\">State of Formation</label>\n      <div class=\"form-control-static col-sm-9 col-md-8\">"
    + alias3((helpers["default"] || (depth0 && depth0["default"]) || alias2).call(alias1,(depth0 != null ? depth0.stateOfFormation : depth0),"--",{"name":"default","hash":{},"data":data}))
    + "</div>\n    </div>\n  </div>\n</div>\n<div class=\"row\">\n  <div class=\"col-sm-12 col-md-6\">\n    <div class=\"form-group\">\n      <label class=\"control-label col-sm-3 col-md-4\">Entity Type</label>\n      <div class=\"form-control-static col-sm-9 col-md-8\">"
    + alias3((helpers["default"] || (depth0 && depth0["default"]) || alias2).call(alias1,(depth0 != null ? depth0.entityType : depth0),"--",{"name":"default","hash":{},"data":data}))
    + "</div>\n    </div>\n  </div>\n  <div class=\"col-sm-12 col-md-6\">\n    <div class=\"form-group\">\n      <label class=\"control-label col-sm-3 col-md-4\">Examiner</label>\n      <div class=\"form-control-static col-sm-9 col-md-8\">"
    + alias3((helpers["default"] || (depth0 && depth0["default"]) || alias2).call(alias1,(depth0 != null ? depth0.examiner : depth0),"--",{"name":"default","hash":{},"data":data}))
    + "</div>\n    </div>\n  </div>\n</div>\n<div class=\"row\">\n  <div class=\"col-sm-12 col-md-6\">\n    <div class=\"form-group\">\n      <label class=\"control-label col-sm-3 col-md-4\">Date Sent to Record Center</label>\n      <div class=\"form-control-static col-sm-9 col-md-8\">"
    + alias3((helpers["default"] || (depth0 && depth0["default"]) || alias2).call(alias1,(depth0 != null ? depth0.recordCenterSentDate : depth0),"--",{"name":"default","hash":{},"data":data}))
    + "</div>\n    </div>\n  </div>\n  <div class=\"col-sm-12 col-md-6\">\n    <div class=\"form-group\">\n      <label class=\"control-label col-sm-3 col-md-4\">Date Destroyed</label>\n      <div class=\"form-control-static col-sm-9 col-md-8\">"
    + alias3((helpers["default"] || (depth0 && depth0["default"]) || alias2).call(alias1,(depth0 != null ? depth0.dateDestroyed : depth0),"--",{"name":"default","hash":{},"data":data}))
    + "</div>\n    </div>\n  </div>\n</div>";
},"useData":true});