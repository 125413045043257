/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var Marionette = require('backbone.marionette');

var formatAsDollars = require('common/util/formatAsDollars');

var tmplSecComplianceAGReferralTotalsView = require('../templates/SecComplianceAGReferralTotalsView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplSecComplianceAGReferralTotalsView,

  className: 'sec-compliance-ag-referral-totals-view',

  onBeforeRender: function() {
    this.calculateAndFormatAGPayments();
  },

  calculateAndFormatAGPayments: function() {
    var referredAmount = parseFloat(this.model.get('AGReferredAmount')) || 0;
    var AGPaymentsList = this.model.get('AGPayments') || {};

    // Get total AG payments paid
    var AGPaid = 0;
    _.each(AGPaymentsList, function(AGPayment) {
      AGPaid += parseFloat(AGPayment.amount);
    });
    var AGDue = referredAmount - AGPaid;

    // Format payments
    var referredAmountFormatted = formatAsDollars(referredAmount);
    this.model.set('referredAmountFormatted', referredAmountFormatted);

    var AGPaidFormatted = formatAsDollars(AGPaid);
    this.model.set('AGPaidFormatted', AGPaidFormatted);

    var AGDueFormatted = formatAsDollars(AGDue);
    this.model.set('AGDueFormatted', AGDueFormatted);
  }
});
