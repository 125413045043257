/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Marionette = require('backbone.marionette');
var Ladda = require('ladda');
var Radio = require('backbone.radio');

var BreadcrumbView = require('common/views/BreadcrumbView');

var DepProvisionFormView = require('./DepProvisionFormView');

var SubmitProvision = require('../services/SubmitProvision');

var tmplDepProvisionEditView = require('../templates/DepProvisionEditView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplDepProvisionEditView,

  className: 'dep-provision-edit-view container-fluid',

  ui: {
    provisionForm: '.provision-form-container',
    breadcrumb: '.breadcrumb-container',
    submit: '.btn-submit'
  },

  regions: {
    provisionForm: '@ui.provisionForm',
    breadcrumb: '@ui.breadcrumb'
  },

  events: {
    'click @ui.submit': 'onClickSubmit'
  },

  onBeforeShow: function() {
    var enforcementIdentifiers = this.model.get('enforcementIdentifiers');
    var enforcementAction = enforcementIdentifiers.action;
    var effectiveDate = enforcementIdentifiers.effectiveDate;
    var entityName = enforcementIdentifiers.entityName;
    var enforcementId = this.model.get('enforcementId');
    var entityId = this.model.get('entityId');

    this.showChildView(
      'breadcrumb',
      new BreadcrumbView({
        breadcrumbs: [
          {
            link: '#dep/dashboard',
            text: 'Home'
          },
          {
            link: '#dep/entity/' + entityId + '/dashboard',
            text: entityName
          },
          {
            link: '#dep/entity/' + entityId + '/enforcement/' + enforcementId + '/dashboard',
            text: enforcementAction + ' - Effective as of ' + effectiveDate
          },
          {
            text: 'Edit Provision Details'
          }
        ]
      })
    );
    this.provisionForm = new DepProvisionFormView({
      model: this.model
    });

    this.showChildView('provisionForm', this.provisionForm);
  },

  onClickSubmit: function(e) {
    e.preventDefault();
    this.validate().done(
      function() {
        var laddaContext = Ladda.create(this.ui.submit.get(0));
        laddaContext.start();
        var formData = this.getFormData();
        SubmitProvision(formData)
          .done(function() {
            Radio.channel('navigate').trigger('show:int:dp:enforcement', formData.entityId, formData.enforcementId);
          })
          .fail(function() {
            laddaContext.stop();
          });
      }.bind(this)
    );
  },

  validate: function() {
    return this.ui.provisionForm.parsley().whenValidate();
  },

  getFormData: function() {
    return this.provisionForm.getFormData();
  }
});
