/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');

var Marionette = require('backbone.marionette');
var Syphon = require('backbone.syphon');
var Ladda = require('ladda');
var Radio = require('backbone.radio');

var BreadcrumbView = require('common/views/BreadcrumbView');
var HeaderBarView = require('common/views/HeaderBarView');
var FileUploadView = require('common/views/FileUploadView');

var SubmitNewCorrespondence = require('../services/SubmitNewCorrespondence');

var tmplConSerComplaintCorrespondenceNewView = require('../templates/ConSerComplaintCorrespondenceNewView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplConSerComplaintCorrespondenceNewView,

  className: 'con-ser-complaint-correspondence-new-view container-fluid',

  ui: {
    breadcrumb: '.breadcrumb-container',
    headerBar: '.header-bar-container',
    documentContainer: '.document-container',
    correspondenceForm: '.correspondence-form',
    missingFile: '.missing-file',
    comments: '.comments',
    recipient: '.recipient',
    submit: '.submit',
    missingRecipient: '.missing-recipient'
  },

  regions: {
    breadcrumb: '@ui.breadcrumb',
    headerBar: '@ui.headerBar',
    documentContainer: '@ui.documentContainer'
  },

  events: {
    'click @ui.submit': 'onClickSubmit'
  },

  onBeforeShow: function() {
    var complaint = this.model.get('complaint');
    var complaintId = this.model.get('complaintId');
    var entity = this.model.get('entity');
    var caseManager = _.find(this.model.get('caseManagers'), function(caseManager) {
      return caseManager.userId === complaint.caseManagerId;
    });

    var headerBarData = {
      entityName: entity.entityName || '--',
      complainantName: complaint.complainantName || '--',
      caseManagerName: caseManager ? caseManager.name + ' - (' + caseManager.userId + ')' : '--'
    };

    this.showChildView(
      'breadcrumb',
      new BreadcrumbView({
        breadcrumbs: [
          {
            link: '#conser/dashboard',
            text: 'Home'
          },
          {
            link: '#conser/complaint/' + complaintId + '/dashboard',
            text: 'Complaint - ' + complaint.caseNumber
          },
          {
            text: 'Initiate Correspondence'
          }
        ]
      })
    );

    var headerBarColumns = [
      {
        name: 'entityName',
        label: 'Entity Name',
        minWidth: '160px',
        maxWidth: '200px'
      },
      {
        name: 'complainantName',
        label: 'Consumer Name',
        minWidth: '160px',
        maxWidth: '200px'
      },
      {
        name: 'caseManagerName',
        label: 'Case Manager',
        minWidth: '160px',
        maxWidth: '200px'
      }
    ];

    this.showChildView(
      'headerBar',
      new HeaderBarView({
        columns: new Backbone.Collection(headerBarColumns),
        model: new Backbone.Model(headerBarData)
      })
    );

    this.fileUploadView = new FileUploadView({
      isReadOnly: false,
      isRequired: true,
      allowMultipleFiles: true,
      metadata: {
        documentTypeId: 40001
      }
    });
    this.showChildView('documentContainer', this.fileUploadView);
  },

  removeValidationErrors: function() {
    this.ui.missingFile.hide();
  },

  validate: function() {
    var defer = $.Deferred();
    this.removeValidationErrors();
    var formData = this.getFormData();

    var hasOneSuccessfulFileOrComment = true;
    var hasComments = !!this.ui.comments.val();
    var hasRecipient = formData.recipient === '' ? false : true;
    var uploads = this.fileUploadView.collection;

    if (!uploads.length && !hasComments) {
      hasOneSuccessfulFileOrComment = false;
    } else {
      var hasSuccessfulFile;
      if (uploads) {
        hasSuccessfulFile = uploads.some(function(model) {
          return model.get('fileId');
        });
      }
      hasOneSuccessfulFileOrComment = hasComments || hasSuccessfulFile;
    }

    this.ui.missingFile.hide();
    this.ui.missingRecipient.hide();
    if (!hasOneSuccessfulFileOrComment || !hasRecipient) {
      if (!hasOneSuccessfulFileOrComment) {
        this.ui.missingFile.show();
      }
      if (!hasRecipient) {
        this.ui.missingRecipient.show();
      }
      defer.reject();
    } else {
      defer.resolve();
    }

    return defer.promise();
  },

  getFormData: function() {
    var formData = Syphon.serialize(this);
    formData.documents = this.fileUploadView.collection.pluck('fileId');
    return formData;
  },

  onClickSubmit: function(event) {
    event.preventDefault();
    var self = this;
    var complaintId = this.model.get('complaintId');

    this.validate().done(function() {
      var l = Ladda.create(self.ui.submit[0]);
      l.start();
      var formData = self.getFormData();
      formData.complaintId = complaintId;

      SubmitNewCorrespondence(formData)
        .done(function() {
          Radio.channel('navigate').trigger('show:int:cs:complaint', complaintId);
        })
        .fail(function() {
          l.stop();
        });
    });
  }
});
