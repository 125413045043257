/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Marionette = require('backbone.marionette');
var Syphon = require('backbone.syphon');

var USStateSelectFieldView = require('common/views/USStateSelectFieldView');
var parsleyUtil = require('common/util/parsleyUtil');
var modelGet = require('common/util/modelGet');

var tmplConsumerDiscountAnnualReportGeneralDetailsSectionView = require('./ConsumerDiscountAnnualReportGeneralDetailsSectionView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplConsumerDiscountAnnualReportGeneralDetailsSectionView,

  className: 'consumer-discount-annual-report-general-details-section-view',

  ui: {
    generalDetailsForm: '.general-details-form',
    stateSelectContainer: '.state-select-container',
    allStatesCheckbox: '.all-states-checkbox'
  },

  events: {
    'change @ui.allStatesCheckbox': 'onChangeAllStatesCheckbox'
  },

  regions: {
    stateSelectContainer: '@ui.stateSelectContainer'
  },

  behaviors: {
    PhoneFormatBehavior: {}
  },

  onBeforeShow: function() {
    Syphon.deserialize(this, this.model.get('annualReport'));

    this.stateSelect = new USStateSelectFieldView({
      emptyOption: false,
      name: 'operatingStateCodes',
      isRequired: true,
      multiple: true
    });
    this.showChildView('stateSelectContainer', this.stateSelect);
    this.stateSelect.$el.val(null).select2({
      placeholder: 'Select states'
    });

    // Toggle state selection input based on answer to operating in all states question
    var isOperatingInAllStates = modelGet(this.model, 'annualReport.isOperatingInAllStates');
    this.toggleStateSelectActive(isOperatingInAllStates);

    // Set the value of the Operating States field manually because
    // Backbone.syphon cannot deserialize into a multiselect
    var operatingStateCodes = modelGet(this.model, 'annualReport.operatingStateCodes');
    this.stateSelect.setValue(operatingStateCodes);
  },

  onChangeAllStatesCheckbox: function() {
    var allStatesChecked = this.isAllStatesChecked();
    this.toggleStateSelectActive(allStatesChecked);
  },

  validate: function(config) {
    if (config.type === 'save') {
      this.stateSelect.setIsRequired(false);
      parsleyUtil.disableRequiredValidation(this.ui.generalDetailsForm);
    } else {
      this.stateSelect.setIsRequired(true);
      parsleyUtil.enableRequiredValidation(this.ui.generalDetailsForm);
    }
    return this.ui.generalDetailsForm.parsley().whenValidate();
  },

  getFormData: function() {
    return Syphon.serialize(this);
  },

  toggleStateSelectActive: function(isActive) {
    this.stateSelect.setValue(null).setDisabled(isActive);
  },

  isAllStatesChecked: function() {
    return this.ui.allStatesCheckbox.prop('checked');
  }
});
