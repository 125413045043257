/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Backbone = require('backbone');
var Marionette = require('backbone.marionette');

var AddressDisplayView = require('common/views/AddressDisplayView');

require('../styles/NonDepEntityOfficerView.css');
var tmplNonDepEntityOfficerView = require('../templates/NonDepEntityOfficerView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplNonDepEntityOfficerView,

  className: 'nondep-entity-officer-view panel panel-default panel-row-view',

  ui: {
    officerAddress: '.officer-address'
  },

  regions: {
    officerAddress: '@ui.officerAddress'
  },

  templateHelpers: function() {
    var accessLevelId = this.options.userAccessLevel.accessLevelId,
      isNMLSEntity = this.options.isNMLSEntity,
      isVerified = this.options.isVerified,
      externalManagerAccessLevelId = 20;
    return {
      viewMode: this.options.viewMode,
      showEditButton: accessLevelId >= externalManagerAccessLevelId && !isNMLSEntity && isVerified
    };
  },

  onBeforeShow: function() {
    this.showChildView(
      'officerAddress',
      new AddressDisplayView({
        model: new Backbone.Model(this.model.get('address'))
      })
    );
  }
});
