/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2020] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var Backbone = require('backbone');
var Marionette = require('backbone.marionette');
var Syphon = require('backbone.syphon');
var Radio = require('backbone.radio');
var Ladda = require('ladda');

var AddressDisplayView = require('common/views/AddressDisplayView');
var BreadcrumbView = require('common/views/BreadcrumbView');
var momentUtil = require('common/util/momentUtil');
var toggleForm = require('common/util/toggleForm');
var addressToString = require('common/util/addressToString');
var modelGet = require('common/util/modelGet');

var SecExamHeaderBarView = require('./SecExamHeaderBarView');
var SubmitExam = require('../services/SubmitExam');

require('../styles/SecExamGeneralEditView.css');
var tmplSecExamGeneralEditView = require('../templates/SecExamGeneralEditView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplSecExamGeneralEditView,

  className: 'sec-exam-general-edit-view container-fluid',

  ui: {
    headerBar: '.header-bar-container',
    breadcrumb: '.breadcrumb-container',
    examGeneralForm: '.exam-general-form',
    datePickerField: '.date-picker',
    status: '.status',
    categorySelect: 'select[name="categoryId"]',
    sourceSection: '.source-section',
    violationSelect: '.violation-select',
    otherViolation: '.other-violation',
    subTypeSelect: 'select[name="subtypeId"]',
    branchSection: '.branch-section',
    branchAddressSelect: '.branch-address-select',
    closedDate: '.closed-date',
    closedDateRequired: '.closed-date-required',
    submit: '.submit'
  },

  regions: {
    headerBar: '@ui.headerBar',
    breadcrumb: '@ui.breadcrumb'
  },

  events: {
    'change @ui.status': 'onChangeStatus',
    'change @ui.categorySelect': 'onChangeCategory',
    'change @ui.subTypeSelect': 'onChangeSubType',
    'change @ui.violationSelect': 'onChangeViolationSelect',
    'click @ui.submit': 'onClickSubmit'
  },

  templateHelpers: function() {
    var examiners = modelGet(this.model, 'examiners', []);
    var examiner = modelGet(this.model, 'exam.primaryExaminer');
    var examinerFirstName = modelGet(this.model, 'exam.primaryExaminerFirstName');
    var examinerLastName = modelGet(this.model, 'exam.primaryExaminerLastName');

    /*
     * Current examiner could no longer have examiner role, add current examiner
     * with internal user data if username does not exist in examiners array
     */
    var currentExaminerHasRole = _.find(examiners, function(cm) {
      return examiner === cm.userId;
    });

    if (examiner && !currentExaminerHasRole) {
      var currentExaminer = {
        userId: examiner,
        name: !examinerFirstName && !examinerLastName ? '' : examinerFirstName + ' ' + examinerLastName
      };
      examiners.splice(_.sortedIndex(examiners, currentExaminer, 'name'), 0, currentExaminer);
    }

    return {
      examiners: examiners
    };
  },

  onBeforeShow: function() {
    var examIdentifiers = this.model.get('examIdentifiers') || {};
    var exam = this.model.get('exam') || {};
    var examId = this.model.get('examId');

    var violations = _.pluck(exam.violations, 'violationTypeId');

    this.showChildView('headerBar', new SecExamHeaderBarView({ model: new Backbone.Model(examIdentifiers) }));

    this.showChildView(
      'breadcrumb',
      new BreadcrumbView({
        breadcrumbs: [
          {
            link: '#sec/dashboard',
            text: 'Home'
          },
          {
            link: '#sec/organization/' + examIdentifiers.organizationId + '/dashboard',
            text: examIdentifiers.applicantLegalName
          },
          {
            link:
              '#sec/organization/' +
              examIdentifiers.organizationId +
              '/license/' +
              examIdentifiers.organizationLicenseId +
              '/dashboard',
            text: examIdentifiers.licenseType
          },
          {
            link: '#sec/exam/' + examId + '/dashboard',
            text: 'Exam (' + examIdentifiers.examNumber + ')'
          },
          {
            text: 'Edit Exam'
          }
        ]
      })
    );

    var isClosedDateRequired = exam.statusId !== '1';
    toggleForm(this.ui.closedDate, !isClosedDateRequired);
    toggleForm(this.ui.closedDateRequired, isClosedDateRequired);

    toggleForm(this.ui.otherViolation, exam.hasOtherViolation);

    // Hide source section when exam category is 'Routine Examination'
    toggleForm(this.ui.sourceSection, exam.categoryId !== '2');

    // Show branch section when exam subType is 'Branch'
    toggleForm(this.ui.branchSection, exam.subtypeId === '2');

    Syphon.deserialize(this, exam);

    this.ui.datePickerField.datepicker({});

    this.ui.violationSelect.select2({
      allowClear: true,
      multiple: true,
      data: this.model.get('examInvInqViolationTypes'),
      templateResult: function(violation) {
        var addressMarkup = '<span>' + violation.violationType + '</span>';
        return $(addressMarkup);
      },
      templateSelection: function(violation) {
        if (violation.violationType) {
          return $('<span>' + violation.violationType + '</span>');
        }
      },
      matcher: function(params, data) {
        // If there are no search terms, return all of the data
        if (!params.term || params.term.trim() === '') {
          return data;
        }
        var searchTerm = params.term.toLowerCase();
        var target = data.violationType.toLowerCase();
        if (target.indexOf(searchTerm) > -1) {
          return data;
        }
        // Return `null` if the term should not be displayed
        return null;
      }
    });

    this.ui.branchAddressSelect.select2({
      allowClear: true,
      data: this.model.get('licenseAddresses'),
      templateResult: function(location) {
        var addressMarkup = new AddressDisplayView({
          model: new Backbone.Model(location)
        }).render().el.outerHTML;
        if (location.crdBranchNumber) {
          addressMarkup += '<span class="label label-primary option-label">' + location.crdBranchNumber + '</span>';
        }
        if (location.isDeleted === '1') {
          addressMarkup += ' <span class="label label-warning option-label">Deleted</span>';
        }
        return $(addressMarkup);
      },
      templateSelection: function(location) {
        if (location.addressLine1) {
          return $(
            '<span>' +
              location.addressLine1 +
              (location.isDeleted === '1' ? ' <span class="label label-warning selection-label">Deleted</span>' : '') +
              '</span>'
          );
        } else {
          return $('<span>--</span>');
        }
      },
      matcher: function(params, data) {
        // If there are no search terms, return all of the data
        if (!params.term || params.term.trim() === '') {
          return data;
        }
        // Reduce address to single string for matching.
        var optionText = (addressToString(data) + ' ' + data.crdBranchNumber).toLowerCase();
        if (optionText.indexOf(params.term.toLowerCase()) > -1) {
          return data;
        }
        // Return `null` if the term should not be displayed
        return null;
      }
    });

    this.ui.violationSelect.val(violations).trigger('change');
    this.ui.branchAddressSelect.val(exam.branchAddressId).trigger('change');
  },

  onChangeCategory: function(e) {
    e.preventDefault();

    toggleForm(this.ui.sourceSection, this.ui.categorySelect.val() !== '2');
  },

  onChangeViolationSelect: function() {
    var hasOtherViolation = _.contains(this.ui.violationSelect.val(), '33');
    toggleForm(this.ui.otherViolation, hasOtherViolation);
  },

  onChangeStatus: function() {
    var isClosedDateRequired = this.ui.status.val() !== '1';
    toggleForm(this.ui.closedDate, !isClosedDateRequired);
    toggleForm(this.ui.closedDateRequired, isClosedDateRequired);
  },

  onChangeSubType: function(e) {
    e.preventDefault();

    toggleForm(this.ui.branchSection, this.ui.subTypeSelect.val() === '2');
  },

  onClickSubmit: function(event) {
    event.preventDefault();
    var self = this;
    this.validate().done(function() {
      var l = Ladda.create(self.ui.submit[0]);
      l.start();
      var formData = self.getFormData();
      SubmitExam(formData)
        .done(function() {
          Radio.channel('navigate').trigger('show:int:sc:exam-dashboard', self.model.get('examId'));
        })
        .fail(function() {
          l.stop();
        });
    });
  },

  getFormData: function() {
    var formData = Syphon.serialize(this);

    formData.examId = this.model.get('examId');

    formData.openedDate = momentUtil.formatStr(formData.openedDate, momentUtil.defaultOutboundDateConfig);
    formData.closedDate = momentUtil.formatStr(formData.closedDate, momentUtil.defaultOutboundDateConfig);
    formData.deficienciesLetterSent = momentUtil.formatStr(formData.deficienciesLetterSent, momentUtil.defaultOutboundDateConfig);

    return formData;
  },

  validate: function() {
    return this.ui.examGeneralForm.parsley().whenValidate();
  }
});
