var Handlebars = require("handlebars/dist/handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "          <option value=\""
    + alias4(((helper = (helper = helpers.externalUserId || (depth0 != null ? depth0.externalUserId : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"externalUserId","hash":{},"data":data}) : helper)))
    + "\">"
    + alias4(((helper = (helper = helpers.firstName || (depth0 != null ? depth0.firstName : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"firstName","hash":{},"data":data}) : helper)))
    + " "
    + alias4(((helper = (helper = helpers.lastName || (depth0 != null ? depth0.lastName : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"lastName","hash":{},"data":data}) : helper)))
    + "</option>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "\n<form class=\"correspondence-form\">\n  <div class=\"form-group required\">\n    <label class=\"control-label col-sm-3\">Attach File For Correspondence</label>\n    <div class=\"col-sm-9\">\n      <div class=\"alert alert-danger collapse missing-file m-t-md\" role=\"alert\">\n       <i class=\"fa fa-exclamation-triangle\" aria-hidden=\"true\"></i>\n        At least one document or external correspondence comment must be provided.\n        </div>\n      <div class=\"file-container\"></div>\n    </div>\n  </div>\n  <div class=\"form-group\">\n    <label class=\"control-label col-sm-3\">Correspondence Comments</label>\n    <div class=\"col-sm-9\">\n      <textarea name=\"requestComments\" class=\"form-control correspondence-comments\" rows=\"5\"\n        placeholder=\"Text typed here is temporary and not saved as a record within the Bureau.\"></textarea>\n    </div>\n  </div>\n  <div class=\"form-group required\">\n    <label class=\"control-label col-sm-3\">External User</label>\n    <div class=\"col-sm-9\">\n      <select name=\"externalUser\" class=\"form-control external-user\" data-parsley-required=\"true\">\n        <option value=\"\" selected>--</option>\n"
    + ((stack1 = helpers.each.call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? depth0.entityUsers : depth0)) != null ? stack1.authorizedUsers : stack1),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "      </select>\n    </div>\n  </div>\n</form>";
},"useData":true});