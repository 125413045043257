/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var NProgress = require('nprogress');

var NavUtils = require('psa-core/util/nav');

var Scaffold = require('common/layout/Scaffold');

var GetTrustFundIdentifiers = require('../services/GetTrustFundIdentifiers');

var DepSubFundNewView = require('../views/DepSubFundNewView');

module.exports = function(entityId, fundId) {
  NProgress.start();
  NProgress.set(0.6);

  GetTrustFundIdentifiers(fundId)
    .done(function(subFundIdentifiers) {
      NavUtils.navigate('#dep/entity/' + entityId + '/fund/' + fundId + '/new-sub-fund');

      subFundIdentifiers.entityId = entityId;
      subFundIdentifiers.fundId = fundId;

      Scaffold.content.show(
        new DepSubFundNewView({
          viewMode: 'int',
          model: new Backbone.Model({
            fundIdentifiers: subFundIdentifiers
          })
        })
      );
      NProgress.done();
    })
    .fail(function(err) {
      NProgress.done();
    });
};
