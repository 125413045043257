var Handlebars = require("handlebars/dist/handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression;

  return "\n<div class=\"page-title clearfix\">\n  <h1 class=\"pull-left m-n p-n\">"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.entity : depth0)) != null ? stack1.entityName : stack1), depth0))
    + "</h1>\n</div>\n<div class=\"breadcrumb-container\"></div>\n<div class=\"row\">\n  <div class=\"col-sm-12 col-md-8 col-md-offset-1\">\n    <div class=\"row\">\n      <div class=\"form-group\">\n        <div class=\"col-sm-9 col-sm-offset-3\">\n          <h3 class=\"m-t-n\">Join Individual to Entity</h3>\n        </div>\n      </div>\n    </div>\n    <form class=\"individual-selection-form\">\n      <div class=\"individual-section\">\n        <div class=\"row\">\n          <h3 class=\"col-sm-offset-3 col-sm-9\">Individual</h3>\n        </div>\n        <div class=\"row form-group required\">\n          <div class=\"col-sm-offset-3 col-sm-6\">\n            <div class=\"input-group\">\n              <span class=\"input-group-btn\">\n                <button class=\"btn btn-primary btn-individual-search\">Search for Individual</button>\n              </span>\n              <input type=\"text\" class=\"selected-individual-name form-control\"\n                placeholder=\"Search for an Individual\" name=\"individualName\" readonly>\n              <span class=\"input-group-btn\">\n                <button class=\"btn btn-default clear-individual\">Clear</button>\n              </span>\n              <input type=\"number\" class=\"selected-individual-id\" name=\"individualId\" data-parsley-required=\"true\"\n                data-parsley-errors-container=\".individual-select-error-container\" hidden>\n            </div>\n            <div class=\"individual-select-error-container\"></div>\n          </div>\n        </div>\n      </div>\n    </form>\n    \n    <div class=\"row m-b-lg\">\n      <div class=\"form-group\">\n        <div class=\"col-sm-9 col-sm-offset-3\">\n          <a href=\"#conser/entity/"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.entity : depth0)) != null ? stack1.entityId : stack1), depth0))
    + "/dashboard\" class=\"btn btn-default\">Cancel</a>\n          <button class=\"btn btn-primary submit ladda-button\" data-style=\"expand-right\" type=\"button\">\n            <span class=\"ladda-label\">Submit</span>\n          </button>\n        </div>\n      </div>\n    </div>\n  </div>\n</div>";
},"useData":true});