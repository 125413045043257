var Handlebars = require("handlebars/dist/handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "        <option value=\""
    + alias4(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\">"
    + alias4(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data}) : helper)))
    + "</option>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "\n<div class=\"modal-body\">\n  <form class=\"search-form\">\n    <div class=\"form-group m-b-sm\">\n      <label class=\"control-label\">Entity Name</label>\n      <input type=\"text\" class=\"form-control entity-name\" name=\"entityName\" placeholder=\"Entity Name\"/>\n    </div>\n    <div class=\"form-group m-b-sm\">\n      <label class=\"control-label\">D/B/A</label>\n      <input type=\"text\" class=\"form-control entity-name\" name=\"dba\" placeholder=\"D/B/A\"/>\n    </div>\n    <div class=\"form-group\">\n      <label class=\"control-label\">License Type</label>\n      <select class=\"form-control\" name=\"licenseTypeId\">\n        <option value=\"\">--</option>\n"
    + ((stack1 = helpers.each.call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.licenseTypes : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "      </select>\n    </div>\n    <div class=\"form-group m-b-sm\">\n      <div class=\"checkbox\">\n        <label class=\"control-label \">\n          <input type=\"checkbox\" name=\"includeInactiveLicenses\">\n          Include Inactive Licenses\n        </label>\n      </div>\n    </div>\n    <div class=\"search-help-block text-danger collapse m-b-sm\">Entity Name or DBA are required.</div>\n    <button class=\"btn btn-primary btn-search\">Search</button>\n    <span class=\"search-summary\"></span>\n  </form>\n  <div class=\"search-result-grid-container m-t-md\"></div>\n</div>\n<div class=\"modal-footer\">\n  <button class=\"btn btn-default\" data-dismiss=\"modal\">Cancel</button>\n  <button class=\"btn btn-primary btn-submit ladda-button\" data-style=\"expand-right\" type=\"button\">\n    <span class=\"ladda-label\">Select</span>\n  </button>\n</div>";
},"useData":true});