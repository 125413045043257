/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var moment = require('moment');

var cleanUpResponse = require('common/util/cleanUpResponse');

module.exports = function(entityId) {
  var defer = $.Deferred();

  $.soap({
    body: {
      GetEntityRiskMatricesByEntity: {
        $xmlns: 'http://dobs.pa.gov/dp/tracking/bpm/1.0',
        EntityID: entityId
      }
    },
    blueprintArrays: ['GetEntityRiskMatricesByEntityResponse.DP_EntityRiskMatrix'],
    blueprint: {
      riskMatrices: [
        '$..DP_EntityRiskMatrix',
        {
          id: '$.ID',
          entityId: '$.EntityID',
          riskType: '$.RiskType',
          asOfDate: '$.AsOfDate',
          examStartDate: '$.ExamStartDate',
          riskAssessment: '$.RiskAssessment',
          riskDirection: '$.RiskDirection'
        }
      ]
    }
  })
    .done(function(response) {
      cleanUpResponse(response);

      _.each(response.riskMatrices, function(matrix) {
        if (matrix.asOfDate) {
          matrix.asOfDate = moment(matrix.asOfDate, 'YYYY-MM-DD');
        }
        if (matrix.examStartDate) {
          matrix.examStartDate = moment(matrix.examStartDate, 'YYYY-MM-DD');
        }
        matrix.riskAssessment = matrix.riskAssessment || '--';
        matrix.riskDirection = matrix.riskDirection || '--';
      });

      defer.resolve(response.riskMatrices);
    })
    .fail(function(err) {
      defer.reject(err);
    });

  return defer.promise();
};
