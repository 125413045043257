/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Backbone = require('backbone');
var NProgress = require('nprogress');

var NavUtils = require('psa-core/util/nav');

var Scaffold = require('common/layout/Scaffold');

var GetOfficeManagerByLicense = require('../services/GetOfficeManagerByLicense');
var GetEntityIdentifiers = require('../../common/services/GetEntityIdentifiers');
var GetLicenseIdentifiers = require('../../license-dashboard/services/GetLicenseIdentifiers');

var LicenseOfficeManagerChangeFormView = require('../views/LicenseOfficeManagerChangeFormView');

module.exports = function(entityId, licenseId) {
  NProgress.start();
  NProgress.set(0.6);

  $.when(GetOfficeManagerByLicense(licenseId), GetLicenseIdentifiers(licenseId), GetEntityIdentifiers(entityId))
    .done(function(officeManager, licenseIdentifiers, entityIdentifiers) {
      NavUtils.navigate('ext/nondep/entity/' + entityId + '/license/' + licenseId + '/branch-manager-change');
      Scaffold.content.show(
        new LicenseOfficeManagerChangeFormView({
          viewMode: 'ext',
          model: new Backbone.Model({
            entityId: entityId,
            licenseId: licenseId,
            officeManager: officeManager,
            licenseIdentifiers: licenseIdentifiers,
            entityIdentifiers: entityIdentifiers
          })
        })
      );
      NProgress.done();
    })
    .fail(function(err) {
      NProgress.done();
    });
};
