/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Marionette = require('backbone.marionette');
var Ladda = require('ladda');
var Syphon = require('backbone.syphon');
var Radio = require('backbone.radio');

var FileUploadCollection = require('psa-backbone/models/FileUploadCollection');

var modelGet = require('common/util/modelGet');
var momentUtil = require('common/util/momentUtil');
var BreadcrumbView = require('common/views/BreadcrumbView');
var FileUploadView = require('common/views/FileUploadView');

var DepAuditFormView = require('./DepAuditFormView');

var SubmitTrustFundAudit = require('../services/SubmitTrustFundAudit');
var SubmitTrustSubFundAudit = require('../services/SubmitTrustSubFundAudit');

var tmplDepAuditNewView = require('../templates/DepAuditNewView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplDepAuditNewView,

  className: 'dep-new-audit-form-view container-fluid',

  ui: {
    breadcrumb: '.breadcrumb-container',
    auditFormContainer: '.new-audit-form-container',
    submit: '.btn-submit',
    supportingDocumentsContainer: '.supporting-documents-container'
  },

  regions: {
    breadcrumb: '@ui.breadcrumb',
    auditFormContainer: '@ui.auditFormContainer',
    supportingDocumentsContainer: '@ui.supportingDocumentsContainer'
  },

  events: {
    'click @ui.submit': 'onClickSubmit'
  },

  onBeforeShow: function() {
    var entityId = this.model.get('entityId'),
      entityName = this.model.get('entityName'),
      fundId = this.model.get('fundId'),
      fundName = this.model.get('fundName'),
      subFundId = this.model.get('subFundId'),
      subFundName = this.model.get('subFundName');

    var breadcrumbPath = [
      {
        link: '#dep/dashboard',
        text: 'Home'
      },
      {
        link: '#dep/entity/' + entityId + '/dashboard',
        text: entityName
      },
      {
        link: '#dep/entity/' + entityId + '/fund/' + fundId + '/dashboard',
        text: fundName
      },
      {
        text: 'New Audit'
      }
    ];

    if (subFundId) {
      breadcrumbPath.splice(3, 0, {
        link: '#dep/entity/' + entityId + '/fund/' + fundId + '/sub-fund/' + subFundId + '/dashboard',
        text: subFundName
      });
    }

    this.showChildView(
      'breadcrumb',
      new BreadcrumbView({
        breadcrumbs: breadcrumbPath
      })
    );

    this.auditForm = new DepAuditFormView({
      model: this.model
    });

    this.showChildView('auditFormContainer', this.auditForm);

    this.supportingDocuments = new FileUploadView({
      isReadOnly: this.options.isReadOnly,
      allowMultipleFiles: true,
      isRequired: false,
      collection: new FileUploadCollection([]),
      metadata: {
        documentTypeId: 20001
      }
    });
    this.showChildView('supportingDocumentsContainer', this.supportingDocuments);
  },

  onClickSubmit: function(e) {
    e.preventDefault();

    var entityId = this.model.get('entityId'),
      fundId = this.model.get('fundId'),
      subFundId = this.model.get('subFundId'),
      self = this;

    var laddaContext = Ladda.create(e.currentTarget);
    this.auditForm.validate().done(
      function() {
        laddaContext.start();

        var formData = Syphon.serialize(this);
        formData.entityId = entityId;

        formData.auditDate = momentUtil.formatStr(formData.auditDate, momentUtil.defaultOutboundDateConfig);
        formData.receivedDate = momentUtil.formatStr(formData.receivedDate, momentUtil.defaultOutboundDateConfig);
        formData.approvedDate = momentUtil.formatStr(formData.approvedDate, momentUtil.defaultOutboundDateConfig);
        formData.lastRevisedDate = momentUtil.formatStr(formData.lastRevisedDate, momentUtil.defaultOutboundDateConfig);
        formData.letterReceivedDate = momentUtil.formatStr(
          formData.letterReceivedDate,
          momentUtil.defaultOutboundDateConfig
        );
        formData.responseReceivedDate = momentUtil.formatStr(
          formData.responseReceivedDate,
          momentUtil.defaultOutboundDateConfig
        );
        formData.supportingDocuments = self.supportingDocuments.collection.map(function(file) {
          return {
            fileId: file.get('fileId'),
            documentTypeId: modelGet(file, 'metadata.documentTypeId')
          };
        });

        var service;

        if (subFundId) {
          formData.subFundId = subFundId;
          formData.fundId = fundId;
          service = SubmitTrustSubFundAudit;
        } else {
          formData.fundId = fundId;
          service = SubmitTrustFundAudit;
        }

        service(formData)
          .done(function(auditId) {
            if (subFundId) {
              Radio.channel('navigate').trigger('show:int:dp:sub-fund:audit', entityId, fundId, subFundId, auditId);
            } else {
              Radio.channel('navigate').trigger('show:int:dp:fund:audit', entityId, fundId, auditId);
            }
          })
          .fail(function(err) {
            laddaContext.stop();
          });
      }.bind(this)
    );
  }
});
