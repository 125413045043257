var Handlebars = require("handlebars/dist/handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "\n\n<div class=\"row\">\n  <div class=\"form-horizontal\">\n    <div class=\"col-sm-6\">\n      <div class=\"form-group\">\n        <label class=\"control-label col-sm-4 col-md-3\">Federal ID</label>\n        <div class=\"form-control-static col-sm-8 col-md-9\">"
    + alias4(((helper = (helper = helpers.tin || (depth0 != null ? depth0.tin : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"tin","hash":{},"data":data}) : helper)))
    + "</div>\n      </div>\n    </div>\n    <div class=\"col-sm-6\">\n      <div class=\"form-group\">\n        <label class=\"control-label col-sm-4 col-md-3\">Owner</label>\n        <div class=\"form-control-static col-sm-8 col-md-9\">"
    + alias4(((helper = (helper = helpers.owner || (depth0 != null ? depth0.owner : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"owner","hash":{},"data":data}) : helper)))
    + "</div>\n      </div>\n    </div>\n  </div>\n</div>\n<div class=\"row\">\n  <div class=\"form-horizontal\">\n    <div class=\"col-sm-6\">\n      <div class=\"form-group\">\n        <label class=\"control-label col-sm-4 col-md-3\">Application Type</label>\n        <div class=\"form-control-static col-sm-8 col-md-9\">"
    + alias4(((helper = (helper = helpers.applicationType || (depth0 != null ? depth0.applicationType : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"applicationType","hash":{},"data":data}) : helper)))
    + "</div>\n      </div>\n    </div>\n    <div class=\"col-sm-6\">\n      <div class=\"form-group\">\n        <label class=\"control-label col-sm-4 col-md-3\">Submitted On</label>\n        <div class=\"form-control-static col-sm-8 col-md-9\">"
    + alias4((helpers["default"] || (depth0 && depth0["default"]) || alias2).call(alias1,(depth0 != null ? depth0.submissionDate : depth0),"--",{"name":"default","hash":{},"data":data}))
    + "</div>\n      </div>\n    </div>\n  </div>\n</div>\n<div class=\"row\">\n  <div class=\"form-horizontal\">\n    <div class=\"col-sm-6\">\n      <div class=\"form-group\">\n        <label class=\"control-label col-sm-4 col-md-3\">License Type</label>\n        <div class=\"form-control-static col-sm-8 col-md-9\">"
    + alias4((helpers["default"] || (depth0 && depth0["default"]) || alias2).call(alias1,(depth0 != null ? depth0.licenseType : depth0),"--",{"name":"default","hash":{},"data":data}))
    + "</div>\n      </div>\n    </div>\n    <div class=\"col-sm-6\">\n      <div class=\"form-group\">\n        <label class=\"control-label col-sm-4 col-md-3\">Submitted By</label>\n        <div class=\"form-control-static col-sm-8 col-md-9\">"
    + alias4((helpers["default"] || (depth0 && depth0["default"]) || alias2).call(alias1,(depth0 != null ? depth0.submittedBy : depth0),"--",{"name":"default","hash":{},"data":data}))
    + "</div>\n      </div>\n    </div>\n  </div>\n</div>\n<div class=\"row\">\n  <div class=\"form-horizontal\">\n    <div class=\"col-sm-6\">\n      <div class=\"form-group\">\n        <label class=\"control-label col-sm-4 col-md-3\">Application Status</label>\n        <div class=\"form-control-static col-sm-8 col-md-9\">"
    + alias4((helpers["default"] || (depth0 && depth0["default"]) || alias2).call(alias1,(depth0 != null ? depth0.reportingStatus : depth0),"--",{"name":"default","hash":{},"data":data}))
    + "</div>\n      </div>\n    </div>\n    <div class=\"col-sm-6\">\n      <div class=\"form-group\">\n        <label class=\"control-label col-sm-4 col-md-3\">Withdraw Comments</label>\n        <p class=\"form-control-static col-sm-8 col-md-9\">"
    + alias4((helpers["default"] || (depth0 && depth0["default"]) || alias2).call(alias1,(depth0 != null ? depth0.withdrawalReason : depth0),"--",{"name":"default","hash":{},"data":data}))
    + "</p> \n      </div>\n    </div>\n  </div>\n</div>\n<div class=\"row\">\n  <div class=\"form-horizontal\">\n    <div class=\"col-sm-6\">\n      <div class=\"form-group\">\n        <label class=\"control-label col-sm-4 col-md-3\">License Number</label>\n        <div class=\"form-control-static col-sm-8 col-md-9\">"
    + alias4(container.lambda(((stack1 = (depth0 != null ? depth0.license : depth0)) != null ? stack1.licenseNumber : stack1), depth0))
    + "</div>\n      </div>\n    </div>\n    <div class=\"col-sm-6\">\n      <div class=\"form-group\">\n        <label class=\"control-label col-sm-4 col-md-3\">Completed On</label>\n        <div class=\"form-control-static col-sm-8 col-md-9\">"
    + alias4((helpers["default"] || (depth0 && depth0["default"]) || alias2).call(alias1,(depth0 != null ? depth0.completedDate : depth0),"--",{"name":"default","hash":{},"data":data}))
    + "</div>\n      </div>\n    </div>\n  </div>\n</div>\n<div class=\"row\">\n  <div class=\"form-horizontal\">\n    <div class=\"col-sm-6\">\n      <div class=\"form-group\">\n        <label class=\"control-label col-sm-4 col-md-3\">Application Returned Comments</label>\n        <div class=\"form-control-static col-sm-8 col-md-9\">"
    + alias4((helpers["default"] || (depth0 && depth0["default"]) || alias2).call(alias1,(depth0 != null ? depth0.applicationReturnedDecisionReason : depth0),"--",{"name":"default","hash":{},"data":data}))
    + "</div>\n      </div>\n    </div>\n  </div>\n</div>";
},"useData":true});