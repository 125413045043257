/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var Marionette = require('backbone.marionette');
var NProgress = require('nprogress');

var PageableCollection = require('psa-backbone/models/PageableCollection');

var formatAsDollars = require('common/util/formatAsDollars');
var userHasRole = require('common/util/userHasRole');

var PaymentScheduleGridView = require('../../common/views/PaymentScheduleGridView');
var SubmitUpdateCompliancePayment = require('../services/SubmitUpdateCompliancePayment');

var tmplNonDepCompliancePaymentScheduleSectionView = require('../templates/NonDepCompliancePaymentScheduleSectionView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplNonDepCompliancePaymentScheduleSectionView,

  className: 'non-dep-compliance-payment-schedule-section-view',

  ui: {
    paymentGrid: '.payment-grid'
  },

  regions: {
    paymentGrid: '@ui.paymentGrid'
  },

  onBeforeRender: function() {
    var arrScheduled = _.where(this.model.get('payment'), { statusId: '1' });
    var arrDue = _.where(this.model.get('payment'), { statusId: '2' });
    var arrPaidOnTime = _.where(this.model.get('payment'), { statusId: '3' });
    var arrPaidLate = _.where(this.model.get('payment'), { statusId: '4' });
    var arrMaunallyPaid = _.where(this.model.get('payment'), { statusId: '5' });
    var arrFineAmountPaid = arrPaidOnTime.concat(arrPaidLate, arrMaunallyPaid);
    var arrFineAmountUnpaid = arrScheduled.concat(arrDue);

    var totalFineAmountPaid = _.reduce(
      _.pluck(arrFineAmountPaid, 'amount'),
      function(memo, num) {
        return memo + parseFloat(num);
      },
      0
    );

    var totalPaymentScheduled = _.reduce(
      _.pluck(arrFineAmountUnpaid, 'amount'),
      function(memo, num) {
        return memo + parseFloat(num);
      },
      0
    );

    var caseObj = this.model.get('case');
    caseObj.totalFineAmountPaid = formatAsDollars(totalFineAmountPaid);
    caseObj.totalPaymentScheduled = formatAsDollars(totalPaymentScheduled);
    this.model.set('case', caseObj);
  },

  onBeforeShow: function() {
    if (userHasRole('ND - Compliance Admin')) {
      this.isReadOnly = false;
    } else {
      this.isReadOnly = true;
    }

    this.paymentGrid = new PaymentScheduleGridView({
      emptyText: 'No Payment Scheduled',
      collection: new PageableCollection(this.model.get('payment'), { pageSize: 10 }),
      isReadOnly: this.isReadOnly,
      mode: 'adhoc',
      showCompletedButton: true,
      onDeletePayment: this.onDeletePayment.bind(this),
      onAddPayment: this.onAddPayment.bind(this),
      onAddCompletedPayment: this.onAddCompletedPayment.bind(this),
      onEditPayment: this.onEditPayment.bind(this)
    });
    this.showChildView('paymentGrid', this.paymentGrid);
  },

  onAddPayment: function(paymentObj) {
    var self = this;
    NProgress.start();
    SubmitUpdateCompliancePayment({
      isDeleting: 0,
      description: paymentObj.description,
      amountDue: paymentObj.amount,
      dueDate: paymentObj.dueDate,
      complianceId: this.model.get('case').complianceId
    })
      .done(function(newPaymentItem) {
        self.paymentGrid.collection.add(newPaymentItem);
        self.recalculateTotalPaymentScheduled();
        NProgress.done();
      })
      .fail(function() {
        NProgress.done();
      });
  },

  onAddCompletedPayment: function(paymentObj) {
    var self = this;
    NProgress.start();
    SubmitUpdateCompliancePayment({
      isDeleting: 0,
      description: paymentObj.description,
      amountDue: paymentObj.amount,
      dueDate: paymentObj.dueDate,
      complianceId: this.model.get('case').complianceId,
      isCompleted: 1
    })
      .done(function(newPaymentItem) {
        self.paymentGrid.collection.add(newPaymentItem);
        self.recalculateTotalPaymentScheduled();
        NProgress.done();
      })
      .fail(function() {
        NProgress.done();
      });
  },

  onEditPayment: function(paymentObj) {
    var self = this;
    NProgress.start();
    SubmitUpdateCompliancePayment({
      compliancePaymentId: paymentObj.paymentId,
      isDeleting: 0,
      description: paymentObj.description,
      amountDue: paymentObj.amount,
      dueDate: paymentObj.dueDate
    })
      .done(function() {
        var toEdit = _.findWhere(self.paymentGrid.collection.models, { id: paymentObj.paymentId });
        toEdit.set('description', paymentObj.description);
        toEdit.set('amount', paymentObj.amount);
        toEdit.set('dueDate', paymentObj.dueDate);
        self.recalculateTotalPaymentScheduled();
        NProgress.done();
      })
      .fail(function() {
        NProgress.done();
      });
  },

  onDeletePayment: function(payment) {
    var self = this;
    NProgress.start();
    var defer = $.Deferred();
    SubmitUpdateCompliancePayment({
      compliancePaymentId: payment.get('id'),
      isDeleting: 1
    })
      .done(function() {
        self.paymentGrid.collection.remove(payment);
        self.recalculateTotalPaymentScheduled();
        defer.resolve();
        NProgress.done();
      })
      .fail(function() {
        defer.reject();
        NProgress.done();
      });

    return defer.promise();
  },

  recalculateTotalPaymentScheduled: function() {
    var totalPaymentScheduled = 0;
    var totalPaymentPaid = 0;

    this.paymentGrid.collection.each(function(paymentModel) {
      var amount = paymentModel.get('amount');
      if (paymentModel.get('statusId') === '1' || paymentModel.get('statusId') === '2') {
        totalPaymentScheduled += parseFloat(amount);
      } else {
        totalPaymentPaid += parseFloat(amount);
      }
    });

    totalPaymentScheduled = formatAsDollars(totalPaymentScheduled);
    this.$('.payment-scheduled').text(totalPaymentScheduled);

    totalPaymentPaid = formatAsDollars(totalPaymentPaid);
    this.$('.payment-paid').text(totalPaymentPaid);
  }
});
