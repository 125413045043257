/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var NProgress = require('nprogress');
var Backbone = require('backbone');

var NavUtils = require('psa-core/util/nav');

var Scaffold = require('common/layout/Scaffold');

var GetEntityIdentifiers = require('../../common/services/GetEntityIdentifiers');
var GetLicenseTypesByAllowNew = require('../../new-license/services/GetLicenseTypesByAllowNew');
var GetEntityLocations = require('../../common/services/GetEntityLocations');
var GetAllLicenseStatus = require('../../license-dashboard/services/GetAllLicenseStatus');

var NonDepEntityNewLicenseView = require('../views/NonDepEntityNewLicenseView');

module.exports = function(entityId) {
  NProgress.start();
  NProgress.set(0.6);
  $.when(
    GetLicenseTypesByAllowNew(),
    GetEntityIdentifiers(entityId),
    GetEntityLocations(entityId),
    GetAllLicenseStatus()
  )
    .done(function(licenseTypes, entityIdentifiers, entityLocations, licenseStatus) {
      NavUtils.navigate('nondep/entity/' + entityId + '/new-license');
      Scaffold.content.show(
        new NonDepEntityNewLicenseView({
          model: new Backbone.Model({
            entityId: entityId,
            entityIdentifiers: entityIdentifiers,
            licenseTypes: licenseTypes,
            entityLocations: entityLocations,
            licenseStatus: licenseStatus
          })
        })
      );
      NProgress.done();
    })
    .fail(function() {
      NProgress.done();
    });
};
