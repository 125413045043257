/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Coulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remai
 * the property of Counterpoint Coulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Coulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Coulting Incorporated.
 */

var _ = require('underscore');

module.exports = function(formData) {
  var defer = $.Deferred();

  $.soap({
    body: {
      SubmitTimesheet: {
        $xmlns: 'http://dobs.pa.gov/tt/timesheet/bpm/1.0',
        TimesheetID: formData.timesheetId,
        StatusID: formData.statusId,
        Comments: formData.comments,
        NewTimesheetEntries: (function() {
          if (!formData.timesheetEntries || !formData.timesheetEntries.length) {
            return {};
          } else {
            return {
              TimesheetEntry: _.map(formData.timesheetEntries, function(timesheetEntry) {
                return {
                  CategoryID: timesheetEntry.categoryId,
                  ObjectID: timesheetEntry.objectId,
                  ObjectTypeID: timesheetEntry.objectTypeId,
                  StartDayPlus1: timesheetEntry.startDayPlus1,
                  StartDayPlus2: timesheetEntry.startDayPlus2,
                  StartDayPlus3: timesheetEntry.startDayPlus3,
                  StartDayPlus4: timesheetEntry.startDayPlus4,
                  StartDayPlus5: timesheetEntry.startDayPlus5,
                  StartDayPlus8: timesheetEntry.startDayPlus8,
                  StartDayPlus9: timesheetEntry.startDayPlus9,
                  StartDayPlus10: timesheetEntry.startDayPlus10,
                  StartDayPlus11: timesheetEntry.startDayPlus11,
                  StartDayPlus12: timesheetEntry.startDayPlus12,
                  WorksiteID: timesheetEntry.worksiteId
                };
              })
            };
          }
        })(),
        NewExpenseEntries: (function() {
          if (!formData.expensesEntries || !formData.expensesEntries.length) {
            return {};
          } else {
            return {
              ExpenseEntry: _.map(formData.expensesEntries, function(expenseEntry) {
                return {
                  ObjectID: expenseEntry.objectId,
                  ObjectTypeID: expenseEntry.objectTypeId,
                  ExpenseAmount: expenseEntry.expenseAmount
                };
              })
            };
          }
        })()
      }
    }
  })
    .done(function() {
      defer.resolve();
    })
    .fail(function(err) {
      defer.reject(err);
    });

  return defer.promise();
};
