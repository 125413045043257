/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var TextInputCellEditor = require('../TextInputCellEditor');

var tmplDollarInputCellEditorView = require('./DollarInputCellEditorView.hbs');

var DollarInputCellEditorView = TextInputCellEditor.extend({
  tagName: 'div',

  className: 'dollar-input-cell-editor-view',

  template: tmplDollarInputCellEditorView,

  ui: {
    input: 'input'
  },

  events: {
    'keydown @ui.input': 'saveOrCancel',
    'change @ui.input': 'saveOrCancel'
  },

  templateHelpers: function() {
    return {
      placeholder: this.placeholder,
      modelId: this.model.cid,
      columnName: this.column.get('name')
    };
  },

  onRender: function() {
    var model = this.model;
    var formattedValue = this.formatter.fromRaw(model.get(this.column.get('name')), model);
    this.ui.input.val(formattedValue);
  },

  getValue: function() {
    return this.ui.input.val();
  }
});

module.exports = DollarInputCellEditorView;
