/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');

module.exports = function(data) {
  var generalDetails = data.generalDetails;
  var productionReport = data.productionReport;
  var financialStatements = data.financialStatements || [];
  var affirmation = data.affirmation;
  return $.soap({
    body: {
      SubmitMortgageDiscountCompanyAnnualReport: {
        $xmlns: 'http://dobs.pa.gov/nd/annualreport/bpm/1.0',
        AnnualReportID: data.annualReportId,
        ApplicationID: data.applicationId,
        ContactFullName: generalDetails.contactFullName,
        ContactPhoneNumber: generalDetails.contactPhoneNumber,
        ContactEmail: generalDetails.contactEmail,
        IsCertified: affirmation.isCertified ? '1' : '0',
        CertificationName: affirmation.certificationName,

        LoanReceivablesHeldNotAvailable: productionReport.loanReceivablesHeldNotAvailable ? '1' : '0',
        LoanReceivablesHeldNotAvailableExplanation: productionReport.loanReceivablesInputsExplanation,
        LoanReceivablesHeldNumber: productionReport.loanReceivablesHeldNumber,
        LoanReceivablesHeldDollars: productionReport.loanReceivablesHeldDollars,

        LoansNotAvailable: productionReport.loansNotAvailable ? '1' : '0',
        LoansNotAvailableExplanation: productionReport.loansInputsExplanation,
        LoansNumber: productionReport.loansNumber,
        LoansDollars: productionReport.loansDollars,

        RepossessionNotAvailable: productionReport.repossessionNotAvailable ? '1' : '0',
        RepossessionNotAvailableExplanation: productionReport.repossessionInputsExplanation,
        RepossessionNumber: productionReport.repossessionNumber,
        RepossessionDollars: productionReport.repossessionDollars,

        HasServicedUnownedPAMortgageLoans: productionReport.hasServicedUnownedPAMortgageLoans,
        HasServicedUnownedPAMortgageLoansExplaination: productionReport.hasServicedMortgageExplaination,
        FinancialStatements: (function() {
          if (!financialStatements.length) {
            return {};
          }
          return {
            FinancialStatement: _.map(financialStatements, function(financialStatement) {
              return {
                DocumentID: financialStatement.fileId
              };
            })
          };
        })()
      }
    }
  });
};
