/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

module.exports = function(data) {
  var generalDetails = data.generalDetails;
  var productionReport = data.productionReport;
  var affirmation = data.affirmation;

  return $.soap({
    body: {
      SubmitPawnbrokerAnnualReport: {
        $xmlns: 'http://dobs.pa.gov/nd/annualreport/bpm/1.0',
        AnnualReportID: data.annualReportId,
        ApplicationID: data.applicationId,
        ContactFullName: generalDetails.contactFullName,
        ContactPhoneNumber: generalDetails.contactPhoneNumber,
        ContactEmail: generalDetails.contactEmail,
        HasOtherLoans: generalDetails.hasOtherLoans,
        HasOtherLoansExplanation: generalDetails.hasOtherLoansExplanation,
        BeginningOutstandingNotAvailable: productionReport.beginningOutstandingNotAvailable ? '1' : '0',
        BeginningOutstandingNumber: productionReport.beginningOutstandingNumber,
        BeginningOutstandingDollars: productionReport.beginningOutstandingDollars,
        BeginningOutstandingExplanation: productionReport.beginningOutstandingExplanation,
        ChargedOffLoansNotAvailable: productionReport.chargedOffLoansNotAvailable ? '1' : '0',
        ChargedOffLoansNumber: productionReport.chargedOffLoansNumber,
        ChargedOffLoansDollars: productionReport.chargedOffLoansDollars,
        ChargedOffLoansExplanation: productionReport.chargedOffLoansExplanation,
        EndOutstandingNotAvailable: productionReport.endOutstandingNotAvailable ? '1' : '0',
        EndOutstandingNumber: productionReport.endOutstandingNumber,
        EndOutstandingDollars: productionReport.endOutstandingDollars,
        EndOutstandingExplanation: productionReport.endOutstandingExplanation,
        ForfeitedLoansNotAvailable: productionReport.forfeitedLoansNotAvailable ? '1' : '0',
        ForfeitedLoansNumber: productionReport.forfeitedLoansNumber,
        ForfeitedLoansDollars: productionReport.forfeitedLoansDollars,
        ForfeitedLoansExplanation: productionReport.forfeitedLoansExplanation,
        NewPawnLoansNotAvailable: productionReport.newPawnLoansNotAvailable ? '1' : '0',
        NewPawnLoansNumber: productionReport.newPawnLoansNumber,
        NewPawnLoansDollars: productionReport.newPawnLoansDollars,
        NewPawnLoansExplanation: productionReport.newPawnLoansExplanation,
        RedeemedLoansNotAvailable: productionReport.redeemedLoansNotAvailable ? '1' : '0',
        RedeemedLoansNumber: productionReport.redeemedLoansNumber,
        RedeemedLoansDollars: productionReport.redeemedLoansDollars,
        RedeemedLoansExplanation: productionReport.redeemedLoansExplanation,
        TotalCancelledLoansNotAvailable: productionReport.totalCancelledLoansNotAvailable ? '1' : '0',
        TotalCancelledLoansNumber: productionReport.totalCancelledLoansNumber,
        TotalCancelledLoansDollars: productionReport.totalCancelledLoansDollars,
        TotalCancelledLoansExplanation: productionReport.totalCancelledLoansExplanation,
        IsCertified: affirmation.isCertified ? '1' : '0',
        CertificationName: affirmation.certificationName
      }
    }
  });
};
