/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var cleanUpResponse = require('common/util/cleanUpResponse');

module.exports = function(categoryId) {
  var defer = $.Deferred();

  $.soap({
    body: {
      GetAllMergerAcqFilingLookups: {
        $xmlns: 'http://dobs.pa.gov/dp/lookup/bpm/1.0'
      }
    },
    blueprintArrays: [
      'GetAllMergerAcqFilingLookupsResponse.Regulators.DP_LU_AcqMergerRegulator',
      'GetAllMergerAcqFilingLookupsResponse.PursuantOptions.DP_LU_AcqMergerPursuantOption',
      'GetAllMergerAcqFilingLookupsResponse.OwnershipStructures.DP_LU_AcqMergerOwnershipStructure',
      'GetAllMergerAcqFilingLookupsResponse.MergerFilingTypes.DP_LU_AcqMergerFilingType',
      'GetAllMergerAcqFilingLookupsResponse.TargetTypes.DP_LU_AcqMergerTargetType',
      'GetAllMergerAcqFilingLookupsResponse.CharterTypes.DP_LU_AcqMergerCharterType',
      'GetAllMergerAcqFilingLookupsResponse.StateCharters.DP_LU_AcqMergerStateCharter',
      'GetAllMergerAcqFilingLookupsResponse.NameTypes.DP_LU_AcqMergerNameType'
    ],
    blueprint: {
      regulators: [
        '$..DP_LU_AcqMergerRegulator',
        {
          id: '$..ID',
          regulator: '$..Regulator'
        }
      ],
      pursuantOptions: [
        '$..DP_LU_AcqMergerPursuantOption',
        {
          id: '$..ID',
          pursuantOption: '$..PursuantOption'
        }
      ],
      ownershipStructures: [
        '$..DP_LU_AcqMergerOwnershipStructure',
        {
          id: '$..ID',
          structure: '$..Structure'
        }
      ],
      mergerFilingTypes: [
        '$..DP_LU_AcqMergerFilingType',
        {
          id: '$..ID',
          filingType: '$..FilingType'
        }
      ],
      targetTypes: [
        '$..DP_LU_AcqMergerTargetType',
        {
          id: '$..ID',
          targetType: '$..TargetType'
        }
      ],
      charterTypes: [
        '$..DP_LU_AcqMergerCharterType',
        {
          id: '$..ID',
          charterType: '$..CharterType'
        }
      ],
      stateCharters: [
        '$..DP_LU_AcqMergerStateCharter',
        {
          id: '$..ID',
          stateCharter: '$..StateCharter'
        }
      ],
      nameTypes: [
        '$..DP_LU_AcqMergerNameType',
        {
          id: '$..ID',
          nameType: '$..NameType'
        }
      ]
    }
  })
    .done(function(response) {
      cleanUpResponse(response);
      defer.resolve(response);
    })
    .fail(function(err) {
      defer.reject(err);
    });

  return defer.promise();
};
