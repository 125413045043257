/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var cleanUpResponse = require('common/util/cleanUpResponse');

module.exports = function(licenseId) {
  var defer = $.Deferred();

  $.soap({
    body: {
      GetExamByLicense: {
        $xmlns: 'http://dobs.pa.gov/nd/exam/bpm/1.0',
        LicenseID: licenseId
      }
    },
    blueprintArrays: ['GetExamByLicenseResponse.Exams.ND_Exam'],
    blueprint: {
      exam: [
        '$..ND_Exam',
        {
          examId: '$.ID',
          statusId: '$.StatusID',
          status: '$.Status',
          examCoverageStartDate: '$.ExamCoverageStartDate',
          examCoverageEndDate: '$.ExamCoverageEndDate',
          examStartDate: '$.ExamStartDate',
          examEndDate: '$.ExamEndDate'

        }
      ]
    }
  })
    .done(function(response) {
      cleanUpResponse(response);

      defer.resolve(response.exam);
    })
    .fail(function(err) {
      defer.reject(err);
    });

  return defer.promise();
};
