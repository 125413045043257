var Handlebars = require("handlebars/dist/handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "  <div class=\"row\">\n    <div class=\"col-sm-6\">\n      <input type=\"text\" class=\"form-control\" name=\"firstName\" placeholder=\"First Name\">\n    </div>\n    <div class=\"col-sm-6\">\n      <input type=\"text\" class=\"form-control\" name=\"lastName\" placeholder=\"Last Name\">\n    </div>\n  </div>\n  <div class=\"row m-t-sm\">\n    <div class=\"col-sm-6\">\n      <button class=\"btn btn-primary search-individual\">\n        <span>Search</span>\n      </button>\n    </div>\n  </div>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "\n<div class=\"modal-body\">\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.searchable : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "  <div class=\"search-result-summary collapse\">Found <span class=\"hit-count\"></span> results.</div>\n  <div class=\"search-result-grid-container m-t-md\"></div>\n  <div class=\"search-result-max alert alert-inline alert-warning collapse\">More results match the search criteria than\n    shown, please refine your search criteria.\n    <span class=\"fa fa-question-circle fa-lg refine-seach-tip\" data-toggle=\"tooltip\" data-placement=\"bottom\"\n      title=\"Only the top "
    + container.escapeExpression(((helper = (helper = helpers.searchLimit || (depth0 != null ? depth0.searchLimit : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"searchLimit","hash":{},"data":data}) : helper)))
    + " results, ordered by the strength of the match, are returned by this search. Provide additional criteria to help refine the search.\"></span>\n  </div>\n</div>\n<div class=\"modal-footer\">\n  <button class=\"btn btn-default\" data-dismiss=\"modal\">Cancel</button>\n  <button class=\"btn btn-primary btn-submit ladda-button\" data-style=\"expand-right\" type=\"button\">\n    <span class=\"ladda-label\">Select</span>\n  </button>\n</div>";
},"useData":true});