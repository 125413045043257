var Handlebars = require("handlebars/dist/handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), alias4=helpers.helperMissing, alias5="function";

  return "\n<div class=\"page-title clearfix\">\n  <h1 class=\"pull-left m-n p-n\">"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.entityIdentifiers : depth0)) != null ? stack1.entityName : stack1), depth0))
    + "</h1>\n</div>\n<div class=\"page-subtitle\">\n  <h2>Filing</h2>\n  <div class=\"page-subtitle-details\">"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.filingIdentifiers : depth0)) != null ? stack1.filingType : stack1), depth0))
    + "</div>\n</div>\n<div class=\"breadcrumb-container\"></div>\n<div class=\"row\">\n  <div class=\"col-sm-10 col-sm-offset-1 m-b-md\">\n    <div class=\"row\">\n      <div class=\"col-md-6 col-sm-12\">\n        <div class=\"row\">\n          <div class=\"col-md-8 col-sm-9 col-md-offset-4 col-sm-offset-3\">\n            <h2 class=\"m-t-n\">New Condition</h2>\n          </div>\n        </div>\n      </div>\n    </div>\n    <form class=\"condition-form-container\"></form>\n    <div class=\"row\">\n      <div class=\"col-md-10 col-sm-9 col-md-offset-2 col-sm-offset-3\">\n        <a href=\"#dep/entity/"
    + alias2(((helper = (helper = helpers.entityId || (depth0 != null ? depth0.entityId : depth0)) != null ? helper : alias4),(typeof helper === alias5 ? helper.call(alias3,{"name":"entityId","hash":{},"data":data}) : helper)))
    + "/filing/"
    + alias2(((helper = (helper = helpers.filingId || (depth0 != null ? depth0.filingId : depth0)) != null ? helper : alias4),(typeof helper === alias5 ? helper.call(alias3,{"name":"filingId","hash":{},"data":data}) : helper)))
    + "/dashboard\" class=\"btn btn-default\">Cancel</a>\n        <button class=\"btn btn-primary submit ladda-button\" data-style=\"expand-right\" type=\"button\">\n          <span class=\"ladda-label\">Submit</span>\n        </button>\n      </div>\n    </div>\n  </div>\n</div>";
},"useData":true});