/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var moment = require('moment');

var cleanUpResponse = require('common/util/cleanUpResponse');

module.exports = function(applicationId) {
  var deferred = $.Deferred();

  $.soap({
    body: {
      GetEntityNameChangeApplication: {
        $xmlns: 'http://dobs.pa.gov/nd/application/bpm/1.0',
        ApplicationID: applicationId
      }
    },
    blueprintArrays: [
      'GetEntityNameChangeApplicationResponse.CurrentDBAs.ND_EntityDBA',
      'GetEntityNameChangeApplicationResponse.NewDBAs.ND_AppEntityNameChangeDBA',
      'GetEntityNameChangeApplicationResponse.ApplicationDocuments.ND_ApplicationDocument'
    ],
    blueprint: {
      newEntityName: '$..EntityNewName',
      oldEntityName: '$..EntityOldName',
      isForeignEntity: '$..IsForeignEntity',
      webAddress: '$..WebAddress',
      listWebsiteBusiness: '$..ListWebsiteBusiness',
      entityOrganizationTypeId: '$..EntityOrganizationTypeID',
      entityName2: '$..EntityName2',
      entityNameForced: '$..EntityNameForced',
      tin: '$..TIN',
      documentExceptionLegalOpinion: '$..DocumentExceptionLegalOpinion',
      phoneNumber: '$..PhoneNumber',
      phoneNumberExt: '$..PhoneNumberExt',
      secondaryPhoneNumber: '$..SecondaryPhoneNumber',
      secondaryPhoneNumberExt: '$..SecondaryPhoneNumberExt',
      faxNumber: '$..FaxNumber',
      faxNumberExt: '$..FaxNumberExt',
      incorporationDate: '$..IncorporationDate',
      incorporationPlace: '$..IncorporationPlace',
      agentOfService: {
        fullName: '$..AgentOfServiceFullName',
        addressId: '$..AgentOfServiceAddressID',
        addressLine1: '$..AddressLine1',
        addressLine2: '$..AddressLine2',
        addressLine3: '$..AddressLine3',
        city: '$..City',
        state: '$..State',
        postalCode: '$..PostalCode',
        country: '$..Country',
        county: '$..County',
        phoneNumber: '$..AgentOfServicePhoneNumber'
      },
      entityId: '$..EntityID',
      articlesOfIncorporationDocument: {
        fileId: '$..ArticlesOfIncorporationDocumentID',
        fileName: '$..ArticlesOfIncorporationDocumentName'
      },
      operatingAgreementDocument: {
        fileId: '$..OperatingAgreementDocumentID',
        fileName: '$..OperatingAgreementDocumentName'
      },
      byLawsDocument: {
        fileId: '$..ByLawsDocumentID',
        fileName: '$..ByLawsDocumentName'
      },
      foreignRegistrationStatementDocument: {
        fileId: '$..ForeignRegistrationStatmentDocumentID',
        fileName: '$..ForeignRegistrationStatmentDocumentName'
      },
      oldArticlesOfIncorporationDocument: {
        fileId: '$..OldArticlesOfIncorporationDocumentID',
        fileName: '$..OldOperatingAgreementDocumentName'
      },
      oldOperatingAgreementDocument: {
        fileId: '$..OldOperatingAgreementDocumentID',
        fileName: '$..OldOperatingAgreementDocumentName'
      },
      oldByLawsDocument: {
        fileId: '$..OldByLawsDocumentID',
        fileName: '$..OldByLawsDocumentName'
      },
      oldForeignRegistrationStatementDocument: {
        fileId: '$..OldForeignRegistrationStatementDocumentID',
        fileName: '$..CurrentForeignRegistrationStatementDocumentName'
      },
      effectiveChangeDate: '$..EffectiveChangeDate',
      certificationName: '$..CertificationName',
      isCertified: '$..IsCertified',
      dbas: [
        '$..ND_EntityDBA',
        {
          id: '$..ID',
          dbaName: '$..DBA',
          fictitiousNameDocument: {
            fileId: '$..FictitiousDocumentID',
            fileName: '$..FictitiousDocumentName'
          }
        }
      ],
      newDBAs: [
        '$..ND_AppEntityNameChangeDBA',
        {
          deleteId: '$..DBADeleteID',
          dbaName: '$..DBA',
          endDate: '$..EndDate',
          registrationDate: '$..RegistrationDate',
          fictitiousNameDocument: {
            fileId: '$..FictitiousDocumentID',
            fileName: '$..FictitiousDocumentName'
          }
        }
      ],
      applicationDocuments: [
        '$..ND_ApplicationDocument',
        {
          fileId: '$.DocumentID',
          fileName: '$..Name'
        }
      ]
    }
  })
    .done(function(response) {
      cleanUpResponse(response);
      response.isCertified = response.isCertified === '1';

      _.each(response.dbas, function(dba) {
        var matchedIndex = response.newDBAs.findIndex(function(newDBA) {
          return newDBA.deleteId === dba.id;
        });
        dba.shouldDelete = matchedIndex !== -1;
        if (dba.shouldDelete) {
          response.newDBAs.splice(matchedIndex, 1);
        }
      });

      response.incorporationDate = response.incorporationDate
        ? moment(response.incorporationDate, 'YYYY-MM-DD').format('MM/DD/YYYY')
        : null;
      response.effectiveChangeDate = moment(response.effectiveChangeDate, 'YYYY-MM-DD').format('MM/DD/YYYY');

      deferred.resolve(response);
    })
    .fail(function(err) {
      deferred.reject(err);
    });

  return deferred.promise();
};
