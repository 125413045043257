/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var cleanUpResponse = require('common/util/cleanUpResponse');
var _ = require('underscore');

module.exports = function(entityCategoryId) {
  var defer = $.Deferred();

  $.soap({
    body: {
      GetAllTrackingLookups: {
        $xmlns: 'http://dobs.pa.gov/dp/lookup/bpm/1.0'
      }
    },
    blueprintArrays: [
      'GetAllTrackingLookupsResponse.TrackingTypes.DP_LU_TrackingType',
      'GetAllTrackingLookupsResponse.TrackingEntityTypes.DP_LU_TrackingEntityType',
      'GetAllTrackingLookupsResponse.TrackingSources.DP_LU_TrackingSource',
      'GetAllTrackingLookupsResponse.Quarters.DP_LU_Quarter',
      'GetAllTrackingLookupsResponse.RiskTypes.DP_LU_RiskType',
      'GetAllTrackingLookupsResponse.RiskAssessments.DP_LU_RiskAssessment',
      'GetAllTrackingLookupsResponse.RiskDirections.DP_LU_RiskDirection'
    ],
    blueprint: {
      trackingTypes: [
        '$..DP_LU_TrackingType',
        {
          id: '$.ID',
          type: '$.Type',
          categoryId: '$.CategoryID'
        }
      ],
      trackingEntityTypes: [
        '$..DP_LU_TrackingEntityType',
        {
          id: '$.ID',
          entityType: '$.EntityType'
        }
      ],
      trackingSources: [
        '$..DP_LU_TrackingSource',
        {
          id: '$.ID',
          source: '$.Source'
        }
      ],
      quarters: [
        '$..DP_LU_Quarter',
        {
          id: '$.ID',
          quarter: '$.Quarter',
          quarterEnd: '$.QuarterEnd'
        }
      ],
      riskTypes: [
        '$..DP_LU_RiskType',
        {
          id: '$..ID',
          type: '$..Type',
          categoryId: '$..CategoryID'
        }
      ],
      riskAssessments: [
        '$..DP_LU_RiskAssessment',
        {
          id: '$..ID',
          assessment: '$..Assessment'
        }
      ],
      riskDirections: [
        '$..DP_LU_RiskDirection',
        {
          id: '$..ID',
          direction: '$..Direction'
        }
      ]
    }
  })
    .done(function(response) {
      cleanUpResponse(response);

      response.trackingTypes = _.filter(response.trackingTypes, function(type) {
        return (
          type.categoryId === entityCategoryId && _.includes(['1', '2', '3', '4', '6', '12', '13', '21', '23'], type.id)
        );
      });

      response.riskTypes = _.filter(response.riskTypes, function(type) {
        return type.categoryId === entityCategoryId;
      });

      defer.resolve(response);
    })
    .fail(function(err) {
      defer.reject(err);
    });

  return defer.promise();
};
