var Handlebars = require("handlebars/dist/handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "      <option value=\""
    + alias4(((helper = (helper = helpers.externalUserId || (depth0 != null ? depth0.externalUserId : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"externalUserId","hash":{},"data":data}) : helper)))
    + "\">"
    + alias4(((helper = (helper = helpers.userDisplay || (depth0 != null ? depth0.userDisplay : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"userDisplay","hash":{},"data":data}) : helper)))
    + "</option>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "\n<form class=\"lawyer-form\">\n  <p>If a filer user is specified, that user will be able to perform all actions relating to this filing. Filers can only be selected from the list of users who have been joined to this entity with the access level of <strong>User</strong>.</p>\n  <div class=\"form-group new-lawyer\">\n    <label class=\"control-label\">Filing Filer</label>\n    <select name=\"lawyerExternalUserId\" class=\"form-control lawyer-select\">\n      <option value=\"\">--</option>\n"
    + ((stack1 = helpers.each.call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.lawyers : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "    </select>\n  </div>\n</form>\n";
},"useData":true});