/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var moment = require('moment');

module.exports = function(otdsTicket) {
  var defer = $.Deferred();

  var momentInstant = new moment().format();

  $.soap({
    header: {
      OTAuthentication: {
        $xmlns: 'urn:api.bpm.opentext.com',
        AuthenticationToken: otdsTicket
      }
    },
    body: {
      'samlp:Request': {
        '$xmlns:samlp': 'urn:oasis:names:tc:SAML:1.0:protocol',
        $MajorVersion: '1',
        $MinorVersion: '1',
        $IssueInstant: momentInstant,
        'samlp:AuthenticationQuery': {
          'saml:Subject': {
            '$xmlns:saml': 'urn:oasis:names:tc:SAML:1.0:assertion',
            'saml:NameIdentifier': {
              $Format: 'urn:oasis:names:tc:SAML:1.1:nameid-format:unspecified'
            }
          }
        }
      }
    },
    blueprint: {
      artifact: '$..AssertionArtifact.__text'
    }
  })
    .done(function(result) {
      defer.resolve(result.artifact);
    })
    .fail(function(err) {
      if (err.status === 403) {
        defer.reject(err.statusText);
      } else {
        defer.reject(err);
      }
    });

  return defer.promise();
};
