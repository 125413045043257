/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var cleanUpResponse = require('common/util/cleanUpResponse');

module.exports = function() {
  var deferred = $.Deferred();

  $.soap({
    body: {
      GetAllExamLookups: {
        $xmlns: 'http://dobs.pa.gov/sc/lookup/bpm/1.0'
      }
    },
    blueprintArrays: [
      'GetAllExamLookupsResponse.ExamTypes.SC_LU_ExamType',
      'GetAllExamLookupsResponse.ExamSubtypes.SC_LU_ExamSubtype',
      'GetAllExamLookupsResponse.ExamStatus.SC_LU_ExamStatus',
      'GetAllExamLookupsResponse.ExamSources.SC_LU_ExamSource',
      'GetAllExamLookupsResponse.ExamCategories.SC_LU_ExamCategory',
      'GetAllExamLookupsResponse.ExamConductedLocations.SC_LU_ExamConductedLocation',
      'GetAllExamLookupsResponse.ExamOffices.SC_LU_ExamInvInqOffice',
      'GetAllExamLookupsResponse.ExamActions.SC_LU_ExamAction',
      'GetAllExamLookupsResponse.ExamDecisions.SC_LU_ExamDecision'
    ],
    blueprint: {
      examTypes: [
        '$..SC_LU_ExamType',
        {
          id: '$.ID',
          type: '$.Type'
        }
      ],
      examSubtypes: [
        '$..SC_LU_ExamSubtype',
        {
          id: '$.ID',
          subtype: '$.Subtype'
        }
      ],
      examStatuses: [
        '$..SC_LU_ExamStatus',
        {
          id: '$.ID',
          status: '$.Status'
        }
      ],
      examSources: [
        '$..SC_LU_ExamSource',
        {
          id: '$.ID',
          source: '$.Source'
        }
      ],
      examCategories: [
        '$..SC_LU_ExamCategory',
        {
          id: '$.ID',
          category: '$.Category'
        }
      ],
      examConductedLocations: [
        '$..SC_LU_ExamConductedLocation',
        {
          id: '$.ID',
          location: '$.Location'
        }
      ],
      examActions: [
        '$..SC_LU_ExamAction',
        {
          id: '$.ID',
          action: '$.Action'
        }
      ],
      examDecisions: [
        '$..SC_LU_ExamDecision',
        {
          id: '$.ID',
          decision: '$.Decision'
        }
      ]
    }
  })
    .done(function(response) {
      cleanUpResponse(response);
      deferred.resolve(response);
    })
    .fail(function(err) {
      deferred.reject();
    });

  return deferred.promise();
};
