/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Backbone = require('backbone');
var NProgress = require('nprogress');
var NavUtils = require('psa-core/util/nav');
var Scaffold = require('common/layout/Scaffold');

var GetEntityIdentifiers = require('../../common/services/GetEntityIdentifiers');
var GetEntityDetails = require('../../entity-form/services/GetEntityDetails');
var GetLicenseIdentifiers = require('../../license-dashboard/services/GetLicenseIdentifiers');
var GetLicenseDBA = require('../../license-dashboard/services/GetLicenseDBA');
var LicenseDBAEditFormView = require('../views/LicenseDBAEditFormView');

module.exports = function(entityId, licenseId, applicationId) {
  NProgress.start();
  NProgress.set(0.6);
  var isEdit = 1;

  $.when(
    GetLicenseDBA(licenseId, isEdit),
    GetEntityDetails(entityId),
    GetLicenseIdentifiers(licenseId),
    GetEntityIdentifiers(entityId)
  )
    .done(function(licenseDBAs, entityDetails, licenseIdentifiers, entityIdentifiers) {
      NavUtils.navigate('ext/nondep/entity/' + entityId + '/license/' + licenseId + '/edit-dba');

      Scaffold.content.show(
        new LicenseDBAEditFormView({
          isReadOnly: false,
          viewMode: 'ext',
          model: new Backbone.Model({
            entitydbas: entityDetails.dbas,
            entityId: entityId,
            licenseId: licenseId,
            licenseDBAs: licenseDBAs,
            licenseIdentifiers: licenseIdentifiers,
            entityIdentifiers: entityIdentifiers
          })
        })
      );

      NProgress.done();
    })
    .fail(function(err) {
      NProgress.done();
    });
};
