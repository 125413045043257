var Handlebars = require("handlebars/dist/handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "  <div class=\"row\">\n    <div class=\"col-sm-12 col-md-6\">\n      <div class=\"form-group\">\n        <label class=\"control-label col-sm-3  col-md-4\">TIN</label>\n        <div class=\"form-control-static col-sm-9  col-md-8\">"
    + alias3((helpers["default"] || (depth0 && depth0["default"]) || alias2).call(alias1,(depth0 != null ? depth0.tin : depth0),"--",{"name":"default","hash":{},"data":data}))
    + "</div>\n      </div>\n    </div>\n    <div class=\"col-sm-12 col-md-6\">\n      <div class=\"form-group\">\n        <label class=\"control-label col-sm-3  col-md-4\">Case Number</label>\n        <div class=\"form-control-static col-sm-9  col-md-8\">"
    + alias3((helpers["default"] || (depth0 && depth0["default"]) || alias2).call(alias1,(depth0 != null ? depth0.caseNumber : depth0),"--",{"name":"default","hash":{},"data":data}))
    + "</div>\n      </div>\n    </div>\n  </div>\n  <div class=\"row\">\n    <div class=\"col-sm-12 col-md-6\">\n      <div class=\"form-group\">\n        <label class=\"control-label col-sm-3  col-md-4\">Clearance Decision</label>\n        <div class=\"form-control-static col-sm-9  col-md-8\">"
    + alias3((helpers["default"] || (depth0 && depth0["default"]) || alias2).call(alias1,(depth0 != null ? depth0.clearanceDecision : depth0),"--",{"name":"default","hash":{},"data":data}))
    + "</div>\n      </div>\n    </div>\n    <div class=\"col-sm-12 col-md-6\">\n      <div class=\"form-group\">\n        <label class=\"control-label col-sm-3  col-md-4\">Clearance Decision Date</label>\n        <div class=\"form-control-static col-sm-9  col-md-8\">"
    + alias3((helpers["default"] || (depth0 && depth0["default"]) || alias2).call(alias1,(depth0 != null ? depth0.clearanceDecisionDate : depth0),"--",{"name":"default","hash":{},"data":data}))
    + "</div>\n      </div>\n    </div>\n  </div>\n  <div class=\"row\">\n    <div class=\"col-sm-12 col-md-6\">\n      <div class=\"form-group\">\n        <label class=\"control-label col-sm-3  col-md-4\">Case Status</label>\n        <div class=\"form-control-static col-sm-9  col-md-8\">"
    + alias3((helpers["default"] || (depth0 && depth0["default"]) || alias2).call(alias1,(depth0 != null ? depth0.caseStatus : depth0),"--",{"name":"default","hash":{},"data":data}))
    + "</div>\n      </div>\n    </div>\n    <div class=\"col-sm-12 col-md-6\">\n      <div class=\"form-group\">\n        <label class=\"control-label col-sm-3  col-md-4\">Case Status Reason</label>\n        <div class=\"form-control-static col-sm-9  col-md-8\">"
    + alias3((helpers["default"] || (depth0 && depth0["default"]) || alias2).call(alias1,(depth0 != null ? depth0.caseStatusReason : depth0),"--",{"name":"default","hash":{},"data":data}))
    + "</div>\n      </div>\n    </div>\n  </div>\n  <div class=\"row\">\n    <div class=\"col-sm-12 col-md-6\">\n      <div class=\"form-group\">\n        <label class=\"control-label col-sm-3  col-md-4\">Latest Agency Tracking Number</label>\n        <div class=\"form-control-static col-sm-9  col-md-8\">"
    + alias3((helpers["default"] || (depth0 && depth0["default"]) || alias2).call(alias1,(depth0 != null ? depth0.latestAgencyTrackingNumber : depth0),"--",{"name":"default","hash":{},"data":data}))
    + "</div>\n      </div>\n    </div>\n    <div class=\"col-sm-12 col-md-6\">\n      <div class=\"form-group\">\n        <label class=\"control-label col-sm-3  col-md-4\">Latest Request Date</label>\n        <div class=\"form-control-static col-sm-9  col-md-8\">"
    + alias3((helpers["default"] || (depth0 && depth0["default"]) || alias2).call(alias1,(depth0 != null ? depth0.latestRequestDate : depth0),"--",{"name":"default","hash":{},"data":data}))
    + "</div>\n      </div>\n    </div>\n  </div>\n  <div class=\"row\">\n    <div class=\"col-sm-12\">\n      <div class=\"form-group\">\n        <label class=\"control-label col-sm-3  col-md-2\">Error Description</label>\n        <p class=\"form-control-static col-sm-9  col-md-10\">"
    + alias3((helpers["default"] || (depth0 && depth0["default"]) || alias2).call(alias1,(depth0 != null ? depth0.errorDescription : depth0),"--",{"name":"default","hash":{},"data":data}))
    + "</p>\n      </div>\n    </div>\n  </div>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "\n<div class=\"form-horizontal form-static m-b-lg\">\n"
    + ((stack1 = helpers["with"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.ricsCompliance : depth0),{"name":"with","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "</div>";
},"useData":true});