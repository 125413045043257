var Handlebars = require("handlebars/dist/handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "        <option value=\""
    + alias4(((helper = (helper = helpers.externalUserId || (depth0 != null ? depth0.externalUserId : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"externalUserId","hash":{},"data":data}) : helper)))
    + "\">"
    + alias4(((helper = (helper = helpers.userDisplay || (depth0 != null ? depth0.userDisplay : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"userDisplay","hash":{},"data":data}) : helper)))
    + "</option>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var helper;

  return "      <a href=\"#ext/dep/entity/"
    + container.escapeExpression(((helper = (helper = helpers.entityId || (depth0 != null ? depth0.entityId : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"entityId","hash":{},"data":data}) : helper)))
    + "/dashboard\" class=\"btn btn-default\" role=\"button\">Cancel</a>\n";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.entityId : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.program(8, data, 0),"data":data})) != null ? stack1 : "");
},"6":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "        <a href=\"#dep/entity/"
    + alias4(((helper = (helper = helpers.entityId || (depth0 != null ? depth0.entityId : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"entityId","hash":{},"data":data}) : helper)))
    + "/filing/"
    + alias4(((helper = (helper = helpers.filingId || (depth0 != null ? depth0.filingId : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"filingId","hash":{},"data":data}) : helper)))
    + "/dashboard\" class=\"btn btn-default\" role=\"button\">Cancel</a>\n";
},"8":function(container,depth0,helpers,partials,data) {
    return "        <a href=\"#dep/dashboard\" class=\"btn btn-default\" role=\"button\">Cancel</a>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.escapeExpression, alias2=depth0 != null ? depth0 : (container.nullContext || {}), alias3=helpers.helperMissing;

  return "\n<h1 class=\"page-title\">"
    + alias1(container.lambda(((stack1 = (depth0 != null ? depth0.filingDetails : depth0)) != null ? stack1.filingType : stack1), depth0))
    + " Filing</h1>\n<div class=\"header-bar\"></div>\n<div class=\"breadcrumb-container\"></div>\n\n<div class=\"row\">\n  <div class=\"col-sm-8 col-md-9 col-sm-offset-4 col-md-offset-3\">\n    <h3 class=\"m-t-n\">Manage Filer</h3>\n  </div>\n</div>\n\n<div class=\"form-horizontal form-static\">\n  <div class=\"form-group m-b-n\">\n    <label class=\"control-label col-sm-4 col-md-3\">Current Filer</label>\n    <div class=\"form-control-static col-sm-8 col-md-9\">"
    + alias1((helpers["default"] || (depth0 && depth0["default"]) || alias3).call(alias2,(depth0 != null ? depth0.lawyerDisplay : depth0),"--",{"name":"default","hash":{},"data":data}))
    + "</div>\n  </div>\n</div>\n<form class=\"form-horizontal manage-lawyer-form\">\n  <div class=\"form-group required\">\n    <label class=\"control-label col-sm-4 col-md-3\">Should This Filing Have a Filer?</label>\n    <div class=\"col-md-4\">\n      <label class=\"radio-inline\">\n        <input type=\"radio\" name=\"hasLawyer\" value=\"1\" data-parsley-required=\"true\" checked />\n        Yes\n      </label>\n      <label class=\"radio-inline\">\n        <input type=\"radio\" name=\"hasLawyer\" value=\"0\" data-parsley-required=\"true\" />\n        No\n      </label>\n    </div>\n  </div>\n  <div class=\"form-group required new-lawyer\">\n    <label class=\"control-label col-sm-4 col-md-3\">New Filer</label>\n    <div class=\"col-md-4\">\n      <select name=\"lawyerExternalUserId\" class=\"form-control\" data-parsley-required=\"true\">\n        <option value=\"\">--</option>\n"
    + ((stack1 = helpers.each.call(alias2,(depth0 != null ? depth0.lawyers : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "      </select>\n    </div>\n  </div>\n</form>\n\n<div class=\"row\">\n  <div class=\"col-sm-8 col-md-9 col-sm-offset-4 col-md-offset-3\">\n"
    + ((stack1 = (helpers.ifCond || (depth0 && depth0.ifCond) || alias3).call(alias2,(depth0 != null ? depth0.viewMode : depth0),"===","ext",{"name":"ifCond","hash":{},"fn":container.program(3, data, 0),"inverse":container.program(5, data, 0),"data":data})) != null ? stack1 : "")
    + "    <button class=\"btn btn-primary ladda-button submit\" data-style=\"expand-right\">\n      <span class=\"ladda-label\">Submit</span>\n    </button>\n  </div>\n</div>";
},"useData":true});