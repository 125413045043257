/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Marionette = require('backbone.marionette');
var Syphon = require('backbone.syphon');

var toggleForm = require('common/util/toggleForm');
var modelGet = require('common/util/modelGet');
var parsleyUtil = require('common/util/parsleyUtil');

var tmplDebtManagementServicesAnnualReportProductionReportSectionView = require('./DebtManagementServicesAnnualReportProductionReportSectionView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplDebtManagementServicesAnnualReportProductionReportSectionView,

  className: 'debt-management-services-annual-report-production-report-section-view',

  behaviors: {
    RadioGroupToggleBehavior: {
      radios: [
        {
          target: '#isSufficientExplanationArea',
          radioGroup: '[name="isSufficient"]',
          valueToShow: '0'
        }
      ]
    }
  },

  ui: {
    productionReportForm: '.production-report-form',
    notAvailable: '.not-available',
    numEnrolledInputs: '#numEnrolledInputs',
    numEnrolledExplanation: '#numEnrolledExplanation',
    numSignedUpInputs: '#numSignedUpInputs',
    numSignedUpExplanation: '#numSignedUpExplanation',
    numCompletedInputs: '#numCompletedInputs',
    numCompletedExplanation: '#numCompletedExplanation',
    numDroppedOutAfterThreeMonthsInputs: '#numDroppedOutAfterThreeMonthsInputs',
    numDroppedOutAfterThreeMonthsExplanation: '#numDroppedOutAfterThreeMonthsExplanation',
    delinquentMoreThanThirtyDaysInputs: '#delinquentMoreThanThirtyDaysInputs',
    delinquentMoreThanThirtyDaysExplanation: '#delinquentMoreThanThirtyDaysExplanation',
    numDeniedInputs: '#numDeniedInputs',
    numDeniedExplanation: '#numDeniedExplanation',
    dollarDebtManagementInputs: '#dollarDebtManagementInputs',
    dollarDebtManagementExplanation: '#dollarDebtManagementExplanation',
    numDebtManagementExplanation: '#numDebtManagementExplanation',
    paymentTransmittedInputs: '#paymentTransmittedInputs',
    paymentTransmittedExplanation: '#paymentTransmittedExplanation',
    maxFundsInputs: '#maxFundsInputs',
    maxFundsExplanation: '#maxFundsExplanation'
  },

  events: {
    'change @ui.notAvailable': 'onChangeNotAvailable'
  },

  onBeforeShow: function() {
    var numEnrolledNotAvailable = modelGet(this.model, 'annualReport.numEnrolledNotAvailable');
    this.toggleQuestionInputs(this.ui.numEnrolledInputs, numEnrolledNotAvailable);
    this.toggleExplanationInputs(this.ui.numEnrolledExplanation, numEnrolledNotAvailable);

    var numSignedUpNotAvailable = modelGet(this.model, 'annualReport.numSignedUpNotAvailable');
    this.toggleQuestionInputs(this.ui.numSignedUpInputs, numSignedUpNotAvailable);
    this.toggleExplanationInputs(this.ui.numSignedUpExplanation, numSignedUpNotAvailable);

    var numCompletedNotAvailable = modelGet(this.model, 'annualReport.numCompletedNotAvailable');
    this.toggleQuestionInputs(this.ui.numCompletedInputs, numCompletedNotAvailable);
    this.toggleExplanationInputs(this.ui.numCompletedExplanation, numCompletedNotAvailable);

    var numDroppedOutAfterThreeMonthsNotAvailable = modelGet(
      this.model,
      'annualReport.numDroppedOutAfterThreeMonthsNotAvailable'
    );
    this.toggleQuestionInputs(this.ui.numDroppedOutAfterThreeMonthsInputs, numDroppedOutAfterThreeMonthsNotAvailable);
    this.toggleExplanationInputs(
      this.ui.numDroppedOutAfterThreeMonthsExplanation,
      numDroppedOutAfterThreeMonthsNotAvailable
    );

    var delinquentMoreThanThirtyDaysNotAvailable = modelGet(
      this.model,
      'annualReport.delinquentMoreThanThirtyDaysNotAvailable'
    );
    this.toggleQuestionInputs(this.ui.delinquentMoreThanThirtyDaysInputs, delinquentMoreThanThirtyDaysNotAvailable);
    this.toggleExplanationInputs(
      this.ui.delinquentMoreThanThirtyDaysExplanation,
      delinquentMoreThanThirtyDaysNotAvailable
    );

    var numDeniedNotAvailable = modelGet(this.model, 'annualReport.numDeniedNotAvailable');
    this.toggleQuestionInputs(this.ui.numDeniedInputs, numDeniedNotAvailable);
    this.toggleExplanationInputs(this.ui.numDeniedExplanation, numDeniedNotAvailable);

    var dollarDebtManagementNotAvailable = modelGet(this.model, 'annualReport.dollarDebtManagementNotAvailable');
    this.toggleQuestionInputs(this.ui.dollarDebtManagementInputs, dollarDebtManagementNotAvailable);
    this.toggleExplanationInputs(this.ui.dollarDebtManagementExplanation, dollarDebtManagementNotAvailable);

    var paymentTransmittedNotAvailable = modelGet(this.model, 'annualReport.paymentTransmittedNotAvailable');
    this.toggleQuestionInputs(this.ui.paymentTransmittedInputs, paymentTransmittedNotAvailable);
    this.toggleExplanationInputs(this.ui.paymentTransmittedExplanation, paymentTransmittedNotAvailable);

    var maxFundsNotAvailable = modelGet(this.model, 'annualReport.maxFundsNotAvailable');
    this.toggleQuestionInputs(this.ui.maxFundsInputs, maxFundsNotAvailable);
    this.toggleExplanationInputs(this.ui.maxFundsExplanation, maxFundsNotAvailable);

    Syphon.deserialize(this, this.model.get('annualReport'));
  },

  onChangeNotAvailable: function(e) {
    var $checkbox = $(e.target);
    var isNotAvailable = $checkbox.prop('checked');
    var formTargetSelector = $checkbox.attr('data-target');
    var $questionInputs = this.$el.find(formTargetSelector + 'Inputs');
    var $explanationInputs = this.$el.find(formTargetSelector + 'Explanation');
    this.toggleQuestionInputs($questionInputs, isNotAvailable);
    this.toggleExplanationInputs($explanationInputs, isNotAvailable);
  },

  validate: function(config) {
    if (config.type === 'save') {
      parsleyUtil.disableRequiredValidation(this.ui.productionReportForm);
    } else {
      parsleyUtil.enableRequiredValidation(this.ui.productionReportForm);
    }
    return this.ui.productionReportForm.parsley().whenValidate();
  },

  getFormData: function() {
    return Syphon.serialize(this);
  },

  toggleQuestionInputs: function($element, isNotAvailable) {
    toggleForm($element, !isNotAvailable);
  },

  toggleExplanationInputs: function($element, isAvailable) {
    toggleForm($element, isAvailable);
  }
});
