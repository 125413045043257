/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var Marionette = require('backbone.marionette');

require('../styles/NonDepIndividualRowView.css');
var tmplNonDepIndividualRowView = require('../templates/NonDepIndividualRowView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplNonDepIndividualRowView,

  tagName: 'tr',

  className: 'non-dep-individual-row-view',

  ui: {
    address: '.address'
  },

  regions: {
    address: '@ui.address'
  },

  templateHelpers: function() {
    var highlightLabels = {
      firstName: 'First Name',
      lastName: 'Last Name',
      ssn: 'SSN',
      dob: 'Date of Birth',
      nmlsId: 'NMLS ID'
    };
    var hits = this.model.get('hits');
    _.each(hits, function(hit) {
      hit.searchMatches = _.reduce(
        hit.highlight,
        function(memo, matches, key) {
          return memo.concat(
            _.map(matches, function(match) {
              return {
                label: highlightLabels[key],
                value: match
              };
            })
          );
        },
        []
      );
    });
  }
});
