/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var cleanUpResponse = require('common/util/cleanUpResponse');

module.exports = function(formData) {
  var deferred = $.Deferred();

  $.soap({
    body: {
      SubmitIndividualAddress: {
        $xmlns: 'http://dobs.pa.gov/sc/individual/bpm/1.0',
        IndividualAddressID: formData.individualAddressId,
        IndividualID: formData.individualId,
        TypeID: formData.typeId,
        AddressLine1: formData.addressLine1,
        AddressLine2: formData.addressLine2,
        AddressLine3: formData.addressLine3,
        County: formData.county,
        City: formData.city,
        State: formData.state,
        PostalCode: formData.postalCode,
        Country: formData.country,
        IsDeleted: formData.isDeleted
      }
    },
    blueprint: {
      address: {
        addressLine1: '$..AddressLine1',
        addressLine2: '$..AddressLine2',
        addressLine3: '$..AddressLine3',
        county: '$..County',
        city: '$..City',
        state: '$..State',
        postalCode: '$..PostalCode',
        country: '$..Country'
      },
      type: '$..Type',
      typeId: '$..TypeID'
    }
  })
    .done(function(response) {
      cleanUpResponse(response);
      deferred.resolve(response);
    })
    .fail(function(err) {
      deferred.reject();
    });

  return deferred.promise();
};
