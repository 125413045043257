var Handlebars = require("handlebars/dist/handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "";
},"3":function(container,depth0,helpers,partials,data) {
    return "    <a class=\"btn btn-primary btn-new-investment pull-right\" href=\"#corpfin/new-investment\" role=\"button\">Add\n      Investment</a>\n";
},"5":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "          <option value=\""
    + alias4(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\">"
    + alias4(((helper = (helper = helpers.type || (depth0 != null ? depth0.type : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"type","hash":{},"data":data}) : helper)))
    + "</option>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "\n<div class=\"side-bar-container pull-left\"></div>\n<div class=\"dashboard-container pull-right\">\n  <div class=\"clearfix\">\n    <h2 class=\"pull-left\">Investment Search</h2>\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.isReadOnly : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data})) != null ? stack1 : "")
    + "  </div>\n  <div class=\"row\">\n    <div class=\"col-md-3\">\n      <div class=\"form-group\">\n        <label class=\"control-label\">Fund Name</label>\n        <input type=\"text\" name=\"fundName\" class=\"form-control\" placeholder=\"Fund Name\" />\n      </div>\n    </div>\n    <div class=\"col-md-3\">\n      <div class=\"form-group\">\n        <label class=\"control-label\">Investment Type</label>\n        <select name=\"investmentTypeId\" class=\"form-control\">\n          <option value=\"\">--</option>\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.recordTypes : depth0),{"name":"each","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "        </select>\n      </div>\n    </div>\n    <div class=\"col-md-3\">\n      <div class=\"form-group\">\n        <label class=\"control-label\">File Number</label>\n        <input type=\"text\" name=\"fileNumber\" class=\"form-control\" placeholder=\"File Number\" />\n      </div>\n    </div>\n  </div>\n  <div class=\"search-buttons m-b-sm\">\n    <button class=\"btn btn-primary ladda-button search\" data-style=\"expand-right\">\n      <span class=\"ladda-label\"><i class=\"fa fa-search m-r-sm\"></i>Search</span>\n    </button>\n    <button class=\"btn btn-default clear-filters\"><i class=\"fa fa-eraser m-r-sm\"></i>Clear Filters</button>\n    <span class=\"search-summary\"></span>\n  </div>\n  <div class=\"search-results-container\"></div>\n</div>";
},"useData":true});