/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Marionette = require('backbone.marionette');
var Radio = require('backbone.radio');

var UIUtil = require('psa-core/util/ui');
var GridView = require('psa-marionette/views/GridView');

var MomentCell = require('common/backgrid/MomentCell');
var MultiButtonCell = require('common/backgrid/MultiButtonCell');

var ConSerComplaintWithdrawCorrespondenceModalView = require('./ConSerComplaintWithdrawCorrespondenceModalView');
var tmplConserComplaintSectionCorrespondenceView = require('../templates/ConserComplaintSectionCorrespondenceView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplConserComplaintSectionCorrespondenceView,

  className: 'con-ser-complaint-section-correspondence-view',

  ui: {
    correspondenceGrid: '.correspondence-grid'
  },

  regions: {
    correspondenceGrid: '@ui.correspondenceGrid'
  },

  onBeforeRender: function() {},

  onBeforeShow: function() {
    var correspondences = this.model.get('correspondences');
    var complaintId = this.model.get('complaintId');

    this.correspondenceGrid = new GridView({
      columns: [
        {
          name: 'requestedByName',
          label: 'Initiated By',
          cell: 'string',
          headerCell: 'custom',
          width: 25,
          editable: false
        },
        {
          name: 'requestedOn',
          label: 'Initiated On',
          cell: MomentCell.MomentCell,
          formatter: MomentCell.MomentDateFormatter.extend({
            emptyValue: '--'
          }),
          sortValue: MomentCell.MomentSortValue,
          headerCell: 'custom',
          width: 25,
          editable: false
        },
        {
          name: 'status',
          label: 'Status',
          cell: 'string',
          headerCell: 'custom',
          width: 34,
          editable: false
        },
        {
          name: '',
          label: '',
          cell: MultiButtonCell.extend({
            buttons: [
              {
                name: 'Withdraw',
                buttonClass: 'btn-danger btn-sm',
                renderable: function(dataModel) {
                  var statusId = dataModel.get('statusId');
                  var recipientTypeId = dataModel.get('recipientTypeId');
                  // Correspondence task sent out to external
                  return (statusId === '1' && recipientTypeId !== '3') || (statusId === '2' && recipientTypeId === '3');
                },
                onClick: this.onClickWithdrawCorrespondence.bind(this)
              },
              {
                name: 'Open',
                buttonClass: 'btn-primary btn-sm',
                href: function(model) {
                  var correspondenceId = model.get('correspondenceId');
                  return '#conser/complaint/' + complaintId + '/correspondence/' + correspondenceId;
                }
              }
            ]
          }),
          headerCell: 'custom',
          width: 16,
          sortable: false,
          editable: false
        }
      ],
      emptyText: 'No Related Correspondences',
      collection: new Backbone.Collection(correspondences)
    });
    this.showChildView('correspondenceGrid', this.correspondenceGrid);
  },

  onClickWithdrawCorrespondence: function(e, model) {
    e.preventDefault();
    var complaintId = model.get('complaintId');
    UIUtil.showModal({
      title: 'Withdraw Correspondence',
      view: new ConSerComplaintWithdrawCorrespondenceModalView({ model: model })
    }).done(function() {
      Radio.channel('navigate').trigger('show:int:cs:complaint', complaintId);
    });
  }
});
