var Handlebars = require("handlebars/dist/handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "    <div class=\"row\">\n      <div class=\"col-sm-6 col-sm-offset-3\">\n        <div class=\"alert alert-info payment-alert\" role=\"alert\">\n          <p class=\"title\">\n            <i class=\"fa fa-exclamation-triangle\" aria-hidden=\"true\"></i>\n            <span>"
    + alias4(((helper = (helper = helpers.PAYMENT_DELETED_TITLE || (depth0 != null ? depth0.PAYMENT_DELETED_TITLE : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"PAYMENT_DELETED_TITLE","hash":{},"data":data}) : helper)))
    + "</span>\n          </p>\n          <p>"
    + alias4(((helper = (helper = helpers.PAYMENT_DELETED_MESSAGE || (depth0 != null ? depth0.PAYMENT_DELETED_MESSAGE : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"PAYMENT_DELETED_MESSAGE","hash":{},"data":data}) : helper)))
    + "</p>\n          <a href=\"#ext/nondep/dashboard\" role=\"button\" class=\"btn btn-primary m-t-sm\">Return Home</a>\n        </div>\n      </div>\n    </div>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {});

  return "\n<div class=\"title-bar clearfix\">\n  <h1 class=\"page-title\">"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? depth0.task : depth0)) != null ? stack1.taskType : stack1)) != null ? stack1.description : stack1), depth0))
    + "</h1>\n</div>\n<div class=\"header-bar-container\"></div>\n<div class=\"breadcrumb-container\"></div>\n<div class=\"container-fluid m-b-lg\">\n"
    + ((stack1 = helpers["if"].call(alias3,((stack1 = (depth0 != null ? depth0.compliancePaymentDetails : depth0)) != null ? stack1.paymentIsDeleted : stack1),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "  <div class=\"summary-container\"></div>\n  <div class=\"payment-schedule\">\n    <div class=\"form-horizontal\">\n      <div class=\"form-group\">\n        <label class=\"control-label col-sm-3\">Fine Amount</label>\n        <div class=\"form-control-static col-sm-6\">"
    + alias2(((helper = (helper = helpers.fineAmount || (depth0 != null ? depth0.fineAmount : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(alias3,{"name":"fineAmount","hash":{},"data":data}) : helper)))
    + "</div>\n      </div>\n      <div class=\"form-group\">\n        <label class=\"control-label col-sm-3\">Payment Plan Description</label>\n        <p class=\"form-control-static col-sm-6\">"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.compliancePaymentDetails : depth0)) != null ? stack1.paymentPlanDescription : stack1), depth0))
    + "</p>\n      </div>\n    </div>\n  </div>\n  <div class=\"row\">\n    <div class=\"payment-invoice col-sm-9 col-md-6 col-sm-offset-3\"></div>\n  </div>\n</div>";
},"useData":true});