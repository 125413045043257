/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Marionette = require('backbone.marionette');

var GridView = require('psa-marionette/views/GridView');
var PageableCollection = require('psa-backbone/models/PageableCollection');

var ButtonLinkCell = require('common/backgrid/ButtonLinkCell');
var DollarCell = require('common/backgrid/DollarCell');
var MomentCell = require('common/backgrid/MomentCell');
var LinkCell = require('common/backgrid/LinkCell');

var NonDepInternalSidebarNavView = require('../../common/views/NonDepInternalSidebarNavView');

require('../styles/NonDepInternalComplianceView.css');
var tmplNonDepInternalComplianceView = require('../templates/NonDepInternalComplianceView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplNonDepInternalComplianceView,

  className: 'non-dep-compliance-view container-fluid',

  ui: {
    sideBar: '.side-bar-container',
    onGoingCases: '.ongoing-cases-container',
    paymentDue: '.payment-due-container'
  },

  regions: {
    sideBar: '@ui.sideBar',
    onGoingCases: '@ui.onGoingCases',
    paymentDue: '@ui.paymentDue'
  },

  onBeforeShow: function() {
    this.showChildView('sideBar', new NonDepInternalSidebarNavView());

    var onGoingCasesGrid = new GridView({
      columns: [
        {
          name: 'caseNumber',
          label: 'Case Number',
          cell: 'string',
          headerCell: 'custom',
          width: 12,
          editable: false
        },
        {
          name: 'entityName',
          label: 'Entity Name',
          cell: LinkCell.extend({
            emptyText: '--',
            href: function(model) {
              var entityId = model.get('entityId');
              var url = entityId ? '#nondep/entity/' + entityId + '/dashboard' : '';
              return url;
            }
          }),
          headerCell: 'custom',
          width: 15,
          editable: false
        },
        {
          name: 'licenseNumber',
          label: 'License Number',
          cell: LinkCell.extend({
            emptyText: '--',
            href: function(model) {
              var entityId = model.get('entityId');
              var licenseId = model.get('licenseId');
              var url = entityId && licenseId ? '#nondep/entity/' + entityId + '/license/' + licenseId : '';
              return url;
            }
          }),
          headerCell: 'custom',
          width: 13,
          editable: false
        },
        {
          name: 'licenseCode',
          label: 'License Type',
          cell: 'string',
          headerCell: 'custom',
          width: 10,
          editable: false
        },
        {
          name: 'administrator',
          label: 'Administrator',
          cell: 'string',
          headerCell: 'custom',
          width: 18,
          editable: false
        },
        {
          name: 'complianceStatus',
          label: 'Status',
          cell: 'string',
          headerCell: 'custom',
          width: 22,
          editable: false
        },
        {
          name: '',
          label: '',
          cell: ButtonLinkCell.extend({
            name: 'Open',
            buttonClass: 'btn-primary btn-sm',
            href: function(model) {
              return '#nondep/compliance/case/' + model.get('id');
            }
          }),
          headerCell: 'custom',
          width: 10,
          editable: false,
          sortable: false
        }
      ],
      collection: new PageableCollection(this.model.get('complianceCases'), { pageSize: 10 }),
      emptyText: 'No Ongoing Compliance Cases'
    });
    this.showChildView('onGoingCases', onGoingCasesGrid);

    var paymentDueGrid = new GridView({
      columns: [
        {
          name: 'caseNumber',
          label: 'Case Number',
          cell: 'string',
          headerCell: 'custom',
          width: 12,
          editable: false
        },
        {
          name: 'entityName',
          label: 'Entity Name',
          cell: LinkCell.extend({
            emptyText: '--',
            href: function(model) {
              var entityId = model.get('entityId');
              var url = entityId ? '#nondep/entity/' + entityId + '/dashboard' : '';
              return url;
            }
          }),
          headerCell: 'custom',
          width: 15,
          editable: false
        },
        {
          name: 'licenseNumber',
          label: 'License Number',
          cell: LinkCell.extend({
            emptyText: '--',
            href: function(model) {
              var entityId = model.get('entityId');
              var licenseId = model.get('licenseId');
              var url = entityId && licenseId ? '#nondep/entity/' + entityId + '/license/' + licenseId : '';
              return url;
            }
          }),
          headerCell: 'custom',
          width: 13,
          editable: false
        },
        {
          name: 'licenseCode',
          label: 'License Type',
          cell: 'string',
          headerCell: 'custom',
          width: 10,
          editable: false
        },
        {
          name: 'description',
          label: 'Description',
          cell: 'string',
          headerCell: 'custom',
          width: 18,
          editable: false
        },
        {
          name: 'amountDue',
          label: 'Payment Amount',
          cell: DollarCell,
          headerCell: 'custom',
          width: 13,
          editable: false
        },
        {
          name: 'dueDate',
          label: 'Due Date',
          cell: MomentCell.MomentCell,
          formatter: MomentCell.MomentDateFormatter.extend({
            emptyValue: '--'
          }),
          sortValue: MomentCell.MomentSortValue,
          headerCell: 'custom',
          width: 9,
          editable: false
        },
        {
          name: '',
          label: '',
          cell: ButtonLinkCell.extend({
            name: 'Open',
            buttonClass: 'btn-primary btn-sm',
            href: function(model) {
              return '#nondep/compliance/case/' + model.get('complianceId');
            }
          }),
          headerCell: 'custom',
          width: 10,
          editable: false,
          sortable: false
        }
      ],

      collection: new PageableCollection(this.model.get('paymentDue'), { pageSize: 10 }),
      emptyText: 'No Payment Due'
    });
    this.showChildView('paymentDue', paymentDueGrid);
  }
});
