/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Marionette = require('backbone.marionette');
var Syphon = require('backbone.syphon');

var FileUploadView = require('common/views/FileUploadView');
var makeUploadCollection = require('common/util/makeUploadCollection');

var tmplDepExtInitiateCorrespondenceFormView = require('../templates/DepExtInitiateCorrespondenceFormView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplDepExtInitiateCorrespondenceFormView,

  className: 'dep-initiate-correspondence-form-view',

  ui: {
    fileContainer: '.file-container',
    correspondenceForm: '.correspondence-form',
    correspondenceComments: '.correspondence-comments',
    missingFile: '.missing-file'
  },

  regions: {
    fileContainer: '@ui.fileContainer'
  },

  onBeforeShow: function() {
    this.fileUploadView = new FileUploadView({
      collection: makeUploadCollection([]),
      isReadOnly: false,
      allowMultipleFiles: true,
      metadata: {
        documentTypeId: 20012
      }
    });
    this.showChildView('fileContainer', this.fileUploadView);
  },

  removeValidationErrors: function() {
    this.ui.missingFile.hide();
  },

  validate: function() {
    var defer = $.Deferred();
    this.removeValidationErrors();

    var hasOneSuccessfulFile = true;
    var uploads = this.fileUploadView.collection;

    if (!uploads.length) {
      hasOneSuccessfulFile = false;
    } else {
      var hasSuccessfulFile;
      if (uploads) {
        hasSuccessfulFile = uploads.some(function(model) {
          return model.get('file1');
        });
      }
      hasOneSuccessfulFile = hasSuccessfulFile;
    }
    if (!hasOneSuccessfulFile) {
      this.ui.missingFile.show();
      defer.reject();
    }
    if (hasOneSuccessfulFile) {
      defer.resolve();
    }

    return defer.promise();
  },

  getFormData: function() {
    var formData = Syphon.serialize(this);

    formData.documents = this.fileUploadView.collection.toJSON();

    return formData;
  }
});
