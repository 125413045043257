/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var Syphon = require('backbone.syphon');
var Marionette = require('backbone.marionette');
var Ladda = require('ladda');
var Radio = require('backbone.radio');

var modelGet = require('common/util/modelGet');
var UIUtil = require('psa-core/util/ui');
var GridView = require('psa-marionette/views/GridView');
var PageableCollection = require('psa-backbone/models/PageableCollection');

var BreadcrumbView = require('common/views/BreadcrumbView');
var BooleanCell = require('common/backgrid/BooleanCell');
var AddressCell = require('common/backgrid/AddressCell');

var DepEntitySearchWithCategoryModalView = require('./DepEntitySearchWithCategoryModalView');

var SubmitEntityMerge = require('../services/SubmitEntityMerge');

var tmplDepEntityMergeView = require('../templates/DepEntityMergeView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplDepEntityMergeView,

  className: 'dep-entity-merge-view container-fluid',

  ui: {
    breadcrumb: '.breadcrumb-container',
    entityMergeForm: '.entity-merge-form',
    entitySearchSection: '.entity-search-section',
    entitySearchButton: '.btn-entity-search',
    clearEntitySearch: '.clear-entity-search',
    selectedEntitySearchEntityName: '.selected-entity-search-entity-name',
    selectedEntityId: '.selected-entity-id',
    submit: '.btn-submit',
    entityLocationGridContainer: '.entity-location-grid-container'
  },

  events: {
    'click @ui.clearEntitySearch': 'onClearEntitySearch',
    'click @ui.entitySearchButton': 'onClickEntitySearch',
    'click @ui.submit': 'onClickSubmit'
  },

  regions: {
    breadcrumb: '@ui.breadcrumb',
    entityLocationGridContainer: '@ui.entityLocationGridContainer'
  },

  onBeforeShow: function() {
    this.showChildView(
      'breadcrumb',
      new BreadcrumbView({
        breadcrumbs: [
          {
            link: '#dep/dashboard',
            text: 'Home'
          },
          {
            text: this.model.get('entityIdentifiers').entityName,
            link: '#dep/entity/' + this.model.get('entityId') + '/dashboard'
          },
          {
            text: 'Merge Entity'
          }
        ]
      })
    );

    this.ui.submit.prop('disabled', true);
    var locationPickerGridColumns = [
      {
        name: 'shouldKeep',
        label: 'Include?',
        cell: BooleanCell,
        headerCell: 'custom',
        width: 10,
        editable: true,
        sortable: false
      },
      {
        name: 'address',
        label: 'Address',
        cell: AddressCell,
        headerCell: 'custom',
        width: 60,
        editable: false,
        sortable: false
      },
      {
        name: 'country',
        label: 'Country',
        cell: 'string',
        headerCell: 'custom',
        width: 30,
        editable: false
      }
    ];
    this.entityLocationsGrid = new GridView({
      columns: locationPickerGridColumns,
      pageable: true,
      collection: new PageableCollection([], { pageSize: 25 }),
      emptyText: 'No Locations Available'
    });
    this.showChildView('entityLocationGridContainer', this.entityLocationsGrid);
  },

  onClickEntitySearch: function(e) {
    e.preventDefault();
    var self = this;

    UIUtil.showModal({
      title: 'Search for an Entity',
      dialogClasses: 'modal-dialog-entity-search-modal-view',
      view: new DepEntitySearchWithCategoryModalView({
        model: this.model,
        categoryId: modelGet(this.model, 'entityIdentifiers.categoryId')
      })
    }).done(function(entity) {
      if (entity) {
        self.ui.selectedEntitySearchEntityName.val(entity.entityName);
        self.ui.selectedEntityId.val(entity.id);
        var placeholderView = Radio.channel('dp').request('build:dep-location-picker-grid-view', entity.id);
        self.showChildView('entityLocationGridContainer', placeholderView);
        placeholderView.options.viewPromise.done(function(view) {
          self.entityLocationsGrid = view;
          self.ui.submit.prop('disabled', false);
        });
      } else {
        self.ui.selectedEntitySearchEntityName.val('');
        self.ui.selectedEntityId.val('');
        self.entityLocationsGrid.collection.reset();
      }
      self.ui.entityMergeForm.parsley().validate();
    });
  },

  onClearEntitySearch: function(e) {
    e.preventDefault();
    this.ui.selectedEntitySearchEntityName.val('');
    this.ui.selectedEntityId.val('');
    this.entityLocationsGrid.collection.reset();
  },

  onClickSubmit: function(event) {
    event.preventDefault();
    var self = this;
    this.validate().done(
      function() {
        var laddaContext = Ladda.create(this.ui.submit.get(0));
        laddaContext.start();
        var formData = self.getFormData();
        SubmitEntityMerge(formData)
          .done(function() {
            Radio.channel('navigate').trigger('show:int:dp:entity', formData.entityId);
          })
          .fail(function() {
            laddaContext.stop();
          });
      }.bind(this)
    );
  },

  validate: function() {
    return this.ui.entityMergeForm.parsley().whenValidate();
  },

  getFormData: function() {
    var formData = Syphon.serialize(this);
    formData.entityId = this.model.get('entityId');
    formData.entityName = this.model.get('entityIdentifiers').entityName;
    formData.mergedLocations = _.reduce(
      this.entityLocationsGrid.collection.toJSON(),
      function(memo, location) {
        if (location.shouldKeep) {
          memo.push(location.id);
        }
        return memo;
      },
      []
    );
    return formData;
  }
});
