/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Cookie = require('js-cookie');
var _ = require('underscore');
var Session = require('psa-core/Session');

var config = require('./' + ENVIRONMENT + '.json');

if ($.isEmptyObject(config)) {
  throw 'Error: Configuration is empty. The most likely cause is a build for an invalid environment.';
}

config.webServiceUrl = [config.gatewayProtocol, config.gatewayHost, '/home/', config.organizationName].join('');

config.getOrganizationDN = function() {
  return ['o=', config.organizationName, ',', config.instanceIdentifier].join('');
};

config.getOrganizationName = function() {
  return config.organizationName;
};

config.getPreLoginUrl = function() {
  return [config.webServiceUrl, config.preLoginUrl].join('');
};

config.getLogMeOutUrl = function() {
  return [config.webServiceUrl, config.logMeOutUrl].join('');
};

config.getGatewayUrl = function() {
  var url = [config.webServiceUrl, config.gatewayUrl].join('');
  if (!_.isUndefined(Session.preLoginInfo)) {
    var cookieName = Session.preLoginInfo.CheckName;
    var cookieValue = Cookie.get(Session.preLoginInfo.CheckName);
    if (cookieValue) {
      url += '?' + cookieName + '=' + cookieValue;
      url += '&organization=' + encodeURIComponent(config.getOrganizationDN());
    }
  }
  return url;
};

config.getGatewayUrlPub = function() {
  var url = [config.webServiceUrl, config.gatewayUrl].join('');
  return url;
};

config.getUploadGatewayUrl = function() {
  var url = [config.webServiceUrl, config.uploadGatewayUrl].join('');
  var cookieName = Session.preLoginInfo.CheckName;
  var cookieValue = Cookie.get(Session.preLoginInfo.CheckName);
  if (cookieValue) {
    url += '?' + cookieName + '=' + cookieValue;
    url += '&organization=' + encodeURIComponent(config.getOrganizationDN());
  }
  return url;
};

config.getDownloadGatewayUrl = function() {
  var url = [config.webServiceUrl, config.downloadGatewayUrl].join('');
  var cookieName = Session.preLoginInfo.CheckName;
  var cookieValue = Cookie.get(Session.preLoginInfo.CheckName);
  if (cookieValue) {
    url += '?' + cookieName + '=' + cookieValue;
    url += '&organization=' + encodeURIComponent(config.getOrganizationDN());
  }
  return url;
};

config.getLandingUrl = function() {
  return [config.gatewayProtocol + config.gatewayHost + '/'].join('');
};

config.getGatewayCookieDomain = function() {
  return config.gatewayCookieDomain;
};

config.getOTDSLoginForm = function() {
  return [config.otdsProtocol, config.otdsHost, config.otdsRestUrl, '/login'].join('');
};

config.getOTDSContentServerAuthUrl = function() {};

config.getOTDSRESTAuthEndpoint = function() {
  return [config.otdsProtocol, config.otdsHost, config.otdsRestUrl, '/rest/authentication/credentials'].join('');
};

config.getOTDSTicketCookieName = function() {
  return config.otdsTicketCookieName;
};

config.getProcessSuiteOTDSResourceId = function() {
  return config.processSuiteOTDSResourceId;
};

config.getBaseWebContext = function() {
  return config.webContext;
};

config.getDefaultNavigationTrigger = function() {
  return config.defaultNavigationTrigger;
};

config.getExternalUserServiceAccountUserId = function() {
  return config.externalUserServiceAccountUserId;
};

config.getDoBSLogInUrl = function() {
  return [
    config.webServiceUrl,
    config.gatewayUrl,
    '?organization=',
    encodeURIComponent(config.getOrganizationDN()),
    '&login=1'
  ].join('');
};

config.getAnonymousDoBSUrl = function() {
  var url = [config.webServiceUrl, config.gatewayUrl].join('');
  url += '?organization=' + encodeURIComponent(config.getOrganizationDN()) + '&login=0';
  return url;
};

config.getDoBSMFAValidationUrl = function() {
  return [
    config.webServiceUrl,
    config.gatewayUrl,
    '?organization=',
    encodeURIComponent(config.getOrganizationDN()),
    '&login=2'
  ].join('');
};

config.getDoBSMFAResendTokenUrl = function() {
  return [
    config.webServiceUrl,
    config.gatewayUrl,
    '?organization=',
    encodeURIComponent(config.getOrganizationDN()),
    '&login=3'
  ].join('');
};

config.getDoBSSessionToken = function() {
  return config.dobsSessionToken;
};

config.getKeystoneLoginCookieName = function() {
  return config.keystoneLoginCookieName;
};

config.getKeystoneLoginRedirectURL = function() {
  var url = [config.keystoneLoginURL, '?appCode=', config.keystoneAppCode, '&redirect='].join('');
  if (ENVIRONMENT === 'local.ext') {
    url = [url, config.keystoneHelperAppRedirectURL].join('');
  } else {
    url = [url, config.gatewayProtocol, config.gatewayHost].join('');
  }
  if (!_.isUndefined(config.tomeeAppName) && config.tomeeAppName !== '') {
    url = [url, '/', config.tomeeAppName].join('');
  }
  return url;
};

config.getKeystoneLogoutRedirectURL = function() {
  var url = config.keystoneLogoutURL + '?redirect=' + config.gatewayProtocol + config.gatewayHost;
  if (!_.isUndefined(config.tomeeAppName) && config.tomeeAppName !== '') {
    return url + '/' + config.tomeeAppName;
  }
  return url;
};

config.getPayeezyUrl = function() {
  return config.payeezyUrl;
};

config.getKeystoneRegister = function() {
  return config.keystoneRegister;
};

config.getKeystoneForgotUserName = function() {
  return config.keystoneForgotUserName;
};

config.getKeystoneForgotPassword = function() {
  return config.keystoneForgotPassword;
};

config.getAboutKeystoneLogin = function() {
  return config.aboutKeystoneLogin;
};

config.getKeystoneTroubleRegistering = function() {
  return config.keystoneTroubleRegistering;
};

config.getIHubJSAPIUrl = function() {
  return config.iHubProtocol + config.iHubJSAPIHost;
};

config.getOTCSSmartUIUrl = function(nodeId) {
  return config.otcsUrl + '/app/nodes/' + nodeId;
};

config.getCSFileEditorLink = function(nodeId) {
  var encodedOTCSURL = encodeURIComponent(config.otcsUrl);
  return (
    config.otcsUrl +
    '?func=Edit.Edit&nodeid=' +
    nodeId +
    '&uiType=2&nextURL=' +
    encodedOTCSURL +
    '%3Ffunc%3Dcsui.closewindow'
  );
};

config.getIHubFolderLink = function(folderDirectory) {
  var folderEncoded = encodeURIComponent(folderDirectory);
  return config.getIHubJSAPIUrl() + '/iportal/dashboard/jsp/myfiles.jsp?folder=' + folderEncoded;
};

config.getInternalHost = function() {
  return config.internalHost;
};

config.getExternalUrl = function() {
  return config.externalUrl;
};

module.exports = config;
