/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2020] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Marionette = require('backbone.marionette');
var Syphon = require('backbone.syphon');
var _ = require('underscore');

var FileUploadCollection = require('psa-backbone/models/FileUploadCollection');

var parsleyUtil = require('common/util/parsleyUtil');
var makeUploadCollection = require('common/util/makeUploadCollection');
var toggleForm = require('common/util/toggleForm');

var FileUploadView = require('common/views/FileUploadView');
var CommentsDocumentView = require('common/views/CommentsDocumentView');

require('../../styles/DepFilingPrintView.css');
var tmplDepBankSubsidiaryRequiredInformationDocView = require('./DepBankSubsidiaryRequiredInformationDocView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplDepBankSubsidiaryRequiredInformationDocView,

  className: 'dep-bank-parity-required-information-doc-view-view',

  ui: {
    documentForm: '.document-form',
    agencyDocContainer: '.agency-doc-container',
    fdicDocContainer: '.fdic-doc-container',
    riskAnalysisContainer: '.risk-analysis-container',
    imposedRestrictionContainer: '.imposed-restriction-container',
    contactInformationContainer: '.contact-information-container',
    isCitationInterpretiveLetter: '[name="isCitationInterpretiveLetter"]',
    isCitation24InterpretiveLetter: '[name="isCitation24InterpretiveLetter"]'
  },

  regions: {
    agencyDocContainer: '@ui.agencyDocContainer',
    fdicDocContainer: '@ui.fdicDocContainer',
    riskAnalysisContainer: '@ui.riskAnalysisContainer',
    imposedRestrictionContainer: '@ui.imposedRestrictionContainer',
    contactInformationContainer: '@ui.contactInformationContainer'
  },

  behaviors: {
    ReadOnlyFormBehavior: {},
    ToggleBehavoir: {
      toggles: [
        // A checkboxes
        {
          target: '.citation-usc-comments-row',
          group: '[name="isCitationUSC"]',
          valuesToShow: ['on']
        },
        {
          target: '.citation-cfr-comments-row',
          group: '[name="isCitationCFR"]',
          valuesToShow: ['on']
        },
        {
          target: '.citation-other-comments-row',
          group: '[name="isCitationOther"]',
          valuesToShow: ['on']
        },
        {
          target: '.agency-row',
          group: '[name="isCitationInterpretiveLetter"]',
          valuesToShow: ['on']
        },
        // B checkboxes
        {
          target: '.citation24-home-state-comments-row',
          group: '[name="isCitation24HomeState"]',
          valuesToShow: ['on']
        },
        {
          target: '.citation24-legal-citations-comments-row',
          group: '[name="isCitation24LegalCitations"]',
          valuesToShow: ['on']
        },
        {
          target: '.fdic-row',
          group: '[name="isCitation24InterpretiveLetter"]',
          valuesToShow: ['on']
        },
        // 7 checkboxes
        {
          target: '.legal-basis7-comments-row',
          group: '[name="isLegalBasis7"]',
          valuesToShow: ['on']
        },
        {
          target: '.legal-basis-usc-comments-row',
          group: '[name="isLegalBasisUSC"]',
          valuesToShow: ['on']
        },
        {
          target: '.legal-basis-cfr-comments-row',
          group: '[name="isLegalBasisCFR"]',
          valuesToShow: ['on']
        },
        {
          target: '.section-201-c',
          group: '[name="activityTypeId"]',
          valuesToShow: ['1']
        },
        {
          target: '.section-201-d',
          group: '[name="activityTypeId"]',
          valuesToShow: ['2']
        }
      ]
    }
  },

  templateHelpers: function() {
    return {
      isReadOnly: this.options.isReadOnly
    };
  },

  onBeforeShow: function() {
    var filingContent = this.model.get('filingContent') || {};
    var agencyDocs = filingContent.agencyInterpretiveDocs || [];
    var fdicDocs = filingContent.fdicInterpretiveDocs || [];
    Syphon.deserialize(this, filingContent);

    // Agency Documents
    this.agencyDocs = new CommentsDocumentView({
      isReadOnly: this.options.isReadOnly,
      allowMultipleFiles: true,
      isRequired: true,
      fileUploadLabel: 'Section II - Agency Interpretive Letter(s)',
      commentsLabel: 'Agency Interpretive Letter(s) Comments',
      commentsName: 'citationInterpretiveLetterComments',
      collection: new FileUploadCollection(agencyDocs),
      model: new Backbone.Model({
        citationInterpretiveLetterComments: filingContent.citationInterpretiveLetterComments
      }),
      documentTypeId: 20001
    });
    this.showChildView('agencyDocContainer', this.agencyDocs);

    // FDIC Documents
    this.fdicDocs = new CommentsDocumentView({
      isReadOnly: this.options.isReadOnly,
      allowMultipleFiles: true,
      isRequired: true,
      fileUploadLabel: 'Section II - FDIC Interpretive Letter(s)',
      commentsLabel: 'FDIC Interpretive Letter(s) Comments',
      commentsName: 'citation24InterpretiveLetterComments',
      collection: new FileUploadCollection(fdicDocs),
      model: new Backbone.Model({
        citation24InterpretiveLetterComments: filingContent.citation24InterpretiveLetterComments
      }),
      documentTypeId: 20001
    });
    this.showChildView('fdicDocContainer', this.fdicDocs);

    //Risk Analysis Doc
    this.riskAnalysisDoc = new FileUploadView({
      isReadOnly: this.options.isReadOnly,
      collection: makeUploadCollection(filingContent.riskAnalysisDoc),
      isRequired: false,
      metadata: {
        documentTypeId: 20001
      }
    });
    this.showChildView('riskAnalysisContainer', this.riskAnalysisDoc);

    // Imposed Restriction Doc
    this.imposedRestrictionsDoc = new FileUploadView({
      isReadOnly: this.options.isReadOnly,
      collection: makeUploadCollection(filingContent.imposedRestrictionsDoc),
      isRequired: false,
      metadata: {
        documentTypeId: 20001
      }
    });
    this.showChildView('imposedRestrictionContainer', this.imposedRestrictionsDoc);

    // Contact Information Doc
    this.contactInformationDoc = new FileUploadView({
      isReadOnly: this.options.isReadOnly,
      collection: makeUploadCollection(filingContent.contactInformationDoc),
      isRequired: false,
      metadata: {
        documentTypeId: 20001
      }
    });
    this.showChildView('contactInformationContainer', this.contactInformationDoc);
  },

  onAttach: function() {
    if (this.options.mode === 'print') {
      this.switchToPrint();
    }
  },

  switchToPrint: function() {
    var textAreas = $('.input-view');

    _.each(textAreas, function(textArea) {
      var textAreaVal = textArea.value;
      var textAreaName = textArea.name;
      var printElementId = '#'.concat(textAreaName, 'PrintView');
      $(printElementId).text(textAreaVal);
    });
    toggleForm(textAreas, false);
  },

  validate: function(config) {
    this.ui.documentForm.parsley().reset();
    this.riskAnalysisDoc.removeValidationErrors();
    this.imposedRestrictionsDoc.removeValidationErrors();
    this.contactInformationDoc.removeValidationErrors();
    this.agencyDocs.removeValidationErrors();
    this.fdicDocs.removeValidationErrors();

    if (config.type === 'save') {
      // Turn off required field validation for this form
      parsleyUtil.disableRequiredValidation(this.ui.documentForm);
    } else {
      var validationPromises = [
        this.ui.documentForm.parsley().whenValidate(),
        this.riskAnalysisDoc.validate({ type: 'submit' }),
        this.imposedRestrictionsDoc.validate({ type: 'submit' }),
        this.contactInformationDoc.validate({ type: 'submit' })
      ];

      if (this.ui.isCitationInterpretiveLetter.is(':checked')) {
        validationPromises.push(this.agencyDocs.validate());
      }

      if (this.ui.isCitation24InterpretiveLetter.is(':checked')) {
        validationPromises.push(this.fdicDocs.validate());
      }

      // Turn on required field validation for this form
      parsleyUtil.enableRequiredValidation(this.ui.documentForm);
    }
    return $.when.apply($, validationPromises);
  },

  getFormData: function() {
    var formData = Syphon.serialize(this);
    formData.agencyDocIds = _.pluck(this.agencyDocs.fileUploadView.collection.toJSON(), 'fileId');
    formData.fdicDocIds = _.pluck(this.fdicDocs.fileUploadView.collection.toJSON(), 'fileId');
    formData.riskAnalysisDocId = this.riskAnalysisDoc.getFirstFileId();
    formData.imposedRestrictionsDocId = this.imposedRestrictionsDoc.getFirstFileId();
    formData.contactInformationDocId = this.contactInformationDoc.getFirstFileId();

    var checkboxes = [
      'isCitationUSC',
      'isCitationCFR',
      'isCitationOther',
      'isCitationInterpretiveLetter',
      'isCitation24FederalDepositAct',
      'isCitation24HomeState',
      'isCitation24LegalCitations',
      'isCitation24InterpretiveLetter',
      'isLegalBasis7',
      'isLegalBasisUSC',
      'isLegalBasisCFR'
    ];
    _.each(checkboxes, function(attr) {
      formData[attr] = +(formData[attr] ? formData[attr] : null);
    });
    return formData;
  }
});
