/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var cleanUpResponse = require('common/util/cleanUpResponse');

module.exports = function(applicationId) {
  var deferred = $.Deferred();

  $.soap({
    body: {
      GetEntitySiteRecordKeepingApplication: {
        $xmlns: 'http://dobs.pa.gov/nd/application/bpm/1.0',
        ApplicationID: applicationId
      }
    },
    blueprintArrays: [
      'GetEntitySiteRecordKeepingApplicationResponse.SitesRecordKeeping.SiteRecordKeeping',
      'GetEntitySiteRecordKeepingApplicationResponse.ApplicationDocuments.ND_ApplicationDocument'
    ],
    blueprint: {
      applicationId: '$..ApplicationID.__text',
      entityId: '$..EntityID.__text',
      instructionsDocumentId: '$..InstructionsDocumentID.__text',
      instructionsDocumentName: '$..InstructionsDocumentName.__text',
      certificationName: '$..CertificationName.__text',
      isCertified: '$..IsCertified.__text',
      applicationDocuments: [
        '$..ND_ApplicationDocument',
        {
          fileId: '$.DocumentID',
          fileName: '$..Name'
        }
      ],
      booksAndRecords: [
        '$..SiteRecordKeeping',
        {
          id: '$..SiteRecordkeepingID',
          companyName: '$..CompanyName',
          firstName: '$..FirstName',
          lastName: '$..LastName',
          addressLine1: '$..AddressLine1',
          addressLine2: '$..AddressLine2',
          addressLine3: '$..AddressLine3',
          city: '$..City',
          state: '$..State',
          country: '$..Country',
          county: '$..County',
          postalCode: '$..PostalCode',
          phone: '$..Phone',
          fax: '$..Fax',
          email: '$..Email',
          description: '$..Description',
          isDeleted: '$..IsDeleted'
        }
      ]
    }
  })
    .done(function(response) {
      cleanUpResponse(response);
      response.isCertified = response.isCertified === '1';

      deferred.resolve(response);
    })
    .fail(function(err) {
      deferred.reject();
    });

  return deferred.promise();
};
