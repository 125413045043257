/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var moment = require('moment');

var cleanUpResponse = require('common/util/cleanUpResponse');

module.exports = function(data) {
  var defer = $.Deferred();

  $.soap({
    body: {
      SubmitEntityCallReport: {
        $xmlns: 'http://dobs.pa.gov/dp/callreport/bpm/1.0',
        EntityID: data.entityId,
        CallReportID: data.callReportId,
        ReportDate: data.reportDate ? moment(data.reportDate, 'MM/DD/YYYY').format('YYYY-MM-DD') : null,
        ReportQuarterEndID: data.reportQuarterEndId,
        ReportYear: data.reportYear,
        TotalManagedAssets: data.totalManagedAssets,
        TotalNonManagedAssets: data.totalNonManagedAssets,
        CustodySafekeepingAccounts: data.custodySafekeepingAccounts,
        TotalTrustCompanyAssets: data.totalTrustCompanyAssets,
        TotalCapital: data.totalCapital,
        TotalAssetsUnderManagement: data.totalAssetsUnderManagement,
        IsDeleted: data.isDeleted,
        IsAmended: data.isAmended,
        Comments: data.comments
      }
    },
    blueprint: {
      newCallReportId: '$..NewCallReportID'
    }
  })
    .done(function(response) {
      cleanUpResponse(response, { cleanUpTextKeys: true });
      defer.resolve(response);
    })
    .fail(function(err) {
      defer.reject(err);
    });

  return defer.promise();
};
