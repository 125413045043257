/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

 var Backbone = require('backbone');

 var PlaceholderView = require('common/views/PlaceholderView');
 
 var GetEntityExamTurnaround = require('../services/GetEntityExamTurnaround');
 
 var DepExamTurnaroundSectionView = require('../views/DepExamTurnaroundSectionView');
 
 module.exports = {
   channel: 'dp',
 
   event: 'build:exam-turnaround-section-view',
 
   builder: function(examId, examAreas) {
     return new PlaceholderView({
       viewPromise: buildExamTurnaroundSectionView(examId, examAreas)
     });
   }
 };
 
 function buildExamTurnaroundSectionView(examId, examAreas) {
   var deferred = $.Deferred();
   GetEntityExamTurnaround(examId)
     .done(function(examTurnaround) {
       deferred.resolve(
         new DepExamTurnaroundSectionView({
           model: new Backbone.Model({
             examTurnaround: examTurnaround,
             examAreas: examAreas
           })
         })
       );
     })
     .fail(function(err) {
       deferred.reject(err);
     });
   return deferred.promise();
 }
 