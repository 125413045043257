/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Marionette = require('backbone.marionette');
var Syphon = require('backbone.syphon');

var tmplDepSubsidiaryFormView = require('../templates/DepSubsidiaryFormView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplDepSubsidiaryFormView,

  className: 'dep-subsidiary-form-view',

  ui: {
    statusDate: '.status-date',
    approvalDate: '.approval-date',
    address: '.address'
  },

  onBeforeShow: function() {
    Syphon.deserialize(this, this.model.get('subsidiary'));

    this.ui.statusDate.datepicker();
    this.ui.approvalDate.datepicker();
  },

  getFormData: function() {
    var formData = Syphon.serialize(this);
    formData.entityId = this.model.get('entityId');
    formData.subsidiaryId = this.model.get('addressId');
    formData.addressId = this.model.get('subsidiaryId');
    return formData;
  }
});
