/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Marionette = require('backbone.marionette');

var tmplCorpFinInvInvestmentDetailSectionView = require('../templates/CorpFinInvInvestmentDetailSectionView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplCorpFinInvInvestmentDetailSectionView,

  className: 'corp-fin-inv-investment-detail-section-view form-horizontal form-static m-b-lg',

  templateHelpers: function() {
    var recordTypeId = this.model.get('recordTypeId');
    var isMF = recordTypeId === '6';
    return {
      isMF: isMF,
      isNoDollarAmount: this.model.get('isNoDollarAmount') === '1'
    };
  }
});
