/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Marionette = require('backbone.marionette');
var Syphon = require('backbone.syphon');

var toggleForm = require('common/util/toggleForm');

var tmplDepAuditFormView = require('../templates/DepAuditFormView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplDepAuditFormView,

  className: 'dep-fund-form-view',

  ui: {
    fundForm: 'form',
    auditDate: '.audit-date',
    receivedDate: '.received-date',
    auditTrustType: '.audit-trust-type-select',
    auditFirm: '.audit-firm'
  },

  events: {
    'change @ui.auditTrustType': 'onChangeAuditTrustType'
  },

  templateHelpers: function() {
    return { cpaAudit: this.model.get('auditTrustTypeId') === '1' };
  },

  onBeforeShow: function() {
    Syphon.deserialize(this, this.model.get('audit'));
    this.ui.auditDate.datepicker();
    this.ui.receivedDate.datepicker();
    this.onChangeAuditTrustType();
  },

  validate: function() {
    return this.ui.fundForm.parsley().whenValidate();
  },

  getFormData: function() {
    return Syphon.serialize(this);
  },

  onChangeAuditTrustType: function() {
    var selectedTrustType = this.ui.auditTrustType.val();
    var isCPATrust = selectedTrustType === '1';

    toggleForm(this.ui.auditFirm, isCPATrust);
  }
});
