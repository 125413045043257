var Handlebars = require("handlebars/dist/handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "            <option value=\""
    + alias4(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\">"
    + alias4(((helper = (helper = helpers.type || (depth0 != null ? depth0.type : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"type","hash":{},"data":data}) : helper)))
    + "</option>\n";
},"3":function(container,depth0,helpers,partials,data) {
    return "phone-format";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "\n<div class=\"form-horizontal\">\n  <div class=\"row\">\n    <div class=\"col-sm-12\">\n      <div class=\"form-group required\">\n        <label class=\"control-label col-sm-3\">Contact Type</label>\n        <div class=\"col-sm-9\">\n          <select name=\"entityContactTypes\" class=\"contact-type-select form-control\" multiple=\"multiple\"\n            data-parsley-required=\"true\" data-parsley-trigger-after-failure=\"change.select2\">\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.contactTypes : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "          </select>\n        </div>\n      </div>\n    </div>\n  </div>\n  <div class=\"row\">\n    <div class=\"col-sm-12\">\n      <div class=\"form-group required\">\n        <label class=\"control-label col-sm-3\">First Name</label>\n        <div class=\"col-sm-9\">\n          <input type=\"text\" name=\"firstName\" class=\"form-control\" placeholder=\"First Name\" data-parsley-maxlength=\"100\"\n            data-parsley-required=\"true\" />\n        </div>\n      </div>\n    </div>\n  </div>\n  <div class=\"row\">\n    <div class=\"col-sm-12\">\n      <div class=\"form-group\">\n        <label class=\"control-label col-sm-3\">Middle Initial</label>\n        <div class=\"col-sm-9\">\n          <input type=\"text\" name=\"middleInitial\" class=\"form-control\" placeholder=\"Middle Initial\"\n            data-parsley-maxlength=\"10\" />\n        </div>\n      </div>\n    </div>\n  </div>\n  <div class=\"row\">\n    <div class=\"col-sm-12\">\n      <div class=\"form-group required\">\n        <label class=\"control-label col-sm-3\">Last Name</label>\n        <div class=\"col-sm-9\">\n          <input type=\"text\" name=\"lastName\" class=\"form-control\" placeholder=\"Last Name\" data-parsley-maxlength=\"100\"\n            data-parsley-required=\"true\" />\n        </div>\n      </div>\n    </div>\n  </div>\n  <div class=\"row\">\n    <div class=\"col-sm-12\">\n      <div class=\"form-group\">\n        <label class=\"control-label col-sm-3\">Title</label>\n        <div class=\"col-sm-9\">\n          <input type=\"text\" name=\"title\" class=\"form-control\" placeholder=\"Title\" data-parsley-maxlength=\"50\" />\n        </div>\n      </div>\n    </div>\n  </div>\n  <div class=\"row\">\n    <div class=\"col-sm-12\">\n      <div class=\"form-group\">\n        <label class=\"control-label col-sm-3\">Phone Number</label>\n        <div class=\"col-sm-9\">\n          <input type=\"text\" name=\"phoneNumber\" class=\"form-control "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.isTrust : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\"\n            placeholder=\"Phone Number\" data-parsley-maxlength=\"50\" />\n        </div>\n      </div>\n    </div>\n  </div>\n  <div class=\"form-group\">\n    <div class=\"col-sm-9 col-sm-offset-3\">\n      <button class=\"btn btn-primary copy-address\" type=\"button\">\n        Copy from PPOB Address\n      </button>\n    </div>\n  </div>\n  <div class=\"address-form-container\"></div>\n  <div class=\"row\">\n    <div class=\"col-sm-12\">\n      <div class=\"form-group\">\n        <label class=\"control-label col-sm-3\">Additional Numbers</label>\n        <div class=\"col-sm-9\">\n          <button class=\"btn btn-default add-addition-number m-b-md\">Add Additional Number</button>\n          <div class=\"additional-number-form-container\"></div>\n        </div>\n      </div>\n    </div>\n  </div>\n  <div class=\"row\">\n    <div class=\"col-sm-12\">\n      <div class=\"form-group\">\n        <label class=\"control-label col-sm-3\">Email Addresses</label>\n        <div class=\"col-sm-9\">\n          <button class=\"btn btn-default m-b-md add-email-address\">Add Email Address</button>\n          <div class=\"email-form-container\"></div>\n        </div>\n      </div>\n    </div>\n  </div>\n</div>";
},"useData":true});