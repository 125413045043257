var Handlebars = require("handlebars/dist/handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "    <a role=\"button\" class=\"btn btn-primary\" href=\"#nondep/time-tracking/my-list\" target=\"_blank\">Open\n      Past Timesheets</a>\n";
},"3":function(container,depth0,helpers,partials,data) {
    return "    <div class=\"dropdown-container\"></div>\n";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.lambda, alias3=container.escapeExpression;

  return "<div class=\"header-bar\"></div>\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.showReopenButton : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.showReopenButton : depth0),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "<div class=\"clearfix\">\n  <h3 class=\"pull-left m-t-sm\">Time Entries</h3>\n  <button class=\"btn btn-readonly add pull-right btn-primary add-entry m-t-sm\">Add Entry</button>\n</div>\n<div class=\"time-entries-section\">\n  <div class=\"labels row\">\n    <div class=\"col-time-category\">\n      <label class=\"control-label\">Category</label>\n    </div>\n    <div class=\"col-time-exam\">\n      <label class=\"control-label\">Exam</label>\n    </div>\n    <div class=\"col-time-hrs\">\n      <div class=\"row\">\n        <div class=\"col-hr-label\">\n          <div class=\"form-group\">\n            <label class=\"control-label\">Mon.</label>\n            <p>"
    + alias3(alias2(((stack1 = (depth0 != null ? depth0.payPeriod : depth0)) != null ? stack1.startDayPlus1Display : stack1), depth0))
    + "</p>\n          </div>\n        </div>\n        <div class=\"col-hr-label\">\n          <div class=\"form-group\">\n            <label class=\"control-label\">Tue.</label>\n            <p>"
    + alias3(alias2(((stack1 = (depth0 != null ? depth0.payPeriod : depth0)) != null ? stack1.startDayPlus2Display : stack1), depth0))
    + "</p>\n          </div>\n        </div>\n        <div class=\"col-hr-label\">\n          <div class=\"form-group\">\n            <label class=\"control-label\">Wed.</label>\n            <p>"
    + alias3(alias2(((stack1 = (depth0 != null ? depth0.payPeriod : depth0)) != null ? stack1.startDayPlus3Display : stack1), depth0))
    + "</p>\n          </div>\n        </div>\n        <div class=\"col-hr-label\">\n          <div class=\"form-group\">\n            <label class=\"control-label\">Thurs.</label>\n            <p>"
    + alias3(alias2(((stack1 = (depth0 != null ? depth0.payPeriod : depth0)) != null ? stack1.startDayPlus4Display : stack1), depth0))
    + "</p>\n          </div>\n        </div>\n        <div class=\"col-hr-label\">\n          <div class=\"form-group\">\n            <label class=\"control-label\">Fri.</label>\n            <p>"
    + alias3(alias2(((stack1 = (depth0 != null ? depth0.payPeriod : depth0)) != null ? stack1.startDayPlus5Display : stack1), depth0))
    + "</p>\n          </div>\n        </div>\n        <div class=\"col-hr-label\">\n          <div class=\"form-group\">\n            <label class=\"control-label\">Mon.</label>\n            <p>"
    + alias3(alias2(((stack1 = (depth0 != null ? depth0.payPeriod : depth0)) != null ? stack1.startDayPlus8Display : stack1), depth0))
    + "</p>\n          </div>\n        </div>\n        <div class=\"col-hr-label\">\n          <div class=\"form-group\">\n            <label class=\"control-label\">Tue.</label>\n            <p>"
    + alias3(alias2(((stack1 = (depth0 != null ? depth0.payPeriod : depth0)) != null ? stack1.startDayPlus9Display : stack1), depth0))
    + "</p>\n          </div>\n        </div>\n        <div class=\"col-hr-label\">\n          <div class=\"form-group\">\n            <label class=\"control-label\">Wed.</label>\n            <p>"
    + alias3(alias2(((stack1 = (depth0 != null ? depth0.payPeriod : depth0)) != null ? stack1.startDayPlus10Display : stack1), depth0))
    + "</p>\n          </div>\n        </div>\n        <div class=\"col-hr-label\">\n          <div class=\"form-group\">\n            <label class=\"control-label\">Thurs.</label>\n            <p>"
    + alias3(alias2(((stack1 = (depth0 != null ? depth0.payPeriod : depth0)) != null ? stack1.startDayPlus11Display : stack1), depth0))
    + "</p>\n          </div>\n        </div>\n        <div class=\"col-hr-label\">\n          <div class=\"form-group\">\n            <label class=\"control-label\">Fri.</label>\n            <p>"
    + alias3(alias2(((stack1 = (depth0 != null ? depth0.payPeriod : depth0)) != null ? stack1.startDayPlus12Display : stack1), depth0))
    + "</p>\n          </div>\n        </div>\n      </div>\n    </div>\n  </div>\n  <form class=\"time-entries-container\"></form>\n  <div class=\"time-totals-container\"></div>\n</div>\n<div class=\"row m-t-lg m-b-lg\">\n  <div class=\"col-expenses-section\">\n    <div class=\"clearfix\">\n      <h3 class=\"pull-left\">Expenses</h3>\n      <button class=\"btn btn-readonly add pull-right btn-primary add-expense\">Add Expense</button>\n    </div>\n    <div class=\"expenses-section\">\n      <div class=\"labels row\">\n        <div class=\"col-exp-exam\">\n          <label class=\"control-label\">Exam</label>\n        </div>\n        <div class=\"col-expenses\">\n          <label class=\"control-label\">Expenses</label>\n        </div>\n      </div>\n      <form class=\"expenses-container\"></form>\n    </div>\n  </div>\n  <div class=\"col-comments-section\">\n    <h3>Comments</h3>\n    <textarea class=\"form-control align-comments\" name=\"comments\" rows=\"7\" placeholder=\"Comments\"></textarea>\n    <div class=\"pull-right m-t-lg m-b-lg\">\n      <button class=\"btn btn-readonly btn-default save ladda-button\" data-style=\"expand-right\" type=\"button\">\n        <span class=\"ladda-label\"><i class=\"fa fa-save\"></i> Save</span>\n      </button>\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.reviewer : depth0),{"name":"if","hash":{},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.examiner : depth0),{"name":"if","hash":{},"fn":container.program(12, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "    </div>\n  </div>\n</div>\n";
},"6":function(container,depth0,helpers,partials,data) {
    return "    <a class=\"btn btn-primary reopen ladda-button m-b-sm\" data-style=\"expand-right\">\n      <span class=\"ladda-label\">Reopen Timesheet</span>\n    </a>\n";
},"8":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression;

  return "<div class=\"alert alert-info\" role=\"alert\">\n  <p class=\"title\">\n    <i class=\"fa fa-check\"></i>\n    <span>This timesheet was submitted by "
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.timesheet : depth0)) != null ? stack1.submittedByFirstName : stack1), depth0))
    + " "
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.timesheet : depth0)) != null ? stack1.submittedByLastName : stack1), depth0))
    + "\n      ("
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.timesheet : depth0)) != null ? stack1.submittedByUsername : stack1), depth0))
    + ") on "
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.timesheet : depth0)) != null ? stack1.submittedOn : stack1), depth0))
    + ".</span>\n  </p>\n</div>\n";
},"10":function(container,depth0,helpers,partials,data) {
    return "      <button class=\"btn btn-readonly btn-primary submit ladda-button\" data-style=\"expand-right\" type=\"button\">\n        <span class=\"ladda-label\">Mark as Reviewed</span>\n      </button>\n";
},"12":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "      <button class=\"btn btn-readonly btn-primary submit ladda-button\" data-style=\"expand-right\" type=\"button\">\n        <span class=\"ladda-label\">"
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.isResubmit : depth0),{"name":"if","hash":{},"fn":container.program(13, data, 0),"inverse":container.program(15, data, 0),"data":data})) != null ? stack1 : "")
    + "</span>\n      </button>\n";
},"13":function(container,depth0,helpers,partials,data) {
    return "Resubmit";
},"15":function(container,depth0,helpers,partials,data) {
    return "Submit";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "\n<div class=\"page-title clearfix\">\n  <h1 class=\"pull-left m-n p-n\">Time Tracking</h1>\n  <div class=\"page-title-buttons-container\">\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.examiner : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.reviewer : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "  </div>\n</div>\n<div class=\"alert alert-info invalid-timesheet-container collapse\" role=\"alert\">\n  <p class=\"title\">\n    <span>User has no timesheet available. Please contact your administrator.</span>\n  </p>\n</div>\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.validTimesheet : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"useData":true});