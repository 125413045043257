/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var moment = require('moment');

var cleanUpResponse = require('common/util/cleanUpResponse');

module.exports = function(id) {
  var deferred = $.Deferred();

  $.soap({
    body: {
      GetIndividualLicense: {
        $xmlns: 'http://dobs.pa.gov/sc/individuallicense/bpm/1.0',
        ID: id
      }
    },
    blueprint: {
      id: '$..ID',
      CSFolderId: '$..CSFolderID',
      individualId: '$..IndividualID',
      organizationId: '$..OrganizationID',
      licenseTypeId: '$..LicenseTypeID',
      licenseStatusId: '$..LicenseStatusID',
      licenseStatusDate: '$..LicenseStatusDate',
      filingExaminerUserId: '$..FilingExaminerUserID',
      status: '$..Status',
      type: '$..Type',
      filingExaminerUserName: '$..FilingExaminerUserName',
      applicantLegalName: '$..ApplicantLegalName',
      dbaName: '$..DBAName',
      CRDNumber: '$..CRDNumber'
    }
  })
    .done(function(response) {
      cleanUpResponse(response);

      if (response.licenseStatusDate) {
        response.licenseStatusDate = moment(response.licenseStatusDate, 'YYYY-MM-DD').format('MM/DD/YYYY');
      }

      if (response.filingExaminerUserId) {
        response.filingExaminerDisplay = response.filingExaminerUserName + ' - (' + response.filingExaminerUserId + ')';
      } else {
        response.filingExaminerDisplay = '--';
      }

      deferred.resolve(response);
    })
    .fail(function(err) {
      deferred.reject();
    });
  return deferred.promise();
};
