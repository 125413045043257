/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var Syphon = require('backbone.syphon');
var Marionette = require('backbone.marionette');

var AddressDisplayView = require('common/views/AddressDisplayView');
var EmptyView = require('common/views/EmptyView');

var DepBankLocationActivitiesRelocationSubFormView = require('./DepBankLocationActivitiesRelocationSubFormView');
var DepBankLocationActivitiesDiscontinueSubFormView = require('./DepBankLocationActivitiesDiscontinueSubFormView');
var DepBankLocationActivitiesConvertSubFormView = require('./DepBankLocationActivitiesConvertSubFormView');

var tmplDepBankLocationActivitiesItemView = require('./DepBankLocationActivitiesItemView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplDepBankLocationActivitiesItemView,

  className: 'dep-bank-location-activities-item-view panel panel-default',

  ui: {
    actionTypeDropdown: '.action-type-dropdown',
    actionForm: '.action-form',
    currentLocationAddress: '.current-location-address'
  },

  regions: {
    actionForm: '@ui.actionForm',
    currentLocationAddress: ' @ui.currentLocationAddress'
  },

  events: {
    'change @ui.actionTypeDropdown': 'onChangeActionType'
  },

  templateHelpers: function() {
    return {
      childIndex: this.options.childIndex + 1,
      isReadOnly: this.options.isReadOnly,
      locationTypes: this.options.locationTypes,
      actionTypes: this.options.actionTypes
    };
  },

  onBeforeShow: function() {
    var action = this.model.get('locationAction') || {};
    var currLocationAddress = this.model.get('currentLocationAddress') || {};

    this.showChildView(
      'currentLocationAddress',
      new AddressDisplayView({ model: new Backbone.Model(currLocationAddress) })
    );

    Syphon.deserialize(this, action);
    this.onChangeActionType();
  },

  onChangeActionType: function() {
    var actionTypeId = this.ui.actionTypeDropdown.val();

    var relocation = actionTypeId === '1';
    var discontinue = actionTypeId === '2';
    var convert = actionTypeId === '3';

    var locationAddresses = this.model.collection.toJSON();
    _.each(locationAddresses, function(location) {
      location.currentLocationAddress.id = location.entityLocationId;
      location.currentLocationAddress.openedDate = location.openedDate;
    });

    if (relocation) {
      this.actionForm = new DepBankLocationActivitiesRelocationSubFormView({
        model: this.model,
        isReadOnly: this.options.isReadOnly,
        locationTypes: this.options.locationTypes,
        childIndex: this.options.childIndex,
        entityCategoryId: this.options.entityCategoryId
      });
    } else if (discontinue) {
      this.actionForm = new DepBankLocationActivitiesDiscontinueSubFormView({
        model: this.model,
        isReadOnly: this.options.isReadOnly,
        locationTypes: this.options.locationTypes,
        childIndex: this.options.childIndex,
        entityCategoryId: this.options.entityCategoryId,
        locations: this.model.collection
      });
    } else if (convert) {
      this.actionForm = new DepBankLocationActivitiesConvertSubFormView({
        model: this.model,
        isReadOnly: this.options.isReadOnly,
        locationTypes: this.options.locationTypes,
        childIndex: this.options.childIndex
      });
    } else {
      this.actionForm = new EmptyView();
    }
    this.showChildView('actionForm', this.actionForm);
  },

  validate: function(config) {
    var actionTypeId = this.ui.actionTypeDropdown.val();
    if (actionTypeId && _.isFunction(this.actionForm.validate)) {
      return this.actionForm.validate(config);
    }
  },

  getFormData: function() {
    var formData = Syphon.serialize(this);
    formData.entityLocationId = this.model.get('entityLocationId');

    if (formData.locationActionTypeId && _.isFunction(this.actionForm.getFormData)) {
      _.extend(formData, this.actionForm.getFormData());
    }

    return formData;
  }
});
