/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var moment = require('moment');

var cleanUpResponse = require('common/util/cleanUpResponse');

module.exports = function(examId) {
  var defer = $.Deferred();

  $.soap({
    body: {
      GetExamPaymentsByExamId: {
        $xmlns: 'http://dobs.pa.gov/nd/exam/bpm/1.0',
        ExamID: examId
      }
    },
    blueprintArrays: ['GetExamPaymentsByExamIdResponse.ND_ExamPayment'],
    blueprint: {
      examPayments: [
        '$..ND_ExamPayment',
        {
          id: '$.ID',
          processInstanceId: '$.ProcessInstanceID',
          examId: '$.ExamID',
          description: '$.Description',
          dueDate: '$.DueDate',
          amount: '$.AmountDue',
          isDeleted: '$.IsDeleted',
          statusId: '$.StatusID',
          paidOnDate: '$.PaidOnDate',
          paidBy: '$.PaidBy',
          modifiedBy: '$.ModifiedBy',
          modifiedDate: '$.ModifiedDate',
          createdBy: '$.CreatedBy',
          createdDate: '$.CreatedDate',
          paymentStatus: '$.PaymentStatus',
          feeAmount: '$.FeeAmount',
          numberOfFees: '$.NumberOfFeesApplied'
        }
      ],
      examAdminFee: '$..ExamAdminFee'
    }
  })
    .done(function(response) {
      cleanUpResponse(response, { cleanUpTextKeys: true });
      _.each(response.examPayments, function(payment) {
        payment.dueDate = moment(payment.dueDate, 'YYYY-MM-DD');

        if (payment.paidOnDate) {
          payment.paidOnDate = moment(payment.paidOnDate, 'YYYY-MM-DD');
        }
      });

      var paidStatuses = ['3', '4', '5'];

      response.totalAmountPaid = _.reduce(
        response.examPayments,
        function(totalPaid, payment) {
          if (_.contains(paidStatuses, payment.statusId)) {
            return totalPaid + +payment.amount;
          }
          return totalPaid;
        },
        0
      ).toFixed(2);

      defer.resolve(response);
    })
    .fail(function(err) {
      defer.reject(err);
    });

  return defer.promise();
};
