/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Marionette = require('backbone.marionette');
var Ladda = require('ladda');
var Radio = require('backbone.radio');
var _ = require('underscore');

var modelGet = require('common/util/modelGet');
var BreadcrumbView = require('common/views/BreadcrumbView');
var NonDepInternalIndividualFormView = require('./NonDepInternalIndividualFormView');

var SubmitIndividualGeneralInformation = require('../services/SubmitIndividualGeneralInformation');
var SubmitEntityOfficerGeneralInformation = require('../services/SubmitEntityOfficerGeneralInformation');

var tmplNonDepEditIndividualGeneralInformationView = require('../templates/NonDepEditIndividualGeneralInformationView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplNonDepEditIndividualGeneralInformationView,

  className: 'non-dep-individual-edit-general-info-view container-fluid',

  ui: {
    individualForm: '.individual-form-container',
    breadcrumb: '.breadcrumb-container',
    submit: '.btn-submit'
  },

  regions: {
    individualForm: '@ui.individualForm',
    breadcrumb: '@ui.breadcrumb'
  },

  events: {
    'click @ui.submit': 'onClickSubmit'
  },

  onBeforeShow: function() {
    var individual = this.model.get('individual'),
      individualType = individual.individualType,
      entityId = this.model.get('entityId'),
      entityName = this.model.get('entityName'),
      licenseId = this.model.get('licenseId'),
      licenseNumber = this.model.get('licenseNumber'),
      licenseType = this.model.get('licenseType'),
      breadcrumbs;

    if (individualType === 'Entity Officer') {
      breadcrumbs = [
        {
          link: '#nondep/dashboard',
          text: 'Home'
        },
        {
          text: entityName,
          link: '#nondep/entity/' + entityId + '/dashboard'
        },
        {
          text: individual.lastName
            ? individualType + ' - ' + individual.firstName + ' ' + individual.lastName
            : individualType + ' - ' + individual.firstName,
          link: '#nondep/individual/' + this.model.get('individualId') + '/dashboard'
        },
        {
          text: 'Edit General Information'
        }
      ];
    } else if (individualType === 'Office Manager') {
      breadcrumbs = [
        {
          link: '#nondep/dashboard',
          text: 'Home'
        },
        {
          text: entityName,
          link: '#nondep/entity/' + entityId + '/dashboard'
        },
        {
          text: 'License #' + licenseNumber + ' - ' + licenseType,
          link: '#nondep/entity/' + entityId + '/license/' + licenseId
        },
        {
          text: individualType + ' - ' + individual.firstName + ' ' + individual.lastName,
          link: '#nondep/individual/' + this.model.get('individualId') + '/dashboard'
        },
        {
          text: 'Edit General Information'
        }
      ];
    } else {
      breadcrumbs = [
        {
          link: '#nondep/dashboard',
          text: 'Home'
        },
        {
          text: individualType + ' - ' + individual.firstName + ' ' + individual.lastName,
          link: '#nondep/individual/' + this.model.get('individualId') + '/dashboard'
        },
        {
          text: 'Edit General Information'
        }
      ];
    }

    this.showChildView(
      'breadcrumb',
      new BreadcrumbView({
        breadcrumbs: breadcrumbs
      })
    );

    this.individualForm = new NonDepInternalIndividualFormView({
      individualType: modelGet(this.model, 'individual.individualType'),
      model: new Backbone.Model(
        _.extend(this.model.get('individual'), {
          entityName: this.model.get('entityName'),
          entityId: this.model.get('entityId'),
          individualId: this.model.get('individualId'),
          officerTypes: this.model.get('officerTypes'),
          jnetTypes: this.model.get('jnetTypes'),
          allEntityOfficers: this.model.get('allEntityOfficers')
        })
      )
    });

    this.showChildView('individualForm', this.individualForm);
  },

  onClickSubmit: function(e) {
    e.preventDefault();
    this.validate().done(
      function() {
        var laddaContext = Ladda.create(this.ui.submit.get(0));
        laddaContext.start();
        var formData = this.getFormData();
        var individualType = modelGet(this.model, 'individual.individualType');
        if (individualType === 'Entity Officer') {
          SubmitEntityOfficerGeneralInformation(formData)
            .done(
              function() {
                if (formData.isDeleted) {
                  Radio.channel('navigate').trigger('show:int:nd:dashboard');
                } else {
                  Radio.channel('navigate').trigger('show:individual-dashboard', this.model.get('individualId'));
                }
              }.bind(this)
            )
            .fail(function() {
              laddaContext.stop();
            });
        } else {
          SubmitIndividualGeneralInformation(formData)
            .done(
              function() {
                if (individualType === 'Office Manager') {
                  Radio.channel('navigate').trigger(
                    'show:int:nd:license-dashboard',
                    this.model.get('entityId'),
                    modelGet(this.model, 'individual.licenseId')
                  );
                } else {
                  Radio.channel('navigate').trigger('show:individual-dashboard', this.model.get('individualId'));
                }
              }.bind(this)
            )
            .fail(function() {
              laddaContext.stop();
            });
        }
      }.bind(this)
    );
  },

  validate: function() {
    return this.individualForm.validate();
  },

  getFormData: function() {
    return this.individualForm.getFormData();
  }
});
