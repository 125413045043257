var Handlebars = require("handlebars/dist/handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = (helpers.ifCond || (depth0 && depth0.ifCond) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.id : depth0),"!=","1",{"name":"ifCond","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"2":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "            <option value=\""
    + alias4(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\">"
    + alias4(((helper = (helper = helpers.status || (depth0 != null ? depth0.status : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"status","hash":{},"data":data}) : helper)))
    + "</option>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "\n\n<div class=\"form-horizontal\">\n  <form class=\"service-member-form\">\n    <div class=\"form-group required\">\n      <label class=\"control-label col-sm-3\">Are you a veteran or current service member?</label>\n      <div class=\"col-sm-9\">\n        <label class=\"radio-inline form-label\">\n          <input type=\"radio\" name=\"isVeteranServiceMember\" value=\"1\" data-parsley-required=\"true\"\n          data-parsley-errors-container=\"is-veteran-error-container\"/>\n          Yes\n        </label>\n        <label class=\"radio-inline form-label\">\n          <input type=\"radio\" name=\"isVeteranServiceMember\" value=\"0\" data-parsley-required=\"true\"\n          data-parsley-errors-container=\"is-veteran-error-container\"/>\n          No\n        </label>\n        <div id=\"is-veteran-error-container\"></div>\n      </div>\n    </div>\n    <div class=\"form-group required service-description\">\n      <label class=\"control-label col-sm-3\">Which best describes your status?</label>\n        <div class=\"col-sm-7 col-md-9\">\n          <select class=\"form-control us-states-select\" name=\"serviceMemberStatus\" data-parsley-required=\"true\">\n"
    + ((stack1 = helpers.each.call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.serviceMemberStatuses : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "          </select>\n        </div>\n    </div>\n  </form>\n</div>";
},"useData":true});