/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var Marionette = require('backbone.marionette');

var modelGet = require('common/util/modelGet');

var tmplDepExamRatingSectionView = require('../templates/DepExamRatingSectionView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplDepExamRatingSectionView,

  className: 'dep-exam-rating-section-view',

  ui: {
    noRatings: '.rating-empty-container',
    ssRating: '.ss-rating-container',
    itRating: '.it-rating-container',
    trustRating: '.trust-rating-container',
    trustCompanyItRating: '.trust-company-it-rating-container',
    complianceRating: '.compliance-rating-container',
    craRating: '.cra-rating-container',
    bhcRating: '.bhc-rating-container',
    ursitRating: '.ursit-rating-container',
    trustCompanyBSARating: '.trust-company-bsa-rating-container'
  },

  onBeforeShow: function() {
    var examAreaIds = _.pluck(modelGet(this.model, 'examAreas', []), 'id');
    var isHCExam = this.model.get('isHoldingCompanyExam') === '1';
    var entityTypeId = this.options.entityTypeId;
    var isTSP = entityTypeId === '3' || entityTypeId === '12' || entityTypeId === '23';
    var isTrustCompany = this.options.entityCharterTypeId === '21';

    if (isTSP) {
      this.ui.ursitRating.show();
    } else {
      if (examAreaIds.length > 0 || isHCExam || entityTypeId === '3') {
        var ssAreaIds = ['1', '11'];
        var hasSSExamArea = _.intersection(ssAreaIds, examAreaIds).length > 0;
        if (hasSSExamArea) {
          this.ui.ssRating.show();
        }

        var itAreaIds = ['3', '12', '22'];
        var hasITExamArea = _.intersection(itAreaIds, examAreaIds).length > 0;
        if (hasITExamArea) {
          this.ui.itRating.show();
        }

        var trustAreaIds = ['2', '21'];
        var hasTrustExamArea = _.intersection(trustAreaIds, examAreaIds).length > 0;
        if (hasTrustExamArea) {
          this.ui.trustRating.show();
        }

        var complianceAreaIds = ['4', '13'];
        var hasComplianceExamArea = _.intersection(complianceAreaIds, examAreaIds).length > 0;
        if (hasComplianceExamArea) {
          this.ui.complianceRating.show();
        }

        var craAreaIds = ['5'];
        var hasCRAExamArea = _.intersection(craAreaIds, examAreaIds).length > 0;
        if (hasCRAExamArea) {
          this.ui.craRating.show();
        }

        if (isHCExam) {
          this.ui.bhcRating.show();
        }
      } else {
        this.ui.noRatings.show();
      }
    }

    if (isTrustCompany) {
      this.ui.trustCompanyItRating.show();
      this.ui.itRating.hide();
      this.ui.trustCompanyBSARating.show();
    }
  }
});
