/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Backbone = require('backbone');

var UIUtil = require('psa-core/util/ui');
var GridView = require('psa-marionette/views/GridView');
var Ladda = require('ladda');

var ConfirmationModalView = require('common/views/ConfirmationModalView');
var MomentCell = require('common/backgrid/MomentCell');
var ParagraphCell = require('common/backgrid/ParagraphCell');
var MultiButtonCell = require('common/backgrid/MultiButtonCell');

var SubmitProvision = require('../services/SubmitProvision');

module.exports = GridView.extend({
  className: 'dep-enforcement-provisions-section-view',

  onBeforeRender: function() {
    if (GridView.prototype.onBeforeRender) {
      GridView.prototype.onBeforeRender.apply(this.arguments);
    }

    this.options.columns = [
      {
        name: 'provisionNumber',
        label: 'Provision Number',
        cell: 'string',
        headerCell: 'custom',
        width: 15,
        editable: false
      },
      {
        name: 'categoryName',
        label: 'Category',
        cell: 'string',
        headerCell: 'custom',
        width: 12,
        editable: false
      },
      {
        name: 'responseDueDate',
        label: 'Response Due Date',
        cell: MomentCell.MomentCell,
        formatter: MomentCell.MomentDateFormatter.extend({
          emptyValue: '--'
        }),
        sortValue: MomentCell.MomentSortValue,
        headerCell: 'custom',
        width: 12,
        editable: false
      },
      {
        name: 'isOngoingDisplay',
        label: 'Ongoing',
        cell: 'string',
        headerCell: 'custom',
        width: 12,
        editable: false
      },
      {
        name: 'status',
        label: 'Status',
        cell: 'string',
        headerCell: 'custom',
        width: 12,
        editable: false
      },
      {
        name: 'provisions',
        label: 'Provisions',
        cell: ParagraphCell,
        headerCell: 'custom',
        width: 13,
        editable: false,
        sortable: false
      },
      {
        name: 'comments',
        label: 'Comments',
        cell: ParagraphCell,
        headerCell: 'custom',
        width: 14,
        editable: false,
        sortable: false
      },
      {
        name: '',
        label: '',
        width: 10,
        cell: MultiButtonCell.extend({
          buttons: [
            {
              name: '',
              labelClass: 'fa fa-edit',
              buttonClass: 'btn-primary btn-sm',
              href: function(model) {
                var entityId = model.get('entityId');
                var enforcementActionId = model.get('enforcementActionId');
                var provisionId = model.get('id');
                return (
                  '#dep/entity/' +
                  entityId +
                  '/enforcement/' +
                  enforcementActionId +
                  '/provision/' +
                  provisionId +
                  '/edit'
                );
              }
            },
            {
              name: '',
              label: '',
              labelClass: 'fa fa-copy',
              buttonClass: 'btn-primary btn-sm',
              onClick: this.onClickDuplicateProvision.bind(this)
            },
            {
              name: '',
              labelClass: 'fa fa-trash',
              buttonClass: 'btn-danger btn-sm delete-provision',
              onClick: this.onClickDeleteProvision.bind(this)
            }
          ]
        }),
        sortable: false,
        editable: false
      }
    ];
    this.options.emptyText = 'No Provisions';
  },

  onClickDeleteProvision: function(e, model) {
    e.preventDefault();
    var message = 'Are you sure you want to delete this provision?';
    var self = this;
    UIUtil.showModal({
      title: 'Confirm Deleting Provision',
      view: new ConfirmationModalView({
        confirmText: 'Delete Provision',
        model: new Backbone.Model({
          message: message,
          onConfirm: function() {
            var provisionId = model.get('id');
            return SubmitProvision({ provisionId: provisionId, isDeleted: '1' });
          },
          onConfirmDone: function() {
            self.collection.remove(model);
          }
        })
      })
    });
  },

  onClickDuplicateProvision: function(e, model) {
    var provision = model.toJSON();
    var self = this;
    provision.provisionId = null;
    provision.enforcementId = provision.enforcementActionId;
    if (provision.comments === '--') {
      provision.comments = null;
    }

    var laddaContext = Ladda.create(e.currentTarget);
    laddaContext.start();
    SubmitProvision(provision)
      .done(function(newProvId) {
        provision.id = newProvId;
        if (!provision.comments) {
          provision.comments = '--';
        }
        self.collection.add(provision);
        laddaContext.stop();
      })
      .fail(function() {
        laddaContext.stop();
      });
  }
});
