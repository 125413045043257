/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var cleanUpResponse = require('common/util/cleanUpResponse');

module.exports = function(entityId) {
  var defer = $.Deferred();
  $.soap({
    body: {
      GetLicenseContactsAndOfficersByEntity: {
        $xmlns: 'http://dobs.pa.gov/nd/entity/bpm/1.0',
        EntityID: entityId
      }
    },
    blueprintArrays: ['GetLicenseContactsAndOfficersByEntityResponse.ND_LicenseContact'],
    blueprint: {
      contacts: [
        '$..ND_LicenseContact',
        {
          firstName: '$.FirstName',
          lastName: '$.LastName',
          title: '$.Title',
          homePhoneNumber: '$.HomePhoneNumber',
          cellPhoneNumber: '$.CellPhoneNumber',
          email: '$.Email',
          addressLine1: '$.AddressLine1',
          addressLine2: '$.AddressLine2',
          city: '$.City',
          postalCode: '$.PostalCode',
          state: '$.State',
          country: '$.Country',
          county: '$.County',
          company: '$.Company',
          workPhoneNumber: '$.WorkPhoneNumber',
          workPhoneExtension: '$.WorkPhoneExtension',
          fax: '$.Fax'
        }
      ]
    }
  })
    .done(function(response) {
      cleanUpResponse(response);
      defer.resolve(response.contacts);
    })
    .fail(function(err) {
      defer.reject();
    });

  return defer.promise();
};
