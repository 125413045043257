/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Marionette = require('backbone.marionette');
var Radio = require('backbone.radio');

var PaymentInvoiceView = require('common/views/PaymentInvoiceView');

var StartFilingReviewProcess = require('../services/StartFilingReviewProcess');
var SubmitEntityUpdateFilingTask = require('../../tasks/services/SubmitEntityUpdateFilingTask');

var tmplDepFilingPaymentView = require('../templates/DepFilingPaymentView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplDepFilingPaymentView,

  className: 'dep-filing-payment-view',

  ui: {
    paymentInvoice: '.payment-invoice'
  },

  regions: {
    paymentInvoice: '@ui.paymentInvoice'
  },

  onBeforeShow: function() {
    var filingId = this.model.get('filingId');
    var taskId = this.model.get('taskId');
    // Possible values of viewMode: 'new', resubmit'
    var viewMode = this.options.viewMode || 'new';
    var isNewFiling = viewMode === 'new';
    this.showChildView(
      'paymentInvoice',
      new PaymentInvoiceView({
        submitButtonTextZeroAmount: 'Submit Filing',
        model: new Backbone.Model({
          department: 'DEPOSITORY',
          paymentObjectType: 'FILING',
          paymentObjectId: filingId,
          items: this.model.get('invoiceItems'),
          taskId: this.model.get('taskId')
        }),
        handleZeroAmount: function() {
          var data = {
            filingId: filingId,
            taskId: taskId
          };
          if (isNewFiling) {
            return StartFilingReviewProcess(data).done(function() {
              Radio.channel('navigate').trigger('show:ext:dp:dashboard');
            });
          } else {
            return SubmitEntityUpdateFilingTask(data).done(function() {
              Radio.channel('navigate').trigger('show:ext:dp:dashboard');
            });
          }
        }
      })
    );
  }
});
