/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var _get = require('lodash.get');
var moment = require('moment');

var cleanUpResponse = require('common/util/cleanUpResponse');
var getUserIdFromDn = require('common/util/getUserIdFromDn');

module.exports = function() {
  var deferred = $.Deferred();
  $.soap({
    body: {
      GetAvailableTasks: {
        $xmlns: 'http://dobs.pa.gov/cs/task/bpm/1.0'
      }
    },
    blueprintArrays: [
      'GetAvailableTasksResponse.Tasks.NOTF_TASK_INSTANCE',
      'GetAvailableTasksResponse.Correspondences.CS_Correspondence',
      'GetAvailableTasksResponse.TaskTypes.CM_LU_TaskType'
    ],
    blueprint: {
      tasks: [
        '$..NOTF_TASK_INSTANCE',
        {
          taskId: '$..TaskId',
          deliveryDate: '$..DeliveryDate',
          correspondenceId: '$..CSCorrespondenceID',
          taskTypeName: '$..TaskTypeName',
          complaintId: '$..CSComplaintID',
          assignedRole: '$..Target'
        }
      ],
      taskMetaData: {
        taskTypes: [
          '$..CM_LU_TaskType',
          {
            id: '$.ID',
            name: '$.Name',
            description: '$.Description'
          }
        ],
        correspondences: [
          '$..CS_Correspondence',
          {
            id: '$..CorrespondenceID',
            status: '$..CorrespondenceStatus',
            entityId: '$..EntityID',
            departmentId: '$..DepartmentID',
            entityName: '$..EntityName',
            caseManager: '$..CaseManager',
            ComplainantFirstName: '$..ComplainantFirstName',
            ComplainantLastName: '$..ComplainantLastName'
          }
        ]
      }
    }
  })
    .done(function(response) {
      cleanUpResponse(response);

      _.each(response.tasks, function(task) {
        task.deliveryDate = moment.utc(task.deliveryDate, moment.ISO_8601).local();

        var taskTypeName = _get(task, 'taskTypeName', {});
        var correspondenceId = _get(task, 'correspondenceId', {});

        if (taskTypeName.__text) {
          taskTypeName = taskTypeName.__text;
        }

        if (correspondenceId.__text) {
          correspondenceId = correspondenceId.__text;
        }

        if (task.assignedRole && task.assignedRole.__text) {
          task.assignedRole = getUserIdFromDn(task.assignedRole.__text);
        } else {
          task.assignedRole = getUserIdFromDn(task.assignedRole);
        }

        var type = _.find(response.taskMetaData.taskTypes, function(type) {
          return taskTypeName === type.name;
        });

        task.type = type.description;

        if (correspondenceId) {
          var correspondence = _.find(response.taskMetaData.correspondences, function(correspondence) {
            return correspondenceId === correspondence.id;
          });

          if (correspondence) {
            correspondence.ComplainantFirstName = correspondence.ComplainantFirstName || '';
            correspondence.ComplainantLastName = correspondence.ComplainantLastName || '';
            if (correspondence.ComplainantFirstName || correspondence.ComplainantLastName) {
              var complainantName = correspondence.ComplainantFirstName + ' ' + correspondence.ComplainantLastName;
            } else {
              complainantName = '--';
            }

            task.complainantName = complainantName;
            task.caseManager = correspondence.caseManager;
            task.entityName = correspondence.entityName;
            task.departmentId = correspondence.departmentId;
            task.entityId = correspondence.entityId;
          }
        }
      });
      deferred.resolve(response.tasks);
    })
    .fail(function(err) {
      deferred.reject();
    });

  return deferred.promise();
};
