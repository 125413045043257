/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var Marionette = require('backbone.marionette');
var Radio = require('backbone.radio');
var Ladda = require('ladda');

var InvoiceGridView = require('../InvoiceGridView');

require('./PaymentInvoiceView.css');
var tmplPaymentInvoiceView = require('./PaymentInvoiceView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplPaymentInvoiceView,

  className: 'payment-invoice-view',

  options: {
    emptyText: 'No Payment Items Found',
    submitButtonTextZeroAmount: 'Submit',
    isReadOnly: false
  },

  ui: {
    paymentGrid: '.payment-grid',
    submitButton: '.btn-submit',
    paymentForm: '.payment-form'
  },

  regions: {
    paymentGrid: '@ui.paymentGrid',
    paymentForm: '@ui.paymentForm'
  },

  events: {
    'click @ui.submitButton': 'onClickSubmit'
  },

  onBeforeShow: function() {
    this.paymentGrid = new InvoiceGridView({
      emptyText: this.options.emptyText,
      collection: new Backbone.Collection(this.model.get('items'))
    });
    this.showChildView('paymentGrid', this.paymentGrid);

    if (this.paymentGrid.getTotal() <= 0) {
      this.ui.submitButton.text(this.options.submitButtonTextZeroAmount);
    }

    if (this.options.isReadOnly) {
      this.ui.submitButton.hide();
    }
  },

  onClickSubmit: function(e) {
    e.preventDefault();
    var self = this;

    var l = Ladda.create(this.ui.submitButton[0]);
    l.start();

    var onBeforeSubmit = this.options.onBeforeSubmit;
    // Set initial value of promise to be already resolve promise. If an
    // onBeforeSubmit function is provided, and it returns a promise, overwrite
    // the default promise with the result.
    var onBeforeSubmitPromise = $.when();
    if (onBeforeSubmit && _.isFunction(onBeforeSubmit)) {
      var result = onBeforeSubmit();
      // Check if the result of onBeforeSubmit is a promise.
      if (result && _.isFunction(result.then)) {
        onBeforeSubmitPromise = result;
      }
    }

    onBeforeSubmitPromise
      .done(function() {
        /**
         * Whenever an application is submitted, if the amount owed is $0.00, then
         * call the handleZeroAmount handler that is passed in. Otherwise, take the
         * user to Payeezy to collect payment.
         */
        if (self.paymentGrid.getTotal() <= 0) {
          var handleZeroAmount = self.options.handleZeroAmount;
          if (handleZeroAmount && _.isFunction(handleZeroAmount)) {
            var resultPromise = handleZeroAmount();
            if (resultPromise.fail && _.isFunction(resultPromise.fail)) {
              resultPromise.fail(function(err) {
                l.stop();
              });
            }
          }
        } else {
          var deptChannelMap = {
            'NON-DEPOSITORY': 'nd',
            DEPOSITORY: 'dp',
            CORPORATE_FINANCE: 'cf'
          };
          var department = self.model.get('department');
          var deptChannel = deptChannelMap[department] || 'nd';
          Radio.channel(deptChannel)
            .request('build:payeezy-view', {
              paymentAmount: self.paymentGrid.getTotalFormatted(),
              paymentObjectType: self.model.get('paymentObjectType'),
              paymentObjectId: self.model.get('paymentObjectId'),
              items: self.model.get('items'),
              taskId: self.model.get('taskId')
            })
            .done(function(payeezyView) {
              self.showChildView('paymentForm', payeezyView);
            })
            .fail(function(err) {
              l.stop();
            });
        }
      })
      .fail(function() {
        l.stop();
      });
  }
});
