/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');

var cleanUpResponse = require('common/util/cleanUpResponse');

module.exports = function() {
  var deferred = $.Deferred();

  $.soap({
    body: {
      GetAllCorrespondences: {
        $xmlns: 'http://dobs.pa.gov/cs/correspondence/bpm/1.0'
      }
    },
    blueprintArrays: ['GetAllCorrespondencesResponse.Correspondences.CS_Correspondence'],
    blueprint: {
      correspondences: [
        '$..CS_Correspondence',
        {
          correspondenceId: '$..ID',
          complaintId: '$..ComplaintID',
          status: '$..Status',
          statusId: '$..StatusID',
          requestComments: '$..RequestComments',
          requestedBy: '$..RequestedBy',
          requestedByName: '$..RequestedByName',
          requestedOn: '$..RequestedOn',
          replyComments: '$..ReplyComments',
          repliedBy: '$..RepliedBy',
          repliedByName: '$..RepliedByName',
          repliedByEmail: '$..RepliedByEmail',
          repliedOn: '$..RepliedOn',
          entityName: '$..EntityName',
          caseManagerName: '$..CaseManagerName',
          complainantName: '$..ComplainantName',
          caseManager: '$..CaseManager',
          caseNumber: '$..CaseNumber'
        }
      ]
    }
  })
    .done(function(response) {
      cleanUpResponse(response);

      _.each(response.correspondences, function(correspondence) {
        correspondence.caseManagerName = correspondence.caseManagerName || '--';
        correspondence.complainantName = correspondence.complainantName || '--';
      });

      deferred.resolve(response.correspondences);
    })
    .fail(function(err) {
      deferred.reject();
    });

  return deferred.promise();
};
