/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var _get = require('lodash.get');
var moment = require('moment');

var cleanUpResponse = require('common/util/cleanUpResponse');
var momentUtil = require('common/util/momentUtil');

module.exports = function(applicationId) {
  var defer = $.Deferred();

  $.soap({
    body: {
      GetApplicationDetails: {
        $xmlns: 'http://dobs.pa.gov/nd/application/bpm/1.0',
        ApplicationID: applicationId
      }
    },
    blueprintArrays: [
      'GetApplicationDetailsResponse.ND_Application.Transactions.ND_PayeezyTransaction',
      'GetApplicationDetailsResponse.ND_Application.BranchLicenses.ND_License'
    ],
    blueprint: {
      applicationId: '$..ND_Application.ID',
      applicationTypeId: '$..ApplicationTypeID',
      applicationType: '$..ApplicationType',
      applicationTypeDescription: '$..ApplicationTypeDescription',
      applicationStatusId: '$..ApplicationStatusID',
      applicationLicenseStatusId: '$..ApplicationLicenseStatusID',
      applicationStatus: '$..ExternalApplicationStatus',
      withdrawalReason: '$..WithdrawalReason',
      parentLicenseId: '$..ParentLicenseID',
      parentApplicationId: '$..ParentApplicationID',
      parentApplicationType: '$..ParentApplicationType',
      parentApplicationAddressLine1: '$..ParentApplicationAddressLine1',
      CSFolderId: '$..CSFolderID',
      identoGoLink: '$..IdentoGoLink',
      submittedBy: '$..SubmittedBy',
      submissionDate: '$..SubmissionDate',
      externalReviewComments: '$..ExternalReviewComments',
      applicationReturnedDecisionReason: '$..ApplicationReturnedDecisionReason.__text',
      applicationLicenseStatus: '$..ApplicationLicenseStatus',
      reportingStatusId: '$..ReportingStatusID',
      reportingStatus: '$..ReportingStatus',
      formIdentifier: '$..FormIdentifier',
      applicationCategoryId: '$..ApplicationCategoryID',
      entityId: '$..EntityID',
      entityName: '$..EntityName',
      entityIsVerified: '$..IsVerified',
      tin: '$..TIN',
      entityNMLSId: '$..AppNMLSEntityID',
      appNMLSId: '$..AppNMLSID',
      webAddress: '$..WebAddress',
      applicationFee: '$..ApplicationFee',
      renewalFee: '$..RenewalFee',
      licenseType: '$..LicenseType',
      nmlsLicenseType: '$..AppNMLSLicenseType',
      renewalPeriodSpansYears: '$..RenewalPeriodSpansYears',
      branchApplicationFee: '$..BranchApplicationFee',
      branchRenewalFee: '$..BranchRenewalFee',
      completedDate: '$..CompletedDate',
      isDroppedOut: '$..IsDroppedOut',
      location: {
        locationId: '$..Location.ID',
        isPrimaryPlaceOfBusiness: '$..Location.IsPrimaryPlaceOfBusiness',
        officePhoneNumber: '$..Location.OfficePhoneNumber',
        officeFaxNumber: '$..Location.OfficeFaxNumber',
        addressID: '$..Location.AddressID',
        entityID: '$..Location.EntityID',
        addressLine1: '$..Location.AddressLine1',
        addressLine2: '$..Location.AddressLine2',
        addressLine3: '$..Location.AddressLine3',
        city: '$..Location.City',
        state: '$..Location.State',
        postalCode: '$..Location.PostalCode',
        country: '$..Location.Country'
      },
      license: {
        id: '$..License.ID',
        nmlsid: '$..License.NMLSID',
        licenseNumber: '$..License.LicenseNumber',
        locationId: '$..License.LocationID',
        officeManagerIndividualId: '$..License.OfficeManagerIndividualID',
        licenseStatusId: '$..License.LicenseStatusID',
        licenseStatus: '$..LicenseStatus',
        licenseTypeId: '$..License.LicenseTypeID',
        parentLicenseId: '$..License.ParentLicenseID',
        originalIssueDate: '$..License.OriginalIssueDate',
        effectiveStartDate: '$..License.EffectiveStartDate',
        effectiveEndDate: '$..License.EffectiveEndDate',
        certificateDocumentId: '$..License.CertificateDocumentID',
        reinstatementPeriodStart: '$..License.ReinstatementPeriodStart',
        reinstatementPeriodEnd: '$..License.ReinstatementPeriodEnd',
        renewalInstructionsDocumentId: '$..License.RenewalInstructionsDocumentID',
        renewalPeriodStart: '$..License.RenewalPeriodStart',
        renewalPeriodEnd: '$..License.RenewalPeriodEnd',
        hasBranchLicenses: '$..License.HasBranchLicenses',
        licenseType: '$..License.LicenseType'
      },
      branchLicenses: [
        '$..BranchLicenses.ND_License',
        {
          id: '$.ID',
          licenseNumber: '$.LicenseNumber',
          locationId: '$.LocationID',
          officeManagerIndividualId: '$.OfficeManagerIndividualID',
          licenseStatusId: '$.LicenseStatusID',
          licenseTypeId: '$.LicenseTypeID',
          parentLicenseId: '$.ParentLicenseID',
          originalIssueDate: '$.OriginalIssueDate',
          effectiveStartDate: '$.EffectiveStartDate',
          effectiveEndDate: '$.EffectiveEndDate',
          certificateDocumentId: '$.CertificateDocumentID',
          licenseStatus: '$.LicenseStatus',
          officeManagerFirstName: '$.OfficeManagerFirstName',
          officeManagerLastName: '$.OfficeManagerLastName',
          addressLine1: '$.AddressLine1',
          addressLine2: '$.AddressLine2',
          addressLine3: '$.AddressLine3',
          city: '$.City',
          country: '$.Country',
          county: '$.County',
          postalCode: '$.PostalCode',
          state: '$.State'
        }
      ],
      transactions: [
        '$..ND_PayeezyTransaction',
        {
          id: '$.ID',
          invoiceNumber: '$.InvoiceNumber',
          payedAmount: '$.PayedAmount'
        }
      ]
    }
  })
    .done(function(response) {
      cleanUpResponse(response);
      response.locationName = response.location.addressLine1;
      response.entityIsVerified = response.entityIsVerified === '1';
      response.license.licenseNumber = response.license.licenseNumber || '--';
      response.completedDate = response.completedDate || '--';
      response.currentLicenseStatus = response.license.licenseStatus || '--';
      if (typeof response.applicationReturnedDecisionReason === 'string') {
        response.applicationReturnedDecisionReason = response.applicationReturnedDecisionReason.substring(0, 251);
      }

      response.isNMLSEntity = !!response.entityNMLSId;

      if (!response.licenseType && !response.license.licenseType && response.nmlsLicenseType) {
        response.licenseType = response.nmlsLicenseType;
      } else if (!response.licenseType && response.license.licenseType) {
        response.licenseType = response.license.licenseType;
      }

      if (response.applicationFee) {
        response.applicationFee = parseFloat(response.applicationFee);
      }
      if (response.renewalFee) {
        response.renewalFee = parseFloat(response.renewalFee);
      }
      if (response.branchApplicationFee) {
        response.branchApplicationFee = parseFloat(response.branchApplicationFee);
      }
      if (response.branchRenewalFee) {
        response.branchRenewalFee = parseFloat(response.branchRenewalFee);
      }

      response.location.isPrimaryPlaceOfBusiness = response.location.isPrimaryPlaceOfBusiness === '1';
      response.hasLocation = !!_get(response, 'location.locationId');
      _.each(response.branchLicenses, function(branchLicense) {
        branchLicense.locationName = branchLicense.addressLine1;
        branchLicense.originalIssueDate = moment(branchLicense.originalIssueDate, 'YYYY-MM-DD');
      });
      if (response.submissionDate) {
        response.submissionDate = moment(response.submissionDate, moment.ISO_8601);
      }

      response.completedDate = momentUtil.formatStr(response.completedDate, momentUtil.defaultInboundDateTimeConfig);

      if (response.license.renewalPeriodStart) {
        response.license.renewalPeriodStart = moment(
          moment(response.license.renewalPeriodStart, 'YYYY-MM-DD').format('MM-DD'),
          'MM-DD'
        );
      }
      if (response.license.renewalPeriodEnd) {
        response.license.renewalPeriodEnd = moment(
          moment(response.license.renewalPeriodEnd, 'YYYY-MM-DD').format('MM-DD'),
          'MM-DD'
        );
      }
      response.totalPastTransactions = _.chain(response.transactions)
        .each(function(transaction) {
          transaction.payedAmount = parseFloat(transaction.payedAmount);
        })
        .reduce(function(memo, transaction) {
          memo += transaction.payedAmount;
          return memo;
        }, 0)
        .value();
      defer.resolve(response);
    })
    .fail(function(err) {
      defer.reject(err);
    });

  return defer.promise();
};
