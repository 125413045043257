var Handlebars = require("handlebars/dist/handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "<div class=\"well well-sm review-comments-container\">\n  <h4 class=\"m-t-n\">\n    <i class=\"fa fa-university\" aria-hidden=\"true\"></i> Application Review Comments\n  </h4>\n  <p class=\"review-comments m-n\">"
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? depth0.applicationDetails : depth0)) != null ? stack1.externalReviewComments : stack1), depth0))
    + "</p>\n</div>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "\n<h1 class=\"page-title\">Submit "
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? depth0.applicationDetails : depth0)) != null ? stack1.applicationTypeDescription : stack1), depth0))
    + "</h1>\n<div class=\"header-bar\"></div>\n<div class=\"breadcrumb-container\"></div>\n\n"
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? depth0.applicationDetails : depth0)) != null ? stack1.externalReviewComments : stack1),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "<div class=\"application-container\"></div>\n";
},"useData":true});