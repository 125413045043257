/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var cleanUpResponse = require('common/util/cleanUpResponse');

module.exports = function(entityId) {
  var deferred = $.Deferred();

  $.soap({
    body: {
      GetEntityExternalUsersAndInvites: {
        $xmlns: 'http://dobs.pa.gov/nd/entity/bpm/1.0',
        EntityID: entityId
      }
    },
    blueprintArrays: [
      'GetEntityExternalUsersAndInvitesResponse.ExternalUsers.ND_EntityExternalUser',
      'GetEntityExternalUsersAndInvitesResponse.Invites.ND_EntityJoinCode',
      'GetEntityExternalUsersAndInvitesResponse.AccessLevels.ND_LU_EntityExternalUserAccessLevel'
    ],
    blueprint: {
      authorizedUsers: [
        '$..ND_EntityExternalUser',
        {
          id: '$.ID',
          externalUserId: '$.ExternalUserID',
          firstName: '$.FirstName',
          lastName: '$.LastName',
          userId: '$.UserID',
          email: '$.Email',
          accessLevel: '$.AccessLevelName'
        }
      ],
      invitedUsers: [
        '$..ND_EntityJoinCode',
        {
          id: '$.ID',
          firstName: '$.FirstName',
          lastName: '$.LastName',
          email: '$.Email',
          accessLevel: '$.AccessLevelName'
        }
      ],
      userAccessLevels: [
        '$..ND_LU_EntityExternalUserAccessLevel',
        {
          id: '$.ID',
          name: '$.Name',
          description: '$.Description'
        }
      ]
    }
  })
    .done(function(response) {
      cleanUpResponse(response);
      deferred.resolve(response);
    })
    .fail(function(err) {
      deferred.reject(err);
    });

  return deferred.promise();
};
