/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Marionette = require('backbone.marionette');
var Syphon = require('backbone.syphon');

var parsleyUtil = require('common/util/parsleyUtil');
var makeUploadCollection = require('common/util/makeUploadCollection');
var FileUploadView = require('common/views/FileUploadView');

var CommentsDocumentView = require('common/views/CommentsDocumentView');

var tmplDepBankAcquisitionMergerQuestionnaireSectionView = require('./DepBankAcquisitionMergerQuestionnaireSectionView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplDepBankAcquisitionMergerQuestionnaireSectionView,

  className: 'dep-bank-acquisition-merger-questionnaire-section-view',

  behaviors: {
    ReadOnlyFormBehavior: {},
    RadioGroupToggleBehavior: {
      radios: [
        {
          target: '.federal-applications-container',
          radioGroup: 'input[name="isUploadingFedApp"]',
          valueToShow: '1'
        },
        {
          target: '.questionnaire-container',
          radioGroup: 'input[name="isUploadingFedApp"]',
          valueToShow: '0'
        }
      ]
    }
  },

  ui: {
    documentForm: '.document-form',
    questionnaireContainer: '.questionnaire-container',
    federalApplicationsContainer: '.federal-applications-container',
    balanceSheetContainer: '.balance-sheet-container',
    incomeStatementContainer: '.income-statement-container',
    capitalScheduleContainer: '.capital-schedule-container'
  },

  regions: {
    questionnaireContainer: '@ui.questionnaireContainer',
    federalApplicationsContainer: '@ui.federalApplicationsContainer',
    balanceSheetContainer: '@ui.balanceSheetContainer',
    incomeStatementContainer: '@ui.incomeStatementContainer',
    capitalScheduleContainer: '@ui.capitalScheduleContainer'
  },

  templateHelpers: function() {
    return {
      isReadOnly: this.options.isReadOnly
    };
  },

  onBeforeShow: function() {
    var filingContent = this.model.get('filingContent') || {};
    var federalApplications = filingContent.federalApplications || {};

    Syphon.deserialize(this, filingContent);

    this.federalApplications = new CommentsDocumentView({
      isReadOnly: this.options.isReadOnly,
      allowMultipleFiles: false,
      isRequired: true,
      fileUploadLabel: 'Section IV - Federal Applications',
      commentsLabel: 'Section IV - Federal Applications Comments',
      commentsName: 'federalApplicationsComments',
      collection: makeUploadCollection(federalApplications.file),
      model: new Backbone.Model({ federalApplicationsComments: federalApplications.comments }),
      documentTypeId: 20001
    });
    this.showChildView('federalApplicationsContainer', this.federalApplications);

    this.balanceSheetDocument = new FileUploadView({
      isReadOnly: this.options.isReadOnly,
      allowMultipleFiles: false,
      isRequired: false,
      collection: makeUploadCollection(filingContent.balanceSheetDocument),
      metadata: {
        documentTypeId: 20001
      }
    });
    this.showChildView('balanceSheetContainer', this.balanceSheetDocument);

    this.incomeStatementDocument = new FileUploadView({
      isReadOnly: this.options.isReadOnly,
      allowMultipleFiles: false,
      isRequired: false,
      collection: makeUploadCollection(filingContent.incomeStatementDocument),
      metadata: {
        documentTypeId: 20001
      }
    });
    this.showChildView('incomeStatementContainer', this.incomeStatementDocument);

    this.capitalScheduleDocument = new FileUploadView({
      isReadOnly: this.options.isReadOnly,
      allowMultipleFiles: false,
      isRequired: false,
      collection: makeUploadCollection(filingContent.capitalScheduleDocument),
      metadata: {
        documentTypeId: 20001
      }
    });
    this.showChildView('capitalScheduleContainer', this.capitalScheduleDocument);
  },

  validate: function(config) {
    var formData = Syphon.serialize(this);
    this.ui.documentForm.parsley().reset();
    this.federalApplications.removeValidationErrors();

    if (config.type === 'save') {
      // Turn off required field validation for this form
      parsleyUtil.disableRequiredValidation(this.ui.documentForm);
    } else {
      var validationPromises = [this.ui.documentForm.parsley().whenValidate()];
      if (formData.isUploadingFedApp === '1') {
        validationPromises.push(this.federalApplications.validate());
      }
      if (formData.isUploadingFedApp === '0') {
        validationPromises.push(this.balanceSheetDocument.validate());
        validationPromises.push(this.incomeStatementDocument.validate());
        validationPromises.push(this.capitalScheduleDocument.validate());
      }

      // Turn on required field validation for this form
      parsleyUtil.enableRequiredValidation(this.ui.documentForm);
    }
    return $.when.apply($, validationPromises);
  },

  getFormData: function() {
    var formData = Syphon.serialize(this);
    formData.federalApplicationsDocId = this.federalApplications.getFirstFileId();
    formData.balanceSheetDocId = this.balanceSheetDocument.getFirstFileId();
    formData.incomeStatementDocId = this.incomeStatementDocument.getFirstFileId();
    formData.capitalScheduleDocId = this.capitalScheduleDocument.getFirstFileId();

    return formData;
  }
});
