var Handlebars = require("handlebars/dist/handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.escapeExpression;

  return "\n<h1 class=\"page-title\">"
    + alias1(container.lambda(((stack1 = (depth0 != null ? depth0.entityIdentifiers : depth0)) != null ? stack1.entityName : stack1), depth0))
    + "</h1>\n<div class=\"header-bar\"></div>\n<div class=\"breadcrumb-container\"></div>\n<h2 class=\"m-t-n\">Initiate Correspondence</h2>\n<div class=\"form-horizontal col-sm-9 col-sm-offset-1 correspondence-form-container\">\n</div>\n<div class=\"form-horizontal col-sm-9 col-sm-offset-1\">\n  <div class=\"row m-b-lg\">\n    <div class=\"col-sm-12\">\n      <div class=\"form-group\">\n        <div class=\"col-sm-9 col-sm-offset-3\">\n          <a href=\"#dep/entity/"
    + alias1(((helper = (helper = helpers.entityId || (depth0 != null ? depth0.entityId : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"entityId","hash":{},"data":data}) : helper)))
    + "/dashboard\" class=\"btn btn-default\">Cancel</a>\n          <button class=\"btn btn-primary btn-submit submit\">Submit</button>\n        </div>\n      </div>\n    </div>\n  </div>\n</div>";
},"useData":true});