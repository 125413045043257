/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var cleanUpResponse = require('common/util/cleanUpResponse');

module.exports = function(annualReportId) {
  var deferred = $.Deferred();

  $.soap({
    body: {
      GetDebtSettlementServicesAnnualReport: {
        $xmlns: 'http://dobs.pa.gov/nd/annualreport/bpm/1.0',
        AnnualReportID: annualReportId
      }
    },
    blueprintArrays: [
      'GetDebtSettlementServicesAnnualReportResponse.StateDropdownActivities.ND_AnnualReportDebtSettlementServicesOtherStateActivities'
    ],
    blueprint: {
      id: '$..ID',
      annualReportId: '$..AnnualReportID',
      numBeginningEnrolledNotAvailable: '$..NumBeginningEnrolledNotAvailable',
      numBeginningEnrolledNumber: '$..NumBeginningEnrolledNumber',
      numBeginningEnrolledDollars: '$..NumBeginningEnrolledDollars',
      numBeginningEnrolledExplanation: '$..NumBeginningEnrolledExplanation',
      numSignedUpNotAvailable: '$..NumSignedUpNotAvailable',
      numSignedUpNumber: '$..NumSignedUpNumber',
      numSignedUpDollars: '$..NumSignedUpDollars',
      numSignedUpExplanation: '$..NumSignedUpExplanation',
      numCompletedNotAvailable: '$..NumCompletedNotAvailable',
      numCompletedNumber: '$..NumCompletedNumber',
      numCompletedDollars: '$..NumCompletedDollars',
      numCompletedExplanation: '$..NumCompletedExplanation',
      numDroppedOutAfterThreeMonthsNotAvailable: '$..NumDroppedOutAfterThreeMonthsNotAvailable',
      numDroppedOutAfterThreeMonthsNumber: '$..NumDroppedOutAfterThreeMonthsNumber',
      numDroppedOutAfterThreeMonthsDollars: '$..NumDroppedOutAfterThreeMonthsDollars',
      numDroppedOutAfterThreeMonthsExplanation: '$..NumDroppedOutAfterThreeMonthsExplanation',
      numDroppedOutBeforeThreeMonthsNotAvailable: '$..NumDroppedOutBeforeThreeMonthsNotAvailable',
      numDroppedOutBeforeThreeMonthsNumber: '$..NumDroppedOutBeforeThreeMonthsNumber',
      numDroppedOutBeforeThreeMonthsDollars: '$..NumDroppedOutBeforeThreeMonthsDollars',
      numDroppedOutBeforeThreeMonthsExplanation: '$..NumDroppedOutBeforeThreeMonthsExplanation',
      numEnrolledNotAvailable: '$..NumEnrolledNotAvailable',
      numEnrolledNumber: '$..NumEnrolledNumber',
      numEnrolledDollars: '$..NumEnrolledDollars',
      numEnrolledExplanation: '$..NumEnrolledExplanation',
      numDeniedNotAvailable: '$..NumDeniedNotAvailable',
      numDeniedNumber: '$..NumDeniedNumber',
      numDeniedDollars: '$..NumDeniedDollars',
      numDeniedExplanation: '$..NumDeniedExplanation',
      isRevoked: '$..IsRevoked',
      isRevokedDocument: {
        fileId: '$..IsRevokedDocumentID',
        fileName: '$..IsRevokedDocumentName'
      },
      hasSurrenderedDocument: {
        fileId: '$..HasSurrenderedDocumentID',
        fileName: '$..HasSurrenderedDocumentName'
      },
      hasEnforcementDocument: {
        fileId: '$..HasEnforcementDocumentID',
        fileName: '$..HasEnforcementDocumentName'
      },
      hasLawsuitsDocument: {
        fileId: '$..HasLawsuitsDocumentID',
        fileName: '$..HasLawsuitsDocumentName'
      },
      isUnderInvestigationDocument: {
        fileId: '$..IsUnderInvestigationDocumentId',
        fileName: '$..IsUnderInvestigationName'
      },
      hasSurrendered: '$..HasSurrendered',
      hasEnforcement: '$..HasEnforcement',
      isUnderInvestigation: '$..IsUnderInvestigation',
      hasLawsuitsInitiated: '$..HasLawsuitsInitiated',
      hasLawsuitsContinued: '$..HasLawsuitsContinued',
      hasLawsuitsResolved: '$..HasLawsuitsResolved',
      hasCriminalOfficer: '$..HasCriminalOfficer',
      hasCriminalOfficerExplaination: '$..HasCriminalOfficerExplaination',
      isRevokedDocumentId: '$..IsRevokedDocumentID',
      hasSurrenderedDocumentId: '$..HasSurrenderedDocumentID',
      hasEnforcementDocumentId: '$..HasEnforcementDocumentID',
      hasLawsuitsDocumentId: '$..HasLawsuitsDocumentID',
      isUnderInvestigationDocumentId: '$..IsUnderInvestigationDocumentId',
      hasNewOwners: '$..HasNewOwners',
      hasNewOwnersExplanation: '$..HasNewOwnersExplanation',
      hasQuittedOwners: '$..HasQuittedOwners',
      hasQuittedOwnersExplanation: '$..HasQuittedOwnersExplanation',
      hasNewAssets: '$..HasNewAssets',
      hasNewAssetsExplanation: '$..HasNewAssetsExplanation',
      otherTerritoriesExplanation: '$..OtherTerritoriesExplanation',
      certificationName: '$..CertificationName',
      isCertified: '$..IsCertified',
      stateDropdownActivities: [
        '$..ND_AnnualReportDebtSettlementServicesOtherStateActivities',
        {
          state: '$.State',
          debtManagementActivity: '$.DebtManagementActivity',
          debtSettlementActivity: '$.DebtSettlementActivity'
        }
      ]
    }
  })
    .done(function(response) {
      cleanUpResponse(response);
      response.numBeginningEnrolledNotAvailable = response.numBeginningEnrolledNotAvailable === '1';
      response.numSignedUpNotAvailable = response.numSignedUpNotAvailable === '1';
      response.numCompletedNotAvailable = response.numCompletedNotAvailable === '1';
      response.numDroppedOutAfterThreeMonthsNotAvailable = response.numDroppedOutAfterThreeMonthsNotAvailable === '1';
      response.numDroppedOutBeforeThreeMonthsNotAvailable = response.numDroppedOutBeforeThreeMonthsNotAvailable === '1';
      response.numEnrolledNotAvailable = response.numEnrolledNotAvailable === '1';
      response.numDeniedNotAvailable = response.numDeniedNotAvailable === '1';
      response.isCertified = response.isCertified === '1';

      deferred.resolve(response);
    })
    .fail(function(err) {
      deferred.reject();
    });

  return deferred.promise();
};
