/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');

var GetContactsByEntityAndDepartment = require('../../common/services/GetContactsByEntityAndDepartment');
var GetLicenseContactsByEntityAndLicenseType = require('../services/GetLicenseContactsByEntityAndLicenseType');

module.exports = {
  channel: 'cs',

  event: 'build:contact-modal-list',

  builder: function(data) {
    return buildIndividualsDropdown(data);
  }
};

function buildIndividualsDropdown(data) {
  var deferred = $.Deferred();
  var services = [GetContactsByEntityAndDepartment(data)];
  if (data.departmentId === '1000' && data.licenseTypeId) {
    services.push(GetLicenseContactsByEntityAndLicenseType(data));
  }
  $.when
    .apply($, services)
    .done(function(contacts, licenseContacts) {
      if (_.isArray(licenseContacts)) {
        contacts = contacts.concat(licenseContacts);
      }
      deferred.resolve(contacts);
    })
    .fail(function(err) {
      deferred.reject(err);
    });

  return deferred.promise();
}
