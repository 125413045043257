/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Marionette = require('backbone.marionette');

var EmptySetView = require('common/views/EmptySetView');

var NonDepEntityFormDBAView = require('./NonDepEntityFormDBAView');

module.exports = Marionette.CollectionView.extend({
  className: 'nondep-entity-form-dba-collection-view',

  childView: NonDepEntityFormDBAView,

  emptyView: EmptySetView,

  emptyViewOptions: function() {
    return {
      emptyText: 'No New DBAs'
    };
  },

  childViewOptions: function(model, index) {
    // Pass the index to the collection view
    return {
      childIndex: index,
      isReadOnly: this.options.isReadOnly
    };
  },

  childEvents: {
    'remove:dba': 'onChildClickRemoveDBA'
  },

  onChildClickRemoveDBA: function(childView) {
    this.collection.remove(childView.model);
  },

  getFormData: function() {
    if (this.collection.length > 0) {
      return this.children.map(function(child) {
        return child.getFormData();
      });
    }
  },

  validate: function(config) {
    // Run the .validate() method on every child view, which should return true
    // if valid
    if (this.collection.length) {
      var validationPromises = this.children.map(function(childView) {
        var validateView = childView.validate.bind(childView);
        return validateView(config);
      });
      return $.when.apply($, validationPromises);
    }
  }
});
