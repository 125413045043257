/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var moment = require('moment');

var cleanUpResponse = require('common/util/cleanUpResponse');

module.exports = function(individualId) {
  var deferred = $.Deferred();

  $.soap({
    body: {
      GetComplaintsByCSIndividual: {
        $xmlns: 'http://dobs.pa.gov/cs/complaint/bpm/1.0',
        IndividualID: individualId
      }
    },
    blueprintArrays: ['GetComplaintsByCSIndividualResponse.CS_Complaint'],
    blueprint: {
      complaints: [
        '$..CS_Complaint',
        {
          complaintId: '$..ID',
          caseNumber: '$..CaseNumber',
          status: '$..Status',
          statusId: '$..StatusID',
          weight: '$..Weight',
          caseManager: '$..CaseManager',
          entityId: '$..EntityID',
          maxOpenedDate: '$..MaxOpenedDate',
          maxClosedDate: '$..MaxClosedDate',
          isDeleted: '$..IsDeleted'
        }
      ]
    }
  })
    .done(function(response) {
      cleanUpResponse(response);
      _.each(response.complaints, function(complaint) {
        if (complaint.statusId === '1') {
          complaint.dateOpenedClosed = complaint.maxOpenedDate;
        } else {
          complaint.dateOpenedClosed = complaint.maxClosedDate;
        }

        if (complaint.dateOpenedClosed) {
          complaint.dateOpenedClosed = moment(complaint.dateOpenedClosed, 'YYYY-MM-DD');
        }

        _.each(['caseNumber', 'status', 'weight', 'caseManager'], function(attr) {
          if (!complaint[attr]) {
            complaint[attr] = '--';
          }
        });
      });

      deferred.resolve(response.complaints);
    })
    .fail(function(err) {
      deferred.reject();
    });

  return deferred.promise();
};
