/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2020] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var Marionette = require('backbone.marionette');
var Syphon = require('backbone.syphon');
var Ladda = require('ladda');

var GridView = require('psa-marionette/views/GridView');
var PageableCollection = require('psa-backbone/models/PageableCollection');

var SelectOneCell = require('common/backgrid/SelectOneCell');
var AddressCell = require('common/backgrid/AddressCell');

var GetIndividualByName = require('../services/GetIndividualByName');

var tmplConSerIndividualSearchModalView = require('../templates/ConSerIndividualSearchModalView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplConSerIndividualSearchModalView,

  className: 'con-ser-individual-search-modal-view',

  ui: {
    searchResultsGrid: '.search-result-grid-container',
    search: '.search-individual',
    searchResultSummary: '.search-result-summary',
    searchResultMax: '.search-result-max',
    hitCount: '.hit-count',
    searchTime: '.search-time',
    refineSeachTip: '.refine-seach-tip',
    submit: '.btn-submit'
  },

  regions: {
    searchResultsGrid: '@ui.searchResultsGrid'
  },

  events: {
    'click @ui.search': 'onClickSearch',
    'click @ui.submit': 'onClickSubmit'
  },

  templateHelpers: function() {
    return {
      searchLimit: this.searchLimit,
      searchable: this.options.searchable
    };
  },

  onBeforeShow: function() {
    var collection = this.collection.toJSON();
    var individuals = collection[0].individuals;
    _.each(individuals, function(individual) {
      individual.contactType = individual.contactType ? individual.contactType : '--';
      individual.title = individual.title ? individual.title : '--';
      individual.firstName = individual.firstName ? individual.firstName : '--';
      individual.lastName = individual.lastName ? individual.lastName : '--';
    });

    var totalHits = collection[0].totalHits;
    this.ui.hitCount.text(totalHits);
    this.ui.searchResultSummary.show();
    if (totalHits === 50) {
      this.ui.searchResultMax.show();
    }

    var gridColumns = [
      {
        name: 'selected',
        label: '',
        cell: SelectOneCell.SelectOneRowCell,
        headerCell: SelectOneCell.SelectOneHeaderCell,
        width: 5,
        editable: true,
        sortable: false
      },
      {
        name: 'contactType',
        label: 'Contact Type',
        cell: 'string',
        headerCell: 'custom',
        width: 20,
        editable: false
      },
      {
        name: 'title',
        label: 'Title',
        cell: 'string',
        headerCell: 'custom',
        width: 10,
        editable: false
      },
      {
        name: 'firstName',
        label: 'First Name',
        cell: 'string',
        headerCell: 'custom',
        width: 20,
        editable: false
      },
      {
        name: 'lastName',
        label: 'Last Name',
        cell: 'string',
        headerCell: 'custom',
        width: 20,
        editable: false
      },
      {
        name: 'address',
        label: 'Address',
        cell: AddressCell,
        headerCell: 'custom',
        width: 25,
        editable: false,
        sortable: false
      }
    ];

    this.individualSearchResults = new GridView({
      columns: gridColumns,
      collection: new PageableCollection(individuals, { pageSize: 10 }),
      emptyText: 'No individuals found.'
    });

    this.showChildView('searchResultsGrid', this.individualSearchResults);
  },

  onClickSearch: function(e) {
    e.preventDefault();
    var self = this;
    this.ui.searchResultSummary.hide();
    this.ui.searchResultMax.hide();

    var data = Syphon.serialize(this, {
      inputReaders: Syphon.SearchInputReaderSet
    });
    var l = Ladda.create(this.ui.search[0]);
    l.start();

    GetIndividualByName(data.firstName, data.lastName)
      .done(function(data) {
        l.stop();
        self.individualSearchResults.collection.fullCollection.reset(data.individuals);
        self.ui.hitCount.text(data.totalHits);
        self.ui.searchResultSummary.show();
        if (data.totalHits === 50) {
          self.ui.searchResultMax.show();
        }
      })
      .fail(function(err) {
        l.stop();
      });
  },

  onClickSubmit: function(e) {
    e.preventDefault();
    var selectedModel = this.individualSearchResults.viewGrid.getSelectedModel();
    if (selectedModel) {
      this.triggerMethod('modal:close', selectedModel.toJSON());
    } else {
      this.triggerMethod('modal:close');
    }
  }
});
