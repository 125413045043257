/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2020] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Backbone = require('backbone');
var NProgress = require('nprogress');

var NavUtils = require('psa-core/util/nav');

var Scaffold = require('common/layout/Scaffold');
var GetEntityIdentifiers = require('../../common/services/GetEntityIdentifiers');
var GetAllContactLookups = require('../../common/services/GetAllContactLookups');

var GetEntityContact = require('../services/GetEntityContact');
var DepContactEditView = require('../views/DepContactEditView');

module.exports = function(entityId, contactId) {
  NProgress.start();
  NProgress.set(0.6);

  $.when(GetEntityIdentifiers(entityId), GetEntityContact(contactId))
    .done(function(entityIdentifiers, contact) {
      NavUtils.navigate('dep/entity/' + entityId + '/contact/' + contactId + '/edit');
      GetAllContactLookups(entityIdentifiers.categoryId, contact.existingContactTypes, contact.contactTypeId)
        .done(function(lookups) {
          Scaffold.content.show(
            new DepContactEditView({
              model: new Backbone.Model({
                entityIdentifiers: entityIdentifiers,
                contact: contact,
                contactTypes: lookups.contactTypes
              })
            })
          );
          NProgress.done();
        })
        .fail(function() {
          NProgress.done();
        });
    })
    .fail(function() {
      NProgress.done();
    });
};
