/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Backbone = require('backbone');
var Marionette = require('backbone.marionette');
var Radio = require('backbone.radio');

var UIUtil = require('psa-core/util/ui');
var userHasRole = require('common/util/userHasRole');

var SideNavSectionView = require('common/views/SideNavSectionView');
var BreadcrumbView = require('common/views/BreadcrumbView');
var DropdownView = require('common/views/DropdownView');
var ConfirmationModalView = require('common/views/ConfirmationModalView');
var ContentServerLinkView = require('common/views/ContentServerLinkView');

var SubmitRecord = require('../../common/services/SubmitRecord');
var CorpFinRecordHeaderBarView = require('../../common/views/CorpFinRecordHeaderBarView');
var CorpFinRegRegistrationSectionView = require('./CorpFinRegRegistrationSectionView');
var CorpFinRegSecuritiesDescriptionSectionView = require('./CorpFinRegSecuritiesDescriptionSectionView');

var tmplCorpFinRegistrationDashboardView = require('../templates/CorpFinRegistrationDashboardView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplCorpFinRegistrationDashboardView,

  className: 'corp-fin-registration-dashboard-view container-fluid m-b-lg',

  ui: {
    registrationWrapper: '.reg-wrapper',
    csLinkContainer: '.cs-link-container',
    regActionDropDown: '.dropdown-container',
    headerBar: '.header-bar',
    breadcrumb: '.breadcrumb-container'
  },

  regions: {
    registrationWrapper: '@ui.registrationWrapper',
    csLinkContainer: '@ui.csLinkContainer',
    regActionDropDown: '@ui.regActionDropDown',
    headerBar: '@ui.headerBar',
    breadcrumb: '@ui.breadcrumb'
  },

  onBeforeShow: function() {
    var recordId = this.model.get('recordId');
    var record = this.model.get('record') || {};
    var recordCategoryId = record.recordCategoryId;
    var fileNumber = record.fileNumber;
    var isReadOnly = userHasRole('CF - Read Only');

    this.showChildView(
      'breadcrumb',
      new BreadcrumbView({
        breadcrumbs: [
          {
            link: '#corpfin/dashboard',
            text: 'Home'
          },
          {
            text: 'Registration (' + fileNumber + ')'
          }
        ]
      })
    );

    if (!isReadOnly) {
      var dropDownOptions = [
        {
          text: 'Edit Registration',
          link: '#corpfin/registration/' + recordId + '/edit-details'
        },
        {
          text: 'Delete Registration',
          onClick: this.onClickDelete.bind(this)
        },
        {
          isDivider: true
        },
        {
          text: 'Add Fee',
          link: '#corpfin/registration/' + recordId + '/new-fee'
        },
        {
          text: 'Edit Securities Description',
          link: '#corpfin/registration/' + recordId + '/edit-securities-description'
        },
        {
          text: 'Add Secondary Name',
          link: '#corpfin/registration/' + recordId + '/new-secondary-name'
        },
        {
          text: 'Add Affiliate',
          link: '#corpfin/registration/' + recordId + '/new-affiliate'
        }
      ];
    }

    this.showChildView(
      'regActionDropDown',
      new DropdownView({
        rightAlign: true,
        model: new Backbone.Model({
          name: 'Registration Actions',
          options: dropDownOptions
        })
      })
    );

    this.showChildView(
      'csLinkContainer',
      new ContentServerLinkView({
        model: new Backbone.Model({
          buttonText: 'Open Registration Folder',
          contentServerId: record.recordCSFolderId
        })
      })
    );

    this.showChildView(
      'headerBar',
      new CorpFinRecordHeaderBarView({
        model: new Backbone.Model(record)
      })
    );

    this.registration = new SideNavSectionView({
      collection: new Backbone.Collection([
        {
          id: 'registration',
          title: 'Registration',
          view: new CorpFinRegRegistrationSectionView({
            model: new Backbone.Model(record)
          })
        },
        {
          id: 'fee',
          title: 'Fee',
          view: Radio.channel('cf').request('build:corp-fin-fees-section-view', recordId, recordCategoryId, isReadOnly)
        },
        {
          id: 'priorNames',
          title: 'Prior Names',
          view: Radio.channel('cf').request(
            'build:corp-fin-prior-names-section-view',
            recordId,
            recordCategoryId,
            isReadOnly
          )
        },
        {
          id: 'securitiesDescription',
          title: 'Securities Description',
          view: new CorpFinRegSecuritiesDescriptionSectionView({
            model: new Backbone.Model(record)
          })
        },
        {
          id: 'secondaryNames',
          title: 'Secondary Names',
          view: Radio.channel('cf').request(
            'build:corp-fin-secondary-names-section-view',
            recordId,
            recordCategoryId,
            isReadOnly
          )
        },
        {
          id: 'affiliates',
          title: 'Affiliates',
          view: Radio.channel('cf').request(
            'build:corp-fin-affiliates-section-view',
            recordId,
            recordCategoryId,
            isReadOnly
          )
        },
        {
          id: 'notes',
          title: 'Notes',
          view: Radio.channel('cf').request('build:notes-view', {
            objectId: recordId,
            objectTypeId: 'REGISTRATION'
          })
        },
        {
          id: 'auditLog',
          title: 'Audit Log',
          view: Radio.channel('cf').request('build:audit-log-view', {
            objectId: recordId,
            objectTypeId: 'REGISTRATION'
          })
        }
      ])
    });
    this.showChildView('registrationWrapper', this.registration);
  },

  onClickDelete: function() {
    var self = this;
    var message = 'Are you sure you want to delete this registration?';
    UIUtil.showModal({
      title: 'Confirm Deleting Registration',
      view: new ConfirmationModalView({
        confirmText: 'Delete Registration',
        model: new Backbone.Model({
          message: message,
          onConfirm: function() {
            var registrationId = self.model.get('recordId');
            return SubmitRecord({ recordId: registrationId, isDeleted: '1' });
          },
          onConfirmDone: function() {
            Radio.channel('navigate').trigger('show:int:cf:dashboard');
          }
        })
      })
    });
  }
});
