/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var moment = require('moment');

var cleanUpResponse = require('common/util/cleanUpResponse');

module.exports = function(complianceId) {
  var defer = $.Deferred();

  $.soap({
    body: {
      GetComplianceSanctionsByCompliance: {
        $xmlns: 'http://dobs.pa.gov/sc/compliance/bpm/1.0',
        ComplianceID: complianceId
      }
    },
    blueprintArrays: ['GetComplianceSanctionsByComplianceResponse.SC_ComplianceSanction'],
    blueprint: {
      complianceSanctions: [
        '$..SC_ComplianceSanction',
        {
          complianceSanctionId: '$.ID',
          complianceId: '$.ComplianceID',
          sanctionId: '$.SanctionID',
          sanction: '$.Sanction',
          note: '$.Note',
          isDeleted: '$.IsDeleted',
          createdDate: '$.CreatedDate',
          createdBy: '$.CreatedBy',
          modifiedDate: '$.ModifiedDate',
          modifiedBy: '$.ModifiedBy'
        }
      ]
    }
  })
    .done(function(response) {
      cleanUpResponse(response);

      _.each(response.complianceSanctions, function(sanction) {
        sanction.note = sanction.note || '--';

        if (sanction.createdDate) {
          sanction.createdDate = moment(sanction.createdDate, moment.ISO_8601);
        }
        if (sanction.modifiedDate) {
          sanction.modifiedDate = moment(sanction.modifiedDate, moment.ISO_8601);
        }
      });

      defer.resolve(response.complianceSanctions);
    })
    .fail(function(err) {
      defer.reject(err);
    });

  return defer.promise();
};
