/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var Marionette = require('backbone.marionette');
var callByNeed = require('common/util/callByNeed');

module.exports = Marionette.Behavior.extend({
  onBeforeShow: function() {
    var self = this;
    _.each(callByNeed(this.options.toggles), function(toggle) {
      self.$(toggle.group).on('change', function() {
        self.handleTargetDisplay(toggle);
      });
      self.handleTargetDisplay(toggle);
    });
  },

  handleTargetDisplay: function(toggle) {
    var currentValue;
    if (this.$(toggle.group).prop('tagName') === 'SELECT') {
      currentValue = this.$(toggle.group + ' option:selected').val();
    } else {
      currentValue = this.$(toggle.group + ':checked').val();
    }
    if (
      _.find(toggle.valuesToShow, function(val) {
        return val === currentValue;
      })
    ) {
      this.$(toggle.target).show();
      this.$(toggle.target).removeAttr('disabled');
    } else {
      this.$(toggle.target).hide();
      this.$(toggle.target).attr('disabled', '');
    }
  }
});
