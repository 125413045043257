/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var get = require('lodash.get');
var moment = require('moment');

var cleanUpResponse = require('common/util/cleanUpResponse');
var isNonDepAmendmentTask = require('../../common/util/isNonDepAmendmentTask');

module.exports = function() {
  var deferred = $.Deferred();

  $.soap({
    body: {
      GetSelfServiceDashboard: {
        $xmlns: 'http://dobs.pa.gov/nd/dashboard/bpm/1.0'
      }
    },
    blueprintArrays: [
      'GetSelfServiceDashboardResponse.Entities.ND_Entity',
      'GetSelfServiceDashboardResponse.Applications.ND_Application',
      'GetSelfServiceDashboardResponse.Tasks.Tasks.NOTF_TASK_INSTANCE',
      'GetSelfServiceDashboardResponse.Tasks.Applications.ND_Application',
      'GetSelfServiceDashboardResponse.Tasks.Exams.ND_Exam',
      'GetSelfServiceDashboardResponse.Tasks.Compliances.ND_Compliance',
      'GetSelfServiceDashboardResponse.Tasks.Correspondences.ND_Correspondence',
      'GetSelfServiceDashboardResponse.Tasks.TaskTypes.CM_LU_TaskType',
      'GetSelfServiceDashboardResponse.Tasks.Licenses.ND_License'
    ],
    blueprint: {
      entities: [
        '$.GetSelfServiceDashboardResponse.Entities.ND_Entity',
        {
          id: '$.ID',
          tin: '$.TIN',
          entityName: '$.EntityName',
          entityStatusId: '$.EntityStatusID',
          entityStatus: '$.EntityStatus',
          createdDate: '$.CreatedDate'
        }
      ],
      applications: [
        '$.GetSelfServiceDashboardResponse.Applications.ND_Application',
        {
          id: '$..ID',
          applicationType: '$..ApplicationType',
          entityName: '$..EntityName',
          tin: '$..TIN',
          modifiedDate: '$..ModifiedDate',
          accessLevelId: '$..EntityExternalUserAccessLevelID'
        }
      ],
      tasks: [
        '$..NOTF_TASK_INSTANCE',
        {
          taskId: '$..TaskId',
          deliveryDate: '$..DeliveryDate',
          applicationId: '$..ApplicationID',
          examId: '$..ExamID',
          complianceId: '$..ComplianceID',
          taskTypeName: '$..TaskTypeName',
          correspondenceId: '$..CorrespondenceID',
          dueDate: '$..DueDate',
          licenseId: '$..LicenseID',
          entityId: '$..EntityID'
        }
      ],
      taskMetaData: {
        applications: [
          '$.GetSelfServiceDashboardResponse.Tasks.Applications.ND_Application',
          {
            id: '$..ApplicationID',
            entityName: '$..EntityName',
            applicationType: '$..ApplicationType',
            applicationCategory: '$..ApplicationCategory',
            licenseType: '$..LicenseType',
            licenseNumber: '$..LicenseNumber',
            address: {
              addressLine1: '$..AddressLine1',
              addressLine2: '$..AddressLine2',
              addressLine3: '$..AddressLine3',
              city: '$..City',
              state: '$..State',
              postalCode: '$..PostalCode',
              country: '$..Country'
            }
          }
        ],
        exams: [
          '$..ND_Exam',
          {
            id: '$..ExamID',
            entityName: '$..EntityName',
            licenseType: '$..LicenseType',
            licenseNumber: '$..LicenseNumber',
            address: {
              addressLine1: '$..AddressLine1',
              addressLine2: '$..AddressLine2',
              addressLine3: '$..AddressLine3',
              city: '$..City',
              state: '$..State',
              postalCode: '$..PostalCode',
              country: '$..Country'
            }
          }
        ],
        compliances: [
          '$..ND_Compliance',
          {
            id: '$..ComplianceID',
            entityName: '$..EntityName',
            licenseType: '$..LicenseType',
            operatingAsLicenseType: '$..OperatingAsLicenseType',
            licenseNumber: '$..LicenseNumber',
            address: {
              addressLine1: '$..AddressLine1',
              addressLine2: '$..AddressLine2',
              addressLine3: '$..AddressLine3',
              city: '$..City',
              state: '$..State',
              postalCode: '$..PostalCode',
              country: '$..Country'
            }
          }
        ],
        correspondences: [
          '$..ND_Correspondence',
          {
            correspondenceId: '$..CorrespondenceID',
            entityName: '$..EntityName',
            licenseType: '$..LicenseType',
            licenseNumber: '$..LicenseNumber',
            licenseId: '$..LicenseID',
            applicationCategory: '$..ApplicationCategory',
            applicationType: '$..ApplicationType',
            address: {
              addressLine1: '$..AddressLine1',
              addressLine2: '$..AddressLine2',
              addressLine3: '$..AddressLine3',
              city: '$..City',
              state: '$..State',
              postalCode: '$..PostalCode',
              country: '$..Country'
            }
          }
        ],
        licenses: [
          '$..ND_License',
          {
            licenseId: '$..ID',
            entityName: '$..EntityName',
            licenseType: '$..LicenseType',
            licenseNumber: '$..LicenseNumber',
            address: {
              addressLine1: '$..AddressLine1',
              addressLine2: '$..AddressLine2',
              addressLine3: '$..AddressLine3',
              city: '$..City',
              state: '$..State',
              postalCode: '$..PostalCode',
              country: '$..Country'
            }
          }
        ],
        taskTypes: [
          '$..CM_LU_TaskType',
          {
            id: '$.ID',
            name: '$.Name',
            description: '$.Description'
          }
        ]
      }
    }
  })
    .done(function(response) {
      cleanUpResponse(response);

      _.each(response.entities, function(entity) {
        entity.tin = entity.tin || '--';
        entity.createdDate = moment(entity.createdDate, moment.ISO_8601);
      });

      _.each(response.applications, function(app) {
        app.modifiedDate = moment(app.modifiedDate, moment.ISO_8601);
        app.accessLevelId = Number(app.accessLevelId);
      });

      _.each(response.tasks, function(task) {
        task.deliveryDate = moment.utc(task.deliveryDate, moment.ISO_8601).local();

        if (!moment(task.dueDate, moment.ISO_8601).isValid()) {
          task.dueDate = task.dueDate || '--';
        } else {
          task.dueDate = moment(task.dueDate, moment.ISO_8601).format('MM/DD/YYYY');
        }

        var type = _.find(response.taskMetaData.taskTypes, function(type) {
          return task.taskTypeName === type.name;
        });

        task.type = type.description;
        if (task.applicationId) {
          var application = _.find(response.taskMetaData.applications, function(application) {
            return task.applicationId === application.id;
          });

          if (application) {
            task.applicationType = application.applicationCategory;
            task.entityName = application.entityName;
            task.licenseType = application.licenseType || '--';
            task.licenseNumber = application.licenseNumber || '--';
            task.location = get(application, 'address.addressLine1', '--') || '--';
            var isAmendmentTask = isNonDepAmendmentTask(type.id);
            if (isAmendmentTask) {
              task.type += ' - ' + application.applicationType;
            }
          }
        } else if (task.examId) {
          /* Handle Exam Meta Data */

          var exam = _.find(response.taskMetaData.exams, function(exam) {
            return task.examId === exam.id;
          });

          if (exam) {
            task.applicationType = '--';
            task.entityName = exam.entityName;
            task.licenseType = exam.licenseType || '--';
            task.licenseNumber = exam.licenseNumber || '--';
            task.location = get(exam, 'address.addressLine1', '--') || '--';
          }
        } else if (task.complianceId) {
          /* Handle Compliance Meta Data */

          var compliance = _.find(response.taskMetaData.compliances, function(compliance) {
            return task.complianceId === compliance.id;
          });

          if (compliance) {
            task.applicationType = '--';
            task.entityName = compliance.entityName;
            task.licenseType = compliance.licenseType || compliance.operatingAsLicenseType;
            task.licenseNumber = compliance.licenseNumber || '--';
            task.location = get(compliance, 'address.addressLine1', '--') || '--';
          }
        } else if (task.correspondenceId) {
          var correspondence = _.find(response.taskMetaData.correspondences, function(correspondence) {
            return task.correspondenceId === correspondence.correspondenceId;
          });
          if (correspondence) {
            task.applicationType = correspondence.applicationType || '--';
            task.entityName = correspondence.entityName || '--';
            task.licenseType = correspondence.licenseType || '--';
            task.licenseNumber = correspondence.licenseNumber || '--';
            task.location = get(correspondence, 'address.addressLine1', '--') || '--';
          }
        } else if (task.licenseId) {
          var license = _.find(response.taskMetaData.licenses, function(license) {
            return task.licenseId === license.licenseId;
          });
          if (license) {
            task.entityName = license.entityName || '--';
            task.licenseType = license.licenseType || '--';
            task.licenseNumber = license.licenseNumber || '--';
            task.location = get(license, 'address.addressLine1', '--') || '--';
          }
        } else if (task.entityId) {
          var entity = _.find(response.entities, function(entity) {
            return task.entityId === entity.id;
          });
          if (entity) {
            task.entityName = entity.entityName || '--';
            task.licenseType = '--';
            task.licenseNumber = '--';
            task.location = '--';
          }
        }
      });

      delete response.taskMetaData;
      deferred.resolve(response);
    })
    .fail(function(err) {
      deferred.reject();
    });

  return deferred.promise();
};
