/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var Backbone = require('backbone');
var NProgress = require('nprogress');

var NavUtils = require('psa-core/util/nav');

var Scaffold = require('common/layout/Scaffold');

var GetEntityIdentifiers = require('../../common/services/GetEntityIdentifiers');
var GetEntityDetails = require('../../entity-form/services/GetEntityDetails');
var GetAllEntityOrganizationTypes = require('../../entity-form/services/GetAllEntityOrganizationTypes');

var NonDepEntityEditBooksAndRecordsView = require('../views/NonDepEntityEditBooksAndRecordsView');

module.exports = function(entityId) {
  NProgress.start();
  NProgress.set(0.6);

  $.when(GetEntityIdentifiers(entityId), GetEntityDetails(entityId), GetAllEntityOrganizationTypes())
    .done(function(entityIdentifiers, entityDetails, entityOrganizationTypes) {
      NavUtils.navigate('nondep/entity/' + entityId + '/edit-books-and-records');
      var data = _.extend(entityIdentifiers, entityDetails, {
        entityOrganizationTypes: entityOrganizationTypes
      });
      Scaffold.content.show(
        new NonDepEntityEditBooksAndRecordsView({
          model: new Backbone.Model(data)
        })
      );
      NProgress.done();
    })
    .fail(function() {
      NProgress.done();
    });
};
