/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var _get = require('lodash.get');
var momentUtil = require('common/util/momentUtil');

module.exports = function(formData) {
  var filingId = formData.filingId;
  var pointOfContact = formData.pointOfContact;
  var lawyerExternalUserId = _get(formData, 'locationActivitiesSubmissionLawyer.lawyerExternalUserId');
  var locationActivities = formData.allLocationActivities;

  var publicationRequirements = formData.publicationRequirements;
  var boardResolution = formData.boardResolution;
  var supportingDocuments = formData.supportingDocuments || [];
  var certification = formData.bankLocationActivitiesCertification || {};

  var formOutboundDateConfig = {
    sourceFormat: 'MM/DD/YYYY',
    targetFormat: 'YYYY-MM-DD',
    default: ''
  };

  _.each(locationActivities, function(locationActivity) {
    locationActivity.anticipatedOpeningDate = momentUtil.formatStr(
      locationActivity.anticipatedOpeningDate,
      formOutboundDateConfig
    );
    locationActivity.anticipatedClosingDate = momentUtil.formatStr(
      locationActivity.anticipatedClosingDate,
      formOutboundDateConfig
    );
    locationActivity.discontinuanceDate = momentUtil.formatStr(
      locationActivity.discontinuanceDate,
      formOutboundDateConfig
    );
    locationActivity.anticipatedRecoupDate = momentUtil.formatStr(
      locationActivity.anticipatedRecoupDate,
      formOutboundDateConfig
    );
  });

  return $.soap({
    body: {
      SubmitBankLocationActivitiesFiling: {
        $xmlns: 'http://dobs.pa.gov/dp/filing/bpm/1.0',
        FilingID: filingId,
        LocationActivitiesFilingID: formData.locationActivitiesFilingId,
        PublicationReqsDocID: publicationRequirements.docId,
        PublicationReqsComments: publicationRequirements.publicationReqsComments,
        BoardResolutionDocID: boardResolution.docId,
        BoardResolutionComments: boardResolution.boardResolutionComments,
        IsCertified: certification.isCertified ? '1' : '0',
        CertificationName: certification.certificationName,
        CertificationDocID: certification.certificationDocId,
        PointOfContact: {
          Name: pointOfContact.name,
          Title: pointOfContact.title,
          Phone: pointOfContact.phone,
          Email: pointOfContact.email,
          AddressLine1: pointOfContact.addressLine1,
          AddressLine2: pointOfContact.addressLine2,
          AddressLine3: pointOfContact.addressLine3,
          City: pointOfContact.city,
          State: pointOfContact.state,
          PostalCode: pointOfContact.postalCode,
          County: pointOfContact.county,
          Country: pointOfContact.country
        },
        LawyerExternalUserID: lawyerExternalUserId,
        LocationActivities: (function() {
          if (!locationActivities || locationActivities.length === 0) {
            return {};
          }
          return {
            LocationActivity: _.map(locationActivities, function(locationActivity) {
              return {
                EntityLocationID: locationActivity.entityLocationId,
                LocationActionTypeID: locationActivity.locationActionTypeId,
                LocationTypeID: locationActivity.locationTypeId,
                DiscontinuanceDate: locationActivity.discontinuanceDate,
                AnticipatedOpeningDate: locationActivity.anticipatedOpeningDate,
                AnticipatedClosingDate: locationActivity.anticipatedClosingDate,
                LocationAddress: (function() {
                  if (!locationActivity.locationAddress) {
                    return {};
                  }
                  return {
                    AddressLine1: locationActivity.locationAddress.addressLine1,
                    AddressLine2: locationActivity.locationAddress.addressLine2,
                    AddressLine3: locationActivity.locationAddress.addressLine3,
                    County: locationActivity.locationAddress.county,
                    City: locationActivity.locationAddress.city,
                    State: locationActivity.locationAddress.state,
                    PostalCode: locationActivity.locationAddress.postalCode,
                    Country: locationActivity.locationAddress.country
                  };
                })(),
                LocationContactFirstName: locationActivity.locationContactFirstName,
                LocationContactLastName: locationActivity.locationContactLastName,
                LocationContactMiddleInitial: locationActivity.locationContactMiddleInitial,
                LocationContactTitle: locationActivity.locationContactTitle,
                LocationContactAddress: (function() {
                  if (!locationActivity.contactAddress) {
                    return {};
                  }
                  return {
                    AddressLine1: locationActivity.contactAddress.addressLine1,
                    AddressLine2: locationActivity.contactAddress.addressLine2,
                    AddressLine3: locationActivity.contactAddress.addressLine3,
                    County: locationActivity.contactAddress.county,
                    City: locationActivity.contactAddress.city,
                    State: locationActivity.contactAddress.state,
                    PostalCode: locationActivity.contactAddress.postalCode,
                    Country: locationActivity.contactAddress.country
                  };
                })(),
                LocationContactPhone: locationActivity.locationContactPhone,
                LocationContactEmail: locationActivity.locationContactEmail,
                IsBranchAcquired: locationActivity.isBranchAcquired,
                SellingEntityName: locationActivity.sellingEntityName,
                SellingEntityAddress: (function() {
                  if (!locationActivity.sellingAddress) {
                    return {};
                  }
                  return {
                    AddressLine1: locationActivity.sellingAddress.addressLine1,
                    AddressLine2: locationActivity.sellingAddress.addressLine2,
                    AddressLine3: locationActivity.sellingAddress.addressLine3,
                    County: locationActivity.sellingAddress.county,
                    City: locationActivity.sellingAddress.city,
                    State: locationActivity.sellingAddress.state,
                    PostalCode: locationActivity.sellingAddress.postalCode,
                    Country: locationActivity.sellingAddress.country
                  };
                })(),
                ForeignBranchDocID: locationActivity.foreignBranchDocId,
                ForeignBranchComments: locationActivity.foreignBranchComments,
                FixedAssetsDocID: locationActivity.fixedAssetsDocId,
                FixedAssetsComments: locationActivity.fixedAssetsComments,
                NoticeCertClosingBranchDocID: locationActivity.noticeCertClosingBranchDocId,
                NoticeCertClosingBranchComments: locationActivity.closingNoticeComments,
                QuestionnaireDocID: locationActivity.questionnaireDocId,
                QuestionnaireComments: locationActivity.questionnaireComments,
                TransferredToLocationID: locationActivity.transferredToLocationId,
                IsOperationTransferred: locationActivity.isOperationTransferred,
                IsTransferredToNewLocation: locationActivity.isTransferredToNewLocation,
                BasisForDiscontinuance: locationActivity.basisForDiscontinuance,
                DecisionBasis: locationActivity.decisionBasis,
                HasTempQuarters: locationActivity.hasTempQuarters,
                TempFacilityDetails: locationActivity.tempFacilityDetails,
                RelatedCosts: locationActivity.relatedCosts,
                AnticipatedRecoupDate: locationActivity.anticipatedRecoupDate,
                LeasedPremise: locationActivity.leasedPremise,
                HasRelatedInterest: locationActivity.hasRelatedInterest,
                HasInsiderInterest: locationActivity.hasInsiderInterest,
                InsiderNameAndRelationship: locationActivity.insiderNameAndRelationship,
                StepsTaken: locationActivity.stepsTaken,
                TransactionApprovingDocID: locationActivity.transactionApprovingDocId,
                SecurityPrecautions: locationActivity.securityPrecautions,
                InsuranceCoverage: locationActivity.insuranceCoverage,
                ApprovalRequired: locationActivity.approvalRequired,
                MobileTransactionApprovingDocID: locationActivity.mobileTransactionApprovingDocId,
                LpoInstitution: locationActivity.lpoInstitution,
                LpoAddressPhone: locationActivity.lpoAddressPhone,
                LpoActivities: locationActivity.lpoActivities,
                ShareOffice: locationActivity.shareOffice,
                ShareOfficeExplanation: locationActivity.shareOfficeExplanation,
                LpoEmployees: locationActivity.lpoEmployees,
                EmploymentContracts: locationActivity.employmentContracts,
                EmploymentContractDocID: locationActivity.employmentContractDocId,
                NewMarketCompliance: locationActivity.newMarketCompliance,
                LeaseAgreement: locationActivity.leaseAgreement,
                LeaseAgreementDocID: locationActivity.leaseAgreementDocId,
                BusinessPlan: locationActivity.businessPlan,
                FinancialStatement1DocID: locationActivity.financialStatement1DocId,
                FinancialStatement2DocID: locationActivity.financialStatement2DocId,
                FinancialStatement3DocID: locationActivity.financialStatement3DocId,
                LegalOpinion: locationActivity.legalOpinion,
                LpoCondition: locationActivity.lpoCondition,
                AdoptedAuthorizingDocID: locationActivity.adoptedAuthorizingDocId,
                AuthorizingDocID: locationActivity.authorizingDocId,
                AuthorizingDocumentComments: locationActivity.authorizingDocumentComments,
                HasRelatedInterestMobile: locationActivity.hasRelatedInterestMobile,
                IsFullService: locationActivity.isFullService,
                IsLimitedService: locationActivity.isLimitedService,
                AcqLimitedServiceExplanation: locationActivity.acqLimitedServiceExplanation,
                AcqBasis: locationActivity.acqBasis,
                AcqAnticipatedClosingDate: locationActivity.acqAnticipatedClosingDate,
                AcqAnticipatedOpeningDate: locationActivity.acqAnticipatedOpeningDate,
                AcqCosts: locationActivity.acqCosts,
                AcqRecoup: locationActivity.acqRecoup,
                AcqLeaseTerms: locationActivity.acqLeaseTerms,
                AcqHasRelatedInterest: locationActivity.acqHasRelatedInterest,
                AcqHasInsiderInterest: locationActivity.acqHasInsiderInterest,
                AcqInsiderNameAndRelationship: locationActivity.acqInsiderNameAndRelationship,
                AcqStepsTaken: locationActivity.acqStepsTaken,
                AcqPurchaseAgreementDocID: locationActivity.acqPurchaseAgreementDocId,
                AcqStatementDocID: locationActivity.acqStatementDocId,
                AcqTransactionApprovingDocID: locationActivity.acqTransactionApprovingDocId,
                AnticipatedOpeningDates: locationActivity.anticipatedOpeningDates,
                ApprovalRequiredDocIDs: (function() {
                  if (!_get(locationActivity, 'approvalRequiredDocIds.length')) {
                    return {};
                  }
                  return {
                    Document: _.map(locationActivity.approvalRequiredDocIds, function(approvalRequiredDoc) {
                      return { DocumentID: approvalRequiredDoc.fileId };
                    })
                  };
                })()
              };
            })
          };
        })(),
        SupportingDocuments: (function() {
          if (!supportingDocuments.length) {
            return {};
          }
          return {
            Document: _.map(supportingDocuments, function(supportingDocument) {
              return {
                DocumentID: supportingDocument.fileId
              };
            })
          };
        })()
      }
    }
  });
};
