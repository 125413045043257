/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var Backbone = require('backbone');
var Marionette = require('backbone.marionette');
var Syphon = require('backbone.syphon');
var Ladda = require('ladda');
var Radio = require('backbone.radio');
var moment = require('moment');

var Session = require('psa-core/Session');

var modelGet = require('common/util/modelGet');
var toggleForm = require('common/util/toggleForm');
var ContentServerEditorLinkView = require('common/views/ContentServerEditorLinkView');

var SubmitReviewFilingTask = require('../../services/SubmitReviewFilingTask');

var tmplDepReviewerReviewFilingWorkTabView = require('./DepReviewerReviewFilingWorkTabView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplDepReviewerReviewFilingWorkTabView,

  templateHelpers: function() {
    // All reviewed filings will have at least a comment or a recommendation.
    // Show read-only previous review recommendation field for all tasks
    // that are not the first review, even if the review recommendation is blank
    var filingDetails = this.model.get('filingDetails') || {};
    var filingTypeId = filingDetails.filingTypeId;
    // Filing type is CU Audit, Trust Audit or Trust Call Report
    // Filing type is CU Audit, Trust Audit, Trust Call Report or CIF/CTF
    var isSubmission = _.includes(['111', '205', '305', '306', '307'], filingTypeId);
    var isCurrentReviewerTemp = filingDetails.isCurrentReviewerTemp === '1';
    var isSubmissionTempReviewer = isSubmission && isCurrentReviewerTemp;

    return {
      hasPreviousReview: (filingDetails.reviewComments || filingDetails.reviewRecommendation) && !isSubmission,
      isSubmission: isSubmission,
      isSubmissionTempReviewer: isSubmissionTempReviewer
    };
  },

  className: 'reviewer-review-filing-work-tab-view container-fluid',

  ui: {
    reviewDecisionForm: '.review-decision-form',
    reviewAction: '.review-action',
    reviewerFormGroup: '.reviewer-form-group',
    actionFormGroup: '.action-form-group',
    recommendationOptionalFormGroup: '.recommendation-optional-form-group',
    recommendationRequiredFormGroup: '.recommendation-required-form-group',
    recommendation: '.recommendation-id',
    commentsRequiredFormGroup: '.comments-required-form-group',
    commentsOptionalFormGroup: '.comments-optional-form-group',
    comments: '.comments',
    reviewRolesCheckboxes: 'input[name=reviewRoles]',
    filingReviewerRole: '.filing-reviewer-role',
    adhocLegalReviewerRole: '.adhoc-legal-reviewer-role',
    checklistLinkContainer: '.checklist-link-container',
    analysisMemoLinkContainer: '.analysis-memo-link-container',
    decisionLetterLinkContainer: '.decision-letter-link-container',
    checklistLinkSection: '.checklist-link-section',
    analysisMemoLinkSection: '.analysis-memo-link-section',
    decisionLetterLinkSection: '.decision-letter-link-section',
    documentsSection: '.documents-section',
    submit: '.submit',
    isFiling: '.is-filing',
    notifyEneity: '.notify-eneity',
    isNotifyEntityCheckbox: 'input[name="isNotifyEntity"]',
    commentsReworkFormGroup: '.comments-rework-form-group'
  },

  regions: {
    checklistLinkContainer: '@ui.checklistLinkContainer',
    analysisMemoLinkContainer: '@ui.analysisMemoLinkContainer',
    decisionLetterLinkContainer: '@ui.decisionLetterLinkContainer'
  },

  events: {
    'change @ui.reviewAction': 'onChangeReviewAction',
    'click @ui.submit': 'onClickSubmit'
  },

  onBeforeRender: function() {
    var filingDetails = this.model.get('filingDetails') || {};
    var reviewRoles = this.model.get('reviewRoles');
    var filingReviewDecisions = this.model.get('filingReviewDecisions');
    var recommendationLabel = 'Recommendation';
    var filingTypeId = filingDetails.filingTypeId;
    // Filing type is CU Audit, Trust Audit, Trust Call Report or CIF/CTF
    var isSubmission = _.includes(['111', '205', '305', '306', '307'], filingTypeId);
    var isCIFCTFSubmission = _.includes(['111', '305'], filingTypeId);

    var currentReviewerRoleTypeId = filingDetails.currentReviewerRoleTypeId;
    var currentReviewerRoleIndex = _.findIndex(reviewRoles, { reviewRoleId: currentReviewerRoleTypeId });
    var indexOfReturnToPreviousReviewer = _.findIndex(filingReviewDecisions, { id: '1' });
    var indexOfSendToNextReviewer = _.findIndex(filingReviewDecisions, { id: '2' });
    var isCurrentReviewerLegal = filingDetails.isCurrentReviewerLegal === '1';
    var isCurrentReviewerTemp = filingDetails.isCurrentReviewerTemp === '1';
    var isFirstReviewer = filingDetails.currentReviewerRoleTypeId === _.first(reviewRoles).reviewRoleId;
    var isLastReviewer = filingDetails.currentReviewerRoleTypeId === _.last(reviewRoles).reviewRoleId;

    if (isCIFCTFSubmission) {
      // filingReviewDecisions = _.where(filingReviewDecisions, { isSubmission: '1' });
      filingReviewDecisions.splice(0, 5);
    } else if (isSubmission) {
      filingReviewDecisions.splice(0, 5);
      filingReviewDecisions.splice(-1);
    } else {
      // filingReviewDecisions = _.where(filingReviewDecisions, { isSubmission: '0' });
      filingReviewDecisions.splice(-4, 4);

      // Check if a previous review role exists, and if so, set the decision text
      // to include the name of the previous role.
      if (currentReviewerRoleIndex > 0) {
        var previousReviewerRoleIndex = currentReviewerRoleIndex - 1;
        var previousReviewerRole = reviewRoles[previousReviewerRoleIndex].role;
        filingReviewDecisions[indexOfReturnToPreviousReviewer].decision = 'Return to ' + previousReviewerRole;
      }

      // Check if a next review role exists, and if so, set the decision text
      // to include the name of the next role.
      if (currentReviewerRoleIndex < reviewRoles.length - 1) {
        var nextReviewerRoleIndex = currentReviewerRoleIndex + 1;
        var nextReviewerRole = reviewRoles[nextReviewerRoleIndex].role;
        filingReviewDecisions[indexOfSendToNextReviewer].decision = 'Send to ' + nextReviewerRole;
      }

      // Check if first reviewer in sequence, and remove the return to pervious
      // reviewer option if true.
      if (isFirstReviewer) {
        filingReviewDecisions.splice(indexOfReturnToPreviousReviewer, 1);
        // Update index of send to next reviewer after modifying the array
        indexOfSendToNextReviewer = _.findIndex(filingReviewDecisions, { id: '2' });
      }

      // Check if last reviewer in sequence (id = 2), and rename send to next
      // reviewer option to "Send to Final Processor" if true.
      if (isLastReviewer && !isCurrentReviewerLegal && !isCurrentReviewerTemp) {
        filingReviewDecisions[indexOfSendToNextReviewer].decision = 'Send to Final Processing';
        recommendationLabel = 'Decision';
      }
    }

    this.model.set('recommendationLabel', recommendationLabel);
  },

  onBeforeShow: function() {
    var filingDetails = this.model.get('filingDetails') || {};
    var isCurrentReviewerLegal = filingDetails.isCurrentReviewerLegal === '1';
    var isCurrentReviewerTemp = filingDetails.isCurrentReviewerTemp === '1';
    var filingTypeId = filingDetails.filingTypeId;
    // Filing type is CU Audit, Trust Audit, Trust Call Report or CIF/CTF
    var isSubmission = _.includes(['111', '205', '305', '306', '307'], filingTypeId);

    // Show current review role as DP - Legal if ad hoc legal
    // Don't show at all if assigned directly to temp reviewer
    if (isCurrentReviewerLegal) {
      this.ui.adhocLegalReviewerRole.show();
    } else if (!isCurrentReviewerTemp) {
      this.ui.filingReviewerRole.show();
    }

    // Hide Review Roles sections for submissions
    toggleForm(this.ui.isFiling, !isSubmission);

    // Hide Action dropdown if AdHoc Legal or Temp Reviewer

    if (isCurrentReviewerLegal || isCurrentReviewerTemp) {
      toggleForm(this.ui.actionFormGroup, false);
    }

    _.each(
      modelGet(this.model, 'reviewRoles'),
      function(role) {
        this.ui.reviewRolesCheckboxes.filter('input[value=' + role.reviewRoleId + ']').prop('checked', true);
      }.bind(this)
    );

    var reviewComments = filingDetails.reviewComments;
    if (reviewComments) {
      reviewComments = '\n\n' + reviewComments;
    } else {
      reviewComments = '';
    }

    this.ui.comments.val(reviewComments);

    this.showChildView(
      'checklistLinkContainer',
      new ContentServerEditorLinkView({
        model: new Backbone.Model({
          linkText: 'Edit Checklist',
          contentServerId: filingDetails.checklistCSId
        })
      })
    );

    this.showChildView(
      'analysisMemoLinkContainer',
      new ContentServerEditorLinkView({
        model: new Backbone.Model({
          linkText: 'Edit Analysis Memo',
          contentServerId: filingDetails.analysisMemoCSId
        })
      })
    );

    this.showChildView(
      'decisionLetterLinkContainer',
      new ContentServerEditorLinkView({
        model: new Backbone.Model({
          linkText: 'Edit Decision Letter',
          contentServerId: filingDetails.decisionLetterCSId
        })
      })
    );

    var showChecklist = filingDetails.checklistCSId;
    var showAnalysisMemo = filingDetails.analysisMemoCSId;
    var showDecisionLetter = filingDetails.decisionLetterCSId;

    if (!showChecklist) {
      this.ui.checklistLinkSection.hide();
    }
    if (!showAnalysisMemo) {
      this.ui.analysisMemoLinkSection.hide();
    }
    if (!showDecisionLetter) {
      this.ui.decisionLetterLinkSection.hide();
    }
    if (!showChecklist && !showAnalysisMemo && !showDecisionLetter) {
      this.ui.documentsSection.hide();
    }

    this.onChangeReviewAction();
  },

  onChangeReviewAction: function() {
    var reviewAction = this.ui.reviewAction.val();
    this.toggleReviewForm(reviewAction);
  },

  toggleReviewForm: function(reviewActionId) {
    var sendToPrevious = reviewActionId === '1';
    var sendToNext = reviewActionId === '2';
    var sendToLegal = reviewActionId === '3';
    var sendToAnother = reviewActionId === '4' || reviewActionId === '12';
    var needInfo = reviewActionId === '5';
    var closeComplete = reviewActionId === '13';
    var sendForRework = reviewActionId === '14';

    var filingDetails = this.model.get('filingDetails') || {};
    var filingTypeId = filingDetails.filingTypeId;
    // Filing type is CU Audit, Trust Audit, Trust Call Report or CIF/CTF
    var isSubmission = _.includes(['111', '205', '305', '306', '307'], filingTypeId);
    var isCIFCTFFiling = _.includes(['111', '305'], filingTypeId);

    // Only show Reviewer field when decision is Send to Another Reviewer
    toggleForm(this.ui.reviewerFormGroup, sendToAnother);

    // Only show Notify Entity field when decision is Close/Complete and is CIF/CTF Submission Filing
    toggleForm(this.ui.notifyEneity, isCIFCTFFiling && closeComplete);

    // Only show External Comments field when decision is Send for Rework and is CIF/CTF Submission Filing
    toggleForm(this.ui.commentsReworkFormGroup, isCIFCTFFiling && sendForRework);

    // When form is toggled, a separate, different input field for recommendation and comments
    // with correct requirements is shown.
    // Store and repopulate value to prevent dispruption to user.
    var currentRecommendation = this.ui.recommendation.val();
    if (isSubmission) {
      toggleForm(this.ui.recommendationOptionalFormGroup, false);
      toggleForm(this.ui.recommendationRequiredFormGroup, false);
    } else {
      toggleForm(this.ui.recommendationOptionalFormGroup, !sendToNext);
      toggleForm(this.ui.recommendationRequiredFormGroup, sendToNext);
      this.ui.recommendation.val(currentRecommendation);
    }

    var currentReviewComments = this.ui.comments.val();
    var commentsRequired = sendToPrevious || sendToLegal || needInfo || sendToAnother;
    toggleForm(this.ui.commentsOptionalFormGroup, !commentsRequired);
    toggleForm(this.ui.commentsRequiredFormGroup, commentsRequired);
    this.ui.comments.val(currentReviewComments);
  },

  onClickSubmit: function(e) {
    e.preventDefault();
    var self = this;
    this.validate().done(function() {
      var l = Ladda.create(self.ui.submit[0]);
      l.start();
      SubmitReviewFilingTask(self.getFormData())
        .done(function() {
          Radio.channel('navigate').trigger('show:int:dp:dashboard');
        })
        .fail(function() {
          l.stop();
        });
    });
  },

  validate: function() {
    return this.ui.reviewDecisionForm.parsley().whenValidate();
  },

  getFormData: function() {
    var filingDetails = this.model.get('filingDetails') || {};
    var timestamp = moment().format('MM/DD/YYYY h:mm A');
    var userName = Session.user.description;

    var formData = Syphon.serialize(this);
    _.extend(formData, {
      taskId: this.model.get('taskId'),
      filingId: this.model.get('filingId')
    });

    formData.reviewComments = '[' + userName + ' - ' + timestamp + '] \n' + formData.reviewComments;

    // Review decision is always Return to Previous when AdHoc legal or Temp Review
    var isCurrentReviewerLegal = filingDetails.isCurrentReviewerLegal === '1';
    var isCurrentReviewerTemp = filingDetails.isCurrentReviewerTemp === '1';
    if (isCurrentReviewerLegal || isCurrentReviewerTemp) {
      formData.reviewDecisionId = '1';
    }

    return formData;
  }
});
