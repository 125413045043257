/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var Marionette = require('backbone.marionette');

var GridView = require('psa-marionette/views/GridView');
var UIUtil = require('psa-core/util/ui');
var PageableCollection = require('psa-backbone/models/PageableCollection');

var MomentCell = require('common/backgrid/MomentCell');
var ButtonLinkCell = require('common/backgrid/ButtonLinkCell');
var ConfirmationModalView = require('common/views/ConfirmationModalView');

var SubmitEntityRiskMatrix = require('../../tracking-dashboard/services/SubmitEntityRiskMatrix');

var tmplDepEntityTrackingSectionView = require('../templates/DepEntityTrackingSectionView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplDepEntityTrackingSectionView,

  className: 'dep-entity-tracking-section-view',

  ui: {
    trackingsList: '.tracking-list-container',
    riskMatricesList: '.risk-matrix-list-container',
    thirdPartyVendorList: '.third-party-vendor-list-container'
  },

  regions: {
    trackingsList: '@ui.trackingsList',
    riskMatricesList: '@ui.riskMatricesList',
    thirdPartyVendorList: '@ui.thirdPartyVendorList'
  },

  onBeforeShow: function() {
    var trackings = _.filter(this.model.get('tracking'), function(t) {
      return _.includes(['1', '2', '3', '4', '6', '11', '12', '13', '21', '23'], t.trackingTypeId);
    });
    var keyThirdPartyVendors = _.filter(this.model.get('tracking'), function(t) {
      return _.includes(['5', '14', '22'], t.trackingTypeId);
    });
    // Hide inactive third party vendors
    keyThirdPartyVendors = _.filter(keyThirdPartyVendors, function(t) {
      return t.isActive !== '0';
    });

    this.trackingsGrid = new GridView({
      columns: [
        {
          name: 'trackingType',
          label: 'Tracking Type',
          cell: 'string',
          headerCell: 'custom',
          width: 20,
          editable: false
        },
        {
          name: 'enteredDate',
          label: 'Date Entered',
          cell: MomentCell.MomentCell,
          formatter: MomentCell.MomentDateFormatter.extend({
            emptyValue: '--'
          }),
          sortValue: MomentCell.MomentSortValue,
          headerCell: 'custom',
          width: 15,
          editable: false
        },
        {
          name: 'trackingSource',
          label: 'Source',
          cell: 'string',
          headerCell: 'custom',
          width: 15,
          editable: false
        },
        {
          name: 'comments',
          label: 'Comments',
          cell: 'string',
          headerCell: 'custom',
          width: 40,
          editable: false
        },
        {
          name: '',
          label: '',
          cell: ButtonLinkCell.extend({
            name: 'Open',
            buttonClass: 'btn-primary btn-sm',
            href: function(model) {
              var entityId = model.get('entityId');
              var trackingId = model.get('id');
              return '#dep/entity/' + entityId + '/tracking/' + trackingId + '/dashboard';
            }
          }),
          headerCell: 'custom',
          width: 10,
          editable: false
        }
      ],
      collection: new PageableCollection(trackings, { pageSize: 10 }),
      emptyText: 'No Tracking'
    });
    this.showChildView('trackingsList', this.trackingsGrid);

    this.riskMatrixGrid = new GridView({
      columns: [
        {
          name: 'riskType',
          label: 'Type Of Risk',
          cell: 'string',
          editable: false,
          headerCell: 'custom',
          width: 20
        },
        {
          name: 'asOfDate',
          label: 'As Of Date',
          cell: MomentCell.MomentCell,
          formatter: MomentCell.MomentDateFormatter.extend({
            emptyValue: '--'
          }),
          sortValue: MomentCell.MomentSortValue,
          editable: false,
          headerCell: 'custom',
          width: 15
        },
        {
          name: 'examStartDate',
          label: 'Exam Start Date',
          cell: MomentCell.MomentCell,
          formatter: MomentCell.MomentDateFormatter.extend({
            emptyValue: '--'
          }),
          sortValue: MomentCell.MomentSortValue,
          editable: false,
          headerCell: 'custom',
          width: 17.5
        },
        {
          name: 'riskAssessment',
          label: 'Risk Assessment',
          cell: 'string',
          editable: false,
          headerCell: 'custom',
          width: 17.5
        },
        {
          name: 'riskDirection',
          label: 'Direction Of Risk',
          cell: 'string',
          editable: false,
          headerCell: 'custom',
          width: 17.5
        },
        {
          name: '',
          label: '',
          cell: ButtonLinkCell.extend({
            name: 'Open',
            buttonClass: 'btn-primary btn-sm',
            href: function(model) {
              var entityId = model.get('entityId');
              var riskMatrixId = model.get('id');
              return '#dep/entity/' + entityId + '/risk-matrix/' + riskMatrixId + '/dashboard';
            }
          }),
          headerCell: 'custom',
          width: 10,
          editable: false
        }
      ],
      collection: new PageableCollection(this.model.get('riskMartrix'), { pageSize: 10 }),
      emptyText: 'No Risk Matrices'
    });
    this.showChildView('riskMatricesList', this.riskMatrixGrid);

    this.keyThirdPartyVendorsGrid = new GridView({
      columns: [
        {
          name: 'vendorName',
          label: 'Vendor Name',
          cell: 'string',
          headerCell: 'custom',
          width: 20,
          editable: false
        },
        {
          name: 'vendorService',
          label: 'Vendor Service',
          cell: 'string',
          headerCell: 'custom',
          width: 70,
          editable: false
        },

        {
          name: '',
          label: '',
          cell: ButtonLinkCell.extend({
            name: 'Open',
            buttonClass: 'btn-primary btn-sm',
            href: function(model) {
              var entityId = model.get('entityId');
              var vendorId = model.get('id');
              return '#dep/entity/' + entityId + '/key-third-party-vendor/' + vendorId + '/dashboard';
            }
          }),
          headerCell: 'custom',
          width: 10,
          editable: false
        }
      ],
      collection: new PageableCollection(keyThirdPartyVendors, { pageSize: 10 }),
      emptyText: 'No Key Third-Party Vendors'
    });
    this.showChildView('thirdPartyVendorList', this.keyThirdPartyVendorsGrid);
  },

  onClickDelete: function(e, model) {
    e.preventDefault();

    UIUtil.showModal({
      title: 'Confirm Deleting Risk Matrix',
      view: new ConfirmationModalView({
        confirmText: 'Delete Risk Matrix',
        model: new Backbone.Model({
          message: 'Are you sure you want to delete this risk matrix?',
          onConfirm: function() {
            return SubmitEntityRiskMatrix({
              id: model.get('id'),
              shouldDelete: '1'
            });
          },
          onConfirmDone: function() {
            this.riskMatrixGrid.collection.remove(model);
          }.bind(this)
        })
      })
    });
  }
});
