/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var Marionette = require('backbone.marionette');
var Syphon = require('backbone.syphon');
var DeepCollection = require('psa-backbone/models/DeepCollection');

var GridView = require('psa-marionette/views/GridView');
var NonDepSelectRowCell = require('../../common/backgrid/NonDepSelectRowCell');
var FileUploadCell = require('common/backgrid/FileUploadCell');

var tmplLicenseDBAsSectionView = require('../templates/LicenseDBAsSectionView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplLicenseDBAsSectionView,

  className: 'license-dbas-section-view-2',

  ui: {
    dbaForm: '.dba-form',
    dbas: '.dbas'
  },

  regions: {
    dbaForm: '@ui.dbaForm',
    dbas: '@ui.dbas'
  },

  onBeforeShow: function() {
    var checkedEntityDbas = _.pluck(this.model.get('applicationDBAs'), 'entityDBAId');
    var entityDBAs = _.map(this.model.get('entityDBAs'), function(dba) {
      dba.isChecked = _.includes(checkedEntityDbas, dba.id);
      return dba;
    });

    this.dbaGridView = new GridView({
      columns: [
        {
          name: 'isChecked',
          label: '',
          cell: NonDepSelectRowCell.SelectRowCell,
          headerCell: NonDepSelectRowCell.SelectAllHeaderCell,
          width: 10,
          editable: true,
          sortable: false
        },
        {
          name: 'dba',
          label: 'Name',
          cell: 'string',
          headerCell: 'custom',
          width: 40,
          editable: false
        },
        {
          name: 'fictitiousNameDocument',
          label: 'Fictitious Name Registration Document',
          cell: FileUploadCell.extend({
            isReadOnly: true
          }),
          headerCell: 'custom',
          width: 50,
          editable: false,
          sortable: false
        }
      ],
      collection: new DeepCollection(entityDBAs),
      emptyText: 'Entity has no D/B/A registrations.'
    });
    this.showChildView('dbas', this.dbaGridView);
  },

  getFormData: function() {
    var formData = Syphon.serialize(this);
    var selectedModel = this.dbaGridView.viewGrid.getSelectedModels();

    var entityDBAIds = _.map(selectedModel, function(dba) {
      return dba.get('id');
    });
    formData.entityDBAIds = entityDBAIds;
    return formData;
  }
});
