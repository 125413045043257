/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Marionette = require('backbone.marionette');
var Syphon = require('backbone.syphon');

var tmplDepCallReportDetailsSectionView = require('../templates/DepCallReportDetailsSectionView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplDepCallReportDetailsSectionView,

  className: 'dep-call-report-details-section-view',

  templateHelpers: function() {
    var categoryId = this.model.get('entityIdentifiers').categoryId;
    var callReportTypeId = this.model.get('callReportTypeId');
    var callReportTrustTypeId = this.model.get('callReportTrustTypeId');
    var opinionId = this.model.get('opinionId');

    var isBank = categoryId === '1';
    var isTrust = categoryId === '3';
    var internalCallReport = opinionId === '2' || callReportTrustTypeId === '2';

    var showTrustCallReportType =
      callReportTypeId === '22' ||
      (categoryId === '1' && callReportTypeId === '3') ||
      (categoryId === '3' && callReportTypeId === '22');

    var showOpinionDetails = isTrust || showTrustCallReportType;

    var showOpinion = !isTrust;
    if (!isTrust) {
      showOpinion = !showOpinionDetails;
    }

    var showManagementLetter;
    var showResponseReceived;

    if ((isBank && callReportTypeId === '1') || (isTrust && callReportTypeId === '21')) {
      showOpinion = true;
      showManagementLetter = true;
      showResponseReceived = true;
      showTrustCallReportType = false;
      showOpinionDetails = false;
    }

    if (isBank && callReportTypeId === '2') {
      showResponseReceived = true;
    }

    return {
      isTrust: isTrust,
      internalCallReport: internalCallReport,
      showTrustCallReportType: showTrustCallReportType,
      showOpinionDetails: showOpinionDetails,
      showOpinion: showOpinion,
      showManagementLetter: showManagementLetter,
      showResponseReceived: showResponseReceived
    };
  },

  onBeforeShow: function() {
    Syphon.deserialize(this, this.model.toJSON());
  }
});
