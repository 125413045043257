/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');

var cleanUpResponse = require('common/util/cleanUpResponse');

module.exports = function(recordId) {
  var deferred = $.Deferred();

  $.soap({
    body: {
      GetSecondaryNamesByRecord: {
        $xmlns: 'http://dobs.pa.gov/cf/record/bpm/1.0',
        RecordID: recordId
      }
    },
    blueprintArrays: ['GetSecondaryNamesByRecordResponse.CF_SecondaryName'],
    blueprint: {
      secondaryNames: [
        '$..CF_SecondaryName',
        {
          id: '$..ID',
          lastName: '$..LastName',
          firstName: '$..FirstName',
          middleInitial: '$..MiddleInitial',
          suffix: '$..Suffix',
          title: '$..Title',
          typeId: '$..TypeID',
          type: '$..Type',
          isDeleted: '$..IsDeleted',
          createdDate: '$..CreatedDate',
          createdBy: '$..CreatedBy',
          modifiedDate: '$..ModifiedDate',
          modifiedBy: '$..ModifiedBy'
        }
      ]
    }
  })
    .done(function(response) {
      cleanUpResponse(response);

      _.each(response.secondaryNames, function(secondaryName) {
        secondaryName.displayName = secondaryName.lastName + ', ' + secondaryName.firstName;
        if (secondaryName.middleInitial) {
          secondaryName.displayName += ' ' + secondaryName.middleInitial;
        }
        if (secondaryName.suffix) {
          secondaryName.displayName += ' ' + secondaryName.suffix;
        }
        secondaryName.recordId = recordId;

        secondaryName.titleDisplay = secondaryName.title || '--';
        secondaryName.typeDisplay = secondaryName.type || '--';
      });

      deferred.resolve(response.secondaryNames);
    })
    .fail(function(err) {
      deferred.reject();
    });

  return deferred.promise();
};
