/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Marionette = require('backbone.marionette');
var Radio = require('backbone.radio');

var HeaderBarView = require('common/views/HeaderBarView');
var HeaderBarLinkCellView = require('common/views/HeaderBarView/HeaderBarLinkCellView');
var BreadcrumbView = require('common/views/BreadcrumbView');

var SubmitApplicationPaymentView = require('./SubmitApplicationPaymentView');

var SubmitEntitySubmitApplicationTask = require('../../services/SubmitEntitySubmitApplicationTask');

require('./EntitySubmitApplicationTaskView.css');
var tmplEntitySubmitApplicationTaskWithFee = require('./EntitySubmitApplicationTaskWithFee.hbs');
var tmplEntitySubmitApplicationTaskWithoutFee = require('./EntitySubmitApplicationTaskWithoutFee.hbs');

module.exports = Marionette.LayoutView.extend({
  getTemplate: function() {
    var applicationCategoryId = this.model.get('applicationDetails').applicationCategoryId;
    var isLicenseRenewalApplication = applicationCategoryId === '2';
    if (isLicenseRenewalApplication) {
      return tmplEntitySubmitApplicationTaskWithFee;
    } else {
      return tmplEntitySubmitApplicationTaskWithoutFee;
    }
  },

  className: 'entity-submit-application-task-view container-fluid',

  ui: {
    headerBar: '.header-bar',
    breadcrumb: '.breadcrumb-container',
    applicationTabPane: '.application-tab-pane',
    applicationContainer: '.application-container',
    paymentTabPane: '.payment-tab-pane',
    paymentContainer: '.payment-container',
    paymentTab: '.payment-tab'
  },

  regions: {
    headerBar: '@ui.headerBar',
    breadcrumb: '@ui.breadcrumb',
    applicationTabPane: '@ui.applicationTabPane',
    applicationContainer: '@ui.applicationContainer',
    paymentTabPane: '@ui.paymentTabPane',
    paymentContainer: '@ui.paymentContainer'
  },

  onBeforeShow: function() {
    var self = this;
    var entityId = this.model.get('applicationDetails').entityId;
    var entityName = this.model.get('applicationDetails').entityName;
    var applicationTypeDescription = this.model.get('applicationDetails').applicationTypeDescription;

    var headerBarColumns = [
      {
        name: 'entityName',
        label: 'Entity Name',
        minWidth: '160px',
        maxWidth: '300px'
      },
      {
        name: 'tin',
        label: 'Federal ID Number',
        minWidth: '160px'
      },
      {
        name: 'applicationStatus',
        label: 'Application Status',
        minWidth: '160px',
        maxWidth: '200px',
        overflow: 'truncate'
      },
      {
        name: 'webAddress',
        label: 'Web Address',
        width: '350px',
        overflow: 'truncate',
        headerCell: HeaderBarLinkCellView
      }
    ];
    // If the application contains details about a specific location, add that column
    if (!this.model.get('applicationDetails').hasLocation) {
      headerBarColumns.splice(3, 0, {
        name: 'locationName',
        label: 'Location',
        minWidth: '160px',
        maxWidth: '350px',
        overflow: 'truncate'
      });
    }
    this.showChildView(
      'headerBar',
      new HeaderBarView({
        columns: new Backbone.Collection(headerBarColumns),
        model: new Backbone.Model(this.model.get('applicationDetails'))
      })
    );

    this.showChildView(
      'breadcrumb',
      new BreadcrumbView({
        breadcrumbs: [
          {
            link: '#ext/nondep/dashboard',
            text: 'Home'
          },
          {
            link: '#ext/nondep/entity/' + entityId + '/dashboard',
            text: entityName
          },
          {
            text: 'Submit ' + applicationTypeDescription
          }
        ]
      })
    );
    this.showChildView('applicationContainer', this.options.applicationFormView);

    Radio.channel('nd:submit-application').on('resubmit', function(l) {
      SubmitEntitySubmitApplicationTask({
        taskId: self.model.get('taskId'),
        applicationId: self.model.get('applicationId'),
        applicationDetails: self.model.get('applicationDetails'),
        licenseId: self.model.get('applicationDetails').license.id
      })
        .done(function() {
          Radio.channel('navigate').trigger('show:ext:nd:dashboard');
        })
        .fail(function() {
          l.stop();
        });
    });

    Radio.channel('nd:submit-application').on('proceed-to-payment', function(items) {
      self.showChildView(
        'paymentContainer',
        new SubmitApplicationPaymentView({
          model: new Backbone.Model({
            department: 'NON-DEPOSITORY',
            paymentObjectType: 'APPLICATION',
            paymentObjectId: self.model.get('applicationId'),
            items: items,
            taskId: self.model.get('taskId')
          })
        })
      );
      self.ui.paymentTab.tab('show');
      self.ui.applicationTabPane.removeClass('active');
      self.ui.paymentTabPane.addClass('active');
    });
  },

  onDestroy: function() {
    Radio.channel('nd:submit-application').reset();
  }
});
