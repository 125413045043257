var Handlebars = require("handlebars/dist/handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), alias4=helpers.helperMissing, alias5="function";

  return "\n<div class=\"title-bar clearfix\">\n  <h1 class=\"page-title\">"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.entityIdentifiers : depth0)) != null ? stack1.entityName : stack1), depth0))
    + "</h1>\n</div>\n<div class=\"page-subtitle\">\n  <h2>Select Employer Group</h2>\n  <div class=\"page-subtitle-details\">"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.segIdentifiers : depth0)) != null ? stack1.name : stack1), depth0))
    + "</div>\n</div>\n<div class=\"breadcrumb-container\"></div>\n<div class=\"row\">\n  <div class=\"col-sm-12 col-md-10\">\n    <div class=\"row\">\n      <div class=\"col-sm-9 col-sm-offset-3\">\n        <h2 class=\"m-t-n\">Edit SEG Location</h2>\n      </div>\n    </div>\n    <form class=\"address\"></form>\n    <div class=\"row m-b-lg\">\n      <div class=\"col-sm-8 col-sm-offset-3\">\n        <a href=\"#dep/entity/"
    + alias2(((helper = (helper = helpers.entityId || (depth0 != null ? depth0.entityId : depth0)) != null ? helper : alias4),(typeof helper === alias5 ? helper.call(alias3,{"name":"entityId","hash":{},"data":data}) : helper)))
    + "/seg/"
    + alias2(((helper = (helper = helpers.segId || (depth0 != null ? depth0.segId : depth0)) != null ? helper : alias4),(typeof helper === alias5 ? helper.call(alias3,{"name":"segId","hash":{},"data":data}) : helper)))
    + "/dashboard\" class=\"btn btn-default\">Cancel</a>\n        <button class=\"btn btn-primary btn-submit\">Submit</button>\n      </div>\n    </div>\n  </div>\n</div>";
},"useData":true});