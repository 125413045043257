var Handlebars = require("handlebars/dist/handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper;

  return container.escapeExpression(((helper = (helper = helpers.reportDate || (depth0 != null ? depth0.reportDate : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"reportDate","hash":{},"data":data}) : helper)));
},"3":function(container,depth0,helpers,partials,data) {
    return "--";
},"5":function(container,depth0,helpers,partials,data) {
    return "Yes";
},"7":function(container,depth0,helpers,partials,data) {
    return "No";
},"9":function(container,depth0,helpers,partials,data) {
    var helper;

  return container.escapeExpression(((helper = (helper = helpers.totalManagedAssetsDisplay || (depth0 != null ? depth0.totalManagedAssetsDisplay : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"totalManagedAssetsDisplay","hash":{},"data":data}) : helper)));
},"11":function(container,depth0,helpers,partials,data) {
    var helper;

  return container.escapeExpression(((helper = (helper = helpers.totalNonManagedAssetsDisplay || (depth0 != null ? depth0.totalNonManagedAssetsDisplay : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"totalNonManagedAssetsDisplay","hash":{},"data":data}) : helper)));
},"13":function(container,depth0,helpers,partials,data) {
    var helper;

  return container.escapeExpression(((helper = (helper = helpers.custodySafekeepingAccountsDisplay || (depth0 != null ? depth0.custodySafekeepingAccountsDisplay : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"custodySafekeepingAccountsDisplay","hash":{},"data":data}) : helper)));
},"15":function(container,depth0,helpers,partials,data) {
    var helper;

  return container.escapeExpression(((helper = (helper = helpers.totalAssetsUnderManagementDisplay || (depth0 != null ? depth0.totalAssetsUnderManagementDisplay : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"totalAssetsUnderManagementDisplay","hash":{},"data":data}) : helper)));
},"17":function(container,depth0,helpers,partials,data) {
    var helper;

  return container.escapeExpression(((helper = (helper = helpers.totalCapitalDisplay || (depth0 != null ? depth0.totalCapitalDisplay : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"totalCapitalDisplay","hash":{},"data":data}) : helper)));
},"19":function(container,depth0,helpers,partials,data) {
    var helper;

  return container.escapeExpression(((helper = (helper = helpers.totalTrustCompanyAssetsDisplay || (depth0 != null ? depth0.totalTrustCompanyAssetsDisplay : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"totalTrustCompanyAssetsDisplay","hash":{},"data":data}) : helper)));
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "\n<div class=\"call-report-form form-horizontal form-static m-t-lg\">\n  <div class=\"row\">\n    <div class=\"col-sm-12 col-md-6\">\n      <div class=\"form-group\">\n        <label class=\"control-label col-sm-5 col-md-4\">Report Date</label>\n        <div class=\"form-control-static col-sm-7 col-md-8\">\n          "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.reportDate : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data})) != null ? stack1 : "")
    + "\n        </div>\n      </div>\n    </div>\n  </div>\n  <div class=\"row\">\n    <div class=\"col-sm-12 col-md-6\">\n      <div class=\"form-group\">\n        <label class=\"control-label col-sm-5 col-md-4\">Amended</label>\n        <div class=\"form-control-static col-sm-7 col-md-8\">\n          "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.isAmended : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.program(7, data, 0),"data":data})) != null ? stack1 : "")
    + "\n        </div>\n      </div>\n    </div>\n  </div>\n  <div class=\"row\">\n    <div class=\"col-sm-12 col-md-6\">\n      <div class=\"form-group\">\n        <label class=\"control-label col-sm-5 col-md-4\">Total Managed Assets</label>\n        <div class=\"form-control-static col-sm-7 col-md-8\">"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.totalManagedAssetsDisplay : depth0),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.program(3, data, 0),"data":data})) != null ? stack1 : "")
    + "</div>\n      </div>\n    </div>\n  </div>\n  <div class=\"row\">\n    <div class=\"col-sm-12 col-md-6\">\n      <div class=\"form-group\">\n        <label class=\"control-label col-sm-5 col-md-4\">Total Non-Managed Assets</label>\n        <div class=\"form-control-static col-sm-7 col-md-8\">"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.totalNonManagedAssetsDisplay : depth0),{"name":"if","hash":{},"fn":container.program(11, data, 0),"inverse":container.program(3, data, 0),"data":data})) != null ? stack1 : "")
    + "\n        </div>\n      </div>\n    </div>\n  </div>\n  <div class=\"row\">\n    <div class=\"col-sm-12 col-md-6\">\n      <div class=\"form-group\">\n        <label class=\"control-label col-sm-5 col-md-4\">Custody and Safekeeping Accounts</label>\n        <div class=\"form-control-static col-sm-7 col-md-8\">"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.custodySafekeepingAccountsDisplay : depth0),{"name":"if","hash":{},"fn":container.program(13, data, 0),"inverse":container.program(3, data, 0),"data":data})) != null ? stack1 : "")
    + "</div>\n      </div>\n    </div>\n  </div>\n  <div class=\"row\">\n    <div class=\"col-sm-12 col-md-6\">\n      <div class=\"form-group\">\n        <label class=\"control-label col-sm-5 col-md-4\">Total Assets Under Management</label>\n        <p class=\"form-control-static col-sm-7 col-md-8\">"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.totalAssetsUnderManagementDisplay : depth0),{"name":"if","hash":{},"fn":container.program(15, data, 0),"inverse":container.program(3, data, 0),"data":data})) != null ? stack1 : "")
    + "</p>\n      </div>\n    </div>\n  </div>\n  <div class=\"row\">\n    <div class=\"col-sm-12 col-md-6\">\n      <div class=\"form-group\">\n        <label class=\"control-label col-sm-5 col-md-4\">Total Capital</label>\n        <div class=\"form-control-static col-sm-7 col-md-8\">"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.totalCapitalDisplay : depth0),{"name":"if","hash":{},"fn":container.program(17, data, 0),"inverse":container.program(3, data, 0),"data":data})) != null ? stack1 : "")
    + "</div>\n      </div>\n    </div>\n  </div>\n  <div class=\"row\">\n    <div class=\"col-sm-12 col-md-6\">\n      <div class=\"form-group\">\n        <label class=\"control-label col-sm-5 col-md-4\">Total Trust Company Assets</label>\n        <div class=\"form-control-static col-sm-7 col-md-8\">"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.totalTrustCompanyAssetsDisplay : depth0),{"name":"if","hash":{},"fn":container.program(19, data, 0),"inverse":container.program(3, data, 0),"data":data})) != null ? stack1 : "")
    + "</div>\n      </div>\n    </div>\n  </div>\n  <div class=\"row\">\n    <div class=\"col-sm-12 col-md-6\">\n      <div class=\"form-group\">\n        <label class=\"control-label col-sm-5 col-md-4\">Comments</label>\n        <p class=\"form-control-static col-sm-7 col-md-8\">"
    + container.escapeExpression((helpers["default"] || (depth0 && depth0["default"]) || helpers.helperMissing).call(alias1,(depth0 != null ? depth0.comments : depth0),"--",{"name":"default","hash":{},"data":data}))
    + "</p>\n      </div>\n    </div>\n  </div>\n</div>";
},"useData":true});