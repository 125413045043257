/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var Backbone = require('backbone');
var Marionette = require('backbone.marionette');
var Radio = require('backbone.radio');
var moment = require('moment');

var UIUtil = require('psa-core/util/ui');

var ContentServerLinkView = require('common/views/ContentServerLinkView');

var SideNavSectionView = require('common/views/SideNavSectionView');
var BreadcrumbView = require('common/views/BreadcrumbView');
var DropdownView = require('common/views/DropdownView');
var ConfirmationModalView = require('common/views/ConfirmationModalView');

var modelGet = require('common/util/modelGet');
var userHasRole = require('common/util/userHasRole');

var ExamHeaderBarView = require('../../common/views/ExamHeaderBarView');
var NonDepExamGeneralInformationSectionView = require('./NonDepExamGeneralInformationSectionView');
var NonDepExamPaymentScheduleSectionView = require('./NonDepExamPaymentScheduleSectionView');
var NonDepExamNotesSectionView = require('./NonDepExamNotesSectionView');
var NonDepExamAuditLogSectionView = require('./NonDepExamAuditLogSectionView');
var CancelExam = require('../services/CancelExam');
var SubmitRequestExamMaterial = require('../services/SubmitRequestExamMaterial');
var SubmitCancelRequestExamMaterial = require('../services/SubmitCancelRequestExamMaterial');

var tmplNonDepInternalExamDashboardView = require('../templates/NonDepInternalExamDashboardView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplNonDepInternalExamDashboardView,

  className: 'non-dep-exam-dashboard-view container-fluid',

  ui: {
    examWrapper: '.exam-wrapper',
    entityActionDropDown: '.dropdown-container',
    breadcrumb: '.breadcrumb-container',
    headerBar: '.header-bar',
    csLinkContainer: '.cs-link-container'
  },

  regions: {
    headerBar: '@ui.headerBar',
    entityActionDropDown: '@ui.entityActionDropDown',
    examWrapper: '@ui.examWrapper',
    breadcrumb: '@ui.breadcrumb',
    csLinkContainer: '@ui.csLinkContainer'
  },

  onBeforeShow: function() {
    var exam = this.model.get('exam') || {};
    var examId = exam.examId;
    var examStatusId = exam.examStatusId;

    var dropDownOptions = [
      {
        text: 'Initiate Correspondence',
        link: '#nondep/dashboard/exam/' + examId + '/initiate-correspondence'
      }
    ];

    // If the exam has the state of either "Pre-Exam Preparation" or
    // "Waiting on Exam", and the exam date is after today, allow the exam to be
    // rescheduled.
    if (_.contains(['4', '5'], examStatusId) && moment(exam.examDate).isAfter()) {
      dropDownOptions.push({
        text: 'Reschedule Exam',
        link: '#nondep/dashboard/exam/' + examId + '/reschedule'
      });
    }

    /**
     * Exam is considered in progress if it has one of the following states:
     * - Pre-Exam Preparation (id: 4)
     * - Waiting on Exam (id: 5)
     * - In Progress (id: 6)
     * - Review & Approval (id: 7)
     * - Violations Resolution (id: 8)
     */
    var hasExamLoanLogs = exam.hasExamLoanLogs;
    var hasExamPolicyProcedures = exam.hasExamPolicyProcedures;
    var examInProgress = _.contains(['4', '5', '6', '7', '8'], examStatusId);

    if (examInProgress && (hasExamLoanLogs || hasExamPolicyProcedures)) {
      dropDownOptions.push({
        text: 'Preview Exam Materials',
        link: '#nondep/exam/' + examId + '/exam-materials'
      });
    }

    // Appears after the Prepare Examination Materials and loan logs task was submitted by the external user
    var roles = _.some(['ND - Exam Director', 'ND - Manager', 'ND - AO1 Bucket 3'], userHasRole);

    if (roles && _.contains(['4', '5', '6'], examStatusId)) {
      switch (exam.isRequestExamMaterial) {
        case '0':
          dropDownOptions.push({
            text: 'Mark Examination Materials as Received',
            onClick: this.onClickCancelRequestExamMaterial.bind(this)
          });
          break;
        case '1':
          dropDownOptions.push({
            text: 'Request Examination Materials',
            onClick: this.onClickRequestExamMaterials.bind(this)
          });
          break;
      }
    }

    // If billing status (9) or (10), allow exam invoice to be changed
    if (_.contains(['9', '10'], examStatusId)) {
      dropDownOptions.push(
        {
          text: 'Update Exam Invoice',
          link: '#nondep/dashboard/exam/' + examId + '/update-payment-schedule'
        },
        {
          text: 'Update Refund Amount',
          link: '#nondep/dashboard/exam/' + examId + '/update-billing-details'
        }
      );
    }

    // Modify Examiner Action is available to Deputy Secretary, Director, and Exam Director
    var modifyExamRoles = _.some(['ND - Licensing Chief', 'ND - Exam Director', 'ND - Director'], userHasRole);
    var isExamInProgress = _.contains(['2', '3', '4', '5', '6', '7', '8', '9'], examStatusId);
    if (modifyExamRoles && isExamInProgress) {
      dropDownOptions.push({
        text: 'Modify Examiner',
        link: '#nondep/dashboard/exam/' + examId + '/modify-examiner'
      });
    }

    var isExamComplete = _.contains(['10', '11'], examStatusId);
    if (roles & !isExamComplete) {
      dropDownOptions.push(
        {
          isDivider: true
        },
        {
          text: 'End Exam Workflow',
          onClick: this.onClickEndExam.bind(this)
        }
      );
    }

    this.showChildView(
      'entityActionDropDown',
      new DropdownView({
        rightAlign: true,
        model: new Backbone.Model({
          name: 'Exam Actions',
          options: dropDownOptions
        })
      })
    );

    this.showChildView(
      'headerBar',
      new ExamHeaderBarView({
        model: new Backbone.Model(_.extend(this.model.get('exam'), { title: 'Exam' }))
      })
    );

    this.showChildView(
      'csLinkContainer',
      new ContentServerLinkView({
        model: new Backbone.Model({
          buttonText: 'Open Exam Folder',
          contentServerId: modelGet(this.model, 'exam.CSFolderId')
        })
      })
    );

    this.exam = new SideNavSectionView({
      useNavigationButtons: false,
      collection: new Backbone.Collection([
        {
          id: 'generalInformation',
          title: 'General Information',
          view: new NonDepExamGeneralInformationSectionView({
            model: this.model,
            isReadOnly: this.options.isReadOnly
          })
        },
        {
          id: 'paymentSchedule',
          title: 'Exam Invoice',
          view: new NonDepExamPaymentScheduleSectionView({
            model: this.model
          })
        },
        {
          id: 'notes',
          title: 'Notes',
          view: new NonDepExamNotesSectionView({
            model: this.model
          })
        },
        {
          id: 'auditLog',
          title: 'Audit Log',
          view: new NonDepExamAuditLogSectionView({
            model: this.model
          })
        }
      ])
    });
    this.showChildView('examWrapper', this.exam);

    var entityName = this.model.get('exam').entityName;
    var entityId = this.model.get('exam').entityId;
    var licenseNumber = this.model.get('exam').licenseNumber;
    var licenseId = this.model.get('exam').licenseId;
    this.showChildView(
      'breadcrumb',
      new BreadcrumbView({
        breadcrumbs: [
          {
            link: '#nondep/dashboard',
            text: 'Home'
          },
          {
            text: entityName,
            link: '#nondep/entity/' + entityId + '/dashboard'
          },
          {
            text: 'License #' + licenseNumber,
            link: '#nondep/entity/' + entityId + '/license/' + licenseId
          },
          {
            text: 'Exam #' + examId
          }
        ]
      })
    );
  },

  onClickEndExam: function() {
    var self = this;
    var message = 'Are you sure you want to cancel this exam workflow process?';
    UIUtil.showModal({
      title: 'Confirm End Exam Workflow',
      view: new ConfirmationModalView({
        confirmText: 'End Exam Workflow',
        model: new Backbone.Model({
          message: message,
          onConfirm: function() {
            var examId = self.model.get('exam').examId;
            return CancelExam({ examId: examId });
          },
          onConfirmDone: function() {
            Backbone.history.loadUrl();
          }
        })
      })
    });
  },

  onClickRequestExamMaterials: function(e) {
    e.preventDefault();
    var examId = this.model.get('exam').examId;
    var entityId = this.model.get('exam').entityId;

    var message =
      'Would you like to request examination materials again? This action will assign the task to the external user.';
    UIUtil.showModal({
      title: 'Confirm Request Examination Materials',
      view: new ConfirmationModalView({
        confirmText: 'Request Examination Materials',
        model: new Backbone.Model({
          message: message,
          onConfirm: function() {
            return SubmitRequestExamMaterial({ examId: examId, entityId: entityId }).done(function() {
              setTimeout(function() {
                Radio.channel('navigate').trigger('show:int:nd:dashboard:exams:dashboard', examId);
              }, 2000);
            });
          }
        })
      })
    });
  },

  onClickCancelRequestExamMaterial: function(e) {
    e.preventDefault();
    var examId = this.model.get('exam').examId;

    var message =
      'Would you like to mark the examination materials as received? This action will delete the existing task assigned to the external user.';
    UIUtil.showModal({
      title: 'Confirm Mark Examination Materials as Received',
      view: new ConfirmationModalView({
        confirmText: 'Mark Examination Materials as Received',
        model: new Backbone.Model({
          message: message,
          onConfirm: function() {
            return SubmitCancelRequestExamMaterial({ examId: examId }).done(function() {
              setTimeout(function() {
                Radio.channel('navigate').trigger('show:int:nd:dashboard:exams:dashboard', examId);
              }, 2000);
            });
          }
        })
      })
    });
  }
});
