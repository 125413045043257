/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Marionette = require('backbone.marionette');
var Syphon = require('backbone.syphon');
var moment = require('moment');

var tmplPaymentModalView = require('../templates/PaymentModalView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplPaymentModalView,

  className: 'payment-modal-view',

  ui: {
    modalBody: '.modal-body',
    paymentForm: '.payment-form',
    dueDate: '.due-date',
    submit: '.submit'
  },

  events: {
    'click @ui.submit': 'onClickSubmit'
  },

  templateHelpers: function() {
    return {
      showFees: this.options.showFees
    };
  },

  onBeforeShow: function() {
    var formData = this.model.toJSON();
    var dueDate = formData.dueDate;
    if (dueDate && moment.isMoment(dueDate) && dueDate.isValid()) {
      formData.dueDate = dueDate.format('MM/DD/YYYY');
    }
    Syphon.deserialize(this, formData);

    var datePickerConf = {
      startDate: '01/01/1901'
    };

    this.ui.dueDate.datepicker(datePickerConf);
    this.showFees = this.options.showFees;
  },

  onClickSubmit: function(e) {
    e.preventDefault();
    this.ui.paymentForm
      .parsley()
      .whenValidate()
      .done(
        function() {
          this.triggerMethod('modal:close', this.getFormData());
        }.bind(this)
      );
  },

  getFormData: function() {
    var data = Syphon.serialize(this);
    data.dueDate = moment(data.dueDate, 'MM/DD/YYYY');
    data.amount = parseFloat(data.amount).toFixed(2);
    if (data.feeAmount) {
      data.feeAmount = parseFloat(data.feeAmount).toFixed(2);
    }
    return data;
  }
});
