/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Backbone = require('backbone');

var GetLicenseContactChangeApplication = require('../services/GetLicenseContactChangeApplication');
var GetLicenseContacts = require('../services/GetLicenseContacts');
var GetLicenseContactsAndOfficersByEntity = require('../../common/services/GetLicenseContactsAndOfficersByEntity');

var LicenseContactsChangeApplicationView = require('../forms/LicenseContactsChangeApplication');

module.exports = {
  event: 'build:amendment:license-contact-change',

  channel: 'nd:application',

  builder: function(config) {
    var defer = $.Deferred();
    GetLicenseContactChangeApplication(config.applicationId).done(function(appData) {
      $.when(GetLicenseContacts(appData.licenseId), GetLicenseContactsAndOfficersByEntity(appData.entityId)).done(
        function(oldLicenseContacts, existingEntityContacts) {
          defer.resolve(
            new LicenseContactsChangeApplicationView({
              viewMode: config.viewMode,
              mode: config.mode,
              isReadOnly: config.isReadOnly,
              model: new Backbone.Model({
                licenseContacts: appData.contacts,
                applicationId: appData.applicationId,
                entityId: appData.entityId,
                licenseId: appData.licenseId,
                isCertified: appData.isCertified,
                certificationName: appData.certificationName,
                oldContacts: oldLicenseContacts,
                requiredLicenseContactTypes: appData.requiredLicenseContactTypes,
                licenseContactTypes: appData.licenseContactTypes,
                existingContacts: existingEntityContacts,
                applicationDocuments: appData.applicationDocuments
              })
            })
          );
        }
      );
    });
    return defer.promise();
  }
};
