var Handlebars = require("handlebars/dist/handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "  <div class=\"form-group\">\n    <label class=\"control-label col-sm-3\">Previous Action</label>\n    <div class=\"col-sm-9 col-lg-7\">\n      <div class=\"form-control-static\">"
    + alias4(((helper = (helper = helpers.decisionRole || (depth0 != null ? depth0.decisionRole : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"decisionRole","hash":{},"data":data}) : helper)))
    + " - "
    + alias4(((helper = (helper = helpers.decisionName || (depth0 != null ? depth0.decisionName : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"decisionName","hash":{},"data":data}) : helper)))
    + "</div>\n    </div>\n  </div>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var helper;

  return "  <div class=\"form-group\">\n    <label class=\"control-label col-sm-3\">Previous Decision Reason</label>\n    <div class=\"col-sm-9 col-lg-7\">\n      <p class=\"form-control-static\">"
    + container.escapeExpression(((helper = (helper = helpers.decisionReason || (depth0 != null ? depth0.decisionReason : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"decisionReason","hash":{},"data":data}) : helper)))
    + "</p>\n    </div>\n  </div>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "  \n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.reviewDecisionId : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.decisionReason : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"useData":true});