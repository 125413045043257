/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');

var cleanUpResponse = require('common/util/cleanUpResponse');

module.exports = function(categoryId) {
  var deferred = $.Deferred();

  $.soap({
    body: {
      GetAllDirectorLookups: {
        $xmlns: 'http://dobs.pa.gov/dp/lookup/bpm/1.0'
      }
    },
    blueprintArrays: [
      'GetAllDirectorLookupsResponse.DirectorElectionBases.DP_LU_DirectorElectionBasis',
      'GetAllDirectorLookupsResponse.OfficerDirectorStatuses.DP_LU_OfficerDirectorStatus',
      'GetAllDirectorLookupsResponse.DirectorBoardTypes.DP_LU_DirectorBoardType',
      'GetAllDirectorLookupsResponse.DirectorReasons.DP_LU_DirectorReason',
      'GetAllDirectorLookupsResponse.DirectorPositionHelds.DP_LU_DirectorPositionHeld'
    ],
    blueprint: {
      directorElectionBasis: [
        '$..DP_LU_DirectorElectionBasis',
        {
          id: '$..ID',
          basis: '$..Basis'
        }
      ],
      officerDirectorStatus: [
        '$..DP_LU_OfficerDirectorStatus',
        {
          id: '$..ID',
          status: '$..Status'
        }
      ],
      directorBoardType: [
        '$..DP_LU_DirectorBoardType',
        {
          id: '$..ID',
          boardType: '$..BoardType'
        }
      ],
      directorReason: [
        '$..DP_LU_DirectorReason',
        {
          id: '$..ID',
          reason: '$..Reason'
        }
      ],
      directorPositionHelds: [
        '$..DP_LU_DirectorPositionHeld',
        {
          id: '$..ID',
          positionHeld: '$..PositionHeld',
          categoryId: '$..CategoryID'
        }
      ]
    }
  })
    .done(function(response) {
      cleanUpResponse(response);
      var isBank = categoryId === '1';
      var isCreditUnion = categoryId === '2';
      var isTrust = categoryId === '3';

      response.directorPositionHelds = _.where(response.directorPositionHelds, { categoryId: categoryId });

      if (isBank || isTrust) {
        response.directorElectionBasis = _.filter(response.directorElectionBasis, function(type) {
          return type.id === '10' || type.id === '11';
        });
      } else if (isCreditUnion) {
        response.directorBoardType = _.filter(response.directorBoardType, function(type) {
          return type.id === '1';
        });
        response.directorElectionBasis = _.filter(response.directorElectionBasis, function(type) {
          return type.id === '11' || type.id === '12';
        });
      }

      deferred.resolve(response);
    })
    .fail(function(err) {
      deferred.reject();
    });

  return deferred.promise();
};
