/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var Backbone = require('backbone');
var Marionette = require('backbone.marionette');
var Syphon = require('backbone.syphon');
var bodybuilder = require('bodybuilder');
var Ladda = require('ladda');
var GridView = require('psa-marionette/views/GridView');
var PageableCollection = require('psa-backbone/models/PageableCollection');

var StringCell = require('common/backgrid/StringCell');
var DollarCell = require('common/backgrid/DollarCell');
var ButtonLinkCell = require('common/backgrid/ButtonLinkCell');
var SearchHighlightCell = require('common/backgrid/SearchHighlightCell');
var ElasticSearchSummaryView = require('common/views/ElasticSearchSummaryView');
var toHash = require('common/util/toHash');
var userHasRole = require('common/util/userHasRole');

var DepInternalSidebarNavView = require('../../common/views/DepInternalSidebarNavView');
var SearchDepEntities = require('../../common/services/SearchDepEntities');

require('../styles/DepEntitySearchView.css');
var tmplDepEntitySearchView = require('../templates/DepEntitySearchView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplDepEntitySearchView,

  className: 'dep-entity-search-view container-fluid',

  ui: {
    sideBar: '.side-bar-container',
    newEntity: '.btn-new-entity',
    searchSummary: '.search-summary',
    searchResultsContainer: '.search-results-container',
    search: '.search',
    clearFilters: '.clear-filters',
    formInputs: ':input'
  },

  regions: {
    sideBar: '@ui.sideBar',
    searchResultsContainer: '@ui.searchResultsContainer',
    searchSummary: '@ui.searchSummary'
  },

  events: {
    'click @ui.search': 'onClickSearch',
    'click @ui.clearFilters': 'onClickClearFilters',
    'keydown @ui.formInputs': 'onKeydownOfInputs'
  },

  templateHelpers: function() {
    // Only show button to update CU Assets in bulk to Credit Union AO and EA
    var isCUAO = userHasRole('DP - AO - Credit Union');
    var isCUEA = userHasRole('DP - EA - Credit Union');
    var isAA1 = userHasRole('DP - AA1') || userHasRole('DP - AA1 - Credit Union');
    var isA01 =
      userHasRole('AO1') ||
      userHasRole('AO1 - Bucket 1') ||
      userHasRole() ||
      userHasRole('AO1 - Bucket 2') ||
      userHasRole('AO1 - Bucket 3') ||
      userHasRole('AO1 - Bucket 4') ||
      userHasRole('AO1 - Bucket 5') ||
      userHasRole('DP - AO - Trust') ||
      isCUAO;
    var isDPEIPB = userHasRole('DP - Exam Initial Processor - Bank');
    return { showBulkEdit: isCUAO || isCUEA, showAddEntity: isAA1 || isA01 || isDPEIPB };
  },

  initialize: function() {
    this.lookupHashes = {
      charterTypes: toHash(this.model.get('charterTypes'), 'id', 'type'),
      corporateStructures: toHash(this.model.get('corporateStructures'), 'id', 'type'),
      federalRegulators: toHash(this.model.get('federalRegulators'), 'id', 'regulator'),
      ownershipStructures: toHash(this.model.get('ownershipStructures'), 'id', 'structure'),
      statuses: toHash(this.model.get('statuses'), 'id', 'status'),
      entityTypes: toHash(this.model.get('entityTypes'), 'id', 'type')
    };
  },

  onBeforeShow: function() {
    this.showChildView('sideBar', new DepInternalSidebarNavView());

    this.searchSummaryView = new ElasticSearchSummaryView({
      model: new Backbone.Model({
        searchLimit: this.model.get('searchLimit')
      })
    });
    this.showChildView('searchSummary', this.searchSummaryView);

    this.searchResults = new GridView({
      columns: [
        {
          name: 'entityName',
          label: 'Entity Name',
          cell: StringCell,
          headerCell: 'custom',
          width: 20,
          editable: false
        },
        {
          name: 'charterType',
          label: 'Charter Type',
          cell: StringCell,
          headerCell: 'custom',
          width: 12,
          editable: false
        },
        {
          name: 'ownershipStructure',
          label: 'Ownership Structure',
          cell: StringCell,
          headerCell: 'custom',
          width: 10,
          editable: false
        },
        {
          name: 'totalAssetsAmount',
          label: 'Asset Size',
          cell: DollarCell.extend({
            decimals: 0
          }),
          headerCell: 'custom',
          width: 13,
          editable: false
        },
        {
          name: 'federalRegulator',
          label: 'Federal Regulator',
          cell: StringCell,
          headerCell: 'custom',
          width: 10,
          editable: false
        },
        {
          name: 'ssRatingComposite',
          label: 'Composite Rating',
          cell: StringCell,
          headerCell: 'custom',
          width: 10,
          editable: false
        },
        {
          name: 'highlight',
          label: 'Matches',
          cell: SearchHighlightCell.extend({
            highlightLabels: {
              entityName: 'Entity Name',
              certificationNumber: 'Certification Number',
              entityType: 'Entity Type',
              charterType: 'Charter Type',
              ownershipStructure: 'Ownership Structure',
              corporateStructure: 'Corporate Structure',
              federalRegulator: 'Federal Regulator',
              status: 'Status'
            }
          }),
          headerCell: 'custom',
          width: 20,
          sortable: false,
          editable: false
        },
        {
          name: '',
          label: '',
          cell: ButtonLinkCell.extend({
            name: 'Open',
            newTab: true,
            buttonClass: 'btn-primary btn-sm',
            href: function(model) {
              return '#dep/entity/' + model.get('entityId') + '/dashboard';
            }
          }),
          headerCell: 'custom',
          width: 5,
          sortable: false,
          editable: false
        }
      ],
      collection: new PageableCollection([], { pageSize: 25 }),
      emptyText: 'No Entities Found'
    });

    this.showChildView('searchResultsContainer', this.searchResults);

    this.$('.backgrid.table').addClass('table-bordered');
  },

  onKeydownOfInputs: function(e) {
    // Searches on press of enter
    if (e.keyCode === 13) {
      this.onClickSearch(e);
    }
  },

  onClickSearch: function() {
    var self = this;
    var data = Syphon.serialize(this, {
      inputReaders: Syphon.SearchInputReaderSet
    });
    if (data.entityTypeIds) {
      data.entityTypeIds = JSON.parse(data.entityTypeIds);
    }

    // If no inputs were given, do not perform a search.
    if (
      !data.entityName &&
      !data.certificationNumber &&
      !data.entityTypeIds &&
      !data.charterTypeId &&
      !data.ownershipStructureId &&
      !data.corporateStructureId &&
      !data.federalRegulatorId &&
      !data.statusId
    ) {
      return;
    }
    this.searchSummaryView.reset();

    var l = Ladda.create(this.ui.search[0]);
    l.start();
    var body = bodybuilder();

    if (data.entityName) {
      body.query('bool', function(b) {
        b.orQuery('match', 'entityName', {
          query: data.entityName,
          fuzziness: 'AUTO',
          analyzer: 'standard'
        });
        b.orQuery('wildcard', 'entityName', data.entityName.toLowerCase() + '*');
        b.orQuery('match', 'entityName.phonetic', data.entityName);
        return b;
      });
    }

    if (data.certificationNumber) {
      body.query('wildcard', 'certificationNumber', data.certificationNumber + '*');
    }

    if (data.entityTypeIds) {
      if (data.entityTypeIds.length === 1) {
        body.query('match', 'entityTypeId', data.entityTypeIds[0]);
      } else {
        body.query('bool', function(b) {
          _.each(data.entityTypeIds, function(entityTypeId) {
            b.orQuery('match', 'entityTypeId', entityTypeId);
          });
          return b;
        });
      }
    }

    if (data.charterTypeId) {
      body.query('match', 'charterTypeId', data.charterTypeId);
    }

    if (data.ownershipStructureId) {
      body.query('match', 'ownershipStructureId', data.ownershipStructureId);
    }

    if (data.corporateStructureId) {
      body.query('match', 'corporateStructureId', data.corporateStructureId);
    }

    if (data.federalRegulatorId) {
      body.query('match', 'federalRegulatorId', data.federalRegulatorId);
    }

    if (data.statusId) {
      body.query('match', 'statusId', data.statusId);
    }

    body.size(this.model.get('searchLimit'));
    body.rawOption('highlight', require('./dep-entity-search-highlights.json'));
    var bodyObj = body.build();
    // Keep for testing
    // console.log(JSON.stringify(bodyObj, null, 2));
    // console.log('formData:', data);
    SearchDepEntities(bodyObj, data, this.lookupHashes)
      .done(function(data) {
        l.stop();
        self.searchResults.collection.fullCollection.reset(data.hits);
        self.searchSummaryView.display(data);
      })
      .fail(function() {
        l.stop();
      });
  },

  onClickClearFilters: function() {
    this.ui.formInputs.val('');
  }
});
