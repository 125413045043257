/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */
var moment = require('moment');

module.exports = function(data) {
  var deferred = $.Deferred();
  data.bond.expiration = moment(data.bond.expiration, 'MM/DD/YYYY').isValid()
    ? moment(data.bond.expiration, 'MM/DD/YYYY').format('YYYY-MM-DD')
    : '';

  data.bond.requiredTo = moment(data.bond.requiredTo, 'MM/DD/YYYY').isValid()
    ? moment(data.bond.requiredTo, 'MM/DD/YYYY').format('YYYY-MM-DD')
    : '';

  $.soap({
    body: {
      SubmitLicenseBond: {
        $xmlns: 'http://dobs.pa.gov/nd/license/bpm/1.0',
        BondID: data.bondId,
        BondCompanyID: data.bond.bondCompanyId,
        BondNumber: data.bond.bondNumber,
        BondAmount: data.bond.bondAmount,
        Expiration: data.bond.expiration,
        RequiredTo: data.bond.requiredTo,
        LicenseRequirement: data.bond.licenseRequirement,
        TypeID: data.bond.typeId,
        StatusID: data.bond.statusId,
        EntityID: data.entityId,
        LicenseID: data.licenseId,
        BondTypeOther: data.bond.bondTypeOther,
        IsDeleted: data.bond.isDeleted
      }
    }
  })
    .done(function(response) {
      deferred.resolve(response);
    })
    .fail(function(err) {
      deferred.reject();
    });

  return deferred.promise();
};
