/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');

var momentUtil = require('common/util/momentUtil');
var cleanUpResponse = require('common/util/cleanUpResponse');

module.exports = function(filingConditionId) {
  var deferred = $.Deferred();

  $.soap({
    body: {
      GetFilingCondition: {
        $xmlns: 'http://dobs.pa.gov/dp/filing/bpm/1.0',
        FilingConditionID: filingConditionId
      }
    },
    blueprint: {
      conditionId: '$..FilingConditionID',
      filingId: '$..FilingID',
      entityId: '$..EntityID',
      typeId: '$..TypeID',
      type: '$..DP_FilingCondition.Type',
      frequencyId: '$..FrequencyID',
      frequency: '$..DP_FilingCondition.Frequency',
      addressActivity: '$..AddressActivity',
      dueDate: '$..DueDate',
      statusId: '$..StatusID',
      status: '$..DP_FilingCondition.Status',
      receivedDate: '$..ReceivedDate',
      comments: '$..Comments',
      isDeleted: '$..IsDeleted'
    }
  })
    .done(function(response) {
      cleanUpResponse(response);

      var inboundDateConfig = _.extend({}, momentUtil.defaultInboundDateConfig, { default: '' });

      response.dueDate = momentUtil.formatStr(response.dueDate, inboundDateConfig);
      response.receivedDate = momentUtil.formatStr(response.receivedDate, inboundDateConfig);

      deferred.resolve(response);
    })
    .fail(function(err) {
      deferred.reject();
    });

  return deferred.promise();
};
