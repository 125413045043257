/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');

module.exports = function(data) {
  return $.soap({
    body: {
      SubmitEntityExamAnswersTask: {
        $xmlns: 'http://dobs.pa.gov/nd/task/bpm/1.0',
        TaskID: data.taskId,
        ExamID: data.examId,
        TaskType: data.taskType,
        ExamSetupCode: data.examSetupCode,
        ExamQuestionResponses: (function() {
          if (!data.examQuestionResponses || !data.examQuestionResponses.length) {
            return;
          }
          return {
            ExamQuestionResponse: _.map(data.examQuestionResponses, function(examQuestionResponse) {
              return {
                ExamSetupQuestionID: examQuestionResponse.examSetupQuestionId,
                IsNotApplicable: examQuestionResponse.isNotApplicable ? '1' : '0',
                NotApplicableReason: examQuestionResponse.notApplicableReason,
                ResponseValue: examQuestionResponse.responseValue,
                ResponseDocuments: (function() {
                  if (!examQuestionResponse.responseDocuments || !examQuestionResponse.responseDocuments.length) {
                    return;
                  }
                  return {
                    ResponseDocument: _.map(examQuestionResponse.responseDocuments, function(responseDocument) {
                      return {
                        DocumentID: responseDocument.documentId
                      };
                    })
                  };
                })()
              };
            })
          };
        })()
      }
    }
  });
};
