/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2020] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var Marionette = require('backbone.marionette');
var Syphon = require('backbone.syphon');
var Ladda = require('ladda');

var GridView = require('psa-marionette/views/GridView');
var PageableCollection = require('psa-backbone/models/PageableCollection');

var SelectOneCell = require('common/backgrid/SelectOneCell');
var MomentCell = require('common/backgrid/MomentCell');
var toggleForm = require('common/util/toggleForm');

var GetExamInvInqByCategoryAndCaseNumber = require('../services/GetExamInvInqByCategoryAndCaseNumber');
var SubmitInvInqRelatedCase = require('../services/SubmitInvInqRelatedCase');

var tmplSecInvInqRelatedCaseAddModalView = require('../templates/SecInvInqRelatedCaseAddModalView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplSecInvInqRelatedCaseAddModalView,

  className: 'sec-inv-inq-related-case-add-modal-view',

  ui: {
    searchForm: '.search-form',
    otherRelatedCaseBlock: '.other-related-case-block',
    otherRelatedCaseForm: '.other-related-case-form',
    searchResultsGrid: '.search-result-grid-container',
    relatedCaseType: '.relatedCaseType',
    searchBlock: '.search-block',
    search: '.btn-search',
    searchResultSummary: '.search-result-summary',
    searchResultMax: '.search-result-max',
    hitCount: '.hit-count',
    searchTime: '.search-time',
    refineSeachTip: '.refine-seach-tip',
    formInputs: ':input',
    submit: '.btn-submit'
  },

  regions: {
    searchResultsGrid: '@ui.searchResultsGrid'
  },

  events: {
    'change @ui.relatedCaseType': 'onChangeRelatedCaseType',
    'click @ui.search': 'onClickSearchRelatedCases',
    'click @ui.submit': 'onClickSubmit',
    'keydown @ui.formInputs': 'onKeydownOfInputs'
  },

  templateHelpers: function() {
    return {
      searchLimit: this.options.searchLimit
    };
  },

  onBeforeShow: function() {
    this.relatedCaseSearchResults = new GridView({
      columns: [
        {
          name: 'selected',
          label: '',
          cell: SelectOneCell.SelectOneRowCell,
          headerCell: SelectOneCell.SelectOneHeaderCell,
          width: 10,
          editable: true,
          sortable: false
        },
        {
          name: 'category',
          label: 'Type',
          cell: 'string',
          headerCell: 'custom',
          width: 15,
          editable: false
        },
        {
          name: 'caseNumber',
          label: 'Case Number',
          cell: 'string',
          headerCell: 'custom',
          width: 15,
          editable: false
        },
        {
          name: 'status',
          label: 'Status',
          cell: 'string',
          headerCell: 'custom',
          width: 14,
          editable: false
        },
        {
          name: 'openedDate',
          label: 'Date Opened',
          cell: MomentCell.MomentCell,
          formatter: MomentCell.MomentDateFormatter.extend({
            emptyValue: '--'
          }),
          sortValue: MomentCell.MomentSortValue,
          headerCell: 'custom',
          width: 14,
          editable: false
        },
        {
          name: 'upgradedDate',
          label: 'Date Upgraded',
          cell: MomentCell.MomentCell,
          formatter: MomentCell.MomentDateFormatter.extend({
            emptyValue: '--'
          }),
          sortValue: MomentCell.MomentSortValue,
          headerCell: 'custom',
          width: 14,
          editable: false
        },
        {
          name: 'closedDate',
          label: 'Date Closed',
          cell: MomentCell.MomentCell,
          formatter: MomentCell.MomentDateFormatter.extend({
            emptyValue: '--'
          }),
          sortValue: MomentCell.MomentSortValue,
          headerCell: 'custom',
          width: 14,
          editable: false
        }
      ],
      collection: new PageableCollection([], { pageSize: 5 }),
      emptyText: 'Search returned no related case.'
    });

    this.showChildView('searchResultsGrid', this.relatedCaseSearchResults);
  },

  onKeydownOfInputs: function(e) {
    // Searches on press of enter
    if (e.keyCode === 13) {
      this.onClickSearchRelatedCases(e);
    }
  },

  onClickSearchRelatedCases: function(e) {
    e.preventDefault();
    var self = this;

    this.ui.searchForm
      .parsley()
      .whenValidate()
      .done(
        function() {
          this.ui.searchResultSummary.hide();
          this.ui.searchResultMax.hide();

          var data = Syphon.serialize(this);
          var category = _.findWhere(this.model.get('invInqRelatedCaseTypes'), { id: data.relatedCaseTypeId }) || {};
          data.category = category.relatedCaseType;

          var l = Ladda.create(this.ui.search[0]);
          l.start();
          GetExamInvInqByCategoryAndCaseNumber(data)
            .done(function(data) {
              l.stop();
              self.relatedCaseSearchResults.collection.fullCollection.reset(data.relatedCases);
              self.ui.hitCount.text(data.totalHits);
              self.ui.searchResultSummary.show();
              if (data.totalHits === self.options.searchLimit) {
                self.ui.searchResultMax.show();
              }
            })
            .fail(function(err) {
              l.stop();
            });
        }.bind(this)
      );
  },

  onClickSubmit: function(e) {
    e.preventDefault();
    var self = this;
    var isRelatedCaseTypeOther = this.ui.relatedCaseType.val() === '4';

    if (isRelatedCaseTypeOther) {
      this.validateOther().done(function() {
        var laddaContext = Ladda.create(e.currentTarget).start(),
          formData = self.getFormData();
        formData.invInqId = self.model.get('invInqId');

        SubmitInvInqRelatedCase(formData).done(function(relatedCase) {
          self.triggerMethod('modal:close', relatedCase);
          laddaContext.stop();
        });
      });
    } else {
      var selectedModel = this.relatedCaseSearchResults.viewGrid.getSelectedModel();

      if (selectedModel) {
        var laddaContext = Ladda.create(e.currentTarget).start(),
          formData = _.pick(selectedModel.toJSON(), ['objectTypeId', 'objectId', 'relatedTypeId']);
        formData.invInqId = self.model.get('invInqId');

        SubmitInvInqRelatedCase(formData).done(function(relatedCase) {
          self.triggerMethod('modal:close', relatedCase);
          laddaContext.stop();
        });
      } else {
        this.triggerMethod('modal:close');
      }
    }
  },

  onChangeRelatedCaseType: function() {
    var isRelatedCaseTypeOther = this.ui.relatedCaseType.val() === '4';

    toggleForm(this.ui.searchBlock, !isRelatedCaseTypeOther);
    toggleForm(this.ui.otherRelatedCaseBlock, isRelatedCaseTypeOther);

    this.ui.submit.text(isRelatedCaseTypeOther ? 'Submit' : 'Select');
  },

  validateOther: function() {
    return this.ui.otherRelatedCaseForm.parsley().whenValidate();
  },

  getFormData: function() {
    var formData = Syphon.serialize(this);
    formData.relatedTypeId = formData.relatedCaseTypeId;

    return formData;
  }
});
