/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Marionette = require('backbone.marionette');
var Ladda = require('ladda');
var Radio = require('backbone.radio');

var BreadcrumbView = require('common/views/BreadcrumbView');
var DepIrregularityFormView = require('./DepIrregularityFormView');

var SubmitIrregularity = require('../services/SubmitIrregularity');

var tmplDepIrregularityEditView = require('../templates/DepIrregularityEditView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplDepIrregularityEditView,

  className: 'dep-irregularity-edit-view container-fluid',

  ui: {
    irregularityForm: '.irregularity-form-container',
    breadcrumb: '.breadcrumb-container',
    submit: '.btn-submit'
  },

  regions: {
    irregularityForm: '@ui.irregularityForm',
    breadcrumb: '@ui.breadcrumb'
  },

  events: {
    'click @ui.submit': 'onClickSubmit'
  },

  onBeforeShow: function() {
    var irregularity = this.model.get('irregularity') || {};
    var irregularityId = this.model.get('irregularityId');
    var entityId = this.model.get('entityId');

    this.showChildView(
      'breadcrumb',
      new BreadcrumbView({
        breadcrumbs: [
          {
            link: '#dep/dashboard',
            text: 'Home'
          },
          {
            link: '#dep/entity/' + entityId + '/dashboard',
            text: this.model.get('entityIdentifiers').entityName
          },
          {
            link: '#dep/entity/' + entityId + '/irregularity/' + irregularityId + '/dashboard',
            text: irregularity.eventDate + ' - ' + irregularity.category
          },
          {
            text: 'Edit Irregularity'
          }
        ]
      })
    );

    this.irregularityForm = new DepIrregularityFormView({
      model: this.model
    });

    this.showChildView('irregularityForm', this.irregularityForm);
  },

  onClickSubmit: function(e) {
    e.preventDefault();
    this.validate().done(
      function() {
        var laddaContext = Ladda.create(this.ui.submit.get(0));
        laddaContext.start();
        var formData = this.getFormData();
        SubmitIrregularity(this.getFormData())
          .done(function() {
            Radio.channel('navigate').trigger('show:int:dp:irregularity', formData.entityId, formData.irregularityId);
          })
          .fail(function() {
            laddaContext.stop();
          });
      }.bind(this)
    );
  },

  validate: function() {
    return this.ui.irregularityForm.parsley().whenValidate();
  },

  getFormData: function() {
    return this.irregularityForm.getFormData();
  }
});
