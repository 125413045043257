/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var Marionette = require('backbone.marionette');

var modelGet = require('common/util/modelGet');

var tmplDepFilingMainView = require('../templates/DepFilingMainView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplDepFilingMainView,

  className: 'dep-filing-main-view',


  ui: {
    reviewRolesCheckboxes: 'input[name=reviewRoles]'
  },


 onBeforeShow: function() {
   

    _.each(
      modelGet(this.model, 'reviewRoles'),
      function(role) {
        
        this.ui.reviewRolesCheckboxes.filter('input[value=' + role.reviewRoleId + ']').prop('checked', true);
      }.bind(this)
    );
 },

  templateHelpers: function() {
    return {
      isBank: this.model.get('entityCategoryId') === '1',
      isGenericFiling: _.contains(["100", "200", "300"], this.model.get('filing').filingTypeId)
    };
  }
});
