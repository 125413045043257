/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var cleanUpResponse = require('common/util/cleanUpResponse');

module.exports = function(data) {
  var defer = $.Deferred();

  $.soap({
    body: {
      GetEntityByEntityNameAndCategory: {
        $xmlns: 'http://dobs.pa.gov/dp/entity/bpm/1.0',
        EntityName: data.entityName,
        CategoryID: data.categoryId
      }
    },
    blueprintArrays: ['GetEntityByEntityNameAndCategoryResponse.DP_Entity'],
    blueprint: {
      entities: [
        '$..DP_Entity',
        {
          id: '$.ID',
          entityName: '$.EntityName',
          entityTypeId: '$.EntityTypeID',
          entityType: '$.Type',
          ppobPhone: '$.PPOBPhone',
          entityAddress: {
            addressId: '$..AddressID',
            addressLine1: '$..AddressLine1',
            addressLine2: '$..AddressLine2',
            addressLine3: '$..AddressLine3',
            city: '$..City',
            county: '$..County',
            country: '$..Country',
            postalCode: '$..PostalCode',
            state: '$..State'
          }
        }
      ]
    }
  })
    .done(function(response) {
      cleanUpResponse(response);

      if (data.filterEntityTypeIds) {
        response.entities = _.filter(response.entities, function(entity) {
          return _.contains(data.filterEntityTypeIds, entity.entityTypeId);
        });
      }

      response.totalHits = response.entities.length;

      defer.resolve(response);
    })
    .fail(function(err) {
      defer.reject(err);
    });

  return defer.promise();
};
