/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');

var cleanUpResponse = require('common/util/cleanUpResponse');

module.exports = function(entityId) {
  var defer = $.Deferred();

  $.soap({
    body: {
      GetEntityLocations: {
        $xmlns: 'http://dobs.pa.gov/nd/entity/bpm/1.0',
        EntityID: entityId
      }
    },
    blueprintArrays: ['GetEntityLocationsResponse.ND_Location'],
    blueprint: {
      locations: [
        '$..ND_Location',
        {
          id: '$.ID',
          isPrimaryPlaceOfBusiness: '$.IsPrimaryPlaceOfBusiness',
          addressLine1: '$.AddressLine1',
          addressLine2: '$.AddressLine2',
          addressLine3: '$.AddressLine3',
          city: '$.City',
          state: '$.State',
          postalCode: '$.PostalCode',
          country: '$.Country',
          county: '$.County',
          officePhoneNumber: '$.OfficePhoneNumber',
          officeFaxNumber: '$.OfficeFaxNumber'
        }
      ]
    }
  })
    .done(function(response) {
      cleanUpResponse(response);
      _.each(response.locations, function(location) {
        location.isPrimaryPlaceOfBusiness = location.isPrimaryPlaceOfBusiness === '1';
      });
      defer.resolve(response.locations);
    })
    .fail(function(err) {
      defer.reject();
    });

  return defer.promise();
};
