/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var GridView = require('psa-marionette/views/GridView');
var UIUtil = require('psa-core/util/ui');

var ConfirmationModalView = require('common/views/ConfirmationModalView');
var MultiButtonCell = require('common/backgrid/MultiButtonCell');
var MomentCell = require('common/backgrid/MomentCell');

var SubmitDeletePriorName = require('../services/SubmitDeletePriorName');

module.exports = GridView.extend({
  className: 'corp-fin-prior-names-section-view',

  onBeforeRender: function() {
    if (GridView.prototype.onBeforeRender) {
      GridView.prototype.onBeforeRender.apply(this.arguments);
    }

    var isReadOnly = this.options.isReadOnly;

    this.options.columns = [
      {
        name: 'priorName',
        label: 'Prior Name',
        cell: 'string',
        headerCell: 'custom',
        width: 60,
        editable: false
      },
      {
        name: 'changeDate',
        label: 'Date Changed',
        cell: MomentCell.MomentCell,
        formatter: MomentCell.MomentDateFormatter.extend({
          emptyValue: '--'
        }),
        sortValue: MomentCell.MomentSortValue,
        headerCell: 'custom',
        width: 20,
        editable: false
      },
      {
        name: 'deleteButtonCell',
        label: '',
        width: 20,
        cell: MultiButtonCell.extend({
          buttons: [
            {
              name: '',
              labelClass: 'fa fa-trash',
              buttonClass: 'btn-danger btn-sm delete-prior-name',
              renderable: function() {
                return !isReadOnly;
              },
              onClick: this.onClickDeletePriorName.bind(this)
            }
          ]
        }),
        editable: false,
        sortable: false
      }
    ];
    this.options.emptyText = 'No Prior Names';
  },

  onClickDeletePriorName: function(e, model) {
    e.preventDefault();
    var self = this;
    var message = 'Are you sure you want to delete this prior name?';
    UIUtil.showModal({
      title: 'Confirm Deleting Prior Name',
      view: new ConfirmationModalView({
        confirmText: 'Delete Prior Name',
        model: new Backbone.Model({
          message: message,
          onConfirm: function() {
            var priorNameId = model.get('id');
            return SubmitDeletePriorName({ priorNameId: priorNameId });
          },
          onConfirmDone: function() {
            self.collection.remove(model);
          }
        })
      })
    });
  }
});
