var Handlebars = require("handlebars/dist/handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper;

  return container.escapeExpression(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"name","hash":{},"data":data}) : helper)));
},"3":function(container,depth0,helpers,partials,data) {
    return "--";
},"5":function(container,depth0,helpers,partials,data) {
    var helper;

  return container.escapeExpression(((helper = (helper = helpers.status || (depth0 != null ? depth0.status : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"status","hash":{},"data":data}) : helper)));
},"7":function(container,depth0,helpers,partials,data) {
    var helper;

  return container.escapeExpression(((helper = (helper = helpers.statusDate || (depth0 != null ? depth0.statusDate : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"statusDate","hash":{},"data":data}) : helper)));
},"9":function(container,depth0,helpers,partials,data) {
    var helper;

  return container.escapeExpression(((helper = (helper = helpers.type || (depth0 != null ? depth0.type : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"type","hash":{},"data":data}) : helper)));
},"11":function(container,depth0,helpers,partials,data) {
    var helper;

  return container.escapeExpression(((helper = (helper = helpers.approvalDate || (depth0 != null ? depth0.approvalDate : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"approvalDate","hash":{},"data":data}) : helper)));
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "  \n<div class=\"subsidiary-form form-horizontal form-static m-t-lg\">\n  <div class=\"row\">\n    <div class=\"col-md-6 col-sm-12\">\n      <div class=\"form-group\">\n          <label class=\"control-label col-sm-3 col-md-4\">Name</label>\n          <div class=\"form-control-static col-sm-9 col-md-8\">"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.name : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data})) != null ? stack1 : "")
    + "</div>\n      </div>\n    </div>\n    <div class=\"col-md-6 col-sm-12\">\n      <div class=\"form-group\">\n          <label class=\"control-label col-sm-3 col-md-4\">Status</label>\n          <div class=\"form-control-static col-sm-9 col-md-8\">"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.status : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.program(3, data, 0),"data":data})) != null ? stack1 : "")
    + "</div>\n      </div>\n    </div>\n  </div>\n  <div class=\"row\">\n    <div class=\"col-md-6 col-sm-12\">\n      <div class=\"form-group\">\n        <label class=\"control-label col-sm-3 col-md-4\">Status Date</label>\n        <div class=\"form-control-static col-sm-9 col-md-8\">"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.statusDate : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.program(3, data, 0),"data":data})) != null ? stack1 : "")
    + "</div>\n      </div>\n    </div>\n    <div class=\"col-md-6 col-sm-12\">\n      <div class=\"form-group\">\n          <label class=\"control-label col-sm-3 col-md-4\">Type</label>\n          <div class=\"form-control-static col-sm-9 col-md-8\">"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.type : depth0),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.program(3, data, 0),"data":data})) != null ? stack1 : "")
    + "</div>\n      </div>\n    </div>\n  </div>\n  <div class=\"form-group\">\n      <label class=\"control-label col-sm-3 col-md-2\">Approval Date</label>\n      <div class=\"form-control-static col-sm-9 col-md-8\">"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.approvalDate : depth0),{"name":"if","hash":{},"fn":container.program(11, data, 0),"inverse":container.program(3, data, 0),"data":data})) != null ? stack1 : "")
    + "</div>\n  </div>\n  <div class=\"form-group\">\n      <label class=\"control-label col-sm-3 col-md-2\">Comments</label>\n      <p class=\"form-control-static col-sm-9 col-md-8\">"
    + container.escapeExpression((helpers["default"] || (depth0 && depth0["default"]) || helpers.helperMissing).call(alias1,(depth0 != null ? depth0.comments : depth0),"--",{"name":"default","hash":{},"data":data}))
    + "</p>\n  </div>\n</div>\n";
},"useData":true});