/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var Backbone = require('backbone');
var Radio = require('backbone.radio');
var Marionette = require('backbone.marionette');

var UIUtil = require('psa-core/util/ui');

var modelGet = require('common/util/modelGet');
var SideNavSectionView = require('common/views/SideNavSectionView');
var BreadcrumbView = require('common/views/BreadcrumbView');
var DropdownView = require('common/views/DropdownView');

var DepEntityConfirmCreateFilingModalView = require('./DepEntityConfirmCreateFilingModalView');
var DepExtEntityPendingTasksView = require('./DepExtEntityPendingTasksView');

var tmplDepExtEntityDashboardView = require('../templates/DepExtEntityDashboardView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplDepExtEntityDashboardView,

  className: 'dep-ext-entity-dashboard-view container-fluid m-b-lg',

  behaviors: {
    ReadOnlyFormBehavior: {}
  },

  ui: {
    entityWrapper: '.entity-wrapper',
    entityActionDropDown: '.dropdown-container',
    breadcrumb: '.breadcrumb-container'
  },

  regions: {
    entityWrapper: '@ui.entityWrapper',
    entityActionDropDown: '@ui.entityActionDropDown',
    breadcrumb: '@ui.breadcrumb'
  },

  onBeforeShow: function() {
    var self = this;

    var entityId = this.model.get('entityId');
    var entityName = modelGet(this.model, 'entityIdentifiers.entityName');
    var entityTypeId = modelGet(this.model, 'entityIdentifiers.entityTypeId');
    var charterTypeId = modelGet(this.model, 'entityIdentifiers.charterTypeId');
    var isCollectiveInvestmentFund = modelGet(this.model, 'entityIdentifiers.isCollectiveInvestmentFund');
    var fomType = modelGet(this.model, 'entityIdentifiers.fomTypeID');

    var isBank = _.contains(['1', '2', '3', '4', '5', '7'], entityTypeId);
    var isCU = _.contains(['11', '12', '13', '14'], entityTypeId);
    var isTrust = _.contains(['21', '22', '23', '24', '25'], entityTypeId);
    var isSEG = isCU && charterTypeId === '11' && fomType === '1';

    // CIF/CTF available for 1. Trust, charter type Trust Company only
    // 2. Bank, charter types Bank and Trust, Savings Bank with Trust
    var showCIFCTF = _.contains(['2', '21', '31'], charterTypeId);

    var userAccessLevelId = +this.model.get('accessLevelId');
    var isLawyerUser = userAccessLevelId === 1;

    var viewMode = this.options.viewMode;

    this.showChildView(
      'breadcrumb',
      new BreadcrumbView({
        breadcrumbs: [
          {
            link: '#ext/dep/dashboard',
            text: 'Home'
          },
          {
            text: entityName
          }
        ]
      })
    );

    if (userAccessLevelId >= 20 || isLawyerUser) {
      var dropDownOptions = [
        // This will be added later
        {
          text: 'Submit Generic Filing',
          onClick: function() {
            self.confirmNewFiling('100');
          }
        }
      ];

      if (isBank) {
        // Add all other bank filing links
        dropDownOptions = dropDownOptions.concat([
          // this will be added back later
          {
            text: 'Submit Bank Articles of Amendment Filing',
            onClick: function() {
              self.confirmNewFiling('102');
            }
          },
          {
            text: 'Submit Location Activities Filing',
            onClick: function() {
              self.confirmNewFiling('103');
            }
          },
          {
            text: 'Submit Bank Parity Filing',
            onClick: function() {
              self.confirmNewFiling('104');
            }
          },
          {
            text: 'Submit Bank Subsidiary Filing',
            onClick: function() {
              self.confirmNewFiling('105');
            }
          },
          {
            text: 'Submit Conversion National To State Filing',
            onClick: function() {
              self.confirmNewFiling('107');
            }
          },
          {
            text: 'Submit Section 112 Filing',
            onClick: function() {
              self.confirmNewFiling('109');
            }
          },
          {
            text: 'Submit Bank Acquisition Merger Filing',
            onClick: function() {
              self.confirmNewFiling('112');
            }
          }
          // {
          //   isDivider: true
          // },
          // {
          //   text: 'Initiate Correspondence',
          //   link: '#ext/dep/entity/' + entityId + '/initiate-correspondence'
          // }
        ]);

        if (showCIFCTF) {
          dropDownOptions = dropDownOptions.concat([
            {
              text: 'Submit Collective Investment/Common Trust',
              onClick: function() {
                self.confirmNewFiling('111');
              }
            }
          ]);
        }
      }

      if (isCU) {
        // Add all other bank filing links

        dropDownOptions = dropDownOptions.concat([
          {
            text: 'Submit Credit Union Articles of Amendment Filing',
            onClick: function() {
              self.confirmNewFiling('201');
            }
          },
          {
            text: 'Submit Location Activities Filing',
            onClick: function() {
              self.confirmNewFiling('202');
            }
          },
          {
            text: 'Submit Credit Union Parity Filing',
            onClick: function() {
              self.confirmNewFiling('203');
            }
          },
          // {
          //   text: 'Submit SEG Request Filing',
          //   onClick: function() {
          //     self.confirmNewFiling('204');
          //   }
          // },
          // {
          //   text: 'Submit Generic Filing',
          //   onClick: function() {
          //     self.confirmNewFiling('100');
          //   }
          // },
          {
            text: 'Submit Audit',
            onClick: function() {
              self.confirmNewFiling('205');
            }
          }
        ]);
      }
      if (isSEG) {
        dropDownOptions = dropDownOptions.concat([
          {
            text: 'Submit SEG Request Filing',
            onClick: function() {
              self.confirmNewFiling('204');
            }
          }
        ]);
      }
      if (isTrust) {
        // Add all other bank filing links
        dropDownOptions = dropDownOptions.concat([
          {
            text: 'Submit Trust Articles of Amendment Filing',
            onClick: function() {
              self.confirmNewFiling('301');
            }
          },
          //  this will be added back in the future
          {
            text: 'Submit Section 112 Filing',
            onClick: function() {
              self.confirmNewFiling('304');
            }
          },
          {
            text: 'Submit Audit',
            onClick: function() {
              self.confirmNewFiling('306');
            }
          },
          {
            text: 'Submit Call Report',
            onClick: function() {
              self.confirmNewFiling('307');
            }
          },
          // this will be added back in the future
          {
            text: 'Submit Location Activities Filing',
            onClick: function() {
              self.confirmNewFiling('308');
            }
          }
          // {
          //   text: 'Submit Generic Filing',
          //   onClick: function() {
          //     self.confirmNewFiling('100');
          //   }
          // }
        ]);

        if (showCIFCTF) {
          dropDownOptions = dropDownOptions.concat([
            {
              text: 'Submit Collective Investment/Common Trust',
              onClick: function() {
                self.confirmNewFiling('305');
              }
            }
          ]);
        }
      }
      // this will be added back later
      dropDownOptions = dropDownOptions.concat([
        {
          isDivider: true
        },
        {
          text: 'Initiate Correspondence',
          link: '#ext/dep/entity/' + entityId + '/initiate-correspondence'
        }
      ]);

      this.showChildView(
        'entityActionDropDown',
        new DropdownView({
          rightAlign: true,
          model: new Backbone.Model({
            name: 'Entity Actions',
            options: dropDownOptions
          })
        })
      );
    }

    var sideNavTabs = [
      {
        id: 'pendingTasks',
        title: 'Pending Tasks',
        view: new DepExtEntityPendingTasksView({
          model: new Backbone.Model({
            tasks: this.model.get('tasks')
          })
        })
      },
      {
        id: 'activeFilings',
        title: 'Active Filings',
        view: Radio.channel('dp').request('build:ext-active-filings-section-view', {
          entityId: entityId,
          userAccessLevelId: userAccessLevelId
        })
      },
      {
        id: 'locations',
        title: 'Locations',
        view: Radio.channel('dp').request('build:ext-entity-location-view', entityId)
      }
    ];

    // CIF/CTF available for 1. Trust, charter type Trust Company only
    // 2. Bank, charter types Bank and Trust, Savings Bank with Trust
    var entityCharterTypeHasCIF = ['2', '21', '31'];
    var entityHasCIFs = _.contains(entityCharterTypeHasCIF, charterTypeId);
    var showCIF = isCollectiveInvestmentFund === '1' && entityHasCIFs && !isLawyerUser;
    if (showCIF) {
      sideNavTabs.splice(2, 0, {
        id: 'funds',
        title: 'Funds',
        view: Radio.channel('dp').request('build:ext-entity-fund-view', entityId)
      });
    }

    if (userAccessLevelId >= 30) {
      sideNavTabs.push({
        id: 'userManagement',
        title: 'User Management',
        view: Radio.channel('dp').request('build:user-management-section-view', entityId, viewMode)
      });
    }

    this.entity = new SideNavSectionView({
      collection: new Backbone.Collection(sideNavTabs)
    });

    this.showChildView('entityWrapper', this.entity);
  },

  confirmNewFiling: function(filingTypeId) {
    var entityId = this.model.get('entityId');
    if (filingTypeId === '100') {
      var filingTypeMap = { '1': '100', '2': '200', '3': '300' };
      var categoryId = modelGet(this.model, 'entityIdentifiers.categoryId');
      filingTypeId = filingTypeMap[categoryId];
    }
    UIUtil.showModal({
      title: 'Confirm Creating Filing Draft',
      view: new DepEntityConfirmCreateFilingModalView({
        entityId: entityId,
        isExternalFiling: '1',
        filingTypeId: filingTypeId
      })
    }).done(function(response) {
      Radio.channel('navigate').trigger('show:ext:dp:draft-filing', response.filingId);
    });
  }
});
