/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var Backbone = require('backbone');
var Marionette = require('backbone.marionette');
var Syphon = require('backbone.syphon');
var Radio = require('backbone.radio');
var Ladda = require('ladda');

var momentUtil = require('common/util/momentUtil');
var modelGet = require('common/util/modelGet');
var HeaderBarView = require('common/views/HeaderBarView');
var BreadcrumbView = require('common/views/BreadcrumbView');
var FileUploadTableView = require('common/views/FileUploadTableView');
var FileUploadView = require('common/views/FileUploadView');
var makeUploadCollection = require('common/util/makeUploadCollection');

var SubmitRespondCorrespondenceTask = require('../../services/SubmitRespondCorrespondenceTask');

var tmplRespondExternalCorrespondenceTaskView = require('./RespondExternalCorrespondenceTaskView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplRespondExternalCorrespondenceTaskView,

  className: 'non-dep-respond-external-correspondence-task-view container-fluid',

  ui: {
    headerBar: '.header-bar',
    breadcrumb: '.breadcrumb-container',
    correspondenceForm: '.correspondence-form',
    extFileUploadTableContainer: '.ext-file-upload-table-container',
    intFileUploadTableContainer: '.int-file-upload-table-container',
    submit: '.submit'
  },

  regions: {
    headerBar: '@ui.headerBar',
    breadcrumb: '@ui.breadcrumb',
    extFileUploadTableContainer: '@ui.extFileUploadTableContainer',
    intFileUploadTableContainer: '@ui.intFileUploadTableContainer'
  },

  behaviors: {
    TaskBehavior: {}
  },

  events: {
    'click @ui.submit': 'onClickSubmit'
  },

  onBeforeShow: function() {
    var correspondence = this.model.get('correspondence') || {};
    var extDocuments = modelGet(this.model, 'correspondence.extDocuments', []);
    var headerBarColumns = [
      {
        name: 'entityName',
        label: 'Entity Name',
        minWidth: '160px',
        maxWidth: '300px'
      },
      {
        name: 'tin',
        label: 'Federal ID Number',
        minWidth: '160px',
        maxWidth: '300px'
      }
    ];

    this.showChildView(
      'headerBar',
      new HeaderBarView({
        columns: new Backbone.Collection(headerBarColumns),
        model: new Backbone.Model(correspondence)
      })
    );

    this.showChildView(
      'breadcrumb',
      new BreadcrumbView({
        breadcrumbs: [
          {
            link: '#nondep/dashboard',
            text: 'Home'
          },
          {
            text: 'Respond to Correspondence'
          }
        ]
      })
    );

    Syphon.deserialize(this, correspondence);

    this.extFileUploadTableView = new FileUploadTableView({
      collection: new Backbone.Collection(extDocuments),
      emptyText: 'No External Documents',
      isReadOnly: true
    });

    this.showChildView('extFileUploadTableContainer', this.extFileUploadTableView);

    this.intFileUploadTableView = new FileUploadView({
      collection: makeUploadCollection([]),
      isReadOnly: false,
      allowMultipleFiles: true,
      metadata: {
        documentTypeId: 30013
      }
    });

    this.showChildView('intFileUploadTableContainer', this.intFileUploadTableView);
  },

  onClickSubmit: function(e) {
    e.preventDefault();
    var self = this;
    this.validate().done(function() {
      var l = Ladda.create(self.ui.submit[0]);
      l.start();
      SubmitRespondCorrespondenceTask(self.getFormData())
        .done(function() {
          Radio.channel('navigate').trigger('show:int:nd:dashboard');
        })
        .fail(function() {
          l.stop();
        });
    });
  },

  validate: function() {
    return this.ui.correspondenceForm.parsley().whenValidate();
  },

  getFormData: function() {
    var formData = Syphon.serialize(this);
    formData.fiscalYearEndDate = momentUtil.formatStr(formData.fiscalYearEndDate, momentUtil.defaultOutboundDateConfig);

    _.extend(formData, {
      taskId: this.model.get('taskId'),
      correspondenceId: this.model.get('correspondenceId')
    });
    formData.documents = this.intFileUploadTableView.collection.toJSON();
    formData.isInternal = '1';
    return formData;
  }
});
