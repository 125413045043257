/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Marionette = require('backbone.marionette');
var Radio = require('backbone.radio');

var modelGet = require('common/util/modelGet');

var BreadcrumbView = require('common/views/BreadcrumbView');
var Ladda = require('ladda');

var SubmitReviewCorrespondenceTask = require('../../services/SubmitReviewCorrespondenceTask');

var tmplNonDepLicenseViewNotificationTaskView = require('./NonDepLicenseViewNotificationTaskView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplNonDepLicenseViewNotificationTaskView,

  className: 'entity-view-notification-task-view container-fluid',

  ui: {
    breadcrumb: '.breadcrumb-container',
    documentContainer: '.document-container',
    submit: '.submit',
    notificationContainer: '.notification-container'
  },

  regions: {
    breadcrumb: '@ui.breadcrumb',
    documentContainer: '@ui.documentContainer'
  },

  events: {
    'click @ui.submit': 'onClickSubmit'
  },

  onBeforeShow: function() {
    var entityId = this.model.get('entityId');
    var entityName = modelGet(this.model, 'application.entityName');
    var taskDescription = modelGet(this.model, 'task.taskType.description');

    this.showChildView(
      'breadcrumb',
      new BreadcrumbView({
        breadcrumbs: [
          {
            link: '#nondep/dashboard',
            text: 'Home'
          },
          {
            link: '#nondep/entity/' + entityId + '/dashboard',
            text: entityName
          },
          {
            text: taskDescription
          }
        ]
      })
    );
  },

  onClickSubmit: function(e) {
    e.preventDefault();
    var l = Ladda.create(this.ui.submit[0]);
    l.start();
    SubmitReviewCorrespondenceTask({ taskId: this.model.get('taskId') })
      .done(function() {
        Radio.channel('navigate').trigger('show:int:nd:dashboard');
      })
      .fail(function() {
        l.stop();
      });
  }
});
