/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Marionette = require('backbone.marionette');
var Syphon = require('backbone.syphon');

var tmplDepExamOtherInformationViolationModalView = require('../templates/DepExamOtherInformationViolationModalView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplDepExamOtherInformationViolationModalView,

  className: 'exam-violation-modal-view',

  ui: {
    form: 'form',

    legalCiteSelect: '.legal-cite-select',
    descriptionSelect: '.description-select',

    datepicker: '.datepicker',

    submit: '.submit'
  },

  events: {
    'click @ui.submit': 'onClickSubmit'
  },

  templateHelpers: function() {
    return {
      lookups: this.options.lookups
    };
  },

  onBeforeShow: function() {
    Syphon.deserialize(this, this.model.toJSON());
    this.ui.datepicker.datepicker();
  },

  onClickSubmit: function(e) {
    e.preventDefault();
    var self = this;
    this.ui.form
      .parsley()
      .whenValidate()
      .done(
        function() {
          var formData = this.getFormData();

          self.triggerMethod('modal:close', formData);
        }.bind(this)
      );
  },

  getFormData: function() {
    var formData = Syphon.serialize(this.ui.form);

    formData.legalCiteDisplay = this.ui.legalCiteSelect.find(':selected').text() || '--';
    formData.commentsDisplay = formData.comments || '--';

    return formData;
  }
});
