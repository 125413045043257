/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Marionette = require('backbone.marionette');
var Radio = require('backbone.radio');
var Ladda = require('ladda');

var modelGet = require('common/util/modelGet');
var BreadcrumbView = require('common/views/BreadcrumbView');

var LicenseHeaderBarView = require('../../common/views/LicenseHeaderBarView');
var SubmitLicenseBond = require('../services/SubmitLicenseBond');
var LicenseBondFormView = require('../../new-license/views/LicenseBondFormView');

var tmplNonDepEditLicenseBondView = require('../templates/NonDepEditLicenseBondView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplNonDepEditLicenseBondView,

  className: 'non-dep-edit-license-bond-view container-fluid',

  ui: {
    headerBarContainer: '.header-bar-container',
    breadCrumbContainer: '.breadcrumb-container',
    nonDepEditlicenseBondFormViewContainer: '.non-dep-edit-license-bond-view-container',
    submitButton: '.submit-button'
  },

  regions: {
    headerBarContainer: '@ui.headerBarContainer',
    breadCrumbContainer: '@ui.breadCrumbContainer',
    nonDepEditlicenseBondFormViewContainer: '@ui.nonDepEditlicenseBondFormViewContainer'
  },

  events: {
    'click @ui.submitButton': 'onClickSubmit'
  },

  onBeforeShow: function() {
    var entityName = modelGet(this.model, 'licenseIdentifiers.entityName');
    var entityId = this.model.get('entityId');
    var licenseId = this.model.get('licenseId');
    var licenseType = modelGet(this.model, 'licenseIdentifiers.licenseType');
    var licenseNumber = modelGet(this.model, 'licenseIdentifiers.licenseNumber');

    this.showChildView(
      'headerBarContainer',
      new LicenseHeaderBarView({
        model: new Backbone.Model(this.model.get('licenseIdentifiers'))
      })
    );

    this.showChildView(
      'breadCrumbContainer',
      new BreadcrumbView({
        breadcrumbs: [
          {
            text: 'Home',
            link: '#nondep/dashboard'
          },
          {
            text: entityName,
            link: '#nondep/entity/' + entityId + '/dashboard'
          },
          {
            text: 'License #' + licenseNumber + ' - ' + licenseType,
            link: '#nondep/entity/' + entityId + '/license/' + licenseId
          },
          {
            text: 'Edit Bond'
          }
        ]
      })
    );

    this.licenseBondFormView = new LicenseBondFormView({
      model: this.model
    });

    this.showChildView('nonDepEditlicenseBondFormViewContainer', this.licenseBondFormView);
  },

  onClickSubmit: function(event) {
    event.preventDefault();
    var self = this;
    this.validate().done(function() {
      var bondId = self.model.get('licenseBond').Id;

      var bond = self.getFormData();
      bond.isDeleted = 0;

      var appData = {
        entityId: self.model.get('entityId'),
        licenseId: self.model.get('licenseId'),
        bondId: bondId,
        bond: bond
      };
      var laddaContext = Ladda.create(self.ui.submitButton.get(0));
      laddaContext.start();
      SubmitLicenseBond(appData)
        .done(function(response) {
          Radio.channel('navigate').trigger(
            'show:int:nd:license-dashboard',
            self.model.get('entityId'),
            self.model.get('licenseId')
          );
        })
        .fail(function(err) {
          laddaContext.stop();
        });
    });
  },

  validate: function() {
    var config = {};
    return this.licenseBondFormView.validate(config);
  },

  getFormData: function() {
    var formData = this.licenseBondFormView.getFormData();
    formData.id = this.model.get('id');
    return formData;
  }
});
