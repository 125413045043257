/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2020] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Marionette = require('backbone.marionette');
var Syphon = require('backbone.syphon');

var modelGet = require('common/util/modelGet');

var tmplDepDirectorFormView = require('../templates/DepDirectorFormView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplDepDirectorFormView,

  className: 'dep-director-form-view-view',

  ui: {
    termStartDate: '.term-start-date',
    termEndDate: '.term-end-date',
    electionDate: '.election-date'
  },

  templateHelpers: function() {
    var categoryId = modelGet(this.model, 'entityIdentifiers.categoryId');
    var isCreditUnion = categoryId === '2';

    return {
      isCreditUnion: isCreditUnion
    };
  },

  onBeforeShow: function() {
    Syphon.deserialize(this, this.model.get('director'));

    this.ui.termStartDate.datepicker({});
    this.ui.termEndDate.datepicker({});
    this.ui.electionDate.datepicker({});
  },

  getFormData: function() {
    return Syphon.serialize(this);
  }
});
