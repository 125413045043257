/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _get = require('lodash.get');

var showErrorMessage = require('./showErrorMessage');

var errorTypes = {
  'general.system.error': {
    errorTitle: 'System Error Occurred',
    errorMessage: 'A system error has occurred. Contact site administrator.'
  },
  'bpm:general.system.error': {
    errorTitle: 'System Error Occurred',
    errorMessage: 'A system error has occurred. Contact site administrator.'
  },
  'bpm:entity-exists': {
    errorTitle: 'Entity Already Exists',
    errorMessage:
      'An entity with this Tax ID already exists. This means either this entity has already been created within the system, or it was synced from NMLS.'
  },
  'bpm:existing-license-at-location': {
    errorTitle: 'Active License at Location Exists',
    errorMessage: 'An active license with the selected type already exists at this location.'
  },
  'bpm:invalid-filing-submission': {
    errorTitle: 'Invalid Filing Submission',
    errorMessage: 'This filing is currently not eligible to be submitted. Contact site administrator.'
  },
  'bpm:cm.access.denied': {
    errorTitle: 'Access Denied',
    errorMessage: 'You do not have the required access to perform the requested action.'
  },
  'bpm:invalid-join-code': {
    errorTitle: 'Invalid Join Code',
    errorMessage:
      'We are unable to revoke the invitation due to an invalid join code. Kindly refresh the page and try again.'
  }
};

module.exports = function(event, jqxhr, settings, thrownError) {
  var httpStatusCode = _get(jqxhr, 'status');
  var dataType = settings.dataType || '';
  var isSoapRequest = dataType.indexOf('soap') > 0;
  var errorCode = 'general.system.error';

  var showError = false;

  if (httpStatusCode === 403) {
    errorCode = 'bpm:cm.access.denied';
    showError = true;
  } else if (isSoapRequest) {
    errorCode = _get(jqxhr, 'message.code', 'general.system.error');
    showError = true;
  }

  if (showError) {
    var errorType = errorTypes[errorCode] || errorTypes['general.system.error'];

    showErrorMessage({
      errorCode: errorCode,
      errorTitle: errorType.errorTitle,
      errorMessage: errorType.errorMessage
    });
  }
};
