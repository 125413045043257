var Handlebars = require("handlebars/dist/handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "        <option value=\""
    + alias4(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\">"
    + alias4(((helper = (helper = helpers.recipientType || (depth0 != null ? depth0.recipientType : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"recipientType","hash":{},"data":data}) : helper)))
    + "</option>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.escapeExpression, alias2=depth0 != null ? depth0 : (container.nullContext || {});

  return "\n<div class=\"page-title clearfix\">\n  <h1 class=\"pull-left m-n p-n\">Complaint - "
    + alias1(container.lambda(((stack1 = (depth0 != null ? depth0.complaint : depth0)) != null ? stack1.caseNumber : stack1), depth0))
    + "</h1>\n</div>\n<div class=\"header-bar-container\"></div>\n<div class=\"breadcrumb-container\"></div>\n<form class=\"form-horizontal col-sm-9 col-sm-offset-1 correspondence-form\">\n  <div class=\"form-group required\">\n    <label class=\"control-label col-sm-3\">Attach File(s) For Correspondence</label>\n    <div class=\"col-sm-9\">\n      <div class=\"document-container\"></div>\n      <div class=\"alert alert-danger collapse missing-file m-t-md\" role=\"alert\">\n        <i class=\"fa fa-exclamation-triangle\" aria-hidden=\"true\"></i>\n        At least one document or correspondence comment must be provided.\n      </div>\n    </div>\n  </div>\n  <div class=\"form-group\">\n    <label class=\"control-label col-sm-3\">Correspondence</label>\n    <div class=\"col-sm-9\">\n      <textarea type=\"text\" name=\"requestComments\" class=\"form-control comments\" placeholder=\"Correspondence\"\n        rows=\"5\"></textarea>\n    </div>\n  </div>\n  <div class=\"form-group required\">\n    <label class=\"control-label col-sm-3\">Recipient</label>\n    <div class=\"col-sm-9\">\n      <select name=\"recipient\" class=\"form-control\">\n        <option value=\"\" selected>--</option>\n"
    + ((stack1 = helpers.each.call(alias2,(depth0 != null ? depth0.recipientTypes : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "      </select>\n      <div class=\"alert alert-danger collapse missing-recipient m-t-md\" role=\"alert\">\n        <i class=\"fa fa-exclamation-triangle\" aria-hidden=\"true\"></i>\n        Select a recipient\n      </div>\n    </div>\n  </div>\n</form>\n<div class=\"form-horizontal col-sm-9 col-sm-offset-1\">\n  <div class=\"row m-b-lg\">\n    <div class=\"col-sm-12\">\n      <div class=\"form-group\">\n        <div class=\"col-sm-9 col-sm-offset-3\">\n          <a href=\"#conser/complaint/"
    + alias1(((helper = (helper = helpers.complaintId || (depth0 != null ? depth0.complaintId : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(alias2,{"name":"complaintId","hash":{},"data":data}) : helper)))
    + "/dashboard\" class=\"btn btn-default\">Cancel</a>\n          <button class=\"btn btn-primary btn-submit submit\">Submit</button>\n        </div>\n      </div>\n    </div>\n  </div>\n</div>";
},"useData":true});