/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Marionette = require('backbone.marionette');

var Config = require('../../../config/Config');

var tmplContentServerLinkView = require('./ContentServerLinkView.hbs');

module.exports = Marionette.ItemView.extend({
  template: tmplContentServerLinkView,

  tagName: 'a',

  className: 'btn btn-content-server content-server-link-view',

  attributes: function() {
    var url = Config.getOTCSSmartUIUrl(this.model.get('contentServerId'));
    return {
      role: 'button',
      href: url,
      target: '_blank'
    };
  }
});
