/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var momentUtil = require('common/util/momentUtil');
var cleanUpResponse = require('common/util/cleanUpResponse');

module.exports = function(staffId) {
  var defer = $.Deferred();

  $.soap({
    body: {
      GetExamInvInqStaffById: {
        $xmlns: 'http://dobs.pa.gov/sc/examInvInq/bpm/1.0',
        StaffID: staffId
      }
    },
    blueprint: {
      id: '$..ID',
      objectTypeId: '$..ObjectTypeID',
      objectId: '$..ObjectID',
      staffMember: '$..StaffMember',
      firstName: '$..FirstName',
      lastName: '$..LastName',
      functionId: '$..FunctionID',
      assignedDate: '$..AssignedDate',
      removedDate: '$..RemovedDate'
    }
  })
    .done(function(response) {
      cleanUpResponse(response);

      var formInboundDateConfig = {
        sourceFormat: 'YYYY-MM-DD',
        targetFormat: 'MM/DD/YYYY',
        default: undefined
      };

      response.assignedDate = momentUtil.formatStr(response.assignedDate, formInboundDateConfig);
      response.removedDate = momentUtil.formatStr(response.removedDate, formInboundDateConfig);

      defer.resolve(response);
    })
    .fail(function(err) {
      defer.reject(err);
    });

  return defer.promise();
};
