/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');

module.exports = function(formData) {
  var documents = formData.documents || [];

  return $.soap({
    body: {
      SubmitExamExternalTask: {
        $xmlns: 'http://dobs.pa.gov/sc/task/bpm/1.0',
        TaskID: formData.taskId,
        ExamID: formData.examId,
        ReplyComments: formData.replyComments,
        TaskType: formData.taskType,
        Documents: (function() {
          if (!documents.length) {
            return {};
          }
          return {
            Document: _.map(documents, function(document) {
              return {
                DocumentID: document.fileId
              };
            })
          };
        })()
      }
    }
  });
};
