var Handlebars = require("handlebars/dist/handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression;

  return "\n<div class=\"page-title clearfix\">\n  <h1 class=\"pull-left m-n p-n\">"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? depth0.task : depth0)) != null ? stack1.taskType : stack1)) != null ? stack1.description : stack1), depth0))
    + "</h1>\n</div>\n<div class=\"header-bar-container\"></div>\n<div class=\"breadcrumb-container\"></div>\n<div class=\"task-status\"></div>\n\n<div class=\"container-fluid m-b-lg\">\n  <form class=\"form-horizontal determination-form\">\n    <div class=\"col-sm-10 col-sm-offset-1\">\n      <div class=\"case-summary-container\">\n        <div class=\"form-group\">\n          <label class=\"control-label col-sm-4 col-md-3\">Jump To</label>\n          <div class=\"form-control-static col-sm-8 col-md-9 jump-to-container\"></div>\n        </div>\n        <div class=\"summary-container\"></div>\n        <div class=\"form-group\">\n          <label class=\"control-label col-sm-4 col-md-3\">Narrative</label>\n          <p class=\"form-control-static text-break col-sm-6 col-md-7\">"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.complianceDetails : depth0)) != null ? stack1.narrative : stack1), depth0))
    + "</p>\n        </div>\n      </div>\n      <div class=\"form-group required\">\n        <label class=\"control-label col-sm-4 col-md-3\">Decision</label>\n        <div class=\"col-sm-6 col-md-7 \">\n          <select name=\"decisionId\" class=\"decision-select form-control\" data-parsley-required=\"true\"\n            data-parsley-errors-container=\".decision-select-error-container\">\n            <option value=\"\" selected>--</option>\n            <option value=\"2103\">Violation - Issue Warning Letter</option>\n            <option value=\"2105\">Violation - Create Enforcement Action</option>\n            <option value=\"2104\">No Violation</option>\n          </select>\n          <div class=\"decision-select-error-container\"></div>\n        </div>\n      </div>\n      <div class=\"form-group required warning-letter-upload-form\">\n        <label class=\"control-label col-sm-4 col-md-3\">Warning Letter</label>\n        <div class=\"col-sm-6 col-md-7\">\n          <div class=\"warning-letter-upload-container\"></div>\n          <div class=\"case-decision-select-error-container\"></div>\n        </div>\n      </div>\n      <div class=\"col-sm-6 col-md-7 col-sm-offset-4 col-md-offset-3\">\n        <a class=\"btn btn-default btn-cancel\" href=\"#nondep/dashboard\">Cancel</a>\n        <button class=\"btn btn-primary btn-submit\">Submit</button>\n        <div class=\"task-actions\"></div>\n      </div>\n    </div>\n  </form>\n</div>";
},"useData":true});