/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Backbone = require('backbone');
var Marionette = require('backbone.marionette');

var AddressDisplayView = require('common/views/AddressDisplayView');

require('../styles/NonDepLicenseContactDetailView.css');
var tmplNonDepLicenseContactDetailView = require('../templates/NonDepLicenseContactDetailView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplNonDepLicenseContactDetailView,

  className: 'non-dep-license-contact-detail-view panel panel-default panel-row-view',

  ui: {
    contactAddress: '.contact-address'
  },

  regions: {
    contactAddress: '@ui.contactAddress'
  },

  templateHelpers: function() {
    return {
      viewMode: this.options.viewMode
    };
  },

  onBeforeShow: function() {
    this.showChildView(
      'contactAddress',
      new AddressDisplayView({
        model: new Backbone.Model(this.model.get('address'))
      })
    );
  }
});
