/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Marionette = require('backbone.marionette');
var Syphon = require('backbone.syphon');

var modelGet = require('common/util/modelGet');
var toggleForm = require('common/util/toggleForm');
var AddressView = require('common/views/AddressView');

var tmplDepIrregularityFormView = require('../templates/DepIrregularityFormView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplDepIrregularityFormView,

  className: 'dep-irregularity-form-view',

  ui: {
    eventDate: '.event-date',
    categoryDropdown: 'select[name="categoryId"]',
    otherCategoryArea: '.otherCategoryArea',
    dateOfBirth: '.date-of-birth',
    address: '.address'
  },

  regions: {
    address: '@ui.address'
  },

  events: {
    'click @ui.categoryDropdown': 'onChangeCategory'
  },

  onBeforeShow: function() {
    var categoryId = modelGet(this.model, 'irregularity.categoryId');
    // categoryId: 99 === 'Other'
    toggleForm(this.ui.otherCategoryArea, categoryId === '99');

    this.showChildView(
      'address',
      new AddressView({
        horizontal: true,
        isRequired: false,
        objectName: 'address',
        startingCountry: modelGet(this.model, 'irregularity.address.country'),
        startingPostalCode: modelGet(this.model, 'irregularity.address.postalCode'),
        startingCounty: modelGet(this.model, 'irregularity.address.county')
      })
    );
    Syphon.deserialize(this, this.model.get('irregularity'));

    this.ui.eventDate.datepicker({
      startView: 'days'
    });

    this.ui.dateOfBirth.datepicker({
      endDate: '0d',
      startView: 'years'
    });
  },

  onChangeCategory: function(e) {
    var formData = Syphon.serialize(this);
    toggleForm(this.ui.otherCategoryArea, formData.categoryId === '99');
  },

  getFormData: function() {
    var formData = Syphon.serialize(this);
    formData.entityId = this.model.get('entityId');
    formData.irregularityId = this.model.get('irregularityId');
    return formData;
  }
});
