/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var moment = require('moment');
var Marionette = require('backbone.marionette');
var Syphon = require('backbone.syphon');

var FileUploadView = require('common/views/FileUploadView');
var makeUploadCollection = require('common/util/makeUploadCollection');
var momentUtil = require('common/util/momentUtil');

require('../styles/NonDepEntityFormDBAView.css');
var tmplNonDepEntityFormDBAView = require('../templates/NonDepEntityFormDBAView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplNonDepEntityFormDBAView,

  className: 'nondep-entity-form-dba-view panel panel-default',

  ui: {
    removeDBA: '.remove-dba',
    fictitiousNameRegistrationContainer: '.fictitious-name-registration-container',
    datePickerFields: '.date-picker'
  },

  regions: {
    fictitiousNameRegistrationContainer: '@ui.fictitiousNameRegistrationContainer'
  },

  triggers: {
    'click @ui.removeDBA': 'remove:dba'
  },

  templateHelpers: function() {
    return {
      childIndex: this.options.childIndex + 1
    };
  },

  onBeforeShow: function() {
    var registrationDate = this.model.get('registrationDate');
    if (registrationDate) {
      this.model.set('registrationDate', moment(registrationDate, 'YYYY-MM-DD').format('MM/DD/YYYY'));
    }

    var endDate = this.model.get('endDate');
    if (endDate) {
      this.model.set('endDate', moment(endDate, 'YYYY-MM-DD').format('MM/DD/YYYY'));
    }
    Syphon.deserialize(this, this.model.toJSON());
    this.ui.datePickerFields.datepicker({});

    this.fictitiousNameRegistration = new FileUploadView({
      isRequired: true,
      isReadOnly: this.options.isReadOnly,
      collection: makeUploadCollection(this.model.get('fictitiousNameDocument')),
      metadata: {
        documentTypeId: 10017
      }
    });
    this.showChildView('fictitiousNameRegistrationContainer', this.fictitiousNameRegistration);
  },

  validate: function(config) {
    return this.fictitiousNameRegistration.validate(config);
  },

  getFormData: function() {
    var formData = Syphon.serialize(this);
    var fictitiousNameRegistrationFileId = this.fictitiousNameRegistration.getFirstFileId();
    formData.fictitiousDocumentId = fictitiousNameRegistrationFileId;
    formData.registrationDate = momentUtil.formatStr(formData.registrationDate, momentUtil.defaultOutboundDateConfig);
    formData.endDate = momentUtil.formatStr(formData.endDate, momentUtil.defaultOutboundDateConfig);

    return formData;
  }
});
