/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var _get = require('lodash.get');

var FileUploadView = require('psa-marionette/views/FileUploadView');

var FilesCollectionView = require('./FilesCollectionView');

var fileUploadConfig = require('../../fileupload/fileUploadConfig');

require('./FileUploadView.css');

module.exports = FileUploadView.extend({
  initialize: function() {
    if (FileUploadView.prototype.initialize) {
      FileUploadView.prototype.initialize.apply(this, arguments);
    }
    this.listenTo(this.collection, 'add', this.onModelAdd);
    this.listenTo(this.collection, 'remove', this.onModelRemove);
    this.listenTo(this.collection, 'remove upload:canceled', this.onFileRemove);
  },

  options: function() {
    var newDefaults = _.extend({}, FileUploadView.prototype.options, fileUploadConfig);
    return newDefaults;
  },

  onRender: function() {
    this.showChildView(
      'filesCollection',
      new FilesCollectionView({
        collection: this.collection
      })
    );
    if (!this.options.allowMultipleFiles) {
      this.$('input[type="file"]').removeAttr('multiple');
      this.$('.upload').text(this.options.uploadSingleLabel);
      this.$('.upload').after(
        '<p class="input-label upload-notice"><i class="fa fa-info-circle"></i> Maximum file size per attachment: 300 MB.</p>'
      );
      if (this.collection.length > 0) {
        this.$('.upload').hide();
        this.$('.upload-notice').hide();
      }
    } else {
      this.$('.upload').after(
        '<p class="input-label upload-notice"><i class="fa fa-info-circle"></i> Maximum file size per attachment: 300 MB. Multiple attachments allowed.</p>'
      );
    }
    if (this.options.isReadOnly) {
      this.$('.upload').hide();
      this.$('.upload-notice').hide();
    }
  },

  onBeforeShow: function() {
    // DO NOT DELETE THIS
    // Purposefully do nothing here, what happens hear is now done in onRender safely
  },

  validate: function(config) {
    var defer = $.Deferred();
    var validationType = _get(config, 'type', 'submit', 'save');
    if (validationType === 'submit' && this.options.isRequired) {
      this.removeValidationErrors();
      var hasOneSuccessfulFile = true;
      if (!this.collection.length) {
        hasOneSuccessfulFile = false;
      } else {
        hasOneSuccessfulFile = this.collection.some(function(model) {
          return model.get('fileId');
        });
      }
      if (!hasOneSuccessfulFile) {
        var $formGroup = this.$el.closest('.form-group');
        $formGroup.addClass('has-error');
        this.$el.after('<span class="validation-error-container help-block">This value is required</span>');
        defer.reject();
      } else {
        defer.resolve();
      }
    } else if (validationType === 'save') {
      this.removeValidationErrors();
      defer.resolve();
    } else {
      defer.resolve();
    }
    return defer.promise();
  },

  getFirstFileId: function() {
    if (!this.collection.length || !this.collection.at(0)) {
      return null;
    }
    if (!this.collection.at(0).get('fileId')) {
      return null;
    }
    return this.collection.at(0).get('fileId');
  },

  removeValidationErrors: function() {
    var $formGroup = this.$el.closest('.form-group');
    $formGroup.removeClass('has-error');
    $formGroup.find('.validation-error-container').remove();
  },

  // Method that is called whenever an item is added to the collection
  onModelAdd: function() {
    if (FileUploadView.prototype.hideUploadForSingleFile) {
      FileUploadView.prototype.hideUploadForSingleFile.apply(this, arguments);
      if (!this.options.allowMultipleFiles) {
        this.$('.upload-notice').hide();
      }
    }
    this.removeValidationErrors();
  },

  // Method that is called whenever an item is added to the collection
  onModelRemove: function() {
    if (FileUploadView.prototype.resetSingleFileUpload) {
      FileUploadView.prototype.resetSingleFileUpload.apply(this, arguments);
      if (!this.options.allowMultipleFiles) {
        this.$('.upload-notice').show();
      }
    }
  },

  // Method that is called whenever an item is added to the collection
  onFileRemove: function() {
    if (FileUploadView.prototype.onFileRemove) {
      FileUploadView.prototype.onFileRemove.apply(this, arguments);
    }
  }
});
