/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var modelGet = require('common/util/modelGet');
var Marionette = require('backbone.marionette');

var GridView = require('psa-marionette/views/GridView');
var FileUploadCell = require('common/backgrid/FileUploadCell');
var ButtonCell = require('common/backgrid/ButtonCell');

var BooleanCell = require('common/backgrid/BooleanCell');

var tmplExamQuestionFileUploadTableView = require('../templates/ExamQuestionFileUploadTableView.hbs');

var ExamQuestionFileUploadTableGridView = GridView.extend({
  className: 'exam-question-file-upload-table-grid-view',

  onBeforeRender: function() {
    if (GridView.prototype.onBeforeRender) {
      GridView.prototype.onBeforeRender.apply(this, arguments);
    }
    this.options.columns = [
      {
        name: 'file',
        label: 'Document',
        cell: FileUploadCell.extend({
          allowMultipleFiles: false,
          isReadOnly: this.options.isReadOnly,
          metadata: this.options.metadata
        }),
        headerCell: 'custom',
        width: 90,
        editable: false,
        sortable: false
      },
      {
        name: 'removeFile',
        label: '',
        cell: ButtonCell.extend({
          name: 'Remove',
          buttonClass: 'btn-danger btn-sm',
          onClick: this.onClickRemoveFile.bind(this)
        }),
        headerCell: 'custom',
        width: 10,
        editable: false,
        sortable: false
      }
    ];

    var mode = this.options.mode;
    var isInternal = mode === 'record-results' || mode === 'review-results';
    var isInternalRecording = mode === 'record-results';

    if (isInternal) {
      this.options.columns.splice(1, 1, {
        name: 'isRetained',
        label: 'Retain?',
        cell: BooleanCell,
        headerCell: 'custom',
        width: 10,
        editable: isInternalRecording,
        sortable: false
      });
    }
  },

  onClickRemoveFile: function(e, model) {
    e.preventDefault();
    this.collection.remove(model);
  }
});

module.exports = Marionette.LayoutView.extend({
  template: tmplExamQuestionFileUploadTableView,

  className: 'exam-question-file-upload-table-view',

  ui: {
    addFileRow: '.add-file-row',
    fileGridContainer: '.file-grid-container',
    validationAlert: '.validation-alert',
    validationAlertText: '.validation-alert-text'
  },

  regions: {
    fileGridContainer: '@ui.fileGridContainer'
  },

  events: {
    'click @ui.addFileRow': 'onClickAddFileRow'
  },

  onRender: function() {
    if (!this.collection) {
      this.collection = new Backbone.Collection();
    }

    var mode = this.options.mode;
    var isInternal = mode === 'record-results' || mode === 'review-results';

    this.fileUploadTableGridView = new ExamQuestionFileUploadTableGridView({
      isReadOnly: isInternal,
      emptyText: this.options.emptyText,
      collection: this.collection,
      mode: this.options.mode,
      metadata: this.options.metadata
    });
    if (isInternal) {
      this.ui.addFileRow.hide();
    }
    this.showChildView('fileGridContainer', this.fileUploadTableGridView);

    this.$('.table').addClass('table-bordered');
  },

  onClickAddFileRow: function(e) {
    e.preventDefault();
    this.collection.add({});
  },

  validate: function() {
    var defer = $.Deferred();
    this.ui.validationAlert.hide();
    this.ui.validationAlertText.empty();
    var missingDocuments = false;
    this.collection.each(function(model) {
      missingDocuments = missingDocuments || !modelGet(model, 'file[0].fileId');
    });
    if (missingDocuments) {
      this.ui.validationAlertText.append('Some rows are missing documents, each must have a document uploaded. ');
      this.ui.validationAlert.show();
      defer.reject();
    } else {
      defer.resolve();
    }
    return defer.promise();
  },

  getFormData: function() {
    return this.collection.map(function(model) {
      return {
        documentId: modelGet(model, 'file[0].fileId'),
        documentTypeId: modelGet(model, 'file[0].metadata.documentTypeId'),
        isRetained: modelGet(model, 'isRetained') ? '1' : '0'
      };
    });
  },

  getRetainedDocuments: function() {
    return this.collection.reduce(function(memo, model) {
      var document = model.toJSON();
      if (document.isRetained) {
        memo.push(document.id);
      }
      return memo;
    }, []);
  }
});
