/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var _get = require('lodash.get');

var cleanUpResponse = require('common/util/cleanUpResponse');

module.exports = function(formData) {
  var deferred = $.Deferred();

  $.soap({
    body: {
      SubmitInvestigationInquiry: {
        $xmlns: 'http://dobs.pa.gov/sc/investigationinquiry/bpm/1.0',
        InvInqID: formData.invInqId,
        ObjectTypeID: formData.objectTypeId,
        IsOrganization: formData.isOrganization,
        ObjectID: formData.objectId,
        ObjectLicenseID: formData.objectLicenseId,
        PrimaryExaminer: formData.primaryExaminer,
        CaseNumber: formData.caseNumber,
        CategoryID: formData.categoryId,
        StatusID: formData.statusId,
        OpenedDate: formData.openedDate,
        ClosedDate: formData.closedDate,
        UpgradedDate: formData.upgradedDate,
        TypeID: formData.typeId,
        OfficeAssignedID: formData.officeAssignedId,
        Description: formData.description,
        IsVictimsSenior: formData.isVictimsSenior,
        SeniorVictimCount: formData.seniorVictimCount,
        OtherViolation: formData.otherViolation,
        HasNextStep: formData.hasNextStep,
        IsDeleted: formData.isDeleted,
        Violations: (function() {
          if (!_get(formData, 'violations.length')) {
            return {};
          }
          return _.map(formData.violations, function(violationId) {
            return {
              ViolationTypeID: violationId
            };
          });
        })()
      }
    },
    blueprint: {
      invInqId: '$..InvInqID.__text'
    }
  })
    .done(function(response) {
      cleanUpResponse(response);
      deferred.resolve(response.invInqId);
    })
    .fail(function(err) {
      deferred.reject();
    });

  return deferred.promise();
};
