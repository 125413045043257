var Handlebars = require("handlebars/dist/handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "        <option value=\""
    + alias4(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\">"
    + alias4(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data}) : helper)))
    + "</option>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "\n<div class=\"side-bar-container pull-left\"></div>\n<div class=\"dashboard-container pull-right\">\n  <div class=\"breadcrumb-container\"></div>\n  <div class=\"dashboard-title\">\n    <h2>"
    + container.escapeExpression(((helper = (helper = helpers.examYear || (depth0 != null ? depth0.examYear : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"examYear","hash":{},"data":data}) : helper)))
    + " Exam Year - Issue Exams</h2>\n  </div>\n  <div class=\"alert alert-danger collapse validation-alert\" id=\"noLicenseSelectedAlert\" role=\"alert\">\n    <i class=\"fa fa-exclamation-triangle\" aria-hidden=\"true\"></i>\n    <span class=\"validation-alert-text\">To issue exam(s), please select at least one license.</span>\n  </div>\n  <div class=\"alert alert-danger collapse validation-alert\" id=\"missingRegionAlert\" role=\"alert\">\n    <i class=\"fa fa-exclamation-triangle\" aria-hidden=\"true\"></i>\n    <span class=\"validation-alert-text\">Please set a region for each selected license.</span>\n  </div>\n  <div class=\"row\">\n    <div class=\"col-sm-6\">\n      <label class=\"control-label\">Select License Type</label>\n      <select name=\"licenseType\" id=\"licenseType\" class=\"form-control\" multiple=\"multiple\">\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.licenseTypes : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "      </select>\n    </div>\n    <button class=\"btn btn-default\" id=\"apply\">Apply</button>\n  </div>\n  <div class=\"licenses-container\"></div>\n  <button class=\"btn btn-primary pull-left m-b-md m-r-md ladda-button\" id=\"issue\"><span class=\"ladda-label\">Issue\n      Selected Exams</span></button>\n</div>";
},"useData":true});