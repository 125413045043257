/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var moment = require('moment');

module.exports = function(formData) {
  var deferred = $.Deferred();
  formData.effectiveStartDate = moment(formData.effectiveStartDate, 'MM/DD/YYYY').isValid()
    ? moment(formData.effectiveStartDate, 'MM/DD/YYYY').format('YYYY-MM-DD')
    : '';

  formData.effectiveEndDate = moment(formData.effectiveEndDate, 'MM/DD/YYYY').isValid()
    ? moment(formData.effectiveEndDate, 'MM/DD/YYYY').format('YYYY-MM-DD')
    : '';

  $.soap({
    body: {
      SubmitCreateNewLicense: {
        $xmlns: 'http://dobs.pa.gov/nd/license/bpm/1.0',
        LicenseTypeId: formData.licenseTypeId,
        ParentLicenseId: formData.parentLicenseId,
        LocationId: formData.locationId,
        LicenseStatusId: formData.licenseStatusId,
        EffectiveStartDate: formData.effectiveStartDate,
        EffectiveEndDate: formData.effectiveEndDate
      }
    },
    blueprint: {
      licenseId: '$..LicenseId'
    }
  })
    .done(function(response) {
      deferred.resolve(response.licenseId);
    })
    .fail(function(err) {
      deferred.reject();
    });

  return deferred.promise();
};
