/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var Marionette = require('backbone.marionette');
var Radio = require('backbone.radio');
var Ladda = require('ladda');

var SpinnerView = require('common/views/SpinnerView');

var SubmitAttachments = require('../services/SubmitAttachments');

require('../styles/AttachmentModalView.css');
var tmplAttachmentModalView = require('../templates/AttachmentModalView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplAttachmentModalView,

  className: 'attachment-modal-view',

  ui: {
    modalBody: '.modal-body',
    fileUploadTableContainer: '.file-upload-table-container',
    submit: '.submit'
  },

  regions: {
    fileUploadTableContainer: '@ui.fileUploadTableContainer'
  },

  events: {
    'click @ui.submit': 'onClickSubmit'
  },

  onBeforeShow: function() {
    this.showChildView('fileUploadTableContainer', new SpinnerView());
    var data = {
      departmentName: this.model.get('departmentName'),
      isReadOnly: false
    };
    Radio.channel('cm')
      .request('build:file-upload-table', data)
      .done(
        function(fileUploadTableView) {
          this.documentTypes = _.chain(fileUploadTableView.options.documentTypes)
            .indexBy('value')
            .mapObject('display')
            .value();
          this.ui.modalBody.removeClass('loading');
          this.fileUploadTableView = fileUploadTableView;
          this.showChildView('fileUploadTableContainer', this.fileUploadTableView);
        }.bind(this)
      );
  },

  onClickSubmit: function(e) {
    e.preventDefault();
    var self = this;
    this.fileUploadTableView.validate().done(function() {
      var l = Ladda.create(self.ui.submit[0]);
      l.start();
      var attachments = self.fileUploadTableView.collection.map(function(attachmentModel) {
        var documentTypeName = self.documentTypes[attachmentModel.get('documentType')];
        var file = attachmentModel.get('file')[0];
        return {
          objectTypeId: self.model.get('objectTypeId'),
          objectId: self.model.get('objectId'),
          documentId: file.fileId,
          file: {
            fileId: file.fileId,
            fileName: file.fileName
          },
          documentTypeId: attachmentModel.get('documentType'),
          documentType: documentTypeName
        };
      });
      var entityId = self.model.get('entityId');
      SubmitAttachments({ entityId: entityId, attachments: attachments })
        .done(function() {
          self.triggerMethod('modal:close', attachments);
        })
        .fail(function() {
          l.stop();
        });
    });
  }
});
