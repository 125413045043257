/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var moment = require('moment');

var cleanUpResponse = require('common/util/cleanUpResponse');

module.exports = function(entityId) {
  var deferred = $.Deferred();

  $.soap({
    body: {
      GetFilingsByEntity: {
        $xmlns: 'http://dobs.pa.gov/dp/filing/bpm/1.0',
        EntityID: entityId
      }
    },
    blueprintArrays: ['GetFilingsByEntityResponse.DP_Filing'],
    blueprint: {
      filings: [
        '$..DP_Filing',
        {
          filingId: '$..FilingID',
          entityId: '$..EntityID',
          recordStatusId: '$..RecordStatusID',
          recordStatus: '$..RecordStatus',
          action: '$..Action',
          filingTypeId: '$..FilingTypeID',
          filingType: '$..FilingType',
          acceptanceSentDate: '$..AcceptanceSentDate',
          acceptedDate: '$..AcceptedDate',
          comments: '$..Comments',
          submissionTypeName: '$..SubmissionTypeName',
          actionDate: '$..ActionDate'
        }
      ]
    }
  })
    .done(function(response) {
      cleanUpResponse(response);

      // hide complete/close submissions and completed SEG filings (filingType 204 with record status of 5 - Approved, 6 - Approved-Outstanding, 7 - Denied)
      response.filings = _.reject(response.filings, function(filing) {
        return (
          (_.includes(['111', '205', '305', '306', '307'], filing.filingTypeId) && filing.recordStatusId === '14') ||
          (filing.filingTypeId === '204' && _.includes(['5', '6', '7'], filing.recordStatusId))
        );
      });

      _.each(response.filings, function(filing) {
        if (filing.acceptanceSentDate) {
          filing.acceptanceSentDate = moment(filing.acceptanceSentDate, 'YYYY-MM-DD');
        }
        if (filing.actionDate) {
          filing.actionDate = moment(filing.actionDate, 'YYYY-MM-DD');
        }

        //add submission type to filing type if generic submission
        if (_.includes(['100', '200', '300'], filing.filingTypeId) && filing.submissionTypeName) {
          filing.filingType = filing.filingType + ' - ' + filing.submissionTypeName;
        }
        filing.action = filing.action || '--';
        filing.filingType = filing.filingType || '--';
        filing.recordStatus = filing.recordStatus || '--';

        var comments = filing.comments;
        if (comments) {
          filing.comments = comments.length > 100 ? comments.substring(0, 100) + '...' : comments;
        } else {
          filing.comments = '--';
        }
      });
      deferred.resolve(response.filings);
    })
    .fail(function(err) {
      deferred.reject();
    });

  return deferred.promise();
};
