/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var cleanUpResponse = require('common/util/cleanUpResponse');

module.exports = function(applicationId) {
  var deferred = $.Deferred();
  var moment = require('moment');

  $.soap({
    body: {
      GetLicenseDBAEditApplication: {
        $xmlns: 'http://dobs.pa.gov/nd/application/bpm/1.0',
        ApplicationID: applicationId
      }
    },
    blueprintArrays: [
      'GetLicenseDBAEditApplicationResponse.ApplicationDocuments.ND_ApplicationDocument',
      'GetLicenseDBAEditApplicationResponse.ND_LicenseDBAs.ND_LicenseDBA'
    ],
    blueprint: {
      entityId: '$..EntityID.__text',
      licenseDBAs: [
        '$..ND_LicenseDBA',
        {
          id: '$..ID',
          dbaName: '$..DBA',
          fictitiousNameDocument: {
            fileId: '$..FictitiousDocumentID',
            fileName: '$..FictitiousDocumentName'
          },
          registrationDate: '$..RegistrationDate',
          endDate: '$..EndDate',
          isChecked: '$..IsChecked'
        }
      ],
      licenseId: '$..LicenseID.__text',
      certificationName: '$..CertificationName.__text',
      isCertified: '$..IsCertified.__text',
      effectiveChangeDate: '$..EffectiveDate.__text',
      applicationDocuments: [
        '$..ND_ApplicationDocument',
        {
          fileId: '$..DocumentID',
          fileName: '$..Name'
        }
      ]
    }
  })
    .done(function(response) {
      cleanUpResponse(response);
      response.effectiveChangeDate = moment(response.effectiveChangeDate, 'YYYY-MM-DD').format('MM/DD/YYYY');
      deferred.resolve(response);
    })
    .fail(function(err) {
      deferred.reject();
    });

  return deferred.promise();
};
