/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2020] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Backbone = require('backbone');
var Marionette = require('backbone.marionette');
var Radio = require('backbone.radio');
var Ladda = require('ladda');

var BreadcrumbView = require('common/views/BreadcrumbView');

var ConSerEntityFormView = require('./ConSerEntityFormView');

var SubmitEntity = require('../services/SubmitEntity');

var tmplConSerEntityEditView = require('../templates/ConSerEntityEditView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplConSerEntityEditView,

  className: 'con-ser-entity-edit-view container-fluid',

  ui: {
    breadcrumb: '.breadcrumb-container',
    entityContainer: '.entity-form-container',
    submit: '.submit'
  },

  regions: {
    breadcrumb: '@ui.breadcrumb',
    entityContainer: '@ui.entityContainer'
  },

  events: {
    'click @ui.submit': 'onClickSubmit'
  },

  onBeforeShow: function() {
    var entity = this.model.get('entity') || {};
    var entityId = entity.entityId;

    this.showChildView(
      'breadcrumb',
      new BreadcrumbView({
        breadcrumbs: [
          {
            link: '#conser/dashboard',
            text: 'Home'
          },
          {
            link: '#conser/4000/entity/' + entityId + '/dashboard',
            text: entity.entityName
          },
          {
            text: 'Edit Entity Details'
          }
        ]
      })
    );

    this.entityForm = new ConSerEntityFormView({
      model: new Backbone.Model(this.model.get('entity'))
    });
    this.showChildView('entityContainer', this.entityForm);
  },

  onClickSubmit: function(e) {
    e.preventDefault();
    var self = this;
    this.validate().done(function() {
      var l = Ladda.create(self.ui.submit[0]);
      l.start();
      var formData = self.getFormData();
      SubmitEntity(formData)
        .done(function() {
          Radio.channel('navigate').trigger(
            'show:int:cs:entity',
            self.model.get('departmentId'),
            self.model.get('entityId')
          );
        })
        .fail(function() {
          l.stop();
        });
    });
  },

  getFormData: function() {
    var formData = this.entityForm.getFormData();
    var entity = this.model.get('entity') || {};

    formData.entityId = entity.entityId;

    return formData;
  },

  validate: function() {
    return this.ui.entityContainer.parsley().whenValidate();
  }
});
