/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Backbone = require('backbone');
var Marionette = require('backbone.marionette');

var FileUploadCollection = require('psa-backbone/models/FileUploadCollection');

var FileUploadView = require('common/views/FileUploadView');

var AddressDisplayView = require('common/views/AddressDisplayView');

require('../styles/NonDepEntitySectionIdentificationView.css');
var tmplNonDepEntitySectionIdentificationView = require('../templates/NonDepEntitySectionIdentificationView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplNonDepEntitySectionIdentificationView,

  className: 'nondep-entity-section-identification-view',

  ui: {
    dba: '.doing-business-as',
    foreignEntityInfo: '.foreign-entity-information',
    aosAddress: '.agent-of-service-address',
    articlesOfIncorporationContainer: '.articles-of-incorporation-container',
    operatingAgreementContainer: '.operating-agreement-container',
    isOfficerConvictedDocumentDocumentContainer: '.is-officer-convicted-document',
    byLawsContainer: '.by-laws-container',
    foreignRegistrationContainer: '.foreign-registration-container'
  },

  regions: {
    dba: '@ui.dba',
    aosAddress: '@ui.aosAddress',
    articlesOfIncorporationContainer: '@ui.articlesOfIncorporationContainer',
    operatingAgreementContainer: '@ui.operatingAgreementContainer',
    byLawsContainer: '@ui.byLawsContainer',
    isOfficerConvictedDocumentDocumentContainer: '@ui.isOfficerConvictedDocumentDocumentContainer',
    foreignRegistrationContainer: '@ui.foreignRegistrationContainer'
  },

  templateHelpers: function() {
    return {
      isNMLSEntity: this.model.get('isNMLSEntity')
    };
  },

  onBeforeShow: function() {
    if (this.model.get('articlesOfIncorporationDocument').fileId) {
      this.articlesOfIncorporation = new FileUploadView({
        isReadOnly: true,
        collection: new FileUploadCollection([this.model.get('articlesOfIncorporationDocument')])
      });
      this.showChildView('articlesOfIncorporationContainer', this.articlesOfIncorporation);
    }

    if (this.model.get('operatingAgreementDocument').fileId) {
      this.operatingAgreement = new FileUploadView({
        isReadOnly: true,
        collection: new FileUploadCollection([this.model.get('operatingAgreementDocument')])
      });
      this.showChildView('operatingAgreementContainer', this.operatingAgreement);
    }

    if (this.model.get('byLawsDocument').fileId) {
      this.byLaws = new FileUploadView({
        isReadOnly: true,
        collection: new FileUploadCollection([this.model.get('byLawsDocument')])
      });
      this.showChildView('byLawsContainer', this.byLaws);
    }

    if (this.model.get('isOfficerConvicted') === '1') {
      this.isOfficerConvictedDocumentDocument = new FileUploadView({
        isReadOnly: true,
        collection: new FileUploadCollection([this.model.get('isOfficerConvictedDocument')])
      });
      this.showChildView('isOfficerConvictedDocumentDocumentContainer', this.isOfficerConvictedDocumentDocument);
    }

    if (this.model.get('isForeignEntity')) {
      this.ui.foreignEntityInfo.removeClass('hidden');

      this.showChildView(
        'aosAddress',
        new AddressDisplayView({
          model: new Backbone.Model(this.model.get('agentOfService').address)
        })
      );

      if (this.model.get('foreignNameRegistrationDocument').fileId) {
        this.foreignRegistration = new FileUploadView({
          isReadOnly: true,
          collection: new FileUploadCollection([this.model.get('foreignNameRegistrationDocument')])
        });
        this.showChildView('foreignRegistrationContainer', this.foreignRegistration);
      }
    }
  }
});
