/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');

module.exports = function(data) {
  return $.soap({
    body: {
      SubmitAO1ApplicationReviewTask: {
        $xmlns: 'http://dobs.pa.gov/nd/task/bpm/1.0',
        TaskID: data.taskId,
        ApplicationID: data.applicationId,
        ReviewDecisionID: data.reviewDecisionId,
        DecisionReason: data.decisionReason,
        ExternalReviewComments: data.externalReviewComments,
        ApplicationLicenseStatusID: data.applicationLicenseStatusId,
        ApplicationInvestigation: {
          EntityHasCriminal: data.entityHasCriminal ? '1' : '0',
          EntityHasFinancial: data.entityHasFinancial ? '1' : '0',
          EntityHasRegulatory: data.entityHasRegulatory ? '1' : '0',
          EntityHasCivil: data.entityHasCivil ? '1' : '0',
          Individuals: (function() {
            if (!data.individualInvestigations || !data.individualInvestigations.length) {
              return {};
            }
            return {
              Individual: _.map(data.individualInvestigations, function(individualInvestigation) {
                return {
                  IndividualID: individualInvestigation.individualId,
                  HasCriminal: individualInvestigation.hasCriminal ? '1' : '0',
                  HasFinancial: individualInvestigation.hasFinancial ? '1' : '0',
                  HasRegulatory: individualInvestigation.hasRegulatory ? '1' : '0',
                  HasCivil: individualInvestigation.hasCivil ? '1' : '0'
                };
              })
            };
          })()
        },
        LicenseBonds: (function() {
          if (!data.licenseBonds || !data.licenseBonds.length) {
            return {};
          }
          return {
            LicenseBond: _.map(data.licenseBonds, function(licenseBond) {
              return {
                BondCompanyID: licenseBond.bondCompanyId,
                BondNumber: licenseBond.bondNumber,
                BondAmount: licenseBond.bondAmount,
                Expiration: licenseBond.expiration,
                RequiredTo: licenseBond.requiredTo,
                StatusID: licenseBond.statusId,
                TypeID: licenseBond.typeId,
                BondTypeOther: licenseBond.bondTypeOther,
                LicenseRequirement: licenseBond.licenseRequirement
              };
            })
          };
        })()
      }
    }
  });
};
