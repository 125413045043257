/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var cleanUpResponse = require('common/util/cleanUpResponse');

module.exports = function(entityId) {
  var deferred = $.Deferred();

  $.soap({
    body: {
      GetEntitySiteRecordKeeping: {
        $xmlns: 'http://dobs.pa.gov/nd/entity/bpm/1.0',
        EntityID: entityId
      }
    },
    blueprintArrays: ['GetEntitySiteRecordKeepingResponse.SiteRecordKeepings.ND_EntitySiteRecordkeeping'],
    blueprint: {
      instructionsDocument: {
        instructionsDocId: '$..InstructionsDocID',
        instructionsDocumentName: '$..InstructionsDocumentName'
      },
      siteRecordKeepings: [
        '$..ND_EntitySiteRecordkeeping',
        {
          id: '$.ID',
          companyName: '$.CompanyName',
          firstName: '$.FirstName',
          lastName: '$.LastName',
          phone: '$.Phone',
          email: '$.Email',
          fax: '$.Fax',
          description: '$.Description',
          address: {
            addressLine1: '$.AddressLine1',
            addressLine2: '$.AddressLine2',
            addressLine3: '$.AddressLine3',
            city: '$.City',
            state: '$.State',
            postalCode: '$.PostalCode',
            country: '$.Country'
          }
        }
      ]
    }
  })
    .done(function(response) {
      cleanUpResponse(response);
      deferred.resolve(response);
    })
    .fail(function(err) {
      deferred.reject();
    });
  return deferred.promise();
};
