/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var _get = require('lodash.get');
var Backbone = require('backbone');
var Marionette = require('backbone.marionette');
var Syphon = require('backbone.syphon');
var Radio = require('backbone.radio');
var Ladda = require('ladda');

var UIUtil = require('psa-core/util/ui');
var GridView = require('psa-marionette/views/GridView');

var ParagraphCell = require('common/backgrid/ParagraphCell');
var MultiButtonCell = require('common/backgrid/MultiButtonCell');
var MomentCell = require('common/backgrid/MomentCell');
var FileUploadView = require('common/views/FileUploadView');
var toggleForm = require('common/util/toggleForm');
var modelGet = require('common/util/modelGet');
var BreadcrumbView = require('common/views/BreadcrumbView');
var momentUtil = require('common/util/momentUtil');
var makeUploadCollection = require('common/util/makeUploadCollection');

var SubmitEntityExamMain = require('../services/SubmitEntityExamMain');
var DepExamProcessingResponseModalView = require('./DepExamProcessingResponseModalView');
var DepExamTSPNewParticipatingAgencyModalView = require('./DepExamTSPNewParticipatingAgencyModalView');
var DepExamTSPNewPriorExamModalView = require('./DepExamTSPNewPriorExamModalView');

var paGridView;
var priorGridView;

var tmplDepExamMainEditView = require('../templates/DepExamMainEditView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplDepExamMainEditView,

  className: 'dep-exam-main-edit-view container-fluid',

  ui: {
    form: 'form',
    editInfoForm: '.edit-form',
    editTspInfoForm: '.edit-tsp-form',
    breadcrumb: '.breadcrumb-container',
    datepicker: '.datepicker',

    conductedByCheckboxes: 'input[name=conductedBys]',
    examAreaCheckboxes: 'input[name=areas]',
    hcExamCheckboxes: 'input[name=isHoldingCompanyExam]',

    isHCExam: '.is-hc-exam',
    examAreaLabel: '.exam-area-label',

    ssSection: '.ss-container',
    ssIsNotifyEntity: 'input[name="ssArea[isNotifyEntity]"]',
    ssFirstDayLetterSection: '.ss-first-day-letter-section',
    ssFirstDayLetter: '.ss-first-day-letter-section .first-day-letter-container',
    ssFirstDayLetterDefault: '.ss-container .first-day-letter-default',
    ssEic: '.ss-container .eic-container',
    ssFedEic: '.ss-container .fed-eic-container',
    ssFirstDayLetterSent: 'input[name="ssArea[firstDayLetterDate]"]',
    ssExamHours: '.ss-container .exam-hours-container',

    trustSection: '.trust-container',
    trustIsNotifyEntity: 'input[name="trustArea[isNotifyEntity]"]',
    trustFirstDayLetterSection: '.trust-first-day-letter-section',
    trustFirstDayLetter: '.trust-container .first-day-letter-container',
    trustFirstDayLetterDefault: '.trust-container .first-day-letter-default',
    trustEic: '.trust-container .eic-container',
    trustFedEic: '.trust-container .fed-eic-container',
    trustFirstDayLetterSent: 'input[name="trustArea[firstDayLetterDate]"]',
    trustExamHours: '.trust-container .exam-hours-container',

    itSection: '.it-container',
    itIsNotifyEntity: 'input[name="itArea[isNotifyEntity]"]',
    itFirstDayLetterSection: '.it-first-day-letter-section',
    itFirstDayLetter: '.it-container .first-day-letter-container',
    itFirstDayLetterSent: 'input[name="itArea[firstDayLetterDate]"]',
    itFirstDayLetterDefault: '.it-container .first-day-letter-default',
    itEic: '.it-container .eic-container',
    itFedEic: '.it-container .fed-eic-container',
    itExamHours: '.it-container .exam-hours-container',

    complianceSection: '.compliance-container',
    craSection: '.cra-container',
    bhcSection: '.bhc-container',

    editForm: '.edit-info-container',
    editTSPForm: '.edit-tsp-info-container',

    examYear: '.exam-year',
    firstDayLetterSent: '.first-day-letter-container',

    eicSections: '.eic-container',
    federalEICSections: '.fed-eic-container',

    examTypeSelect: '.exam-type-select',
    leadAgencySelect: '.lead-agency-select',
    tspLeadAgencySelect: '.tsp-lead-agency-select',
    otherAgency: '.other-agency-in-charge',
    tspScopeSelect: '.tsp-exam-scope-select',
    otherScope: '.other-scope',

    participatingAgencyGrid: '.participating-agency-grid-container',
    priorExamGrid: '.prior-exam-grid-container',

    // Checkboxes Error Containers
    conductedBysErrorContainer: '.conductedBys-error-container',
    areasErrorContainer: '.areas-error-container',

    // Processing Section
    examProcessType: '.process-type-container',
    bankFedAgency: '.bank-fed-agency-field',
    jointOtherSection: '.joint-other-container',
    saerCompleteDateContainer: '.saer-complete-date-container',
    completionDateContainer: '.completion-date-container',

    ssEICSignOff: '.ss-eic-signoff-container',
    itEICSignOff: '.it-eic-signoff-container',
    trustEICSignOff: '.trust-eic-signoff-container',

    addResponse: '.btn-add-response',
    responseGrid: '.response-grid-container',

    examDueDate: '.exam-due-date',

    submit: '.btn-submit',
    addParticipatingAgency: '.add-participating-agency',
    addPriorExam: '.add-prior-exam'
  },

  regions: {
    breadcrumb: '@ui.breadcrumb',
    ssFirstDayLetter: '@ui.ssFirstDayLetter',
    ssExamHours: '@ui.ssExamHours',
    trustFirstDayLetter: '@ui.trustFirstDayLetter',
    itFirstDayLetter: '@ui.itFirstDayLetter',
    itExamHours: '@ui.itExamHours',
    responseGrid: '@ui.responseGrid',
    editForm: '@ui.editForm',
    editTSPForm: '@ui.editTSPForm',
    participatingAgencyGrid: '@ui.participatingAgencyGrid',
    priorExamGrid: '@ui.priorExamGrid',
    trustExamHours: '@ui.trustExamHours'
  },

  events: {
    'change @ui.hcExamCheckboxes': 'onChangeIsHCExam',
    'change @ui.examAreaCheckboxes': 'onChangeExamArea',
    'change @ui.conductedByCheckboxes': 'onChangeConductedBy',
    'change @ui.examTypeSelect': 'onExamTypeSelect',
    'change @ui.leadAgencySelect': 'onLeadAgencySelect',
    'change @ui.tspScopeSelect': 'onTSPScopeSelect',
    'change @ui.tspLeadAgencySelect': 'onTSPLeadAgencySelect',
    'click @ui.addResponse': 'onClickAddResponse',
    'click @ui.submit': 'onClickSubmit',
    'click @ui.addParticipatingAgency': 'onClickAddAgency',
    'click @ui.addPriorExam': 'onClickAddPriorExam',
    'click @ui.itIsNotifyEntity': 'onClickitIsNotifyEntity',
    'click @ui.trustIsNotifyEntity': 'onChangeTrustIsNotifyEntity',
    'click @ui.ssIsNotifyEntity': 'onChangeSSIsNotifyEntity'
  },

  templateHelpers: function() {
    var isBank = this.model.get('entityCategoryId') === '1';
    var examAreas = modelGet(this.model, 'lookups.examAreas');
    var examiners = modelGet(this.model, 'lookups.examiners', []);

    var ssExaminers = _.clone(examiners);
    var ssExaminer = modelGet(this.model, 'exam.ssArea.eic');
    var ssExaminerFirstName = modelGet(this.model, 'exam.ssArea.eicFirstName');
    var ssExaminerLastName = modelGet(this.model, 'exam.ssArea.eicLastName');
    var isSSNotifyEntity = modelGet(this.model, 'exam.ssArea.isNotifyEntity');

    var trustExaminers = _.clone(examiners);
    var trustExaminer = modelGet(this.model, 'exam.trustArea.eic');
    var trustExaminerFirstName = modelGet(this.model, 'exam.trustArea.eicFirstName');
    var trustExaminerLastName = modelGet(this.model, 'exam.trustArea.eicLastName');
    var isTrustNotifyEntity = modelGet(this.model, 'exam.trustArea.isNotifyEntity');

    var itExaminers = _.clone(examiners);
    var itExaminer = modelGet(this.model, 'exam.itArea.eic');
    var itExaminerFirstName = modelGet(this.model, 'exam.itArea.eicFirstName');
    var itExaminerLastName = modelGet(this.model, 'exam.itArea.eicLastName');
    var isITNotifyEntity = modelGet(this.model, 'exam.itArea.isNotifyEntity');

    /*
     * Current eics could no longer have examiner role, add current eic for
     * each area with internal user data if username does not exist in
     * examiners array
     */
    var currentSSExaminerHasRole = _.find(examiners, function(cm) {
      return ssExaminer === cm.userId;
    });

    if (ssExaminer && !currentSSExaminerHasRole) {
      var currentSSExaminer = {
        userId: ssExaminer,
        name: !ssExaminerFirstName && !ssExaminerLastName ? '' : ssExaminerFirstName + ' ' + ssExaminerLastName
      };
      ssExaminers.splice(_.sortedIndex(examiners, currentSSExaminer, 'name'), 0, currentSSExaminer);
    }

    var currentTrustExaminerHasRole = _.find(examiners, function(cm) {
      return trustExaminer === cm.userId;
    });

    if (trustExaminer && !currentTrustExaminerHasRole) {
      var currentTrustExaminer = {
        userId: trustExaminer,
        name:
          !trustExaminerFirstName && !trustExaminerLastName ? '' : trustExaminerFirstName + ' ' + trustExaminerLastName
      };
      trustExaminers.splice(_.sortedIndex(examiners, currentTrustExaminer, 'name'), 0, currentTrustExaminer);
    }

    var currentITExaminerHasRole = _.find(examiners, function(cm) {
      return itExaminer === cm.userId;
    });

    if (itExaminer && !currentITExaminerHasRole) {
      var currentITExaminer = {
        userId: itExaminer,
        name: !itExaminerFirstName && !itExaminerLastName ? '' : itExaminerFirstName + ' ' + itExaminerLastName
      };
      itExaminers.splice(_.sortedIndex(examiners, currentITExaminer, 'name'), 0, currentITExaminer);
    }

    return {
      isBank: isBank,
      ssAreaId: (_.findWhere(examAreas, { area: 'S&S' }) || {}).id,
      trustAreaId: (_.findWhere(examAreas, { area: 'Trust' }) || {}).id,
      itAreaId: (_.findWhere(examAreas, { area: 'Information Technology' }) || {}).id,
      complianceAreaId: (_.findWhere(examAreas, { area: 'Compliance' }) || {}).id,
      craAreaId: (_.findWhere(examAreas, { area: 'CRA' }) || {}).id,
      ssExaminers: ssExaminers,
      trustExaminers: trustExaminers,
      itExaminers: itExaminers,
      isSSNotifyEntity: isSSNotifyEntity === '1',
      isITNotifyEntity: isITNotifyEntity === '1',
      isTrustNotifyEntity: isTrustNotifyEntity === '1'
    };
  },

  onBeforeShow: function() {
    var entityId = this.model.get('entityId');
    var entityName = this.model.get('entityName');
    var examId = modelGet(this.model, 'exam.id');
    var examType = modelGet(this.model, 'exam.examType');
    var leadAgency = modelGet(this.model, 'exam.leadAgency');
    var entityTypeId = this.model.get('entityTypeId');
    this.showChildView(
      'breadcrumb',
      new BreadcrumbView({
        breadcrumbs: [
          {
            link: '#dep/dashboard',
            text: 'Home'
          },
          {
            link: '#dep/entity/' + entityId + '/dashboard',
            text: entityName
          },
          {
            link: '#dep/entity/' + entityId + '/exam/' + examId + '/dashboard',
            text: examType + ' - ' + leadAgency
          },
          {
            text: 'Edit Main'
          }
        ]
      })
    );

    var categoryId = this.model.get('entityCategoryId');

    // Bank
    var isBank = categoryId === '1';
    toggleForm(this.ui.saerCompleteDateContainer, isBank);

    // Credit Union
    if (categoryId === '2') {
      // Hide Bank Exam Federal Agency Fields
      this.ui.bankFedAgency.hide();
    } else {
      this.ui.completionDateContainer.hide();
    }
    // Trust
    if (categoryId === '3') {
      // Hide Bank Exam Federal Agency Fields
      this.ui.bankFedAgency.hide();

      toggleForm(this.ui.examProcessType, false);
    }
    toggleForm(this.ui.examDueDate, categoryId === '2' || categoryId === '3' || categoryId === '1');

    //Show S&S Exam Hours for trust or credit union
    toggleForm(this.ui.ssExamHours, categoryId === '2' || categoryId === '3');

    //Show IT Exam Hours for trust or credit union
    toggleForm(this.ui.itExamHours, categoryId === '2' || categoryId === '3');

    var isTSP = entityTypeId === '3' || entityTypeId === '12' || entityTypeId === '23';
    if (isTSP) {
      toggleForm(this.ui.editTSPForm, true);
      toggleForm(this.ui.editForm, false);

      var isOtherExamScope = modelGet(this.model, 'exam.examScopeId') === '13';
      toggleForm(this.ui.otherScope, isOtherExamScope);

      var isOtherLeadAgency =
        modelGet(this.model, 'exam.leadAgencyId') === '31' ||
        modelGet(this.model, 'exam.leadAgencyId') === '37' ||
        modelGet(this.model, 'exam.leadAgencyId') === '43';

      toggleForm(this.ui.otherAgency, isOtherLeadAgency);

      this.agencyCollection = new Backbone.Collection(modelGet(this.model, 'exam.examParticipatingAgencies', []));

      var participatingAgencyColumns = [
        {
          name: 'nameDisplay',
          label: 'Name',
          cell: 'string',
          editable: false,
          headerCell: 'custom',
          width: 30
        },
        {
          name: 'regionDisplay',
          label: 'Region/District',
          cell: 'string',
          editable: false,
          headerCell: 'custom',
          width: 30
        },
        {
          name: 'otherRegionDisplay',
          label: 'Other Region/District',
          cell: 'string',
          editable: false,
          headerCell: 'custom',
          width: 30
        },
        {
          name: '',
          label: '',
          cell: MultiButtonCell.extend({
            buttons: [
              {
                name: '',
                labelClass: 'fa fa-edit',
                buttonClass: 'btn-primary btn-sm edit-processing-response',
                onClick: this.onClickEditParticipatingAgency.bind(this)
              },
              {
                name: '',
                labelClass: 'fa fa-trash',
                buttonClass: 'btn-danger btn-sm delete-processing-response',
                onClick: this.onClickDeleteParticipatingAgency.bind(this)
              }
            ]
          }),
          editable: false,
          sortable: false
        }
      ];
      paGridView = new GridView({
        columns: participatingAgencyColumns,
        emptyText: 'No Participating Agencies',
        collection: this.agencyCollection
      });
      this.showChildView('participatingAgencyGrid', paGridView);

      this.priorExamCollection = new Backbone.Collection(modelGet(this.model, 'exam.examPriorExams', []));

      var priorExamColumns = [
        {
          name: 'priorExamDate',
          label: 'Date',
          cell: MomentCell.MomentCell,
          formatter: MomentCell.MomentDateFormatter.extend({
            emptyValue: '--'
          }),
          editable: false,
          headerCell: 'custom',
          width: 20
        },
        {
          name: 'ratingDisplay',
          label: 'Rating',
          cell: 'string',
          editable: false,
          headerCell: 'custom',
          width: 20
        },
        {
          name: 'leadAgencyDisplay',
          label: 'Agency In Charge',
          cell: 'string',
          editable: false,
          headerCell: 'custom',
          width: 20
        },
        {
          name: 'otherLeadAgencyDisplay',
          label: 'Other Agency In Charge',
          cell: 'string',
          editable: false,
          headerCell: 'custom',
          width: 20
        },
        {
          name: '',
          label: '',
          cell: MultiButtonCell.extend({
            buttons: [
              {
                name: '',
                labelClass: 'fa fa-edit',
                buttonClass: 'btn-primary btn-sm edit-processing-response',
                onClick: this.onClickEditPriorExam.bind(this)
              },
              {
                name: '',
                labelClass: 'fa fa-trash',
                buttonClass: 'btn-danger btn-sm delete-processing-response',
                onClick: this.onClickDeletePriorExam.bind(this)
              }
            ]
          }),
          editable: false,
          sortable: false
        }
      ];
      priorGridView = new GridView({
        columns: priorExamColumns,
        emptyText: 'No Prior Exams',
        collection: this.priorExamCollection
      });
      this.showChildView('priorExamGrid', priorGridView);

      this.showProcessingFields();

      Syphon.deserialize(this, modelGet(this.model, 'exam', {}), {
        exclude: [
          'ssArea[examAreaId]',
          'trustArea[examAreaId]',
          'itArea[examAreaId]',
          'complianceArea[examAreaId]',
          'craArea[examAreaId]'
        ]
      });
      this.ui.datepicker.datepicker();
    } else {
      toggleForm(this.ui.editTSPForm, false);
      toggleForm(this.ui.editForm, true);
      this.showProcessingFields();

      this.responseCollection = new Backbone.Collection(modelGet(this.model, 'exam.examProcessingResponses', []));

      this.showChildView(
        'responseGrid',
        new GridView({
          columns: [
            {
              name: 'responseDueDate',
              label: 'Response Due',
              cell: 'string',
              headerCell: 'custom',
              width: 15,
              editable: false,
              sortable: false
            },
            {
              name: 'responseReceivedDate',
              label: 'Response Received',
              cell: 'string',
              headerCell: 'custom',
              width: 15,
              editable: false,
              sortable: false
            },
            {
              name: 'acknowledgementSentDate',
              label: 'Acknowledgement Sent',
              cell: 'string',
              headerCell: 'custom',
              width: 15,
              editable: false,
              sortable: false
            },
            {
              name: 'notes',
              label: 'Notes',
              cell: ParagraphCell,
              headerCell: 'custom',
              width: 45,
              editable: false,
              sortable: false
            },
            {
              name: '',
              label: '',
              cell: MultiButtonCell.extend({
                buttons: [
                  {
                    name: '',
                    labelClass: 'fa fa-edit',
                    buttonClass: 'btn-primary btn-sm edit-processing-response',
                    onClick: this.onClickEditProcessingResponse.bind(this)
                  },
                  {
                    name: '',
                    labelClass: 'fa fa-trash',
                    buttonClass: 'btn-danger btn-sm delete-processing-response',
                    onClick: this.onClickDeleteProcessingResponse.bind(this)
                  }
                ]
              }),
              editable: false,
              sortable: false
            }
          ],
          collection: this.responseCollection,
          emptyText: 'No Processing Responses'
        })
      );

      this.ui.datepicker.datepicker();

      if (this.model.get('entityTypeId') === '2' || this.model.get('entityTypeId') === '22') {
        this.ui.isHCExam.show();

        var isHCExam = modelGet(this.model, 'exam.isHoldingCompanyExam') === '1';
        toggleForm(this.ui.bhcSection, isHCExam);
        if (isHCExam) {
          this.ui.examAreaLabel.removeClass('required');
        }
      }

      _.each(
        modelGet(this.model, 'exam.examAreas'),
        function(examArea) {
          this.ui.examAreaCheckboxes.filter('input[value=' + examArea.id + ']').prop('checked', true);
        }.bind(this)
      );

      var conductedBys = _.pluck(modelGet(this.model, 'exam.examConductedBy'), 'conductedById');
      _.each(
        conductedBys,
        function(conductedBy) {
          this.ui.conductedByCheckboxes.filter('input[value=' + conductedBy + ']').prop('checked', true);
        }.bind(this)
      );

      this.ui.examYear.hide();

      Syphon.deserialize(this, modelGet(this.model, 'exam', {}), {
        exclude: [
          'ssArea[examAreaId]',
          'trustArea[examAreaId]',
          'itArea[examAreaId]',
          'complianceArea[examAreaId]',
          'craArea[examAreaId]'
        ]
      });
      this.ui.datepicker.datepicker();

      if (!this.ui.ssIsNotifyEntity.filter(':checked').length) {
        this.ui.ssIsNotifyEntity.filter('[value="0"]').prop('checked', true);
      }

      if (!this.ui.trustIsNotifyEntity.filter(':checked').length) {
        this.ui.trustIsNotifyEntity.filter('[value="0"]').prop('checked', true);
      }

      if (!this.ui.itIsNotifyEntity.filter(':checked').length) {
        this.ui.itIsNotifyEntity.filter('[value="0"]').prop('checked', true);
      }

      var ItIsNotifyReadOnly = modelGet(this.model, 'exam.itArea.isNotifyEntity') === '1';
      var SSIsNotifyReadOnly = modelGet(this.model, 'exam.ssArea.isNotifyEntity') === '1';
      var TrustIsNotifyReadOnly = modelGet(this.model, 'exam.trustArea.isNotifyEntity') === '1';

      var ssFirstDayLetter = modelGet(this.model, 'exam.ssArea.firstDayLetter', {});
      this.ssFirstDayLetterUpload = new FileUploadView({
        isReadOnly: SSIsNotifyReadOnly,
        allowMultipleFiles: false,
        collection: makeUploadCollection(ssFirstDayLetter),
        metadata: {
          documentTypeId: 20010
        }
      });
      this.showChildView('ssFirstDayLetter', this.ssFirstDayLetterUpload);

      if (ssFirstDayLetter.fileId) {
        this.ui.ssFirstDayLetterDefault.hide();
      } else {
        this.ui.ssFirstDayLetter.hide();
      }

      var trustFirstDayLetter = modelGet(this.model, 'exam.trustArea.firstDayLetter', {});
      this.trustFirstDayLetterUpload = new FileUploadView({
        isReadOnly: TrustIsNotifyReadOnly,
        allowMultipleFiles: false,
        collection: makeUploadCollection(trustFirstDayLetter),
        metadata: {
          documentTypeId: 20010
        }
      });
      this.showChildView('trustFirstDayLetter', this.trustFirstDayLetterUpload);

      if (trustFirstDayLetter.fileId) {
        this.ui.trustFirstDayLetterDefault.hide();
      } else {
        this.ui.trustFirstDayLetter.hide();
      }

      var itFirstDayLetter = modelGet(this.model, 'exam.itArea.firstDayLetter', {});
      this.itFirstDayLetterUpload = new FileUploadView({
        isReadOnly: ItIsNotifyReadOnly,
        allowMultipleFiles: false,
        collection: makeUploadCollection(itFirstDayLetter),
        metadata: {
          documentTypeId: 20010
        }
      });
      this.showChildView('itFirstDayLetter', this.itFirstDayLetterUpload);

      if (itFirstDayLetter.fileId) {
        this.ui.itFirstDayLetterDefault.hide();
      } else {
        this.ui.itFirstDayLetter.hide();
      }

      this.updateExamAreas();
    }
  },

  showProcessingFields: function() {
    var examAreas = _.pluck(modelGet(this.model, 'exam.examAreas', []), 'id');

    // Show S&S EIC Sign Off
    var ssAreaIds = ['1', '11'];
    toggleForm(this.ui.ssEICSignOff, _.intersection(ssAreaIds, examAreas).length > 0);

    // Show IT EIC Sign Off
    var itAreaIds = ['3', '12', '22'];
    toggleForm(this.ui.itEICSignOff, _.intersection(itAreaIds, examAreas).length > 0);

    // Show Trust EIC Sign Off
    var trustAreaIds = ['2', '21'];
    toggleForm(this.ui.trustEICSignOff, _.intersection(trustAreaIds, examAreas).length > 0);
  },

  validateCheckboxes: function() {
    var deferred = $.Deferred(),
      hasErrors = false;

    if (this.ui.conductedByCheckboxes.filter(':checked').length > 0) {
      this.ui.conductedBysErrorContainer.hide();
    } else {
      hasErrors = true;
      this.ui.conductedBysErrorContainer.parents('.form-group').addClass('has-error');
      this.ui.conductedBysErrorContainer.show();
    }

    var isHC = this.ui.hcExamCheckboxes.filter(':checked').val() === '1';
    if (isHC || this.ui.examAreaCheckboxes.filter(':checked').length > 0) {
      this.ui.areasErrorContainer.hide();
    } else {
      hasErrors = true;
      this.ui.areasErrorContainer.parents('.form-group').addClass('has-error');
      this.ui.areasErrorContainer.show();
    }

    if (hasErrors) {
      deferred.reject();
    } else {
      deferred.resolve();
    }
    return deferred.promise();
  },

  validate: function() {
    var validationArray;
    var entityTypeId = this.model.get('entityTypeId');
    var isTSP = entityTypeId === '3' || entityTypeId === '12' || entityTypeId === '23';
    if (isTSP) {
      validationArray = [this.ui.editTspInfoForm.parsley().whenValidate()];
    } else {
      validationArray = [
        this.ui.editInfoForm.parsley().whenValidate(),
        this.validateCheckboxes(),
        this.ssFirstDayLetterUpload.validate(),
        this.trustFirstDayLetterUpload.validate(),
        this.itFirstDayLetterUpload.validate()
      ];
    }
    return $.when.apply($, validationArray);
  },

  removeValidationErrors: function() {
    this.ui.formGroups.removeClass('has-error');
    this.ui.validationErrorContainers.hide();
    this.ssFirstDayLetterUpload.removeValidationErrors();
    this.itFirstDayLetterUpload.removeValidationErrors();
    this.trustFirstDayLetterUpload.removeValidationErrors();
  },

  getFormData: function() {
    var entityTypeId = this.model.get('entityTypeId');

    var formData = Syphon.serialize(this.ui.form, {
      exclude: ['examAreas', 'conductedBys']
    });

    formData.id = modelGet(this.model, 'exam.id');
    formData.entityId = modelGet(this.model, 'exam.entityId');
    var isTSP = entityTypeId === '3' || entityTypeId === '12' || entityTypeId === '23';
    if (isTSP) {
      var participatingAgencies = paGridView.collection.toJSON();
      formData.participatingAgencies = participatingAgencies;
      var priorExam = priorGridView.collection.toJSON();
      formData.priorExam = priorExam;

      momentUtil.formatDateAttributes(
        formData,
        [
          'TSPAsOfDate',
          'initialProcessorReceivedDate',
          'sentToCaseManagerDate',
          'sentToFinalReviewerDate',
          'stateLeadAgencySentToFedDate',
          'stateLeadAgencyReceivedFromFedDate',
          'leadAgencyReceivedFromFedDate',
          'leadAgencyReturnedToFedDate',
          'finalProcessorReceivedDate',
          'finalProcessorSentForTransmittalDate',
          'signoffSSDate',
          'signoffITDate',
          'signoffTrustDate',
          'transmittalDate',
          'receivedByEntityDate',
          'examClosedDate',
          'dueDate',
          'saerCompleteDate',
          'completionDate'
        ],
        momentUtil.defaultOutboundDateConfig.sourceFormat,
        momentUtil.defaultOutboundDateConfig.targetFormat
      );
    } else {
      var entityId = this.model.get('entityId');
      formData.conductedBys = this.ui.conductedByCheckboxes
        .filter(':checked')
        .map(function() {
          return {
            entityId: entityId,
            conductedById: this.value
          };
        })
        .toArray();

      formData.areas = this.ui.examAreaCheckboxes
        .filter(':checked')
        .map(function() {
          return {
            entityId: entityId,
            id: this.value
          };
        })
        .toArray();

      var currentExamData = this.model.get('exam');
      var independentLeadAgencies = ['2', '3', '4'],
        examAreaFields = [
          'examAreaId',
          'asOfDate',
          'federalEIC',
          'examStartDate',
          'exitMeetingDate',
          'isNotifyEntity',
          'firstDayLetterDate',
          'eic',
          'firstDayLetterAcknowledgedDate',
          'examHours'
        ];

      // Remove eic and firstDayLetterDate if exam was independent since these fields are hidden when independent
      if (currentExamData.examTypeId === '2' && _.contains(independentLeadAgencies, currentExamData.leadAgencyId)) {
        examAreaFields.splice(5, 2);
      }

      // Check if different exam areas were selected
      var areasUpdated =
        _.difference(_.pluck(formData.areas, 'id'), _.pluck(currentExamData.examAreas, 'id')).length !== 0;

      // Check if ss/it/trust area data was updated
      var areaDataUpdated = (areaDataUpdated =
        areaDataUpdated ||
        _.some(['ssArea', 'itArea', 'trustArea'], function(areaName) {
          // pick only form fields and area id off of current exam data
          var currentArea = currentExamData[areaName]
            ? _.pick.apply(this, [currentExamData[areaName]].concat(examAreaFields))
            : currentExamData[areaName];

          return !_.isEqual(formData[areaName], currentArea);
        }));

      // Check if compliance/cra data was updated
      areaDataUpdated =
        areaDataUpdated ||
        _get(currentExamData, 'complianceArea.asOfDate') !== _get(formData, 'complianceArea.asOfDate');

      areaDataUpdated =
        areaDataUpdated || _get(currentExamData, 'craArea.asOfDate') !== _get(formData, 'craArea.asOfDate');

      formData.isExamAreaUpdated = areasUpdated || areaDataUpdated;

      // Set non editable exam area data
      if (formData.ssArea && formData.ssArea.isNotifyEntity === '1') {
        formData.ssArea.firstDayLetterDocId = modelGet(this.model, 'exam.ssArea.firstDayLetter.fileId');
      }

      if (formData.trustArea && formData.trustArea.isNotifyEntity === '1') {
        formData.trustArea.firstDayLetterDocId = modelGet(this.model, 'exam.trustArea.firstDayLetter.fileId');
      }

      if (formData.itArea && formData.itArea.isNotifyEntity === '1') {
        formData.itArea.firstDayLetterDocId = modelGet(this.model, 'exam.itArea.firstDayLetter.fileId');
      }

      if (formData.itArea) {
        formData.itArea.isNotifyEntity = formData.itArea.isNotifyEntity === '1';
        formData.itArea.firstDayLetterDocId = this.itFirstDayLetterUpload.getFirstFileId();
      }

      if (formData.ssArea) {
        formData.ssArea.isNotifyEntity = formData.ssArea.isNotifyEntity === '1';
        formData.ssArea.firstDayLetterDocId = this.ssFirstDayLetterUpload.getFirstFileId();
      }

      if (formData.trustArea) {
        formData.trustArea.isNotifyEntity = formData.trustArea.isNotifyEntity === '1';
        formData.trustArea.firstDayLetterDocId = this.trustFirstDayLetterUpload.getFirstFileId();
      }

      momentUtil.formatDateAttributes(
        formData,
        [
          'bhcAsOfDate',
          'ssArea.firstDayLetterDate',
          'ssArea.asOfDate',
          'ssArea.examStartDate',
          'ssArea.exitMeetingDate',
          'ssArea.firstDayLetterAcknowledgedDate',
          'trustArea.firstDayLetterDate',
          'trustArea.asOfDate',
          'trustArea.examStartDate',
          'trustArea.exitMeetingDate',
          'trustArea.firstDayLetterAcknowledgedDate',
          'itArea.firstDayLetterDate',
          'itArea.asOfDate',
          'itArea.examStartDate',
          'itArea.exitMeetingDate',
          'itArea.firstDayLetterAcknowledgedDate',
          'complianceArea.asOfDate',
          'craArea.asOfDate',
          'initialProcessorReceivedDate',
          'sentToCaseManagerDate',
          'sentToFinalReviewerDate',
          'stateLeadAgencySentToFedDate',
          'stateLeadAgencyReceivedFromFedDate',
          'leadAgencyReceivedFromFedDate',
          'leadAgencyReturnedToFedDate',
          'finalProcessorReceivedDate',
          'finalProcessorSentForTransmittalDate',
          'signoffSSDate',
          'signoffITDate',
          'signoffTrustDate',
          'transmittalDate',
          'receivedByEntityDate',
          'examClosedDate',
          'dueDate',
          'saerCompleteDate',
          'completionDate'
        ],
        momentUtil.defaultOutboundDateConfig.sourceFormat,
        momentUtil.defaultOutboundDateConfig.targetFormat
      );

      var currentProcessingResponses = modelGet(this.model, 'exam.examProcessingResponses', []),
        processingResponses = this.responseCollection.toJSON();

      formData.isExamConductedByUpdated = !_.isEqual(
        _.pluck(formData.conductedBys, 'conductedById'),
        _.pluck(modelGet(this.model, 'exam.examConductedBy'), 'conductedById')
      );

      formData.isProcessingResponseUpdated =
        currentProcessingResponses.length !== processingResponses.length ||
        _.some(processingResponses, function(response, index) {
          return !_.isEqual(response, currentProcessingResponses[index]);
        });

      formData.processingResponses = _.each(processingResponses, function(response) {
        response.entityId = entityId;
        response.responseDueDate = momentUtil.formatStr(response.responseDueDate, momentUtil.defaultOutboundDateConfig);
        response.responseReceivedDate = momentUtil.formatStr(
          response.responseReceivedDate,
          momentUtil.defaultOutboundDateConfig
        );
        response.acknowledgementSentDate = momentUtil.formatStr(
          response.acknowledgementSentDate,
          momentUtil.defaultOutboundDateConfig
        );
      });
    }
    return formData;
  },

  updateExamAreas: function() {
    // Disable all area sections
    toggleForm(this.ui.ssSection, false);
    toggleForm(this.ui.trustSection, false);
    toggleForm(this.ui.itSection, false);
    toggleForm(this.ui.complianceSection, false);
    toggleForm(this.ui.craSection, false);

    var pdobsLeadAgencyIds = ['1', '11', '21'],
      independentLeadAgencies = ['2', '3', '4'];

    var isIndependentExam =
      this.ui.examTypeSelect.val() === '2' && _.contains(independentLeadAgencies, this.ui.leadAgencySelect.val());

    var conductedByAgencies = this.ui.conductedByCheckboxes.filter(':checked').map(function() {
      return this.value;
    });

    // Enable selected sections
    this.ui.examAreaCheckboxes.each(
      function(ind, obj) {
        switch (obj.value) {
          case '1':
          case '11':
            toggleForm(this.ui.ssSection, obj.checked);

            if (obj.checked) {
              if (isIndependentExam) {
                toggleForm(this.ui.ssFirstDayLetter, false);
                toggleForm(this.ui.ssEic, false);
              } else {
                toggleForm(this.ui.ssFirstDayLetter, true);
                toggleForm(this.ui.ssEic, _.intersection(pdobsLeadAgencyIds, conductedByAgencies).length);
              }
              toggleForm(this.ui.ssFedEic, _.difference(conductedByAgencies, pdobsLeadAgencyIds).length);
            }
            break;
          case '2':
          case '21':
            toggleForm(this.ui.trustSection, obj.checked);

            if (obj.checked) {
              if (isIndependentExam) {
                toggleForm(this.ui.trustFirstDayLetter, false);
                toggleForm(this.ui.trustEic, false);
              } else {
                toggleForm(this.ui.trustFirstDayLetter, true);
                toggleForm(this.ui.trustEic, _.intersection(pdobsLeadAgencyIds, conductedByAgencies).length);
              }
              toggleForm(this.ui.trustFedEic, _.difference(conductedByAgencies, pdobsLeadAgencyIds).length);
            }
            break;
          case '3':
          case '12':
          case '22':
            toggleForm(this.ui.itSection, obj.checked);

            if (obj.checked) {
              if (isIndependentExam) {
                toggleForm(this.ui.itFirstDayLetter, false);
                toggleForm(this.ui.itEic, false);
              } else {
                toggleForm(this.ui.itFirstDayLetter, true);
                toggleForm(this.ui.itEic, _.intersection(pdobsLeadAgencyIds, conductedByAgencies).length);
              }
              toggleForm(this.ui.itFedEic, _.difference(conductedByAgencies, pdobsLeadAgencyIds).length);
            }
            break;
          case '4':
          case '13':
            toggleForm(this.ui.complianceSection, obj.checked);
            break;
          case '5':
            toggleForm(this.ui.craSection, obj.checked);
        }
      }.bind(this)
    );
  },

  onTSPScopeSelect: function() {
    var scopeValue = this.ui.tspScopeSelect.val();
    var isOtherExamScope = scopeValue === '13';
    toggleForm(this.ui.otherScope, isOtherExamScope);
  },

  onTSPLeadAgencySelect: function() {
    var agencyValue = this.ui.tspLeadAgencySelect.val();
    var isOtherLeadAgency = agencyValue === '31' || agencyValue === '37' || agencyValue === '43';
    toggleForm(this.ui.otherAgency, isOtherLeadAgency);
  },

  onChangeExamArea: function(e) {
    e.preventDefault();

    var examAreas = this.ui.examAreaCheckboxes.filter(':checked').map(function() {
      return this.value;
    });
    // Show S&S EIC Sign Off
    var ssAreaIds = ['1', '11'];
    toggleForm(this.ui.ssEICSignOff, _.intersection(ssAreaIds, examAreas).length > 0);

    // Show IT EIC Sign Off
    var itAreaIds = ['3', '12', '22'];
    toggleForm(this.ui.itEICSignOff, _.intersection(itAreaIds, examAreas).length > 0);

    // Show Trust EIC Sign Off
    var trustAreaIds = ['2', '21'];
    toggleForm(this.ui.trustEICSignOff, _.intersection(trustAreaIds, examAreas).length > 0);

    this.updateExamAreas();
  },

  onChangeConductedBy: function(e) {
    e.preventDefault();

    this.updateExamAreas();
  },

  onChangeIsHCExam: function(e) {
    e.preventDefault();
    var isHCExam = this.ui.hcExamCheckboxes.filter(':checked').val() === '1';

    toggleForm(this.ui.bhcSection, isHCExam);

    if (!isHCExam) {
      this.ui.examAreaLabel.addClass('required');
    } else {
      this.ui.examAreaLabel.removeClass('required');
    }
  },

  onExamTypeSelect: function(e) {
    e.preventDefault();
    var categoryId = this.model.get('entityCategoryId');

    this.updateExamAreas();
    if (categoryId === '1') {
      this.updateExamAreas();
    }
  },

  onClickitIsNotifyEntity: function(e) {
    var notifyEntity = this.ui.itIsNotifyEntity.filter(':checked').val() === '1';

    this.ui.itFirstDayLetterSection.toggleClass('required', notifyEntity);
    if (notifyEntity) {
      this.ui.itFirstDayLetterSent.attr('data-parsley-required', true);
      this.itFirstDayLetterUpload.options.isRequired = true;
      this.ui.itFirstDayLetterSent.datepicker('setDate', new Date());
    } else {
      this.ui.itFirstDayLetterSent.removeAttr('data-parsley-required');
      this.itFirstDayLetterUpload.options.isRequired = false;
      this.itFirstDayLetterUpload.removeValidationErrors();
    }
  },

  onChangeSSIsNotifyEntity: function() {
    var notifyEntity = this.ui.ssIsNotifyEntity.filter(':checked').val() === '1';
    this.ui.ssFirstDayLetterSection.toggleClass('required', notifyEntity);
    if (notifyEntity) {
      this.ui.ssFirstDayLetterSent.attr('data-parsley-required', true);
      this.ssFirstDayLetterUpload.options.isRequired = true;
      this.ui.ssFirstDayLetterSent.datepicker('setDate', new Date());
    } else {
      this.ui.ssFirstDayLetterSent.removeAttr('data-parsley-required');
      this.ssFirstDayLetterUpload.options.isRequired = false;
      this.ssFirstDayLetterUpload.removeValidationErrors();
    }
  },

  onChangeTrustIsNotifyEntity: function() {
    var notifyEntity = this.ui.trustIsNotifyEntity.filter(':checked').val() === '1';

    this.ui.trustFirstDayLetterSection.toggleClass('required', notifyEntity);
    if (notifyEntity) {
      this.ui.trustFirstDayLetterSent.attr('data-parsley-required', true);
      this.trustFirstDayLetterUpload.options.isRequired = true;
      this.ui.trustFirstDayLetterSent.datepicker('setDate', new Date());
    } else {
      this.ui.trustFirstDayLetterSent.removeAttr('data-parsley-required');
      this.trustFirstDayLetterUpload.options.isRequired = false;
      this.trustFirstDayLetterUpload.removeValidationErrors();
    }
  },

  onLeadAgencySelect: function(e) {
    e.preventDefault();
    var categoryId = this.model.get('entityCategoryId');

    this.updateExamAreas();
    if (categoryId === '1') {
      this.updateExamAreas();
    }
  },

  onClickAddResponse: function(e) {
    e.preventDefault();

    UIUtil.showModal({
      title: 'New Processing Response',
      view: new DepExamProcessingResponseModalView({
        model: new Backbone.Model({})
      })
    }).done(
      function(response) {
        this.responseCollection.add(response);
      }.bind(this)
    );
  },

  onClickEditProcessingResponse: function(e, response) {
    e.preventDefault();

    UIUtil.showModal({
      title: 'Edit Processing Response',
      view: new DepExamProcessingResponseModalView({
        model: response
      })
    }).done(function(editedResponse) {
      response.set(editedResponse);
    });
  },

  onClickDeleteProcessingResponse: function(e, response) {
    e.preventDefault();

    this.responseCollection.remove(response);
  },

  onClickAddAgency: function(e) {
    e.preventDefault();
    var self = this;
    UIUtil.showModal({
      title: 'New Participating Agency',
      view: new DepExamTSPNewParticipatingAgencyModalView({
        examNames: modelGet(this.model, 'lookups.examConductedByLeadAgencies'),
        examRegions: modelGet(this.model, 'lookups.examRegionDistricts'),
        examId: modelGet(this.model, 'exam.id')
      })
    }).done(function(agency) {
      var isRegionDistrictIdOther = agency.regionDistrictId === '16';
      if (!isRegionDistrictIdOther) {
        agency.otherRegionDisplay = '--';
      } else {
        agency.otherRegionDisplay = agency.otherRegion;
      }
      self.agencyCollection.add(agency);
    });
  },

  onClickEditParticipatingAgency: function(e, agency) {
    e.preventDefault();
    UIUtil.showModal({
      title: 'Edit Participating Agency',
      view: new DepExamTSPNewParticipatingAgencyModalView({
        examNames: modelGet(this.model, 'lookups.examConductedByLeadAgencies'),
        examRegions: modelGet(this.model, 'lookups.examRegionDistricts'),
        examId: modelGet(this.model, 'exam.id'),
        model: agency
      })
    }).done(function(editedAgency) {
      var isRegionDistrictIdOther = editedAgency.regionDistrictId === '16';
      if (!isRegionDistrictIdOther) {
        editedAgency.otherRegionDisplay = '--';
      } else {
        editedAgency.otherRegionDisplay = editedAgency.otherRegion;
      }
      agency.set(editedAgency);
    });
  },

  onClickDeleteParticipatingAgency: function(e, agency) {
    e.preventDefault();
    this.agencyCollection.remove(agency);
  },

  onClickAddPriorExam: function(e) {
    e.preventDefault();
    var self = this;
    UIUtil.showModal({
      title: 'New Prior Exam',
      view: new DepExamTSPNewPriorExamModalView({
        examNames: modelGet(this.model, 'lookups.examConductedByLeadAgencies'),
        examId: modelGet(this.model, 'exam.id')
      })
    }).done(function(priorExam) {
      var isLeadAgencyOther =
        priorExam.leadAgencyId !== '31' && priorExam.leadAgencyId !== '37' && priorExam.leadAgencyId !== '43';
      if (isLeadAgencyOther) {
        priorExam.otherLeadAgencyDisplay = '--';
      } else {
        priorExam.otherLeadAgencyDisplay = priorExam.otherLeadAgency;
      }
      self.priorExamCollection.add(priorExam);
    });
  },

  onClickEditPriorExam: function(e, priorExam) {
    e.preventDefault();
    UIUtil.showModal({
      title: 'Edit Prior Exam',
      view: new DepExamTSPNewPriorExamModalView({
        examNames: modelGet(this.model, 'lookups.examConductedByLeadAgencies'),
        examId: modelGet(this.model, 'exam.id'),
        model: priorExam
      })
    }).done(function(editedPriorExam) {
      var isLeadAgencyOther =
        editedPriorExam.leadAgencyId !== '31' &&
        editedPriorExam.leadAgencyId !== '37' &&
        editedPriorExam.leadAgencyId !== '43';
      if (isLeadAgencyOther) {
        editedPriorExam.otherLeadAgencyDisplay = '--';
      } else {
        editedPriorExam.otherLeadAgencyDisplay = editedPriorExam.otherLeadAgency;
      }
      priorExam.set(editedPriorExam);
    });
  },

  onClickDeletePriorExam: function(e, priorExam) {
    e.preventDefault();
    this.priorExamCollection.remove(priorExam);
  },

  onClickSubmit: function(e) {
    e.preventDefault();

    var l = Ladda.create(this.ui.submit[0]);
    l.start();
    this.validate()
      .done(
        function() {
          var formData = this.getFormData();
          var entityId = modelGet(this.model, 'exam.entityId');
          SubmitEntityExamMain(formData)
            .done(function(examId) {
              Radio.channel('navigate').trigger('show:int:dp:exam', entityId, examId);
            })
            .fail(function(err) {
              l.stop();
            });
        }.bind(this)
      )
      .fail(function() {
        l.stop();
      });
  }
});
