/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var cleanUpResponse = require('common/util/cleanUpResponse');

module.exports = function() {
  var defer = $.Deferred();

  $.soap({
    body: {
      GetAllComplianceLookups: {
        $xmlns: 'http://dobs.pa.gov/sc/lookup/bpm/1.0'
      }
    },
    blueprintArrays: [
      'GetAllComplianceLookupsResponse.ComplianceCrimAuthActions.SC_LU_ComplianceCrimAuthAction',
      'GetAllComplianceLookupsResponse.ComplianceCivAuthActions.SC_LU_ComplianceCivAuthAction',
      'GetAllComplianceLookupsResponse.ComplianceActionTypes.SC_LU_ComplianceActionType',
      'GetAllComplianceLookupsResponse.ComplianceSanctions.SC_LU_ComplianceSanction',
      'GetAllComplianceLookupsResponse.ComplianceOrderStatuses.SC_LU_ComplianceOrderStatus'
    ],
    blueprint: {
      complianceCrimAuthActions: [
        '$..SC_LU_ComplianceCrimAuthAction',
        {
          id: '$.ID',
          action: '$.Action'
        }
      ],
      complianceCivAuthActions: [
        '$..SC_LU_ComplianceCivAuthAction',
        {
          id: '$.ID',
          action: '$.Action'
        }
      ],
      complianceActionTypes: [
        '$..SC_LU_ComplianceActionType',
        {
          id: '$.ID',
          actionType: '$.ActionType'
        }
      ],
      complianceSanctions: [
        '$..SC_LU_ComplianceSanction',
        {
          id: '$.ID',
          sanction: '$.Sanction'
        }
      ],
      complianceOrderStatuses: [
        '$..SC_LU_ComplianceOrderStatus',
        {
          id: '$.ID',
          orderStatus: '$.OrderStatus'
        }
      ]
    }
  })
    .done(function(response) {
      cleanUpResponse(response);
      defer.resolve(response);
    })
    .fail(function(err) {
      defer.reject(err);
    });

  return defer.promise();
};
