/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Marionette = require('backbone.marionette');
var Backbone = require('backbone');
var Radio = require('backbone.radio');

var UIUtil = require('psa-core/util/ui');

var modelGet = require('common/util/modelGet');

var ConfirmationModalView = require('common/views/ConfirmationModalView');
var BreadcrumbView = require('common/views/BreadcrumbView');
var DropdownView = require('common/views/DropdownView');

var SubmitFilingCondition = require('../services/SubmitFilingCondition');

var tmplDepConditionDashboardView = require('../templates/DepConditionDashboardView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplDepConditionDashboardView,

  className: 'dep-condition-dashboard-view container-fluid',

  ui: {
    breadcrumb: '.breadcrumb-container',
    conditionActionDropdown: '.condition-action-drop-down'
  },

  regions: {
    breadcrumb: '@ui.breadcrumb',
    conditionActionDropdown: '@ui.conditionActionDropdown'
  },

  onBeforeShow: function() {
    var condition = this.model.get('condition') || {};
    this.conditionId = condition.conditionId;
    this.filingId = condition.filingId;
    this.entityId = condition.entityId;
    var entityName = modelGet(this.model, 'entityIdentifiers.entityName');
    var filingIdentifiers = this.model.get('filingIdentifiers') || {};
    var filingType = filingIdentifiers.filingType;
    var conditionType = condition.type;

    this.showChildView(
      'breadcrumb',
      new BreadcrumbView({
        breadcrumbs: [
          {
            link: '#dep/dashboard',
            text: 'Home'
          },
          {
            link: '#dep/entity/' + this.entityId + '/dashboard',
            text: entityName
          },
          {
            link: '#dep/entity/' + this.entityId + '/filing/' + this.filingId + '/dashboard',
            text: filingType + ' Filing'
          },
          {
            text: conditionType + ' Condition'
          }
        ]
      })
    );

    var dropDownOptions = [
      {
        text: 'Edit Condition',
        link: '#dep/entity/' + this.entityId + '/filing/' + this.filingId + '/condition/' + this.conditionId + '/edit'
      },
      {
        text: 'Delete Condition',
        onClick: this.onClickDelete.bind(this)
      }
    ];

    this.showChildView(
      'conditionActionDropdown',
      new DropdownView({
        rightAlign: true,
        model: new Backbone.Model({
          name: 'Condition Actions',
          options: dropDownOptions
        })
      })
    );
  },
  onClickDelete: function() {
    var self = this;
    UIUtil.showModal({
      title: 'Confirm Deleting Condition',
      view: new ConfirmationModalView({
        confirmText: 'Delete Condition',
        model: new Backbone.Model({
          message: 'Are you sure you want to delete this condition?',
          onConfirm: function() {
            return SubmitFilingCondition({
              filingConditionId: self.conditionId,
              isDeleted: 1
            });
          }
        }),
        onConfirmDone: function() {
          Radio.channel('navigate').trigger('show:int:dp:filing', self.entityId, self.filingId);
        }
      })
    });
  }
});
