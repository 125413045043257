/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Backbone = require('backbone');
var NProgress = require('nprogress');

var NavUtils = require('psa-core/util/nav');

var Scaffold = require('common/layout/Scaffold');

var GetCompliance = require('../services/GetCompliance');
var GetAllComplianceLookups = require('../services/GetAllComplianceLookups');
var SecComplianceGeneralInfoEditView = require('../views/SecComplianceGeneralInfoEditView');

module.exports = function(complianceId) {
  NProgress.start();
  NProgress.set(0.6);
  $.when(GetCompliance(complianceId), GetAllComplianceLookups())
    .done(function(compliance, lookups) {
      NavUtils.navigate('sec/compliance/' + complianceId + '/edit-general-info');
      Scaffold.content.show(
        new SecComplianceGeneralInfoEditView({
          viewMode: 'int',
          model: new Backbone.Model({
            compliance: compliance,
            identifiers: compliance.identifiers,
            complianceId: complianceId,
            actionTypes: lookups.complianceActionTypes,
            orderStatuses: lookups.complianceOrderStatuses,
            crimAuthActions: lookups.complianceCrimAuthActions,
            civAuthActions: lookups.complianceCivAuthActions
          })
        })
      );
      NProgress.done();
    })
    .fail(function(err) {
      NProgress.done();
    });
};
