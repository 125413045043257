/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Backbone = require('backbone');
var NProgress = require('nprogress');

var getComplianceCommonViews = require('../../util/getComplianceCommonViews');

var GetComplianceDetailsForEntity = require('../../../common/services/GetComplianceDetailsForEntity');
var EntityRemitPaymentForComplianceView = require('./EntityRemitPaymentForComplianceView');

module.exports = {
  event: 'build:nd:entity-remit-payment-for-compliance',

  channel: 'task',

  builder: function(task) {
    var defer = $.Deferred();

    var complianceId = task.taskData.ND.complianceId;
    var compliancePaymentId = task.taskData.ND.compliancePaymentId;
    var isAssigned = task.state === 'ASSIGNED';

    GetComplianceDetailsForEntity({
      complianceId: complianceId,
      compliancePaymentId: compliancePaymentId
    })
      .done(function(compliancePaymentDetails) {
        NProgress.set(0.85);

        var commonViews = getComplianceCommonViews({
          viewMode: 'ext',
          task: task,
          complianceDetails: compliancePaymentDetails
        });

        defer.resolve(
          new EntityRemitPaymentForComplianceView({
            isReadOnly: !isAssigned,
            complianceHeaderBar: commonViews.complianceHeaderBar,
            complianceBreadcrumbs: commonViews.complianceBreadcrumbs,
            complianceSummary: commonViews.complianceSummary,
            model: new Backbone.Model({
              task: task,
              taskId: task.taskId,
              compliancePaymentDetails: compliancePaymentDetails
            })
          })
        );
      })
      .fail(function(err) {
        defer.reject(err);
      });
    return defer.promise();
  }
};
