/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Backgrid = require('backgrid');

var tmplListLinkCell = require('./ListLinkCell.hbs');

module.exports = Backgrid.ListLinkCell = Backgrid.Cell.extend({
  className: 'list-link-cell',

  render: function() {
    this.$el.addClass(this.column.get('name'));

    var cellValue = this.model.get(this.column.get('name'));
    var emptyText = this.column.get('emptyText') ? this.column.get('emptyText') : '--';
    var content = tmplListLinkCell({
      values: cellValue,
      emptyText: emptyText,
      newTab: this.newTab
    });
    this.$el.empty().append(content);
    this.delegateEvents();
    return this;
  }
});
