/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');

module.exports = function(formData) {
  var defer = $.Deferred();

  $.soap({
    body: {
      GetAllFundAuditLookups: {
        $xmlns: 'http://dobs.pa.gov/dp/lookup/bpm/1.0'
      }
    },
    blueprintArrays: [
      'GetAllFundAuditLookupsResponse.AuditFirms.DP_LU_AuditFirm',
      'GetAllFundAuditLookupsResponse.AuditOpinions.DP_LU_AuditOpinion',
      'GetAllFundAuditLookupsResponse.AuditTypes.DP_LU_AuditType',
      'GetAllFundAuditLookupsResponse.AuditTypes.DP_LU_AuditTrustType'
    ],
    blueprint: {
      auditFirms: [
        '$..DP_LU_AuditFirm',
        {
          id: '$.ID',
          firm: '$.Firm'
        }
      ],
      auditOpinions: [
        '$..DP_LU_AuditOpinion',
        {
          id: '$.ID',
          opinion: '$.Opinion'
        }
      ],
      auditTypes: [
        '$..DP_LU_AuditType',
        {
          id: '$.ID',
          type: '$.Type'
        }
      ],
      auditTrustTypes: [
        '$..DP_LU_AuditTrustType',
        {
          id: '$.ID',
          trustType: '$.TrustType'
        }
      ]
    }
  })
    .done(function(response) {
      response.auditTypes = _.filter(response.auditTypes, function(type) {
        var whitelist = ['23'];
        return _.contains(whitelist, type.id);
      });
      response.auditFirms = _.sortBy(response.auditFirms, 'firm');
      defer.resolve(response);
    })
    .fail(function(err) {
      defer.reject(err);
    });

  return defer.promise();
};
