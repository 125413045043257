/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var Marionette = require('backbone.marionette');
var Syphon = require('backbone.syphon');

var PageableCollection = require('psa-backbone/models/PageableCollection');

var tmplNonDepInternalApplicationFiltersView = require('../templates/NonDepInternalApplicationFiltersView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplNonDepInternalApplicationFiltersView,

  className: 'non-dep-internal-application-filters-view',

  ui: {
    applyButton: '.apply',
    clearFilters: '.clear-filters',
    formInputs: ':input'
  },

  events: {
    'click @ui.applyButton': 'onClickApply',
    'click @ui.clearFilters': 'onClickClearFilters',
    'keydown @ui.formInputs': 'onKeydownOfInputs'
  },

  templateHelpers: function() {
    var applications = this.model.get('applications');
    var showOwnerSearch = this.options.showOwnerSearch;
    var applicationsOwners = _.uniq(_.without(_.pluck(applications, 'owner'), '--'));
    return {
      applicationsOwners: applicationsOwners,
      showOwnerSearch: showOwnerSearch
    };
  },

  onBeforeShow: function() {
    this.shadowCollection = new PageableCollection(this.model.get('applications'), { pageSize: 25 });
    this.collection = new PageableCollection(this.model.get('applications'), { pageSize: 25 });
  },

  onKeydownOfInputs: function(e) {
    // Searches on press of enter
    if (e.keyCode === 13) {
      this.onClickApply(e);
    }
  },

  onClickApply: function(e) {
    e.preventDefault();
    var self = this;
    var filter = Syphon.serialize(this);
    filter = _.pick(filter, _.identity);
    this.shadowCollection.reset(this.model.get('applications'));
    var models;
    _.each(filter, function(filterVal, filterType, filter) {
      models = self.shadowCollection.fullCollection.filter(function(model) {
        return self.filter(model, filterType, filterVal);
      });
      self.shadowCollection.fullCollection.reset(models);
    });
    if (!_.isEmpty(filter)) {
      self.shadowCollection.reset(models);
    }
    self.triggerMethod('apply:filters', models);
  },

  filter: function(shadow, filterType, filterVal) {
    if (filterType === 'applicationTypeId' || filterType === 'applicationStatusId' || filterType === 'owner') {
      return shadow.attributes[filterType] === filterVal;
    } else {
      var temp = isNaN(shadow.attributes[filterType])
        ? shadow.attributes[filterType].toLowerCase()
        : shadow.attributes[filterType];
      filterVal = isNaN(filterVal) ? filterVal.toLowerCase() : filterVal;
      return typeof temp === 'string' && temp.indexOf(filterVal) > -1;
    }
  },

  onClickClearFilters: function() {
    this.ui.formInputs.val('');
    var models = this.model.get('applications');
    this.shadowCollection.reset(models);
    this.triggerMethod('apply:filters', models);
  }
});
