/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');

var cleanUpResponse = require('common/util/cleanUpResponse');
var momentUtil = require('common/util/momentUtil');

module.exports = function(filingId) {
  var deferred = $.Deferred();
  $.soap({
    body: {
      GetCollectiveInvestmentFundFiling: {
        $xmlns: 'http://dobs.pa.gov/dp/filing/bpm/1.0',
        FilingID: filingId
      }
    },
    blueprintArrays: [
      'GetCollectiveInvestmentFundFilingResponse.TRSubFund.DP_FilTRSubFund',
      'GetCollectiveInvestmentFundFilingResponse.TrustFunds.DP_TrustFund',
      'GetCollectiveInvestmentFundFilingResponse.TrustSubFunds.DP_TrustSubFund',
      'GetCollectiveInvestmentFundFilingResponse.SupportingDocuments.DP_FilingSupportingDocument'
    ],
    blueprint: {
      id: '$..ID',
      filingId: '$..FilingID',
      entityId: '$..EntityID',
      fundActionId: '$..FundActionID',
      fundId: '$..FundID',
      fundName: '$..FundName',
      supportingDocDescriptionField: '$..SupportingDocDescriptionField',
      investmentPowers: '$..InvestmentPowers',
      incomeAllocation: '$..IncomeAllocation',
      participationTOC: '$..ParticipationTOC',
      assetValuaton: '$..AssetValuaton',
      valuationPeriod: '$..ValuationPeriod',
      terminationBasis: '$..TerminationBasis',
      accountAudit: '$..AccountAudit',
      otherMatters: '$..OtherMatters',
      trustDocument: {
        fileId: '$..TrustDocID',
        fileName: '$..TrustDocName'
      },
      summaryOfChanges: '$..SummaryOfChanges',
      trustAmendedDocument: {
        fileId: '$..TrustAmendedDocID',
        fileName: '$..TrustAmendedDocName'
      },
      trustRedlineDocument: {
        fileId: '$..TrustRedlineDocID',
        fileName: '$..TrustRedlineDocName'
      },
      terminationDate: '$..TerminationDate',
      effectiveDateAmend: '$..EffectiveAmendDate',
      effectiveDateTrust: '$..EffectiveTrustDate',
      subFundFiling: [
        '$..DP_FilTRSubFund',
        {
          id: '$..ID',
          filTRFundId: '$..FilTRFundID',
          subFundId: '$..SubFundID',
          subfundActionId: '$..FundActionID',
          subfundName: '$..SubfundName',
          summaryOfChanges: '$..SummaryOfChanges',
          isTerminated: '$..IsTerminated',
          subfundTerminationDate: '$..TerminationDate',
          effectiveDateTrust: '$..EffectiveTrustDate',
          effectiveDateAmend: '$..EffectiveAmendDate'
        }
      ],
      trustFunds: [
        '$..DP_TrustFund',
        {
          id: '$..ID',
          name: '$..Name'
        }
      ],
      trustSubfunds: [
        '$..DP_TrustSubFund',
        {
          id: '$..ID',
          name: '$..Name',
          trustFundId: '$..TrustFundID'
        }
      ],
      supportingDocuments: [
        '$..DP_FilingSupportingDocument',
        {
          id: '$..ID',
          filingId: '$..FilingID',
          documentId: '$..DocumentID',
          documentName: '$..DocumentName',
          documentTypeId: '$..DocumentTypeID',
          documentType: '$..DocumentType',
          supportingDocDescriptionField: '$..SupportingDocDescriptionField',
          file: {
            fileId: '$.DocumentID',
            fileName: '$.DocumentName'
          }
        }
      ]
    }
  })

    .done(function(response) {
      cleanUpResponse(response);
      var emptyDefaultInboundDateConfig = {
        sourceFormat: 'YYYY-MM-DD',
        targetFormat: 'MM/DD/YYYY',
        default: ''
      };

      response.terminationDate = momentUtil.formatStr(response.terminationDate, emptyDefaultInboundDateConfig);
      response.effectiveDateTrust = momentUtil.formatStr(response.effectiveDateTrust, emptyDefaultInboundDateConfig);
      response.effectiveDateAmend = momentUtil.formatStr(response.effectiveDateAmend, emptyDefaultInboundDateConfig);



      var newSubFunds = [];
      _.each(response.subFundFiling, function(subFund) {
        subFund.subfundTerminationDate = momentUtil.formatStr(
          subFund.subfundTerminationDate,
          emptyDefaultInboundDateConfig
        );
        if (subFund.subfundActionId === '1') {
          newSubFunds.push(subFund);
        }
      });
      response.newSubFunds = newSubFunds;

      // Map actions associated with existing subfunds
      _.each(response.trustSubfunds, function(subFund) {
        if (subFund.trustFundId) {
          var subFundFiling = _.find(response.subFundFiling, function(subFundFiling) {
            return subFundFiling.subFundId === subFund.id;
          });
          if (subFundFiling) {
            subFund.subFundFiling = subFundFiling;
          }
        }
      });

      deferred.resolve(response);
    })
    .fail(function(err) {
      deferred.reject();
    });

  return deferred.promise();
};
