/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Marionette = require('backbone.marionette');
var Syphon = require('backbone.syphon');
var Radio = require('backbone.radio');
var Ladda = require('ladda');

var modelGet = require('common/util/modelGet');
var BreadcrumbView = require('common/views/BreadcrumbView');

var SubmitExamOwner = require('../services/SubmitExamOwner');

var tmplDepExamManageOwnerView = require('../templates/DepExamManageOwnerView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplDepExamManageOwnerView,

  className: 'dep-exam-manage-owner-view container-fluid m-b-lg',

  ui: {
    form: 'form',
    breadcrumb: '.breadcrumb-container',
    manageOwnerForm: '.manage-owner-form',

    submit: '.btn-submit'
  },

  regions: {
    breadcrumb: '@ui.breadcrumb'
  },

  events: {
    'click @ui.submit': 'onClickSubmit'
  },

  behaviors: {
    ToggleBehavoir: {
      toggles: [
        {
          target: '.new-owner',
          group: '[name="hasOwner"]',
          valuesToShow: '1'
        }
      ]
    }
  },

  onBeforeShow: function() {
    var entityId = this.model.get('entityId');
    var entityName = this.model.get('entityName');
    var examId = modelGet(this.model, 'exam.id');
    var examType = modelGet(this.model, 'exam.examType');
    var leadAgency = modelGet(this.model, 'exam.leadAgency');

    this.showChildView(
      'breadcrumb',
      new BreadcrumbView({
        breadcrumbs: [
          {
            link: '#dep/dashboard',
            text: 'Home'
          },
          {
            link: '#dep/entity/' + entityId + '/dashboard',
            text: entityName
          },
          {
            link: '#dep/entity/' + entityId + '/exam/' + examId + '/dashboard',
            text: examType + ' - ' + leadAgency
          },
          {
            text: 'Manager Owner'
          }
        ]
      })
    );

    Syphon.deserialize(this, this.model.get('exam'));
  },

  validate: function() {
    return this.ui.manageOwnerForm.parsley().whenValidate();
  },

  getFormData: function() {
    return Syphon.serialize(this.ui.manageOwnerForm);
  },

  onClickSubmit: function(e) {
    e.preventDefault();

    var l = Ladda.create(this.ui.submit[0]);
    l.start();
    this.validate()
      .done(
        function() {
          var formData = this.getFormData(),
            entityId = this.model.get('entityId'),
            examId = this.model.get('examId');

          formData.examId = examId;
          SubmitExamOwner(formData)
            .done(function() {
              Radio.channel('navigate').trigger('show:int:dp:exam', entityId, formData.examId);
            })
            .fail(function(err) {
              l.stop();
            });
        }.bind(this)
      )
      .fail(function() {
        l.stop();
      });
  }
});
