/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Marionette = require('backbone.marionette');

var EmptySetView = require('common/views/EmptySetView');

var DepBankLocationActivitiesNewLocationItemView = require('./DepBankLocationActivitiesNewLocationItemView');

module.exports = Marionette.CollectionView.extend({
  className: 'dep-bank-location-activities-new-location-collection-view',

  childView: DepBankLocationActivitiesNewLocationItemView,

  emptyView: EmptySetView,

  emptyViewOptions: function() {
    return {
      emptyText: 'No New Locations'
    };
  },

  childEvents: {
    'remove:location': 'onChildClickRemoveLocation'
  },

  behaviors: {
    ReadOnlyFormBehavior: {}
  },

  childViewOptions: function(model, index) {
    return {
      childIndex: index,
      isReadOnly: this.options.isReadOnly,
      actionTypes: this.model.get('actionTypes'),
      locationTypes: this.model.get('locationTypes'),
      entityCategoryId: this.model.get('entityCategoryId'),
      mode: this.options.mode
    };
  },

  onChildClickRemoveLocation: function(childView) {
    this.collection.remove(childView.model);
  },

  validate: function(config) {
    // Run the .validate() method on every child view, which should return true
    // if valid
    if (this.collection.length > 0) {
      var validationPromises = this.children.map(function(childView) {
        var validateView = childView.validate.bind(childView);
        return validateView(config);
      });
      return $.when.apply($, validationPromises);
    } else {
      return $.Deferred().resolve();
    }
  },

  getFormData: function() {
    if (this.collection.length > 0) {
      return this.children.map(function(child) {
        return child.getFormData();
      });
    }
    return [];
  }
});
