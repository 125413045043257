/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Syphon = require('backbone.syphon');
var Marionette = require('backbone.marionette');
var Ladda = require('ladda');

var InterruptFilingProcess = require('../../common/services/InterruptFilingProcess');

var FileUploadView = require('../../../common/views/FileUploadView');
require('../styles/DepEntityWithdrawFilingModalView.css');

var tmplDepEntityWithdrawFilingModalView = require('../templates/DepEntityWithdrawFilingModalView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplDepEntityWithdrawFilingModalView,

  className: 'withdraw-filing-modal-view',

  ui: {
    cancelButton: '.btn-cancel',
    submitButton: '.btn-submit',
    WithdrawFilingForm: '.withdraw-filing-form',
    formBody: '.form-body',
    resultMessage: '.result-message',
    resultInstructions: '.result-instructions',
    resultBody: '.result-body',
    withdrawalDoc: '.withdrawal-doc'
  },

  regions: {
    withdrawalDoc: '@ui.withdrawalDoc'
  },

  events: {
    'click @ui.submitButton': 'onClickSubmit'
  },

  onBeforeShow: function() {
    this.withdrawalDoc = new FileUploadView({
      isReadOnly: false,
      allowMultipleFiles: false,
      isRequired: true,
      metadata: {
        documentTypeId: 20001
      }
    });
    this.showChildView('withdrawalDoc', this.withdrawalDoc);
  },

  validate: function() {
    this.ui.WithdrawFilingForm.parsley().reset();
    var validationPromises = [this.ui.WithdrawFilingForm.parsley().whenValidate(), this.withdrawalDoc.validate()];
    return $.when.apply($, validationPromises);
  },

  onClickSubmit: function(e) {
    e.preventDefault();
    var self = this;
    var filingId = self.model.get('filingId');
 
    self.validate()
      .done(function() {
        var formData = Syphon.serialize(self);
        formData.widthdrawalReasonDocId = self.withdrawalDoc.getFirstFileId();
        formData.filingId = filingId;
        formData.skipOrWithdraw = 'WITHDRAW';
        var laddaContext = Ladda.create(self.ui.submitButton.get(0));
        laddaContext.start();
        InterruptFilingProcess(formData).done(function() {
        self.triggerMethod('modal:close');
        });
      }
      );
  }
});
