/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var Marionette = require('backbone.marionette');
var Syphon = require('backbone.syphon');
var Ladda = require('ladda');

var modelGet = require('common/util/modelGet');

var SubmitLicenseInfoDetail = require('../services/SubmitLicenseInfoDetail');

var tmplSecLicenseInfoModalView = require('../templates/SecLicenseInfoModalView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplSecLicenseInfoModalView,

  className: 'sec-license-info-modal-view',

  ui: {
    licenseInfoForm: '.license-info-form',
    submit: '.submit',
    effectiveDate: '.effective-date'
  },

  events: {
    'click @ui.submit': 'onClickSubmit'
  },

  onBeforeShow: function() {
    var licenseInfo = this.model.get('licenseInfo');
    Syphon.deserialize(this, licenseInfo);
    this.ui.effectiveDate.datepicker();
  },

  onClickSubmit: function(e) {
    e.preventDefault();
    var self = this;
    this.ui.licenseInfoForm
      .parsley()
      .whenValidate()
      .done(function() {
        var l = Ladda.create(self.ui.submit[0]);
        l.start();
        var formData = Syphon.serialize(self);
        var id = modelGet(self.model, 'licenseInfo.id');
        var objectId = modelGet(self.model, 'licenseId');
        formData.objectTypeId = self.options.objectTypeId;
        formData.objectId = objectId;
        formData.id = id;
        SubmitLicenseInfoDetail(formData)
          .done(function(newLicenseInfo) {
            var licenseDescriptions = self.model.get('licenseDescription');
            var des = _.findWhere(licenseDescriptions, { id: newLicenseInfo.descriptionId });
            newLicenseInfo.description = des.description;
            self.triggerMethod('modal:close', newLicenseInfo);
          })
          .fail(function() {
            l.stop();
          });
      });
  }
});
