/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */


var cleanUpResponse = require('common/util/cleanUpResponse');
var moment = require('moment');
var Session = require('psa-core/Session');

module.exports = function(complaintId) {
  var deferred = $.Deferred();

  $.soap({
    body: {
      GetExternalComplaint: {
        $xmlns: 'http://dobs.pa.gov/cs/complaint/bpm/1.0',
        ComplaintID: complaintId,
        UserID: Session.user.id
      }
    },
    blueprintArrays: [
      'GetExternalComplaintResponse.Documents.CS_ComplaintDocument'
    ],
    blueprint: {
      isDraft: '$..IsDraft.__text',
      complaintId: '$..ID.__text',
      complainant: {
        firstName: '$..ContactFirstName.__text',
        lastName: '$..ContactLastName.__text',
        email: '$..ContactEmail.__text',
        phoneNumber: '$..ContactPhone.__text',
        workPhoneNumber: '$..ContactPhone2.__text',
        address : {
          addressLine1: '$..ContactAddressLine1.__text',
          addressLine2: '$..ContactAddressLine2.__text',
          city: '$..ContactCity.__text',
          state: '$..ContactState.__text',
          postalCode: '$..ContactPostalCode.__text',
          country: '$..ContactCountry.__text',
          county: '$..ContactCounty.__text',
        },
        contactPreference: '$..ContactContactPreference.__text',
        consumerRelation: '$..ComplainantConsumerRelation.__text',
        investorFirstName: '$..ComplainantFirstName.__text',
        investorLastName: '$..ComplainantLastName.__text',
        investorEmail: '$..ComplainantEmail.__text',
        investorPhoneNumber: '$..ComplainantPhone.__text',
        investorCellPhoneNumber: '$..ComplainantCellphone.__text',
        investorAddress : {
          addressLine1: '$..ComplainantAddressLine1.__text',
          addressLine2: '$..ComplainantAddressLine2.__text',
          city: '$..ComplainantCity.__text',
          state: '$..ComplainantState.__text',
          postalCode: '$..ComplainantPostalCode.__text',
          country: '$..ComplainantCountry.__text',
          county: '$..ComplainantCounty.__text',
        },
        investorContactPreference: '$..ComplainantContactPreference.__text',
        isInvestor: '$..IsInvestor.__text',
        investorRelation: '$..ComplainantConsumerRelation.__text',
      },
      serviceMemberStatus: '$..ServiceMemberStatus.__text', // 2 veteran, 3 active duty, 4 reserve
      company: {
        name: '$..EntityName.__text',
        accountNumber: '$..EntityAccountNumber.__text',
        email: '$..EntityEmail.__text',
        phoneNumber: '$..EntityPhone.__text',
        website: '$..EntityWebsite.__text',
        address : {
          addressLine1: '$..EntityAddressLine1.__text',
          addressLine2: '$..EntityAddressLine2.__text',
          city: '$..EntityCity.__text',
          state: '$..EntityState.__text',
          postalCode: '$..EntityPostalCode.__text',
          country: '$..EntityCountry.__text',
          county: '$..EntityCounty.__text',
        },
        hasContactedAttempt: '$..HasAttemptedContact.__text', // HasAttemptedContactWithEntity
        contactIndividuals: '$..EntityContactedIndividuals.__text', // ContactedEntityIndividuals
      },
      hasPendingLitigation: '$..HasPendingLitigation.__text',
      natureOfComplaint: '$..NatureOfComplaint.__text', // Synopsis
      desiredResolution: '$..DesiredResolution.__text', 
      isAffirmed: '$..AuthorityIsAffirmed.__text',
      lastInvestmentDate: '$..LastInvestmentDate.__text',
      initialInvestmentDate: '$..InitialInvestmentDate.__text',
      canTestify: '$..CanTestify.__text',
      noTestifyReason: '$..NoTestifyReason.__text',
      documents: [
        '$..CS_ComplaintDocument',
        {
          complaintDocumentId: '$.ID',
          fileId: '$.DocumentID',
          complaintId: '$.ComplaintID',
          isInternal: '$..IsInternal',
          fileName: '$..Name',
          documentTypeId: '$..DocumentTypeID',
          documentType: '$.Description'
        }
      ]
    }
  })
    .done(function(response) {
      cleanUpResponse(response);

      if (response.lastInvestmentDate) {
        response.lastInvestmentDate = response.lastInvestmentDate ? moment(response.lastInvestmentDate, moment.ISO_8601).format('MM/DD/YYYY') : '';
      }
      if (response.initialInvestmentDate) {
        response.initialInvestmentDate = response.initialInvestmentDate ? moment(response.initialInvestmentDate, moment.ISO_8601).format('MM/DD/YYYY') : '';
      }
      
      if (response.serviceMemberStatus === '1') {
        response.isVeteranServiceMember = '0';
      } else if (response.serviceMemberStatus !== undefined) {
        response.isVeteranServiceMember = '1';
      }

      if (response.isAffirmed === '0') {
        response.isAffirmed = undefined;
      }

      if (response.isInformationalOnly === '0') {
        response.isInformationalOnly = undefined;
      }

      deferred.resolve(response);
    })
    .fail(function(err) {
      deferred.reject(err);
    });

  return deferred.promise();
};
