/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2020] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Marionette = require('backbone.marionette');
var _get = require('lodash.get');
var parsleyUtil = require('common/util/parsleyUtil');
var Syphon = require('backbone.syphon');

var CommentsDocumentView = require('common/views/CommentsDocumentView');

var makeUploadCollection = require('common/util/makeUploadCollection');

var tmplDepBankConversionNationalRequiredInfoDocSectionView = require('./DepBankConversionNationalRequiredInfoDocSectionView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplDepBankConversionNationalRequiredInfoDocSectionView,

  className: 'dep-bank-conversion-national-required-info-doc-section-view',

  behaviors: {
    RadioGroupToggleBehavior: {
      radios: [
        {
          target: '.federal-reserve-bank-container',
          radioGroup: 'input[name="hasHCStructure"]',
          valueToShow: '1'
        }
      ]
    }
  },

  ui: {
    requiredInformationForm: '.required-information-form',
    branchesDocContainer: '.branches-doc-container',
    subsidiariesDocContainer: '.subsidiaries-doc-container',
    affiliatesDocContainer: '.affiliates-doc-container',
    articlesOfConversionDocContainer: '.articles-of-conversion-doc-container',
    bylawsDocContainer: '.bylaws-doc-container',
    nameReservationDocContainer: '.name-reservation-doc-container'
  },

  regions: {
    branchesDocContainer: '@ui.branchesDocContainer',
    subsidiariesDocContainer: '@ui.subsidiariesDocContainer',
    affiliatesDocContainer: '@ui.affiliatesDocContainer',
    articlesOfConversionDocContainer: '@ui.articlesOfConversionDocContainer',
    bylawsDocContainer: '@ui.bylawsDocContainer',
    nameReservationDocContainer: '@ui.nameReservationDocContainer'
  },

  onBeforeShow: function() {
    var filingContent = this.model.get('filingContent') || {};

    Syphon.deserialize(this, filingContent);
    this.branchesDoc = new CommentsDocumentView({
      isReadOnly: this.options.isReadOnly,
      allowMultipleFiles: false,
      isRequired: true,
      fileUploadLabel: 'Branches',
      commentsLabel: 'Branches Comments',
      commentsName: 'branchesComments',
      collection: makeUploadCollection(_get(filingContent, 'branchesDoc.file')),
      model: new Backbone.Model({
        branchesComments: _get(filingContent, 'branchesDoc.comments')
      }),
      documentTypeId: 20001
    });
    this.showChildView('branchesDocContainer', this.branchesDoc);

    this.subsidiariesDoc = new CommentsDocumentView({
      isReadOnly: this.options.isReadOnly,
      allowMultipleFiles: false,
      isRequired: true,
      fileUploadLabel: 'Subsidiaries',
      commentsLabel: 'Subsidiaries Comments',
      commentsName: 'subsidiariesComments',
      collection: makeUploadCollection(_get(filingContent, 'subsidiariesDoc.file')),
      model: new Backbone.Model({
        subsidiariesComments: _get(filingContent, 'subsidiariesDoc.comments')
      }),
      documentTypeId: 20001
    });
    this.showChildView('subsidiariesDocContainer', this.subsidiariesDoc);

    this.affiliatesDoc = new CommentsDocumentView({
      isReadOnly: this.options.isReadOnly,
      allowMultipleFiles: false,
      isRequired: true,
      fileUploadLabel: 'Affiliates',
      commentsLabel: 'Affiliates Comments',
      commentsName: 'affiliatesComments',
      collection: makeUploadCollection(_get(filingContent, 'affiliatesDoc.file')),
      model: new Backbone.Model({
        affiliatesComments: _get(filingContent, 'affiliatesDoc.comments')
      }),
      documentTypeId: 20001
    });
    this.showChildView('affiliatesDocContainer', this.affiliatesDoc);

    this.articlesOfConversionDoc = new CommentsDocumentView({
      isReadOnly: this.options.isReadOnly,
      allowMultipleFiles: false,
      isRequired: true,
      fileUploadLabel: '11. Upload the executed Articles of Conversion for the Bank.',
      commentsLabel: 'Articles of Conversion Comments',
      commentsName: 'articlesOfConversionComments',
      collection: makeUploadCollection(_get(filingContent, 'articlesOfConversionDoc.file')),
      model: new Backbone.Model({
        articlesOfConversionComments: _get(filingContent, 'articlesOfConversionDoc.comments')
      }),
      documentTypeId: 20001
    });
    this.showChildView('articlesOfConversionDocContainer', this.articlesOfConversionDoc);

    this.bylawsDoc = new CommentsDocumentView({
      isReadOnly: this.options.isReadOnly,
      allowMultipleFiles: false,
      isRequired: true,
      fileUploadLabel: '12. Upload revised bylaws for the Bank.',
      commentsLabel: 'Revised Bylaws Comments',
      commentsName: 'bylawsComments',
      collection: makeUploadCollection(_get(filingContent, 'bylawsDoc.file')),
      model: new Backbone.Model({
        bylawsComments: _get(filingContent, 'bylawsDoc.comments')
      }),
      documentTypeId: 20001
    });
    this.showChildView('bylawsDocContainer', this.bylawsDoc);

    this.nameReservationDoc = new CommentsDocumentView({
      isReadOnly: this.options.isReadOnly,
      allowMultipleFiles: false,
      isRequired: true,
      fileUploadLabel:
        '13. If the bank is changing its name, upload the evidence of the name reservation with the PA Department of State.',
      commentsLabel: 'Evidence of Name Reservation Comments',
      commentsName: 'nameReservationComments',
      collection: makeUploadCollection(_get(filingContent, 'nameReservationDoc.file')),
      model: new Backbone.Model({
        nameReservationComments: _get(filingContent, 'nameReservationDoc.comments')
      }),
      documentTypeId: 20001
    });
    this.showChildView('nameReservationDocContainer', this.nameReservationDoc);
  },

  validate: function(config) {
    this.ui.requiredInformationForm.parsley().reset();

    this.branchesDoc.removeValidationErrors();
    this.subsidiariesDoc.removeValidationErrors();
    this.affiliatesDoc.removeValidationErrors();
    this.articlesOfConversionDoc.removeValidationErrors();
    this.bylawsDoc.removeValidationErrors();
    this.nameReservationDoc.removeValidationErrors();

    if (config.type === 'submit') {
      var validationPromises = [
        this.ui.requiredInformationForm.parsley().whenValidate(),
        this.branchesDoc.validate(),
        this.subsidiariesDoc.validate(),
        this.affiliatesDoc.validate(),
        this.articlesOfConversionDoc.validate(),
        this.bylawsDoc.validate(),
        this.nameReservationDoc.validate()
      ];
      // Turn on required field validation for this form
      parsleyUtil.enableRequiredValidation(this.ui.requiredInformationForm);
    } else {
      // Turn off required field validation for this form
      parsleyUtil.disableRequiredValidation(this.ui.requiredInformationForm);
    }
    return $.when.apply($, validationPromises);
  },

  getFormData: function() {
    var formData = Syphon.serialize(this);
    formData.branchesDocId = this.branchesDoc.getFirstFileId();
    formData.subsidiariesDocId = this.subsidiariesDoc.getFirstFileId();
    formData.affiliatesDocId = this.affiliatesDoc.getFirstFileId();
    formData.articlesOfConversionDocId = this.articlesOfConversionDoc.getFirstFileId();
    formData.bylawsDocId = this.bylawsDoc.getFirstFileId();
    formData.nameReservationDocId = this.nameReservationDoc.getFirstFileId();
    return formData;
  }
});
