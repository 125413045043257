/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var Backbone = require('backbone');
var Backgrid = require('backgrid');

require('./BooleanCell.css');

module.exports = Backbone.View.extend({
  /** @property */
  className: 'boolean-cell',

  /** @property */
  tagName: 'td',

  /** @property */
  events: {
    'keydown input[type=checkbox]': 'onKeydown',
    'change input[type=checkbox]': 'onChange',
    'click input[type=checkbox]': 'enterEditMode'
  },

  formatter: Backgrid.CellFormatter,

  initialize: function(options) {
    var self = this;
    this.column = options.column;
    if (!(this.column instanceof Backgrid.Column)) {
      this.column = new Backgrid.Column(this.column);
    }
    var formatter = Backgrid.resolveNameToClass(this.column.get('formatter') || this.formatter, 'Formatter');

    if (!_.isFunction(formatter.fromRaw) && !_.isFunction(formatter.toRaw)) {
      formatter = new formatter();
    }

    this.formatter = formatter;

    this.listenTo(this.column, 'change:renderable', function(column, renderable) {
      self.$el.toggleClass('renderable', renderable);
    });

    if (Backgrid.callByNeed(this.column.renderable(), this.column, this.model)) {
      this.$el.addClass('renderable');
    }
  },

  /**
     Returns the checkbox.
   */
  checkbox: function() {
    return this.$el.find('input[type=checkbox]');
  },

  /**
     Focuses the checkbox.
  */
  enterEditMode: function() {
    this.checkbox().focus();
  },

  /**
     Unfocuses the checkbox.
  */
  exitEditMode: function() {
    this.checkbox().blur();
  },

  /**
     Process keyboard navigation.
  */
  onKeydown: function(e) {
    var command = new Backgrid.Command(e);
    if (command.passThru()) {
      return true; // skip ahead to `change`
    }
    if (command.cancel()) {
      e.stopPropagation();
      this.checkbox().blur();
    } else if (command.save() || command.moveLeft() || command.moveRight() || command.moveUp() || command.moveDown()) {
      e.preventDefault();
      e.stopPropagation();
      this.model.trigger('backgrid:edited', this.model, this.column, command);
    }
  },

  onChange: function(e) {
    var val = this.formatter.toRaw(this.checkbox().prop('checked'), this.model);
    this.model.set(this.column.get('name'), val);
    this.model.trigger('backgrid:edited', this.model, this.column, new Backgrid.Command(e));
  },

  /**
     Renders a checkbox in a table cell.
  */
  render: function() {
    this.$el.empty();
    var model = this.model,
      column = this.column;
    var editable = Backgrid.callByNeed(column.editable(), column, model);
    this.$el.addClass(this.column.get('name'));
    this.$el.append(
      $('<input>', {
        tabIndex: -1,
        type: 'checkbox',
        checked: this.formatter.fromRaw(model.get(column.get('name')), model),
        disabled: !editable,
        'data-parsley-excluded': true
      })
    );
    this.delegateEvents();
    return this;
  }
});
