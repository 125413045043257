/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');

var cleanUpResponse = require('common/util/cleanUpResponse');

module.exports = function() {
  var deferred = $.Deferred();

  $.soap({
    body: {
      GetAllCollectiveInvestmentFundFilingLookups: {
        $xmlns: 'http://dobs.pa.gov/dp/lookup/bpm/1.0'
      }
    },
    blueprintArrays: ['GetAllCollectiveInvestmentFundFilingLookupsResponse.TrustFundActions.DP_LU_TrustFundAction'],
    blueprint: {
      fundActions: [
        '$..DP_LU_TrustFundAction',
        {
          id: '$..ID',
          fundAction: '$..FundAction'
        }
      ]
    }
  })
    .done(function(response) {
      cleanUpResponse(response);
      response.subFundActions = _.filter(response.fundActions, function(fundAction) {
        return fundAction.id !== '1';
      });
      deferred.resolve(response);
    })
    .fail(function(err) {
      deferred.reject();
    });

  return deferred.promise();
};
