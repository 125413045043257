/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var Backbone = require('backbone');
var Marionette = require('backbone.marionette');
var Radio = require('backbone.radio');

var UIUtil = require('psa-core/util/ui');

var modelGet = require('common/util/modelGet');
var SideNavSectionView = require('common/views/SideNavSectionView');
var BreadcrumbView = require('common/views/BreadcrumbView');
var DropdownView = require('common/views/DropdownView');
var ConfirmationModalView = require('common/views/ConfirmationModalView');
var ContentServerLinkView = require('common/views/ContentServerLinkView');

var SubmitEntityCallReport = require('../services/SubmitEntityCallReport');
var DepCallReportDetailsSectionView = require('./DepCallReportDetailsSectionView');

var tmplDepCallReportDashboardView = require('../templates/DepCallReportDashboardView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplDepCallReportDashboardView,

  className: 'dep-call-report-dashboard-view container-fluid',

  ui: {
    callReportWrapper: '.call-report-wrapper',
    breadcrumb: '.breadcrumb-container',
    csLinkContainer: '.cs-link-container',
    callReportActionDropDown: '.dropdown-container'
  },

  regions: {
    callReportWrapper: '@ui.callReportWrapper',
    csLinkContainer: '@ui.csLinkContainer',
    breadcrumb: '@ui.breadcrumb',
    callReportActionDropDown: '@ui.callReportActionDropDown'
  },

  onBeforeShow: function() {
    var callReport = this.model.get('callReport') || {};
    var entityIdentifiers = this.model.get('entityIdentifiers');
    var callReportId = this.model.get('callReportId');
    var entityId = this.model.get('entityId');
    var entityName = modelGet(this.model, 'entityIdentifiers.entityName');
    var callReportsCSFolderId = this.model.get('callReportsCSFolderId');
    var csFolderId = callReport.csFolderId;

    this.showChildView(
      'callReportActionDropDown',
      new DropdownView({
        rightAlign: true,
        model: new Backbone.Model({
          name: 'Call Report Actions',
          options: [
            {
              text: 'Edit Call Report',
              link: '#dep/entity/' + entityId + '/call-report/' + callReportId + '/edit'
            },
            {
              text: 'Delete Call Report',
              onClick: this.onClickDelete.bind(this)
            }
          ]
        })
      })
    );

    this.showChildView(
      'breadcrumb',
      new BreadcrumbView({
        breadcrumbs: [
          {
            link: '#dep/dashboard',
            text: 'Home'
          },
          {
            link: '#dep/entity/' + entityId + '/dashboard',
            text: entityName
          },
          {
            text: callReport.reportYear + ' - ' + callReport.quarterEnd
          }
        ]
      })
    );

    this.showChildView(
      'csLinkContainer',
      new ContentServerLinkView({
        model: new Backbone.Model({
          buttonText: 'Open Call Report Folder',
          contentServerId: csFolderId ? csFolderId : callReportsCSFolderId
        })
      })
    );

    this.callReport = new SideNavSectionView({
      useNavigationButtons: false,
      collection: new Backbone.Collection([
        {
          id: 'callReportDetails',
          title: 'Call Report Details',
          view: new DepCallReportDetailsSectionView({
            viewMode: 'int',
            model: new Backbone.Model(_.extend(callReport, { entityIdentifiers: entityIdentifiers }))
          })
        },
        {
          id: 'notes',
          title: 'Notes',
          view: Radio.channel('dp').request('build:notes-view', {
            objectId: callReportId,
            objectTypeId: 'ENTITY_CALL_REPORT'
          })
        },
        {
          id: 'audittLog',
          title: 'Audit Log',
          view: Radio.channel('dp').request('build:audit-log-view', {
            objectId: callReportId,
            objectTypeId: 'ENTITY_CALL_REPORT'
          })
        }
      ])
    });
    this.showChildView('callReportWrapper', this.callReport);
  },

  onClickDelete: function() {
    var self = this;
    var message = 'Are you sure you want to delete this call report?';
    UIUtil.showModal({
      title: 'Confirm Deleting Call Report',
      view: new ConfirmationModalView({
        confirmText: 'Delete Call Report',
        model: new Backbone.Model({
          message: message,
          onConfirm: function() {
            var callReportId = self.model.get('callReportId');
            return SubmitEntityCallReport({ callReportId: callReportId, isDeleted: '1' });
          },
          onConfirmDone: function() {
            var entityId = self.model.get('entityId');
            Radio.channel('navigate').trigger('show:int:dp:entity', entityId);
          }
        })
      })
    });
  }
});
