/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');

module.exports = function(data) {
  var deferred = $.Deferred();

  $.soap({
    body: {
      SubmitLicenseContacts: {
        $xmlns: 'http://dobs.pa.gov/nd/license/bpm/1.0',
        EntityID: data.entityId,
        LicenseID: data.licenseId,
        Contacts: (function() {
          if (!data.contacts.length) {
            return {};
          }
          return {
            Contact: _.map(data.contacts, function(contact) {
              return {
                FirstName: contact.firstName,
                LastName: contact.lastName,
                HomePhoneNumber: contact.homePhoneNumber,
                CellPhoneNumber: contact.cellPhoneNumber,
                WorkPhoneNumber: contact.workPhoneNumber,
                WorkPhoneExtension: contact.workPhoneExtension,
                Fax: contact.fax,
                Company: contact.company,
                Email: contact.email,
                LicenseContactTypeID: contact.licenseContactTypeId,
                Title: contact.title,
                Address: {
                  AddressLine1: contact.addressLine1,
                  AddressLine2: contact.addressLine2,
                  AddressLine3: contact.addressLine3,
                  County: contact.county,
                  City: contact.city,
                  State: contact.state,
                  PostalCode: contact.postalCode,
                  Country: contact.country
                }
              };
            })
          };
        })()
      }
    }
  })
    .done(function(response) {
      deferred.resolve(response);
    })
    .fail(function(err) {
      deferred.reject();
    });

  return deferred.promise();
};
