var Handlebars = require("handlebars/dist/handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper;

  return "    <li>\n      <a href=\"#\" class=\"reassign\">\n        <i class=\"fa fa-exchange\"></i> Reassign Task\n      </a>\n    </li>\n    <li>\n      <a href=\"#\" class=\"release\">\n        <i class=\"fa fa-external-link\"></i> "
    + container.escapeExpression(((helper = (helper = helpers.releaseTaskLabel || (depth0 != null ? depth0.releaseTaskLabel : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"releaseTaskLabel","hash":{},"data":data}) : helper)))
    + "\n      </a>\n    </li>\n";
},"3":function(container,depth0,helpers,partials,data) {
    return "    <li>\n      <a href=\"#\" class=\"assign\">\n        <i class=\"fa fa-edit\"></i> Assign Task\n      </a>\n    </li>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "\n<div class=\"btn-group\">\n  <button type=\"button\" class=\"btn btn-plain dropdown-toggle\" data-toggle=\"dropdown\">\n    Task Actions <span class=\"caret\"></span>\n  </button>\n  <ul class=\"dropdown-menu\">\n"
    + ((stack1 = helpers.unless.call(alias1,(depth0 != null ? depth0.adminAssignUnclaimedTask : depth0),{"name":"unless","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.adminAssignUnclaimedTask : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "  </ul>\n</div>";
},"useData":true});