/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var Backbone = require('backbone');
var Marionette = require('backbone.marionette');
var Syphon = require('backbone.syphon');
var Radio = require('backbone.radio');
var Ladda = require('ladda');

var FileUploadCollection = require('psa-backbone/models/FileUploadCollection');

var modelGet = require('common/util/modelGet');
var makeUploadCollection = require('common/util/makeUploadCollection');

var HeaderBarView = require('common/views/HeaderBarView');
var BreadcrumbView = require('common/views/BreadcrumbView');
var FileUploadView = require('common/views/FileUploadView');

var SubmitRespondToCorrespondenceTask = require('../../services/SubmitRespondToCorrespondenceTask');

var tmplConSerRespondCorrespondenceTaskView = require('./ConSerRespondCorrespondenceTaskView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplConSerRespondCorrespondenceTaskView,

  className: 'con-ser-respond-correspondence-task-view container-fluid',

  ui: {
    headerBar: '.header-bar-container',
    breadcrumb: '.breadcrumb-container',
    correspondenceForm: '.correspondence-form',
    extFileUploadTableContainer: '.ext-file-upload-table-container',
    hasFinDocs: '.has-fin-docs',
    extFinancialUploadTableContainer: '.ext-financial-upload-table-container',
    intFileUploadTableContainer: '.int-file-upload-table-container',
    submit: '.submit',
    cancel: '.cancel'
  },

  regions: {
    headerBar: '@ui.headerBar',
    breadcrumb: '@ui.breadcrumb',
    extFileUploadTableContainer: '@ui.extFileUploadTableContainer',
    extFinancialUploadTableContainer: '@ui.extFinancialUploadTableContainer',
    intFileUploadTableContainer: '@ui.intFileUploadTableContainer'
  },

  events: {
    'click @ui.submit': 'onClickSubmit',
    'click @ui.cancel': 'onClickCancel'
  },

  onBeforeShow: function() {
    var correspondence = this.model.get('correspondence') || {};
    var intDocuments = modelGet(this.model, 'correspondence.intDocuments', []);
    var headerBarData = {
      complainantName: correspondence.complainantName || '--',
      entityName: correspondence.entityName || correspondence.complainantEntityName || '--'
    };

    var headerBarColumns = [
      {
        name: 'entityName',
        label: 'Entity Name',
        minWidth: '160px',
        maxWidth: '350px',
        overflow: 'truncate'
      },
      {
        name: 'complainantName',
        label: 'Consumer Name',
        minWidth: '160px',
        maxWidth: '350px',
        overflow: 'truncate'
      }
    ];

    this.showChildView(
      'headerBar',
      new HeaderBarView({
        columns: new Backbone.Collection(headerBarColumns),
        model: new Backbone.Model(headerBarData)
      })
    );

    var departmentName = this.model.get('departmentName');
    var getHomeLink = function(departmentName) {
      if (departmentName === 'NON_DEPOSITORY') {
        return '#ext/nondep/dashboard';
      } else if (departmentName === 'DEPOSITORY') {
        return '#ext/dep/dashboard';
      } else if (departmentName === 'SECURITIES') {
        return '#ext/sec/dashboard';
      } else if (departmentName === 'CONSUMER_SERVICES') {
        return '#ext/conser/dashboard';
      }
      return null;
    };

    this.showChildView(
      'breadcrumb',
      new BreadcrumbView({
        breadcrumbs: [
          {
            link: getHomeLink(departmentName),
            text: 'Home'
          },
          {
            text: 'Respond to Correspondence'
          }
        ]
      })
    );

    Syphon.deserialize(this, correspondence);

    this.intFileUploadTableView = new FileUploadView({
      collection: new FileUploadCollection(intDocuments),
      emptyText: 'No Internal Documents',
      isReadOnly: true
    });

    this.showChildView('intFileUploadTableContainer', this.intFileUploadTableView);

    this.extFileUploadView = new FileUploadView({
      collection: makeUploadCollection([]),
      isReadOnly: false,
      allowMultipleFiles: true,
      metadata: {
        documentTypeId: 40001
      }
    });

    this.showChildView('extFileUploadTableContainer', this.extFileUploadView);
  },

  onClickSubmit: function(e) {
    e.preventDefault();
    var self = this;
    var departmentName = this.model.get('departmentName');
    var eventDict = {
      NON_DEPOSITORY: 'show:ext:nd:dashboard',
      DEPOSITORY: 'show:ext:dp:dashboard',
      SECURITIES: 'show:ext:sec:dashboard',
      CONSUMER_SERVICES: 'show:ext:cs:dashboard'
    };

    this.validate().done(function() {
      var l = Ladda.create(self.ui.submit[0]);
      l.start();
      SubmitRespondToCorrespondenceTask(self.getFormData())
        .done(function() {
          Radio.channel('navigate').trigger(eventDict[departmentName]);
        })
        .fail(function() {
          l.stop();
        });
    });
  },

  onClickCancel: function(e) {
    e.preventDefault();
    var self = this;
    var departmentName = this.model.get('departmentName');
    var eventDict = {
      NON_DEPOSITORY: 'show:ext:nd:dashboard',
      DEPOSITORY: 'show:ext:dp:dashboard',
      SECURITIES: 'show:ext:sec:dashboard',
      CONSUMER_SERVICES: 'show:ext:cs:dashboard'
    };
    Radio.channel('navigate').trigger(eventDict[departmentName]);
  },

  validate: function() {
    var validationArray = [this.ui.correspondenceForm.parsley().whenValidate(), this.extFileUploadView.validate()];

    if (this.ui.hasFinDocs.filter(':checked').val() === '1') {
      validationArray.push(this.extFinancialUploadTableView.validate());
    }

    return $.when.apply($, validationArray);
  },

  getFormData: function() {
    var formData = Syphon.serialize(this);

    _.extend(formData, {
      taskId: this.model.get('taskId'),
      correspondenceId: this.model.get('correspondenceId')
    });
    formData.documents = this.extFileUploadView.collection.toJSON();

    return formData;
  }
});
