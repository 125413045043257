/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Backbone = require('backbone');
var Marionette = require('backbone.marionette');
var Radio = require('backbone.radio');

var PaymentInvoiceView = require('common/views/PaymentInvoiceView');

var StartFilingReviewProcess = require('../services/StartFilingReviewProcess');

var tmplCorpFinFilingPaymentView = require('../templates/CorpFinFilingPaymentView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplCorpFinFilingPaymentView,

  className: 'corp-fin-filing-payment-view',

  ui: {
    paymentInvoice: '.payment-invoice'
  },

  regions: {
    paymentInvoice: '@ui.paymentInvoice'
  },

  onBeforeShow: function() {
    var filingId = this.model.get('filingId');

    this.showChildView(
      'paymentInvoice',
      new PaymentInvoiceView({
        submitButtonTextZeroAmount: 'Submit Filing',
        model: new Backbone.Model({
          department: 'CORPORATE_FINANCE',
          paymentObjectType: 'FILING',
          paymentObjectId: filingId,
          items: this.model.get('invoiceItems')
        }),
        handleZeroAmount: function() {
          return StartFilingReviewProcess(filingId).done(function() {
            Radio.channel('navigate').trigger('show:ext:cf:dashboard');
          });
        }
      })
    );
  }
});
