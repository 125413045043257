/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2020] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Backbone = require('backbone');
var Marionette = require('backbone.marionette');

var GridView = require('psa-marionette/views/GridView');
var UIUtil = require('psa-core/util/ui');
var PageableCollection = require('psa-backbone/models/PageableCollection');

var MultiButtonCell = require('common/backgrid/MultiButtonCell');
var ConfirmationModalView = require('common/views/ConfirmationModalView');

var SubmitExamInvInqRelatedSubject = require('../services/SubmitExamInvInqRelatedSubject');
var SecOrganizationSearchModalView = require('./SecOrganizationSearchModalView');
var SecExamInvInqIndividualSearchModalView = require('./SecExamInvInqIndividualSearchModalView');

var tmplSecExamInvInqSectionRelatedSubjectView = require('../templates/SecExamInvInqSectionRelatedSubjectView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplSecExamInvInqSectionRelatedSubjectView,

  className: 'sec-exam-inv-inq-section-related-subject-view',

  ui: {
    organizationListContainer: '.organization-list-container',
    individualListContainer: '.individual-list-container',
    selectOrganization: '.select-organization',
    selectIndividual: '.select-individual'
  },

  regions: {
    organizationListContainer: '@ui.organizationListContainer',
    individualListContainer: '@ui.individualListContainer'
  },

  events: {
    'click @ui.selectOrganization': 'searchOrganization',
    'click @ui.selectIndividual': 'searchIndividual'
  },

  onBeforeShow: function() {
    this.organizationGrid = new GridView({
      columns: [
        {
          name: 'dbaName',
          label: 'DBA Name',
          cell: 'string',
          headerCell: 'custom',
          width: 40,
          editable: false
        },
        {
          name: 'crdNumber',
          label: 'CRD Number',
          cell: 'string',
          headerCell: 'custom',
          width: 40,
          editable: false
        },
        {
          name: 'editDeleteMultiButtonCell',
          label: '',
          cell: MultiButtonCell.extend({
            buttons: [
              {
                name: '',
                labelClass: 'fa fa-external-link',
                buttonClass: 'btn-primary btn-sm',
                href: function(model) {
                  return '#sec/organization/' + model.get('subjectObjectId') + '/dashboard';
                }
              },
              {
                name: '',
                labelClass: 'fa fa-trash',
                buttonClass: 'btn-danger btn-sm',
                onClick: this.onClickDeleteOrganization.bind(this)
              }
            ]
          }),
          editable: false,
          sortable: false,
          width: 20
        }
      ],
      emptyText: 'No Organizations',
      collection: new PageableCollection(this.model.get('organizations'), { pageSize: 10 })
    });
    this.showChildView('organizationListContainer', this.organizationGrid);

    this.individualGrid = new GridView({
      columns: [
        {
          name: 'firstName',
          label: 'First Name',
          cell: 'string',
          headerCell: 'custom',
          width: 20,
          editable: false
        },
        {
          name: 'middleInitial',
          label: 'Middle Initial',
          cell: 'string',
          headerCell: 'custom',
          width: 20,
          editable: false
        },
        {
          name: 'lastName',
          label: 'Last Name',
          cell: 'string',
          headerCell: 'custom',
          width: 20,
          editable: false
        },
        {
          name: 'crdNumber',
          label: 'CRD Number',
          cell: 'string',
          headerCell: 'custom',
          width: 20,
          editable: false
        },
        {
          name: 'editDeleteMultiButtonCell',
          label: '',
          cell: MultiButtonCell.extend({
            buttons: [
              {
                name: '',
                labelClass: 'fa fa-external-link',
                buttonClass: 'btn-primary btn-sm',
                href: function(model) {
                  return '#sec/individual/' + model.get('subjectObjectId') + '/dashboard';
                }
              },
              {
                name: '',
                labelClass: 'fa fa-trash',
                buttonClass: 'btn-danger btn-sm',
                onClick: this.onClickDeleteIndividual.bind(this)
              }
            ]
          }),
          editable: false,
          sortable: false,
          width: 20
        }
      ],
      emptyText: 'No Individuals',
      collection: new PageableCollection(this.model.get('individuals'), { pageSize: 10 })
    });
    this.showChildView('individualListContainer', this.individualGrid);
  },

  searchOrganization: function() {
    var self = this;
    UIUtil.showModal({
      title: 'Search for an Organization',
      dialogClasses: 'modal-dialog-organization-search-modal-view modal-lg',
      view: new SecOrganizationSearchModalView({
        searchLimit: 50,
        onSubmit: function(organization) {
          return SubmitExamInvInqRelatedSubject({
            objectId: self.model.get('objectId'),
            objectTypeId: self.model.get('objectTypeId'),
            subjectObjectTypeId: 'ORGANIZATION',
            subjectObjectId: organization.organizationId
          }).done(function(relatedSubjectId) {
            self.organizationGrid.collection.add({
              id: relatedSubjectId,
              subjectObjectId: organization.organizationId,
              dbaName: organization.dbaName,
              crdNumber: organization.crdNumber
            });
          });
        }
      })
    });
  },

  searchIndividual: function() {
    var self = this;
    UIUtil.showModal({
      title: 'Search for an Individual',
      dialogClasses: 'modal-dialog-individual-search-modal-view modal-lg',
      view: new SecExamInvInqIndividualSearchModalView({
        searchLimit: 50,
        model: new Backbone.Model({
          objectId: this.model.get('objectId'),
          objectTypeId: this.model.get('objectTypeId')
        })
      })
    }).done(function(individual) {
      if (individual) {
        self.individualGrid.collection.add(new Backbone.Model(individual));
      }
    });
  },

  onClickDeleteOrganization: function(e, model) {
    e.preventDefault();
    var self = this;

    UIUtil.showModal({
      title: 'Confirm Removing Organization',
      view: new ConfirmationModalView({
        confirmText: 'Remove Organization',
        model: new Backbone.Model({
          message: 'Are you sure you want to remove this related organization?',
          onConfirm: function() {
            return SubmitExamInvInqRelatedSubject({
              id: model.get('id')
            });
          },
          onConfirmDone: function() {
            self.organizationGrid.collection.remove(model);
          }
        })
      })
    });
  },

  onClickDeleteIndividual: function(e, model) {
    e.preventDefault();
    var self = this;

    UIUtil.showModal({
      title: 'Confirm Removing Individual',
      view: new ConfirmationModalView({
        confirmText: 'Remove Individual',
        model: new Backbone.Model({
          message: 'Are you sure you want to remove this related individual?',
          onConfirm: function() {
            return SubmitExamInvInqRelatedSubject({
              id: model.get('id')
            });
          },
          onConfirmDone: function() {
            self.individualGrid.collection.remove(model);
          }
        })
      })
    });
  }
});
