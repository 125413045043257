/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _get = require('lodash.get');
var NProgress = require('nprogress');
var Radio = require('backbone.radio');

var NavUtils = require('psa-core/util/nav');

var Scaffold = require('common/layout/Scaffold');

var getFilingValidFields = require('../util/getFilingValidFields');

var GetEntityIdentifiers = require('../../common/services/GetEntityIdentifiers');
var GetEntityDocMgmtDetails = require('../../common/services/GetEntityDocMgmtDetails');
var GetFiling = require('../services/GetFiling');
var GetFilingDetails = require('../../common/services/GetFilingDetails');

var GetFilingReviewRolesByFilingID = require('../../tasks/services/GetFilingReviewRolesByFilingID');
var GetAllFilingReviewLookups = require('../../tasks/services/GetAllFilingReviewLookups');

var DepFilingDashboardView = require('../views/DepFilingDashboardView');

module.exports = function(entityId, filingId) {
  NProgress.start();
  NProgress.set(0.6);

  $.when(
    GetEntityIdentifiers(entityId),
    GetEntityDocMgmtDetails(entityId),
    GetFiling(filingId),
    GetFilingDetails(filingId),
    GetFilingReviewRolesByFilingID(filingId)
  ).done(function(entityIdentifiers, entityDocMgmt, filing, filingDetails, reviewRoles) {
    $.when(
      GetAllFilingReviewLookups(entityIdentifiers.categoryId)
        .done(function(lookups) {
          var filingTypeId = filing.filingTypeId;
          var genericSubmissionTypeId = _get(filing, 'genericSubmissionDetails.submissionTypeId');
          NavUtils.navigate('dep/entity/' + entityId + '/filing/' + filingId + '/dashboard');
          var validFields = getFilingValidFields({
            categoryId: entityIdentifiers.categoryId,
            filingTypeId: filingTypeId,
            genericSubmissionTypeId: genericSubmissionTypeId
          });
          var formIdentifier = filingDetails.formIdentifier;
          var BuildFilingPromise = Radio.channel('dp:filing').request('build:' + formIdentifier, {
            filingId: filingId,
            mode: 'int-expand',
            entityCategoryId: filingDetails.entityCategoryId,
            isReadOnly: true
          });

          BuildFilingPromise.done(function(filingDetailsView) {
            filingDetailsView.model.set({
              filingId: filingId,
              filingDetails: filingDetails
            });
            Scaffold.content.show(
              new DepFilingDashboardView({
                model: new Backbone.Model({
                  entityCategoryId: entityIdentifiers.categoryId,
                  entityIdentifiers: entityIdentifiers,
                  filing: filing,
                  entityFilingsCSFolderId: entityDocMgmt.filingsCSFolderId,
                  validFields: validFields,
                  filingDetailsView: filingDetailsView,
                  reviewRoles: reviewRoles,
                  allReviewRoles: lookups.filingReviewRoles
                })
              })
            );
            NProgress.done();
          });
          NProgress.done();
        })

        .fail(function() {
          NProgress.done();
        })
    );
  });
};
