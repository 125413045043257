/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Marionette = require('backbone.marionette');
var UIUtil = require('psa-core/util/ui');
var _ = require('underscore');

var toggleForm = require('common/util/toggleForm');

var SelectFieldView = require('common/views/SelectFieldView');
var NonDepEntitySearchModalView = require('../views/NonDepEntitySearchModalView');

var GetLicensesByEntityId = require('../../compliance-dashboard/services/GetLicensesByEntity');

var tmplNonDepCreateComplianceCaseRequestFormView = require('../templates/NonDepCreateComplianceCaseRequestFormView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplNonDepCreateComplianceCaseRequestFormView,

  className: 'non-dep-create-compliance-case-request-form-view',

  ui: {
    createComplianceCaseForm: '.compliance-form',
    entitySearchButton: '.btn-entity-search',
    selectedEntityId: '.selected-entity-id',
    selectedEntityName: '.selected-entity-name',
    existingLicenseCheckbox: '.existing-license-checkbox',
    licenseNumberGroup: '.license-number-form-group',
    licenseNumberSelect: '.license-number-select-container',
    operatingAsGroup: '.operating-as-form-group',
    operatingAsSelect: '.operating-as-select',
    violationsSelect: '.violations-select'
  },

  regions: {
    licenseNumberSelect: '@ui.licenseNumberSelect',
    operatingAsSelect: '@ui.operatingAsSelect'
  },

  events: {
    'click @ui.entitySearchButton': 'onClickEntitySearchButton',
    'change @ui.existingLicenseCheckbox': 'onChangeExistingLicenseCheckbox'
  },

  onBeforeShow: function() {
    this.licenseNumberSelect = new SelectFieldView({
      isDisabled: false,
      isRequired: true,
      name: 'licenseId',
      selectClass: 'license-number-select',
      collection: new Backbone.Collection([])
    });

    this.showChildView('licenseNumberSelect', this.licenseNumberSelect);

    toggleForm(this.ui.licenseNumberGroup, false);
    toggleForm(this.ui.operatingAsGroup, true);

    this.ui.licenseNumberSelect.find('select').select2();
    this.ui.operatingAsSelect.select2();
    this.ui.violationsSelect.select2();

    var entityId = this.model.get('entityId');
    var licenseId = this.model.get('licenseId');
    if (entityId) {
      this.ui.selectedEntityId.val(entityId);
      this.ui.selectedEntityName.val(this.model.get('entityName'));

      var formInputs = this.ui.createComplianceCaseForm.find(':button,a');
      formInputs.prop('disabled', true);

      GetLicensesByEntityId(entityId)
        .done(
          function(result) {
            this.licenseNumberSelect.collection.reset(
              _.map(result.licenses, function(license) {
                return {
                  text: license.licenseNumber + ' - ' + license.licenseType,
                  value: license.id
                };
              })
            );
            formInputs.prop('disabled', false);
            if (licenseId) {
              this.ui.existingLicenseCheckbox.prop('checked', true);
              this.onChangeExistingLicenseCheckbox(event);
              this.licenseNumberSelect.setValue(licenseId);
            }
          }.bind(this)
        )
        .fail(function() {
          this.licenseNumberSelect.collection.reset();
          formInputs.prop('disabled', false);
        });
    }
  },

  onClickEntitySearchButton: function(e) {
    e.preventDefault();

    UIUtil.showModal({
      title: 'Search for an Entity',
      dialogClasses: 'modal-dialog-entity-search-modal-view modal-lg',
      view: new NonDepEntitySearchModalView({
        model: this.model
      })
    }).done(
      function(entity) {
        this.selectedEntity = entity;
        if (this.selectedEntity) {
          this.ui.selectedEntityId.val(this.selectedEntity.entityId);
          this.ui.selectedEntityName.val(this.selectedEntity.entityName);

          var formInputs = this.ui.createComplianceCaseForm.find(':button,a');
          formInputs.prop('disabled', true);

          GetLicensesByEntityId(entity.entityId)
            .done(
              function(result) {
                this.licenseNumberSelect.collection.reset(
                  _.map(result.licenses, function(license) {
                    return {
                      text: license.licenseNumber + ' - ' + license.licenseType,
                      value: license.id
                    };
                  })
                );
                formInputs.prop('disabled', false);
              }.bind(this)
            )
            .fail(function() {
              this.licenseNumberSelect.collection.reset();
              formInputs.prop('disabled', false);
            });
        } else {
          this.ui.selectedEntityId.val('');
          this.ui.selectedEntityName.val('');
          this.licenseNumberSelect.collection.reset();
        }
      }.bind(this)
    );
  },

  onChangeExistingLicenseCheckbox: function(e) {
    e.preventDefault();

    var checked = this.ui.existingLicenseCheckbox.is(':checked');
    toggleForm(this.ui.licenseNumberGroup, checked);
    toggleForm(this.ui.operatingAsGroup, !checked);
  },

  validate: function() {
    return this.ui.createComplianceCaseForm.parsley().whenValidate();
  }
});
