/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Backbone = require('backbone');
var Marionette = require('backbone.marionette');

var Session = require('psa-core/Session');

var makeFormReadOnly = require('../util/makeFormReadOnly');
var userHasRole = require('../util/userHasRole');
var modelGet = require('common/util/modelGet');

var TaskStatusAlertView = require('../views/TaskStatusAlertView');
var TaskActionsView = require('../views/TaskActionsView');

module.exports = Marionette.Behavior.extend({
  events: {
    'click .btn.release': 'onClickRelease',
    'click .btn.reassign': 'onClickReassign'
  },

  onBeforeRender: function() {
    this.model = new Backbone.Model(this.view.model.get('task'));
  },

  onBeforeShow: function() {
    var isTaskCreated = this.model.get('isCreated');
    var isTaskAssigned = this.model.get('isAssigned');
    var isTaskAssigneeCurrentUser = this.model.get('assignee') === Session.user.userName;
    var isTaskAssignedToCurrentUser = isTaskAssigned && isTaskAssigneeCurrentUser;
    var isDoBSDepartmentAdmin = userHasRole('DoBS Department Admin');
    var departmentName = modelGet(this.model, 'taskData.CM.departmentName');
    var isAssignedToUser = modelGet(this.model, 'targets.0.type') === 'user';

    var viewHasTaskStatusContainer = this.view.$('.task-status').length > 0;
    if (viewHasTaskStatusContainer) {
      this.view.addRegion('taskStatus', '.task-status');
      this.view.showChildView(
        'taskStatus',
        new TaskStatusAlertView({
          model: this.model,
          departmentName: departmentName
        })
      );
    }
    // Make the form read-only if the the person viewing the task is not
    // assigned, or is not the assigned user.
    if (!isTaskAssignedToCurrentUser) {
      makeFormReadOnly(this.view.$el);
    }
    var viewHasTaskActionsContainer = this.view.$('.task-actions').length > 0;
    if (
      (isTaskAssigned && viewHasTaskActionsContainer && !isAssignedToUser && isTaskAssignedToCurrentUser) ||
      (isDoBSDepartmentAdmin && viewHasTaskActionsContainer && (isTaskCreated || isTaskAssigned))
    ) {
      this.view.addRegion('taskActions', '.task-actions');
      this.view.showChildView(
        'taskActions',
        new TaskActionsView({
          model: this.model,
          departmentName: departmentName
        })
      );
    }
  }
});
