/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Backbone = require('backbone');

var PlaceholderView = require('common/views/PlaceholderView');

var GetEntitySiteRecordKeeping = require('../services/GetEntitySiteRecordKeeping');

var NonDepEntityBooksAndRecordsCollectionView = require('../views/NonDepEntityBooksAndRecordsCollectionView');

module.exports = {
  channel: 'nd',

  event: 'build:entity-site-record-keeping-view',

  /**
   * @param config Defines how the view should be built
   * @param config.entityId The entityId of the entity
   * @param {string} config.viewMode Should be 'int' or 'ext', so view can be configured for internal or external
   */
  builder: function(config) {
    return new PlaceholderView({
      viewPromise: buildEntitySiteRecordKeepingsView(config)
    });
  }
};

function buildEntitySiteRecordKeepingsView(config) {
  var deferred = $.Deferred();
  GetEntitySiteRecordKeeping(config.entityId)
    .done(function(siteRecordKeepings) {
      deferred.resolve(
        new NonDepEntityBooksAndRecordsCollectionView({
          viewMode: config.viewMode,
          collection: new Backbone.Collection(siteRecordKeepings.siteRecordKeepings)
        })
      );
    })
    .fail(function(err) {
      deferred.reject(err);
    });
  return deferred.promise();
}
