/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Marionette = require('backbone.marionette');
var Radio = require('backbone.radio');
var _ = require('underscore');

var UIUtil = require('psa-core/util/ui');
var GridView = require('psa-marionette/views/GridView');
var PageableCollection = require('psa-backbone/models/PageableCollection');

var StringCell = require('common/backgrid/StringCell');
var MomentCell = require('common/backgrid/MomentCell');
var MultiButtonCell = require('common/backgrid/MultiButtonCell');

var DepEntityWithdrawFilingModalView = require('./DepEntityWithdrawFilingModalView');

var tmplDepExtEntityActiveFilingsSectionView = require('../templates/DepExtEntityActiveFilingsSectionView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplDepExtEntityActiveFilingsSectionView,

  className: 'dep-ext-entity-active-filings-section-view',

  ui: {
    activeFilingsContainer: '.active-filings-container'
  },

  regions: {
    activeFilingsContainer: '@ui.activeFilingsContainer'
  },

  onBeforeShow: function() {
    var self = this;
    var userAccessLevelId = this.model.get('userAccessLevelId');

    this.showChildView(
      'activeFilingsContainer',
      new GridView({
        columns: [
          {
            name: 'filingType',
            label: 'Filing Type',
            cell: 'string',
            headerCell: 'custom',
            width: 30,
            editable: false
          },
          {
            name: 'receivedDate',
            label: 'Date Submitted',
            cell: MomentCell.MomentCell,
            formatter: MomentCell.MomentDateFormatter.extend({
              emptyValue: '--'
            }),
            sortValue: MomentCell.MomentSortValue,
            headerCell: 'custom',
            width: 25,
            editable: false
          },
          {
            name: 'lawyerDisplay',
            label: 'Lawyer',
            cell: StringCell,
            headerCell: 'custom',
            width: 25,
            editable: false
          },
          {
            name: '',
            label: '',
            cell: MultiButtonCell.extend({
              buttons: [
                {
                  name: 'Actions',
                  buttonClass: 'btn-default',
                  dropdownOptions: function(model) {
                    var entityId = model.get('entityId');
                    var filingId = model.get('filingId');
                    var recordStatusId = this.model.get('recordStatusId');
                    return [
                      {
                        text: 'Withdraw Filing',
                        onClick: self.onClickWithdrawFiling.bind(self),
                        renderable: _.includes(['2', '3', '4'], recordStatusId)
                      },
                      {
                        text: 'Manage Filer',
                        link: '#ext/dep/entity/' + entityId + '/filing/' + filingId + '/manage-lawyer',
                        renderable: userAccessLevelId >= 20
                      },
                      {
                        text: 'Print Filing',
                        link: '#ext/dep/filing/' + filingId + '/print',
                        newTab: true
                      }
                    ];
                  }
                },
                {
                  name: 'View',
                  buttonClass: 'btn-primary',
                  href: function(model) {
                    return '#ext/dep/filing/' + model.get('filingId') + '/view';
                  }
                }
              ]
            }),
            headerCell: 'custom',
            width: 15,
            editable: false,
            sortable: false
          }
        ],
        collection: new PageableCollection(this.model.get('filings'), { pageSize: 25 }),
        emptyText: 'No Active Filings'
      })
    );
  },

  onClickWithdrawFiling: function(e, model) {
    e.preventDefault();
    var entityId = model.get('entityId');
    UIUtil.showModal({
      title: 'Confirm Withdrawing Filing',
      view: new DepEntityWithdrawFilingModalView({ model: model }),
      dialogClasses: 'modal-dialog-withdraw-filing-modal-view modal-lg'
    }).done(function() {
      Radio.channel('navigate').trigger('show:ext:dp:entity-dashboard', entityId);
    });
  }
});
