/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Backbone = require('backbone');
var Marionette = require('backbone.marionette');
var Radio = require('backbone.radio');

var GridView = require('psa-marionette/views/GridView');
var PageableCollection = require('psa-backbone/models/PageableCollection');
var UIUtil = require('psa-core/util/ui');

var MultiButtonCell = require('common/backgrid/MultiButtonCell');
var MomentCell = require('common/backgrid/MomentCell');
var ConfirmationModalView = require('common/views/ConfirmationModalView');

var SubmitLicenseInfoDetail = require('../services/SubmitLicenseInfoDetail');

var tmplSecOrganizationLicenseInfoSectionView = require('../templates/SecLicenseInfoSectionView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplSecOrganizationLicenseInfoSectionView,

  className: 'sec-license-info-section-view',

  ui: {
    licenseGrid: '.license-information-grid-container'
  },

  regions: {
    licenseGrid: '@ui.licenseGrid'
  },

  onBeforeShow: function() {
    this.licenseCollection = new PageableCollection(this.model.get('licenseInfos'), { pageSize: 25 });

    this.showChildView(
      'licenseGrid',
      new GridView({
        columns: [
          {
            name: 'description',
            label: 'Description',
            cell: 'string',
            headerCell: 'custom',
            width: 20,
            editable: false
          },
          {
            name: 'effectiveDate',
            label: 'Effective Date',
            cell: MomentCell.MomentCell,
            formatter: MomentCell.MomentDateFormatter.extend({
              emptyValue: '--'
            }),
            sortValue: MomentCell.MomentSortValue,
            headerCell: 'custom',
            width: 15,
            editable: false
          },
          {
            name: 'note',
            label: 'Note',
            cell: 'string',
            headerCell: 'custom',
            width: 55,
            editable: false
          },
          {
            name: 'editDeleteMultiButtonCell',
            label: '',
            cell: MultiButtonCell.extend({
              buttons: [
                {
                  name: '',
                  labelClass: 'fa fa-edit',
                  buttonClass: 'btn-primary btn-sm',
                  onClick: this.onClickEditLicenseInfo.bind(this)
                },
                {
                  name: '',
                  labelClass: 'fa fa-trash',
                  buttonClass: 'btn-danger btn-sm',
                  onClick: this.onClickDeleteLicenseInfo.bind(this)
                }
              ]
            }),
            editable: false,
            sortable: false
          }
        ],
        emptyText: 'No Associated License Information Details',
        collection: this.licenseCollection
      })
    );
  },

  onClickEditLicenseInfo: function(e, model) {
    var self = this;
    var modalViewPromise = Radio.channel('sc').request('build:license-info-modal-view', {
      objectId: model.get('objectId'),
      id: model.get('id'),
      objectTypeId: self.options.objectTypeId
    });
    UIUtil.showModal({
      title: 'Edit License Information Details',
      view: modalViewPromise
    }).done(function(editedLicenseInfo) {
      model.set(editedLicenseInfo);
    });
  },

  onClickDeleteLicenseInfo: function(e, model) {
    var self = this;
    var message = 'Are you sure you want to delete this license information?';
    UIUtil.showModal({
      title: 'Confirm Deleting License Information',
      view: new ConfirmationModalView({
        confirmText: 'Delete License Information',
        model: new Backbone.Model({
          message: message,
          onConfirm: function() {
            var id = model.get('id');
            return SubmitLicenseInfoDetail({ id: id, isDeleted: '1' });
          },
          onConfirmDone: function() {
            self.licenseCollection.remove(model);
          }
        })
      })
    });
  }
});
