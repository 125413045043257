/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var moment = require('moment');

var cleanUpResponse = require('common/util/cleanUpResponse');

module.exports = function(data) {
  var defer = $.Deferred();

  $.soap({
    body: {
      GetAllCompliancePaymentDue: {
        $xmlns: 'http://dobs.pa.gov/nd/compliance/bpm/1.0'
      }
    },
    blueprintArrays: ['GetAllCompliancePaymentDueResponse.ND_CompliancePayment'],
    blueprint: {
      paymentDue: [
        '$..ND_CompliancePayment',
        {
          id: '$.ID',
          dueDate: '$.DueDate',
          amountDue: '$.AmountDue',
          description: '$.Description',
          caseNumber: '$.CaseNumber',
          entityId: '$.EntityID',
          entityName: '$.EntityName',
          licenseId: '$.LicenseID',
          licenseNumber: '$.LicenseNumber',
          status: '$.Status',
          dueDays: '$.DueDays',
          complianceId: '$.ComplianceID',
          licenseCode: '$.Code'
        }
      ]
    }
  })
    .done(function(response) {
      cleanUpResponse(response);

      _.each(response.paymentDue, function(payment) {
        payment.dueDate = moment(payment.dueDate, 'YYYY-MM-DD');
        payment.caseNumber = payment.caseNumber || '--';
        payment.licenseNumber = payment.licenseNumber || '--';
        payment.amountDue = Number(payment.amountDue);
        payment.licenseCode = payment.licenseCode || '--';
      });

      defer.resolve(response.paymentDue);
    })
    .fail(function(err) {
      defer.reject(err);
    });

  return defer.promise();
};
