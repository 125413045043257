/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2020] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Backbone = require('backbone');
var Marionette = require('backbone.marionette');
var Radio = require('backbone.radio');

var UIUtil = require('psa-core/util/ui');

var ConfirmationModalView = require('common/views/ConfirmationModalView');
var DropdownView = require('common/views/DropdownView');
var BreadcrumbView = require('common/views/BreadcrumbView');

var SubmitEntityRiskMatrix = require('../services/SubmitEntityRiskMatrix');

var tmplDepRiskMatrixDashboardView = require('../templates/DepRiskMatrixDashboardView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplDepRiskMatrixDashboardView,

  className: 'dep-risk-matrix-dashboard-view container-fluid',

  ui: {
    headerBar: '.header-bar-container',
    breadcrumb: '.breadcrumb-container',
    actionDropdown: '.dropdown-container'
  },

  regions: {
    headerBar: '@ui.headerBar',
    breadcrumb: '@ui.breadcrumb',
    actionDropdown: '@ui.actionDropdown'
  },

  onBeforeShow: function() {
    var entityId = this.model.get('entityId');
    var entityName = this.model.get('entityName');
    var riskMatrix = this.model.get('riskMatrix') || {};

    this.showChildView(
      'actionDropdown',
      new DropdownView({
        rightAlign: true,
        model: new Backbone.Model({
          name: 'Risk Matrix Actions',
          options: [
            {
              text: 'Edit Risk Matrix',
              link: '#dep/entity/' + entityId + '/risk-matrix/' + riskMatrix.id + '/edit'
            },
            {
              text: 'Delete Risk Matrix',
              onClick: this.onClickDelete.bind(this)
            }
          ]
        })
      })
    );

    this.showChildView(
      'breadcrumb',
      new BreadcrumbView({
        breadcrumbs: [
          {
            link: '#dep/dashboard',
            text: 'Home'
          },
          {
            link: '#dep/entity/' + entityId + '/dashboard',
            text: entityName
          },
          {
            text: riskMatrix.riskType + ' (' + riskMatrix.asOfDate + ')'
          }
        ]
      })
    );
  },

  onClickDelete: function(e) {
    e.preventDefault();

    var riskMatrix = this.model.get('riskMatrix') || {};
    var entityId = this.model.get('entityId');
    UIUtil.showModal({
      title: 'Confirm Deleting Risk Matrix',
      view: new ConfirmationModalView({
        confirmText: 'Delete Risk Matrix',
        model: new Backbone.Model({
          message: 'Are you sure you want to delete this entity risk matrix?',
          onConfirm: function() {
            return SubmitEntityRiskMatrix({
              id: riskMatrix.id,
              shouldDelete: '1'
            });
          },
          onConfirmDone: function() {
            Radio.channel('navigate').trigger('show:int:dp:entity', entityId);
          }
        })
      })
    });
  }
});
