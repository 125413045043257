/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2020] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var Backbone = require('backbone');
var Marionette = require('backbone.marionette');
var Radio = require('backbone.radio');
var Syphon = require('backbone.syphon');
var Ladda = require('ladda');

var GridView = require('psa-marionette/views/GridView');
var SelectRowCell = require('psa-backgrid/SelectRowCell');

var modelGet = require('common/util/modelGet');
var momentUtil = require('common/util/momentUtil');
var MomentCell = require('common/backgrid/MomentCell');
var BreadcrumbView = require('common/views/BreadcrumbView');

var SubmitEntityOfficerBulkUpdate = require('../services/SubmitEntityOfficerBulkUpdate.js');

var tmplDepOfficerBulkEditView = require('../templates/DepOfficerBulkEditView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplDepOfficerBulkEditView,

  className: 'dep-entity-bulk-edit-officers-view container-fluid',

  ui: {
    breadcrumb: '.breadcrumb-container',
    form: 'form',
    officerUpdateGrid: '.officer-update-grid-container',
    officerUpdateGridErrorsContainer: '#officerUpdateErrorsContainer',
    termStartDate: 'input[name="termStartDate"]',
    termStartDateErrorsContainer: '#termStartDateErrorsContainer',
    termEndDateErrorsContainer: '#termEndDateErrorsContainer',
    dataRequiredErrorsContainer: '#dataRequiredErrorsContainer',
    termEndDate: 'input[name="termEndDate"]',
    submit: '.btn-submit'
  },

  regions: {
    breadcrumb: '@ui.breadcrumb',
    officerUpdateGrid: '@ui.officerUpdateGrid'
  },

  events: {
    'click @ui.submit': 'onClickSubmit'
  },

  onBeforeShow: function() {
    var entityId = modelGet(this.model, 'entityIdentifiers.entityId');
    var entityName = modelGet(this.model, 'entityIdentifiers.entityName');

    this.showChildView(
      'breadcrumb',
      new BreadcrumbView({
        breadcrumbs: [
          {
            link: '#dep/dashboard',
            text: 'Home'
          },
          {
            link: '#dep/entity/' + entityId + '/dashboard',
            text: entityName
          },
          {
            text: 'Bulk Edit Officers'
          }
        ]
      })
    );

    this.officersGrid = new GridView({
      columns: [
        {
          name: 'selected',
          label: 'Update',
          cell: SelectRowCell.SelectRowCell,
          headerCell: SelectRowCell.SelectAllHeaderCell,
          width: 5,
          editable: true,
          sortable: false
        },
        {
          name: 'officerType',
          label: 'Type',
          cell: 'string',
          headerCell: 'custom',
          width: 10,
          editable: false
        },
        {
          name: 'firstName',
          label: 'First Name',
          cell: 'string',
          headerCell: 'custom',
          width: 10,
          editable: false
        },
        {
          name: 'middleInitial',
          label: 'Middle Initial',
          cell: 'string',
          headerCell: 'custom',
          width: 5,
          editable: false
        },
        {
          name: 'lastName',
          label: 'Last Name',
          cell: 'string',
          headerCell: 'custom',
          width: 10,
          editable: false
        },
        {
          name: 'title',
          label: 'Title',
          cell: 'string',
          headerCell: 'custom',
          width: 10,
          editable: false
        },
        {
          name: 'status',
          label: 'Status',
          cell: 'string',
          headerCell: 'custom',
          width: 10,
          editable: false
        },
        {
          name: 'termLength',
          label: 'Term Length',
          cell: 'string',
          headerCell: 'custom',
          width: 10,
          editable: false
        },
        {
          name: 'termStartDate',
          label: 'Term Start',
          cell: MomentCell.MomentCell,
          formatter: MomentCell.MomentDateFormatter.extend({
            emptyValue: '--'
          }),
          sortValue: MomentCell.MomentSortValue,
          headerCell: 'custom',
          width: 10,
          editable: false
        },
        {
          name: 'termEndDate',
          label: 'Term End',
          cell: MomentCell.MomentCell,
          formatter: MomentCell.MomentDateFormatter.extend({
            emptyValue: '--'
          }),
          sortValue: MomentCell.MomentSortValue,
          headerCell: 'custom',
          width: 10,
          editable: false
        }
      ],
      collection: new Backbone.Collection(this.model.get('officers')),
      emptyText: 'No Officers'
    });
    this.showChildView('officerUpdateGrid', this.officersGrid);

    this.ui.termStartDate.datepicker();
    this.ui.termEndDate.datepicker();
  },

  onClickSubmit: function(e) {
    var self = this;

    this.validate().done(function() {
      var laddaContext = Ladda.create(e.currentTarget).start();
      SubmitEntityOfficerBulkUpdate(self.getFormData())
        .done(function() {
          Radio.channel('navigate').trigger('show:int:dp:entity', modelGet(self.model, 'entityIdentifiers.entityId'));
        })
        .fail(function() {
          laddaContext.stop();
        });
    });
  },

  validate: function() {
    var validationPromises = [this.ui.form.parsley().whenValidate()],
      self = this;

    self.ui.officerUpdateGridErrorsContainer.closest('div.form-group').removeClass('has-error');
    self.ui.officerUpdateGridErrorsContainer.hide();

    // Officer Grid Validation
    validationPromises.push(
      (function() {
        var def = $.Deferred();

        if (!self.officersGrid.viewGrid.getSelectedModels().length) {
          self.ui.officerUpdateGridErrorsContainer.closest('div.form-group').addClass('has-error');
          self.ui.officerUpdateGridErrorsContainer.show();

          def.reject();
        }

        def.resolve();

        return def.promise();
      })()
    );

    return $.when.apply($, validationPromises);
  },

  getFormData: function() {
    var formData = Syphon.serialize(this);

    formData.termStartDate = momentUtil.formatStr(formData.termStartDate, momentUtil.defaultOutboundDateConfig);
    formData.termEndDate = momentUtil.formatStr(formData.termEndDate, momentUtil.defaultOutboundDateConfig);

    formData.officerIds = _.map(this.officersGrid.viewGrid.getSelectedModels(), function(model) {
      return model.get('officerId');
    });

    return formData;
  }
});
