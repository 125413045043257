/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var cleanUpResponse = require('common/util/cleanUpResponse');

module.exports = function(formData) {
  var deferred = $.Deferred();

  $.soap({
    body: {
      SubmitMrba: {
        $xmlns: 'http://dobs.pa.gov/dp/mrba/bpm/1.0',
        Mrba: {
          MrbaID: formData.mrbaId,
          EntityID: formData.entityId,
          TypeID: formData.typeId,
          Number: formData.number,
          ExamAsOfDate: formData.examAsOfDate,
          CategoryID: formData.categoryId,
          AgencyID: formData.agencyId,
          DueDate: formData.dueDate,
          StatusID: formData.statusId,
          HistoryID: formData.historyId,
          LatestResponseDueDate: formData.latestResponseDueDate,
          ResponseHistory: formData.responseHistory,
          LatestReceivedDate: formData.latestReceivedDate,
          ReceivedHistory: formData.receivedHistory,
          IsDeleted: formData.isDeleted,
          Comments: formData.comments,
          Description: formData.description
        }
      }
    },
    blueprint: {
      mrbaId: '$..MrbaID'
    }
  })
    .done(function(response) {
      cleanUpResponse(response, { cleanUpTextKeys: true });
      deferred.resolve(response.mrbaId);
    })
    .fail(function(err) {
      deferred.reject();
    });

  return deferred.promise();
};
