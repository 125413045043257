/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Backbone = require('backbone');
var NProgress = require('nprogress');

var NavUtils = require('psa-core/util/nav');

var Scaffold = require('common/layout/Scaffold');
var GetUsersForRole = require('common/services/GetUsersForRole');

var SecOrganizationLicenseFinancialsNewView = require('../views/SecOrganizationLicenseFinancialsNewView');
var GetOrganizationLicenseIdentifiers = require('../services/GetOrganizationLicenseIdentifiers');
var GetLatestOrganizationLicenseFinancialByOrganizationAndLicense = require('../services/GetLatestOrganizationLicenseFinancialByOrganizationAndLicense');

module.exports = function(organizationId, licenseId) {
  NProgress.start();
  NProgress.set(0.6);
  $.when(
    GetOrganizationLicenseIdentifiers(licenseId),
    GetUsersForRole('SC - Chief Accounting'),
    GetLatestOrganizationLicenseFinancialByOrganizationAndLicense(organizationId, licenseId)
  )
    .done(function(identifiers, chiefAccountingUsers, latestFinancial) {
      NavUtils.navigate('#sec/organization/' + organizationId + '/license/' + licenseId + '/new-financials');
      Scaffold.content.show(
        new SecOrganizationLicenseFinancialsNewView({
          viewMode: 'int',
          model: new Backbone.Model({
            organizationId: organizationId,
            licenseId: licenseId,
            identifiers: identifiers,
            chiefAccountingUsers: chiefAccountingUsers,
            latestFinancial: latestFinancial
          })
        })
      );
      NProgress.done();
    })
    .fail(function(err) {
      NProgress.done();
    });
};
