/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');

module.exports = function(data) {
  return $.soap({
    body: {
      SubmitUpdateExamPayment: {
        $xmlns: 'http://dobs.pa.gov/nd/exam/bpm/1.0',
        ExamID: data.examId,
        PaymentAmount: data.totalPaymentDue,
        PaymentSchedule: (function() {
          var payments = _.map(data.paymentSchedule, function(payment) {
            return {
              Description: payment.description,
              Amount: payment.amount,
              FeeAmount: payment.feeAmount,
              DueDate: payment.dueDate.format('YYYY-MM-DD'),
              IsCompleted: payment.isCompleted
            };
          });
          if (payments) {
            return {
              Payment: payments
            };
          }
        })()
      }
    }
  });
};
