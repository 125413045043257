/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var _get = require('lodash.get');
var moment = require('moment');

module.exports = function(formData) {
  var filingId = formData.filingId;
  var pointOfContact = formData.pointOfContact;
  var lawyerExternalUserId = _get(formData, 'callReportSubmissionLawyer.lawyerExternalUserId');
  var submission = formData.submission || {};
  var supportingDocuments = formData.supportingDocuments || [];
  var certification = formData.certification || {};

  submission.reportDate = submission.reportDate ? moment(submission.reportDate, 'MM/DD/YYYY').format('YYYY-MM-DD') : '';

  return $.soap({
    body: {
      SubmitSubmitCallReportFiling: {
        $xmlns: 'http://dobs.pa.gov/dp/filing/bpm/1.0',
        FilingID: filingId,
        SubmitCallReportFilingID: formData.submitCallReportFilingId,
        ReportQuarterEndID: submission.reportQuarterEndId,
        ReportYear: submission.reportYear,
        ReportDate: submission.reportDate,
        IsAmended: submission.isAmended,
        TotalManagedAssets: submission.totalManagedAssets,
        TotalNonManagedAssets: submission.totalNonManagedAssets,
        CustodySafekeepingAccounts: submission.custodySafekeepingAccounts,
        TotalAssetsUnderManagement: submission.totalAssetsUnderManagement,
        TotalCapital: submission.totalCapital,
        TotalTrustCompanyAssets: submission.totalTrustCompanyAssets,
        ReportDocID: submission.reportDocId,
        Comments: submission.comments,
        IsCertified: certification.isCertified ? '1' : '0',
        CertificationName: certification.certificationName,
        CertificationDocID: certification.certificationDocId,
        PointOfContact: {
          Name: pointOfContact.name,
          Title: pointOfContact.title,
          Phone: pointOfContact.phone,
          Email: pointOfContact.email,
          AddressLine1: pointOfContact.addressLine1,
          AddressLine2: pointOfContact.addressLine2,
          AddressLine3: pointOfContact.addressLine3,
          City: pointOfContact.city,
          State: pointOfContact.state,
          PostalCode: pointOfContact.postalCode,
          County: pointOfContact.county,
          Country: pointOfContact.country
        },
        LawyerExternalUserID: lawyerExternalUserId,
        SupportingDocuments: (function() {
          if (!supportingDocuments.length) {
            return {};
          }
          return {
            Document: _.map(supportingDocuments, function(supportingDocument) {
              return {
                DocumentID: supportingDocument.fileId
              };
            })
          };
        })()
      }
    }
  });
};
