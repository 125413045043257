/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');

var cleanUpResponse = require('common/util/cleanUpResponse');

module.exports = function(entityCategoryId, entityCharterId) {
  var defer = $.Deferred();

  $.soap({
    body: {
      GetAllEntityAuditLookups: {
        $xmlns: 'http://dobs.pa.gov/dp/lookup/bpm/1.0'
      }
    },
    blueprintArrays: [
      'GetAllEntityAuditLookupsResponse.AuditOpinions.DP_LU_AuditOpinion',
      'GetAllEntityAuditLookupsResponse.AuditTypes.DP_LU_AuditType',
      'GetAllEntityAuditLookupsResponse.AuditTrustTypes.DP_LU_AuditTrustType',
      'GetAllEntityAuditLookupsResponse.AuditFirms.DP_LU_AuditFirm'
    ],
    blueprint: {
      auditOpinions: [
        '$..DP_LU_AuditOpinion',
        {
          id: '$.ID',
          opinion: '$.Opinion'
        }
      ],
      auditTypes: [
        '$..DP_LU_AuditType',
        {
          id: '$.ID',
          type: '$.Type',
          categoryId: '$.CategoryID'
        }
      ],
      auditTrustTypes: [
        '$..DP_LU_AuditTrustType',
        {
          id: '$.ID',
          trustType: '$.TrustType'
        }
      ],
      auditFirms: [
        '$..DP_LU_AuditFirm',
        {
          id: '$.ID',
          firm: '$.Firm'
        }
      ]
    }
  })
    .done(function(response) {
      cleanUpResponse(response);

      var isCategoryBank = entityCategoryId === '1';
      var isCategoryCU = entityCategoryId === '2';
      var isCategoryTrust = entityCategoryId === '3';
      var isCharterBankAndTrust = entityCharterId === '2' || entityCharterId === '31';

      response.auditTypes = _.where(response.auditTypes, { categoryId: entityCategoryId });
      
      if (isCategoryTrust) {
        response.auditTypes = _.filter(response.auditTypes, function(type) {
          return type.id !== '23';
        });
      }

      if (isCategoryBank && !isCharterBankAndTrust) {
        response.auditTypes = _.filter(response.auditTypes, function(type) {
          return type.id !== '3';
        });
      }

      if (isCategoryBank) {
        response.auditOpinions = _.filter(response.auditOpinions, function(type) {
          return type.id !== '2' && type.id !== '4' && type.id !== '5';
        });
      }

      if (isCategoryTrust) {
        response.auditOpinions = _.filter(response.auditOpinions, function(type) {
          return type.id !== '4' && type.id !== '5';
        });
      }

      if (isCategoryCU) {
        response.auditOpinions = _.filter(response.auditOpinions, function(type) {
          return type.id !== '2';
        });
      }

      response.auditFirms = _.sortBy(response.auditFirms, 'firm')

      defer.resolve(response);
    })
    .fail(function(err) {
      defer.reject(err);
    });

  return defer.promise();
};
