/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Backbone = require('backbone');
var Marionette = require('backbone.marionette');
var Syphon = require('backbone.syphon');
var Radio = require('backbone.radio');
var Ladda = require('ladda');

var modelGet = require('common/util/modelGet');
var HeaderBarView = require('common/views/HeaderBarView');
var BreadcrumbView = require('common/views/BreadcrumbView');
var FileUploadTableView = require('common/views/FileUploadTableView');

var SubmitReviewCorrespondenceTask = require('../../services/SubmitReviewCorrespondenceTask');

var tmplSecReviewCorrespondenceTaskView = require('./SecReviewCorrespondenceTaskView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplSecReviewCorrespondenceTaskView,

  className: 'sec-review-correspondence-task-view container-fluid',

  ui: {
    headerBar: '.header-bar',
    breadcrumb: '.breadcrumb-container',
    correspondenceForm: '.correspondence-form',
    extFileUploadTableContainer: '.ext-file-upload-table-container',
    intFileUploadTableContainer: '.int-file-upload-table-container',
    submit: '.submit'
  },

  regions: {
    headerBar: '@ui.headerBar',
    breadcrumb: '@ui.breadcrumb',
    extFileUploadTableContainer: '@ui.extFileUploadTableContainer',
    intFileUploadTableContainer: '@ui.intFileUploadTableContainer'
  },

  events: {
    'click @ui.submit': 'onClickSubmit'
  },

  behaviors: {
    TaskBehavior: {}
  },

  templateHelpers: function() {
    return {
      hasFinDocs: modelGet(this.model, 'correspondence.hasFinDocs') === '1'
    };
  },

  onBeforeShow: function() {
    var correspondence = this.model.get('correspondence') || {};
    var intDocuments = modelGet(this.model, 'correspondence.intDocuments', []);
    var extDocuments = modelGet(this.model, 'correspondence.extDocuments', []);

    var headerBarColumns = [
      {
        name: 'orgLegalName',
        label: 'Applicant Legal Name',
        minWidth: '160px',
        maxWidth: '300px'
      },
      {
        name: 'crdNumber',
        label: 'CRD Number',
        minWidth: '160px',
        maxWidth: '300px'
      }
    ];

    this.showChildView(
      'headerBar',
      new HeaderBarView({
        columns: new Backbone.Collection(headerBarColumns),
        model: new Backbone.Model(correspondence)
      })
    );

    this.showChildView(
      'breadcrumb',
      new BreadcrumbView({
        breadcrumbs: [
          {
            link: '#sec/dashboard',
            text: 'Home'
          },
          {
            text: 'Review to Correspondence'
          }
        ]
      })
    );

    Syphon.deserialize(this, correspondence);
    this.intFileUploadTableView = new FileUploadTableView({
      collection: new Backbone.Collection(intDocuments),
      emptyText: 'No Internal Documents',
      isReadOnly: true
    });

    this.showChildView('intFileUploadTableContainer', this.intFileUploadTableView);

    this.extFileUploadTableView = new FileUploadTableView({
      collection: new Backbone.Collection(extDocuments),
      emptyText: 'No External Documents',
      isReadOnly: true
    });

    this.showChildView('extFileUploadTableContainer', this.extFileUploadTableView);
  },

  onClickSubmit: function(e) {
    e.preventDefault();
    var l = Ladda.create(this.ui.submit[0]);
    l.start();
    SubmitReviewCorrespondenceTask({ taskId: this.model.get('taskId') })
      .done(function() {
        Radio.channel('navigate').trigger('show:int:sc:dashboard');
      })
      .fail(function() {
        l.stop();
      });
  }
});
