/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Marionette = require('backbone.marionette');
var formatAsDollars = require('common/util/formatAsDollars');

var tmplNonDepComplianceGeneralInformationSectionView = require('../templates/NonDepComplianceGeneralInformationSectionView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplNonDepComplianceGeneralInformationSectionView,

  className: 'non-dep-compliance-general-information-section-view',

  onBeforeRender: function() {
    var caseObj = this.model.get('case');
    var fineAmountOrderedStr = caseObj.fineAmountOrdered;
    if (fineAmountOrderedStr) {
      var fineAmount = formatAsDollars(parseFloat(fineAmountOrderedStr));
      caseObj.fineAmountOrdered = fineAmount;
    } else {
      caseObj.fineAmountOrdered = '$0.00';
    }
  }
});
