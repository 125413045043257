/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var Backbone = require('backbone');
var Marionette = require('backbone.marionette');
var Syphon = require('backbone.syphon');
var Ladda = require('ladda');
var Radio = require('backbone.radio');

var AddressDisplayView = require('common/views/AddressDisplayView');
var SelectFieldView = require('common/views/SelectFieldView');
var addressToString = require('common/util/addressToString');
var BreadcrumbView = require('common/views/BreadcrumbView');

var EntityHeaderBarView = require('../../common/views/EntityHeaderBarView');

var GetActiveLicensesAndPendingApplicationsByTypeAndEntity = require('../services/GetActiveLicensesAndPendingApplicationsByTypeAndEntity');
var SubmitNewLicenseApplication = require('../services/SubmitNewLicenseApplication');

var tmplNewLicenseApplicationView = require('../templates/NewLicenseApplicationView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplNewLicenseApplicationView,

  className: 'new-license-application-view container-fluid',

  ui: {
    headerBar: '.header-bar',
    breadcrumb: '.breadcrumb-container',
    applyForLicenseForm: '.apply-for-license-form',
    licenseTypeSelect: '.license-type-select',
    parentLicenseFormGroup: '.parent-license-form-group',
    parentLicenseSelectContainer: '.parent-license-select-container',
    parentLicenseSelect: '.parent-license-select',
    parentSelectHelpBlock: '.parent-select-help-block',
    parentIsApplicationWarning: '.parent-is-application-warning',
    locationSelect: '.location-select',
    submit: '.submit'
  },

  regions: {
    parentLicenseSelectContainer: '@ui.parentLicenseSelectContainer',
    headerBar: '@ui.headerBar',
    breadcrumb: '@ui.breadcrumb'
  },

  events: {
    'change @ui.licenseTypeSelect': 'onChangeLicenseType',
    'click @ui.submit': 'onClickSubmit',
    'change @ui.parentLicenseSelect': 'onChangeParentLicense'
  },

  onBeforeShow: function() {
    this.showChildView(
      'headerBar',
      new EntityHeaderBarView({
        model: new Backbone.Model(this.model.get('entityIdentifiers'))
      })
    );

    this.showChildView(
      'breadcrumb',
      new BreadcrumbView({
        breadcrumbs: [
          {
            link: '#ext/nondep/dashboard',
            text: 'Home'
          },
          {
            link: '#ext/nondep/entity/' + this.model.get('entityIdentifiers').id + '/dashboard',
            text: this.model.get('entityIdentifiers').entityName
          },
          {
            text: 'Apply for a License'
          }
        ]
      })
    );
    this.ui.locationSelect.select2({
      allowClear: true,
      data: this.model.get('entityLocations'),
      dropdownCssClass: 'select2-options-bordered',
      templateResult: function(location) {
        var addressMarkup = new AddressDisplayView({
          model: new Backbone.Model(location)
        }).render().el.outerHTML;
        if (location.isPrimaryPlaceOfBusiness) {
          addressMarkup += '<span class="label label-primary">Headquarters</span>';
        }
        return $(addressMarkup);
      },
      templateSelection: function(location) {
        if (location.addressLine1) {
          return $('<span>' + location.addressLine1 + '</span>');
        } else {
          return $('<span>--</span>');
        }
      },
      matcher: function(params, data) {
        // If there are no search terms, return all of the data
        if (!params.term || params.term.trim() === '') {
          return data;
        }
        // Reduce address to single string for matching.
        var addressStr = addressToString(data, { separator: ' ' }) || '';
        var searchTerm = params.term.toLowerCase();
        var target = addressStr.toLowerCase();
        if (target.indexOf(searchTerm) > -1) {
          return data;
        }
        // Return `null` if the term should not be displayed
        return null;
      }
    });

    this.parentLicenseSelect = new SelectFieldView({
      isDisabled: true,
      isRequired: true,
      name: 'parentObjectId',
      selectClass: 'parent-license-select',
      collection: new Backbone.Collection([])
    });
    this.showChildView('parentLicenseSelectContainer', this.parentLicenseSelect);
  },

  onChangeLicenseType: function(e) {
    var self = this,
      licenseTypeId = this.ui.licenseTypeSelect.val(),
      licenseTypes = this.model.get('licenseTypes');

    var selectedLicenseType = _.findWhere(licenseTypes, {
      id: licenseTypeId
    });

    if (selectedLicenseType && selectedLicenseType.isBranch) {
      var data = {
        licenseTypeId: selectedLicenseType.branchParentLicenseTypeId,
        entityId: this.model.get('entityIdentifiers').id
      };
      this.setParentLicenseDisabled(false);
      this.parentLicenseSelect.setDisabled(true);
      this.ui.submit.attr('disabled', true);
      GetActiveLicensesAndPendingApplicationsByTypeAndEntity(data)
        .done(function(data) {
          var options = _.map(data.licenses, function(license) {
            return {
              value: license.id,
              text: license.licenseType + ' License #' + license.licenseNumber + ' at ' + license.addressLine1,
              attributes: {
                'data-object-type': 'LICENSE'
              }
            };
          });

          var pendingApplications = _.map(data.applications, function(application) {
            return {
              value: application.id,
              text: application.applicationType + ' Application #' + application.id + ' at ' + application.addressLine1,
              attributes: {
                'data-object-type': 'APPLICATION'
              }
            };
          });

          options = options.concat(pendingApplications);

          self.parentLicenseSelect.collection.reset(options);
          self.parentLicenseSelect.setDisabled(false);
          self.ui.submit.attr('disabled', false);
        })
        .fail(function(err) {
          self.setParentLicenseDisabled(true);
        });
    } else {
      this.setParentLicenseDisabled(true);
    }
  },

  onChangeParentLicense: function() {
    var parentLicense = this.$('select.parent-license-select').children('option:selected');

    if (parentLicense && parentLicense.data('object-type') === 'APPLICATION') {
      this.ui.parentSelectHelpBlock.hide();
      this.ui.parentIsApplicationWarning.show();
      this.ui.parentIsApplicationWarning.closest('.form-group').addClass('has-warning');
    } else {
      this.ui.parentSelectHelpBlock.show();
      this.ui.parentIsApplicationWarning.hide();
      this.ui.parentIsApplicationWarning.closest('.form-group').removeClass('has-warning');
    }
  },

  onClickSubmit: function(e) {
    e.preventDefault();
    var self = this,
      l = Ladda.create(this.ui.submit[0]);
    l.start();

    this.ui.applyForLicenseForm
      .parsley()
      .whenValidate()
      .done(function() {
        var formData = Syphon.serialize(self);

        formData.parentObjectType = self.$('.parent-license-select option:selected').data('object-type');

        SubmitNewLicenseApplication(formData)
          .done(function(newApplicationId) {
            Radio.channel('navigate').trigger('show:ext:nd:draft-license-application', newApplicationId);
          })
          .fail(function() {
            l.stop();
          });
      })
      .fail(function() {
        l.stop();
      });
  },

  setParentLicenseDisabled: function(isDisabled) {
    if (isDisabled) {
      this.ui.parentLicenseFormGroup.removeClass('required');
      this.parentLicenseSelect.setValue('');
      this.ui.parentLicenseFormGroup.addClass('disabled');
      this.parentLicenseSelect.setDisabled(true);
    } else {
      this.ui.parentLicenseFormGroup.addClass('required');
      this.ui.parentLicenseFormGroup.removeClass('disabled');
      this.parentLicenseSelect.setDisabled(false);
    }
  }
});
