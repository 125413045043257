/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Backbone = require('backbone');
var Marionette = require('backbone.marionette');

var GridView = require('psa-marionette/views/GridView');
var PageableCollection = require('psa-backbone/models/PageableCollection');

var FileUploadCell = require('common/backgrid/FileUploadCell');
var AddressDisplayView = require('common/views/AddressDisplayView');
var FileUploadView = require('common/views/FileUploadView');
var MomentCell = require('common/backgrid/MomentCell');
var makeUploadCollection = require('common/util/makeUploadCollection');

require('../styles/NonDepLicenseGeneralInformationView.css');
var tmplNonDepLicenseGeneralInformationView = require('../templates/NonDepLicenseGeneralInformationView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplNonDepLicenseGeneralInformationView,

  className: 'non-dep-license-general-information-view',

  ui: {
    locationContainer: '.license-location-container',
    certificateContainer: '.license-certificate-container',
    historyView: '.license-history-view',
    historyContainer: '.license-history-container',
    dba: '.dba'
  },

  regions: {
    dba: '@ui.dba',
    locationContainer: '@ui.locationContainer',
    certificateContainer: '@ui.certificateContainer',
    historyContainer: '@ui.historyContainer'
  },

  templateHelpers: function() {
    return {
      viewMode: this.options.viewMode
    };
  },

  onBeforeShow: function() {
    var isNMLSLicense = this.model.get('isNMLSLicense');

    this.showChildView(
      'locationContainer',
      new AddressDisplayView({
        model: new Backbone.Model(this.model.get('licenseAddress'))
      })
    );

    if (!isNMLSLicense) {
      this.showChildView(
        'certificateContainer',
        new FileUploadView({
          isReadOnly: true,
          collection: makeUploadCollection(this.model.get('certificateDocument'))
        })
      );
    }

    this.showChildView(
      'dba',
      new GridView({
        columns: [
          {
            name: 'dbaName',
            label: 'Name',
            cell: 'string',
            headerCell: 'custom',
            width: 50,
            editable: false
          },
          {
            name: 'cancelledDate',
            label: 'Cancelled Date',
            cell: MomentCell.MomentCell,
            formatter: MomentCell.MomentDateFormatter.extend({
              emptyValue: '--'
            }),
            sortValue: MomentCell.MomentSortValue,
            headerCell: 'custom',
            width: 40,
            editable: false
          },
          {
            name: 'fictitiousNameDocument',
            label: 'Fictitious Name Registration Document',
            cell: FileUploadCell.extend({
              isReadOnly: true
            }),
            headerCell: 'custom',
            width: 50,
            editable: false,
            sortable: false
          }
        ],
        collection: new PageableCollection(this.model.get('licenseDbas'), { pageSize: 10 }),
        emptyText: 'License has no D/B/A registrations.'
      })
    );

    if (this.options.viewMode === 'int') {
      this.statusHistoryGrid = new GridView({
        columns: [
          {
            name: 'status',
            label: 'Status',
            cell: 'string',
            headerCell: 'custom',
            editable: false,
            width: 60
          },
          {
            name: 'effectiveDate',
            label: 'Effective Date',
            cell: MomentCell.MomentCell,
            formatter: MomentCell.MomentDateFormatter.extend({
              emptyValue: '--'
            }),
            sortValue: MomentCell.MomentSortValue,
            headerCell: 'custom',
            editable: false,
            width: 40
          }
        ],
        collection: new PageableCollection(this.model.get('licenseStatusHistory'), { pageSize: 10 }),
        emptyText: 'No License Status History'
      });
      this.showChildView('historyContainer', this.statusHistoryGrid);

      this.statusHistoryGrid.viewGrid.sort('effectiveDate', 'descending');
    }
  }
});
