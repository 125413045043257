/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var Backbone = require('backbone');
var Marionette = require('backbone.marionette');
var Syphon = require('backbone.syphon');
var Radio = require('backbone.radio');
var Ladda = require('ladda');

var makeUploadCollection = require('common/util/makeUploadCollection');
var HeaderBarView = require('common/views/HeaderBarView');
var HeaderBarAddressCellView = require('common/views/HeaderBarView/HeaderBarAddressCellView');
var BreadcrumbView = require('common/views/BreadcrumbView');
var FileUploadView = require('common/views/FileUploadView');

var SubmitVoluntaryFilingWithdrawalTask = require('../../services/SubmitVoluntaryFilingWithdrawalTask');

var tmplDepReviewVoluntaryFilingWithdrawalTaskView = require('./DepReviewVoluntaryFilingWithdrawalTaskView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplDepReviewVoluntaryFilingWithdrawalTaskView,

  className: 'dep-review-voluntary-filing-withdrawal-task-view container-fluid',

  ui: {
    headerBar: '.header-bar',
    breadcrumb: '.breadcrumb-container',
    reviewWithdrawalForm: '.review-withdrawal-form',
    withdrawalLetterContainer: '.withdrawal-letter-container',
    extWithdrawalLetterContainer: '.ext-withdrawal-letter-container',
    submit: '.submit'
  },

  regions: {
    headerBar: '@ui.headerBar',
    breadcrumb: '@ui.breadcrumb',
    withdrawalLetterContainer: '@ui.withdrawalLetterContainer',
    extWithdrawalLetterContainer: '@ui.extWithdrawalLetterContainer'
  },

  events: {
    'click @ui.submit': 'onClickSubmit'
  },

  behaviors: {
    TaskBehavior: {}
  },

  onBeforeShow: function() {
    var filingDetails = this.model.get('filingDetails') || {};
    var pointOfContact = filingDetails.pointOfContact || {};
    var ppobAddress = filingDetails.ppobAddress || {};
    var filingType = filingDetails.filingType;

    var headerBarData = {
      filingType: filingDetails.filingType,
      entityName: filingDetails.entityName,
      entityType: filingDetails.entityType,
      address: {
        addressLine1: ppobAddress.addressLine1,
        addressLine2: ppobAddress.addressLine2,
        city: ppobAddress.city,
        state: ppobAddress.state,
        postalCode: ppobAddress.postalCode,
        country: ppobAddress.country,
        county: ppobAddress.county
      },
      pointOfContact: pointOfContact.name || '--'
    };

    var headerBarColumns = [
      {
        name: 'filingType',
        label: 'Filing Type',
        minWidth: '160px',
        maxWidth: '200px'
      },
      {
        name: 'entityName',
        label: 'Entity Name',
        minWidth: '160px',
        maxWidth: '300px'
      },
      {
        name: 'entityType',
        label: 'Entity Type',
        minWidth: '160px',
        maxWidth: '300px'
      },
      {
        name: 'pointOfContact',
        label: 'Point of Contact',
        width: '160px',
        overflow: 'truncate'
      },
      {
        name: 'address',
        label: 'Principal Place of Business Address',
        minWidth: '160px',
        headerCell: HeaderBarAddressCellView
      }
    ];

    this.showChildView(
      'headerBar',
      new HeaderBarView({
        columns: new Backbone.Collection(headerBarColumns),
        model: new Backbone.Model(headerBarData)
      })
    );

    this.showChildView(
      'breadcrumb',
      new BreadcrumbView({
        breadcrumbs: [
          {
            link: '#dep/dashboard',
            text: 'Home'
          },
          {
            text: 'Review Voluntary Withdrawal of ' + filingType
          }
        ]
      })
    );

    this.withdrawalLetter = new FileUploadView({
      isRequired: true,
      collection: makeUploadCollection(this.model.get('withdrawalLetterCollection')),
      isReadOnly: this.options.isReadOnly,
      metadata: {
        documentTypeId: '20006'
      }
    });
    this.showChildView('withdrawalLetterContainer', this.withdrawalLetter);

    this.extWithdrawalLetter = new FileUploadView({
      isRequired: true,
      collection: makeUploadCollection(filingDetails.withdraw.file),
      isReadOnly: true,
      metadata: {
        documentTypeId: '20006'
      }
    });
    this.showChildView('extWithdrawalLetterContainer', this.extWithdrawalLetter);
  },

  onClickSubmit: function(e) {
    e.preventDefault();
    var self = this;
    this.validate().done(function() {
      var l = Ladda.create(self.ui.submit[0]);
      l.start();
      SubmitVoluntaryFilingWithdrawalTask(self.getFormData())
        .done(function() {
          Radio.channel('navigate').trigger('show:int:dp:dashboard');
        })
        .fail(function() {
          l.stop();
        });
    });
  },

  validate: function() {
    return $.when.apply($, [this.ui.reviewWithdrawalForm.parsley().whenValidate(), this.withdrawalLetter.validate()]);
  },

  getFormData: function() {
    var formData = Syphon.serialize(this);

    _.extend(formData, {
      taskId: this.model.get('taskId'),
      filingId: this.model.get('filingId'),
      withdrawalLetterDocId: this.withdrawalLetter.getFirstFileId()
    });

    return formData;
  }
});
