/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Marionette = require('backbone.marionette');
var Syphon = require('backbone.syphon');
var Radio = require('backbone.radio');
var Ladda = require('ladda');

var UIUtil = require('psa-core/util/ui');

var BreadcrumbView = require('common/views/BreadcrumbView');
var ConfirmationModalView = require('common/views/ConfirmationModalView');

var SecInternalSidebarNavView = require('./SecInternalSidebarNavView');
var SubmitNewOrganization = require('../services/SubmitNewOrganization');
var GetOrganizationCRDNumberExists = require('../../organization-dashboard/services/GetOrganizationCRDNumberExists');

require('../styles/SecOrganizationNewView.css');
var tmplSecOrganizationNewView = require('../templates/SecOrganizationNewView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplSecOrganizationNewView,

  className: 'sec-organization-new-view container-fluid',

  ui: {
    sideBar: '.side-bar-container',
    breadCrumbContainer: '.breadcrumb-container',
    newOrganizationForm: '.new-organization-form',
    submit: '.btn-submit',
    noCRDNumber: '.no-crd-number',
    nonFINRANumber: '.nonFINRANumber',
    crdNumber: 'input[name="crdNumber"]',
    crdNumberFormGroup: '.crdnumberform-group'
  },

  regions: {
    breadCrumbContainer: '@ui.breadCrumbContainer',
    sideBar: '@ui.sideBar'
  },

  events: {
    'click @ui.submit': 'onClickSubmit',
    'click @ui.noCRDNumber': 'onClickNoCRD'
  },

  onBeforeShow: function() {
    this.showChildView('sideBar', new SecInternalSidebarNavView());
    this.showChildView(
      'breadCrumbContainer',
      new BreadcrumbView({
        breadcrumbs: [
          {
            text: 'Home',
            link: '#sec/dashboard'
          },
          {
            text: 'Create A New Organization'
          }
        ]
      })
    );
  },

  onClickNoCRD: function() {
    if (this.ui.noCRDNumber[0].checked) {
      this.ui.crdNumber.prop('disabled', true);
      this.ui.crdNumberFormGroup.toggleClass('required', false);
    } else {
      this.ui.crdNumber.prop('disabled', false);
      this.ui.crdNumberFormGroup.toggleClass('required', true);
    }
  },

  onClickSubmit: function(e) {
    e.preventDefault();
    var self = this;
    var formData = this.getFormData();

    if (this.ui.nonFINRANumber[0].checked) {
      formData.nonFINRANumber = 1;
    } else {
      formData.nonFINRANumber = null;
    }

    this.validate().done(function() {
      var spinner = Ladda.create(self.ui.submit.get(0));
      spinner.start();
      GetOrganizationCRDNumberExists(formData)
        .done(function(organizationCRDNumberExists) {
          if (organizationCRDNumberExists) {
            spinner.stop();
            self.showDuplicateCRDConfirmationModal();
          } else {
            SubmitNewOrganization(formData)
              .done(function(newOrgId) {
                Radio.channel('navigate').trigger('show:int:sc:organization', newOrgId);
              })
              .fail(function() {
                spinner.stop();
              });
          }
        })
        .fail(function() {
          spinner.stop();
        });
    });
  },

  validate: function() {
    return this.ui.newOrganizationForm.parsley().whenValidate();
  },

  getFormData: function() {
    return Syphon.serialize(this.ui.newOrganizationForm);
  },

  showDuplicateCRDConfirmationModal: function() {
    var formData = this.getFormData();
    var crdNumber = formData.crdNumber;

    return UIUtil.showModal({
      title: 'Duplicate CRD Number ' + crdNumber,
      view: new ConfirmationModalView({
        confirmButtonClass: 'collapse',
        rejectText: 'Close',
        model: new Backbone.Model({
          message:
            'Another organization already exists with CRD Number ' +
            crdNumber +
            '. Please ensure that the organization you are trying to create does not already exist before proceeding.'
        })
      })
    });
  }
});

