var Handlebars = require("handlebars/dist/handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.escapeExpression, alias2=depth0 != null ? depth0 : (container.nullContext || {}), alias3=helpers.helperMissing, alias4="function";

  return "\n<div class=\"page-title clearfix\">\n  <h1 class=\"pull-left m-n p-n\">"
    + alias1(container.lambda(((stack1 = (depth0 != null ? depth0.identifiers : depth0)) != null ? stack1.dbaName : stack1), depth0))
    + "</h1>\n</div>\n<div class=\"header-bar-container\"></div>\n<div class=\"breadcrumb-container\"></div>\n<div class=\"row m-b-xs\">\n  <div class=\"col-sm-12 col-md-10 col-md-offset-1\">\n    <div class=\"form-group\">\n      <div class=\"col-sm-9 col-md-8 col-md-offset-2 col-sm-offset-3\">\n        <h3 class=\"m-t-n\">Edit License</h3>\n      </div>\n    </div>\n  </div>\n</div>\n<form class=\"license-form-container\"></form>\n<div class=\"row m-b-lg\">\n  <div class=\"col-sm-12 col-md-10 col-md-offset-1\">\n    <div class=\"form-group\">\n      <div class=\"col-sm-9 col-md-8 col-md-offset-2 col-sm-offset-3\">\n        <a href=\"#sec/individual/"
    + alias1(((helper = (helper = helpers.individualId || (depth0 != null ? depth0.individualId : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias2,{"name":"individualId","hash":{},"data":data}) : helper)))
    + "/license/"
    + alias1(((helper = (helper = helpers.licenseId || (depth0 != null ? depth0.licenseId : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias2,{"name":"licenseId","hash":{},"data":data}) : helper)))
    + "/dashboard\" class=\"btn btn-default\">Cancel</a>\n        <button class=\"btn btn-primary btn-submit\">Submit</button>\n      </div>\n    </div>\n  </div>\n</div>";
},"useData":true});