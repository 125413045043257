/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var cleanUpResponse = require('common/util/cleanUpResponse');

module.exports = function(annualReportId) {
  var deferred = $.Deferred();

  $.soap({
    body: {
      GetMortgageDiscountCompanyAnnualReport: {
        $xmlns: 'http://dobs.pa.gov/nd/annualreport/bpm/1.0',
        AnnualReportID: annualReportId
      }
    },
    blueprint: {
      id: '$..ID',
      annualReportId: '$..AnnualReportID',
      loanReceivablesHeldNotAvailable: '$..LoanReceivablesHeldNotAvailable',
      loanReceivablesHeldNotAvailableExplanation: '$..LoanReceivablesHeldNotAvailableExplanation',
      loanReceivablesHeldNumber: '$..LoanReceivablesHeldNumber',
      loanReceivablesHeldDollars: '$..LoanReceivablesHeldDollars',
      loansNotAvailable: '$..LoansNotAvailable',
      loansNotAvailableExplanation: '$..LoansNotAvailableExplanation',
      loansNumber: '$..LoansNumber',
      loansDollars: '$..LoansDollars',
      repossessionNotAvailable: '$..RepossessionNotAvailable',
      repossessionNotAvailableExplanation: '$..RepossessionNotAvailableExplanation',
      repossessionNumber: '$..RepossessionNumber',
      repossessionDollars: '$..RepossessionDollars',
      hasServicedUnownedPAMortgageLoans: '$..HasServicedUnownedPAMortgageLoans',
      hasServicedMortgageExplaination: '$..HasServicedUnownedPAMortgageLoansExplaination',
      certificationName: '$..CertificationName',
      isCertified: '$..IsCertified'
    }
  })
    .done(function(response) {
      cleanUpResponse(response);

      response.loanReceivablesHeldNotAvailable = response.loanReceivablesHeldNotAvailable === '1';
      response.loansNotAvailable = response.loansNotAvailable === '1';
      response.repossessionNotAvailable = response.repossessionNotAvailable === '1';
      response.isCertified = response.isCertified === '1';
      response.loanReceivablesInputsExplanation = response.loanReceivablesHeldNotAvailableExplanation;
      response.loansInputsExplanation = response.loansNotAvailableExplanation;
      response.repossessionInputsExplanation = response.repossessionNotAvailableExplanation;

      if (response.operatingStateCodes) {
        response.operatingStateCodes = JSON.parse(response.operatingStateCodes);
      }
      deferred.resolve(response);
    })
    .fail(function(err) {
      deferred.reject();
    });

  return deferred.promise();
};
