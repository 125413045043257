/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Marionette = require('backbone.marionette');

var BreadcrumbView = require('common/views/BreadcrumbView');

var NonDepEntityFormView = require('./NonDepEntityFormView');

var tmplNonDepEntityFormDraftPageView = require('../templates/NonDepEntityFormDraftPageView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplNonDepEntityFormDraftPageView,

  className: 'nondep-entity-form-edit-page-view container-fluid',

  ui: {
    editEntityForm: '.edit-entity-form',
    breadcrumb: '.breadcrumb-container'
  },

  regions: {
    editEntityForm: '@ui.editEntityForm',
    breadcrumb: '@ui.breadcrumb'
  },

  templateHelpers: function() {
    return {
      isExtEdit: this.options.viewMode === 'extEdit'
    };
  },

  onBeforeShow: function() {
    var entityName = this.model.get('entityName');
    var entityId = this.model.get('id');

    var breadcrumbs = [
      {
        link: '#ext/nondep/dashboard',
        text: 'Home'
      }
    ];

    if (this.options.viewMode === 'extEdit') {
      breadcrumbs = breadcrumbs.concat([
        {
          link: '#ext/nondep/entity/' + entityId + '/dashboard',
          text: entityName
        }
      ]);
    }

    breadcrumbs.push({
      text: 'Edit Entity'
    });

    this.showChildView(
      'breadcrumb',
      new BreadcrumbView({
        breadcrumbs: breadcrumbs
      })
    );

    this.form = new NonDepEntityFormView({
      useNavigationButtons: true,
      viewMode: this.options.viewMode,
      model: this.model
    });
    this.showChildView('editEntityForm', this.form);
  }
});
