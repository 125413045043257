/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2020] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var Backbone = require('backbone');
var Marionette = require('backbone.marionette');
var Syphon = require('backbone.syphon');
var Radio = require('backbone.radio');
var Ladda = require('ladda');

var toggleForm = require('common/util/toggleForm');

var BreadcrumbView = require('common/views/BreadcrumbView');
var HeaderBarView = require('common/views/HeaderBarView');
var FileUploadTableView = require('common/views/FileUploadTableView');

var SubmitNewExam = require('../services/SubmitNewExam');

var tmplSecExamNewView = require('../templates/SecExamNewView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplSecExamNewView,

  className: 'sec-exam-new-view container-fluid',

  ui: {
    headerBar: '.header-bar-container',
    breadcrumb: '.breadcrumb-container',
    exanForm: 'form',
    categorySelect: 'select[name="categoryId"]',
    sourceSection: '.source-section',
    subTypeSelect: 'select[name="subtypeId"]',
    branchSection: '.branch-section',
    fileUploadTable: '.file-upload-table',
    submit: '.submit',
    openedDate: '.opened-date'
  },

  regions: {
    headerBar: '@ui.headerBar',
    breadcrumb: '@ui.breadcrumb',
    fileUploadTable: '@ui.fileUploadTable'
  },

  events: {
    'change @ui.categorySelect': 'onChangeCategory',
    'change @ui.subTypeSelect': 'onChangeSubType',
    'click @ui.submit': 'onClickSubmit'
  },

  onBeforeShow: function() {
    var identifiers = this.model.get('identifiers') || {};
    var licenseId = this.model.get('licenseId') || {};
    var organizationId = this.model.get('organizationId');

    this.showChildView(
      'breadcrumb',
      new BreadcrumbView({
        breadcrumbs: [
          {
            link: '#sec/dashboard',
            text: 'Home'
          },
          {
            link: '#sec/organization/' + organizationId + '/dashboard',
            text: identifiers.applicantLegalName
          },
          {
            link: '#sec/organization/' + organizationId + '/license/' + licenseId + '/dashboard',
            text: 'License Type - ' + identifiers.type
          },
          {
            text: 'New Exam'
          }
        ]
      })
    );

    var headerBarColumns = [
      {
        name: 'CRDNumber',
        label: 'CRD Number',
        minWidth: '160px',
        maxWidth: '200px'
      }
    ];

    this.showChildView(
      'headerBar',
      new HeaderBarView({
        columns: new Backbone.Collection(headerBarColumns),
        model: new Backbone.Model(identifiers)
      })
    );

    var allDocumentTypes = this.model.get('documentTypes');
    var documentTypesToShow = ['30007', '30008', '30012']; // Exam Set Up, Exam Supporting Document, Financial
    var filteredDocumentTypes = _.filter(allDocumentTypes, function(documentTypes) {
      return _.contains(documentTypesToShow, documentTypes.value);
    });

    this.fileUploadTable = new FileUploadTableView({
      documentTypes: filteredDocumentTypes,
      emptyText: 'No Document Provided',
      collection: new Backbone.Collection()
    });
    this.showChildView('fileUploadTable', this.fileUploadTable);

    toggleForm(this.ui.branchSection, false);
    this.ui.openedDate.datepicker();
  },

  onChangeCategory: function(e) {
    e.preventDefault();

    // Hide source section when exam category is 'Routine Examination'
    toggleForm(this.ui.sourceSection, this.ui.categorySelect.val() !== '2');
  },

  onChangeSubType: function(e) {
    e.preventDefault();

    // Show branch section when exam subType is 'Branch'
    toggleForm(this.ui.branchSection, this.ui.subTypeSelect.val() === '2');
  },

  onClickSubmit: function(event) {
    event.preventDefault();
    var self = this;
    this.validate().done(function() {
      var l = Ladda.create(self.ui.submit[0]);
      l.start();
      var formData = self.getFormData();
      SubmitNewExam(formData)
        .done(function(examId) {
          Radio.channel('navigate').trigger('show:int:sc:exam-dashboard', examId);
        })
        .fail(function() {
          l.stop();
        });
    });
  },

  getFormData: function() {
    var formData = Syphon.serialize(this);

    formData.organizationId = this.model.get('organizationId');
    formData.organizationLicenseId = this.model.get('licenseId');
    formData.documents = this.fileUploadTable.getFormData();
    return formData;
  },

  validate: function() {
    return $.when.apply($, [this.ui.exanForm.parsley().whenValidate(), this.fileUploadTable.validate()]);
  }
});
