/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Marionette = require('backbone.marionette');
var Radio = require('backbone.radio');

var MomentCell = require('common/backgrid/MomentCell');
var LinkCell = require('common/backgrid/LinkCell');
var ButtonLinkCell = require('common/backgrid/ButtonLinkCell');
var GridSpinnerView = require('common/views/GridSpinnerView');

var SecInternalSidebarNavView = require('./SecInternalSidebarNavView');

require('../styles/SecInternalAllTasksView.css');
var tmplSecInternalAllTasksView = require('../templates/SecInternalAllTasksView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplSecInternalAllTasksView,

  className: 'sec-internal-all-tasks-view container-fluid',

  ui: {
    sideBar: '.side-bar-container',
    allTasksContainer: '.all-tasks-container'
  },

  regions: {
    sideBar: '@ui.sideBar',
    allTasksContainer: '@ui.allTasksContainer'
  },

  onBeforeShow: function() {
    this.showChildView('sideBar', new SecInternalSidebarNavView());
    var taskGridColumns = [
      {
        name: 'type',
        label: 'Task Name',
        cell: 'string',
        headerCell: 'custom',
        width: 18,
        editable: false
      },
      {
        name: 'applicantLegalName',
        label: 'Applicant Legal Name',
        cell: LinkCell.extend({
          emptyText: '--',
          href: function(model) {
            var organizationId = model.get('organizationId');
            var url = organizationId ? '#sec/organization/' + organizationId + '/dashboard' : '';
            return url;
          }
        }),
        headerCell: 'custom',
        width: 18,
        editable: false
      },
      {
        name: 'primaryExaminer',
        label: 'Primary Examiner',
        cell: 'string',
        headerCell: 'custom',
        width: 17,
        editable: false
      },
      {
        name: 'deliveryDate',
        label: 'Assigned On',
        cell: MomentCell.MomentCell,
        formatter: MomentCell.MomentDateTimeFormatter.extend({
          emptyValue: '--'
        }),
        sortValue: MomentCell.MomentSortValue,
        headerCell: 'custom',
        width: 15,
        editable: false
      },
      {
        name: 'assignee',
        label: 'Assigned To',
        cell: 'string',
        headerCell: 'custom',
        width: 12,
        editable: false
      },
      {
        name: '',
        label: '',
        cell: ButtonLinkCell.extend({
          name: 'Open',
          buttonClass: 'btn-primary btn-sm',
          href: function(model) {
            return '#sec/task/' + model.get('taskId');
          }
        }),
        editable: false,
        sortable: false,
        headerCell: 'custom',
        width: 10
      }
    ];

    this.allTasksView = new GridSpinnerView({
      columns: taskGridColumns,
      spinner: {
        scale: 0.75,
        length: 10
      },
      pageable: true
    });

    this.showChildView('allTasksContainer', this.allTasksView);

    Radio.channel('sc:internal-dashboard')
      .request('build:sec-internal-all-tasks-grid-view', taskGridColumns)
      .done(
        function(gridView) {
          if (!this.isDestroyed) {
            this.allTasksView = gridView;
            this.showChildView('allTasksContainer', gridView);
            gridView.viewGrid.sort('deliveryDate', 'descending');
          }
        }.bind(this)
      );
  }
});
