var Handlebars = require("handlebars/dist/handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "    <div class=\"row\">\n      <div class=\"col-sm-12\">\n        <div class=\"form-group required\">\n          <label class=\"control-label col-sm-3 \">Upload Financial Documents?</label>\n          <div class=\"col-sm-9\">\n            <label class=\"radio-inline form-label\">\n              <input type=\"radio\" name=\"hasFinDocs\" class=\"has-fin-docs\" value=\"1\" data-parsley-required=\"true\"\n                data-parsley-errors-container=\"#hasFinDocsError\" /> Yes\n            </label>\n            <label class=\"radio-inline form-label\">\n              <input type=\"radio\" name=\"hasFinDocs\" class=\"has-fin-docs\" value=\"0\" data-parsley-required=\"true\"\n                data-parsley-errors-container=\"#hasFinDocsError\" /> No\n            </label>\n            <div id=\"hasFinDocsError\" class=\"has-errors\"></div>\n          </div>\n        </div>\n      </div>\n    </div>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), alias4=helpers.helperMissing;

  return "\n<div class=\"page-title clearfix\">\n  <h1 class=\"pull-left m-n p-n\">"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? depth0.task : depth0)) != null ? stack1.taskType : stack1)) != null ? stack1.description : stack1), depth0))
    + " for "
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.correspondence : depth0)) != null ? stack1.orgLegalName : stack1), depth0))
    + "</h1>\n</div>\n<div class=\"header-bar\"></div>\n<div class=\"breadcrumb-container\"></div>\n<div class=\"form-horizontal\">\n  <form class=\"correspondence-form\">\n    <div class=\"row\">\n      <div class=\"col-sm-12\">\n        <div class=\"form-group\">\n          <label class=\"control-label col-sm-3\">DoBS Contact Name</label>\n          <div class=\"col-sm-9 col-md-6\">\n            <p class=\"form-control-static \">"
    + alias2((helpers["default"] || (depth0 && depth0["default"]) || alias4).call(alias3,((stack1 = (depth0 != null ? depth0.correspondence : depth0)) != null ? stack1.requestedByName : stack1),"--",{"name":"default","hash":{},"data":data}))
    + "</p>\n          </div>\n        </div>\n      </div>\n    </div>\n    <div class=\"row\">\n      <div class=\"col-sm-12\">\n        <div class=\"form-group\">\n          <label class=\"control-label col-sm-3\">Contact Date</label>\n          <div class=\"col-sm-9 col-md-6\">\n            <p class=\"form-control-static \">"
    + alias2((helpers["default"] || (depth0 && depth0["default"]) || alias4).call(alias3,((stack1 = (depth0 != null ? depth0.correspondence : depth0)) != null ? stack1.requestedOn : stack1),"--",{"name":"default","hash":{},"data":data}))
    + "</p>\n          </div>\n        </div>\n      </div>\n    </div>\n    <div class=\"row\">\n      <div class=\"col-sm-9 col-sm-offset-3\">\n        <h3>Correspondence</h3>\n      </div>\n    </div>\n    <div class=\"row\">\n      <div class=\"form-group\">\n        <div class=\"col-sm-offset-3 col-sm-9 col-md-6\">\n          <div class=\"alert alert-danger collapse missing-response\" role=\"alert\">\n            <i class=\"fa fa-exclamation-triangle\" aria-hidden=\"true\"></i>\n            At least one correspondence attachment or comment must be provided.\n          </div>\n        </div>\n      </div>\n    </div>\n    <div class=\"row\">\n      <div class=\"col-sm-12\">\n        <div class=\"form-group\">\n          <label class=\"control-label col-sm-3\">Message From DoBS</label>\n          <div class=\"col-sm-9 col-md-6\">\n            <p class=\"form-control-static\">"
    + alias2((helpers["default"] || (depth0 && depth0["default"]) || alias4).call(alias3,((stack1 = (depth0 != null ? depth0.correspondence : depth0)) != null ? stack1.requestComments : stack1),"--",{"name":"default","hash":{},"data":data}))
    + "</p>\n          </div>\n        </div>\n      </div>\n      <label class=\"control-label col-sm-3\"></label>\n      <div class=\"col-sm-9 col-md-6\">\n           Please be advised that all representations in your response are subject to the \n              penalties of 18 Pa. C.S. §4904 (relating to unsworn falsification to authorities).  \n              Furthermore, your response will be considered a document filed with the \n              Department of Banking and Securities for purposes of Section 407 of the 1972 Act, \n              70 P.S. §1-407.\n      </div>\n   </div>\n    <div class=\"row\">\n      <div class=\"col-sm-12\">\n        <div class=\"form-group\">\n          <label class=\"control-label col-sm-3\">Reply Comments</label>\n          <div class=\"col-sm-9 col-md-6\">\n            <textarea type=\"text\" class=\"form-control \" name=\"replyComments\"\n              placeholder=\"Do not include pertinent information in this section\" rows=\"5\"></textarea>\n          </div>\n        </div>\n      </div>\n    </div>\n    <div class=\"row\">\n      <div class=\"col-sm-9 col-sm-offset-3\">\n        <h3>Documents</h3>\n      </div>\n    </div>\n    <div class=\"row\">\n      <div class=\"col-sm-12\">\n        <div class=\"form-group\">\n          <label class=\"control-label col-sm-3 \">Document List</label>\n          <div class=\" col-sm-9 col-md-6\">\n            <div class=\"int-file-upload-table-container\"></div>\n          </div>\n        </div>\n      </div>\n    </div>\n    <div class=\"row\">\n      <div class=\"col-sm-12\">\n        <div class=\"form-group\">\n          <label class=\"control-label col-sm-3 \">Attach file(s) for Correspondence</label>\n          <div class=\" col-sm-9 col-md-6\">\n            <div class=\"ext-file-upload-table-container\"></div>\n          </div>\n        </div>\n      </div>\n    </div>\n"
    + ((stack1 = (helpers.ifCond || (depth0 && depth0.ifCond) || alias4).call(alias3,((stack1 = (depth0 != null ? depth0.correspondence : depth0)) != null ? stack1.correspondenceType : stack1),"!=","2",{"name":"ifCond","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "    <div class=\"financial-documents-section\">\n      <div class=\"row\">\n        <div class=\"col-sm-12\">\n          <div class=\"form-group required\">\n            <label class=\"control-label col-sm-3 \">Attach Financial Document(s)</label>\n            <div class=\" col-sm-9 col-md-6\">\n              <div class=\"ext-financial-upload-table-container\"></div>\n            </div>\n          </div>\n        </div>\n      </div>\n      <div class=\"row\">\n        <div class=\"col-sm-12\">\n          <div class=\"form-group required\">\n            <label class=\"control-label col-sm-3\">Fiscal Year End Date</label>\n            <div class=\"col-sm-9 col-md-3\">\n              <div class=\"input-group\">\n                <input type=\"text\" placeholder=\"Fiscal Year End Date\" name=\"fiscalYearEndDate\"\n                  class=\"form-control date-picker\" data-parsley-required=\"true\" data-parsley-date=\"\"\n                  data-parsley-trigger-after-failure=\"changeDate\"\n                  data-parsley-errors-container=\"#fiscalDateErrorsContainer\" />\n                <div class=\"input-group-addon\">\n                  <i class=\"fa fa-calendar\"></i>\n                </div>\n              </div>\n              <div id=\"fiscalDateErrorsContainer\"></div>\n            </div>\n          </div>\n        </div>\n      </div>\n    </div>\n  </form>\n  <div class=\"form-group\">\n    <div class=\"col-sm-9 col-lg-7 col-sm-offset-3 m-b-lg\">\n      <a href=\"#ext/sec/dashboard\" class=\"btn btn-default\" role=\"button\">Cancel</a>\n      <button type=\"button\" class=\"btn btn-primary submit\">Submit</button>\n    </div>\n  </div>\n</div>";
},"useData":true});