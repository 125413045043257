/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var moment = require('moment');
var Backbone = require('backbone');
var Marionette = require('backbone.marionette');
var Syphon = require('backbone.syphon');
var Radio = require('backbone.radio');
var Ladda = require('ladda');

var Session = require('psa-core/Session');

var modelGet = require('common/util/modelGet');
var HeaderBarView = require('common/views/HeaderBarView');
var BreadcrumbView = require('common/views/BreadcrumbView');
var FileUploadTableView = require('common/views/FileUploadTableView');
var ContentServerLinkView = require('common/views/ContentServerLinkView');

var SubmitReviewApplicationDirectorFinancial = require('../../services/SubmitReviewApplicationDirectorFinancial');

var tmplSecReviewApplicationDirectorFinancialTaskView = require('./SecReviewApplicationDirectorFinancialTaskView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplSecReviewApplicationDirectorFinancialTaskView,

  className: 'sec-review-application-director-financial-task-view container-fluid',

  ui: {
    headerBar: '.header-bar',
    breadcrumb: '.breadcrumb-container',
    financialStatementForm: '.financial-statement-form',
    csLinkContainer: '.cs-link-container',
    extFileUploadTableContainer: '.ext-file-upload-table-container',
    intFileUploadTableContainer: '.int-file-upload-table-container',
    datePickerFields: '.date-picker',
    fiscalYearEndDate: '.fiscal-year-end-row',
    submit: '.submit'
  },

  regions: {
    headerBar: '@ui.headerBar',
    breadcrumb: '@ui.breadcrumb',
    extFileUploadTableContainer: '@ui.extFileUploadTableContainer',
    intFileUploadTableContainer: '@ui.intFileUploadTableContainer',
    csLinkContainer: '@ui.csLinkContainer'
  },

  events: {
    'click @ui.submit': 'onClickSubmit'
  },

  behaviors: {
    TaskBehavior: {}
  },

  onBeforeRender: function() {
    var reviewDecisionTypes = _.filter(this.model.get('reviewDecisionTypes'), function(decisionType) {
      var isApproved = decisionType.id === '23';
      var isRejected = decisionType.id === '24';
      return isApproved || isRejected;
    });

    this.model.set({
      reviewDecisionTypes: reviewDecisionTypes
    });
  },

  onBeforeShow: function() {
    var financialStatement = this.model.get('financialStatement') || {};
    var documents = modelGet(this.model, 'financialStatement.documents', []);

    this.showChildView(
      'csLinkContainer',
      new ContentServerLinkView({
        model: new Backbone.Model({
          buttonText: 'Open Financial Statement Folder',
          contentServerId: financialStatement.csFolderId
        })
      })
    );

    this.ui.datePickerFields.datepicker();
    var headerBarColumns = [
      {
        name: 'applicantLegalName',
        label: 'Applicant Legal Name',
        minWidth: '160px',
        maxWidth: '300px'
      },
      {
        name: 'CRDNumber',
        label: 'CRD Number',
        minWidth: '160px',
        maxWidth: '300px'
      },
      {
        name: 'nonFINRANumber',
        label: 'Non FINRA Number',
        minWidth: '160px',
        maxWidth: '300px'
      }
    ];

    this.showChildView(
      'headerBar',
      new HeaderBarView({
        columns: new Backbone.Collection(headerBarColumns),
        model: new Backbone.Model(this.model.get('organization'))
      })
    );

    this.showChildView(
      'breadcrumb',
      new BreadcrumbView({
        breadcrumbs: [
          {
            link: '#sec/dashboard',
            text: 'Home'
          },
          {
            text: 'Submit Financials'
          }
        ]
      })
    );

    this.intFileUploadTableView = new FileUploadTableView({
      documentTypes: this.model.get('documentTypes'),
      collection: new Backbone.Collection([]),
      emptyText: 'No Documents',
      isReadOnly: false
    });

    this.showChildView('intFileUploadTableContainer', this.intFileUploadTableView);

    this.extFileUploadTableView = new FileUploadTableView({
      collection: new Backbone.Collection(documents),
      emptyText: 'No Documents',
      isReadOnly: true
    });

    this.showChildView('extFileUploadTableContainer', this.extFileUploadTableView);
  },

  onClickSubmit: function(e) {
    e.preventDefault();
    var self = this;
    this.validate().done(function() {
      var l = Ladda.create(self.ui.submit[0]);
      l.start();
      SubmitReviewApplicationDirectorFinancial(self.getFormData())
        .done(function() {
          Radio.channel('navigate').trigger('show:int:sc:dashboard');
        })
        .fail(function() {
          l.stop();
        });
    });
  },

  validate: function() {
    return $.when.apply($, [
      this.ui.financialStatementForm.parsley().whenValidate(),
      this.intFileUploadTableView.validate({ type: 'submit' }, 0)
    ]);
  },

  getFormData: function() {
    var formData = Syphon.serialize(this);

    _.extend(formData, {
      taskId: this.model.get('taskId'),
      financialStatementId: this.model.get('financialStatementId')
    });
    formData.documents = this.intFileUploadTableView.getFormData();

    var timestamp = moment().format('MM/DD/YYYY h:mm A');
    var userName = Session.user.description;
    formData.internalComments = '[' + userName + ' - ' + timestamp + '] \n' + formData.internalComments;

    return formData;
  }
});
