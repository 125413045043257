/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Backbone = require('backbone');

var GetDocumentTypesByDepartment = require('../../services/GetDocumentTypesByDepartment');
var FileUploadTableView = require('./FileUploadTableView');

module.exports = {
  channel: 'cm',

  event: 'build:file-upload-table',

  builder: function(data) {
    var deferred = $.Deferred();
    GetDocumentTypesByDepartment(data.departmentName)
      .done(function(documentTypes) {
        deferred.resolve(
          new FileUploadTableView({
            documentTypes: documentTypes,
            isReadOnly: data.isReadOnly,
            emptyText: data.emptyText,
            collection: new Backbone.Collection()
          })
        );
      })
      .fail(function(err) {
        deferred.reject(err);
      });
    return deferred.promise();
  }
};
