/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var cleanUpResponse = require('common/util/cleanUpResponse');
var momentUtil = require('common/util/momentUtil');

module.exports = function(complianceId) {
  var defer = $.Deferred();

  $.soap({
    body: {
      GetCompliance: {
        $xmlns: 'http://dobs.pa.gov/sc/compliance/bpm/1.0',
        ComplianceID: complianceId
      }
    },
    blueprint: {
      complianceId: '$..ID',
      csFolderId: '$..CSFolderID',
      objectTypeId: '$..ObjectTypeID',
      objectId: '$..ObjectID',
      isRegistered: '$..IsRegistered',
      actionTypeId: '$..ActionTypeID',
      actionType: '$..ActionType',
      otherActionTypeId: '$..OtherActionTypeID',
      otherActionType: '$..OtherActionType',
      ogcDocketNumber: '$..OGCDocketNumber',
      issuedDate: '$..IssuedDate',
      orderStatusId: '$..OrderStatusID',
      orderStatus: '$..OrderStatus',
      criminalAuthActionId: '$..CriminalAuthActionID',
      criminalAuthAction: '$..CriminalAuthAction',
      civilAuthActionId: '$..CivilAuthActionID',
      civilAuthAction: '$..CivilAuthAction',
      isDeleted: '$..IsDeleted',
      createdDate: '$..CreatedDate',
      createdBy: '$..CreatedBy',
      modifiedDate: '$..ModifiedDate',
      modifiedBy: '$..ModifiedBy',
      identifiers: {
        applicantLegalName: '$..Name',
        crdNumber: '$..CRDNumber',
        licenseType: '$..LicenseType',
        examCaseNumber: '$..ExamCaseNumber',
        orgIndObjectId: '$..OrgIndObjectID',
        licenseId: '$..LicenseID',
        isExamOrg: '$..IsExamOrg',
        isInvInqOrg: '$..IsInvInqOrg',
        isInvInqInd: '$..IsInvInqInd',
        category: '$..Category'
      }
    }
  })
    .done(function(response) {
      cleanUpResponse(response);

      var formInboundDateConfig = {
        sourceFormat: 'YYYY-MM-DD',
        targetFormat: 'MM/DD/YYYY',
        default: undefined
      };

      response.issuedDate = momentUtil.formatStr(response.issuedDate, formInboundDateConfig);
      response.isRegisteredDisplay = response.isRegistered === '1' ? 'Yes' : 'No';

      response.identifiers.licenseType = response.identifiers.licenseType || '--';
      response.identifiers.objectId = response.objectId;
      response.identifiers.actionType = response.actionType;
      response.identifiers.complianceObjectTypeDisplay =
        response.objectTypeId === 'EXAM' ? 'Exam' : response.identifiers.category;

      defer.resolve(response);
    })
    .fail(function(err) {
      defer.reject(err);
    });

  return defer.promise();
};
