/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Marionette = require('backbone.marionette');
var Syphon = require('backbone.syphon');
var Ladda = require('ladda');

var momentUtil = require('common/util/momentUtil');

var SubmitExamNextSteps = require('../../common/services/SubmitExamNextSteps');
var SubmitInvestigationInquiryNextSteps = require('../../common/services/SubmitInvestigationInquiryNextSteps');

var tmplSecNextStepEditModalView = require('../templates/SecNextStepEditModalView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplSecNextStepEditModalView,

  className: 'next-step-edit-modal-view',

  ui: {
    modalBody: '.modal-body',
    datepickers: '.date-picker',
    editForm: 'form',
    submit: '.submit'
  },

  events: {
    'click @ui.submit': 'onClickSubmit'
  },

  templateHelpers: function() {
    return {
      scUsers: this.options.scUsers
    };
  },

  onBeforeShow: function() {
    Syphon.deserialize(this, this.model.toJSON());
    this.ui.datepickers.datepicker();
  },

  onClickSubmit: function(e) {
    e.preventDefault();
    var self = this;
    this.ui.editForm
      .parsley()
      .whenValidate()
      .done(function() {
        var l = Ladda.create(self.ui.submit[0]);
        l.start();

        var submitService;
        if (self.model.get('nextStepType') === 'EXAM') {
          submitService = SubmitExamNextSteps;
        } else {
          submitService = SubmitInvestigationInquiryNextSteps;
        }

        var formData = self.getFormData();
        submitService(formData)
          .done(function() {
            self.triggerMethod('modal:close', Syphon.serialize(self.ui.editForm));
          })
          .fail(function() {
            l.stop();
          });
      });
  },

  getFormData: function() {
    var data = Syphon.serialize(this.ui.editForm);

    if (this.model.get('nextStepType') === 'EXAM') {
      data.examId = this.model.get('id');
    } else {
      data.invInqId = this.model.get('id');
    }

    data.nextStepProjectedDate = momentUtil.formatStr(data.nextStepProjectedDate, momentUtil.defaultOutboundDateConfig);

    data.hasNextStep = 1;

    return data;
  }
});
