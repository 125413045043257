/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2020] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Backbone = require('backbone');
var Marionette = require('backbone.marionette');
var Syphon = require('backbone.syphon');
var Radio = require('backbone.radio');
var bodybuilder = require('bodybuilder');
var Ladda = require('ladda');
var moment = require('moment');

var GridView = require('psa-marionette/views/GridView');
var PageableCollection = require('psa-backbone/models/PageableCollection');

var SelectOneCell = require('common/backgrid/SelectOneCell');
var MomentCell = require('common/backgrid/MomentCell');
var ElasticSearchSummaryView = require('common/views/ElasticSearchSummaryView');
var BreadcrumbView = require('common/views/BreadcrumbView');

var SearchConSerComplaints = require('../../internal-dashboard/services/SearchConSerComplaints');

var SubmitComplaintRelated = require('../services/SubmitComplaintRelated');

var tmplConSerRelatedComplaintNewView = require('../templates/ConSerRelatedComplaintNewView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplConSerRelatedComplaintNewView,

  className: 'con-ser-related-complaint-new-view container-fluid',

  ui: {
    breadcrumb: '.breadcrumb-container',
    searchResultsContainer: '.search-results-container',
    search: '.search',
    clearFilters: '.clear-filters',
    formInputs: ':input',
    searchSummary: '.search-summary',
    submit: '.submit'
  },

  regions: {
    breadcrumb: '@ui.breadcrumb',
    searchResultsContainer: '@ui.searchResultsContainer',
    searchSummary: '@ui.searchSummary'
  },

  events: {
    'change @ui.departmentSelect': 'onChangeDepartment',
    'click @ui.search': 'onClickSearch',
    'click @ui.clearFilters': 'onClickClearFilters',
    'keydown @ui.formInputs': 'onKeydownOfInputs',
    'click @ui.submit': 'onClickSubmit'
  },

  onBeforeShow: function() {
    var complaint = this.model.get('complaint') || {};
    var complaintId = this.model.get('complaintId');

    this.showChildView(
      'breadcrumb',
      new BreadcrumbView({
        breadcrumbs: [
          {
            link: '#conser/dashboard',
            text: 'Home'
          },
          {
            link: '#conser/complaint/' + complaintId + '/dashboard',
            text: complaint.caseNumber ? 'Complaint - ' + complaint.caseNumber : 'New Complaint'
          },
          {
            text: 'New Related Complaint'
          }
        ]
      })
    );

    this.searchSummaryView = new ElasticSearchSummaryView({
      model: new Backbone.Model({
        searchLimit: this.model.get('searchLimit')
      })
    });
    this.showChildView('searchSummary', this.searchSummaryView);

    this.searchResults = new GridView({
      columns: [
        {
          name: 'selected',
          label: '',
          cell: SelectOneCell.SelectOneRowCell,
          headerCell: SelectOneCell.SelectOneHeaderCell,
          width: 3,
          editable: true,
          sortable: false
        },
        {
          name: 'caseNumber',
          label: 'Case Number',
          cell: 'string',
          headerCell: 'custom',
          width: 11,
          editable: false
        },
        {
          name: 'entityName',
          label: 'Entity Name',
          cell: 'string',
          headerCell: 'custom',
          width: 11,
          editable: false
        },
        {
          name: 'individualName',
          label: 'Individual Name',
          cell: 'string',
          headerCell: 'custom',
          width: 11,
          editable: false
        },
        {
          name: 'department',
          label: 'Entity Type 1',
          cell: 'string',
          headerCell: 'custom',
          width: 11,
          editable: false
        },
        {
          name: 'entityType2',
          label: 'Entity Type 2',
          cell: 'string',
          headerCell: 'custom',
          width: 11,
          editable: false
        },
        {
          name: 'weight',
          label: 'Level',
          cell: 'string',
          headerCell: 'custom',
          width: 11,
          editable: false
        },
        {
          name: 'status',
          label: 'Status',
          cell: 'string',
          headerCell: 'custom',
          width: 11,
          editable: false
        },
        {
          name: 'initialOpenedDate',
          label: 'Date Opened',
          cell: MomentCell.MomentCell,
          formatter: MomentCell.MomentDateFormatter.extend({
            emptyValue: '--'
          }),
          sortValue: MomentCell.MomentSortValue,
          headerCell: 'custom',
          width: 11,
          editable: false
        }
      ],
      collection: new PageableCollection([], { pageSize: 50 }),
      emptyText: 'No Complaints Found'
    });

    this.showChildView('searchResultsContainer', this.searchResults);

    this.$('.backgrid.table').addClass('table-bordered');
  },

  onKeydownOfInputs: function(e) {
    // Searches on press of enter
    if (e.keyCode === 13) {
      this.onClickSearch(e);
    }
  },

  onClickSearch: function() {
    var self = this;
    var data = Syphon.serialize(this);
    // If no inputs were given, do not perform a search.
    if (
      !data.caseNumber &&
      !data.entityName &&
      !data.individualName &&
      !data.weightId &&
      !data.complainantPhoneNumber
    ) {
      return;
    }
    this.searchSummaryView.reset();

    var l = Ladda.create(this.ui.search[0]);
    l.start();
    var body = bodybuilder();

    if (data.caseNumber) {
      body.query('wildcard', 'caseNumber', data.caseNumber + '*');
    }

    if (data.entityName) {
      body.query('bool', function(b) {
        b.orQuery('match', 'entityName', {
          query: data.entityName,
          fuzziness: 'AUTO',
          analyzer: 'standard'
        });
        b.orQuery('wildcard', 'entityName', data.entityName.toLowerCase() + '*');
        b.orQuery('match', 'entityName.phonetic', data.entityName);
        return b;
      });
    }

    if (data.individualName) {
      body.query('bool', function(b) {
        b.orQuery('match', 'individualName', {
          query: data.individualName,
          fuzziness: 'AUTO'
        });
        b.orQuery('wildcard', 'individualName', data.individualName.toLowerCase() + '*');
        return b;
      });
    }

    if (data.departmentId) {
      body.query('match', 'departmentId', data.departmentId);
    }

    if (data.entityType2Id) {
      body.query('match', 'entityType2Id', data.entityType2Id);
    }

    if (data.weightId) {
      body.query('match', 'weightId', data.weightId);
    }

    if (data.statusId) {
      body.query('match', 'statusId', data.statusId);
    }

    if (data.initialOpenedDate) {
      var initialOpenedDateFormatted = moment(data.initialOpenedDate, 'MM/DD/YYYY').format('YYYY-MM-DD');
      body.query('range', 'initialOpenedDate', {
        gte: initialOpenedDateFormatted,
        lte: initialOpenedDateFormatted
      });
    }

    if (data.complainantPhoneNumber) {
      var cleanComplainantPhoneNumber = data.complainantPhoneNumber.replace(/[^0-9]/g, '');
      body.query('wildcard', 'complainantPhoneNumber', cleanComplainantPhoneNumber + '*');
    }

    body.size(this.model.get('searchLimit'));
    var bodyObj = body.build();
    // Keep for testing
    // console.log(JSON.stringify(bodyObj, null, 2));
    SearchConSerComplaints(bodyObj)
      .done(function(data) {
        l.stop();
        self.searchResults.collection.fullCollection.reset(data.hits);
        self.searchSummaryView.display(data);
      })
      .fail(function(err) {
        l.stop();
      });
  },

  onClickClearFilters: function() {
    this.ui.formInputs.val('');
    this.entityType2Dropdown.collection.reset();
    this.entityType2Dropdown.setDisabled(true);
  },

  onClickSubmit: function(e) {
    e.preventDefault();
    var self = this;
    var l = Ladda.create(this.ui.submit[0]);
    l.start();
    var selectedModel = this.searchResults.viewGrid.getSelectedModel();
    if (selectedModel) {
      e.preventDefault();
      selectedModel = selectedModel.toJSON();
      SubmitComplaintRelated({
        complaintId: self.model.get('complaintId'),
        relatedComplaintId: selectedModel.complaintId
      })
        .done(function() {
          Radio.channel('navigate').trigger('show:int:cs:complaint', self.model.get('complaintId'));
        })
        .fail(function() {
          l.stop();
        });
    } else {
      Radio.channel('navigate').trigger('show:int:cs:complaint', self.model.get('complaintId'));
    }
  }
});
