/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var Backbone = require('backbone');
var Marionette = require('backbone.marionette');
var Radio = require('backbone.radio');
var Ladda = require('ladda');
var Syphon = require('backbone.syphon');

var FileUploadCollection = require('psa-backbone/models/FileUploadCollection');

var FileUploadView = require('common/views/FileUploadView');
var downloadDocument = require('common/util/downloadDocument');

var NonDepEntityFormSectionBooksAndRecordsView = require('../../../entity-form/views/NonDepEntityFormSectionBooksAndRecordsView');

var SubmitSiteRecordKeepingApplication = require('../../services/SubmitEntitySiteRecordKeepingApplication');

var tmplEntityRecordsLocationChangeApplicationView = require('./EntityRecordsLocationChangeApplicationView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplEntityRecordsLocationChangeApplicationView,

  className: 'entity-records-location-change-form-view row',

  ui: {
    recordsLocationSectionContainer: '.entity-section-records-location-container',
    affirmationForm: '.affirmation-form',
    downloadInstructions: '.download-instructions',
    applicationDocumentsFileContainer: '.application-documents-container',
    submitButton: '.submit-button',
    isCertified: 'input[name="isCertified"]',
    certificationName: 'input[name="certificationName"]'
  },

  regions: {
    recordsLocationSectionContainer: '@ui.recordsLocationSectionContainer',
    applicationDocumentsFileContainer: '@ui.applicationDocumentsFileContainer'
  },

  events: {
    'click @ui.downloadInstructions': 'onClickDownloadInstructions',
    'click @ui.submitButton': 'onClickSubmit'
  },

  behaviors: {
    ReadOnlyFormBehavior: {}
  },

  templateHelpers: function() {
    var instructionsDoc = this.model.get('instructionsDocument') || {};
    var instructionsDocExists = instructionsDoc.instructionsDocId || instructionsDoc.instructionsDocumentName;
    var hideInstructionDoc = !instructionsDocExists || this.options.isReadOnly;
    var showAffirmation =
      !this.options.isReadOnly ||
      (this.options.mode === 'print' || this.options.mode === 'int' || this.options.mode === 'int-expand');
    return {
      showAffirmation: showAffirmation,
      isReadOnly: this.options.isReadOnly,
      hideInstructionDoc: hideInstructionDoc
    };
  },

  onBeforeShow: function() {
    var booksAndRecords = this.model.get('booksAndRecords') || [];
    var deletedBooksAndRecords = [];
    var booksAndRecordsToShow = [];

    var self = this;
    if (this.options.isReadOnly && this.options.viewMode !== 'ext') {
      // Flag updated and new Books and Records to display changes for internal reviewer
      booksAndRecordsToShow = _.map(booksAndRecords, function(booksAndRecord) {
        if (booksAndRecord.id && booksAndRecord.isDeleted !== '1') {
          booksAndRecord.isUpdate = true;
          var oldBooksAndRecord = _.findWhere(self.model.get('oldBooksAndRecords'), {
            id: booksAndRecord.id
          });
          booksAndRecord.oldBooksAndRecord = oldBooksAndRecord;
        } else if (!booksAndRecord.id) {
          booksAndRecord.isNew = true;
        }
        return booksAndRecord;
      });
    } else {
      // Show read only form without changes on the external 'View Application' page
      booksAndRecordsToShow = _.filter(booksAndRecords, function(booksAndRecord) {
        return booksAndRecord.isDeleted !== '1';
      });
      // For resubmissions, store previously deleted books and records to send back to internal reviewer with the rest of the form updates
      deletedBooksAndRecords = _.where(booksAndRecords, { isDeleted: '1' });
    }

    this.entityFormSectionBooksAndRecordsView = new NonDepEntityFormSectionBooksAndRecordsView({
      isReadOnly: this.options.isReadOnly,
      isAmendment: true,
      viewMode: this.options.viewMode,
      mode: this.options.mode,
      model: new Backbone.Model({
        alternateSitekeepings: booksAndRecordsToShow,
        deletedBooksAndRecords: deletedBooksAndRecords
      })
    });
    this.showChildView('recordsLocationSectionContainer', this.entityFormSectionBooksAndRecordsView);

    this.applicationDocumentsFile = new FileUploadView({
      isRequired: true,
      allowMultipleFiles: true,
      collection: new FileUploadCollection(this.model.get('applicationDocuments')),
      isReadOnly: this.options.isReadOnly,
      metadata: {
        documentTypeId: 10023
      }
    });
    this.showChildView('applicationDocumentsFileContainer', this.applicationDocumentsFile);

    var showAffirmationData =
      this.options.mode === 'print' || this.options.mode === 'int' || this.options.mode === 'int-expand';
    if (showAffirmationData) {
      this.ui.isCertified.prop('checked', this.model.get('isCertified'));
      this.ui.certificationName.val(this.model.get('certificationName'));
    }
  },

  onClickDownloadInstructions: function(e) {
    var instructionsDocument = this.model.get('instructionsDocument') || {};
    e.preventDefault();
    downloadDocument({
      fileId: instructionsDocument.instructionsDocId,
      fileName: instructionsDocument.instructionsDocumentName,
      isStatic: true
    });
  },

  onClickSubmit: function(event) {
    event.preventDefault();
    var self = this;

    this.validate().done(function() {
      var formData = Syphon.serialize(self);

      var appData = {
        entityId: self.model.get('entityId'),
        applicationId: self.model.get('applicationId'),
        booksAndRecords: self.getFormData(),
        applicationDocuments: self.applicationDocumentsFile.collection.pluck('fileId'),
        isCertified: formData.isCertified,
        certificationName: formData.certificationName
      };
      var laddaContext = Ladda.create(self.ui.submitButton.get(0));
      laddaContext.start();
      SubmitSiteRecordKeepingApplication(appData)
        .done(function(response) {
          Radio.channel('nd:submit-application').trigger('submit');
          Radio.channel('nd:submit-application').trigger('resubmit', laddaContext);
        })
        .fail(function(err) {
          laddaContext.stop();
        });
    });
  },

  validate: function() {
    var validationPromises = [
      this.entityFormSectionBooksAndRecordsView.validate({ type: 'submit', isStandAlone: true })
    ];
    if (!this.options.isReadOnly) {
      validationPromises.push(this.ui.affirmationForm.parsley().whenValidate());
    }
    return $.when.apply($, validationPromises);
  },

  getFormData: function() {
    return this.entityFormSectionBooksAndRecordsView.getFormData();
  }
});
