/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Marionette = require('backbone.marionette');
var PageableCollection = require('psa-backbone/models/PageableCollection');
var Syphon = require('backbone.syphon');
var Ladda = require('ladda');
var bodybuilder = require('bodybuilder');
var _ = require('underscore');

var toHash = require('common/util/toHash');

var ElasticSearchSummaryView = require('common/views/ElasticSearchSummaryView');
var GridView = require('psa-marionette/views/GridView');

var SelectOneCell = require('common/backgrid/SelectOneCell');
var ListCell = require('common/backgrid/ListCell');
var AddressCell = require('common/backgrid/AddressCell');

var SearchDepEntities = require('../services/SearchDepEntities');

require('../styles/DepEntitySearchModalView.css');
var tmplDepEntitySearchModalView = require('../templates/DepEntitySearchModalView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplDepEntitySearchModalView,

  className: 'dep-entity-search-modal-view',

  ui: {
    searchResultsGrid: '.search-result-grid-container',
    search: '.btn-search',
    searchSummary: '.search-summary',
    formInputs: ':input',
    submit: '.btn-submit'
  },

  regions: {
    searchSummary: '@ui.searchSummary',
    searchResultsGrid: '@ui.searchResultsGrid'
  },

  events: {
    'click @ui.search': 'onClickSearchEntities',
    'click @ui.submit': 'onClickSubmit',
    'keydown @ui.formInputs': 'onKeydownOfInputs'
  },

  initialize: function() {
    this.lookupHashes = {
      entityTypes: toHash(this.model.get('entityTypes'), 'id', 'type')
    };
  },

  onBeforeShow: function() {
    this.searchSummaryView = new ElasticSearchSummaryView({
      model: new Backbone.Model({
        searchLimit: this.model.get('searchLimit')
      })
    });
    this.showChildView('searchSummary', this.searchSummaryView);

    this.searchResultsGrid = new GridView({
      columns: [
        {
          name: 'selected',
          label: '',
          cell: SelectOneCell.SelectOneRowCell,
          headerCell: SelectOneCell.SelectOneHeaderCell,
          width: 5,
          editable: true,
          sortable: false
        },
        {
          name: 'entityName',
          label: 'Entity Name',
          cell: 'string',
          headerCell: 'custom',
          width: 25,
          editable: false
        },
        {
          name: 'dbas',
          label: 'DBA(s)',
          cell: ListCell,
          headerCell: 'custom',
          width: 25,
          editable: false,
          sortable: false
        },
        {
          name: 'ppobAddress',
          label: 'Address',
          cell: AddressCell,
          headerCell: 'custom',
          width: 25,
          editable: false,
          sortable: false
        },
        {
          name: 'entityType',
          label: 'Type',
          cell: 'string',
          headerCell: 'custom',
          width: 20,
          editable: false
        }
      ],
      collection: new PageableCollection([], { pageSize: 3 }),
      emptyText: 'Search returned no entities.'
    });

    this.showChildView('searchResultsGrid', this.searchResultsGrid);
  },

  onKeydownOfInputs: function(e) {
    // Searches on press of enter
    if (e.keyCode === 13) {
      this.onClickSearchEntities(e);
    }
  },

  onClickSearchEntities: function(e) {
    e.preventDefault();
    var self = this;

    var data = Syphon.serialize(this, {
      inputReaders: Syphon.SearchInputReaderSet
    });

    // Only perform search of entityName has a value
    if (!data.entityName && !data.dba) {
      return;
    }

    var l = Ladda.create(this.ui.search[0]);
    l.start();

    this.searchSummaryView.reset();

    var body = bodybuilder();

    if (data.entityName) {
      body.query('bool', function(b) {
        b.orQuery('match', 'entityName', {
          query: data.entityName,
          fuzziness: 'AUTO',
          analyzer: 'standard'
        });
        b.orQuery('wildcard', 'entityName', data.entityName.toLowerCase() + '*');
        b.orQuery('match', 'entityName.phonetic', data.entityName);
        return b;
      });
    }

    if (data.dba) {
      body.query('nested', 'path', 'dbas', function(nb) {
        nb.query('bool', 'minimum_should_match', 1, function(bb) {
          bb.orQuery('match', 'dbas.dba', {
            query: data.dba,
            fuzziness: 'AUTO',
            analyzer: 'standard'
          });
          bb.orQuery('wildcard', 'dbas.dba', data.dba.toLowerCase() + '*');
          bb.orQuery('match', 'dbas.dba.phonetic', data.dba);
          return bb;
        });
        return nb;
      });
    }

    body.size(this.model.get('searchLimit'));
    var bodyObj = body.build();

    SearchDepEntities(bodyObj, data, this.lookupHashes)
      .done(function(data) {
        l.stop();

        _.each(data.hits, function(hit) {
          hit.dbas = _.map(hit.dbas, function(dba) {
            return {
              value: dba
            };
          });
        });

        self.searchResultsGrid.collection.fullCollection.reset(data.hits);
        self.searchSummaryView.display(data);
      })
      .fail(function() {
        l.stop();
      });
  },

  onClickSubmit: function(e) {
    e.preventDefault();
    var selectedModel = this.searchResultsGrid.viewGrid.getSelectedModel();

    if (selectedModel) {
      this.triggerMethod('modal:close', selectedModel.toJSON());
    } else {
      this.triggerMethod('modal:close');
    }
  }
});
