/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var Backbone = require('backbone');
var Marionette = require('backbone.marionette');
var Syphon = require('backbone.syphon');
var Radio = require('backbone.radio');
var FileUploadCollection = require('psa-backbone/models/FileUploadCollection');
var FileUploadView = require('common/views/FileUploadView');
var MomentCell = require('common/backgrid/MomentCell');
var Ladda = require('ladda');

var GridView = require('psa-marionette/views/GridView');
var NonDepSelectRowCell = require('../../../common/backgrid/NonDepSelectRowCell');
var FileUploadCell = require('common/backgrid/FileUploadCell');

var SubmitLicenseDBAEditApplication = require('../../services/SubmitLicenseDBAEditApplication');
var tmplLicenseDBAEditView = require('./LicenseDBAEditApplicationView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplLicenseDBAEditView,

  className: 'license-dbas-section-view',

  behaviors: {
    ReadOnlyFormBehavior: {}
  },
  ui: {
    dbaForm: '.dba-form',
    dbas: '.dbas',
    effectiveChangeDate: '.effective-change-date',
    effectiveChangeDateForm: '.effective-change-date-form',
    isCertified: 'input[name="isCertified"]',
    certificationName: 'input[name="certificationName"]',
    submitButton: '.submit-button'
  },

  regions: {
    dbaForm: '@ui.dbaForm',
    dbas: '@ui.dbas'
  },

  events: {
    'click @ui.submitButton': 'onClickSubmit'
  },

  onBeforeShow: function() {
    var showAffirmationData =
      this.options.mode === 'print' || this.options.mode === 'int' || this.options.mode === 'int-expand';

    this.ui.effectiveChangeDate.datepicker({
      startDate: '0d'
    });

    if (showAffirmationData) {
      this.ui.isCertified.prop('checked', this.model.get('isCertified'));
      this.ui.certificationName.val(this.model.get('certificationName'));
      this.ui.effectiveChangeDate.val(this.model.get('effectiveChangeDate'));
    }

    this.applicationDocumentsFile = new FileUploadView({
      isRequired: true,
      allowMultipleFiles: true,
      collection: new FileUploadCollection(this.model.get('applicationDocuments')),
      isReadOnly: this.options.isReadOnly,
      metadata: {
        documentTypeId: 10023
      }
    });

    var selectedModel = this.model.get('licenseDBAs');
    var entityDBANotChecked = new Backbone.Model(this.model.get('entitydbas'));

    var selectedAttribute;
    if (this.options.isReadOnly || this.options.mode === 'resubmit') {
      selectedAttribute = 'id';
    } else {
      selectedAttribute = 'entityDBAId';
    }
    var checkedEntityDbas = _.pluck(selectedModel, selectedAttribute);
    var entityDBAChecked = _.map(entityDBANotChecked.attributes, function(dba) {
      dba.isChecked = _.includes(checkedEntityDbas, dba.id);
      return dba;
    });

    var entityDBAs = new Backbone.Collection(entityDBAChecked);

    this.dbaGridView = new GridView({
      columns: [
        {
          name: 'isChecked',
          label: '',
          cell: NonDepSelectRowCell.SelectRowCell,
          headerCell: NonDepSelectRowCell.SelectAllHeaderCell,
          width: 10,
          editable: true,
          sortable: false
        },
        {
          name: 'dbaName',
          label: 'DBA Name',
          cell: 'string',
          headerCell: 'custom',
          width: 30,
          editable: false
        },
        {
          name: 'fictitiousNameDocument',
          label: 'Fictitious Name Registration',
          cell: FileUploadCell.extend({
            allowMultipleFiles: false,
            isReadOnly: true
          }),
          width: 50,
          headerCell: 'custom',
          editable: false,
          sortable: false
        },
        {
          name: 'registrationDate',
          label: 'Registration Date',
          cell: MomentCell.MomentCell,
          formatter: MomentCell.MomentDateFormatter.extend({
            emptyValue: '--'
          }),
          sortValue: MomentCell.MomentSortValue,
          headerCell: 'custom',
          width: 20,
          editable: false,
          sortable: false
        }
      ],
      collection: entityDBAs,
      emptyText: 'Entity has no D/B/A registrations.'
    });
    this.showChildView('dbas', this.dbaGridView);

    this.ui.effectiveChangeDate.datepicker({
      startDate: '0d'
    });
  },

  onClickSubmit: function(e) {
    e.preventDefault();
    var self = this;

    this.validate().done(function() {
      var laddaContext = Ladda.create(self.ui.submitButton.get(0));
      laddaContext.start();
      SubmitLicenseDBAEditApplication(self.getFormData())
        .done(function() {
          Radio.channel('nd:submit-application').trigger('submit');
          Radio.channel('nd:submit-application').trigger('resubmit', laddaContext);
        })
        .fail(function(err) {
          laddaContext.stop();
        });
    });
  },

  getFormData: function() {
    var formData = Syphon.serialize(this);
    var licenseId = this.model.get('licenseId');
    formData.licenseId = licenseId;
    var entityId = this.model.get('entityId');
    formData.entityId = entityId;
    var applicationId = this.model.get('applicationId');
    formData.applicationId = applicationId;
    formData.applicationDocuments = this.applicationDocumentsFile.collection.pluck('fileId');
    var selectedModel = this.dbaGridView.viewGrid.getSelectedModels();

    var entityDBAIds = _.map(selectedModel, function(dba) {
      return dba.get('id');
    });

    formData.entityDbas = entityDBAIds;
    return formData;
  },

  validate: function() {
    return this.ui.effectiveChangeDateForm.parsley().whenValidate();
  }
});
