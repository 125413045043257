/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by strade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Marionette = require('backbone.marionette');
var GridView = require('psa-marionette/views/GridView');

var AddressDisplayView = require('common/views/AddressDisplayView');

require('../styles/NonDepInternalIndividualView.css');
var tmplNonDepInternalIndividualView = require('../templates/NonDepInternalIndividualView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplNonDepInternalIndividualView,

  className: 'non-dep-internal-individual-view',

  ui: {
    editIndividualButton: '.edit-individual',
    associatedLicensesContainer: '.associated-licenses-container',
    addressContainer: '.address-container'
  },

  regions: {
    addressContainer: '@ui.addressContainer',
    associatedLicensesContainer: '@ui.associatedLicensesContainer'
  },

  templateHelpers: function() {
    return {
      individualType: this.options.individualType
    };
  },

  onBeforeShow: function() {
    this.showChildView(
      'addressContainer',
      new AddressDisplayView({
        model: this.model
      })
    );
    if (this.options.individualType === 'Entity Officer') {
      this.showChildView(
        'associatedLicensesContainer',
        new GridView({
          columns: [
            {
              name: 'entityName',
              label: 'Entity Name',
              cell: 'string',
              headerCell: 'custom',
              width: 40,
              editable: false
            },
            {
              name: 'licenseType',
              label: 'License Type',
              cell: 'string',
              headerCell: 'custom',
              width: 20,
              editable: false
            },
            {
              name: 'licenseNumber',
              label: 'License Number',
              cell: 'string',
              headerCell: 'custom',
              width: 20,
              editable: false
            }
          ],
          collection: new Backbone.Collection(this.model.get('associatedLicenses')),
          emptyText: 'Individual has no associated licenses.'
        })
      );
    }
  }
});
