/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var Syphon = require('backbone.syphon');
var Marionette = require('backbone.marionette');

var FileUploadView = require('common/views/FileUploadView');
var makeUploadCollection = require('common/util/makeUploadCollection');
var parsleyUtil = require('common/util/parsleyUtil');
var toggleForm = require('common/util/toggleForm');

require('../styles/NonDepEntityFormSectionIdentificationView.css');
var tmplNonDepEntityFormSectionIdentificationView = require('../templates/NonDepEntityFormSectionIdentificationView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplNonDepEntityFormSectionIdentificationView,

  className: 'nondep-entity-form-section-identification-view',

  ui: {
    identificationForm: '.entity-identification-form',
    entityNameInput: '.entity-name-input',
    articlesOfIncorporationContainer: '.articles-of-incorporation-container',
    operatingAgreementContainer: '.operating-agreement-container',
    byLawsContainer: '.by-laws-container',
    foreignRegistrationContainer: '.foreign-registration-container',
    entityOrganizationTypeId: '.entity-organization-type-id',
    ssnContainer: '.ssn-container',
    tinContainer: '.tin-container',
    isOfficerConvicted: '.officer-convicted-document-container',
    isOfficerConvictedDocumentContainer: '.is-officer-convicted-document-container',
    isForeignEntity: 'input[name="isForeignEntity"]',
    isForeignEntityNoRadio: 'input[name="isForeignEntity"][value="0"]',
    foreignEntityInfoForm: '.foreign-entity-info-form',
    incorporationDate: '.incorporation-date'
  },

  regions: {
    articlesOfIncorporationContainer: '@ui.articlesOfIncorporationContainer',
    operatingAgreementContainer: '@ui.operatingAgreementContainer',
    byLawsContainer: '@ui.byLawsContainer',
    isOfficerConvictedDocumentContainer: '@ui.isOfficerConvictedDocumentContainer',
    foreignRegistrationContainer: '@ui.foreignRegistrationContainer'
  },

  events: {
    'change @ui.entityOrganizationTypeId': 'onChangeOrganizationType',
    'change this.$([name="isOfficerConvicted"]': 'onChangeIsOfficerConvicted',
    'change @ui.isForeignEntity': 'onChangeIsForeignEntity'
  },

  behaviors: {
    RadioGroupToggleBehavior: {
      radios: function() {
        var radios = [
          {
            target: '.foreign-entity-info-form',
            radioGroup: '[name="isForeignEntity"]',
            valueToShow: '1'
          }
        ];

        if (this.options.viewMode === 'adHocEdit') {
          radios.push({
            target: '.officer-convicted-document-container',
            radioGroup: '[name="isOfficerConvicted"]',
            valueToShow: '1'
          });
        }

        return radios;
      }
    },
    PhoneFormatBehavior: {}
  },

  templateHelpers: function() {
    return {
      viewMode: this.options.viewMode
    };
  },

  onBeforeShow: function() {
    this.articlesOfIncorporation = new FileUploadView({
      collection: makeUploadCollection(this.model.get('articlesOfIncorporationDocument')),
      isReadOnly: this.options.isReadOnly,
      metadata: {
        documentTypeId: 10019
      }
    });
    this.showChildView('articlesOfIncorporationContainer', this.articlesOfIncorporation);

    this.operatingAgreement = new FileUploadView({
      collection: makeUploadCollection(this.model.get('operatingAgreementDocument')),
      isReadOnly: this.options.isReadOnly,
      metadata: {
        documentTypeId: 10021
      }
    });
    this.showChildView('operatingAgreementContainer', this.operatingAgreement);

    this.byLaws = new FileUploadView({
      collection: makeUploadCollection(this.model.get('byLawsDocument')),
      isReadOnly: this.options.isReadOnly,
      metadata: {
        documentTypeId: 10021
      }
    });
    this.showChildView('byLawsContainer', this.byLaws);

    if (this.options.viewMode === 'adHocEdit') {
      this.isOfficerConvictedDocument = new FileUploadView({
        collection: makeUploadCollection(this.model.get('isOfficerConvictedDocument')),
        isReadOnly: this.options.isReadOnly,
        isRequired: true,
        metadata: {
          documentTypeId: 10025
        }
      });
      this.showChildView('isOfficerConvictedDocumentContainer', this.isOfficerConvictedDocument);
    }

    var foreignRegistrationRequired = this.options.viewMode !== 'adHocEdit';
    this.foreignRegistration = new FileUploadView({
      isRequired: foreignRegistrationRequired,
      collection: makeUploadCollection(this.model.get('foreignNameRegistrationDocument')),
      isReadOnly: this.options.isReadOnly,
      metadata: {
        documentTypeId: 10018
      }
    });
    this.showChildView('foreignRegistrationContainer', this.foreignRegistration);

    var isSoleProp = this.model.get('entityOrganizationTypeId') === '6';
    toggleForm(this.ui.ssnContainer, isSoleProp);
    toggleForm(this.ui.tinContainer, !isSoleProp);

    Syphon.deserialize(this, this.model.toJSON());

    this.ui.incorporationDate.datepicker();

    if (!this.model.get('isForeignEntity')) {
      this.ui.isForeignEntityNoRadio.prop('checked', true);
    }
  },

  onChangeOrganizationType: function() {
    // If organizationtype ID = 6 (Sole Proprietorship), show and enable SSN instead of TIN
    var isSoleProp = this.ui.entityOrganizationTypeId.val() === '6';
    toggleForm(this.ui.ssnContainer, isSoleProp);
    toggleForm(this.ui.tinContainer, !isSoleProp);
  },

  onChangeIsForeignEntity: function() {
    var isForeignEntity = this.ui.isForeignEntity.filter(':checked').val() === '1';
    if (this.sideNavView) {
      if (this.sideNavView.getViewBySection('entityFormBooksAndRecords')) {
        var booksAndRecordsSectionView = this.sideNavView.getViewBySection('entityFormBooksAndRecords');
        booksAndRecordsSectionView.toggleNeedsOneBookAndRecord(isForeignEntity);
      }
    }
  },

  validate: function(config) {
    this.ui.identificationForm.parsley().reset();
    if (config.type === 'save') {
      // Turn off required field validation for these forms
      parsleyUtil.disableRequiredValidation(this.ui.identificationForm);
      parsleyUtil.disableRequiredValidation(this.ui.foreignEntityInfoForm);
      // Always ensure that at least an entity name must be provided.
      this.ui.entityNameInput.attr('data-parsley-required', 'true');
    } else {
      // Turn on required field validation for this form
      parsleyUtil.enableRequiredValidation(this.ui.identificationForm);
      parsleyUtil.enableRequiredValidation(this.ui.foreignEntityInfoForm);
    }
    var formData = Syphon.serialize(this.ui.identificationForm);
    var isForeignEntity = formData.isForeignEntity === '1';
    var validationPromises = [this.ui.identificationForm.parsley().whenValidate()];
    if (isForeignEntity) {
      validationPromises.push(this.ui.foreignEntityInfoForm.parsley().whenValidate());
      validationPromises.push(this.foreignRegistration.validate(config));
    }
    if (this.options.viewMode === 'adHocEdit' && formData.isOfficerConvicted === '1') {
      validationPromises.push(this.isOfficerConvictedDocument.validate(config));
    }
    return $.when.apply($, validationPromises);
  },

  getFormData: function() {
    var formData = Syphon.serialize(this);

    formData = _.extend(formData, {
      articlesOfIncorporationDocumentId: this.articlesOfIncorporation.getFirstFileId(),
      operatingAgreementDocumentId: this.operatingAgreement.getFirstFileId(),
      byLawsDocumentId: this.byLaws.getFirstFileId()
    });
    if (this.options.viewMode === 'adHocEdit') {
      if (formData.isOfficerConvicted === '1') {
        formData.isOfficerConvictedDocumentId = this.isOfficerConvictedDocument.getFirstFileId();
      }
    }

    var isForeignEntity = formData.isForeignEntity === '1';
    if (isForeignEntity) {
      formData.foreignRegistrationStatementDocumentId = this.foreignRegistration.getFirstFileId();
    }

    // If sole proprietor, set TIN Type as SSN, else EIN
    if (this.ui.entityOrganizationTypeId.val() === '6') {
      formData.tinTypeId = '2';
    } else {
      formData.tinTypeId = '1';
    }
    return formData;
  }
});
