/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2020] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Backbone = require('backbone');
var NProgress = require('nprogress');

var NavUtils = require('psa-core/util/nav');

var Scaffold = require('common/layout/Scaffold');
var GetUsersForRole = require('common/services/GetUsersForRole');

var GetAllExamLookups = require('../../common/services/GetAllExamLookups');
var GetAllExamInvInqLookups = require('../../common/services/GetAllExamInvInqLookups');
var GetExam = require('../services/GetExam');
var GetExamIdentifiers = require('../services/GetExamIdentifiers');
var GetLicenseAddressByOrganization = require('../services/GetLicenseAddressByOrganization');

var SecExamGeneralEditView = require('../views/SecExamGeneralEditView');

module.exports = function(examId) {
  NProgress.start();
  NProgress.set(0.6);

  $.when(
    GetExam(examId),
    GetExamIdentifiers(examId),
    GetAllExamLookups(),
    GetAllExamInvInqLookups(),
    GetUsersForRole('SC - Examiner')
  )
    .done(function(exam, examIdentifiers, lookups, examInvInqLookups, examiners) {
      GetLicenseAddressByOrganization(exam.organizationId)
        .done(function(licenseAddresses) {
          NavUtils.navigate('#sec/exam/' + examId + '/edit');
          Scaffold.content.show(
            new SecExamGeneralEditView({
              model: new Backbone.Model({
                examIdentifiers: examIdentifiers,
                examId: examId,
                exam: exam,
                examTypes: lookups.examTypes,
                examSubtypes: lookups.examSubtypes,
                examStatuses: lookups.examStatuses,
                examSources: lookups.examSources,
                examCategories: lookups.examCategories,
                examConductedLocations: lookups.examConductedLocations,
                examInvInqOffices: examInvInqLookups.examInvInqOffices,
                examInvInqViolationTypes: examInvInqLookups.examInvInqViolationTypes,
                examiners: examiners,
                licenseAddresses: licenseAddresses
              })
            })
          );
          NProgress.done();
        })
        .fail(function() {
          NProgress.done();
        });
    })
    .fail(function() {
      NProgress.done();
    });
};
