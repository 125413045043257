var Handlebars = require("handlebars/dist/handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper;

  return "<a href=\"#corpfin/registration/"
    + container.escapeExpression(((helper = (helper = helpers.recordId || (depth0 != null ? depth0.recordId : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"recordId","hash":{},"data":data}) : helper)))
    + "/dashboard\"\n              class=\"btn btn-default\">Cancel</a>";
},"3":function(container,depth0,helpers,partials,data) {
    var helper;

  return "<a href=\"#corpfin/exemption/"
    + container.escapeExpression(((helper = (helper = helpers.recordId || (depth0 != null ? depth0.recordId : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"recordId","hash":{},"data":data}) : helper)))
    + "/dashboard\"\n              class=\"btn btn-default\">Cancel</a>";
},"5":function(container,depth0,helpers,partials,data) {
    var helper;

  return "<a href=\"#corpfin/investment/"
    + container.escapeExpression(((helper = (helper = helpers.recordId || (depth0 != null ? depth0.recordId : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"recordId","hash":{},"data":data}) : helper)))
    + "/dashboard\"\n              class=\"btn btn-default\">Cancel</a>";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "\n<div class=\"page-title clearfix\">\n  <h1 class=\"pull-left m-n p-n\">"
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? depth0.record : depth0)) != null ? stack1.recordName : stack1), depth0))
    + "</h1>\n  <div class=\"page-title-buttons-container\">\n    <div class=\"cs-link-container\"></div>\n  </div>\n</div>\n<div class=\"header-bar\"></div>\n<div class=\"breadcrumb-container\"></div>\n<div class=\"form-horizontal\">\n  <div class=\"row\">\n    <div class=\"col-sm-11 col-md-8 col-md-offset-1\">\n      <div class=\"row\">\n        <div class=\"form-group\">\n          <div class=\"col-sm-9 col-sm-offset-3\">\n            <h3 class=\"m-t-n\">New Secondary Name</h3>\n          </div>\n        </div>\n      </div>\n      <form class=\"secondary-name-form-container\"></form>\n      <div class=\"row m-b-lg\">\n        <div class=\"form-group\">\n          <div class=\"col-sm-9 col-sm-offset-3\">\n            "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.isRegistration : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n            "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.isExemption : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n            "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.isInvestment : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n            <button class=\"btn btn-primary submit ladda-button\" data-style=\"expand-right\" type=\"button\"> <span\n                class=\"ladda-label\">Submit</span></button>\n            </button>\n          </div>\n        </div>\n      </div>\n    </div>\n  </div>\n</div>";
},"useData":true});