/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var Marionette = require('backbone.marionette');
var Syphon = require('backbone.syphon');
var moment = require('moment');
var Ladda = require('ladda');
var Radio = require('backbone.radio');

var Session = require('psa-core/Session');

var makeFormReadOnly = require('common/util/makeFormReadOnly');
var modelGet = require('common/util/modelGet');
var toggleForm = require('common/util/toggleForm');

var SubmitAO1ApplicationReviewTask = require('../../services/SubmitAO1ApplicationReviewTask');
var ApplicationPreviousDecisionView = require('../../views/ApplicationPreviousDecisionView');

var AO1ReviewIndividualInvestigationCollectionView = require('./AO1ReviewIndividualInvestigationCollectionView');
var AO1ReviewLicenseBondCollectionView = require('./AO1ReviewLicenseBondCollectionView');

require('./AO1ReviewApplicationWorkTabView.css');
var tmplAO1ReviewApplicationWorkTabView = require('./AO1ReviewApplicationWorkTabView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplAO1ReviewApplicationWorkTabView,

  className: 'ao1-review-application-work-tab-view container-fluid',

  ui: {
    previousDecisionContainer: '.previous-decision-container',
    parentApplicationPendingWarning: '.parent-app-pending-warning',
    reviewForm: '.review-form',
    reviewDecision: '.review-decision',
    decisionReasonContainer: '.decision-reason-container',
    investigationSection: '.investigation-section',
    externalReviewCommentsContainer: '.external-review-comments-container',
    externalReviewComments: '[name="externalReviewComments"]',
    addIndividualInvestigation: '.add-individual-investigation',
    individualInvestigationsContainer: '.individual-investigations-container',
    licenseBoneSection: '.license-bone-section',
    addLicenseBond: '.add-license-bond',
    licenseBondsContainer: '.license-bonds-container',
    submit: '.submit'
  },

  regions: {
    previousDecisionContainer: '@ui.previousDecisionContainer',
    individualInvestigationsContainer: '@ui.individualInvestigationsContainer',
    licenseBondsContainer: '@ui.licenseBondsContainer'
  },

  events: {
    'change @ui.reviewDecision': 'onChangeReviewDecision',
    'click @ui.addIndividualInvestigation': 'onClickAddIndividualInvestigation',
    'click @ui.addLicenseBond': 'onClickAddLicenseBond',
    'click @ui.submit': 'onClickSubmit'
  },

  templateHelpers: function() {
    var applicationDetails = this.model.get('applicationDetails') || {};
    return {
      isNMLS: applicationDetails.applicationTypeId === '28'
    };
  },

  onBeforeShow: function() {
    this.showChildView(
      'previousDecisionContainer',
      new ApplicationPreviousDecisionView({
        model: new Backbone.Model(this.model.get('previousReviewDecision'))
      })
    );
    this.individualInvestigations = new AO1ReviewIndividualInvestigationCollectionView({
      individuals: this.model.get('applicationIndividuals'),
      collection: new Backbone.Collection()
    });
    this.showChildView('individualInvestigationsContainer', this.individualInvestigations);
    var applicationDetails = this.model.get('applicationDetails');
    if (applicationDetails.externalReviewComments) {
      this.ui.externalReviewComments.val('\n\n' + applicationDetails.externalReviewComments);
    }

    var newBranchApplicationTypeIds = _.pluck(this.model.get('newBranchApplicationTypes'), 'id');
    if (
      _.contains(newBranchApplicationTypeIds, applicationDetails.applicationTypeId) &&
      !applicationDetails.parentLicenseId
    ) {
      makeFormReadOnly(this.$el);
      this.ui.parentApplicationPendingWarning.show();
    }

    this.licenseBonds = new AO1ReviewLicenseBondCollectionView({
      collection: new Backbone.Collection(),
      entityBondCompanies: this.model.get('entityBondCompanies'),
      entityBondType: this.model.get('entityBondType'),
      entityBondStatus: this.model.get('entityBondStatus')
    });
    this.showChildView('licenseBondsContainer', this.licenseBonds);
  },

  onChangeReviewDecision: function() {
    var reviewDecision = this.ui.reviewDecision.val();

    var appCategory = modelGet(this.model, 'applicationDetails.applicationCategoryId');
    var isNewApp = appCategory === '1';
    var isRenewalApp = appCategory === '2';

    var isApproved = reviewDecision === '1101';
    var showReviewDec = reviewDecision && ((isNewApp && !isApproved) || isRenewalApp);
    toggleForm(this.ui.decisionReasonContainer, showReviewDec);

    // Further Investigation Required
    var isMTReview = reviewDecision === '1103';
    toggleForm(this.ui.investigationSection, isMTReview);

    // Return to Applicant
    var isReturning = reviewDecision === '1102';
    toggleForm(this.ui.externalReviewCommentsContainer, isReturning);

    // Add Option to add Bonds for approved new license application
    toggleForm(this.ui.licenseBoneSection, isNewApp && isApproved);
  },

  onClickAddIndividualInvestigation: function() {
    this.individualInvestigations.collection.add({});
  },

  onClickAddLicenseBond: function() {
    this.licenseBonds.collection.add({});
  },

  onClickSubmit: function(e) {
    e.preventDefault();
    var self = this;
    var reviewDecisionId = this.ui.reviewDecision.val();
    var parsleyGroups = {
      '1101': 'approved',
      '1102': 'returning',
      '1103': 'investigation',
      '1104': 'abandoned',
      '1105': 'withdrawal',
      '1106': 'denied',
      '1107': 'setstatus'
    };
    var parsleyGroup = parsleyGroups[reviewDecisionId] ? parsleyGroups[reviewDecisionId] : 'none';
    var isReturning = reviewDecisionId === '1102';
    var isMTReview = reviewDecisionId === '1103';
    var appCategory = modelGet(this.model, 'applicationDetails.applicationCategoryId');
    var isNewApp = appCategory === '1';
    var isApproved = reviewDecisionId === '1101';

    this.ui.reviewForm
      .parsley()
      .whenValidate({ group: parsleyGroup })
      .done(function() {
        var l = Ladda.create(self.ui.submit[0]);
        l.start();
        var formData = Syphon.serialize(self);

        // For renewals, set app license status to current license status
        var isRenewal = modelGet(self.model, 'applicationDetails.applicationCategoryId') === '2';
        if (isRenewal) {
          formData.applicationLicenseStatusId = modelGet(self.model, 'applicationDetails.license.licenseStatusId');
        }

        _.extend(formData, {
          taskId: self.model.get('taskId'),
          applicationId: self.model.get('applicationId')
        });
        if (isReturning) {
          var timestamp = moment().format('MM/DD/YYYY h:mm A');
          var userName = Session.user.description;
          formData.externalReviewComments =
            '[' + userName + ' - ' + timestamp + '] \n' + formData.externalReviewComments;
        }
        if (isMTReview) {
          formData.individualInvestigations = self.individualInvestigations.getFormData();
        }
        if (isNewApp && isApproved) {
          formData.licenseBonds = self.licenseBonds.getFormData();
        }

        SubmitAO1ApplicationReviewTask(formData)
          .done(function() {
            Radio.channel('navigate').trigger('show:int:nd:dashboard');
          })
          .fail(function() {
            l.stop();
          });
      });
  }
});
