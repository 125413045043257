/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2020] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Backbone = require('backbone');
var NProgress = require('nprogress');

var NavUtils = require('psa-core/util/nav');

var Scaffold = require('common/layout/Scaffold');
var GetUsersForRole = require('common/services/GetUsersForRole');

var GetExam = require('../services/GetExam');
var GetExamIdentifiers = require('../services/GetExamIdentifiers');
var SecExamNextStepEditView = require('../views/SecExamNextStepEditView');

module.exports = function(examId) {
  NProgress.start();
  NProgress.set(0.6);

  $.when(GetExam(examId), GetExamIdentifiers(examId), GetUsersForRole('SC - User'))
    .done(function(exam, examIdentifiers, users) {
      NavUtils.navigate('#sec/exam/' + examId + '/edit-next-steps');
      Scaffold.content.show(
        new SecExamNextStepEditView({
          model: new Backbone.Model({
            examIdentifiers: examIdentifiers,
            examId: examId,
            exam: exam,
            users: users
          })
        })
      );
      NProgress.done();
    })
    .fail(function() {
      NProgress.done();
    });
};
