/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var cleanUpResponse = require('common/util/cleanUpResponse');

module.exports = function(annualReportId) {
  var deferred = $.Deferred();

  $.soap({
    body: {
      GetPawnbrokerAnnualReport: {
        $xmlns: 'http://dobs.pa.gov/nd/annualreport/bpm/1.0',
        AnnualReportID: annualReportId
      }
    },
    blueprint: {
      id: '$..ID',
      annualReportId: '$..AnnualReportID',
      hasOtherLoans: '$..HasOtherLoans',
      hasOtherLoansExplanation: '$..HasOtherLoansExplanation',
      beginningOutstandingNotAvailable: '$..BeginningOutstandingNotAvailable',
      beginningOutstandingNumber: '$..BeginningOutstandingNumber',
      beginningOutstandingDollars: '$..BeginningOutstandingDollars',
      beginningOutstandingExplanation: '$..BeginningOutstandingExplanation',
      chargedOffLoansNotAvailable: '$..ChargedOffLoansNotAvailable',
      chargedOffLoansNumber: '$..ChargedOffLoansNumber',
      chargedOffLoansDollars: '$..ChargedOffLoansDollars',
      chargedOffLoansExplanation: '$..ChargedOffLoansExplanation',
      endOutstandingNotAvailable: '$..EndOutstandingNotAvailable',
      endOutstandingDollars: '$..EndOutstandingDollars',
      endOutstandingNumber: '$..EndOutstandingNumber',
      endOutstandingExplanation: '$..EndOutstandingExplanation',
      forfeitedLoansNotAvailable: '$..ForfeitedLoansNotAvailable',
      forfeitedLoansNumber: '$..ForfeitedLoansNumber',
      forfeitedLoansDollars: '$..ForfeitedLoansDollars',
      forfeitedLoansExplanation: '$..ForfeitedLoansExplanation',
      newPawnLoansNotAvailable: '$..NewPawnLoansNotAvailable',
      newPawnLoansNumber: '$..NewPawnLoansNumber',
      newPawnLoansDollars: '$..NewPawnLoansDollars',
      newPawnLoansExplanation: '$..NewPawnLoansExplanation',
      redeemedLoansNotAvailable: '$..RedeemedLoansNotAvailable',
      redeemedLoansNumber: '$..RedeemedLoansNumber',
      redeemedLoansDollars: '$..RedeemedLoansDollars',
      redeemedLoansExplanation: '$..RedeemedLoansExplanation',
      totalCancelledLoansNotAvailable: '$..TotalCancelledLoansNotAvailable',
      totalCancelledLoansNumber: '$..TotalCancelledLoansNumber',
      totalCancelledLoansDollars: '$..TotalCancelledLoansDollars',
      totalCancelledLoansExplanation: '$..TotalCancelledLoansExplanation',
      certificationName: '$..CertificationName',
      isCertified: '$..IsCertified'
    }
  })
    .done(function(response) {
      cleanUpResponse(response);

      response.beginningOutstandingNotAvailable = response.beginningOutstandingNotAvailable === '1';
      response.chargedOffLoansNotAvailable = response.chargedOffLoansNotAvailable === '1';
      response.endOutstandingNotAvailable = response.endOutstandingNotAvailable === '1';
      response.forfeitedLoansNotAvailable = response.forfeitedLoansNotAvailable === '1';
      response.newPawnLoansNotAvailable = response.newPawnLoansNotAvailable === '1';
      response.redeemedLoansNotAvailable = response.redeemedLoansNotAvailable === '1';
      response.totalCancelledLoansNotAvailable = response.totalCancelledLoansNotAvailable === '1';
      response.isCertified = response.isCertified === '1';

      deferred.resolve(response);
    })
    .fail(function(err) {
      deferred.reject();
    });

  return deferred.promise();
};
