/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var Marionette = require('backbone.marionette');
var Syphon = require('backbone.syphon');
var Ladda = require('ladda');

var GridView = require('psa-marionette/views/GridView');
var PageableCollection = require('psa-backbone/models/PageableCollection');

var BreadcrumbView = require('common/views/BreadcrumbView');
var BooleanCell = require('common/backgrid/BooleanCell');
var AddressCell = require('common/backgrid/AddressCell');
var LinkCell = require('common/backgrid/LinkCell');

var NonDepInternalSidebarNavView = require('../../common/views/NonDepInternalSidebarNavView');
var SubmitAssignExam = require('../services/SubmitAssignExam');

require('../styles/NonDepInternalAssignExamsView.css');
var tmplNonDepInternalAssignExamsView = require('../templates/NonDepInternalAssignExamsView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplNonDepInternalAssignExamsView,

  className: 'non-dep-internal-assign-exams-view container-fluid',

  ui: {
    sideBar: '.side-bar-container',
    exams: '.exams-container',
    breadcrumb: '.breadcrumb-container',
    assign: '#assign',
    primaryExaminer: '#primaryExaminer',
    secondaryExaminers: '#secondaryExaminers',
    chooseExaminersForm: '.choose-examiners-form',
    noExamSelectedAlert: '#noExamsSelected'
  },

  regions: {
    sideBar: '@ui.sideBar',
    exams: '@ui.exams',
    breadcrumb: '@ui.breadcrumb'
  },

  events: {
    'click @ui.assign': 'onClickAssign'
  },

  onBeforeShow: function() {
    this.showChildView('sideBar', new NonDepInternalSidebarNavView());
    this.collection = new PageableCollection(this.model.get('exams'), { pageSize: 100 });

    var examYear = this.model.get('examYear');
    var examYearId = this.model.get('examYearId');
    this.showChildView(
      'breadcrumb',
      new BreadcrumbView({
        breadcrumbs: [
          {
            link: '#nondep/dashboard/exams',
            text: 'Exams'
          },
          {
            link: '#nondep/dashboard/exams/' + examYearId,
            text: examYear + ' Exam Year'
          },
          {
            text: 'Assign Exams'
          }
        ]
      })
    );

    this.ui.primaryExaminer.select2({
      allowClear: true
    });

    this.ui.secondaryExaminers.select2();
    this.examGridView = new GridView({
      columns: [
        {
          name: 'assign',
          label: 'Assign',
          cell: BooleanCell,
          headerCell: 'custom',
          width: 5,
          editable: true,
          sortable: false
        },
        {
          name: 'examId',
          label: 'Exam Number',
          cell: 'string',
          headerCell: 'custom',
          width: 10,
          editable: false
        },
        {
          name: 'entityName',
          label: 'Entity Name',
          cell: LinkCell.extend({
            emptyText: '--',
            newTab: true,
            href: function(model) {
              var entityId = model.get('entityId');
              var url = entityId ? '#nondep/entity/' + entityId + '/dashboard' : '';
              return url;
            }
          }),
          headerCell: 'custom',
          width: 20,
          editable: false
        },
        {
          name: 'licenseNumber',
          label: 'License Number',
          cell: LinkCell.extend({
            emptyText: '--',
            newTab: true,
            href: function(model) {
              var entityId = model.get('entityId');
              var licenseId = model.get('licenseId');
              var url = entityId && licenseId ? '#nondep/entity/' + entityId + '/license/' + licenseId : '';
              return url;
            }
          }),
          headerCell: 'custom',
          width: 10,
          editable: false
        },
        {
          name: 'licenseType',
          label: 'License Type',
          cell: 'string',
          headerCell: 'custom',
          width: 10,
          editable: false
        },
        {
          name: 'region',
          label: 'Region',
          cell: 'string',
          headerCell: 'custom',
          width: 8,
          editable: false
        },
        {
          name: 'county',
          label: 'County',
          cell: 'string',
          headerCell: 'custom',
          width: 10,
          editable: false
        },
        {
          name: 'address',
          label: 'Street Address',
          cell: AddressCell,
          headerCell: 'custom',
          width: 17,
          editable: false,
          sortable: false
        }
      ],
      collection: this.collection,
      emptyText: 'No Exams Found'
    });

    this.showChildView('exams', this.examGridView);
  },

  onClickAssign: function(e) {
    //TO DO
    e.preventDefault();
    var self = this;
    var selectedExams = _.where(this.examGridView.collection.fullCollection.toJSON(), { assign: true });

    if (this.validate(selectedExams)) {
      this.ui.chooseExaminersForm
        .parsley()
        .whenValidate()
        .done(function() {
          var l = Ladda.create(self.ui.assign[0]);
          l.start();

          var examiners = Syphon.serialize(self);
          var assignment = { examiners: examiners, exams: selectedExams };
          SubmitAssignExam(assignment)
            .done(function() {
              var models = self.collection.fullCollection.filter(function(model) {
                return !model.get('assign');
              });
              self.collection.fullCollection.reset(models);
            })
            .done(function() {
              l.stop();
            })
            .fail(function() {
              l.stop();
            });
        });
    }
  },

  validate: function(selectedExams) {
    this.ui.noExamSelectedAlert.hide();

    if (selectedExams.length === 0) {
      this.ui.noExamSelectedAlert.show();
      return false;
    } else {
      return true;
    }
  }
});
