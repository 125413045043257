/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2020] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var NProgress = require('nprogress');

var NavUtils = require('psa-core/util/nav');
var Session = require('psa-core/Session');

var Scaffold = require('common/layout/Scaffold');

var GetComplaint = require('../services/GetComplaint');
var GetAllComplaintLookups = require('../services/GetAllComplaintLookups');
var GetAllDepEntityLookups = require('../../../depository/common/services/GetAllEntityLookups');
var GetAllNonDepLicenseTypes = require('../../../non-depository/common/services/GetAllLicenseTypes');

var ConSerRelatedComplaintNewView = require('../views/ConSerRelatedComplaintNewView');

module.exports = function(complaintId) {
  NProgress.start();
  NProgress.set(0.6);
  var searchLimit = Session.config.CM_ELASTICSEARCH_MAX_RESULTS || 1000;
  $.when(GetComplaint(complaintId), GetAllComplaintLookups(), GetAllDepEntityLookups(), GetAllNonDepLicenseTypes())
    .done(function(complaint, complaintLookups, depEntityLookups, nonDepLicenseTypes) {
      NavUtils.navigate('conser/complaint/' + complaintId + '/new-related-case');
      Scaffold.content.show(
        new ConSerRelatedComplaintNewView({
          model: new Backbone.Model({
            complaintId: complaintId,
            complaint: complaint,
            searchLimit: searchLimit,
            complaintStatuses: complaintLookups.complaintStatuses,
            weights: complaintLookups.weights,
            departments: complaintLookups.departments,
            depEntityTypes: depEntityLookups.entityTypes,
            nonDepLicenseTypes: nonDepLicenseTypes
          })
        })
      );
      NProgress.done();
    })
    .fail(function() {
      NProgress.done();
    });
};
