/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Marionette = require('backbone.marionette');
var Syphon = require('backbone.syphon');
var Ladda = require('ladda');
var Radio = require('backbone.radio');

var parsleyUtil = require('common/util/parsleyUtil');

var SubmitDebtManagementServicesAnnualReport = require('../../services/SubmitDebtManagementServicesAnnualReport');

var tmplDebtManagementServicesAnnualReportAffirmationSectionView = require('./DebtManagementServicesAnnualReportAffirmationSectionView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplDebtManagementServicesAnnualReportAffirmationSectionView,

  className: 'debtManagementServices-annual-report-affirmation-section-view',

  ui: {
    affirmationForm: '.affirmation-form',
    submit: '.submit'
  },

  events: {
    'click @ui.submit': 'onClickSubmit'
  },

  onBeforeShow: function(e) {
    if (this.options.mode === 'print' || this.options.mode === 'int' || this.options.mode === 'int-expand') {
      Syphon.deserialize(this, this.model.get('annualReport'));
    }
  },

  onClickSubmit: function(e) {
    e.preventDefault();
    var self = this;

    this.sideNavView.validateAll({ type: 'submit' }).done(function() {
      var l = Ladda.create(self.ui.submit[0]);
      l.start();
      var rawFormData = self.sideNavView.getFormData();
      var formData = {
        applicationId: self.model.get('applicationId'),
        annualReportId: self.model.get('annualReportId'),
        generalDetails: rawFormData.debtManagementServicesAnnualReportGeneralDetails,
        productionReport: rawFormData.debtManagementServicesAnnualReportProductionReport,
        affirmation: rawFormData.debtManagementServicesAnnualReportAffirmation
      };
      SubmitDebtManagementServicesAnnualReport(formData)
        .done(function() {
          Radio.channel('nd:submit-application').trigger('resubmit', l);
        })
        .fail(function() {
          l.stop();
        });
    });
  },

  validate: function(config) {
    if (config.type === 'save') {
      parsleyUtil.disableRequiredValidation(this.ui.affirmationForm);
    } else {
      parsleyUtil.enableRequiredValidation(this.ui.affirmationForm);
    }
    return this.ui.affirmationForm.parsley().whenValidate();
  },

  getFormData: function() {
    return Syphon.serialize(this);
  }
});
