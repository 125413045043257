var Handlebars = require("handlebars/dist/handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression;

  return "\n\n<div class=\"page-title clearfix\">\n  <h1 class=\"pull-left m-n p-n\">"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? depth0.task : depth0)) != null ? stack1.taskType : stack1)) != null ? stack1.description : stack1), depth0))
    + " for "
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.filingDetails : depth0)) != null ? stack1.filingType : stack1), depth0))
    + "</h1>\n  <div class=\"page-title-buttons-container\">\n    <a role=\"button\" class=\"btn btn-primary\"\n      href=\"#dep/entity/"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.filingDetails : depth0)) != null ? stack1.entityId : stack1), depth0))
    + "/filing/"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.filingDetails : depth0)) != null ? stack1.filingId : stack1), depth0))
    + "/dashboard\" target=\"_blank\">Open\n      Filing</a>\n  </div>\n</div>\n<div class=\"header-bar\"></div>\n<div class=\"breadcrumb-container\"></div>\n<div class=\"task-status\"></div>\n<div class=\"form-horizontal\">\n  <form class=\"review-withdrawal-form\">\n    <div class=\"form-group\">\n      <label class=\"control-label col-sm-3\">Status</label>\n      <div class=\"col-sm-9 col-md-7 form-control-static\">"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.filingDetails : depth0)) != null ? stack1.recordStatus : stack1), depth0))
    + "</div>\n    </div>\n    <div class=\"form-group\">\n      <label class=\"control-label col-sm-3\">Withdrawal Reason</label>\n      <div class=\"col-sm-9 col-md-7 form-control-static\">"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? depth0.filingDetails : depth0)) != null ? stack1.withdraw : stack1)) != null ? stack1.withdrawReasonExt : stack1), depth0))
    + "</div>\n    </div>\n    <div class=\"form-group\">\n      <label class=\"control-label col-sm-3\">Withdrawal Document</label>\n      <div class=\"col-sm-9 ext-withdrawal-letter-container\"></div>\n    </div>\n    <div class=\"form-group withdrawal-letter required\">\n      <label class=\"control-label col-sm-3\">Withdrawal Letter</label>\n      <div class=\"col-sm-9 withdrawal-letter-container\"></div>\n    </div>\n    <div class=\"form-group\">\n      <label class=\"control-label col-sm-3\">Comments</label>\n      <div class=\"col-sm-9 col-md-7\">\n        <textarea type=\"text\" name=\"withdrawalReason\" class=\"form-control withdrawal-reason\" placeholder=\"Comments\"\n          rows=\"5\"></textarea>\n      </div>\n    </div>\n  </form>\n  <div class=\"form-group\">\n    <div class=\"col-sm-9 col-lg-7 col-sm-offset-3\">\n      <a href=\"#dep/dashboard\" class=\"btn btn-default\" role=\"button\">Cancel</a>\n      <button type=\"button\" class=\"btn btn-primary submit\">Submit</button>\n      <div class=\"task-actions\"></div>\n    </div>\n  </div>\n</div>";
},"useData":true});