var Handlebars = require("handlebars/dist/handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "            <option value=\""
    + alias4(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\">"
    + alias4(((helper = (helper = helpers.description || (depth0 != null ? depth0.description : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"description","hash":{},"data":data}) : helper)))
    + "</option>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "\n<div class=\"modal-body\">\n  <form class=\"license-info-form\">\n    <div class=\"row\">\n      <div class=\"col-sm-6\">\n        <div class=\"form-group required\">\n          <label class=\"control-label\">Description</label>\n          <select name=\"descriptionId\" class=\"form-control\" data-parsley-required=\"true\">\n          <option value=\"\">--</option>\n"
    + ((stack1 = helpers.each.call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.licenseDescription : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "          </select>  \n        </div>\n      </div>\n      <div class=\"col-sm-6\">\n        <div class=\"form-group\">\n          <label class=\"control-label\">Effective Date</label>\n          <div class=\"input-group\">\n            <input type=\"text\" name=\"effectiveDate\" class=\"form-control datepicker effective-date\"\n              placeholder=\"Effective Date\" data-parsley-trigger-after-failure=\"changeDate\" \n              data-parsley-date=\"\"\n              data-parsley-errors-container=\"#statusDateChangeErrorsContainer\"/>\n            <div class=\"input-group-addon\">\n              <i class=\"fa fa-calendar\"></i>\n            </div>\n          </div>   \n          <span id=\"statusDateChangeErrorsContainer\"></span>\n        </div> \n      </div>\n    </div>\n    <div class=\"form-group\">\n      <label class=\"control-label\">Note</label>\n      <textarea class=\"form-control\" name=\"note\" rows=\"4\" placeholder=\"Note\"></textarea>\n    </div>\n  </form>\n</div>\n<div class=\"modal-footer\">\n  <button class=\"btn btn-default\" data-dismiss=\"modal\">Cancel</button>\n  <button class=\"btn btn-primary submit ladda-button\" data-style=\"expand-right\" type=\"button\">\n    <span class=\"ladda-label\">Submit</span>\n  </button>\n</div>";
},"useData":true});