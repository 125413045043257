/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Marionette = require('backbone.marionette');
var Radio = require('backbone.radio');

var tmplNonDepExamNotesSectionView = require('../templates/NonDepExamNotesSectionView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplNonDepExamNotesSectionView,

  className: 'non-dep-exam-notes-section-view',

  ui: {
    notesContainer: '.notes-container'
  },

  regions: {
    notesContainer: '@ui.notesContainer'
  },

  onBeforeShow: function() {
    var notesView = Radio.channel('nd').request('build:notes-view', {
      objectId: this.model.get('exam').examId,
      objectTypeId: 'EXAM'
    });

    this.showChildView('notesContainer', notesView);
  }
});
