/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Marionette = require('backbone.marionette');
var Syphon = require('backbone.syphon');
var modelGet = require('common/util/modelGet');
var toggleForm = require('common/util/toggleForm');
var _ = require('underscore');
var SelectFieldView = require('common/views/SelectFieldView');
var Backbone = require('backbone');

var tmplDepEnforcementDetailsFormView = require('../templates/DepEnforcementDetailsFormView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplDepEnforcementDetailsFormView,

  className: 'dep-enforcement-form-view',

  ui: {
    examAsOfDate: '.exam-as-of-date',
    effectiveDate: '.effective-date',
    terminationDate: '.termination-date',
    infoFields: '.non-tsp-info',
    tspFields: '.tsp-info',
    statusSelect: '.status-select-container',
    returnedToCompositeHalf: '.returned-to-composite-half',
    actionTypeSelect: '.action-type-select'
  },

  regions: {
    statusSelect: '@ui.statusSelect'
  },

  events: {
    'change @ui.actionTypeSelect': 'onChangeActionTypeSelect'
  },

  onBeforeShow: function() {
    var entityTypeId = modelGet(this.model, 'entityIdentifiers.entityTypeId');
    var action = modelGet(this.model, 'enforcement.actionId');

    var isTSP = entityTypeId === '3' || entityTypeId === '12' || entityTypeId === '23';

    toggleForm(this.ui.tspFields, isTSP);
    toggleForm(this.ui.infoFields, !isTSP);

    this.statusCollection = new Backbone.Collection();
    var statusSelect = new SelectFieldView({
      name: 'statusId',
      isDisabled: false,
      isRequired: false,
      collection: this.statusCollection
    });

    this.refreshStatuses(action);

    this.showChildView('statusSelect', statusSelect);

    Syphon.deserialize(this, this.model.get('enforcement'));

    this.ui.examAsOfDate.datepicker();
    this.ui.effectiveDate.datepicker();
    this.ui.terminationDate.datepicker();
    if (entityTypeId === '1' || entityTypeId === '4' || entityTypeId === '7' || entityTypeId === '8') {
      this.ui.returnedToCompositeHalf.datepicker();
    }
  },

  refreshStatuses: function(action) {
    this.statusCollection.reset(
      _.map(modelGet(this.model, 'statuses'), function(status) {
        return { value: status.id, text: status.status };
      })
    );
  },

  onChangeActionTypeSelect: function(e) {
    this.refreshStatuses(e.currentTarget.value);
  },

  getFormData: function() {
    var formData = Syphon.serialize(this);
    formData.entityId = this.model.get('entityId');
    formData.enforcementId = this.model.get('enforcementId');
    return formData;
  }
});
