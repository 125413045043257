/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Marionette = require('backbone.marionette');
var Syphon = require('backbone.syphon');

var parsleyUtil = require('common/util/parsleyUtil');

require('./ConsumerDiscountRenewalCertifyCapitalSectionView.css');
var tmplConsumerDiscountRenewalCertifyCapitalSectionView = require('./ConsumerDiscountRenewalCertifyCapitalSectionView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplConsumerDiscountRenewalCertifyCapitalSectionView,

  className: 'consumer-discount-renewal-certify-capital-section-view',

  ui: {
    certifyCapitalForm: '.certify-capital-form'
  },

  onBeforeShow: function() {
    Syphon.deserialize(this, this.model.get('renewalData'));
  },

  validate: function(config) {
    if (config.type === 'save') {
      parsleyUtil.disableRequiredValidation(this.ui.certifyCapitalForm);
    } else {
      parsleyUtil.enableRequiredValidation(this.ui.certifyCapitalForm);
    }
    return this.ui.certifyCapitalForm.parsley().whenValidate();
  },

  getFormData: function() {
    var formData = Syphon.serialize(this);
    return formData;
  }
});
