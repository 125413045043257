/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var Backbone = require('backbone');
var Marionette = require('backbone.marionette');
var moment = require('moment');

var GridView = require('psa-marionette/views/GridView');
var SelectBoxCell = require('psa-backgrid/SelectBoxCell');

var BooleanCell = require('common/backgrid/BooleanCell');
var MomentCell = require('common/backgrid/MomentCell');

var tmplConsumerDiscountRenewalBranchesSectionView = require('./ConsumerDiscountRenewalBranchesSectionView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplConsumerDiscountRenewalBranchesSectionView,

  className: 'consumer-discount-renewal-branches-section-view',

  ui: {
    branchLicensesContainer: '.branch-licenses-container',
    validationAlert: '.validation-alert'
  },

  regions: {
    branchLicensesContainer: '@ui.branchLicensesContainer'
  },

  initialize: function() {
    this.MINIMUM_LICENSE_AGE = 3;
  },

  templateHelpers: function() {
    return {
      minimumLicenseAge: this.MINIMUM_LICENSE_AGE
    };
  },

  onBeforeShow: function() {
    var licenses = this.model.get('applicationDetails').branchLicenses;
    var renewals = this.model.get('renewalData').branchLicenses;
    var licenseRenewals = _.map(licenses, function(license) {
      var renewal = _.findWhere(renewals, { branchLicenseId: license.id });
      if (renewal) {
        license.isRenewing = renewal.isRenewing;
        license.bondCompanyId = renewal.bondCompanyId;
        license.bondNumber = renewal.bondNumber;
        license.bondExpirationDate = renewal.bondExpirationDate;
      }
      return license;
    });

    this.branchLicensesGrid = new GridView({
      columns: [
        {
          name: 'licenseNumber',
          label: 'License #',
          cell: 'string',
          headerCell: 'custom',
          width: 10,
          editable: false
        },
        {
          name: 'locationName',
          label: 'Location',
          cell: 'string',
          headerCell: 'custom',
          width: 25,
          editable: false
        },
        {
          name: 'originalIssueDate',
          label: 'Original License Date',
          cell: MomentCell.MomentCell,
          formatter: MomentCell.MomentDateFormatter.extend({
            emptyValue: '--'
          }),
          sortValue: MomentCell.MomentSortValue,
          headerCell: 'custom',
          width: 15,
          editable: false
        },
        {
          name: 'isRenewing',
          label: 'Renew License?',
          cell: BooleanCell,
          headerCell: 'custom',
          width: 10,
          editable: true,
          sortable: false
        },
        {
          name: 'bondCompanyId',
          label: 'Bonding Company',
          cell: SelectBoxCell,
          headerCell: 'custom',
          width: 20,
          editable: !this.options.isReadOnly,
          sortable: false,
          noValueText: '--',
          selectOpts: new Backbone.Collection(this.model.get('bondCompanies'))
        },
        {
          name: 'bondNumber',
          label: 'Bond #',
          cell: 'string',
          headerCell: 'custom',
          width: 15,
          editable: !this.options.isReadOnly
        },
        {
          name: 'bondExpirationDate',
          label: 'Expiration Date',
          cell: MomentCell.MomentCell,
          formatter: MomentCell.MomentDateFormatter,
          headerCell: 'custom',
          width: 15,
          editable: !this.options.isReadOnly,
          sortable: false
        }
      ],
      emptyText: 'No Branch Licenses Available for Renewal',
      collection: new Backbone.Collection(licenseRenewals)
    });
    this.showChildView('branchLicensesContainer', this.branchLicensesGrid);

    this.$('.table').addClass('table-bordered');
  },

  validate: function(config) {
    if (config.type === 'save') {
      return this.ui.validationAlert.parsley().whenValidate();
    } else {
      var defer = $.Deferred();
      var self = this;

      this.ui.validationAlert.hide();
      var licenses = this.branchLicensesGrid.collection.toJSON();
      var isValid = _.every(licenses, function(license) {
        if (license.isRenewing) {
          var originalIssueDate = moment(license.originalIssueDate);
          var today = moment();
          var branchLicenseAgeInYears = today.diff(originalIssueDate, 'years');
          var mustGiveBondInfo = branchLicenseAgeInYears < self.MINIMUM_LICENSE_AGE;
          var isMissingBondInfo = !(license.bondCompanyId && license.bondNumber && license.bondExpirationDate);
          if (mustGiveBondInfo && isMissingBondInfo) {
            self.ui.validationAlert.show();
            return false;
          } else {
            return true;
          }
        }
        return true;
      });
      if (isValid) {
        defer.resolve();
      } else {
        defer.reject();
      }
      return defer.promise();
    }
  },

  getFormData: function() {
    return this.branchLicensesGrid.collection.toJSON();
  }
});
