/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var Backbone = require('backbone');
var Backgrid = require('backgrid');
var Marionette = require('backbone.marionette');

var UIUtil = require('psa-core/util/ui');
var PageableCollection = require('psa-backbone/models/PageableCollection');

var ConfirmationModalView = require('common/views/ConfirmationModalView');

var NoteEntryCollectionView = require('./NoteEntryCollectionView');
var NoteModalView = require('./NoteModalView');

var tmplNotesView = require('./NotesView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplNotesView,

  className: 'notes-view',

  ui: {
    addNote: '.add-note',
    noteEntryCollectionContainer: '.note-entry-collection-container',
    paginator: '.paginator-container'
  },

  events: {
    'click @ui.addNote': 'onClickAddNote'
  },

  regions: {
    noteEntryCollectionContainer: '@ui.noteEntryCollectionContainer',
    paginator: '@ui.paginator'
  },

  childEvents: {
    'edit:note': 'onChildEditNote',
    'delete:note': 'onChildDeleteNote'
  },

  initialize: function(options) {
    this.submitNoteService = options.submitNoteService;
    this.managerRole = options.managerRole;
  },

  onBeforeShow: function() {
    var notes = this.model.get('notes') || [];
    this.collection = new PageableCollection(notes, {
      pageSize: 10
    });
    this.collection.setSorting('id',1);
    this.collection.fullCollection.sort();
    this.collection.fullCollection.comparator = function(note1, note2) {
      var date1 = note1.get('editDateTimeMoment');
      var date2 = note2.get('editDateTimeMoment');
      return date1.isBefore(date2) ? 1 : -1;
    };
    this.noteEntryCollection = new NoteEntryCollectionView({
      collection: this.collection,
      noteTypes: this.model.get('noteTypes'),
      managerRole: this.managerRole
    });
    this.showChildView('noteEntryCollectionContainer', this.noteEntryCollection);

    this.showChildView(
      'paginator',
      new Backgrid.Extension.Paginator({
        collection: this.collection
      })
    );
  },

  onClickAddNote: function() {
    var self = this;
    UIUtil.showModal({
      title: 'Add a New Note',
      view: new NoteModalView({
        model: this.model,
        managerRole: this.managerRole,
        submitNoteService: this.submitNoteService
      })
    }).done(function(newNote) {
      self.collection.add(newNote);
      self.collection.fullCollection.sort();
    });
  },

  onChildEditNote: function(childView) {
    var self = this;
    var editData = _.extend({}, this.model.toJSON(), childView.model.toJSON());
    UIUtil.showModal({
      title: 'Edit Note',
      view: new NoteModalView({
        model: new Backbone.Model(editData),
        managerRole: this.managerRole,
        submitNoteService: this.submitNoteService
      })
    }).done(function(noteData) {
      childView.model.set(_.omit(noteData, _.isUndefined));
      self.collection.fullCollection.sort();
    });
  },

  onChildDeleteNote: function(childView) {
    var self = this;
    var message =
      'Are you sure you want to delete this note posted by ' +
      childView.model.get('createdBy') +
      ' on ' +
      childView.model.get('editDateTime') +
      '?';
    UIUtil.showModal({
      title: 'Confirm Deleting Note',
      view: new ConfirmationModalView({
        confirmText: 'Delete Note',
        model: new Backbone.Model({
          message: message,
          onConfirm: function() {
            var noteId = childView.model.id;
            var noteContent = childView.model.get('content');
            return self.submitNoteService({ id: noteId, content: noteContent, isDeleted: '1' });
          },
          onConfirmDone: function() {
            self.collection.remove(childView.model);
          }
        })
      })
    });
  }
});
