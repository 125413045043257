/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Marionette = require('backbone.marionette');

var DepBankLocationActivitiesNewLocationCollectionView = require('./DepBankLocationActivitiesNewLocationCollectionView');

require('./DepBankLocationNewLocationsSectionView.css');
var tmplDepBankLocationNewLocationsSectionView = require('./DepBankLocationNewLocationsSectionView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplDepBankLocationNewLocationsSectionView,

  className: 'dep-bank-location-new-locations-section-view',

  ui: {
    addLocation: '.add-location',
    newLocationsCollectionContainer: '.new-locations-collection-container'
  },

  regions: {
    newLocationsCollectionContainer: '@ui.newLocationsCollectionContainer'
  },

  events: {
    'click @ui.addLocation': 'onClickAddLocation'
  },

  behaviors: {
    ReadOnlyFormBehavior: {}
  },

  onBeforeShow: function() {
    var newLocations = this.options.collection;

    this.newLocations = new DepBankLocationActivitiesNewLocationCollectionView({
      collection: newLocations,
      model: this.model,
      isReadOnly: this.options.isReadOnly,
      mode: this.options.mode
    });
    this.showChildView('newLocationsCollectionContainer', this.newLocations);
  },

  onClickAddLocation: function(e) {
    e.preventDefault();

    this.newLocations.collection.unshift({});
  },

  validate: function(config) {
    return this.newLocations.validate(config);
  },

  getFormData: function() {
    return this.newLocations.getFormData();
  }
});
