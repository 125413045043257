/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Backgrid = require('backgrid');

var InputCellEditor = Backgrid.InputCellEditor.extend({
  /**
     Renders a text input with the cell value formatted for display, if it
     exists.
  */
  render: function() {
    var model = this.model;
    var formattedValue = this.formatter.fromRaw(model.get(this.column.get('name')), model);
    var inputValue = formattedValue !== '--' ? formattedValue : '';
    this.$el.val(inputValue);
    return this;
  }
});

module.exports = InputCellEditor;
