/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Backbone = require('backbone');
var Marionette = require('backbone.marionette');
var Radio = require('backbone.radio');
var Ladda = require('ladda');
var Syphon = require('backbone.syphon');

var UIUtil = require('psa-core/util/ui');

var HeaderBarView = require('common/views/HeaderBarView');
var DropdownView = require('common/views/DropdownView');

var SubmitTimesheet = require('../../common/services/SubmitTimesheet');
var TimeTrkTotalsView = require('../../common/views/TimeTrkTotalsView');
var TimeTrkNonDepEntryRowCollectionView = require('./TimeTrkNonDepEntryRowCollectionView');
var TimeTrkNonDepExpensesCollectionView = require('./TimeTrkNonDepExpensesCollectionView');
var TimeTrkNonDepTimesheetStatusModalView = require('./TimeTrkNonDepTimesheetStatusModalView');
var SubmitTimesheetStatus = require('../services/SubmitTimesheetStatus');

var tmplTimeTrkNonDepExaminerEntryView = require('../templates/TimeTrkNonDepExaminerEntryView.hbs');

require('../styles/TimeTrkNonDepExaminerEntryView.css');
module.exports = Marionette.LayoutView.extend({
  template: tmplTimeTrkNonDepExaminerEntryView,

  className: 'time-trk-non-dep-examiner-entry-view container-fluid',

  // behaviors: {
  //   ReadOnlyFormBehavior: {}
  // },

  ui: {
    invalidTimesheetContainer: '.invalid-timesheet-container',
    headerBar: '.header-bar',
    addEntry: '.add-entry',
    timeEntriesContainer: '.time-entries-container',
    timeTotalsContainer: '.time-totals-container',
    addExpense: '.add-expense',
    expensesContainer: '.expenses-container',
    save: '.save',
    submit: '.submit',
    actionDropdown: '.dropdown-container',
    reopen: '.reopen'
  },

  regions: {
    headerBar: '@ui.headerBar',
    timeEntriesContainer: '@ui.timeEntriesContainer',
    timeTotalsContainer: '@ui.timeTotalsContainer',
    expensesContainer: '@ui.expensesContainer',
    actionDropdown: '@ui.actionDropdown'
  },

  events: {
    'click @ui.addEntry': 'onClickAddEntry',
    'click @ui.addExpense': 'onClickAddExpense',
    'click @ui.save': 'onClickSave',
    'click @ui.submit': 'onClickSubmit',
    'click @ui.reopen': 'onClickReopen'
  },

  childEvents: {
    'update:hour-totals': 'updateHourTotals'
  },

  templateHelpers: function() {
    var timesheet = this.model.get('timesheet') || {};
    var isResubmit = !!timesheet.submittedByUserId;

    return {
      validTimesheet: !!timesheet.timesheetId,
      reviewer: this.options.viewMode === 'reviewer',
      examiner: this.options.viewMode === 'examiner',
      mt: this.options.viewMode === 'mt',
      isReadOnly: this.options.isReadOnly,
      // "Reopen Timesheet" button is only available when the status isn't already open for submitter
      showReopenButton: this.options.isReadOnly && this.options.viewMode === 'examiner',
      isResubmit: isResubmit
    };
  },

  onBeforeShow: function() {
    var timesheet = this.model.get('timesheet') || {};
    var timesheetEntries = timesheet.timesheetEntries || {};
    var expensesEntries = timesheet.expensesEntries || {};
    var payPeriod = this.model.get('payPeriod') || {};
    var status = timesheet.status || {};
    var isReadOnly = this.options.isReadOnly;
    var reviewer = this.options.viewMode === 'reviewer';

    var isReviewed = timesheet.statusId === '3';

    if (!timesheet.timesheetId) {
      this.ui.invalidTimesheetContainer.show();
    } else {
      var headerBarData = {
        title: 'Timesheet',
        assignedTo:
          timesheet.assignedToFirstName +
          ' ' +
          timesheet.assignedToLastName +
          ' (' +
          timesheet.assignedToUsername +
          ')',
        payPeriod: payPeriod.payPeriodDisplay,
        status: status
      };

      var headerBarColumns = [
        {
          name: 'payPeriod',
          label: 'Pay Period'
        },
        {
          name: 'assignedTo',
          label: 'Assigned To'
        },
        {
          name: 'status',
          label: 'Status'
        }
      ];

      this.showChildView(
        'headerBar',
        new HeaderBarView({
          columns: new Backbone.Collection(headerBarColumns),
          model: new Backbone.Model(headerBarData)
        })
      );

      if (reviewer) {
        this.showChildView(
          'actionDropdown',
          new DropdownView({
            rightAlign: true,
            model: new Backbone.Model({
              name: 'Timesheet Actions',
              options: [
                {
                  text: 'Update Timesheet Status',
                  onClick: this.onClickEditStatus.bind(this)
                }
              ]
            })
          })
        );
      }

      Syphon.deserialize(this, timesheet);

      this.timeEntries = new TimeTrkNonDepEntryRowCollectionView({
        collection: new Backbone.Collection(timesheetEntries),
        model: this.model,
        isReadOnly: this.options.isReadOnly
      });
      this.showChildView('timeEntriesContainer', this.timeEntries);

      this.updateHourTotals();

      this.expenses = new TimeTrkNonDepExpensesCollectionView({
        collection: new Backbone.Collection(expensesEntries),
        model: this.model,
        isReadOnly: this.options.isReadOnly
      });
      this.showChildView('expensesContainer', this.expenses);

      // Hide 'Mark as Reviewed' button if already reviewed
      if (isReviewed) {
        this.ui.submit.hide();
      }

      if (isReadOnly) {
        this.$el.find(':input').prop('disabled', true);

        // Hide all buttons with class 'btn-readonly'
        this.$el.find('.btn-readonly').hide();

        // Remove required class from form groups
        this.$el.find('.form-group').removeClass('required');
      }
    }
  },

  onClickAddEntry: function(e) {
    e.preventDefault();
    this.timeEntries.collection.add({});
  },

  onClickReopen: function(e) {
    e.preventDefault();
    var laddaContext = Ladda.create(this.ui.reopen.get(0));
    var timesheetId = this.model.get('timesheetId');

    laddaContext.start();
    SubmitTimesheetStatus({ statusId: '1', timesheetId: timesheetId })
      .done(function() {
        Backbone.history.loadUrl();
      })
      .fail(function() {
        laddaContext.stop();
      });
  },

  updateHourTotals: function() {
    this.model.set('timeEntries', this.timeEntries.getTimeEntries());

    this.timeTotals = new TimeTrkTotalsView({
      model: this.model,
      submitAttempted: this.model.get('submitAttempted')
    });

    this.showChildView('timeTotalsContainer', this.timeTotals);
  },

  onClickEditStatus: function(e) {
    e.preventDefault();
    var timesheetId = this.model.get('timesheetId');
    var timesheet = this.model.get('timesheet') || {};
    var timesheetStatuses = this.model.get('timesheetStatuses') || {};
    // console.log(timesheetId, timesheet, timesheetStatuses);
    UIUtil.showModal({
      title: 'Update Timesheet Status',
      view: new TimeTrkNonDepTimesheetStatusModalView({
        model: new Backbone.Model({
          timesheetId: timesheetId,
          timesheet: timesheet,
          timesheetStatuses: timesheetStatuses
        })
      })
    }).done(function(newNote) {
      Backbone.history.loadUrl();
    });
  },

  onClickAddExpense: function(e) {
    e.preventDefault();
    this.expenses.collection.add({});
  },

  onClickSave: function(e) {
    e.preventDefault();
    var formData = this.getFormData();
    formData.statusId = '1';

    var laddaContext = Ladda.create(this.ui.save.get(0));
    laddaContext.start();
    SubmitTimesheet(formData)
      .done(function() {
        laddaContext.stop();
      })
      .fail(function() {
        laddaContext.stop();
      });
  },

  onClickSubmit: function(e) {
    e.preventDefault();
    var self = this;
    var isExaminer = self.options.viewMode === 'examiner';
    var isReviewer = self.options.viewMode === 'reviewer';
    this.model.set('submitAttempted', true);

    this.validate().done(function() {
      var laddaContext = Ladda.create(self.ui.submit.get(0));
      laddaContext.start();
      var formData = self.getFormData();
      if (isExaminer) {
        formData.statusId = '2';
      } else if (isReviewer) {
        formData.statusId = '3';
      }
      SubmitTimesheet(formData)
        .done(function() {
          if (isExaminer) {
            // Backbone.history.loadUrl();
            Radio.channel('navigate').trigger('show:int:nd:time-tracking-my-list');
          } else {
            if (isReviewer) {
              Radio.channel('navigate').trigger('show:nondep:tt:examiner-entries');
            }
          }
        })
        .fail(function() {
          laddaContext.stop();
        });
    });
  },

  validate: function() {
    var validationArray = [
      this.ui.timeEntriesContainer.parsley().whenValidate(),
      this.ui.expensesContainer.parsley().whenValidate(),
      this.timeTotals.validateTotalTimes()
    ];
    return $.when.apply($, validationArray);
  },

  getFormData: function() {
    var formData = Syphon.serialize(this);

    formData.timesheetId = this.model.get('timesheetId');
    formData.timesheetEntries = this.timeEntries.getFormData();
    formData.expensesEntries = this.expenses.getFormData();

    return formData;
  }
});
