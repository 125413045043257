/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var moment = require('moment');

var momentUtil = require('common/util/momentUtil');
var cleanUpResponse = require('common/util/cleanUpResponse');

var buildExternalUserDisplay = require('../../common/util/buildExternalUserDisplay');

module.exports = function(filingId) {
  var deferred = $.Deferred();
  $.soap({
    body: {
      GetFiling: {
        $xmlns: 'http://dobs.pa.gov/dp/filing/bpm/1.0',
        FilingID: filingId
      }
    },
    blueprintArrays: [
      'GetFilingResponse.Filing.Conditions.DP_FilingCondition',
      'GetFilingResponse.Filing.Section112IndividualNames.DP_FilingSection112IndividualName'
    ],
    blueprint: {
      filingId: '$..ID',
      processInstanceId: '$..ProcessInstanceID',
      csFolderId: '$..CSFolderID',
      recordStatusId: '$..RecordStatusID',
      recordStatus: '$..RecordStatus',
      workflowStatusId: '$..WorkflowStatusID',
      workflowStatus: '$..WorkflowStatus',
      filingTypeId: '$..FilingTypeID',
      filingType: '$..FilingType',
      formIdentifier: '$..FormIdentifier',
      entityId: '$..EntityID',
      entityName: '$..EntityName',
      entityTypeId: '$..EntityTypeID',
      entityCategoryId: '$..EntityCategoryID',
      pointOfContactId: '$..PointOfContactID',
      pointOfContact: '$..PointOfContact',
      pdfExportDocId: '$..PDFExportDocID',
      receivedDate: '$..ReceivedDate',
      decisionDueDate: '$..DecisionDueDate',
      isAfterTheFactFilingRequired: '$..IsAfterTheFactFilingRequired',
      isPreAcceptInfoRequest: '$..IsPreAcceptInfoRequest',
      preAcceptInfoRequestMadeDate: '$..PreAcceptInfoRequestMadeDate',
      preAcceptInfoRequestReceivedDate: '$..PreAcceptInfoRequestReceivedDate',
      acknowledgementSentDate: '$..AcknowledgementSentDate',
      acknowledgementNotificationDate:  '$..AcknowledgementNotificationDate',
      infoRequested: '$..InfoRequested',
      infoRequestDate: '$..InfoRequestDate',
      infoReceivedDate: '$..InfoReceivedDate',
      acceptanceSentDate: '$..AcceptanceSentDate',
      acceptedDate: '$..AcceptedDate',
      caseManager: '$..CaseManager',
      caseManagerSentDate: '$..CaseManagerSentDate',
      supervisor: '$..Supervisor',
      supervisorSentDate: '$..SupervisorSentDate',
      chiefExaminer: '$..ChiefExaminer',
      chiefExaminerSentDate: '$..ChiefExaminerSentDate',
      director: '$..Director',
      directorSentDate: '$..DirectorSentDate',
      legalSentDate: '$..LegalSentDate',
      deputySecretary: '$..DeputySecretary',
      deputySecretarySentDate: '$..DeputySecretarySentDate',
      aa1: '$..AA1',
      aa1SentDate: '$..AA1SentDate',
      pubProofsGeneralOptionId: '$..PubProofsGeneralOptionID',
      pubProofsGeneralOption: '$..PubProofsGeneralOption',
      pubProofsGeneralReceivedDate: '$..PubProofsGeneralReceivedDate',
      pubProofsGeneralExpireDate: '$..PubProofsGeneralExpireDate',
      pubProofsLegalOptionId: '$..PubProofsLegalOptionID',
      pubProofsLegalOption: '$..PubProofsLegalOption',
      pubProofsLegalReceivedDate: '$..PubProofsLegalReceivedDate',
      pubProofsLegalExpireDate: '$..PubProofsLegalExpireDate',
      bulletinOptionId: '$..BulletinOptionID',
      bulletinOption: '$..BulletinOption',
      bulletinPublishedDate: '$..BulletinPublishedDate',
      bulletinExpireDate: '$..BulletinExpireDate',
      infoRequestMadeDate: '$..InfoRequestMadeDate',
      infoRequestReceivedDate: '$..InfoRequestReceivedDate',
      actionId: '$..ActionID',
      action: '$..Action',
      actionDate: '$..ActionDate',
      isFollowUpRequired: '$..IsFollowUpRequired',
      frbApprovalDate: '$..FRBApprovalDate',
      frbApprovalOptionId: '$..FRBApprovalOptionID',
      frbApprovalOption: '$..FRBApprovalOption',
      fdicApprovalDate: '$..FDICApprovalDate',
      fdicApprovalOptionId: '$..FDICApprovalOptionID',
      fdicApprovalOption: '$..FDICApprovalOption',
      ncuaApprovalDate: '$..NCUAApprovalDate',
      ncuaApprovalOptionId: '$..NCUAApprovalOptionID',
      ncuaApprovalOption: '$..NCUAApprovalOption',
      subsidiaryName: '$..SubsidiaryName',
      subsidiaryStatusId: '$..SubsidiaryStatusID',
      subsidiaryStatus: '$..SubsidiaryStatus',
      approvalType: '$..ApprovalType',
      approvalSubsidiary: '$..ApprovalSubsidiary',
      approvalDate: '$..ApprovalDate',
      isDeleted: '$..IsDeleted',
      comments: '$..Comments',
      reviewDecisionComments: '$..ReviewDecisionComments',
      genericSubmissionDetails: {
        submissionTypeId: '$..GenericFilingDetails.DP_FilGenericSubmission.SubmissionTypeID',
        submissionType: '$..GenericFilingDetails.DP_FilGenericSubmission.SubmissionType'
      },
      lawyer: {
        externalUserId: '$..LawyerExternalUserID', // int example: 2003
        firstName: '$..LawyerFirstName',
        lastName: '$..LawyerLastName',
        userID: '$..LawyerUserID' // string example: 'jdoe'
      },
      conditions: [
        '$..DP_FilingCondition',
        {
          filingConditionId: '$..FilingConditionID',
          filingId: '$..FilingID',
          typeId: '$..TypeID',
          type: '$..Type',
          frequencyId: '$..FrequencyID',
          frequency: '$..Frequency',
          addressActivity: '$..AddressActivity',
          dueDate: '$..DueDate',
          statusId: '$..StatusID',
          status: '$..Status',
          receivedDate: '$..ReceivedDate',
          comments: '$.Comments',
          isDeleted: '$..IsDeleted'
        }
      ],
      section112IndividualNames: [
        '$..DP_FilingSection112IndividualName',
        {
          id: '$..ID',
          filingId: '$..FilingID',
          section112IndividualName: '$..Section112IndividualName'
        }
      ]
    }
  })
    .done(function(response) {
      cleanUpResponse(response, { cleanUpTextKeys: true });

      var dateAttributes = [
        'receivedDate',
        'decisionDueDate',
        'acknowledgementSentDate',
        'acknowledgementNotificationDate',
        'preAcceptInfoRequestMadeDate',
        'preAcceptInfoRequestReceivedDate',
        'infoRequestDate',
        'infoReceivedDate',
        'acceptanceSentDate',
        'acceptedDate',
        'caseManagerSentDate',
        'supervisorSentDate',
        'chiefExaminerSentDate',
        'directorSentDate',
        'legalSentDate',
        'deputySecretarySentDate',
        'aa1SentDate',
        'pubProofsGeneralReceivedDate',
        'pubProofsGeneralExpireDate',
        'pubProofsLegalReceivedDate',
        'pubProofsLegalExpireDate',
        'bulletinPublishedDate',
        'bulletinExpireDate',
        'infoRequestMadeDate',
        'infoRequestReceivedDate',
        'actionDate',
        'frbApprovalDate',
        'fdicApprovalDate',
        'ncuaApprovalDate',
        'approvalDate'
      ];

      response.daysFromReceipt = moment(response.receivedDate, 'YYYY-MM-DD').isValid()
        ? moment().diff(moment(response.receivedDate, 'YYYY-MM-DD'), 'days')
        : undefined;

      response.daysFromAcceptanceToCompletion =
        moment(response.actionDate, 'YYYY-MM-DD').isValid() &&
        moment(response.acceptanceSentDate, 'YYYY-MM-DD').isValid()
          ? moment(response.actionDate, 'YYYY-MM-DD').diff(moment(response.acceptanceSentDate, 'YYYY-MM-DD'), 'days')
          : undefined;

      momentUtil.formatDateAttributes(response, dateAttributes, 'YYYY-MM-DD', 'MM/DD/YYYY');

      var lawyer = response.lawyer;
      response.lawyer.displayName = buildExternalUserDisplay(lawyer.userID, lawyer.firstName, lawyer.lastName);

      if (response.isFollowUpRequired === '0') {
        response.isFollowUpRequiredDisplay = 'No';
      } else if (response.isFollowUpRequired === '1') {
        response.isFollowUpRequiredDisplay = 'Yes';
      }

      if (response.isAfterTheFactFilingRequired === '0') {
        response.isAfterTheFactFilingRequiredDisplay = 'No';
      } else if (response.isAfterTheFactFilingRequired === '1') {
        response.isAfterTheFactFilingRequiredDisplay = 'Yes';
      }

      _.each(response.conditions, function(condition) {
        condition.entityId = response.entityId;

        if (condition.dueDate) {
          condition.dueDate = moment(condition.dueDate, 'YYYY-MM-DD');
        }
        if (condition.receivedDate) {
          condition.receivedDate = moment(condition.receivedDate, 'YYYY-MM-DD');
        }
        _.each(['type', 'frequency', 'addressActivity', 'middleInitial', 'status'], function(attr) {
          if (!condition[attr]) {
            condition[attr] = '--';
          }
        });
        var comments = condition.comments;
        if (comments) {
          condition.comments = comments.length > 100 ? comments.substring(0, 100) + '...' : comments;
        } else {
          condition.comments = '--';
        }
      });
      deferred.resolve(response);
    })
    .fail(function(err) {
      deferred.reject();
    });

  return deferred.promise();
};
