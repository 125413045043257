/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Backbone = require('backbone');

var PageableCollection = require('psa-backbone/models/PageableCollection');

var PlaceholderView = require('common/views/PlaceholderView');

var GetExamInvInqReferral = require('../services/GetExamInvInqReferral');
var SecExamInvInqSectionReferralView = require('../views/SecExamInvInqSectionReferralView');

module.exports = {
  channel: 'sc',

  event: 'build:exam-inv-inq-section-referral-view',

  builder: function(data) {
    return new PlaceholderView({
      viewPromise: buildReferralView(data)
    });
  }
};

function buildReferralView(data) {
  var deferred = $.Deferred();

  GetExamInvInqReferral({ objectId: data.objectId, objectTypeId: data.objectTypeId })
    .done(function(referrals) {
      deferred.resolve(
        new SecExamInvInqSectionReferralView({
          model: new Backbone.Model({
            objectId: data.objectId,
            objectTypeId: data.objectTypeId
          }),
          collection: new PageableCollection(referrals, { pageSize: 25 })
        })
      );
    })
    .fail(function(err) {
      deferred.reject(err);
    });

  return deferred.promise();
}
