/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var cleanUpResponse = require('common/util/cleanUpResponse');

module.exports = function(formData) {
  var deferred = $.Deferred();

  $.soap({
    body: {
      SubmitEntityTracking: {
        $xmlns: 'http://dobs.pa.gov/dp/tracking/bpm/1.0',
        ID: formData.id,
        EntityID: formData.entityId,
        TrackingTypeID: formData.trackingTypeId,
        EnteredDate: formData.enteredDate,
        SourceID: formData.trackingSourceId,
        EntityTypeID: formData.trackingEntityTypeId,
        AdoptionDeadlineDate: formData.adoptionDeadlineDate,
        ExpectedAdoptionDate: formData.expectedAdoptionDate,
        AdoptionDate: formData.adoptionDate,
        IsAdopted: formData.isAdopted,
        IsServiceMRBS: formData.isServiceMRBS,
        IsMRBInvestments: formData.isMRBInvestments,
        IsDirect: formData.isDirect,
        IsIndirect: formData.isIndirect,
        IsBank: formData.isBank,
        IsHC: formData.isHC,
        Year: formData.year,
        QuarterID: formData.quarterId,
        IsLoanGrowthOver20: formData.isLoanGrowthOver20,
        IsCommunityLeverageRatioUnder9: formData.isCommunityLeverageRatioUnder9,
        IsCREToRBCOver300: formData.isCREToRBCOver300,
        IsPastDueRatioOver5: formData.isPastDueRatioOver5,
        IsTexasRatioOver30: formData.isTexasRatioOver30,
        IsROAUnderPt25: formData.isROAUnderPt25,
        IsTier1LeverageUnder8: formData.isTier1LeverageUnder8,
        IsNonCoreRationOver20: formData.isNonCoreRatioOver20,
        IsLoanToDepositOver100: formData.isLoanToDepositOver100,
        IsLoanGrowthOver10: formData.isLoanGrowthOver10,
        IsNetAssetsUnder8Pt5: formData.isNetAssetsUnder8Pt5,
        IsPastDueRatioOver3Pt5: formData.isPastDueRatioOver3Pt5,
        IsNetLongTermAssetsOver25: formData.isNetLongTermAssetsOver25,
        IsROAUnderPt50: formData.isROAUnderPt50,
        IsNetWorthGrowthOver10: formData.isNetWorthGrowthOver10,
        IsCashShortTermUnder10: formData.isCashShortTermUnder10,
        IsShareGrowthOver10: formData.isShareGrowthOver10,
        IsAssetGrowthOver10: formData.isAssetGrowthOver10,
        AsOfDate: formData.asOfDate,
        ExamStartDate: formData.examStartDate,
        VendorName: formData.vendorName,
        VendorService: formData.vendorService,
        ShouldDelete: formData.shouldDelete,
        Comments: formData.comments,
        IsActive: formData.isActive,
        Details: formData.details
      }
    },
    blueprint: {
      trackingId: '$..EntityTrackingID'
    }
  })
    .done(function(response) {
      cleanUpResponse(response, { cleanUpTextKeys: true });
      deferred.resolve(response.trackingId);
    })
    .fail(function(err) {
      deferred.reject(err);
    });

  return deferred.promise();
};
