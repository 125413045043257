var Handlebars = require("handlebars/dist/handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression;

  return "\n<div class=\"page-title clearfix\">\n  <h1 class=\"page-title m-n p-n\">"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.applicationDetails : depth0)) != null ? stack1.entityName : stack1), depth0))
    + "</h1>\n</div>\n<div class=\"header-bar\"></div>\n<div class=\"breadcrumb-container\"></div>\n<h2 class=\"m-t-n\">Generate Delinquency Letter</h2>\n<form class=\"form-horizontal doc-gen-form m-t-lg\">\n  <div class=\"form-group required\">\n    <label class=\"control-label col-sm-3\">External Review Comments</label>\n    <div class=\"col-sm-6\">\n      <textarea class=\"form-control\" name=\"externalReviewComments\" rows=\"3\" data-parsley-required=\"true\"\n        placeholder=\"External Review Comments\"></textarea>\n    </div>\n  </div>\n</form>\n<div class=\"row\">\n  <div class=\"col-sm-6 col-sm-offset-3\">\n     <a href=\"#nondep/application/"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.applicationDetails : depth0)) != null ? stack1.applicationId : stack1), depth0))
    + "/dashboard\" class=\"btn btn-default\" role=\"button\">Cancel</a>\n    <button class=\"btn btn-primary submit ladda-button\" data-style=\"expand-right\" type=\"button\"><span\n        class=\"ladda-label\">Generate Document</span></button>\n  </div>\n</div>\n<form class=\"form-horizontal\">\n  <div class=\"form-group m-t-sm\">\n    <label class=\"control-label collapse download-container col-sm-3\">Download Delinquency Letter:</label>\n    <div class=\"col-sm-6\">\n      <div class=\"document-container pull-left\"></div>\n    </div>\n  </div>\n</form>";
},"useData":true});