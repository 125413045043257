/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var ButtonCell = require('../../backgrid/ButtonCell');
var GridView = require('psa-marionette/views/GridView');
var FileUploadCell = require('common/backgrid/FileUploadCell');
var SelectBoxCell = require('psa-backgrid/SelectBoxCell');

module.exports = GridView.extend({
  className: 'file-upload-table-grid-view',

  onBeforeRender: function() {
    if (GridView.prototype.onBeforeRender) {
      GridView.prototype.onBeforeRender.apply(this, arguments);
    }
    this.options.columns = [
      {
        name: 'file',
        label: 'Document',
        cell: FileUploadCell.extend({
          allowMultipleFiles: false,
          isReadOnly: this.options.isReadOnly,
          metadata: this.options.metadata
        }),
        headerCell: 'custom',
        width: 45,
        editable: false,
        sortable: false
      },
      {
        name: 'documentType',
        label: 'Document Type',
        cell: SelectBoxCell,
        headerCell: 'custom',
        width: 45,
        editable: !this.options.isReadOnly,
        sortable: false,
        noValueText: '--',
        selectOpts: new Backbone.Collection(this.options.documentTypes)
      },
      {
        name: 'removeFile',
        label: '',
        cell: ButtonCell.extend({
          name: 'Remove',
          buttonClass: 'btn-danger btn-sm',
          onClick: this.onClickRemoveFile.bind(this)
        }),
        headerCell: 'custom',
        width: 10,
        editable: false,
        sortable: false
      }
    ];

    if (this.options.isReadOnly) {
      this.options.columns.splice(2, 1);
      this.options.columns[0].width = 50;
      this.options.columns[1].width = 50;
      this.options.columns[1].cell = 'string';
    }
  },

  onClickRemoveFile: function(e, model) {
    e.preventDefault();
    this.collection.remove(model);
  }
});
