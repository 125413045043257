/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Backbone = require('backbone');
var Marionette = require('backbone.marionette');
var Syphon = require('backbone.syphon');
var Ladda = require('ladda');

var GridView = require('psa-marionette/views/GridView');
var PageableCollection = require('psa-backbone/models/PageableCollection');
var DropdownView = require('common/views/DropdownView');

var ButtonLinkCell = require('common/backgrid/ButtonLinkCell');
var ElasticSearchSummaryView = require('common/views/ElasticSearchSummaryView');

var CorpFinInternalSidebarNavView = require('../../common/views/CorpFinInternalSidebarNavView');
var SearchCorpFinRecords = require('../services/SearchCorpFinRecords');
var CorpFinRecordSearchBody = require('../util/CorpFinRecordSearchBody');

require('../styles/CorpFinRecordSearchView.css');
var tmplCorpFinRecordSearchView = require('../templates/CorpFinRecordSearchView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplCorpFinRecordSearchView,

  className: 'corp-fin-record-search-view container-fluid',

  ui: {
    sideBar: '.side-bar-container',
    searchResultsContainer: '.search-results-container',
    search: '.search',
    clearFilters: '.clear-filters',
    formInputs: ':input',
    searchSummary: '.search-summary',
    recordActionDropDown: '.dropdown-container'
  },

  regions: {
    sideBar: '@ui.sideBar',
    searchResultsContainer: '@ui.searchResultsContainer',
    searchSummary: '@ui.searchSummary',
    recordActionDropDown: '@ui.recordActionDropDown'
  },

  events: {
    'click @ui.search': 'onClickSearch',
    'click @ui.clearFilters': 'onClickClearFilters',
    'keydown @ui.formInputs': 'onKeydownOfInputs'
  },

  onBeforeShow: function() {
    this.showChildView('sideBar', new CorpFinInternalSidebarNavView());

    this.searchSummaryView = new ElasticSearchSummaryView({
      model: new Backbone.Model({
        searchLimit: this.model.get('searchLimit')
      })
    });
    this.showChildView('searchSummary', this.searchSummaryView);

    this.searchResults = new GridView({
      columns: [
        {
          name: 'recordName',
          label: 'Record Name',
          cell: 'string',
          headerCell: 'custom',
          width: 18,
          editable: false
        },
        {
          name: 'recordCategory',
          label: 'Record Category',
          cell: 'string',
          headerCell: 'custom',
          width: 18,
          editable: false
        },
        {
          name: 'fileNumber',
          label: 'File Number',
          cell: 'string',
          headerCell: 'custom',
          width: 18,
          editable: false
        },
        {
          name: 'filedDate',
          label: 'Date Filed',
          cell: 'string',
          headerCell: 'custom',
          width: 18,
          editable: false
        },
        {
          name: 'dispositionDate',
          label: 'Disposition Date',
          cell: 'string',
          headerCell: 'custom',
          width: 18,
          editable: false
        },
        {
          name: '',
          label: '',
          cell: ButtonLinkCell.extend({
            name: 'Open',
            newTab: true,
            buttonClass: 'btn-primary btn-sm',
            href: function(model) {
              var recordCategories = {
                Registration: 'registration',
                Exemption: 'exemption',
                Investment: 'investment'
              };
              var recordId = model.get('recordId');
              var recordCategory = model.get('recordCategory');

              return '#corpfin/' + recordCategories[recordCategory] + '/' + recordId + '/dashboard';
            }
          }),
          headerCell: 'custom',
          sortable: false,
          editable: false,
          width: 10
        }
      ],
      collection: new PageableCollection([], { pageSize: 25 }),
      emptyText: 'No Records Found'
    });

    this.showChildView('searchResultsContainer', this.searchResults);

    var dropDownOptions = [
      {
        text: 'SAP / Transmittal Cross Reference Batch Update',
        link: '#corpfin/sap-transmittal-cross-ref-batch-update'
      }
    ];

    this.showChildView(
      'recordActionDropDown',
      new DropdownView({
        rightAlign: true,
        model: new Backbone.Model({
          name: 'Record Actions',
          options: dropDownOptions
        })
      })
    );

    this.$('.backgrid.table').addClass('table-bordered');
  },

  onKeydownOfInputs: function(e) {
    // Searches on press of enter
    if (e.keyCode === 13) {
      this.onClickSearch(e);
    }
  },

  onClickSearch: function() {
    var self = this;
    var searchData = Syphon.serialize(this, {
      inputReaders: Syphon.SearchInputReaderSet
    });
    // If no inputs were given, do not perform a search.
    if (!searchData.searchTerm) {
      return;
    }
    this.searchSummaryView.reset();

    var l = Ladda.create(this.ui.search[0]);
    l.start();

    var bodyObj = new CorpFinRecordSearchBody(this.model.get('searchLimit'), searchData.searchTerm);

    // Keep for testing
    // console.log(JSON.stringify(bodyObj, null, 2));
    SearchCorpFinRecords(bodyObj)
      .done(function(searchRes) {
        l.stop();
        self.searchResults.collection.fullCollection.reset(searchRes.hits);
        self.searchSummaryView.display(searchRes);
      })
      .fail(function(err) {
        l.stop();
      });
  },

  onClickClearFilters: function() {
    this.ui.formInputs.val('');
  }
});
