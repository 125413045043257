/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var momentUtil = require('common/util/momentUtil');

var cleanUpResponse = require('common/util/cleanUpResponse');

module.exports = function(fundId) {
  var defer = $.Deferred();

  $.soap({
    body: {
      GetExternalTrustFund: {
        $xmlns: 'http://dobs.pa.gov/dp/fund/bpm/1.0',
        ID: fundId
      }
    },
    blueprint: {
      fundId: '$..ID.__text',
      name: '$..Name.__text',
      entityId: '$..EntityID.__text',
      entityName: '$..EntityName.__text',
      effectiveDate: '$..EffectiveDate.__text',
      approvalDate: '$..ApprovalDate.__text',
      lastAmendmentDate: '$..LastAmendmentDate.__text',
      lastAmendmentApprovalDate: '$..LastAmendmentApprovalDate.__text',
      effectiveAmendDate: '$..EffectiveAmendDate.__text'
    }
  })
    .done(function(response) {
      cleanUpResponse(response);

      response.effectiveDate = momentUtil.formatStr(response.effectiveDate, momentUtil.defaultInboundDateConfig);
      response.approvalDate = momentUtil.formatStr(response.approvalDate, momentUtil.defaultInboundDateConfig);
      response.lastAmendmentDate = momentUtil.formatStr(
        response.lastAmendmentDate,
        momentUtil.defaultInboundDateConfig
      );
      response.lastAmendmentApprovalDate = momentUtil.formatStr(
        response.lastAmendmentApprovalDate,
        momentUtil.defaultInboundDateConfig
      );
      response.effectiveAmendDate = momentUtil.formatStr(
        response.effectiveAmendDate,
        momentUtil.defaultInboundDateConfig
      );

      defer.resolve(response);
    })
    .fail(function(err) {
      defer.reject(err);
    });

  return defer.promise();
};
