var Handlebars = require("handlebars/dist/handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "\n<div class=\"col-sm-8 col-sm-offset-1 m-b-lg\">\n  <div class=\"form-horizontal\">\n    <form class=\"license-edit-dba-section-view-container\">\n      <p>Please select applicable D/B/As</p>\n      <p>Please unselect D/B/As that you wish to cancel from the license</p>\n      <form class=\"dba-form form-horizontal\">\n        <div class=\"dbas\"></div>\n      </form>\n\n      <form class=\"effective-change-date-form\" data-parsley-focus=\"none\">\n        <div class=\"form-group required\">\n          <label class=\"control-label col-sm-3\">Effective Date of Change</label>\n          <div class=\"col-sm-4\">\n            <div class=\"input-group effective-change-date-container\">\n              <input type=\"text\" name=\"effectiveChangeDate\" class=\"form-control datepicker effective-change-date\"\n                placeholder=\"Effective Date of Change\" value=\""
    + alias4(((helper = (helper = helpers.effectiveChangeDate || (depth0 != null ? depth0.effectiveChangeDate : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"effectiveChangeDate","hash":{},"data":data}) : helper)))
    + "\" data-parsley-required=\"true\"\n                data-parsley-date=\"\" data-parsley-trigger-after-failure=\"changeDate\"\n                data-parsley-errors-container=\"#effectiveChangeDateErrorsContainer\" />\n              <div class=\"input-group-addon\">\n                <i class=\"fa fa-calendar\"></i>\n              </div>\n            </div>\n            <div id=\"effectiveChangeDateErrorsContainer\"></div>\n          </div>\n        </div>\n\n        <div class=\"form-group required\">\n          <label class=\"control-label col-sm-3\">Affirmation</label>\n          <label class=\"control-label font-weight-normal text-left col-sm-9\">\n            <input type=\"checkbox\" name=\"isCertified\" value=\"1\" data-parsley-required=\"true\"\n              data-parsley-errors-container=\".affirmation-error-container\" />\n            I understand by submitting this Entity DBA Edit Application: I am agreeing to be bound by the following\n            declaration: \"I declare that all of my answers on this License Application are complete, true and correct. I\n            make this declaration subject to the penalties of 18 PA.C.S. § 4904 relating to unsworn falsification to\n            authorities.\"\n          </label>\n          <div class=\"col-sm-offset-3 col-sm-9\">\n            <div class=\"affirmation-error-container\"></div>\n          </div>\n        </div>\n        <div class=\"form-group required\">\n          <div class=\"col-sm-offset-3 col-sm-9\">\n            <label><strong>Enter Name Below</strong></label>\n            <input type=\"text\" class=\"form-control\" name=\"certificationName\" data-parsley-required=\"true\"\n              value=\""
    + alias4(((helper = (helper = helpers.certificationName || (depth0 != null ? depth0.certificationName : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"certificationName","hash":{},"data":data}) : helper)))
    + "\" data-parsley-maxlength=\"200\" />\n          </div>\n        </div>\n\n      </form>\n    </form>\n    <div class=\"row m-b-lg\">\n      <div class=\"btn-container col-sm-9 col-sm-offset-3\">\n        <a class=\"btn btn-default\" href=\"#ext/nondep/entity/"
    + alias4(((helper = (helper = helpers.entityId || (depth0 != null ? depth0.entityId : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"entityId","hash":{},"data":data}) : helper)))
    + "/license/"
    + alias4(((helper = (helper = helpers.licenseId || (depth0 != null ? depth0.licenseId : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"licenseId","hash":{},"data":data}) : helper)))
    + "\" role=\"button\">Cancel</a>\n        <button class=\"btn btn-primary submit-button ladda-button\" data-style=\"expand-right\" type=\"button\">\n          <span class=\"ladda-label\">Submit</span>\n        </button>\n      </div>\n    </div>\n  </div>\n\n</div>\n";
},"useData":true});