/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var NProgress = require('nprogress');

var NavUtils = require('psa-core/util/nav');

var Scaffold = require('common/layout/Scaffold');

var DepSEGEditView = require('../views/DepSEGEditView');
var GetSEG = require('../services/GetSEG');
var GetEntityIdentifiers = require('../../common/services/GetEntityIdentifiers');
var GetAllSEGLookups = require('../services/GetAllSEGLookups');

module.exports = function(entityId, segId) {
  NProgress.start();
  NProgress.set(0.6);
  $.when(GetSEG(segId), GetEntityIdentifiers(entityId), GetAllSEGLookups())
    .done(function(seg, entityIdentifiers, lookups) {
      NavUtils.navigate('#dep/entity/' + entityId + '/seg/' + segId + '/edit');
      Scaffold.content.show(
        new DepSEGEditView({
          viewMode: 'int',
          model: new Backbone.Model({
            entityId: entityId,
            segId: segId,
            entityIdentifiers: entityIdentifiers,
            status: lookups.status,
            individualTypes: lookups.individualTypes,
            entityTypes: lookups.entityTypes,
            seg: seg
          })
        })
      );
      NProgress.done();
    })
    .fail(function(err) {
      NProgress.done();
    });
};
