/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

/**
 * Toggles the visibility of a jQuery object based on given condition. If the
 * condition is true, the field will be shown, else the field is hidden. Also
 * disables form elements so they are not used by Backbone.Syphon or Parsley.
 * @param {jQuery} $element The jQuery element having its visibility toggled.
 * @param {boolean} state The condition deciding whether to show or hide the element.
 * @example
 *
 * toggleForm($('.title'), true)
 * // shows the title
 *
 * toggleForm($('.title'), false)
 * // hides the title
 */
module.exports = function($element, state) {
  if (!$element) {
    return;
  }
  if (state) {
    $element.show();
    $element.removeAttr('disabled');
  } else {
    $element.hide();
    $element.attr('disabled', '');
  }
};
