/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');

var momentUtil = require('common/util/momentUtil');
var cleanUpResponse = require('common/util/cleanUpResponse');

module.exports = function(assignee) {
  var deferred = $.Deferred();

  $.soap({
    body: {
      GetOpenNextSteps: {
        $xmlns: 'http://dobs.pa.gov/sc/nextstep/bpm/1.0'
      }
    },
    blueprintArrays: ['GetOpenNextStepsResponse.NextSteps.SC_NextStep'],
    blueprint: {
      nextSteps: [
        '$..SC_NextStep',
        {
          id: '$.ID',
          caseNumber: '$..CaseNumber',
          objectType: '$.ObjectType',
          objectId: '$.ObjectID',
          dbaName: '$.DBAName',
          lastName: '$.LastName',
          firstName: '$.FirstName',
          middleInitial: '$.MiddleInitial',
          nextStepDescription: '$.NextStepDescription',
          nextStepStatus: '$.NextStepStatus',
          nextStepType: '$.NextStepType',
          nextStepProjectedDate: '$.NextStepProjectedDate',
          nextStepAssignedTo: '$.NextStepAssignedTo',
          modifiedDate: '$.ModifiedDate'
        }
      ]
    }
  })
    .done(function(response) {
      cleanUpResponse(response);

      _.each(response.nextSteps, function(step) {
        step.displayName =
          step.objectType === 'ORGANIZATION'
            ? step.dbaName
            : step.lastName + (step.firstName ? ', ' + step.firstName : '') + (step.middleInitial ? ' ' + step.middleInitial : '');

        step.nextStepProjectedDate = momentUtil.formatStr(
          step.nextStepProjectedDate,
          momentUtil.defaultInboundDateConfig
        );

        step.modifiedDate = momentUtil.formatStr(step.modifiedDate, momentUtil.defaultInboundDateConfig);
      });

      deferred.resolve(response.nextSteps);
    })
    .fail(function(err) {
      deferred.reject(err);
    });

  return deferred.promise();
};
