/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Backbone = require('backbone');
var Marionette = require('backbone.marionette');
var Radio = require('backbone.radio');
var modelGet = require('common/util/modelGet');

var UIUtil = require('psa-core/util/ui');
var ConfirmationModalView = require('common/views/ConfirmationModalView');
var SubmitEntityExamMain = require('../services/SubmitEntityExamMain');

var ContentServerLinkView = require('common/views/ContentServerLinkView');
var BreadcrumbView = require('common/views/BreadcrumbView');
var DropdownView = require('common/views/DropdownView');
var SideNavSectionView = require('common/views/SideNavSectionView');
var userHasRole = require('common/util/userHasRole');

var DepExamMainSectionView = require('./DepExamMainSectionView');
var DepExamRatingSectionView = require('./DepExamRatingSectionView');
var DepExamOtherInformationSectionView = require('./DepExamOtherInformationSectionView');
var DepExamReassignModalView = require('./DepExamReassignModalView');

var tmplDepExamDashboardView = require('../templates/DepExamDashboardView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplDepExamDashboardView,

  className: 'dep-exam-dashboard-view container-fluid m-b-lg',

  ui: {
    examWrapper: '.exam-wrapper',
    csLinkContainer: '.cs-link-container',
    examActionDropdown: '.dropdown-container',
    breadcrumb: '.breadcrumb-container'
  },

  regions: {
    examWrapper: '@ui.examWrapper',
    csLinkContainer: '@ui.csLinkContainer',
    examActionDropdown: '@ui.examActionDropdown',
    breadcrumb: '@ui.breadcrumb'
  },

  onBeforeShow: function() {
    var entityId = this.model.get('entityId');
    var examId = modelGet(this.model, 'exam.id');
    var entityTypeId = this.model.get('entityTypeId');
    var isLegalHold = this.model.get('entityHasLegalHold');
    isLegalHold = isLegalHold === '1';

    var isAA1 = userHasRole('DP - AA1') || userHasRole('DP - AA1 - Credit Union');
    var isAO = userHasRole('DP - AO - Credit Union') || userHasRole('DP - AO - Trust');
    var isDPAA1 = userHasRole('DP - AA1');
    var isDirector =
      userHasRole('DP - Director - Bank') ||
      userHasRole('DP - Director - Credit Union') ||
      userHasRole('DP - Director - Credit Union Trust') ||
      userHasRole('DP - Director - Trust');

    var IsEICReassign = isAA1 || isAO || isDirector;

    this.showChildView(
      'csLinkContainer',
      new ContentServerLinkView({
        model: new Backbone.Model({
          buttonText: 'Open Exam Folder',
          contentServerId: modelGet(this.model, 'exam.csFolderId', '0') || this.model.get('examsCSFolderId')
        })
      })
    );

    this.showChildView(
      'breadcrumb',
      new BreadcrumbView({
        breadcrumbs: [
          {
            link: '#dep/dashboard',
            text: 'Home'
          },
          {
            link: '#dep/entity/' + entityId + '/dashboard',
            text: this.model.get('entityName')
          },
          {
            text: modelGet(this.model, 'exam.examType') + ' - ' + modelGet(this.model, 'exam.leadAgency')
          }
        ]
      })
    );

    var dropDownOptions = [];
    var sideNavTabs = [];
    var isTSP = entityTypeId === '3' || entityTypeId === '12' || entityTypeId === '23';

    if (isTSP) {
      dropDownOptions = [
        {
          text: 'Edit Main Information',
          link: '#dep/entity/' + entityId + '/exam/' + examId + '/edit-main'
        },
        {
          text: 'Edit Rating Information',
          link: '#dep/entity/' + entityId + '/exam/' + examId + '/edit-rating'
        }
      ];
      if (isDPAA1 || isAO) {
        dropDownOptions.push({ isDivider: true });
        dropDownOptions.push({
          text: isLegalHold ? 'Cannot Delete - Has Legal Hold' : 'Delete Exam',
          onClick: isLegalHold ? '' : this.onClickDelete.bind(this)
        });
      }

      sideNavTabs = [
        {
          id: 'main',
          title: 'Main',
          view: new DepExamMainSectionView({
            model: this.model
          })
        },
        {
          id: 'ratings',
          title: 'Ratings',
          view: new DepExamRatingSectionView({
            entityTypeId: entityTypeId,
            model: new Backbone.Model(this.model.get('exam'))
          })
        },
        {
          id: 'notes',
          title: 'Notes',
          view: Radio.channel('dp').request('build:notes-view', {
            objectId: examId,
            objectTypeId: 'EXAM'
          })
        },
        {
          id: 'auditLog',
          title: 'Audit Log',
          view: Radio.channel('dp').request('build:audit-log-view', {
            objectId: examId,
            objectTypeId: 'EXAM'
          })
        }
      ];
    } else {
      dropDownOptions = [
        {
          text: 'Edit Main Information',
          link: '#dep/entity/' + entityId + '/exam/' + examId + '/edit-main'
        },
        {
          text: 'Edit Rating Information',
          link: '#dep/entity/' + entityId + '/exam/' + examId + '/edit-rating'
        },
        {
          text: 'Edit Other Information',
          link: '#dep/entity/' + entityId + '/exam/' + examId + '/edit-other'
        },
        { isDivider: true },
        {
          text: isLegalHold ? 'Cannot Delete - Has Legal Hold' : 'Delete Exam',
          onClick: isLegalHold ? '' : this.onClickDelete.bind(this)
        }
      ];

      sideNavTabs = [
        {
          id: 'main',
          title: 'Main',
          view: new DepExamMainSectionView({
            model: this.model
          })
        },
        {
          id: 'ratings',
          title: 'Ratings',
          view: new DepExamRatingSectionView({
            entityTypeId: entityTypeId,
            entityCharterTypeId: this.model.get('entityCharterTypeId'),
            model: new Backbone.Model(this.model.get('exam'))
          })
        },
        {
          id: 'examTurnaround',
          title: 'Exam Turnaround',
          view: Radio.channel('dp').request(
            'build:exam-turnaround-section-view',
            examId,
            modelGet(this.model, 'exam.examAreas', [])
          )
        },
        {
          id: 'otherInformation',
          title: 'Other Information',
          view: new DepExamOtherInformationSectionView({
            model: this.model
          })
        },
        {
          id: 'notes',
          title: 'Notes',
          view: Radio.channel('dp').request('build:notes-view', {
            objectId: examId,
            objectTypeId: 'EXAM'
          })
        },
        {
          id: 'auditLog',
          title: 'Audit Log',
          view: Radio.channel('dp').request('build:audit-log-view', {
            objectId: examId,
            objectTypeId: 'EXAM'
          })
        }
      ];
    }

    dropDownOptions.push({ isDivider: true });
    dropDownOptions.push({
      text: 'Manage Owner',
      link: '#dep/entity/' + entityId + '/exam/' + examId + '/manage-owner'
    });

    if (IsEICReassign) {
      dropDownOptions.push({
        text: 'Reassign EIC',
        onClick: this.onClickReassign.bind(this)
      });
    }

    this.showChildView(
      'examActionDropdown',
      new DropdownView({
        rightAlign: true,
        model: new Backbone.Model({
          name: 'Exam Actions',
          options: dropDownOptions
        })
      })
    );

    this.exam = new SideNavSectionView({
      useNavigationButtons: this.options.isReadOnly,
      collection: new Backbone.Collection(sideNavTabs)
    });
    this.showChildView('examWrapper', this.exam);
  },

  onClickDelete: function() {
    var self = this;
    UIUtil.showModal({
      title: 'Confirm Deleting Exam',
      view: new ConfirmationModalView({
        confirmText: 'Delete Exam',
        model: new Backbone.Model({
          message: 'Are you sure you want to delete this exam?',
          onConfirm: function() {
            return SubmitEntityExamMain({
              id: modelGet(self.model, 'exam.id'),
              isDeleted: 1
            });
          },
          onConfirmDone: function() {
            Radio.channel('navigate').trigger('show:int:dp:entity', modelGet(self.model, 'exam.entityId'));
          }
        })
      })
    });
  },

  onClickReassign: function() {
    var self = this;

    UIUtil.showModal({
      title: 'Reassign Exam EIC?',
      view: new DepExamReassignModalView({
        model: new Backbone.Model({
          id: modelGet(self.model, 'exam.id'),
          examiners: this.model.get('examiners'),
          eic: modelGet(this.model, 'exam.eic')
        })
      })
    });
  }
});
