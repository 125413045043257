/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Marionette = require('backbone.marionette');
var Syphon = require('backbone.syphon');

var toggleForm = require('common/util/toggleForm');
var modelGet = require('common/util/modelGet');
var parsleyUtil = require('common/util/parsleyUtil');

var tmplMortgageDiscountCompanyAnnualReportProductionReportSectionView = require('./MortgageDiscountCompanyAnnualReportProductionReportSectionView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplMortgageDiscountCompanyAnnualReportProductionReportSectionView,

  className: 'mortgage-discount-company-annual-report-production-report-section-view',

  behaviors: {
    RadioGroupToggleBehavior: {
      radios: [
        {
          target: '#hasServicedMortgageExplainationArea',
          radioGroup: '[name="hasServicedUnownedPAMortgageLoans"]',
          valueToShow: '1'
        }
      ]
    }
  },

  ui: {
    productionReportForm: '.production-report-form',
    notAvailable: '.not-available',
    loanReceivablesInputs: '#loanReceivablesInputs',
    loansInputs: '#loansInputs',
    repossessionInputs: '#repossessionInputs',
    loanReceivablesInputsExplanation: '#loanReceivablesInputsExplanation',
    loansInputsExplanation: '#loansInputsExplanation',
    repossessionInputsExplanation: '#repossessionInputsExplanation'
  },

  events: {
    'change @ui.notAvailable': 'onChangeNotAvailable'
  },

  onBeforeShow: function() {
    var loanReceivablesHeldNotAvailable = modelGet(this.model, 'annualReport.loanReceivablesHeldNotAvailable');
    this.toggleQuestionInputs(this.ui.loanReceivablesInputs, loanReceivablesHeldNotAvailable);
    this.toggleExplanationInputs(this.ui.loanReceivablesInputsExplanation, loanReceivablesHeldNotAvailable);

    // Toggle loans based on answer
    var loansNotAvailable = modelGet(this.model, 'annualReport.loansNotAvailable');
    this.toggleQuestionInputs(this.ui.loansInputs, loansNotAvailable);
    this.toggleExplanationInputs(this.ui.loansInputsExplanation, loansNotAvailable);

    // Toggle repossession based on answer
    var repossessionNotAvailable = modelGet(this.model, 'annualReport.repossessionNotAvailable');
    this.toggleQuestionInputs(this.ui.repossessionInputs, repossessionNotAvailable);
    this.toggleExplanationInputs(this.ui.repossessionInputsExplanation, repossessionNotAvailable);

    Syphon.deserialize(this, this.model.get('annualReport'));
  },

  onChangeNotAvailable: function(e) {
    var $checkbox = $(e.target);
    var isNotAvailable = $checkbox.prop('checked');
    var formTargetSelector = $checkbox.attr('data-target');
    var $questionInputs = this.$el.find(formTargetSelector);
    var $explanationInputs = this.$el.find(formTargetSelector + 'Explanation');
    this.toggleQuestionInputs($questionInputs, isNotAvailable);
    this.toggleExplanationInputs($explanationInputs, isNotAvailable);
  },

  validate: function(config) {
    if (config.type === 'save') {
      parsleyUtil.disableRequiredValidation(this.ui.productionReportForm);
    } else {
      parsleyUtil.enableRequiredValidation(this.ui.productionReportForm);
    }
    return this.ui.productionReportForm.parsley().whenValidate();
  },

  getFormData: function() {
    return Syphon.serialize(this);
  },

  toggleQuestionInputs: function($element, isNotAvailable) {
    toggleForm($element, !isNotAvailable);
  },

  toggleExplanationInputs: function($element, isAvailable) {
    toggleForm($element, isAvailable);
  }
});
