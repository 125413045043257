var Handlebars = require("handlebars/dist/handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "          <option value=\""
    + alias4(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\">"
    + alias4(((helper = (helper = helpers.type || (depth0 != null ? depth0.type : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"type","hash":{},"data":data}) : helper)))
    + "</option>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "        <option value=\""
    + alias4(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\">"
    + alias4(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data}) : helper)))
    + "</option>\n";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "    <div class=\"row\">\n      <div class=\"col-sm-12\">\n        <button class=\"btn btn-primary submit ladda-button\" data-style=\"expand-right\" type=\"button\">\n"
    + ((stack1 = (helpers.ifCond || (depth0 && depth0.ifCond) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.mode : depth0),"===","ext-new",{"name":"ifCond","hash":{},"fn":container.program(6, data, 0),"inverse":container.program(11, data, 0),"data":data})) != null ? stack1 : "")
    + "        </button>\n      </div>\n    </div>\n";
},"6":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? depth0.filingDetails : depth0)) != null ? stack1.filingfee : stack1),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.program(9, data, 0),"data":data})) != null ? stack1 : "");
},"7":function(container,depth0,helpers,partials,data) {
    return "            <span class=\"ladda-label\">Finalize Filing and Proceed to Cart</span>\n";
},"9":function(container,depth0,helpers,partials,data) {
    return "              <span class=\"ladda-label\">Finalize Filing and Submit</span>\n";
},"11":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = (helpers.ifCond || (depth0 && depth0.ifCond) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.mode : depth0),"===","int-new",{"name":"ifCond","hash":{},"fn":container.program(12, data, 0),"inverse":container.program(14, data, 0),"data":data})) != null ? stack1 : "");
},"12":function(container,depth0,helpers,partials,data) {
    return "            <span class=\"ladda-label\">Submit Filing</span>\n";
},"14":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = (helpers.ifCond || (depth0 && depth0.ifCond) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.mode : depth0),"===","resubmit",{"name":"ifCond","hash":{},"fn":container.program(15, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"15":function(container,depth0,helpers,partials,data) {
    return "            <span class=\"ladda-label\">Finalize Filing and Submit</span>\n          ";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "\n<form class=\"submission-form\">\n  <div class=\"row\">\n    <div class=\"col-sm-6\">\n      <div class=\"form-group required\">\n        <label class=\"control-label\">Audit Dated</label>\n        <div class=\"input-group\">\n          <input type=\"text\" name=\"auditDated\" class=\"form-control date-picker audit-dated\"\n            placeholder=\"As-of date of the audit\" data-parsley-required=\"true\" data-parsley-date=\"\"\n            data-parsley-trigger-after-failure=\"changeDate\" data-parsley-errors-container=\"#auditDateErrorsContainer\" />\n          <div class=\"input-group-addon\">\n            <i class=\"fa fa-calendar\"></i>\n          </div>\n        </div>\n        <div id=\"auditDateErrorsContainer\"></div>\n      </div>\n    </div>\n  </div>\n  <div class=\"row\">\n    <div class=\"col-sm-6\">\n      <div class=\"form-group audit-type-form-group required\">\n        <label class=\"control-label\">Audit Type</label>\n        <select name=\"auditTypeId\" class=\"form-control audit-type\" data-parsley-required=\"true\">\n          <option value=\"\">--</option>\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.auditTypes : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "        </select>\n      </div>\n    </div>\n  </div>\n  <div class=\"other-audit-type\">\n    <div class=\"form-group required\">\n      <label class=\"control-label\">CPA or Approved Internal Audit Program</label>\n      <div class=\"row\">\n        <div class=\"col-sm-12\">\n          <label class=\"radio-inline\">\n            <input type=\"radio\" name=\"isCPA\" value=\"1\" data-parsley-required=\"true\"\n              data-parsley-errors-container=\"#isCPAErrorContainer\"> CPA\n          </label>\n          <label class=\"radio-inline\">\n            <input type=\"radio\" name=\"isCPA\" value=\"0\" data-parsley-required=\"true\"\n              data-parsley-errors-container=\"#isCPAErrorContainer\"> Approved Internal Audit Program\n          </label>\n          <div id=\"isCPAErrorContainer\"></div>\n        </div>\n      </div>\n    </div>\n  </div>\n  <div class=\"fund-audit-type\">\n    <div class=\"form-group required\">\n      <label class=\"control-label\">Fund or Subfund</label>\n      <div class=\"row\">\n        <div class=\"col-sm-12\">\n          <label class=\"radio-inline\">\n            <input type=\"radio\" name=\"isFund\" value=\"1\" data-parsley-required=\"true\"\n              data-parsley-errors-container=\"#isFundErrorContainer\"> Fund\n          </label>\n          <label class=\"radio-inline\">\n            <input type=\"radio\" name=\"isFund\" value=\"0\" data-parsley-required=\"true\"\n              data-parsley-errors-container=\"#isFundErrorContainer\"> Subfund\n          </label>\n          <div id=\"isFundErrorContainer\"></div>\n        </div>\n      </div>\n    </div>\n    <div class=\"form-group required\">\n      <label class=\"control-label\">Fund Name</label>\n      <select name=\"fundId\" class=\"form-control fund-name\" data-parsley-required=\"true\">\n        <option value=\"\">--</option>\n"
    + ((stack1 = helpers.each.call(alias1,((stack1 = (depth0 != null ? depth0.filingContent : depth0)) != null ? stack1.trustFunds : stack1),{"name":"each","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "      </select>\n    </div>\n    <div class=\"subfund-audit-container\">\n      <div class=\"form-group required subfund-form-group\">\n        <label class=\"control-label\">Subfund Name</label>\n        <div class=\"subfund-select-container\"></div>\n      </div>\n    </div>\n\n  </div>\n  <div class=\"form-group audit-file-form-group required\">\n    <label class=\"control-label\">Audit File</label>\n    <div class=\"audit-file-document-container\"></div>\n  </div>\n  <div class=\"form-group\">\n    <label class=\"control-label\">Comments</label>\n    <textarea type=\"text\" name=\"auditComments\" class=\"form-control input-view\" placeholder=\"Comments\" rows=\"5\"></textarea>\n  </div>\n  <div class=\"form-group print-view\" id=\"auditCommentsPrintView\"></div>\n  <div class=\"form-group\">\n    <label class=\"control-label\">Other Supporting Documents</label>\n    <div class=\"supporting-documents-container\"></div>\n  </div>\n  \n"
    + ((stack1 = (helpers.ifCond || (depth0 && depth0.ifCond) || helpers.helperMissing).call(alias1,(depth0 != null ? depth0.mode : depth0),"!=","print",{"name":"ifCond","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "</form>";
},"useData":true});