/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var cleanUpResponse = require('common/util/cleanUpResponse');

module.exports = window.GetAllApplicationLookups = function() {
  var deferred = $.Deferred();
  $.soap({
    body: {
      GetAllApplicationLookups: {
        $xmlns: 'http://dobs.pa.gov/sc/lookup/bpm/1.0'
      }
    },
    blueprintArrays: [
      'GetAllApplicationLookups.ApplicationWorkflowStatuses.SC_LU_ApplicationWorkflowStatus',
      'GetAllApplicationLookups.ApplicationLicenseTypes.SC_LU_ApplicationLicenseType',
      'GetAllApplicationLookups.ApplicationTypes.SC_LU_ApplicationType',
      'GetAllApplicationLookups.ApplicationRecordStatuses.SC_LU_ApplicationRecordStatus'
    ],
    blueprint: {
      applicationWorkflowStatuses: [
        '$..SC_LU_ApplicationWorkflowStatus',
        {
          id: '$..ID',
          status: '$..Status'
        }
      ],
      applicationLicenseTypes: [
        '$..SC_LU_ApplicationLicenseType',
        {
          id: '$..ID',
          type: '$..Type'
        }
      ],
      applicationRecordStatuses: [
        '$..SC_LU_ApplicationRecordStatus',
        {
          id: '$..ID',
          status: '$..Status',
          licenseStatusId: '$..LicenseStatusID'
        }
      ],
      applicationTypes: [
        '$..SC_LU_ApplicationType',
        {
          id: '$..ID',
          type: '$..Type'
        }
      ]
    }
  })
    .done(function(response) {
      cleanUpResponse(response);
      deferred.resolve(response);
    })
    .fail(function(err) {
      deferred.reject();
    });

  return deferred.promise();
};
