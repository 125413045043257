/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var momentUtil = require('common/util/momentUtil');
var cleanUpResponse = require('common/util/cleanUpResponse');

module.exports = function(portfolioId) {
  var deferred = $.Deferred();

  $.soap({
    body: {
      GetPortfolio: {
        $xmlns: 'http://dobs.pa.gov/cf/investment/bpm/1.0',
        PortfolioID: portfolioId
      }
    },
    blueprintArrays: ['GetPortfolioResponse.Classes.CF_PortfolioClass'],
    blueprint: {
      id: '$..ID',
      recordId: '$..RecordID',
      portfolioName: '$..PortfolioName',
      portfolioDate: '$..PortfolioDate',
      isDeleted: '$..IsDeleted',
      createdDate: '$..CreatedDate',
      createdBy: '$..CreatedBy',
      modifiedDate: '$..ModifiedDate',
      modifiedBy: '$..ModifiedBy',
      classes: [
        '$..CF_PortfolioClass',
        {
          id: '$..ID',
          portfolioId: '$..PortfolioID',
          className: '$..ClassName'
        }
      ]
    }
  })
    .done(function(response) {
      cleanUpResponse(response);

      var formInboundDateConfig = {
        sourceFormat: 'YYYY-MM-DD',
        targetFormat: 'MM/DD/YYYY',
        default: undefined
      };

      response.portfolioDate = momentUtil.formatStr(response.portfolioDate, formInboundDateConfig);

      deferred.resolve(response);
    })
    .fail(function(err) {
      deferred.reject();
    });

  return deferred.promise();
};
