var Handlebars = require("handlebars/dist/handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression;

  return "\n<div class=\"page-title clearfix\">\n  <h1 class=\"pull-left m-n p-n\">"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? depth0.task : depth0)) != null ? stack1.taskType : stack1)) != null ? stack1.description : stack1), depth0))
    + "</h1>\n</div>\n<div class=\"header-bar-container\"></div>\n<div class=\"breadcrumb-container\"></div>\n<div class=\"task-status\"></div>\n\n<div class=\"m-b-lg\">\n  <form class=\"form-horizontal review-enforcement-action-form\">\n    <div class=\"case-summary-container\">\n      <div class=\"form-group\">\n        <label class=\"control-label col-sm-3\">Jump To</label>\n        <div class=\"form-control-static col-sm-6 jump-to-container\"></div>\n      </div>\n      <div class=\"summary-container\"></div>\n      <div class=\"form-group\">\n        <label class=\"control-label col-sm-3\">Narrative</label>\n        <p class=\"form-control-static col-sm-6\">"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.complianceDetails : depth0)) != null ? stack1.narrative : stack1), depth0))
    + "</p>\n      </div>\n    </div>\n    <div class=\"enforcement-action-form-container\"></div>\n    <div class=\"form-group required\">\n      <label class=\"control-label col-sm-3\">Review Decision</label>\n      <div class=\"col-sm-6\">\n        <select name=\"reviewDecision\" class=\"review-decision-select form-control\" data-parsley-required=\"true\"\n          data-parsley-errors-container=\".review-decision-select-error-container\">\n          <option value=\"\" selected>--</option>\n          <option value=\"2107\">Approve Enforcement Action</option>\n          <option value=\"2106\">Return For Revisions</option>\n        </select>\n        <div class=\"review-decision-select-error-container\"></div>\n      </div>\n    </div>\n    <div class=\"form-group required review-comments-form-group\">\n      <label class=\"control-label col-sm-3\">Review Comments</label>\n      <div class=\"col-sm-6\">\n        <textarea name=\"enforcementActionReviewComments\" class=\"review-comments form-control\"\n          placeholder=\"Review Comments\" rows=\"10\" data-parsley-required=\"true\"\n          data-parsley-errors-container=\".review-comments-error-container\"></textarea>\n        <div class=\"review-comments-error-container\"></div>\n      </div>\n    </div>\n    <div class=\"row\">\n      <div class=\"col-sm-6 col-sm-offset-3\">\n        <a class=\"btn btn-default btn-cancel\" href=\"#nondep/dashboard\">Cancel</a>\n        <button class=\"btn btn-primary btn-submit\">Submit</button>\n        <div class=\"task-actions\"></div>\n      </div>\n    </div>\n  </form>\n</div>";
},"useData":true});