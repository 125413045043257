/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Backbone = require('backbone');
var Marionette = require('backbone.marionette');
var Radio = require('backbone.radio');
var _ = require('underscore');

var UIUtil = require('psa-core/util/ui');

var modelGet = require('common/util/modelGet');
var userHasRole = require('common/util/userHasRole');
var ContentServerLinkView = require('common/views/ContentServerLinkView');
var SideNavSectionView = require('common/views/SideNavSectionView');
var BreadcrumbView = require('common/views/BreadcrumbView');
var DropdownView = require('common/views/DropdownView');
var HeaderBarView = require('common/views/HeaderBarView');
var CancelFinancialStatementTask = require('../services/CancelFinancialStatementTask.js');

var ConfirmationModalView = require('common/views/ConfirmationModalView');

var SecOrganizationLicenseDetailsSectionView = require('./SecOrganizationLicenseDetailsSectionView');

var tmplSecOrganizationLicenseDashboardView = require('../templates/SecOrganizationLicenseDashboardView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplSecOrganizationLicenseDashboardView,

  className: 'sec-organization-license-dashboard-view container-fluid m-b-lg',

  ui: {
    organizationLicenseWrapper: '.organization-license-wrapper',
    csLinkContainer: '.cs-link-container',
    actionDropdown: '.dropdown-container',
    breadcrumb: '.breadcrumb-container',
    headerBar: '.header-bar-container'
  },

  regions: {
    organizationLicenseWrapper: '@ui.organizationLicenseWrapper',
    csLinkContainer: '@ui.csLinkContainer',
    actionDropdown: '@ui.actionDropdown',
    breadcrumb: '@ui.breadcrumb',
    headerBar: '@ui.headerBar'
  },

  onBeforeShow: function() {
    var identifiers = this.model.get('identifiers') || {};
    var license = this.model.get('license') || {};
    var licenseId = this.model.get('licenseId') || {};
    var organizationId = modelGet(this.model, 'organizationId');
    var licenseType = license.type;
    var financialTask = this.model.get('license').financialTask || {};

    var headerBarColumns = [
      {
        name: 'CRDNumber',
        label: 'CRD Number',
        minWidth: '160px',
        maxWidth: '200px'
      }
    ];

    identifiers.title = 'License';
    this.showChildView(
      'headerBar',
      new HeaderBarView({
        columns: new Backbone.Collection(headerBarColumns),
        model: new Backbone.Model(identifiers)
      })
    );

    this.showChildView(
      'csLinkContainer',
      new ContentServerLinkView({
        model: new Backbone.Model({
          buttonText: 'Open License Folder',
          contentServerId: license.CSFolderId || identifiers.CSFolderId
        })
      })
    );

    var dropDownOptions = [
      {
        text: 'Edit License',
        link: '#sec/organization/' + organizationId + '/license/' + licenseId + '/edit'
      },
      {
        isDivider: true
      },
      {
        text: 'Add License Information Detail',
        onClick: this.onClickAddLicenseInfo.bind(this)
      },
      {
        text: 'Add License Address',
        onClick: this.onClickAddAddress.bind(this)
      },
      {
        text: 'Add Payment - Non FINRA Member Details',
        onClick: this.onClickAddPayment.bind(this)
      },
      {
        text: 'Add Financial Details',
        link: '#sec/organization/' + organizationId + '/license/' + licenseId + '/new-financials'
      },
      {
        text: 'Add Investigation/Inquiry',
        link: '#sec/organization/' + organizationId + '/license/' + licenseId + '/new-investigation-inquiry'
      }
    ];

    var allowNewExam =
      userHasRole('SC - Chief Examiner') || userHasRole('SC - Exam Director') || userHasRole('SC - Exam Clerical');

    if (allowNewExam) {
      dropDownOptions = dropDownOptions.concat([
        {
          isDivider: true
        },
        {
          text: 'Authorize Exam',
          link: '#sec/organization/' + organizationId + '/license/' + licenseId + '/new-exam'
        }
      ]);
    }

    var roles = _.some(['SC - Chief Accounting'], userHasRole);
    var self = this;

    if (roles) {
      _.each(financialTask, function(task) {
        console.log(task)
        if (task.workflowstatusID === '1' || task.workflowstatusID === '3') {
          var id = task.id;
          dropDownOptions.push(
            {
              isDivider: true
            },
            {
              text: 'End Financial Statement Workflow ' + task.createdDate,
              onClick: function() {
                self.onClickEndFinancialStatementTask(id);
              }
            }
          );
        }
      });
    }

    this.showChildView(
      'actionDropdown',
      new DropdownView({
        rightAlign: true,
        model: new Backbone.Model({
          name: 'License Actions',
          options: dropDownOptions
        })
      })
    );

    this.showChildView(
      'breadcrumb',
      new BreadcrumbView({
        breadcrumbs: [
          {
            link: '#sec/dashboard',
            text: 'Home'
          },
          {
            link: '#sec/organization/' + organizationId + '/dashboard',
            text: identifiers.applicantLegalName
          },
          {
            text: licenseType
          }
        ]
      })
    );

    var sideNavTabs = [
      {
        id: 'license',
        title: 'License',
        view: new SecOrganizationLicenseDetailsSectionView({
          model: new Backbone.Model(this.model.get('license'))
        })
      },
      {
        id: 'licenseInfotmation',
        title: 'License Information List',
        view: Radio.channel('sc').request('build:license-info-view', {
          organizationId: organizationId,
          objectId: licenseId,
          objectTypeId: 'ORG-LICENSE'
        })
      },
      {
        id: 'address',
        title: 'Address List',
        view: Radio.channel('sc').request('build:license-address-view', {
          organizationId: organizationId,
          objectId: licenseId,
          objectTypeId: 'ORG-LICENSE'
        })
      },
      {
        id: 'payments',
        title: 'Payments - Non FINRA Member List',
        view: Radio.channel('sc').request('build:license-nonfinra-payment-member-view', {
          organizationId: organizationId,
          objectId: licenseId,
          objectTypeId: 'ORG-LICENSE'
        })
      },
      {
        id: 'financial',
        title: 'Financials',
        view: Radio.channel('sc').request(
          'build:organization-license-financials-section-view',
          organizationId,
          licenseId
        )
      },
      {
        id: 'exams',
        title: 'Exams',
        view: Radio.channel('sc').request('build:organization-license-exams-section-view', {
          organizationId: organizationId,
          organizationLicenseId: licenseId
        })
      },
      {
        id: 'invinq',
        title: 'Investigations/Inquiries',
        view: Radio.channel('sc').request('build:related-inv-inq-section-view', {
          objectId: organizationId,
          objectTypeId: 'ORGANIZATION',
          objectLicenseId: licenseId
        })
      },

      {
        id: 'notes',
        title: 'Notes',
        view: Radio.channel('sc').request('build:notes-view', {
          objectId: licenseId,
          objectTypeId: 'ORG-LICENSE'
        })
      },
      {
        id: 'auditLog',
        title: 'Audit Log',
        view: Radio.channel('sc').request('build:audit-log-view', {
          objectId: licenseId,
          objectTypeId: 'ORG-LICENSE'
        })
      }
    ];

    this.organization = new SideNavSectionView({
      collection: new Backbone.Collection(sideNavTabs)
    });

    this.showChildView('organizationLicenseWrapper', this.organization);
  },

  onClickAddLicenseInfo: function(e, model) {
    var self = this;
    var modalViewPromise = Radio.channel('sc').request('build:license-info-modal-view', {
      objectId: self.model.get('licenseId'),
      objectTypeId: self.options.objectTypeId
    });
    UIUtil.showModal({
      title: 'Add License Information Details',
      view: modalViewPromise
    }).done(function(wasSuccessful) {
      if (wasSuccessful) {
        Backbone.history.loadUrl();
      }
    });
  },

  onClickAddAddress: function() {
    var self = this;
    var modalViewPromise = Radio.channel('sc').request('build:license-address-modal-view', {
      objectId: self.model.get('licenseId'),
      objectTypeId: self.options.objectTypeId
    });
    UIUtil.showModal({
      title: 'Add License Address',
      view: modalViewPromise
    }).done(function(wasSuccessful) {
      if (wasSuccessful) {
        Backbone.history.loadUrl();
      }
    });
  },

  onClickAddPayment: function() {
    var self = this;
    var modalViewPromise = Radio.channel('sc').request('build:license-nonfinra-payment-member-modal-view', {
      objectId: self.model.get('licenseId'),
      objectTypeId: self.options.objectTypeId
    });
    UIUtil.showModal({
      title: 'Add Payment - Non FINRA Member Details',
      view: modalViewPromise
    }).done(function(wasSuccessful) {
      if (wasSuccessful) {
        Backbone.history.loadUrl();
      }
    });
  },

  onClickEndFinancialStatementTask: function(id) {
    var message = 'Are you sure you want to End this Task?';
    UIUtil.showModal({
      title: 'Confirm End Financial Statement Task',
      view: new ConfirmationModalView({
        confirmText: 'End Task',
        model: new Backbone.Model({
          message: message,
          onConfirm: function() {
            return CancelFinancialStatementTask(id);
          },
          onConfirmDone: function() {
            Backbone.history.loadUrl();
          }
        })
      })
    });
  }
});
