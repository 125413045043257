/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var moment = require('moment');

var formatAsDollars = require('common/util/formatAsDollars');
var cleanUpResponse = require('common/util/cleanUpResponse');
var _ = require('underscore');

module.exports = function(id) {
  var deferred = $.Deferred();

  $.soap({
    body: {
      GetOrganizationLicenseFinancials: {
        $xmlns: 'http://dobs.pa.gov/sc/organizationlicense/bpm/1.0',
        ID: id
      }
    },
    blueprint: {
      id: '$..ID',
      CSFolderId: '$..CSFolderID',
      organizationId: '$..OrganizationID',
      organizationLicenseId: '$..OrganizationLicenseID',
      financialYear: '$..FinancialYear',
      fiscalYearEnd: '$..FiscalYearEnd',
      isFinancialReportReq: '$..IsFinancialReportReq',
      FSDueDate: '$..FSDueDate',
      FSFiledDate: '$..FSFiledDate',
      FSAssignedTo: '$..FSAssignedTo',
      FSClearedDate: '$..FSClearedDate',
      FSClearedBy: '$..FSClearedBy',
      FSAssignedToName: '$..FSAssignedToName',
      FSClearedByName: '$..FSClearedByName',
      isCustody: '$..IsCustody',
      custodyDate: '$..CustodyDate',
      isAuditReq: '$..IsAuditReq',
      isDiscretion: '$..IsDiscretion',
      discretionDate: '$..DiscretionDate',
      isSuretyBondOnFile: '$..IsSuretyBondOnFile',
      suretyBondAmount: '$..SuretyBondAmount',
      netWorthReqAmount: '$..NetWorthReqAmount',
      netWorthCalculated: '$..NetWorthCalculated'
    }
  })
    .done(function(response) {
      cleanUpResponse(response);

      if (response.FSDueDate) {
        response.FSDueDate = moment(response.FSDueDate, 'YYYY-MM-DD').format('MM/DD/YYYY');
      }
      if (response.FSFiledDate) {
        response.FSFiledDate = moment(response.FSFiledDate, 'YYYY-MM-DD').format('MM/DD/YYYY');
      }
      if (response.FSClearedDate) {
        response.FSClearedDate = moment(response.FSClearedDate, 'YYYY-MM-DD').format('MM/DD/YYYY');
      }
      if (response.custodyDate) {
        response.custodyDate = moment(response.custodyDate, 'YYYY-MM-DD').format('MM/DD/YYYY');
      }
      if (response.discretionDate) {
        response.discretionDate = moment(response.discretionDate, 'YYYY-MM-DD').format('MM/DD/YYYY');
      }

      if  (_.isNull(response.fiscalYearEnd) || (_.isNull(response.financialYear))|| (response.fiscalYearEnd === '/')|| (response.financialYear=== '0')) 
      {
        response.fiscalYearEnd = '';
      ;}
      if (response.fiscalYearEnd){
        response.fiscalYearEnd = moment(response.fiscalYearEnd, 'MM/DD/YYYY').format('MM/DD');
        response.fiscalYearEnd = (response.fiscalYearEnd + '/' + response.financialYear);
      }
           
      response.suretyBondAmountDisplay = formatAsDollars(parseFloat(response.suretyBondAmount));
      response.netWorthReqAmountDisplay = formatAsDollars(parseFloat(response.netWorthReqAmount));
      response.netWorthCalculatedDisplay = formatAsDollars(parseFloat(response.netWorthCalculated));

      deferred.resolve(response);
    })
    .fail(function(err) {
      deferred.reject();
    });

  return deferred.promise();
};
