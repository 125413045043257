/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Backbone = require('backbone');

var UIUtil = require('psa-core/util/ui');
var GridView = require('psa-marionette/views/GridView');

var ConfirmationModalView = require('common/views/ConfirmationModalView');
var MultiButtonCell = require('common/backgrid/MultiButtonCell');
var ListCell = require('common/backgrid/ListCell');
var MomentCell = require('common/backgrid/MomentCell');

var SubmitPortfolio = require('../services/SubmitPortfolio');

require('../styles/CorpFinInvPortfolioSectionView.css');

module.exports = GridView.extend({
  className: 'corp-fin-inv-portfolio-section-view',

  onBeforeRender: function() {
    if (GridView.prototype.onBeforeRender) {
      GridView.prototype.onBeforeRender.apply(this.arguments);
    }

    var isReadOnly = this.options.isReadOnly;

    this.options.columns = [
      {
        name: 'portfolioName',
        label: 'Portfolio Name',
        cell: 'string',
        headerCell: 'custom',
        width: 35,
        editable: false
      },
      {
        name: 'portfolioDate',
        label: 'Portfolio Date',
        cell: MomentCell.MomentCell,
        formatter: MomentCell.MomentDateFormatter.extend({
          emptyValue: '--'
        }),
        sortValue: MomentCell.MomentSortValue,
        headerCell: 'custom',
        width: 20,
        editable: false
      },
      {
        name: 'portfolioClasses',
        label: 'Portfolio Classes',
        cell: ListCell,
        headerCell: 'custom',
        width: 35,
        editable: false,
        sortable: false
      },
      {
        name: 'editDeleteMultiButtonCell',
        label: '',
        width: 10,
        cell: MultiButtonCell.extend({
          buttons: [
            {
              name: '',
              labelClass: 'fa fa-edit',
              buttonClass: 'btn-primary btn-sm edit-inv-portfolio',
              href: function(model) {
                return '#corpfin/investment/' + model.get('recordId') + '/portfolio/' + model.get('id') + '/edit';
              },
              renderable: function() {
                return !isReadOnly;
              }
            },
            {
              name: '',
              labelClass: 'fa fa-trash',
              buttonClass: 'btn-danger btn-sm delete-inv-portfolio',
              onClick: this.onClickDeletePortfolio.bind(this),
              renderable: function() {
                return !isReadOnly;
              }
            }
          ]
        }),
        editable: false,
        sortable: false
      }
    ];
    this.options.emptyText = 'No Investment Portfolios';
  },

  onClickDeletePortfolio: function(e, model) {
    e.preventDefault();
    var self = this;
    var message = 'Are you sure you want to delete this portfolio?';
    UIUtil.showModal({
      title: 'Confirm Deleting Portfolio',
      view: new ConfirmationModalView({
        confirmText: 'Delete Portfolio',
        model: new Backbone.Model({
          message: message,
          onConfirm: function() {
            var portfolioId = model.get('id');
            return SubmitPortfolio({ portfolioId: portfolioId, isDeleted: 1 });
          },
          onConfirmDone: function() {
            self.collection.remove(model);
          }
        })
      })
    });
  }
});
