/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */
var Syphon = require('backbone.syphon');

var Marionette = require('backbone.marionette');

var parsleyUtil = require('common/util/parsleyUtil');

var tmplConSerComplaintFormServiceMemberView = require('../templates/ConSerComplaintFormServiceMemberView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplConSerComplaintFormServiceMemberView,

  className: 'nondep-entity-form-section-books-and-records-view',

  ui: {
    serviceMemberForm: '.service-member-form',
    isVeteranServiceMember: '[name="isVeteranServiceMember"]',
    serviceMemberStatus: '[name="serviceMemberStatus"]'
  },

  behaviors: {
    RadioGroupToggleBehavior: {
      radios: function() {
         return [{
          target: '.service-description',
          radioGroup: '[name="isVeteranServiceMember"]',
          valueToShow: '1'
        }];
      }
    }
  },

  events: {
    'change @ui.isVeteranServiceMember': 'defaultToVeteran'
  },

  defaultToVeteran: function() {
    if (this.ui.isVeteranServiceMember[0].checked) {
      if (this.model.get('serviceMemberStatus') === '1') {
        this.ui.serviceMemberStatus.val(2);
      }
    }
  },

  onBeforeShow: function() {
    Syphon.deserialize(this, this.model.toJSON());
  },

  validate: function(config) {
    this.ui.serviceMemberForm.parsley().reset();

    if (config.type === 'save') {
      // Turn off required field validation for this form
      parsleyUtil.disableRequiredValidation(this.ui.serviceMemberForm);
    } else {
      // Turn on required field validation for this form
      parsleyUtil.enableRequiredValidation(this.ui.serviceMemberForm);
    }

    var validationPromises = [this.ui.serviceMemberForm.parsley().whenValidate()];

    return $.when.apply(this, validationPromises);
  },

  getFormData: function() {
    var formData = Syphon.serialize(this.ui.serviceMemberForm);

    if (formData.isVeteranServiceMember === 0) {
      formData.serviceMemberStatus = 1;
    }

    return formData;
  }
});
