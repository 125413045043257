/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var moment = require('moment');

var cleanUpResponse = require('common/util/cleanUpResponse');

module.exports = function(locationId) {
  var defer = $.Deferred();

  $.soap({
    body: {
      GetLocation: {
        $xmlns: 'http://dobs.pa.gov/dp/location/bpm/1.0',
        LocationID: locationId
      }
    },
    blueprint: {
      entityId: '$..EntityID',
      openedDate: '$..OpenedDate',
      closedDate: '$..ClosedDate',
      statusId: '$..StatusID',
      status: '$..Status',
      officeTypeId: '$..OfficeTypeID',
      officeType: '$..OfficeType',
      activityId: '$..ActivityID',
      activity: '$..Activity',
      locationTypeId: '$..LocationTypeID',
      locationType: '$..LocationType',
      relocatedTo: '$..RelocatedTo',
      addressId: '$..AddressID',
      addressLine1: '$..AddressLine1',
      addressLine2: '$..AddressLine2',
      county: '$..County',
      city: '$..City',
      state: '$..State',
      postalCode: '$..PostalCode',
      country: '$..Country',
      comments: '$..Comments'
    }
  })
    .done(function(response) {
      cleanUpResponse(response);
      if (!response.openedDate) {
        response.openedDate = '';
      } else {
        response.openedDate = moment(response.openedDate, 'YYYY-MM-DD').format('MM/DD/YYYY');
      }

      if (response.closedDate) {
        response.closedDate = moment(response.closedDate, 'YYYY-MM-DD').format('MM/DD/YYYY');
      }

      defer.resolve(response);
    })
    .fail(function(err) {
      defer.reject(err);
    });

  return defer.promise();
};
