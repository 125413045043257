/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var Marionette = require('backbone.marionette');
var Syphon = require('backbone.syphon');

var AddressView = require('common/views/AddressView');
var AddressDisplayView = require('common/views/AddressDisplayView');

require('../styles/LicenseContactView.css');
var tmplLicenseContactView = require('../templates/LicenseContactView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplLicenseContactView,

  className: function() {
    var baseClass = 'contact-view panel';
    if (this.model.get('isDeleted') === '1') {
      return baseClass + ' panel-danger';
    } else {
      return baseClass + ' panel-info';
    }
  },

  ui: {
    address: '.address',
    oldAddress: '.old-address',
    copyContact: '.copy-contact',
    removeContact: '.remove-contact',
    contactNumber: '.contact-number',
    contactType: '[name="licenseContactTypeId"]',
    conatctTypeSelect: '.contact-type-select',
    contactTitle: '.contact-title',
    contactTitleInput: '.contact-title-input',
    workPhoneNumber: '.work-phone'
  },

  regions: {
    address: '@ui.address',
    oldAddress: '@ui.oldAddress'
  },

  triggers: {
    'click @ui.copyContact': 'copy:contact',
    'click @ui.removeContact': 'remove:contact'
  },

  behaviors: {
    PhoneFormatBehavior: {}
  },

  templateHelpers: function() {
    var amendmentChanges;
    var showChanges = this.options.isReadOnly && this.options.viewMode !== 'ext' && this.model.get('isUpdate');
    if (showChanges) {
      amendmentChanges = this.getAmendmentChanges();
    }
    return {
      contactTypes: this.options.contactTypes,
      modelId: this.model.cid,
      viewMode: this.options.viewMode,
      isDeleted: this.model.get('isDeleted') === '1',
      isNew: this.model.get('isNew'),
      isUpdate: this.model.get('isUpdate'),
      amendmentChanges: amendmentChanges
    };
  },

  onBeforeShow: function() {
    var showChanges = this.options.isReadOnly && this.options.viewMode !== 'ext' && this.model.get('isUpdate');
    var amendmentChanges;
    if (showChanges) {
      amendmentChanges = this.getAmendmentChanges();
    }

    this.showChildView(
      'address',
      new AddressView({
        horizontal: false,
        startingCountry: this.model.get('country'),
        startingPostalCode: this.model.get('postalCode'),
        startingCounty: this.model.get('county'),
        isRequired: true,
        requiredFields: {
          addressLine1: true
        }
      })
    );

    var oldContact = this.model.get('oldContact') || {};
    if (showChanges && oldContact && amendmentChanges.address) {
      this.showChildView('oldAddress', new AddressDisplayView({ model: new Backbone.Model(oldContact.address) }));
    }

    Syphon.deserialize(this, this.model.toJSON());
  },

  getFormData: function() {
    var formData = Syphon.serialize(this);
    formData.licenseContactId = this.model.get('id');
    return formData;
  },

  getSelectedContactType: function() {
    return this.ui.contactType.val();
  },

  getAmendmentChanges: function() {
    var oldContact = this.model.get('oldContact') || {};

    var appAddress = {
      addressLine1: this.model.get('addressLine1'),
      addressLine2: this.model.get('addressLine2'),
      addressLine3: this.model.get('addressLine3'),
      city: this.model.get('city'),
      state: this.model.get('state'),
      postalCode: this.model.get('postalCode'),
      country: this.model.get('country')
    };

    // When an existing contact was updated and application is being reviewed internally,
    // check that an old value exists for each Contact property and that new value is different
    return {
      contactType:
        oldContact.licenseContactTypeId && this.model.get('licenseContactTypeId') !== oldContact.licenseContactTypeId,
      title: oldContact.title && this.model.get('title') !== oldContact.title,
      firstName: oldContact.firstName && this.model.get('firstName') !== oldContact.firstName,
      lastName: oldContact.lastName && this.model.get('lastName') !== oldContact.lastName,
      homePhone: oldContact.homePhoneNumber && this.model.get('homePhoneNumber') !== oldContact.homePhoneNumber,
      cellPhone: oldContact.cellPhoneNumber && this.model.get('cellPhoneNumber') !== oldContact.cellPhoneNumber,
      company: oldContact.company && this.model.get('company') !== oldContact.company,
      workPhone: oldContact.workPhoneNumber && this.model.get('workPhoneNumber') !== oldContact.workPhoneNumber,
      workPhoneExt:
        oldContact.workPhoneExtension && this.model.get('workPhoneExtension') !== oldContact.workPhoneExtension,
      fax: oldContact.fax && this.model.get('fax') !== oldContact.fax,
      email: oldContact.email && this.model.get('email') !== oldContact.email,
      address: !_.isEqual(appAddress, oldContact.address)
    };
  }
});
