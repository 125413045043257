/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2020] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Marionette = require('backbone.marionette');
var Syphon = require('backbone.syphon');
var _ = require('underscore');

var tmplDepOfficerFormView = require('../templates/DepOfficerFormView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplDepOfficerFormView,

  className: 'dep-officer-form-view-view',

  ui: {
    officerTypeSelect: '.officer-type-select',
    termStartDate: '.term-start-date',
    termEndDate: '.term-end-date'
  },

  onBeforeShow: function() {
    var officers = this.model.get('entityofficers');
    var officeredit = this.model.get('officer') || {};

    var self = this;
    var coCeoCount = 0;

    Syphon.deserialize(this, this.model.get('officer'));

    _.each(officers, function(officer) {
      if (officer.officerTypeId !== '37') {
        if (officer.officerTypeId === '5' && coCeoCount !== 1) {
          coCeoCount++;
        } else {
          if (officer.officerTypeId !== officeredit.officerTypeId) {
            if (officer.statusId === '1') {
              self.ui.officerTypeSelect.find('option[value="' + officer.officerTypeId + '"]').remove();
            }
          }
        }
      }
    });

    this.ui.termStartDate.datepicker({});
    this.ui.termEndDate.datepicker({});
  },

  getFormData: function() {
    return Syphon.serialize(this);
  }
});
