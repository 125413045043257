/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Marionette = require('backbone.marionette');
var Syphon = require('backbone.syphon');

var tmplDepAuditDetailsSectionView = require('../templates/DepAuditDetailsSectionView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplDepAuditDetailsSectionView,

  className: 'dep-audit-details-section-view',

  templateHelpers: function() {
    var categoryId = this.model.get('entityIdentifiers').categoryId;
    var auditTypeId = this.model.get('auditTypeId');
    var auditTrustTypeId = this.model.get('auditTrustTypeId');
    var opinionId = this.model.get('opinionId');

    var isBank = categoryId === '1';
    var isTrust = categoryId === '3';
    var internalAudit = opinionId === '2' || auditTrustTypeId === '2';

    var showTrustAuditType =
      auditTypeId === '22' ||
      (categoryId === '1' && auditTypeId === '3') ||
      (categoryId === '3' && auditTypeId === '22');

    var showOpinionDetails = isTrust || showTrustAuditType;

    var showOpinion = !isTrust;
    if (!isTrust) {
      showOpinion = !showOpinionDetails;
    }

    var showManagementLetter;
    var showResponseReceived;

    if ((isBank && auditTypeId === '1') || (isTrust && auditTypeId === '21')) {
      showOpinion = true;
      showManagementLetter = true;
      showResponseReceived = true;
      showTrustAuditType = false;
      showOpinionDetails = false;
    }

    if (isBank && auditTypeId === '2') {
      showResponseReceived = true;
    }

    return {
      isTrust: isTrust,
      internalAudit: internalAudit,
      showTrustAuditType: showTrustAuditType,
      showOpinionDetails: showOpinionDetails,
      showOpinion: showOpinion,
      showManagementLetter: showManagementLetter,
      showResponseReceived: showResponseReceived
    };
  },

  onBeforeShow: function() {
    Syphon.deserialize(this, this.model.toJSON());
  }
});
