/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Backbone = require('backbone');
var Radio = require('backbone.radio');

var UIUtil = require('psa-core/util/ui');
var GridView = require('psa-marionette/views/GridView');

var ConfirmationModalView = require('common/views/ConfirmationModalView');
var MomentCell = require('common/backgrid/MomentCell');
var ParagraphCell = require('common/backgrid/ParagraphCell');
var MultiButtonCell = require('common/backgrid/MultiButtonCell');

var SubmitProgressReport = require('../services/SubmitProgressReport');

module.exports = GridView.extend({
  className: 'dep-enforcement-progress-reports-section-view',

  onBeforeRender: function() {
    if (GridView.prototype.onBeforeRender) {
      GridView.prototype.onBeforeRender.apply(this.arguments);
    }

    this.options.columns = [
      {
        name: 'year',
        label: 'Year',
        cell: 'string',
        headerCell: 'custom',
        width: 12,
        editable: false
      },
      {
        name: 'quarter',
        label: 'Quarter',
        cell: 'string',
        headerCell: 'custom',
        width: 12,
        editable: false
      },
      {
        name: 'dueDate',
        label: 'Due Date',
        cell: MomentCell.MomentCell,
        formatter: MomentCell.MomentDateFormatter.extend({
          emptyValue: '--'
        }),
        sortValue: MomentCell.MomentSortValue,
        headerCell: 'custom',
        width: 12,
        editable: false
      },
      {
        name: 'submissionDate',
        label: 'Received Date',
        cell: MomentCell.MomentCell,
        formatter: MomentCell.MomentDateFormatter.extend({
          emptyValue: '--'
        }),
        sortValue: MomentCell.MomentSortValue,
        headerCell: 'custom',
        width: 12,
        editable: false
      },
      {
        name: 'acknowledgementDate',
        label: 'Acknowledgment Date',
        cell: MomentCell.MomentCell,
        formatter: MomentCell.MomentDateFormatter.extend({
          emptyValue: '--'
        }),
        sortValue: MomentCell.MomentSortValue,
        headerCell: 'custom',
        width: 12,
        editable: false
      },
      {
        name: 'status',
        label: 'Status',
        cell: 'string',
        headerCell: 'custom',
        width: 12,
        editable: false
      },
      {
        name: 'comments',
        label: 'Comments',
        cell: ParagraphCell,
        headerCell: 'custom',
        width: 15,
        editable: false,
        sortable: false
      },

      {
        name: '',
        label: '',
        width: 10,
        cell: MultiButtonCell.extend({
          buttons: [
            {
              name: '',
              labelClass: 'fa fa-edit',
              buttonClass: 'btn-primary btn-sm edit-progress-report',
              href: function(model) {
                var entityId = model.get('entityId');
                var enforcementActionId = model.get('enforcementActionId');
                var progressReportId = model.get('id');
                return (
                  '#dep/entity/' +
                  entityId +
                  '/enforcement/' +
                  enforcementActionId +
                  '/progress-report/' +
                  progressReportId +
                  '/edit'
                );
              }
            },
            {
              name: '',
              labelClass: 'fa fa-trash',
              buttonClass: 'btn-danger btn-sm delete-progress-report',
              onClick: this.onClickDeleteProgressReport.bind(this)
            }
          ]
        }),
        sortable: false,
        editable: false
      }
    ];
    this.options.emptyText = 'No Progress Reports';
  },

  onClickDeleteProgressReport: function(e, model) {
    e.preventDefault();
    var message = 'Are you sure you want to delete this progress report?';
    UIUtil.showModal({
      title: 'Confirm Deleting Progress Report',
      view: new ConfirmationModalView({
        confirmText: 'Delete Progress Report',
        model: new Backbone.Model({
          message: message,
          onConfirm: function() {
            var progressReportId = model.get('id');
            return SubmitProgressReport({ progressReportId: progressReportId, isDeleted: '1' });
          },
          onConfirmDone: function() {
            var entityId = model.get('entityId');
            var enforcementId = model.get('enforcementActionId');
            Radio.channel('navigate').trigger('show:int:dp:enforcement', entityId, enforcementId);
          }
        })
      })
    });
  }
});
