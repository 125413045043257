/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Backbone = require('backbone');
var Marionette = require('backbone.marionette');
var Radio = require('backbone.radio');
var Ladda = require('ladda');

var modelGet = require('common/util/modelGet');
var BreadcrumbView = require('common/views/BreadcrumbView');
var LicenseHeaderBarView = require('../../common/views/LicenseHeaderBarView');

var LicenseContactsSectionView = require('../../new-license/views/LicenseContactsSectionView');
var SubmitLicenseContacts = require('../services/SubmitLicenseContacts');

var tmplNonDepEditLicenseContactsView = require('../templates/NonDepEditLicenseContactsView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplNonDepEditLicenseContactsView,

  className: 'license-contacts-change-form-view container-fluid',

  ui: {
    headerBarContainer: '.header-bar-container',
    breadCrumbContainer: '.breadcrumb-container',
    licenseContactsSectionViewContainer: '.license-contacts-section-view-container',
    submitButton: '.submit-button'
  },

  regions: {
    headerBarContainer: '@ui.headerBarContainer',
    breadCrumbContainer: '@ui.breadCrumbContainer',
    licenseContactsSectionViewContainer: '@ui.licenseContactsSectionViewContainer'
  },

  events: {
    'click @ui.submitButton': 'onClickSubmit'
  },

  behaviors: {
    PhoneFormatBehavior: {}
  },

  onBeforeShow: function() {
    var entityName = this.model.get('entityName'),
      entityId = this.model.get('entityId'),
      licenseId = this.model.get('licenseId'),
      licenseContacts = this.model.get('licenseContacts'),
      licenseContactTypes = this.model.get('licenseContactTypes'),
      requiredLicenseContactTypes = this.model.get('requiredLicenseContactTypes'),
      licenseType = modelGet(this.model, 'licenseIdentifiers.licenseType'),
      licenseNumber = modelGet(this.model, 'licenseIdentifiers.licenseNumber'),
      existingContacts = modelGet(this.model, 'existingContacts');

    this.showChildView(
      'headerBarContainer',
      new LicenseHeaderBarView({
        model: new Backbone.Model(this.model.get('licenseIdentifiers'))
      })
    );

    this.showChildView(
      'breadCrumbContainer',
      new BreadcrumbView({
        breadcrumbs: [
          {
            text: 'Home',
            link: '#nondep/dashboard'
          },
          {
            text: entityName,
            link: '#nondep/entity/' + entityId + '/dashboard'
          },
          {
            text: 'License #' + licenseNumber + ' - ' + licenseType,
            link: '#nondep/entity/' + entityId + '/license/' + licenseId
          },
          {
            text: 'Change License Contacts'
          }
        ]
      })
    );

    this.licenseContactsSectionView = new LicenseContactsSectionView({
      viewMode: this.options.viewMode,
      model: new Backbone.Model({
        licenseContactTypes: licenseContactTypes,
        requiredContactTypes: requiredLicenseContactTypes,
        contacts: licenseContacts,
        existingContacts: existingContacts
      })
    });
    this.showChildView('licenseContactsSectionViewContainer', this.licenseContactsSectionView);
  },

  onClickSubmit: function(event) {
    event.preventDefault();
    var self = this;
    this.validate().done(function() {
      var appData = {
        entityId: self.model.get('entityId'),
        licenseId: self.model.get('licenseId'),
        contacts: self.getFormData()
      };
      var laddaContext = Ladda.create(self.ui.submitButton.get(0));
      laddaContext.start();
      SubmitLicenseContacts(appData)
        .done(function(response) {
          Radio.channel('navigate').trigger(
            'show:int:nd:license-dashboard',
            self.model.get('entityId'),
            self.model.get('licenseId')
          );
        })
        .fail(function(err) {
          laddaContext.stop();
        });
    });
  },

  validate: function() {
    var config = {};
    return this.licenseContactsSectionView.validate(config);
  },

  getFormData: function() {
    return this.licenseContactsSectionView.getFormData();
  }
});
