/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var moment = require('moment');

var momentUtil = require('common/util/momentUtil');
var cleanUpResponse = require('common/util/cleanUpResponse');

module.exports = function(data) {
  var deferred = $.Deferred();

  $.soap({
    body: {
      GetTimesheet: {
        $xmlns: 'http://dobs.pa.gov/tt/timesheet/bpm/1.0',
        TimesheetID: data.timesheetId,
        DepartmentID: data.departmentId,
        PeriodID: data.periodId
      }
    },
    blueprintArrays: [
      'GetTimesheetResponse.TimesheetEntries.TT_TimesheetEntry',
      'GetTimesheetResponse.ExpensesEntries.TT_ExpenseEntry',
      'GetTimesheetResponse.Exams.ND_Exam',
      'GetTimesheetResponse.ExamMetaData.ND_Exam',
      'GetTimesheetResponse.Exams.DP_EntityExam',
      'GetTimesheetResponse.ExamMetaData.DP_EntityExam',
      'GetTimesheetResponse.Exams.SC_Exam',
      'GetTimesheetResponse.InvInqs.SC_InvestigationInquiry'
    ],
    blueprint: {
      timesheetId: '$.GetTimesheetResponse.Timesheet.TT_Timesheet.ID',
      periodId: '$..PeriodID',
      periodEndDate: '$..PeriodEndDate',
      periodStartDate: '$..PeriodStartDate',
      year: '$..Year',
      departmentId: '$..DepartmentID',
      statusId: '$..StatusID',
      status: '$..Status',
      comments: '$..Comments',
      assignedToUserId: '$..AssignedToUserID',
      assignedToUsername: '$..AssignedToUsername',
      assignedToFirstName: '$..AssignedToFirstName',
      assignedToLastName: '$..AssignedToLastName',
      submittedByUserId: '$..SubmittedByUserID',
      submittedByFirstName: '$..SubmittedByFirstName',
      submittedByLastName: '$..SubmittedByLastName',
      submittedByUsername: '$..SubmittedByUsername',
      submittedOn: '$..SubmittedOn',
      reviewedByUserId: '$..ReviewedByUserID',
      reviewedOn: '$..ReviewedOn',
      isDeleted: '$..IsDeleted',
      createdDate: '$..CreatedDate',
      createdBy: '$..CreatedBy',
      modifiedDate: '$..ModifiedDate',
      modifiedBy: '$..ModifiedBy',
      timesheetEntries: [
        '$..TT_TimesheetEntry',
        {
          id: '$..ID',
          timesheetId: '$..TimesheetID',
          categoryId: '$..CategoryID',
          category: '$..Category',
          objectTypeId: '$..ObjectTypeID',
          objectId: '$..ObjectID',
          worksiteId: '$..WorksiteID',
          timeEntries: {
            startDayPlus1: '$..StartDayPlus1',
            startDayPlus2: '$..StartDayPlus2',
            startDayPlus3: '$..StartDayPlus3',
            startDayPlus4: '$..StartDayPlus4',
            startDayPlus5: '$..StartDayPlus5',
            startDayPlus8: '$..StartDayPlus8',
            startDayPlus9: '$..StartDayPlus9',
            startDayPlus10: '$..StartDayPlus10',
            startDayPlus11: '$..StartDayPlus11',
            startDayPlus12: '$..StartDayPlus12'
          }
        }
      ],
      expensesEntries: [
        '$..TT_ExpenseEntry',
        {
          id: '$..ID',
          timesheetId: '$..TimesheetID',
          objectTypeId: '$..ObjectTypeID',
          objectId: '$..ObjectID',
          expenseAmount: '$..ExpenseAmount'
        }
      ],
      ndOpenExams: [
        '$.GetTimesheetResponse.Exams.ND_Exam',
        {
          objectId: '$..ID',
          examDate: '$..ExamDate',
          entityId: '$..EntityID',
          entityName: '$..EntityName',
          examStatusId: '$..ExamStatusID'
        }
      ],
      ndExamMetaData: [
        '$.GetTimesheetResponse.ExamMetaData.ND_Exam',
        {
          objectId: '$..ID',
          examDate: '$..ExamDate',
          entityId: '$..EntityID',
          entityName: '$..EntityName',
          examStatusId: '$..ExamStatusID'
        }
      ],
      dpOpenExams: [
        '$.GetTimesheetResponse.Exams.DP_EntityExam',
        {
          objectId: '$..ID',
          signoffITDate: '$..SignoffITDate',
          signoffTrustDate: '$..SignoffTrustDate',
          signoffSSDate: '$..SignoffSSDate',
          entityId: '$..EntityID',
          entityName: '$..EntityName',
          examStatusId: '$..ExamStatusID',
          examStartDate: '$..ExamStartDate'
        }
      ],
      dpExamMetaData: [
        '$.GetTimesheetResponse.ExamMetaData.DP_EntityExam',
        {
          objectId: '$..ID',
          signoffITDate: '$..SignoffITDate',
          signoffTrustDate: '$..SignoffTrustDate',
          signoffSSDate: '$..SignoffSSDate',
          entityId: '$..EntityID',
          entityName: '$..EntityName',
          examStatusId: '$..ExamStatusID'
        }
      ],
      scOpenExams: [
        '$.GetTimesheetResponse.Exams.SC_Exam',
        {
          objectId: '$..ID',
          openedDate: '$..OpenedDate',
          dbaName: '$..DBAName',
          examNumber: '$..ExamNumber'
        }
      ],
      scOpenInvInqs: [
        '$.GetTimesheetResponse.InvInqs.SC_InvestigationInquiry',
        {
          objectId: '$..ID',
          openedDate: '$..OpenedDate',
          isOrganization: '$..IsOrganization',
          dbaName: '$..DBAName',
          examStatusId: '$..ExamStatusID',
          firstName: '$..FirstName',
          middleInitial: '$..MiddleInitial',
          lastName: '$..LastName',
          caseNumber: '$..CaseNumber',
          category: '$..Category'
        }
      ],
      scExamMetaData: [
        '$.GetTimesheetResponse.Exams.SC_Exam',
        {
          objectId: '$..ID',
          openedDate: '$..OpenedDate',
          dbaName: '$..DBAName',
          examNumber: '$..ExamNumber'
        }
      ],
      scInvInqMetaData: [
        '$.GetTimesheetResponse.InvInqs.SC_InvestigationInquiry',
        {
          objectId: '$..ID',
          openedDate: '$..OpenedDate',
          isOrganization: '$..IsOrganization',
          dbaName: '$..DBAName',
          examStatusId: '$..ExamStatusID',
          firstName: '$..FirstName',
          middleInitial: '$..MiddleInitial',
          lastName: '$..LastName',
          caseNumber: '$..CaseNumber',
          category: '$..Category'
        }
      ]
    }
  })
    .done(function(response) {
      cleanUpResponse(response);

      var isNonDep = response.departmentId === '1000';
      var isDep = response.departmentId === '2000';
      var isSec = response.departmentId === '3000';

      var monthDayInboundDateConfig = {
        sourceFormat: 'YYYY-MM-DD',
        targetFormat: 'MM/DD',
        default: undefined
      };

      var objectInboundDateConfig = {
        sourceFormat: 'YYYY-MM-DD',
        targetFormat: 'MM/DD/YYYY',
        default: 'Not Started'
      };

      // Format all dates displayed on time tracking form
      response.payPeriod = {};
      var startMonday = moment(response.periodStartDate, 'YYYY-MM-DD')
        .add(1, 'days')
        .format('MMMM Do YYYY');
      var endFriday = moment(response.periodStartDate, 'YYYY-MM-DD')
        .add(12, 'days')
        .format('MMMM Do YYYY');
      response.payPeriod.payPeriodDisplay = startMonday + ' to ' + endFriday;

      var daysNeeded = [1, 2, 3, 4, 5, 8, 9, 10, 11, 12];

      _.each(daysNeeded, function(day) {
        var dateName = 'startDayPlus' + day + 'Display';
        var date = moment(response.periodStartDate, 'YYYY-MM-DD').add(day, 'days');
        response.payPeriod[dateName] = momentUtil.formatStr(date, monthDayInboundDateConfig);
      });

      response.submittedOn = momentUtil.formatStr(response.submittedOn, momentUtil.defaultInboundDateTimeConfig);

      // Format open objects (exam/inv/inq) for dropdown
      if (isNonDep) {
        if (response.ndOpenExams && response.ndOpenExams.length) {
          _.each(response.ndOpenExams, function(ndExam) {
            ndExam.examDate = momentUtil.formatStr(ndExam.examDate, objectInboundDateConfig);
            ndExam.objectDisplayName = ndExam.objectId + ' - ' + ndExam.entityName + ' - ' + ndExam.examDate;
          });
        }
      }

      if (isDep) {
        if (response.dpOpenExams && response.dpOpenExams.length) {
          _.each(response.dpOpenExams, function(dpExam) {
            if (dpExam.examStartDate) {
              dpExam.examStartDate = momentUtil.formatStr(dpExam.examStartDate, objectInboundDateConfig);
            } else {
              dpExam.examStartDate = 'No Start Date';
            }
            dpExam.objectDisplayName = dpExam.objectId + ' - ' + dpExam.entityName + ' - ' + dpExam.examStartDate;
          });
        }
      }

      if (isSec) {
        if (response.scOpenExams && response.scOpenExams.length) {
          _.each(response.scOpenExams, function(scExam) {
            scExam.openedDate = momentUtil.formatStr(scExam.openedDate, objectInboundDateConfig);
            scExam.objectTypeId = 'EXAM';
            scExam.objectDisplayName =
              scExam.objectTypeId + ' #' + scExam.examNumber + ' - ' + scExam.dbaName + ' - ' + scExam.openedDate;
          });
        }

        if (response.scOpenInvInqs && response.scOpenInvInqs.length) {
          _.each(response.scOpenInvInqs, function(scInvInq) {
            var scInvInqName;
            if (scInvInq.isOrganization === '1') {
              scInvInqName = scInvInq.dbaName;
            } else {
              scInvInqName = scInvInq.firstName + ' ' + scInvInq.lastName;
            }
            scInvInq.openedDate = momentUtil.formatStr(scInvInq.openedDate, objectInboundDateConfig);
            scInvInq.objectTypeId = 'INV-INQ';
            scInvInq.objectDisplayName =
              scInvInq.objectTypeId + ' #' + scInvInq.caseNumber + ' - ' + scInvInqName + ' - ' + scInvInq.openedDate;
          });
        }

        response.scOpenObjects = response.scOpenExams.concat(response.scOpenInvInqs);
        delete response.scOpenExams;
        delete response.scOpenInvInqs;
      }

      // Pre-format ND exam objects since they get mapped for time entries
      // and expense so they cant be done in timesheet enumeration
      if (isNonDep) {
        _.each(response.ndExamMetaData, function(ndExam) {
          ndExam.examDate = momentUtil.formatStr(ndExam.examDate, objectInboundDateConfig);
          ndExam.objectDisplayName = ndExam.objectId + ' - ' + ndExam.entityName + ' - ' + ndExam.examDate;
        });
      }

      // Map objects (exam/inv/inq) to timesheet entries
      var tempExam;
      var tempInvInq;
      _.each(response.timesheetEntries, function(timesheetEntry) {
        if (isNonDep) {
          tempExam = _.find(response.ndExamMetaData, function(ndExam) {
            return ndExam.objectId === timesheetEntry.objectId;
          });
          if (tempExam) {
            timesheetEntry.objectDisplayName = tempExam.objectDisplayName;
            timesheetEntry.objectId = tempExam.objectId;
          }
        }
        if (isDep) {
          tempExam = _.find(response.dpExamMetaData, function(dpExam) {
            return dpExam.objectId === timesheetEntry.objectId;
          });
          if (tempExam) {
            if (tempExam.signoffSSDate) {
              tempExam.examDate = momentUtil.formatStr(tempExam.signoffSSDate, objectInboundDateConfig);
            } else if (tempExam.signoffITDate) {
              tempExam.examDate = momentUtil.formatStr(tempExam.signoffITDate, objectInboundDateConfig);
            } else if (tempExam.signoffTrustDate) {
              tempExam.examDate = momentUtil.formatStr(tempExam.signoffTrustDate, objectInboundDateConfig);
            } else {
              tempExam.examDate = 'In Process';
            }

            tempExam.objectDisplayName = tempExam.objectId + ' - ' + tempExam.entityName + ' - ' + tempExam.examDate;

            timesheetEntry.objectDisplayName = tempExam.objectDisplayName;
            timesheetEntry.objectId = tempExam.objectId;
          }
        }

        if (isSec) {
          if (timesheetEntry.objectTypeId === 'EXAM') {
            tempExam = _.find(response.scExamMetaData, function(scExam) {
              return scExam.objectId === timesheetEntry.objectId;
            });
            if (tempExam) {
              tempExam.openedDate = momentUtil.formatStr(tempExam.openedDate, objectInboundDateConfig);
              tempExam.objectTypeId = 'EXAM';
              tempExam.objectDisplayName =
                tempExam.objectTypeId +
                ' #' +
                tempExam.examNumber +
                ' - ' +
                tempExam.dbaName +
                ' - ' +
                tempExam.openedDate;

              timesheetEntry.objectDisplayName = tempExam.objectDisplayName;
              timesheetEntry.objectId = tempExam.objectId;
              timesheetEntry.objectTypeId = tempExam.objectTypeId;
            }
          }

          if (timesheetEntry.objectTypeId === 'INV-INQ') {
            tempInvInq = _.find(response.scInvInqMetaData, function(scInvInq) {
              return scInvInq.objectId === timesheetEntry.objectId;
            });
            if (tempInvInq) {
              var scInvInqName;
              if (tempInvInq.isOrganization === '1') {
                scInvInqName = tempInvInq.dbaName;
              } else {
                scInvInqName = tempInvInq.firstName + ' ' + tempInvInq.lastName;
              }
              tempInvInq.openedDate = momentUtil.formatStr(tempInvInq.openedDate, objectInboundDateConfig);
              tempInvInq.objectTypeId = 'INV-INQ';
              tempInvInq.objectDisplayName =
                tempInvInq.objectTypeId +
                ' #' +
                tempInvInq.caseNumber +
                ' - ' +
                scInvInqName +
                ' - ' +
                tempInvInq.openedDate;

              timesheetEntry.objectDisplayName = tempInvInq.objectDisplayName;
              timesheetEntry.objectId = tempInvInq.objectId;
              timesheetEntry.objectTypeId = tempInvInq.objectTypeId;
            }
          }
        }
      });

      // Map exams to expenses (ND Only)
      _.each(response.expensesEntries, function(expenseEntry) {
        var tempExam = _.find(response.ndExamMetaData, function(ndExam) {
          return ndExam.objectId === expenseEntry.objectId;
        });
        if (tempExam) {
          expenseEntry.objectDisplayName = tempExam.objectDisplayName;
          expenseEntry.objectId = tempExam.objectId;
        }
      });

      deferred.resolve(response);
    })
    .fail(function(err) {
      deferred.reject();
    });

  return deferred.promise();
};
