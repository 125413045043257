/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Backbone = require('backbone');
var Marionette = require('backbone.marionette');

var callByNeed = require('common/util/callByNeed');
var ButtonsPanelCellButtonView = require('./ButtonsPanelCellButtonView');

require('./ButtonsPanelCellView.css');

module.exports = Marionette.CollectionView.extend({
  className: 'buttons-panel-cell-view',

  childView: ButtonsPanelCellButtonView,

  initialize: function(options) {
    this.column = options.model;
    this.dataModel = options.dataModel;
    this.collection = new Backbone.Collection(this.buttons);
    var renderable = callByNeed(this.model.get('renderable'), this, this.dataModel);
    if (renderable === false) {
      this.$el.addClass('collapse');
    }
  },

  attributes: function() {
    return {
      style: 'width:' + this.model.get('width') + '%'
    };
  },

  childViewOptions: function(model, index) {
    return {
      dataModel: this.dataModel,
      childIndex: index
    };
  }
});
