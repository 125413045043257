/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Marionette = require('backbone.marionette');
var Ladda = require('ladda');
var Radio = require('backbone.radio');
var Syphon = require('backbone.syphon');

var BreadcrumbView = require('common/views/BreadcrumbView');

var SubmitEntityLegalHoldStatus = require('../services/SubmitEntityLegalHoldStatus');

require('../styles/DepEntityEditLegalHoldView.css');
var tmplDepEntityEditLegalHoldView = require('../templates/DepEntityEditLegalHoldView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplDepEntityEditLegalHoldView,

  className: 'dep-entity-edit-legal-hold-view container-fluid m-b-lg',

  ui: {
    breadcrumb: '.breadcrumb-container',
    legalHoldForm: '.legal-hold-form',
    submit: '.btn-submit'
  },

  regions: {
    breadcrumb: '@ui.breadcrumb'
  },

  events: {
    'click @ui.submit': 'onClickSubmit'
  },

  onBeforeShow: function() {
    var entityId = this.model.get('entityId');

    this.showChildView(
      'breadcrumb',
      new BreadcrumbView({
        breadcrumbs: [
          {
            link: '#dep/dashboard',
            text: 'Home'
          },
          {
            text: this.model.get('entityName'),
            link: '#dep/entity/' + entityId + '/dashboard'
          },
          {
            text: 'Edit Legal Hold Status'
          }
        ]
      })
    );
  },

  onClickSubmit: function(e) {
    e.preventDefault();
    var entityId = this.model.get('entityId');

    this.validate().done(
      function() {
        var laddaContext = Ladda.create(this.ui.submit.get(0));
        laddaContext.start();
        var formData = Syphon.serialize(this);
        var newLegalHoldStatus = formData.placeLegalHold ? '1' : '0';
        SubmitEntityLegalHoldStatus({
          entityId: entityId,
          newLegalHoldStatus: newLegalHoldStatus
        })
          .done(function() {
            Radio.channel('navigate').trigger('show:int:dp:entity', entityId);
          })
          .fail(function() {
            laddaContext.stop();
          });
      }.bind(this)
    );
  },

  validate: function() {
    return this.ui.legalHoldForm.parsley().whenValidate();
  }
});
