/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var Backbone = require('backbone');
var Marionette = require('backbone.marionette');
var Syphon = require('backbone.syphon');
var parsleyUtil = require('common/util/parsleyUtil');
var toggleForm = require('common/util/toggleForm');

var AddressView = require('common/views/AddressView');
var AddressDisplayView = require('common/views/AddressDisplayView');

var NonDepEntityFormOfficerOtherLicenseCollectionView = require('../../entity-form/views/NonDepEntityFormOfficerOtherLicenseCollectionView');

var tmplNonDepIndividual = require('../templates/NonDepIndividualView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplNonDepIndividual,

  className: 'non-dep-individual-view',

  behaviors: {
    ReadOnlyFormBehavior: {},
    PhoneFormatBehavior: {}
  },

  ui: {
    oldAddress: '.old-address',
    address: '.address',
    ein: '.ein',
    firstNameLabel: '#first-name-label',
    firstName: '.first-name',
    lastName: '.last-name',
    lastNameSection: '.last-name-section',
    middleNameSection: '.middle-name-section',
    ssn: '.ssn',
    ssnSection: '.ssn-section',
    dateOfBirth: '.date-of-birth',
    dateOfBirthSection: '.date-of-birth-section',
    associatedLicensesCollectionContainer: '.associated-licenses-collection-container',
    addAssociatedLicense: '.add-associated-license',
    nonDepIndividualForm: '.non-dep-individual-form',
    removeOfficerCheckbox: '.remove-officer-checkbox',
    removeOfficerLabel: '.remove-officer-label'
  },

  regions: {
    oldAddress: '@ui.oldAddress',
    address: '@ui.address',
    associatedLicensesCollectionContainer: '@ui.associatedLicensesCollectionContainer'
  },

  triggers: {},

  events: {
    'change @ui.ein': 'onChangeEIN',
    'click @ui.addAssociatedLicense': 'onClickAddAssociatedLicense'
  },

  /**
   * Cases when SSN, Title, DoB, Email are NOT REQUIRED (fullProfileRequired = false)
   * - New Floating Individual from Individual Search via case management
   * - Edit Individual information from Dashboard via case management (floating, officers, branch managers)
   * - Setting initial Branch Manager for branch license via case management
   **/

  options: {
    fullProfileRequired: true
  },

  initialize: function(options) {
    this.viewMode = options.viewMode;
  },

  templateHelpers: function() {
    var amendmentChanges;
    var showChanges = this.options.isReadOnly && this.options.viewMode !== 'ext' && this.options.isAmendment;
    if (showChanges) {
      amendmentChanges = this.getAmendmentChanges();
    }

    return {
      viewMode: this.options.viewMode,
      fullProfileRequired: this.options.fullProfileRequired,
      childIndex: this.options.childIndex + 1,
      individualType: this.options.individualType,
      officerTypes: this.options.officerTypes,
      amendmentChanges: amendmentChanges
    };
  },

  onBeforeShow: function() {
    var showChanges = this.options.isReadOnly && this.options.viewMode !== 'ext' && this.options.isAmendment;
    var amendmentChanges;
    if (showChanges) {
      amendmentChanges = this.getAmendmentChanges();
    }

    this.showChildView(
      'address',
      new AddressView({
        horizontal: false,
        startingCountry: this.model.get('country'),
        startingPostalCode: this.model.get('postalCode'),
        startingCounty: this.model.get('county'),
        isRequired: this.options.fullProfileRequired
      })
    );

    var oldIndividual = this.model.get('oldIndividual') || {};
    var oldAddress = {
      addressLine1: oldIndividual.addressLine1,
      addressLine2: oldIndividual.addressLine2,
      addressLine3: oldIndividual.addressLine3,
      city: oldIndividual.city,
      state: oldIndividual.state,
      postalCode: oldIndividual.postalCode,
      country: oldIndividual.country
    };

    if (showChanges && oldIndividual && amendmentChanges.address) {
      this.showChildView('oldAddress', new AddressDisplayView({ model: new Backbone.Model(oldAddress) }));
    }

    Syphon.deserialize(this, this.model.toJSON());

    this.ui.dateOfBirth.datepicker({
      endDate: '0d',
      startView: 'years'
    });

    this.officerOtherLicenses = new NonDepEntityFormOfficerOtherLicenseCollectionView({
      collection: new Backbone.Collection(this.model.get('associatedLicenses'))
    });

    if (this.options.individualType === 'Entity Officer') {
      this.showChildView('associatedLicensesCollectionContainer', this.officerOtherLicenses);
      this.onChangeEIN();
      var allOfficers = this.model.get('allEntityOfficers');
      if (!_.isUndefined(allOfficers)) {
        if (allOfficers.length === 1) {
          toggleForm(this.ui.removeOfficerCheckbox, false);
          toggleForm(this.ui.removeOfficerLabel, false);
        }
      }
    }
  },

  onChangeEIN: function() {
    var needIndividualInfo = !this.ui.ein.val();

    if (needIndividualInfo) {
      this.ui.lastName.attr('data-parsley-required', needIndividualInfo);
      this.ui.lastNameSection.toggleClass('required', needIndividualInfo);
      this.ui.ssn.attr('data-parsley-required', needIndividualInfo);
      this.ui.ssnSection.toggleClass('required', needIndividualInfo);
      this.ui.dateOfBirth.attr('data-parsley-required', needIndividualInfo);
      this.ui.dateOfBirthSection.toggleClass('required', needIndividualInfo);
      this.ui.firstNameLabel[0].innerHTML = 'First Name';
      this.ui.firstName.attr('placeholder', 'First Name');

      toggleForm(this.ui.lastNameSection, true);
      toggleForm(this.ui.ssnSection, true);
      toggleForm(this.ui.dateOfBirthSection, true);
      toggleForm(this.ui.middleNameSection, true);
    } else {
      this.ui.lastName.removeAttr('data-parsley-required', needIndividualInfo);
      this.ui.lastNameSection.toggleClass('required', needIndividualInfo);
      this.ui.ssn.removeAttr('data-parsley-required', needIndividualInfo);
      this.ui.ssnSection.toggleClass('required', needIndividualInfo);
      this.ui.dateOfBirth.removeAttr('data-parsley-required', needIndividualInfo);
      this.ui.dateOfBirthSection.toggleClass('required', needIndividualInfo);
      this.ui.firstNameLabel[0].innerHTML = 'Company Name';
      this.ui.firstName.attr('placeholder', 'Company Name');

      toggleForm(this.ui.lastNameSection, false);
      toggleForm(this.ui.ssnSection, false);
      toggleForm(this.ui.dateOfBirthSection, false);
      toggleForm(this.ui.middleNameSection, false);
    }
  },

  onClickAddAssociatedLicense: function(e) {
    e.preventDefault();
    this.officerOtherLicenses.collection.add({});
  },

  onChildRemoveAssociatedLicense: function(childView) {
    this.officerOtherLicenses.collection.remove(childView.model);
  },

  validate: function(config) {
    this.ui.nonDepIndividualForm.parsley().reset();
    if (config.type === 'submit') {
      // Turn on required field validation for this form
      parsleyUtil.enableRequiredValidation(this.ui.nonDepIndividualForm);
    } else {
      // Turn off required field validation for this form
      parsleyUtil.disableRequiredValidation(this.ui.nonDepIndividualForm);
    }

    return this.ui.nonDepIndividualForm.parsley().whenValidate();
  },

  getFormData: function() {
    var model = this.model.toJSON();
    var formData = {};
    if (this.options.isReadOnly) {
      formData.entityOfficerTypeId = model.entityOfficerTypeId;
      formData.isDeleted = model.isDeleted;
      formData.firstName = model.firstName;
      formData.lastName = model.lastName;
      formData.middleName = model.middleName;
      formData.title = model.title;
      formData.suffix = model.suffix;
      formData.ssn = model.ssn;
      formData.dob = model.dob;
      formData.homePhoneNumber = model.homePhoneNumber;
      formData.cellPhoneNumber = model.cellPhoneNumber;
      formData.email = model.email;

      formData.addressLine1 = model.addressLine1;
      formData.addressLine2 = model.addressLine2;
      formData.addressLine3 = model.addressLine3;
      formData.city = model.city;
      formData.state = model.state;
      formData.postalCode = model.postalCode;
      formData.country = model.country;
      formData.county = model.county;
    } else {
      formData = Syphon.serialize(this);
    }
    formData.entityOfficerId = this.model.get('entityOfficerId');
    formData.entityId = this.model.get('entityId');
    formData.associatedLicenses = this.officerOtherLicenses.getFormData();
    return formData;
  },

  // validate: function(config) {
  //   var defer = $.Deferred();
  //   this.ui.individualForm.parsley().reset();

  //   if (config.type === 'save') {
  //     parsleyUtil.disableRequiredValidation(this.ui.individualForm);
  //   } else {
  //     // Turn on required field validation for this form
  //     parsleyUtil.enableRequiredValidation(this.ui.individualForm);
  //   }

  //   this.ui.individualForm
  //     .parsley()
  //     .whenValidate()
  //     .done(function() {
  //       defer.resolve();
  //     })
  //     .fail(function() {
  //       defer.reject();
  //     });
  //   return defer.promise();
  // },

  getAmendmentChanges: function() {
    var oldIndividual = this.model.get('oldIndividual') || {};
    var oldAddress;

    var appAddress = {
      addressLine1: this.model.get('addressLine1'),
      addressLine2: this.model.get('addressLine2'),
      addressLine3: this.model.get('addressLine3'),
      city: this.model.get('city'),
      state: this.model.get('state'),
      postalCode: this.model.get('postalCode'),
      country: this.model.get('country')
    };

    if (!_.isEmpty(oldIndividual)) {
      oldAddress = {
        addressLine1: oldIndividual.addressLine1,
        addressLine2: oldIndividual.addressLine2,
        addressLine3: oldIndividual.addressLine3,
        city: oldIndividual.city,
        state: oldIndividual.state,
        postalCode: oldIndividual.postalCode,
        country: oldIndividual.country
      };
    }

    // When an existing contact was updated and application is being reviewed internally,
    // check that an old value exists for each Contact property and that new value is different
    return {
      firstName: oldIndividual.firstName && this.model.get('firstName') !== oldIndividual.firstName,
      lastName: oldIndividual.lastName && this.model.get('lastName') !== oldIndividual.lastName,
      middleName: oldIndividual.middleName && this.model.get('middleName') !== oldIndividual.middleName,
      title: oldIndividual.title && this.model.get('title') !== oldIndividual.title,
      suffix: oldIndividual.suffix && this.model.get('suffix') !== oldIndividual.suffix,
      ssn: oldIndividual.ssn && this.model.get('ssn') !== oldIndividual.ssn,
      dob: oldIndividual.dobDisplay && this.model.get('dob') !== oldIndividual.dobDisplay,
      homePhoneNumber:
        oldIndividual.homePhoneNumber && this.model.get('homePhoneNumber') !== oldIndividual.homePhoneNumber,
      cellPhoneNumber:
        oldIndividual.cellPhoneNumber && this.model.get('cellPhoneNumber') !== oldIndividual.cellPhoneNumber,
      email: oldIndividual.email && this.model.get('email') !== oldIndividual.email,
      address: oldAddress && !_.isEqual(appAddress, oldAddress)
    };
  }
});
