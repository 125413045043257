/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var FileView = require('psa-marionette/views/FileView');

var GetDoesDocumentExist = require('../../services/GetDoesDocumentExist');
var showErrorMessage = require('../../util/showErrorMessage');

module.exports = FileView.extend({
  downloadFile: function(e) {
    e.preventDefault();
    var self = this;
    GetDoesDocumentExist(this.model.get('fileId')).done(function(documentExists) {
      if (documentExists) {
        self.model.download(self.$('.file-download-form'));
      } else {
        showErrorMessage({
          errorCode: 'file:download:not-found',
          errorTitle: 'File Not Found',
          errorMessage:
            'The file which you attempted to download no longer exists. You will not be able to download the requested document.'
        });
      }
    });
  }
});
