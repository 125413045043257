var Handlebars = require("handlebars/dist/handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "\n<div class=\"panel-heading\" role=\"tab\" id=\"heading-"
    + alias4(((helper = (helper = helpers.modelId || (depth0 != null ? depth0.modelId : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"modelId","hash":{},"data":data}) : helper)))
    + "\">\n  <div class=\"panel-title\">\n    <a data-toggle=\"collapse\" data-parent=\"#accordion\" href=\"#collapse-"
    + alias4(((helper = (helper = helpers.modelId || (depth0 != null ? depth0.modelId : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"modelId","hash":{},"data":data}) : helper)))
    + "\" aria-expanded=\"true\"\n      aria-controls=\"collapse-"
    + alias4(((helper = (helper = helpers.modelId || (depth0 != null ? depth0.modelId : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"modelId","hash":{},"data":data}) : helper)))
    + "\">\n      Agent\n    </a>\n    <button class=\"btn btn-danger remove-agent pull-right\">Remove</button>\n  </div>\n</div>\n<div id=\"collapse-"
    + alias4(((helper = (helper = helpers.modelId || (depth0 != null ? depth0.modelId : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"modelId","hash":{},"data":data}) : helper)))
    + "\" class=\"panel-collapse collapse in\" role=\"tabpanel\"\n  aria-labelledby=\"heading-"
    + alias4(((helper = (helper = helpers.modelId || (depth0 != null ? depth0.modelId : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"modelId","hash":{},"data":data}) : helper)))
    + "\">\n  <div class=\"panel-body\">\n    <form>\n      <div class=\"row\">\n        <div class=\"col-sm-12\">\n          <div class=\"form-group required\">\n            <label class=\"control-label\">Full Name</label>\n            <input type=\"text\" name=\"fullName\" class=\"form-control\" placeholder=\"Full Name\" data-parsley-required=\"true\"\n              data-parsley-maxlength=\"100\" />\n          </div>\n        </div>\n      </div>\n      <div class=\"row\">\n        <div class=\"col-sm-6\">\n          <div class=\"form-group required\">\n            <label class=\"control-label\">Phone Number</label>\n            <input type=\"text\" name=\"telephoneNumber\" class=\"form-control phone-format\" placeholder=\"Phone Number\"\n              data-parsley-required=\"true\" data-parsley-maxlength=\"20\" />\n          </div>\n        </div>\n        <div class=\"col-sm-6\">\n          <div class=\"form-group\">\n            <label class=\"control-label\">Fax Number</label>\n            <input type=\"text\" name=\"faxNumber\" class=\"form-control\" placeholder=\"Fax Number\"\n              data-parsley-maxlength=\"20\" />\n          </div>\n        </div>\n      </div>\n      <div class=\"form-group\">\n        <label class=\"control-label\">Email Address</label>\n        <input type=\"text\" name=\"email\" class=\"form-control\" placeholder=\"Email\" data-parsley-maxlength=\"254\"\n          data-parsley-type=\"email\" />\n      </div>\n      <div class=\"address\"></div>\n    </form>\n  </div>\n</div>";
},"useData":true});