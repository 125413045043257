/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2020] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Backbone = require('backbone');
var Marionette = require('backbone.marionette');

var UIUtil = require('psa-core/util/ui');
var GridView = require('psa-marionette/views/GridView');
var PageableCollection = require('psa-backbone/models/PageableCollection');

var ConfirmationModalView = require('common/views/ConfirmationModalView');
var MultiButtonCell = require('common/backgrid/MultiButtonCell');
var MomentCell = require('common/backgrid/MomentCell');

var SubmitComplaintRelated = require('../services/SubmitComplaintRelated');

var tmplConSerComplaintSectionRelatedComplaintsView = require('../templates/ConSerComplaintSectionRelatedComplaintsView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplConSerComplaintSectionRelatedComplaintsView,

  className: 'con-ser-complaint-section-related-complaints-view',

  ui: {
    relatedComplaintList: '.related-complaint-list'
  },

  regions: {
    relatedComplaintList: '@ui.relatedComplaintList'
  },

  onBeforeShow: function() {
    this.relatedGrid = new GridView({
      columns: [
        {
          name: 'caseNumber',
          label: 'Case Number',
          cell: 'string',
          headerCell: 'custom',
          width: 15,
          editable: false
        },
        {
          name: 'entityName',
          label: 'Entity Name',
          cell: 'string',
          headerCell: 'custom',
          width: 25,
          editable: false
        },
        {
          name: 'individualName',
          label: 'Individual Name',
          cell: 'string',
          headerCell: 'custom',
          width: 20,
          editable: false
        },
        {
          name: 'caseManager',
          label: 'Case Manager',
          cell: 'string',
          headerCell: 'custom',
          width: 20,
          editable: false
        },
        {
          name: 'dateOpened',
          label: 'Date Opened',
          cell: MomentCell.MomentCell,
          formatter: MomentCell.MomentDateFormatter.extend({
            emptyValue: '--'
          }),
          sortValue: MomentCell.MomentSortValue,
          headerCell: 'custom',
          width: 20,
          editable: false
        },
        {
          name: '',
          label: '',
          cell: MultiButtonCell.extend({
            buttons: [
              {
                name: 'Open',
                buttonClass: 'btn-primary btn-sm',
                href: function(model) {
                  return '#conser/complaint/' + model.get('complaintId') + '/dashboard';
                }
              },
              {
                name: 'Remove',
                buttonClass: 'btn-danger btn-sm ladda-button',
                onClick: this.onClickDeleteRelatedComplaints.bind(this)
              }
            ]
          }),
          headerCell: 'custom',
          width: 20,
          editable: false,
          sortable: false
        }
      ],
      collection: new PageableCollection(this.model.get('relatedComplaints'), { pageSize: 25 }),
      emptyText: 'No Related Complaints'
    });
    this.showChildView('relatedComplaintList', this.relatedGrid);
  },

  onClickDeleteRelatedComplaints: function(e, model) {
    e.preventDefault();
    var self = this;
    var message = 'Are you sure you want to remove this related complaint: ' + model.get('caseNumber') + '?';
    UIUtil.showModal({
      title: 'Confirm Related Complaint Removal',
      view: new ConfirmationModalView({
        confirmText: 'Remove Related Complaint',
        model: new Backbone.Model({
          message: message,
          onConfirm: function() {
            var formData = {};
            formData.complaintRelatedId = model.get('complaintRelatedId');
            return SubmitComplaintRelated(formData).done(function() {
              self.relatedGrid.collection.remove(model);
            });
          }
        })
      })
    });
  }
});
