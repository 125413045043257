/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var moment = require('moment');

var cleanUpResponse = require('common/util/cleanUpResponse');

module.exports = function(formData) {
  var defer = $.Deferred();

  formData.licenseStatusDate = formData.licenseStatusDate
    ? moment(formData.licenseStatusDate, 'MM/DD/YYYY').format('YYYY-MM-DD')
    : '';

  formData.FINRADate = formData.FINRADate ? moment(formData.FINRADate, 'MM/DD/YYYY').format('YYYY-MM-DD') : '';

  formData.SECRegistrationDate = formData.SECRegistrationDate
    ? moment(formData.SECRegistrationDate, 'MM/DD/YYYY').format('YYYY-MM-DD')
    : '';

  $.soap({
    body: {
      SubmitOrganizationLicense: {
        $xmlns: 'http://dobs.pa.gov/sc/organizationlicense/bpm/1.0',
        OrganizationID: formData.organizationId,
        LicenseID: formData.licenseId,
        LicenseTypeID: formData.licenseTypeId,
        LicenseStatusID: formData.licenseStatusId,
        LicenseStatusDate: formData.licenseStatusDate,
        FilingExaminerUserID: formData.filingExaminerUserId,
        FINRADate: formData.FINRADate,
        SECNumber: formData.SECNumber,
        SECRegistrationDate: formData.SECRegistrationDate,
        IsCustody: formData.isCustody,
        FiscalYearEnd: formData.fiscalYearEnd,
        IsFinancialReportReq: formData.isFinancialReportReq,
        IsDiscretion: formData.isDiscretion,
        LicenseCustodys: (function() {
          if (!formData.custodyTypeIds || !formData.custodyTypeIds.length) {
            return;
          }
          return {
            CustodyTypeID: formData.custodyTypeIds
          };
        })()
      }
    },
    blueprint: {
      id: '$..ID'
    }
  })
    .done(function(response) {
      cleanUpResponse(response, { cleanUpTextKeys: true });
      defer.resolve(response);
    })
    .fail(function(err) {
      defer.reject(err);
    });

  return defer.promise();
};
