/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Marionette = require('backbone.marionette');

var tmplCorpFinOfferingSectionView = require('../templates/CorpFinOfferingSectionView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplCorpFinOfferingSectionView,

  className: 'corp-fin-offering-section-view m-b-lg',

  ui: {
    isIndefiniteOffering: '.is-indefinite-offering',
    offeringType: '.offering-type',
    offeringAmounts: '.offering-amounts',
    dates207J: '.207J-dates',
    exemptionName: '.exemption-name'
  },

  onBeforeShow: function() {
    var isReg = this.model.get('recordCategoryId') === '1';
    var isExemption = this.model.get('recordCategoryId') === '2';
    var isTypeC = this.model.get('recordTypeId') === '1';
    var isTypeRD = this.model.get('recordTypeId') === '9';
    var isIndefiniteOffering = this.model.get('isIndefiniteOffering') === '1';

    if (isReg && isTypeC) {
      this.ui.dates207J.show();
    }

    if (isExemption) {
      this.ui.isIndefiniteOffering.show();

      if (isIndefiniteOffering) {
        this.ui.offeringAmounts.hide();
      }

      if (isTypeRD) {
        this.ui.offeringType.show();
      }
    }
  }
});
