/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2020] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Marionette = require('backbone.marionette');

var GridView = require('psa-marionette/views/GridView');
var MomentCell = require('common/backgrid/MomentCell');
var ButtonLinkCell = require('common/backgrid/ButtonLinkCell');
var MultiButtonCell = require('common/backgrid/MultiButtonCell');
var UIUtil = require('psa-core/util/ui');
var ConfirmationModalView = require('common/views/ConfirmationModalView');
var tmplDepIntEntityDraftFilingsSectionView = require('../templates/DepIntEntityDraftFilingsSectionView.hbs');
var SubmitFiling = require('../../filing-dashboard/services/SubmitFiling');
var userHasRole = require('common/util/userHasRole');

module.exports = Marionette.LayoutView.extend({
  template: tmplDepIntEntityDraftFilingsSectionView,

  className: 'dep-int-draft-filings-section-view container-fluid',

  ui: { draftFilingsList: '.draft-filings-list-container' },

  regions: { draftFilingsList: '@ui.draftFilingsList' },

  onBeforeShow: function() {
    var isAA1 = userHasRole('DP - AA1');
    var isAOTrust = userHasRole('DP - AO - Trust');
    var isAOCU = userHasRole('DP - AO - Credit Union');

    this.draftFilingsGrid = new GridView({
      columns: [
        {
          name: 'filGenericSubmissionType',
          label: 'Type',
          cell: 'string',
          headerCell: 'custom',
          width: 40,
          editable: false
        },
        {
          name: 'createdBy',
          label: 'Created By',
          cell: 'string',
          headerCell: 'custom',
          width: 25,
          editable: false
        },
        {
          name: 'modifiedDate',
          label: 'Last Modified',
          cell: MomentCell.MomentCell,
          formatter: MomentCell.MomentDateTimeFormatter.extend({
            emptyValue: '--'
          }),
          sortValue: MomentCell.MomentSortValue,
          headerCell: 'custom',
          width: 25,
          editable: false
        },
        {
          name: '',
          label: '',
          cell: ButtonLinkCell.extend({
            name: 'Open',
            buttonClass: 'btn-primary btn-sm',
            href: function(model) {
              var filingId = model.get('filingId');
              return '#dep/filing/' + filingId + '/draft';
            }
          }),
          headerCell: 'custom',
          width: 10,
          editable: false,
          sortable: false
        }
      ],
      emptyText: 'No Filings',
      collection: this.collection
    });

    if (isAA1 || isAOTrust || isAOCU) {
      this.draftFilingsGrid.options.columns.push({
        name: '',
        label: '',
        width: 5,
        cell: MultiButtonCell.extend({
          buttons: [
            {
              name: '',
              labelClass: 'fa fa-trash',
              buttonClass: 'btn-danger btn-sm',
              onClick: this.onClickDeleteDraftFiling.bind(this)
            }
          ]
        }),

        headerCell: 'custom',
        editable: false,
        sortable: false
      });
    }

    this.showChildView('draftFilingsList', this.draftFilingsGrid);
  },
  onClickDeleteDraftFiling: function(e, model) {
    e.preventDefault();
    var message = 'Are you sure you want to delete this Draft Filing?';
    var self = this;
    UIUtil.showModal({
      title: 'Confirm Deleting Draft Filing',
      view: new ConfirmationModalView({
        confirmText: 'Delete Draft Filing',
        model: new Backbone.Model({
          message: message,
          onConfirm: function() {
            var filingId = model.get('filingId');
            return SubmitFiling({
              filingId: filingId,
              isDeleted: 1
            });
          },
          onConfirmDone: function() {
            self.collection.remove(model);
          }
        })
      })
    });
  }
});
