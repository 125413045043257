/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2020] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var Backbone = require('backbone');

var GridView = require('psa-marionette/views/GridView');
var UIUtil = require('psa-core/util/ui');

var AddressCell = require('common/backgrid/AddressCell');
var ParagraphCell = require('common/backgrid/ParagraphCell');
var MultiButtonCell = require('common/backgrid/MultiButtonCell');
var ConfirmationModalView = require('common/views/ConfirmationModalView');

var SubmitInvInqComplainant = require('../services/SubmitInvInqComplainant');

module.exports = GridView.extend({
  className: 'sec-inv-inq-section-complainant-view',

  initialize: function(options) {
    _.extend(options, {
      emptyText: 'No Complainants',
      columns: [
        {
          name: 'name',
          label: 'Name',
          cell: 'string',
          headerCell: 'custom',
          width: 10,
          editable: false
        },
        {
          name: 'address',
          label: 'Address',
          cell: AddressCell,
          headerCell: 'custom',
          width: 15,
          editable: false,
          sortable: false
        },
        {
          name: 'age',
          label: 'Age',
          cell: 'string',
          headerCell: 'custom',
          width: 5,
          editable: false
        },
        {
          name: 'email',
          label: 'Email',
          cell: 'string',
          headerCell: 'custom',
          width: 15,
          editable: false
        },
        {
          name: 'phone',
          label: 'Phone Number',
          cell: 'string',
          headerCell: 'custom',
          width: 10,
          editable: false
        },
        {
          name: 'fax',
          label: 'Fax',
          cell: 'string',
          headerCell: 'custom',
          width: 10,
          editable: false
        },
        {
          name: 'note',
          label: 'Note',
          cell: ParagraphCell,
          headerCell: 'custom',
          width: 25,
          editable: false
        },
        {
          name: 'editDeleteMultiButtonCell',
          label: '',
          cell: MultiButtonCell.extend({
            buttons: [
              {
                name: '',
                labelClass: 'fa fa-edit',
                buttonClass: 'btn-primary btn-sm edit-processing-response',
                href: function(model) {
                  return (
                    '#sec/investigation-inquiry/' + model.get('invInqId') + '/complainant/' + model.get('id') + '/edit'
                  );
                }
              },
              {
                name: '',
                labelClass: 'fa fa-trash',
                buttonClass: 'btn-danger btn-sm delete-processing-response',
                onClick: this.onClickDelete.bind(this)
              }
            ]
          }),
          editable: false,
          sortable: false,
          width: 10
        }
      ]
    });

    if (GridView.prototype.initialize) {
      GridView.prototype.initialize.apply(options);
    }
  },

  onClickDelete: function(e, model) {
    e.preventDefault();

    UIUtil.showModal({
      title: 'Confirm Deleting Complainant',
      view: new ConfirmationModalView({
        confirmText: 'Delete Complainant',
        model: new Backbone.Model({
          message: 'Are you sure you want to delete this complainant?',
          onConfirm: function() {
            return SubmitInvInqComplainant({
              complainantId: model.get('id'),
              isDeleted: '1'
            });
          },
          onConfirmDone: function() {
            this.collection.remove(model);
          }.bind(this)
        })
      })
    });
  }
});
