/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Marionette = require('backbone.marionette');
var Syphon = require('backbone.syphon');
var toggleForm = require('common/util/toggleForm');

var momentUtil = require('common/util/momentUtil');
var moment = require('moment');

var tmplDepExamTSPNewPriorExamModalView = require('../templates/DepExamTSPNewPriorExamModalView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplDepExamTSPNewPriorExamModalView,

  className: 'new-tsp-part-agency',

  ui: {
    form: 'form',
    datePickerFields: '.date-picker',
    otherAgency: '.other-agency-in-charge',
    agencySelect: '.agency-select',
    submit: '.submit'
  },

  events: {
    'click @ui.submit': 'onClickSubmit',
    'change @ui.agencySelect': 'onChangeAgencySelect'
  },

  templateHelpers: function() {
    return {
      examNames: this.options.examNames
    };
  },

  onShow: function() {
    var priorExam = this.model.toJSON();
    if (priorExam.priorExamDate && priorExam.priorExamDate !== '--') {
      priorExam.priorExamDate = moment(priorExam.priorExamDate, 'YYYY-MM-DD').format('MM/DD/YYYY');
    }

    Syphon.deserialize(this, priorExam);
    this.datePicker();
    this.onChangeAgencySelect();
  },

  datePicker: function() {
    this.ui.datePickerFields.datepicker();
  },

  onChangeAgencySelect: function() {
    var agencyValue = this.ui.agencySelect.val();
    var isOtherAgency = agencyValue === '31' || agencyValue === '37' || agencyValue === '43';

    toggleForm(this.ui.otherAgency, isOtherAgency);
  },

  getFormData: function() {
    var formData = Syphon.serialize(this.ui.form);

    formData.leadAgency = this.ui.agencySelect.find(':selected').text();
    formData.leadAgencyDisplay = this.ui.agencySelect.find(':selected').text();
    formData.ratingDisplay = formData.rating || '--';
    formData.examId = this.options.examId;

    momentUtil.formatDateAttributes(
      formData,
      ['priorExamDate'],
      momentUtil.defaultOutboundDateConfig.sourceFormat,
      momentUtil.defaultOutboundDateConfig.targetFormat
    );
    return formData;
  },

  onClickSubmit: function(e) {
    e.preventDefault();
    var self = this;
    this.ui.form
      .parsley()
      .whenValidate()
      .done(
        function() {
          var formData = this.getFormData();

          self.triggerMethod('modal:close', formData);
        }.bind(this)
      );
  }
});
