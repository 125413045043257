/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Marionette = require('backbone.marionette');
var Syphon = require('backbone.syphon');
var Ladda = require('ladda');

var AddressView = require('common/views/AddressView');
var modelGet = require('common/util/modelGet');
var toggleForm = require('common/util/toggleForm');

var SubmitLicenseAddress = require('../services/SubmitLicenseAddress');

var tmplSecLicenseAddressModalView = require('../templates/SecLicenseAddressModalView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplSecLicenseAddressModalView,

  className: 'sec-license-address-modal-view',

  ui: {
    licenseAddressForm: '.license-address-form',
    submit: '.submit',
    address: '.address',
    branchNumberContainer: '.branch-number-container',
    addressTypeDropdown: 'select[name="typeId"]'
  },

  regions: {
    address: '@ui.address'
  },

  events: {
    'click @ui.submit': 'onClickSubmit',
    'change @ui.addressTypeDropdown': 'onChangeAddressType'
  },

  onBeforeShow: function() {
    var licenseAddress = this.model.get('licenseAddress');
    this.showChildView(
      'address',
      new AddressView({
        horizontal: false,
        requiredFields: {
          addressLine1: true
        },
        startingCountry: modelGet(this.model, 'licenseAddress.country'),
        startingPostalCode: modelGet(this.model, 'licenseAddress.postalCode'),
        startingCounty: modelGet(this.model, 'licenseAddress.county')
      })
    );
    toggleForm(this.ui.branchNumberContainer, licenseAddress.typeId === '5');

    Syphon.deserialize(this, licenseAddress);
  },

  onChangeAddressType: function() {
    var formData = Syphon.serialize(this);
    toggleForm(this.ui.branchNumberContainer, formData.typeId === '5');
  },

  onClickSubmit: function(e) {
    e.preventDefault();
    var self = this;
    this.ui.licenseAddressForm
      .parsley()
      .whenValidate()
      .done(function() {
        var l = Ladda.create(self.ui.submit[0]);
        l.start();
        var formData = Syphon.serialize(self);
        var id = modelGet(self.model, 'licenseAddress.id');
        var objectId = modelGet(self.model, 'licenseId');
        var addressId = modelGet(self.model, 'licenseAddress.addressId');
        formData.objectTypeId = self.options.objectTypeId;
        formData.objectId = objectId;
        formData.id = id;
        formData.addressId = addressId;
        SubmitLicenseAddress(formData)
          .done(function(newAddress) {
            self.triggerMethod('modal:close', newAddress);
          })
          .fail(function() {
            l.stop();
          });
      });
  }
});
