var Handlebars = require("handlebars/dist/handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "\n<div class=\"on-deck-view\">\n  <p>ON DECK</p>\n  <ul class=\"nav nav-pills nav-stacked\">\n    <li>\n      <a class=\"menu-item\" href=\"#conser/my-tasks\">\n        <i class=\"menu-item-icon fa fa-bars fa-fw\"></i>\n        <span class=\"menu-item-label\">My Tasks</span>\n      </a>\n    </li>\n    <li>\n      <a class=\"menu-item\" href=\"#conser/dashboard\">\n        <i class=\"menu-item-icon fa fa-home fa-fw\"></i>\n        <span class=\"menu-item-label\">My Complaints</span>\n      </a>\n    </li>\n    <li>\n      <a class=\"menu-item\" href=\"#conser/unassigned-complaints\">\n        <i class=\"menu-item-icon fa fa-comments-o fa-fw\"></i>\n        <span class=\"menu-item-label\">Unassigned Complaints</span>\n      </a>\n    </li>\n  </ul>\n</div>\n<div class=\"menu-view\">\n  <p>MENU</p>\n  <ul class=\"nav nav-pills nav-stacked\">\n    <li>\n      <a class=\"menu-item\" href=\"#conser/entity-search\">\n        <i class=\"menu-item-icon fa fa-building fa-fw\"></i>\n        <span class=\"menu-item-label\">Entity Search</span>\n      </a>\n    </li>\n    <li>\n      <a class=\"menu-item\" href=\"#conser/complaint-search\">\n        <i class=\"menu-item-icon fa fa-file fa-fw\"></i>\n        <span class=\"menu-item-label\">Complaint Search</span>\n      </a>\n    </li>\n    <li>\n      <a class=\"menu-item\" href=\"#conser/individual-search\">\n        <i class=\"menu-item-icon fa fa-user fa-fw\"></i>\n        <span class=\"menu-item-label\">Individual Search</span>\n      </a>\n    </li>\n    <li>\n      <a class=\"menu-item\" href=\"#conser/correspondence-search\">\n        <i class=\"menu-item-icon fa fa-clipboard fa-fw\"></i>\n        <span class=\"menu-item-label\">Correspondence Search</span>\n      </a>\n    </li>\n    <li>\n      <a class=\"menu-item\" href=\""
    + alias4(((helper = (helper = helpers.iHubFolderLink || (depth0 != null ? depth0.iHubFolderLink : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"iHubFolderLink","hash":{},"data":data}) : helper)))
    + "\" target=\"_blank\">\n        <i class=\"menu-item-icon fa fa-bar-chart fa-fw\"></i>\n        <span class=\"menu-item-label\">Reports</span>\n        <i class=\"fa fa-external-link external-link\"></i>\n      </a>\n    </li>\n    <li>\n      <a class=\"menu-item\" href=\""
    + alias4(((helper = (helper = helpers.csFolderLink || (depth0 != null ? depth0.csFolderLink : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"csFolderLink","hash":{},"data":data}) : helper)))
    + "\" target=\"_blank\">\n        <i class=\"menu-item-icon fa fa-folder-open-o\"></i>\n        <span class=\"menu-item-label\">Content Server</span>\n        <i class=\"fa fa-external-link external-link\"></i>\n      </a>\n    </li>\n  </ul>\n</div>";
},"useData":true});