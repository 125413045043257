var Handlebars = require("handlebars/dist/handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression;

  return "\n<h3 class=\"pay-period-title\">Pay Period from "
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.payPeriod : depth0)) != null ? stack1.payPeriodDisplay : stack1), depth0))
    + "</h3>\n<div class=\"clearfix\">\n  <h3 class=\"pull-left m-t-sm\">Time Entries</h3>\n  <button class=\"btn add pull-right btn-primary add-entry m-t-sm\">Add Entry</button>\n</div>\n<div class=\"time-entries-section\">\n  <div class=\"labels row\">\n    <div class=\"col-time-entity-type\">\n      <label class=\"control-label\">Entity Type</label>\n    </div>\n    <div class=\"col-time-category\">\n      <label class=\"control-label\">Category</label>\n    </div>\n    <div class=\"col-time-exam\">\n      <label class=\"control-label\">Exam</label>\n    </div>\n    <div class=\"col-time-worksite\">\n      <label class=\"control-label\">Worksite</label>\n    </div>\n    <div class=\"col-time-hrs\">\n      <div class=\"row\">\n        <div class=\"col-hr-label\">\n          <div class=\"form-group\">\n            <label class=\"control-label\">Mon.</label>\n            <p>"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.payPeriod : depth0)) != null ? stack1.startDayPlus1Display : stack1), depth0))
    + "</p>\n          </div>\n        </div>\n        <div class=\"col-hr-label\">\n          <div class=\"form-group\">\n            <label class=\"control-label\">Tue.</label>\n            <p>"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.payPeriod : depth0)) != null ? stack1.startDayPlus2Display : stack1), depth0))
    + "</p>\n          </div>\n        </div>\n        <div class=\"col-hr-label\">\n          <div class=\"form-group\">\n            <label class=\"control-label\">Wed.</label>\n            <p>"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.payPeriod : depth0)) != null ? stack1.startDayPlus3Display : stack1), depth0))
    + "</p>\n          </div>\n        </div>\n        <div class=\"col-hr-label\">\n          <div class=\"form-group\">\n            <label class=\"control-label\">Thurs.</label>\n            <p>"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.payPeriod : depth0)) != null ? stack1.startDayPlus4Display : stack1), depth0))
    + "</p>\n          </div>\n        </div>\n        <div class=\"col-hr-label\">\n          <div class=\"form-group\">\n            <label class=\"control-label\">Fri.</label>\n            <p>"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.payPeriod : depth0)) != null ? stack1.startDayPlus5Display : stack1), depth0))
    + "</p>\n          </div>\n        </div>\n        <div class=\"col-hr-label\">\n          <div class=\"form-group\">\n            <label class=\"control-label\">Mon.</label>\n            <p>"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.payPeriod : depth0)) != null ? stack1.startDayPlus8Display : stack1), depth0))
    + "</p>\n          </div>\n        </div>\n        <div class=\"col-hr-label\">\n          <div class=\"form-group\">\n            <label class=\"control-label\">Tue.</label>\n            <p>"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.payPeriod : depth0)) != null ? stack1.startDayPlus9Display : stack1), depth0))
    + "</p>\n          </div>\n        </div>\n        <div class=\"col-hr-label\">\n          <div class=\"form-group\">\n            <label class=\"control-label\">Wed.</label>\n            <p>"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.payPeriod : depth0)) != null ? stack1.startDayPlus10Display : stack1), depth0))
    + "</p>\n          </div>\n        </div>\n        <div class=\"col-hr-label\">\n          <div class=\"form-group\">\n            <label class=\"control-label\">Thurs.</label>\n            <p>"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.payPeriod : depth0)) != null ? stack1.startDayPlus11Display : stack1), depth0))
    + "</p>\n          </div>\n        </div>\n        <div class=\"col-hr-label\">\n          <div class=\"form-group\">\n            <label class=\"control-label\">Fri.</label>\n            <p>"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.payPeriod : depth0)) != null ? stack1.startDayPlus12Display : stack1), depth0))
    + "</p>\n          </div>\n        </div>\n      </div>\n    </div>\n  </div>\n  <form class=\"time-entries-container\"></form>\n  <div class=\"time-totals-container\"></div>\n</div>\n<div class=\"row m-t-lg\">\n  <div class=\"col-comments-section\">\n    <h3>Comments</h3>\n    <textarea class=\"form-control align-comments\" name=\"comments\" rows=\"7\" placeholder=\"Comments\"></textarea>\n  </div>\n</div>\n<button class=\"btn btn-primary save ladda-button pull-right m-b-lg\" data-style=\"expand-right\" type=\"button\">\n  <span class=\"ladda-label\"><i class=\"fa fa-save\"></i> Save</span>\n</button>";
},"useData":true});