/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Marionette = require('backbone.marionette');
var Syphon = require('backbone.syphon');

var parsleyUtil = require('common/util/parsleyUtil');
var toggleForm = require('common/util/toggleForm');
var modelGet = require('common/util/modelGet');

var tmplPawnbrokerAnnualReportProductionReportSectionView = require('./PawnbrokerAnnualReportProductionReportSectionView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplPawnbrokerAnnualReportProductionReportSectionView,

  className: 'pawnbroker-annual-report-production-report-section-view',

  ui: {
    productionReportForm: '.production-report-form',
    notAvailable: '.not-available',
    beginningOutstandingInputs: '#beginningOutstandingInputs',
    beginningOutstandingExplanation: '#beginningOutstandingExplanation',
    newPawnLoansInputs: '#newPawnLoansInputs',
    newPawnLoansExplanation: '#newPawnLoansExplanation',
    redeemedLoansInputs: '#redeemedLoansInputs',
    redeemedLoansExplanation: '#redeemedLoansExplanation',
    forfeitedLoansInputs: '#forfeitedLoansInputs',
    forfeitedLoansExplanation: '#forfeitedLoansExplanation',
    chargedOffLoansInputs: '#chargedOffLoansInputs',
    chargedOffLoansExplanation: '#chargedOffLoansExplanation',
    totalCancelledLoansInputs: '#totalCancelledLoansInputs',
    totalCancelledLoansExplanation: '#totalCancelledLoansExplanation',
    endOutstandingInputs: '#endOutstandingInputs',
    endOutstandingExplanation: '#endOutstandingExplanation'
  },

  events: {
    'change @ui.notAvailable': 'onChangeNotAvailable'
  },

  onBeforeShow: function() {
    // Toggle loan outstandings based on answer
    var beginningOutstandingNotAvailable = modelGet(this.model, 'annualReport.beginningOutstandingNotAvailable');
    this.toggleQuestionInputs(this.ui.beginningOutstandingInputs, beginningOutstandingNotAvailable);
    this.toggleExplanationInputs(this.ui.beginningOutstandingExplanation, beginningOutstandingNotAvailable);

    // Toggle new pawn loans based on answer
    var newPawnLoansNotAvailable = modelGet(this.model, 'annualReport.newPawnLoansNotAvailable');
    this.toggleQuestionInputs(this.ui.newPawnLoansInputs, newPawnLoansNotAvailable);
    this.toggleExplanationInputs(this.ui.newPawnLoansExplanation, newPawnLoansNotAvailable);

    // Toggle redeemed loans based on answer
    var redeemedLoansNotAvailable = modelGet(this.model, 'annualReport.redeemedLoansNotAvailable');
    this.toggleQuestionInputs(this.ui.redeemedLoansInputs, redeemedLoansNotAvailable);
    this.toggleExplanationInputs(this.ui.redeemedLoansExplanation, redeemedLoansNotAvailable);

    // Toggle forfeited loans based on answer
    var forfeitedLoansNotAvailable = modelGet(this.model, 'annualReport.forfeitedLoansNotAvailable');
    this.toggleQuestionInputs(this.ui.forfeitedLoansInputs, forfeitedLoansNotAvailable);
    this.toggleExplanationInputs(this.ui.forfeitedLoansExplanation, forfeitedLoansNotAvailable);

    // Toggle charged off loans based on answer
    var chargedOffLoansNotAvailable = modelGet(this.model, 'annualReport.chargedOffLoansNotAvailable');
    this.toggleQuestionInputs(this.ui.chargedOffLoansInputs, chargedOffLoansNotAvailable);
    this.toggleExplanationInputs(this.ui.chargedOffLoansExplanation, chargedOffLoansNotAvailable);

    // Toggle charged off loans based on answer
    var totalCancelledLoansNotAvailable = modelGet(this.model, 'annualReport.totalCancelledLoansNotAvailable');
    this.toggleQuestionInputs(this.ui.totalCancelledLoansInputs, totalCancelledLoansNotAvailable);
    this.toggleExplanationInputs(this.ui.totalCancelledLoansExplanation, totalCancelledLoansNotAvailable);

    // Toggle charged off loans based on answer
    var endOutstandingNotAvailable = modelGet(this.model, 'annualReport.endOutstandingNotAvailable');
    this.toggleQuestionInputs(this.ui.endOutstandingInputs, endOutstandingNotAvailable);
    this.toggleExplanationInputs(this.ui.endOutstandingExplanation, endOutstandingNotAvailable);

    Syphon.deserialize(this, this.model.get('annualReport'));
  },

  onChangeNotAvailable: function(e) {
    var $checkbox = $(e.target);
    var isNotAvailable = $checkbox.prop('checked');
    var formTargetSelector = $checkbox.attr('data-target');
    var $questionInputs = this.$el.find(formTargetSelector + 'Inputs');
    var $explanationInputs = this.$el.find(formTargetSelector + 'Explanation');
    this.toggleQuestionInputs($questionInputs, isNotAvailable);
    this.toggleExplanationInputs($explanationInputs, isNotAvailable);
  },

  validate: function(config) {
    if (config.type === 'save') {
      parsleyUtil.disableRequiredValidation(this.ui.productionReportForm);
    } else {
      parsleyUtil.enableRequiredValidation(this.ui.productionReportForm);
    }
    return this.ui.productionReportForm.parsley().whenValidate();
  },

  getFormData: function() {
    return Syphon.serialize(this);
  },

  toggleQuestionInputs: function($element, isNotAvailable) {
    toggleForm($element, !isNotAvailable);
  },

  toggleExplanationInputs: function($element, isAvailable) {
    toggleForm($element, isAvailable);
  }
});
