/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');

var cleanUpResponse = require('common/util/cleanUpResponse');

module.exports = function(categoryId) {
  var deferred = $.Deferred();

  $.soap({
    body: {
      GetAllMrbaLookups: {
        $xmlns: 'http://dobs.pa.gov/dp/lookup/bpm/1.0'
      }
    },
    blueprintArrays: [
      'GetAllMrbaLookupsResponse.Mrba.Lookups.DP_LU_MRBAType',
      'GetAllMrbaLookupsResponse.Mrba.Lookups.DP_LU_MRBACategory',
      'GetAllMrbaLookupsResponse.Mrba.Lookups.DP_LU_MRBAAgency',
      'GetAllMrbaLookupsResponse.Mrba.Lookups.DP_LU_MRBAHistory',
      'GetAllMrbaLookupsResponse.Mrba.Lookups.DP_LU_MRBAStatus'
    ],
    blueprint: {
      mrbaTypes: [
        '$..DP_LU_MRBAType',
        {
          id: '$.ID',
          type: '$.Type'
        }
      ],
      mrbaCategories: [
        '$..DP_LU_MRBACategory',
        {
          id: '$.ID',
          name: '$.Name',
          categoryId: '$.CategoryID'
        }
      ],
      mrbaAgencies: [
        '$..DP_LU_MRBAAgency',
        {
          id: '$.ID',
          agency: '$.Agency',
          categoryId: '$.CategoryID'
        }
      ],
      mrbaHistories: [
        '$..DP_LU_MRBAHistory',
        {
          id: '$.ID',
          history: '$.History'
        }
      ],
      mrbaStatus: [
        '$..DP_LU_MRBAStatus',
        {
          id: '$.ID',
          status: '$.Status'
        }
      ]
    }
  })
    .done(function(response) {
      cleanUpResponse(response);
      response.mrbaCategories = _.filter(response.mrbaCategories, function(category) {
        return category.categoryId === categoryId;
      });

      response.mrbaAgencies = _.filter(response.mrbaAgencies, function(agency) {
        return agency.categoryId === categoryId;
      });

      deferred.resolve(response);
    })
    .fail(function(err) {
      deferred.reject();
    });

  return deferred.promise();
};
