/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2020] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Backbone = require('backbone');
var Marionette = require('backbone.marionette');
var Radio = require('backbone.radio');
var Ladda = require('ladda');
var Syphon = require('backbone.syphon');

var BreadcrumbView = require('common/views/BreadcrumbView');
var momentUtil = require('common/util/momentUtil');

var SecInvInqHeaderBarView = require('./SecInvInqHeaderBarView');
var SubmitInvInqSubpoena = require('../services/SubmitInvInqSubpoena');

var tmplSecInvInqSubpoenaEditView = require('../templates/SecInvInqSubpoenaEditView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplSecInvInqSubpoenaEditView,

  className: 'sec-inv-inq-subpoena-edit-view container-fluid',

  ui: {
    headerBar: '.header-bar-container',
    breadcrumb: '.breadcrumb-container',
    subpoenaForm: '.subpoena-form',
    datePickerField: '.date-picker',
    submit: '.submit'
  },

  regions: {
    headerBar: '@ui.headerBar',
    breadcrumb: '@ui.breadcrumb',
    addressForm: '@ui.addressForm'
  },

  events: { 'click @ui.submit': 'onClickSubmit' },
  onBeforeShow: function() {
    var subpoena = this.model.get('subpoena') || {};
    var identifiers = this.model.get('identifiers') || {};
    var invInqId = this.model.get('invInqId');

    var category = identifiers.category;
    var caseNumber = identifiers.caseNumber;
    var objectId = identifiers.objectId;
    var objectLicenseId = identifiers.objectLicenseId;

    var breadcrumbs = [
      {
        link: '#sec/dashboard',
        text: 'Home'
      }
    ];

    if (identifiers.isOrganization === '1') {
      breadcrumbs.push({
        link: '#sec/organization/' + objectId + '/dashboard',
        text: identifiers.applicantLegalName
      });
      if (objectLicenseId) {
        breadcrumbs.push({
          link: '#sec/organization/' + objectId + '/license/' + objectLicenseId + '/dashboard',
          text: identifiers.licenseType
        });
      }
    } else {
      breadcrumbs.push({
        link: '#sec/individual/' + objectId + '/dashboard',
        text: identifiers.applicantLegalName
      });
      if (objectLicenseId) {
        breadcrumbs.push({
          link: '#sec/individual/' + objectId + '/license/' + objectLicenseId + '/dashboard',
          text: identifiers.licenseType
        });
      }
    }

    breadcrumbs = breadcrumbs.concat([
      {
        link: '#sec/investigation-inquiry/' + invInqId + '/dashboard',
        text: category + ' (' + caseNumber + ')'
      },
      {
        text: 'Subpoena'
      }
    ]);

    this.showChildView(
      'headerBar',
      new SecInvInqHeaderBarView({
        model: new Backbone.Model(identifiers),
        isLicenseInvInq: !!objectLicenseId
      })
    );

    this.showChildView(
      'breadcrumb',
      new BreadcrumbView({
        breadcrumbs: breadcrumbs
      })
    );

    Syphon.deserialize(this, subpoena);
    this.ui.datePickerField.datepicker();
  },

  getFormData: function() {
    var formData = Syphon.serialize(this);

    formData.dueDate = momentUtil.formatStr(formData.dueDate, momentUtil.defaultOutboundDateConfig);
    formData.subpoenaDate = momentUtil.formatStr(formData.subpoenaDate, momentUtil.defaultOutboundDateConfig);

    formData.invInqId = this.model.get('invInqId');
    formData.subpoenaId = this.model.get('subpoenaId');
    return formData;
  },

  validate: function() {
    return this.ui.subpoenaForm.parsley().whenValidate();
  },

  onClickSubmit: function(event) {
    event.preventDefault();
    var self = this;
    this.validate().done(function() {
      var l = Ladda.create(self.ui.submit[0]);
      l.start();
      var formData = self.getFormData();
      SubmitInvInqSubpoena(formData)
        .done(function() {
          Radio.channel('navigate').trigger('show:int:sc:investigation-inquiry-dashboard', self.model.get('invInqId'));
        })
        .fail(function() {
          l.stop();
        });
    });
  }
});
