var Handlebars = require("handlebars/dist/handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "      <button type=\"button\" class=\"btn btn-danger skip-rfi-task\">Skip RFI</button>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.escapeExpression, alias2=depth0 != null ? depth0 : (container.nullContext || {});

  return "\n<div class=\"page-title clearfix\">\n  <h1 class=\"pull-left m-n p-n\">"
    + alias1(container.lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.task : depth0)) != null ? stack1.taskType : stack1)) != null ? stack1.description : stack1), depth0))
    + "</h1>\n</div>\n<div class=\"header-bar\"></div>\n<div class=\"breadcrumb-container\"></div>\n<div class=\"task-status\"></div>\n<div class=\"form-horizontal\">\n  <form class=\"exam-create-rfi-form\">\n    <div class=\"form-group\">\n      <label class=\"control-label col-sm-3\">Link to Exam Dashboard</label>\n      <div class=\"col-sm-9\">\n        <a href=\"#sec/exam/"
    + alias1(((helper = (helper = helpers.examId || (depth0 != null ? depth0.examId : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(alias2,{"name":"examId","hash":{},"data":data}) : helper)))
    + "/dashboard\" target=\"_blank\" class=\"btn btn-primary\">Open Exam Dashboard</a>\n      </div>\n    </div>\n    <div class=\"form-group\">\n      <label class=\"control-label col-sm-3\">Internal Comments</label>\n      <div class=\"col-sm-6\">\n        <textarea class=\"form-control\" rows=\"5\" name=\"internalComments\" placeholder=\"Internal Comments\"></textarea>\n      </div>\n    </div>\n    <div class=\"form-group collapse missing-file\">\n      <div class=\"col-sm-offset-3 col-sm-6\">\n        <div class=\"alert alert-danger\" role=\"alert\">\n          <i class=\"fa fa-exclamation-triangle\" aria-hidden=\"true\"></i>\n          At least one document or comment must be provided.\n        </div>\n      </div>\n    </div>\n    <div class=\"form-group\">\n      <label class=\"control-label col-sm-3\">External Comments</label>\n      <div class=\"col-sm-6\">\n        <textarea class=\"form-control\" rows=\"5\" name=\"externalComments\" placeholder=\"External Comments\"></textarea>\n      </div>\n    </div>\n    <div class=\"form-group\">\n      <label class=\"control-label col-sm-3\">RFI Request Attachment</label>\n      <div class=\" col-sm-6\">\n        <div class=\"rfi-request-container\"></div>\n      </div>\n    </div>\n  </form>\n  <div class=\"form-group\">\n    <div class=\"col-sm-9 col-sm-offset-3\">\n      <a href=\"#sec/dashboard\" class=\"btn btn-default\" role=\"button\">Cancel</a>\n      <button type=\"button\" class=\"btn btn-primary submit\">Submit</button>\n      <div class=\"task-actions\"></div>\n"
    + ((stack1 = helpers["if"].call(alias2,((stack1 = (depth0 != null ? depth0.exam : depth0)) != null ? stack1.showSkipRFI : stack1),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "    </div>\n  </div>\n</div>";
},"useData":true});