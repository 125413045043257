/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var Marionette = require('backbone.marionette');
var Ladda = require('ladda');
var Radio = require('backbone.radio');

var modelGet = require('common/util/modelGet');
var BreadcrumbView = require('common/views/BreadcrumbView');

var ExamHeaderBarView = require('../../common/views/ExamHeaderBarView');

var SubmitNewCorrespondence = require('../../common/services/SubmitNewCorrespondence');

var NonDepInitiateCorrespondenceFormView = require('../../correspondence/views/NonDepInitiateCorrespondenceFormView');

var tmplNonDepInitiateCorrespondenceView = require('../templates/NonDepInitiateCorrespondenceView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplNonDepInitiateCorrespondenceView,

  className: 'non-dep-initiate-correspondence-view container-fluid',

  ui: {
    headerBar: '.header-bar',
    breadcrumb: '.breadcrumb-container',
    correspondenceFormContainer: '.correspondence-form-container',
    submit: '.submit'
  },

  regions: {
    headerBar: '@ui.headerBar',
    breadcrumb: '@ui.breadcrumb',
    correspondenceFormContainer: '@ui.correspondenceFormContainer'
  },

  events: {
    'click @ui.submit': 'onClickSubmit'
  },

  onBeforeShow: function() {
    var exam = this.model.get('exam') || {};

    this.showChildView(
      'headerBar',
      new ExamHeaderBarView({
        model: new Backbone.Model(_.extend(exam, { title: 'Exam' }))
      })
    );

    this.showChildView(
      'breadcrumb',
      new BreadcrumbView({
        breadcrumbs: [
          {
            link: '#nondep/dashboard',
            text: 'Home'
          },
          {
            text: exam.entityName,
            link: '#nondep/entity/' + exam.entityId + '/dashboard'
          },
          {
            text: 'License #' + exam.licenseNumber,
            link: '#nondep/entity/' + exam.entityId + '/license/' + exam.licenseId
          },
          {
            text: 'Exam #' + exam.examId,
            link: '#nondep/dashboard/exam/' + exam.examId
          },
          {
            text: 'Initiate Correspondence'
          }
        ]
      })
    );

    this.correspondenceForm = new NonDepInitiateCorrespondenceFormView({
      model: this.model
    });

    this.showChildView('correspondenceFormContainer', this.correspondenceForm);
  },

  onClickSubmit: function(e) {
    e.preventDefault();
    var self = this;
    var examId = modelGet(this.model, 'exam.examId');
    var entityId = modelGet(this.model, 'exam.entityId');

    this.correspondenceForm.validate().done(function() {
      var l = Ladda.create(self.ui.submit[0]);
      l.start();
      var formData = self.correspondenceForm.getFormData();
      formData.objectId = examId;
      formData.objectTypeId = 'EXAM';
      formData.entityId = entityId;
      formData.isInternal = '1';

      SubmitNewCorrespondence(formData)
        .done(function() {
          Radio.channel('navigate').trigger('show:int:nd:dashboard:exams:dashboard', examId);
        })
        .fail(function() {
          l.stop();
        });
    });
  }
});
