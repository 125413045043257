/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2020] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Marionette = require('backbone.marionette');
var Syphon = require('backbone.syphon');

var tmplConSerOpenCloseView = require('../templates/ConSerOpenCloseView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplConSerOpenCloseView,

  className: 'con-ser-open-close-view',

  ui: {
    openedDate: '.opened-date',
    closedDate: '.closed-date',
    inputs: ':input',
    datePickerFields: '.date-picker'
  },

  templateHelpers: function() {
    return { childIndex: this.options.childIndex };
  },

  onBeforeShow: function() {
    Syphon.deserialize(this, this.model.toJSON());
    this.ui.datePickerFields.datepicker({ enableOnReadonly: false, startDate: this.model.get('openedDate') });
  },

  getFormData: function() {
    return Syphon.serialize(this);
  },

  setReadonly: function(isReadOnly) {
    this.ui.inputs.attr('readOnly', isReadOnly);
    if (!isReadOnly) {
      this.ui.openedDate.attr('readOnly', true);
    }
  },

  setRequired: function(isRequired) {
    this.ui.closedDate.attr('data-parsley-required', isRequired.toString());
  },

  setClosedDate: function(closedDate) {
    this.ui.closedDate.val(closedDate);
  },

  getClosedDate: function() {
    return this.ui.closedDate.val();
  }
});
