/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var Backbone = require('backbone');
var Marionette = require('backbone.marionette');
var Syphon = require('backbone.syphon');
var Radio = require('backbone.radio');
var Ladda = require('ladda');
var moment = require('moment');

var Session = require('psa-core/Session');

var modelGet = require('common/util/modelGet');
var toggleForm = require('common/util/toggleForm');
var HeaderBarView = require('common/views/HeaderBarView');
var BreadcrumbView = require('common/views/BreadcrumbView');
var HeaderBarAddressCellView = require('common/views/HeaderBarView/HeaderBarAddressCellView');

var SubmitReviewExamTask = require('../../services/SubmitReviewExamTask');

var tmplDepReviewExamTaskView = require('./DepReviewExamTaskView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplDepReviewExamTaskView,

  className: 'dep-review-exam-task-view container-fluid',

  ui: {
    headerBar: '.header-bar',
    breadcrumb: '.breadcrumb-container',
    reviewExamForm: '.review-exam-form',
    reviewRolesCheckboxes: 'input[name=reviewRoles]',
    actionDropdown: '.action-dropdown',
    actionCommentsOptionalRow: '.action-comments-optional-row',
    actionCommentsRequiredRow: '.action-comments-required-row',
    formGroups: '.form-group',
    commentFormGroup: '.comment-form-group',
    submit: '.submit'
  },

  regions: {
    headerBar: '@ui.headerBar',
    breadcrumb: '@ui.breadcrumb'
  },

  events: {
    'change @ui.actionDropdown': 'onChangeAction',
    'click @ui.submit': 'onClickSubmit'
  },

  behaviors: {
    TaskBehavior: {},
    ToggleBehavoir: {
      toggles: [
        {
          target: '.reviewer-row',
          group: '[name="actionId"]',
          valuesToShow: '3'
        }
      ]
    }
  },

  onBeforeRender: function() {
    var self = this;

    var allowSendToPreviouseReviewer =
      modelGet(self.model, 'examDetails.currentReviewerRoleId') === _.first(self.model.get('reviewRoles')).id;

    var examActions = _.filter(modelGet(this.model, 'examActions'), function(action) {
      if (allowSendToPreviouseReviewer) {
        return _.includes(['2', '3'], action.id);
      } else {
        return _.includes(['1', '2', '3'], action.id);
      }
    });

    this.model.set({
      examActions: examActions
    });
  },

  onBeforeShow: function() {
    var examDetails = this.model.get('examDetails') || {};
    var ppobAddress = examDetails.ppobAddress || {};
    var headerBarData = {
      filingType: examDetails.filingType,
      entityName: examDetails.entityName,
      entityType: examDetails.entityType,
      address: {
        addressLine1: ppobAddress.addressLine1,
        addressLine2: ppobAddress.addressLine2,
        city: ppobAddress.city,
        state: ppobAddress.state,
        postalCode: ppobAddress.postalCode,
        country: ppobAddress.country,
        county: ppobAddress.county
      }
    };

    var headerBarColumns = [
      {
        name: 'entityName',
        label: 'Entity Name',
        minWidth: '160px',
        maxWidth: '300px'
      },
      {
        name: 'entityType',
        label: 'Entity Type',
        minWidth: '160px',
        maxWidth: '300px'
      },
      {
        name: 'address',
        label: 'Principal Place of Business Address',
        minWidth: '160px',
        headerCell: HeaderBarAddressCellView
      }
    ];

    this.showChildView(
      'headerBar',
      new HeaderBarView({
        columns: new Backbone.Collection(headerBarColumns),
        model: new Backbone.Model(headerBarData)
      })
    );

    this.showChildView(
      'breadcrumb',
      new BreadcrumbView({
        breadcrumbs: [
          {
            link: '#dep/dashboard',
            text: 'Home'
          },
          {
            text: 'Review Exam'
          }
        ]
      })
    );

    _.each(
      modelGet(this.model, 'reviewRoles'),
      function(role) {
        this.ui.reviewRolesCheckboxes.filter('input[value=' + role.reviewRoleId + ']').prop('checked', true);
      }.bind(this)
    );

    var actionComments;
    if (modelGet(this.model, 'examDetails.actionComments')) {
      actionComments = '\n\n' + modelGet(this.model, 'examDetails.actionComments');
    } else {
      actionComments = '';
    }

    Syphon.deserialize(this, { actionComments: actionComments });
  },

  onClickSubmit: function(e) {
    e.preventDefault();
    var self = this;
    this.validate().done(function() {
      var l = Ladda.create(self.ui.submit[0]);
      l.start();
      SubmitReviewExamTask(self.getFormData())
        .done(function() {
          Radio.channel('navigate').trigger('show:int:dp:dashboard');
        })
        .fail(function() {
          l.stop();
        });
    });
  },

  onChangeAction: function() {
    var commentRequired = this.ui.actionDropdown.val() !== '2';
    toggleForm(this.ui.actionCommentsOptionalRow, !commentRequired);
    toggleForm(this.ui.actionCommentsRequiredRow, commentRequired);
  },

  validate: function() {
    return this.ui.reviewExamForm.parsley().whenValidate();
  },

  getFormData: function() {
    var formData = Syphon.serialize(this);

    _.extend(formData, {
      taskId: this.model.get('taskId'),
      examId: this.model.get('examId')
    });
    var timestamp = moment().format('MM/DD/YYYY h:mm A');
    var userName = Session.user.description;

    if (modelGet(this.model, 'examDetails.isCurrentReviewerTemp')) {
      formData.actionId = '2';
    }

    formData.actionComments = '[' + userName + ' - ' + timestamp + '] \n' + formData.actionComments;
    return formData;
  }
});
