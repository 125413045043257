/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Backbone = require('backbone');
var Marionette = require('backbone.marionette');
var Syphon = require('backbone.syphon');

var parsleyUtil = require('common/util/parsleyUtil');

var FileUploadView = require('common/views/FileUploadView');
var makeUploadCollection = require('common/util/makeUploadCollection');

var NonDepEntityFormOfficerCollectionView = require('./NonDepEntityFormOfficerCollectionView');

var tmplNonDepEntityFormSectionOfficersView = require('../templates/NonDepEntityFormSectionOfficersView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplNonDepEntityFormSectionOfficersView,

  className: 'nondep-entity-form-section-officers-view',

  behaviors: {
    RadioGroupToggleBehavior: {
      radios: [
        {
          target: '.officer-convicted-document-container',
          radioGroup: 'input[name="isOfficerConvicted"]',
          valueToShow: '1'
        }
      ]
    }
  },

  ui: {
    addOfficer: '.add-officer',
    officerForm: '.officer-form',
    officerCollectionContainer: '.officer-collection-container',
    isOfficerConvictedDocumentContainer: '.is-officer-convicted-document-container',
    missingOfficer: '.missing-officer'
  },

  regions: {
    officerCollectionContainer: '@ui.officerCollectionContainer',
    isOfficerConvictedDocumentContainer: '@ui.isOfficerConvictedDocumentContainer'
  },

  events: {
    'click @ui.addOfficer': 'onClickAddOfficer'
  },

  onBeforeShow: function() {
    Syphon.deserialize(this, this.model.toJSON());

    this.officers = new NonDepEntityFormOfficerCollectionView({
      viewMode: 'ext',
      officerTypes: this.model.get('entityOfficerTypes'),
      collection: new Backbone.Collection(this.model.get('entityOfficers'))
    });
    this.showChildView('officerCollectionContainer', this.officers);

    this.isOfficerConvictedDocument = new FileUploadView({
      collection: makeUploadCollection(this.model.get('isOfficerConvictedDocument')),
      isRequired: true,
      isReadOnly: this.options.isReadOnly,
      metadata: {
        documentTypeId: 10025
      }
    });
    this.showChildView('isOfficerConvictedDocumentContainer', this.isOfficerConvictedDocument);
  },

  onClickAddOfficer: function(e) {
    e.preventDefault();
    this.officers.collection.unshift({});
  },

  validateHasOneEntityOfficer: function() {
    var defer = $.Deferred();
    if (this.officers.collection.length > 0) {
      this.ui.missingOfficer.hide();
      defer.resolve();
    } else {
      this.ui.missingOfficer.show();
      defer.reject();
    }
    return defer.promise();
  },

  validate: function(config) {
    this.ui.officerCollectionContainer.parsley().reset();
    this.ui.missingOfficer.hide();
    var formData = this.getFormData();

    if (config.type === 'save') {
      // Turn off required field validation for this form
      parsleyUtil.disableRequiredValidation(this.ui.officerCollectionContainer);
    } else {
      // Turn on required field validation for this form
      parsleyUtil.enableRequiredValidation(this.ui.officerCollectionContainer);
    }
    var validationPromises = [this.ui.officerForm.parsley().whenValidate()];

    if (config.type === 'submit') {
      validationPromises = validationPromises.concat(this.validateHasOneEntityOfficer());
      if (formData.isOfficerConvicted === '1') {
        validationPromises.push(this.isOfficerConvictedDocument.validate(config));
      }
    }

    return $.when.apply(this, validationPromises);
  },

  getFormData: function() {
    var formData = Syphon.serialize(this);
    formData.officersList = this.officers.getFormData();
    if (formData.isOfficerConvicted === '1') {
      formData.isOfficerConvictedDocumentId = this.isOfficerConvictedDocument.getFirstFileId();
    }
    return formData;
  }
});
