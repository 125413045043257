var Handlebars = require("handlebars/dist/handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "            "
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? depth0.address : depth0)) != null ? stack1.county : stack1), depth0))
    + "\n";
},"3":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "        <a href=\"#nondep/entity/"
    + alias4(((helper = (helper = helpers.entityId || (depth0 != null ? depth0.entityId : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"entityId","hash":{},"data":data}) : helper)))
    + "/location/change/"
    + alias4(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\" role=\"button\" class=\"btn btn-sm btn-primary\">\n          <span class=\"fa fa-external-link\" aria-hidden=\"true\"></span>\n          <span>Edit</span>\n        </a>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "\n<div class=\"panel panel-default panel-entity-location panel-row-view\">\n  <div class=\"panel-body row\">\n    <div class=\"col-sm-4\">\n      <div class=\"row m-b-sm\">\n        <label class=\"control-label col-sm-4 col-lg-2\">Address</label>\n        <div class=\"location-address col-sm-8 col-lg-10\"></div>\n        <div class=\"col-sm-offset-4 col-lg-offset-2 col-sm-8 col-lg-10\">\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.address : depth0)) != null ? stack1.county : stack1),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "        </div>\n      </div>\n      <div class=\"row\">\n        <div class=\"col-sm-12 col-lg-6\">\n          <div class=\"row\">\n            <label class=\"control-label col-sm-4 col-lg-4\">Phone</label>\n            <p class=\"col-sm-8 col-lg-8\">"
    + alias4(((helper = (helper = helpers.officePhoneNumber || (depth0 != null ? depth0.officePhoneNumber : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"officePhoneNumber","hash":{},"data":data}) : helper)))
    + "</p>\n          </div>\n        </div>\n        <div class=\"col-sm-12 col-lg-6\">\n          <div class=\"row\">\n            <label class=\"control-label col-sm-4 col-lg-4\">Fax</label>\n            <p class=\"col-sm-8 col-lg-8\">"
    + alias4(((helper = (helper = helpers.officeFaxNumber || (depth0 != null ? depth0.officeFaxNumber : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"officeFaxNumber","hash":{},"data":data}) : helper)))
    + "</p>\n          </div>\n        </div>\n      </div>\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.canEditLocation : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "      <p class=\"ppob-view hidden\"><i class=\"fa fa-building-o p-xs\"></i>Headquarters</p>\n    </div>\n    <div class=\"licenses col-sm-8\"></div>\n  </div>\n</div>";
},"useData":true});