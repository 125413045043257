/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var Backbone = require('backbone');
var Marionette = require('backbone.marionette');
var Radio = require('backbone.radio');

var UIUtil = require('psa-core/util/ui');

var userHasRole = require('common/util/userHasRole');
var modelGet = require('common/util/modelGet');
var ContentServerLinkView = require('common/views/ContentServerLinkView');
var SideNavSectionView = require('common/views/SideNavSectionView');
var BreadcrumbView = require('common/views/BreadcrumbView');
var DropdownView = require('common/views/DropdownView');
var LegalHoldLabelView = require('common/views/LegalHoldLabelView');

var DepEntityConfirmCreateFilingModalView = require('./DepEntityConfirmCreateFilingModalView');

var DepEntityMainSectionView = require('./DepEntityMainSectionView');

var tmplDepIntEntityDashboardView = require('../templates/DepIntEntityDashboardView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplDepIntEntityDashboardView,

  className: 'dep-int-entity-dashboard-view container-fluid m-b-lg',

  behaviors: {
    ReadOnlyFormBehavior: {}
  },

  ui: {
    legalHoldLabelContainer: '.legal-hold-label-container',
    entityWrapper: '.entity-wrapper',
    csLinkContainer: '.cs-link-container',
    entityActionDropDown: '.dropdown-container',
    breadcrumb: '.breadcrumb-container',
    callReportLinkContainer: '.call-report-link-container'
  },

  regions: {
    legalHoldLabelContainer: '@ui.legalHoldLabelContainer',
    entityWrapper: '@ui.entityWrapper',
    csLinkContainer: '@ui.csLinkContainer',
    callReportLinkContainer: '@ui.callReportLinkContainer',
    entityActionDropDown: '@ui.entityActionDropDown',
    breadcrumb: '@ui.breadcrumb'
  },

  onBeforeShow: function() {
    var entity = this.model.get('entity') || {};
    var entityId = modelGet(this.model, 'entity.entityId');
    var entityTypeId = modelGet(this.model, 'entity.entityTypeId');
    var entityCategoryId = modelGet(this.model, 'entity.entityCategoryId');
    var hasLegalHold = this.model.get('hasLegalHold');
    var viewMode = this.options.viewMode;
    var charterTypeId = modelGet(this.model, 'entity.charterTypeId');

    if (hasLegalHold) {
      this.showChildView(
        'legalHoldLabelContainer',
        new LegalHoldLabelView({
          model: new Backbone.Model({
            objectName: 'entity'
          })
        })
      );
    }

    //Show Call Reports folder for Trust
    if (entityCategoryId === '3') {
      this.showChildView(
        'callReportLinkContainer',
        new ContentServerLinkView({
          model: new Backbone.Model({
            buttonText: 'Open Call Reports',
            contentServerId: entity.callReportsCSFolderId
          })
        })
      );
    }

    this.showChildView(
      'csLinkContainer',
      new ContentServerLinkView({
        model: new Backbone.Model({
          buttonText: 'Open Entity Folder',
          contentServerId: entity.CSFolderId
        })
      })
    );

    this.showChildView(
      'breadcrumb',
      new BreadcrumbView({
        breadcrumbs: [
          {
            link: '#dep/dashboard',
            text: 'Home'
          },
          {
            text: entity.entityName
          }
        ]
      })
    );

    var dropDownOptions = [
      {
        text: 'Edit Main Information',
        link: '#dep/entity/' + entityId + '/edit-main'
      },
      {
        text: 'Edit Legal Hold Status',
        link: '#dep/entity/' + entityId + '/edit-legal-hold-status'
      },
      {
        text: 'Merge Entity',
        link: '#dep/entity/' + entityId + '/merge-entity'
      }
    ];

    var dropDownWorkflowOptions = [
      {
        text: 'Submit Generic Filing',
        onClick: this.onClickSubmitGF.bind(this)
      }
    ];
    var dropDownCaseManagementOptions = [];

    var sideNavTabs = [
      {
        id: 'main',
        title: 'Main',
        view: new DepEntityMainSectionView({
          model: new Backbone.Model({
            entity: this.model.get('entity'),
            exams: this.model.get('exams'),
            hcExams: this.model.get('hcExams')
          })
        })
      },
      {
        id: 'pendingTasks',
        title: 'Pending Tasks',
        view: Radio.channel('dp').request('build:pending-tasks-section-view', entityId)
      }
    ];

    var entityTypeHasFiling = ['1', '2', '4', '11', '13', '21', '22', '24'];
    var showFiling = _.contains(entityTypeHasFiling, entityTypeId);
    if (showFiling) {
      sideNavTabs.push({
        id: 'draftFilings',
        title: 'Draft Filings',
        view: Radio.channel('dp').request('build:int-draft-filings-section-view', entityId)
      });
      sideNavTabs.push({
        id: 'filings',
        title: 'Filings',
        view: Radio.channel('dp').request('build:filings-section-view', entityId)
      });
    }

    if (entityCategoryId === '3') {
      dropDownCaseManagementOptions.push({
        text: 'Add Call Report',
        link: '#dep/entity/' + entityId + '/new-call-report'
      });
      sideNavTabs.push({
        id: 'callReport',
        title: 'Call Reports',
        view: Radio.channel('dp').request('build:entity-call-report-view', entityId)
      });
    }

    var entityTypeHasExam = ['1', '2', '3', '10', '11', '12', '21', '23'];
    var showExam = _.contains(entityTypeHasExam, entityTypeId);
    if (showExam) {
      sideNavTabs.push({
        id: 'examinations',
        title: 'Examinations',
        view: Radio.channel('dp').request('build:exam-section-view', entityId, entityTypeId)
      });
    }

    var entityTypeHasContact = ['1', '2', '4', '11', '13', '21', '22', '24'];
    var showContact = _.contains(entityTypeHasContact, entityTypeId);
    if (showContact) {
      dropDownCaseManagementOptions.push({
        text: 'Add Contact',
        link: '#dep/entity/' + entityId + '/new-contact'
      });
      sideNavTabs.push({
        id: 'contacts',
        title: 'Contacts',
        view: Radio.channel('dp').request('build:contacts-section-view', entityId)
      });
    }

    var entityTypeHasLocation = ['1', '2', '3', '4', '10', '11', '12', '13', '21', '22', '23', '24'];
    var isForeignFudiciary = _.contains(['24'], entityTypeId);
    var showLocation = _.contains(entityTypeHasLocation, entityTypeId);
    if (showLocation) {
      dropDownCaseManagementOptions.push({
        // For Foreign Fudiciary uses 'Offices' instead of 'Locations'
        text: isForeignFudiciary ? 'Add Office' : 'Add Location',
        link: '#dep/entity/' + entityId + '/new-location'
      });
      sideNavTabs.push({
        id: 'locations',
        title: isForeignFudiciary ? 'Offices' : 'Locations',
        view: Radio.channel('dp').request('build:entity-location-view', {
          entityId: entityId,
          entityCategoryId: entityCategoryId,
          entityTypeId: entityTypeId
        })
      });
    }

    var entityTypeHasOfficer = ['1', '2', '11', '21', '22'];
    var showOfficer = _.contains(entityTypeHasOfficer, entityTypeId);
    if (showOfficer) {
      dropDownOptions.splice(2, 0, {
        text: 'Bulk Edit Officer',
        link: '#dep/entity/' + entityId + '/bulk-edit-officer'
      });
      dropDownCaseManagementOptions.push({
        text: 'Add Officer',
        link: '#dep/entity/' + entityId + '/new-officer'
      });
      sideNavTabs.push({
        id: 'officers',
        title: 'Officers',
        view: Radio.channel('dp').request('build:officers-section-view', entityId)
      });
    }

    var entityTypeHasDirector = ['1', '2', '11', '21', '22'];
    var isCUTrustHC = _.contains(['11', '21', '22'], entityTypeId);
    var showDirector = _.contains(entityTypeHasDirector, entityTypeId);
    if (showDirector) {
      // For Banks, Credit Unions, and Trusts label uses 'Director' instead of 'Directors-Trustees'

      dropDownOptions.splice(2, 0, {
        text: isCUTrustHC ? 'Bulk Edit Director' : 'Bulk Edit Director-Trustee',
        link: '#dep/entity/' + entityId + '/bulk-edit-director'
      });

      dropDownCaseManagementOptions = dropDownCaseManagementOptions.concat([
        {
          text: isCUTrustHC ? 'Add Director' : 'Add Director-Trustee',
          link: '#dep/entity/' + entityId + '/new-director'
        },
        {
          text: 'Add Governance',
          link: '#dep/entity/' + entityId + '/new-governance'
        },
        {
          text: 'Add Board/Annual Meeting Requirements',
          link: '#dep/entity/' + entityId + '/new-board-annual-meeting-requirements'
        }
      ]);
      sideNavTabs.push({
        id: 'directorTrustees',
        title: isCUTrustHC ? 'Directors' : 'Directors-Trustees',
        view: Radio.channel('dp').request('build:directors-section-view', entityId)
      });
    }

    var entityTypeHasMRBA = ['1', '2', '3', '4', '5', '7', '10', '11', '12', '13', '14', '21', '22', '23', '25'];
    var showMRBA = _.contains(entityTypeHasMRBA, entityTypeId);
    if (showMRBA) {
      // For Credit Union label uses 'Document of Resolution'
      // instead of 'MRBA'
      dropDownCaseManagementOptions.push({
        text: _.contains(['11', '12', '13', '14'], entityTypeId) ? 'Add Document of Resolution' : 'Add MRBA',
        link: '#dep/entity/' + entityId + '/new-mrba'
      });
      sideNavTabs.push({
        id: 'mrba',
        title: _.contains(['11', '12', '13', '14'], entityTypeId) ? 'Documents of Resolution' : 'MRBAs',
        view: Radio.channel('dp').request('build:mrba-section-view', entityId)
      });
    }

    var entityTypeHasEnforcement = ['1', '2', '3', '10', '11', '12', '21', '22', '23'];
    var showEnforcement = _.contains(entityTypeHasEnforcement, entityTypeId);
    if (showEnforcement) {
      dropDownCaseManagementOptions.push({
        text: 'Add Enforcement Action',
        link: '#dep/entity/' + entityId + '/new-enforcement'
      });
      sideNavTabs.push({
        id: 'enforcement',
        title: 'Enforcement Actions',
        view: Radio.channel('dp').request('build:entity-enforcement-view', entityId, entityTypeId)
      });
    }

    var entityTypeHasIrregularity = ['1', '2', '5', '11', '14', '21', '22', '25'];
    var showIrregularity = _.contains(entityTypeHasIrregularity, entityTypeId);
    if (showIrregularity) {
      dropDownCaseManagementOptions.push({
        text: 'Add Irregularity',
        link: '#dep/entity/' + entityId + '/new-irregularity'
      });
      sideNavTabs.push({
        id: 'irregularities',
        title: _.contains(['3', '12', '23'], entityTypeId) ? 'Miscellaneous' : 'Irregularities',
        view: Radio.channel('dp').request('build:entity-irregularity-view', entityId)
      });
    }

    var isCIF = entity.isCollectiveInvestmentFund;
    var entityTypeHasAudit = ['1', '11', '21', '22'];
    var showAudit = _.contains(entityTypeHasAudit, entityTypeId);
    if (showAudit) {
      dropDownCaseManagementOptions.push({
        text: 'Add Audit',
        link: '#dep/entity/' + entityId + '/new-audit'
      });
      sideNavTabs.push({
        id: 'audits',
        title: 'Audits',
        view: Radio.channel('dp').request('build:entity-audit-view', {
          entityId: entityId,
          entityCategoryId: entityCategoryId,
          isCIF: isCIF
        })
      });
    }

    var entityTypeHasSubsidiary = ['1', '2', '11', '21', '22'];
    var isCreditUnion = entityTypeId === '11';
    var showSubsidiary = _.contains(entityTypeHasSubsidiary, entityTypeId);
    // For Credit Unions label uses 'CUSO' instead of 'Subsidiary'
    if (showSubsidiary) {
      dropDownCaseManagementOptions.push({
        text: isCreditUnion ? 'Add CUSO' : 'Add Subsidiary',
        link: '#dep/entity/' + entityId + '/new-subsidiary'
      });
      sideNavTabs.push({
        id: 'subsidiaries',
        title: isCreditUnion ? 'CUSOs' : 'Subsidiaries',
        view: Radio.channel('dp').request('build:entity-subsidiary-view', entityId, entityTypeId)
      });
    }

    var entityTypeHasSEG = ['11', '13'];
    var showSEG = _.contains(entityTypeHasSEG, entityTypeId);
    if (showSEG) {
      dropDownCaseManagementOptions.push({
        text: 'Add SEG',
        link: '#dep/entity/' + entityId + '/new-seg'
      });
      sideNavTabs.push({
        id: 'seg',
        title: 'SEGs',
        view: Radio.channel('dp').request('build:entity-seg-view', entityId)
      });
    }

    // CIF/CTF available for 1. Trust, charter type Trust Company only
    // 2. Bank, charter types Bank and Trust, Savings Bank with Trust
    var entityCharterTypeHasCIF = ['2', '21', '31'];
    var showCIF = entity.isCollectiveInvestmentFund === '1' && _.contains(entityCharterTypeHasCIF, charterTypeId);
    if (showCIF) {
      dropDownCaseManagementOptions.push({
        text: 'Add CIF/CTF',
        link: '#dep/entity/' + entityId + '/new-fund'
      });
      sideNavTabs.push({
        id: 'cif',
        title: 'CIF/CTF',
        view: Radio.channel('dp').request('build:entity-fund-view', entityId, entityCategoryId)
      });
    }

    var entityTypeHasTracking = ['1', '2', '3', '4', '10', '11', '12', '13', '21', '22', '23'];
    var showTracking = _.contains(entityTypeHasTracking, entityTypeId);
    if (showTracking) {
      dropDownCaseManagementOptions.push(
        {
          text: 'Add Tracking',
          link: '#dep/entity/' + entityId + '/new-tracking'
        },
        {
          text: 'Add Risk Matrix',
          link: '#dep/entity/' + entityId + '/new-risk-matrix'
        },
        {
          text: 'Add Key Third-Party Vendor',
          link: '#dep/entity/' + entityId + '/new-key-third-party-vendor'
        }
      );
      sideNavTabs.push({
        id: 'tracking',
        title: 'Tracking',
        view: Radio.channel('dp').request('build:entity-tracking-view', entityId)
      });
    }

    var examInitialProcessorRole = '';

    if (entityCategoryId === '1') {
      examInitialProcessorRole = 'DP - Exam Initial Processor - Bank';
    } else if (entityCategoryId === '2') {
      examInitialProcessorRole = 'DP - Exam Initial Processor - Trust';
    } else if (entityCategoryId === '3') {
      examInitialProcessorRole = 'DP - Exam Initial Processor - Credit Union';
    }

    var examRole = userHasRole(examInitialProcessorRole) || {};
    if (examRole && showExam) {
      dropDownWorkflowOptions.push({
        text: 'Initiate Exam',
        link: '#dep/entity/' + entityId + '/new-exam'
      });
    }

    if (dropDownWorkflowOptions.length > 0) {
      dropDownOptions = dropDownOptions.concat(
        {
          isDivider: true
        },
        dropDownWorkflowOptions
      );
    }

    if (dropDownCaseManagementOptions.length > 0) {
      dropDownOptions = dropDownOptions.concat(
        {
          isDivider: true
        },
        dropDownCaseManagementOptions
      );
    }

    // Initiate Correspondence dropdown option
    var isManager = userHasRole('DP - AA1' || 'AO1');
    if (isManager) {
      dropDownOptions = dropDownOptions.concat([
        {
          isDivider: true
        },
        {
          text: 'Initiate Correspondence',
          link: '#dep/entity/' + entityId + '/initiate-correspondence'
        }
      ]);
    }

    // Always show Notes and Audit Log
    sideNavTabs = sideNavTabs.concat([
      {
        id: 'userManagement',
        title: 'User Management',
        view: Radio.channel('dp').request('build:user-management-section-view', entityId, viewMode)
      },
      {
        id: 'notes',
        title: 'Notes',
        view: Radio.channel('dp').request('build:notes-view', {
          objectId: entityId,
          objectTypeId: 'ENTITY'
        })
      },
      {
        id: 'auditLog',
        title: 'Audit Log',
        view: Radio.channel('dp').request('build:audit-log-view', {
          objectId: entityId,
          objectTypeId: 'ENTITY'
        })
      }
    ]);
    this.showChildView(
      'entityActionDropDown',
      new DropdownView({
        rightAlign: true,
        model: new Backbone.Model({
          name: 'Entity Actions',
          options: dropDownOptions
        })
      })
    );

    this.entity = new SideNavSectionView({
      useNavigationButtons: this.options.isReadOnly,
      collection: new Backbone.Collection(sideNavTabs)
    });
    this.showChildView('entityWrapper', this.entity);
  },

  onClickSubmitGF: function() {
    var entityId = modelGet(this.model, 'entity.entityId');
    var filingTypeMap = { '1': '100', '2': '200', '3': '300' };
    var categoryId = modelGet(this.model, 'entity.entityCategoryId');
    var filingTypeId = filingTypeMap[categoryId];

    UIUtil.showModal({
      title: 'Confirm Creating Filing Draft',
      view: new DepEntityConfirmCreateFilingModalView({
        entityId: entityId,
        isExternalFiling: '0',
        filingTypeId: filingTypeId
      })
    }).done(function(response) {
      Radio.channel('navigate').trigger('show:int:dp:draft-filing', response.filingId);
    });
  }
});
