/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Marionette = require('backbone.marionette');
var Radio = require('backbone.radio');

require('../styles/ExternalFooterView.css');
var tmplExternalFooterView = require('../templates/ExternalFooterView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplExternalFooterView,

  className: 'external-footer-view collapse',

  tagName: 'footer',

  onRender: function() {
    var self = this;

    Radio.channel('footer').on('show:external', function() {
      self.$el.removeClass('collapse');
    });

    Radio.channel('footer').on('hide:footer', function() {
      self.$el.addClass('collapse');
    });
  }
});
