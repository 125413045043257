/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Backbone = require('backbone');
var NProgress = require('nprogress');

var NavUtils = require('psa-core/util/nav');

var Scaffold = require('common/layout/Scaffold');

var GetAllEntityLookups = require('../../../depository/common/services/GetAllEntityLookups');
var GetAllCategories = require('../../common/services/GetAllCategories');
var GetAllWorksites = require('../../common/services/GetAllWorksites');
var GetTimesheet = require('../../common/services/GetTimesheet');
var GetTimeTrackingLookupsByDepartmentName = require('../../common/services/GetTimeTrackingLookupsByDepartmentName');
var TimeTrkPeriodSelectionView = require('../../common/views/TimeTrkPeriodSelectionView');

module.exports = function() {
  NProgress.start();
  NProgress.set(0.6);

  //Possible values: 2000 (Dep), 3000 (Sec)
  var depDptId = '2000';

  $.when(
    GetAllCategories(depDptId),
    GetTimesheet({ departmentId: depDptId }),
    GetTimeTrackingLookupsByDepartmentName(),
    GetAllEntityLookups(),
    GetAllWorksites()
  ).done(function(categories, timesheet, lookups, entityLookups, worksites) {
    NavUtils.navigate('#dep/time-tracking');
    Scaffold.content.show(
      new TimeTrkPeriodSelectionView({
        model: new Backbone.Model({
          dptId: depDptId,
          timesheetId: timesheet.timesheetId,
          payPeriod: timesheet.payPeriod,
          categories: categories,
          timesheet: timesheet,
          periods: lookups.timesheetPeriods,
          years: lookups.timesheetYears,
          entityCategories: entityLookups.entityCategories,
          entityTypes: entityLookups.entityTypes,
          worksites: worksites
        })
      })
    );
    NProgress.done();
  });
};
