/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var config = require('../../config/Config');
var cleanUpResponse = require('common/util/cleanUpResponse');

module.exports = function(formData) {
  var deferred = $.Deferred();
  $.soap({
    url: config.getAnonymousDoBSUrl(),
    body: {
      UpdateComplaintForm: {
        $xmlns: 'http://dobs.pa.gov/complaint/db/1.0',
        tuple: {
          new: {
            ComplaintForm: {
              fname: formData.firstname,
              lname: formData.lastname,
              title: formData.title,
              phonenumber: formData.phonenumber,
              email: formData.email,
              reasonforcontact: formData.message,
              street: formData.address1,
              accountnumber: formData.accountnumber,
              city: formData.city,
              state: formData.state,
              zipcode: formData.zip,
              institutionstreet: formData.institutionAddress,
              institutioncitystatezip: formData.institutionCityStateZip,
              InstitutionName: formData.institutionName
            }
          }
        }
      }
    },
    blueprint: {
      id: '$..tuple.new.ComplaintForm.id'
    }
  })
    .done(function(response) {
      cleanUpResponse(response);
      deferred.resolve(response);
    })
    .fail(function(err) {
      deferred.reject();
    });

  return deferred.promise();
};

