/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Marionette = require('backbone.marionette');

var PageableCollection = require('psa-backbone/models/PageableCollection');
var GridView = require('psa-marionette/views/GridView');

var ButtonLinkCell = require('common/backgrid/ButtonLinkCell');
var MomentCell = require('common/backgrid/MomentCell');

var NonDepInternalSidebarNavView = require('../../common/views/NonDepInternalSidebarNavView');
var NonDepInternalApplicationFiltersView = require('./NonDepInternalApplicationFiltersView');

require('../styles/NonDepInternalActiveApplicationsView.css');
var tmplNonDepInternalActiveApplications = require('../templates/NonDepInternalActiveApplicationsView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplNonDepInternalActiveApplications,

  className: 'non-dep-active-applications-view container-fluid',

  ui: {
    sideBar: '.side-bar-container',
    applicationFilters: '.application-filters',
    activeApplications: '.active-applications-container'
  },

  regions: {
    sideBar: '@ui.sideBar',
    applicationFilters: '@ui.applicationFilters',
    activeApplications: '@ui.activeApplications'
  },

  childEvents: {
    'apply:filters': 'onChildClickApplyFilters'
  },

  onChildClickApplyFilters: function(childView) {
    // Get filtered list from NonDepInternalApplicationFiltersView
    var models = childView.shadowCollection.models;
    this.shadowCollection.fullCollection.reset(models);
  },

  onBeforeShow: function() {
    this.showChildView('sideBar', new NonDepInternalSidebarNavView());
    this.showChildView(
      'applicationFilters',
      new NonDepInternalApplicationFiltersView({ showOwnerSearch: true, model: this.model })
    );

    this.collection = new PageableCollection(this.model.get('applications'), { pageSize: 25 });
    this.shadowCollection = new PageableCollection(this.model.get('applications'), { pageSize: 25 });

    var activeApplicationsGridView = new GridView({
      columns: [
        {
          name: 'entityName',
          label: 'Entity Name',
          cell: 'string',
          headerCell: 'custom',
          width: 15,
          editable: false
        },
        {
          name: 'tin',
          label: 'Federal ID',
          cell: 'string',
          headerCell: 'custom',
          width: 10,
          editable: false
        },
        {
          name: 'nmlsEntityId',
          label: 'NMLS Entity ID',
          cell: 'string',
          headerCell: 'custom',
          width: 10,
          editable: false
        },
        {
          name: 'applicationType',
          label: 'Application Type',
          cell: 'string',
          headerCell: 'custom',
          width: 10,
          editable: false
        },
        {
          name: 'licenseType',
          label: 'License Type',
          cell: 'string',
          headerCell: 'custom',
          width: 15,
          editable: false
        },
        {
          name: 'applicationStatus',
          label: 'Status',
          cell: 'string',
          headerCell: 'custom',
          width: 15,
          editable: false
        },
        {
          name: 'owner',
          label: 'Owner',
          cell: 'string',
          headerCell: 'custom',
          width: 10,
          editable: false
        },
        {
          name: 'submissionDate',
          label: 'Submission Date',
          cell: MomentCell.MomentCell,
          formatter: MomentCell.MomentDateTimeFormatter.extend({
            emptyValue: '--'
          }),
          sortValue: MomentCell.MomentSortValue,
          headerCell: 'custom',
          width: 15,
          editable: false
        },
        {
          name: '',
          label: '',
          cell: ButtonLinkCell.extend({
            name: 'Open',
            buttonClass: 'btn-primary btn-sm',
            href: function(model) {
              return '#nondep/application/' + model.get('id') + '/dashboard';
            }
          }),
          headerCell: 'custom',
          width: 10,
          editable: false,
          sortable: false
        }
      ],
      collection: this.shadowCollection,
      emptyText: 'No Active Applications'
    });
    this.showChildView('activeApplications', activeApplicationsGridView);

    activeApplicationsGridView.getViewGrid().sort('submissionDate', 'descending');
  }
});
