/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var Marionette = require('backbone.marionette');
var Syphon = require('backbone.syphon');
var Radio = require('backbone.radio');
var Ladda = require('ladda');

var parsleyUtil = require('common/util/parsleyUtil');
var EmptyView = require('common/views/EmptyView');
var modelGet = require('common/util/modelGet');

var DepCollectiveInvestmentFundAddSubFormView = require('./DepCollectiveInvestmentFundAddSubFormView');
var DepCollectiveInvestmentFundAmendSubFormView = require('./DepCollectiveInvestmentFundAmendSubFormView');
var DepCollectiveInvestmentFundTerminateSubFormView = require('./DepCollectiveInvestmentFundTerminateSubFormView');
var SubmitCollectiveInvestmentFundFiling = require('../../services/SubmitCollectiveInvestmentFundFiling');

var tmplDepCollectiveInvestmentFundFundSectionView = require('./DepCollectiveInvestmentFundFundSectionView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplDepCollectiveInvestmentFundFundSectionView,

  className: 'dep-collective-investment-fund-fund-section-view',

  behaviors: {
    ReadOnlyFormBehavior: {}
  },

  ui: {
    fundForm: '.fund-form',
    fundActionType: '.fund-action-type',
    actionForm: '.action-form',
    submit: '.submit'
  },

  regions: {
    actionForm: '@ui.actionForm'
  },

  events: {
    'change @ui.fundActionType': 'onChangeFundActionType',
    'click @ui.submit': 'onClickSubmit'
  },

  templateHelpers: function() {
    return {
      isReadOnly: this.options.isReadOnly,
      mode: this.options.mode
    };
  },

  onBeforeShow: function() {
    var filingContent = this.model.get('filingContent') || {};

    Syphon.deserialize(this, filingContent);

    var fundActionId = modelGet(this.model, 'filingContent.fundActionId');
    if (fundActionId === '1') {
      this.actionForm = new DepCollectiveInvestmentFundAddSubFormView({
        model: this.model,
        childIndex: this.options.childIndex,
        isReadOnly: this.options.isReadOnly,
        mode: this.options.mode
      });
    } else if (fundActionId === '2') {
      this.actionForm = new DepCollectiveInvestmentFundAmendSubFormView({
        model: this.model,
        childIndex: this.options.childIndex,
        isReadOnly: this.options.isReadOnly,
        mode: this.options.mode
      });
    } else if (fundActionId === '3') {
      this.actionForm = new DepCollectiveInvestmentFundTerminateSubFormView({
        model: this.model,
        childIndex: this.options.childIndex,
        isReadOnly: this.options.isReadOnly
      });
    } else {
      this.actionForm = new EmptyView();
    }
    this.showChildView('actionForm', this.actionForm);
  },

  onChangeFundActionType: function() {
    var fundActionTypeId = this.ui.fundActionType.val();
    var add = fundActionTypeId === '1';
    var amend = fundActionTypeId === '2';
    var terminate = fundActionTypeId === '3';

    var filingContent = this.model.get('filingContent') || {};
    var newFilingContent = {
      entityId: filingContent.entityId,
      filingId: filingContent.filingId,
      trustFunds: filingContent.trustFunds,
      trustSubfunds: filingContent.trustSubfunds
    };
    var newModel = new Backbone.Model({
      subFundActions: this.model.get('subFundActions'),
      filingContent: newFilingContent
    });
    if (add) {
      this.actionForm = new DepCollectiveInvestmentFundAddSubFormView({
        model: newModel,
        childIndex: this.options.childIndex,
        isReadOnly: this.options.isReadOnly
      });
    } else if (amend) {
      this.actionForm = new DepCollectiveInvestmentFundAmendSubFormView({
        model: newModel,
        childIndex: this.options.childIndex,
        isReadOnly: this.options.isReadOnly
      });
    } else if (terminate) {
      this.actionForm = new DepCollectiveInvestmentFundTerminateSubFormView({
        model: newModel,
        childIndex: this.options.childIndex,
        isReadOnly: this.options.isReadOnly
      });
    } else {
      this.actionForm = new EmptyView();
    }
    this.showChildView('actionForm', this.actionForm);
  },

  onClickSubmit: function(e) {
    e.preventDefault();
    var self = this;

    var l = Ladda.create(this.ui.submit[0]);
    this.sideNavView.validateAll({ type: 'submit' }).done(function() {
      l.start();

      var formData = self.sideNavView.getFormData();
      formData.filingId = self.model.get('filingId');
      formData.collectiveInvestmentFundFilingId = self.model.get('collectiveInvestmentFundFilingId');

      SubmitCollectiveInvestmentFundFiling(formData)
        .done(function() {
          Radio.channel('dp:draft-filing').trigger('proceed-to-payment', l);
          Radio.channel('dp:draft-filing').trigger('submit', l);
          Radio.channel('dp:submit-filing').trigger('resubmit', l);
        })
        .fail(function() {
          l.stop();
        });
    });
  },

  validate: function(config) {
    this.ui.fundForm.parsley().reset();
    var fundActionTypeId = this.ui.fundActionType.val();
    if (fundActionTypeId) {
      this.actionForm.removeValidationErrors();
    }

    if (config.type === 'save') {
      // Turn off required field validation for this form
      parsleyUtil.disableRequiredValidation(this.ui.fundForm);
    } else {
      var validationPromises = [this.ui.fundForm.parsley().whenValidate()];
      if (fundActionTypeId) {
        validationPromises.push(this.actionForm.validate(config));
      }
      // Turn on required field validation for this form
      parsleyUtil.enableRequiredValidation(this.ui.fundForm);
    }
    return $.when.apply($, validationPromises);
  },

  getFormData: function() {
    var formData = Syphon.serialize(this.ui.fundForm);
    if (formData.fundActionId && _.isFunction(this.actionForm.getFormData)) {
      _.extend(formData, this.actionForm.getFormData());
    }
    return formData;
  }
});
