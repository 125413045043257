/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var moment = require('moment');

var cleanUpResponse = require('common/util/cleanUpResponse');

module.exports = function(organizationId) {
  var deferred = $.Deferred();

  $.soap({
    body: {
      GetOrganizationLicenseByOrganization: {
        $xmlns: 'http://dobs.pa.gov/sc/organizationlicense/bpm/1.0',
        OrganizationID: organizationId
      }
    },
    blueprintArrays: ['GetOrganizationLicenseByOrganizationResponse.SC_OrganizationLicense'],
    blueprint: {
      licenses: [
        '$..SC_OrganizationLicense',
        {
          id: '$..ID',
          organizationId: '$..OrganizationID',
          licenseStatusDate: '$..LicenseStatusDate',
          status: '$..Status',
          type: '$..Type'
        }
      ]
    }
  })
    .done(function(response) {
      cleanUpResponse(response);

      _.each(response.licenses, function(license) {
        license.status = license.status || '--';
        license.type = license.type || '--';
        if (license.licenseStatusDate) {
          license.licenseStatusDate = moment(license.licenseStatusDate, 'YYYY-MM-DD');
        }
      });
      deferred.resolve(response.licenses);
    })
    .fail(function(err) {
      deferred.reject();
    });

  return deferred.promise();
};
