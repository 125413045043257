/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var moment = require('moment');

var cleanUpResponse = require('common/util/cleanUpResponse');

module.exports = function(data) {
  var deferred = $.Deferred();

  $.soap({
    body: {
      SubmitIrregularity: {
        $xmlns: 'http://dobs.pa.gov/dp/irregularity/bpm/1.0',
        EntityID: data.entityId,
        IrregularityID: data.irregularityId,
        EventDate: data.eventDate ? moment(data.eventDate, 'MM-DD-YYYY').format('YYYY-MM-DD') : null,
        CategoryID: data.categoryId,
        SubCategoryID: data.subcategoryId,
        CategoryOtherDetails: data.categoryOtherDetails,
        Address: (function() {
          if (data.address) {
            return {
              AddressLine1: data.address.addressLine1,
              AddressLine2: data.address.addressLine2,
              AddressLine3: data.address.addressLine3,
              City: data.address.city,
              State: data.address.state,
              PostalCode: data.address.postalCode,
              Country: data.address.country,
              County: data.address.county
            };
          }
          return {};
        })(),
        FirstName: data.firstName,
        MiddleName: data.middleName,
        LastName: data.lastName,
        DOB: data.dob ? moment(data.dob, 'MM-DD-YYYY').format('YYYY-MM-DD') : null,
        RelationToFinancialInstitution: data.relationToFinancialInstitution,
        IsLawReferred: data.isLawReferred,
        IsFedAction: data.isFedAction,
        IsMonetaryPenalty: data.isMonetaryPenalty,
        IsBanned: data.isBanned,
        IsDeleted: data.isDeleted,
        Comments: data.comments,
        IsReviewedByExaminer: data.isReviewedByExaminer,
        IsIncidentReportCompleted: data.isIncidentReportCompleted
      }
    },
    blueprint: {
      irregularityId: '$..ID'
    }
  })
    .done(function(response) {
      cleanUpResponse(response, { cleanUpTextKeys: true });
      deferred.resolve(response.irregularityId);
    })
    .fail(function(err) {
      deferred.reject(err);
    });

  return deferred.promise();
};
