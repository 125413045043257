/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');

module.exports = function(formData) {
  var section112IndividualNames = formData.section112IndividualNames || {};

  return $.soap({
    body: {
      SubmitFiling: {
        $xmlns: 'http://dobs.pa.gov/dp/filing/bpm/1.0',
        Filing: {
          FilingID: formData.filingId,
          RecordStatusID: formData.recordStatusId,
          DecisionDueDate: formData.decisionDueDate,
          IsAfterTheFactFilingRequired: formData.isAfterTheFactFilingRequired,
          ReceivedDate: formData.receivedDate,
          AcceptedDate: formData.acceptedDate,
          AcknowledgementSentDate: formData.acknowledgementSentDate,
          AcknowledgementNotificationDate: formData.acknowledgementNotificationDate,
          PubProofsGeneralOptionID: formData.pubProofsGeneralOptionId,
          PubProofsGeneralReceivedDate: formData.pubProofsGeneralReceivedDate,
          PubProofsGeneralExpireDate: formData.pubProofsGeneralExpireDate,
          PubProofsLegalOptionID: formData.pubProofsLegalOptionId,
          PubProofsLegalReceivedDate: formData.pubProofsLegalReceivedDate,
          PubProofsLegalExpireDate: formData.pubProofsLegalExpireDate,
          BulletinOptionID: formData.bulletinOptionId,
          BulletinPublishedDate: formData.bulletinPublishedDate,
          BulletinExpireDate: formData.bulletinExpireDate,
          InfoRequestMadeDate: formData.infoRequestMadeDate,
          InfoRequestReceivedDate: formData.infoRequestReceivedDate,
          ActionID: formData.actionId,
          ActionDate: formData.actionDate,
          IsFollowUpRequired: formData.isFollowUpRequired,
          FRBApprovalDate: formData.frbApprovalDate,
          FRBApprovalOptionID: formData.frbApprovalOptionId,
          FDICApprovalOptionID: formData.fdicApprovalOptionId,
          FDICApprovalDate: formData.fdicApprovalDate,
          NCUAApprovalOptionID: formData.ncuaApprovalOptionId,
          NCUAApprovalDate: formData.ncuaApprovalDate,
          SubsidiaryName: formData.subsidiaryName,
          SubsidiaryStatusID: formData.subsidiaryStatusId,
          ApprovalType: formData.approvalType,
          ApprovalSubsidiary: formData.approvalSubsidiary,
          ApprovalDate: formData.approvalDate,
          IsDeleted: formData.isDeleted,
          Comments: formData.comments,
          Section112IndividualNames: (function() {
            if (!section112IndividualNames.length) {
              return {};
            } else {
              return {
                Section112IndividualName: _.map(formData.section112IndividualNames, function(section112IndividualName) {
                  return {
                    Section112IndividualName: section112IndividualName.section112IndividualName
                  };
                })
              };
            }
          })()
        }
      }
    }
  });
};
