/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Marionette = require('backbone.marionette');
var Syphon = require('backbone.syphon');
var _ = require('underscore');

var GridView = require('psa-marionette/views/GridView');
var UIUtil = require('psa-core/util/ui');

var MultiButtonCell = require('common/backgrid/MultiButtonCell');
var modelGet = require('common/util/modelGet');
var parsleyUtil = require('common/util/parsleyUtil');

var DepCreditUnionSEGRequestGroupLocationModalView = require('./DepCreditUnionSEGRequestGroupLocationModalView');

var tmplDepCreditUnionSEGRequestGroupLocationView = require('./DepCreditUnionSEGRequestGroupLocationView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplDepCreditUnionSEGRequestGroupLocationView,

  className: 'dep-credit-union-seg-request-group-address-view',

  ui: {
    addressGrid: '.group-address-grid-container',
    newLocationButton: '.new-location-button',
    groupLocationForm: '.group-location-form',
    paymentAmountErrorAlert: '.payment-amount-error',
    individualTypesErrorAlert: '.individual-types-error',
    individualTypes: '.individualTypes',
    isAllHQ: '.is-all-hq',
    numHq: '.num-hq',
    numEmployessHQ: '#numEmployessHQ',
    numemployees: '.num-employees',
    nummembers: '.num-members',
    numStudents: '.num-students',
    numAlumni: '.num-alumni',
    numBoard: '.num-board',
    numOther: '.num-other',
    numEmployessLocation: '.num-employess-location',
    numMembersLocation: '.num-members-location',
    numStudentsLocation: '.num-students-location',
    numAlumniLocation: '.num-alumni-location',
    numBoardLocation: '.num-board-location',
    numOtherLocation: '.num-other-location'
  },

  regions: {
    addressGrid: '@ui.addressGrid'
  },

  events: {
    'click @ui.newLocationButton': 'onClickAddNewLocation',
    'click @ui.isAllHQ': 'onClickisAllHQ',
    'change @ui.individualTypes': 'onCheckindividualTypes'
  },

  onBeforeShow: function() {
    var filingContent = modelGet(this.model, 'filingContent');

    this.locationCollection = new Backbone.Collection(modelGet(this.model, 'filingContent.groupLocations'));
    this.setIndex();

    this.locationGridView = new GridView({
      emptyText: 'No Associated Group Locations',
      columns: [
        {
          name: 'index',
          label: '#',
          cell: 'string',
          headerCell: 'custom',
          width: 5,
          editable: false
        },
        {
          name: 'addressLine',
          label: 'Address',
          cell: 'string',
          headerCell: 'custom',
          width: 15,
          editable: false
        },
        {
          name: 'city',
          label: 'City',
          cell: 'string',
          headerCell: 'custom',
          width: 10,
          editable: false
        },
        {
          name: 'state',
          label: 'State',
          cell: 'string',
          headerCell: 'custom',
          width: 8,
          editable: false
        },
        {
          name: 'postalCode',
          label: 'Zip Code',
          cell: 'string',
          headerCell: 'custom',
          width: 10,
          editable: false
        },
        {
          name: 'county',
          label: 'County',
          cell: 'string',
          headerCell: 'custom',
          width: 10,
          editable: false
        },

        {
          name: 'employeeCount',
          label: 'Number of Individuals at this Location',
          cell: 'string',
          headerCell: 'custom',
          width: 33,
          editable: false
        },
        {
          name: '',
          label: '',
          cell: MultiButtonCell.extend({
            buttons: [
              {
                name: '',
                labelClass: 'fa fa-edit',
                buttonClass: 'btn-primary btn-sm',
                onClick: this.onClickEditLocation.bind(this)
              },
              {
                name: '',
                labelClass: 'fa fa-trash',
                buttonClass: 'btn-danger btn-sm',
                onClick: this.onClickDeleteLocation.bind(this)
              }
            ]
          }),
          sortable: false,
          editable: false
        }
      ],
      collection: this.locationCollection
    });

    this.showChildView('addressGrid', this.locationGridView);

    Syphon.deserialize(this, filingContent);
    this.setIndividualsHQ(Boolean(filingContent.isAllMembersHQ));
    this.onCheckindividualTypes();
    this.setIndividualsHQ();
  },

  setIndex: function(e) {
    var self = this;
    _.each(this.locationCollection.models, function(location) {
      location.set({ index: self.locationCollection.indexOf(location) + 1 });
    });
  },

  onClickAddNewLocation: function(e) {
    e.preventDefault();
    UIUtil.showModal({
      title: 'Add New Group Location',
      view: new DepCreditUnionSEGRequestGroupLocationModalView()
    }).done(
      function(newPayment) {
        this.locationCollection.add(newPayment);
        this.setIndex();
      }.bind(this)
    );
  },

  onClickEditLocation: function(e, model) {
    e.preventDefault();
    UIUtil.showModal({
      title: 'Edit Group Location',
      view: new DepCreditUnionSEGRequestGroupLocationModalView({ model: model })
    }).done(function(editedLocation) {
      model.set(editedLocation);
    });
  },

  onClickDeleteLocation: function(e, model) {
    e.preventDefault();
    this.locationCollection.remove(model);
    this.setIndex();
  },

  onClickisAllHQ: function(e) {
    this.setIndividualsHQ();
  },

  setIndividualsHQ: function() {
    var isAllHQ = this.ui.isAllHQ.filter(':checked').val() === '1';
    this.convertNonIntLocationCountValuesToZero();

    if (isAllHQ) {     
      var formData = Syphon.serialize(this.ui.groupLocationForm);
      var totalIndividuals =
        parseInt(formData.numEmployessLocation) +
        parseInt(formData.numMembersLocation) +
        parseInt(formData.numStudentsLocation) +
        parseInt(formData.numAlumniLocation) +
        parseInt(formData.numBoardLocation) +
        parseInt(formData.numOtherLocation);
      this.ui.numHq.val(totalIndividuals);
      this.ui.newLocationButton.hide();
      this.ui.addressGrid.hide();
      this.locationCollection.reset([]);
      this.ui.numHq.prop('disabled', true);
    } else {
      this.ui.numHq.prop('disabled', false);
      this.ui.newLocationButton.show();
      this.ui.addressGrid.show();
    }
  },

  onCheckindividualTypes: function() {
    var ischecked = this.ui.individualTypes.filter(':checked');
    var notischecked = this.ui.individualTypes.filter(':not(:checked)');
    var value = _.pluck(ischecked, 'name');
    var novalue = _.pluck(notischecked, 'name');
    var zero = '0';
    var self = this;

    self.ui.numemployees.hide();
    self.ui.nummembers.hide();
    self.ui.numStudents.hide();
    self.ui.numAlumni.hide();
    self.ui.numBoard.hide();
    self.ui.numOther.hide();

    _.each(novalue, function(item) {
      switch (item) {
        case 'Employees':
          self.ui.numEmployessLocation.val(zero);
          break;
        case 'Members':
          self.ui.numMembersLocation.val(zero);
          break;
        case 'Students':
          self.ui.numStudentsLocation.val(zero);
          break;
        case 'Alumni':
          self.ui.numAlumniLocation.val(zero);
          break;
        case 'Board':
          self.ui.numBoardLocation.val(zero);
          break;
        case 'Other':
          self.ui.numOtherLocation.val(zero);
          break;
      }
    });

    _.each(value, function(item) {
      switch (item) {
        case 'Employees':
          self.ui.numemployees.show();
          break;
        case 'Members':
          self.ui.nummembers.show();
          break;
        case 'Students':
          self.ui.numStudents.show();
          break;
        case 'Alumni':
          self.ui.numAlumni.show();
          break;
        case 'Board':
          self.ui.numBoard.show();
          break;
        case 'Other':
          self.ui.numOther.show();
          break;
      }
    });
  },

  groupEmployeesCount: function() {
    var groupEmployeesCount = _.reduce(
      this.locationCollection.toJSON(),
      function(prev, cur) {
        return prev + parseInt(cur.employeeCount);
      },
      0
    );
    return groupEmployeesCount;
  },

  validate: function(config) {
    this.ui.groupLocationForm.parsley().reset();
    this.ui.paymentAmountErrorAlert.hide();
    this.ui.individualTypesErrorAlert.hide();

    var groupEmployeesCount = _.reduce(
      this.locationCollection.toJSON(),
      function(prev, cur) {
        return prev + parseInt(cur.employeeCount);
      },
      0
    );

    if (config.type === 'save') {
      // Turn off required field validation for this form
      parsleyUtil.disableRequiredValidation(this.ui.groupLocationForm);
    } else {
      // Turn on required field validation for this form
      parsleyUtil.enableRequiredValidation(this.ui.groupLocationForm);
      var validationPromises = [
        this.ui.groupLocationForm.parsley().whenValidate(),
        this.groupEmployeesCountValidate(groupEmployeesCount),
        this.individualTypesValidate()
      ];
    }
    return $.when.apply($, validationPromises);
  },

  groupEmployeesCountValidate: function(groupEmployeesCount) {
    var defer = $.Deferred();
    this.ui.paymentAmountErrorAlert.hide();
    this.convertNonIntLocationCountValuesToZero();
    var formData = Syphon.serialize(this.ui.groupLocationForm);
    var totalIndividuals =
      parseInt(formData.numEmployessLocation) +
      parseInt(formData.numMembersLocation) +
      parseInt(formData.numStudentsLocation) +
      parseInt(formData.numAlumniLocation) +
      parseInt(formData.numBoardLocation) +
      parseInt(formData.numOtherLocation);

    groupEmployeesCount += parseInt(formData.numEmployessHQ);

    if (this.ui.isAllHQ.filter(':checked').val() === '0') {
      if (groupEmployeesCount === totalIndividuals) {
        this.ui.paymentAmountErrorAlert.hide();

        defer.resolve();
      } else {
        this.ui.paymentAmountErrorAlert.show();
        defer.reject();
      }
    } else {
      this.ui.paymentAmountErrorAlert.hide();
      defer.resolve();
    }

    return defer.promise();
  },

  individualTypesValidate: function() {
    var defer = $.Deferred();
    var checkedBoxes = this.ui.individualTypes.filter(':checked');
    if (checkedBoxes.length > 0) {
      this.ui.individualTypesErrorAlert.hide();
      defer.resolve();
    } else {
      this.ui.individualTypesErrorAlert.show();
      defer.reject();
    }

    return defer.promise();
  },

  getFormData: function() {
    this.convertNonIntLocationCountValuesToZero();
    var formData = Syphon.serialize(this.ui.groupLocationForm);

    var checkedBoxes = this.ui.individualTypes.filter(':checked');
    formData.individualTypes = _.map(checkedBoxes, function(checkedBox) {
      return checkedBox.value;
    });
    formData.locations = this.locationCollection.toJSON();
    formData.groupEmployeesCount =
      parseInt(formData.numEmployessLocation) +
      parseInt(formData.numMembersLocation) +
      parseInt(formData.numStudentsLocation) +
      parseInt(formData.numAlumniLocation) +
      parseInt(formData.numBoardLocation) +
      parseInt(formData.numOtherLocation);

    return formData;
  },

  convertNonIntLocationCountValuesToZero: function() {
    if (isNaN(parseInt(this.ui.numEmployessLocation.val())) === true) { this.ui.numEmployessLocation.val(0); }  
    if (isNaN(parseInt(this.ui.numMembersLocation.val())) === true) { this.ui.numMembersLocation.val(0); }  
    if (isNaN(parseInt(this.ui.numStudentsLocation.val())) === true) { this.ui.numStudentsLocation.val(0); }  
    if (isNaN(parseInt(this.ui.numAlumniLocation.val())) === true) { this.ui.numAlumniLocation.val(0); }  
    if (isNaN(parseInt(this.ui.numBoardLocation.val())) === true) { this.ui.numBoardLocation.val(0); }  
    if (isNaN(parseInt(this.ui.numOtherLocation.val())) === true) { this.ui.numOtherLocation.val(0); }  
  }
});
