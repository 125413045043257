/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Marionette = require('backbone.marionette');
var Syphon = require('backbone.syphon');

require('../styles/NonDepEntityFormOfficerOtherLicenseView.css');
var tmplNonDepEntityFormOfficerOtherLicenseView = require('../templates/NonDepEntityFormOfficerOtherLicenseView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplNonDepEntityFormOfficerOtherLicenseView,

  className: 'nondep-entity-form-officer-other-license-view row',

  ui: {
    removeAssociatedLicense: '.remove-associated-license'
  },

  triggers: {
    'click @ui.removeAssociatedLicense': 'remove:associated-license'
  },

  templateHelpers: function() {
    var amendmentChanges;
    var showChanges = this.options.isReadOnly && this.options.viewMode !== 'ext';
    if (showChanges) {
      amendmentChanges = this.getAmendmentChanges();
    }

    return {
      contactTypes: this.options.contactTypes,
      modelId: this.model.cid,
      viewMode: this.options.viewMode,
      isReadOnly: this.options.isReadOnly,
      amendmentChanges: amendmentChanges
    };
  },

  onBeforeShow: function() {
    Syphon.deserialize(this, this.model.toJSON());
  },

  getFormData: function() {
    var formData = Syphon.serialize(this);
    formData.associatedLicenseId = this.model.get('id');
    return formData;
  },

  getAmendmentChanges: function() {
    var oldAssociatedLicense = this.model.get('oldAssociatedLicense') || {};

    return {
      entityName: oldAssociatedLicense.entityName && this.model.get('entityName') !== oldAssociatedLicense.entityName,
      licenseNumber:
        oldAssociatedLicense.licenseNumber && this.model.get('licenseNumber') !== oldAssociatedLicense.licenseNumber,
      licenseType:
        oldAssociatedLicense.licenseType && this.model.get('licenseType') !== oldAssociatedLicense.licenseType,
      isDeleted: this.model.get('isDeleted') === '1'
    };
  }
});
