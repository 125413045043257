/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Syphon = require('backbone.syphon');
var Marionette = require('backbone.marionette');

var parsleyUtil = require('common/util/parsleyUtil');
var makeUploadCollection = require('common/util/makeUploadCollection');
var toggleForm = require('common/util/toggleForm');
var modelGet = require('common/util/modelGet');

var AddressView = require('common/views/AddressView');
var CommentsDocumentView = require('common/views/CommentsDocumentView');
var FileUploadView = require('common/views/FileUploadView');

var tmplDepBankLocationActivitiesRelocationSubFormView = require('./DepBankLocationActivitiesRelocationSubFormView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplDepBankLocationActivitiesRelocationSubFormView,

  className: 'dep-bank-location-activities-relocation-sub-form-view form-divider',

  behaviors: {
    RadioGroupToggleBehavior: {
      radios: [
        {
          target: '.temp-facility-details',
          radioGroup: 'input[name="hasTempQuarters"]',
          valueToShow: '1'
        }
      ]
    },
    ToggleBehavoir: {
      toggles: [
        {
          target: '.relocation-questionnaire',
          group: 'select[name="locationTypeId"]',
          valuesToShow: ['1', '2']
        }
      ]
    }
  },

  ui: {
    actionForm: '.action-form',
    newLocationType: '.new-location-type',
    useCurrentContactCheckbox: '.use-current-contact-checkbox',
    datepickerFields: '.datepicker',
    relocationAddressSection: '.relocation-address-section',
    relocationAddress: '.relocation-address',
    LPOContact: '.lpo-contact',
    contactAddress: '.contact-address',
    questionnaireContainer: '.questionnaire-container',
    fixedAssetsContainer: '.fixed-assets-container',
    closingNoticeContainer: '.closing-cert-container',
    // LPO Contact fields to auto-fill on checkbox
    contactFirstName: '.contact-first-name',
    conactLastName: '.contact-last-name',
    contactMiddleInitial: '.contact-middle-initial',
    contactTitle: '.contact-title',
    contactPhone: '.contact-phone',
    contactEmail: '.contact-email',
    transactionApprovingContainer: '.transaction-approving-container',
    hasRelatedInterestInput: 'input[name="hasRelatedInterest"]',
    hasInsiderInterestInput: 'input[name="hasInsiderInterest"]',
    insiderInterest: '.insider-interest'
  },

  regions: {
    relocationAddress: '@ui.relocationAddress',
    contactAddress: '@ui.contactAddress',
    questionnaireContainer: '@ui.questionnaireContainer',
    fixedAssetsContainer: '@ui.fixedAssetsContainer',
    closingNoticeContainer: '@ui.closingNoticeContainer',
    transactionApprovingContainer: '@ui.transactionApprovingContainer'
  },

  events: {
    'change @ui.newLocationType': 'onChangeNewLocationType',
    'change @ui.useCurrentContactCheckbox': 'onChangeUseCurrentContactCheckbox',
    'change @ui.hasRelatedInterestInput': 'onChangeInsiderInterest',
    'change @ui.hasInsiderInterestInput': 'onChangeInsiderInterest'
  },

  templateHelpers: function() {
    var isCU = this.options.entityCategoryId === '2';
    return {
      locationTypes: this.options.locationTypes,
      childIndex: this.options.childIndex,
      currentlyLPO: this.model.get('locationTypeId') === '4' || this.model.get('locationTypeId') === '25',
      isCU: isCU,
      modelId: this.model.cid
    };
  },

  onBeforeShow: function() {
    var locationAction = this.model.get('locationAction') || {};
    var relocationAddress = locationAction.locationAddress || {};
    var contactAddress = locationAction.contactAddress || {};
    var isCU = this.options.entityCategoryId === '2';

    var fixedAssets = locationAction.fixedAssets || {};
    var closingNotice = locationAction.closingNotice || {};
    var transactionApproving = locationAction.transactionApproving || {};

    this.relocationAddress = new AddressView({
      model: new Backbone.Model(relocationAddress),
      objectName: 'locationAddress',
      horizontal: false,
      isRequired: true,
      startingCountry: relocationAddress.country,
      startingPostalCode: relocationAddress.postalCode,
      startingCounty: relocationAddress.county
    });
    this.showChildView('relocationAddress', this.relocationAddress);

    this.contactAddress = new AddressView({
      model: new Backbone.Model(contactAddress),
      objectName: 'contactAddress',
      horizontal: false,
      isRequired: false,
      startingCountry: contactAddress.country,
      startingPostalCode: contactAddress.postalCode,
      startingCounty: contactAddress.county
    });
    this.showChildView('contactAddress', this.contactAddress);

    Syphon.deserialize(this, this.model.get('locationAction'));
    this.onChangeNewLocationType();
    this.ui.datepickerFields.datepicker({});
    toggleForm(
      this.ui.insiderInterest,
      locationAction.hasRelatedInterest === '1' || locationAction.hasInsiderInterest === '1'
    );

    this.fixedAssets = new CommentsDocumentView({
      isReadOnly: this.options.isReadOnly,
      allowMultipleFiles: false,
      isRequired: true,
      fileUploadLabel: 'Location Fixed Assets Investments Ratio Calculation',
      commentsLabel: 'Location Fixed Assets Investments Ratio Calculation Comments',
      commentsName: 'fixedAssetsComments',
      uniqueId: modelGet(this.model, 'locationAction.locationActivityId'),
      collection: makeUploadCollection(fixedAssets.file),
      model: new Backbone.Model({ fixedAssetsComments: fixedAssets.fixedAssetsComments }),
      documentTypeId: 20001
    });
    this.showChildView('fixedAssetsContainer', this.fixedAssets);

    this.transactionApprovingDocument = new FileUploadView({
      isReadOnly: this.options.isReadOnly,
      allowMultipleFiles: false,
      isRequired: false,
      collection: makeUploadCollection(transactionApproving),
      metadata: {
        documentTypeId: 20001
      }
    });
    this.showChildView('transactionApprovingContainer', this.transactionApprovingDocument);

    if (!isCU) {
      this.closingNotice = new CommentsDocumentView({
        isReadOnly: this.options.isReadOnly,
        allowMultipleFiles: false,
        isRequired: false,
        fileUploadLabel: 'Upload Proposed Notice',
        commentsLabel: 'Proposed Notice Comments',
        commentsName: 'closingNoticeComments',
        uniqueId: modelGet(this.model, 'locationAction.locationActivityId'),
        collection: makeUploadCollection(closingNotice.file),
        model: new Backbone.Model({ closingNoticeComments: closingNotice.closingNoticeComments }),
        documentTypeId: 20001
      });
      this.showChildView('closingNoticeContainer', this.closingNotice);
    }
  },

  onChangeNewLocationType: function() {
    var newLocationTypeId = this.ui.newLocationType.val();
    var emptySelection = newLocationTypeId === '';
    var isMobile = newLocationTypeId === '3' || newLocationTypeId === '23';
    var isLPO = newLocationTypeId === '4' || newLocationTypeId === '25';
    var isVirtual = newLocationTypeId === '5' || newLocationTypeId === '12' || newLocationTypeId === '26';

    toggleForm(this.ui.LPOContact, isLPO);
    toggleForm(this.ui.relocationAddressSection, !(isMobile || isVirtual || emptySelection));
  },

  onChangeUseCurrentContactCheckbox: function() {
    if (this.ui.useCurrentContactCheckbox.prop('checked')) {
      this.ui.contactFirstName.val(this.model.get('contactFirstName'));
      this.ui.conactLastName.val(this.model.get('contactLastName'));
      this.ui.contactMiddleInitial.val(this.model.get('contactMiddleInitial'));
      this.ui.contactTitle.val(this.model.get('contactTitle'));
      this.ui.contactPhone.val(this.model.get('contactPhone'));
      this.ui.contactEmail.val(this.model.get('contactEmail'));

      var currContactAddress = this.model.get('currentContactAddress');
      this.contactAddress.setAddress(currContactAddress);
    }
  },

  onChangeInsiderInterest: function() {
    var hasRelatedInterest = $('input[name="hasRelatedInterest"]:checked').val();
    var hasInsiderInterest = $('input[name="hasInsiderInterest"]:checked').val();
    var hasReloInsiderInterest = hasRelatedInterest === '1' || hasInsiderInterest === '1';
    toggleForm(this.ui.insiderInterest, hasReloInsiderInterest);
  },

  validate: function(config) {
    var isCU = this.options.entityCategoryId === '2';

    this.ui.actionForm.parsley().reset();
    this.fixedAssets.removeValidationErrors();
    if (!isCU) {
      this.closingNotice.removeValidationErrors();
    }
    if (config.type === 'save') {
      // Turn off required field validation for this form
      parsleyUtil.disableRequiredValidation(this.ui.actionForm);
    } else {
      var validationPromises = [
        this.ui.actionForm.parsley().whenValidate(),
        this.fixedAssets.validate(),
        this.transactionApprovingDocument.validate()
      ];

      if (!isCU) {
        validationPromises.push(this.closingNotice.validate());
      }

      // Turn on required field validation for this form
      parsleyUtil.enableRequiredValidation(this.ui.actionForm);
    }
    return $.when.apply($, validationPromises);
  },

  getFormData: function() {
    var formData = Syphon.serialize(this);
    var isCU = this.options.entityCategoryId === '2';

    formData.fixedAssetsDocId = this.fixedAssets.getFirstFileId();
    formData.transactionApprovingDocId = this.transactionApprovingDocument.getFirstFileId();
    if (!isCU) {
      formData.noticeCertClosingBranchDocId = this.closingNotice.getFirstFileId();
    }

    return formData;
  }
});
