/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2020] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Backbone = require('backbone');
var Marionette = require('backbone.marionette');
var Radio = require('backbone.radio');

var UIUtil = require('psa-core/util/ui');

var SideNavSectionView = require('common/views/SideNavSectionView');
var ContentServerLinkView = require('common/views/ContentServerLinkView');
var BreadcrumbView = require('common/views/BreadcrumbView');
var DropdownView = require('common/views/DropdownView');
var HeaderBarView = require('common/views/HeaderBarView');

var SecIndividualSectionGeneralView = require('./SecIndividualSectionGeneralView');
var SecIndividualAddressModalView = require('./SecIndividualAddressModalView');

var tmplSecIndividualDashboardView = require('../templates/SecIndividualDashboardView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplSecIndividualDashboardView,

  className: 'sec-individual-dashboard-view container-fluid',

  ui: {
    headerBar: '.header-bar-container',
    breadcrumb: '.breadcrumb-container',
    actionDropdown: '.individual-action-drop-down',
    individualDashboard: '.individual-dashboard-container',
    csLinkContainer: '.cs-link-container'
  },

  regions: {
    headerBar: '@ui.headerBar',
    breadcrumb: '@ui.breadcrumb',
    actionDropdown: '@ui.actionDropdown',
    individualDashboard: '@ui.individualDashboard',
    csLinkContainer: '@ui.csLinkContainer'
  },

  onBeforeShow: function() {
    var individual = this.model.get('individual') || {};
    var individualId = this.model.get('individualId');

    individual.title = 'Individual';
    var headerBarColumns = [
      {
        name: 'crdNumber',
        label: 'CRD Number',
        minWidth: '160px',
        maxWidth: '200px'
      },
      {
        name: 'lastNameDisplay',
        label: 'Last Name',
        minWidth: '160px',
        maxWidth: '200px'
      },
      {
        name: 'firstNameDisplay',
        label: 'First Name',
        minWidth: '160px',
        maxWidth: '200px'
      },
      {
        name: 'middleInitialDisplay',
        label: 'Middle Initial',
        minWidth: '160px',
        maxWidth: '200px'
      }
    ];

    this.showChildView(
      'headerBar',
      new HeaderBarView({
        columns: new Backbone.Collection(headerBarColumns),
        model: new Backbone.Model(individual)
      })
    );

    this.showChildView(
      'csLinkContainer',
      new ContentServerLinkView({
        model: new Backbone.Model({
          buttonText: 'Open Individual Folder',
          contentServerId: individual.CSFolderId
        })
      })
    );

    this.showChildView(
      'breadcrumb',
      new BreadcrumbView({
        breadcrumbs: [
          {
            link: '#sec/dashboard',
            text: 'Home'
          },
          {
            text: individual.dbaName
          }
        ]
      })
    );

    var dropDownOptions = [
      {
        text: 'Edit General Section',
        link: '#sec/individual/' + individualId + '/edit'
      },
      {
        isDivider: true
      },
      {
        text: 'Add Individual License',
        link: '#sec/individual/' + individualId + '/new-license'
      },
      {
        text: 'Add Individual Address',
        onClick: this.onClickAddAddress.bind(this)
      },
      {
        text: 'Add Investigation/Inquiry',
        link: '#sec/individual/' + individualId + '/new-investigation-inquiry'
      }
    ];

    this.showChildView(
      'actionDropdown',
      new DropdownView({
        rightAlign: true,
        model: new Backbone.Model({
          name: 'Individual Actions',
          options: dropDownOptions
        })
      })
    );

    var sideNavTabs = [
      {
        id: 'general',
        title: 'General',
        view: new SecIndividualSectionGeneralView({ model: new Backbone.Model(individual) })
      },
      {
        id: 'license',
        title: 'License',
        view: Radio.channel('sc').request('build:individual-section-license-view', individualId)
      },
      {
        id: 'address',
        title: 'Address List',
        view: Radio.channel('sc').request('build:individual-address-view', individualId)
      },
      {
        id: 'invinq',
        title: 'Investigations/Inquiries',
        view: Radio.channel('sc').request('build:related-inv-inq-section-view', {
          objectId: individualId,
          objectTypeId: 'INDIVIDUAL'
        })
      },
      {
        id: 'notes',
        title: 'Notes',
        view: Radio.channel('sc').request('build:notes-view', { objectId: individualId, objectTypeId: 'INDIVIDUAL' })
      },
      {
        id: 'auditLog',
        title: 'Audit Log',
        view: Radio.channel('sc').request('build:audit-log-view', {
          objectId: individualId,
          objectTypeId: 'INDIVIDUAL'
        })
      }
    ];

    this.dashboard = new SideNavSectionView({
      collection: new Backbone.Collection(sideNavTabs),
      useNavigationButtons: false
    });

    this.showChildView('individualDashboard', this.dashboard);
  },

  onClickAddAddress: function() {
    UIUtil.showModal({
      title: 'Add Individual Address',
      view: new SecIndividualAddressModalView({
        addressTypes: this.model.get('addressTypes'),
        individualId: this.model.get('individualId')
      })
    }).done(function(wasSuccessful) {
      if (wasSuccessful) {
        Backbone.history.loadUrl();
      }
    });
  }
});
