/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var Marionette = require('backbone.marionette');

var FileUploadTableView = require('common/views/FileUploadTableView');

require('../styles/ExamFollowupView.css');
var tmplExamFollowupView = require('../templates/ExamFollowupView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplExamFollowupView,

  className: 'exam-followup-view',

  behaviors: {
    ReadOnlyFormBehavior: {}
  },

  ui: {
    supportingDocumentsContainer: '.supporting-documents-container',
    feedbackInput: '.feedback-input'
  },

  regions: {
    supportingDocumentsContainer: '@ui.supportingDocumentsContainer'
  },

  initialize: function(options) {
    this.mode = options.mode;
  },

  onBeforeRender: function() {
    this.model.set({
      feedbackLabel: this.mode === 'entity' ? 'Entity Response' : 'Examiner Feedback',
      documentsLabel: this.mode === 'entity' ? 'Entity' : 'Examiner Supporting'
    });
  },

  templateHelpers: function() {
    return {
      isReadOnly: this.options.isReadOnly
    };
  },

  onBeforeShow: function() {
    var supportingDocuments = this.model.get('supportingDocuments');
    if (!this.options.isReadOnly) {
      _.each(supportingDocuments, function(document) {
        document.documentType = document.documentTypeId;
      });
    }
    this.fileUploadTable = new FileUploadTableView({
      isReadOnly: this.options.isReadOnly,
      collection: new Backbone.Collection(supportingDocuments),
      emptyText: this.model.get('documentsLabel') + ' Documents Added',
      documentTypes: this.options.documentTypes
    });
    this.showChildView('supportingDocumentsContainer', this.fileUploadTable);

    if (this.model.get('feedback')) {
      this.ui.feedbackInput.val('\n\n' + this.model.get('feedback'));
    }
  },

  getFormData: function() {
    return this.fileUploadTable.getFormData();
  },

  validate: function() {
    return this.fileUploadTable.validate();
  }
});
