/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2020] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Backbone = require('backbone');
var Marionette = require('backbone.marionette');
var Radio = require('backbone.radio');
var Ladda = require('ladda');

var BreadcrumbView = require('common/views/BreadcrumbView');
var HeaderBarView = require('common/views/HeaderBarView');

var SecInvInqGeneralFormView = require('./SecInvInqGeneralFormView');
var SubmitInvestigationInquiry = require('../services/SubmitInvestigationInquiry');

var tmplSecInvInqIndividualLicenseNewView = require('../templates/SecInvInqIndividualLicenseNewView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplSecInvInqIndividualLicenseNewView,

  className: 'sec-inv-inq-individual-license-new-view container-fluid',

  ui: {
    breadcrumb: '.breadcrumb-container',
    headerBar: '.header-bar-container',
    invInqFormContainer: '.inv-inq-form-container',
    submit: '.submit'
  },

  regions: {
    breadcrumb: '@ui.breadcrumb',
    headerBar: '@ui.headerBar',
    invInqFormContainer: '@ui.invInqFormContainer'
  },

  events: { 'click @ui.submit': 'onClickSubmit' },

  onBeforeShow: function() {
    var identifiers = this.model.get('identifiers') || {};
    var licenseId = this.model.get('licenseId') || {};
    var individualId = this.model.get('individualId');

    this.showChildView(
      'breadcrumb',
      new BreadcrumbView({
        breadcrumbs: [
          {
            link: '#sec/dashboard',
            text: 'Home'
          },
          {
            link: '#sec/individual/' + individualId + '/dashboard',
            text: identifiers.dbaName
          },
          {
            link: '#sec/individual/' + individualId + '/license/' + licenseId + '/dashboard',
            text: 'License Type - ' + identifiers.type
          },
          {
            text: 'New Investigation/Inquiry'
          }
        ]
      })
    );

    identifiers.title = 'License';
    var headerBarColumns = [
      {
        name: 'CRDNumber',
        label: 'CRD Number',
        minWidth: '160px',
        maxWidth: '200px'
      },
      {
        name: 'lastName',
        label: 'Last Name',
        minWidth: '160px',
        maxWidth: '200px'
      },
      {
        name: 'firstName',
        label: 'First Name',
        minWidth: '160px',
        maxWidth: '200px'
      },
      {
        name: 'middleInitial',
        label: 'Middle Initial',
        minWidth: '160px',
        maxWidth: '200px'
      }
    ];

    this.showChildView(
      'headerBar',
      new HeaderBarView({
        columns: new Backbone.Collection(headerBarColumns),
        model: new Backbone.Model(identifiers)
      })
    );

    this.generalFormView = new SecInvInqGeneralFormView({ model: this.model });
    this.showChildView('invInqFormContainer', this.generalFormView);
  },

  getFormData: function() {
    var formData = this.generalFormView.getFormData();
    formData.invInqId = this.model.get('invInqId');
    formData.objectTypeId = 'INDIVIDUAL';
    formData.objectId = this.model.get('individualId');
    formData.objectLicenseId = this.model.get('licenseId');
    formData.isOrganization = '0';

    return formData;
  },

  validate: function() {
    return this.ui.invInqFormContainer.parsley().whenValidate();
  },

  onClickSubmit: function(event) {
    event.preventDefault();
    var self = this;
    this.validate().done(function() {
      var l = Ladda.create(self.ui.submit[0]);
      l.start();
      var formData = self.getFormData();
      SubmitInvestigationInquiry(formData)
        .done(function(invInqId) {
          Radio.channel('navigate').trigger('show:int:sc:investigation-inquiry-dashboard', invInqId);
        })
        .fail(function() {
          l.stop();
        });
    });
  }
});
