/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var moment = require('moment');

var cleanUpResponse = require('common/util/cleanUpResponse');

module.exports = function(enforcementActionId) {
  var deferred = $.Deferred();

  $.soap({
    body: {
      GetProvisionByEnforcement: {
        $xmlns: 'http://dobs.pa.gov/dp/enforcement/bpm/1.0',
        EnforcementActionID: enforcementActionId
      }
    },
    blueprintArrays: ['GetProvisionByEnforcementResponse.DP_EnforcementActionProvision'],
    blueprint: {
      provisions: [
        '$..DP_EnforcementActionProvision',
        {
          id: '$..ID',
          enforcementActionId: '$..EnforcementActionID',
          entityId: '$..EntityID',
          provisionNumber: '$..ProvisionNumber',
          categoryId: '$..CategoryID',
          responseDueDate: '$..ResponseDueDate',
          isOngoing: '$..IsOngoing',
          categoryName: '$..Name',
          statusId: '$..StatusID',
          status: '$..Status',
          isDeleted: '$..IsDeleted',
          provisions: '$..Provisions',
          comments: '$..Comments'
        }
      ]
    }
  })
    .done(function(response) {
      cleanUpResponse(response);

      _.each(response.provisions, function(provision) {
        provision.provisionNumber = provision.provisionNumber || '--';
        provision.status = provision.status || '--';
        provision.categoryName = provision.categoryName || '--';
        provision.comments = provision.comments || '--';

        if (provision.isOngoing === '1') {
          provision.isOngoingDisplay = 'Yes';
        } else if (provision.isOngoing === '0') {
          provision.isOngoingDisplay = 'No';
        } else {
          provision.isOngoingDisplay = '--';
        }

        if (provision.responseDueDate) {
          provision.responseDueDate = moment(provision.responseDueDate, 'YYYY-MM-DD');
        }
      });
      deferred.resolve(response.provisions);
    })
    .fail(function(err) {
      deferred.reject();
    });

  return deferred.promise();
};
