/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2020] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Marionette = require('backbone.marionette');
var Syphon = require('backbone.syphon');

var momentUtil = require('common/util/momentUtil');
var toggleForm = require('common/util/toggleForm');

require('./DepOathRosterOfficerFormView.css');

var tmplDepOathRosterOfficerFormView = require('./DepOathRosterOfficerFormView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplDepOathRosterOfficerFormView,

  className: 'dep-oath-roster-officer-form-view panel panel-info',

  ui: {
    removeOfficer: '.remove-officer',
    datePickerFields: '.date-picker',
    titleId: '[name="titleId"]',
    titleComments: '.title-comments',
    titleCommentsRequired: '.title-comments-required'
  },

  events: {
    'change @ui.titleId': 'onChangeTitle'
  },

  triggers: {
    'click @ui.removeOfficer': 'remove:officer'
  },

  templateHelpers: function() {
    var indexOffset = this.model.get('indexOffset') || 0;
    return {
      officerTitles: this.options.officerTitles,
      childIndex: this.options.childIndex + indexOffset
    };
  },

  onBeforeShow: function() {
    this.ui.datePickerFields.datepicker();
    this.onChangeOathRosterType();
    Syphon.deserialize(this, this.model.toJSON());
    toggleForm(this.ui.titleCommentsRequired, false);
  },

  onChangeTitle: function() {
    var isTitleOther = this.ui.titleId.val() === '5';
    toggleForm(this.ui.titleComments, !isTitleOther);
    toggleForm(this.ui.titleCommentsRequired, isTitleOther);
  },

  onChangeOathRosterType: function(e) {
    var oathRosterTypeId = $('.oath-roster-type-id').val();
    toggleForm(this.ui.boardElectedForm, oathRosterTypeId === '1');
    toggleForm(this.ui.shareholderElectedForm, oathRosterTypeId === '2');
  },

  getFormData: function() {
    var formData = Syphon.serialize(this);
    formData.electionDate = momentUtil.formatStr(formData.electionDate, momentUtil.defaultOutboundDateConfig);
    formData.serviceEndDate = momentUtil.formatStr(formData.serviceEndDate, momentUtil.defaultOutboundDateConfig);
    return formData;
  }
});
