/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');

var cleanUpResponse = require('common/util/cleanUpResponse');

module.exports = function(categoryId, entityTypeId) {
  var defer = $.Deferred();

  $.soap({
    body: {
      GetAllEnforcementLookups: {
        $xmlns: 'http://dobs.pa.gov/dp/lookup/bpm/1.0'
      }
    },
    blueprintArrays: [
      'GetAllEnforcementLookups.EnforcementActionActions.DP_LU_EnforcementActionAction',
      'GetAllEnforcementLookups.EnforcementActionAgencies.DP_LU_EnforcementActionAgency',
      'GetAllEnforcementLookups.EnforcementActionAreas.DP_LU_EnforcementActionArea',
      'GetAllEnforcementLookups.EnforcementActionStatuses.DP_LU_EnforcementActionStatus',
      'GetAllEnforcementLookups.ProvisionCategory.DP_LU_ProvisionCategory',
      'GetAllEnforcementLookups.ProgressReportStatuses.DP_LU_ProgressReportStatus',
      'GetAllEnforcementLookups.ProvisionStatuses.DP_LU_ProvisionStatus',
      'GetAllEnforcementLookups.Quarters.DP_LU_Quarter',
      'GetAllEnforcementLookups.EnforcementActionTypes.DP_LU_EnforcementActionType',
      'GetAllEnforcementLookups.ProgressReportYears.DP_LU_ProgressReportYear'
    ],
    blueprint: {
      enforcementActionActions: [
        '$..DP_LU_EnforcementActionAction',
        {
          id: '$.ID',
          action: '$.Action',
          categoryId: '$.CategoryID',
          isTSP: '$.IsTSP'
        }
      ],
      enforcementActionAgencies: [
        '$..DP_LU_EnforcementActionAgency',
        {
          id: '$.ID',
          agency: '$.Agency',
          categoryId: '$.CategoryID'
        }
      ],
      enforcementActionAreas: [
        '$..DP_LU_EnforcementActionArea',
        {
          id: '$.ID',
          area: '$.Area',
          categoryId: '$.CategoryID'
        }
      ],
      enforcementActionStatuses: [
        '$..DP_LU_EnforcementActionStatus',
        {
          id: '$.ID',
          status: '$.Status'
        }
      ],
      provisionCategories: [
        '$..DP_LU_ProvisionCategory',
        {
          id: '$.ID',
          name: '$.Name',
          categoryId: '$.CategoryID'
        }
      ],
      progressReportStatuses: [
        '$..DP_LU_ProgressReportStatus',
        {
          id: '$.ID',
          status: '$.Status'
        }
      ],
      provisionStatuses: [
        '$..DP_LU_ProvisionStatus',
        {
          id: '$.ID',
          status: '$.Status'
        }
      ],
      quarters: [
        '$..DP_LU_Quarter',
        {
          id: '$.ID',
          quarter: '$.Quarter',
          quarterEnd: '$.QuarterEnd'
        }
      ],
      enforcementActionTypes: [
        '$..DP_LU_EnforcementActionType',
        {
          id: '$.ID',
          type: '$.Type',
          categoryId: '$.CategoryID'
        }
      ],
      progressReportYears: [
        '$..DP_LU_ProgressReportYear',
        {
          id: '$.ID',
          reportYear: '$.ReportYear'
        }
      ]
    }
  })
    .done(function(response) {
      cleanUpResponse(response);

      var isTSP = entityTypeId === '3' || entityTypeId === '12' || entityTypeId === '23';
      if (isTSP) {
        response.enforcementActionActions = _.where(response.enforcementActionActions, { isTSP: '1' });
      } else {
        response.enforcementActionActions = _.where(response.enforcementActionActions, { isTSP: '0' });
      }

      if (categoryId) {
        response.enforcementActionAreas = _.where(response.enforcementActionAreas, { categoryId: categoryId });
        response.enforcementActionActions = _.where(response.enforcementActionActions, { categoryId: categoryId });
        response.enforcementActionAgencies = _.where(response.enforcementActionAgencies, { categoryId: categoryId });
        response.provisionCategories = _.where(response.provisionCategories, { categoryId: categoryId });
        response.enforcementActionTypes = _.where(response.enforcementActionTypes, { categoryId: categoryId });
      }

      defer.resolve(response);
    })
    .fail(function(err) {
      defer.reject(err);
    });

  return defer.promise();
};
