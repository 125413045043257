/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Marionette = require('backbone.marionette');
var Syphon = require('backbone.syphon');

var tmplDepProvisionFormView = require('../templates/DepProvisionFormView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplDepProvisionFormView,

  className: 'dep-provision-form-view',

  ui: {
    responseDueDate: '.response-due-date'
  },

  onBeforeShow: function() {
    Syphon.deserialize(this, this.model.get('provision'));
    this.ui.responseDueDate.datepicker();
  },

  getFormData: function() {
    var formData = Syphon.serialize(this);
    formData.entityId = this.model.get('entityId');
    formData.enforcementId = this.model.get('enforcementId');
    formData.provisionId = this.model.get('provisionId');
    return formData;
  }
});
