var Handlebars = require("handlebars/dist/handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "  <div role=\"tabpanel\" class=\"tab-pane active\">\n    <div class=\"well well-sm review-comments-container\">\n      <h4 class=\"m-t-n\">\n        <i class=\"fa fa-university\" aria-hidden=\"true\"></i> Enforcement Action Review Comments\n      </h4>\n      <p class=\"review-comments pre-wrap m-n\">"
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? depth0.complianceDetails : depth0)) != null ? stack1.enforcementActionReviewComments : stack1), depth0))
    + "</p>\n    </div>\n  </div>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression;

  return "\n<div class=\"page-title clearfix\">\n  <h1 class=\"pull-left m-n p-n\">"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? depth0.task : depth0)) != null ? stack1.taskType : stack1)) != null ? stack1.description : stack1), depth0))
    + "</h1>\n</div>\n<div class=\"header-bar-container\"></div>\n<div class=\"breadcrumb-container\"></div>\n<div class=\"task-status\"></div>\n\n<div class=\"container-fluid m-b-lg\">\n"
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? depth0.complianceDetails : depth0)) != null ? stack1.enforcementActionReviewComments : stack1),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "  <form class=\"form-horizontal enforcement-form\">\n    <div class=\"case-summary-container\">\n      <div class=\"form-group\">\n        <label class=\"control-label col-sm-3\">Jump To</label>\n        <div class=\"form-control-static col-sm-6 jump-to-container\"></div>\n      </div>\n      <div class=\"summary-container\"></div>\n      <div class=\"form-group\">\n        <label class=\"control-label col-sm-3\">Narrative</label>\n        <p class=\"form-control-static col-sm-6\">"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.complianceDetails : depth0)) != null ? stack1.narrative : stack1), depth0))
    + "</p>\n      </div>\n    </div>\n    <div class=\"enforcement-action-form-container\"></div>\n    <div class=\"row\">\n      <div class=\"col-sm-6 col-sm-offset-3\">\n        <a class=\"btn btn-default btn-cancel\" href=\"#nondep/dashboard\">Cancel</a>\n        <button class=\"btn btn-primary btn-submit\">Submit</button>\n        <div class=\"task-actions\"></div>\n      </div>\n    </div>\n  </form>\n</div>";
},"useData":true});