/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Marionette = require('backbone.marionette');
var Ladda = require('ladda');
var Radio = require('backbone.radio');

var BreadcrumbView = require('common/views/BreadcrumbView');

var EntityHeaderBarView = require('../../common/views/EntityHeaderBarView');
var NonDepEntityFormSectionIdentificationView = require('../../entity-form/views/NonDepEntityFormSectionIdentificationView');
var SubmitEntityIdentification = require('../services/SubmitEntityIdentification');

var tmplNonDepEntityDashboardView = require('../templates/NonDepEntityEditIdentificationView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplNonDepEntityDashboardView,

  className: 'nondep-entity-edit-identification-view container-fluid m-b-lg',

  ui: {
    entityActionDropDown: '.dropdown-container',
    headerBar: '.header-bar-container',
    breadcrumb: '.breadcrumb-container',
    identificationForm: '.entity-identification-form-container',
    submit: '.btn-submit'
  },

  regions: {
    entityActionDropDown: '@ui.entityActionDropDown',
    headerBar: '@ui.headerBar',
    breadcrumb: '@ui.breadcrumb',
    identificationForm: '@ui.identificationForm'
  },

  events: {
    'click @ui.submit': 'onClickSubmit'
  },

  onBeforeShow: function() {
    this.showChildView(
      'headerBar',
      new EntityHeaderBarView({
        model: this.model
      })
    );

    this.showChildView(
      'breadcrumb',
      new BreadcrumbView({
        breadcrumbs: [
          {
            link: '#nondep/dashboard',
            text: 'Home'
          },
          {
            link: '#nondep/entity/' + this.model.get('id') + '/dashboard',
            text: this.model.get('entityName')
          },
          {
            text: 'Edit Identification'
          }
        ]
      })
    );

    this.identificationForm = new NonDepEntityFormSectionIdentificationView({
      viewMode: 'adHocEdit',
      model: this.model
    });

    this.showChildView('identificationForm', this.identificationForm);
  },

  onClickSubmit: function(e) {
    e.preventDefault();

    this.validate().done(
      function() {
        var laddaContext = Ladda.create(this.ui.submit.get(0));
        laddaContext.start();
        SubmitEntityIdentification(this.getFormData())
          .done(
            function() {
              Radio.channel('navigate').trigger('show:int:nd:entity-dashboard', this.model.get('id'));
            }.bind(this)
          )
          .fail(function() {
            laddaContext.stop();
          });
      }.bind(this)
    );
  },

  validate: function() {
    return this.identificationForm.validate({
      type: 'submit'
    });
  },

  getFormData: function() {
    var formData = this.identificationForm.getFormData();
    formData.entityId = this.model.get('id');
    return formData;
  }
});
