/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var moment = require('moment');

var cleanUpResponse = require('common/util/cleanUpResponse');

var buildExternalUserDisplay = require('../../common/util/buildExternalUserDisplay');

module.exports = function(entityId) {
  var deferred = $.Deferred();

  $.soap({
    body: {
      GetExternalActiveFilingsByEntity: {
        $xmlns: 'http://dobs.pa.gov/dp/filing/bpm/1.0',
        EntityID: entityId
      }
    },
    blueprintArrays: ['GetExternalActiveFilingsByEntityResponse.DP_Filing'],
    blueprint: {
      filings: [
        '$..DP_Filing',
        {
          filingId: '$..ID',
          entityId: '$..EntityID',
          filingTypeId: '$..FilingTypeID',
          filingType: '$..FilingType',
          recordStatus: '$..RecordStatus',
          receivedDate: '$..ReceivedDate',
          recordStatusId: '$..RecordStatusID',
          submissionTypeName: '$..SubmissionTypeName',
          lawyerUserId: '$..LawyerUserID', // string example: 'jdoe'
          lawyerFirstName: '$..LawyerFirstName',
          lawyerLastName: '$..LawyerLastName'
        }
      ]
    }
  })
    .done(function(response) {
      cleanUpResponse(response);
      _.each(response.filings, function(filing) {
        if (filing.receivedDate) {
          filing.receivedDate = moment(filing.receivedDate, 'YYYY-MM-DD');
        }
        if (_.includes(['100', '200', '300'], filing.filingTypeId) && filing.submissionTypeName) {
          filing.filingType = filing.filingType + ' - ' + filing.submissionTypeName;
        }
        filing.filingType = filing.filingType || '--';
        filing.recordStatus = filing.recordStatus || '--';
        filing.lawyerDisplay = buildExternalUserDisplay(
          filing.lawyerUserId,
          filing.lawyerFirstName,
          filing.lawyerLastName
        );
      });
      deferred.resolve(response.filings);
    })
    .fail(function(err) {
      deferred.reject();
    });

  return deferred.promise();
};
