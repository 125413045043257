var Handlebars = require("handlebars/dist/handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression;

  return "\n<div class=\"official-top-header-container\"></div>\n<nav class=\"navbar navbar-ext-default\">\n  <div class=\"container-fluid\">\n    <div class=\"navbar-header\">\n      <button type=\"button\" class=\"navbar-toggle collapsed\" data-toggle=\"collapse\"\n        data-target=\"#bs-example-navbar-collapse-1\" aria-expanded=\"false\">\n        <span class=\"sr-only\">Toggle navigation</span>\n        <span class=\"icon-bar\"></span>\n        <span class=\"icon-bar\"></span>\n        <span class=\"icon-bar\"></span>\n      </button>\n      <div class=\"logo-text\">\n        <a href=\"#ext/applications\">\n          <img class=\"logo\" src=\"img/DoB&SlogoKOKeystonet.png\" alt=\"logo\" />\n          <p class=\"navbar-text navbar-brand\">Self-Service</p>\n        </a>\n      </div>\n    </div>\n    <div class=\"collapse navbar-collapse\" id=\"int-main-menu\">\n      <ul class=\"nav navbar-nav navbar\">\n        <li><a href=\"#ext/applications\">Program Selection</a></li>\n      </ul>\n      <ul class=\"nav navbar-nav navbar-right\">\n        <li>\n          <p class=\"navbar-text welcome-text\">Welcome, "
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.user : depth0)) != null ? stack1.firstName : stack1), depth0))
    + " "
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.user : depth0)) != null ? stack1.lastName : stack1), depth0))
    + "</p>\n        </li>\n        <li><a href=\"#\" class=\"logout\">Logout</a></li>\n      </ul>\n    </div>\n  </div>\n</nav>";
},"useData":true});