/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Marionette = require('backbone.marionette');

var PageableCollection = require('psa-backbone/models/PageableCollection');
var GridView = require('psa-marionette/views/GridView');

var ButtonLinkCell = require('common/backgrid/ButtonLinkCell');
var MomentCell = require('common/backgrid/MomentCell');

var tmplNonDepLicenseComplianceView = require('../templates/NonDepLicenseComplianceView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplNonDepLicenseComplianceView,

  className: 'non-dep-license-compliance-view',

  ui: {
    complianceContainer: '.compliance-container'
  },

  regions: {
    complianceContainer: '@ui.complianceContainer'
  },

  onBeforeShow: function() {
    var licenseComplianceGrid = new GridView({
      columns: [
        {
          name: 'caseNumber',
          label: 'Case Number',
          cell: 'string',
          headerCell: 'custom',
          width: 12,
          editable: false
        },
        {
          name: 'administrator',
          label: 'Administrator',
          cell: 'string',
          headerCell: 'custom',
          width: 15,
          editable: false
        },
        {
          name: 'submissionDate',
          label: 'Submitted On',
          cell: MomentCell.MomentCell,
          formatter: MomentCell.MomentDateFormatter.extend({
            emptyValue: '--'
          }),
          sortValue: MomentCell.MomentSortValue,
          headerCell: 'custom',
          width: 15,
          editable: false
        },
        {
          name: 'complianceStatus',
          label: 'Status',
          cell: 'string',
          headerCell: 'custom',
          width: 22,
          editable: false
        },
        {
          name: '',
          label: '',
          cell: ButtonLinkCell.extend({
            name: 'Open',
            buttonClass: 'btn-primary btn-sm',
            href: function(model) {
              return '#nondep/compliance/case/' + model.get('id');
            }
          }),
          headerCell: 'custom',
          width: 10,
          editable: false,
          sortable: false
        }
      ],
      collection: new PageableCollection(this.model.get('complianceCases'), { pageSize: 10 }),
      emptyText: 'No Related Compliance Cases'
    });
    this.showChildView('complianceContainer', licenseComplianceGrid);
  }
});
