/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var cleanUpResponse = require('common/util/cleanUpResponse');

module.exports = function(formData) {
  var deferred = $.Deferred();

  $.soap({
    body: {
      SubmitFiling: {
        $xmlns: 'http://dobs.pa.gov/cf/filing/bpm/1.0',
        ApplicationTypeID: formData.applicationTypeId,
        FilingTypeID: formData.filingTypeId,
        IsIndefinite: formData.isIndefinite,
        Amount: formData.amount,
        FeeAmount: formData.feeAmount,
        ID: formData.filingId,
        Documents: (function() {
          if (!formData.documents.length) {
            return {};
          }
          return {
            DocumentID: formData.documents
          };
        })()
      }
    },
    blueprint: {
      filingId: '$..FilingID'
    }
  })
    .done(function(response) {
      cleanUpResponse(response, {
        cleanUpTextKeys: true
      });
      deferred.resolve(response.filingId);
    })
    .fail(function(err) {
      deferred.reject();
    });

  return deferred.promise();
};
