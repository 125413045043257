/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var _get = require('lodash.get');
var moment = require('moment');

module.exports = function(searchBody, formData, lookupHashes) {
  var deferred = $.Deferred();

  formData = formData || {};
  var entityTypeIds = formData.entityTypeIds;
  var filingTypeIds = formData.filingTypeIds;
  var recordStatusId = formData.recordStatusId;
  var genericSubmissionTypeId = formData.genericSubmissionTypeId;

  var entityTypes = lookupHashes.entityTypes || {};
  var filingTypes = lookupHashes.filingTypes || {};
  var filingRecordStatuses = lookupHashes.filingRecordStatuses || {};
  var filingWorkflowStatuses = lookupHashes.filingWorkflowStatuses || {};
  var genericSubmissionTypes = lookupHashes.genericSubmissionTypes || {};

  $.soap({
    body: {
      PerformElasticSearch: {
        $xmlns: 'http://dobs.pa.gov/cm/search/bpm/1.0',
        IndexName: 'dp-filing',
        SearchBody: JSON.stringify(searchBody)
      }
    },
    blueprint: {
      searchResults: '$..Response'
    }
  })
    .done(function(result) {
      var searchResult = JSON.parse(result.searchResults);
      // For Testing
      // console.log(JSON.stringify(searchResult, null, 2));

      var hits = _.map(searchResult.hits.hits, function(hit) {
        var result = hit._source;
        result.filingType = '--';
        result.filingRecordStatus = '--';
        result.genericSubmissionType = '--';
        // Set text result of lookup value results
        if (result.filingTypeId) {
          result.filingType = filingTypes[result.filingTypeId];
        }

        if (result.recordStatusId) {
          result.filingRecordStatus = filingRecordStatuses[result.recordStatusId];
        }

        if (result.workflowStatusId) {
          result.filingWorkflowStatus = filingWorkflowStatuses[result.workflowStatusId];
        }

        if (result.genericSubmissionTypeId) {
          result.genericSubmissionType = genericSubmissionTypes[result.genericSubmissionTypeId];
        }

        if (result.actionDate) {
          result.actionDate = moment(result.actionDate, moment.ISO_8601);
        }
        // Set highlights for lookup values
        result.highlight = hit.highlight || {};
        if (entityTypeIds) {
          var entityType = entityTypes[entityTypeIds[0]];
          if (entityType) {
            result.highlight.entityType = ['<em>' + entityType + '</em>'];
          }
        }

        if (filingTypeIds) {
          var filingType = filingTypes[filingTypeIds[0]];
          if (filingType) {
            result.highlight.filingType = ['<em>' + filingType + '</em>'];
          }
        }

        if (genericSubmissionTypeId) {
          var genericSubmissionType = genericSubmissionTypes[genericSubmissionTypeId];
          if (genericSubmissionType) {
            result.highlight.genericSubmissionType = ['<em>' + genericSubmissionType + '</em>'];
          }
        }

        if (recordStatusId) {
          var filingRecordStatus = filingRecordStatuses[recordStatusId];
          if (filingRecordStatus) {
            result.highlight.filingRecordStatus = ['<em>' + filingRecordStatus + '</em>'];
          }
        }

        if (hit.inner_hits) {
          var dbaInnerHits = _get(hit, 'inner_hits.dbas.hits.hits');
          if (dbaInnerHits) {
            var dbaHighlights = _.reduce(
              dbaInnerHits,
              function(memo, dbaInnerHit) {
                var highlight = dbaInnerHit.highlight['dbas.dba'];
                if (highlight) {
                  return memo.concat(highlight);
                } else {
                  return memo;
                }
              },
              []
            );
            result.highlight['dbas.dba'] = dbaHighlights;
          }
        }
        return result;
      });

      var results = {
        hits: hits,
        totalHits: searchResult.hits.total.value,
        took: searchResult.took
      };
      deferred.resolve(results);
    })
    .fail(function(err) {
      deferred.reject(err);
    });

  return deferred.promise();
};

