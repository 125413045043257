/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _get = require('lodash.get');
var Backbone = require('backbone');
var NProgress = require('nprogress');

var GetApplication = require('../../services/GetApplication');
var GetOrganizationIdentifiers = require('../../../common/services/GetOrganizationIdentifiers');
var GetAllRegistrationReviewLookups = require('../../services/GetAllRegistrationReviewLookups');
var SecExamineApplicationAndRegistrantTaskView = require('./SecExamineApplicationAndRegistrantTaskView');

module.exports = {
  event: 'build:sc:examine-application-and-registrant',

  channel: 'task',

  builder: function(task) {
    var defer = $.Deferred();
    var applicationId = _get(task, 'taskData.SC.scApplicationId');
    var organizationId = _get(task, 'taskData.SC.scOrganizationId');

    $.when(GetApplication(applicationId), GetOrganizationIdentifiers(organizationId), GetAllRegistrationReviewLookups())
      .done(function(application, entityIdentifiers, lookups) {
        NProgress.set(0.85);

        defer.resolve(
          new SecExamineApplicationAndRegistrantTaskView({
            model: new Backbone.Model({
              task: task,
              taskId: task.taskId,
              organizationId: organizationId,
              application: application,
              entityIdentifiers: entityIdentifiers,
              decisions: lookups.decisions
            })
          })
        );
      })
      .fail(function(err) {
        defer.reject(err);
      });

    return defer.promise();
  }
};
