/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2020] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Backbone = require('backbone');
var Marionette = require('backbone.marionette');
var Radio = require('backbone.radio');
var Ladda = require('ladda');

var BreadcrumbView = require('common/views/BreadcrumbView');

var ConSerContactFormView = require('../views/ConSerContactFormView');

var SubmitContact = require('../../common/services/SubmitContact');

var tmplConSerContacNewView = require('../templates/ConSerContactNewView.hbs');

var toggleForm = require('common/util/toggleForm');

module.exports = Marionette.LayoutView.extend({
  template: tmplConSerContacNewView,

  className: 'con-ser-contact-new-view container-fluid',

  ui: {
    breadcrumb: '.breadcrumb-container',
    contactContainer: '.contact-form-container',
    submit: '.submit'
  },

  regions: {
    breadcrumb: '@ui.breadcrumb',
    contactContainer: '@ui.contactContainer'
  },

  events: {
    'click @ui.submit': 'onClickSubmit'
  },

  onBeforeShow: function() {
    var entityId = this.model.get('entity').entityId;
    var departmentId = this.model.get('entity').departmentId;
    var entityName = this.model.get('entity').entityName;

    var breadcrumbs = [
      {
        link: '#conser/dashboard',
        text: 'Home'
      },
      {
        text: 'New Contact'
      }
    ];

    if (entityId) {
      breadcrumbs.splice(1, 0, {
        link: '#conser/' + departmentId + '/entity/' + entityId + '/dashboard',
        text: entityName
      });
    }
    this.showChildView(
      'breadcrumb',
      new BreadcrumbView({
        breadcrumbs: breadcrumbs
      })
    );

    this.contactForm = new ConSerContactFormView({
      model: new Backbone.Model({
        contactType: this.model.get('contactType')
      })
    });

    this.showChildView('contactContainer', this.contactForm);
    toggleForm(this.contactForm.ui.contactType, false);
  },

  onClickSubmit: function(e) {
    e.preventDefault();
    var self = this;
    this.validate().done(function() {
      var entityId = self.model.get('entity').entityId;
      var departmentId = self.model.get('entity').departmentId;

      var l = Ladda.create(self.ui.submit[0]);
      l.start();

      var formData = self.getFormData(entityId, departmentId);

      SubmitContact(formData)
        .done(function() {
          Radio.channel('navigate').trigger('show:int:cs:entity', departmentId, entityId);
        })
        .fail(function() {
          l.stop();
        });
    });
  },

  getFormData: function(entityId, departmentId) {
    var formData = this.contactForm.getFormData();
    formData.entityId = entityId;
    formData.departmentId = departmentId;
    formData.isDeleted = 0;

    return formData;
  },

  validate: function() {
    return this.ui.contactContainer.parsley().whenValidate();
  }
});
