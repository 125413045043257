/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');

module.exports = function(data) {
  var deferred = $.Deferred();

  $.soap({
    body: {
      SubmitEntitySiteRecordKeepingApplication: {
        $xmlns: 'http://dobs.pa.gov/nd/application/bpm/1.0',
        EntityID: data.entityId,
        ApplicationID: data.applicationId,
        IsCertified: data.isCertified ? '1' : '0',
        CertificationName: data.certificationName,
        ApplicationDocuments: (function() {
          if (data.applicationDocuments.length === 0) {
            return {};
          }
          return {
            DocumentID: data.applicationDocuments
          };
        })(),
        SitesRecordKeeping: (function() {
          if (!data.booksAndRecords.length) {
            return {};
          }
          return {
            SiteRecordKeeping: _.map(data.booksAndRecords, function(booksAndRecord) {
              return {
                CompanyName: booksAndRecord.companyName,
                FirstName: booksAndRecord.firstName,
                LastName: booksAndRecord.lastName,
                Address: {
                  AddressLine1: booksAndRecord.addressLine1,
                  AddressLine2: booksAndRecord.addressLine2,
                  AddressLine3: booksAndRecord.addressLine3,
                  City: booksAndRecord.city,
                  State: booksAndRecord.state,
                  PostalCode: booksAndRecord.postalCode,
                  Country: booksAndRecord.country,
                  County: booksAndRecord.county
                },
                Phone: booksAndRecord.phone,
                Fax: booksAndRecord.fax,
                Email: booksAndRecord.email,
                Description: booksAndRecord.description,
                IsDeleted: booksAndRecord.isDeleted,
                SiteRecordkeepingID: booksAndRecord.siteRecordkeepingId
              };
            })
          };
        })()
      }
    }
  })
    .done(function(response) {
      deferred.resolve(response);
    })
    .fail(function(err) {
      deferred.reject();
    });

  return deferred.promise();
};
