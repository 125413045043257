/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2020] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Marionette = require('backbone.marionette');
var Syphon = require('backbone.syphon');

var tmplSecIndividualGeneralFormView = require('../templates/SecIndividualGeneralFormView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplSecIndividualGeneralFormView,

  className: 'sec-individual-general-form-view',
  options: {
    mode: 'new'
  },

  ui: {
    otherIdNumber: 'input[name="otherIdNumber"]',
    crdNumberFormGroup: '.crdnumberform-group',
    crdNumber: 'input[name="crdNumber"]',
    noCRDNumber: '.no-crd-number'
  },

  templateHelpers: function() {
    return {
      viewMode: this.options.mode
    };
  },

  events: {
    'change @ui.otherIdNumber': 'onChangeOtherIdNumber',
    'change @ui.crdNumber': 'onChangeCRDNumber',
    'click @ui.noCRDNumber': 'onClickNoCRD'
  },

  onBeforeShow: function() {
    Syphon.deserialize(this, this.model.toJSON());
    if (this.ui.crdNumber.val() === '' && this.options.mode === 'edit') {
      this.ui.noCRDNumber.prop('checked', true)
      this.ui.crdNumber.prop('disabled', true);
      this.ui.crdNumberFormGroup.toggleClass('required', false);
    }
    this.onChangeCRDNumber();
    this.onChangeOtherIdNumber();
  },

  onChangeOtherIdNumber: function() {
    var isEmptyOtherIdNumber;

    if (this.options.mode === 'new') {
      isEmptyOtherIdNumber = !this.ui.otherIdNumber[0].checked;
    } else {
      isEmptyOtherIdNumber = this.ui.otherIdNumber.val() === '';
    }
    if (isEmptyOtherIdNumber) {
      this.ui.crdNumberFormGroup.toggleClass('required', true);
      this.ui.crdNumber.attr('data-parsley-required', true);
    } else {
      this.ui.crdNumberFormGroup.toggleClass('required', false);
      this.ui.crdNumber.removeAttr('data-parsley-required');
    }
  },

  onChangeCRDNumber: function() {
    if (this.options.mode === 'edit') {
      if (this.ui.crdNumber.val() === '') {
        this.ui.noCRDNumber.prop('disabled', false);
      } else {
        this.ui.noCRDNumber.prop('disabled', true);
      }
    }
  },

  onClickNoCRD: function() {
    if (this.ui.noCRDNumber[0].checked) {
      this.ui.crdNumber.prop('disabled', true);
      this.ui.crdNumberFormGroup.toggleClass('required', false);
    } else {
      this.ui.crdNumber.prop('disabled', false);
      this.ui.crdNumberFormGroup.toggleClass('required', true);
    }
  }
});

