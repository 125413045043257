/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Marionette = require('backbone.marionette');
var Syphon = require('backbone.syphon');
var Radio = require('backbone.radio');
var Ladda = require('ladda');

var BreadcrumbView = require('common/views/BreadcrumbView');
var NonDepInternalSidebarNavView = require('../../common/views/NonDepInternalSidebarNavView');

var SubmitInternalNewEntity = require('../services/SubmitInternalNewEntity');

require('../styles/NonDepInternalNewEntityView.css');
var tmplNonDepInternalEntityFormView = require('../templates/NonDepInternalEntityFormView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplNonDepInternalEntityFormView,

  className: 'nondep-internal-new-entity-view container-fluid',

  ui: {
    sideBar: '.side-bar-container',
    breadCrumbContainer: '.breadcrumb-container',
    newEntityForm: '.new-entity-form',
    submit: '.btn-submit'
  },

  regions: {
    breadCrumbContainer: '@ui.breadCrumbContainer',
    sideBar: '@ui.sideBar'
  },

  events: {
    'click @ui.submit': 'onClickSubmit'
  },

  onBeforeShow: function() {
    this.showChildView('sideBar', new NonDepInternalSidebarNavView());

    this.showChildView(
      'breadCrumbContainer',
      new BreadcrumbView({
        breadcrumbs: [
          {
            text: 'Home',
            link: '#nondep/dashboard'
          },
          {
            text: 'Create A New Entity'
          }
        ]
      })
    );
  },

  onClickSubmit: function(e) {
    e.preventDefault();

    this.ui.newEntityForm
      .parsley()
      .whenValidate()
      .done(
        function() {
          var laddaContext = Ladda.create(this.ui.submit.get(0));
          laddaContext.start();
          SubmitInternalNewEntity(this.getFormData())
            .done(function(newEntityId) {
              Radio.channel('navigate').trigger('show:int:nd:entity-dashboard', newEntityId);
            })
            .fail(function() {
              laddaContext.stop();
            });
        }.bind(this)
      );
  },

  getFormData: function() {
    return Syphon.serialize(this.ui.newEntityForm);
  }
});
