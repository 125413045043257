var Handlebars = require("handlebars/dist/handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "      <label class=\"radio-inline\">\n        <input type=\"radio\" name=\"nameTypeId\" value=\""
    + alias4(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\" data-parsley-errors-container=\".name-type-error-container\">\n        "
    + alias4(((helper = (helper = helpers.nameType || (depth0 != null ? depth0.nameType : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"nameType","hash":{},"data":data}) : helper)))
    + "\n      </label>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "\n<form class=\"name-res-form\">\n  <label class=\"control-label\">Name Type</label>\n  <div class=\"form-group\">\n"
    + ((stack1 = helpers.each.call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.nameTypes : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "    <div class=\"name-type-error-container\"></div>\n  </div>\n  <div class=\"proposed-name\">\n    <div class=\"form-group\">\n      <label class=\"control-label\">Proposed Name</label>\n      <input type=\"text\" name=\"proposedName\" class=\"form-control\" placeholder=\"Proposed Name\"\n        data-parsley-maxlength=\"200\" />\n    </div>\n    <div class=\"form-group\">\n      <label class=\"control-label\">Proposed Name Comments</label>\n      <textarea name=\"proposedNameComments\" class=\"form-control input-view\" placeholder=\"Proposed Name Comments\"\n        rows=\"6\"></textarea>\n    </div>\n    <div class=\"form-group print-view\" id=\"proposedNameCommentsPrintView\"></div>\n    <div class=\"row\">\n      <div class=\"col-md-12 col-sm-12\">\n        <div class=\"form-group\">\n          <div class=\"proposed-name-container\"></div>\n        </div>\n      </div>\n    </div>\n  </div>\n  <div class=\"interim-name\">\n    <div class=\"form-group\">\n      <label class=\"control-label\">Interim Name</label>\n      <input type=\"text\" name=\"interimName\" class=\"form-control\" placeholder=\"Interim Name\"\n        data-parsley-maxlength=\"200\" />\n    </div>\n    <div class=\"form-group\">\n      <label class=\"control-label\">Interim Name Comments</label>\n      <textarea name=\"interimNameComments\" class=\"form-control input-view\" placeholder=\"Interim Name Comments\" rows=\"6\"></textarea>\n    </div>\n    <div class=\"form-group print-view\" id=\"interimNameCommentsPrintView\"></div>\n    <div class=\"row\">\n      <div class=\"col-md-12 col-sm-12\">\n        <div class=\"form-group\">\n          <div class=\"interim-name-container\"></div>\n        </div>\n      </div>\n    </div>\n  </div>\n</form>";
},"useData":true});