var Handlebars = require("handlebars/dist/handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "    <div class=\"form-group\">\n      <label class=\"col-md-3 control-label\">Exam Instructions</label>\n      <div class=\"col-md-9\">\n        <div class=\"form-control-static\">\n          <a href=\"#\" class=\"exam-instructions\">Click here to download instructions</a>\n        </div>\n      </div>\n    </div>\n";
},"3":function(container,depth0,helpers,partials,data) {
    return "    <div class=\"form-group\">\n      <label class=\"col-md-3 control-label\">Exam Questionnaire</label>\n      <div class=\"col-md-9\">\n        <div class=\"form-control-static\">\n          <a href=\"#\" class=\"exam-questionnaire\">Click here to download questionnaire</a>\n        </div>\n      </div>\n    </div>\n";
},"5":function(container,depth0,helpers,partials,data) {
    return "    <div class=\"form-group\">\n      <label class=\"col-md-3 control-label\">Setup Letter Verification</label>\n      <div class=\"col-md-9 required\">\n        <div class=\"checkbox\">\n          <label>\n            <input type=\"checkbox\" name=\"hasReadSetupLetter\" data-parsley-required=\"true\" tabindex=\"-1\"> I verify that I have read the set-up letter (sent via email).\n          </label>\n        </div>\n      </div>\n    </div>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {});

  return "\n<h1 class=\"page-title\">"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? depth0.task : depth0)) != null ? stack1.taskType : stack1)) != null ? stack1.description : stack1), depth0))
    + "</h1>\n<div class=\"header-bar\"></div>\n<div class=\"breadcrumb-container\"></div>\n\n<form class=\"exam-materials-form\">\n  <div class=\"form-horizontal\">\n    <div class=\"form-group\">\n      <label class=\"col-md-3 control-label\">Exam Scheduled Date</label>\n      <div class=\"col-md-9\">\n        <div class=\"form-control-static\">"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.exam : depth0)) != null ? stack1.examDate : stack1), depth0))
    + "</div>\n      </div>\n    </div>\n"
    + ((stack1 = helpers["if"].call(alias3,((stack1 = (depth0 != null ? depth0.exam : depth0)) != null ? stack1.examInstructionsDocumentId : stack1),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias3,((stack1 = (depth0 != null ? depth0.exam : depth0)) != null ? stack1.examQuestionnaireDocumentId : stack1),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias3,((stack1 = (depth0 != null ? depth0.exam : depth0)) != null ? stack1.setupLetterDocumentId : stack1),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "  </div>\n  <div class=\"row row-exam-questions\">\n    <div class=\"col-md-3 col-exam-questions-label\">\n      <label class=\"control-label\">Requested Exam Information</label>\n    </div>\n    <div class=\"col-md-6\">\n      <div class=\"exam-questions-container\"></div>\n    </div>\n  </div>\n</form>\n<div class=\"row m-b-lg\">\n  <div class=\"col-md-6 col-md-offset-3\">\n    <a href=\"#ext/nondep/dashboard\" class=\"btn btn-default\" role=\"button\">Cancel</a>\n    <button class=\"btn btn-success save ladda-button\" data-style=\"expand-right\">\n      <span class=\"ladda-label\"><i class=\"fa fa-save\"></i> Save</span>\n    </button>\n    <button class=\"btn btn-primary submit ladda-button\" data-style=\"expand-right\" type=\"button\">\n      <span class=\"ladda-label\">Submit</span>\n    </button>\n  </div>\n</div>";
},"useData":true});