/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var moment = require('moment');

var cleanUpResponse = require('common/util/cleanUpResponse');

module.exports = function(entityId) {
  var deferred = $.Deferred();

  $.soap({
    body: {
      GetDirectorsByEntity: {
        $xmlns: 'http://dobs.pa.gov/dp/director/bpm/1.0',
        EntityID: entityId
      }
    },
    blueprintArrays: ['GetDirectorsByEntityResponse.DP_EntityDirector'],
    blueprint: {
      directors: [
        '$..DP_EntityDirector',
        {
          directorId: '$..ID',
          entityId: '$..EntityID',
          positionHeld: '$..PositionHeld',
          firstName: '$..FirstName',
          lastName: '$..LastName',
          middleInitial: '$..MiddleInitial',
          termLength: '$..TermLength',
          statusId: '$..StatusID',
          status: '$..Status',
          termStartDate: '$..TermStartDate',
          termEndDate: '$..TermEndDate',
          positionHeldId: '$..PositionHeldID',
          positionHeldLookup: '$..PositionHeldLookup',
          isDeleted: '$..IsDeleted'
        }
      ]
    }
  })
    .done(function(response) {
      cleanUpResponse(response);
      _.each(response.directors, function(director) {
        if (director.termStartDate) {
          director.termStartDate = moment(director.termStartDate, 'YYYY-MM-DD');
        }

        if (director.termEndDate) {
          director.termEndDate = moment(director.termEndDate, 'YYYY-MM-DD');
        }

        // Credit Unions have position held as a lookup, so show that value if
        // its set, otherwise show the free text value of position held.
        director.positionHeld = director.positionHeldLookup || director.positionHeld;

        _.each(['positionHeld', 'firstName', 'lastName', 'middleInitial', 'termLength', 'status'], function(attr) {
          if (!director[attr]) {
            director[attr] = '--';
          }
        });
      });

      deferred.resolve(response.directors);
    })
    .fail(function(err) {
      deferred.reject();
    });

  return deferred.promise();
};
