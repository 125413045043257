/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var moment = require('moment');

var cleanUpResponse = require('common/util/cleanUpResponse');

module.exports = function(entityId) {
  var deferred = $.Deferred();

  $.soap({
    body: {
      GetExternalActiveApplicationsByEntity: {
        $xmlns: 'http://dobs.pa.gov/nd/application/bpm/1.0',
        EntityID: entityId
      }
    },
    blueprintArrays: ['GetExternalActiveApplicationsByEntityResponse.ND_Application'],
    blueprint: {
      activeApplications: [
        '$..ND_Application',
        {
          id: '$..ID',
          applicationNumber: '$..ApplicationNumber',
          applicationType: '$..ApplicationType',
          licenseType: '$..LicenseType',
          applicationStatus: '$..ApplicationStatus',
          submissionDate: '$..SubmissionDate',
          applicationCategoryId: '$..ApplicationCategoryID'
        }
      ]
    }
  })
    .done(function(response) {
      cleanUpResponse(response);

      _.each(response.activeApplications, function(app) {
        app.submissionDate = app.submissionDate ? moment(app.submissionDate, moment.ISO_8601) : '--';
        app.entityId = entityId;
        app.licenseType = app.licenseType || '--';
      });

      deferred.resolve(response.activeApplications);
    })
    .fail(function(err) {
      deferred.reject();
    });

  return deferred.promise();
};
