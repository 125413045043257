var Handlebars = require("handlebars/dist/handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "            <option value=\""
    + alias4(((helper = (helper = helpers.statusId || (depth0 != null ? depth0.statusId : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"statusId","hash":{},"data":data}) : helper)))
    + "\">"
    + alias4(((helper = (helper = helpers.status || (depth0 != null ? depth0.status : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"status","hash":{},"data":data}) : helper)))
    + "</option>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "            <option value=\""
    + alias4(((helper = (helper = helpers.officeTypeId || (depth0 != null ? depth0.officeTypeId : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"officeTypeId","hash":{},"data":data}) : helper)))
    + "\">"
    + alias4(((helper = (helper = helpers.officeType || (depth0 != null ? depth0.officeType : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"officeType","hash":{},"data":data}) : helper)))
    + "</option>\n";
},"5":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "            <option value=\""
    + alias4(((helper = (helper = helpers.locationTypeId || (depth0 != null ? depth0.locationTypeId : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"locationTypeId","hash":{},"data":data}) : helper)))
    + "\">"
    + alias4(((helper = (helper = helpers.locationType || (depth0 != null ? depth0.locationType : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"locationType","hash":{},"data":data}) : helper)))
    + "</option>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "\n<div class=\"form-horizontal\">\n  <div class=\"row\">\n    <div class=\"col-sm-6\">\n      <div class=\"form-group required\">\n        <label class=\"control-label col-sm-6\">Status</label>\n        <div class=\"col-sm-6\">\n          <select name=\"statusId\" class=\"location-status-select form-control\" data-parsley-required=\"true\">\n            <option value=\"\">--</option>\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.locationStatus : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "          </select>\n        </div>\n      </div>\n    </div>\n    <div class=\"col-sm-6\">\n      <div class=\"form-group \">\n        <label class=\"control-label col-sm-6\">Date Opened</label>\n        <div class=\"col-sm-6\">\n          <div class=\"input-group\">\n            <input type=\"text\" name=\"openedDate\" class=\"form-control datepicker opened-Date\" placeholder=\"Date Opened\"\n               data-parsley-errors-container=\"#openDateErrorsContainer\" data-parsley-date=\"\"\n              data-parsley-trigger-after-failure=\"changeDate\" />\n            <div class=\"input-group-addon\">\n              <i class=\"fa fa-calendar\"></i>\n            </div>\n          </div>\n        </div>\n        <div class=\"col-sm-6 col-sm-offset-6\">\n          <div id=\"openDateErrorsContainer\"></div>\n        </div>\n      </div>\n    </div>\n  </div>\n  <div class=\"row\">\n    <div class=\"col-sm-6\">\n      <div class=\"form-group\">\n        <label class=\"control-label col-sm-6\">Office Type</label>\n        <div class=\"col-sm-6\">\n          <select name=\"officeTypeId\" class=\"office-type-select form-control\">\n            <option value=\"\">--</option>\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.locationOfficeType : depth0),{"name":"each","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "          </select>\n        </div>\n      </div>\n    </div>\n    <div class=\"col-sm-6\">\n      <div class=\"form-group \">\n        <label class=\"control-label col-sm-6\">Date Closed</label>\n        <div class=\"col-sm-6\">\n          <div class=\"input-group\">\n            <input type=\"text\" name=\"closedDate\" class=\"form-control datepicker closed-Date\" placeholder=\"Date Closed\"\n              data-parsley-errors-container=\"#closedDateChangeErrorsContainer\" data-parsley-date=\"\"\n              data-parsley-trigger-after-failure=\"changeDate\" />\n            <div class=\"input-group-addon\">\n              <i class=\"fa fa-calendar\"></i>\n            </div>\n          </div>\n        </div>\n        <div class=\"col-sm-6 col-sm-offset-6\">\n          <div id=\"closedDateChangeErrorsContainer\"></div>\n        </div>\n      </div>\n    </div>\n  </div>\n  <div class=\"row\">\n    <div class=\"col-sm-6 activity-type\">\n      <div class=\"form-group\">\n        <label class=\"control-label col-sm-6\">Activities</label>\n        <div class=\"col-sm-6\">\n          <div class=\"activity-type-select\"></div>\n        </div>\n      </div>\n    </div>\n    <div class=\"col-sm-6\">\n      <div class=\"form-group\">\n        <label class=\"control-label col-sm-6\">New Location Type</label>\n        <div class=\"col-sm-6\">\n          <select name=\"locationTypeId\" class=\"form-control\">\n            <option value=\"\">--</option>\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.locationType : depth0),{"name":"each","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "          </select>\n        </div>\n      </div>\n    </div>\n  </div>\n  <div class=\"address\"></div>\n  <div class=\"form-group relocated-to\">\n    <label class=\"control-label col-sm-3\">Relocated To</label>\n    <div class=\"col-sm-9\">\n      <textarea class=\"form-control\" rows=\"4\" cols=\"100\" name=\"relocatedTo\"\n        placeholder=\"Address, City, State, Zip and County where it was Relocated To\"></textarea>\n    </div>\n  </div>\n  <div class=\"form-group Comments\">\n    <label class=\"control-label col-sm-3\">Comments</label>\n    <div class=\"col-sm-9\">\n      <textarea class=\"form-control\" rows=\"4\" cols=\"100\" name=\"comments\" placeholder=\"Comments\"></textarea>\n    </div>\n  </div>\n</div>";
},"useData":true});