/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var moment = require('moment');

var cleanUpResponse = require('common/util/cleanUpResponse');

module.exports = function(data) {
  var defer = $.Deferred();

  $.soap({
    body: {
      SubmitSubsidiary: {
        $xmlns: 'http://dobs.pa.gov/dp/subsidiary/bpm/1.0',
        EntityID: data.entityId,
        SubsidiaryID: data.subsidiaryId,
        StatusDate: data.statusDate ? moment(data.statusDate, 'MM-DD-YYYY').format('YYYY-MM-DD') : null,
        Name: data.name,
        StatusID: data.statusId,
        ApprovalDate: data.approvalDate ? moment(data.approvalDate, 'MM-DD-YYYY').format('YYYY-MM-DD') : null,
        TypeID: data.typeId,
        IsDeleted: data.isDeleted,
        Comments: data.comments
      }
    },
    blueprint: {
      newSubsidiaryId: '$..NewSubsidiaryID'
    }
  })
    .done(function(response) {
      cleanUpResponse(response, { cleanUpTextKeys: true });
      defer.resolve(response);
    })
    .fail(function(err) {
      defer.reject();
    });

  return defer.promise();
};
