/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var Marionette = require('backbone.marionette');
var Syphon = require('backbone.syphon');
var Ladda = require('ladda');
var Radio = require('backbone.radio');

var toggleForm = require('common/util/toggleForm');

var ApplicationPreviousDecisionView = require('../../views/ApplicationPreviousDecisionView');
var SubmitMTApplicationReviewTask = require('../../services/SubmitMTApplicationReviewTask');

var MTReviewInvestigation = require('./MTReviewInvestigation');
var MTReviewIndividualInvestigationCollectionView = require('./MTReviewIndividualInvestigationCollectionView');

var tmplMTReviewApplicationWorkTabView = require('./MTReviewApplicationWorkTabView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplMTReviewApplicationWorkTabView,

  className: 'mt-review-application-work-tab-view container-fluid',

  ui: {
    entityInvestigationContainer: '.entity-investigation-container',
    individualInvestigationsContainer: '.individual-investigations-container',
    reviewForm: '.review-form',
    reviewDecision: '.review-decision',
    decisionReasonContainer: '.decision-reason-container',
    previousDecisionContainer: '.previous-decision-container',
    submit: '.submit'
  },

  regions: {
    entityInvestigationContainer: '@ui.entityInvestigationContainer',
    individualInvestigationsContainer: '@ui.individualInvestigationsContainer',
    previousDecisionContainer: '@ui.previousDecisionContainer'
  },

  events: {
    'change @ui.reviewDecision': 'onChangeReviewDecision',
    'click @ui.submit': 'onClickSubmit'
  },

  onBeforeShow: function() {
    this.showChildView(
      'previousDecisionContainer',
      new ApplicationPreviousDecisionView({
        model: new Backbone.Model(this.model.get('previousReviewDecision'))
      })
    );
    var investigation = this.model.get('applicationInvestigation');
    this.entityInvestigation = new MTReviewInvestigation({
      mode: 'entity',
      isReadOnly: false,
      documentTypes: this.options.documentTypes,
      model: new Backbone.Model({
        applicationInvestigationId: investigation.id,
        hasCriminal: investigation.entityHasCriminal,
        hasFinancial: investigation.entityHasFinancial,
        hasRegulatory: investigation.entityHasRegulatory,
        hasCivil: investigation.entityHasCivil,
        documents: investigation.entityDocuments
      })
    });
    this.showChildView('entityInvestigationContainer', this.entityInvestigation);

    this.individualInvestigations = new MTReviewIndividualInvestigationCollectionView({
      isReadOnly: false,
      documentTypes: this.options.documentTypes,
      collection: new Backbone.Collection(investigation.individuals)
    });
    this.showChildView('individualInvestigationsContainer', this.individualInvestigations);
    this.onChangeReviewDecision();
  },

  onChangeReviewDecision: function() {
    var reviewDecision = this.ui.reviewDecision.val();
    var isApproved = reviewDecision === '1201';
    toggleForm(this.ui.decisionReasonContainer, !isApproved && reviewDecision);
  },

  onClickSubmit: function(e) {
    e.preventDefault();
    var self = this;
    $.when(
      this.ui.reviewForm.parsley().whenValidate(),
      this.individualInvestigations.validate(),
      this.entityInvestigation.validate()
    ).done(function() {
      var l = Ladda.create(self.ui.submit[0]);
      l.start();
      var formData = Syphon.serialize(self);
      var entityInvestigation = self.entityInvestigation.getFormData();
      var individualInvestigations = self.individualInvestigations.getFormData();
      var investigationDocuments = entityInvestigation.concat(individualInvestigations);
      _.extend(formData, {
        taskId: self.model.get('taskId'),
        applicationId: self.model.get('applicationId'),
        applicationInvestigationId: self.model.get('applicationInvestigation').id,
        investigationDocuments: investigationDocuments
      });
      SubmitMTApplicationReviewTask(formData)
        .done(function() {
          Radio.channel('navigate').trigger('show:int:nd:dashboard');
        })
        .fail(function(err) {
          l.stop();
        });
    });
  }
});
