/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var Backbone = require('backbone');

var GetEntityDetails = require('../services/GetEntityDetails');
var GetAllEntityOrganizationTypes = require('../services/GetAllEntityOrganizationTypes');
var GetAllEntityOfficerTypes = require('../services/GetAllEntityOfficerTypes');

var NonDepEntityFormView = require('../views/NonDepEntityFormView');

module.exports = {
  event: 'build:entity-form',

  channel: 'nd:entity-form',

  builder: function(config) {
    var defer = $.Deferred();
    $.when(GetEntityDetails(config.entityId), GetAllEntityOfficerTypes(), GetAllEntityOrganizationTypes())
      .done(function(entity, entityOfficerTypes, entityOrganizationTypes) {
        var data = _.extend(entity, {
          entityOfficerTypes: entityOfficerTypes,
          entityOrganizationTypes: entityOrganizationTypes
        });
        defer.resolve(
          new NonDepEntityFormView({
            isReadOnly: config.isReadOnly,
            model: new Backbone.Model(data)
          })
        );
      })
      .fail(function(err) {
        defer.reject(err);
      });
    return defer.promise();
  }
};
