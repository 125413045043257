/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2020] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Marionette = require('backbone.marionette');
var Syphon = require('backbone.syphon');

var parsleyUtil = require('common/util/parsleyUtil');
var toggleForm = require('common/util/toggleForm');

var DepOathRosterDirectorsCollectionView = require('./DepOathRosterDirectorsCollectionView');

var tmplDepOathRosterDirectorsSectionView = require('./DepOathRosterDirectorsSectionView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplDepOathRosterDirectorsSectionView,

  className: 'dep-oath-roster-directors-section-view',

  behaviors: {
    RadioGroupToggleBehavior: {
      radios: [
        {
          target: '.board-elected-form',
          radioGroup: 'input[name="typeId"]',
          valueToShow: '1'
        },
        {
          target: '.shareholder-elected-form',
          radioGroup: 'input[name="typeId"]',
          valueToShow: '2'
        }
      ]
    },
    ToggleBehavoir: {
      toggles: [
        {
          target: '.director-container',
          group: 'input[name="typeId"]',
          valuesToShow: ['1', '2']
        }
      ]
    }
  },

  ui: {
    directorsForm: '.directors-form',
    addDirector: '.add-director',
    directorsContainer: '.directors-container',
    typeId: 'input[name="typeId"]:checked',
    boardElectedForm: '.board-elected-form',
    shareholderElectedForm: '.shareholder-elected-form'
  },

  regions: {
    directorsContainer: '@ui.directorsContainer'
  },

  events: {
    'click @ui.addDirector': 'onClickAddDirector'
  },

  onBeforeShow: function() {
    Syphon.deserialize(this, this.model.get('filingContent'));

    this.directors = new DepOathRosterDirectorsCollectionView({
      collection: this.options.collection
    });
    this.showChildView('directorsContainer', this.directors);
  },

  onClickAddDirector: function(e) {
    e.preventDefault();
    this.directors.collection.unshift({});
  },

  validate: function(config) {
    this.ui.directorsForm.parsley().reset();
    if (config.type === 'submit') {
      // Disable fields in director forms
      var oathRosterTypeId = this.$('input[name="typeId"]:checked').val();
      toggleForm(this.$('.board-elected-form'), oathRosterTypeId === '1');
      toggleForm(this.$('.shareholder-elected-form'), oathRosterTypeId === '2');

      // Turn on required field validation for this form
      parsleyUtil.enableRequiredValidation(this.ui.directorsForm);
    } else {
      // Turn off required field validation for this form
      parsleyUtil.disableRequiredValidation(this.ui.directorsForm);
    }
    return this.ui.directorsForm.parsley().whenValidate();
  },

  getFormData: function() {
    var formData = Syphon.serialize(this);
    formData.directors = this.directors.getFormData();
    return formData;
  }
});
