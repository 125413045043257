/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var Marionette = require('backbone.marionette');

require('./SideNavSectionButtonView.css');
var tmplSideNavSectionButtonView = require('./SideNavSectionButtonView.hbs');

module.exports = Marionette.ItemView.extend({
  template: tmplSideNavSectionButtonView,

  tagName: 'button',

  className: function() {
    var baseClass = 'side-nav-section-button-view btn';
    if (this.model.get('buttonClass')) {
      return baseClass + ' ' + this.model.get('buttonClass');
    } else {
      return baseClass + ' btn-primary';
    }
  },

  events: {
    click: 'onClickButton'
  },

  attributes: function() {
    if (this.options.buttonAttrs) {
      return this.options.buttonAttrs;
    }
  },

  onClickButton: function(e) {
    e.preventDefault();
    var onClick = this.model.get('onClick');
    if (onClick && _.isFunction(onClick)) {
      onClick(e);
    }
  }
});
