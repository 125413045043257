/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var getFileRoot = require('./getFileRoot');

/*
 * Import all function using webpack contexts
 * https://webpack.js.org/guides/dependency-management/
 */
module.exports = function(r, reducePath, merge) {
  var result = {};
  _.each(r.keys(), function(key) {
    if (merge) {
      _.extend(result, r(key));
    } else {
      var keyName = reducePath ? getFileRoot(key) : key;
      result[keyName] = r(key);
    }
  });
  return result;
};
