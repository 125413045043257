/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var Marionette = require('backbone.marionette');
var Syphon = require('backbone.syphon');
var bodybuilder = require('bodybuilder');
var Ladda = require('ladda');

var Session = require('psa-core/Session');
var GridView = require('psa-marionette/views/GridView');
var PageableCollection = require('psa-backbone/models/PageableCollection');

var ElasticSearchSummaryView = require('common/views/ElasticSearchSummaryView');

var SelectOneCell = require('common/backgrid/SelectOneCell');
var ListCell = require('common/backgrid/ListCell');

var SearchNonDepEntities = require('../services/SearchNonDepEntities');

require('../styles/NonDepEntitySearchModalView.css');
var tmplNonDepEntitySearchModalView = require('../templates/NonDepEntitySearchModalView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplNonDepEntitySearchModalView,

  className: 'non-dep-entity-search-modal-view',

  ui: {
    searchForm: '.search-form',
    search: '.btn-search',
    searchHelpBlock: '.search-help-block',
    searchSummary: '.search-summary',
    searchResultsGrid: '.search-result-grid-container',
    formInputs: ':input',
    submit: '.btn-submit'
  },

  regions: {
    searchSummary: '@ui.searchSummary',
    searchResultsGrid: '@ui.searchResultsGrid'
  },

  events: {
    'click @ui.search': 'onClickSearchEntities',
    'click @ui.submit': 'onClickSubmit',
    'keydown @ui.formInputs': 'onKeydownOfInputs'
  },

  initialize: function() {
    this.searchLimit = Session.config.CM_ELASTICSEARCH_MAX_RESULTS || 1000;
  },

  templateHelpers: function() {
    return {
      searchLimit: this.searchLimit
    };
  },

  onBeforeShow: function() {
    this.searchSummaryView = new ElasticSearchSummaryView({
      model: new Backbone.Model({
        searchLimit: this.model.get('searchLimit')
      })
    });
    this.showChildView('searchSummary', this.searchSummaryView);

    this.entitySearchResults = new GridView({
      columns: [
        {
          name: 'selected',
          label: '',
          cell: SelectOneCell.SelectOneRowCell,
          headerCell: SelectOneCell.SelectOneHeaderCell,
          width: 5,
          editable: true,
          sortable: false
        },
        {
          name: 'entityName',
          label: 'Entity Name',
          cell: 'string',
          headerCell: 'custom',
          width: 30,
          editable: false
        },
        {
          name: 'dbas',
          label: 'D/B/A',
          cell: ListCell,
          headerCell: 'custom',
          width: 30,
          editable: false
        },
        {
          name: 'licensesList',
          label: 'Licenses',
          cell: ListCell,
          width: 35,
          editable: false,
          sortable: false
        }
      ],
      collection: new PageableCollection([], { pageSize: 3 }),
      emptyText: 'Search returned no entities.'
    });

    this.showChildView('searchResultsGrid', this.entitySearchResults);
  },

  onKeydownOfInputs: function(e) {
    // Searches on press of enter
    if (e.keyCode === 13) {
      this.onClickSearchEntities(e);
    }
  },

  onClickSearchEntities: function(e) {
    e.preventDefault();
    var self = this;
    this.ui.searchForm
      .parsley()
      .whenValidate()
      .done(function() {
        self.searchEntities();
      });
  },

  onClickSubmit: function(e) {
    e.preventDefault();
    var selectedModel = this.entitySearchResults.viewGrid.getSelectedModel();
    if (selectedModel) {
      this.triggerMethod('modal:close', selectedModel.toJSON());
    } else {
      this.triggerMethod('modal:close');
    }
  },

  searchEntities: function() {
    var formData = Syphon.serialize(this, {
      inputReaders: Syphon.SearchInputReaderSet
    });

    if (!formData.entityName && !formData.dba) {
      this.ui.searchHelpBlock.show();
      return;
    } else {
      this.ui.searchHelpBlock.hide();
    }

    var l = Ladda.create(this.ui.search[0]);
    l.start();
    var body = bodybuilder();

    if (formData.entityName) {
      body.query('bool', function(b) {
        b.orQuery('match', 'entityName', {
          query: formData.entityName,
          fuzziness: 'AUTO',
          analyzer: 'standard'
        });
        b.orQuery('wildcard', 'entityName', {
          boost: 10.0,
          value: formData.entityName.toLowerCase() + '*'
        });
        b.orQuery('match', 'entityName.phonetic', formData.entityName);
        return b;
      });
    }

    if (formData.dba) {
      body.query('bool', function(nb) {
        nb.orQuery('match', 'dbas', {
          query: formData.dba,
          fuzziness: 'AUTO',
          analyzer: 'standard'
        });
        nb.orQuery('wildcard', 'dbas', formData.dba.toLowerCase() + '*');
        nb.orQuery('match', 'dbas.phonetic', formData.dba);
        return nb;
      });
    }

    var activeLicenseStatusIds = [1, 2, 16, 20];

    if (formData.licenseTypeId || !formData.includeInactiveLicenses) {
      body.query('nested', { path: 'licenses', score_mode: 'none' }, function(nb) {
        if (formData.licenseTypeId) {
          nb.query('term', 'licenses.licenseTypeId', formData.licenseTypeId);
        }

        if (!formData.includeInactiveLicenses) {
          nb.query('bool', function(bb) {
            _.each(activeLicenseStatusIds, function(typeId) {
              bb.orQuery('term', 'licenses.licenseStatusId', typeId);
            });

            return bb;
          });
        }

        return nb;
      });

      body.queryMinimumShouldMatch(1);
    }

    body.size(this.searchLimit);
    var bodyObj = body.build();
    // Keep for testing
    // console.log(JSON.stringify(bodyObj, null, 2));
    SearchNonDepEntities(bodyObj)
      .done(
        function(data) {
          l.stop();
          _.each(data.hits, function(hit) {
            hit.licensesList = _.chain(hit.licenses)
              .filter(function(license) {
                var isLicenseType = !formData.licenseTypeId || license.licenseTypeId === +formData.licenseTypeId;
                var isActiveLicense =
                  formData.includeInactiveLicenses || _.contains(activeLicenseStatusIds, license.licenseStatusId);

                return isLicenseType && isActiveLicense;
              })
              .map(function(license) {
                return {
                  label: license.licenseType + ' #' + license.licenseNumber,
                  value: license.licenseStatus
                };
              })
              .value();
          });

          this.entitySearchResults.collection.fullCollection.reset(data.hits);
          this.searchSummaryView.display(data);
        }.bind(this)
      )
      .fail(function(err) {
        l.stop();
      });
  }
});
