/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Marionette = require('backbone.marionette');
var Ladda = require('ladda');
var Radio = require('backbone.radio');

var BreadcrumbView = require('common/views/BreadcrumbView');
var EntityHeaderBarView = require('../../common/views/EntityHeaderBarView');

var SubmitNewCorrespondence = require('../../common/services/SubmitNewCorrespondence');

var NonDepExtInitiateCorrespondenceFormView = require('../../correspondence/views/NonDepExtInitiateCorrespondenceFormView');

var tmplNonDepEntityExternalInitiateCorrespondenceView = require('../templates/NonDepEntityExternalInitiateCorrespondenceView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplNonDepEntityExternalInitiateCorrespondenceView,

  className: 'non-dep-entity-external-initiate-correspondence-view container-fluid',

  ui: {
    headerBar: '.header-bar',
    breadcrumb: '.breadcrumb-container',
    correspondenceFormContainer: '.correspondence-form-container',
    submit: '.submit'
  },

  regions: {
    headerBar: '@ui.headerBar',
    breadcrumb: '@ui.breadcrumb',
    correspondenceFormContainer: '@ui.correspondenceFormContainer'
  },

  events: {
    'click @ui.submit': 'onClickSubmit'
  },

  onBeforeShow: function() {
    var entityIdentifiers = this.model.get('entityIdentifiers') || {};

    this.showChildView(
      'headerBar',
      new EntityHeaderBarView({
        model: new Backbone.Model(this.model.get('entityIdentifiers'))
      })
    );

    this.showChildView(
      'breadcrumb',
      new BreadcrumbView({
        breadcrumbs: [
          {
            link: '#ext/nondep/dashboard',
            text: 'Home'
          },
          {
            text: entityIdentifiers.entityName,
            link: '#ext/nondep/entity/' + entityIdentifiers.id + '/dashboard'
          },
          {
            text: 'Initiate Correspondence'
          }
        ]
      })
    );

    this.correspondenceForm = new NonDepExtInitiateCorrespondenceFormView({
      model: this.model
    });

    this.showChildView('correspondenceFormContainer', this.correspondenceForm);
  },

  onClickSubmit: function(e) {
    e.preventDefault();
    var self = this;
    var entityId = this.model.get('entityId');
    self.correspondenceForm.getFormData();

    this.correspondenceForm.validate().done(function() {
      var l = Ladda.create(self.ui.submit[0]);
      l.start();
      var formData = self.correspondenceForm.getFormData();
      formData.isInternal = '0';
      formData.objectId = entityId;
      formData.objectTypeId = 'ENTITY';
      formData.entityId = entityId;

      SubmitNewCorrespondence(formData)
        .done(function() {
          Radio.channel('navigate').trigger('show:ext:nd:entity-dashboard', entityId);
        })
        .fail(function() {
          l.stop();
        });
    });
  }
});
