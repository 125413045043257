/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Marionette = require('backbone.marionette');

var modelGet = require('common/util/modelGet');
var toggleForm = require('common/util/toggleForm');

var tmplDepEnforcementDetailsSectionView = require('../templates/DepEnforcementDetailsSectionView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplDepEnforcementDetailsSectionView,

  className: 'dep-enforcement-details-section-view',

  ui: {
    infoFields: '.non-tsp-info',
    tspFields: '.tsp-info'
  },

  onBeforeShow: function() {
    var entityTypeId = modelGet(this.model, 'enforcement.entityTypeId');
    var isTSP = entityTypeId === '3' || entityTypeId === '12' || entityTypeId === '23';

    toggleForm(this.ui.infoFields, !isTSP);
    toggleForm(this.ui.tspFields, isTSP);
  }
});
