/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Backbone = require('backbone');
var GetAllBondCompanies = require('../services/GetAllBondCompanies');
var GetCollectorRepossessorRenewalApplication = require('../services/GetCollectorRepossessorRenewalApplication');
var CollectorRepossessorRenewalApplication = require('../forms/CollectorRepossessorRenewalApplication');

module.exports = {
  event: 'build:license-renew:collector-repossessor',

  channel: 'nd:application',

  builder: function(config) {
    var defer = $.Deferred();

    $.when(GetAllBondCompanies(), GetCollectorRepossessorRenewalApplication(config.applicationId))
      .done(function(bondCompanies, application) {
        defer.resolve(
          new CollectorRepossessorRenewalApplication({
            isReadOnly: config.isReadOnly,
            mode: config.mode || 'new', // new, resubmit, print or int-expand
            model: new Backbone.Model({
              bondCompanies: bondCompanies,
              renewalData: application.renewalData,
              employees: application.employees,
              canceledEmployees: application.canceledEmployees
            })
          })
        );
      })

      .fail(function(err) {
        defer.reject(err);
      });

    return defer.promise();
  }
};
