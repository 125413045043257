/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var Backbone = require('backbone');
var Marionette = require('backbone.marionette');
var Syphon = require('backbone.syphon');
var Radio = require('backbone.radio');
var Ladda = require('ladda');

var FileUploadCollection = require('psa-backbone/models/FileUploadCollection');

var momentUtil = require('common/util/momentUtil');
var modelGet = require('common/util/modelGet');

var HeaderBarView = require('common/views/HeaderBarView');
var BreadcrumbView = require('common/views/BreadcrumbView');
var FileUploadView = require('common/views/FileUploadView');

var SubmitFinancialStatementTask = require('../../services/SubmitFinancialStatementTask');

var tmplSecSubmitFinancialStatementTaskView = require('./SecSubmitFinancialStatementTaskView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplSecSubmitFinancialStatementTaskView,

  className: 'sec-submit-financial-statment-task-view container-fluid',

  ui: {
    headerBar: '.header-bar',
    breadcrumb: '.breadcrumb-container',
    financialStatementForm: '.financial-statement-form',
    extFileUploadContainer: '.ext-file-upload-container',
    intFileUploadContainer: '.int-file-upload-container',
    datePickerFields: '.date-picker',
    fiscalYearEndDate: '.fiscal-year-end-row',
    submit: '.submit'
  },

  regions: {
    headerBar: '@ui.headerBar',
    breadcrumb: '@ui.breadcrumb',
    intFileUploadContainer: '@ui.intFileUploadContainer',
    extFileUploadContainer: '@ui.extFileUploadContainer'
  },

  events: {
    'click @ui.submit': 'onClickSubmit'
  },

  templateHelpers: function() {
    var internalComments = modelGet(this.model, 'financialStatement.internalComments'),
      numInternalDocuments = modelGet(this.model, 'financialStatement.internalDocuments.length', 0);
    return {
      numInternalDocuments: numInternalDocuments,
      showInternalFeedback: !!(internalComments || numInternalDocuments)
    };
  },

  onBeforeShow: function() {
    var taskTypeId = modelGet(this.model, 'task.taskType.id', {});
    var submitAnnualFinancialStatementsTask = taskTypeId === '30006';
    var submitAccountingRegistrationTask = taskTypeId === '30022';

    this.ui.datePickerFields.datepicker();
    var headerBarColumns = [
      {
        name: 'applicantLegalName',
        label: 'Applicant Legal Name',
        minWidth: '160px',
        maxWidth: '300px'
      },
      {
        name: 'CRDNumber',
        label: 'CRD Number',
        minWidth: '160px',
        maxWidth: '300px'
      },
      {
        name: 'nonFINRANumber',
        label: 'Non FINRA Number',
        minWidth: '160px',
        maxWidth: '300px'
      }
    ];

    this.showChildView(
      'headerBar',
      new HeaderBarView({
        columns: new Backbone.Collection(headerBarColumns),
        model: new Backbone.Model(this.model.get('organization'))
      })
    );

    this.showChildView(
      'breadcrumb',
      new BreadcrumbView({
        breadcrumbs: [
          {
            link: '#ext/sec/dashboard',
            text: 'Home'
          },
          {
            text: submitAnnualFinancialStatementsTask ? 'Submit Annual Financial Statements' : submitAccountingRegistrationTask ? 'Submit Accounting Registration Financials' : 'Submit Financials'
          }
        ]
      })
    );

    Syphon.deserialize(this, this.model.get('financialStatement'));

    this.intFileUploadTableView = new FileUploadView({
      collection: new FileUploadCollection(
        _.pluck(modelGet(this.model, 'financialStatement.internalDocuments', []), 'file')
      ),
      allowMultipleFiles: true,
      isReadOnly: true
    });

    this.showChildView('intFileUploadContainer', this.intFileUploadTableView);

    this.extFileUploadTableView = new FileUploadView({
      collection: new FileUploadCollection(
        _.pluck(modelGet(this.model, 'financialStatement.externalDocuments', []), 'file')
      ),
      allowMultipleFiles: true,
      isRequired: true,
      metadata: {
        documentTypeId: 30012
      }
    });

    this.showChildView('extFileUploadContainer', this.extFileUploadTableView);
  },

  onClickSubmit: function(e) {
    e.preventDefault();
    var self = this;
    this.validate().done(function() {
      var l = Ladda.create(self.ui.submit[0]);
      l.start();
      SubmitFinancialStatementTask(self.getFormData())
        .done(function() {
          Radio.channel('navigate').trigger('show:ext:sec:dashboard');
        })
        .fail(function() {
          l.stop();
        });
    });
  },

  validate: function() {
    return $.when.apply($, [
      this.ui.financialStatementForm.parsley().whenValidate(),
      this.extFileUploadTableView.validate()
    ]);
  },

  getFormData: function() {
    var formData = Syphon.serialize(this);
    formData.fiscalYearEndDate = momentUtil.formatStr(formData.fiscalYearEndDate, momentUtil.defaultOutboundDateConfig);

    _.extend(formData, {
      taskId: this.model.get('taskId'),
      financialStatementId: this.model.get('financialStatementId')
    });
    formData.documents = this.extFileUploadTableView.collection.toJSON();
    return formData;
  }
});
