/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Backbone = require('backbone');
var Marionette = require('backbone.marionette');
var Ladda = require('ladda');
var Radio = require('backbone.radio');

var HeaderBarView = require('common/views/HeaderBarView');

var SubmitComplianceSanction = require('../services/SubmitComplianceSanction');
var SecComplianceBreadcrumbView = require('./SecComplianceBreadcrumbView');
var SecComplianceSanctionFormView = require('./SecComplianceSanctionFormView');

var tmplSecComplianceSanctionNewView = require('../templates/SecComplianceSanctionNewView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplSecComplianceSanctionNewView,

  className: 'sec-compliance-sanction-new-view container-fluid',

  ui: {
    headerBar: '.header-bar',
    breadcrumb: '.breadcrumb-container',
    complianceSanctionForm: '.compliance-sanction-form-container',
    submit: '.btn-submit'
  },

  regions: {
    headerBar: '@ui.headerBar',
    breadcrumb: '@ui.breadcrumb',
    complianceSanctionForm: '@ui.complianceSanctionForm'
  },

  events: {
    'click @ui.submit': 'onClickSubmit'
  },

  onBeforeShow: function() {
    var compliance = this.model.get('compliance') || {};
    var identifiers = compliance.identifiers || {};
    var complianceId = this.model.get('complianceId');
    var objectId = compliance.objectId;
    var actionType = compliance.actionType;

    var headerData = {
      title: 'Compliance',
      dbaName: identifiers.dbaName,
      crdNumber: identifiers.crdNumber,
      licenseType: identifiers.licenseType,
      examCaseNumber: identifiers.examCaseNumber,
      actionType: identifiers.actionType
    };

    this.showChildView(
      'headerBar',
      new HeaderBarView({
        columns: new Backbone.Collection([
          {
            name: 'crdNumber',
            label: 'CRD Number',
            minWidth: '160px'
          },
          {
            name: 'licenseType',
            label: 'License Type',
            minWidth: '160px'
          },
          {
            name: 'examCaseNumber',
            label: 'Exam/Case Number',
            minWidth: '160px'
          },
          {
            name: 'actionType',
            label: 'Action Type',
            minWidth: '160px'
          }
        ]),
        model: new Backbone.Model(headerData)
      })
    );

    this.showChildView(
      'breadcrumb',
      new SecComplianceBreadcrumbView({
        identifiers: identifiers,
        objectId: objectId,
        breadcrumbs: [
          {
            link: '#sec/compliance/' + complianceId + '/dashboard',
            text: actionType + ' Compliance Case'
          },
          {
            text: 'New Sanction'
          }
        ]
      })
    );

    this.complianceSanctionForm = new SecComplianceSanctionFormView({
      model: this.model
    });

    this.showChildView('complianceSanctionForm', this.complianceSanctionForm);
  },

  onClickSubmit: function(e) {
    e.preventDefault();
    var complianceId = this.model.get('complianceId');

    this.validate().done(
      function() {
        var laddaContext = Ladda.create(this.ui.submit.get(0));
        laddaContext.start();
        var formData = this.getFormData();
        SubmitComplianceSanction(formData)
          .done(function() {
            Radio.channel('navigate').trigger('show:int:sc:compliance', complianceId);
          })
          .fail(function() {
            laddaContext.stop();
          });
      }.bind(this)
    );
  },

  validate: function() {
    return this.ui.complianceSanctionForm.parsley().whenValidate();
  },

  getFormData: function() {
    var formData = this.complianceSanctionForm.getFormData();
    formData.complianceSanctionId = this.model.get('complianceSanctionId');
    formData.complianceId = this.model.get('complianceId');
    return formData;
  }
});
