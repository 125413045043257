/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var NProgress = require('nprogress');

var NavUtils = require('psa-core/util/nav');

var Scaffold = require('common/layout/Scaffold');
var GetEntityIdentifiers = require('../../common/services/GetEntityIdentifiers');

var GetAllEntityOfficerTypes = require('../../entity-form/services/GetAllEntityOfficerTypes');
var GetEntityOfficer = require('../../entity-dashboard/services/GetEntityOfficer');
var GetEntityDetails = require('../../entity-form/services/GetEntityDetails');

var EntityOfficerChangeView = require('../views/EntityOfficerChangeView');

module.exports = function(entityId, officerId) {
  NProgress.start();
  NProgress.set(0.6);
  $.when(
    GetEntityOfficer(officerId),
    GetEntityIdentifiers(entityId),
    GetAllEntityOfficerTypes(),
    GetEntityDetails(entityId)
  )
    .done(function(officer, entityIdentifiers, officerTypes, entityDetails) {
      Scaffold.content.show(
        new EntityOfficerChangeView({
          viewMode: 'ext',
          isAmendment: true,
          model: new Backbone.Model({
            entityId: entityId,
            isOfficerConvicted: entityDetails.isOfficerConvicted,
            isOfficerConvictedDocument: entityDetails.isOfficerConvictedDocument,
            officerId: officerId,
            officer: officer,
            entityIdentifiers: entityIdentifiers,
            officerTypes: officerTypes,
            allEntityOfficers: entityDetails.entityOfficers
          })
        })
      );
      NavUtils.navigate('ext/nondep/entity/' + entityId + '/officer-change/' + officerId);
      NProgress.done();
    })
    .fail(function(err) {
      NProgress.done();
    });
};
