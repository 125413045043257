/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2020] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var NProgress = require('nprogress');
var Backbone = require('backbone');

var NavUtils = require('psa-core/util/nav');

var Scaffold = require('common/layout/Scaffold');
var GetRicsCompliance = require('../services/GetRicsCompliance');
var GetEntityIdentifiers = require('../../common/services/GetEntityIdentifiers');
var GetAllRicsComplianceLookups = require('../services/GetAllRicsComplianceLookups');

var NonDepRicsComplianceFormView = require('../views/NonDepRicsComplianceFormView');

module.exports = function(entityId) {
  NProgress.start();
  NProgress.set(0.6);
  $.when(GetEntityIdentifiers(entityId), GetAllRicsComplianceLookups())
    .done(function(identifiers, lookups) {
      GetRicsCompliance(identifiers.tin)
        .done(function(ricsCompliance) {
          NavUtils.navigate('nondep/entity/' + entityId + '/edit-rics-compliance');

          Scaffold.content.show(
            new NonDepRicsComplianceFormView({
              model: new Backbone.Model({
                entityId: entityId,
                identifiers: identifiers,
                ricsCompliance: ricsCompliance,
                clearaceDecisionCodes: lookups.clearaceDecisionCodes,
                caseStatusCodes: lookups.caseStatusCodes,
                caseStatusReasonCodes: lookups.caseStatusReasonCodes
              })
            })
          );
          NProgress.done();
        })
        .fail(function() {
          NProgress.done();
        });
    })
    .fail(function() {
      NProgress.done();
    });
};
