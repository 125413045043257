var Handlebars = require("handlebars/dist/handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "  <div class=\"row\">\n    <div class=\"col-sm-12\">\n      <h3 class=\"m-t-md p-n col-sm-10 col-sm-offset-2\">FRB Approval</h3>\n      <div class=\"form-group col-sm-6 frb-approval\">\n        <label class=\"control-label col-sm-4\">FRB Approval</label>\n        <div class=\"form-control-static col-sm-8  \">"
    + alias3((helpers["default"] || (depth0 && depth0["default"]) || alias2).call(alias1,((stack1 = (depth0 != null ? depth0.filing : depth0)) != null ? stack1.frbApprovalOption : stack1),"--",{"name":"default","hash":{},"data":data}))
    + "\n        </div>\n      </div>\n      <div class=\"form-group col-sm-6 frb-approval-date\">\n        <label class=\"control-label col-sm-4\">FRB Approval Date</label>\n        <div class=\"form-control-static col-sm-8  \">"
    + alias3((helpers["default"] || (depth0 && depth0["default"]) || alias2).call(alias1,((stack1 = (depth0 != null ? depth0.filing : depth0)) != null ? stack1.frbApprovalDate : stack1),"--",{"name":"default","hash":{},"data":data}))
    + "</div>\n      </div>\n    </div>\n  </div>\n"
    + ((stack1 = helpers.unless.call(alias1,(depth0 != null ? depth0.isTrust : depth0),{"name":"unless","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"2":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "  <div class=\"row\">\n    <div class=\"col-sm-12\">\n      <h3 class=\"m-t-md p-n col-sm-10 col-sm-offset-2\">FDIC Approval</h3>\n      <div class=\"form-group col-sm-6 fdic-approval\">\n        <label class=\"control-label col-sm-4\">FDIC Approval </label>\n        <div class=\"form-control-static col-sm-8  \">"
    + alias3((helpers["default"] || (depth0 && depth0["default"]) || alias2).call(alias1,((stack1 = (depth0 != null ? depth0.filing : depth0)) != null ? stack1.fdicApprovalOption : stack1),"--",{"name":"default","hash":{},"data":data}))
    + "\n        </div>\n      </div>\n      <div class=\"form-group col-sm-6 fdic-approval-date\">\n        <label class=\"control-label col-sm-4\">FDIC Approval Date</label>\n        <div class=\"form-control-static col-sm-8  \">"
    + alias3((helpers["default"] || (depth0 && depth0["default"]) || alias2).call(alias1,((stack1 = (depth0 != null ? depth0.filing : depth0)) != null ? stack1.fdicApprovalDate : stack1),"--",{"name":"default","hash":{},"data":data}))
    + "\n        </div>\n      </div>\n    </div>\n  </div>\n";
},"4":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "  <div class=\"row\">\n    <div class=\"col-sm-12\">\n      <h3 class=\"m-t-md p-n col-sm-10 col-sm-offset-2\">NCUA Approval</h3>\n      <div class=\"form-group col-sm-6 fncua-approval\">\n        <label class=\"control-label col-sm-4\">NCUA Approval </label>\n        <div class=\"form-control-static col-sm-8  \">"
    + alias3((helpers["default"] || (depth0 && depth0["default"]) || alias2).call(alias1,((stack1 = (depth0 != null ? depth0.filing : depth0)) != null ? stack1.ncuaApprovalOption : stack1),"--",{"name":"default","hash":{},"data":data}))
    + "\n        </div>\n      </div>\n      <div class=\"form-group col-sm-6 ncua-approval-date\">\n        <label class=\"control-label col-sm-4\">NCUA Approval Date</label>\n        <div class=\"form-control-static col-sm-8  \">"
    + alias3((helpers["default"] || (depth0 && depth0["default"]) || alias2).call(alias1,((stack1 = (depth0 != null ? depth0.filing : depth0)) != null ? stack1.ncuaApprovalDate : stack1),"--",{"name":"default","hash":{},"data":data}))
    + "\n        </div>\n      </div>\n    </div>\n  </div>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "\n<div class=\"form-horizontal form-static\">\n  <div class=\"row\">\n    <div class=\"col-sm-12\">\n      <h3 class=\"m-t-md p-n col-sm-10 col-sm-offset-2\">Proofs of Publication</h3>\n      <div class=\"form-group col-sm-6 pub-proofs-general-option\">\n        <label class=\"control-label col-sm-4\">General</label>\n        <div class=\"form-control-static col-sm-8  \">\n          "
    + alias3((helpers["default"] || (depth0 && depth0["default"]) || alias2).call(alias1,((stack1 = (depth0 != null ? depth0.filing : depth0)) != null ? stack1.pubProofsGeneralOption : stack1),"--",{"name":"default","hash":{},"data":data}))
    + "</div>\n      </div>\n      <div class=\"form-group col-sm-6 pub-proofs-legal-option\">\n        <label class=\"control-label col-sm-4\">Legal</label>\n        <div class=\"form-control-static col-sm-8  \">\n          "
    + alias3((helpers["default"] || (depth0 && depth0["default"]) || alias2).call(alias1,((stack1 = (depth0 != null ? depth0.filing : depth0)) != null ? stack1.pubProofsLegalOption : stack1),"--",{"name":"default","hash":{},"data":data}))
    + "</div>\n      </div>\n    </div>\n    <div class=\"col-sm-12\">\n      <div class=\"form-group col-sm-6 pub-proofs-general-received-date\">\n        <label class=\"control-label col-sm-4\">General Received Date</label>\n        <div class=\"form-control-static col-sm-8  \">\n          "
    + alias3((helpers["default"] || (depth0 && depth0["default"]) || alias2).call(alias1,((stack1 = (depth0 != null ? depth0.filing : depth0)) != null ? stack1.pubProofsGeneralReceivedDate : stack1),"--",{"name":"default","hash":{},"data":data}))
    + "</div>\n      </div>\n      <div class=\"form-group col-sm-6 pub-proofs-legal-received-date\">\n        <label class=\"control-label col-sm-4\">Legal Received Date</label>\n        <div class=\"form-control-static col-sm-8  \">\n          "
    + alias3((helpers["default"] || (depth0 && depth0["default"]) || alias2).call(alias1,((stack1 = (depth0 != null ? depth0.filing : depth0)) != null ? stack1.pubProofsLegalReceivedDate : stack1),"--",{"name":"default","hash":{},"data":data}))
    + "</div>\n      </div>\n    </div>\n    <div class=\"col-sm-12\">\n      <div class=\"form-group col-sm-6 pub-proofs-general-expire-date\">\n        <label class=\"control-label col-sm-4\">General Comment Period Expires</label>\n        <div class=\"form-control-static col-sm-8  \">\n          "
    + alias3((helpers["default"] || (depth0 && depth0["default"]) || alias2).call(alias1,((stack1 = (depth0 != null ? depth0.filing : depth0)) != null ? stack1.pubProofsGeneralExpireDate : stack1),"--",{"name":"default","hash":{},"data":data}))
    + "</div>\n      </div>\n      <div class=\"form-group col-sm-6 pub-proofs-legal-expire-date\">\n        <label class=\"control-label col-sm-4\">Legal Comment Period Expires</label>\n        <div class=\"form-control-static col-sm-8  \">\n          "
    + alias3((helpers["default"] || (depth0 && depth0["default"]) || alias2).call(alias1,((stack1 = (depth0 != null ? depth0.filing : depth0)) != null ? stack1.pubProofsLegalExpireDate : stack1),"--",{"name":"default","hash":{},"data":data}))
    + "</div>\n      </div>\n    </div>\n    <div class=\"col-sm-12\">\n      <div class=\"form-group col-sm-12 bulletin-option\">\n        <label class=\"control-label col-sm-2\">PA Bulletin</label>\n        <div class=\"form-control-static col-sm-10  \">"
    + alias3((helpers["default"] || (depth0 && depth0["default"]) || alias2).call(alias1,((stack1 = (depth0 != null ? depth0.filing : depth0)) != null ? stack1.bulletinOption : stack1),"--",{"name":"default","hash":{},"data":data}))
    + "</div>\n      </div>\n      <div class=\"form-group col-sm-12 bulletin-published-date\">\n        <label class=\"control-label col-sm-2\">Bulletin Published</label>\n        <div class=\"form-control-static col-sm-10  \">\n          "
    + alias3((helpers["default"] || (depth0 && depth0["default"]) || alias2).call(alias1,((stack1 = (depth0 != null ? depth0.filing : depth0)) != null ? stack1.bulletinPublishedDate : stack1),"--",{"name":"default","hash":{},"data":data}))
    + "</div>\n      </div>\n      <div class=\"form-group col-sm-12 bulletin-expire-date\">\n        <label class=\"control-label col-sm-2\">Bulletin Comment Period Expires</label>\n        <div class=\"form-control-static col-sm-10  \">"
    + alias3((helpers["default"] || (depth0 && depth0["default"]) || alias2).call(alias1,((stack1 = (depth0 != null ? depth0.filing : depth0)) != null ? stack1.bulletinExpireDate : stack1),"--",{"name":"default","hash":{},"data":data}))
    + "\n        </div>\n      </div>\n    </div>\n  </div>\n  <div class=\"row\">\n    <div class=\"col-sm-12\">\n      <h3 class=\"m-t-md p-n col-sm-10 col-sm-offset-2\">Info Request</h3>\n      <div class=\"form-group col-sm-6 info-request-date-made\">\n        <label class=\"control-label col-sm-4\">Info Request Date Made</label>\n        <div class=\"form-control-static col-sm-8  \">\n          "
    + alias3((helpers["default"] || (depth0 && depth0["default"]) || alias2).call(alias1,((stack1 = (depth0 != null ? depth0.filing : depth0)) != null ? stack1.infoRequestMadeDate : stack1),"--",{"name":"default","hash":{},"data":data}))
    + "\n        </div>\n      </div>\n      <div class=\"form-group col-sm-6 info-request-date-received\">\n        <label class=\"control-label col-sm-4\">Info Request Date Received</label>\n        <div class=\"form-control-static col-sm-8  \">\n          "
    + alias3((helpers["default"] || (depth0 && depth0["default"]) || alias2).call(alias1,((stack1 = (depth0 != null ? depth0.filing : depth0)) != null ? stack1.infoRequestReceivedDate : stack1),"--",{"name":"default","hash":{},"data":data}))
    + "</div>\n      </div>\n    </div>\n  </div>\n  <div class=\"row\">\n    <div class=\"col-sm-12\">\n      <h3 class=\"m-t-md p-n col-sm-10 col-sm-offset-2\">Final Action</h3>\n      <div class=\"form-group col-sm-6 action\">\n        <label class=\"control-label col-sm-4\">Action</label>\n        <div class=\"form-control-static col-sm-8  \">"
    + alias3((helpers["default"] || (depth0 && depth0["default"]) || alias2).call(alias1,((stack1 = (depth0 != null ? depth0.filing : depth0)) != null ? stack1.action : stack1),"--",{"name":"default","hash":{},"data":data}))
    + "</div>\n      </div>\n      <div class=\"form-group col-sm-6 action-date\">\n        <label class=\"control-label col-sm-4\">Action Date</label>\n        <div class=\"form-control-static col-sm-8  \">"
    + alias3((helpers["default"] || (depth0 && depth0["default"]) || alias2).call(alias1,((stack1 = (depth0 != null ? depth0.filing : depth0)) != null ? stack1.actionDate : stack1),"--",{"name":"default","hash":{},"data":data}))
    + "</div>\n      </div>\n    </div>\n    <div class=\"col-sm-12\">\n      <div class=\"form-group col-sm-6 days-from-acceptance-to-completion\">\n        <label class=\"control-label col-sm-4\">Days from Acceptance to Completion</label>\n        <div class=\"form-control-static col-sm-8  \">\n          "
    + alias3((helpers["default"] || (depth0 && depth0["default"]) || alias2).call(alias1,((stack1 = (depth0 != null ? depth0.filing : depth0)) != null ? stack1.daysFromAcceptanceToCompletion : stack1),"--",{"name":"default","hash":{},"data":data}))
    + "</div>\n      </div>\n      <div class=\"form-group col-sm-6 is-follow-up-required\">\n        <label class=\"control-label col-sm-4\">Follow Up Required</label>\n        <div class=\"form-control-static col-sm-8  \">\n          "
    + alias3((helpers["default"] || (depth0 && depth0["default"]) || alias2).call(alias1,((stack1 = (depth0 != null ? depth0.filing : depth0)) != null ? stack1.isFollowUpRequiredDisplay : stack1),"--",{"name":"default","hash":{},"data":data}))
    + "\n        </div>\n      </div>\n    </div>\n  </div>\n  <div class=\"row\">\n    <div class=\"col-sm-12\">\n      <div class=\"form-group col-sm-6 acknowledgement-notification-date\">\n        <label class=\"control-label col-sm-4\">Date Acknowledged</label>\n        <div class=\"form-control-static col-sm-8\">\n            "
    + alias3((helpers["default"] || (depth0 && depth0["default"]) || alias2).call(alias1,((stack1 = (depth0 != null ? depth0.filing : depth0)) != null ? stack1.acknowledgementNotificationDate : stack1),"--",{"name":"default","hash":{},"data":data}))
    + "</div>\n      </div>\n    </div>\n  </div>\n"
    + ((stack1 = helpers.unless.call(alias1,(depth0 != null ? depth0.isCU : depth0),{"name":"unless","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.isCU : depth0),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "  <div class=\"row\">\n    <div class=\"col-sm-12\">\n      <h3 class=\"m-t-md p-n col-sm-10 col-sm-offset-2\">Comments</h3>\n      <div class=\"form-group\">\n        <label class=\"control-label col-sm-3 col-md-2\">Comments</label>\n        <p class=\"form-control-static col-sm-9 col-md-10\">"
    + alias3((helpers["default"] || (depth0 && depth0["default"]) || alias2).call(alias1,((stack1 = (depth0 != null ? depth0.filing : depth0)) != null ? stack1.comments : stack1),"--",{"name":"default","hash":{},"data":data}))
    + "</p>\n      </div>\n    </div>\n  </div>\n</div>";
},"useData":true});