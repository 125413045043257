/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Backbone = require('backbone');

var PlaceholderView = require('common/views/PlaceholderView');

var GetDirectorsByEntity = require('../../common/services/GetDirectorsByEntity');
var GetAnnualMeetingsByEntity = require('../services/GetAnnualMeetingsByEntity');
var GetBoardAnnualMeetingsByEntity = require('../services/GetBoardAnnualMeetingsByEntity');
var GetEntityIdentifiers = require('../../common/services/GetEntityIdentifiers');

var DepEntityDirectorsSectionView = require('../views/DepEntityDirectorsSectionView');

module.exports = {
  channel: 'dp',

  event: 'build:directors-section-view',

  builder: function(entityId) {
    return new PlaceholderView({
      viewPromise: buildDirectorsSectionView(entityId)
    });
  }
};

function buildDirectorsSectionView(entityId) {
  var deferred = $.Deferred();
  $.when(
    GetAnnualMeetingsByEntity(entityId),
    GetDirectorsByEntity(entityId),
    GetBoardAnnualMeetingsByEntity(entityId),
    GetEntityIdentifiers(entityId)
  )
    .done(function(annualMeetings, directors, boardAnnualMeetingRequire, entityIdentifiers) {
      deferred.resolve(
        new DepEntityDirectorsSectionView({
          model: new Backbone.Model({
            entityIdentifiers: entityIdentifiers,
            directors: directors,
            annualMeetings: annualMeetings,
            boardAnnualMeetingRequire: boardAnnualMeetingRequire
          })
        })
      );
    })
    .fail(function(err) {
      deferred.reject(err);
    });
  return deferred.promise();
}
