/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var Backbone = require('backbone');

var GetDocumentTypesByDepartment = require('common/services/GetDocumentTypesByDepartment');

var LicenseSurrenderApplication = require('../forms/LicenseSurrenderApplication');
var GetLicenseSurrenderApplication = require('../services/GetLicenseSurrenderApplication');
var GetActiveLicensesByParentLicense = require('../services/GetActiveLicensesByParentLicense');
var GetLicenseDashboard = require('../../license-dashboard/services/GetLicenseDashboard');

module.exports = {
  event: 'build:amendment:license-surrender',

  channel: 'nd:application',

  builder: function(config) {
    var defer = $.Deferred();

    GetLicenseSurrenderApplication(config.applicationId)
      .done(function(appData) {
        $.when(
          GetActiveLicensesByParentLicense(appData.licenseId),
          GetLicenseDashboard(appData.licenseId),
          GetDocumentTypesByDepartment('NON_DEPOSITORY')
        )
          .done(function(childLicenses, licenseDashboard, documentTypes) {
            defer.resolve(
              new LicenseSurrenderApplication({
                mode: config.mode,
                viewMode: config.viewMode,
                isReadOnly: config.isReadOnly,
                model: new Backbone.Model(
                  _.extend(appData, {
                    documentTypes: documentTypes,
                    childLicenses: childLicenses,
                    licenseDashboard: licenseDashboard,
                    applicationId: config.applicationId
                  })
                )
              })
            );
          })
          .fail(function(err) {
            defer.reject(err);
          });
      })
      .fail(function(err) {
        defer.reject(err);
      });

    return defer.promise();
  }
};
