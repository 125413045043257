var Handlebars = require("handlebars/dist/handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "            <option value=\""
    + alias4(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\">"
    + alias4(((helper = (helper = helpers.type || (depth0 != null ? depth0.type : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"type","hash":{},"data":data}) : helper)))
    + "</option>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "\n<div class=\"modal-body\">\n  <form class=\"license-address-form\">\n    <div class=\"row\">\n      <div class=\"col-sm-6\">\n        <div class=\"form-group required\">\n          <label class=\"control-label\">Address Type</label>\n          <select name=\"typeId\" class=\"form-control\" data-parsley-required=\"true\">\n            <option value=\"\">--</option>\n"
    + ((stack1 = helpers.each.call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.addressType : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "          </select>\n        </div>\n      </div>\n      <div class=\"col-sm-6\">\n        <div class=\"form-group required\">\n          <label class=\"control-label\">Principal Place of Business</label>\n          <div>\n            <label class=\"radio-inline form-label\">\n              <input type=\"radio\" name=\"isPrincipal\" value=\"1\" data-parsley-required=\"true\"\n                data-parsley-errors-container=\".notify-entity-error-container\" />\n              Yes\n            </label>\n            <label class=\"radio-inline form-label\">\n              <input type=\"radio\" name=\"isPrincipal\" value=\"0\" />\n              No\n            </label>\n          </div>\n          <div class=\"notify-entity-error-container\"></div>\n        </div>\n      </div>\n    </div>\n    <div class=\"branch-number-container\">\n      <div class=\"form-group\">\n        <label class=\"control-label\">CRD Branch Number</label>\n        <input type=\"text\" name=\"CRDBranchNumber\" class=\"form-control\" placeholder=\"CRD Branch Number\"\n          data-parsley-maxlength=\"20\" />\n      </div>\n    </div>\n    <div class=\"address\"></div>\n  </form>\n</div>\n<div class=\"modal-footer\">\n  <button class=\"btn btn-default\" data-dismiss=\"modal\">Cancel</button>\n  <button class=\"btn btn-primary submit ladda-button\" data-style=\"expand-right\" type=\"button\">\n    <span class=\"ladda-label\">Submit</span>\n  </button>\n</div>";
},"useData":true});