var Handlebars = require("handlebars/dist/handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "\n<div class=\"row\">\n  <div class=\"col-sm-3\">\n    <div class=\"form-group\">\n      <div class=\"col-sm-12\">\n        <div class=\"input-group\">\n          <input type=\"text\" name=\"openedDate\" class=\"form-control opened-date\" placeholder=\"Opened Date\"\n            data-parsley-required=\"true\" data-parsley-date=\"\" data-parsley-trigger-after-failure=\"changeDate\"\n            data-parsley-errors-container=\"#openedDateError"
    + alias4(((helper = (helper = helpers.childIndex || (depth0 != null ? depth0.childIndex : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"childIndex","hash":{},"data":data}) : helper)))
    + "\" />\n          <div class=\"input-group-addon\">\n            <i class=\"fa fa-calendar\"></i>\n          </div>\n        </div>\n        <div id=\"openedDateError"
    + alias4(((helper = (helper = helpers.childIndex || (depth0 != null ? depth0.childIndex : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"childIndex","hash":{},"data":data}) : helper)))
    + "\"></div>\n      </div>\n    </div>\n  </div>\n  <div class=\"col-sm-3\">\n    <div class=\"form-group\">\n      <div class=\"col-sm-12\">\n        <div class=\"input-group\">\n          <input type=\"text\" name=\"closedDate\" class=\"form-control date-picker closed-date\" placeholder=\"Closed Date\"\n            data-parsley-required=\"true\" data-parsley-date=\"\" data-parsley-trigger-after-failure=\"changeDate\"\n            data-parsley-errors-container=\"#closedDateError"
    + alias4(((helper = (helper = helpers.childIndex || (depth0 != null ? depth0.childIndex : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"childIndex","hash":{},"data":data}) : helper)))
    + "\" />\n          <div class=\"input-group-addon\">\n            <i class=\"fa fa-calendar\"></i>\n          </div>\n        </div>\n        <div id=\"closedDateError"
    + alias4(((helper = (helper = helpers.childIndex || (depth0 != null ? depth0.childIndex : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"childIndex","hash":{},"data":data}) : helper)))
    + "\"></div>\n      </div>\n    </div>\n  </div>\n  <div class=\"col-sm-6\">\n    <div class=\"form-group\">\n      <div class=\"col-sm-12\">\n        <input type=\"text\" name=\"comments\" class=\"form-control\" placeholder=\"Comments\" />\n      </div>\n    </div>\n  </div>\n</div>";
},"useData":true});