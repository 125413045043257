/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Marionette = require('backbone.marionette');
var Radio = require('backbone.radio');
var Syphon = require('backbone.syphon');
var Ladda = require('ladda');

var FileUploadView = require('common/views/FileUploadView');
var makeUploadCollection = require('common/util/makeUploadCollection');

var SubmitAdminPerformComplianceDeterminationTask = require('../../services/SubmitAdminPerformComplianceDeterminationTask');
var ComplianceJumpToDropdownView = require('../../views/ComplianceJumpToDropdownView');

require('./ComplianceAdminPerformDeterminationView.css');
var tmplComplianceAdminPerformDeterminationView = require('./ComplianceAdminPerformDeterminationView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplComplianceAdminPerformDeterminationView,

  className: 'non-dep-compliance-case-perform-determination-view container-fluid',

  ui: {
    headerBar: '.header-bar-container',
    jumpTo: '.jump-to-container',
    breadcrumb: '.breadcrumb-container',
    summary: '.summary-container',
    determinationForm: '.determination-form',
    warningLetterUpload: '.warning-letter-upload-container',
    submit: '.btn-submit'
  },

  regions: {
    headerBar: '@ui.headerBar',
    jumpTo: '@ui.jumpTo',
    breadcrumb: '@ui.breadcrumb',
    summary: '@ui.summary',
    warningLetterUpload: '@ui.warningLetterUpload'
  },

  events: {
    'click @ui.submit': 'onClickSubmit'
  },

  behaviors: {
    TaskBehavior: {},
    ToggleBehavoir: {
      toggles: [
        {
          target: '.warning-letter-upload-form',
          group: '.decision-select',
          valuesToShow: ['2103']
        }
      ]
    }
  },

  onBeforeShow: function() {
    var compliance = this.model.get('complianceDetails') || {};

    this.showChildView('headerBar', this.options.complianceHeaderBar);

    this.showChildView(
      'jumpTo',
      new ComplianceJumpToDropdownView({
        model: new Backbone.Model({
          compliance: compliance
        })
      })
    );

    this.showChildView('breadcrumb', this.options.complianceBreadcrumbs);

    this.showChildView('summary', this.options.complianceSummary);

    this.warningLetter = new FileUploadView({
      collection: makeUploadCollection([]),
      isRequired: true,
      isReadOnly: false,
      metadata: {
        documentTypeId: 10005
      }
    });

    this.showChildView('warningLetterUpload', this.warningLetter);
  },

  onClickSubmit: function(e) {
    e.preventDefault();

    this.validate().done(
      function() {
        var laddaContext = Ladda.create(this.ui.submit.get(0));
        laddaContext.start();
        SubmitAdminPerformComplianceDeterminationTask(this.getFormData())
          .done(function() {
            Radio.channel('navigate').trigger('show:int:nd:dashboard');
          })
          .fail(function() {
            laddaContext.stop();
          });
      }.bind(this)
    );
  },

  validate: function() {
    var validationPromises = [this.ui.determinationForm.parsley().whenValidate()];

    if (Syphon.serialize(this).decisionId === '2103') {
      validationPromises.push(this.warningLetter.validate());
    }
    return $.when.apply($, validationPromises);
  },

  getFormData: function() {
    var formData = Syphon.serialize(this);

    formData.complianceId = this.model.get('complianceDetails').complianceId;
    formData.taskId = this.model.get('task').taskId;

    formData.warningLetterDocumentId = this.warningLetter.getFirstFileId();

    return formData;
  }
});
