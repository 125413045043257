/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var moment = require('moment');
var formatAsDollars = require('common/util/formatAsDollars');

var cleanUpResponse = require('common/util/cleanUpResponse');

module.exports = function() {
  var deferred = $.Deferred();

  $.soap({
    body: {
      GetActiveCreditUnions: {
        $xmlns: 'http://dobs.pa.gov/dp/entity/bpm/1.0'
      }
    },
    blueprintArrays: ['GetActiveCreditUnionsResponse.Entities.DP_Entity'],
    blueprint: {
      entities: [
        '$..DP_Entity',
        {
          entityId: '$.EntityID',
          entityName: '$.EntityName',
          totalAssetsAmount: '$.TotalAssetsAmount',
          totalAssetsAsOfDate: '$.TotalAssetsAsOfDate'
        }
      ]
    }
  })
    .done(function(response) {
      cleanUpResponse(response);
      _.each(response.entities, function(entity) {
        if (entity.totalAssetsAsOfDate) {
          entity.totalAssetsAsOfDate = moment(entity.totalAssetsAsOfDate, 'YYYY-MM-DD');
        }
      });

      response.entities.totalsOfCurrentTotalAssests = 0;
      _.each(response.entities, function(entity) {
        if (!_.isNull(entity.totalAssetsAmount)) {
          response.entities.totalsOfCurrentTotalAssests += +entity.totalAssetsAmount;
        }
      });
      response.entities.totalsOfCurrentTotalAssests = formatAsDollars(response.entities.totalsOfCurrentTotalAssests);

      deferred.resolve(response.entities);
    })
    .fail(function(err) {
      deferred.reject();
    });

  return deferred.promise();
};
