/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');

var cleanUpResponse = require('common/util/cleanUpResponse');

module.exports = function(assignment) {
  var defer = $.Deferred();

  $.soap({
    body: {
      SubmitAssignExam: {
        $xmlns: 'http://dobs.pa.gov/nd/exam/bpm/1.0',
        Assignment: {
          Examiners: {
            PrimaryExaminer: assignment.examiners.primaryExaminer,
            SecondaryExaminers: (function() {
              if (!assignment.examiners.secondaryExaminers || !assignment.examiners.secondaryExaminers.length) {
                return {};
              }
              return {
                SecondaryExaminer: {
                  UserID: assignment.examiners.secondaryExaminers
                }
              };
            })()
          },
          Exams: (function() {
            if (!assignment.exams.length) {
              return {};
            }
            return {
              Exam: _.map(assignment.exams, function(exam) {
                return {
                  ExamID: exam.examId
                };
              })
            };
          })()
        }
      }
    }
  })
    .done(function(response) {
      cleanUpResponse(response);
      defer.resolve(response);
    })
    .fail(function(err) {
      defer.reject(err);
    });

  return defer.promise();
};
