/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtaineds
 * from Counterpoint Consulting Incorporated.
 */

var Backbone = require('backbone');
var Marionette = require('backbone.marionette');
var Syphon = require('backbone.syphon');
var bodybuilder = require('bodybuilder');
var Ladda = require('ladda');

var GridView = require('psa-marionette/views/GridView');
var ListLinkCell = require('common/backgrid/ListLinkCell');
var PageableCollection = require('psa-backbone/models/PageableCollection');

var AddressCell = require('common/backgrid/AddressCell');
var ButtonLinkCell = require('common/backgrid/ButtonLinkCell');
var ElasticSearchSummaryView = require('common/views/ElasticSearchSummaryView');
var userHasRole = require('common/util/userHasRole');
var NonDepInternalSidebarNavView = require('../../common/views/NonDepInternalSidebarNavView');

var SearchNonDepEntities = require('../../common/services/SearchNonDepEntities');

require('../styles/NonDepInternalEntitySearchView.css');
var tmplNonDepInternalEntitySearchView = require('../templates/NonDepInternalEntitySearchView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplNonDepInternalEntitySearchView,

  className: 'non-dep-internal-entity-search-view container-fluid',

  ui: {
    sideBar: '.side-bar-container',
    newEntity: '.btn-new-entity',
    searchResultsContainer: '.search-results-container',
    search: '.search',
    clearFilters: '.clear-filters',
    formInputs: ':input',
    checkboxes: 'input[type="checkbox"]',
    searchSummary: '.search-summary'
  },

  regions: {
    sideBar: '@ui.sideBar',
    searchResultsContainer: '@ui.searchResultsContainer',
    searchSummary: '@ui.searchSummary'
  },

  events: {
    'click @ui.search': 'onClickSearch',
    'click @ui.clearFilters': 'onClickClearFilters',
    'keydown @ui.formInputs': 'onKeydownOfInputs'
  },

  onBeforeShow: function() {
    this.showChildView('sideBar', new NonDepInternalSidebarNavView());

    if (userHasRole('ND - Manager')) {
      this.ui.newEntity.show();
    }

    this.searchSummaryView = new ElasticSearchSummaryView({
      model: new Backbone.Model({
        searchLimit: this.model.get('searchLimit')
      })
    });
    this.showChildView('searchSummary', this.searchSummaryView);

    this.searchResults = new GridView({
      columns: [
        {
          name: 'entityName',
          label: 'Entity Name',
          cell: 'string',
          headerCell: 'custom',
          width: 23,
          editable: false
        },
        {
          name: 'firstDBA',
          label: 'D/B/A',
          cell: 'string',
          headerCell: 'custom',
          width: 21,
          editable: false
        },
        {
          name: 'licensesList',
          label: 'Licenses',
          cell: ListLinkCell.extend({
            emptyText: '--',
            newTab: true
          }),
          headerCell: 'custom',
          width: 44,
          editable: false,
          sortable: false
        },
        {
          name: 'ppobAddress',
          label: 'Headquarters Address',
          cell: AddressCell,
          headerCell: 'custom',
          width: 15,
          editable: false,
          sortable: false
        },
        {
          name: '',
          label: '',
          cell: ButtonLinkCell.extend({
            name: 'Open',
            newTab: true,
            buttonClass: 'btn-primary btn-sm',
            href: function(model) {
              return '#nondep/entity/' + model.get('entityId') + '/dashboard';
            }
          }),
          headerCell: 'custom',
          sortable: false,
          editable: false,
          width: 6
        }
      ],
      collection: new PageableCollection([], { pageSize: 25 }),
      emptyText: 'No Entities Found'
    });

    this.showChildView('searchResultsContainer', this.searchResults);

    this.$('.backgrid.table').addClass('table-bordered');
  },

  onKeydownOfInputs: function(e) {
    // Searches on press of enter
    if (e.keyCode === 13) {
      this.onClickSearch(e);
    }
  },

  onClickSearch: function() {
    var self = this;
    var data = Syphon.serialize(this, {
      inputReaders: Syphon.SearchInputReaderSet
    });
    // If no inputs were given, do not perform a search.
    if (
      !data.tin &&
      !data.licenseNumber &&
      !data.nmlsEntityId &&
      !data.licenseTypeId &&
      !data.streetAddress &&
      !data.city &&
      !data.entityName &&
      !data.dba
    ) {
      return;
    }
    this.searchSummaryView.reset();

    var l = Ladda.create(this.ui.search[0]);
    l.start();
    var body = bodybuilder();

    if (data.tin) {
      body.query('bool', function(b) {
        b.orQuery('match', 'tin', {
          query: data.tin,
          fuzziness: 0
        });
        b.orQuery('wildcard', 'tin', data.tin + '*');
        return b;
      });
    }

    if (data.licenseNumber || data.licenseTypeId || data.nmlsEntityId) {
      body.query('nested', 'path', 'licenses', function(nb) {
        if (data.licenseNumber) {
          nb.andQuery('term', 'licenses.licenseNumber', data.licenseNumber);
        }

        if (data.licenseTypeId) {
          nb.andQuery('term', 'licenses.licenseTypeId', data.licenseTypeId);
        }

        if (data.nmlsEntityId) {
          nb.andQuery('term', 'licenses.nmlsEntityId', data.nmlsEntityId);
        }
        return nb;
      });
    }

    if (data.streetAddress) {
      body.query('nested', 'path', 'locations', function(nb) {
        nb.query('bool', 'minimum_should_match', 1, function(bb) {
          if (!data.includeFormerLocations) {
            bb.andQuery('term', 'locations.isDeleted', 0);
          }

          bb.orQuery('match', 'locations.addressLine1', {
            query: data.streetAddress,
            fuzziness: 'AUTO'
          });
          bb.orQuery('wildcard', 'locations.addressLine1', data.streetAddress.toLowerCase() + '*');
          return bb;
        });
        return nb;
      });
    }

    if (data.city) {
      body.query('nested', 'path', 'locations', function(nb) {
        nb.query('bool', 'minimum_should_match', 1, function(bb) {
          if (!data.includeFormerLocations) {
            bb.andQuery('term', 'locations.isDeleted', 0);
          }

          bb.orQuery('match', 'locations.city', {
            query: data.city,
            fuzziness: 'AUTO'
          });
          bb.orQuery('wildcard', 'locations.city', data.city.toLowerCase() + '*');
          return bb;
        });
        return nb;
      });
    }

    if (data.entityName) {
      body.query('bool', function(b) {
        b.orQuery('match', 'entityName', {
          query: data.entityName,
          fuzziness: 'AUTO',
          analyzer: 'standard'
        });
        b.orQuery('wildcard', 'entityName', data.entityName.toLowerCase() + '*');
        b.orQuery('match', 'entityName.phonetic', data.entityName);
        if (data.includeFormerNames) {
          b.orQuery('match', 'formerNames', {
            query: data.entityName,
            fuzziness: 'AUTO',
            analyzer: 'standard'
          });
          b.orQuery('wildcard', 'formerNames', data.entityName.toLowerCase() + '*');
          b.orQuery('match', 'formerNames.phonetic', data.entityName);
        }
        return b;
      });
    }

    if (data.dba) {
      body.query('bool', function(nb) {
        nb.orQuery('match', 'dbas', {
          query: data.dba,
          fuzziness: 'AUTO',
          analyzer: 'standard'
        });
        nb.orQuery('wildcard', 'dbas', data.dba.toLowerCase() + '*');
        nb.orQuery('match', 'dbas.phonetic', data.dba);
        if (data.includeFormerDBA) {
          nb.orQuery('match', 'formerDBAs', {
            query: data.dba,
            fuzziness: 'AUTO',
            analyzer: 'standard'
          });
          nb.orQuery('wildcard', 'formerDBAs', data.dba.toLowerCase() + '*');
          nb.orQuery('match', 'formerDBAs.phonetic', data.dba);
        }
        return nb;
      });
    }

    body.size(this.model.get('searchLimit'));
    var bodyObj = body.build();
    // Keep for testing
    // console.log(JSON.stringify(bodyObj, null, 2));
    SearchNonDepEntities(bodyObj)
      .done(function(data) {
        l.stop();
        self.searchResults.collection.fullCollection.reset(data.hits);
        self.searchSummaryView.display(data);
      })
      .fail(function(err) {
        l.stop();
      });
  },

  onClickClearFilters: function() {
    this.ui.formInputs.val('');
    this.ui.checkboxes.prop('checked', false);
  }
});
