/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _get = require('lodash.get');
var Backbone = require('backbone');
var NProgress = require('nprogress');

var GetExamDetailsForEntity = require('../../../common/services/GetExamDetailsForEntity');
var GetExamTimeTracking = require('../../services/GetExamTimeTracking');
var GetExamPayment = require('../../services/GetExamPayment');

var EntityRemitExamPaymentTaskView = require('./EntityRemitExamPaymentTaskView');

module.exports = {
  event: 'build:nd:entity-remit-payment-for-exam',

  channel: 'task',

  builder: function(task) {
    var defer = $.Deferred();

    var examId = _get(task, 'taskData.ND.examId');
    var examPaymentId = _get(task, 'taskData.ND.examPaymentId');
    var isAssigned = task.state === 'ASSIGNED';

    $.when(GetExamDetailsForEntity(examId), GetExamTimeTracking(examId), GetExamPayment(examPaymentId))
      .done(function(exam, examInvoiceItems, examPayment) {
        NProgress.set(0.85);

        defer.resolve(
          new EntityRemitExamPaymentTaskView({
            isReadOnly: !isAssigned,
            model: new Backbone.Model({
              task: task,
              taskId: task.taskId,
              exam: exam,
              examId: examId,
              examInvoiceItems: examInvoiceItems,
              examPayment: examPayment
            })
          })
        );
      })
      .fail(function(err) {
        defer.reject(err);
      });

    return defer.promise();
  }
};
