var Handlebars = require("handlebars/dist/handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper;

  return "        <a href=\"#nondep/dashboard/exams/"
    + container.escapeExpression(((helper = (helper = helpers.examYearId || (depth0 != null ? depth0.examYearId : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"examYearId","hash":{},"data":data}) : helper)))
    + "/issue\" role=\"button\" class=\"btn btn-primary\">\n          <span>Issue More Exams</span>\n        </a>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var helper;

  return "        <a href=\"#nondep/dashboard/exams/"
    + container.escapeExpression(((helper = (helper = helpers.examYearId || (depth0 != null ? depth0.examYearId : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"examYearId","hash":{},"data":data}) : helper)))
    + "/assign\" role=\"button\" class=\"btn btn-primary\">\n          <span>Assign Examiners to Exams</span>\n        </a>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "\n<div class=\"side-bar-container pull-left\"></div>\n<div class=\"dashboard-container pull-right\">\n  <div class=\"breadcrumb-container\"></div>\n  <div class=\"clearfix\">\n    <h2 class=\"pull-left\">"
    + container.escapeExpression(((helper = (helper = helpers.examYear || (depth0 != null ? depth0.examYear : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"examYear","hash":{},"data":data}) : helper)))
    + " Exam Year Statistics</h2>\n    <div class=\"btn-container pull-right\">\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.showIssueExamsBtn : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.showAssignExaminersBtn : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "    </div>\n  </div>\n  <div class=\"exams-container\"></div>\n</div>";
},"useData":true});