/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Backbone = require('backbone');
var Marionette = require('backbone.marionette');
var Radio = require('backbone.radio');
var Ladda = require('ladda');

var modelGet = require('common/util/modelGet');
var BreadcrumbView = require('common/views/BreadcrumbView');
var HeaderBarView = require('common/views/HeaderBarView');

var SubmitIndividualLicense = require('../services/SubmitIndividualLicense');
var SecIndividualLicenseFormView = require('./SecIndividualLicenseFormView');

var tmplSecIndividualLicenseNewView = require('../templates/SecIndividualLicenseNewView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplSecIndividualLicenseNewView,

  className: 'sec-individual-license-new-view container-fluid m-b-lg',

  ui: {
    licenseFormContainer: '.license-form-container',
    breadcrumb: '.breadcrumb-container',
    headerBar: '.header-bar-container',
    submit: '.btn-submit'
  },

  regions: {
    licenseFormContainer: '@ui.licenseFormContainer',
    breadcrumb: '@ui.breadcrumb',
    headerBar: '@ui.headerBar'
  },

  events: {
    'click @ui.submit': 'onClickSubmit'
  },

  onBeforeShow: function() {
    var identifiers = this.model.get('identifiers') || {};
    var individualId = modelGet(this.model, 'individualId');

    this.showChildView(
      'breadcrumb',
      new BreadcrumbView({
        breadcrumbs: [
          {
            link: '#sec/dashboard',
            text: 'Home'
          },
          {
            link: '#sec/individual/' + individualId + '/dashboard',
            text: identifiers.dbaName
          },
          {
            text: 'New License'
          }
        ]
      })
    );

    identifiers.title = 'Individual';
    var headerBarColumns = [
      {
        name: 'crdNumber',
        label: 'CRD Number',
        minWidth: '160px',
        maxWidth: '200px'
      },
      {
        name: 'firstName',
        label: 'First Name',
        minWidth: '160px',
        maxWidth: '200px'
      },
      {
        name: 'middleInitial',
        label: 'Middle Initial',
        minWidth: '160px',
        maxWidth: '200px'
      },
      {
        name: 'lastName',
        label: 'Last Name',
        minWidth: '160px',
        maxWidth: '200px'
      }
    ];

    this.showChildView(
      'headerBar',
      new HeaderBarView({
        columns: new Backbone.Collection(headerBarColumns),
        model: new Backbone.Model(identifiers)
      })
    );

    this.financialsForm = new SecIndividualLicenseFormView({
      model: this.model
    });

    this.showChildView('licenseFormContainer', this.financialsForm);
  },

  onClickSubmit: function(e) {
    e.preventDefault();
    this.validate().done(
      function() {
        var laddaContext = Ladda.create(this.ui.submit.get(0));
        laddaContext.start();
        var formData = this.getFormData();
        formData.isDeleted = 0;
        SubmitIndividualLicense(formData)
          .done(function(response) {
            Radio.channel('navigate').trigger('show:int:sc:individual:license', formData.individualId, response.id);
          })
          .fail(function() {
            laddaContext.stop();
          });
      }.bind(this)
    );
  },

  validate: function() {
    return this.ui.licenseFormContainer.parsley().whenValidate();
  },

  getFormData: function() {
    return this.financialsForm.getFormData();
  }
});
