/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var Marionette = require('backbone.marionette');
var Radio = require('backbone.radio');
var Ladda = require('ladda');
var Syphon = require('backbone.syphon');

var FileUploadCollection = require('psa-backbone/models/FileUploadCollection');
var FileUploadView = require('common/views/FileUploadView');

var momentUtil = require('common/util/momentUtil');

var NonDepIndividualView = require('../../../common/views/NonDepIndividualView');

var SubmitLicenseOfficeManagerChangeApplication = require('../../services/SubmitLicenseOfficeManagerChangeApplication');

var tmplLicenseOfficeManagerChangeApplicationView = require('./LicenseOfficeManagerChangeApplicationView.hbs');
module.exports = Marionette.LayoutView.extend({
  template: tmplLicenseOfficeManagerChangeApplicationView,

  className: 'license-office-manager-change-application-view row',

  ui: {
    officeManagerChangeForm: '.office-manager-change-form',
    effectiveChangeDate: '.effective-change-date',
    individualFormContainer: '.individual-form-container',
    applicationDocumentsFileContainer: '.application-documents-container',
    submitButton: '.submit-button',
    isCertified: 'input[name="isCertified"]',
    certificationName: 'input[name="certificationName"]'
  },

  regions: {
    individualFormContainer: '@ui.individualFormContainer',
    applicationDocumentsFileContainer: '@ui.applicationDocumentsFileContainer'
  },

  events: {
    'click @ui.submitButton': 'onClickSubmit'
  },

  behaviors: {
    ReadOnlyFormBehavior: {}
  },

  templateHelpers: function() {
    var showAffirmation =
      !this.options.isReadOnly ||
      (this.options.mode === 'print' || this.options.mode === 'int' || this.options.mode === 'int-expand');
    return {
      isReadOnly: this.options.isReadOnly,
      showAffirmation: showAffirmation
    };
  },

  onBeforeShow: function() {
    var officeManager = this.model.get('officeManager') || {};
    var oldIndividual = this.model.get('oldIndividual') || {};

    var emptyDefaultInboundDateConfig = {
      sourceFormat: 'YYYY-MM-DD',
      targetFormat: 'MM/DD/YYYY',
      default: ''
    };

    officeManager.dob = momentUtil.formatStr(officeManager.dob, emptyDefaultInboundDateConfig);

    var effectiveChangeDate = this.model.get('effectiveDate') || {};
    var effectiveChangeDateFormatted = momentUtil.formatStr(effectiveChangeDate, emptyDefaultInboundDateConfig);
    this.model.set('effectiveChangeDate', effectiveChangeDateFormatted);

    Syphon.deserialize(this, this.model.toJSON());

    if (this.options.mode === 'resubmit') {
      this.ui.isCertified.prop('checked', false);
      this.ui.certificationName.val(null);
    }
  
    this.individualForm = new NonDepIndividualView({
      fullProfileRequired: true,
      viewMode: this.options.viewMode,
      model: new Backbone.Model(_.extend(officeManager, { oldIndividual: oldIndividual })),
      isReadOnly: this.options.isReadOnly,
      isAmendment: true
    });
    this.showChildView('individualFormContainer', this.individualForm);

    this.applicationDocumentsFile = new FileUploadView({
      isRequired: true,
      allowMultipleFiles: true,
      collection: new FileUploadCollection(this.model.get('applicationDocuments')),
      isReadOnly: this.options.isReadOnly,
      metadata: {
        documentTypeId: 10023
      }
    });
    this.showChildView('applicationDocumentsFileContainer', this.applicationDocumentsFile);

    this.ui.effectiveChangeDate.datepicker({
      startDate: '0d'
    });
  },

  onClickSubmit: function(e) {
    e.preventDefault();
    var self = this;
    this.validate().done(function() {
      var laddaContext = Ladda.create(self.ui.submitButton.get(0));
      laddaContext.start();
      SubmitLicenseOfficeManagerChangeApplication(self.getFormData())
        .done(function(response) {
          Radio.channel('nd:submit-application').trigger('submit');
          Radio.channel('nd:submit-application').trigger('resubmit', laddaContext);
        })
        .fail(function(err) {
          laddaContext.stop();
        });
    });
  },

  validate: function() {
    return $.when.apply($, [
      this.ui.officeManagerChangeForm.parsley().whenValidate(),
      this.individualForm.validate({ type: 'submit' })
    ]);
  },

  getFormData: function() {
    var formData = Syphon.serialize(this);
    formData.licenseId = this.model.get('licenseId');
    formData.entityId = this.model.get('entityId');
    formData.dob = momentUtil.formatStr(formData.dob, momentUtil.defaultOutboundDateConfig);
    formData.effectiveDate = momentUtil.formatStr(formData.effectiveChangeDate, momentUtil.defaultOutboundDateConfig);
    formData.applicationDocuments = this.applicationDocumentsFile.collection.pluck('fileId');

    return formData;
  }
});
