/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2020] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var Marionette = require('backbone.marionette');
var Ladda = require('ladda');
var Radio = require('backbone.radio');

var modelGet = require('common/util/modelGet');
var momentUtil = require('common/util/momentUtil');
var BreadcrumbView = require('common/views/BreadcrumbView');

var SubmitEntityDirector = require('../services/SubmitEntityDirector');
var DepDirectorFormView = require('./DepDirectorFormView');

var tmplDepDirectorEditView = require('../templates/DepDirectorEditView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplDepDirectorEditView,

  className: 'dep-director-edit-view container-fluid',

  ui: {
    breadcrumb: '.breadcrumb-container',
    directorContainer: '.director-form-container',
    submit: '.submit'
  },

  regions: {
    breadcrumb: '@ui.breadcrumb',
    directorContainer: '@ui.directorContainer'
  },

  events: {
    'click @ui.submit': 'onClickSubmit'
  },

  templateHelpers: function() {
    var isCUTrustHC = _.contains(['11', '21', '22'], modelGet(this.model, 'entityIdentifiers.entityTypeId'));
    return {
      isCUTrustHC: isCUTrustHC
    };
  },

  onBeforeShow: function() {
    var director = this.model.get('director') || {};
    var directorId = director.directorId;
    var entityId = director.entityId;
    var entityName = modelGet(this.model, 'entityIdentifiers.entityName');
    var isCUTrustHC = _.contains(['11', '21', '22'], modelGet(this.model, 'entityIdentifiers.entityTypeId'));

    this.showChildView(
      'breadcrumb',
      new BreadcrumbView({
        breadcrumbs: [
          {
            link: '#dep/dashboard',
            text: 'Home'
          },
          {
            link: '#dep/entity/' + entityId + '/dashboard',
            text: entityName
          },
          {
            link: '#dep/entity/' + entityId + '/director/' + directorId + '/dashboard',
            text: director.positionHeld + ' - ' + director.firstName + ' ' + director.lastName
          },
          {
            text: isCUTrustHC ? 'Edit Director' : 'Edit Director-Trustee'
          }
        ]
      })
    );
    this.directorForm = new DepDirectorFormView({
      model: this.model
    });
    this.showChildView('directorContainer', this.directorForm);
  },

  onClickSubmit: function(e) {
    e.preventDefault();
    var self = this;
    var entityId = modelGet(this.model, 'director.entityId');

    this.validate().done(function() {
      var l = Ladda.create(self.ui.submit[0]);
      l.start();
      var formData = self.getFormData();
      SubmitEntityDirector(formData)
        .done(function(directorId) {
          Radio.channel('navigate').trigger('show:int:dp:director', entityId, directorId);
        })
        .fail(function() {
          l.stop();
        });
    });
  },

  getFormData: function() {
    var formData = this.directorForm.getFormData();

    formData.directorId = modelGet(this.model, 'director.directorId');
    formData.entityId = modelGet(this.model, 'director.entityId');

    formData.termStartDate = momentUtil.formatStr(formData.termStartDate, momentUtil.defaultOutboundDateConfig);
    formData.termEndDate = momentUtil.formatStr(formData.termEndDate, momentUtil.defaultOutboundDateConfig);
    formData.electionDate = momentUtil.formatStr(formData.electionDate, momentUtil.defaultOutboundDateConfig);

    return formData;
  },

  validate: function() {
    return this.ui.directorContainer.parsley().whenValidate();
  }
});
