var Handlebars = require("handlebars/dist/handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "            <option value=\""
    + alias4(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\">"
    + alias4(((helper = (helper = helpers.relatedCaseType || (depth0 != null ? depth0.relatedCaseType : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"relatedCaseType","hash":{},"data":data}) : helper)))
    + "</option>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "\n<div class=\"modal-body\">\n  <form class=\"search-form\">\n    <div class=\"row\">\n      <div class=\"col-sm-6\">\n        <div class=\"form-group required\">\n          <label class=\"control-label\">Category</label>\n          <select name=\"relatedCaseTypeId\" class=\"form-control relatedCaseType\" data-parsley-required=\"true\">\n            <option value=\"\" selected>--</option>\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.invInqRelatedCaseTypes : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "          </select>\n        </div>\n      </div>\n      <div class=\"col-sm-6\">\n        <div class=\"form-group search-block\">\n          <label class=\"control-label\">Case Number</label>\n          <input type=\"text\" class=\"form-control\" name=\"caseNumber\" placeholder=\"Case Number\">\n        </div>\n      </div>\n    </div>\n  </form>\n  <div class=\"search-block\">\n    <div class=\"row m-b-sm\">\n      <div class=\"col-sm-6\">\n        <button class=\"btn btn-primary btn-search\">\n          <span>Search</span>\n        </button>\n      </div>\n    </div>\n  </div>\n  <div class=\"search-block\">\n    <div class=\"search-result-summary collapse\">Found <span class=\"hit-count\"></span> results.</div>\n    <div class=\"search-result-grid-container m-t-md\"></div>\n    <div class=\"search-result-max alert alert-inline alert-warning collapse\">More results match the search criteria\n      than\n      shown, please refine your search criteria.\n      <span class=\"fa fa-question-circle fa-lg refine-seach-tip\" data-toggle=\"tooltip\" data-placement=\"bottom\"\n        title=\"Only the top "
    + container.escapeExpression(((helper = (helper = helpers.searchLimit || (depth0 != null ? depth0.searchLimit : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"searchLimit","hash":{},"data":data}) : helper)))
    + " results, ordered by the strength of the match, are returned by this search. Provide additional criteria to help refine the search.\"></span>\n    </div>\n  </div>\n  <div class=\"other-related-case-block collapse m-t-md\">\n    <form class=\"other-related-case-form\">\n      <div class=\"row\">\n        <div class=\"col-sm-12\">\n          <div class=\"form-group\">\n            <textarea name=\"otherCaseInfo\" class=\"form-control\" placeholder=\"Other\" rows=\"5\"\n              data-parsley-required=\"true\" data-parsley-maxlength=\"200\"></textarea>\n          </div>\n        </div>\n      </div>\n    </form>\n  </div>\n</div>\n<div class=\"modal-footer\">\n  <button class=\"btn btn-default\" data-dismiss=\"modal\">Cancel</button>\n  <button class=\"btn btn-primary btn-submit ladda-button\" data-style=\"expand-right\" type=\"button\">\n    <span class=\"ladda-label\">Select</span>\n  </button>\n</div>";
},"useData":true});