/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var moment = require('moment');

module.exports = function(data) {
  var deferred = $.Deferred();

  $.soap({
    body: {
      SubmitInvestmentHistory: {
        $xmlns: 'http://dobs.pa.gov/cf/investment/bpm/1.0',
        InvestmentHistoryID: data.investmentHistoryId,
        InvestmentID: data.investmentId,
        FiledDate: data.filedDate ? moment(data.filedDate, 'MM-DD-YYYY').format('YYYY-MM-DD') : null,
        DispositionDate: data.dispositionDate ? moment(data.dispositionDate, 'MM-DD-YYYY').format('YYYY-MM-DD') : null,
        EffectiveFromDate: data.effectiveFromDate
          ? moment(data.effectiveFromDate, 'MM-DD-YYYY').format('YYYY-MM-DD')
          : null,
        EffectiveToDate: data.effectiveToDate ? moment(data.effectiveToDate, 'MM-DD-YYYY').format('YYYY-MM-DD') : null,
        FilingNumber: data.filingNumber,
        TransactionDescID: data.transactionDescId,
        Number: data.checkNumber,
        PADollarAmount: data.PADollarAmount,
        FeePaidAmount: data.feePaidAmount,
        StatusID: data.statusId,
        IsDeleted: data.isDeleted
      }
    },
    blueprint: {
      investmentHistoryId: '$..NewInvestmentHistoryID'
    }
  })
    .done(function(response) {
      deferred.resolve(response);
    })
    .fail(function(err) {
      deferred.reject();
    });

  return deferred.promise();
};
