/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var Marionette = require('backbone.marionette');
var moment = require('moment');

var GridView = require('psa-marionette/views/GridView');
var UIUtil = require('psa-core/util/ui');

var DollarCell = require('common/backgrid/DollarCell');
var ConfirmationModalView = require('common/views/ConfirmationModalView');
var MomentCell = require('common/backgrid/MomentCell');

var PaymentScheduleMultiButtonCell = require('../backgrid/PaymentScheduleMultiButtonCell/PaymentScheduleMultiButtonCell');
var PaymentModalView = require('./PaymentModalView');

var tmplPaymentScheduleGridView = require('../templates/PaymentScheduleGridView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplPaymentScheduleGridView,

  className: 'payment-schedule-grid-view',

  ui: {
    paymentScheduleGrid: '.payment-schedule-grid',
    addPaymentButton: '.add-payment',
    addCompletedPaymentButton: '.add-completed-payment'
  },

  regions: {
    paymentGrid: '@ui.paymentScheduleGrid'
  },

  events: {
    'click @ui.addPaymentButton': 'onClickAddPayment',
    'click @ui.addCompletedPaymentButton': 'onClickAddCompletedPayment'
  },

  onBeforeShow: function() {
    //  Hide Add Payment Button if read only
    if (!this.options.isReadOnly) {
      this.ui.addPaymentButton.show();
    } else {
      this.ui.addPaymentButton.hide();
    }

    if (this.options.showCompletedButton && !this.options.isReadOnly) {
      this.ui.addCompletedPaymentButton.show();
    } else {
      this.ui.addCompletedPaymentButton.hide();
    }

    this.paymentScheduleGrid = new GridView({
      columns: [
        {
          name: 'description',
          label: 'Description',
          cell: 'string',
          headerCell: 'custom',
          width: 18,
          editable: false
        },
        {
          name: 'dueDate',
          label: 'Due Date',
          cell: MomentCell.MomentCell,
          formatter: MomentCell.MomentDateFormatter.extend({
            emptyValue: '--'
          }),
          sortValue: MomentCell.MomentSortValue,
          headerCell: 'custom',
          width: 18,
          editable: false
        },
        {
          name: 'amount',
          label: 'Payment Amount',
          cell: DollarCell,
          headerCell: 'custom',
          width: 18,
          editable: false
        },
        // Hide Payment Status and Date Paid if ad hoc mode
        {
          name: 'paymentStatus',
          label: 'Status',
          cell: 'string',
          headerCell: 'custom',
          width: 18,
          editable: false,
          renderable: this.options.mode === 'adhoc'
        },
        // Hide Payment Fees if no fees column is required
        {
          name: 'feeAmount',
          label: 'Fee Amount',
          cell: DollarCell,
          headerCell: 'custom',
          width: 16,
          editable: false,
          renderable: !!this.options.showFees
        },
        {
          name: 'paidOnDate',
          label: 'Date Paid',
          cell: MomentCell.MomentCell,
          formatter: MomentCell.MomentDateFormatter.extend({
            emptyValue: '--'
          }),
          sortValue: MomentCell.MomentSortValue,
          headerCell: 'custom',
          width: 18,
          editable: false,
          renderable: this.options.mode === 'adhoc'
        },
        // Hide Delete and Edit buttons if read only
        {
          name: 'paymentScheduleMultiButtonCell',
          label: '',
          cell: PaymentScheduleMultiButtonCell.extend({
            buttons: [
              {
                name: '',
                onClick: this.onClickEditPayment.bind(this),
                labelClass: 'fa fa-edit',
                buttonClass: 'btn-sm btn-primary edit-payment'
              },
              {
                name: '',
                labelClass: 'fa fa-trash',
                buttonClass: 'btn-danger btn-sm delete-payment',
                onClick: this.onClickDeletePayment.bind(this)
              }
            ]
          }),
          headerCell: 'custom',
          width: 10,
          editable: false,
          sortable: false,
          renderable: !this.options.isReadOnly
        }
      ],
      emptyText: this.options.emptyText,
      collection: this.collection
    });
    this.showChildView('paymentGrid', this.paymentScheduleGrid);
  },

  onClickAddPayment: function(e) {
    e.preventDefault();

    UIUtil.showModal({
      title: 'Add Payment',
      view: new PaymentModalView({
        model: new Backbone.Model({
          dueDate: this.options.defaultDueDate
        })
      })
    }).done(
      function(model) {
        // TODO fix this when new payments aren't automatically set to 'due'
        // Set new payments to display due and unpaid in grid
        model.paymentStatus = 'Due';
        model.statusId = '2';
        // Call if parent view has its own onAddPayment function
        if (this.options.onAddPayment && _.isFunction(this.options.onAddPayment)) {
          this.options.onAddPayment(model);
        } else {
          this.collection.add(model);
        }
      }.bind(this)
    );
  },

  onClickAddCompletedPayment: function(e) {
    e.preventDefault();

    UIUtil.showModal({
      title: 'Add Completed Payment',
      view: new PaymentModalView({
        model: new Backbone.Model({
          dueDate: this.options.defaultDueDate
        }),
        showFees: false
      })
    }).done(
      function(model) {
        // TODO fix this when new payments aren't automatically set to 'due'
        // Set new payments to display due and unpaid in grid
        model.paymentStatus = 'Paid - Manually Added';
        model.paidOnDate = moment();
        model.isCompleted = 1;
        // Call if parent view has its own onAddPayment function
        if (this.options.onAddCompletedPayment && _.isFunction(this.options.onAddCompletedPayment)) {
          this.options.onAddCompletedPayment(model);
        } else {
          this.collection.add(model);
        }
      }.bind(this)
    );
  },

  onClickEditPayment: function(e, model) {
    e.preventDefault();
    UIUtil.showModal({
      title: 'Edit Payment',
      view: new PaymentModalView({
        mode: 'edit',
        showFees: this.options.showFees,
        model: model
      })
    }).done(
      function(paymentItem) {
        paymentItem.paymentId = model.get('id');

        // Call if parent view has its own onEditPayment function
        if (this.options.onEditPayment && _.isFunction(this.options.onEditPayment)) {
          this.options.onEditPayment(paymentItem);
        } else {
          model.set(paymentItem);
        }
      }.bind(this)
    );
  },

  onClickDeletePayment: function(e, model) {
    e.preventDefault();
    var self = this;
    // Show confirmation modal for adhoc mode
    if (this.options.mode === 'adhoc') {
      UIUtil.showModal({
        title: 'Delete Payment Item',
        view: new ConfirmationModalView({
          model: new Backbone.Model({
            message:
              'Are you sure you would like to delete this payment item? If payment is currently due, the task for entity payment will be deleted.',
            onConfirm: function() {
              // Call if parent view has its own onDeletePayment function
              if (self.options.onDeletePayment && _.isFunction(self.options.onDeletePayment)) {
                return self.options.onDeletePayment(model);
              } else {
                var defer = $.Deferred();
                self.collection.remove(model);
                defer.resolve();
                return defer.promise();
              }
            }
          })
        })
      });
    } else {
      this.collection.remove(model);
    }
  }
});
