/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2020] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Marionette = require('backbone.marionette');
var Syphon = require('backbone.syphon');
var AddressView = require('common/views/AddressView');

require('./DepSection112ApplicantFormView.css');
var tmplDepSection112ApplicantFormView = require('./DepSection112ApplicantFormView.hbs');
var toggleForm = require('common/util/toggleForm');

module.exports = Marionette.LayoutView.extend({
  template: tmplDepSection112ApplicantFormView,

  className: 'dep-section112-applicant-form-view panel panel-info',

  ui: {
    address: '.address-container',
    removeApplicant: '.remove-applicant'
  },

  regions: {
    address: '@ui.address'
  },

  triggers: {
    'click @ui.removeApplicant': 'remove:applicant'
  },

  templateHelpers: function() {
    return {
      modelId: this.model.cid
    };
  },

  onBeforeShow: function() {
    var applicant = this.model.toJSON();
    this.addressForm = new AddressView({
      model: new Backbone.Model(applicant),
      horizontal: false,
      isRequired: true,
      startingCountry: applicant.country,
      startingPostalCode: applicant.postalCode,
      startingCounty: applicant.county
    });
    this.showChildView('address', this.addressForm);

    var isFirstApplicant = this.options.childIndex !== 0;
    toggleForm(this.ui.removeApplicant, isFirstApplicant);
    Syphon.deserialize(this, applicant);
  },

  getFormData: function() {
    return Syphon.serialize(this);
  }
});
