/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2020] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var Marionette = require('backbone.marionette');

var Session = require('psa-core/Session');
var UIUtil = require('psa-core/util/ui');
var GridView = require('psa-marionette/views/GridView');
var PageableCollection = require('psa-backbone/models/PageableCollection');

var toggleForm = require('common/util/toggleForm');
var ButtonLinkCell = require('common/backgrid/ButtonLinkCell');
var ButtonCell = require('common/backgrid/ButtonCell');
var ConfirmationModalView = require('common/views/ConfirmationModalView');

var SecEntityJoinCodeFormModalView = require('./SecEntityJoinCodeFormModalView');
var SubmitRevokeEntityJoinCode = require('./../services/SubmitRevokeEntityJoinCode');

var tmplSecUserManagementSectionView = require('../templates/SecUserManagementSectionView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplSecUserManagementSectionView,

  className: 'sec-user-management-section-view',

  ui: {
    authorizedUsersContainer: '.authorized-users-container',
    invitedUsersContainer: '.invited-users-container',
    inviteButton: '#inviteButton'
  },

  regions: {
    authorizedUsersContainer: '@ui.authorizedUsersContainer',
    invitedUsersContainer: '@ui.invitedUsersContainer'
  },

  events: {
    'click @ui.inviteButton': 'onClickInvite'
  },

  onBeforeShow: function() {
    var organizationId = this.model.get('organizationId');
    var authorizedUsers = this.model.get('authorizedUsers') || {};
    var currentUser = _.findWhere(authorizedUsers, { userId: Session.user.userId }) || {};
    var accessLevelId = currentUser.accessLevelId;
    var isAdmin = accessLevelId === '30';
    var manageUser = this.options.viewMode === 'ext' ? !isAdmin : false;

    if (this.options.viewMode === 'ext') {
      toggleForm(this.ui.inviteButton, isAdmin);
    }

    this.authorizedUsersGrid = new GridView({
      columns: [
        {
          name: 'firstName',
          label: 'First Name',
          cell: 'string',
          headerCell: 'custom',
          width: 20,
          editable: false
        },
        {
          name: 'lastName',
          label: 'Last Name',
          cell: 'string',
          headerCell: 'custom',
          width: 19,
          editable: false
        },
        {
          name: 'email',
          label: 'Email',
          cell: 'string',
          headerCell: 'custom',
          width: 25,
          editable: false
        },
        {
          name: 'userId',
          label: 'Keystone ID',
          cell: 'string',
          headerCell: 'custom',
          width: 25,
          editable: false
        },
        {
          name: 'accessLevel',
          label: 'Access Level',
          cell: 'string',
          headerCell: 'custom',
          width: 16,
          editable: false
        },
        {
          name: '',
          label: '',
          cell: ButtonLinkCell.extend({
            name: 'Manage User',
            buttonClass: function(model) {
              var baseClass = 'btn-primary m-t-xs';
              if (model.get('userId') === Session.user.userId || manageUser) {
                baseClass += ' collapse disabled';
              }
              return baseClass;
            },
            href: function(model) {
              return '#sec/organization/' + organizationId + '/user/' + model.get('id');
            }
          }),
          headerCell: 'custom',
          width: 20,
          editable: false,
          sortable: false
        }
      ],
      emptyText: 'No Authorized Users',
      collection: new PageableCollection(this.model.get('authorizedUsers'), { pageSize: 10 })
    });
    this.showChildView('authorizedUsersContainer', this.authorizedUsersGrid);

    this.invitedUsersGrid = new GridView({
      columns: [
        {
          name: 'firstName',
          label: 'First Name',
          cell: 'string',
          headerCell: 'custom',
          width: 20,
          editable: false
        },
        {
          name: 'lastName',
          label: 'Last Name',
          cell: 'string',
          headerCell: 'custom',
          width: 19,
          editable: false
        },
        {
          name: 'email',
          label: 'Email',
          cell: 'string',
          headerCell: 'custom',
          width: 25,
          editable: false
        },
        {
          name: 'accessLevel',
          label: 'Access Level',
          cell: 'string',
          headerCell: 'custom',
          width: 16,
          editable: false
        },
        {
          name: '',
          label: '',
          cell: ButtonCell.extend({
            name: 'Revoke Invitation',
            buttonClass: 'btn-primary m-t-xs',
            onClick: this.onClickRevokeInvitation.bind(this)
          }),
          headerCell: 'custom',
          width: 20,
          editable: false,
          sortable: false
        }
      ],
      emptyText: 'No Invited Users',
      collection: new PageableCollection(this.model.get('invitedUsers'), { pageSize: 10 })
    });
    this.showChildView('invitedUsersContainer', this.invitedUsersGrid);
  },

  onClickInvite: function(e) {
    e.preventDefault();
    var self = this;
    UIUtil.showModal({
      title: 'Invite a User to Join ' + this.model.get('organizationIdentifiers').DBAName,
      model: this.model,
      view: new SecEntityJoinCodeFormModalView()
    }).done(function(newJoinCode) {
      self.invitedUsersGrid.collection.add(newJoinCode);
    });
  },

  onClickRevokeInvitation: function(e, model) {
    e.preventDefault();
    var joinCodeId = model.get('id');
    var self = this;

    var message = 'Are you sure you want to revoke this invitation?';
    UIUtil.showModal({
      title: 'Confirm Revoking Invitation',
      view: new ConfirmationModalView({
        confirmText: 'Revoke Invitation',
        model: new Backbone.Model({
          message: message,
          onConfirm: function() {
            return SubmitRevokeEntityJoinCode({ joinCodeId: joinCodeId }).done(function(resultObj) {
              if (resultObj.result === '1') {
                self.invitedUsersGrid.collection.remove(model);
              }
            });
          },
          onConfirmDone: function() {}
        })
      })
    });
  }
});
