/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Backbone = require('backbone');

var PlaceholderView = require('common/views/PlaceholderView');

var TimeTrkInvalidTimesheetView = require('../../common/views/TimeTrkInvalidTimesheetView');
var GetTimesheet = require('../../common/services/GetTimesheet');
var GetAllCategories = require('../../common/services/GetAllCategories');
var TimeTrkSecExaminerEntryView = require('../views/TimeTrkSecExaminerEntryView');

module.exports = {
  channel: 'tt',

  event: 'build:time-trk-sec-timesheet-view',

  builder: function(periodId, departmentId) {
    return new PlaceholderView({
      viewPromise: buildTimeTrkSecTimesheetView(periodId, departmentId)
    });
  }
};

function buildTimeTrkSecTimesheetView(periodId, departmentId) {
  var deferred = $.Deferred();
  $.when(GetTimesheet({ periodId: periodId, departmentId: departmentId }), GetAllCategories(departmentId))
    .done(function(timesheet, categories) {
      if (timesheet.timesheetId) {
        deferred.resolve(
          new TimeTrkSecExaminerEntryView({
            model: new Backbone.Model({
              timesheetId: timesheet.timesheetId,
              payPeriod: timesheet.payPeriod,
              categories: categories,
              timesheet: timesheet
              // entityCategories: entityLookups.entityCategories,
              // entityTypes: entityLookups.entityTypes
            })
          })
        );
      } else {
        deferred.resolve(new TimeTrkInvalidTimesheetView());
      }
    })
    .fail(function(err) {
      deferred.reject(err);
    });
  return deferred.promise();
}
