/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Marionette = require('backbone.marionette');
var _ = require('underscore');

var PageableCollection = require('psa-backbone/models/PageableCollection');
var GridView = require('psa-marionette/views/GridView');

var formatAsDollars = require('common/util/formatAsDollars');

var PaymentScheduleGridView = require('../../common/views/PaymentScheduleGridView');

var tmplNonDepExamPaymentScheduleSectionView = require('../templates/NonDepExamPaymentScheduleSectionView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplNonDepExamPaymentScheduleSectionView,

  className: 'non-dep-exam-payment-schedule-section-view',

  ui: {
    paymentGrid: '.payment-grid',
    userHourGrid: '.user-hour-grid',
    managePaymentButton: '.update-payment-schedule'
  },

  regions: {
    paymentGrid: '@ui.paymentGrid',
    userHourGrid: '@ui.userHourGrid'
  },

  onBeforeRender: function() {
    // Calc and set amount paid
    var arrPaidOnTime = _.where(this.model.get('examPayments'), { statusId: '3' });
    var arrMaunallyPaid = _.where(this.model.get('examPayments'), { statusId: '5' });
    var arrPaidLate = _.where(this.model.get('examPayments'), { statusId: '4' });
    var arrAmountPaid = arrPaidOnTime.concat(arrPaidLate, arrMaunallyPaid);
    var totalAmountPaid =
      _.reduce(
        _.pluck(arrAmountPaid, 'amount'),
        function(memo, num) {
          return memo + parseFloat(num);
        },
        0
      ) +
      _.reduce(
        _.pluck(arrAmountPaid, 'feeAmount'),
        function(memo, num) {
          return memo + (num ? parseFloat(num) : 0);
        },
        0
      );

    // Calculate and Set Total Fees Due
    var totalFeesDue = _.reduce(
      this.model.get('examPayments'),
      function(memo, payment) {
        return memo + (payment.feeAmount ? parseFloat(payment.feeAmount) : 0);
      },
      0
    );
    this.model.get('exam').totalFeesDue = formatAsDollars(totalFeesDue);

    // Calculate and Set Total Amount Due
    var totalAmountDue =
      _.reduce(
        this.model.get('examPayments'),
        function(memo, payment) {
          return memo + (payment.amount ? parseFloat(payment.amount) : 0);
        },
        0
      ) + totalFeesDue;
    this.model.get('exam').totalAmountDue = formatAsDollars(totalAmountDue);

    this.model.get('exam').totalAmountPaid = formatAsDollars(totalAmountPaid);
    var refundAmountOverrideStr = this.model.get('exam').refundAmountOverride;
    this.model.get('exam').refundAmountOverride = formatAsDollars(parseFloat(refundAmountOverrideStr));

    // Commenting out for now (5/17/24). ND asked to remove the field as it's not being used
    // Calc and set amount scheduled (sum of all payment items in grid)
    // var totalPaymentScheduled = _.reduce(
    //   _.pluck(arrPaymentsScheduled, 'amount'),
    //   function(memo, num) {
    //     return memo + parseFloat(num);
    //   },
    //   0
    // );
    // this.model.get('exam').totalPaymentScheduled = formatAsDollars(totalPaymentScheduled);
  },

  onBeforeShow: function() {
    var userHours = this.model.get('userHours');

    this.paymentGrid = new PaymentScheduleGridView({
      emptyText: 'No Payment Scheduled',
      collection: new PageableCollection(this.model.get('examPayments'), { pageSize: 10 }),
      isReadOnly: true,
      showFees: true,
      mode: 'adhoc'
    });
    this.showChildView('paymentGrid', this.paymentGrid);

    _.each(userHours, function(entry) {
      entry.description = entry.firstName + ' ' + entry.lastName + ' Billable Hours';
    });

    this.userHourGrid = new GridView({
      columns: [
        {
          name: 'description',
          label: 'Description',
          cell: 'string',
          headerCell: 'custom',
          width: 40,
          editable: false
        },
        {
          name: 'totalHours',
          label: 'Total Hours',
          cell: 'string',
          headerCell: 'custom',
          width: 60,
          editable: false
        }
      ],
      emptyText: 'No Billable User Hours',
      collection: new Backbone.Collection(userHours)
    });
    this.showChildView('userHourGrid', this.userHourGrid);
  }
});
