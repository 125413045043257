/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Marionette = require('backbone.marionette');
var Radio = require('backbone.radio');

var GetFilingDetails = require('../../common/services/GetFilingDetails');

var SideNavSectionView = require('common/views/SideNavSectionView');
var BreadcrumbView = require('common/views/BreadcrumbView');
var CorpFinGenericSubmissionFormView = require('../../common/views/CorpFinGenericSubmissionFormView');
var CorpFinExtCreateFilingCertificationSectionView = require('./CorpFinExtCreateFilingCertificationSectionView');
var CorpFinFilingPaymentView = require('../../common/views/CorpFinFilingPaymentView');

require('../styles/CorpFinCreateFilingView.css');
var tmplCorpFinExtCreateFilingView = require('../templates/CorpFinExtCreateFilingView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplCorpFinExtCreateFilingView,

  className: 'corp-fin-ext-generic-submission-view container-fluid',

  ui: {
    breadcrumb: '.breadcrumb-container',
    filingTab: '.filing-tab',
    filingTabPanel: '.filing-tab-panel',
    filingContainer: '.filing-container',
    paymentTab: '.payment-tab',
    paymentContainer: '.payment-container'
  },

  regions: {
    breadcrumb: '@ui.breadcrumb',
    filingContainer: '@ui.filingContainer',
    paymentContainer: '@ui.paymentContainer'
  },

  onBeforeShow: function() {
    var breadcrumbs = [
      {
        link: '#ext/corpfin/dashboard',
        text: 'Home'
      },
      {
        text: 'New Generic Submission'
      }
    ];

    this.showChildView(
      'breadcrumb',
      new BreadcrumbView({
        breadcrumbs: breadcrumbs
      })
    );

    this.form = new SideNavSectionView({
      collection: new Backbone.Collection([
        {
          id: 'genericSubmissionForm',
          title: 'Submission',
          view: new CorpFinGenericSubmissionFormView({
            isReadOnly: false,
            model: this.model,
            viewMode: 'ext'
          })
        },
        {
          id: 'genericSubmissionCertification',
          title: 'Certification',
          view: new CorpFinExtCreateFilingCertificationSectionView({})
        }
      ]),
      useNavigationButtons: !this.options.isReadOnly
    });

    this.showChildView('filingContainer', this.form);

    Radio.channel('cf:filing').on(
      'proceed-to-payment',
      function(filingId, laddaContext) {
        GetFilingDetails(filingId)
          .done(
            function(filingDetails) {
              this.showChildView(
                'paymentContainer',
                new CorpFinFilingPaymentView({
                  viewMode: 'new',
                  model: new Backbone.Model({
                    filingId: filingId,
                    invoiceItems: [
                      {
                        description: filingDetails.filingType + ' Filing Fee',
                        quantity: 1,
                        unitPrice: filingDetails.feeAmount
                      }
                    ]
                  })
                })
              );
              this.ui.paymentTab.tab('show');
              this.ui.filingTabPanel.removeClass('active');
              this.ui.paymentContainer.addClass('active');
              laddaContext.stop();
            }.bind(this)
          )
          .fail(function() {
            laddaContext.stop();
          });
      }.bind(this)
    );
  },

  onDestroy: function() {
    Radio.channel('cf:');
  }
});
