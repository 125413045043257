/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var cleanUpResponse = require('common/util/cleanUpResponse');

module.exports = function(entityId) {
  var defer = $.Deferred();

  $.soap({
    body: {
      GetEntityDocMgmtDetails: {
        $xmlns: 'http://dobs.pa.gov/dp/entity/bpm/1.0',
        EntityID: entityId
      }
    },
    blueprint: {
      id: '$..ID',
      entityName: '$..EntityName',
      csFolderId: '$..CSFolderID',
      entityTypeId: '$..EntityTypeID',
      entityType: '$..EntityType',
      entityCategoryId: '$..EntityCategoryID',
      entityCategory: '$..EntityCategory',
      examsCSFolderId: '$..ExamsCSFolderID',
      filingsCSFolderId: '$..FilingsCSFolderID',
      articlesByLawsCSFolderId: '$..ArticlesByLawsCSFolderID',
      mrbaCSFolderId: '$..MRBACSFolderID',
      enforcementCSFolderId: '$..EnforcementCSFolderID',
      irregularitiesCSFolderId: '$..IrregularitiesCSFolderID',
      auditsCSFolderId: '$..AuditsCSFolderID',
      subsidiariesCSFolderId: '$..SubsidiariesCSFolderID',
      segCSFolderId: '$..SEGCSFolderID',
      cifCSFolderId: '$..CIFCSFolderID',
      callReportsCSFolderId: '$..CallReportsCSFolderID',
      correspondencesFolderId: '$..CorrespondencesFolderID'
    }
  })
    .done(function(response) {
      cleanUpResponse(response);
      defer.resolve(response);
    })
    .fail(function(err) {
      defer.reject(err);
    });

  return defer.promise();
};
