var Handlebars = require("handlebars/dist/handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "  <p class=\"title\">\n    <i class=\"fa fa-list-alt\"></i>\n    <span>This task is currently unclaimed.</span>\n  </p>\n  <p>This task is currently unclaimed, and must first be claimed before the task can be completed.</p>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.isAssignedToAnotherUser : depth0),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.program(6, data, 0),"data":data})) != null ? stack1 : "");
},"4":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "  <p class=\"title\">\n    <i class=\"fa fa-lock\"></i>\n    <span>This task is currently assigned to "
    + alias4(((helper = (helper = helpers.assigneeName || (depth0 != null ? depth0.assigneeName : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"assigneeName","hash":{},"data":data}) : helper)))
    + " ("
    + alias4(((helper = (helper = helpers.assignee || (depth0 != null ? depth0.assignee : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"assignee","hash":{},"data":data}) : helper)))
    + ").</span>\n  </p>\n  <p>This task is currently assigned to another user, and only that user can complete this task. In order to become the assignee of this task, you must belong to the <b>"
    + alias4(((helper = (helper = helpers.targetRoleName || (depth0 != null ? depth0.targetRoleName : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"targetRoleName","hash":{},"data":data}) : helper)))
    + "</b> role, and do one of the following:</p>\n  <ul>\n    <li>Ask <b>"
    + alias4(((helper = (helper = helpers.assigneeName || (depth0 != null ? depth0.assigneeName : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"assigneeName","hash":{},"data":data}) : helper)))
    + "</b> to reassign the task to you.</li>\n    <li>Ask <b>"
    + alias4(((helper = (helper = helpers.assigneeName || (depth0 != null ? depth0.assigneeName : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"assigneeName","hash":{},"data":data}) : helper)))
    + "</b> to release the task, then the task will be available for other members of the <b>"
    + alias4(((helper = (helper = helpers.targetRoleName || (depth0 != null ? depth0.targetRoleName : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"targetRoleName","hash":{},"data":data}) : helper)))
    + "</b> role to claim.</li>\n    <li>Have a user who belongs to the <b>DoBS Department Admin</b> role to reassign the task to you.</li>\n  </ul>\n";
},"6":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.isCompleted : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.program(9, data, 0),"data":data})) != null ? stack1 : "");
},"7":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "  <p class=\"title\">\n    <i class=\"fa fa-check\"></i>\n    <span>This task was completed by "
    + alias4(((helper = (helper = helpers.completedByName || (depth0 != null ? depth0.completedByName : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"completedByName","hash":{},"data":data}) : helper)))
    + " ("
    + alias4(((helper = (helper = helpers.completedBy || (depth0 != null ? depth0.completedBy : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"completedBy","hash":{},"data":data}) : helper)))
    + ") on "
    + alias4(((helper = (helper = helpers.completionDate || (depth0 != null ? depth0.completionDate : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"completionDate","hash":{},"data":data}) : helper)))
    + ".</span>\n  </p>\n  <p>This task has been completed by another user, no further action is possible for this task.</p>\n";
},"9":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.isObsolete : depth0),{"name":"if","hash":{},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"10":function(container,depth0,helpers,partials,data) {
    return "  <p class=\"title\">\n    <i class=\"fa fa-chain-broken\"></i>\n    <span>This task is now obsolete.</span>\n  </p>\n  <p>This task is obsolete, which means the task no longer needs to be completed. No further action is possible for this task.</p>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "\n"
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.isUnclaimed : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data})) != null ? stack1 : "");
},"useData":true});