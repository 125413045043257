/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2020] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Marionette = require('backbone.marionette');
var Syphon = require('backbone.syphon');
var _ = require('underscore');

var ConSerContactInformationView = require('./ConSerContactInformationView');

var tmplConSerComplaintContactInformationDisplayView = require('../templates/ConSerComplaintContactInformationDisplayView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplConSerComplaintContactInformationDisplayView,

  className: 'con-ser-contact-display-view',

  ui: {
    addressContainer: '.address-container',
    contactSelectField: '[name="contactId"]',
    contactInformationContainer: '.contact-information-container',
    contactOldInformationContainer: '.contact-old-information-container'
  },

  regions: {
    addressContainer: '@ui.addressContainer',
    contactInformationContainer: '@ui.contactInformationContainer',
    contactOldInformationContainer: '@ui.contactOldInformationContainer'
  },

  events: {
    'change @ui.contactSelectField': 'onChangeContactSelectField'
  },

  onBeforeShow: function() {
    var contact = this.model.get('contact') || {};
    this.contact = contact;
    Syphon.deserialize(this, contact);

    var selectedContact = _.findWhere(this.model.get('contacts'), { contactId: contact.contactId });

    // renders contact information view
    this.renderContactInformationView(selectedContact);

    // renders old contact information
    this.showChildView(
      'contactOldInformationContainer',
      new ConSerContactInformationView({
        model: new Backbone.Model(contact || {})
      })
    );
  },

  onChangeContactSelectField: function(e) {
    var contact = this.getSelectedContact() || {};
    this.contact = contact;

    this.renderContactInformationView(contact);
  },

  renderContactInformationView: function(contact) {
    // changes contact display information
    this.showChildView(
      'contactInformationContainer',
      new ConSerContactInformationView({
        model: new Backbone.Model(contact || {})
      })
    );
  },

  getSelectedContact: function() {
    var contactId = this.ui.contactSelectField.val();
    var contact = _.findWhere(this.model.get('contacts'), { contactId: contactId });

    return contact;
  }
});
