/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Marionette = require('backbone.marionette');

// var Session = require('psa-core/Session');

var BreadcrumbView = require('common/views/BreadcrumbView');
// var downloadDocument = require('common/util/downloadDocument');

var ConSerComplaintFormView = require('./ConSerComplaintFormView');

var tmplConSerComplaintFormNewPageView = require('../templates/ConSerComplaintFormNewPageView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplConSerComplaintFormNewPageView,

  className: 'conser-complaint-form-new-page-view container-fluid',

  ui: {
    newComplaintForm: '.new-complaint-form',
    breadcrumb: '.breadcrumb-container',
    instructionsButton: '.instructions-link'
  },

  regions: {
    newComplaintForm: '@ui.newComplaintForm',
    breadcrumb: '@ui.breadcrumb'
  },

  events: {
    'click @ui.instructionsButton': 'onClickInstructions'
  },

  onBeforeShow: function() {

    this.showChildView(
      'breadcrumb',
      new BreadcrumbView({
        breadcrumbs: [
          {
            link: '#ext/conser/dashboard',
            text: 'Home'
          },
          {
            text: 'Create A New Complaint'
          }
        ]
      })
    );

    this.form = new ConSerComplaintFormView({
      useNavigationButtons: true,
      model: this.model
    });
    this.showChildView('newComplaintForm', this.form);
  }
});
