/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2020] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Backbone = require('backbone');
var Marionette = require('backbone.marionette');
var Syphon = require('backbone.syphon');

var AddressView = require('common/views/AddressView');

var tmplConSerContactFormView = require('../templates/ConSerContactFormView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplConSerContactFormView,

  className: 'con-ser-contact-form-view',

  ui: {
    addressFormContainer: '.address-form-container',
    contactType: '.contacttypeform-group'
  },

  regions: {
    addressFormContainer: '@ui.addressFormContainer'
  },

  onBeforeShow: function() {
    var contact = this.model.toJSON().contact || {};

    this.addressForm = new AddressView({
      model: new Backbone.Model(contact),
      horizontal: true,
      isRequired: false,
      startingCountry: contact.country,
      startingPostalCode: contact.postalCode,
      startingCounty: contact.county
    });

    this.showChildView('addressFormContainer', this.addressForm);
    Syphon.deserialize(this, contact);
  },

  getFormData: function() {
    var formData = Syphon.serialize(this);
    return formData;
  }
});
