/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2020] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Ladda = require('ladda');

var GridView = require('psa-marionette/views/GridView');

var MultiButtonCell = require('common/backgrid/MultiButtonCell');

var SubmitEntityIndividual = require('../services/SubmitEntityIndividual');

module.exports = GridView.extend({
  className: 'con-ser-entity-individuals-section-view',

  onBeforeRender: function() {
    this.options.columns = [
      {
        name: 'firstName',
        label: 'First Name',
        cell: 'string',
        headerCell: 'custom',
        width: 15,
        editable: false
      },
      {
        name: 'lastName',
        label: 'Last Name',
        cell: 'string',
        headerCell: 'custom',
        width: 15,
        editable: false
      },
      {
        name: 'title',
        label: 'Title',
        cell: 'string',
        headerCell: 'custom',
        width: 15,
        editable: false
      },
      {
        name: 'email',
        label: 'Email',
        cell: 'string',
        headerCell: 'custom',
        width: 15,
        editable: false
      },
      {
        name: '',
        label: '',
        cell: MultiButtonCell.extend({
          buttons: [
            {
              name: 'Open',
              buttonClass: 'btn-primary btn-sm',
              href: function(model) {
                return '#conser/individual/' + model.get('individualId') + '/dashboard';
              }
            },
            {
              name: '',
              labelClass: 'fa fa-trash',
              buttonClass: 'btn-danger btn-sm',
              onClick: this.onClickDeleteIndividualLink.bind(this)
            }
          ]
        }),
        headerCell: 'custom',
        width: 10,
        editable: false,
        sortable: false
      }
    ];

    this.options.emptyText = 'No Individuals';
  },

  onClickDeleteIndividualLink: function(e, individual) {
    var self = this;
    e.preventDefault();

    var data = {};
    data.individualId = individual.attributes.individualId;
    data.entityId = this.model.get('entityId');
    data.shouldDelete = true;

    var l = Ladda.create(e.currentTarget);
    l.start();

    SubmitEntityIndividual(data)
      .done(function() {
        self.collection.remove(individual);
        l.stop();
      })
      .fail(function() {
        l.stop();
      });
  }
});
