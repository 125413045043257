/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var moment = require('moment');

var momentUtil = require('common/util/momentUtil');
var cleanUpResponse = require('common/util/cleanUpResponse');

module.exports = function(data) {
  var defer = $.Deferred();

  var editDateTimeStr = data.editDate + ' ' + data.editTime;

  var editDate = momentUtil.formatStr(
    editDateTimeStr,
    _.extend({}, momentUtil.defaultOutboundDateTimeConfig, { sourceFormat: 'MM/DD/YYYY hh:mm A' })
  );

  $.soap({
    body: {
      SubmitNoteUpdate: {
        $xmlns: 'http://dobs.pa.gov/cs/note/bpm/1.0',
        Note: {
          NoteID: data.id,
          ComplaintID: data.objectId,
          Content: data.content,
          IsDeleted: data.isDeleted,
          EditDate: editDate
        }
      }
    },
    blueprint: {
      id: '$..ID',
      objectId: '$..ComplaintID',
      content: '$..Content',
      editDate: '$..EditDate',
      isDeleted: '$..IsDeleted',
      createdDate: '$..CreatedDate',
      createdBy: '$..CreatedBy',
      modifiedDate: '$..ModifiedDate',
      modifiedBy: '$..ModifiedBy'
    }
  })
    .done(function(response) {
      cleanUpResponse(response, { cleanUpTextKeys: true });

      var rawEditDateTime = response.editDate;
      response.createdDate = moment(response, moment.ISO_8601).format('MM/DD/YYYY hh:mm a');
      response.modifiedDate = moment(response.modifiedDate, moment.ISO_8601).format('MM/DD/YYYY hh:mm a');
      response.editDate = moment(rawEditDateTime, moment.ISO_8601).format('MM/DD/YYYY');
      response.editTime = moment(rawEditDateTime, moment.ISO_8601).format('LT');
      response.editDateTimeMoment = moment(rawEditDateTime, moment.ISO_8601);
      response.editDateTime = response.editDateTimeMoment.format('MM/DD/YYYY hh:mm a');

      defer.resolve(response);
    })
    .fail(function(err) {
      defer.reject(err);
    });

  return defer.promise();
};
