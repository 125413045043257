/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Backbone = require('backbone');
var Marionette = require('backbone.marionette');
var Ladda = require('ladda');
var Syphon = require('backbone.syphon');
var Radio = require('backbone.radio');
var _ = require('underscore');

var momentUtil = require('common/util/momentUtil');
var BreadcrumbView = require('common/views/BreadcrumbView');

var SubmitEntityTracking = require('../services/SubmitEntityTracking');
var DepTrackingFormView = require('./DepTrackingFormView');

var tmplDepTrackingNewView = require('../templates/DepTrackingNewView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplDepTrackingNewView,

  className: 'dep-entity-new-tracking-form-view container-fluid',

  ui: {
    breadcrumb: '.breadcrumb-container',
    trackingFormContainer: '.new-tracking-form-container',
    submit: '.btn-submit'
  },

  regions: {
    breadcrumb: '@ui.breadcrumb',
    trackingFormContainer: '@ui.trackingFormContainer'
  },

  events: {
    'click @ui.submit': 'onClickSubmit'
  },

  onBeforeShow: function() {
    var entityId = this.model.get('entityId');
    var entityName = this.model.get('entityName');

    this.showChildView(
      'breadcrumb',
      new BreadcrumbView({
        breadcrumbs: [
          {
            link: '#dep/dashboard',
            text: 'Home'
          },
          {
            link: '#dep/entity/' + entityId + '/dashboard',
            text: entityName
          },
          {
            text: 'New Entity Tracking'
          }
        ]
      })
    );

    this.trackingForm = new DepTrackingFormView({
      model: new Backbone.Model({
        entityTypeId: this.model.get('entityTypeId'),
        entityCategoryId: this.model.get('entityCategoryId'),
        trackingLookups: this.model.get('trackingLookups') || {}
      })
    });

    this.showChildView('trackingFormContainer', this.trackingForm);
  },

  onClickSubmit: function(e) {
    e.preventDefault();

    var laddaContext = Ladda.create(e.currentTarget);
    this.trackingForm.validate().done(
      function() {
        laddaContext.start();

        var entityId = this.model.get('entityId');

        var formData = Syphon.serialize(this);
        formData.entityId = entityId;

        formData.enteredDate = momentUtil.formatStr(formData.enteredDate, momentUtil.defaultOutboundDateConfig);
        formData.adoptionDeadlineDate = momentUtil.formatStr(
          formData.adoptionDeadlineDate,
          momentUtil.defaultOutboundDateConfig
        );
        formData.expectedAdoptionDate = momentUtil.formatStr(
          formData.expectedAdoptionDate,
          momentUtil.defaultOutboundDateConfig
        );
        formData.adoptionDate = momentUtil.formatStr(formData.adoptionDate, momentUtil.defaultOutboundDateConfig);
        formData.asOfDate = momentUtil.formatStr(formData.asOfDate, momentUtil.defaultOutboundDateConfig);
        formData.examStartDate = momentUtil.formatStr(formData.examStartDate, momentUtil.defaultOutboundDateConfig);

        var isBankRedFlag = formData.trackingTypeId === '4';
        var isCreditUnionRedFlag = formData.trackingTypeId === '13';
        var isTrustRedFlag = formData.trackingTypeId === '23';

        var checkboxes = [];
        if (isBankRedFlag) {
          checkboxes = [
            'isLoanGrowthOver20',
            'isCommunityLeverageRatioUnder9',
            'isCREToRBCOver300',
            'isPastDueRatioOver5',
            'isTexasRatioOver30',
            'isTier1LeverageUnder8',
            'isROAUnderPt25',
            'isNonCoreRatioOver20',
            'isLoanToDepositOver100'
          ];
          _.each(checkboxes, function(attr) {
            formData[attr] = +formData[attr];
          });
        } else if (isCreditUnionRedFlag || isTrustRedFlag) {
          checkboxes = [
            'isLoanGrowthOver10',
            'isNetAssetsUnder8Pt5',
            'isPastDueRatioOver3Pt5',
            'isNetLongTermAssetsOver25',
            'isROAUnderPt50',
            'isNetWorthGrowthOver10',
            'isCashShortTermUnder10',
            'isShareGrowthOver10',
            'isAssetGrowthOver10'
          ];
          _.each(checkboxes, function(attr) {
            formData[attr] = +formData[attr];
          });
        }

        SubmitEntityTracking(formData)
          .done(function(trackingId) {
            Radio.channel('navigate').trigger('show:int:dp:tracking', entityId, trackingId);
          })
          .fail(function(err) {
            laddaContext.stop();
          });
      }.bind(this)
    );
  }
});
