var Handlebars = require("handlebars/dist/handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.escapeExpression;

  return "\n<div class=\"title-bar clearfix\">\n  <h1 class=\"page-title\">"
    + alias1(container.lambda(((stack1 = (depth0 != null ? depth0.entityIdentifiers : depth0)) != null ? stack1.entityName : stack1), depth0))
    + "</h1>\n</div>\n<div class=\"breadcrumb-container\"></div>\n\n<div class=\"row m-b-xs\">\n  <div class=\"col-sm-12 col-md-10 col-md-offset-1\">\n    <div class=\"form-group\">\n      <div class=\"col-sm-9 col-md-8 col-md-offset-2 col-sm-offset-3\">\n        <h3 class=\"m-t-n\">New Audit</h2> \n      </div>\n    </div>\n  </div>\n</div>\n<form class=\"audit-form-container\"></form>\n<form>\n  <div class=\"col-sm-12 col-md-10 col-md-offset-1\">\n    <div class=\"form-horizontal\">\n      <div class=\"form-group\">\n        <label class=\"control-label col-md-2 col-sm-3\">Documents</label>\n        <div class=\"col-md-10 col-sm-9\">\n          <div class=\"supporting-documents-container\"></div>\n        </div>\n      </div>\n    </div>\n  </div>\n</form>\n<div class=\"row m-b-lg\">\n  <div class=\"col-sm-12 col-md-10 col-md-offset-1\">\n    <div class=\"form-group\">\n      <div class=\"col-sm-9 col-md-8 col-md-offset-2 col-sm-offset-3\">\n        <a href=\"#dep/entity/"
    + alias1(((helper = (helper = helpers.entityId || (depth0 != null ? depth0.entityId : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"entityId","hash":{},"data":data}) : helper)))
    + "/dashboard\" class=\"btn btn-default\">Cancel</a>\n        <button class=\"btn btn-primary btn-submit\">Submit</button>\n      </div>\n    </div>\n  </div>\n</div>\n";
},"useData":true});