/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var NProgress = require('nprogress');
var Backbone = require('backbone');

var NavUtils = require('psa-core/util/nav');

var Scaffold = require('common/layout/Scaffold');

var GetEntityIdentifiers = require('../../common/services/GetEntityIdentifiers');
var GetEntityLocations = require('../../common/services/GetEntityLocations');

var NonDepEntityChangeLocationView = require('../views/NonDepEntityChangeLocationView');

module.exports = function(entityId, locationId) {
  NProgress.start();
  NProgress.set(0.6);

  $.when(GetEntityIdentifiers(entityId), GetEntityLocations(entityId))
    .done(function(entityIdentifier, entityLocations) {
      var location = entityLocations.find(function(location) {
        return location.id === locationId;
      });
      NavUtils.navigate('nondep/entity/' + entityId + '/location/change/' + locationId);
      Scaffold.content.show(
        new NonDepEntityChangeLocationView({
          viewMode: 'int',
          model: new Backbone.Model(
            _.extend(
              {
                entityId: entityId,
                location: location
              },
              entityIdentifier
            )
          )
        })
      );
      NProgress.done();
    })
    .fail(function(err) {
      NProgress.done();
    });
};
