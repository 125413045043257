/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Backbone = require('backbone');
var Marionette = require('backbone.marionette');
var Ladda = require('ladda');
var _ = require('underscore');

var SideNavSectionView = require('common/views/SideNavSectionView');
var openInNewTab = require('common/util/openInNewTab');
var toggleForm = require('common/util/toggleForm');

var SubmitBankLocationActivitiesFiling = require('../../services/SubmitBankLocationActivitiesFiling');
var DepFilingPointOfContactSectionView = require('../../views/DepFilingPointOfContactSectionView');
var DepFilingLawyerSectionView = require('../../views/DepFilingLawyerSectionView');
var DepFilingInstructionsSectionView = require('../../views/DepFilingInstructionsSectionView');
var DepFilingSupportingDocumentsSectionView = require('../../views/DepFilingSupportingDocumentsSectionView');
var DepBankLocationActivitiesPubReqsSectionView = require('./DepBankLocationActivitiesPubReqsSectionView');
var DepBankLocationActivitiesBoardResSectionView = require('./DepBankLocationActivitiesBoardResSectionView');
var DepBankLocationNewLocationsSectionView = require('./DepBankLocationNewLocationsSectionView');
var DepBankLocationActivitiesSectionView = require('./DepBankLocationActivitiesSectionView');
var DepBankLocationActivitiesCertificationSectionView = require('./DepBankLocationActivitiesCertificationSectionView');

require('../../styles/DepFilingPrintView.css');
var tmplDepBankLocationActivitiesFilingView = require('./DepBankLocationActivitiesFilingView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplDepBankLocationActivitiesFilingView,

  className: 'dep-bank-location-activities-filing-view m-b-md',

  behaviors: {
    ReadOnlyFormBehavior: {}
  },

  ui: {
    filingSections: '.filing-sections',
    nextButton: '.next-button',
    previousButton: '.previous-button'
  },

  events: {
    'click @ui.previousButton': 'onClickPrevious',
    'click @ui.nextButton': 'onClickNext'
  },

  regions: {
    filingSections: '@ui.filingSections'
  },

  onBeforeShow: function() {
    var filingDetails = this.model.get('filingDetails') || {};
    var filingContent = this.model.get('filingContent') || {};
    var actionTypes = this.model.get('actionTypes') || {};
    var locationTypes = this.model.get('locationTypes') || {};

    var filingId = this.model.get('filingId');
    var lawyers = this.model.get('lawyers');
    var entityCategoryId = filingDetails.entityCategoryId;
    var locations = filingContent.locations;
    var newLocations = filingContent.newLocations;

    var instructionsDocument = filingDetails.instructionsDocument;
    var additionalInstructionDocuments = filingDetails.instructionsDocuments;
    var instructions = filingDetails.instructions;
    var pointOfContact = filingDetails.pointOfContact;
    var mode = this.options.mode;
    var isNewExternal = mode === 'ext-new';
    var isForPrinting = mode === 'print';
    var isExpandInternal = mode === 'int-expand';

    this.form = new SideNavSectionView({
      collection: new Backbone.Collection([
        {
          id: 'pointOfContact',
          title: 'Point of Contact',
          view: new DepFilingPointOfContactSectionView({
            mode: this.options.mode,
            isReadOnly: this.options.isReadOnly,
            hasAddress: true,
            hasTitle: true,
            model: new Backbone.Model(pointOfContact)
          })
        },
        {
          id: 'locationActivitiesSubmissionLawyer',
          title: 'Filer',
          view: new DepFilingLawyerSectionView({
            mode: this.options.mode,
            isReadOnly: this.options.isReadOnly,
            model: new Backbone.Model({
              lawyers: lawyers,
              currentLawyer: filingDetails.lawyer
            })
          })
        },
        {
          id: 'instructions',
          title: 'I - General Information and Instructions',
          view: new DepFilingInstructionsSectionView({
            mode: this.options.mode,
            isReadOnly: this.options.isReadOnly,
            model: new Backbone.Model({
              instructionsDocument: instructionsDocument,
              additionalInstructionDocuments: additionalInstructionDocuments,
              instructions: instructions
            })
          })
        },
        {
          id: 'publicationRequirements',
          title: 'II - Publication Requirements',
          view: new DepBankLocationActivitiesPubReqsSectionView({
            mode: this.options.mode,
            isReadOnly: this.options.isReadOnly,
            model: new Backbone.Model({ filingContent: filingContent })
          })
        },
        {
          id: 'boardResolution',
          title: 'III - Board Resolution',
          view: new DepBankLocationActivitiesBoardResSectionView({
            mode: this.options.mode,
            isReadOnly: this.options.isReadOnly,
            model: new Backbone.Model({ filingContent: filingContent })
          })
        },
        {
          id: 'newLocations',
          title: 'Add Locations',
          view: new DepBankLocationNewLocationsSectionView({
            mode: this.options.mode,
            isReadOnly: this.options.isReadOnly,
            collection: new Backbone.Collection(newLocations),
            model: new Backbone.Model({
              actionTypes: actionTypes,
              locationTypes: locationTypes,
              entityCategoryId: entityCategoryId
            })
          })
        },
        {
          id: 'locationActivities',
          title: 'Update Locations',
          view: new DepBankLocationActivitiesSectionView({
            mode: this.options.mode,
            isReadOnly: this.options.isReadOnly,
            collection: new Backbone.Collection(locations),
            model: new Backbone.Model({
              actionTypes: actionTypes,
              locationTypes: locationTypes,
              entityCategoryId: entityCategoryId
            })
          })
        },
        {
          id: 'supportingDocuments',
          title: 'Other Supporting Documents',
          view: new DepFilingSupportingDocumentsSectionView({
            mode: this.options.mode,
            isReadOnly: this.options.isReadOnly,
            model: new Backbone.Model({
              filingDetails: filingDetails,
              filingContent: filingContent
            })
          })
        },
        {
          id: 'bankLocationActivitiesCertification',
          title: 'Certification',
          view: new DepBankLocationActivitiesCertificationSectionView({
            mode: this.options.mode,
            isReadOnly: this.options.isReadOnly,
            model: new Backbone.Model({
              filingId: filingId,
              CSFolderId: filingContent.CSFolderId,
              locationActivitiesFilingId: filingContent.locationActivityFilingId,
              filingDetails: filingDetails
            })
          })
        }
      ]),
      useNavigationButtons: false,
      expanded: isForPrinting || isExpandInternal,
      contentWidth: 'medium',
      useNavigationSavePrevButtons: !this.options.isReadOnly,
      buttons: [
        {
          buttonText: 'Save',
          buttonClass: 'btn-default ladda-button',
          buttonIconClass: 'fa-save',
          labelClass: 'ladda-label',
          buttonAttrs: {
            'data-style': 'expand-right'
          },
          onClick: this.onClickSave.bind(this)
        },
        {
          buttonText: 'Save & Print',
          buttonClass: 'btn-default ladda-button',
          buttonIconClass: 'fa-print',
          labelClass: 'ladda-label',
          buttonAttrs: {
            'data-style': 'expand-right'
          },
          onClick: this.onClickPrint.bind(this)
        }
      ]
    });

    if (this.options.isReadOnly) {
      this.form.collection.remove(this.form.collection.get('instructions'));
      this.form.collection.remove(this.form.collection.get('bankLocationActivitiesCertification'));
    }

    // If this isn't the new external form, or if the form is in print mode, remove the buttons
    if (!isNewExternal || isForPrinting) {
      this.form.options.buttons = [];
    }

    this.showChildView('filingSections', this.form);
  },

  onAttach: function() {
    if (this.options.mode === 'print') {
      this.switchToPrint();
    }
  },

  switchToPrint: function() {
    var textAreas = $('.input-view');

    _.each(textAreas, function(textArea) {
      var textAreaVal = textArea.value;
      var textAreaName = textArea.name;
      var printElementId = '#'.concat(textAreaName, 'PrintView');
      $(printElementId).text(textAreaVal);
    });

    var newLocations = this.model.get('filingContent').newLocations;
    var locations = this.model.get('filingContent').locations;
    this.printLocationActivities(newLocations, true);
    this.printLocationActivities(locations, false);

    toggleForm(textAreas, false);
  },

  printLocationActivities: function(locationArray, isNew) {
    _.each(locationArray, function(location) {
      var textAreas = $('.input-view');
      _.each(textAreas, function(textArea) {
        var textAreaVal = textArea.value;
        var textAreaId = textArea.id;
        var printElementId = '#'.concat(textAreaId, 'PrintView');
        $(printElementId).text(textAreaVal);
      });
      // var questionnaireElementId = '#questionnaireComments';
      // var locationElementId = '#fixedAssetsComments';
      // var foreignElementId = '#foreignBranchComments';
      // var closingNoticeId = '#closingNoticeComments';
      // var basisForDiscontinuanceId = '#basisForDiscontinuance';
      // var authorizingElementId = '#authorizingDocumentComments';

      // if (isNew) {
      //   questionnaireElementId = questionnaireElementId.concat(location.locationActivityId);
      //   locationElementId = locationElementId.concat(location.locationActivityId);
      //   foreignElementId = foreignElementId.concat(location.locationActivityId);
      //   authorizingElementId = authorizingElementId.concat(location.locationActivityId);

      //   var foreignTextArea = $(foreignElementId);
      //   var foreignTextAreaVal = foreignTextArea.val();
      //   var foreignPrintElementId = foreignElementId.concat('PrintView');
      //   $(foreignPrintElementId).text(foreignTextAreaVal);

      //   var authorizingTextArea = $(authorizingElementId);
      //   var authorizingTextAreaVal = authorizingTextArea.val();
      //   var authorizingPrintElementId = authorizingElementId.concat('PrintView');
      //   $(authorizingPrintElementId).text(authorizingTextAreaVal);
      // } else if (location.locationAction) {
      //   questionnaireElementId = questionnaireElementId.concat(location.locationAction.locationActivityId);
      //   locationElementId = locationElementId.concat(location.locationAction.locationActivityId);
      //   closingNoticeId = closingNoticeId.concat(location.locationAction.locationActivityId);
      //   basisForDiscontinuanceId = basisForDiscontinuanceId.concat(location.locationAction.locationActivityId);

      //   var closingNoticeTextArea = $(closingNoticeId);
      //   var closingNoticeTextAreaVal = closingNoticeTextArea.val();
      //   var closingNoticePrintElementId = closingNoticeId.concat('PrintView');
      //   $(closingNoticePrintElementId).text(closingNoticeTextAreaVal);

      //   var basisForDiscontinuanceTextArea = $(basisForDiscontinuanceId);
      //   var basisForDiscontinuanceTextAreaVal = basisForDiscontinuanceTextArea.val();
      //   var basisForDiscontinuancePrintElementId = basisForDiscontinuanceId.concat('PrintView');
      //   $(basisForDiscontinuancePrintElementId).text(basisForDiscontinuanceTextAreaVal);
      //   toggleForm(basisForDiscontinuanceTextArea, false);
      // } else {
      //   return;
      // }

      // var questionnaireTextArea = $(questionnaireElementId);
      // var questionnaireTextAreaVal = questionnaireTextArea.val();
      // var questionnairePrintElementId = questionnaireElementId.concat('PrintView');
      // $(questionnairePrintElementId).text(questionnaireTextAreaVal);

      // var locationTextArea = $(locationElementId);
      // var locationTextAreaVal = locationTextArea.val();
      // var locationPrintElementId = locationElementId.concat('PrintView');
      // $(locationPrintElementId).text(locationTextAreaVal);
    });
  },

  onClickNext: function(e) {
    var l = Ladda.create(e.currentTarget);
    l.start();

    this.saveForm()
      .done(function() {
        l.stop();
      })
      .fail(function() {
        l.stop();
      });
    window.scrollTo(0, 0);
  },

  onClickPrevious: function(e) {
    var l = Ladda.create(e.currentTarget);
    l.start();

    this.saveForm()
      .done(function() {
        l.stop();
      })
      .fail(function() {
        l.stop();
      });
    window.scrollTo(0, 0);
  },

  onClickSave: function(e) {
    var l = Ladda.create(e.currentTarget);
    l.start();

    this.saveForm()
      .done(function() {
        l.stop();
      })
      .fail(function() {
        l.stop();
      });
  },

  onClickPrint: function(e) {
    var filingId = this.model.get('filingId');
    var l = Ladda.create(e.currentTarget);
    l.start();

    this.saveForm()
      .done(function() {
        openInNewTab('#ext/dep/filing/' + filingId + '/print');
        l.stop();
      })
      .fail(function() {
        l.stop();
      });
  },

  saveForm: function() {
    var deferred = $.Deferred();
    var self = this;
    var filingId = this.model.get('filingId');
    var filingContent = this.model.get('filingContent') || {};

    this.form
      .validateAll({ type: 'save' })
      .done(function() {
        var formData = self.form.getFormData();
        formData.filingId = filingId;
        formData.locationActivitiesFilingId = filingContent.locationActivityFilingId;
        formData.allLocationActivities = formData.locationActivities.concat(formData.newLocations);
        SubmitBankLocationActivitiesFiling(formData)
          .done(function() {
            deferred.resolve();
          })
          .fail(function() {
            deferred.reject();
          });
      })
      .fail(function() {
        deferred.reject();
      });
    return deferred.promise();
  }
});
