/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var Backbone = require('backbone');
var Marionette = require('backbone.marionette');
var Syphon = require('backbone.syphon');

var GridView = require('psa-marionette/views/GridView');

var toggleForm = require('common/util/toggleForm');
var BooleanCell = require('common/backgrid/BooleanCell');

var CorpFinInvPortfolioClassesCollectionView = require('./CorpFinInvPortfolioClassesCollectionView');

var tmplCorpFinInvPortfolioFormView = require('../templates/CorpFinInvPortfolioFormView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplCorpFinInvPortfolioFormView,

  className: 'corp-fin-inv-portfolio-form-view row',

  ui: {
    portfolioDate: '.portfolio-date',
    currentPortfolioClasses: '.current-portfolio-classes',
    currentPortfolioClassesContainer: '.current-portfolio-classes-container',
    newPortfolioClassesContainer: '.new-portfolio-classes-container',
    addClass: '.add-class'
  },

  regions: {
    currentPortfolioClassesContainer: '@ui.currentPortfolioClassesContainer',
    newPortfolioClassesContainer: '@ui.newPortfolioClassesContainer'
  },

  events: {
    'click @ui.addClass': 'onClickAddClass'
  },

  childEvents: {
    'remove:class': 'onChildRemoveClass'
  },

  onBeforeShow: function() {
    var portfolio = this.model.get('portfolio') || {};
    var currClasses = portfolio.classes;

    this.currClasses = new GridView({
      columns: [
        {
          name: 'className',
          label: 'Class Name',
          cell: 'string',
          headerCell: 'custom',
          width: 90,
          editable: false
        },
        {
          name: 'shouldDelete',
          label: 'Delete?',
          cell: BooleanCell,
          headerCell: 'custom',
          width: 10,
          editable: true,
          sortable: false
        }
      ],
      emptyText: 'No Portfolio Classes',
      collection: new Backbone.Collection(currClasses)
    });
    this.showChildView('currentPortfolioClassesContainer', this.currClasses);

    this.newClasses = new CorpFinInvPortfolioClassesCollectionView({
      collection: new Backbone.Collection([])
    });
    this.showChildView('newPortfolioClassesContainer', this.newClasses);

    Syphon.deserialize(this, portfolio);

    if (_.isEmpty(portfolio)) {
      toggleForm(this.ui.currentPortfolioClasses, false);
    }

    this.ui.portfolioDate.datepicker();
  },

  onClickAddClass: function(e) {
    e.preventDefault();
    this.newClasses.collection.add({});
  },

  onChildRemoveClass: function(childView) {
    this.newClasses.collection.remove(childView);
  },

  getFormData: function() {
    var formData = Syphon.serialize(this);

    formData.portfolioId = this.model.get('portfolioId');

    formData.existingPortfolioClasses = _.reduce(
      this.currClasses.collection.toJSON(),
      function(memo, portfolioClass) {
        if (portfolioClass.shouldDelete) {
          memo.push(portfolioClass.id);
        }
        return memo;
      },
      []
    );
    formData.newClasses = this.newClasses.getFormData();
    return formData;
  }
});
