/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Marionette = require('backbone.marionette');
var Syphon = require('backbone.syphon');
var Radio = require('backbone.radio');
var Ladda = require('ladda');

var FileUploadCollection = require('psa-backbone/models/FileUploadCollection');
var FileUploadView = require('common/views/FileUploadView');
var switchToPrint = require('common/util/switchToPrint');

var toggleForm = require('common/util/toggleForm');
var AddressView = require('common/views/AddressView');

var SubmitChangeStatusCertificationApplication = require('../../services/SubmitChangeStatusCertificationApplication');
var CertificationDisplayView = require('./CertificationDisplayView');

require('./LicenseCertificationStatusChangeApplicationView.css');
var tmplLicenseCertificationStatusChangeApplicationView = require('./LicenseCertificationStatusChangeApplicationView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplLicenseCertificationStatusChangeApplicationView,

  className: 'license-certification-status-change-application-view row',

  ui: {
    certificationForm: '.certification-form',
    submitButton: '.submit-button',
    address: '.address',
    effectiveChangeDate: '.effective-change-date',
    hireDate: '.hire-date',
    certificateExpirationDate: '.certificate-expiration-date',
    selectCertificationStatusId: '.select-certification-status-id',
    selectCertification: '.select-certification',
    certificationCompleted: '.certification-completed',
    certificationRevoked: '.certification-revoked',
    applicationDocumentsFileContainer: '.application-documents-container',
    formData: '.form-data',
    isCertified: 'input[name="isCertified"]',
    certificationName: 'input[name="certificationName"]'
  },

  regions: {
    address: '@ui.address',
    selectCertification: '@ui.selectCertification',
    applicationDocumentsFileContainer: '@ui.applicationDocumentsFileContainer'
  },

  events: {
    'click @ui.submitButton': 'onClickSubmit',
    'change @ui.selectCertification': 'onChangeSelectedStatusId',
    'change @ui.selectCertificationStatusId': 'onChangeSelectedStatusId'
  },

  behaviors: {
    ReadOnlyFormBehavior: {}
  },

  templateHelpers: function() {
    var showAffirmation =
      !this.options.isReadOnly ||
      (this.options.mode === 'print' || this.options.mode === 'int' || this.options.mode === 'int-expand');
    return {
      isReadOnly: this.options.isReadOnly,
      showAffirmation: showAffirmation
    };
  },

  onBeforeShow: function() {
    var self = this;
    this.showChildView(
      'address',
      new AddressView({
        horizontal: true,
        startingCountry: this.model.get('country'),
        startingPostalCode: this.model.get('postalCode'),
        startingCounty: this.model.get('county'),
        isRequired: true
      })
    );

    this.ui.selectCertification.select2({
      allowClear: true,
      data: this.model.get('currentCreditCounselors'),
      dropdownCssClass: 'select2-options-bordered',
      templateResult: function(creditCounselor) {
        var certificationMarkup = new CertificationDisplayView({
          model: new Backbone.Model(creditCounselor)
        }).render().el.outerHTML;
        return $(certificationMarkup);
      },
      templateSelection: function(creditCounselor) {
        if (creditCounselor.firstName && creditCounselor.lastName) {
          return $(self.formatSelect2Row(creditCounselor));
        } else {
          return $('<span>--</span>');
        }
      },
      minimumResultsForSearch: -1
    });

    this.applicationDocumentsFile = new FileUploadView({
      isRequired: true,
      allowMultipleFiles: true,
      collection: new FileUploadCollection(this.model.get('applicationDocuments')),
      isReadOnly: this.options.isReadOnly,
      metadata: {
        documentTypeId: 10023
      }
    });
    this.showChildView('applicationDocumentsFileContainer', this.applicationDocumentsFile);

    Syphon.deserialize(this, this.model.toJSON());

    if (this.options.mode === 'resubmit') {
      this.ui.isCertified.prop('checked', false);
      this.ui.certificationName.val(null);
    }

    this.ui.hireDate.datepicker({
      endDate: '0d',
      startView: 'years'
    });

    this.ui.effectiveChangeDate.datepicker({
      startView: 'days',
      startDate: '0d'
    });

    this.ui.certificateExpirationDate.datepicker({
      startView: 'years'
    });

    this.ui.selectCertification.val(this.model.get('creditCounselorId')).trigger('change');
    this.onChangeSelectedStatusId();
  },

  onClickSubmit: function(e) {
    e.preventDefault();
    var self = this;
    this.validate().done(function() {
      var laddaContext = Ladda.create(self.ui.submitButton.get(0));
      laddaContext.start();
      SubmitChangeStatusCertificationApplication(self.getFormData())
        .done(function() {
          Radio.channel('nd:submit-application').trigger('submit');
          Radio.channel('nd:submit-application').trigger('resubmit', laddaContext);
        })
        .fail(function(err) {
          laddaContext.stop();
        });
    });
  },

  onChangeSelectedStatusId: function() {
    var formData = Syphon.serialize(this);
    var model = this.model.toJSON();

    switch (this.getFormData().certificationStatusId) {
      case '1':
        toggleForm(this.ui.certificationCompleted, true);
        toggleForm(this.ui.certificationRevoked, false);
        this.deserializeForm('1', model, {});
        break;
      case '2':
        toggleForm(this.ui.certificationCompleted, false);
        toggleForm(this.ui.certificationRevoked, true);
        this.deserializeForm('2', model, {});
        break;
      case '3':
        toggleForm(this.ui.certificationCompleted, false);
        toggleForm(this.ui.certificationRevoked, true);
        this.deserializeForm('3', model, {});
        break;
      case '4':
        toggleForm(this.ui.certificationCompleted, true);
        toggleForm(this.ui.certificationRevoked, true);
        var certification = this.model.get('currentCreditCounselors').find(function(certification) {
          return certification.id === formData.creditCounselorId;
        });
        certification = certification ? certification : {};
        this.deserializeForm('4', model, certification);
        break;
      default:
        toggleForm(this.ui.certificationCompleted, false);
        toggleForm(this.ui.certificationRevoked, false);
        break;
    }
  },

  deserializeForm: function(certificationId, model, otherModel) {
    certificationId === this.model.get('certificationStatusId')
      ? Syphon.deserialize(this.ui.formData, model)
      : Syphon.deserialize(this.ui.formData, otherModel);
  },

  getFormData: function() {
    var formData = Syphon.serialize(this);
    formData.entityId = this.model.get('entityId');
    formData.licenseId = this.model.get('licenseId');
    formData.applicationId = this.model.get('applicationId');
    formData.applicationDocuments = this.applicationDocumentsFile.collection.pluck('fileId');
    return formData;
  },

  validate: function() {
    return $.when(this.ui.certificationForm.parsley().whenValidate());
  },

  formatSelect2Row: function(creditCounselor) {
    return (
      '<span>Employee Name: ' +
      creditCounselor.firstName +
      ' ' +
      creditCounselor.lastName +
      '- Certificate Number: ' +
      creditCounselor.certificateNumber +
      '- Expires: ' +
      creditCounselor.expirationDate +
      '</span>'
    );
  },

  onAttach: function() {
    if (this.options.mode === 'print') {
      switchToPrint(this.$el, '.input-view');
    }
  }
});
