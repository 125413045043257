/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2020] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Backbone = require('backbone');
var Marionette = require('backbone.marionette');
var Radio = require('backbone.radio');
var Ladda = require('ladda');

var BreadcrumbView = require('common/views/BreadcrumbView');

var ConSerEntityFormView = require('./ConSerEntityFormView');

var SubmitEntity = require('../services/SubmitEntity');

var tmplConSerEntityNewView = require('../templates/ConSerEntityNewView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplConSerEntityNewView,

  className: 'con-ser-entity-new-view container-fluid',

  ui: {
    breadcrumb: '.breadcrumb-container',
    entityContainer: '.entity-form-container',
    submit: '.submit'
  },

  regions: {
    breadcrumb: '@ui.breadcrumb',
    entityContainer: '@ui.entityContainer'
  },

  events: {
    'click @ui.submit': 'onClickSubmit'
  },

  onBeforeShow: function() {
    this.showChildView(
      'breadcrumb',
      new BreadcrumbView({
        breadcrumbs: [
          {
            link: '#conser/dashboard',
            text: 'Home'
          },
          {
            text: 'New Entity'
          }
        ]
      })
    );

    this.entityForm = new ConSerEntityFormView({
      model: new Backbone.Model()
    });

    this.showChildView('entityContainer', this.entityForm);
  },

  onClickSubmit: function(e) {
    e.preventDefault();
    var self = this;
    this.validate().done(function() {
      var l = Ladda.create(self.ui.submit[0]);
      l.start();
      var formData = self.getFormData();
      var departmentId = '4000';
      SubmitEntity(formData)
        .done(function(entityId) {
          Radio.channel('navigate').trigger('show:int:cs:entity', departmentId, entityId);
        })
        .fail(function() {
          l.stop();
        });
    });
  },

  getFormData: function() {
    var formData = this.entityForm.getFormData();

    return formData;
  },

  validate: function() {
    return this.ui.entityContainer.parsley().whenValidate();
  }
});
