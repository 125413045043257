/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var cleanUpResponse = require('common/util/cleanUpResponse');

module.exports = function(licenseId) {
  var deferred = $.Deferred();
  $.soap({
    body: {
      GetLicenseContacts: {
        $xmlns: 'http://dobs.pa.gov/nd/license/bpm/1.0',
        LicenseID: licenseId
      }
    },
    blueprintArrays: ['GetLicenseContactsResponse.ND_LicenseContact'],
    blueprint: {
      licenseContacts: [
        '$..ND_LicenseContact',
        {
          id: '$..ID',
          licenseContactTypeId: '$..LicenseContactTypeID',
          licenseContactType: '$..LicenseContactType',
          firstName: '$..FirstName',
          lastName: '$..LastName',
          email: '$..Email',
          homePhoneNumber: '$..HomePhoneNumber',
          cellPhoneNumber: '$..CellPhoneNumber',
          workPhoneNumber: '$..WorkPhoneNumber',
          workPhoneExtension: '$..WorkPhoneExtension',
          fax: '$..Fax',
          company: '$..Company',
          title: '$..Title',
          address: {
            addressLine1: '$..AddressLine1',
            addressLine2: '$..AddressLine2',
            addressLine3: '$..AddressLine3',
            city: '$..City',
            state: '$..State',
            postalCode: '$..PostalCode',
            country: '$..Country'
          }
        }
      ]
    }
  })
    .done(function(response) {
      cleanUpResponse(response);
      deferred.resolve(response.licenseContacts);
    })
    .fail(function(err) {
      deferred.reject();
    });

  return deferred.promise();
};
