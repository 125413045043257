/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2020] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Backbone = require('backbone');
var Marionette = require('backbone.marionette');
var Syphon = require('backbone.syphon');
var Radio = require('backbone.radio');
var Ladda = require('ladda');
var moment = require('moment');

var Session = require('psa-core/Session');

var momentUtil = require('common/util/momentUtil');
var ContentServerLinkView = require('common/views/ContentServerLinkView');
var BreadcrumbView = require('common/views/BreadcrumbView');
var HeaderBarView = require('common/views/HeaderBarView');

var SubmitNewApplication = require('../services/SubmitNewApplication');

var tmplSecRegistrationNewView = require('../templates/SecRegistrationNewView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplSecRegistrationNewView,

  className: 'sec-registration-new-view container-fluid',

  ui: {
    csLinkContainer: '.cs-link-container',
    breadcrumb: '.breadcrumb-container',
    headerBar: '.header-bar-container',
    datePickerFields: '.date-picker',
    applicationForm: '.application-form',
    submit: '.submit'
  },

  regions: {
    csLinkContainer: '@ui.csLinkContainer',
    breadcrumb: '@ui.breadcrumb',
    headerBar: '@ui.headerBar',
    fileContainer: '@ui.fileContainer'
  },

  events: {
    'click @ui.submit': 'onClickSubmit'
  },

  behaviors: {
    ToggleBehavoir: {
      toggles: [
        {
          target: '.primary-examiner-container',
          group: '[name="licenseTypeId"]',
          valuesToShow: '1'
        }
      ]
    }
  },

  onBeforeShow: function() {
    var identifiers = this.model.get('identifiers') || {};
    var organizationId = this.model.get('organizationId');

    this.ui.datePickerFields.datepicker();

    this.showChildView(
      'csLinkContainer',
      new ContentServerLinkView({
        model: new Backbone.Model({
          buttonText: 'Open Organization Folder',
          contentServerId: identifiers.CSFolderId
        })
      })
    );

    this.showChildView(
      'breadcrumb',
      new BreadcrumbView({
        breadcrumbs: [
          {
            link: '#sec/dashboard',
            text: 'Home'
          },
          {
            link: '#sec/organization/' + organizationId + '/dashboard',
            text: identifiers.applicantLegalName
          },
          {
            text: 'New Registration'
          }
        ]
      })
    );

    var headerBarColumns = [
      {
        name: 'CRDNumber',
        label: 'CRD Number',
        minWidth: '160px',
        maxWidth: '200px'
      }
    ];

    identifiers.title = 'Organization';
    this.showChildView(
      'headerBar',
      new HeaderBarView({
        columns: new Backbone.Collection(headerBarColumns),
        model: new Backbone.Model(identifiers)
      })
    );
  },

  onClickSubmit: function(event) {
    event.preventDefault();
    var self = this;
    this.validate().done(function() {
      var l = Ladda.create(self.ui.submit[0]);
      l.start();
      var formData = self.getFormData();
      SubmitNewApplication(formData)
        .done(function() {
          Radio.channel('navigate').trigger('show:int:sc:organization', self.model.get('organizationId'));
        })
        .fail(function() {
          l.stop();
        });
    });
  },

  getFormData: function() {
    var formData = Syphon.serialize(this);

    formData.organizationId = this.model.get('organizationId');
    formData.applicationTypeId = '1';

    formData.initiatedStatusDate = momentUtil.formatStr(
      formData.initiatedStatusDate,
      momentUtil.defaultOutboundDateConfig
    );

    var timestamp = moment().format('MM/DD/YYYY h:mm A');
    var userName = Session.user.description;
    formData.comments = '[' + userName + ' - ' + timestamp + '] \n' + formData.comments;
    return formData;
  },

  validate: function() {
    return this.ui.applicationForm.parsley().whenValidate();
  }
});
