/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2020] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var Marionette = require('backbone.marionette');
var Syphon = require('backbone.syphon');
var Ladda = require('ladda');
var bodybuilder = require('bodybuilder');

var GridView = require('psa-marionette/views/GridView');
var PageableCollection = require('psa-backbone/models/PageableCollection');
var ElasticSearchSummaryView = require('common/views/ElasticSearchSummaryView');

var SelectOneCell = require('common/backgrid/SelectOneCell');
var ListCell = require('common/backgrid/ListCell');
var isPromise = require('common/util/isPromise');

var SearchSecOrganizations = require('../services/SearchSecOrganizations');

require('../styles/SecOrganizationSearchModalView.css');
var tmplSecOrganizationSearchModalView = require('../templates/SecOrganizationSearchModalView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplSecOrganizationSearchModalView,

  className: 'sec-organization-search-modal-view',

  ui: {
    search: '.btn-search',
    formInputs: ':input',
    searchSummary: '.search-summary',
    searchResultsGrid: '.search-result-grid-container',
    submit: '.btn-submit'
  },

  regions: {
    searchSummary: '@ui.searchSummary',
    searchResultsGrid: '@ui.searchResultsGrid'
  },

  events: {
    'click @ui.search': 'onClickSearch',
    'click @ui.submit': 'onClickSubmit',
    'keydown @ui.formInputs': 'onKeydownOfInputs'
  },

  templateHelpers: function() {
    return {
      searchLimit: this.options.searchLimit
    };
  },

  onBeforeShow: function() {
    this.searchSummaryView = new ElasticSearchSummaryView({
      model: new Backbone.Model({
        searchLimit: this.model.get('searchLimit')
      })
    });
    this.showChildView('searchSummary', this.searchSummaryView);

    this.searchResultsGrid = new GridView({
      columns: [
        {
          name: 'selected',
          label: '',
          cell: SelectOneCell.SelectOneRowCell,
          headerCell: SelectOneCell.SelectOneHeaderCell,
          width: 10,
          editable: true,
          sortable: false
        },
        {
          name: 'applicantLegalName',
          label: 'Legal Name',
          cell: 'string',
          headerCell: 'custom',
          width: 37,
          editable: false
        },
        {
          name: 'dbas',
          label: 'D/B/A',
          cell: ListCell,
          headerCell: 'custom',
          width: 38,
          editable: false
        },
        {
          name: 'crdNumber',
          label: 'CRD Number',
          cell: 'string',
          headerCell: 'custom',
          width: 15,
          editable: false
        }
      ],
      collection: new PageableCollection([], { pageSize: 5 }),
      emptyText: 'Search returned no organizations.'
    });

    this.showChildView('searchResultsGrid', this.searchResultsGrid);
  },

  onKeydownOfInputs: function(e) {
    // Searches on press of enter
    if (e.keyCode === 13) {
      this.onClickSearch(e);
    }
  },

  onClickSearch: function(e) {
    e.preventDefault();
    var self = this;

    var data = Syphon.serialize(this, {
      inputReaders: Syphon.SearchInputReaderSet
    });

    // Only perform search of entityName has a value
    if (!data.applicantLegalName && !data.dba && !data.crdNumber) {
      return;
    }

    var l = Ladda.create(this.ui.search[0]);
    l.start();

    this.searchSummaryView.reset();

    var body = bodybuilder();

    if (data.applicantLegalName) {
      body.query('bool', function(b) {
        b.orQuery('match', 'applicantLegalName', {
          query: data.applicantLegalName,
          fuzziness: 'AUTO',
          analyzer: 'standard'
        });
        b.orQuery('wildcard', 'applicantLegalName', data.applicantLegalName.toLowerCase() + '*');
        b.orQuery('match', 'applicantLegalName.phonetic', data.applicantLegalName);
        return b;
      });
    }

    if (data.dba) {
      body.query('bool', function(b) {
        b.orQuery('match', 'dbaName', {
          query: data.dba,
          fuzziness: 'AUTO',
          analyzer: 'standard'
        });
        b.orQuery('wildcard', 'dbaName', data.dba.toLowerCase() + '*');
        b.orQuery('match', 'dbaName.phonetic', data.dba);
        body.orQuery('nested', 'path', 'otherDBAs', function(nb) {
          nb.query('bool', 'minimum_should_match', 1, function(bb) {
            bb.orQuery('match', 'otherDBAs.dba', {
              query: data.dba,
              fuzziness: 'AUTO',
              analyzer: 'standard'
            });
            bb.orQuery('wildcard', 'otherDBAs.dba', data.dba.toLowerCase() + '*');
            bb.orQuery('match', 'otherDBAs.dba.phonetic', data.dba);
            return bb;
          });
          return nb;
        });
        return b;
      });
    }

    if (data.crdNumber) {
      body.query('wildcard', 'crdNumber', data.crdNumber + '*');
    }

    body.size(this.model.get('searchLimit'));
    var bodyObj = body.build();

    SearchSecOrganizations(bodyObj, data, {})
      .done(function(data) {
        l.stop();

        _.each(data.hits, function(hit) {
          hit.dbas = _.map(hit.dbas, function(dba) {
            return {
              value: dba
            };
          });
        });

        self.searchResultsGrid.collection.fullCollection.reset(data.hits);
        self.searchSummaryView.display(data);
      })
      .fail(function() {
        l.stop();
      });
  },

  onClickSubmit: function(e) {
    e.preventDefault();
    var selectedModel = this.searchResultsGrid.viewGrid.getSelectedModel();
    if (!selectedModel) {
      return;
    }
    var organization = selectedModel.toJSON();

    if (_.isFunction(this.options.onSubmit)) {
      var result = this.options.onSubmit(organization);
      if (isPromise(result)) {
        var spinner = Ladda.create(e.currentTarget);
        spinner.start();
        result.always(
          function() {
            this.triggerMethod('modal:close', organization);
          }.bind(this)
        );
      } else {
        this.triggerMethod('modal:close', organization);
      }
    } else {
      this.triggerMethod('modal:close', organization);
    }
  }
});
