/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Marionette = require('backbone.marionette');

var tmplInternalHeaderView = require('../templates/InternalHeaderView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplInternalHeaderView,

  ui: {
    brandLink: '.brand-link',
    brandText: '.navbar-text.navbar-brand',
    timeTrackingLinkItem: '.time-tracking-link-item',
    timeTrackingLink: '.time-tracking-link-item > a'
  },

  onBeforeShow: function() {
    this.setTimeTrackingLink(this.model.get('timeTrackingLink'));
  },

  setBrand: function(brandConfig) {
    this.ui.brandText.text(brandConfig.text);
    this.ui.brandLink.attr('href', brandConfig.link);
    return this;
  },

  setTimeTrackingLink: function(link) {
    if (link) {
      this.ui.timeTrackingLink.attr('href', link);
      this.ui.timeTrackingLinkItem.show();
    } else {
      this.ui.timeTrackingLink.attr('href', '#');
      this.ui.timeTrackingLinkItem.hide();
    }

    return this;
  }
});
