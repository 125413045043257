/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var Marionette = require('backbone.marionette');
var Radio = require('backbone.radio');

var Session = require('psa-core/Session');
var NavUtils = require('psa-core/util/nav');

var importAll = require('./util/importAll');
var startsWith = require('./util/startsWith');

var mappings = importAll(require.context('../', true, /mappings.json$/));
var commands = importAll(require.context('../', true, /\/commands\/..*\.js$/));
var builders = importAll(require.context('../', true, /\/builders\/..*\.js$/));
var taskBuilders = importAll(require.context('../', true, /\/*Builder\.js$/));
_.extend(builders, taskBuilders);

var showLogin = require('./login/commands/ShowLogin');
var showKeyStoneLogin = require('./login/commands/ShowKeyStoneLogin');

/**
 * Marionette now has its own copy of backbone within node_modules, so we need
 * to be careful about any place in the application where state is stored in the
 * Backbone object by Marionette.
 */
// var router = new Backbone.Router();
var router = new Marionette.AppRouter();

/* eslint-disable no-console */
console.log('PUB : ' + PUB);
console.log('EXTERNAL : ' + EXTERNAL);
console.log('ENVIRONMENT : ' + ENVIRONMENT);

/* eslint-enable no-console */

_.each(builders, function(builderClass) {
  var channelName = builderClass.channel || 'nonroutable';
  Radio.channel(channelName).reply(builderClass.event, builderClass.builder);
});

var setHeader = function() {
  var currentRoute = NavUtils.getCurrentRoute();
  var isInternalRoute =
    startsWith(currentRoute, 'nondep') ||
    startsWith(currentRoute, 'dep') ||
    startsWith(currentRoute, 'conser') ||
    startsWith(currentRoute, 'sec') ||
    startsWith(currentRoute, 'corpfin');

  var isExternalRoute = startsWith(currentRoute, 'ext');

  if (!Session.user) {
    Radio.channel('header').trigger('hide:header');
  } else if (currentRoute === 'int/applications') {
    Radio.channel('header').trigger('show:int-app-select');
  } else if (isInternalRoute) {
    Radio.channel('header').trigger('show:internal', currentRoute);
  } else if (isExternalRoute) {
    Radio.channel('header').trigger('show:external');
  }
};

_.each(mappings, function(eventMapping) {
  // iterate over the mappings for this context; each is something like 'show:dashboard:home'
  _.each(eventMapping, function(commandRouteMapping, eventName) {
    // grab the Command class for this mapping and register the radio event and route(s)
    var command = commands['./' + commandRouteMapping.command + '.js'];

    var extendedCommand = function() {
      setHeader();
      if (PUB) {
        command.apply(this, arguments);
      } else if (EXTERNAL && !Session.user) {
        showKeyStoneLogin();
      } else if (!Session.user) {
        showLogin();
      } else {
        command.apply(this, arguments);
      }
    };
    // Associate the event and the command on the hub.
    Radio.channel('navigate').on(eventName, extendedCommand);

    // Associate the route and the command on the router.
    _.each(commandRouteMapping.routes, function(pattern) {
      router.route(pattern, extendedCommand);
    });
  });
});

module.exports = {};

