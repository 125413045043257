/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var PageableCollection = require('psa-backbone/models/PageableCollection');

var PlaceholderView = require('common/views/PlaceholderView');
var MomentCell = require('common/backgrid/MomentCell');
var ButtonLinkCell = require('common/backgrid/ButtonLinkCell');

var DepEntityEnforcementActionsView = require('../views/DepEntityEnforcementActionsView');

var GetEnforcementsByEntity = require('../services/GetEnforcementsByEntity');

module.exports = {
  channel: 'dp',

  event: 'build:entity-enforcement-view',

  builder: function(entityId, entityTypeId) {
    return new PlaceholderView({
      viewPromise: buildEntityEnforcementView(entityId, entityTypeId)
    });
  }
};

function buildEntityEnforcementView(entityId, entityTypeId) {
  var deferred = $.Deferred();
  GetEnforcementsByEntity(entityId)
    .done(function(enforcements) {
      var viewColumns = [];
      var isTSP = entityTypeId === '3' || entityTypeId === '12' || entityTypeId === '23';

      if (isTSP) {
        viewColumns = [
          {
            name: 'action',
            label: 'Action',
            cell: 'string',
            headerCell: 'custom',
            width: 10,
            editable: false
          },
          {
            name: 'cmpDisplay',
            label: 'CMP',
            cell: 'string',
            headerCell: 'custom',
            width: 10,
            editable: false
          },
          {
            name: 'effectiveDate',
            label: 'Effective Date',
            cell: MomentCell.MomentCell,
            formatter: MomentCell.MomentDateFormatter.extend({
              emptyValue: '--'
            }),
            sortValue: MomentCell.MomentSortValue,
            headerCell: 'custom',
            width: 10,
            editable: false
          },
          {
            name: 'terminationDate',
            label: 'Termination Date',
            cell: MomentCell.MomentCell,
            formatter: MomentCell.MomentDateFormatter.extend({
              emptyValue: '--'
            }),
            sortValue: MomentCell.MomentSortValue,
            headerCell: 'custom',
            width: 10,
            editable: false
          },
          {
            name: 'status',
            label: 'Status',
            cell: 'string',
            headerCell: 'custom',
            width: 10,
            editable: false
          },
          {
            name: 'comments',
            label: 'Comments',
            cell: 'string',
            headerCell: 'custom',
            width: 20,
            editable: false
          },
          {
            name: '',
            label: '',
            cell: ButtonLinkCell.extend({
              name: 'Open',
              buttonClass: 'btn-primary btn-sm',
              href: function(model) {
                return '#dep/entity/' + model.get('entityId') + '/enforcement/' + model.get('id') + '/dashboard';
              }
            }),
            headerCell: 'custom',
            width: 8,
            editable: false,
            sortable: false
          }
        ];
      } else {
        viewColumns = [
          {
            name: 'action',
            label: 'Action',
            cell: 'string',
            headerCell: 'custom',
            width: 10,
            editable: false
          },
          {
            name: 'area',
            label: 'Area',
            cell: 'string',
            headerCell: 'custom',
            width: 10,
            editable: false
          },
          {
            name: 'type',
            label: 'Type',
            cell: 'string',
            headerCell: 'custom',
            width: 10,
            editable: false
          },
          {
            name: 'agency',
            label: 'Agency',
            cell: 'string',
            headerCell: 'custom',
            width: 10,
            editable: false
          },
          {
            name: 'examAsOfDate',
            label: 'Exam as of Date',
            cell: MomentCell.MomentCell,
            formatter: MomentCell.MomentDateFormatter.extend({
              emptyValue: '--'
            }),
            sortValue: MomentCell.MomentSortValue,
            headerCell: 'custom',
            width: 10,
            editable: false
          },
          {
            name: 'effectiveDate',
            label: 'Effective Date',
            cell: MomentCell.MomentCell,
            formatter: MomentCell.MomentDateFormatter.extend({
              emptyValue: '--'
            }),
            sortValue: MomentCell.MomentSortValue,
            headerCell: 'custom',
            width: 10,
            editable: false
          },
          {
            name: 'status',
            label: 'Status',
            cell: 'string',
            headerCell: 'custom',
            width: 10,
            editable: false
          },
          {
            name: 'comments',
            label: 'Comments',
            cell: 'string',
            headerCell: 'custom',
            width: 20,
            editable: false
          },
          {
            name: '',
            label: '',
            cell: ButtonLinkCell.extend({
              name: 'Open',
              buttonClass: 'btn-primary btn-sm',
              href: function(model) {
                return '#dep/entity/' + model.get('entityId') + '/enforcement/' + model.get('id') + '/dashboard';
              }
            }),
            headerCell: 'custom',
            width: 8,
            editable: false,
            sortable: false
          }
        ];
      }
      deferred.resolve(
        new DepEntityEnforcementActionsView({
          columns: viewColumns,
          collection: new PageableCollection(enforcements, { pageSize: 25 })
        })
      );
    })
    .fail(function(err) {
      deferred.reject(err);
    });
  return deferred.promise();
}
