/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2020] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var Backbone = require('backbone');

var HeaderBarView = require('common/views/HeaderBarView');

module.exports = HeaderBarView.extend({
  initialize: function(options) {
    var columns = [
      {
        name: 'crdNumber',
        label: 'CRD Number'
      },

      {
        name: 'caseNumber',
        label: 'Case Number'
      }
    ];

    if (options.isLicenseInvInq) {
      columns.push({
        name: 'licenseType',
        label: 'License Type'
      });
    }

    columns.push({
      name: 'category',
      label: 'Category'
    });

    _.extend(options, {
      columns: new Backbone.Collection(columns)
    });

    if (HeaderBarView.prototype.initialize) {
      HeaderBarView.prototype.initialize.apply(options);
    }
  }
});
