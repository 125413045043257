var Marionette = require('backbone.marionette');
var Ladda = require('ladda');
var Radio = require('backbone.radio');

var modelGet = require('common/util/modelGet');
var BreadcrumbView = require('common/views/BreadcrumbView');

var SubmitBoardAnnualMeetingRequirements = require('../services/SubmitBoardAnnualMeetingRequirements');
var DepBoardAnnualMeetingRequireFormView = require('./DepBoardAnnualMeetingRequirementsFormView');

var tmplDepBoardAnnualMeetingRequireEditView = require('../templates/DepBoardAnnualMeetingRequirementsEditView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplDepBoardAnnualMeetingRequireEditView,

  className: 'dep-board-annual-meeting-require-edit-view container-fluid',

  ui: {
    breadcrumb: '.breadcrumb-container',
    boardAnnualMeetingRequireContainer: '.board-annual-meeting-require-form-container',
    submit: '.submit'
  },

  regions: {
    breadcrumb: '@ui.breadcrumb',
    boardAnnualMeetingRequireContainer: '@ui.boardAnnualMeetingRequireContainer'
  },

  events: {
    'click @ui.submit': 'onClickSubmit'
  },

  onBeforeShow: function() {
    var boardAnnualMeetingRequirements = this.model.get('boardAnnualMeetingRequirements') || {};

    this.boardAnnualMeetingRequireId = boardAnnualMeetingRequirements.boardAnnualMeetingRequireId;
    this.entityId = this.model.get('entityId');
    var entityName = modelGet(this.model, 'entityIdentifiers.entityName');

    this.showChildView(
      'breadcrumb',
      new BreadcrumbView({
        breadcrumbs: [
          {
            link: '#dep/dashboard',
            text: 'Home'
          },
          {
            link: '#dep/entity/' + this.entityId + '/dashboard',
            text: entityName
          },
          {
            text: 'Edit Board/Annual Meeting Requirements'
          }
        ]
      })
    );

    this.boardAnnualMeetingRequireForm = new DepBoardAnnualMeetingRequireFormView({
      model: this.model
    });
    this.showChildView('boardAnnualMeetingRequireContainer', this.boardAnnualMeetingRequireForm);
  },

  onClickSubmit: function(event) {
    event.preventDefault();
    var self = this;
    this.boardAnnualMeetingRequireForm.validate().done(function() {
      var l = Ladda.create(self.ui.submit[0]);
      l.start();
      var formData = self.getFormData();
      SubmitBoardAnnualMeetingRequirements(formData)
        .done(function() {
          Radio.channel('navigate').trigger('show:int:dp:entity', self.entityId);
        })
        .fail(function() {
          l.stop();
        });
    });
  },

  getFormData: function() {
    var formData = this.boardAnnualMeetingRequireForm.getFormData();

    formData.boardAnnualMeetingRequireId = this.boardAnnualMeetingRequireId;
    formData.entityId = this.entityId;

    return formData;
  }
});
